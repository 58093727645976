import * as React from 'react';

import { modularScale, BasicSkillIcon, IconSize } from '@adept-at/lib-react-components';
import { Button, Menu, MenuItem, Switch, Tooltip, withStyles } from '@material-ui/core';
import { mdiCheckboxMarkedCircleOutline, mdiChevronDown } from '@mdi/js';
import Icon from '@mdi/react';
import { format } from 'date-fns';
import pluralize from 'pluralize';
import styled from 'styled-components';
import { forceShowScrollbarsJSSFragment } from 'styles/Shared.styles';
import { formatTime } from 'utils/time';

import { Descendant } from '../Enroll';

const ContentTitle = styled.div<{ $strikethrough: boolean }>`
    font-size: ${modularScale(0.5)};
    font-weight: 500;
    color: ${({ theme, $strikethrough }) => ($strikethrough ? theme.colors.textTertiary : theme.colors.textSecondary)};
    text-decoration: ${({ $strikethrough }) => ($strikethrough ? 'line-through' : 'none')};
    display: block;
    max-width: 290px;
    overflow-wrap: break-word;
    white-space: normal;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
`;

const StyledMenuItem = styled(MenuItem)`
    display: flex;
    &:hover: {
        backgroundcolor: transparent;
    }
`;

const Bullet = styled.div`
    font-size: 0.2rem;
    width: 0.5rem;
    margin: 0 0.25rem 0 0.25rem;
`;

const CheckmarkIcon = styled(Icon)`
    color: ${({ theme }) => theme.colors.success};
    margin-left: 0.5rem;
    & :hover: {
        cursor: pointer;
    }
`;

const IconContainer = styled.div`
    display: flex;
    width: 1.5rem;
    color: ${({ theme }) => theme.colors.textTertiary};
    height: 1.5rem;
    margin-right: 0.5rem;
`;

const MiddleSection = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 215px;
`;

const EndSection = styled.div<{ $hide: boolean }>`
    display: flex;
    justify-content: flex-start;
    visibility: ${({ $hide }) => ($hide ? 'hidden' : 'inherit')};
`;

const StyledTooltip = withStyles((theme) => ({
    tooltip: {
        fontSize: '12px',
        zIndex: 5000,
        opacity: 1
    },
    popper: {
        opacity: 1,
        zIndex: 5000
    }
}))(Tooltip);

export const SkillSelection: React.FC<{
    descendants: Descendant[];
    setFilter: React.Dispatch<React.SetStateAction<Record<string, Descendant>>>;
    estimatedTimeToConsume: number;
    skillCount: number;
    filter: Record<string, Descendant>;
}> = ({ descendants, filter, setFilter, skillCount, estimatedTimeToConsume }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onSkillToggle = (descendant: Descendant) => {
        setFilter((previousFilter) => {
            if (previousFilter[descendant.id]) delete previousFilter[descendant.id];
            else {
                previousFilter[descendant.id] = descendant;
            }
            return { ...previousFilter };
        });
    };

    return (
        <>
            <Button
                id="skill-selection-button"
                aria-controls={open ? 'skill-selection-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="outlined"
                disableElevation
                disableRipple
                style={{
                    paddingRight: '5px',
                    marginTop: '1rem',
                    borderColor: 'rgba(26,35,39,0.8)',
                    fontSize: '.81rem'
                }}
                onClick={handleClick}
                endIcon={<Icon path={mdiChevronDown} size={IconSize.Medium} />}
            >
                <>
                    {`${skillCount} ${pluralize('Skill', skillCount)} `}
                    <Bullet>{'\u2B24'}</Bullet>
                    {` ${formatTime(estimatedTimeToConsume, 'medium')}`}
                </>
            </Button>
            <Menu
                //zindex of tooltip is insanely high (2500 ish), so we have to get crazy with the index of Z
                style={{ zIndex: 2700, maxHeight: '60vh', maxWidth: '415px', ...forceShowScrollbarsJSSFragment }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                id="skill-selection-menu"
                MenuListProps={{
                    'aria-labelledby': 'skill-selection-button'
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <>
                    {descendants.map(({ id, title, completionDate, ...rest }) => {
                        return (
                            <StyledMenuItem
                                key={id}
                                disableRipple
                                style={{
                                    marginTop: '0.25rem',
                                    marginBottom: '0.25rem',
                                    backgroundColor: 'transparent',
                                    cursor: 'default'
                                }}
                            >
                                <IconContainer>
                                    <BasicSkillIcon />
                                </IconContainer>
                                <MiddleSection>
                                    <ContentTitle $strikethrough={!!filter[id]}>
                                        {title}
                                        {completionDate ? (
                                            <StyledTooltip
                                                title={`Last completed on ${format(
                                                    new Date(completionDate),
                                                    'MM/dd/yyyy'
                                                )}, selecting relearn will wipe progress and add this to your enrollment period.`}
                                                style={{ zIndex: 9999, position: 'absolute' }}
                                            >
                                                <CheckmarkIcon
                                                    path={mdiCheckboxMarkedCircleOutline}
                                                    size={IconSize.Small}
                                                    style={{ marginRight: '0.5rem' }}
                                                />
                                            </StyledTooltip>
                                        ) : null}
                                    </ContentTitle>
                                </MiddleSection>
                                <EndSection $hide={!completionDate}>
                                    <Button
                                        onClick={() => {
                                            if (completionDate) onSkillToggle({ id, title, completionDate, ...rest });
                                        }}
                                        disableRipple
                                        variant="text"
                                        color="primary"
                                        style={{ display: 'inherit', backgroundColor: 'transparent', width: '100px' }}
                                    >
                                        {'Relearn'}
                                        <Switch
                                            disableRipple
                                            color="primary"
                                            size="small"
                                            defaultChecked={!filter[id]}
                                            checked={!filter[id]}
                                            style={{ backgroundColor: 'transparent' }}
                                        />
                                    </Button>
                                </EndSection>
                            </StyledMenuItem>
                        );
                    })}
                </>
            </Menu>
        </>
    );
};
