import { useCallback } from 'react';

import { ItemProgress, Skill } from 'components/ContentContext/Interfaces';
import { useQueryClient } from 'react-query';

import { buildGetSkillBySlugQueryKey, GetSkillBySlugResponse } from '../getSkillBySlug';

interface UseQueryClientProgressUpdaterOutput {
    updateSkillProgressQueryData: () => void;
}

export const useQueryClientProgressUpdater = (
    tenantSlug: string,
    skillSlug: string,
    buildNewProgressFromPrevious: (previous: Skill) => ItemProgress
): UseQueryClientProgressUpdaterOutput => {
    const queryClient = useQueryClient();

    const updateSkillProgressQueryData = useCallback(() => {
        queryClient.setQueryData<GetSkillBySlugResponse>(
            buildGetSkillBySlugQueryKey(tenantSlug, skillSlug),
            (previous) => {
                const prevSkill = previous?.getEntityBySlug ?? ({} as Skill);

                const updatedSkill: Skill = {
                    ...prevSkill,
                    progress: {
                        ...(prevSkill?.progress as ItemProgress),
                        ...buildNewProgressFromPrevious(prevSkill)
                    }
                };

                return {
                    getEntityBySlug: updatedSkill
                };
            }
        );
    }, [queryClient, skillSlug, tenantSlug, buildNewProgressFromPrevious]);

    return { updateSkillProgressQueryData };
};
