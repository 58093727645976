import React, { useContext, useMemo } from 'react';

import { Switch, Button } from '@material-ui/core';
import { LearnEditContext } from 'pages/learn/LearnEditContext';
import styled from 'styled-components';
import { modularScale } from 'utils/modular_scale';

import { LearnContext } from '../LearnContext';

const EditToggleButton = styled(Button)`
    line-height: 1;
    display: flex;
    align-items: center;
    font-size: ${modularScale(-0.5)};
    margin-right: 2rem;

    &:hover {
        background: none;
    }
`;

const ToggleState = styled.div<{ $isActive }>`
    color: ${(props) => (props.$isActive ? props.theme.colors.primary : 'inherit')};
    cursor: pointer;
`;

const SkillEditToggle: React.FC = () => {
    const { skillEditId, setSkillEditId, hasUpdatePermission } = useContext(LearnEditContext);
    const learnCtx = useContext(LearnContext);

    const handleChange = () => {
        if (skillEditId) {
            setSkillEditId('');
        } else {
            setSkillEditId(learnCtx?.skill?.skillId || '');
        }
    };

    const isEditing = useMemo(() => !!skillEditId, [skillEditId]);

    if (hasUpdatePermission) {
        return (
            <EditToggleButton
                size="small"
                disableRipple
                onClick={handleChange}
                aria-label={`${isEditing ? 'Finish' : 'Begin'} editing skill`}
            >
                <Switch checked={isEditing} color="primary" size="medium" />
                <ToggleState $isActive={isEditing}>{isEditing ? 'Editing' : 'Edit'}</ToggleState>
            </EditToggleButton>
        );
    }

    return null;
};

export default SkillEditToggle;
