import React, { useContext } from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { mdiCircleOutline, mdiCheckCircleOutline } from '@mdi/js';
import Icon from '@mdi/react';
import styled, { ThemeContext } from 'styled-components';

import { mdiProgressCircleOutline } from './assets/paths';

export const ProgressWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-left: 1rem;

    svg {
        width: ${IconSize.Medium};
    }
`;

interface ProgressIconProps {
    state?: number;
}

export const ProgressIcon: React.FC<ProgressIconProps> = ({ state = 0 }) => {
    const theme = useContext(ThemeContext);

    if (state <= 0) {
        return <Icon color={theme.colors.textTertiary} path={mdiCircleOutline} />;
    }

    if (state > 0 && state < 1) {
        return <Icon color={theme.colors.primaryLighter} path={mdiProgressCircleOutline} />;
    }

    return <Icon color={theme.colors.success} path={mdiCheckCircleOutline} />;
};
