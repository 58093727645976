import AdepteductMessage from 'lib/communication/message/base/AdepteductMessage';
import PossibleEventFields from 'lib/communication/message/type/PossibleEventFields';

import { MAX_MESSAGES_PER_CHANNEL, RealTimeMessagesReducerState } from '.';

const handleMessageAppend = (
    state: RealTimeMessagesReducerState,
    values: AdepteductMessage<PossibleEventFields>
): RealTimeMessagesReducerState => {
    const { channelId, id } = values;
    const filteredChannelMessages = (state[channelId] ?? []).filter(
        (stateChannelMessage) => stateChannelMessage.id !== id
    );
    const updatedChannelMessages = [values, ...filteredChannelMessages];

    // Determine if we need to remove messages from the beginning of the list
    const numberOfMessagesToTrim = Math.max(0, updatedChannelMessages.length - MAX_MESSAGES_PER_CHANNEL);

    return {
        ...state,
        [channelId]:
            numberOfMessagesToTrim === 0
                ? updatedChannelMessages
                : updatedChannelMessages.splice(0, numberOfMessagesToTrim)
    };
};

export default handleMessageAppend;
