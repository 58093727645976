import React, { createContext, useState, useCallback, useContext, useEffect } from 'react';

import { BreadCrumbData } from 'components/BreadCrumbTrail';
import { OwnerInfo } from 'components/ContentContext/Interfaces';
import { ProfileContext, ProfileOption } from 'context/ProfileContext';
import { useHistory } from 'react-router-dom';

import { NavButton } from './NavButtons';

interface HeaderContextInterface {
    title: string;
    setTitle: (title: string) => void;

    icon: string;
    setIcon: (icon: string) => void;

    isContentLoading: boolean;
    setIsContentLoading: (isLoading: boolean) => void;

    isSaving: boolean;
    setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;

    breadcrumbs: BreadCrumbData[];
    setBreadcrumbs: React.Dispatch<React.SetStateAction<BreadCrumbData[]>>;

    navButtons: NavButton[];
    setNavButtons: React.Dispatch<React.SetStateAction<NavButton[]>>;

    currentContentOwner: null | ProfileOption;
    setCurrentContentOwner: (ownerInfo: OwnerInfo) => void;
}

//urls where we should default the loading.
export const consumptionUrlIndicators = ['/skill/', '/course/'];

const HeaderContext = createContext({} as HeaderContextInterface);

const { Provider, Consumer } = HeaderContext;

const HeaderProvider: React.FC = ({ children }) => {
    const { availableProfiles } = useContext(ProfileContext);
    const history = useHistory();

    const [isSaving, setIsSaving] = useState(false);
    const [breadcrumbs, setBreadcrumbs] = useState<BreadCrumbData[]>([]);
    const [navButtons, setNavButtons] = useState<NavButton[]>([]);
    const [title, setTitle] = useState('');
    const [icon, setIcon] = useState('');

    //@todo break the ownership/loading of content out into its own context (build off of learn context in the future since it provides is loading and other details )
    //ignore how disgusting this is for now
    //we need to expose isContentLoading to prevent major flickers between the adept logo and an organizations logo,
    //while at the same time still resolving the adept logo if the content resolves as unowned by an org.
    const [isContentLoading, setIsContentLoading] = useState<boolean>(false);
    const [currentContentOwner, setCurrentContentOwnerProfile] = useState<null | ProfileOption>(null);

    useEffect(() => {
        //setting it to true for content consumption urls gets rid of the last bit of flicker.
        consumptionUrlIndicators.forEach((url) => {
            if (history?.location?.pathname.includes(url)) setIsContentLoading(true);
        });
    }, [history?.location?.pathname]);

    const setCurrentContentOwner = useCallback(
        (ownerInfo: OwnerInfo) => {
            let foundMatch = false;
            availableProfiles.forEach((profile) => {
                if (profile.id === ownerInfo.id) {
                    foundMatch = true;
                    setCurrentContentOwnerProfile(profile);
                }
            });
            if (!foundMatch) setCurrentContentOwnerProfile(null);
        },
        [availableProfiles]
    );

    return (
        <Provider
            value={{
                title,
                setTitle,

                icon,
                setIcon,

                currentContentOwner,
                setCurrentContentOwner,

                breadcrumbs,
                setBreadcrumbs,

                navButtons,
                setNavButtons,

                isSaving,
                setIsSaving,

                isContentLoading,
                setIsContentLoading
            }}
        >
            {children}
        </Provider>
    );
};

export { HeaderContext, HeaderProvider, Consumer as HeaderConsumer };
