import React from 'react';

import useSystemChannelNotifier from './useSystemChannelNotifier';
import useUserRegistrationCompleteNotifier from './useUserRegistrationCompleteNotifier';

/**
 * A place for hooks that need access to the GlobalContexts, but require maximum safety from rendering more than once.
 */
const GlobalHooks: React.FC = ({ children }) => {
    useSystemChannelNotifier();
    useUserRegistrationCompleteNotifier();

    return <div data-testid="GlobalHooksLiveHere">{children}</div>;
};

export default GlobalHooks;
