import { useContext, useCallback } from 'react';

import HistoricalCommunicationContext from 'context/HistoricalCommunicationContext';
import { HistoricalMessagesReducerState } from 'context/HistoricalCommunicationContext/useHistoricalMessageRepository';
import { RealTimeCommunicationContext, RealTimeMessagesReducerState } from 'context/RealTimeCommunicationContext';
import { API_COMMUNICATION } from 'lib/ApiConstants';
import AckableAdepteductMessage from 'lib/communication/message/base/AckableAdepteductMessage';
import { useMutation } from 'react-query';

import useGqlClient from '../../../useGqlClient';

import ackMessageBatchApi, { AckMessageDetail } from './ackMessageBatchApi';

interface UseAckMessageBatchOutput {
    ackMessageBatch: (ackMessageDetails: AckMessageDetail[]) => void;
}

export interface AckBatchParams {
    ackMessageDetails: AckMessageDetail[];
}

export const handleAckMessageBatch = (
    state: RealTimeMessagesReducerState | HistoricalMessagesReducerState,
    { ackMessageDetails }: AckBatchParams
): RealTimeMessagesReducerState | HistoricalMessagesReducerState => {
    const channelIds = [...new Set(ackMessageDetails.map((detail) => detail.entityId))];

    return Object.fromEntries(
        Object.entries(state).map(([channelId, messages]) => {
            if (channelIds.includes(channelId)) {
                const updatedMessages = (messages ?? []).map((message) => {
                    const ackDetails = ackMessageDetails.find((details) => details.messageId === message.id);

                    if (message instanceof AckableAdepteductMessage && ackDetails) {
                        message.addAck(ackDetails.ackType, new Date().toISOString());
                    }

                    return message;
                });
                return [channelId, updatedMessages];
            }

            return [channelId, messages];
        })
    );
};

/**
 * Ack a batch of messages, and it will be correctly handled both render-wise and in the backend.
 */
const useAckMessageBatch = (): UseAckMessageBatchOutput => {
    const { ackMessageBatch: ackMessageBatchInContext } = useContext(HistoricalCommunicationContext);
    const { ackMessageBatch: ackMessageBatchInRealTimeContext } = useContext(RealTimeCommunicationContext);

    const { client, withMutationOptions } = useGqlClient(API_COMMUNICATION);
    const { mutate: ackMessageBatchInApi } = useMutation(ackMessageBatchApi(client), withMutationOptions());

    const ackMessageBatch = useCallback(
        (ackMessageDetails: AckMessageDetail[]) => {
            ackMessageBatchInContext(ackMessageDetails);
            ackMessageBatchInRealTimeContext(ackMessageDetails);
            ackMessageBatchInApi({ ackMessageDetails });
        },
        [ackMessageBatchInApi, ackMessageBatchInContext, ackMessageBatchInRealTimeContext]
    );

    return { ackMessageBatch };
};

export default useAckMessageBatch;
