import React, { useContext, useEffect, useRef } from 'react';

import Skill from 'components/builder/skills/Skill';
import { LearnContext } from 'components/learn/LearnContext';
import { ScrollContext } from 'components/learn/ScrollContext';
import { SkillContainer } from 'components/SkillLayout.styles';

export const LearnEditor: React.FC = () => {
    const { setScrollRef } = useContext(ScrollContext);

    const { skill } = useContext(LearnContext);

    const ref = useRef<HTMLElement>(null);

    useEffect(() => {
        if (ref) setScrollRef(ref);
    }, [ref]);

    return (
        <SkillContainer>
            <Skill skill={skill} />
        </SkillContainer>
    );
};
