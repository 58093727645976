import React from 'react';

import { LearningMode } from '@adept-at/lib-react-components';
import { Alert } from '@material-ui/lab';
import Modal from 'components/modals';
import styled from 'styled-components';

interface EnableModeModalProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    title: string;
    subtitle: string;
    mode: LearningMode;
    iconPath: string;
}

const StyledAlert = styled(Alert)`
    margin: 24px 0;
`;

const EnableModeModal: React.FC<EnableModeModalProps> = ({
    open,
    onClose,
    onConfirm,
    title,
    subtitle,
    mode,
    iconPath
}) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            onConfirm={onConfirm}
            modalTitle={title}
            confirmButtonText={`Enable ${mode}`}
            titleIconPath={iconPath}
        >
            <>
                <p>{subtitle}</p>

                <StyledAlert severity="info">
                    <i>Enabling {mode} will reset your current progress on this skill.</i>
                </StyledAlert>
            </>
        </Modal>
    );
};

export default EnableModeModal;
