import React, { useContext, useEffect, useMemo, useState } from 'react';

import { Button } from '@material-ui/core';
import { mdiArrowLeft, mdiArrowRight } from '@mdi/js';
import Icon from '@mdi/react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { modularScale } from '../../../../utils/modular_scale';

import ResponderTextField from './ResponderTextField';
import { ButtonContainer, StepContainer } from './Storyboard.styles';
import { STEPS, StoryboardContext, StoryboardItem } from './StoryboardContext';

const MAX_RESPONSE_CHARACTERS = 175;

const Prompt = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;

    &:last-child {
        font-weight: bold;
    }

    &:not(:last-child):after {
        content: '';
        margin: 0.5rem;
        height: 1.5rem;
        width: 1px;
        background-color: ${(props) => props.theme.colors.border};
    }
`;

const FinalPromptHelper = styled.div`
    font-size: ${modularScale(-1)};
    margin-top: 1rem;
    text-align: center;
`;

interface ResponderProps {
    prompts: string[];
    stepName: string;
    nextStepName: string;
    defaults: StoryboardItem;
    onChange: (storyboardItem: StoryboardItem) => void;
    onFinish?: () => void;
}

const Responder: React.FC<ResponderProps> = ({ onChange, stepName, nextStepName, prompts, defaults, onFinish }) => {
    const { nextStep, prevStep, step, setStep } = useContext(StoryboardContext);
    const hookFormMethods = useForm<{ summary: string | null }>({
        mode: 'onTouched',
        reValidateMode: 'onChange'
    });

    const { register, handleSubmit, errors, formState } = hookFormMethods;

    const [summary, setSummary] = useState<string | null>(defaults?.summary ?? null);
    const history = useHistory();

    useEffect(() => {
        onChange({ summary });
    }, [summary, onChange]);

    const nextStepIdentifier = useMemo(() => {
        if (onFinish) {
            return undefined;
        }

        return `${STEPS[step + 1]}`;
    }, [step, onFinish]);

    const prevStepIdentifier = useMemo(() => {
        if (step === 0) {
            return 'start';
        }

        return `${STEPS[step - 1]}`;
    }, [step]);

    const onNext = () => {
        history.push({ search: nextStepIdentifier ? `?prompt=${nextStepIdentifier}` : undefined });

        if (onFinish) {
            setStep(0);
            onFinish();
            return;
        }

        nextStep();
    };

    const onPrev = () => {
        history.push({ search: `?prompt=${prevStepIdentifier}` });

        prevStep();
    };

    const onSubmit = (values) => {
        setSummary(values.summary);
        onNext();
    };

    return (
        <FormProvider {...hookFormMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <StepContainer>
                    {/* Keep these wrapped in a div so we can address the prompt (last-child) differently */}
                    <div>
                        {prompts.map((prompt, index) => (
                            <Prompt key={index}>{prompt}</Prompt>
                        ))}
                    </div>

                    <ResponderTextField
                        ariaLabel={`${stepName} summary`}
                        name="summary"
                        inputRef={register({
                            maxLength: {
                                value: MAX_RESPONSE_CHARACTERS,
                                message: `Must be ${MAX_RESPONSE_CHARACTERS} characters or less.`
                            }
                        })}
                        error={errors.summary ? true : false}
                        helperText={errors.summary ? errors.summary.message : null}
                        defaultValue={summary ?? undefined}
                    />

                    <FinalPromptHelper>
                        The answer to this question is the &quot;{stepName}&quot; in your Skill Outline. You can always
                        edit it later.
                    </FinalPromptHelper>

                    <ButtonContainer>
                        <Button onClick={onPrev} data-testid={`back-to-${prevStepIdentifier}`}>
                            <Icon path={mdiArrowLeft} /> Back
                        </Button>
                        <Button
                            type="submit"
                            onClick={handleSubmit(onSubmit)}
                            color="primary"
                            variant="contained"
                            data-testid={nextStepIdentifier ? `go-to-${nextStepIdentifier}` : 'finish-storyboard'}
                            disabled={!formState.isValid}
                        >
                            {nextStepName} <Icon path={mdiArrowRight} />
                        </Button>
                    </ButtonContainer>
                </StepContainer>
            </form>
        </FormProvider>
    );
};

export default Responder;
