import React, { useContext, useMemo } from 'react';

import { CommentProvider } from 'components/Comments/CommentContext';
import { CommentAccess } from 'components/Comments/interfaces';
import { EntityType } from 'components/ContentContext/Enums';
import { LearnContext } from 'components/learn/LearnContext';
import { getCommentSections } from 'utils/comments';

import { LearnEditContext } from '../LearnEditContext';

export const CommentsWrapper: React.FC = ({ children }) => {
    const { skill } = useContext(LearnContext);
    const learnEditCtx = useContext(LearnEditContext);
    const showPublicComments = useMemo(() => !!learnEditCtx && !learnEditCtx?.skillEditId, [learnEditCtx]);

    return (
        <CommentProvider
            type={EntityType.SKILL}
            id={skill.skillId}
            access={showPublicComments ? CommentAccess.Public : CommentAccess.Private}
            comments={skill.comments}
            sections={getCommentSections(skill)}
        >
            {children}
        </CommentProvider>
    );
};
