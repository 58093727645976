import React from 'react';

import { StyledTableCell } from './TableCell.styles';

interface ViewsCellProps {
    views?: number;
}

export const VIEWS_CELL_WIDTH = 145;

export const ViewsCell: React.FC<ViewsCellProps> = ({ views }) => {
    return <StyledTableCell width={VIEWS_CELL_WIDTH}>{views ?? 0}</StyledTableCell>;
};
