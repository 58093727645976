import React from 'react';

import { Button } from '@material-ui/core';
import pluralize from 'pluralize';
import styled from 'styled-components';

const SubscribeOffer = styled.div`
    font-size: 0.75rem;
    font-weight: 600;
    margin-top: 0.5rem;
    align-items: center;
    color: ${({ theme }) => theme.colors.textSecondary};
    display: flex;
`;

const SubscribeDialog = styled.div`
    font-size: 0.75rem;
    font-weight: 500;
    align-items: center;
    color: ${({ theme }) => theme.colors.textTertiary};
    display: flex;
    max-width: 80%;
    text-align: center;
`;

export const SubscriptionOption: React.FC<{
    tenantAlias: string;
    subscriptionCost: number;
    tenantCollectionCount: number;
    onSubscribe: () => void;
}> = ({ tenantAlias, tenantCollectionCount, subscriptionCost, onSubscribe }) => {
    return (
        <>
            <SubscribeOffer>{`Subscribe to ${tenantAlias}`}</SubscribeOffer>
            <SubscribeDialog>{`Get access to the entire catalog, which includes this collection and ${tenantCollectionCount} ${pluralize(
                'other',
                tenantCollectionCount
            )} with an annual subscription.`}</SubscribeDialog>
            <Button
                disabled
                style={{ borderWidth: '1.5px', margin: '0.5rem', marginBottom: '1rem' }}
                variant="outlined"
                color="primary"
                onClick={onSubscribe}
            >{`Subscribe for $${subscriptionCost} / year`}</Button>
        </>
    );
};
