import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';

import { SagaStatus } from './types';
import { CreatePriceInput, CreateProductInput, useCreateProduct } from './useCreateProduct';
import { GetProductResponse, useGetProductDetails } from './useGetProductDetails';
import { useGetSagaDetails } from './useGetSagaStatus';

interface ProductContextInterface {
    product?: GetProductResponse['getProduct'];
    productDataIsLoading: boolean;
    createProductIsLoading: boolean;
    sagaIsLoading: boolean;
    productInProgress: boolean;
    sagaStatus?: SagaStatus;
    createProduct: (productInput: CreateProductInput, priceInput: CreatePriceInput) => void;
}

const ProductContext = createContext({} as ProductContextInterface);

const { Provider, Consumer } = ProductContext;

const ProductProvider: React.FC<{ productId?: string | null; refetch: () => void }> = ({
    children,
    productId,
    refetch
}) => {
    const [sagaStatus, setSagaStatus] = useState<SagaStatus>();
    //todo persist sagaId across sessions
    const { enqueueSnackbar } = useSnackbar();
    const [sagaId, setSagaId] = useState<string>();

    const { data: productData, isLoading: productDataIsLoading } = useGetProductDetails({ productId });

    const { data: sagaData, isLoading: sagaIsLoading } = useGetSagaDetails(
        { executionId: sagaId },
        sagaStatus !== SagaStatus.succeeded && sagaStatus !== SagaStatus.failed
    );

    const { mutate: createProductMutation, isLoading: createProductIsLoading } = useCreateProduct(
        setSagaId,
        setSagaStatus,
        null,
        null,
        null
    );

    const createProduct = useCallback(
        async (productInput: CreateProductInput, priceInput: CreatePriceInput) => {
            if (productId || sagaId || createProductIsLoading) return;

            await createProductMutation({ input: productInput, priceInput });
            //ppersist sagadata here ?? make it deterministic off of inputs
        },
        [createProductMutation, productId, sagaId, createProductIsLoading]
    );

    useEffect(() => {
        if (!sagaData) return;
        setSagaStatus(sagaData.getSaga.status);
        if (sagaData.getSaga.status === SagaStatus.succeeded) {
            refetch();
        }
    }, [sagaData, refetch]);

    useEffect(() => {
        if (sagaStatus === SagaStatus.succeeded) {
            enqueueSnackbar('Successfully created a product and price!', { variant: 'success' });
        }
        if (sagaStatus === SagaStatus.failed) {
            enqueueSnackbar('An error occured creating a product and price!', { variant: 'error' });
        }
    }, [sagaStatus, enqueueSnackbar]);

    const productInProgress = useMemo(
        () => productDataIsLoading || sagaIsLoading || createProductIsLoading || sagaStatus === SagaStatus.inProgress,
        [productDataIsLoading, sagaIsLoading, createProductIsLoading, sagaStatus]
    );

    return (
        <Provider
            value={{
                product: productData?.getProduct,
                productDataIsLoading,
                sagaIsLoading,
                createProductIsLoading,
                productInProgress,
                sagaStatus,
                createProduct
            }}
        >
            {children}
        </Provider>
    );
};

export { ProductContext, ProductProvider, Consumer as ProductConsumer };
