import * as yup from 'yup';

import { MessageEventType } from '..';
import AdepteductMessage from '../../base/AdepteductMessage';

interface EventFieldsDeleteMessage {
    deletedAt: string;
    deletedBy: string;
}

export default class ChannelDeleteMessage extends AdepteductMessage<EventFieldsDeleteMessage> {
    schema = yup.object({
        deletedAt: yup.string().required(),
        deletedBy: yup.string().required()
    });

    type = MessageEventType.ChannelDeleteMessage;
}
