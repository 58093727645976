import React, { useCallback } from 'react';

import { RichTextComponentValues, RichTextViewer } from '@adept-at/lib-react-components';
import { useComponentEngine } from 'components/engine';

import CodeView from '../Code/View';
import { LinkView } from '../Link/View';
import QuestionView from '../Question/View';
import { EmbeddedVideo } from '../Video/EmbeddedVideo';
import { VirtualLabView } from '../VirtualLab/View';

const TextView: React.FC<RichTextComponentValues> = (component) => {
    const { container } = useComponentEngine();
    const LinkComponent = useCallback(
        ({ children, ...componentValues }) => (
            <LinkView componentValues={componentValues} container={container}>
                {children}
            </LinkView>
        ),
        [container]
    );

    return (
        <RichTextViewer
            componentValues={component}
            VideoComponent={EmbeddedVideo}
            QuestionComponent={QuestionView}
            CodeComponent={CodeView}
            LinkComponent={LinkComponent}
            LabComponent={(componentValues) => <VirtualLabView componentValues={{ ...componentValues }} />}
        />
    );
};

export default TextView;
