import React, { useContext, useEffect, useMemo, useState } from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { Avatar, Backdrop, Button, Fade, FormControl, FormControlLabel, Switch, TextField } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Autocomplete } from '@material-ui/lab';
import { mdiBriefcaseAccount } from '@mdi/js';
import Icon from '@mdi/react';
import { OwnerType } from 'components/ContentContext/Interfaces';
import { TrackIconPath } from 'components/icons/TrackIcon';
import { useAvatars } from 'context/AvatarsContext/useAvatars';
import { ProfileContext, ProfileType, ProfileOption } from 'context/ProfileContext';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { Buttons, ModalBody, StyledModal, Title } from '../Modal.styles';

import { useCreateSkill } from './useCreateSkill';
import { useDynamicCreateSkillOptions } from './useDynamicCreateSkillOptions';
import {
    CollectionForTenantProfile,
    CollectionType,
    useGetCollectionsForTenantProfile
} from './useGetCollectionsForTenantProfile';

const Control = styled(FormControl)`
    margin-bottom: 1rem;
    width: 100%;
`;

const ModalTitle = styled(Title)`
    margin-bottom: 0.5rem;
`;

const StyledIcon = styled(Icon)`
    margin-right: 0.5rem;
`;

const StyledAvatar = styled(Avatar)`
    margin-right: 0.5rem;
    width: 24px;
    height: 24px;
`;

interface SkillForm {
    title: string;
    owner: ProfileOption;
    collections: string[];
}

export interface DestinationOption {
    id: CollectionType;
    label: string;
    iconPath: string;
}

export const destinationOptions = [
    { id: CollectionType.INTERNAL, label: 'Internal training', iconPath: mdiBriefcaseAccount },
    { id: CollectionType.CATALOG, label: 'Catalog', iconPath: TrackIconPath }
];

interface CreateSkillModalProps {
    isOpen: boolean;
    handleClose: () => void;
}

const OwnerAvatar: React.FC<{ option: ProfileOption }> = ({ option }) => {
    const assetIds = useMemo(() => (option.imageId ? [option.imageId] : []), [option.imageId]);
    const { determineAvatarSourceById } = useAvatars({ assetIds });

    return <StyledAvatar alt={option.alias} src={determineAvatarSourceById(option.imageId ?? '')} />;
};

const OwnerOption: React.FC<{ option: ProfileOption }> = ({ option }) => {
    const { currentUser } = useCurrentUser();

    return (
        <>
            <OwnerAvatar option={option} />
            {option?.type === ProfileType.Personal ? `${currentUser?.name} (you)` : option?.label}
        </>
    );
};

export const CreateSkillModal: React.FC<CreateSkillModalProps> = ({ isOpen, handleClose }) => {
    const { currentUser } = useCurrentUser();
    const { availableProfiles } = useContext(ProfileContext);
    const { register, handleSubmit, errors } = useForm<SkillForm>();
    const [destination, setDestination] = useState<DestinationOption | null>(null);
    const [collections, setCollections] = useState<CollectionForTenantProfile[]>([]);
    const [creatorIsAuthor, setCreatorIsAuthor] = useState(true);
    const [selectedOwner, setSelectedOwner] = useState<ProfileOption | undefined>(
        availableProfiles.find((profile) => profile.type === ProfileType.Personal)
    );

    const ownerInfo = useMemo(
        () =>
            selectedOwner
                ? {
                      type: selectedOwner.type === ProfileType.Personal ? OwnerType.User : OwnerType.Organization,
                      id: selectedOwner.id
                  }
                : undefined,
        [selectedOwner]
    );

    const {
        data,
        isLoading: collectionsLoading,
        refetch
    } = useGetCollectionsForTenantProfile(ownerInfo, destination?.id);

    const collectionOptions = useMemo(
        () => data?.getCollectionsForTenantProfile ?? [],
        [data?.getCollectionsForTenantProfile]
    );

    useDynamicCreateSkillOptions({ setSelectedOwner, setDestination, setCollections, collectionOptions });

    useEffect(() => {
        if (destination?.id && ownerInfo?.type === OwnerType.Organization) {
            refetch();
        }
    }, [destination?.id, refetch, ownerInfo]);

    const onSubmit = ({ title }: SkillForm) => {
        if (ownerInfo) {
            mutate({
                title,
                ownerInfo,
                creatorIsAuthor,
                collectionIds: collections.map((collection) => collection.collectionId)
            });
        }
    };

    const { mutate, isLoading, isSuccess } = useCreateSkill();

    useEffect(() => {
        if (isSuccess) {
            handleClose();
        }
    }, [isSuccess, handleClose]);

    const ownerOptions = useMemo(
        () => availableProfiles.filter(({ type }) => [ProfileType.Organization, ProfileType.Personal].includes(type)),
        [availableProfiles]
    );

    return (
        <StyledModal
            aria-labelledby="create-label"
            aria-describedby="create-label"
            open={isOpen}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500
            }}
        >
            <Fade in={isOpen}>
                <ModalBody>
                    <ModalTitle aria-label="create new skill dialog box">Create skill</ModalTitle>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Control>
                            <TextField
                                fullWidth
                                autoFocus
                                name="title"
                                aria-label="create skill title field"
                                label="Skill title"
                                error={errors.title !== undefined}
                                inputRef={register({
                                    required: true,
                                    maxLength: { value: 50, message: 'Title cannot exceed 50 characters' },
                                    validate: (value) => !!value.trim() || 'At least 1 character is required'
                                })}
                                helperText={errors.title?.message}
                                variant="outlined"
                            />
                        </Control>
                        <Control>
                            <Autocomplete
                                options={ownerOptions}
                                filterOptions={(options, { inputValue }) =>
                                    options.filter(
                                        (option) =>
                                            option.label.toLowerCase().includes(inputValue.toLowerCase()) ||
                                            option.alias.toLowerCase().includes(inputValue.toLowerCase())
                                    )
                                }
                                value={selectedOwner}
                                getOptionLabel={(option) =>
                                    option?.type === ProfileType.Personal ? `${currentUser?.name} (you)` : option?.label
                                }
                                renderOption={(option) => <OwnerOption option={option} />}
                                disableClearable
                                getOptionSelected={(option, value) => option.id === value.id}
                                onChange={(_event, newValue) => {
                                    setSelectedOwner(newValue);
                                    setCollections([]);
                                    setDestination(null);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="owner"
                                        variant="outlined"
                                        label="Skill owner"
                                        inputRef={register({ required: true })}
                                        error={!!errors.owner}
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: selectedOwner ? (
                                                <OwnerAvatar option={selectedOwner} />
                                            ) : null
                                        }}
                                    />
                                )}
                            />
                        </Control>
                        <Control>
                            <FormControlLabel
                                label="Do not assign author role to me"
                                control={
                                    <Switch
                                        checked={!creatorIsAuthor}
                                        onChange={(e) => setCreatorIsAuthor(!e.target.checked)}
                                        color="primary"
                                    />
                                }
                            />
                        </Control>
                        {selectedOwner?.type === ProfileType.Organization ? (
                            <Control>
                                <Autocomplete
                                    options={destinationOptions}
                                    filterOptions={(options, { inputValue }) =>
                                        options.filter((option) =>
                                            option.label.toLowerCase().includes(inputValue.toLowerCase())
                                        )
                                    }
                                    getOptionLabel={(option) => option?.label}
                                    value={destination}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    onChange={(_event, newValue) => {
                                        setDestination(newValue);
                                        setCollections([]);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            name="destination"
                                            variant="outlined"
                                            label="Destination (optional)"
                                            inputRef={register}
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: destination ? (
                                                    <StyledIcon path={destination.iconPath} size={IconSize.Small} />
                                                ) : null
                                            }}
                                        />
                                    )}
                                    renderOption={(option) => (
                                        <>
                                            <StyledIcon path={option.iconPath} size={IconSize.Small} />
                                            {option.label}
                                        </>
                                    )}
                                />
                            </Control>
                        ) : null}
                        {destination ? (
                            <Control>
                                <Autocomplete
                                    multiple
                                    disableCloseOnSelect
                                    options={collectionOptions}
                                    filterOptions={(options, { inputValue }) =>
                                        options.filter(
                                            (option) =>
                                                option.title.toLowerCase().includes(inputValue.toLowerCase()) &&
                                                !collections.find((c) => c.collectionId === option.collectionId)
                                        )
                                    }
                                    loading={collectionsLoading}
                                    getOptionLabel={(option) => option.title}
                                    value={collections}
                                    getOptionSelected={(option, value) => option.collectionId === value.collectionId}
                                    onChange={(_event, newValue) => setCollections(newValue)}
                                    ChipProps={{
                                        size: 'small',
                                        icon: <Icon path={TrackIconPath} />
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            name="collections"
                                            variant="outlined"
                                            label="Collections"
                                            inputRef={register({
                                                validate: () => collections.length > 0
                                            })}
                                            error={!!errors.collections}
                                            helperText={
                                                !!errors.collections
                                                    ? 'At least 1 collection is required when destination is set'
                                                    : null
                                            }
                                        />
                                    )}
                                    renderOption={(option) => (
                                        <>
                                            <StyledIcon path={TrackIconPath} size={IconSize.Small} />
                                            {option.title}
                                        </>
                                    )}
                                />
                            </Control>
                        ) : null}
                        <Buttons>
                            <Button aria-label="cancel skill creation" onClick={handleClose} color="primary">
                                Cancel
                            </Button>
                            <Button
                                aria-label="create skill confirm button"
                                type="submit"
                                disableElevation
                                color="primary"
                                variant="contained"
                                disabled={isLoading}
                            >
                                {isLoading ? (
                                    <CircularProgress size={IconSize.Medium} color={'inherit'} />
                                ) : (
                                    'Create skill'
                                )}
                            </Button>
                        </Buttons>
                    </form>
                </ModalBody>
            </Fade>
        </StyledModal>
    );
};
