import React, { useContext, useState } from 'react';

import { QuestionComponentValues, QuestionViewer, QuestionViewerStatus } from '@adept-at/lib-react-components';
import { LinearProgress } from '@material-ui/core';
import { useParams } from 'react-router';

import { ProfileContext } from '../../../../../../context/ProfileContext';
import { API_CONTENT } from '../../../../../../lib/ApiConstants';
import { useUnsafeMutation } from '../../../../../GraphqlProvider';
import AnswerQuestionError from '../../AnswerQuestionError';
import { AnswerResponse, AnswerExamQuestionArgs, ANSWER_QUESTION, submitExamQuestionAnswer } from '../../lib/question';

export interface ViewExamQuestionProps {
    component: QuestionComponentValues;
    initialValue?: any;
}

export interface ViewParams {
    eid: string;
    sessionId: string;
    skillSlug: string;
}

export const ViewExamQuestion: React.FC<ViewExamQuestionProps> = ({ component, initialValue = undefined }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [value, setValue] = useState(initialValue as any);

    const { currentProfile } = useContext(ProfileContext);
    const orgId = currentProfile?.id;

    const params = useParams() as ViewParams;
    const questionBankId = params?.eid;
    const sessionId = params?.sessionId;

    const [answerQuestion, { error }] = useUnsafeMutation<AnswerResponse, AnswerExamQuestionArgs>(
        ANSWER_QUESTION,
        {},
        { host: API_CONTENT }
    );

    const mutationParams = {
        questionBankId,
        sessionId,
        orgId: orgId as string,
        questionId: component.questionId as string,
        answer: value
    };

    return (
        <>
            <AnswerQuestionError error={error} />
            {isSubmitting && <LinearProgress />}

            <form>
                <QuestionViewer.Question.Factory componentValues={component} answer={value} setAnswer={setValue} />
                <QuestionViewer.SubmitButton
                    status={isSubmitting ? QuestionViewerStatus.Submitting : QuestionViewerStatus.Initial}
                    data-testid="questionView-submit"
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                    onClick={() => submitExamQuestionAnswer(setIsSubmitting, answerQuestion, mutationParams)}
                >
                    Submit
                </QuestionViewer.SubmitButton>
            </form>
        </>
    );
};

export default ViewExamQuestion;
