import { OrganizationTerminology } from 'components/settings/OrganizationInfoSettings/Terminology/hooks/useUpdateTerminology';
import { ClientError } from 'graphql-request';
import gql from 'graphql-tag';
import { useBuildAuthenticatedQueryFn } from 'hooks/useGqlClient/useBuildAuthenticatedQueryFn';
import { Api } from 'lib/ApiConstants';
import { useQuery, UseQueryResult } from 'react-query';

// @GRAPHQL Query getOrganizationsForUser
const queryName = 'getOrganizationsForUser';

export { queryName as queryKey };

/* Changing from Org Membership to -> Org Affiliation. To discern membership, check roles on the organizaiton. */
const GET_ORGANIZATIONS_FOR_USER_QUERY = gql`
    query getOrganizationsForUser {
        getOrganizationsForUser: getUserAffiliations {
            id
            name
            alias
            image
            roles
            images {
                logoSquare {
                    contentAssetId
                }
                logoHeader {
                    contentAssetId
                }
            }
            colors {
                background
            }
            tenants {
                id
                name
            }
            terminology {
                skill
                collection
            }
        }
    }
`;

export interface OrganizationForUserTenant {
    id: string;
    name: string;
}

export interface OrganizationForUser {
    id: string;
    name: string;
    alias: string;
    tenants: OrganizationForUserTenant[];
    image?: string;
    roles: string[];
    images?: {
        logoSquare: { contentAssetId: string };
        logoHeader: { contentAssetId: string };
    };
    colors?: {
        background: string;
    };
    terminology?: OrganizationTerminology;
}

export interface GetOrganizationsForUserResponse {
    getOrganizationsForUser: OrganizationForUser[];
}

export const useGetOrganizationsForUser = (
    _variables = {},
    options = {}
): UseQueryResult<GetOrganizationsForUserResponse, ClientError> => {
    const { queryFn } = useBuildAuthenticatedQueryFn<GetOrganizationsForUserResponse>(
        GET_ORGANIZATIONS_FOR_USER_QUERY,
        {},
        { api: Api.Accounts }
    );

    return useQuery<GetOrganizationsForUserResponse, ClientError>(queryName, queryFn, options);
};
