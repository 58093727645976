import { useMemo } from 'react';

import { ClientError } from 'graphql-request';
import useGqlClient from 'hooks/useGqlClient';
import { API_CONTENT } from 'lib/ApiConstants';
import { useQuery } from 'react-query';

import getCollectionChildren, {
    buildGetCollectionChildrenQueryKey,
    GetCollectionChildrenResponse
} from './getCollectionChildren';
import getContentBasicDetails, {
    buildGetContentBasicDetailsQueryKey,
    ContentBasicDetail,
    GetContentBasicDetailsResponse,
    SkillBasicDetail
} from './getContentBasicDetails';

interface UseCollectionContentOutput {
    contentLoading: boolean;
    content: ContentBasicDetail[];
    skills: SkillBasicDetail[];
}

const useCollectionContent = (collectionId?: string): UseCollectionContentOutput => {
    const { client: contentClient, withQueryOptions } = useGqlClient(API_CONTENT);

    const collectionChildrenQueryKey = useMemo(() => buildGetCollectionChildrenQueryKey(collectionId), [collectionId]);
    const { data: collectionChildrenData, isLoading: collectionChildrenLoading } = useQuery<
        GetCollectionChildrenResponse,
        ClientError
    >(
        withQueryOptions({
            queryKey: collectionChildrenQueryKey,
            queryFn: getCollectionChildren(contentClient, collectionId ?? ''),
            enabled: !!collectionId
        })
    );

    const children = useMemo(() => collectionChildrenData?.getCollection?.children ?? [], [collectionChildrenData]);

    const contentBasicDetailsQueryKey = useMemo(() => buildGetContentBasicDetailsQueryKey(children), [children]);
    const { data: contentBasicDetailsData, isLoading: contentBasicDetailsLoading } = useQuery<
        GetContentBasicDetailsResponse,
        ClientError
    >(
        withQueryOptions({
            queryKey: contentBasicDetailsQueryKey,
            queryFn: getContentBasicDetails(contentClient, children),
            enabled: children.length > 0
        })
    );

    const content = useMemo(
        () =>
            contentBasicDetailsData?.getEntityBasicDetails?.filter(
                (content) => Object.keys(content ?? {}).length > 0
            ) ?? [],
        [contentBasicDetailsData?.getEntityBasicDetails]
    );

    const skills = useMemo(() => content.filter((c) => 'skillId' in c) as SkillBasicDetail[], [content]);

    return {
        contentLoading: collectionChildrenLoading || contentBasicDetailsLoading,
        content,
        skills
    };
};

export default useCollectionContent;
