import { useMemo } from 'react';

import { OptimistMutationContext, OptimistRegisterUpdaterOptions, useOptimist } from '@adept-at/lib-react-optimist';
import { buildGetSkillBySlugQueryKey, GetSkillBySlugResponse } from 'components/learn/getSkillBySlug';
import { ClientError, gql } from 'graphql-request';
import { useBuildAuthenticatedMutationFn } from 'hooks/useGqlClient/useBuildAuthenticatedMutationFn';
import { Api } from 'lib/ApiConstants';
import { useSnackbar } from 'notistack';
import { useMutation, UseMutationResult } from 'react-query';
import { setSkillEditedInSessionStorage } from 'utils/skill/editedSkills';

import { Image, Skill } from '../../../ContentContext/Interfaces';
import {
    GetSkillByIdResponse,
    buildQueryKey as buildGetSkillByIdQueryKey
} from '../../../skills/hooks/useGetSkillById';
import { SkillMetadata } from '../interfaces';

export const UPDATE_SKILL_META_MUTATION = gql`
    mutation updateSkillMeta(
        $skillId: UUIDv4!
        $title: String
        $subtitle: String
        $storyboard: JSON
        $description: String
        $images: JSON
        $tags: [String]
    ) {
        updateSkillMeta(
            skillId: $skillId
            title: $title
            subtitle: $subtitle
            storyboard: $storyboard
            description: $description
            images: $images
            tags: $tags
        ) {
            success
        }
    }
`;

export interface UpdateSkillMetaResult {
    success: boolean;
}

interface UpdateSkillImages {
    catalog: string | null;
    featured: string | null;
}

interface UpdateSkillMetaProps extends SkillMetadata {
    skillId: string;
    images?: UpdateSkillImages;
    tags?: string[];
}

export interface SkillMutationProperties {
    skillId: Skill['skillId'];
    skillSlug: Skill['skillSlug'];
    tenantSlug: Skill['tenantSlug'];
}

export const useUpdateSkillMeta = ({
    skillId,
    skillSlug,
    tenantSlug
}: SkillMutationProperties): UseMutationResult<
    UpdateSkillMetaResult,
    ClientError,
    UpdateSkillMetaProps,
    OptimistMutationContext
> => {
    const { enqueueSnackbar } = useSnackbar();

    const { registerUpdater, mutationOptions } = useOptimist<
        UpdateSkillMetaResult,
        ClientError,
        UpdateSkillMetaProps
    >();

    const skillByIdOptions: OptimistRegisterUpdaterOptions<UpdateSkillMetaResult, ClientError, UpdateSkillMetaProps> =
        useMemo(() => {
            return {
                onError: () => {
                    enqueueSnackbar('An error occurred while updating skill.', { variant: 'error' });
                },
                onSettled: () => {
                    setSkillEditedInSessionStorage(skillId, tenantSlug, skillSlug);
                },
                refetchQuery: false
            };
        }, [enqueueSnackbar, skillId, tenantSlug, skillSlug]);

    const skillBySlugOptions: OptimistRegisterUpdaterOptions<UpdateSkillMetaResult, ClientError, UpdateSkillMetaProps> =
        useMemo(() => {
            return {
                onError: () => {
                    enqueueSnackbar('An error occurred while updating skill.', { variant: 'error' });
                },
                onSettled: () => {
                    setSkillEditedInSessionStorage(skillId, tenantSlug, skillSlug);
                },
                refetchQuery: false
            };
        }, [enqueueSnackbar, skillId, tenantSlug, skillSlug]);

    const skillQueryByIdKey = useMemo(() => buildGetSkillByIdQueryKey(skillId), [skillId]);
    const skillQueryBySlugKey = useMemo(
        () => buildGetSkillBySlugQueryKey(tenantSlug ?? '', skillSlug ?? ''),
        [tenantSlug, skillSlug]
    );

    const updateSkill = ({
        previousSkill,
        newSkillMeta
    }: {
        previousSkill: Skill;
        newSkillMeta: UpdateSkillMetaProps;
    }): Skill => {
        const updatedSkill = {
            ...previousSkill,
            title: newSkillMeta.title ?? previousSkill.title,
            subtitle: newSkillMeta.subtitle ?? previousSkill.subtitle,
            description: newSkillMeta.description ?? previousSkill.description,
            meta: {
                ...previousSkill.meta,
                storyboard: newSkillMeta.storyboard ?? previousSkill.meta?.storyboard
            },
            tags: newSkillMeta.tags ?? previousSkill.tags
        };

        if (newSkillMeta.images) {
            updatedSkill.images = {
                catalog:
                    newSkillMeta.images.catalog === null
                        ? null
                        : {
                              ...(previousSkill.images?.catalog as Image),
                              id: newSkillMeta.images.catalog
                          },
                featured:
                    newSkillMeta.images?.featured === null
                        ? null
                        : {
                              ...(previousSkill.images?.featured as Image),
                              id: newSkillMeta.images.featured
                          }
            };
        }

        return updatedSkill;
    };

    registerUpdater<GetSkillByIdResponse>(
        skillQueryByIdKey,
        (previous, newSkillMeta) => {
            const previousSkill = previous?.getSkillById ?? {};
            const updatedSkill = updateSkill({ previousSkill, newSkillMeta });
            return {
                getSkillById: updatedSkill
            };
        },
        skillByIdOptions
    );

    registerUpdater<GetSkillBySlugResponse>(
        skillQueryBySlugKey,
        (previous, newSkillMeta) => {
            const previousSkill = previous?.getEntityBySlug ?? {};
            const updatedSkill = updateSkill({ previousSkill, newSkillMeta });
            return {
                getEntityBySlug: updatedSkill
            };
        },
        skillBySlugOptions
    );

    const { mutationFn } = useBuildAuthenticatedMutationFn<UpdateSkillMetaResult, UpdateSkillMetaProps>(
        UPDATE_SKILL_META_MUTATION,
        { api: Api.Content }
    );

    return useMutation<UpdateSkillMetaResult, ClientError, UpdateSkillMetaProps, OptimistMutationContext>(
        mutationFn,
        mutationOptions
    );
};
