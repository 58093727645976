import { useMemo } from 'react';

import { OptimistMutationContext, OptimistRegisterUpdaterOptions, useOptimist } from '@adept-at/lib-react-optimist';
import { ClientError } from 'graphql-request';
import produce from 'immer';
import { useSnackbar } from 'notistack';
import { useMutation, UseMutationResult } from 'react-query';

import useGqlClient from '../../../../hooks/useGqlClient';
import { API_CONTENT } from '../../../../lib/ApiConstants';
import { buildGetAssessmentByIdQueryKey } from '../../../../pages/assessment/AssessmentEdit';
import { RemoveComponentVariables, RemoveComponentResult } from '../../../engine/types';
import { GetAssessmentByIdResponse } from '../../queries/getAssessmentById';

import makeRemoveComponent from './makeRemoveAssessmentComponent';

const useRemoveComponent = (
    entityId: string
): UseMutationResult<RemoveComponentResult, ClientError, RemoveComponentVariables, OptimistMutationContext> => {
    const { enqueueSnackbar } = useSnackbar();
    const { client, withMutationOptions } = useGqlClient(API_CONTENT);

    const { registerUpdater: registerRemoveComponentUpdater, mutationOptions: UpsertSkillComponentMutationOptions } =
        useOptimist<RemoveComponentResult, ClientError, RemoveComponentVariables>();

    const RemoveComponentOptions: OptimistRegisterUpdaterOptions<
        RemoveComponentResult,
        ClientError,
        RemoveComponentVariables
    > = useMemo(() => {
        return {
            onError: () => {
                enqueueSnackbar('An error occurred while removing component.', { variant: 'error' });
            },
            onSuccess: () => {
                enqueueSnackbar('Component removed.', { variant: 'success' });
            },
            refetchQuery: false
        };
    }, [enqueueSnackbar]);

    registerRemoveComponentUpdater<GetAssessmentByIdResponse>(
        buildGetAssessmentByIdQueryKey(entityId),
        (previous, { componentId }) =>
            produce(previous, (draft) => {
                try {
                    delete draft.getAssessmentById.components[componentId];
                } catch (e) {}
            }),
        RemoveComponentOptions
    );

    return useMutation<RemoveComponentResult, ClientError, RemoveComponentVariables, OptimistMutationContext>(
        makeRemoveComponent<RemoveComponentResult, RemoveComponentVariables>(client),
        withMutationOptions(UpsertSkillComponentMutationOptions)
    );
};

export default useRemoveComponent;
