import React, { createContext, useContext, useCallback } from 'react';

import { CommonComponentValues, RichTextComponentValuesBody } from '@adept-at/lib-react-components';
import { DraftJsEntity } from 'components/engine/common/RichTextEditor/utils';
import useMapAsState from 'use-map-as-state';

import { useComponentEngineComponent } from '../../ComponentContext';
import { useFocusableComponent } from '../focusable';

import { useComponentEditor, ComponentFocus } from './';

export type ComponentTypeSpecificValues<TComponentType> = Omit<TComponentType, keyof CommonComponentValues>;

const pick = (object, keys) =>
    keys.reduce((obj, key) => {
        if (object && object.hasOwnProperty(key)) {
            obj[key] = object[key];
        }
        return obj;
    }, {});

const cleanEntityMap = (body: RichTextComponentValuesBody) => {
    const entityMap = Object.fromEntries(
        Object.entries(body?.val?.entityMap ?? {}).map(([entityKey, entity]) => {
            if (entity?.type === DraftJsEntity.IMAGE && entity?.data?.image?.url) {
                return [
                    entityKey,
                    { ...entity, data: { ...entity.data, image: { ...entity.data.image, url: undefined } } }
                ];
            }

            return [entityKey, entity];
        })
    );

    return {
        ...body,
        val: { ...body.val, entityMap }
    };
};

export interface EditableComponentContextInterface<TComponentValues> {
    currentValues: ComponentTypeSpecificValues<TComponentValues>;
    onCancel: () => void;
    onSave: (keysToSave?: string[]) => void;
    reset: () => void;
    onChange: <TComponentFieldValue>(field: string) => (value: TComponentFieldValue) => void;
    onChangeMany: (fieldsWithValues: Partial<ComponentTypeSpecificValues<TComponentValues>>) => void;
    onChangeManyAndSave: (
        fieldsWithValues: Partial<ComponentTypeSpecificValues<TComponentValues>>,
        keysToSave?: string[]
    ) => void;
    onChangeAndSave: <TComponentFieldValue>(
        field: string,
        keysToSave?: string[]
    ) => (value: TComponentFieldValue) => void;
}

interface EditableComponentContextProps<TComponentValues> {
    initialValues: TComponentValues;
    children: React.ReactNode;
}

export const EditableComponentContext = createContext({} as EditableComponentContextInterface<any>);

export const EditableComponentProvider = <TComponentValues,>({
    children,
    initialValues
}: EditableComponentContextProps<TComponentValues>): React.ReactElement => {
    const { onUpsertComponent, onRemoveComponent } = useComponentEditor();
    const { id, type, order } = useComponentEngineComponent();

    const { doesComponentHaveFocus, unsetComponentFocus } = useFocusableComponent();

    const currentValues = useMapAsState(new Map(Object.entries(initialValues)));

    const currentValuesAsObject = useCallback(
        (): ComponentTypeSpecificValues<TComponentValues> =>
            Object.fromEntries(currentValues) as ComponentTypeSpecificValues<TComponentValues>,
        [currentValues]
    );

    const customValuesAsObject = useCallback(
        (customValues: Map<string, TComponentValues>): ComponentTypeSpecificValues<TComponentValues> =>
            Object.fromEntries(customValues) as unknown as ComponentTypeSpecificValues<TComponentValues>,
        []
    );

    const reset = () => {
        Object.entries(initialValues).forEach(([key, value]) => {
            currentValues.set(key, value);
        });
    };

    const save = useCallback(
        (valuesForUpsert) => {
            onUpsertComponent<ComponentTypeSpecificValues<TComponentValues>>(id, type, order, valuesForUpsert);
        },
        [onUpsertComponent, id, type, order]
    );

    const onSave = useCallback(
        (keysToSave: string[] = []) => {
            const valuesForUpsert =
                keysToSave.length === 0 ? currentValuesAsObject() : pick(currentValuesAsObject(), keysToSave);

            if (valuesForUpsert.body) {
                const cleanBody = cleanEntityMap(valuesForUpsert.body);
                return save({ ...valuesForUpsert, body: cleanBody });
            }
            save(valuesForUpsert);
        },
        [currentValuesAsObject, save]
    );

    const onSaveDraft = useCallback(
        (draft: Map<string, TComponentValues>, keysToSave: string[] = []) => {
            const valuesForUpsert =
                keysToSave.length === 0 ? customValuesAsObject(draft) : pick(customValuesAsObject(draft), keysToSave);
            save(valuesForUpsert);
        },
        // @TODO missing customValuesAsObject as dependency
        [currentValuesAsObject, save]
    );

    const onCancel = () => {
        // @TODO prompt unsaved changes? Do we track dirty state in this hook?

        if (doesComponentHaveFocus(id, ComponentFocus.AddAndEdit)) {
            onRemoveComponent(id);
        }

        unsetComponentFocus(id);

        reset();
    };

    //returns a draft that has the updated state before the render even happens,
    //this way we can avoid having to use the useStateUpdateWithCallback
    const onChange = useCallback(
        <TComponentFieldValue,>(fieldName: string) =>
            (value: TComponentFieldValue): Map<string, TComponentValues> => {
                return currentValues.set(fieldName, value);
            },
        [currentValues]
    );
    const onChangeMany = (fieldsWithValues: Partial<ComponentTypeSpecificValues<TComponentValues>>) => {
        return (
            Object.entries(fieldsWithValues)
                .map(([key, value]) => {
                    return currentValues.set(key, value);
                })
                .pop() || currentValues
        );
    };

    const onChangeManyAndSave = (
        fieldsWithValues: Partial<ComponentTypeSpecificValues<TComponentValues>>,
        keysToSave?: string[]
    ) => {
        const changeDraft = onChangeMany(fieldsWithValues);
        onSaveDraft(changeDraft, keysToSave);
    };
    const onChangeAndSave =
        <TComponentFieldValue,>(fieldName: string, keysToSave?: string[]) =>
        (value: TComponentFieldValue) => {
            const changeDraft = onChange(fieldName)(value);
            onSaveDraft(changeDraft, keysToSave);
        };

    return (
        <EditableComponentContext.Provider
            value={{
                currentValues: currentValuesAsObject(),
                onCancel,
                onSave,
                reset,
                onChange,
                onChangeMany,
                onChangeManyAndSave,
                onChangeAndSave
            }}
        >
            {children}
        </EditableComponentContext.Provider>
    );
};

export const useEditableComponent = <TComponentValues,>(): EditableComponentContextInterface<TComponentValues> => {
    const context = useContext(EditableComponentContext);

    if (!context) {
        throw new Error('useEditableComponent must be used within a EditableComponentContextInterface');
    }

    return context;
};
