import { GraphQLClient } from 'graphql-request';
import gql from 'graphql-tag';
import buildGqlMutationFn from 'hooks/useGqlClient/helpers/buildGqlMutationFn';
import { MutationFunction } from 'react-query';

import { ToggleReactionResult, ToggleReactionVariables } from '.';

export const TOGGLE_REACTION = gql`
    mutation toggleEntityReaction(
        $entityType: MessageEntityType
        $entityId: UUIDv4!
        $messageId: String
        $reaction: String
        $hasParent: Boolean
    ) {
        toggleEntityReaction(
            entityType: $entityType
            entityId: $entityId
            messageId: $messageId
            reaction: $reaction
            hasParent: $hasParent
        ) {
            success
        }
    }
`;

const makeToggleReaction = (client: GraphQLClient): MutationFunction<ToggleReactionResult, ToggleReactionVariables> =>
    buildGqlMutationFn(client, TOGGLE_REACTION);

export default makeToggleReaction;
