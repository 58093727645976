import React, { useContext, useMemo } from 'react';

import { TooltippedIconButton, TooltippedButtonSize } from '@adept-at/lib-react-components';
import {
    mdiCalendarClock,
    mdiDotsVertical,
    mdiPlayCircle,
    mdiBookshelf,
    mdiTrashCan,
    mdiDragHorizontalVariant,
    mdiHeart
} from '@mdi/js';
import Icon from '@mdi/react';
import { BookshelvesContext } from 'components/bookshelves/BookshelvesContext';
import { EntityType } from 'components/ContentContext/Enums';
import { useFavorites } from 'components/dash/Favorites/useFavorites';
import useRemoveFavoriteItem from 'components/dash/hooks/useRemoveFavoriteItem';
import useUpsertFavoriteItem from 'components/dash/hooks/useUpsertFavoriteItem';
import { BasicSkillInfoWithProgress } from 'components/dash/interfaces';
import { ScheduleContext } from 'components/dash/Schedule/ScheduleContext';
import { useRemoveCollectionItem } from 'components/RootProfileCollection/useRemoveCollectionItem';
import { useOrganizationTerminology } from 'hooks/useOrganizationTerminology';
import { usePopover } from 'hooks/usePopover';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { StyledListItem, StyledMenu } from 'styles/Shared.styles';

import { NestedCollectionContext } from '../context/NestedCollectionContext';
import { BasicDetailsSkillResponse } from '../useGetEntitiesBasicDetails/getEntitiesBasicDetails';

interface ActionOverlayProps {
    skill: BasicDetailsSkillResponse;
    learnUrl: string;
}

const ActionButtons = styled.div`
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px;
`;

const ActionOverlay: React.FC<ActionOverlayProps> = ({ learnUrl, skill }) => {
    const { terminology } = useOrganizationTerminology();
    const { openLearnLaterModal } = useContext(ScheduleContext);
    const { openBookshelfModal } = useContext(BookshelvesContext);
    const {
        collectionId,
        isEditMode,
        collectionIdForEditableCollectionQuery,
        refetchEditableCollection,
        basicDetailsQueryKey,
        parentQuerySlug
    } = useContext(NestedCollectionContext);

    const { open, anchorEl, handlePopoverClick, handlePopoverClose } = usePopover();
    const history = useHistory();

    const { favorites } = useFavorites();
    const isFavorited = useMemo(
        () => !!favorites.find((favorite) => (favorite as BasicSkillInfoWithProgress).skillId === skill.skillId),
        [favorites, skill.skillId]
    );
    const { mutate: addToFavorites } = useUpsertFavoriteItem();
    const { mutate: removeFromFavorites } = useRemoveFavoriteItem();

    const { mutate: removeItemFromCollection } = useRemoveCollectionItem({
        isNestedCollection: true,
        collectionIdForEditableCollectionQuery,
        basicDetailsQueryKey,
        refetchEditableCollection,
        parentQuerySlug
    });

    const onRemoveItemClick = ({ id, type }: { id: string; type: EntityType }) => {
        const mutateVariables = {
            collectionId,
            item: {
                type,
                id
            }
        };

        removeItemFromCollection(mutateVariables);
        handlePopoverClose();
    };

    return (
        <ActionButtons>
            {isEditMode ? (
                <TooltippedIconButton
                    size={TooltippedButtonSize.Small}
                    aria-label="Drag to reorder"
                    icon={<Icon path={mdiDragHorizontalVariant} />}
                    title="Drag to reorder"
                />
            ) : null}
            <TooltippedIconButton
                title="More actions"
                size={TooltippedButtonSize.Small}
                icon={<Icon path={mdiDotsVertical} />}
                onClick={handlePopoverClick}
            />
            <StyledMenu anchorEl={anchorEl} open={open} onClose={handlePopoverClose}>
                {skill.skillSlug ? (
                    <StyledListItem button onClick={() => history.push(learnUrl)}>
                        <Icon path={mdiPlayCircle} /> Learn now
                    </StyledListItem>
                ) : null}

                <StyledListItem
                    button
                    onClick={() => {
                        openLearnLaterModal({
                            type: EntityType.SKILL,
                            id: skill.skillId
                        });
                        handlePopoverClose();
                    }}
                >
                    <Icon path={mdiCalendarClock} /> Learn later
                </StyledListItem>
                <StyledListItem
                    button
                    onClick={() => {
                        openBookshelfModal({ id: skill.skillId, type: EntityType.Skill, title: skill.title });
                        handlePopoverClose();
                    }}
                >
                    <Icon path={mdiBookshelf} /> Add to bookshelf
                </StyledListItem>
                <StyledListItem
                    button
                    onClick={() => {
                        const item = { type: EntityType.Skill, id: skill.skillId };
                        isFavorited ? removeFromFavorites({ item }) : addToFavorites({ item });
                        handlePopoverClose();
                    }}
                >
                    <Icon path={mdiHeart} /> {`${isFavorited ? 'Remove from' : 'Add to'} favorites`}
                </StyledListItem>
                {isEditMode ? (
                    <StyledListItem
                        button
                        onClick={() => {
                            onRemoveItemClick({ id: skill.skillId, type: EntityType.Skill });
                        }}
                    >
                        <Icon path={mdiTrashCan} /> Remove from {terminology.collection}
                    </StyledListItem>
                ) : null}
            </StyledMenu>
        </ActionButtons>
    );
};

export default ActionOverlay;
