enum AdeptOwnerType {
    Tenant = 'TENANT',
    User = 'USER',
    Group = 'GROUP',
    Organization = 'ORGANIZATION',
    OrganizationGroup = 'ORGANIZATION_GROUP',
    UserGroup = 'USER_GROUP'
}

export default AdeptOwnerType;
