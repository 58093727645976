import React, { useContext, useMemo } from 'react';

import {
    IconSize,
    LearningModeMenu,
    modularScale,
    SkillActionButtons,
    ViewerHeader
} from '@adept-at/lib-react-components';
import { useOptimist } from '@adept-at/lib-react-optimist';
import { Button } from '@material-ui/core';
import { mdiPencil } from '@mdi/js';
import Icon from '@mdi/react';
import { BookshelvesContext } from 'components/bookshelves/BookshelvesContext';
import { useSkillCollaborators } from 'components/builder/Meta/SkillHeader/useSkillCollaborators';
import { useSkillOwner } from 'components/builder/Meta/SkillHeader/useSkillOwner';
import { mapAvailabilityToStatus, WorkflowStatus } from 'components/CollaborationTable';
import { EntityType } from 'components/ContentContext/Enums';
import { Skill } from 'components/ContentContext/Interfaces';
import { useFavorites } from 'components/dash/Favorites/useFavorites';
import useRemoveFavoriteItem from 'components/dash/hooks/useRemoveFavoriteItem';
import useUpsertFavoriteItem from 'components/dash/hooks/useUpsertFavoriteItem';
import { BasicSkillInfo } from 'components/dash/interfaces';
import { ScheduleContext } from 'components/dash/Schedule/ScheduleContext';
import { format } from 'date-fns';
import { ClientError } from 'graphql-request';
import { API_CONTENT } from 'lib/ApiConstants';
import { LearnEditContext } from 'pages/learn/LearnEditContext';
import styled from 'styled-components';

import { useUnsafeMutation } from '../../GraphqlProvider';
import { buildGetSkillBySlugQueryKey, GetSkillBySlugResponse } from '../getSkillBySlug';
import { LearnContext } from '../LearnContext';
import { useLearningModeButton } from '../modes/useLearningModeButton';
import { REMOVE_RATING, UPSERT_RATING } from '../mutations';
import { useResetSkillProgress } from '../useProgress/useResetSkillProgress';

import { ContinueLearningButton } from './ContinueLearningButton';
import { ProgressIndicator } from './ProgressIndicator';

interface UpsertRatingParams {
    resourceType: string;
    resourceId: string;
    rating: number;
}

interface RemoveRatingParams {
    resourceType: string;
    resourceId: string;
}

interface RemoveRatingResponse {
    removeRating: {
        success: boolean;
    };
}

const ActionsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    padding: 0 12px;
`;

const InfoText = styled.span`
    color: ${(props) => props.theme.colors.textTertiary};
    text-transform: uppercase;
    margin-right: 1rem;
    font-size: ${modularScale(-1)};
    display: inline-block;
`;

const SkillHeaderWrapper = styled.div`
    padding-bottom: 1rem;
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
`;

export enum Rate {
    LIKE = 1,
    DISLIKE = 0
}

export const SkillHeader: React.FC = () => {
    const { skill, skillSlug, tenantSlug } = useContext(LearnContext);
    const { setSkillEditId, hasUpdatePermission } = useContext(LearnEditContext);
    const { openBookshelfModal } = useContext(BookshelvesContext);
    const { openLearnLaterModal } = useContext(ScheduleContext);
    const { favorites } = useFavorites();
    const { mutate: favoriteItem } = useUpsertFavoriteItem();
    const { mutate: unfavoriteItem } = useRemoveFavoriteItem();
    const { mode: learningMode, onChangeLearningMode, disabledModes } = useLearningModeButton();

    const skillBySlugQueryKey = useMemo(
        () => buildGetSkillBySlugQueryKey(tenantSlug, skillSlug),
        [tenantSlug, skillSlug]
    );

    const { mutate: resetProgress } = useResetSkillProgress(tenantSlug, skillSlug);

    const { registerUpdater: registerUpsertUpdater, mutationOptions: upsertMutationOptions } = useOptimist<
        Skill,
        ClientError,
        UpsertRatingParams
    >();
    registerUpsertUpdater<GetSkillBySlugResponse>(
        skillBySlugQueryKey,
        (previous, rating) => {
            const previousRatings = previous?.getEntityBySlug?.ratings ?? {
                currentUserRating: null,
                likes: 0,
                dislikes: 0
            };

            // if the user had a previous rating value and it does not equal the new rating value then we must add to likes/dislikes and remove from the other
            const switchedRating =
                previousRatings.currentUserRating !== null && previousRatings.currentUserRating !== rating.rating;

            const updatedRatings = switchedRating
                ? {
                      currentUserRating: rating.rating,
                      likes: rating.rating === Rate.LIKE ? previousRatings.likes + 1 : previousRatings.likes - 1,
                      dislikes:
                          rating.rating === Rate.DISLIKE ? previousRatings.dislikes + 1 : previousRatings.dislikes - 1
                  }
                : {
                      currentUserRating: rating.rating,
                      likes: rating.rating === Rate.LIKE ? previousRatings.likes + 1 : previousRatings.likes,
                      dislikes: rating.rating === Rate.DISLIKE ? previousRatings.dislikes + 1 : previousRatings.dislikes
                  };

            return { getEntityBySlug: { ...previous?.getEntityBySlug, ratings: updatedRatings } };
        },
        { refetchQuery: false }
    );

    const [upsertRating] = useUnsafeMutation<void, UpsertRatingParams>(
        UPSERT_RATING,
        {},
        { host: API_CONTENT },
        upsertMutationOptions
    );

    const { registerUpdater: registerRemoveUpdater, mutationOptions: removeMutationOptions } = useOptimist<
        Skill,
        ClientError,
        RemoveRatingParams
    >();
    registerRemoveUpdater<GetSkillBySlugResponse>(skillBySlugQueryKey, (previous) => {
        const previousRatings = previous?.getEntityBySlug?.ratings ?? {
            currentUserRating: null,
            likes: 0,
            dislikes: 0
        };

        const updatedRatings = {
            currentUserRating: null,
            likes: previousRatings.currentUserRating === Rate.LIKE ? previousRatings.likes - 1 : previousRatings.likes,
            dislikes:
                previousRatings.currentUserRating === Rate.DISLIKE
                    ? previousRatings.dislikes - 1
                    : previousRatings.dislikes
        };

        return { getEntityBySlug: { ...previous?.getEntityBySlug, ratings: updatedRatings } };
    });

    const [removeRating] = useUnsafeMutation<RemoveRatingResponse, RemoveRatingParams>(
        REMOVE_RATING,
        {},
        { host: API_CONTENT },
        removeMutationOptions
    );

    const onRate = (rating: Rate) => {
        if (skill) {
            if (skill.ratings?.currentUserRating === rating) {
                removeRating({ resourceType: 'skill', resourceId: skill.skillId });
            } else {
                upsertRating({ resourceType: 'skill', resourceId: skill.skillId, rating });
            }
        }
    };

    const hasFavoritedItem = useMemo(
        () => !!favorites.find((favorite) => (favorite as BasicSkillInfo).skillId === skill.skillId),
        [favorites, skill.skillId]
    );

    const onFavoriteClick = () => {
        if (hasFavoritedItem) {
            unfavoriteItem({ item: { type: EntityType.SKILL, id: skill.skillId } });
        } else {
            favoriteItem({ item: { type: EntityType.SKILL, id: skill.skillId } });
        }
    };

    const onAddToSchedule = () => {
        openLearnLaterModal({ type: EntityType.Skill, id: skill.skillId });
    };

    const onAddToBookshelf = () => {
        openBookshelfModal({
            title: skill.title,
            type: EntityType.Skill,
            id: skill.skillId
        });
    };

    const collaborators = useSkillCollaborators(skill.authors);
    const { name, avatarSrc } = useSkillOwner(skill.owner);

    return (
        <SkillHeaderWrapper>
            <ViewerHeader.Skill
                ownerName={name}
                ownerAvatarSrc={avatarSrc}
                uncroppedImage={skill.images?.catalog}
                title={skill.title}
                subtitle={skill.subtitle}
                collaborators={collaborators}
                createdAt={skill.createdAt ? format(new Date(skill.createdAt), 'LLLL d, yyyy') : ''}
                estimatedSecondsToConsume={skill.estimatedSecondsToConsume ?? 0}
                parentTitle={skill.title}
            />
            <ActionsWrapper>
                <ProgressIndicator />
                <LearningModeMenu
                    variant="button"
                    onChangeLearningMode={onChangeLearningMode}
                    disabledModes={disabledModes}
                    mode={learningMode}
                />
            </ActionsWrapper>
            <ActionsWrapper>
                <ContinueLearningButton />
                <SkillActionButtons
                    onChangeLearningMode={onChangeLearningMode}
                    disabledModes={disabledModes}
                    mode={learningMode}
                    onEdit={hasUpdatePermission ? () => setSkillEditId(skill.skillId) : undefined}
                    onResetProgress={() => resetProgress({ skillId: skill.skillId })}
                    liked={skill.ratings?.currentUserRating === Rate.LIKE}
                    likes={skill.ratings?.likes}
                    onThumbsUp={() => onRate(Rate.LIKE)}
                    onFavorite={onFavoriteClick}
                    favorited={hasFavoritedItem}
                    onAddToSchedule={onAddToSchedule}
                    onAddToBookshelf={onAddToBookshelf}
                />
            </ActionsWrapper>
            {hasUpdatePermission ? (
                <ActionsWrapper>
                    <div>
                        <InfoText>
                            Availability: <b>{mapAvailabilityToStatus(skill)}</b>
                        </InfoText>
                        {skill.workflow ? (
                            <InfoText>
                                Workflow: <b>{WorkflowStatus[skill.workflow]}</b>
                            </InfoText>
                        ) : null}
                    </div>
                    <Button
                        onClick={() => setSkillEditId(skill.skillId)}
                        startIcon={<Icon path={mdiPencil} size={IconSize.Small} />}
                    >
                        Edit
                    </Button>
                </ActionsWrapper>
            ) : null}
        </SkillHeaderWrapper>
    );
};
