import React from 'react';

const Room: React.FC = () => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 24 24"
            xmlSpace="preserve"
            fill="currentColor"
        >
            <title>Room</title>
            <path d={roomIconPath} />
        </svg>
    );
};

export default Room;

export const roomIconPath = `M11.3,2.1L2.6,5.6C2.2,5.7,2,6.1,2,6.5v11c0,0.4,0.2,0.8,0.6,0.9l8.6,3.5c0.5,0.2,1,0.2,1.5,0l8.6-3.5
c0.4-0.2,0.6-0.5,0.6-0.9v-11c0-0.4-0.2-0.8-0.6-0.9l-8.6-3.5C12.3,2,11.7,2,11.3,2.1z M4,7.2l7-2.8v9.1l-7,2.8V7.2z M12,20l-6-2.4
l6-2.4l6,2.4L12,20z M20,16.3l-2-0.8V8.6l-3-1.2v6.9l-2-0.8V4.4l7,2.8V16.3z M6,8.6l3-1.2v4.8l-3,1.2V8.6z`;
