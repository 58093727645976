import React from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { mdiArrowRight } from '@mdi/js';
import Icon from '@mdi/react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { modularScale } from 'utils/modular_scale';

export const HeaderContainer = styled.div`
    padding: 32px 32px 16px 32px;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
`;

const StyledLink = styled(Link)`
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.primary};
    font-size: ${modularScale(-0.5)};

    svg {
        margin-left: 8px;
    }
`;

const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
`;

interface TableTitleProps {
    title: string;
    link: string;
    linkLabel: string;
}

export const TableTitle: React.FC<TableTitleProps> = ({ title, link, linkLabel }) => {
    return (
        <TitleContainer>
            <h1>{title}</h1>
            <StyledLink to={link}>
                {linkLabel}
                <Icon path={mdiArrowRight} size={IconSize.Small} />
            </StyledLink>
        </TitleContainer>
    );
};
