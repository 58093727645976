import React, { useState, useEffect } from 'react';

import TextEditInPlace, { TextEditInPlaceProps } from './TextEditInPlace';

export const TextEditInPlaceWithState: React.FC<TextEditInPlaceProps> = ({
    editing: receivedEditing = false,
    onStartEditing = () => {},
    onStopEditing = () => {},
    children,
    ...theRest
}) => {
    const [editing, setEditing] = useState(receivedEditing);

    useEffect(() => {
        setEditing(receivedEditing);
    }, [receivedEditing, setEditing]);

    return (
        <TextEditInPlace
            editing={editing}
            onStartEditing={() => {
                onStartEditing();
                setEditing(true);
            }}
            onStopEditing={() => {
                onStopEditing();
                setEditing(false);
            }}
            {...theRest}
        >
            {children}
        </TextEditInPlace>
    );
};
