import { useRef, RefObject, useContext } from 'react';

import { VideoRefsContext } from './VideoRefsContext';

export const useVideoRef = (): RefObject<HTMLVideoElement> => {
    const videoRefsCtx = useContext(VideoRefsContext);
    const videoRef = useRef<HTMLVideoElement>(null);

    if (videoRefsCtx && videoRef?.current?.id && !videoRefsCtx.videoRefs[videoRef.current.id]) {
        videoRefsCtx.addVideoRef(videoRef);
    }

    return videoRef;
};
