import React, { useMemo } from 'react';

import { Box, Slider, withStyles } from '@material-ui/core';
import { EnrollmentInterval } from 'components/product/types';
import pluralize from 'pluralize';

import { MarkLabelComponent } from './MarkLabelComponent';

export const incrementValues = {
    week: 'week'
};

export const calculateConsumptionValue = (value: number): number => {
    switch (value) {
        case 1:
            return 120;
        case 2:
            return 60;
        case 3:
            return 30;
        case 4:
            return 20;
        case 5:
            return 10;
        default:
            return 0;
    }
};

export const paymentMarks = [
    {
        value: 1,
        label: '2 hrs'
    },
    {
        value: 2,
        label: '1 hr'
    },
    {
        value: 3,
        label: '30 mins'
    },
    {
        value: 4,
        label: '20 mins'
    },
    {
        value: 5,
        label: '10 mins'
    }
];

export const DEFAULT_CONSUMPTION_INCREMENT = paymentMarks[3].value;

const StyledSlider = withStyles(
    (theme) => ({
        mark: {
            height: '0.25rem',
            width: '0.25rem',
            marginTop: '0.125rem',
            marginLeft: '0.125rem',
            borderRadius: '50%'
        },
        root: { width: '95%' },
        thumb: { width: '1rem', height: '1rem' },
        rail: {
            height: '0.5rem',
            borderRadius: '10px',
            width: '103%',
            paddingLeft: '0.5rem',
            paddingRight: '0.5rem',
            mark: {
                color: theme.palette.primary
            }
        },
        valueLabel: {
            transform: 'none',
            paddingLeft: '0.25rem',
            '& > span > span': {
                color: theme.palette.background.default,
                backgroundColor: theme.palette.primary.main,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '5rem',
                height: '2rem',
                fontSize: '1rem',
                borderRadius: '4px'
            }
        },

        track: {
            height: '0.5rem',
            borderRadius: '10px',
            mark: {
                color: theme.palette.background
            }
        }
    }),
    { withTheme: true }
)(Slider);

export const PaymentSlider: React.FC<{
    enrollmentIncrement: number | null;
    enrollmentInterval: EnrollmentInterval;
    consumptionIncrement: number | null;
    setConsumptionIncrement: (inc: number) => void;
}> = ({ enrollmentIncrement, setConsumptionIncrement, enrollmentInterval, consumptionIncrement }) => {
    const customMarks = useMemo(() => {
        return paymentMarks.map(({ value, label }) => ({
            value,
            label: <MarkLabelComponent label={label} pace={'per day'} />
        }));
    }, []);

    if (enrollmentIncrement === null || consumptionIncrement === null) return null;

    return (
        <Box sx={{ width: '90%', marginTop: '2.5rem', marginBottom: '0.5rem', marginLeft: '0.25rem' }}>
            <StyledSlider
                aria-label={`How much content will you consume per ${enrollmentInterval}`}
                valueLabelFormat={() => `${pluralize(enrollmentInterval, enrollmentIncrement, true)}`}
                defaultValue={consumptionIncrement}
                value={consumptionIncrement}
                getAriaValueText={(value) => {
                    return `${calculateConsumptionValue(value)} minutes per day`;
                }}
                step={1}
                min={1}
                max={5}
                marks={customMarks}
                onChange={(_e, value) => {
                    setConsumptionIncrement(value as number);
                }}
                valueLabelDisplay="on"
            />
        </Box>
    );
};
