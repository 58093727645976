import { useMemo } from 'react';

import { OptimistMutationContext, useOptimist } from '@adept-at/lib-react-optimist';
import { EntityInfo } from 'components/ContentContext/Interfaces';
import { typeToIdKey } from 'components/dash/utils';
import { ClientError } from 'graphql-request';
import gql from 'graphql-tag';
import { useBuildAuthenticatedMutationFn } from 'hooks/useGqlClient/useBuildAuthenticatedMutationFn';
import { useSnackbar } from 'notistack';
import { useMutation, UseMutationResult } from 'react-query';

import { Api } from '../../../lib/ApiConstants';
import { EntityType } from '../../ContentContext/Enums';

import { queryKey as getScheduleQueryKey, GetScheduleResponse } from './useGetSchedule';

export interface UpsertScheduleItemResult {
    upsertScheduleItem: {
        success: boolean;
    };
}

export interface UpsertScheduleItemVariables {
    date: string;
    item: EntityInfo;
}

export const UPSERT_SCHEDULE_ITEM = gql`
    mutation upsertScheduleItem($item: EntityInfoInput!, $date: ISO8601!) {
        upsertScheduleItem(item: $item, date: $date) {
            success
        }
    }
`;

interface UseUpsertScheduleItemProps {
    onSuccess?: () => void;
}

const useUpsertScheduleItem = ({ onSuccess = () => {} }: UseUpsertScheduleItemProps = {}): UseMutationResult<
    UpsertScheduleItemResult,
    ClientError,
    UpsertScheduleItemVariables,
    OptimistMutationContext
> => {
    const { enqueueSnackbar } = useSnackbar();

    const { registerUpdater: registerUpsertScheduleItemUpdater, mutationOptions } = useOptimist<
        UpsertScheduleItemResult,
        ClientError,
        UpsertScheduleItemVariables
    >();

    const upsertScheduleItemOptions = useMemo(() => {
        return {
            onError: () => {
                enqueueSnackbar('An error occurred. Unable to schedule item', { variant: 'error' });
            },
            onSuccess: () => {
                enqueueSnackbar('Item successfully scheduled', { variant: 'success' });
            }
        };
    }, [enqueueSnackbar]);

    registerUpsertScheduleItemUpdater<GetScheduleResponse>(
        getScheduleQueryKey,
        (previous, newItem) => {
            if (newItem.item.type === EntityType.LIVE_LEARNING_SESSION) {
                return {
                    getSchedule: {
                        ...previous?.getSchedule,
                        items: (previous?.getSchedule?.items ?? []).concat([
                            {
                                type: EntityType.LIVE_LEARNING_SESSION,
                                date: newItem.date,
                                item: { sessionId: newItem.item.id }
                            }
                        ])
                    }
                };
            }

            const items = [...(previous?.getSchedule?.items ?? [])];
            const idKey: string = typeToIdKey[newItem.item.type];
            const existingItemIndex = items.findIndex(
                (item) => !!item && item.type === newItem.item.type && item.item[idKey] === newItem.item.id
            );

            if (existingItemIndex > -1) {
                items[existingItemIndex] = { ...items[existingItemIndex], date: newItem.date };
            } else {
                // set type as any - missing other required attributes
                const item: any = { [idKey]: newItem.item.id };
                items.push({
                    date: newItem.date,
                    type: newItem.item.type,
                    item
                });
            }

            return {
                getSchedule: {
                    ...previous?.getSchedule,
                    items
                }
            };
        },
        upsertScheduleItemOptions
    );

    const { mutationFn } = useBuildAuthenticatedMutationFn<UpsertScheduleItemResult, UpsertScheduleItemVariables>(
        UPSERT_SCHEDULE_ITEM,
        { api: Api.Content }
    );

    return useMutation<UpsertScheduleItemResult, ClientError, UpsertScheduleItemVariables, OptimistMutationContext>(
        mutationFn,
        {
            ...mutationOptions,
            onSuccess: (...args) => {
                if (mutationOptions.onSuccess) {
                    mutationOptions.onSuccess(...args);
                }

                onSuccess();
            }
        }
    );
};

export default useUpsertScheduleItem;
