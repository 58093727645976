import { useMemo } from 'react';

import produce from 'immer';
import { useQueryClient } from 'react-query';

import { buildGetAssessmentByIdQueryKey } from '../../../../pages/assessment/AssessmentEdit';
import { RemoveComponentVariables } from '../../../engine/types';
import { GetAssessmentByIdResponse } from '../../queries/getAssessmentById';

type MutationFunction = (variables: RemoveComponentVariables) => void;

const useRemoveComponentFromLocalStateOnly = (assessmentId: string): MutationFunction => {
    const queryClient = useQueryClient();

    const entityQueryKey = useMemo(() => buildGetAssessmentByIdQueryKey(assessmentId), [assessmentId]);

    return ({ componentId }: RemoveComponentVariables) => {
        queryClient.setQueryData<GetAssessmentByIdResponse>(entityQueryKey, (previous) =>
            !previous
                ? ({} as GetAssessmentByIdResponse)
                : produce(previous, (draft) => {
                      try {
                          delete draft.getAssessmentById.components[componentId];
                      } catch (e) {}
                  })
        );
    };
};

export default useRemoveComponentFromLocalStateOnly;
