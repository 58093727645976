import React, { createContext, useState, useMemo, useContext, FunctionComponent, useEffect, useCallback } from 'react';

import { useCroppedImage, CropDetails } from '@adept-at/lib-react-components';
import { ImageDestination } from 'components/builder/InlineUploadImage';
import { DefaultCollectionImageType, DefaultImagesByColor, Images } from 'components/ContentContext/Interfaces';
import { GetEditableCollectionResponse } from 'components/RootProfileCollection/useGetEditableCollection';
import { useUpdateCollectionMeta } from 'components/RootProfileCollection/useUpdateCollectionMeta';
import { ClientError } from 'graphql-request';
import { RefetchOptions, QueryObserverResult } from 'react-query';

import { useAddCollectionImages } from '../hooks/useAddCollectionImages';
import { useRemoveCollectionImage } from '../hooks/useRemoveCollectionImage';
import { useRemoveAllCollectionImages } from '../hooks/useRemoveCollectionImages';

const CATALOG_IMAGE_WIDTH = 125;
const CATALOG_IMAGE_HEIGHT = 125;
const FEATURED_IMAGE_WIDTH = 1330;
const FEATURED_IMAGE_HEIGHT = 700;
const FEATURED_MOBILE_IMAGE_WIDTH = 415;
const FEATURED_MOBILE_IMAGE_HEIGHT = 560;

const CATALOG_IMAGE_ASPECT_RATIO = 1;
const FEATURED_IMAGE_ASPECT_RATIO = 1.6; // 2400 / 1500;

const CATALOG_KEY = 'catalog';
const FEATURED_KEY = 'featured';

export enum UploadTag {
    DARK = 'mode:dark',
    LIGHT = 'mode:light',
    DESKTOP = 'type:desktop',
    MOBILE = 'type:mobile'
}

export type UploadImageMode = 'light' | 'dark';
export type UploadImageType = 'desktop' | 'mobile';
export type UploadedImageDestination = 'catalog' | 'featured';

export interface UploadDestinationProps {
    type: UploadImageType;
    mode: UploadImageMode;
    destination: UploadedImageDestination;
}

export interface UploadImageProps {
    width: number;
    height: number;
    aspectRatio: number;
}

enum ImageDestinations {
    CATALOG = 'catalog',
    FEATURED = 'featured'
}

enum ImageTypes {
    DESKTOP = 'desktop',
    MOBILE = 'mobile'
}

enum ImageModes {
    DARK = 'dark',
    LIGHT = 'light'
}
interface CollectionImageShape {
    catalogLight?: DefaultCollectionImageType | string | null;
    catalogDark?: DefaultCollectionImageType | string | null;
    featuredLight?: DefaultCollectionImageType | string | null;
    featuredLightMobile?: DefaultCollectionImageType | string | null;
    featuredDarkMobile?: DefaultCollectionImageType | string | null;
    featuredDark?: DefaultCollectionImageType | string | null;
}

interface CollectionImageContextShape extends CollectionImageShape {
    collectionImages?: CollectionImageShape;
    defaultImages?: DefaultImagesByColor;
    defaultCatalog?: string | null;
    defaultFeatured?: CollectionImageShape;
    uploadedImages?: CollectionImageShape;
    uploadDestination?: UploadDestinationProps;
    uploadModalOpen?: boolean;
    uploadResolutions?: UploadImageProps;
    uploadAttributes?: Record<string, unknown>;
}

interface CollectionUploadImageAPI extends CollectionImageContextShape {
    handleUploadModalOpen?: () => void;
    handleUploadModalClose?: () => void;
    onFinishUpload?: (assetId: string, image: string | null, crop: CropDetails) => void;
    setUploadDestination?: (options: UploadDestinationProps) => void;
    handleUseDefaultBackground?: (type: DefaultCollectionImageType) => void;
    handleUseDefaultCatalogImage?: (type: DefaultCollectionImageType) => void;
    handleRemoveCatalogDarkImage?: () => void;
}

interface CollectionImageProviderProps {
    images: Images | null;
    defaultCatalogImage: DefaultCollectionImageType;
    defaultFeaturedImage: DefaultCollectionImageType;
    collectionId: string;
    parentQuerySlug: string;
    refetchEditableCollection: (
        options?: RefetchOptions
    ) => Promise<QueryObserverResult<GetEditableCollectionResponse, ClientError>>;
    collectionIdForEditableCollectionQuery: string;
}

export const CollectionImageContext = createContext({} as CollectionUploadImageAPI);

export const CollectionImageProvider: FunctionComponent<CollectionImageProviderProps> = ({
    images,
    defaultCatalogImage,
    defaultFeaturedImage,
    collectionId,
    parentQuerySlug,
    collectionIdForEditableCollectionQuery,
    refetchEditableCollection,
    children
}) => {
    const { mutate: updateImages } = useAddCollectionImages({
        collectionId: collectionIdForEditableCollectionQuery,
        parentQuerySlug
    });

    const { mutate: removeImage } = useRemoveCollectionImage({
        collectionId: collectionIdForEditableCollectionQuery,
        parentQuerySlug
    });

    const { mutate: removeCollectionImages } = useRemoveAllCollectionImages({
        collectionId: collectionIdForEditableCollectionQuery,
        parentQuerySlug
    });

    const { mutate: updateMetadata } = useUpdateCollectionMeta({
        collectionId: collectionIdForEditableCollectionQuery,
        parentQuerySlug
    });

    const [uploadModalOpen, setUploadModalOpen] = useState<boolean>(false);
    const [croppedImage, setCroppedImage] = useState<string | null>(null);
    const [crop, setCrop] = useState<CropDetails | null>(null);

    const [uploadDestination, setUploadDestination] = useState<UploadDestinationProps>({
        mode: ImageModes.LIGHT,
        type: ImageTypes.DESKTOP,
        destination: ImageDestinations.CATALOG
    });

    const [uploadedImages, setUploadedImages] = useState<CollectionImageShape>({
        catalogLight: null,
        catalogDark: null,
        featuredLight: null,
        featuredLightMobile: null,
        featuredDark: null,
        featuredDarkMobile: null
    });

    const isDesktopDark = (tags: string[] | null | undefined) =>
        tags?.join()?.includes(UploadTag.DARK) && tags?.join()?.includes(UploadTag.DESKTOP);

    const isDesktopLight = (tags: string[] | null | undefined) =>
        tags?.join()?.includes(UploadTag.LIGHT) && tags?.join()?.includes(UploadTag.DESKTOP);

    const isMobileDark = (tags: string[] | null | undefined) =>
        tags?.join()?.includes(UploadTag.DARK) && tags?.join()?.includes(UploadTag.MOBILE);

    const isMobileLight = (tags: string[] | null | undefined) =>
        tags?.join()?.includes(UploadTag.LIGHT) && tags?.join()?.includes(UploadTag.MOBILE);

    const featuredImages = useMemo(() => {
        const light = images?.featuredImages?.find((item) => isDesktopLight(item?.tags));
        const dark = images?.featuredImages?.find((item) => isDesktopDark(item?.tags));
        const lightMobile = images?.featuredImages?.find((item) => isMobileLight(item?.tags));
        const darkMobile = images?.featuredImages?.find((item) => isMobileDark(item?.tags));

        return {
            light: {
                url: light?.processed[0]?.url ?? null,
                assetId: light?.id ?? null
            },
            dark: {
                url: dark?.processed[0]?.url ?? null,
                assetId: dark?.id ?? null
            },
            lightMobile: {
                url: lightMobile?.processed[0]?.url ?? null,
                assetId: lightMobile?.id ?? null
            },
            darkMobile: {
                url: darkMobile?.processed[0].url ?? null,
                assetId: darkMobile?.id ?? null
            }
        };
    }, [images]);

    const catalogImages = useMemo(() => {
        const light = images?.catalogImages?.find((item) => isDesktopLight(item?.tags));
        const dark = images?.catalogImages?.find((item) => isDesktopDark(item?.tags));

        return {
            light: {
                url: light?.processed[0]?.url ?? null,
                assetId: light?.id ?? null
            },
            dark: {
                url: dark?.processed[0]?.url ?? null,
                assetId: dark?.id ?? null
            }
        };
    }, [images]);

    const handleUploadModalOpen = () => {
        setUploadModalOpen(true);
    };

    const handleUploadModalClose = () => {
        setUploadModalOpen(false);
    };

    const collectionImages = useMemo<CollectionImageContextShape>(() => {
        const legacyCatalog = images?.catalog?.processed[0]?.url;
        const legacyFeatured = images?.featured?.processed[0]?.url;

        const catalogLight = uploadedImages?.catalogLight ?? catalogImages.light?.url ?? legacyCatalog ?? null;
        const catalogDark = uploadedImages?.catalogDark ?? catalogImages.dark?.url ?? legacyCatalog ?? null;

        const featuredLight = uploadedImages.featuredLight ?? featuredImages?.light?.url ?? legacyFeatured ?? null;

        const featuredDark =
            uploadedImages.featuredDark ??
            featuredImages?.dark?.url ??
            uploadedImages.featuredLight ??
            featuredImages?.light?.url ??
            legacyFeatured ??
            null;

        const featuredLightMobile =
            uploadedImages.featuredLightMobile ??
            featuredImages?.lightMobile?.url ??
            uploadedImages.featuredLight ??
            featuredImages?.light?.url ??
            legacyFeatured ??
            null;

        const featuredDarkMobile =
            uploadedImages.featuredDarkMobile ??
            uploadedImages.featuredDark ??
            featuredImages?.darkMobile?.url ??
            featuredImages?.dark?.url ??
            uploadedImages.featuredLight ??
            featuredImages?.light?.url ??
            legacyFeatured ??
            null;

        return {
            catalogLight,
            catalogDark,
            featuredLight,
            featuredDark,
            featuredLightMobile,
            featuredDarkMobile
        };
    }, [
        images,
        uploadedImages,
        catalogImages?.dark,
        catalogImages?.light,
        featuredImages?.dark?.url,
        featuredImages?.light?.url,
        featuredImages?.darkMobile?.url,
        featuredImages?.lightMobile?.url
    ]);

    const defaultCatalog = useMemo(
        () => images?.defaultImages?.[defaultCatalogImage]?.catalog,
        [defaultCatalogImage, images?.defaultImages]
    );

    const defaultFeatured = useMemo(
        () => images?.defaultImages?.[defaultFeaturedImage],
        [defaultFeaturedImage, images?.defaultImages]
    );

    const uploadResolutions = useMemo(() => {
        if (uploadDestination.destination === ImageDestinations.FEATURED) {
            if (uploadDestination.type === ImageTypes.MOBILE) {
                return {
                    width: FEATURED_MOBILE_IMAGE_WIDTH,
                    height: FEATURED_MOBILE_IMAGE_HEIGHT,
                    aspectRatio: 1
                };
            }
            return {
                width: FEATURED_IMAGE_WIDTH,
                height: FEATURED_IMAGE_HEIGHT,
                aspectRatio: FEATURED_IMAGE_ASPECT_RATIO
            };
        }

        return {
            width: CATALOG_IMAGE_WIDTH,
            height: CATALOG_IMAGE_HEIGHT,
            aspectRatio: CATALOG_IMAGE_ASPECT_RATIO
        };
    }, [uploadDestination]);

    const buildTags = useMemo(() => {
        const uploadMode = uploadDestination.mode === ImageModes.DARK ? UploadTag.DARK : UploadTag.LIGHT;
        const uploadType = uploadDestination.type === ImageTypes.MOBILE ? UploadTag.MOBILE : UploadTag.DESKTOP;

        const tags = [uploadMode, uploadType].join(', ');
        return tags;
    }, [uploadDestination]);

    const uploadAttributes = useMemo(() => {
        switch (uploadDestination.destination) {
            case ImageDestinations.CATALOG:
                return {
                    tags: ['image', ImageDestination.CollectionCatalog, buildTags]
                };
            case ImageDestinations.FEATURED:
                return {
                    tags: ['image', ImageDestination.CollectionFeatured, buildTags]
                };
            default:
                return { tags: ['image', ImageDestination.CollectionCatalog, buildTags] };
        }
    }, [uploadDestination, buildTags]);

    const uploadedImageDestination = useMemo(() => {
        switch (uploadDestination.destination) {
            case ImageDestinations.CATALOG:
                if (uploadDestination.mode === ImageModes.DARK) {
                    return 'catalogDark';
                }
                return 'catalogLight';
            case ImageDestinations.FEATURED:
                if (uploadDestination.mode === ImageModes.DARK) {
                    return uploadDestination.type === ImageTypes.MOBILE ? 'featuredDarkMobile' : 'featuredDark';
                }
                return uploadDestination.type === ImageTypes.MOBILE ? 'featuredLightMobile' : 'featuredLight';
            default:
                return 'catalogLight';
        }
    }, [uploadDestination]);

    const imageToReplace = useMemo(() => {
        switch (uploadDestination.destination) {
            case ImageDestinations.CATALOG: {
                if (uploadDestination.mode === ImageModes.DARK) {
                    return catalogImages?.dark?.assetId;
                }
                return catalogImages?.light?.assetId;
            }
            case ImageDestinations.FEATURED: {
                switch (uploadDestination.type) {
                    case ImageTypes.MOBILE:
                        if (uploadDestination.mode === ImageModes.DARK) {
                            return featuredImages?.darkMobile?.assetId;
                        }
                        return featuredImages?.lightMobile?.assetId;
                    case ImageTypes.DESKTOP:
                        if (uploadDestination.mode === ImageModes.DARK) {
                            return featuredImages?.dark?.assetId;
                        }
                        return featuredImages?.light?.assetId;
                    default:
                        return null;
                }
            }
        }
    }, [uploadDestination, featuredImages, catalogImages]);

    const { imageSrc: croppedImageSrc = null } = useCroppedImage(croppedImage, crop);

    const uploadImage = (assetId: string) => {
        const imageUpdates = {
            catalog: images?.catalog?.id ?? null,
            featured: images?.featured?.id ?? null,
            defaultCatalogImageKey: null,
            defaultFeaturedImageKey: null
        };
        const imageInput = {
            assetId,
            collectionId,
            collectionImageType: uploadDestination.destination
        };

        if (imageToReplace) {
            removeImage({ collectionId, collectionImageType: uploadDestination.destination, assetId: imageToReplace });
        }

        updateImages({ ...imageInput });
        updateMetadata({ input: { collectionId, images: imageUpdates } });
    };

    const setUploadedCroppedImage = useCallback(() => {
        setUploadedImages({ ...uploadedImages, [uploadedImageDestination]: croppedImageSrc });
        setCroppedImage(null);
    }, [uploadedImageDestination, croppedImageSrc, uploadedImages]);

    useEffect(() => {
        if (croppedImageSrc && croppedImageSrc !== uploadedImages[uploadedImageDestination]) {
            setUploadedCroppedImage();
        }
    }, [croppedImage, croppedImageSrc, uploadedImages, uploadedImageDestination, setUploadedCroppedImage]);

    const onFinishUpload = (assetId: string, image: string | null, crop: CropDetails) => {
        setCroppedImage(image);
        setCrop(crop);
        uploadImage(assetId);
        handleUploadModalClose();
    };

    const defaultImages = useMemo(() => images?.defaultImages, [images?.defaultImages]);

    const getFeaturedDefaultImages = (color) => {
        const defaultImages = { ...images?.defaultImages?.[color] };
        delete defaultImages?.catalog;
        return defaultImages;
    };

    const handleDefaultFeatured = (type: DefaultCollectionImageType) => {
        const defaultFeaturedImages = getFeaturedDefaultImages(type);
        return setUploadedImages({ ...uploadedImages, ...defaultFeaturedImages });
    };

    const handleDefaultCatalog = (type: DefaultCollectionImageType) => {
        const defaultCatalogImage = images?.defaultImages?.[type]?.catalog;
        return setUploadedImages({ ...uploadedImages, catalogLight: defaultCatalogImage });
    };

    const handleUseDefaultBackground = (type: DefaultCollectionImageType) => {
        const imageUpdates = {
            catalog: images?.catalog?.id ?? null,
            featured: null
        };

        if (images?.featuredImages && images?.featuredImages[0]?.id) {
            removeCollectionImages({ collectionId, collectionImageType: FEATURED_KEY });
        }

        updateMetadata({ input: { collectionId, images: imageUpdates, defaultFeaturedImageKey: type } });

        handleDefaultFeatured(type);
    };

    const handleUseDefaultCatalogImage = (type: DefaultCollectionImageType) => {
        const imageUpdates = {
            catalog: null,
            featured: images?.featured?.id ?? null
        };

        if (images?.catalogImages && images?.catalogImages[0]?.id) {
            removeCollectionImages({ collectionId, collectionImageType: CATALOG_KEY });
        }

        updateMetadata({ input: { collectionId, images: imageUpdates, defaultCatalogImageKey: type } });

        handleDefaultCatalog(type);
    };

    // Called when "Use custom dark mode image" is toggled to false on catalog image modal
    const handleRemoveCatalogDarkImage = () => {
        if (catalogImages?.dark?.assetId) {
            removeImage({
                collectionId,
                collectionImageType: ImageDestinations.CATALOG,
                assetId: catalogImages.dark.assetId
            });
            refetchEditableCollection();
        }
    };

    return (
        <CollectionImageContext.Provider
            value={{
                collectionImages,
                defaultImages,
                defaultCatalog,
                defaultFeatured,
                uploadedImages,
                uploadModalOpen,
                uploadResolutions,
                uploadAttributes,
                onFinishUpload,
                setUploadDestination,
                handleUploadModalOpen,
                handleUploadModalClose,
                handleUseDefaultBackground,
                handleUseDefaultCatalogImage,
                handleRemoveCatalogDarkImage
            }}
        >
            {children}
        </CollectionImageContext.Provider>
    );
};

export const useCollectionImageContext = (): CollectionUploadImageAPI => {
    const context = useContext(CollectionImageContext);

    if (!context) {
        throw new Error('useCollectionImageContext must be used within CollectionImageProvider');
    }

    return context;
};
