import { useReducer, useEffect, Dispatch } from 'react';

import { ClientError } from 'graphql-request';
import profilesModifiedListener from 'hooks/GlobalHooks/useAffiliationsModified';
import { CurrentUser, useCurrentUser } from 'hooks/useCurrentUser';
import { QueryStatus, RefetchOptions, QueryObserverResult } from 'react-query';
import { isTrue } from 'utils/isTrue';

import { ProfileOption, ProfileType } from '..';

import { useGetOrganizationsForUser, GetOrganizationsForUserResponse } from './useGetOrganizationsForUser';

const dedupeOptions = (options: ProfileOption[]): ProfileOption[] => {
    const { result } = options.reduce(
        (memo, profile) => {
            const { id, type } = profile;
            const dedupKey = `${type}:${id}`;

            if (memo.dedup.indexOf(dedupKey) !== -1) {
                return memo;
            }

            memo.dedup.push(dedupKey);
            memo.result.push(profile);
            return memo;
        },
        { result: new Array<ProfileOption>(), dedup: new Array<string>() }
    );

    return result;
};

export enum AvailableProfilesUpdateAction {
    Merge = 'merge',
    Remove = 'remove',
    Added = 'added',
    Clear = 'clear',
    Reorder = 'reorder'
}

export interface AvailableProfilesUpdate {
    options: ProfileOption[];
    action: AvailableProfilesUpdateAction;
}

const availableProfilesReducer = (previousState: ProfileOption[], update: AvailableProfilesUpdate): ProfileOption[] => {
    const { options, action = AvailableProfilesUpdateAction.Merge } = update;

    switch (action) {
        case AvailableProfilesUpdateAction.Merge:
            /* TODO - Plumb in functionality to Alert/Notify on new profiles.
            const existingProfiles = previousState.map((_profile) => _profile.id);
            options.forEach((_profile) => {
                if (existingProfiles.indexOf(_profile.id) < 0) {
                    console.log('This is a newly added profile:', _profile);
                }
            });
            */
            return dedupeOptions([...previousState, ...options]);

        case AvailableProfilesUpdateAction.Reorder:
            const personalProfile: ProfileOption[] = [];

            previousState.forEach((_profile) => {
                if (_profile.type === ProfileType.Personal) {
                    personalProfile.push(_profile);
                    return;
                }
            });

            return [...personalProfile, ...options];

        case AvailableProfilesUpdateAction.Clear:
            return options ?? new Array<ProfileOption>();

        case AvailableProfilesUpdateAction.Remove:
            const trimmedProfiles = previousState.filter((_profile) => _profile.id !== options[0].id);
            return trimmedProfiles;
    }

    return previousState;
};

interface UseAvailableProfilesOutput {
    availableProfiles: ProfileOption[];
    setAvailableProfiles: Dispatch<AvailableProfilesUpdate>;
    status: QueryStatus;
    fetchAvailableProfiles: (
        options?: RefetchOptions | undefined
    ) => Promise<QueryObserverResult<GetOrganizationsForUserResponse, ClientError>>;
    error?: ClientError | null;
}

const useAvailableProfiles = (): UseAvailableProfilesOutput => {
    const { currentUser } = useCurrentUser();

    const [availableProfiles, setAvailableProfiles] = useReducer(availableProfilesReducer, new Array<ProfileOption>());

    const {
        status,
        error,
        refetch: fetchAvailableProfiles
    } = useGetOrganizationsForUser(
        /**
         * @FIXME our buildMock utility requires the signature is (variables, options)... deal better with this in
         * future state. Without this, we cannot appropriately use options with our mock
         */
        {},
        {
            enabled: isTrue<CurrentUser>(currentUser),
            onSuccess: (data: GetOrganizationsForUserResponse) => {
                if (data?.getOrganizationsForUser?.length > 0) {
                    setAvailableProfiles({
                        options: data.getOrganizationsForUser.map(
                            ({ id, name, alias, tenants, image, images, colors, terminology }) => ({
                                id,
                                alias,
                                label: name,
                                imageId: images?.logoSquare?.contentAssetId ?? image,
                                images: {
                                    logoSquare: images?.logoSquare?.contentAssetId ?? image ?? null,
                                    logoHeader: images?.logoHeader?.contentAssetId ?? null
                                },
                                colors: {
                                    background: colors?.background ?? null
                                },
                                type: ProfileType.Organization,
                                terminology,
                                tenants
                            })
                        ),
                        action: AvailableProfilesUpdateAction.Merge
                    });
                }
            }
        }
    );

    useEffect(() => {
        if (currentUser && currentUser.userId && currentUser.alias) {
            const { userId, alias } = currentUser;

            setAvailableProfiles({
                options: [{ type: ProfileType.Personal, label: 'Personal', id: userId, alias, tenants: [] }],
                action: AvailableProfilesUpdateAction.Merge
            });
        }
    }, [currentUser]);

    profilesModifiedListener(fetchAvailableProfiles, setAvailableProfiles);

    return { availableProfiles, setAvailableProfiles, fetchAvailableProfiles, status, error };
};

export default useAvailableProfiles;
