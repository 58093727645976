import { useMemo } from 'react';

import qs from 'qs';
import { useLocation } from 'react-router';

export const useQueryParams = (): Record<string, string | string[]> => {
    const { search: locationSearch } = useLocation();
    const queryParams = useMemo(() => qs.parse(locationSearch, { ignoreQueryPrefix: true }) ?? {}, [locationSearch]);

    return queryParams as Record<string, string | string[]>;
};
