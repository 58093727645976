import gql from 'graphql-tag';

export const ADD_COMMENT = gql`
    mutation createComment(
        $contextId: UUIDv4!
        $body: String!
        $type: CommentContextType!
        $access: CommentContextAccess!
        $parent: String!
    ) {
        createComment(contextId: $contextId, body: $body, parent: $parent, access: $access, type: $type) {
            commentId
            createdBy
            createdAt
            body
            parent
            editedAt
            deletedAt
            thread
        }
    }
`;

export const UPDATE_COMMENT = gql`
    mutation updateComment(
        $contextId: UUIDv4!
        $body: String!
        $commentId: UUIDv4!
        $type: CommentContextType!
        $access: CommentContextAccess!
    ) {
        updateComment(contextId: $contextId, body: $body, commentId: $commentId, access: $access, type: $type) {
            body
            editedAt
            commentId
        }
    }
`;

export const REMOVE_COMMENT = gql`
    mutation removeComment(
        $contextId: UUIDv4!
        $commentId: UUIDv4!
        $type: CommentContextType!
        $access: CommentContextAccess!
    ) {
        removeComment(contextId: $contextId, commentId: $commentId, access: $access, type: $type) {
            commentId
            deletedAt
        }
    }
`;
