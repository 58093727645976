import React, { useCallback, useState } from 'react';

import { IconSize, TooltippedButtonSize, TooltippedFab } from '@adept-at/lib-react-components';
import { mdiImageArea, mdiImageEdit } from '@mdi/js';
import { Icon } from '@mdi/react';
import { CatalogImageCircledPath } from 'components/icons/CatalogImageCircled';
import { usePopover } from 'hooks/usePopover';
import styled from 'styled-components';
import { StyledMenu, StyledListItem } from 'styles/Shared.styles';

import { EditCatalogImageModal, EditCollectionBackgroundModal, UploadImageModal } from '../Modals';

const FEATURED_MODAL_WIDTH = '1000px';

const Container = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 140px;
    top: 40px;
`;

interface EditOptions {
    removeDarkImage?: boolean;
}

interface EditCatalogCollectionProps {
    isEditMode?: boolean;
    collectionTitle: string;
    handleRemoveDarkImage?: () => void;
}

export const EditCollectionImageActions: React.FC<EditCatalogCollectionProps> = ({
    isEditMode,
    collectionTitle = '',
    handleRemoveDarkImage = () => {}
}) => {
    const [catalogModalOpen, setCatalogModalOpen] = useState(false);
    const [backgroundModalOpen, setBackgroundModalOpen] = useState(false);

    const { open, anchorEl, handlePopoverClick, handlePopoverClose } = usePopover();

    const onCatalogClicked = useCallback(() => {
        handlePopoverClose();
        setCatalogModalOpen(true);
    }, [handlePopoverClose]);

    const onBackgroundItemClicked = useCallback(() => {
        handlePopoverClose();
        setBackgroundModalOpen(true);
    }, [handlePopoverClose]);

    const handleCatalogModalClose = useCallback(() => {
        setCatalogModalOpen(false);
    }, []);

    const handleOnConfirm = (options?: EditOptions) => {
        const shouldRemoveDarkImage = options?.removeDarkImage;

        if (shouldRemoveDarkImage) {
            handleRemoveDarkImage();
        }
        handleCatalogModalClose();
    };

    const handleBackgroundModalClose = useCallback(() => {
        setBackgroundModalOpen(false);
    }, []);

    if (!isEditMode) return null;

    return (
        <Container>
            <TooltippedFab
                title="Edit images"
                size={TooltippedButtonSize.Medium}
                onClick={handlePopoverClick}
                icon={<Icon path={mdiImageEdit} />}
            />
            <StyledMenu keepMounted open={open} anchorEl={anchorEl} onClose={handlePopoverClose}>
                <StyledListItem button onClick={onCatalogClicked}>
                    <Icon path={CatalogImageCircledPath} size={IconSize.Medium} />
                    Catalog image
                </StyledListItem>
                <StyledListItem button onClick={onBackgroundItemClicked}>
                    <Icon path={mdiImageArea} size={IconSize.Medium} />
                    Background image
                </StyledListItem>
            </StyledMenu>
            <EditCatalogImageModal
                contentTitle={collectionTitle}
                open={catalogModalOpen}
                onClose={handleCatalogModalClose}
                onConfirm={handleOnConfirm}
            />
            <EditCollectionBackgroundModal
                contentTitle={collectionTitle}
                open={backgroundModalOpen}
                onClose={handleBackgroundModalClose}
                onConfirm={handleBackgroundModalClose}
            />
            <UploadImageModal
                circularCrop={catalogModalOpen}
                maxWidth={backgroundModalOpen ? FEATURED_MODAL_WIDTH : undefined}
            />
        </Container>
    );
};
