import React from 'react';

import LogoIcon from 'components/icons/Logo';
import styled from 'styled-components';

const LogoWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 10rem;
`;

const Logo = styled.div`
    color: ${(props) => props.theme.colors.primary};
    max-width: 200px;
    transform: rotate(270deg);
`;

const Title = styled.h1`
    text-align: center;
    font-weight: 200;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 20px;
`;

const TitleWrapper = styled.div`
    margin-top: 4rem;
`;

export const NotFoundError: React.FC = () => (
    <>
        <LogoWrapper>
            <Logo>
                <LogoIcon size={75} />
            </Logo>
        </LogoWrapper>
        <TitleWrapper>
            <Title>Page Not Found</Title>
        </TitleWrapper>
    </>
);
