import React from 'react';

import { Dialog, DialogTitle, DialogContent, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';

import { CurrentStatus } from './CurrentStatus';

const IconCloseButton = styled(IconButton)`
    float: right;
`;

interface AuthExplorerDialogProps {
    open: boolean;
    onClose: () => void;
}

export const AuthExplorerDialog: React.FC<AuthExplorerDialogProps> = ({ open = false, onClose }) => {
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>
                Auth Explorer
                <IconCloseButton aria-label="Close modal" onClick={onClose}>
                    <CloseIcon />
                </IconCloseButton>
            </DialogTitle>
            <DialogContent style={{ marginBottom: '20px' }}>
                <CurrentStatus />
            </DialogContent>
        </Dialog>
    );
};
