import { IconSize } from '@adept-at/lib-react-components';
import styled from 'styled-components';

import { modularScale } from '../../utils/modular_scale';

export const Card = styled.div`
    background-color: ${(props) => props.theme.colors.surface};
    border: 1px solid ${(props) => props.theme.colors.border};
    padding: 1rem;
    border-radius: 4px;
    margin-bottom: 1.5rem;

    &:hover {
        box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
            0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    }
`;

export const Clickable = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
`;

export const Info = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
`;

export const Title = styled.div``;

export const Name = styled.h2`
    font-size: ${modularScale(1)};
    font-weight: 600;
`;

export const CardType = styled.div`
    font-size: ${modularScale(-1)};
    color: ${(props) => props.theme.colors.textTertiary};
    text-transform: uppercase;
    letter-spacing: 1px;
`;

export const Stats = styled.div`
    margin-top: auto;
    color: ${(props) => props.theme.colors.textSecondary};
    font-size: ${modularScale(-1)};
`;

export const Attributes = styled.div`
    display: flex;
    flex-direction: column;
    height: 90px;
    flex: 1 1 auto;
`;

export const Metadata = styled.div`
    display: grid;
    align-items: start;
    grid-template-columns: 60% 40%;
    grid-template-rows: auto;
`;

export const MenuWrapper = styled.div`
    margin-left: auto;
    color: ${(props) => props.theme.colors.primary};
    cursor: pointer;
    align-self: start;

    svg {
        width: ${IconSize.Medium};
    }
`;
