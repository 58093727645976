import React, { createContext, useContext, useEffect, useState } from 'react';

import clone from 'clone-deep';

import { EditorContext, EditMode } from '../../context/EditorContext';

export interface StoryboardContextInterface {
    step: number;
    setStep: (step: number) => void;
    nextStep: () => void;
    prevStep: () => void;

    workingStoryboard: StoryboardSchema;

    why: StoryboardItem;
    who: StoryboardItem;
    how: StoryboardItem;

    setWhy: (why: StoryboardItem) => void;
    setWho: (who: StoryboardItem) => void;
    setHow: (how: StoryboardItem) => void;
}

const StoryboardContext = createContext({} as StoryboardContextInterface);

const { Provider, Consumer } = StoryboardContext;

export interface StoryboardItem {
    summary: string | null;
}

export interface StoryboardSchema {
    why: StoryboardItem;
    who: StoryboardItem;
    how: StoryboardItem;
}

export const defaultStoryboard = {
    why: { summary: null },
    who: { summary: null },
    how: { summary: null }
};

export const STEPS = ['why', 'who', 'how'];

const StoryboardProvider: React.FC = ({ children }) => {
    const {
        updateWorkingSkillMetadata,
        workingSkill: { meta },
        editMode
    } = useContext(EditorContext);

    const storyboard = !!meta?.storyboard ? clone(meta.storyboard) : defaultStoryboard;

    const [step, setStep] = useState(-1);
    const [workingStoryboard, setWorkingStoryboard] = useState<StoryboardSchema>(storyboard);

    const [why, setWhy] = useState<StoryboardItem>(storyboard.why);
    const [who, setWho] = useState<StoryboardItem>(storyboard.who);
    const [how, setHow] = useState<StoryboardItem>(storyboard.how);

    // @TODO should these really be useEffects? Why not perform the updates and update the state from the caller
    useEffect(() => {
        if (!workingStoryboard || JSON.stringify(why) === JSON.stringify(workingStoryboard.why)) return;

        setWorkingStoryboard({ ...workingStoryboard, why });
    }, [why, workingStoryboard]);

    useEffect(() => {
        if (!workingStoryboard || JSON.stringify(who) === JSON.stringify(workingStoryboard.who)) return;

        setWorkingStoryboard({ ...workingStoryboard, who });
    }, [who, workingStoryboard]);

    useEffect(() => {
        if (!workingStoryboard || JSON.stringify(how) === JSON.stringify(workingStoryboard.how)) return;

        setWorkingStoryboard({ ...workingStoryboard, how });
    }, [how, workingStoryboard]);

    useEffect(() => {
        if (JSON.stringify(workingStoryboard) === JSON.stringify(meta.storyboard)) return;

        /**
         * @TODO Found a bug where this is firing for the Edit-in-place text fields when this dialog is hidden.
         * I don't like furthering use of the EditMode, but we'll look at mode for now to filter those out. Turns out
         * updating our local state here is not entirely helpful because the EditorContext state gets pulled in and that
         * is stale (not affected by the updateSkillMeta mutation that occured from the Edit-in-place).
         *
         * In short, the layers of derived state are causing a little bit of mess and this needs to be reworked. The fix
         * would be to not use EditorContext to do these updates but instead a clean hook for any updating to occur
         * and maybe some orchestrated queryClient refetches to get the updated skill values to propogate down the tree.
         * Either way, it's going to require a rework.
         *
         * @FIXME NOTICE: this fix only works if they do not open the dialog again. If they do without a refresh they
         * will lose their changes.
         */
        if (editMode === EditMode.Storyboard) {
            updateWorkingSkillMetadata({ storyboard: workingStoryboard });
        }
    }, [workingStoryboard, meta.storyboard, updateWorkingSkillMetadata, editMode]);

    const nextStep = () => {
        setStep(step + 1);
    };

    const prevStep = () => {
        setStep(step - 1);
    };

    return (
        <Provider
            value={{
                step,
                setStep,
                nextStep,
                prevStep,

                workingStoryboard: clone(workingStoryboard),

                why,
                who,
                how,

                setWhy,
                setWho,
                setHow
            }}
        >
            {children}
        </Provider>
    );
};

export { StoryboardContext, StoryboardProvider, Consumer as StoryboardConsumer };
