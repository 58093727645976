import React, { useRef } from 'react';

import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useGetLabsByCreator } from 'components/labs/hooks/useGetLabsByCreator';

import { useEditableComponent, EditButtons } from '../../mixins/editor';

import { TemporaryLabValues } from './VirtualLabComponentValues';

interface VirtualLabEditProps {
    onSave?: (values: Partial<TemporaryLabValues>) => void;
    onCancel?: () => void;
}

const VirtualLabEdit: React.FC<VirtualLabEditProps> = ({ onSave, onCancel }) => {
    const {
        currentValues: { vLabId, vLabTemplateId, name, vms },
        onSave: defaultOnSave,
        onCancel: defaultOnCancel,
        onChange
    } = useEditableComponent<TemporaryLabValues>();

    const { data: labsData, isLoading: _labsLoading, isError: _labsErrored } = useGetLabsByCreator();

    const containerRef = useRef<HTMLDivElement | null>(null);

    const save = () => {
        if (onSave) {
            return onSave({ vLabId, vLabTemplateId, name, vms });
        }
        defaultOnSave();
    };

    return (
        <div ref={containerRef}>
            <div>Lab Id: {vLabId || 'none'}</div>
            <div>Lab Template Id: {vLabTemplateId || 'none'}</div>
            <FormControl style={{ minWidth: '120px' }}>
                <InputLabel id="lab-select-label">Virtual Lab</InputLabel>
                <Select
                    value={vLabTemplateId}
                    id="lab-select"
                    labelId="lab-select-label"
                    onChange={(e) => {
                        const templateId = e.target.value;
                        const lab = labsData?.getVirtualLabsByCreator.find((value) => value.vLabId === templateId);

                        onChange('vLabTemplateId')(e.target.value as string);
                        onChange('name')(lab?.name);
                        onChange('vms')(JSON.stringify(lab?.virtualMachines ?? {}));
                    }}
                    label="Lab"
                    data-testid={'code-lab-select'}
                >
                    {labsData?.getVirtualLabsByCreator.map(({ vLabId: id, name }) => {
                        return (
                            <MenuItem key={id} value={id} data-testid={`lab-id-${id}`}>
                                {name}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            <EditButtons onCancel={onCancel ?? defaultOnCancel} onSave={save} />
        </div>
    );
};

export default VirtualLabEdit;
