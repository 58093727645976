import React from 'react';

import EditableTextArea from 'components/forms/EditableText/EditableTextArea';
import { useMediaQueriesForTextareaTitleWidth } from 'hooks/useMediaQueriesForContentTitleWIdth';
import { modularScale } from 'utils/modular_scale';

export const TITLE_TEXTAREA_MAX_WIDTH = '500px';
export const TITLE_TEXTAREA_FONT_WEIGHT = 700;
export const TITLE_TEXTAREA_LINE_HEIGHT = '30px';
const TITLE_FONT_SIZE = 2;

interface AboutTitleProps {
    isEditMode: boolean;
    title: string;
    fontSize?: number;
    onSave: (newTitle: string) => void;
}

export const AboutTitle: React.FC<AboutTitleProps> = ({ isEditMode, title, fontSize = TITLE_FONT_SIZE, onSave }) => {
    const textAreaWidth = useMediaQueriesForTextareaTitleWidth();

    return (
        <EditableTextArea
            canEdit={isEditMode}
            initialContent={title}
            buttonAriaLabel={'edit title'}
            saveContent={onSave}
            validation={(content) => !!content}
            styleProps={{
                maxWidth: TITLE_TEXTAREA_MAX_WIDTH,
                width: textAreaWidth,
                fontSize: modularScale(fontSize),
                fontWeight: TITLE_TEXTAREA_FONT_WEIGHT,
                lineHeight: TITLE_TEXTAREA_LINE_HEIGHT
            }}
        />
    );
};
