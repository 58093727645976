import React, { createContext, useContext, useEffect, useState } from 'react';

import { useModal } from 'hooks/useModal';
import AckableAdepteductMessage from 'lib/communication/message/base/AckableAdepteductMessage';
import PossibleEventFields from 'lib/communication/message/type/PossibleEventFields';

import DeleteMessageModal from '../DeleteMessageModal';

export interface ChatContextInterface {
    threadParentId?: string;
    setThreadParentId: React.Dispatch<React.SetStateAction<string | undefined>>;
    setRoomId: React.Dispatch<React.SetStateAction<string | undefined>>;
    setOrganizationId: React.Dispatch<React.SetStateAction<string | undefined>>;
    organizationId?: string;
    roomId?: string;
    openDeleteModal: (message: AckableAdepteductMessage<PossibleEventFields>) => void;
}

export const ChatContext = createContext({} as ChatContextInterface);

const { Provider } = ChatContext;

interface ChatProviderProps {
    organizationId?: string;
    roomId?: string;
}

const ChatProvider: React.FC<ChatProviderProps> = ({
    organizationId: organizationIdProp,
    roomId: roomIdProp,
    children
}) => {
    const [threadParentId, setThreadParentId] = useState<string>();
    const [roomId, setRoomId] = useState(roomIdProp);
    const [organizationId, setOrganizationId] = useState(organizationIdProp);

    const [messageToDelete, setMessageToDelete] = useState<AckableAdepteductMessage<PossibleEventFields>>();
    const { modalOpen, handleModalOpen, handleModalClose } = useModal();

    const closeDeleteModal = () => {
        handleModalClose();
        setMessageToDelete(undefined);
    };

    const openDeleteModal = (message: AckableAdepteductMessage<PossibleEventFields>) => {
        setMessageToDelete(message);
        handleModalOpen();
    };

    useEffect(() => {
        setOrganizationId(organizationIdProp);
    }, [organizationIdProp]);

    useEffect(() => {
        setRoomId(roomIdProp);
    }, [roomIdProp]);

    return (
        <Provider
            value={{
                threadParentId,
                setThreadParentId,
                organizationId,
                setOrganizationId,
                roomId,
                setRoomId,
                openDeleteModal
            }}
        >
            {children}
            <DeleteMessageModal open={modalOpen} onClose={closeDeleteModal} message={messageToDelete} />
        </Provider>
    );
};

const useChatContext = (): ChatContextInterface => {
    const context = useContext(ChatContext);

    if (!context) {
        throw new Error('useChatContext must be used within a ChatProvider');
    }

    return context;
};

export { ChatProvider, useChatContext };
