import React, { useContext, useEffect } from 'react';

import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { SidebarContext } from 'components/SidebarContext';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { SKILL_CONTENT_WIDTH } from 'styles/variables';

import { modularScale } from '../../../../utils/modular_scale';
import { EditorContext } from '../../context/EditorContext';

import { useSkippedStorage } from './hooks/useSkippedStorage';
import How from './How';
import { StoryboardContext } from './StoryboardContext';
import Tutorial from './Tutorial';
import Who from './Who';
import Why from './Why';

function getSteps() {
    return ['Why', 'Who', 'How'];
}

const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
    max-width: ${SKILL_CONTENT_WIDTH}px;
    width: 100%;
    margin: 0 auto;
    padding: 2rem;
`;

const Container = styled.div`
    align-items: center;
    background-color: ${(props) => props.theme.colors.surface};
    border: 1px solid ${(props) => props.theme.colors.border};
    border-radius: 3px;
    padding: 3rem;
`;

const Title = styled.h1`
    font-size: ${modularScale(2)};
    font-weight: 400;
    text-align: center;
`;

const Controls = styled.div`
    margin-top: 3rem;
    text-align: center;
`;

const Skip = styled.span`
    cursor: pointer;
    opacity: 50%;
    color: ${(props) => props.theme.colors.textPrimary};
`;

const Storyboard: React.FC = () => {
    const { step } = useContext(StoryboardContext);
    const { clearEditMode, workingSkill } = useContext(EditorContext);
    const { openSidebar } = useContext(SidebarContext);

    useEffect(() => {
        openSidebar({ isOpen: false });
    }, [openSidebar]);

    const { addSkipForSkill } = useSkippedStorage();

    const history = useHistory();

    const steps = getSteps();

    const renderStep = () => {
        switch (step) {
            case 0:
                return <Why />;
            case 1:
                return <Who />;
            case 2:
                return <How />;
            default:
                return <Tutorial />;
        }
    };

    const skip = () => {
        addSkipForSkill(workingSkill.id);
        history.push({ search: undefined });
        clearEditMode();
    };

    return (
        <Content>
            <Container>
                <Title>Storyboard Your Skill</Title>
                <Stepper activeStep={step} alternativeLabel style={{ marginBottom: '2rem' }}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                {renderStep()}
            </Container>
            <Controls>
                <Skip aria-label="skip storyboard" data-testid="skip-storyboard" onClick={skip}>
                    Skip Storyboard
                </Skip>
            </Controls>
        </Content>
    );
};

const StoryboardWithProvider: React.FC = () => <Storyboard />;

export default StoryboardWithProvider;
