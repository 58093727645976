import React from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { mdiCheck } from '@mdi/js';
import Icon from '@mdi/react';

import { StyledTableCell } from './TableCell.styles';

interface LikedCellProps {
    liked?: boolean;
}

export const LIKED_CELL_WIDTH = 100;

export const LikedCell: React.FC<LikedCellProps> = ({ liked }) => {
    return (
        <StyledTableCell width={LIKED_CELL_WIDTH}>
            {liked ? <Icon path={mdiCheck} size={IconSize.Medium} /> : null}
        </StyledTableCell>
    );
};
