import React from 'react';

import { LinkComponentValues, LinkViewer } from '@adept-at/lib-react-components';
import { EntityInfo } from 'components/ContentContext/Interfaces';

import { useGenerateLinkOnClick } from './hooks/useGenerateLinkOnclick';

export interface LinkComponentProps {
    componentValues: LinkComponentValues;
    container: EntityInfo;
}

export const LinkView: React.FC<LinkComponentProps> = ({ componentValues, container, children }) => {
    const onClick = useGenerateLinkOnClick(componentValues, container);

    return (
        <LinkViewer
            aria-label={`Link to ${componentValues.linkType}`}
            componentValues={componentValues}
            onClick={onClick}
        >
            {children}
        </LinkViewer>
    );
};
