import { useReducer } from 'react';

import { UserSearchOption, UserSearchOptionType } from './types';

export enum OptionsReducerActionType {
    Clear,
    Replace,
    Add,
    UpdateDetails
}

interface ClearAction {
    type: OptionsReducerActionType.Clear;
}

interface AddAction {
    type: OptionsReducerActionType.Add;
    option: UserSearchOption;
}

interface ReplaceAction {
    type: OptionsReducerActionType.Replace;
    option: UserSearchOption;
}

interface UpdateDetailsAction {
    type: OptionsReducerActionType.UpdateDetails;
    details: Record<string, any>;
}

export type Action = ReplaceAction | AddAction | UpdateDetailsAction | ClearAction;

// @TODO ShareRecipientOption should be a generic type so we can use this without details

const optionsReducer = (
    options: Record<string, UserSearchOption>,
    action: ReplaceAction | AddAction | UpdateDetailsAction | ClearAction
): Record<string, UserSearchOption> => {
    switch (action.type) {
        case OptionsReducerActionType.Clear:
            return {};

        case OptionsReducerActionType.Replace:
            return {
                [action.option.id]: action.option
            };

        case OptionsReducerActionType.Add:
            options[action.option.id] = action.option;
            return options;

        case OptionsReducerActionType.UpdateDetails:
            const newUserDetails = action.details;
            const newUserDetailsIds = Object.keys(newUserDetails);

            return Object.entries(options).reduce((memo, [optionId, option]) => {
                const { type, id } = option;

                if (type !== UserSearchOptionType.UserId || !newUserDetailsIds.includes(id)) {
                    return {
                        ...memo,
                        [optionId]: option
                    };
                }

                return {
                    ...memo,
                    [optionId]: {
                        ...option,
                        details: newUserDetails[id] ?? {}
                    }
                };
            }, {});

        default:
            return options;
    }
};

export const useUserSearchOptions = (
    initialOptions: Record<string, UserSearchOption> = {}
): [Record<string, UserSearchOption>, React.Dispatch<Action>] => {
    return useReducer(optionsReducer, initialOptions);
};
