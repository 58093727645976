import React, { createContext, useCallback, useEffect, useMemo, useRef } from 'react';

import TagManager, { TagManagerArgs } from 'react-gtm-module';
import { useLocation, useParams } from 'react-router-dom';

/** May need to become an env variable someday for different envs */
const GOOGLE_TAG_MANAGER_ID = 'GTM-K7Q3ZML';
const ANALYTICS_APP = 'learn-webapp';

export interface AnalyticsTagTrackingContextOutput {
    sendAnalyticsData: (dataLayerParams: Record<string, string | number | boolean | Record<string, string>>) => void;
}

const AnalyticsTagTrackingContext = createContext<AnalyticsTagTrackingContextOutput>(
    undefined as unknown as AnalyticsTagTrackingContextOutput
);

/** Do not useState or Reducer in this provider, we don't want to re-render the whole app. */
export const AnalyticsTagTrackingProvider: React.FC = ({ children }) => {
    const isInitialized = useRef(false);
    const location = useLocation();
    const routerParams = useParams();

    const initArgs: TagManagerArgs = useMemo(
        () => ({
            gtmId: GOOGLE_TAG_MANAGER_ID,
            dataLayer: {
                nodeEnv: process.env.NODE_ENV,
                webapp: ANALYTICS_APP,
                reactRouterParams: routerParams,
                reactRouterPathname: location.pathname,
                reactRouterQuery: location.search
            }
        }),
        [location, routerParams]
    );

    useEffect(() => {
        if (process.env.NODE_ENV === 'production' && !isInitialized.current) {
            TagManager.initialize(initArgs);
            isInitialized.current = true;
        }
    }, [initArgs]);

    const sendAnalyticsData = useCallback(
        (dataLayerParams: Record<string, string | number | boolean | Record<string, string>>): void => {
            if (dataLayerParams) {
                TagManager.dataLayer(dataLayerParams);
            }
        },
        []
    );

    return (
        <AnalyticsTagTrackingContext.Provider value={{ sendAnalyticsData }}>
            {children}
        </AnalyticsTagTrackingContext.Provider>
    );
};

export default AnalyticsTagTrackingContext;
