import React from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { InputAdornment, TextField } from '@material-ui/core';
import { mdiCurrencyUsd, mdiPlus, mdiMinus } from '@mdi/js';
import Icon from '@mdi/react';

import { useEnrollmentPriceValue } from './hooks/useEnrollmentPriceValue';
import { PriceFieldEndAdornmentButton } from './PriceFieldEndAdornmentButton';

interface PriceFieldProps {
    label?: string;
    minimumValue?: number;
    initialValue?: number;
    enabled?: boolean;
    onValueUpdated?: (value: number) => void;
}

export const PriceField: React.FC<PriceFieldProps> = ({
    label = 'Price',
    minimumValue,
    initialValue,
    enabled = true,
    onValueUpdated = () => {}
}) => {
    const { onChange, formattedValue, onIncrease, onDecrease, onFocus, onBlur } = useEnrollmentPriceValue({
        initialValue,
        minimumValue,
        onValueUpdated
    });

    return (
        <TextField
            variant="outlined"
            label={label}
            value={formattedValue}
            fullWidth
            disabled={!enabled}
            InputProps={{
                onChange,
                onFocus,
                onBlur,
                startAdornment: (
                    <InputAdornment position="start">
                        <Icon path={mdiCurrencyUsd} size={IconSize.Medium} />
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        <PriceFieldEndAdornmentButton
                            enabled={enabled}
                            tooltipText="-1"
                            onClick={onDecrease}
                            iconPath={mdiMinus}
                        />
                        <PriceFieldEndAdornmentButton
                            enabled={enabled}
                            tooltipText="+1"
                            onClick={onIncrease}
                            iconPath={mdiPlus}
                        />
                    </InputAdornment>
                )
            }}
        />
    );
};
