import { useMemo, useContext } from 'react';

import { EditorContext } from 'components/builder/context/EditorContext';
import { OwnerType, OwnerInfo } from 'components/ContentContext/Interfaces';
import { LearnContext } from 'components/learn/LearnContext';
import { NestedCollectionContext } from 'components/NestedCollection/context/NestedCollectionContext';
import { RootCollectionContext } from 'components/RootProfileCollection/context/RootCollectionContext';
import { ProfileContext, ProfileType } from 'context/ProfileContext';

export const useAssetOwner = (): OwnerInfo | null => {
    const learnCtx = useContext(LearnContext);
    const editCtx = useContext(EditorContext);
    const rootCollectionCtx = useContext(RootCollectionContext);
    const nestedCollectionCtx = useContext(NestedCollectionContext);
    const profileCtx = useContext(ProfileContext);

    const owner = useMemo(() => {
        const ownerInfo = learnCtx?.skill?.owner ?? editCtx?.workingSkill?.owner ?? nestedCollectionCtx?.ownerInfo;

        if (ownerInfo) {
            return ownerInfo;
        }

        const rootCollectionInfo = rootCollectionCtx?.ownerId
            ? { type: OwnerType.Organization, id: rootCollectionCtx.ownerId }
            : null;

        if (rootCollectionInfo) {
            return rootCollectionInfo;
        }

        const profileInfo = profileCtx.currentProfile
            ? {
                  id: profileCtx.currentProfile.id,
                  type:
                      profileCtx.currentProfile.type === ProfileType.Personal ? OwnerType.User : OwnerType.Organization
              }
            : null;

        return profileInfo;
    }, [
        learnCtx?.skill?.owner,
        editCtx?.workingSkill?.owner,
        nestedCollectionCtx?.ownerInfo,
        rootCollectionCtx?.ownerId,
        profileCtx?.currentProfile
    ]);

    return owner;
};
