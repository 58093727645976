import React, { ReactElement } from 'react';

import { Button } from '@material-ui/core';
import styled from 'styled-components';

const StyledButton = styled(Button)<{ $isActive?: boolean }>`
    color: ${(props) => (props?.$isActive ? props.theme.colors.primary : props.theme.colors.textSecondary)};
    border-color: ${(props) => (props?.$isActive ? props.theme.colors.primary : props.theme.colors.border)};
    margin-left: 0.5rem;
    border-radius: 1rem;
    font-size: 1rem;
    height: 2rem;
    min-width: fit-content;
    margin-top: 0.5rem;
`;

export const ProgressFilterButton = ({
    label,
    ariaLabel,
    isActive,
    onClick,
    value
}: {
    label: string;
    ariaLabel: string;
    isActive: boolean;
    onClick: () => void;
    value: number;
}): ReactElement => (
    <StyledButton aria-label={ariaLabel} $isActive={isActive} onClick={onClick} variant="outlined" value={value}>
        {label}
    </StyledButton>
);
