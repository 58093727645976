import { gql, GraphQLClient } from 'graphql-request';
import { QueryFunction } from 'react-query';

import buildGqlQueryFn from '../../../hooks/useGqlClient/helpers/buildGqlQueryFn';
import { Assessment } from '../types';

export interface GetAssessmentByIdResponse {
    getAssessmentById: Assessment;
}

const QUERY_GET_ASSESSMENT_BY_ID = gql`
    query getAssessmentById($assessmentId: UUIDv4!) {
        getAssessmentById(assessmentId: $assessmentId) {
            assessmentId
            title
            description
            organizationId
            updatedAt
            createdAt
            ownedBy
            owner {
                type
                id
            }
            components
        }
    }
`;

const getAssessmentById = (
    client: GraphQLClient,
    variables: { assessmentId: string }
): QueryFunction<GetAssessmentByIdResponse> =>
    buildGqlQueryFn<GetAssessmentByIdResponse>(client, QUERY_GET_ASSESSMENT_BY_ID, variables);

export default getAssessmentById;
