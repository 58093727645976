import React, { useState } from 'react';

import { modularScale } from '@adept-at/lib-react-components';
import { TextField, Button } from '@material-ui/core';
import useMessageActions from 'hooks/communication/useMessageActions';
import AckableAdepteductMessage from 'lib/communication/message/base/AckableAdepteductMessage';
import { MessageEntityTypeFromStandard } from 'lib/communication/message/MessageRecord';
import PossibleEventFields from 'lib/communication/message/type/PossibleEventFields';
import styled from 'styled-components';

import useEditMessage from './useEditMessage';

const ButtonContainer = styled.div`
    margin-left: auto;
`;

const StyledTextField = styled(TextField)`
    > div {
        font-size: ${modularScale(-0.5)};
    }
`;

interface EditMessageFormProps {
    message: AckableAdepteductMessage<PossibleEventFields>;
    stopEditing: () => void;
}

const EditMessageForm: React.FC<EditMessageFormProps> = ({ message, stopEditing }) => {
    const { mutate: editMessage } = useEditMessage();
    const [messageText, setMessageText] = useState(message.fields.text ?? '');
    const { updateMessage } = useMessageActions();

    const submit = () => {
        if (!messageText) {
            // TODO: prompt delete message
            return;
        }

        if (messageText !== message.fields.text) {
            editMessage({
                entityType: MessageEntityTypeFromStandard[message.entityType],
                entityId: message.entityId,
                messageId: message.id,
                text: messageText,
                hasParent: !!message.parent
            });

            updateMessage({ channelId: message.entityId, messageId: message.id, updatedText: messageText });
        }

        stopEditing();
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>): void => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            submit();
        }
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMessageText(e.target.value);
    };

    return (
        <>
            <StyledTextField
                autoFocus
                variant="outlined"
                multiline
                maxRows={15}
                size="small"
                placeholder="Edit message"
                value={messageText}
                onKeyDown={handleKeyDown}
                onChange={onChange}
                onFocus={(e) =>
                    // set cursor to end of text
                    e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)
                }
            />
            <ButtonContainer>
                <Button size="small" color="primary" onClick={stopEditing}>
                    Cancel
                </Button>
                <Button size="small" color="primary" onClick={submit}>
                    Save changes
                </Button>
            </ButtonContainer>
        </>
    );
};

export default EditMessageForm;
