import React from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { mdiFire, mdiHand } from '@mdi/js';
import Icon from '@mdi/react';
import PossibleEventFields from 'lib/communication/message/type/PossibleEventFields';
import styled from 'styled-components';

import AdepteductMessageProps from '../AdepteductMessageProps';

const StyledIcon = styled(Icon)`
    color: orange;
`;

const StyledIcon2 = styled(Icon)`
    color: blue;
`;

export interface ReactionEventFields extends PossibleEventFields {
    reaction?: string;
}

const Reaction = React.forwardRef<HTMLDivElement | null, AdepteductMessageProps<ReactionEventFields>>(
    ({ message }, ref) => {
        const {
            fields: { reaction }
        } = message;

        const renderReactionIcon = () => {
            return reaction === 'fire' ? (
                <StyledIcon path={mdiFire} size={IconSize.Medium} />
            ) : (
                <StyledIcon2 path={mdiHand} size={IconSize.Medium} />
            );
        };

        return <div ref={ref}>{renderReactionIcon()}</div>;
    }
);

export default Reaction;
