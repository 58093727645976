import React, { useCallback, useEffect, useState } from 'react';

import { OwnerType } from 'components/ContentContext/Interfaces';
import { getEntityProgressStatus } from 'components/learn/modes/accountability/utils';
import AddNestedCollections from 'components/RootProfileCollection/AddNestedCollections';
import { ContentCardsWrapper } from 'components/RootProfileCollection/Collections.styles';
import { CollectionChild } from 'components/RootProfileCollection/context/useGetTenantProfileForOwner';
import { NestedCollectionCard } from 'components/RootProfileCollection/NestedCollectionCard';
import { GetEditableCollectionResponse } from 'components/RootProfileCollection/useGetEditableCollection';
import { CollectionBasicDetail } from 'components/RootProfileCollection/useGetNestedCollections/getCollectionBasicDetails';
import useUpsertCollectionItem from 'components/RootProfileCollection/useUpsertCollectionItem';
import { OutlineProgress, ProgressContainer } from 'components/skills/OutlineProgress';
import { ClientError } from 'graphql-request';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { QueryObserverResult, QueryStatus, RefetchOptions } from 'react-query';
import { useLocation, useParams } from 'react-router';

import { AddToCollection } from '../AddToCollection';
import CollectionContentLoadingCards from '../CollectionContentLoadingCards';
import { useCollectionSlugs } from '../hooks/useCollectionSlugs';
import { BasicDetailsSkillResponse } from '../useGetEntitiesBasicDetails/getEntitiesBasicDetails';

import { ContentSectionCard } from './ContentSectionCard';
import { EmptyContentState } from './EmptyContentState';

export interface ContentSectionProps {
    isNestedCollection: boolean;
    rootProfileType?: OwnerType;
    childrenBasicDetails: (BasicDetailsSkillResponse | CollectionBasicDetail | undefined)[];
    getChildrenBasicDetailsStatus: QueryStatus;
    isEditMode: boolean;
    tenantSlug: string;
    collectionSlug?: string;
    collectionId: string;
    refetchEditableCollection: (
        options?: RefetchOptions
    ) => Promise<QueryObserverResult<GetEditableCollectionResponse, ClientError>>;
    parentQuerySlug: string;
    collectionIdForEditableCollectionQuery: string;
}

// TODO: add in labs etc. once they exist
export const ContentSection: React.FC<ContentSectionProps> = ({
    isNestedCollection,
    rootProfileType,
    childrenBasicDetails,
    getChildrenBasicDetailsStatus,
    isEditMode,
    tenantSlug,
    collectionSlug,
    collectionId,
    refetchEditableCollection,
    parentQuerySlug,
    collectionIdForEditableCollectionQuery
}) => {
    const { hasParentCollection } = useCollectionSlugs();

    const { skillSlug } = useParams<{ skillSlug: string }>();

    const { mutate: mutateCollectionChildrenOrder } = useUpsertCollectionItem(
        collectionId,
        refetchEditableCollection,
        collectionIdForEditableCollectionQuery,
        parentQuerySlug
    );

    const [collectionChildren, setCollectionChildren] = useState<
        (BasicDetailsSkillResponse | CollectionBasicDetail | undefined)[]
    >([]);

    useEffect(() => {
        if (childrenBasicDetails) setCollectionChildren(childrenBasicDetails);
    }, [childrenBasicDetails]);

    const location = useLocation();

    const formatLinkUrl = useCallback(
        (collectionSlug: string | null) => {
            // insure user can't click away from page until the collection slug comes in response
            if (!collectionSlug || !tenantSlug) return '#';

            if (rootProfileType) {
                return `${
                    rootProfileType === OwnerType.Organization ? '' : '/my-profile'
                }/${tenantSlug}/${collectionSlug}`;
            }

            return `${location.pathname}/${collectionSlug}`;
        },
        [tenantSlug, location.pathname, rootProfileType]
    );

    const onDragEnd = (result: DropResult) => {
        const { destination, source, draggableId } = result;
        if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index))
            return;

        const reordered: (BasicDetailsSkillResponse | CollectionBasicDetail | undefined)[] = reorder(
            collectionChildren,
            source.index,
            destination.index
        );

        // save state on frontend
        setCollectionChildren(reordered);

        const [childId, childType] = draggableId.split(',');

        // mutate backend
        mutateCollectionChildrenOrder({
            collectionId,
            item: {
                type: childType as CollectionChild,
                id: childId
            },
            order: destination.index
        });
    };

    const reorder = (
        list: (BasicDetailsSkillResponse | CollectionBasicDetail | undefined)[],
        startIndex: number,
        endIndex: number
    ): (BasicDetailsSkillResponse | CollectionBasicDetail | undefined)[] => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    if (getChildrenBasicDetailsStatus === 'loading') {
        return <CollectionContentLoadingCards />;
    }

    if (isEditMode) {
        if (collectionChildren.length === 0) {
            return (
                <ContentCardsWrapper $viewingInCollection>
                    <EmptyContentState isNestedCollection={isNestedCollection} />
                </ContentCardsWrapper>
            );
        }

        return (
            <ContentCardsWrapper $hasParentCollection={hasParentCollection} $viewingInCollection>
                {isNestedCollection ? <AddToCollection /> : <AddNestedCollections />}
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId={'droppable-content'}>
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {collectionChildren.map((child, index) => (
                                    <Draggable
                                        key={
                                            (child as BasicDetailsSkillResponse)?.skillId
                                                ? (child as BasicDetailsSkillResponse)?.skillId
                                                : (child as CollectionBasicDetail)?.collectionId
                                        }
                                        draggableId={
                                            (child as BasicDetailsSkillResponse)?.skillId
                                                ? `${(child as BasicDetailsSkillResponse)?.skillId},${
                                                      CollectionChild.Skill
                                                  }`
                                                : `${(child as CollectionBasicDetail)?.collectionId},${
                                                      CollectionChild.Collection
                                                  }`
                                        }
                                        index={index}
                                    >
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                {(child as BasicDetailsSkillResponse)?.skillId ? (
                                                    <ContentSectionCard
                                                        key={index}
                                                        index={index}
                                                        entity={child as BasicDetailsSkillResponse}
                                                    />
                                                ) : (
                                                    <NestedCollectionCard
                                                        key={index}
                                                        collection={child as CollectionBasicDetail}
                                                        parentCollectionId={collectionId}
                                                        parentSlug={collectionSlug}
                                                        linkUrl={formatLinkUrl(
                                                            (child as CollectionBasicDetail)?.collectionSlug
                                                        )}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </ContentCardsWrapper>
        );
    }

    return (
        <ContentCardsWrapper $hasParentCollection={hasParentCollection} $viewingInCollection={!skillSlug} $padding>
            {collectionChildren.map((child, index) => {
                return (
                    <ProgressContainer key={index}>
                        <OutlineProgress
                            isFirst={index === 0}
                            isLast={index === collectionChildren.length - 1}
                            status={getEntityProgressStatus(child)}
                        />

                        {(child as BasicDetailsSkillResponse)?.skillId ? (
                            <ContentSectionCard
                                index={index}
                                entity={child as BasicDetailsSkillResponse}
                                isActiveSkill={skillSlug === (child as BasicDetailsSkillResponse)?.skillSlug}
                            />
                        ) : (
                            <NestedCollectionCard
                                collection={child as CollectionBasicDetail}
                                parentCollectionId={collectionId}
                                parentSlug={collectionSlug}
                                linkUrl={formatLinkUrl((child as CollectionBasicDetail)?.collectionSlug)}
                            />
                        )}
                    </ProgressContainer>
                );
            })}
        </ContentCardsWrapper>
    );
};
