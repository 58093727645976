import { useContext, useEffect } from 'react';

import { OptimistRegisterUpdaterOptions, OptimistUpdateCallback } from '@adept-at/lib-react-optimist';
import { WorkflowStatus } from 'components/CollaborationTable';
import { ClientError } from 'graphql-request';
import { QueryKey } from 'react-query';

import { GetCollectionChildrenDetailsResponse } from '../collection/getCollectionChildrenDetails';
import { StudioCollectionContext } from '../collection/StudioCollectionContext';

interface WorkflowProps {
    props: {
        skillId?: string;
        collectionId?: string;
        workflow: keyof typeof WorkflowStatus;
    };
    result: {
        updateSkillMeta?: {
            success: boolean;
        };
        updateCollectionWorkflow?: {
            success: boolean;
        };
    };
}

export const useOptimistForEntityWorkflowUpdates = <TWorkflow extends WorkflowProps>(
    registerUpdater: <TPrevious>(
        queryKey: QueryKey,
        updateCallback: OptimistUpdateCallback<TWorkflow['props'], TPrevious>,
        options?: OptimistRegisterUpdaterOptions<TWorkflow['result'], ClientError, TWorkflow['props']> | undefined
    ) => void
): void => {
    const studioCollectionContext = useContext(StudioCollectionContext);

    useEffect(() => {
        if (studioCollectionContext?.contentQueryKey) {
            registerUpdater<GetCollectionChildrenDetailsResponse>(
                studioCollectionContext?.contentQueryKey,
                (previous, variables) => {
                    const updatedDetails = (previous?.getEntityBasicDetails ?? []).map((details) => {
                        if ('skillId' in details && details.skillId === variables.skillId) {
                            return {
                                ...details,
                                workflow: variables.workflow
                            };
                        }
                        if ('collectionId' in details && details.collectionId === variables.collectionId) {
                            return {
                                ...details,
                                workflow: variables.workflow
                            };
                        }
                        return details;
                    });

                    return {
                        ...previous,
                        getEntityBasicDetails: updatedDetails
                    };
                },
                { refetchQuery: false }
            );
        }
    }, [studioCollectionContext?.contentQueryKey, registerUpdater]);
};
