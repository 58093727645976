import { CollectionChild } from 'components/RootProfileCollection/context/useGetTenantProfileForOwner';
import { GraphQLClient } from 'graphql-request';
import gql from 'graphql-tag';
import buildGqlQueryFn from 'hooks/useGqlClient/helpers/buildGqlQueryFn';
import { QueryFunction, QueryKey } from 'react-query';

export const buildGetContentBasicDetailsQueryKey = (entities: CollectionChildEntity[]): QueryKey => [
    'getContentBasicDetails',
    entities
];

export const getContentBasicDetailsQuery = gql`
    query getEntityBasicDetails($entities: [EntityInfoInput]!) {
        getEntityBasicDetails(entities: $entities) {
            ... on SkillBasicDetail {
                skillId
                title
                skillSlug
            }
            ... on CollectionBasicDetail {
                collectionId
                title
                collectionSlug
            }
        }
    }
`;

interface CollectionChildEntity {
    type: CollectionChild;
    id: string;
}

export interface SkillBasicDetail {
    skillId: string;
    skillSlug: string;
    title: string;
}

interface CollectionBasicDetail {
    collectionId: string;
    collectionSlug: string;
    title: string;
}

export type ContentBasicDetail = SkillBasicDetail | CollectionBasicDetail;

export interface GetContentBasicDetailsResponse {
    getEntityBasicDetails: ContentBasicDetail[];
}

const getContentBasicDetails = (
    client: GraphQLClient,
    entities: CollectionChildEntity[]
): QueryFunction<GetContentBasicDetailsResponse> => buildGqlQueryFn(client, getContentBasicDetailsQuery, { entities });

export default getContentBasicDetails;
