import { useContext, useMemo } from 'react';

import { OwnerType } from 'components/ContentContext/Interfaces';
import { useGetSkillCreations } from 'components/creations2/useGetSkillCreations';

import { NestedCollectionContext } from '../context/NestedCollectionContext';
import { BasicDetailsSkillResponse } from '../useGetEntitiesBasicDetails/getEntitiesBasicDetails';

import { useCollaborationSkills } from './useCollaborationSkills';

interface UseAvailableSkillsOutput {
    availableSkills:
        | {
              title: string;
              id: string;
          }[]
        | undefined;
    isLoading: boolean;
}

export const useAvailableSkillOptions = (): UseAvailableSkillsOutput => {
    const { ownerInfo, childrenBasicDetails } = useContext(NestedCollectionContext);

    const collectionSkillIds = childrenBasicDetails.flatMap((child) => {
        if (child?.hasOwnProperty('skillId')) return (child as BasicDetailsSkillResponse).skillId;
    });

    const orgId = useMemo(() => (ownerInfo?.type === OwnerType.Organization ? ownerInfo.id : undefined), [ownerInfo]);

    const { data: userSkillCreations, status: getAvailableSkillsStatus } = useGetSkillCreations();
    const { collaborationSkills, status: getCollaborationSkillsStatus } = useCollaborationSkills(orgId);

    const isLoading = useMemo(
        () => getAvailableSkillsStatus === 'loading' || getCollaborationSkillsStatus === 'loading',
        [getAvailableSkillsStatus, getCollaborationSkillsStatus]
    );

    const formattedAvailableSkills = useMemo(
        () =>
            ownerInfo?.type === OwnerType.User
                ? userSkillCreations?.getSkills
                      .filter((skill) => !collectionSkillIds.includes(skill.skillId))
                      .map((skill) => ({ title: skill.title, id: skill.skillId }))
                : collaborationSkills
                      .filter((skill) => !collectionSkillIds.includes(skill.item.skillId))
                      .map((skill) => ({ title: skill.item.title, id: skill.item.skillId })),
        [userSkillCreations, collectionSkillIds, ownerInfo, collaborationSkills]
    );

    return { availableSkills: formattedAvailableSkills, isLoading };
};
