import React from 'react';

import { CodeComponentValues } from '@adept-at/lib-react-components';

import { useComponentEngine } from '../../EngineContext';
import { EditableComponentProvider } from '../../mixins/editor';
import { ComponentEngineMode } from '../../types';

import CodeEdit from './Edit';
import CodeView from './View';

interface CodeComponentProps {
    id: string;
    order: number;
}

export const emptyCodeComponentValues = {
    codeBody: '',
    language: undefined
};

export const Code: React.FC<CodeComponentProps & CodeComponentValues> = ({ id, order, ...componentValues }) => {
    const { mode } = useComponentEngine();

    if (mode === ComponentEngineMode.Edit) {
        return (
            <EditableComponentProvider initialValues={componentValues}>
                <CodeEdit />
            </EditableComponentProvider>
        );
    }
    return <CodeView {...componentValues} />;
};

export const title = 'Code';

export const Component = Code;
