import { CollaborativeSkill } from 'components/CollaborationTable';
import { ClientError, gql } from 'graphql-request';
import { useBuildAuthenticatedQueryFn } from 'hooks/useGqlClient/useBuildAuthenticatedQueryFn';
import { Api } from 'lib/ApiConstants';
import { useQuery, UseQueryResult } from 'react-query';

export interface GetSkillCreationsResponse {
    getSkills: CollaborativeSkill[];
}

export const queryCacheKey = 'getSkillCreations';

// @GRAPHQL Query getSkill
export const queryName = 'getSkill';

const GET_SKILL_CREATIONS_QUERY = gql`
    query getSkills($ownerList: [OwnerInfo], $includeArchived: Boolean) {
        getSkills(ownerList: $ownerList, includeArchived: $includeArchived) {
            skillId
            title
            createdAt
            updatedAt
            skillSlug
            tenantSlug
            views
            estimatedSecondsToConsume
            canArchive
            owner {
                type
                id
            }
            archived
            reportStats {
                allTime {
                    likeCount
                }
            }
            comments {
                public {
                    commentId
                }
            }
        }
    }
`;

interface UseGetSkillCreationsVariables {
    includeArchived: boolean;
}

export const useGetSkillCreations = (
    variables: Partial<UseGetSkillCreationsVariables> = {},
    options = {}
): UseQueryResult<GetSkillCreationsResponse, ClientError> => {
    const { queryFn } = useBuildAuthenticatedQueryFn<GetSkillCreationsResponse, UseGetSkillCreationsVariables>(
        GET_SKILL_CREATIONS_QUERY,
        { includeArchived: true, ...variables },
        { api: Api.Content }
    );

    return useQuery<GetSkillCreationsResponse, ClientError>(queryCacheKey, queryFn, options);
};
