import { EntityType } from 'components/ContentContext/Enums';
import { EntityInfo, EntityInfoInput } from 'components/ContentContext/Interfaces';

/* Actions are duplicated from Backend and should be kept "up to date". */
export enum SkillActions {
    Create = 'Create',
    Read = 'Read',
    Update = 'Update',
    Delete = 'Delete',
    Comment = 'Comment',
    TransferOwnership = 'TransferOwnership',
    GrantAccess = 'GrantAccess'
}

export enum CollectionActions {
    Read = 'Read',
    Create = 'Create',
    Delete = 'Delete',
    UpdateMetadata = 'UpdateMetadata',
    UpsertItem = 'UpsertItem',
    RemoveItem = 'RemoveItem',
    CommitEdits = 'CommitEdits',
    DiscardEdits = 'DiscardEdits',
    Enroll = 'Enroll'
}

export enum OrganizationActions {
    Read = 'Read',
    Update = 'Update',
    Delete = 'Delete',
    ListMembers = 'ListMembers',
    Create = 'Create'
}

export const actionsForEntityMap = {
    [EntityType.Skill]: SkillActions,
    [EntityType.Collection]: CollectionActions,
    [EntityType.Organization]: OrganizationActions
};

export type ValidEntityAction = OrganizationActions | CollectionActions | SkillActions;

export interface AuthorizedActionsMap {
    [EntityType.Skill]?: SkillActions[];
    [EntityType.COLLECTION]?: CollectionActions[];
    [EntityType.Organization]?: OrganizationActions[];
}

export interface UseAuthorizedActionsOutput {
    entity: EntityInfo;
    actions: SkillActions | CollectionActions | OrganizationActions;
}

export interface AuthorizedActionsRequest {
    entity: EntityInfoInput;
}

export interface ContentEntitiesResponse {
    [EntityType.Skill]: SkillActions[];
    [EntityType.COLLECTION]: CollectionActions[];
}

// Sub collection of all the Entity Types Authorization is applied to.
export const CONTENT_ENTITIES = [EntityType.Skill, EntityType.COLLECTION];
export const ACCOUNTS_ENTITIES = [EntityType.Organization];
