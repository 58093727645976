import React, { ReactElement } from 'react';

import { useAuth, AuthStatus } from 'context/AuthContext';
import { useLastKnownProfile } from 'context/ProfileContext/useLastKnownProfile';
import { Route, Redirect, RouteProps } from 'react-router';

import AppLoading from '../AppLoading';

const ProtectedRoute = <T extends RouteProps = RouteProps>({ ...props }: T): ReactElement => {
    const { status, isAuthenticating, isAuthenticated } = useAuth();
    const { lastKnownProfile } = useLastKnownProfile();

    /**
     * @TODO Each of these states should have a timeout so we don't end up staring at them indefinitely in the case of
     * an error
     */

    if (status === AuthStatus.Initial) {
        return <AppLoading text={'Initializing...'} />;
    }

    if (isAuthenticating) {
        return <AppLoading text={'Loading...'} />;
    }

    /**
     * All of the following must be true in order to display the "Loading Profile..." message:
     *
     * 1. User is logged in.
     * 2. We have never loaded a profile since page loaded (or they submitted a login)
     * 3. The Auth is not in a state of Reauthorizing, which today only means a session refresh.
     */
    if (isAuthenticated && !lastKnownProfile && status !== AuthStatus.Reauthenticating) {
        return <AppLoading text={'Loading Profile...'} />;
    }

    if (isAuthenticated && lastKnownProfile) {
        return <Route {...props} />;
    }

    return (
        <Redirect
            to={{
                pathname: '/login',
                state: { from: `${props?.location?.pathname}${props?.location?.search}` }
            }}
        />
    );
};

export default ProtectedRoute;
