import { useMemo } from 'react';

import { GroupType } from 'context/OrganizationContext';
import { gql, ClientError } from 'graphql-request';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useBuildAuthenticatedQueryFn } from 'hooks/useGqlClient/useBuildAuthenticatedQueryFn';
import { Api } from 'lib/ApiConstants';
import { QueryKey, useQuery, UseQueryResult } from 'react-query';
import { isTrue } from 'utils/isTrue';

// @GRAPHQL Query getGroupsForUser
const queryName = 'getGroupsForUser';

// @TODO this is used by Optimist in useSetGroupVisited...
export const buildGetGroupsForUserQueryKey = (userId?: string): QueryKey => ['getGroupsForUser', userId];

const GET_GROUPS_FOR_USER_QUERY = gql`
    query ${queryName} {
        ${queryName} {
            orgId
            id
            name
            visitedAt
            createdAt
            members
            type
            pinned
        }
    }
`;

export interface GroupForUser {
    orgId: string;
    id: string;
    name: string;
    members: string[];
    visitedAt: string;
    createdAt: string;
    type: GroupType;
    pinned: boolean;
}

export interface GetGroupsForUserResult {
    [queryName]: GroupForUser[];
}

export const useGetGroupsForUser = (): UseQueryResult<GetGroupsForUserResult, ClientError> => {
    // @TODO queries that wish for auth information to be part of the queryKey should somehow be formed within useBuildAuthenticatedQueryFn
    const { currentUser } = useCurrentUser();

    const { queryFn } = useBuildAuthenticatedQueryFn<GetGroupsForUserResult>(
        GET_GROUPS_FOR_USER_QUERY,
        {},
        { api: Api.Accounts }
    );

    const queryKey = useMemo(() => [queryName, currentUser?.userId], [currentUser]);

    return useQuery<GetGroupsForUserResult, ClientError>(queryKey, {
        retry: false, // @FIXME this should be retryable
        enabled: isTrue(currentUser?.userId),
        queryFn
    });
};
