import { GraphQLClient } from 'graphql-request';
import { RequestDocument } from 'graphql-request/dist/types';
import { QueryKey, QueryFunctionContext, QueryFunction } from 'react-query';

/**
 * This is a function that returns another function, the exact format react-query needs.
 * Nearly the entire purpose of this is to handle the authenticated GraphQLClient, and the pageParamKey, and hopefully makes the double/triple function easier to grok.
 *
 * @param client This should be the authenticated client from this hook, not a standard client, in nearly all cases.
 * @param query @see RequestDocument , usually created by graphql-tag.
 * @param variables Any NON-page specific variables to add to the request.
 * @param pageParamKey This is the name or key of the variable that controls pagination in the query.
 * In this example, it is "fromMessageId":
 * query getMessagesForChannel($channelId: UUIDv4!, $fromMessageId: String) {
 *  getMessagesForChannel(channelId: $channelId, fromMessageId: $fromMessageId) {
 */
const buildGqlInfiniteQueryFn = <
    TQueryFnData = unknown,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    TPageParam = any,
    TVariables = unknown,
    TQueryKey extends QueryKey = QueryKey
>(
    client: GraphQLClient,
    query: RequestDocument,
    variables: TVariables,
    pageParamKey: string
): QueryFunction<TQueryFnData> => {
    const infiniteQueryFn = async ({
        pageParam
    }: QueryFunctionContext<TQueryKey, TPageParam>): Promise<TQueryFnData> => {
        return await client.request<TQueryFnData>(query, {
            ...variables,
            [pageParamKey]: pageParam
        });
    };

    return infiniteQueryFn;
};

export default buildGqlInfiniteQueryFn;
