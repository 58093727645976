import React, { useState, useContext, useEffect } from 'react';

import { Button } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import styled from 'styled-components';

import { EditorContext, componentEditModes, EditMode } from './context/EditorContext';
import ModalContents from './Editor/Modal/Contents';
import Header from './Editor/Modal/Header';

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    bottom: 0;
`;

const DeleteModal = () => {
    const { deleteComponent, selectedComponent, clearEditMode, componentEditMode, editMode } =
        useContext(EditorContext);

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (componentEditMode === componentEditModes.delete) {
            setVisible(true);
        }
    }, [componentEditMode]);

    const onCancel = (e) => {
        e.preventDefault();

        clearEditMode();
        setVisible(false);
    };

    const onConfirm = (e) => {
        e.preventDefault();
        if (editMode === EditMode.AddComponent) {
            deleteComponent(selectedComponent, false);
        } else {
            deleteComponent(selectedComponent);
        }
        clearEditMode();
        setVisible(false);
    };

    return (
        <Modal open={visible} onClose={onCancel}>
            <ModalContents width={500} height={200}>
                <Header>Are you sure you want to delete this component?</Header>
                <ButtonContainer>
                    <Button color="primary" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button color="primary" variant="contained" onClick={onConfirm} style={{ marginLeft: '1rem' }}>
                        Delete Component
                    </Button>
                </ButtonContainer>
            </ModalContents>
        </Modal>
    );
};

export default DeleteModal;
