import React, {
    useState,
    useEffect,
    useRef,
    ChangeEvent,
    FocusEvent,
    KeyboardEvent,
    useMemo,
    useCallback,
    CSSProperties
} from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { IconButton, TextField, Tooltip } from '@material-ui/core';
import { mdiPencil } from '@mdi/js';
import Icon from '@mdi/react';
import styled, { useTheme } from 'styled-components';
import { modularScale } from 'utils/modular_scale';

const StyledIconButton = styled(IconButton)<{ $iconSize: IconSize; $buttonPadding: string }>`
    padding: ${(props) => props.$buttonPadding};

    &.MuiIconButton-root {
        padding: ${(props) => props.$buttonPadding};
    }

    svg {
        width: ${(props) => props.$iconSize};
        color: ${(props) => props.theme.colors.primary};
    }
`;

const StyledForm = styled.form<{
    $width: string;
    $canEdit: boolean;
}>`
    display: flex;
    align-items: center;
    height: fit-content;

    width: ${(props) => (props.$canEdit ? props.$width : 'unset')};

    &:hover {
        ${StyledIconButton} {
            background: ${(props) => props.theme.colors.actionHover};
        }
    }
`;

const StyledTextField = styled(TextField)<{ $inputPaddingRight: string }>`
    overflow-x: visible;

    .MuiInputBase-input {
        color: ${(props) => props.theme.colors.text};
        cursor: pointer;
        padding-right: ${(props) => props.$inputPaddingRight};

        &.Mui-disabled {
            cursor: text;
        }
    }

    .MuiInput-underline:before {
        border: 0;
    }

    .MuiInput-underline:hover:not(.MuiDisabled):before {
        border: 0;
    }
`;

const returnIconSize = (modularScaleValue: number): IconSize => {
    if (modularScaleValue <= 2) {
        return IconSize.Small;
    }

    if (modularScaleValue <= 4) {
        return IconSize.Medium;
    }

    return IconSize.Large;
};

export interface EditableTextProps {
    saveContent: (content: string) => void;
    initialContent: string;
    modularScaleValue: number;
    validation?: (content: string) => boolean;
    buttonAriaLabel?: string;
    inputAriaLabel?: string;
    shouldClearOnEdit?: boolean;
    canEdit?: boolean;
    maxCharacters?: number;
    pencilTooltipText?: string;
    initialIsEditing?: boolean;
    stylesProp?: CSSProperties;
}

const EditableText: React.FC<EditableTextProps> = ({
    canEdit = true,
    shouldClearOnEdit = false,
    inputAriaLabel,
    buttonAriaLabel,
    initialContent,
    saveContent,
    validation,
    modularScaleValue,
    maxCharacters,
    pencilTooltipText,
    initialIsEditing,
    stylesProp
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [content, setContent] = useState(initialContent);
    const targetRef = useRef<HTMLSpanElement>(null);

    const inputRef = useRef<HTMLInputElement>(null);

    const theme = useTheme();

    useEffect(() => {
        setContent(initialContent);
    }, [initialContent]);

    const isValid = validation ? validation(content || '') : true;

    const beginEdit = useCallback(() => {
        if (shouldClearOnEdit) {
            setContent('');
        }

        inputRef?.current?.select();
        inputRef?.current?.focus();

        setIsEditing(true);
    }, [shouldClearOnEdit]);

    useEffect(() => {
        if (initialIsEditing) {
            beginEdit();
        }
    }, [initialIsEditing, beginEdit]);

    const onSubmit = () => {
        if (content === '') {
            setContent(initialContent);
        } else if (isValid) {
            save();
        }

        inputRef?.current?.blur();
        setIsEditing(false);
    };

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setContent(e.target.value);
    };

    const onBlur = () => {
        setIsEditing(false);

        onSubmit();
    };

    const onFocus = (_e: FocusEvent<HTMLInputElement>) => {
        beginEdit();
    };

    const onKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            inputRef?.current?.blur();
        }
    };

    const save = () => {
        saveContent(content || '');
    };

    const DEFAULT_INPUT_WIDTH = 'fit-content';

    const [inputWidth, setInputWidth] = useState(DEFAULT_INPUT_WIDTH);

    useEffect(() => {
        const width = targetRef?.current?.offsetWidth ? `${targetRef?.current?.offsetWidth}px` : DEFAULT_INPUT_WIDTH;

        setInputWidth(width);
    }, [content]);

    const iconSize = returnIconSize(modularScaleValue);
    const buttonPadding = '8px';
    const buttonMarginLeft = useMemo(() => `calc(${iconSize} / 2)`, [iconSize]);
    const containerWidth = useMemo(
        () => `calc(${inputWidth} + ${iconSize} + ${buttonMarginLeft} + ${buttonPadding} + ${buttonPadding})`,
        [inputWidth, iconSize, buttonMarginLeft, buttonPadding]
    );

    const shouldRenderEditButton: boolean = useMemo(() => {
        return !isEditing && canEdit;
    }, [isEditing, canEdit]);

    const inputStyles: CSSProperties = {
        fontSize: modularScale(modularScaleValue),
        fontWeight: 'bold',
        whiteSpace: 'pre',
        marginBottom: '-0.1rem',
        fontFamily: theme.fonts.fontFamily,
        ...stylesProp
    };

    const openMaxCharacterTooltip: boolean = useMemo(() => {
        if (!isEditing) {
            return false;
        }

        if (!shouldRenderEditButton && maxCharacters && maxCharacters - content.length <= 10) {
            return true;
        }
        return false;
    }, [shouldRenderEditButton, maxCharacters, content?.length, isEditing]);

    return (
        <StyledForm aria-label="editable text form" $width={containerWidth} $canEdit={canEdit}>
            <span
                style={{
                    ...inputStyles,
                    position: 'absolute',
                    opacity: 0
                }}
                ref={targetRef}
            >
                {content}
            </span>
            <Tooltip title={`${content.length} / ${maxCharacters}`} open={openMaxCharacterTooltip} placement="right">
                <StyledTextField
                    name="content"
                    value={content}
                    error={!isValid}
                    multiline={false}
                    onFocus={onFocus}
                    onKeyPress={onKeyPress}
                    onChange={onChange}
                    onBlur={onBlur}
                    disabled={!canEdit}
                    onMouseMove={(e) => {
                        // allows for selection of entire field if mouse is slightly moving when clicked
                        e.preventDefault();
                    }}
                    onTouchMove={(e) => {
                        // allows for selection of entire field if finger is slightly moving when touched
                        e.preventDefault();
                    }}
                    data-testid="EditableText-text-field"
                    inputRef={inputRef}
                    inputProps={{
                        style: {
                            width: inputWidth,
                            minWidth: isEditing ? containerWidth : 'unset',
                            ...inputStyles
                        },
                        'aria-label': inputAriaLabel || 'text field',
                        maxLength: maxCharacters
                    }}
                    $inputPaddingRight={buttonMarginLeft}
                />
            </Tooltip>
            {shouldRenderEditButton && (
                <Tooltip title={pencilTooltipText ?? ''} hidden={!pencilTooltipText}>
                    <StyledIconButton
                        $iconSize={iconSize}
                        $buttonPadding={buttonPadding}
                        onClick={beginEdit}
                        data-testid="EditableText-edit-btn"
                        aria-label={buttonAriaLabel || 'edit text'}
                    >
                        <Icon path={mdiPencil} />
                    </StyledIconButton>
                </Tooltip>
            )}
        </StyledForm>
    );
};

export default EditableText;
