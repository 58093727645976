import React from 'react';

import AdepteductMessageProps from 'components/communication/message/AdepteductMessageProps';
import ChannelPostMessageChatMessage from 'components/communication/message/chat/ChannelPostMessageComponent';
import ChannelTemplateChatMessage from 'components/communication/message/chat/ChannelTemplateMessage';
import * as yup from 'yup';

import { MessageEventType } from '..';
import AckableAdepteductMessage from '../../base/AckableAdepteductMessage';
import { MessageDestination } from '../../MessageRecord';
import PossibleEventFields from '../PossibleEventFields';

interface EventFieldsReactionUpdated {
    user: string;
    text: string;
    template: string;
    attachments?: [];
    reaction: string;
    action: string;
}

export default class ChannelReactionUpdated extends AckableAdepteductMessage<EventFieldsReactionUpdated> {
    schema = yup.object({
        user: yup.string().required(),
        text: yup.string(),
        template: yup.string(),
        reaction: yup.string().required(),
        action: yup.string().oneOf(['add', 'remove'])
    });

    type = MessageEventType.ChannelReactionUpdated;

    mapDestinationToComponent = (
        destination?: MessageDestination
    ): React.ForwardRefExoticComponent<
        AdepteductMessageProps<PossibleEventFields> & React.RefAttributes<HTMLDivElement | null>
    > => {
        if (destination !== MessageDestination.Chat) {
            console.log(`Received a ${this.type} message that did not have destination of ${MessageDestination.Chat}`);
        }

        if (this.fields.template) {
            return ChannelTemplateChatMessage;
        }

        return ChannelPostMessageChatMessage;
    };
}
