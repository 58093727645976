import React from 'react';

import { CircularProgress, Typography } from '@material-ui/core';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const ProgressIndicator = styled(CircularProgress)`
    margin: 10px;
`;

interface UploadProgressProps {
    progress: number | string | null | undefined;
}

export const UploadProgress: React.FC<UploadProgressProps> = ({ progress = null, children }) => (
    <Container>
        <ProgressIndicator />
        {progress === null ? (
            <Typography>{children}</Typography>
        ) : (
            <Typography>Uploading: {progress}% complete</Typography>
        )}
    </Container>
);
