import React, { Component, ReactElement, ReactNode } from 'react';

import { datadogLogs } from '@datadog/browser-logs';
import styled from 'styled-components';

import LogoIcon from './icons/Logo';

export interface GlobalErrorBoundaryProps {
    children: ReactNode;
}

export interface GlobalErrorBoundaryState {
    hasError: boolean;
}

const MainContent = styled.main`
    margin: 10rem auto;
    display: flex;
    max-width: 30rem;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;
const ErrorTitle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1em;
    svg {
        transform: rotate(45deg);
    }
`;
const LogoWrapper = styled.div`
    margin: 0 1em;
`;
const Message = styled.p`
    padding-left: 4.5em;
`;

export class GlobalErrorBoundary extends Component<GlobalErrorBoundaryProps, GlobalErrorBoundaryState> {
    constructor(props: GlobalErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }
    static getDerivedStateFromError(_error: Error): GlobalErrorBoundaryState {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    componentDidCatch(error: Error, info: unknown): void {
        if (process.env.NODE_ENV === 'production') {
            datadogLogs.logger.error('Unhandled error in learn app', { error, info });
        } else {
            console.error(error, info);
        }
    }

    render(): ReactElement | ReactNode {
        if (this.state.hasError) {
            return (
                <MainContent>
                    <ErrorTitle>
                        <LogoWrapper>
                            <LogoIcon size={'4em'} />
                        </LogoWrapper>
                        <h1>Something weird happened.</h1>
                    </ErrorTitle>
                    <Message>If this problem continues, please contact support.</Message>
                </MainContent>
            );
        }

        return this.props.children;
    }
}
