/**
 * Redirect Context aims to strip Query Args off at a top level context and remove them from the URL
 * for a cleaner User Experience, and then provide the Query Args to lower level components if needed.
 */
import React, { useEffect, Dispatch } from 'react';

import { useLocation, useHistory } from 'react-router-dom';

export interface QueryStringContextInterface {
    query: URLSearchParams | null; // Parse querystring into nicer to deal with Object.
    pathname: string; // Original Pathname that redirect landed at.
    source?: string; // Where did Redirect come from? (This may not exist..)
}

export interface QueryStringContextWrapper {
    queryString: QueryStringContextInterface;
    dispatch: Dispatch<QueryStringContextInterface>;
}

const QueryStringContext = React.createContext<QueryStringContextWrapper | undefined>(undefined);

const { Provider } = QueryStringContext;

const QueryStringProvider: React.FC = ({ children }) => {
    const { search, pathname } = useLocation();
    const history = useHistory();

    let queryArgs: URLSearchParams | null = null;

    if (search.length > 0) {
        queryArgs = new URLSearchParams(search);
    }

    const value: QueryStringContextInterface = {
        query: queryArgs,
        pathname
    };

    const [querystring, dispatch] = React.useState(value);

    useEffect(() => {
        /* If there were query parameters - we plan to remove them. */
        if (value.query) {
            /* Replace vs. Push to avoid infinite render loops of `history` dependents. */
            history.replace(pathname);
        }
    }, []); //omitting dependencies so this will only be triggered once in a (components) lifetime.

    return <Provider value={{ queryString: querystring, dispatch }}>{children}</Provider>;
};

const useQueryString = (): QueryStringContextWrapper => {
    const context = React.useContext(QueryStringContext);

    if (!context) {
        throw new Error('useRedirect must be used within a RedirectProvider.');
    }

    return context;
};

export { QueryStringProvider, useQueryString };
