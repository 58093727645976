import React from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { mdiCheck } from '@mdi/js';
import Icon from '@mdi/react';

import { StyledTableCell } from './TableCell.styles';

interface FavoritedCellProps {
    favorited?: boolean;
}

export const FAVORITED_CELL_WIDTH = 125;

export const FavoritedCell: React.FC<FavoritedCellProps> = ({ favorited }) => {
    return (
        <StyledTableCell width={FAVORITED_CELL_WIDTH}>
            {favorited ? <Icon path={mdiCheck} size={IconSize.Medium} /> : null}
        </StyledTableCell>
    );
};
