import { useMemo } from 'react';

import { RequestDocument } from 'graphql-request/dist/types';
import { Api } from 'lib/ApiConstants';

import { UseBuildAuthenticatedQueryFnOptions } from '../type';

import { useBuildAuthenticatedPromisfiedFetch } from './useBuildAuthenticatedPromisfiedFetch';

interface UseBuildAuthenticatedQueryFnInterface<TQueryResponse> {
    queryFn: () => Promise<TQueryResponse>;
}

export const useBuildAuthenticatedQueryFn = <TQueryResponse, TVariables = Record<string, unknown>>(
    queryDocument: RequestDocument,
    variables: TVariables = {} as TVariables,
    { api = Api.Accounts, headers }: UseBuildAuthenticatedQueryFnOptions
): UseBuildAuthenticatedQueryFnInterface<TQueryResponse> => {
    const { fetch } = useBuildAuthenticatedPromisfiedFetch<TQueryResponse, TVariables>(queryDocument, api, headers);

    const queryFn = useMemo(() => () => fetch(variables), [fetch, variables]);

    return { queryFn };
};
