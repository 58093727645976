import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import axios, { AxiosResponse } from 'axios';
import { AuthContext } from 'context/AuthContext';
import { API_ENDPOINTS } from 'lib/ApiConstants';
import adapter from 'webrtc-adapter';

import { MediaStatus } from '../PublisherContext';
import { MILLICAST_PUBLISH_URL } from '../utils';

import useReadyToPresent from './useReadyToPresent';
import useStopPresenting from './useStopPresenting';
import useToggleCamera from './useToggleCamera';
import useToggleMicrophone from './useToggleMicrophone';

const VIDEO_HEIGHT = 360;
const VIDEO_WIDTH = 480;
const PHOTO_HEIGHT_AND_WIDTH = 70;

const HIGHEST_COMMON_DENOMINATOR = Math.floor(Math.min(VIDEO_HEIGHT, VIDEO_WIDTH) / PHOTO_HEIGHT_AND_WIDTH);
const MIDDLE_HEIGHT_AND_WIDTH = HIGHEST_COMMON_DENOMINATOR * PHOTO_HEIGHT_AND_WIDTH;
const SX = (VIDEO_WIDTH - MIDDLE_HEIGHT_AND_WIDTH) / 2;
const SY = (VIDEO_HEIGHT - MIDDLE_HEIGHT_AND_WIDTH) / 2;
const CROPPED_HEIGHT_AND_WIDTH = Math.max(VIDEO_WIDTH, VIDEO_HEIGHT);

interface UsePublisherOptions {
    userId?: string;
    sessionId: string;
    setStartingToPresent: React.Dispatch<React.SetStateAction<boolean>>;
    setPublishToken: React.Dispatch<React.SetStateAction<string | undefined>>;
    isPresenting: boolean;
    fetchIceServers: () => Promise<RTCIceServer[]>;
    iceServers?: RTCIceServer[];
}

interface PublishResponse {
    data: {
        urls: string[];
        jwt: string;
    };
}

interface UsePublisherOutput {
    isPublishing: boolean;
    initializeCapture: (stream: MediaStream) => void;
    startPublishing: (token: string) => void;
    bindVideo: (videoElement: HTMLVideoElement) => void;
    shutdown: () => void;
    mediaReady: number;
    cameraOn: boolean;
    toggleCamera: (status: MediaStatus) => void;
    toggleCameraLoading: boolean;
    microphoneOn: boolean;
    setMicrophoneOn: React.Dispatch<React.SetStateAction<boolean>>;
    toggleMicrophone: (status: MediaStatus) => void;
    toggleMicrophoneLoading: boolean;
}

const setMediaBitrate = (sdp: string, media: 'audio' | 'video', bitrate: number) => {
    const lines = sdp.split('\n');

    let line = lines.findIndex((line) => line.indexOf(`m=${media}`) === 0);

    if (line === -1) {
        console.debug('Could not find the m line for', media);
        return sdp;
    }

    // Pass the m line
    line++;

    // Skip i and c lines
    while (lines[line].indexOf('i=') === 0 || lines[line].indexOf('c=') === 0) {
        line++;
    }

    // If we're on a b line, replace it
    if (lines[line].indexOf('b') === 0) {
        lines[line] = `b=AS:${bitrate}`;
        return lines.join('\n');
    }

    let modifier = 'AS';

    if (adapter.browserDetails.browser === 'firefox') {
        bitrate = (bitrate >>> 0) * 1000;
        modifier = 'TIAS';
    }

    // Add a new b line
    let newLines = lines.slice(0, line);
    newLines.push(`b=${modifier}:${bitrate}`);
    newLines = newLines.concat(lines.slice(line, lines.length));

    return newLines.join('\n');
};

export const usePublisher = ({
    userId,
    sessionId,
    setStartingToPresent,
    setPublishToken,
    isPresenting,
    fetchIceServers,
    iceServers
}: UsePublisherOptions): UsePublisherOutput => {
    const [isPublishing, setIsPublishing] = useState(false);
    const [mediaReady, setMediaReady] = useState(0);
    const [cameraOn, setCameraOn] = useState(true);
    const [microphoneOn, setMicrophoneOn] = useState(true);
    const { mutate: stopPresenting } = useStopPresenting({ setPublishToken });
    const { mutate: readyToPresent, isError } = useReadyToPresent({ setStartingToPresent });
    const { token } = useContext(AuthContext);
    const [videoEnabled, setVideoEnabled] = useState(false);

    const publishWebsocket = useRef<WebSocket | null>(null);
    const hiddenVideoElement = useRef<HTMLVideoElement | null>(null);
    const hiddenVideoCanvasElement = useRef<HTMLCanvasElement | null>(null);
    const hiddenPhotoCanvasElement = useRef<HTMLCanvasElement | null>(null);
    const inputStream = useRef<MediaStream | null>(null);
    const videoStream = useRef<MediaStream | null>(null);
    const audioStream = useRef<MediaStream | null>(null);

    useEffect(() => {
        if (isError) {
            stopPresenting({ sessionId });
        }
    }, [isError, stopPresenting, sessionId]);

    // Copy the webcam video image to the small canvas
    useEffect(() => {
        if (mediaReady && cameraOn && videoEnabled) {
            const timer = setInterval(() => {
                if (hiddenVideoCanvasElement.current && hiddenVideoElement.current) {
                    hiddenVideoCanvasElement.current
                        .getContext('2d')
                        ?.drawImage(
                            hiddenVideoElement.current,
                            0,
                            0,
                            hiddenVideoCanvasElement.current.width,
                            hiddenVideoCanvasElement.current.height
                        );
                }
            }, 100);

            return () => clearInterval(timer);
        }
    }, [mediaReady, cameraOn, videoEnabled]);

    useEffect(() => {
        if (videoEnabled && cameraOn && mediaReady && token && sessionId && !isPresenting) {
            const timer = setInterval(() => {
                if (hiddenPhotoCanvasElement.current && hiddenVideoElement.current) {
                    hiddenPhotoCanvasElement.current
                        .getContext('2d')
                        ?.drawImage(
                            hiddenVideoElement.current,
                            SX,
                            SY,
                            CROPPED_HEIGHT_AND_WIDTH,
                            CROPPED_HEIGHT_AND_WIDTH,
                            0,
                            0,
                            PHOTO_HEIGHT_AND_WIDTH,
                            PHOTO_HEIGHT_AND_WIDTH
                        );

                    const imageData = hiddenPhotoCanvasElement.current.toDataURL('image/jpeg', 0.95);

                    axios
                        .post(
                            `${API_ENDPOINTS.communication}/userimage`,
                            {
                                imageData,
                                sessionId
                            },
                            {
                                headers: {
                                    Authorization: `Bearer ${token}`
                                }
                            }
                        )
                        .catch((err) => {
                            console.error('Unable to save user image', err);
                        });
                }
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [mediaReady, token, sessionId, cameraOn, videoEnabled, isPresenting]);

    const bindVideo = useCallback(
        (element: HTMLVideoElement) => {
            if (videoEnabled && mediaReady && element) {
                element.srcObject = videoStream.current;
                element.oncanplay = async () => {
                    try {
                        element.play();
                    } catch (error) {
                        console.error('Error playing bound video', error);
                    }
                };
            }
        },
        [mediaReady, videoEnabled]
    );

    const shutdown = useCallback(() => {
        if (inputStream.current) {
            inputStream.current.getTracks().forEach((track) => {
                track.stop();
            });
        }
    }, []);

    useEffect(() => {
        if (videoStream.current) {
            videoStream.current.getTracks()[0].enabled = cameraOn;
        }
    }, [cameraOn]);

    useEffect(() => {
        if (inputStream.current) {
            inputStream.current.getAudioTracks()[0].enabled = microphoneOn;
        }
    }, [microphoneOn]);

    const initializeCapture = useCallback(
        async (mediaStream: MediaStream) => {
            if (inputStream.current) {
                shutdown();
                setIsPublishing(false);
            }

            // Save the input stream for future muting / unmuting functionality
            inputStream.current = mediaStream;

            const hasVideo = mediaStream.getVideoTracks()?.length > 0;
            setVideoEnabled(hasVideo);

            // Create the hidden video and canvas elements that we will use to capture the video and resize it
            hiddenVideoElement.current = document.createElement('video');
            hiddenVideoElement.current.width = VIDEO_WIDTH;
            hiddenVideoElement.current.height = VIDEO_HEIGHT;
            hiddenVideoElement.current.muted = true;

            hiddenVideoCanvasElement.current = document.createElement('canvas');
            hiddenVideoCanvasElement.current.width = VIDEO_WIDTH;
            hiddenVideoCanvasElement.current.height = VIDEO_HEIGHT;

            hiddenPhotoCanvasElement.current = document.createElement('canvas');
            hiddenPhotoCanvasElement.current.width = PHOTO_HEIGHT_AND_WIDTH;
            hiddenPhotoCanvasElement.current.height = PHOTO_HEIGHT_AND_WIDTH;

            hiddenVideoElement.current.srcObject = mediaStream;
            hiddenVideoElement.current.play();

            // In Firefox, canvas.captureStream() does not work unless you have already called canvas.getContext()
            // https://bugzilla.mozilla.org/show_bug.cgi?id=1572422
            hiddenVideoCanvasElement.current.getContext('2d');

            // Capture a 15 frame per second video stream from the canvas element
            // We have to cast to any because captureStream not yet part of the type
            videoStream.current = (hiddenVideoCanvasElement.current as any)?.captureStream(15);

            setMediaReady((prev) => prev + 1);
        },
        [shutdown]
    );

    const publishToMillicast = useCallback(
        async (publishToken: string, userId?: string): Promise<AxiosResponse<PublishResponse> | null> => {
            try {
                const response = await axios.post(
                    MILLICAST_PUBLISH_URL,
                    {
                        streamName: userId
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${publishToken}`
                        }
                    }
                );
                readyToPresent({ sessionId });
                return response;
            } catch (err) {
                console.warn(`Unable to publish to stream for user ${userId}`, err);
                stopPresenting({ sessionId });
                setStartingToPresent(false);
                return null;
            }
        },
        [sessionId, readyToPresent, stopPresenting, setStartingToPresent]
    );

    const startPublishing = useCallback(
        async (publishToken: string) => {
            // Get the audio from the stream passed in
            audioStream.current = inputStream.current ? new MediaStream(inputStream.current.getAudioTracks()) : null;

            const publishInfo = await publishToMillicast(publishToken, userId);

            if (!publishInfo) {
                return;
            }

            const websocketURL = publishInfo.data.data.urls[0];
            const jwtToken = publishInfo.data.data.jwt;

            const servers = iceServers ?? (await fetchIceServers());

            const connectionConfig = {
                iceServers: servers,
                bundlePolicy: 'max-bundle' as RTCBundlePolicy
            };

            const peerConnection = new RTCPeerConnection(connectionConfig);

            //add media to connection
            audioStream.current?.getTracks().forEach((track) => {
                peerConnection.addTrack(track);
            });

            videoStream.current?.getVideoTracks().forEach((track) => {
                peerConnection.addTrack(track);
            });

            publishWebsocket.current = new WebSocket(`${websocketURL}?token=${jwtToken}`);

            publishWebsocket.current.onopen = async () => {
                const description = await peerConnection.createOffer({
                    offerToReceiveAudio: true,
                    offerToReceiveVideo: true
                });

                await peerConnection.setLocalDescription(description);

                const payload = {
                    type: 'cmd',
                    transId: Math.random() * 10000,
                    name: 'publish',
                    data: {
                        name: userId,
                        sdp: description.sdp,
                        codec: 'vp8'
                    }
                };

                if (publishWebsocket.current) {
                    publishWebsocket.current.send(JSON.stringify(payload));
                }
            };

            publishWebsocket.current.addEventListener('message', (event) => {
                const message = JSON.parse(event.data);

                switch (message.type) {
                    // Handle counter response coming from the Media Server.
                    case 'response':
                        const { data } = message;

                        // Bandwidth limit audio and video

                        const answer = new RTCSessionDescription({
                            type: 'answer',
                            sdp: data.sdp
                        });

                        peerConnection
                            .setRemoteDescription(answer)
                            .then((_d) => {
                                setIsPublishing(true);
                            })
                            .catch((e) => {
                                console.error('Unable to set remote description', e);
                            });

                        break;
                }
            });
        },
        [userId, publishToMillicast, iceServers, fetchIceServers]
    );

    const {
        mutate: toggleMicrophoneApi,
        isSuccess: toggleMicrophoneSuccess,
        isLoading: toggleMicrophoneLoading
    } = useToggleMicrophone();
    const toggleMicrophone = useCallback(
        (status: MediaStatus) => {
            toggleMicrophoneApi({ sessionId, status });
        },
        [sessionId, toggleMicrophoneApi]
    );
    useEffect(() => {
        if (toggleMicrophoneSuccess) {
            setMicrophoneOn((prev) => !prev);
        }
    }, [toggleMicrophoneSuccess]);

    const {
        mutate: toggleCameraApi,
        isSuccess: toggleCameraSuccess,
        isLoading: toggleCameraLoading
    } = useToggleCamera();
    const toggleCamera = useCallback(
        (status: MediaStatus) => {
            toggleCameraApi({ sessionId, status });
        },
        [sessionId, toggleCameraApi]
    );
    useEffect(() => {
        if (toggleCameraSuccess) {
            setCameraOn((prev) => !prev);
        }
    }, [toggleCameraSuccess]);

    return {
        shutdown,
        isPublishing,
        initializeCapture,
        startPublishing,
        bindVideo,
        mediaReady,
        cameraOn,
        microphoneOn,
        setMicrophoneOn,
        toggleMicrophone,
        toggleMicrophoneLoading,
        toggleCamera,
        toggleCameraLoading
    };
};
