import { IconSize, modularScale } from '@adept-at/lib-react-components';
import styled from 'styled-components';

export const List = styled.div`
    top: calc(50% - 18px);
    border-radius: 4px;
    background-color: ${(props) => props.theme.colors.surface};
    padding: 8px;
`;

export const ListItems = styled.div`
    display: flex;
`;

export const ListItem = styled.div<{ $selected?: boolean }>`
    align-items: center;
    background-color: ${(props) => props.theme.colors.surface};
    border-radius: 4px;
    border: 1px solid ${(props) => (props.$selected ? props.theme.colors.primary : props.theme.colors.darkGrey)};
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 64px;
    justify-content: center;
    margin: 0 4px;
    width: 120px;
    word-break: normal;
    z-index: 1;

    &:first-of-type {
        margin: 0 4px 0 0;
    }

    &:last-of-type {
        margin: 0 4px 0 0;
    }

    svg {
        color: ${(props) => (props.$selected ? props.theme.colors.primary : '#58656c')};
        width: ${IconSize.Medium};
    }
`;

export const Title = styled.div`
    color: rgba(0, 0, 0, 0.5);
    font-size: ${modularScale(-1)};
    text-align: center;
    margin-top: 0.5rem;
`;
