import { useContext, useEffect, useMemo } from 'react';

import { OptimistMutationContext, OptimistRegisterUpdaterOptions, useOptimist } from '@adept-at/lib-react-optimist';
import {
    GetCollaborationsByOwnerResponse,
    buildGetCollaborationsByOwnerQueryKey
} from 'components/CollaborationTable/getCollaborationsByOwner';
import { OwnerInfo } from 'components/ContentContext/Interfaces';
import {
    buildGetNestedCollectionBySlugQueryKey,
    GetNestedCollectionBySlugQueryResponse
} from 'components/NestedCollection/context/getNestedCollectionBySlug';
import { NestedCollectionContext } from 'components/NestedCollection/context/NestedCollectionContext';
import { GetCollectionResponse, buildGetCollectionQueryKey } from 'components/studio/collection/getCollection';
import { GetCollectionChildrenDetailsResponse } from 'components/studio/collection/getCollectionChildrenDetails';
import { StudioCollectionContext } from 'components/studio/collection/StudioCollectionContext';
import { ClientError } from 'graphql-request';
import gql from 'graphql-tag';
import { useBuildAuthenticatedMutationFn } from 'hooks/useGqlClient/useBuildAuthenticatedMutationFn';
import { Api } from 'lib/ApiConstants';
import { useSnackbar } from 'notistack';
import { InfiniteData, useMutation, UseMutationResult } from 'react-query';

import { buildGetEditableCollectionQueryKey, GetEditableCollectionResponse } from './useGetEditableCollection';

export const UPDATE_COLLECTION_VISIBILITY_MUTATION = gql`
    mutation updateCollectionVisibility($collectionId: UUIDv4!, $visible: Boolean!) {
        updateCollectionVisibility(collectionId: $collectionId, visible: $visible) {
            success
        }
    }
`;

export interface UpdateCollectionVisibilityResponse {
    updateCollectionVisibility: {
        success: boolean;
    };
}

export interface UpdateCollectionVisibilityVariables {
    collectionId: string;
    visible: boolean;
}

export const useUpdateCollectionVisibility = (
    collectionId: string,
    owner?: OwnerInfo
): UseMutationResult<
    UpdateCollectionVisibilityResponse,
    ClientError,
    UpdateCollectionVisibilityVariables,
    OptimistMutationContext
> => {
    const { enqueueSnackbar } = useSnackbar();

    const { mutationOptions, registerUpdater } = useOptimist<
        UpdateCollectionVisibilityResponse,
        ClientError,
        UpdateCollectionVisibilityVariables
    >();

    const { mutationFn } = useBuildAuthenticatedMutationFn<
        UpdateCollectionVisibilityResponse,
        UpdateCollectionVisibilityVariables
    >(UPDATE_COLLECTION_VISIBILITY_MUTATION, {
        api: Api.Content
    });

    const UpdateCollectionVisibiltyOptions: OptimistRegisterUpdaterOptions<
        UpdateCollectionVisibilityResponse,
        ClientError,
        UpdateCollectionVisibilityVariables
    > = useMemo(() => {
        return {
            onError: () => {
                enqueueSnackbar('An error occurred while updating collection visibilty.', { variant: 'error' });
            },
            refetchQuery: false
        };
    }, [enqueueSnackbar]);

    registerUpdater<GetCollectionResponse>(
        buildGetCollectionQueryKey(collectionId),
        (previous, args) => {
            return {
                ...previous,
                getCollection: {
                    ...previous?.getCollection,
                    visible: args.visible
                }
            };
        },
        UpdateCollectionVisibiltyOptions
    );

    registerUpdater<GetEditableCollectionResponse>(
        buildGetEditableCollectionQueryKey(collectionId),
        (previous, args) => {
            return {
                ...previous,
                getEditableCollection: {
                    ...previous?.getEditableCollection,
                    visible: args.visible
                }
            };
        },
        UpdateCollectionVisibiltyOptions
    );

    useEffect(() => {
        if (owner) {
            registerUpdater<InfiniteData<GetCollaborationsByOwnerResponse>>(
                buildGetCollaborationsByOwnerQueryKey(owner),
                (previous, args) => {
                    const updatedPages = (previous?.pages ?? []).map((page) => {
                        return {
                            ...page,
                            getCollaborationsByOwner: {
                                ...page?.getCollaborationsByOwner,
                                items: (page?.getCollaborationsByOwner?.items ?? []).map((item) =>
                                    'collectionId' in item.item && item.item.collectionId === args.collectionId
                                        ? { ...item, item: { ...item.item, visible: args.visible } }
                                        : item
                                )
                            }
                        };
                    });

                    return {
                        ...previous,
                        pages: updatedPages
                    };
                },
                { refetchQuery: false }
            );
        }
    }, [owner, registerUpdater]);

    const studioCollectionContext = useContext(StudioCollectionContext);
    useEffect(() => {
        if (studioCollectionContext?.contentQueryKey) {
            registerUpdater<GetCollectionChildrenDetailsResponse>(
                studioCollectionContext?.contentQueryKey,
                (previous, args) => {
                    const updatedDetails = (previous?.getEntityBasicDetails ?? []).map((details) => {
                        if ('collectionId' in details && details.collectionId === args.collectionId) {
                            return {
                                ...details,
                                visible: args.visible
                            };
                        }
                        return details;
                    });

                    return {
                        ...previous,
                        getEntityBasicDetails: updatedDetails
                    };
                },
                { refetchQuery: false }
            );
        }
    }, [studioCollectionContext?.contentQueryKey, registerUpdater]);

    const nestedCollectionContext = useContext(NestedCollectionContext);
    useEffect(() => {
        if (nestedCollectionContext?.parentQuerySlug) {
            registerUpdater<GetNestedCollectionBySlugQueryResponse>(
                buildGetNestedCollectionBySlugQueryKey(nestedCollectionContext.parentQuerySlug),
                (previous, args) => {
                    return {
                        ...previous,
                        getEntityBySlug: {
                            ...previous?.getEntityBySlug,
                            visible: args.visible
                        }
                    };
                },
                { refetchQuery: false }
            );
        }
    });

    return useMutation<
        UpdateCollectionVisibilityResponse,
        ClientError,
        UpdateCollectionVisibilityVariables,
        OptimistMutationContext
    >(mutationFn, mutationOptions);
};
