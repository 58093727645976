import React, { ReactElement, useMemo, useState } from 'react';

import {
    AdeptCard,
    AdeptCardContent,
    ProgressIndicator,
    ProgressIndicatorStatus
} from '@adept-at/lib-react-components';
import { EntityType } from 'components/ContentContext/Enums';
import { isSkillInProgress } from 'components/learn/modes/accountability/utils';
import OrganizationLabel from 'components/organization/OrganizationLabel';
import { format } from 'date-fns';
import useUserDetails, { UserDetails } from 'hooks/useUserDetails';
import pluralize from 'pluralize';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { formatTime } from 'utils/time';

import { BlueText, CONTENT_CARD_WIDTH, DotListItem, HoverStyles, CARD_MARGIN } from './Dashboard.styles';
import { FavoriteItem } from './hooks/useGetFavorites';
import { BasicSkillInfoWithProgress } from './interfaces';
import { generateItemUrl } from './utils';

interface ContentCardProps {
    item: FavoriteItem;
    extraActions?: (isVisible: boolean) => ReactElement;
}

const StyledAdeptCard = styled(AdeptCard)<{ $hideBorder: boolean; $hasMinWidth?: boolean }>`
    width: ${(props) => (props.$hasMinWidth ? 'inherit' : `${CONTENT_CARD_WIDTH}px`)};
    min-width: ${(props) => (props.$hasMinWidth ? '320px' : 'inherit')};
    box-shadow: none;
    border: ${(props) => (props.$hideBorder ? 0 : `1px solid ${props.theme.colors.border}`)};
    margin-right: ${CARD_MARGIN}px;
    overflow: visible;

    ${HoverStyles}
`;

const StyledProgressIndicator = styled(ProgressIndicator)`
    margin-left: 8px;
`;

const buildTimeDescriptor = (item: BasicSkillInfoWithProgress) => {
    const secondsToConsume = item.estimatedSecondsToConsume ?? 0;

    if (isSkillInProgress(item)) {
        const secondsConsumed = item.progress?.secondsConsumed ?? 0;
        const remainingTime = secondsToConsume - secondsConsumed;

        return (
            <DotListItem key="time-indicator">
                <ProgressIndicator progressStatus={ProgressIndicatorStatus.InProgress} />
                <BlueText>{`${formatTime(remainingTime, 'medium')} left`}</BlueText>
            </DotListItem>
        );
    }

    return formatTime(secondsToConsume, 'medium');
};

const buildFinalDotListItem = (item: BasicSkillInfoWithProgress, usersDetails) => {
    const createdBy = usersDetails?.[item.createdBy]?.alias ? `by ${usersDetails?.[item.createdBy]?.alias}` : null;

    if (item.progress?.completedAt) {
        return (
            <DotListItem key="completed-indicator">
                {createdBy}
                <StyledProgressIndicator
                    progressStatus={ProgressIndicatorStatus.Complete}
                    tooltipTitle={`Completed on ${format(new Date(item.progress.completedAt), 'MM/dd/yyyy')}`}
                />
            </DotListItem>
        );
    }

    return createdBy;
};

const buildItemDetails = (item: FavoriteItem, usersDetails: Record<string, UserDetails>) => {
    if ('collectionId' in item) {
        return {
            component: AdeptCardContent.Collection,
            title: item.title,
            url: generateItemUrl(item),
            images: item.images,
            description: item.stats?.descendants?.map(({ count, type }) =>
                pluralize(type === EntityType.VIRTUAL_LAB ? 'lab' : type, count ?? 0, true)
            )
        };
    }

    if ('skillId' in item) {
        return {
            component: AdeptCardContent.Skill,
            title: item.title,
            url: generateItemUrl(item),
            organizationId: item.organizationId,
            images: item.images,
            description: [buildTimeDescriptor(item), buildFinalDotListItem(item, usersDetails)]
        };
    }

    return {};
};

const ContentCard: React.FC<ContentCardProps> = ({ item, extraActions }) => {
    const [actionButtonsVisible, setActionButtonsVisible] = useState(false);

    const history = useHistory();
    const { pathname } = window.location;

    const hasMinWidth = useMemo(() => pathname === '/favorites', [pathname]);

    const creator = useMemo(() => {
        if ('collectionId' in item) {
            return [];
        }
        if ('vLabId' in item) {
            return [item.created];
        }
        return [item.createdBy];
    }, [item]);

    const { usersDetails } = useUserDetails({ userIds: creator });

    const {
        component: CardComponent,
        title,
        description,
        url,
        organizationId,
        images
    } = useMemo(() => buildItemDetails(item, usersDetails), [item, usersDetails]);

    if (!CardComponent) {
        return null;
    }

    const handleCardClick = () => {
        if (url) {
            if ('skillId' in item) {
                history.push(url, { from: history.location.pathname });
            } else {
                history.push(url);
            }
        }
    };

    return (
        <StyledAdeptCard
            $hideBorder={'collectionId' in item}
            $hasMinWidth={hasMinWidth}
            onMouseEnter={() => setActionButtonsVisible(true)}
            onMouseLeave={() => setActionButtonsVisible(false)}
        >
            <CardComponent
                imageProps={{ image: images?.catalog }}
                organizationComponent={
                    organizationId ? <OrganizationLabel organizationId={organizationId} /> : undefined
                }
                secondaryInfo={description}
                title={title ?? ''}
                onClick={handleCardClick}
                quickActionsComponent={extraActions ? extraActions(actionButtonsVisible) : undefined}
            />
        </StyledAdeptCard>
    );
};

export default ContentCard;
