import React from 'react';

import { SkillLoader } from '@adept-at/lib-react-components';

import { SkillContainer } from './SkillLayout.styles';

export const EntityLoading: React.FC = () => {
    return (
        <SkillContainer>
            <SkillLoader />
        </SkillContainer>
    );
};
