import { IconSize } from '@adept-at/lib-react-components';
import styled from 'styled-components';
import { SKILL_CONTENT_WIDTH } from 'styles/variables';

export const Container = styled.div``;

export const SelectionContainer = styled.div`
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.colors.border};
    padding: 2rem;
    max-width: ${SKILL_CONTENT_WIDTH}px;
    margin: 0 auto;
`;

export const ActionItem = styled.div`
    color: ${(props) => props.theme.colors.primary};
    font-weight: bold;
    cursor: pointer;
`;

export const TypeOption = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 1 50%;
    border-right: solid 1px ${(props) => props.theme.colors.border};

    &:last-child {
        border-right: none;
    }
`;

export const IconLine = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    svg {
        width: ${IconSize.Medium};
        margin-right: 1rem;
    }
`;
