import React, { ReactNode } from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { Tooltip } from '@material-ui/core';
import { mdiAccountMultiple, mdiAccountOffOutline, mdiDomain, mdiEarth, mdiStore } from '@mdi/js';
import Icon from '@mdi/react';
import { EntityType } from 'components/ContentContext/Enums';
import { CollectionAuthor, Lab, OwnerInfo, Role, SkillAuthor } from 'components/ContentContext/Interfaces';
import { CollectionType } from 'components/modals/Skills/useGetCollectionsForTenantProfile';
import { EntityChild } from 'components/NestedCollection/context/getNestedCollectionBySlug';
import { CollectionChild, CollectionStats } from 'components/RootProfileCollection/context/useGetTenantProfileForOwner';
import { CollectionProductDetails } from 'components/studio/collection/getCollection';
import { OrganizationTerminology } from 'hooks/useOrganizationTerminology';
import { isTrue } from 'utils/isTrue';
import { formatTime } from 'utils/time';
import { uppercaseFirst } from 'utils/uppercaseFirst';

import { CollaborationRowOnClick } from './TableRow';

export enum ViewerStatus {
    SHARED,
    PUBLIC,
    PRIVATE,
    ORG,
    STORE
}

export enum BaseFilter {
    ALL = 'All',
    SKILLS = 'Skills',
    COLLECTIONS = 'Collections',
    LABS = 'Lab Templates',
    ASSESSMENTS = 'Assessments',
    DELETED = 'Deleted'
}

export enum StudioFilter {
    BY_ME = 'Authored by me'
}

export enum AvailabilityStatus {
    HIDDEN = 'Hidden',
    INTERNAL = 'Internal',
    INTERNAL_HIDDEN = 'Internal | Hidden',
    ENROLLMENT = 'Enrollment',
    PUBLIC = 'Public | No Login',
    NO_ENROLLMENT = 'No Enrollment',
    FREE_ENROLLMENT = 'Enrollment | Free',
    PAID_ENROLLMENT = 'Enrollment | Paid'
}

interface AvailabilityAttributes {
    free?: boolean;
    visible: boolean;
    loginRequired?: boolean;
    internal?: boolean;
    hasProduct?: boolean;
}

export const mapAvailabilityToStatus = ({
    free,
    visible,
    loginRequired,
    internal,
    hasProduct
}: AvailabilityAttributes): AvailabilityStatus | undefined => {
    if (internal && !visible) {
        return AvailabilityStatus.INTERNAL_HIDDEN;
    }

    if (internal) {
        return AvailabilityStatus.INTERNAL;
    }

    if (!visible) {
        return AvailabilityStatus.HIDDEN;
    }

    if (hasProduct) {
        return AvailabilityStatus.ENROLLMENT;
    }

    if (free && !loginRequired) {
        return AvailabilityStatus.PUBLIC;
    }

    if (!loginRequired) {
        return AvailabilityStatus.NO_ENROLLMENT;
    }

    if (loginRequired && free) {
        return AvailabilityStatus.FREE_ENROLLMENT;
    }

    if (loginRequired && !free) {
        return AvailabilityStatus.PAID_ENROLLMENT;
    }
};

export enum WorkflowStatus {
    NEEDS_TRAINER = 'Potential Skill | Needs Trainer',
    NEEDS_CONTENT = 'Trainer Assigned | Needs Content',
    NEEDS_PEER = 'Content Complete | Needs Peer Review',
    NEEDS_SEO = 'Peer Review Complete | Needs SEO Review',
    NEEDS_FINAL = 'Reviews Complete | Needs Final Go',
    PUBLISHED = 'Published',
    RETIREMENT_SCHEDULED = 'Scheduled for Retirement',
    RETIRED = 'Retired'
}

export interface AncestorCollection {
    collectionId: string;
    rootCollectionId: string;
    title: string;
    rootType: CollectionType;
    stats?: CollectionStats;
}

export interface CollaborativeSkill {
    skillId: string;
    title: string;
    createdAt: string;
    createdBy?: string;
    updatedAt: string;
    views: number;
    estimatedSecondsToConsume: number;
    canArchive: boolean;
    archived: boolean;
    reportStats?: {
        allTime: {
            likeCount: number;
        };
    };
    owner: OwnerInfo;
    skillSlug: string;
    tenantSlug: string;
    authors: SkillAuthor[];
    workflow?: keyof typeof WorkflowStatus;
    ancestorCollections: AncestorCollection[];
    free: boolean;
    visible: boolean;
    loginRequired: boolean;
}

export interface CollaborativeCollection {
    collectionId: string;
    title: string;
    visible: boolean;
    productDetails: CollectionProductDetails;
    children: {
        id: string;
        type: CollectionChild;
    }[];
    rootType: CollectionType;
    itemsVersion: number;
    colCreatedAt: string;
    colCreatedBy?: string;
    colUpdatedAt: string;
    stats?: {
        estimatedSecondsToConsume: number;
    };
    reportStats?: {
        allTime: {
            likeCount: number;
            viewCount: number;
        };
    };
    owner: OwnerInfo;
    collectionSlug: string;
    tenantSlug: string;
    authors: CollectionAuthor[];
    workflow?: keyof typeof WorkflowStatus;
    ancestorCollections: AncestorCollection[];
}

export interface Creation {
    id: string;
    title: string;
    createdAt: string;
    modifiedAt: string;
    viewers: ViewerStatus;
    views: number;
    likes: number;
    details: string[];
    type: EntityType;
    clickUrl?: string;
    editUrl: string;
    consumptionUrl?: string;
    canArchive: boolean;
    archived: boolean;
    owner: OwnerInfo;
    slug: string;
    onClick: CollaborationRowOnClick;
}

export interface CollaborativeEntity extends Creation {
    workflow?: WorkflowStatus;
    authors: CollectionAuthor[];
    availability?: AvailabilityStatus;
    ancestorCollections: AncestorCollection[];
    subtitle?: string;
    free?: boolean;
    visible?: boolean;
    loginRequired?: boolean;
    isInternal: boolean;
    enrollmentDetails?: {
        productName: string;
        children: EntityChild[];
        itemsVersion: number;
        productDetails: CollectionProductDetails;
        collectionId: string;
        tenantSlug: string;
    };
    order?: number;
}

export type Filter = BaseFilter | StudioFilter;

export const viewerStatusToTooltippedIcon = (viewerStatus: ViewerStatus): ReactNode => {
    switch (viewerStatus) {
        case ViewerStatus.PRIVATE:
            return (
                <Tooltip title="Not shared">
                    <Icon path={mdiAccountOffOutline} size={IconSize.Small} />
                </Tooltip>
            );
        case ViewerStatus.PUBLIC:
            return (
                <Tooltip title="Public">
                    <Icon path={mdiEarth} size={IconSize.Small} />
                </Tooltip>
            );
        case ViewerStatus.SHARED:
            return (
                <Tooltip title="Shared">
                    <Icon path={mdiAccountMultiple} size={IconSize.Small} />
                </Tooltip>
            );
        case ViewerStatus.ORG:
            return (
                <Tooltip title="Shared with organization">
                    <Icon path={mdiDomain} size={IconSize.Small} />
                </Tooltip>
            );
        case ViewerStatus.STORE:
            return (
                <Tooltip title="Published in store">
                    <Icon path={mdiStore} size={IconSize.Small} />
                </Tooltip>
            );
        default:
            return null;
    }
};

export interface HeadCellPreference {
    id: string;
    label: string;
    checked: boolean;
    sticky?: boolean;
    borderLeft?: boolean;
    borderRight?: boolean;
}

export const buildCreationFromSkill = (item: CollaborativeSkill, termOverride?: string): Creation => {
    return {
        id: item.skillId,
        title: item.title,
        editUrl: `/skillbuilder/edit/${item.skillId}`,
        onClick: ({ history }) => history.push(`/organization/${item.owner.id}/studio/skill/${item.skillId}`),
        consumptionUrl: `/learn/${item.tenantSlug}/skill/${item.skillSlug}`,
        createdAt: item.createdAt,
        modifiedAt: item.updatedAt,
        archived: item.archived,
        viewers: ViewerStatus.PUBLIC,
        views: item.views ?? 0,
        likes: item.reportStats?.allTime?.likeCount ?? 0,
        slug: item.skillSlug,
        type: EntityType.SKILL,
        canArchive: item.canArchive,
        details: [
            !!termOverride ? uppercaseFirst(termOverride) : uppercaseFirst(EntityType.SKILL),
            formatTime(item.estimatedSecondsToConsume ?? 0, 'medium')
        ],
        owner: item.owner
    };
};

export const buildCreationFromCollection = (item: CollaborativeCollection, termOverride?: string): Creation => {
    return {
        id: item.collectionId,
        title: item.title,
        editUrl: `/${item.tenantSlug}/${item.collectionSlug}`,
        onClick: ({ history }) => history.push(`/organization/${item.owner.id}/studio/collection/${item.collectionId}`),
        createdAt: item.colCreatedAt,
        modifiedAt: item.colUpdatedAt,
        archived: false,
        viewers: ViewerStatus.PUBLIC,
        views: item.reportStats?.allTime?.viewCount ?? 0,
        likes: item.reportStats?.allTime?.likeCount ?? 0,
        slug: item.collectionSlug,
        type: EntityType.COLLECTION,
        canArchive: false,
        details: [
            !!termOverride ? uppercaseFirst(termOverride) : uppercaseFirst(EntityType.COLLECTION),
            formatTime(item.stats?.estimatedSecondsToConsume ?? 0, 'medium')
        ],
        owner: item.owner
    };
};

export const buildCreationFromLab = (item: Lab): Creation => {
    return {
        id: item.vLabId,
        title: item.name,
        editUrl: `/labbuilder/${item.vLabId}`,
        onClick: ({ editItem }) =>
            editItem({
                id: item.vLabId,
                editUrl: `/labbuilder/${item.vLabId}`,
                type: EntityType.VIRTUAL_LAB,
                canArchive: false
            }),
        createdAt: item.createdAt,
        modifiedAt: item.updatedAt,
        archived: false, // TODO
        viewers: ViewerStatus.PUBLIC,
        views: 0, // TODO
        likes: 0, // TODO
        slug: item.slug,
        type: EntityType.VIRTUAL_LAB,
        canArchive: false, // TODO
        details: [EntityType.VIRTUAL_LAB],
        owner: item.owner
    };
};

export const buildCollaborationFromSkill = (item: CollaborativeSkill, termOverride?: string): CollaborativeEntity => {
    return {
        ...buildCreationFromSkill(item, termOverride),
        authors: item.authors.map(({ userId, authorType }) => ({ userId, authorTypes: [authorType] })),
        ancestorCollections: item.ancestorCollections ?? [],
        workflow: item.workflow ? WorkflowStatus[item.workflow] : undefined,
        availability: mapAvailabilityToStatus(item),
        free: item.free,
        loginRequired: item.loginRequired,
        visible: item.visible,
        subtitle: '',
        //false for now,
        isInternal: false
    };
};

export const buildCollaborationWithOrder = (
    orderedChildren: EntityChild[],
    item: CollaborativeSkill | CollaborativeCollection,
    role: Role | null,
    termOverrides: OrganizationTerminology
): CollaborativeEntity => {
    const itemId = 'skillId' in item ? item.skillId : item.collectionId;
    const itemType = 'skillId' in item ? CollectionChild.Skill : CollectionChild.Collection;
    const collaboration =
        'skillId' in item
            ? buildCollaborationFromSkill(item, termOverrides.skill)
            : buildCollaborationFromCollection(item, termOverrides.collection);

    const index = orderedChildren.findIndex((child) => child.id === itemId && child.type === itemType);
    return {
        ...collaboration,
        order: index
    };
};

export const buildCollaborationFromCollection = (
    item: CollaborativeCollection,
    termOverride?: string
): CollaborativeEntity => {
    const internal = item.rootType === CollectionType.INTERNAL;
    return {
        ...buildCreationFromCollection(item, termOverride),
        authors: item.authors.filter(Boolean),
        ancestorCollections: item.ancestorCollections ?? [],
        workflow: item.workflow ? WorkflowStatus[item.workflow] : undefined,
        visible: item.visible,
        availability: mapAvailabilityToStatus({
            ...item,
            internal,
            hasProduct: isTrue(item.productDetails?.productId)
        }),
        isInternal: internal,
        enrollmentDetails: {
            productName: item.tenantSlug + '/' + item.collectionSlug,
            collectionId: item.collectionId,
            children: item.children,
            productDetails: item.productDetails,
            itemsVersion: item.itemsVersion,
            tenantSlug: item.tenantSlug
        }
    };
};

interface RefineResultsProps<T> {
    results: T[];
    search: string;
    filter?: (item: T, userId?: string) => boolean;
    userId?: string;
}

export const refineResults = <T extends Creation>({ results, filter, search, userId }: RefineResultsProps<T>): T[] => {
    if (!filter && !search) {
        return results;
    }

    const regex = search ? new RegExp(search, 'gi') : null;

    return results.filter((item) => {
        const matchesFilter = filter ? filter(item, userId) : true;
        const matchesSearch = regex ? item.title.match(regex) : true;

        return matchesFilter && matchesSearch;
    });
};
