import { useCallback, useContext, useEffect } from 'react';

import { SectionDescriptionEditorContext } from 'components/builder/context/SectionDescriptionEditorContext';
import { Modifier, SelectionState, EditorState } from 'draft-js';
import { Map } from 'immutable';

import { RichTextEditorContext } from './Context';

export const useEditableSectionDescriptions = (): void => {
    const { blockKey, setBlockKey, description, setDescription } = useContext(SectionDescriptionEditorContext);
    const { setEditorState } = useContext(RichTextEditorContext);

    const upsertSectionDescription = useCallback(
        (sectionBlockKey: string, description: string) => {
            setEditorState((prev) => {
                const contentState = prev.getCurrentContent();
                const contentBlock = contentState.getBlockForKey(sectionBlockKey);
                const blockData = Map([['description', description]]);

                const contentStateWithBlockData = Modifier.setBlockData(
                    contentState,
                    new SelectionState({
                        anchorKey: contentBlock.getKey(),
                        anchorOffset: 0,
                        focusKey: contentBlock.getKey(),
                        focusOffset: contentBlock.getLength()
                    }),
                    blockData
                );

                const newEditorState = EditorState.set(prev, { currentContent: contentStateWithBlockData });
                return newEditorState;
            });
        },
        [setEditorState]
    );

    useEffect(() => {
        if (blockKey && description !== undefined) {
            upsertSectionDescription(blockKey, description);
            setBlockKey(undefined);
            setDescription(undefined);
        }
    }, [blockKey, description, upsertSectionDescription, setBlockKey, setDescription]);
};
