import React, { useEffect, useRef } from 'react';

import { mdiArrowTopLeftBottomRight } from '@mdi/js';
import Icon from '@mdi/react';
import { useDebouncedCallback } from 'use-debounce';

import { useComponentEngineComponent } from '../../../..';
import { ComponentFocus } from '../../../../mixins/editor';
import { useFocusableComponent } from '../../../../mixins/focusable';
import { useResizableContainer, ImageDimensions } from '../../ResizableContainer';

import { ResizableContainer, Resizer } from './ImageEdit.styles';
import UploadedImagePreview from './Preview';

import { UploadedImageComponentImage } from './';

interface UploadedImageEditProps {
    image: UploadedImageComponentImage;
    onResize: (size: ImageDimensions) => void;
}

const RESIZE_DEBOUNCE_DELAY_IN_MS = 500;

export const UploadedImageEdit: React.FC<UploadedImageEditProps> = ({ image, onResize }) => {
    const { id } = useComponentEngineComponent();
    const resizeRef = useRef<HTMLDivElement>(null);
    const size = useResizableContainer(resizeRef);
    const { doesComponentHaveAnyFocus } = useFocusableComponent();

    const [resize] = useDebouncedCallback(async () => {
        onResize(size);
    }, RESIZE_DEBOUNCE_DELAY_IN_MS);

    useEffect(() => {
        if (!size.height || !size.width) return;
        resize();
    }, [size, resize]);
    if (!image) {
        // @TODO - shouldn't get here
        return null;
    }
    const isBeingEdited = doesComponentHaveAnyFocus(id, [ComponentFocus.Edit, ComponentFocus.AddAndEdit]);

    return (
        <ResizableContainer
            initialWidth={(image?.dimensions?.width as number) ?? null}
            ref={resizeRef}
            $isBeingEdited={isBeingEdited}
        >
            <UploadedImagePreview image={image} />
            {isBeingEdited ? (
                <Resizer>
                    <Icon path={mdiArrowTopLeftBottomRight} />
                </Resizer>
            ) : null}
        </ResizableContainer>
    );
};
