import { ClientError } from 'graphql-request';
import gql from 'graphql-tag';
import { useBuildAuthenticatedQueryFn } from 'hooks/useGqlClient/useBuildAuthenticatedQueryFn';
import { Api } from 'lib/ApiConstants';
import { ADEPT_USE_PRIMARY_HEADER } from 'lib/constants';
import { QueryKey, useQuery, UseQueryResult } from 'react-query';

const queryName = 'getEditableCollectionVersions';
export const buildGetEditableCollectionVersionsQueryKey = (editableCollectionId: string): QueryKey => [
    queryName,
    editableCollectionId
];

export const GET_EDITABLE_COLLECTION_VERSIONS_QUERY = gql`
    query ${queryName}($collectionId: UUIDv4!) {
        getEditableCollection(collectionId: $collectionId) {
            itemsVersion
            infoVersion
        }
    }
`;

export interface GetEditableCollectionVersionsResponse {
    getEditableCollection: {
        itemsVersion: number;
        infoVersion: number;
    };
}

export interface useGetEditableCollectionVersionsVariables {
    collectionId: string;
}

export const useGetEditableCollectionVersions = (
    variables: useGetEditableCollectionVersionsVariables,
    enabled = true,
    usePrimary = true
): UseQueryResult<GetEditableCollectionVersionsResponse, ClientError> => {
    const { queryFn } = useBuildAuthenticatedQueryFn<
        GetEditableCollectionVersionsResponse,
        useGetEditableCollectionVersionsVariables
    >(GET_EDITABLE_COLLECTION_VERSIONS_QUERY, variables, {
        api: Api.Content,
        headers: { [ADEPT_USE_PRIMARY_HEADER]: usePrimary }
    });

    return useQuery<GetEditableCollectionVersionsResponse, ClientError>(
        buildGetEditableCollectionVersionsQueryKey(variables.collectionId),
        { queryFn, enabled }
    );
};
