// purpose: to have header content that can be shared among standalone building page and pages that allow you to switch between learning and building
import React, { useContext, useMemo } from 'react';

import { TooltippedIconButton, TooltippedButtonSize, defaultMuiTheme } from '@adept-at/lib-react-components';
import { Switch, useMediaQuery } from '@material-ui/core';
import { mdiFileCog } from '@mdi/js';
import Icon from '@mdi/react';
import { WorkflowStatus } from 'components/CollaborationTable';
import { WorkflowChip } from 'components/CollaborationTable/WorkflowChip';
import { EntityType } from 'components/ContentContext/Enums';
import { Skill } from 'components/ContentContext/Interfaces';
import { PanelRightPath } from 'components/icons/PannelRight';
import { Divider } from 'components/learn/LearningHeader/index.styles';
import { PageSettingsModal } from 'components/NestedCollection/PageSettingsModal';
import { SidebarContext } from 'components/SidebarContext';
import { useGetSkillById } from 'components/skills/hooks/useGetSkillById';
import { useModal } from 'hooks/useModal';
import styled from 'styled-components';
import { BREAKPOINT_1500PX } from 'styles/variables';
import { modularScale } from 'utils/modular_scale';
import { getEditedSkillsFromSessionStorage } from 'utils/skill/editedSkills';

import { AvailabilityButton } from '../Meta/SkillHeader/AvailabilityButton';
import { CollaboratorsButton } from '../Meta/SkillHeader/CollaboratorsButton';
import { SkillEditMenu } from '../skills/SkillEditMenu';

export const PreviewToggle = styled.div`
    line-height: 1;
    display: flex;
    align-items: center;
    font-size: ${modularScale(-1)};
    margin-right: 2rem;
`;

export const CustomSwitch = styled(Switch)`
    .MuiSwitch-thumb {
        background-color: ${(props) => props.theme.colors.primary};
    }

    .MuiSwitch-track {
        background-color: ${(props) => props.theme.colors.primary};
    }
`;

export const ToggleState = styled.div<{ isActive }>`
    color: ${(props) => (props.isActive ? props.theme.colors.primary : 'inherit')};
    font-weight: ${(props) => (props.isActive ? 'bold' : 'inherit')};
    cursor: pointer;
`;

const ActionButtons = styled.div`
    display: flex;
    margin-left: auto;
    align-items: center;
    button {
        margin-left: 0.25rem;
        margin-right: 0.25rem;
        border: none !important;
    }
`;

interface BuildingHeaderProps {
    skillId: string;
    onToggleEdit: () => void;
}

export const BuildingHeaderContent: React.FC<BuildingHeaderProps> = ({ skillId, onToggleEdit }) => {
    const showActionButtonsInSubheader = useMediaQuery(defaultMuiTheme.breakpoints.up(BREAKPOINT_1500PX));

    const usePrimary = useMemo(() => {
        const editedSkills = getEditedSkillsFromSessionStorage();
        return editedSkills.includes(skillId);
    }, [skillId]);

    const { status, data } = useGetSkillById(skillId, usePrimary);
    const skill = useMemo(() => data?.getSkillById as Skill, [data]);

    const { isOpen, openSidebar } = useContext(SidebarContext);

    const {
        modalOpen: pageSettingsOpen,
        handleModalClose: handlePageSettingsClose,
        handleModalOpen: handlePageSettingsOpen
    } = useModal();

    const renderOpenPanelBtn = () => {
        if (isOpen) return null;

        return (
            <>
                <Divider />
                <TooltippedIconButton
                    aria-label={'Open side panel'}
                    size={TooltippedButtonSize.Medium}
                    onClick={() => openSidebar({ isOpen: true, savePreference: true })}
                    icon={<Icon path={PanelRightPath} />}
                    title={'Open side panel'}
                />
            </>
        );
    };

    if (skill && status === 'success') {
        return (
            <>
                <ActionButtons>
                    {showActionButtonsInSubheader ? (
                        <>
                            <CollaboratorsButton skill={skill} />
                            <AvailabilityButton skill={skill} />
                            <WorkflowChip
                                variant="button"
                                id={skill.skillId}
                                type={EntityType.SKILL}
                                owner={skill.owner}
                                workflow={skill.workflow ? WorkflowStatus[skill.workflow] : undefined}
                            />
                            <Divider />
                            <TooltippedIconButton
                                title="Page Settings"
                                icon={<Icon path={mdiFileCog} />}
                                onClick={handlePageSettingsOpen}
                            />
                            <PageSettingsModal
                                contentType={EntityType.SKILL}
                                contentTitle={skill.title}
                                description={skill.subtitle ?? ''}
                                catalogImage={skill.images?.catalog?.processed?.[0]?.url}
                                open={pageSettingsOpen}
                                onClose={handlePageSettingsClose}
                                onConfirm={() => {
                                    // @TODO Update and save values
                                    handlePageSettingsClose();
                                }}
                            />
                            <Divider />
                        </>
                    ) : null}
                    <SkillEditMenu onToggleEdit={onToggleEdit} skill={skill} />
                    {renderOpenPanelBtn()}
                </ActionButtons>
            </>
        );
    }

    return null;
};
