import React, { ReactElement } from 'react';

import { IconSize, TooltippedIconButton } from '@adept-at/lib-react-components';
import { mdiViewGrid } from '@mdi/js';
import Icon from '@mdi/react';

import { LayoutViewButton } from './FavoritesLayoutViewButtons';

export const GridViewButtons = ({
    isSmallScreen,
    setIsGridView
}: {
    isSmallScreen: boolean;
    setIsGridView: React.Dispatch<React.SetStateAction<boolean>>;
}): ReactElement => {
    if (isSmallScreen) {
        return (
            <TooltippedIconButton
                icon={<Icon path={mdiViewGrid} />}
                title="Grid view"
                aria-label="Click to switch to grid view"
                onClick={() => setIsGridView(true)}
            />
        );
    }

    return (
        <LayoutViewButton
            onClick={() => setIsGridView(true)}
            startIcon={<Icon path={mdiViewGrid} size={IconSize.Small} />}
        >
            Grid view
        </LayoutViewButton>
    );
};
