import React from 'react';

import { AnswerOption, QuestionComponentValues, QuestionType } from '@adept-at/lib-react-components';
import { Collapse, Fade, FormControl, TextField, IconButton, Checkbox, FormHelperText } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { AddAnswer } from '../../helpers/AddAnswer';
import { ToggleRandomOrder } from '../../helpers/ToggleRandomOrder';
import { BoxWithPadding } from '../../Question.styles';

interface Props {
    values: QuestionComponentValues;
    onChange: <TComponentFieldValue>(field: string) => (value: TComponentFieldValue) => void;
    onOptionChange: (index: number, value: AnswerOption) => void;
    error: any;
}

export const CheckboxEdit: React.FC<Props> = ({
    values: { questionType, checkboxAnswers, answerOptions, hasRandomOrder },
    onChange,
    onOptionChange,
    error
}) => (
    <Collapse timeout={500} in={questionType === QuestionType.Checkbox}>
        <Fade timeout={800} in={questionType === QuestionType.Checkbox}>
            <FormControl component="fieldset" error={error['checkboxEdit-checkbox']}>
                {answerOptions.map((option, index) => (
                    <BoxWithPadding key={option.key} display="flex" alignItems="center">
                        <Checkbox
                            key={`${option.key}-checkbox`}
                            color="primary"
                            size="medium"
                            name="checkboxAnswers"
                            checked={checkboxAnswers.includes(option.key)}
                            onChange={({ target: { checked } }) => {
                                let checkboxAnswersCopy = checkboxAnswers.slice(0);
                                if (checked) {
                                    checkboxAnswersCopy.push(option.key);
                                } else {
                                    checkboxAnswersCopy = checkboxAnswersCopy.filter(
                                        (element) => element !== option.key
                                    );
                                }
                                onChange('checkboxAnswers')(checkboxAnswersCopy);
                            }}
                        />
                        <TextField
                            key={`${option.key}-text`}
                            name="answerOptions"
                            variant="outlined"
                            fullWidth
                            value={option.text}
                            onChange={({ target: { value } }) => {
                                const updatedContent = { ...option, text: value };
                                onOptionChange(index, updatedContent);
                            }}
                            error={!!error[`checkboxEdit-text-${option.key}`]}
                            helperText={error[`checkboxEdit-text-${option.key}`]}
                        />
                        <IconButton
                            key={`${option.key}-icon-button`}
                            onClick={() => {
                                if (answerOptions.length <= 1) {
                                    return;
                                }
                                const draftCheckboxAnswers = [...checkboxAnswers];
                                const answerIndex = draftCheckboxAnswers.indexOf(option.key);
                                if (answerIndex > -1) {
                                    draftCheckboxAnswers.splice(answerIndex, 1);
                                }
                                onChange('checkboxAnswers')(draftCheckboxAnswers);
                                const draftAnswerOptions = [...answerOptions];
                                draftAnswerOptions.splice(index, 1);
                                onChange('answerOptions')(draftAnswerOptions);
                            }}
                        >
                            <DeleteIcon key={`${option.key}-del-icon`} />
                        </IconButton>
                    </BoxWithPadding>
                ))}
                <FormHelperText>{error['checkboxEdit-checkbox']}</FormHelperText>
                <BoxWithPadding display="flex" justifyContent="space-between">
                    <AddAnswer answerOptions={answerOptions} onChange={onChange}>
                        Add Another Answer
                    </AddAnswer>
                    <ToggleRandomOrder hasRandomOrder={hasRandomOrder} onChange={onChange} />
                </BoxWithPadding>
            </FormControl>
        </Fade>
    </Collapse>
);

export default CheckboxEdit;
