import React, { ReactElement } from 'react';

import { IconSize, TooltippedIconButton } from '@adept-at/lib-react-components';
import { mdiViewList } from '@mdi/js';
import Icon from '@mdi/react';

import { LayoutViewButton } from './FavoritesLayoutViewButtons';

export const ListViewButtons = ({
    isSmallScreen,
    setIsGridView
}: {
    isSmallScreen: boolean;
    setIsGridView: React.Dispatch<React.SetStateAction<boolean>>;
}): ReactElement => {
    if (isSmallScreen) {
        return (
            <TooltippedIconButton
                icon={<Icon path={mdiViewList} />}
                title="List View"
                aria-label="Click to switch to list view"
                onClick={() => setIsGridView(false)}
            />
        );
    }

    return (
        <LayoutViewButton
            onClick={() => setIsGridView(false)}
            startIcon={<Icon path={mdiViewList} size={IconSize.Small} />}
        >
            List view
        </LayoutViewButton>
    );
};
