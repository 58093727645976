import { useMemo } from 'react';

import { ClientError, GraphQLClient } from 'graphql-request';
import gql from 'graphql-tag';
import useGqlClient from 'hooks/useGqlClient';
import buildGqlMutationFn from 'hooks/useGqlClient/helpers/buildGqlMutationFn';
import { API_ACCOUNTS } from 'lib/ApiConstants';
import { useSnackbar } from 'notistack';
import { MutationFunction, useMutation, UseMutationResult } from 'react-query';

export interface UpdateAffiliationsVariables {
    affiliations: string[];
}

export interface UpdateAffiliationsMutationResponse {
    success: boolean;
}

const REORDER_AFFILIATIONS = gql`
    mutation reorderUserAffiliations($affiliations: [String]) {
        reorderUserAffiliations(affiliations: $affiliations) {
            success
        }
    }
`;

const makeReorderAffiliationsMutation = (
    client: GraphQLClient
): MutationFunction<UpdateAffiliationsMutationResponse, UpdateAffiliationsVariables> =>
    buildGqlMutationFn(client, REORDER_AFFILIATIONS);

const useReorderAffiliations = ({
    affiliations
}: UpdateAffiliationsVariables): UseMutationResult<
    UpdateAffiliationsMutationResponse,
    ClientError,
    UpdateAffiliationsVariables
> => {
    const { client, withMutationOptions } = useGqlClient(API_ACCOUNTS);
    const { enqueueSnackbar } = useSnackbar();

    const setReorderAffiliationsOptions = useMemo(() => {
        return {
            onError: () => {
                enqueueSnackbar('Unable to update Icon Order.', { variant: 'error' });
            },
            onSuccess: () => {
                enqueueSnackbar('Icon order updated.', { variant: 'success' });
            }
        };
    }, [enqueueSnackbar]);

    return useMutation<UpdateAffiliationsMutationResponse, ClientError, UpdateAffiliationsVariables>(
        makeReorderAffiliationsMutation(client),
        withMutationOptions(setReorderAffiliationsOptions)
    );
};

export default useReorderAffiliations;
