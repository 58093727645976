import React, { useEffect, useState, useCallback, useMemo } from 'react';

import { Fade, Backdrop, Button } from '@material-ui/core';
import { mdiUpload } from '@mdi/js';
import Icon from '@mdi/react';
import axios from 'axios';
import { ImageFormat } from 'components/ContentContext/Interfaces';
import gql from 'graphql-tag';
import { useAssetOwner } from 'hooks/useAssetOwner';
import { API_CONTENT } from 'lib/ApiConstants';
import { getMaxCropPercentage } from 'lib/imageUtils';
import { useSnackbar } from 'notistack';
import { useDropzone } from 'react-dropzone';
import ReactCrop from 'react-image-crop';

import { useUnsafeMutation } from '../../GraphqlProvider';
import Loading from '../../Loading';

import {
    Buttons,
    DragText,
    Dropzone,
    ModalBody,
    SelectText,
    StyledModal,
    Title,
    UploadButton,
    UploadControls,
    UploadHelpText
} from './ImageUploadModal.styles';

import 'react-image-crop/dist/ReactCrop.css';

const CREATE_UPLOAD_INTENT_QUERY = gql`
    mutation createAssetUploadIntent($assetType: AssetType, $attributes: JSON, $ownerInfo: OwnerInfo!) {
        createAssetUploadIntent(assetType: $assetType, attributes: $attributes, ownerInfo: $ownerInfo) {
            assetId
            uploadURL
        }
    }
`;

interface CropDetails {
    unit: string;
    width: number;
    height: number;
    x: number;
    y: number;
    aspect?: number;
}

interface CreateUploadIntentResultProps {
    assetId: string;
    uploadURL: string;
}

interface CreateUploadIntentResult {
    createAssetUploadIntent: CreateUploadIntentResultProps;
}

interface UploadModalProps {
    isOpen: boolean;
    handleClose: () => void;
    onFinishUpload: (
        assetId: string,
        imageDataURL: string | null,
        dimensions: { width: string | number | null; height: string | number | null }
    ) => void;
    attributes?: Record<string, unknown>;
    cropRatio: number | null;
    circularCrop?: boolean;
}

/**
 * @deprecated
 *
 * @see "components/ImageUpload" for new functionality
 */
const CroppingImageUploadModal: React.FC<UploadModalProps> = ({
    isOpen,
    onFinishUpload,
    handleClose,
    cropRatio = null,
    attributes = {},
    circularCrop = false
}) => {
    const ownerInfo = useAssetOwner();

    const { enqueueSnackbar } = useSnackbar();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [assetId, setAssetId] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isUploading, setIsUploading] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [uploadProgress, setUploadProgress] = useState(0);
    const [image, setImage] = useState<string | null>(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [selectedFileName, setSelectedFileName] = useState('');
    const [crop, setCrop] = useState<CropDetails>({} as CropDetails);
    const [cropPixels, setCropPixels] = useState<any>(null);
    const [displayWidth, setDisplayWidth] = useState(0);
    const [displayHeight, setDisplayHeight] = useState(0);
    const [naturalWidth, setNaturalWidth] = useState(0);
    const [naturalHeight, setNaturalHeight] = useState(0);
    const [cropWidthPixels, setCropWidthPixels] = useState(0);
    const [cropHeightPixels, setCropHeightPixels] = useState(0);
    const [imageFormat, setImageFormat] = useState<ImageFormat | null>(null);

    const uploadAttributes = useMemo(() => {
        return {
            ...attributes,
            crop: cropPixels,
            format: imageFormat
        };
    }, [attributes, cropPixels, imageFormat]);

    const [createUploadIntent, { error: createUploadIntentError, data: createUploadIntentData }] =
        useUnsafeMutation<CreateUploadIntentResult>(
            CREATE_UPLOAD_INTENT_QUERY,
            {
                // Attributes for S3 Object Metadata
                attributes: uploadAttributes
            },
            { host: API_CONTENT }
        );

    const uploadConfig = {
        onUploadProgress: function (progressEvent) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(percentCompleted);
        }
    };

    useEffect(() => {
        if (createUploadIntentError) {
            enqueueSnackbar('An error has occurred while uploading your image', { variant: 'error' });
            setIsUploading(false);
        }
    }, [createUploadIntentError]);

    useEffect(() => {
        if (!createUploadIntentData) {
            return;
        }

        const {
            createAssetUploadIntent: { uploadURL, assetId }
        } = createUploadIntentData ?? ({} as CreateUploadIntentResultProps);

        async function doUpload() {
            try {
                await axios.put(uploadURL, selectedFile, uploadConfig);

                setUploadProgress(100);

                setAssetId(assetId);

                setImage(null);
                setSelectedFile(null);
                setImageFormat(null);
                setSelectedFileName('');

                const imageURL = await getCroppedImg();

                onFinishUpload(assetId, imageURL, { width: cropPixels.width, height: cropPixels.height });
                setIsUploading(false);
            } catch (err) {
                console.log(err);

                enqueueSnackbar('An error has occurred.', { variant: 'error' });
            }
        }

        doUpload();
    }, [createUploadIntentData]);

    const onDropAccepted = (acceptedFiles: File[]) => {
        setSelectedFile(acceptedFiles[0]);
        setSelectedFileName(acceptedFiles[0].name);

        const uploadedFormat = acceptedFiles[0]?.type?.split('/')[1];
        const format = uploadedFormat === 'jpeg' ? ImageFormat.JPG : (uploadedFormat.toUpperCase() as ImageFormat);
        setImageFormat(format);

        const reader = new FileReader();
        reader.onload = () => setImage(reader.result as string);
        reader.readAsDataURL(acceptedFiles[0]);
    };

    const onDropRejected = (rejectedFiles) => {
        const { errors } = rejectedFiles[0];
        enqueueSnackbar(errors[0].message, { variant: 'error' });
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDropAccepted,
        onDropRejected,
        accept: ['.jpg', '.jpeg', '.png'],
        maxSize: 10485760 // 10MB
    });

    const getCroppedImg = (): Promise<string | null> => {
        if (image === null) {
            return Promise.resolve(null);
        }
        const dummyImage: HTMLImageElement = document.createElement('img') as HTMLImageElement;

        if (!dummyImage) {
            return Promise.resolve(null);
        }
        dummyImage.src = image;

        return new Promise((resolve, _reject) => {
            dummyImage.onload = function () {
                const canvas = document.createElement('canvas');

                canvas.width = cropPixels.width;
                canvas.height = cropPixels.height;

                const ctx = canvas.getContext('2d');

                if (!ctx) {
                    return Promise.resolve(null);
                }

                // Place a white rectangle on the canvas. This is important for transparent background pngs
                ctx.fillStyle = 'white';
                ctx.fillRect(0, 0, cropPixels.width, cropPixels.height);

                ctx.drawImage(
                    dummyImage,
                    cropPixels.x,
                    cropPixels.y,
                    cropPixels.width,
                    cropPixels.height,
                    0,
                    0,
                    cropPixels.width,
                    cropPixels.height
                );
                const base64Image = canvas.toDataURL('image/jpg');
                resolve(base64Image);
            };
        });
    };

    useEffect(() => {
        if (!cropPixels) {
            return;
        }

        createUploadIntent({ assetType: 'image', ownerInfo }, {});
    }, [cropPixels]);

    const onFinish = () => {
        setIsUploading(true);

        // set cropping values in pixels before starting the upload
        console.log(`crop: ${JSON.stringify(crop)}`);

        const cropPixels: any = { ...crop };
        if (cropPixels.unit === '%') {
            console.log('Cropping units are in percents');

            // Save crop size in pixels before scaling
            setCropWidthPixels(Math.floor((displayWidth * cropPixels.width) / 100));
            setCropHeightPixels(Math.floor((displayHeight * cropPixels.height) / 100));

            cropPixels.unit = 'px';
            cropPixels.x = Math.floor((cropPixels.x / 100) * naturalWidth);
            cropPixels.y = Math.floor((cropPixels.y / 100) * naturalHeight);
            cropPixels.width = Math.floor((cropPixels.width / 100) * naturalWidth);
            cropPixels.height = Math.floor((cropPixels.height / 100) * naturalHeight);
        } else {
            // Scale up crop settings
            const scaleX = naturalWidth / displayWidth;
            const scaleY = naturalHeight / displayHeight;

            // Set crop size in pixels before scaling
            // SAS - These state values don't appear to be used
            setCropWidthPixels(cropPixels.width);
            setCropHeightPixels(cropPixels.height);

            cropPixels.x = Math.floor(cropPixels.x * scaleX);
            cropPixels.y = Math.floor(cropPixels.y * scaleY);
            cropPixels.width = Math.floor(cropPixels.width * scaleX);
            cropPixels.height = Math.floor(cropPixels.height * scaleY);
        }

        console.log(`cropPixels: ${JSON.stringify(cropPixels)}`);
        console.log(`cropWidthPixels: ${cropWidthPixels}`);
        console.log(`cropHeightPixels: ${cropHeightPixels}`);

        setCropPixels(cropPixels);
    };

    const canSave = () => {
        return isLoading === false && isUploading === false;
    };

    const Controls = () => (
        <UploadControls>
            <Icon path={mdiUpload} />
            <DragText>Drag your image here</DragText>
            <SelectText>or select a file</SelectText>
            <UploadButton color="primary" variant="contained" onClick={() => {}}>
                Choose File
            </UploadButton>
        </UploadControls>
    );

    const onImageLoaded = useCallback((img) => {
        const aspect = cropRatio;

        // Aspect ratio is width / height
        // Example: 800/250 = 3.2

        // We use the natural width and height because the actual width and height
        // may not have the same aspect ratio due to rounding.
        if (aspect) {
            const { widthPercent, heightPercent } = getMaxCropPercentage({
                imageWidthInPixels: img.naturalWidth,
                imageHeightInPixels: img.naturalHeight,
                aspectRatio: aspect
            });

            // Center
            const y = (100 - heightPercent) / 2;
            const x = (100 - widthPercent) / 2;

            const params = {
                unit: '%',
                width: widthPercent,
                height: heightPercent,
                x,
                y,
                aspect
            };

            setCrop(params);
        } else {
            // Select the entire image with cropper
            setCrop({
                unit: '%',
                width: 100,
                height: 100,
                x: 0,
                y: 0
            });
        }

        setDisplayWidth(img.width);
        setDisplayHeight(img.height);
        setNaturalWidth(img.naturalWidth);
        setNaturalHeight(img.naturalHeight);

        setIsLoading(false);

        return false; // Return false if you set crop state in here.
    }, []);

    return (
        <StyledModal
            aria-labelledby="upload-image"
            aria-describedby="upload-image"
            open={isOpen}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500
            }}
        >
            <Fade in={isOpen}>
                <ModalBody>
                    <Title>Upload Image</Title>
                    {isUploading ? (
                        <>
                            <Loading />
                            Cropping and Uploading...
                        </>
                    ) : (
                        <>
                            {image ? (
                                <ReactCrop
                                    src={image}
                                    crop={crop}
                                    onImageLoaded={onImageLoaded}
                                    onChange={(newCrop) => setCrop(newCrop)}
                                    circularCrop={circularCrop}
                                />
                            ) : (
                                <div>
                                    <Dropzone {...getRootProps()} image={image} isLoading={isLoading}>
                                        {Controls()}
                                    </Dropzone>
                                    <UploadHelpText>
                                        Accepted formats: .jpg, .jpeg, .png | Maximum file size: 10MB
                                    </UploadHelpText>
                                    <input {...getInputProps()} />
                                </div>
                            )}
                            <Buttons>
                                {isUploading ? 'Cropping and Uploading...' : ''}
                                <Button data-testid={'image-upload-cancel'} onClick={handleClose}>
                                    Cancel
                                </Button>
                                <Button
                                    color="primary"
                                    data-testid={'image-upload-save'}
                                    variant="contained"
                                    disabled={isUploading || !canSave()}
                                    onClick={() => onFinish()}
                                >
                                    Save
                                </Button>
                            </Buttons>
                        </>
                    )}
                </ModalBody>
            </Fade>
        </StyledModal>
    );
};

export default CroppingImageUploadModal;
