import React, { useState } from 'react';

import { Typography, FormControl, FormHelperText, InputLabel, OutlinedInput } from '@material-ui/core';
import styled from 'styled-components';

const StyledTitle = styled(Typography)`
    line-height: 20px;
    color: ${(props) => props.theme.colors.textSecondary};
    font-size: 0.75rem;
    letter-spacing: 0.08333em;
    text-transform: uppercase;
    margin-bottom: 4px;
`;

const Container = styled.div`
    width: 100%;
`;

const SearchURL = styled(Typography)`
    padding-bottom: 8px;
    color: rgba(26, 35, 39, 0.8);
    font-size: 0.875rem;
`;

const TitleTag = styled(Typography)`
    margin-bottom: 8px;
    color: #1a0dab;
    font-size: 1.25rem;
`;

const SearchDescription = styled(Typography)`
    font-size: 0.875rem;
    /* These preview components should't change between light / dark mode accoriding to design */
    color: rgba(26, 35, 39, 0.8);
`;

export const PreviewWrapper = styled.div`
    padding: 1rem;
    margin-bottom: 2rem;
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.colors.border};
    background-color: white;
`;

const FormWrapper = styled.div`
    margin: 2rem 0;

    .MuiFormControl-root {
        width: 100%;
    }

    .MuiFormHelperText-root {
        margin-left: 4px;
        margin-top: 4px;
        color: ${(props) => props.theme.colors.textTertiary};
        font-size: 0.875rem;
        letter-spacing: 0;
        line-height: inherit;
    }
`;

interface SEOSettingsProps {
    contentTitle?: string;
    contentDescription?: string | null;
}

export const SEOSettings: React.FC<SEOSettingsProps> = ({ contentTitle, contentDescription }) => {
    const [urlSlug, setURLSlug] = useState(window?.location?.pathname?.split('/')[2]);
    const [titleTag, setTitleTag] = useState(contentTitle ?? '');
    const [description, setDescription] = useState(contentDescription ?? '');

    const renderSearchURL = (asHelperText?: boolean) => {
        return (
            <SearchURL>
                {`${window?.location?.host}` + `/${window?.location?.pathname?.split('/')[1]}`}
                {asHelperText ? <strong>{`/${urlSlug}`}</strong> : `/${urlSlug}`}
            </SearchURL>
        );
    };

    return (
        <Container>
            <StyledTitle>Search Result Preview</StyledTitle>
            <PreviewWrapper>
                {renderSearchURL()}
                <TitleTag>{titleTag}</TitleTag>
                <SearchDescription>{description}</SearchDescription>
            </PreviewWrapper>
            <FormWrapper>
                <FormControl variant="outlined">
                    <InputLabel htmlFor="url-slug">URL Slug</InputLabel>
                    <OutlinedInput
                        id="url-slug"
                        label="URL Slug"
                        aria-describedby="slug-helper-text"
                        defaultValue={urlSlug}
                        onChange={(e) => setURLSlug(e.target.value)}
                    />
                    <FormHelperText id="slug-helper-text">{renderSearchURL(true)}</FormHelperText>
                </FormControl>
            </FormWrapper>
            <FormWrapper>
                <FormControl variant="outlined">
                    <InputLabel htmlFor="title-tag">Title Tag</InputLabel>
                    <OutlinedInput
                        id="title-tag"
                        label="Title Tag"
                        aria-describedby="title-helper-text"
                        defaultValue={titleTag}
                        onChange={(e) => setTitleTag(e.target.value)}
                    />
                    <FormHelperText id="title-helper-text">
                        Page title displayed on search engine result pages (SERPs) as well as the browser&apos;s title
                        bar or tab. Optimal title length is 55 characters.
                    </FormHelperText>
                </FormControl>
            </FormWrapper>
            <FormWrapper>
                <FormControl variant="outlined">
                    <InputLabel htmlFor="meta-description">Meta Description</InputLabel>
                    <OutlinedInput
                        id="meta-description"
                        label="Meta Description"
                        aria-describedby="meta-helper-text"
                        defaultValue={description}
                        multiline
                        rows={3}
                        maxRows={4}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    <FormHelperText id="meta-helper-text">
                        Brief description of the content on this page displayed on search engine result pages (SERPs).
                    </FormHelperText>
                </FormControl>
            </FormWrapper>
        </Container>
    );
};
