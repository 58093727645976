import { useState, useEffect } from 'react';

import { EntityType } from 'components/ContentContext/Enums';
import { OwnerInfo } from 'components/ContentContext/Interfaces';
import { ClientError } from 'graphql-request';
import gql from 'graphql-tag';
import { useBuildAuthenticatedQueryFn } from 'hooks/useGqlClient/useBuildAuthenticatedQueryFn';
import { Api } from 'lib/ApiConstants';
import { useQuery, UseQueryResult } from 'react-query';

/* eslint-disable import/namespace */
import { ValidEntityAction, CONTENT_ENTITIES } from './types';

/* GraphQL Request to get Owner Action*/
export interface IsAuthorizedOwnerActionRequest {
    owner: OwnerInfo | null;
    entity: EntityType;
    action: ValidEntityAction;
}

/* GraphQL Response for Owner Action */
export interface IsAuthorizedOwnerActionResponse {
    getIsAuthorizedOwnerAction: boolean;
}

/* Exposed hook interface. */
export interface IsAuthorizedOwnerAction {
    isAuthorized: boolean;
    refetch: () => any;
}

/* Individual method to see if a singular action is authorized on behalf of owner (i.e Create Skills). */
const IS_AUTHORIZED_OWNER_ACTION_QUERY = gql`
    query getIsAuthorizedOwnerAction($owner: OwnerInfoInput!, $entity: EntityType, $action: String!) {
        getIsAuthorizedOwnerAction(owner: $owner, entity: $entity, action: $action)
    }
`;

const useGetIsAuthorizedOwnerAction = (
    variables: IsAuthorizedOwnerActionRequest,
    options = {}
): UseQueryResult<IsAuthorizedOwnerActionResponse, ClientError> => {
    const { queryFn } = useBuildAuthenticatedQueryFn<IsAuthorizedOwnerActionResponse, IsAuthorizedOwnerActionRequest>(
        IS_AUTHORIZED_OWNER_ACTION_QUERY,
        variables,
        options
    );

    const queryKey = `useIsAuthorizedOwnerAction-${variables?.owner?.id ?? 'undefined'}-${variables.entity}-${
        variables.action
    }`;

    return useQuery<IsAuthorizedOwnerActionResponse, ClientError>(queryKey, queryFn, options);
};

/**
 * Exposed hook for internal consumption.
 * Determine if `currentuser` can perform Action on behalf of Owner, for entity.
 */
export const useIsAuthorizedOwnerAction = (
    owner: OwnerInfo | null,
    entity: EntityType,
    action: ValidEntityAction
): IsAuthorizedOwnerAction => {
    const [isAuthorized, setIsAuthorized] = useState(false);

    const targetApi = CONTENT_ENTITIES.includes(entity) ? Api.Content : Api.Accounts;
    /* Return a list of the appropriate actions. */
    const { data, status, refetch } = useGetIsAuthorizedOwnerAction(
        { owner, entity, action },
        { api: targetApi, enabled: owner?.id !== null }
    );

    useEffect(() => {
        if (status === 'success' && data?.getIsAuthorizedOwnerAction) {
            setIsAuthorized(data.getIsAuthorizedOwnerAction);
        }
    }, [status, data])

    return { isAuthorized, refetch };
};
