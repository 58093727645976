import React from 'react';

import { defaultMuiTheme } from '@adept-at/lib-react-components';
import styled from 'styled-components';
import { SUB_HEADER_HEIGHT } from 'styles/variables';

const SubHeaderContainer = styled.div<{ $backgroundColor: string | null; $marginBottom?: string }>`
    display: flex; // if we use breakpoint to hide for small screens this needs to be display: none
    flex-shrink: 0;
    padding: 0 1rem;
    color: ${(props) => props.theme.colors.textSecondary};
    height: ${SUB_HEADER_HEIGHT}px;
    background-color: ${(props) => (props.$backgroundColor ? props.$backgroundColor : props.theme.colors.surface)};
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;

    ${(props) => (props.$marginBottom ? `margin-bottom: ${props.$marginBottom};` : null)};

    /* ${defaultMuiTheme.breakpoints.up(
        'sm'
    )} {   // Commenting this out for now, was used to hide SubHeader for TrackTown users
        display: flex;
    } */
`;

interface SubHeaderProps {
    labelProps?: { 'aria-label': string };
    backgroundColor?: string | null;
    marginBottom?: string;
}

const SubHeader: React.FC<SubHeaderProps> = ({
    labelProps = undefined,
    backgroundColor = null,
    marginBottom,
    children
}) => {
    return (
        <SubHeaderContainer
            $backgroundColor={backgroundColor}
            $marginBottom={marginBottom}
            aria-label={labelProps?.['aria-label'] ?? 'subheader for main content actions'}
        >
            {children}
        </SubHeaderContainer>
    );
};

export default SubHeader;
