import React, { useContext, useMemo } from 'react';

import { TooltippedButtonSize, TooltippedIconButton } from '@adept-at/lib-react-components';
import { Divider } from '@material-ui/core';
import {
    mdiArrowRight,
    mdiBookshelf,
    mdiCalendarClock,
    mdiDotsVertical,
    mdiEye,
    mdiEyeOff,
    mdiHeart,
    mdiTrashCan,
    mdiDragHorizontalVariant,
    mdiDelete
} from '@mdi/js';
import Icon from '@mdi/react';
import { BookshelvesContext } from 'components/bookshelves/BookshelvesContext';
import { EntityType } from 'components/ContentContext/Enums';
import { useFavorites } from 'components/dash/Favorites/useFavorites';
import useRemoveFavoriteItem from 'components/dash/hooks/useRemoveFavoriteItem';
import useUpsertFavoriteItem from 'components/dash/hooks/useUpsertFavoriteItem';
import { BasicCollectionInfo } from 'components/dash/interfaces';
import { ScheduleContext } from 'components/dash/Schedule/ScheduleContext';
import { NestedCollectionContext } from 'components/NestedCollection/context/NestedCollectionContext';
import { useModal } from 'hooks/useModal';
import { useOrganizationTerminology } from 'hooks/useOrganizationTerminology';
import { usePopover } from 'hooks/usePopover';
import { useHistory } from 'react-router';
import { StyledListItem, StyledMenu } from 'styles/Shared.styles';

import { RootCollectionContext } from './context/RootCollectionContext';
import { DeleteCollectionDialog } from './DeleteCollectionDialog';
import { useDeleteCollection } from './useDeleteCollection';
import { CollectionBasicDetail } from './useGetNestedCollections/getCollectionBasicDetails';
import { useRemoveCollectionItem } from './useRemoveCollectionItem';
import { useUpdateCollectionVisibility } from './useUpdateCollectionVisibility';

interface CollectionQuickActionProps {
    parentCollectionId: string;
    collection: CollectionBasicDetail;
    url: string | null;
    parentSlug?: string;
    inSubheader?: boolean;
}

const CollectionQuickAction: React.FC<CollectionQuickActionProps> = ({
    url,
    collection,
    parentCollectionId,
    parentSlug,
    inSubheader = false
}) => {
    const { terminology } = useOrganizationTerminology();
    const history = useHistory();
    const { openBookshelfModal } = useContext(BookshelvesContext);
    const { favorites } = useFavorites();
    const { openLearnLaterModal } = useContext(ScheduleContext);
    const { open, anchorEl, handlePopoverClick, handlePopoverClose } = usePopover();
    const {
        modalOpen: deleteDialogIsOpen,
        handleModalClose: closeDeleteDialog,
        handleModalOpen: openDeleteDialog
    } = useModal();

    const {
        isEditMode: isCatalogEditMode,
        parentQuerySlug: rootParentQuerySlug,
        collectionId: editableRootCollectionId,
        collectionIdForEditableCollectionQuery: idForRootEditableCollectionQuery,
        refetchEditableCollection: refetchEditableRoot,
        basicDetailsQueryKey: rootBasicDetailsQueryKey
    } = useContext(RootCollectionContext);

    const {
        isEditMode: isNestedCollectionEditMode,
        collectionId: editableNestedCollectionId,
        collectionSlugs,
        tenantSlug,
        parentQuerySlug,
        collectionIdForEditableCollectionQuery: idForNestedEditableCollectionQuery,
        refetchEditableCollection: refetchEditableNested,
        basicDetailsQueryKey: nestedBasicDetailsQueryKey
    } = useContext(NestedCollectionContext);

    const editedCollectionSlug = useMemo(() => {
        if (parentQuerySlug) {
            return parentQuerySlug;
        }

        return rootParentQuerySlug;
    }, [parentQuerySlug, rootParentQuerySlug]);

    const { mutate: addToFavorites } = useUpsertFavoriteItem();
    const { mutate: removeFromFavorites } = useRemoveFavoriteItem();
    const { mutate: updateCollectionVisibility } = useUpdateCollectionVisibility(
        isCatalogEditMode ? idForRootEditableCollectionQuery : idForNestedEditableCollectionQuery
    );
    const { mutate: removeCollectionFromCatalog } = useRemoveCollectionItem({
        isNestedCollection: isCatalogEditMode ? false : true,
        collectionIdForEditableCollectionQuery: isCatalogEditMode
            ? idForRootEditableCollectionQuery
            : idForNestedEditableCollectionQuery,
        basicDetailsQueryKey: isCatalogEditMode ? rootBasicDetailsQueryKey : nestedBasicDetailsQueryKey,
        parentQuerySlug: editedCollectionSlug,
        refetchEditableCollection: isCatalogEditMode ? refetchEditableRoot : refetchEditableNested
    });

    const { mutate: deleteCollection } = useDeleteCollection({
        collectionId: collection.collectionId,
        parentCollectionId,
        parentSlug,
        editedCollectionSlug
    });

    const hasFavoritedItem = useMemo(
        () =>
            !!favorites.find((favorite) => (favorite as BasicCollectionInfo).collectionId === collection.collectionId),
        [favorites, collection.collectionId]
    );

    const onRemoveCollectionClick = (collectionIdToBeRemoved: string) => {
        const mutateVariables = {
            collectionId: isCatalogEditMode ? editableRootCollectionId : editableNestedCollectionId,
            item: {
                type: EntityType.COLLECTION,
                id: collectionIdToBeRemoved
            }
        };

        removeCollectionFromCatalog(mutateVariables);

        handlePopoverClose();
    };

    const onToggleVisibility = () => {
        updateCollectionVisibility({
            collectionId: isCatalogEditMode ? idForRootEditableCollectionQuery : idForNestedEditableCollectionQuery,
            visible: !collection.visible
        });
        handlePopoverClose();
    };

    const onCloseDeleteDialog = () => {
        closeDeleteDialog();
        handlePopoverClose();
    };

    const onConfirmDeleteCollection = () => {
        deleteCollection(
            { collectionId: idForNestedEditableCollectionQuery }, // here we only are deleting from w/in a nested collection and we want to delete the immutable collection related to the editableCollection
            {
                onSuccess: () => {
                    if (inSubheader) {
                        if (collectionSlugs.length === 1) {
                            //we are one nested collection level deep and want to redirect to catalog
                            history.push(`/${tenantSlug}/catalog`);
                        } else {
                            const currentUrl = window.location.pathname;
                            const parentUrl = currentUrl.substring(0, currentUrl.lastIndexOf('/'));
                            history.push(parentUrl);
                        }
                    }
                }
            }
        );

        onCloseDeleteDialog();
    };

    return (
        <>
            {(isCatalogEditMode || isNestedCollectionEditMode) && !inSubheader ? (
                <TooltippedIconButton
                    size={TooltippedButtonSize.Small}
                    aria-label="Drag to reorder"
                    icon={<Icon path={mdiDragHorizontalVariant} />}
                    title="Drag to reorder"
                />
            ) : null}
            <TooltippedIconButton
                size={TooltippedButtonSize.Small}
                aria-label="More actions for item"
                icon={<Icon path={mdiDotsVertical} />}
                title="More actions"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handlePopoverClick(e);
                }}
            />
            <StyledMenu
                anchorEl={anchorEl}
                open={open}
                onClose={handlePopoverClose}
                onClick={(e) => e.stopPropagation()}
            >
                {!inSubheader && url ? (
                    <StyledListItem button onClick={() => history.push(url)}>
                        <Icon path={mdiArrowRight} /> Go to {terminology.collection}
                    </StyledListItem>
                ) : null}
                {!(isCatalogEditMode || isNestedCollectionEditMode) ? (
                    <div>
                        <StyledListItem
                            button
                            onClick={() => {
                                openLearnLaterModal({ type: EntityType.COLLECTION, id: collection.collectionId });
                                handlePopoverClose();
                            }}
                        >
                            <Icon path={mdiCalendarClock} /> Learn later
                        </StyledListItem>
                        <StyledListItem
                            button
                            onClick={() => {
                                openBookshelfModal({
                                    type: EntityType.COLLECTION,
                                    id: collection.collectionId,
                                    title: collection.title
                                });
                                handlePopoverClose();
                            }}
                        >
                            <Icon path={mdiBookshelf} /> Add to bookshelf
                        </StyledListItem>
                        <StyledListItem
                            button
                            onClick={() => {
                                const item = { type: EntityType.COLLECTION, id: collection.collectionId };
                                if (hasFavoritedItem) {
                                    removeFromFavorites({ item });
                                } else {
                                    addToFavorites({ item });
                                }
                                handlePopoverClose();
                            }}
                        >
                            <Icon path={mdiHeart} /> {`${hasFavoritedItem ? 'Remove from' : 'Add to'} favorites`}
                        </StyledListItem>
                    </div>
                ) : null}
                {(isCatalogEditMode || isNestedCollectionEditMode) && inSubheader ? (
                    <StyledListItem button onClick={onToggleVisibility}>
                        <Icon path={collection.visible ? mdiEyeOff : mdiEye} /> Make{' '}
                        {collection.visible ? 'invisible' : 'visible'}
                    </StyledListItem>
                ) : null}
                {(isCatalogEditMode || isNestedCollectionEditMode) && !inSubheader ? (
                    <StyledListItem
                        button
                        onClick={() => {
                            onRemoveCollectionClick(collection.collectionId);
                        }}
                    >
                        <Icon path={mdiTrashCan} /> Remove from {terminology.collection}
                    </StyledListItem>
                ) : null}
                {(isCatalogEditMode || isNestedCollectionEditMode) && inSubheader ? (
                    <div>
                        <Divider />
                        <StyledListItem button onClick={openDeleteDialog}>
                            <Icon path={mdiDelete} /> Delete
                        </StyledListItem>
                    </div>
                ) : null}
                <DeleteCollectionDialog
                    isOpen={deleteDialogIsOpen}
                    onClose={onCloseDeleteDialog}
                    onConfirm={onConfirmDeleteCollection}
                />
            </StyledMenu>
        </>
    );
};

export default CollectionQuickAction;
