import { useMemo } from 'react';

import { useFavorites } from 'components/dash/Favorites/useFavorites';
import { FavoriteItem } from 'components/dash/hooks/useGetFavorites';
import { BasicCollectionInfoWithProgress, BasicSkillInfoWithProgress } from 'components/dash/interfaces';
import { isSkillComplete, isSkillInProgress, isSkillNotStarted } from 'components/learn/modes/accountability/utils';

import { FavoritesProgressFilter, FavoritesSortBy } from '../constants';

import { useSortFavorites } from './useSortFavorites';

export const useFavoriteFilters = (
    progressFilter: FavoritesProgressFilter,
    searchString: string,
    sortBy: FavoritesSortBy
): { filteredFavorites: FavoriteItem[]; favoritesLoading: boolean } => {
    const { favorites, favoritesLoading } = useFavorites();
    const { sortedFavorites } = useSortFavorites({ favorites: [...favorites], sortBy });

    const filteredFavoritesByProgress = useMemo(() => {
        if (!sortedFavorites) return [];

        if (progressFilter === FavoritesProgressFilter.All) {
            return sortedFavorites;
        }

        if (progressFilter === FavoritesProgressFilter.NotStarted) {
            return sortedFavorites.filter((favorite) => isSkillNotStarted(favorite));
        }

        if (progressFilter === FavoritesProgressFilter.InProgress) {
            return sortedFavorites.filter((favorite) => isSkillInProgress(favorite));
        }

        if (progressFilter === FavoritesProgressFilter.Completed) {
            return sortedFavorites.filter((favorite) => isSkillComplete(favorite));
        }

        return [];
    }, [sortedFavorites, progressFilter]);

    const filteredSearchedFavorites = useMemo(() => {
        if (!searchString) return filteredFavoritesByProgress;

        return filteredFavoritesByProgress.filter((favorite) =>
            (favorite as BasicSkillInfoWithProgress).skillId
                ? (favorite as BasicSkillInfoWithProgress).title.toLowerCase().includes(searchString.toLowerCase())
                : (favorite as BasicCollectionInfoWithProgress).title.toLowerCase().includes(searchString.toLowerCase())
        );
    }, [searchString, filteredFavoritesByProgress]);

    return { filteredFavorites: filteredSearchedFavorites, favoritesLoading };
};
