import React, { useContext, useMemo } from 'react';

import { IconSize, modularScale } from '@adept-at/lib-react-components';
import { Avatar } from '@material-ui/core';
import { useAvatars } from 'context/AvatarsContext/useAvatars';
import { ProfileContext, ProfileType } from 'context/ProfileContext';
import styled from 'styled-components';

interface OrganizationLabelProps {
    organizationId?: string;
}

const OrganizationLabelWrapper = styled.div`
    display: flex;
    font-size: ${modularScale(-1)};

    div {
        height: ${IconSize.Small};
        width: ${IconSize.Small};
        margin-right: 8px;
    }
`;

const OrganizationLabel: React.FC<OrganizationLabelProps> = ({ organizationId }) => {
    const { availableProfiles } = useContext(ProfileContext);

    const profile = useMemo(() => {
        if (organizationId) {
            return availableProfiles.find(
                (profile) => profile.id === organizationId && profile.type === ProfileType.Organization
            );
        }
    }, [organizationId, availableProfiles]);

    const assetIds = useMemo(() => (profile?.imageId ? [profile.imageId] : []), [profile]);
    const { avatars, determineAvatarSourceById } = useAvatars({ assetIds });

    if (profile) {
        return (
            <OrganizationLabelWrapper>
                {avatars && assetIds.length > 0 ? (
                    <Avatar alt={`${profile.label} logo`} src={determineAvatarSourceById(assetIds[0])} />
                ) : null}
                {profile.label}
            </OrganizationLabelWrapper>
        );
    }

    return null;
};

export default OrganizationLabel;
