import React, { createContext, useCallback, useContext, useState, useEffect, useMemo } from 'react';

import { useMediaQuery } from '@material-ui/core';
import json2mq from 'json2mq';
import { SIDEBAR_WIDTH, SKILL_CONTENT_WIDTH } from 'styles/variables';

import { CommentContext } from './Comments/CommentContext';

export enum SidebarTab {
    Comments,
    Outline,
    Guidance
}

interface OpenSidebarInterface {
    isOpen: boolean;
    savePreference?: boolean;
}

interface SidebarContextInterface {
    isOpen: boolean;
    openSidebar: (options: OpenSidebarInterface) => void;

    overlay: boolean;
    setOverlay: (overlay: boolean) => void;

    activeTab: SidebarTab;
    setActiveTab: (activeTab: SidebarTab) => void;

    close: () => void;
}

const SidebarContext = createContext({} as SidebarContextInterface);

const { Provider, Consumer } = SidebarContext;

const SIDEBAR_PREFERENCE_KEY = 'sidebar_preference';

export enum SidebarPreference {
    open = 'open',
    collapsed = 'collapsed'
}

const SidebarProvider: React.FC = ({ children }) => {
    const { setParent } = useContext(CommentContext);

    // openByDefault is used to detect window resizing, but can be incorrect on initial render, causing a jitter :/
    const openByDefault = useMediaQuery(json2mq({ minWidth: SKILL_CONTENT_WIDTH + SIDEBAR_WIDTH }));
    // openByWindowWidth is correct on initial render, but can't detect resizing
    const openByWindowWidth = useMemo(() => (window?.innerWidth ?? 0) > SKILL_CONTENT_WIDTH + SIDEBAR_WIDTH, []);

    const openByPreference = useMemo(() => localStorage.getItem(SIDEBAR_PREFERENCE_KEY) === SidebarPreference.open, []);

    const [activeTab, setActiveTab] = useState<SidebarTab>(SidebarTab.Comments);
    const [isOpen, setIsOpen] = useState(openByWindowWidth ? openByPreference : openByWindowWidth);
    const [overlay, setOverlay] = useState(false);

    const openSidebar = useCallback(
        ({ isOpen, savePreference }: OpenSidebarInterface) => {
            setIsOpen(isOpen ?? true);

            // at smaller screen widths manually opening and closing is done out of necessity,
            // at larger screen widths it is a preference
            if (savePreference && openByDefault) {
                const value = isOpen ? SidebarPreference.open : SidebarPreference.collapsed;

                try {
                    localStorage.setItem(SIDEBAR_PREFERENCE_KEY, value);
                } catch (err) {
                    console.log(err);
                }
            }
        },
        [openByDefault]
    );

    const close = useCallback(() => {
        setParent('');
        openSidebar({ isOpen: false, savePreference: true });
    }, [setParent, openSidebar]);

    useEffect(() => {
        setOverlay(!openByDefault);

        const sidebarPreference = localStorage.getItem(SIDEBAR_PREFERENCE_KEY);

        if (!sidebarPreference || !openByDefault) {
            // no preference? open or close depending on screen width
            // also should automatically close when screen width is no longer open by default
            setIsOpen(openByDefault);
        } else if (sidebarPreference === SidebarPreference.open && openByDefault) {
            // preference to have open? automatically open when screen width allows
            setIsOpen(true);
        }
    }, [openByDefault]);

    return (
        <Provider
            value={{
                isOpen,
                openSidebar,

                overlay,
                setOverlay,

                activeTab,
                setActiveTab,

                close
            }}
        >
            {children}
        </Provider>
    );
};

export { SidebarContext, SidebarProvider, Consumer as SidebarConsumer };
