import React, { useContext, useMemo } from 'react';

import { IconSize, inProgressIconPath, progressStateToStatus } from '@adept-at/lib-react-components';
import { mdiCheckboxMarkedCircleOutline, mdiCircleOutline } from '@mdi/js';
import Icon from '@mdi/react';
import styled from 'styled-components';
import { ProgressState } from 'utils/progress';

import { LearnContext } from '../LearnContext';
import { getEntityProgressStatus } from '../modes/accountability/utils';

const ProgressWrapper = styled.div<{ $progressState: ProgressState }>`
    display: flex;
    align-items: center;
    color: ${(props) =>
        props.$progressState === ProgressState.COMPLETE ? props.theme.colors.success : props.theme.colors.textTertiary};

    svg {
        margin-right: 0.5rem;
    }
`;

export const ProgressIndicator: React.FC = () => {
    const { skill } = useContext(LearnContext);

    const progressState = useMemo(() => getEntityProgressStatus(skill), [skill]);

    const iconPath = useMemo(() => {
        switch (progressState) {
            case ProgressState.COMPLETE:
                return mdiCheckboxMarkedCircleOutline;
            case ProgressState.IN_PROGRESS:
                return inProgressIconPath;
            default:
                return mdiCircleOutline;
        }
    }, [progressState]);

    return (
        <ProgressWrapper $progressState={progressState}>
            <Icon path={iconPath} size={IconSize.Medium} />
            {progressStateToStatus(progressState)}
        </ProgressWrapper>
    );
};
