import React, { createContext, useState, RefObject, useEffect, useCallback } from 'react';

interface VideoRefsContextInterface {
    videoRefs: Record<string, RefObject<HTMLVideoElement>>;
    addVideoRef: (AddVideoRefInterface) => void;
}

const VideoRefsContext = createContext(undefined as unknown as VideoRefsContextInterface);

const { Provider, Consumer } = VideoRefsContext;

const VideoRefsProvider: React.FC = ({ children }) => {
    const [videoRefs, setVideoRefs] = useState<Record<string, RefObject<HTMLVideoElement>>>({});

    const addVideoRef = (ref: RefObject<HTMLVideoElement>) => {
        if (ref?.current?.id) {
            setVideoRefs({
                ...videoRefs,
                [ref.current.id]: ref
            });
        }
    };

    const onPlayPauseOtherVideos = useCallback(
        (event) => {
            Object.values(videoRefs).map((ref) => {
                const currentRef = ref?.current;
                const targetRef = event?.target;

                if (currentRef?.id !== targetRef?.id && currentRef?.pause) {
                    currentRef.pause();
                }
            });
        },
        [videoRefs]
    );

    useEffect(() => {
        Object.values(videoRefs).map((ref) => {
            const currentRef = ref?.current;

            if (currentRef) {
                currentRef.addEventListener('play', onPlayPauseOtherVideos);
            }
        });

        return () =>
            Object.values(videoRefs).forEach((ref) => {
                const currentRef = ref?.current;

                if (currentRef) {
                    currentRef.removeEventListener('play', onPlayPauseOtherVideos);
                }
            });
    }, [videoRefs, onPlayPauseOtherVideos]);

    return (
        <Provider
            value={{
                videoRefs,
                addVideoRef
            }}
        >
            {children}
        </Provider>
    );
};

export { VideoRefsContext, VideoRefsProvider, Consumer as VideoRefsConsumer };
