import { gql, GraphQLClient } from 'graphql-request';
import { QueryFunction, QueryKey } from 'react-query';

import buildGqlQueryFn from '../../../hooks/useGqlClient/helpers/buildGqlQueryFn';

export interface BookshelfMinimal {
    bookshelfId: string;
    createdAt: string;
    title: string;
    items: {
        id: string;
    }[];
}

export interface GetItemIdsInBookshelvesResponse {
    getBookshelves: BookshelfMinimal[];
}

const BOOKSHELF_ITEM_LIMIT = 100;

const bookshelfItemIdsFragment = gql`
    bookshelfId
    createdAt
    title
    items {
        id
    }
`;

/* Minimal query for identifying when items are already in a bookshelf */
const GET_ITEM_IDS_IN_BOOKSHELVES = gql`
    query getBookshelves ($itemsPerBookshelf: Int) {
        getBookshelves (itemsPerBookshelf: $itemsPerBookshelf) {
            ${bookshelfItemIdsFragment}
        }
    }
`;

export const buildGetItemIdsInBookshelvesQueryKey = (userId?: string): QueryKey => ['getItemIdsInBookshelves', userId];

const getItemIdsInBookshelves = (client: GraphQLClient): QueryFunction<GetItemIdsInBookshelvesResponse> =>
    buildGqlQueryFn<GetItemIdsInBookshelvesResponse>(client, GET_ITEM_IDS_IN_BOOKSHELVES, {
        itemsPerBookshelf: BOOKSHELF_ITEM_LIMIT
    });

export default getItemIdsInBookshelves;
