import { useCallback, useMemo } from 'react';

import { OptimistMutationContext, useOptimist } from '@adept-at/lib-react-optimist';
import {
    buildGetCollaborationsByOwnerQueryKey,
    GetCollaborationsByOwnerResponse
} from 'components/CollaborationTable/getCollaborationsByOwner';
import { OwnerInfo } from 'components/ContentContext/Interfaces';
import {
    buildGetNestedCollectionBySlugQueryKey,
    GetNestedCollectionBySlugQueryResponse
} from 'components/NestedCollection/context/getNestedCollectionBySlug';
import {
    buildGetCollectionQueryKey,
    CollectionProductDetails,
    GetCollectionResponse
} from 'components/studio/collection/getCollection';
import { ClientError, gql } from 'graphql-request';
import { useBuildAuthenticatedMutationFn } from 'hooks/useGqlClient/useBuildAuthenticatedMutationFn';
import { Api } from 'lib/ApiConstants';
import { InfiniteData, useMutation, UseMutationResult } from 'react-query';

import { PurchaseInterval, SagaStatus, IntervalType } from './types';

export interface CreateProductResult {
    createProduct: {
        executionId: string;
        startedAt: string;
        status: SagaStatus;
    };
}

export interface CreateProductInput {
    userId: string;
    tenantId: string;
    ownerInput: OwnerInfo;
    productName: string;
    productData: { collectionId: string };
}

export interface CreatePriceInput {
    interval: PurchaseInterval;
    intervalType: IntervalType;
    pricePerUnitCents: number;
}

export interface CreateProductVariables {
    input: CreateProductInput;
    priceInput?: CreatePriceInput;
}

const CREATE_PRODUCT_MUTATION = gql`
    mutation createProduct($input: CreateProductInput!, $priceInput: CreatePriceInput) {
        createProduct(input: $input, priceInput: $priceInput) {
            startedAt
            executionId
        }
    }
`;

export const useCreateProduct = (
    setSagaId: (id: string) => void,
    setSagaStatus: (status: SagaStatus) => void,
    ownerInfo: OwnerInfo | null,
    entitySlug: string | null,
    collectionId: string | null
): UseMutationResult<CreateProductResult, ClientError, CreateProductVariables, OptimistMutationContext> => {
    const { mutationFn } = useBuildAuthenticatedMutationFn<CreateProductResult, CreateProductVariables>(
        CREATE_PRODUCT_MUTATION,
        { api: Api.Payment }
    );
    const { mutationOptions, registerUpdater } = useOptimist<
        CreateProductResult,
        ClientError,
        CreateProductVariables
    >();

    mutationOptions.onSuccess = (data) => {
        setSagaId(data.createProduct.executionId);
        setSagaStatus(data.createProduct.status);
    };

    const getCollaborationsByOwnerKey = useMemo(
        () => (ownerInfo ? buildGetCollaborationsByOwnerQueryKey(ownerInfo) : null),
        [ownerInfo]
    );

    const getStudiCollectionkey = useMemo(
        () => (collectionId ? buildGetCollectionQueryKey(collectionId) : null),
        [collectionId]
    );

    const getEntityBySlugKey = useMemo(
        () => (entitySlug ? buildGetNestedCollectionBySlugQueryKey(entitySlug) : null),
        [entitySlug]
    );

    const updateProductDetails = useCallback(
        (createProductVariables: CreateProductVariables): CollectionProductDetails => {
            return {
                productId: 'wow',
                prices: [
                    { priceId: 'fake', pricePerUnitCents: 0 },
                    {
                        priceId: 'fake2',
                        pricePerUnitCents: createProductVariables.priceInput?.pricePerUnitCents ?? 500
                    }
                ]
            };
        },
        []
    );

    if (getEntityBySlugKey !== null) {
        registerUpdater<GetNestedCollectionBySlugQueryResponse>(
            getEntityBySlugKey,
            ({ getEntityBySlug }, createProductVariables) => {
                return {
                    getEntityBySlug: {
                        ...getEntityBySlug,
                        productDetails: updateProductDetails(createProductVariables)
                    }
                };
            },
            { refetchQuery: false }
        );
    }

    if (getStudiCollectionkey !== null) {
        registerUpdater<GetCollectionResponse>(
            getStudiCollectionkey,
            ({ getCollection }, createProductVariables) => {
                return {
                    getCollection: {
                        ...getCollection,
                        productDetails: updateProductDetails(createProductVariables)
                    }
                };
            },
            { refetchQuery: false }
        );
    }

    if (getCollaborationsByOwnerKey !== null) {
        registerUpdater<InfiniteData<GetCollaborationsByOwnerResponse>>(
            getCollaborationsByOwnerKey,
            (previous, createProductVariables) => {
                const updatedPages = (previous?.pages ?? []).map((page) => {
                    return {
                        ...page,
                        getCollaborationsByOwner: {
                            ...page?.getCollaborationsByOwner,
                            items: (page?.getCollaborationsByOwner?.items ?? []).map((item) =>
                                'collectionId' in item.item &&
                                item.item.collectionId === createProductVariables.input.productData.collectionId
                                    ? {
                                          ...item,
                                          item: {
                                              ...item.item,
                                              productDetails: updateProductDetails(createProductVariables)
                                          }
                                      }
                                    : item
                            )
                        }
                    };
                });

                return {
                    ...previous,
                    pages: updatedPages
                };
            },
            { refetchQuery: false }
        );
    }

    return useMutation<CreateProductResult, ClientError, CreateProductVariables, OptimistMutationContext>(
        mutationFn,
        mutationOptions
    );
};
