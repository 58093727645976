import axios, { AxiosError } from 'axios';
import { useMutation, UseMutationResult } from 'react-query';

interface UploadFileForIntentResult {
    something: 'test';
}

interface UploadFileForIntentParameters {
    uploadUrl: string;
    file: File;
    onProgress?: (percentComplete: number) => void;
}

export const useUploadFileForIntent = (): UseMutationResult<
    UploadFileForIntentResult,
    AxiosError<any>,
    UploadFileForIntentParameters
> =>
    useMutation<UploadFileForIntentResult, AxiosError, UploadFileForIntentParameters>(
        ({ uploadUrl, file, onProgress = () => {} }) =>
            axios.put(uploadUrl, file, {
                onUploadProgress: function (progressEvent) {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    onProgress(percentCompleted);
                }
            })
    );
