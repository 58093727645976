import { GraphQLClient } from 'graphql-request';
import { RequestDocument } from 'graphql-request/dist/types';
import { MutationFunction } from 'react-query';

/**
 * This is a function that returns another function, the exact format react-query needs.
 * Nearly the entire purpose of this is to handle the authenticated GraphQLClient, and hopefully makes the double/triple function easier to grok.
 *
 * @param client This should be the authenticated client from this hook, not a standard client, in nearly all cases.
 * @param mutation @see RequestDocument , usually created by graphql-tag.
 */
const buildGqlMutationFn = <TData = unknown, TVariables = unknown>(
    client: GraphQLClient,
    mutation: RequestDocument
): MutationFunction<TData, TVariables> => {
    // Query Function context contains the QueryKey, and that's about it. You can use the variables in the query key, but we are not by default.
    const mutationFn = async (variables: TVariables): Promise<TData> => {
        return await client.request<TData, TVariables>(mutation, {
            ...variables
        });
    };

    return mutationFn;
};

export default buildGqlMutationFn;
