import { gql, GraphQLClient } from 'graphql-request';
import buildGqlQueryFn from 'hooks/useGqlClient/helpers/buildGqlQueryFn';
import { QueryFunction, QueryKey } from 'react-query';

import { MediaStatus } from '../PublisherContext';

export interface GetUsersForSessionResponse {
    getUsersForMeetingSession: UserForSession[];
}

interface UserForSession {
    userId: string;
    camera: MediaStatus;
    version: number;
}

export const buildGetUsersForSessionQueryKey = (sessionId?: string): QueryKey => ['getUsersForSession', sessionId];

const GET_USERS_FOR_SESSION = gql`
    query getUsersForMeetingSession($sessionId: UUIDv4!, $excludeUser: Boolean) {
        getUsersForMeetingSession(sessionId: $sessionId, excludeUser: $excludeUser) {
            userId
            camera
            version
        }
    }
`;

const getUsersForSession = (
    client: GraphQLClient,
    variables: { sessionId: string }
): QueryFunction<GetUsersForSessionResponse> =>
    buildGqlQueryFn<GetUsersForSessionResponse>(client, GET_USERS_FOR_SESSION, { ...variables, excludeUser: true });

export default getUsersForSession;
