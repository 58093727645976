import { MeetingSession } from 'components/rooms/hooks/getSessionsForChannel';
import * as yup from 'yup';

import { MessageEventType } from '../..';
import AckableAdepteductMessage from '../../../base/AckableAdepteductMessage';
import PossibleEventFields from '../../PossibleEventFields';

export enum SessionChangedAction {
    Added = 'added',
    Removed = 'removed',
    Changed = 'changed',
    Exchange = 'exchange'
}

export interface EventFieldsSessionChanged extends PossibleEventFields {
    action: SessionChangedAction;
    session: MeetingSession;
}

export default class ChannelMeetingSessionChanged extends AckableAdepteductMessage<EventFieldsSessionChanged> {
    schema = yup.object({
        action: yup.string().oneOf(['added', 'removed', 'changed']),
        session: yup.object({
            id: yup.string().required(),
            meetingId: yup.string(),
            startTime: yup.string(),
            createdBy: yup.string(),
            details: yup.object()
        })
    });

    type = MessageEventType.ChannelMeetingSessionChanged;
}
