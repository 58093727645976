import { useMemo } from 'react';

import { useAreVersionsMatching } from 'components/NestedCollection/hooks/versions/useAreVersionsMatching';
import { CollectionChild } from 'components/RootProfileCollection/context/useGetTenantProfileForOwner';

import { EntityChild } from '../context/getNestedCollectionBySlug';

enum CantAttachReasons {
    CHILDREN = 'Cannot create a product while this collection contains other collections. Please remove the collection and publish your changes to continue.',
    ITEMS_VERSION = 'Cannot create a product while this collection has pending changes. Please publish your changes to continue.',
    CAN_ATTACH = ''
}

export const useCanAttachProduct = ({
    collectionId,
    children,
    itemsVersion
}: {
    children: EntityChild[];
    itemsVersion: number;
    collectionId: string;
}): { canAttach: boolean; isLoading: boolean; reason: CantAttachReasons } => {
    const { isLoading, isMatching } = useAreVersionsMatching(itemsVersion, collectionId);

    const containsCollections = useMemo(() => {
        return children.some(({ type }) => type === CollectionChild.Collection);
    }, [children]);

    const reason = useMemo(() => {
        if (containsCollections) {
            return CantAttachReasons.CHILDREN;
        }

        if (!isMatching) {
            return CantAttachReasons.ITEMS_VERSION;
        }
        return CantAttachReasons.CAN_ATTACH;
    }, [isMatching, containsCollections]);

    return { isLoading, canAttach: isMatching && !containsCollections, reason };
};
