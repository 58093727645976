import React from 'react';

import { FormControlLabel, Switch } from '@material-ui/core';

interface Props {
    allowMultiVotes: boolean;
    onChange: <TComponentFieldValue>(field: string) => (value: TComponentFieldValue) => void;
}

export const ToggleMultipleVotes: React.FC<Props> = ({ allowMultiVotes, onChange }) => (
    <FormControlLabel
        control={
            <Switch
                data-testid="pollEdit-toggleMultipleVotes"
                checked={allowMultiVotes}
                onChange={(e) => onChange('allowMultiVotes')(Boolean(e.target.checked))}
                name="allowMultiVotes"
                color="primary"
            />
        }
        labelPlacement="start"
        label="Allow multiple votes"
    />
);
