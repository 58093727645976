import React, { FC, createContext, useContext, useMemo } from 'react';

import { ClientError } from 'graphql-request';
import { QueryStatus, RefetchOptions, QueryObserverResult } from 'react-query';
import { isTrue } from 'utils/isTrue';

import { ProfileContext, ProfileType } from '../ProfileContext';

import { useGetOrganizationByAlias, Organization, GetOrganizationByAliasResult } from './useGetOrganizationByAlias';
import { useGetOrganizationGroups, OrganizationGroup, GetOrganizationGroupsResponse } from './useGetOrganizationGroups';
import {
    useGetOrganizationInvitations,
    OrganizationInvitationDetails,
    GetOrganizationInvitationsResponse
} from './useGetOrganizationInvitations';

export enum GroupType {
    GROUP = 'GROUP',
    JOB_ROLE = 'JOB_ROLE',
    PERSONAL = 'PERSONAL',
    OWNER = 'OWNER'
}

export const isGroup = (type: GroupType): boolean => type !== GroupType.JOB_ROLE && type !== GroupType.PERSONAL;
export const isJobRole = (type: GroupType): boolean => type === GroupType.JOB_ROLE;

export interface OrganizationProviderInterface {
    organization?: Organization;
    fetchOrganization: (
        options?: RefetchOptions | undefined
    ) => Promise<QueryObserverResult<GetOrganizationByAliasResult, ClientError>>;
    organizationStatus: QueryStatus;

    organizationInvitations?: OrganizationInvitationDetails[];
    fetchOrganizationInvitations: (
        options?: RefetchOptions | undefined
    ) => Promise<QueryObserverResult<GetOrganizationInvitationsResponse, ClientError>>;
    organizationInvitationsStatus: QueryStatus;

    organizationGroups?: OrganizationGroup[];
    fetchOrganizationGroups: (
        options?: RefetchOptions | undefined
    ) => Promise<QueryObserverResult<GetOrganizationGroupsResponse, ClientError>>;
    organizationGroupsStatus: QueryStatus;
}

const OrganizationContext = createContext({} as OrganizationProviderInterface);

const { Provider, Consumer } = OrganizationContext;

const OrganizationProvider: FC = ({ children }) => {
    const { currentProfile } = useContext(ProfileContext);
    const { alias, id: organizationId } = currentProfile ?? {};

    const currentProfileIsOrganization: boolean = useMemo(
        () => currentProfile?.type === ProfileType.Organization,
        [currentProfile]
    );

    const {
        status: organizationStatus,
        data: organizationData,
        refetch: fetchOrganization
    } = useGetOrganizationByAlias(alias ?? '', { enabled: currentProfileIsOrganization && isTrue(alias) });

    const organization = useMemo(() => organizationData?.getOrganizationByAlias, [organizationData]);

    const {
        status: organizationInvitationsStatus,
        data: organizationInvitationData,
        refetch: fetchOrganizationInvitations
    } = useGetOrganizationInvitations(
        {
            organizationId: organizationId ?? '',
            mostRecentOnly: true
        },
        { enabled: currentProfileIsOrganization && isTrue(organizationId) }
    );

    const organizationInvitations = useMemo(
        () => organizationInvitationData?.getOrganizationInvitations,
        [organizationInvitationData]
    );

    const {
        status: organizationGroupsStatus,
        data: organizationGroupsData,
        refetch: fetchOrganizationGroups
    } = useGetOrganizationGroups(
        { organizationId: organizationId ?? '' },
        { enabled: currentProfileIsOrganization && isTrue(organizationId) }
    );

    const organizationGroups = useMemo(() => organizationGroupsData?.getOrganizationGroups, [organizationGroupsData]);

    return (
        <Provider
            value={{
                organization,
                fetchOrganization,
                organizationStatus,
                organizationInvitations,
                fetchOrganizationInvitations,
                organizationInvitationsStatus,
                organizationGroups,
                fetchOrganizationGroups,
                organizationGroupsStatus
            }}
        >
            {children}
        </Provider>
    );
};

export { OrganizationContext, OrganizationProvider, Consumer as AccountConsumer };
