import { useMemo } from 'react';

import { OptimistMutationContext, OptimistRegisterUpdaterOptions, useOptimist } from '@adept-at/lib-react-optimist';
import {
    buildGetEditableCollectionQueryKey,
    GetEditableCollectionResponse
} from 'components/RootProfileCollection/useGetEditableCollection';
import { ClientError } from 'graphql-request';
import gql from 'graphql-tag';
import { useBuildAuthenticatedMutationFn } from 'hooks/useGqlClient/useBuildAuthenticatedMutationFn';
import { Api } from 'lib/ApiConstants';
import { useSnackbar } from 'notistack';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { setCollectionEditedInSessionStorage } from 'utils/collection';

const REMOVE_COLLECTION_IMAGE_QUERY = gql`
    mutation removeCollectionImage(
        $collectionId: UUIDv4!
        $collectionImageType: CollectionImageType!
        $assetId: UUIDv4!
    ) {
        removeCollectionImage(collectionId: $collectionId, imageType: $collectionImageType, assetId: $assetId) {
            infoVersion
            itemsVersion
        }
    }
`;

export interface UpdateCollectionMetaResult {
    updateCollectionMetadata: { infoVersion: number; itemsVersion: number };
}

interface UpateCollectionImageInput {
    collectionId: string;
    collectionImageType: string;
    assetId: string;
}

interface UseUpateCollectionImageInput {
    collectionId: string;
    parentQuerySlug: string;
}

export const useRemoveCollectionImage = ({
    collectionId,
    parentQuerySlug
}: UseUpateCollectionImageInput): UseMutationResult<
    UpdateCollectionMetaResult,
    ClientError,
    UpateCollectionImageInput,
    OptimistMutationContext
> => {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const collectionQueryKey = useMemo(() => buildGetEditableCollectionQueryKey(collectionId), [collectionId]);

    const { registerUpdater: registerUpdateProfileMetaUpdater, mutationOptions: UpdateProfileMetaMutationOptions } =
        useOptimist<UpdateCollectionMetaResult, ClientError, UpateCollectionImageInput>();

    const UpdateCollectionMetaOptions: OptimistRegisterUpdaterOptions<
        UpdateCollectionMetaResult,
        ClientError,
        UpateCollectionImageInput
    > = useMemo(() => {
        return {
            onError: () => {
                enqueueSnackbar('An error occurred while updating collection.', { variant: 'error' });
            },
            onSuccess: (data, request) => {
                setCollectionEditedInSessionStorage(request.collectionId, parentQuerySlug);

                // update versions on editableCollection from response to keep in sync w/ what backend is expecting
                queryClient.setQueryData<GetEditableCollectionResponse>(collectionQueryKey, (previous) => {
                    if (!previous) return { getEditableCollection: {} } as GetEditableCollectionResponse;

                    return {
                        getEditableCollection: {
                            ...previous?.getEditableCollection,
                            itemsVersion: data.updateCollectionMetadata.itemsVersion,
                            infoVersion: data.updateCollectionMetadata.infoVersion
                        }
                    };
                });
            },
            refetchQuery: false
        };
    }, [enqueueSnackbar, queryClient, collectionQueryKey, parentQuerySlug]);

    registerUpdateProfileMetaUpdater<GetEditableCollectionResponse>(
        collectionQueryKey,
        (previous, newCollectionImage) => {
            const updatedCollection = {
                ...previous?.getEditableCollection,
                [newCollectionImage?.collectionImageType]: null
            };

            return {
                getEditableCollection: updatedCollection
            };
        },
        UpdateCollectionMetaOptions
    );

    const { mutationFn } = useBuildAuthenticatedMutationFn<UpdateCollectionMetaResult, UpateCollectionImageInput>(
        REMOVE_COLLECTION_IMAGE_QUERY,
        { api: Api.Content }
    );

    return useMutation<UpdateCollectionMetaResult, ClientError, UpateCollectionImageInput, OptimistMutationContext>(
        mutationFn,
        { ...UpdateProfileMetaMutationOptions }
    );
};
