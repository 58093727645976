import { ClientError } from 'graphql-request';
import gql from 'graphql-tag';
import { useBuildAuthenticatedQueryFn } from 'hooks/useGqlClient/useBuildAuthenticatedQueryFn';
import { Api } from 'lib/ApiConstants';
import { QueryKey, useQuery, UseQueryResult } from 'react-query';

import { ContentAccessSeat } from './types';

const queryName = 'getContentAccessGroupSeatsForUser';
export const buildGetContentAccessGroupSeatsQueryKey = (): QueryKey => [queryName];
const REFETCH_SEATS_INTERVAL = 1000;
export const GET_ACCESS_GROUP_SEATS = gql`
    query ${queryName}($active: Boolean!) {
        ${queryName}(active: $active) {
            userId
            assignedAt
            assignedBy
            active
            collectionId
            endsAt
            startsAt
        }
    }
`;

export interface GetContentAccessGroupSeatsResponse {
    getContentAccessGroupSeatsForUser: ContentAccessSeat[];
}

export interface useGetContentAccessGroupSeatsForUserVariables {
    active: boolean;
}

export const useGetContentAccessGroupSeatsForUser = (
    variables: useGetContentAccessGroupSeatsForUserVariables,
    enabled = true,
    refetch = false
): UseQueryResult<GetContentAccessGroupSeatsResponse, ClientError> => {
    const { queryFn } = useBuildAuthenticatedQueryFn<
        GetContentAccessGroupSeatsResponse,
        useGetContentAccessGroupSeatsForUserVariables
    >(GET_ACCESS_GROUP_SEATS, variables, { api: Api.Content });

    return useQuery<GetContentAccessGroupSeatsResponse, ClientError>(buildGetContentAccessGroupSeatsQueryKey(), {
        queryFn,
        enabled,
        refetchInterval: refetch ? REFETCH_SEATS_INTERVAL : false
    });
};
