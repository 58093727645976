import styled from 'styled-components';

export const Status = styled.div`
    display: flex;
    align-items: center;

    svg {
        width: 16px;
        margin-left: 0.5rem;
    }
`;

export const StatusMessage = styled.div`
    display: flex;
    align-items: center;
`;

export const Green = styled(StatusMessage)`
    color: ${(props) => props.theme.colors.success};
`;

export const Yellow = styled(StatusMessage)`
    color: ${(props) => props.theme.colors.warning};
`;

export const Red = styled(StatusMessage)`
    color: ${(props) => props.theme.colors.error};
`;
