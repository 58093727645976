import React, { useContext } from 'react';

import { IconSize, defaultMuiTheme } from '@adept-at/lib-react-components';
import {
    Menu,
    useMediaQuery,
    useTheme as useMuiTheme,
    makeStyles,
    createStyles,
    Theme,
    Avatar
} from '@material-ui/core';
import { mdiAccountCog, mdiExitToApp, mdiMenuDown } from '@mdi/js';
import Icon from '@mdi/react';
import { useAvatars } from 'context/AvatarsContext/useAvatars';
import { CurrentUser, useCurrentUser } from 'hooks/useCurrentUser';
import { usePopover } from 'hooks/usePopover';
import { Link } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';

const StyledMenu = styled(Menu)`
    ${defaultMuiTheme.breakpoints.down('xs')} {
        top: 1rem;
        right: 1rem;
        left: 1rem;
        width: calc(100% - 2rem);
    }
    .MuiList-root {
        background: ${(props) => props.theme.colors.floatingSurface};
    }
`;

const MenuWrapper = styled.div<{ onClick }>`
    margin-right: 1rem;
    ${defaultMuiTheme.breakpoints.up('sm')} {
        margin-right: 0.5rem;
    }

    display: flex;
    align-items: center;
    cursor: pointer;
`;

const IconWrapper = styled.div`
    display: none;
    ${defaultMuiTheme.breakpoints.up('sm')} {
        display: flex;
    }

    align-items: center;

    svg {
        width: ${IconSize.Medium};
    }
`;

interface LinkProps {
    color?: string;
}

const StyledMenuItem = styled.li`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    font-weight: 600;
    font-size: ${(props) => props.theme.fonts.textSizeSmall};

    svg {
        margin-right: 0.5rem;
    }
`;

const HeaderAvatar = styled(Avatar)`
    height: 40px;
    width: 40px;
`;

const MenuAvatar = styled(Avatar)`
    height: 74px;
    width: 74px;
    margin-bottom: 1rem;
`;

const StyledMenuContainer = styled.div`
    padding: 1.2rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const StyledLink = styled(Link)<LinkProps>`
    font-size: ${(props) => props.theme.ms(-1)};
    display: flex;
    align-items: center;
    color: ${(props) => props.color || 'unset'};
`;

const StyledAnchor = styled.a`
    font-size: ${(props) => props.theme.ms(-1)};
    text-align: center;
    color: ${(props) => props.theme.colors.textSecondary};

    &:first-child {
        margin-right: 0.75rem;
    }

    &:last-child {
        margin-left: 0.75rem;
    }
`;

const StyledMenuRow = styled.div`
    display: flex;
    justify-content: space-between;
`;

const StyledDisplayName = styled.h5`
    font-size: ${(props) => props.theme.ms(0)};
    font-weight: 500;
`;

const StyledEmail = styled.p`
    margin-bottom: 2rem;
    font-size: ${(props) => props.theme.ms(0)};
    font-weight: normal;
    color: ${(props) => props.theme.colors.textSecondary};
`;

const displayName = (user: CurrentUser | null | undefined): string | undefined => {
    if (user?.firstName && user?.lastName) {
        return `${user.firstName} ${user.lastName}`;
    }

    return user?.alias;
};

const displayPrimaryEmail = (user: CurrentUser | null | undefined) => {
    const primaryEmailAddress = user?.emails?.find((email) => email?.primary)?.address;

    if (primaryEmailAddress) {
        return primaryEmailAddress;
    }

    return user?.emails?.find((email) => email?.address)?.address;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mobileMenu: {
            [theme.breakpoints.down('xs')]: {
                top: '1rem',
                right: '1rem',
                left: '1rem',
                width: 'calc(100% - 2rem)'
            }
        }
    })
);

const AccountMenu: React.FC = () => {
    const { currentUser } = useCurrentUser();
    const theme = useContext(ThemeContext);
    const { avatars } = useAvatars({ assetIds: currentUser?.image ? [currentUser?.image] : [] });
    const { open, anchorEl, handlePopoverClick, handlePopoverClose } = usePopover();

    const muiTheme = useMuiTheme();
    const isMobile = useMediaQuery(muiTheme.breakpoints.down('xs'));

    const getAvatarImage = () => {
        if (!(currentUser?.image && avatars[currentUser?.image])) {
            return;
        }

        return avatars[currentUser.image].crop ?? avatars[currentUser.image].image ?? undefined;
    };

    const styles = useStyles();

    return (
        <>
            <MenuWrapper aria-label="account menu dropdown" onClick={handlePopoverClick}>
                <HeaderAvatar src={getAvatarImage()} alt={currentUser?.alias} />
                <IconWrapper>
                    <Icon path={mdiMenuDown} />
                </IconWrapper>
            </MenuWrapper>

            <StyledMenu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handlePopoverClose}
                classes={{ paper: styles.mobileMenu }}
            >
                <StyledMenuContainer>
                    <MenuAvatar src={getAvatarImage()} alt={currentUser?.alias} />
                    <StyledDisplayName>{displayName(currentUser)}</StyledDisplayName>
                    <StyledEmail>{displayPrimaryEmail(currentUser)}</StyledEmail>

                    {isMobile ? null : (
                        <StyledMenuItem>
                            <StyledLink color={theme.colors.primaryLighter} to={'/settings/account'}>
                                <Icon size={IconSize.Small} color={theme.colors.primaryLighter} path={mdiAccountCog} />
                                Account Settings
                            </StyledLink>
                        </StyledMenuItem>
                    )}

                    <StyledMenuItem>
                        <StyledLink to={'/logout'}>
                            <Icon aria-label="sign out" size={IconSize.Small} path={mdiExitToApp} />
                            Sign out
                        </StyledLink>
                    </StyledMenuItem>

                    <StyledMenuRow>
                        <StyledAnchor href="https://adept.at/terms-of-service" target="_blank">
                            Terms of Service
                        </StyledAnchor>

                        <StyledAnchor href="https://adept.at/privacy-policy" target="_blank">
                            Privacy Policy
                        </StyledAnchor>
                    </StyledMenuRow>
                </StyledMenuContainer>
            </StyledMenu>
        </>
    );
};

export default AccountMenu;
