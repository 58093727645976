import React from 'react';

import { ComponentValuesType } from '@adept-at/lib-react-components';
import styled from 'styled-components';

import { getWordCount } from '../../../utils';

import { HeaderPlaceholder, TextSection, SingleLine, ButtonPlaceholder, VideoBlock } from './Placeholders';

interface HiddenComponentProps {
    componentValues: Record<string, any>;
    componentType: ComponentValuesType;
}

const StyledHiddenComponent = styled.div`
    margin-bottom: 45px;
`;

const QuestionLine = styled(SingleLine)`
    width: 33%;
`;

const StyledQuestionButton = styled(ButtonPlaceholder)`
    margin-top: 15px;
`;

const HiddenComponent: React.FC<HiddenComponentProps> = ({ componentValues, componentType }) => {
    switch (componentType) {
        case ComponentValuesType.RichText: {
            const wordCount = getWordCount(componentValues?.body?.val?.blocks || []);
            const wordsPerLine = 15.5;

            const lines = Math.ceil(wordCount / wordsPerLine);

            return (
                <StyledHiddenComponent>
                    <TextSection lines={lines} />
                </StyledHiddenComponent>
            );
        }

        case ComponentValuesType.Question: {
            return (
                <StyledHiddenComponent>
                    <HeaderPlaceholder />

                    <QuestionLine />
                    <QuestionLine />
                    <QuestionLine />

                    <StyledQuestionButton />
                </StyledHiddenComponent>
            );
        }

        case ComponentValuesType.Image:
        case ComponentValuesType.UnsplashImage:
        case ComponentValuesType.Video:
        case ComponentValuesType.PDF: {
            return (
                <StyledHiddenComponent>
                    <VideoBlock />
                </StyledHiddenComponent>
            );
        }

        default: {
            return (
                <StyledHiddenComponent>
                    <TextSection />
                </StyledHiddenComponent>
            );
        }
    }
};

export default HiddenComponent;
