import styled from 'styled-components';
import { modularScale } from 'utils/modular_scale';

export const RecordWindowText = styled.div`
    font-size: ${modularScale(0)};
    font-weight: 400;
    text-align: center;
`;

export const InstructionsWrapper = styled.div`
    margin-top: 20px;
`;

export const InstructionalText = styled.div`
    font-size: ${modularScale(-0.5)};
    text-align: center;
`;

export const StyledLink = styled.a`
    cursor: pointer;
    text-decoration: none;
    color: ${(props) => props.theme.colors.primary};
`;
