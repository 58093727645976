import React, { useState } from 'react';

import { modularScale } from '@adept-at/lib-react-components';
import { Button } from '@material-ui/core';
import { EditableText } from 'components/forms/EditableText';
import EditableTextArea from 'components/forms/EditableText/EditableTextArea';
import { useMediaQueriesForTextareaTitleWidth } from 'hooks/useMediaQueriesForContentTitleWIdth';
import styled from 'styled-components';

import { AboutTitleSubContainer } from './Collections.styles';
import { AboutInfo } from './constants';
import { useUpdateCollectionMeta } from './useUpdateCollectionMeta';

const AddAboutTitleSubButton = styled(Button)`
    padding: 0;
    margin-bottom: 0.5rem;
`;

const DEFAULT_TITLE_SUB = 'Add subtitle';

interface AboutTitleSubProps {
    aboutTitleSub?: string;
    isEditMode: boolean;
    collectionId: string;
    collectionIdForEditableCollectionQuery: string;
    parentQuerySlug: string;
    aboutInfo: AboutInfo | null;
}

export const AboutTitleSub: React.FC<AboutTitleSubProps> = ({
    aboutTitleSub,
    isEditMode,
    collectionId,
    parentQuerySlug,
    collectionIdForEditableCollectionQuery,
    aboutInfo
}) => {
    const textAreaWidth = useMediaQueriesForTextareaTitleWidth();

    const { mutate: updateSubtitle } = useUpdateCollectionMeta({
        collectionId: collectionIdForEditableCollectionQuery,
        parentQuerySlug
    });

    const [showEditableInput, setShowEditableInput] = useState(false);

    const saveSubtitle = (newSubtitle) => {
        if (newSubtitle === aboutInfo?.titleSub || !collectionId) return;

        updateSubtitle({ input: { collectionId, titleSub: newSubtitle } });
        setShowEditableInput(false);
    };

    if (aboutInfo?.titleSub || showEditableInput) {
        return (
            <AboutTitleSubContainer>
                <EditableTextArea
                    canEdit={isEditMode}
                    initialContent={aboutInfo?.titleSub ?? ''}
                    buttonAriaLabel={'edit sub title'}
                    saveContent={saveSubtitle}
                    validation={(content) => !!content}
                    styleProps={{ fontWeight: 'inherit', fontSize: modularScale(0.8), width: textAreaWidth }}
                />
            </AboutTitleSubContainer>
        );
    }

    if (isEditMode) {
        return (
            <AboutTitleSubContainer>
                <AddAboutTitleSubButton onClick={() => setShowEditableInput(true)} color="primary">
                    {aboutTitleSub ?? DEFAULT_TITLE_SUB}
                </AddAboutTitleSubButton>
            </AboutTitleSubContainer>
        );
    }

    return null;
};
