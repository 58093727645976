import { ClientError } from 'graphql-request';
import gql from 'graphql-tag';
import { useBuildAuthenticatedQueryFn } from 'hooks/useGqlClient/useBuildAuthenticatedQueryFn';
import { Api } from 'lib/ApiConstants';
import { QueryKey, useQuery, UseQueryResult } from 'react-query';

import { SagaStatus } from './types';

const queryName = 'getSaga';
export const buildGetSagaQueryKey = (executionId: string): QueryKey => [queryName, executionId];

export const GET_SAGA_STATUS = gql`
    query ${queryName}($executionId: String!) {
        ${queryName}(executionId: $executionId) {
           status
        }
    }
`;

export interface GetSagaResponse {
    getSaga: { status: SagaStatus };
}

export interface useGetSagaDetailsVariables {
    executionId?: string;
}

export const useGetSagaDetails = (
    variables: useGetSagaDetailsVariables,
    enabled = true
): UseQueryResult<GetSagaResponse, ClientError> => {
    const { queryFn } = useBuildAuthenticatedQueryFn<GetSagaResponse, useGetSagaDetailsVariables>(
        GET_SAGA_STATUS,
        variables,
        { api: Api.Payment }
    );

    return useQuery<GetSagaResponse, ClientError>(buildGetSagaQueryKey(variables.executionId ?? 'unknown'), {
        queryFn,
        enabled: !!variables.executionId && enabled,
        refetchInterval: 1500
    });
};
