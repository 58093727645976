import { Button, Modal } from '@material-ui/core';
import styled from 'styled-components';

import { modularScale } from '../../../utils/modular_scale';

export const StyledModal = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ModalBody = styled.div`
    background: ${(props) => props.theme.colors.surface};
    width: 100%;
    max-width: 500px;
    outline: none;
    padding: 2rem;
    max-height: 75vh;
    overflow-y: auto;
`;

export const Title = styled.h2`
    font-weight: 400;
    font-size: ${modularScale(1)};
    margin-bottom: 1rem;
`;

export const DragText = styled.div`
    font-size: ${modularScale(1)};
    margin-bottom: 1rem;
`;

export const BackgroundPreview = styled.div<{ image; isLoading }>`
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #000;
    background-position: center;
    background-size: cover;
    transition: filter 300ms ease;
    background-image: url(${(props) => props.image});
    filter: ${(props) => (props.isLoading ? 'blur(20px)' : 'none')};
`;

export const LoadingSpinner = styled.div<{ isLoading }>`
    position: absolute;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    z-index: 2;
    display: ${(props) => (props.isLoading ? 'flex' : 'none')};
`;

export const Progress = styled.div<{ isLoading; uploadProgress }>`
    position: absolute;
    content: '';
    background-color: ${(props) => props.theme.colors.surface};
    height: 100%;
    width: ${(props) => 100 - props.uploadProgress}%;
    right: 0;
    opacity: 0.5;
    display: ${(props) => (props.isLoading ? 'inline' : 'none')};
    z-index: 1;
    transition: width 0.1s linear;
`;

export const PreviewWrapper = styled.div<{ isLoading }>`
    color: ${(props) => props.theme.colors.white};
    position: absolute;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    transform: ${(props) => (props.isLoading ? 'scale(0.8)' : 'scale(1)')};
    border-radius: ${(props) => (props.isLoading ? '50px' : '0')};
    transition: transform 300ms ease, border-radius 300ms ease;
    overflow: hidden;
    justify-content: center;
`;

export const SelectText = styled.div`
    position: relative;
    font-style: italic;
    margin-bottom: 2rem;
    color: ${(props) => props.theme.colors.lightGrey};

    &:after,
    &:before {
        position: absolute;
        content: '';
        height: 1px;
        width: 25px;
        background: ${(props) => props.theme.colors.lightGrey};
        top: 0;
        bottom: 0;
        margin: auto;
    }

    &:after {
        left: calc(100% + 10px);
    }

    &:before {
        right: calc(100% + 10px);
    }
`;

export const UploadButton = styled(Button)`
    font-size: ${modularScale(0)};
`;

export const Dropzone = styled.div<{ image; isLoading; width? }>`
    position: relative;
    background-color: rgba(209, 218, 223, 0.15);
    border: ${(props) => (props.image && !props.isLoading ? 'none' : `dashed 4px ${props.theme.colors.border}`)};
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: ${(props) => (props.width ? props.width : '100%')};
    margin-bottom: 1rem;

    &:after {
        content: '';
        display: block;
        padding-bottom: 100%;
    }
`;

export const UploadControls = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    svg {
        color: ${(props) => props.theme.colors.textSecondary};
        width: 100px;
        margin-bottom: 1rem;
    }
`;

export const UploadHelpText = styled.div`
    font-size: ${modularScale(-1)};
    color: #888888;
    margin-bottom: 2rem;
`;

export const Buttons = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;
