import { CropDetails } from '@adept-at/lib-react-components';
import { gql, ClientError } from 'graphql-request';
import useGqlClient from 'hooks/useGqlClient';
import buildGqlQueryFn from 'hooks/useGqlClient/helpers/buildGqlQueryFn';
import { API_CONTENT } from 'lib/ApiConstants';
import { useQuery, UseQueryResult } from 'react-query';

export interface GetPublicImageVariables {
    assetId: string | null;
}

export interface GetPublicImageResult {
    getPublicImage: PublicImage;
}

export interface PublicImage {
    id: string;
    crop: CropDetails;
    original: {
        url: string;
    };
    processed: [{ url: string }];
}

/**
 * @TODO optimization here -- we should be using processed values and selecting size specifically. Seems like
 * consumers of this are just dealing with the original + crop
 */

export const GET_PUBLIC_IMAGE_QUERY = gql`
    query getPublicImage($assetId: UUIDv4!) {
        getPublicImage(assetId: $assetId) {
            id
            crop
            original {
                url
            }
            processed @sizes(widths: [72]) @formats(exts: [JPG]) {
                url
            }
        }
    }
`;

export const usePublicImage = ({ assetId }: GetPublicImageVariables): UseQueryResult<GetPublicImageResult, Error> => {
    const { client, withQueryOptions } = useGqlClient(API_CONTENT);

    const queryFn = buildGqlQueryFn<GetPublicImageResult>(client, GET_PUBLIC_IMAGE_QUERY, { assetId });

    return useQuery<GetPublicImageResult, ClientError>(
        withQueryOptions({
            queryKey: ['getPublicImage', assetId],
            queryFn,
            enabled: !!assetId
        })
    );
};
