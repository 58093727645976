import React from 'react';

import { LinkComponentValues, LinkType } from '@adept-at/lib-react-components';
import { FormControl } from '@material-ui/core';
import styled from 'styled-components';

import { useComponentEngine, useComponentEngineComponent } from '../..';
import { useEditableComponent, ComponentFocus, EditButtons } from '../../mixins/editor';
import { useFocusableComponent } from '../../mixins/focusable';

import { LinkExternalForm } from './ExternalForm';
import { LinkForm } from './Form';
import { LinkMetaForm } from './MetaForm';
import { LinkOptions } from './Options';
import { LinkView } from './View';

export const LinkEditForm = styled.div`
    fieldset {
        display: block;

        label {
            position: relative;
            transform: none;
            padding-bottom: 5px;
        }
    }
`;

interface LinkEditProps {
    onCancel?: () => void;
    onSave?: (values: Partial<LinkComponentValues>) => void;
    entityKey?: string;
}

export const emptyLinkComponentValues: LinkComponentValues = {
    text: '',
    url: '',
    linkType: LinkType.META,
    action: 'default' as LinkComponentValues['action']
};

export const LinkEdit: React.FC<LinkEditProps> = ({ onCancel, onSave }) => {
    const { id } = useComponentEngineComponent();
    const { container, containerRefetch } = useComponentEngine();

    const { doesComponentHaveAnyFocus } = useFocusableComponent();
    const {
        currentValues: { linkType, externalId, url, text, ...rest },
        onSave: defaultOnSave,
        onCancel: defaultOnCancel,
        onChange
    } = useEditableComponent<LinkComponentValues>();

    if (!doesComponentHaveAnyFocus(id, [ComponentFocus.Edit, ComponentFocus.AddAndEdit])) {
        return <LinkView componentValues={{ linkType, externalId, url, text, ...rest }} container={container} />;
    }

    const save = () => {
        if (onSave) {
            return onSave({ linkType, externalId, url, text });
        }

        defaultOnSave();

        //ideal to refetch the container and populate the metadata details, no way to optimistically do so
        //editor doesnt seem to care about refetch
        if (containerRefetch && linkType === LinkType.META) {
            containerRefetch();
        }
    };

    //pre-existing text links wont have a type yet, so redirect em to the normal link form
    if (!linkType || linkType === LinkType.TEXT) return <LinkForm onClose={onCancel ? onCancel : () => {}} />;

    return (
        <LinkEditForm aria-label={'link-edit-form'}>
            <FormControl style={{ minWidth: '120px' }}>
                <LinkOptions selectedOption={linkType} selectOption={onChange('linkType')} />
            </FormControl>
            {linkType === LinkType.META ? (
                <LinkMetaForm onChange={onChange} text={text} url={url ?? ''} />
            ) : (
                <LinkExternalForm onChange={onChange} externalId={externalId ?? ''} text={text} linkType={linkType} />
            )}
            <EditButtons onCancel={onCancel ?? defaultOnCancel} onSave={save} />
        </LinkEditForm>
    );
};
