import { TooltippedIconButton, defaultMuiTheme, IconSize } from '@adept-at/lib-react-components';
import { Button, Typography, Divider, lighten } from '@material-ui/core';
import styled from 'styled-components';
import { SUB_HEADER_HEIGHT } from 'styles/variables';

export const NestedCollectionContainer = styled.div`
    background-color: ${(props) => props.theme.colors.surface};
`;

export const NestedCollectionAboutSection = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    h1 {
        font-size: 2rem;
        letter-spacing: 0.06px;
        line-height: 36px;
    }
`;

export const AvatarHeadingContainer = styled.div`
    position: relative;
    margin-bottom: 0px;
`;

export const CollectionDurationAndAuthorSection = styled.div`
    text-align: start;
    margin-left: 1rem;
`;

export const NestedAuthorsSection = styled.div`
    display: flex;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 2rem;
`;

export const NestedCollectionActions = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
`;

const ENROLL_BUTTON_HEIGHT = 42;
const ENROLL_BUTTON_HEIGHT_CONDENSED = 36;

const ENROLL_BUTTON_FONT_NORAML = 1;
const ENROLL_BUTTON_FONT_CONDENSED = 0.87;

const ENROLL_BUTTON_PADDING = 20;
const ENROLL_BUTTON_PADDING_CONDENSED = 15;

export const EnrollButton = styled(Button)<{ $isStickyMode?: boolean }>`
    box-shadow: none;
    color: ${(props) => props.theme.colors.surface};
    height: ${(props) => (props.$isStickyMode ? ENROLL_BUTTON_HEIGHT_CONDENSED : ENROLL_BUTTON_HEIGHT)}px;
    font-size: ${(props) => (props.$isStickyMode ? ENROLL_BUTTON_FONT_CONDENSED : ENROLL_BUTTON_FONT_NORAML)}rem;
    padding: 0 ${(props) => (props.$isStickyMode ? ENROLL_BUTTON_PADDING_CONDENSED : ENROLL_BUTTON_PADDING)}px;
    background-color: ${(props) => props.theme.colors[props.$isStickyMode ? 'primary' : 'secondary']};

    &&& {
        :hover {
            background-color: ${(props) =>
                lighten(props.theme.colors[props.$isStickyMode ? 'primary' : 'secondary'], 0.3)};
        }
    }
`;

export const CollectionQuickActions = styled.div`
    display: flex;
    align-items: center;
`;

export const QuickActionItemContainer = styled.div<{ $hideOnMobile?: boolean }>`
    margin-right: 4px;
    display: flex;
    align-items: center;

    ${defaultMuiTheme.breakpoints.down('xs')} {
        display: ${(props) => (props.$hideOnMobile ? 'none' : 'flex')};
    }
`;

export const QuickActionItem = styled(TooltippedIconButton)<{ $isStickyMode?: boolean }>`
    font-size: 1.5rem;
    svg {
        color: ${(props) => props.theme.colors[props.$isStickyMode ? 'primary' : 'secondary']};
    }
`;

export const ExtraInfoOuterContainer = styled.div`
    margin-top: 1rem;
    padding-top: 8px;
`;

export const ExtraInfoInnerContainer = styled.div`
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
`;

export const ExtraInfoItemContainer = styled.div`
    display: flex;
    align-items: center;

    ${defaultMuiTheme.breakpoints.down('xs')} {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const CollectionDateAndDuration = styled(Typography)`
    margin-right: 1rem;
`;

export const StickyContainer = styled.div<{ $visible?: boolean }>`
    top: 0px;
    display: flex;
    position: sticky;
    width: 100%;
    z-index: 10;
    transition: height 250ms ease, min-height 250ms ease;
    overflow: hidden;
    min-height: ${(props) => (props.$visible ? SUB_HEADER_HEIGHT : 0)}px;
    height: ${(props) => (props.$visible ? SUB_HEADER_HEIGHT : 0)}px;
    background-color: ${(props) => props.theme.colors.surface};
    border-bottom: ${(props) => props.$visible && `1px solid ${props.theme.colors.border}`};
`;

export const StickyInnerContainer = styled.div`
    width: 100%;
    margin: 0 34px;
    display: flex;
    align-items: center;

    ${defaultMuiTheme.breakpoints.down('sm')} {
        margin: 0 12px;
    }
`;

export const StyledTypography = styled(Typography)`
    font-size: 0.75rem;
    font-family: inherit;
    letter-spacing: 0.08em;
    padding: 6px 16px 6px 0;
    text-transform: uppercase;
    font-weight: ${(props) => props.theme.weight.semiBold};
    color: ${(props) => props.theme.colors.textSecondary};
    display: flex;
    flex-wrap: wrap;

    strong {
        margin-left: 0.2rem;
    }
`;

export const CollectionLikeCount = styled(Typography)`
    font-size: 0.875rem;
    margin-right: 0.5rem;
    font-family: inherit;
    font-weight: ${(props) => props.theme.weight.semiBold};
    color: ${(props) => props.theme.colors.textPrimary};
`;

export const EditCollectionButton = styled(Button)`
    font-weight: 500;
    font-size: 0.8rem;
    border-color: transparent;
    color: ${(props) => props.theme.colors.textSecondary};

    &&& {
        svg {
            width: ${IconSize.Small};
            height: ${IconSize.Small};
            margin-right: 8px;
        }
    }
`;

export const StyledDivider = styled(Divider)`
    height: 1.5rem;
    margin: 0 8px;
    background-color: ${(props) => props.theme.colors.border};
`;

export const StyledStrong = styled.strong`
    display: flex;
    align-items: center;
`;
