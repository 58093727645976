import React, { useEffect } from 'react';

import { VideoComponentValues } from '@adept-at/lib-react-components';
import { CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { useEditableComponent } from '../../../mixins/editor';
import useEncodeStatus from '../hooks/useEncodeStatus';
import { usePlaybackDetails } from '../hooks/usePlaybackDetails';
import { EncodeStatus } from '../types';

interface LoaderProps {
    videoId: string;
}

const ENCODING_SERVICE_HOSTNAME = 'stream.mux.com';

const InProgress = ({ children }) => (
    <Alert icon={<CircularProgress size="16px" style={{ margin: 'auto' }} />} severity="info">
        {children}
    </Alert>
);

/**
 * @TODO Pulling playbackUrl and posterImageUrl explicitly allows the requiring component to override the playbackUrl and
 * posterImageUrl values on this component. This allows them to wait for the Mux encode to complete and then refetch
 * and set those details specifically.
 *
 * A future optimization (from a code and request standpoint) would be to add a generalized way to refetch values for
 * a component. This would require a new query on the Content API:
 *  getComponentForEntityById({type: 'skill', id: skillId}, componentId)
 *
 * Then we could build a general hook to retrieve/refetch this component data. As a result of this, the
 * useGetPlaybackDetails hook can then be decommissioned in favor of a hook for component specifically.
 */

export const Loader: React.FC<LoaderProps> = ({ videoId, children }) => {
    const { playbackUrl, refetch: refetchPlaybackDetails } = usePlaybackDetails();
    const { onChange } = useEditableComponent<VideoComponentValues>();
    const { encodeStatus } = useEncodeStatus({ videoId });

    useEffect(() => {
        onChange('playbackUrl')(playbackUrl);
    }, [playbackUrl]);
    useEffect(() => {
        if (!encodeStatus || !playbackUrl) {
            return;
        }

        const { hostname } = new URL(playbackUrl);

        if (encodeStatus === EncodeStatus.Complete && hostname !== ENCODING_SERVICE_HOSTNAME) {
            refetchPlaybackDetails();
        }
    }, [encodeStatus, playbackUrl, refetchPlaybackDetails]);

    if (!playbackUrl || !encodeStatus || encodeStatus === EncodeStatus.Unknown) {
        return <InProgress>Retrieving Video Details</InProgress>;
    }

    if (encodeStatus === EncodeStatus.None) {
        return <InProgress>Determining Video Encode Status</InProgress>;
    }

    return <>{children}</>;
};
