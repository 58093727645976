import { useEffect } from 'react';

import { VideoComponentValues } from '@adept-at/lib-react-components';

import { useEditableComponent } from '../../../../mixins/editor';
import { usePlaybackDetails } from '../../hooks/usePlaybackDetails';

import useSetPosterImageFrameTime from './useSetPosterImageFrameTime';

interface UseUpdatePosterImageProps {
    containerId: string;
}

interface UseUpdatePosterImageInterface {
    updatePosterImage: (time: number) => void;
}

export const useUpdatePosterImage = ({ containerId }: UseUpdatePosterImageProps): UseUpdatePosterImageInterface => {
    const { posterImageUrl, refetch: refetchPlaybackDetails } = usePlaybackDetails();

    const {
        onChange,
        currentValues: { videoId = null }
    } = useEditableComponent<VideoComponentValues>();

    // @TODO this is skill specific. We need to update here and in the Content API
    const { mutate: setPosterImageFrameTime, isSuccess: isSetPosterImageFrameTimeSuccess } =
        useSetPosterImageFrameTime(containerId);

    // When we have successfully updated the poster image, we need to refetch the playback details
    useEffect(() => {
        if (!isSetPosterImageFrameTimeSuccess) {
            return;
        }

        refetchPlaybackDetails();
    }, [isSetPosterImageFrameTimeSuccess, refetchPlaybackDetails]);

    useEffect(() => {
        if (!posterImageUrl) {
            return;
        }

        onChange<string>('posterImageUrl')(posterImageUrl);
    }, [posterImageUrl, onChange]);

    return {
        updatePosterImage: (time: number) => {
            setPosterImageFrameTime({ videoId, frameTime: time });
        }
    };
};
