import React, { useContext } from 'react';

import { CollectorProvider } from 'components/devtools/Collector';
import { DevToolsOverlay } from 'components/devtools/Overlay';

import { DevToolsButton } from './DevToolsButton';
import { DisplayProvider, DisplayContext } from './Display';
import { useDevTools } from './useDevTools';

const DevToolsCollector = ({ children }) => <CollectorProvider>{children}</CollectorProvider>;

const DevToolsToggle = () => {
    const { visible, show, hide } = useContext(DisplayContext);

    return (
        <DevToolsButton
            onClick={() => {
                visible ? hide() : show();
            }}
        />
    );
};

export const DevTools: React.FC = ({ children }) => {
    const { enabled } = useDevTools();

    if (!enabled) {
        return <DevToolsCollector>{children}</DevToolsCollector>;
    }

    return (
        <DevToolsCollector>
            <DisplayProvider>
                <DevToolsToggle />
                <DevToolsOverlay />
            </DisplayProvider>
            {children}
        </DevToolsCollector>
    );
};
