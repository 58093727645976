import React from 'react';

import { TabContent } from 'components/Sidebar/index.styles';

import { SkillSection } from '../../utils/skill/sections';

import SkillOutlineContent from './SkillOutlineContent';

interface SkillOutlineProps {
    sections: SkillSection[];
}

const SkillOutline: React.FC<SkillOutlineProps> = ({ sections }) => {
    return (
        <TabContent>
            <SkillOutlineContent sections={sections} />
        </TabContent>
    );
};

export default SkillOutline;
