// This file should be considered deprecated, and is the only reason for some lint and TS shims.
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Custom hooks inspired by discussion: https://github.com/tannerlinsley/react-query/issues/55
 */
import { RequestDocument, ClientError, GraphQLError, GraphQLResponse } from 'graphql-request/dist/types';
import useGqlClient, { GqlClientStatus } from 'hooks/useGqlClient';
import { API_ACCOUNTS } from 'lib/ApiConstants';
import {
    useQuery as useReactQueryQuery,
    useMutation as useReactQueryMutation,
    UseQueryOptions,
    RefetchOptions,
    QueryObserverResult,
    UseMutationResult,
    UseMutateFunction,
    QueryKey
} from 'react-query';

export type ReactQueryErrorCode = 'FORBIDDEN_ACCESS' | 'EMAIL_ALREADY_CONFIRMED';

interface GraphQLErrorWithExtensions extends GraphQLError {
    extensions?: { withoutExtensionsExceptionStacktrace?: { code?: ReactQueryErrorCode } };
}

/** Bit of a misnomer, this is really a graphql-request error with an extensions property */
export interface ReactQueryError extends ClientError {
    response: GraphQLResponse & {
        errors?: GraphQLErrorWithExtensions[];
    };
}

interface UseQueryAbstractionParams<
    TResult, // TODO: Make this unknown (but we need to be better with using types first)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _TKey,
    TVariables,
    TError
> {
    name: QueryKey | string | unknown[] | undefined;
    query: RequestDocument;
    variables?: TVariables;
    config?: UseQueryOptions<TResult, TError>;
}

export const useUnsafeQuery = <
    TResult = any, // TODO: Make this unknown (but we need to be better with using types first)
    TKey = any, // TODO: Make this extends QueryKey = QueryKey (but we need to be better with using types first, and make sure all of our queryKeys are tuples)
    TVariables = any, // TODO: Make this extends AnyVariables = AnyVariables (but we need to be better with using types first)
    TError extends Error = ReactQueryError
>(
    { name, query, variables, config = {} }: UseQueryAbstractionParams<TResult, TKey, TVariables, TError>,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    options?
): {
    loading: boolean;
    error: TError | null | undefined;
    data: TResult | undefined;
    queryKey: any;
    refetch: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<TResult, TError>>;
    isFetching: boolean;
    isStale: boolean;
    failureCount: number;
} => {
    const { host = API_ACCOUNTS, onQueryKey = () => {} } = options || {};

    const { client, status: gqlClientStatus, withQueryOptions } = useGqlClient(host);

    const { error, data, status, refetch, ...others } = useReactQueryQuery<TResult, TError, TResult>(
        withQueryOptions({
            ...config,
            queryKey: name,
            queryFn: async () => {
                return client.request(query, variables);
            },
            enabled: gqlClientStatus === GqlClientStatus.Ready && (config?.enabled ?? true)
        })
    );

    onQueryKey(name);

    return {
        ...others,
        loading: others?.isLoading,
        error: error || (!others?.isFetching && !data) ? error : undefined,
        data,
        queryKey: name,
        refetch
    };
};

export const useUnsafeMutation = <TResults, TVariables = unknown, TError extends Error = ReactQueryError>(
    query: RequestDocument,
    variables = {},
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    options?,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    mutationOptions? // TODO: MutationOptions<TResults, TVariables, TError>
): [
    UseMutateFunction<TResults, TError, TVariables, unknown>,
    UseMutationResult<TResults, TError, TVariables> & { loading?: boolean }
] => {
    const { host = API_ACCOUNTS } = options || {};

    const { client, withMutationOptions } = useGqlClient(host);

    const { mutate, data, error, status, ...others } = useReactQueryMutation<TResults, TError, TVariables>(
        async (moreVariables) => {
            return client.request(query, { ...variables, ...moreVariables });
        },
        withMutationOptions(mutationOptions)
    );

    return [
        mutate,
        {
            ...others,
            mutate,
            status,
            loading: others.isLoading,
            error,
            data
        }
    ];
};
