import { GraphQLClient } from 'graphql-request';
import gql from 'graphql-tag';
import { MutationFunction } from 'react-query';

import buildGqlMutationFn from '../../../../../../../hooks/useGqlClient/helpers/buildGqlMutationFn';

import { SetPosterImageFrameTimeResult, SetPosterImageFrameTimeVariables } from '.';

export const SET_POSTER_IMAGE_FRAME_TIME = gql`
    mutation setPosterImageFrameTime($videoId: String!, $frameTime: Float!) {
        setPosterImageFrameTime(videoId: $videoId, frameTime: $frameTime) {
            success
        }
    }
`;

const makeSetPosterImageFrameTime = (
    client: GraphQLClient
): MutationFunction<SetPosterImageFrameTimeResult, SetPosterImageFrameTimeVariables> =>
    buildGqlMutationFn(client, SET_POSTER_IMAGE_FRAME_TIME);

export default makeSetPosterImageFrameTime;
