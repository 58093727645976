import { IconSize } from '@adept-at/lib-react-components';
import { IconButton, TextField } from '@material-ui/core';
import styled from 'styled-components';
import { modularScale } from 'utils/modular_scale';

export const TextViewWrapper = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: row;
`;

export const StyledCurrentValue = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledIconButton = styled(IconButton)`
    visibility: ${(props) => (props.hidden ? 'hidden' : 'visible')};
    padding: 0;
    width: 40px;
    height: 40px;
    margin: 8px 0 8px 0.5rem;
    svg {
        width: ${IconSize.Medium};
        color: ${(props) => props.theme.colors.primary};
    }
`;

export const StyledTextField = styled(TextField)`
    .MuiInputBase-root {
        font-size: ${modularScale(2)};
        font-weight: 500;
        line-height: 1.5;
        padding-top: 0;
        letter-spacing: 0;
    }
`;
