import React from 'react';

import { IconButtonProps } from '@material-ui/core';
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';

import { StyledCloseIcon } from './Modal.styles';

interface ModalCloseIconProps extends IconButtonProps {
    showCloseIcon?: boolean;
    onClose: () => void;
}

const ModalCloseIcon: React.FC<ModalCloseIconProps> = ({ showCloseIcon, onClose, style }) => {
    if (showCloseIcon)
        return (
            <StyledCloseIcon aria-label="close modal" onClick={onClose} style={style}>
                <Icon path={mdiClose} />
            </StyledCloseIcon>
        );

    return null;
};

export default ModalCloseIcon;
