import React from 'react';

import { Tabs } from '@material-ui/core';

import { FavoritesProgressFilter } from './constants';
import { ProgressFilterButton } from './ProgressFilterButton';

export const FavoritesProgressFilters: React.FC<{
    activeFilter: FavoritesProgressFilter;
    setActiveFilter: React.Dispatch<React.SetStateAction<FavoritesProgressFilter>>;
}> = ({ activeFilter, setActiveFilter }) => {
    return (
        <Tabs
            value={0}
            role="group"
            variant="scrollable"
            TabIndicatorProps={{
                style: {
                    display: 'none'
                }
            }}
            aria-label="Progress filter buttons"
        >
            <ProgressFilterButton
                isActive={activeFilter === FavoritesProgressFilter.All}
                onClick={() => setActiveFilter(FavoritesProgressFilter.All)}
                label={FavoritesProgressFilter.All}
                ariaLabel="Show all favorites"
                value={0}
            />
            <ProgressFilterButton
                isActive={activeFilter === FavoritesProgressFilter.InProgress}
                onClick={() => setActiveFilter(FavoritesProgressFilter.InProgress)}
                label={FavoritesProgressFilter.InProgress}
                ariaLabel="Show in progress favorites"
                value={1}
            />

            <ProgressFilterButton
                isActive={activeFilter === FavoritesProgressFilter.NotStarted}
                onClick={() => setActiveFilter(FavoritesProgressFilter.NotStarted)}
                label={FavoritesProgressFilter.NotStarted}
                ariaLabel="Show not started favorites"
                value={2}
            />
            <ProgressFilterButton
                isActive={activeFilter === FavoritesProgressFilter.Completed}
                onClick={() => setActiveFilter(FavoritesProgressFilter.Completed)}
                label={FavoritesProgressFilter.Completed}
                ariaLabel="Show completed favorites"
                value={3}
            />
        </Tabs>
    );
};
