import React, { createContext, useEffect, useState, useRef, useCallback } from 'react';

import { ComponentValuesType, sortComponentsByOrder } from '@adept-at/lib-react-components';
import { useFocusableComponent } from 'components/engine/mixins/focusable';

import useRemoveSkillComponent from './hooks/useRemoveSkillComponent';
import useUpdateSkillComponentOrder from './hooks/useUpdateSkillComponentsOrder';
import { useUpdateSkillMeta } from './hooks/useUpdateSkillMeta';
import useUpsertSkillComponent from './hooks/useUpsertSkillComponent';
import {
    WorkingSkill,
    SkillMetadata,
    EditorContextInterface,
    ReorderedComponents,
    WorkingSkillImages
} from './interfaces';

export const componentEditModes = {
    none: 'none',
    edit: 'edit',
    delete: 'delete',
    add: 'add'
};

export enum SaveSkillType {
    componentUpsert = 'componentUpsert',
    componentOrder = 'componentOrder',
    componentDelete = 'componentDelete',
    meta = 'meta'
}

export const COMPONENT_ORDER_PADDING = 100;

const EditorContext = createContext({} as EditorContextInterface);

const { Provider, Consumer } = EditorContext;

export enum EditMode {
    None = 'none',
    Storyboard = 'storyboard',
    Overview = 'overview',
    Conclusion = 'conclusion',
    Component = 'component',
    AddComponent = 'addComponent',
    Reorder = 'reorder',
    Captions = 'captions',
    Preview = 'preview'
}

/**
 * Construct an id that is unique to all the components in the skill
 *
 * @param existingComponents
 */
const getNewComponentId = (existingComponents) => {
    const newComponentId = Math.random().toString(36).replace('0.', '').substr(3);

    if (newComponentId in existingComponents) {
        return getNewComponentId(existingComponents);
    }

    return newComponentId;
};

const EditorProvider = ({ mode = EditMode.None, skill, toggleComponentEngineEditMode, children }) => {
    const [editMode, setEditMode] = useState<EditMode>(mode);
    const [componentEditMode, setComponentEditMode] = useState(componentEditModes.none);
    const [selectedComponent, setSelectedComponent] = useState<string | undefined>();
    const componentContainerRef = useRef(null);
    const { setComponentFocus } = useFocusableComponent();

    const skillMutationProperties = {
        skillId: skill.skillId,
        skillSlug: skill.skillSlug,
        tenantSlug: skill.tenantSlug
    };

    //when adding a component, and before saving it to the skill we need a place to keep its state.
    const { mutate: updateMeta } = useUpdateSkillMeta(skillMutationProperties);
    const { mutate: upsertComponent } = useUpsertSkillComponent(skillMutationProperties);
    const { mutate: removeComponent } = useRemoveSkillComponent(skillMutationProperties);
    const { mutate: updateComponentsOrder } = useUpdateSkillComponentOrder(skillMutationProperties);

    const [workingSkill, setWorkingSkill] = useState<WorkingSkill>({
        components: {},
        title: '',
        tags: [],
        meta: {
            storyboard: ''
        },
        ...skill
    });

    useEffect(() => {
        if (!skill) return;
        setWorkingSkill({ ...workingSkill, ...skill });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [skill]);

    const setComponentContainerRef = (ref) => {
        componentContainerRef.current = ref;
    };

    const isPreview = useCallback(() => editMode === EditMode.Preview, [editMode]);

    const editOverview = useCallback(() => {
        setEditMode(EditMode.Overview);
    }, []);

    const editConclusion = useCallback(() => {
        setEditMode(EditMode.Conclusion);
    }, []);

    const editStoryboard = useCallback(() => {
        setEditMode(EditMode.Storyboard);
    }, []);

    const editComponentOrder = useCallback(() => {
        setEditMode(EditMode.Reorder);
    }, []);

    const previewSkill = useCallback(() => {
        setEditMode(EditMode.Preview);
    }, []);

    const editCaptions = useCallback(() => {
        setEditMode(EditMode.Captions);
    }, []);

    const editComponent = (componentId, mode = EditMode.Component) => {
        setSelectedComponent(componentId);
        setEditMode(mode);
    };

    const isEditingComponent = (componentId) =>
        [EditMode.Component, EditMode.AddComponent].includes(editMode) && selectedComponent === componentId;

    const clearEditMode = useCallback(() => {
        setSelectedComponent(undefined);
        setEditMode(EditMode.None);
        setComponentEditMode(componentEditModes.none);
    }, []);

    const updateWorkingSkillMetadata = useCallback((values: SkillMetadata) => {
        updateMeta({
            skillId: workingSkill.id,
            ...values
        });
    }, []);

    const getOrderForNextComponent = (fromComponentId) => {
        const sortedComponents = sortComponentsByOrder(workingSkill.components);

        if (sortedComponents.length === 0) {
            return COMPONENT_ORDER_PADDING;
        }

        if (fromComponentId === null) {
            return Math.ceil(sortedComponents[0].order / 2);
        }

        for (let i = 0; i < sortedComponents.length; i++) {
            if (sortedComponents[i].id !== fromComponentId) {
                continue;
            }

            const current = sortedComponents[i].order;
            const next = sortedComponents[i + 1] ? sortedComponents[i + 1].order : current + 200;

            return Math.ceil((current + next) / 2);
        }

        return null;
    };

    const updateWorkingSkillComponentById = useCallback(
        (componentId, newValues) => {
            const updatedComponent = {
                ...workingSkill.components[componentId],
                ...newValues
            };
            const { addedAt, updatedAt, type, order, ...values } = updatedComponent;

            upsertComponent({
                skillId: workingSkill.id,
                componentType: type,
                componentId,
                order: order ?? COMPONENT_ORDER_PADDING,
                values: JSON.stringify({ ...values, type })
            });

            // If we were adding a component, and now have saved it, switch to edit mode
            // The video component saves the video asset id in the process of adding and needs
            // to then switch to edit mode. Otherwise when the component is deleted it does not persist.
            // @TODO - Move this logic to the video component
            if (editMode === EditMode.AddComponent) {
                setEditMode(EditMode.Component);
            }
        },
        [workingSkill]
    );

    const updateWorkingSkillComponentsOrder = useCallback(
        (components: ReorderedComponents) => {
            updateComponentsOrder({
                skillId: workingSkill.id,
                componentsToOrder: JSON.stringify(components)
            });
        },
        [workingSkill.id]
    );

    const addSection = useCallback(
        (order) => {
            const type = ComponentValuesType.SectionTitle;
            const DEFAULT_SECTION_TITLE = 'Enter Section Title...';

            const newComponentId = getNewComponentId(workingSkill.components);

            setComponentFocus(newComponentId, 'section-edit');

            setWorkingSkill({
                ...workingSkill,
                components: {
                    ...workingSkill.components,
                    [newComponentId]: { id: newComponentId, type, title: DEFAULT_SECTION_TITLE, order }
                }
            });

            upsertComponent({
                order: order,
                componentType: type,
                skillId: workingSkill.id,
                componentId: newComponentId,
                values: JSON.stringify({ id: newComponentId, type, title: DEFAULT_SECTION_TITLE })
            });
        },
        [workingSkill]
    );

    const updateWorkingSkillImages = useCallback((imageData: string, type: 'catalog' | 'featured') => {
        setWorkingSkill((prev) => ({
            ...prev,
            images: {
                ...prev.images,
                [type]: {
                    ...prev.images?.[type],
                    isProcessed: true,
                    processed: [{ url: imageData }]
                }
            } as WorkingSkillImages
        }));
    }, []);

    const addComponent = useCallback(
        (type, order) => {
            const newComponentId = getNewComponentId(workingSkill.components);

            const updatedSkill = {
                ...workingSkill,
                components: {
                    ...workingSkill.components,
                    [newComponentId]: { id: newComponentId, type, order }
                }
            };

            setWorkingSkill(updatedSkill);
            editComponent(newComponentId, EditMode.AddComponent);
        },
        [workingSkill]
    );

    const deleteComponent = useCallback(
        (componentId: string, persist = true) => {
            if (!componentId) {
                return;
            }

            const { [componentId]: removed, ...remainingComponents } = workingSkill.components;

            const updatedSkill = {
                ...workingSkill,
                components: remainingComponents
            };

            /**
             * If a component is deleted before it was ever saved to content-api, we don't need to account for it.
             *
             * This occurs in the case that you add a new component but cancel before ever saving.
             */

            if (persist) {
                removeComponent({
                    skillId: workingSkill.id,
                    componentId
                });
            } else {
                // Serves as a revert.
                setWorkingSkill(updatedSkill);
            }
        },
        [workingSkill]
    );

    const onDeleteClicked = (componentId) => {
        setSelectedComponent(componentId);
        setComponentEditMode(componentEditModes.delete);
    };

    return (
        <Provider
            value={{
                workingSkill,

                toggleComponentEngineEditMode,

                selectedComponent,
                componentEditMode,
                editMode,

                updateWorkingSkillMetadata,

                updateWorkingSkillComponentsOrder,
                updateWorkingSkillComponentById,
                updateWorkingSkillImages,

                getOrderForNextComponent,

                addComponent,
                addSection,

                onDeleteClicked,
                deleteComponent,

                previewSkill,
                isPreview,

                editStoryboard,
                editOverview,
                editConclusion,
                editComponentOrder,
                editCaptions,

                editComponent,
                isEditingComponent,
                clearEditMode,

                setComponentContainerRef
            }}
        >
            {children}
        </Provider>
    );
};

export { EditorContext, EditorProvider, Consumer as EditorConsumer };
