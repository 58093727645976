import React, { useCallback, ReactElement } from 'react';

import { Checkbox, TableRow } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { CHECKBOX_WIDTH, HeadCell } from 'components/SortableTable/EnhancedTableHead';
import { useHistory, RouterProps } from 'react-router';
import styled from 'styled-components';

import { useEditItemContext, EditItemFunction } from './EditItemContext';
import { getCellComponent, getCellWidth } from './TableCells';
import { ROW_HEIGHT, StickyTableCell, StyledTableCell } from './TableCells/TableCell.styles';

import { CollaborationTableItem } from '.';

const StyledTableRow = styled(TableRow)`
    display: flex;
    min-width: 100%;
    width: unset !important;

    td {
        height: ${ROW_HEIGHT}px;
        display: flex;
        align-items: center;
    }

    &:hover {
        cursor: pointer;
        background-color: ${(props) => props.theme.colors.actionHover};
    }

    ${(props) =>
        props.selected ? `background-color: ${props.theme.colors.pollViewerBackgroundColorHover} !important` : null};
`;

interface RowProps<T> {
    item: T;
    headCellDetails: HeadCell<T>[];
    selected: Set<string>;
    setSelected: React.Dispatch<React.SetStateAction<Set<string>>>;
    multiSelect?: boolean;
    ActionComponent?: React.ComponentType<{ item: T }>;
    style?: React.CSSProperties;
}

export type CollaborationRowOnClick = (args: { history: RouterProps['history']; editItem: EditItemFunction }) => void;

export const Row = <T extends CollaborationTableItem>({
    item,
    headCellDetails,
    multiSelect,
    selected,
    setSelected,
    style,
    ActionComponent
}: RowProps<T>): ReactElement => {
    const { editItem } = useEditItemContext();
    const history = useHistory();

    const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const rowId = event.target.id;
        onSelect(rowId, checked);
    };

    const onSelect = (rowId: string, checked: boolean) => {
        setSelected((prev) => {
            if (checked) {
                return new Set(prev.add(rowId));
            }

            const itemExisted = prev.delete(rowId);
            if (itemExisted) {
                return new Set(prev);
            }

            return prev;
        });
    };

    const isRowChecked = useCallback((rowId: string) => selected.has(rowId), [selected]);

    const onClick = () => {
        item.onClick({ history, editItem });
    };

    return (
        <StyledTableRow hover onClick={onClick} selected={isRowChecked(item.id)} key={item.id} style={style}>
            {multiSelect ? (
                <StickyTableCell padding="checkbox" left={0} width={CHECKBOX_WIDTH}>
                    <Checkbox
                        id={item.id}
                        onClick={(e) => e.stopPropagation()}
                        onChange={onCheckboxChange}
                        color="primary"
                        checked={isRowChecked(item.id)}
                        inputProps={{ 'aria-label': `Select item ${item.id}` }}
                    />
                </StickyTableCell>
            ) : null}
            {headCellDetails.map(({ id }) => {
                const Component = getCellComponent(id as string);
                return Component ? <Component key={`${id}`} {...item} left={multiSelect ? CHECKBOX_WIDTH : 0} /> : null;
            })}
            {ActionComponent ? (
                <StickyTableCell $borderLeft right="0px" padding="checkbox" width={CHECKBOX_WIDTH}>
                    <ActionComponent item={item} />
                </StickyTableCell>
            ) : null}
        </StyledTableRow>
    );
};

interface LoadingRowProps<T> {
    headCellDetails: HeadCell<T>[];
    multiSelect?: boolean;
    hasActionComponent: boolean;
    index: number;
    style?: React.CSSProperties;
}

export const LoadingRow = <T extends { id: string }>({
    multiSelect,
    headCellDetails,
    index,
    style,
    hasActionComponent
}: LoadingRowProps<T>): ReactElement => {
    return (
        <StyledTableRow style={style}>
            {multiSelect ? (
                <StickyTableCell left={0} padding="checkbox" width={CHECKBOX_WIDTH}>
                    <Checkbox disabled inputProps={{ 'aria-label': `Loading row checkbox ${index}` }} />
                </StickyTableCell>
            ) : null}
            {headCellDetails.map(({ id, sticky, borderLeft, borderRight }) => {
                const CellWrapper = sticky ? StickyTableCell : StyledTableCell;
                const width = getCellWidth(id as string);

                const additionalProps = sticky
                    ? {
                          left: multiSelect ? CHECKBOX_WIDTH : 0,
                          $borderLeft: borderLeft,
                          $borderRight: borderRight
                      }
                    : {};

                return (
                    <CellWrapper
                        key={`${id}-${index}`}
                        align="left"
                        $flexGrow={id === 'title'}
                        width={width}
                        {...additionalProps}
                    >
                        <Skeleton variant="text" width={width} />
                    </CellWrapper>
                );
            })}
            {hasActionComponent ? (
                <StickyTableCell $borderLeft right="0px" padding="checkbox" width={CHECKBOX_WIDTH} />
            ) : null}
        </StyledTableRow>
    );
};
