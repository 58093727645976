import { IconSize } from '@adept-at/lib-react-components';
import { FormControl, Button } from '@material-ui/core';
import styled from 'styled-components';

export const AddPeopleModalBody = styled.div`
    padding: 2rem;
`;

export const ModalHeader = styled.div`
    margin-bottom: 3vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ModalFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 3vh;
`;

export const DetailsFields = styled.div`
    width: 60%;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    margin-right: 30px;
`;

export const ImageUpload = styled.div`
    display: block;

    h3 {
        margin-bottom: 2vh;
    }

    > svg {
        width: ${IconSize.ExtraLarge};
        padding: 10px;
        border-radius: 10%;
        background: #c9e2f1;
        margin-bottom: 2vh;
    }
`;

export const Control = styled(FormControl)`
    margin-bottom: 25px;
`;

export const ErrorWrapper = styled.div`
    margin-bottom: 25px;
`;

export const ImageButton = styled(Button)`
    svg {
        width: ${IconSize.Small};
        margin-right: 5px;
    }
`;
