import React, { createContext, useEffect, useState, useCallback } from 'react';

import { useMediaQuery } from '@material-ui/core';
import { usePrevious } from 'hooks/usePrevious';
import json2mq from 'json2mq';
import { useHistory } from 'react-router-dom';

import { isElectronUserAgent as isElectron } from '../../utils/electron';

interface FromHistory {
    from?: string;
}

export type HistoryState = undefined | FromHistory;

export enum Tab {
    Home,
    Subscriptions,
    Organization,
    Marketplace,
    Test
}

interface OpenNavbarInterface {
    isOpen?: boolean;
    toggle?: boolean;
    savePreference?: boolean;
}

interface NavContextInterface {
    isOpen: boolean;
    openNavbar: (options: OpenNavbarInterface) => void;

    overlay: boolean;
    setOverlay: (overlay: boolean) => void;

    activeTab: Tab;
    setActiveTab: (tab: Tab) => void;

    isElectron: () => boolean;
}

const NavContext = createContext({} as NavContextInterface);

const { Provider, Consumer } = NavContext;

export const NAV_CACHE_PREFIX = 'nav_';

const NAVBAR_PREFERENCE_KEY = 'navbar_preference';
const NAV_DEFAULT_TO_OPEN_WIDTH = 1200;

export enum NavbarPreference {
    open = 'open',
    collapsed = 'collapsed'
}

const NavProvider: React.FC = ({ children }) => {
    const history = useHistory();

    const getCachedTab = (key): Tab => {
        const value = localStorage.getItem(NAV_CACHE_PREFIX + key);

        if (value === null) {
            return Tab.Home;
        }

        return parseInt(value);
    };

    const [activeTab, setActiveTab] = useState(getCachedTab('activeTab'));

    const openByDefault = useMediaQuery(json2mq({ minWidth: NAV_DEFAULT_TO_OPEN_WIDTH }));

    const [isOpen, setIsOpen] = useState(true);
    const [overlay, setOverlay] = useState(false);

    const openNavbar = useCallback(
        ({ isOpen: open, toggle, savePreference }: OpenNavbarInterface) => {
            if (toggle) {
                setIsOpen((prev) => !prev);
            } else {
                setIsOpen(open ?? true);
            }

            // at smaller screen widths manually opening and closing is done out of necessity,
            // at larger screen widths it is a preference
            if (openByDefault && savePreference) {
                const booleanValue = toggle ? !isOpen : open;
                const value = booleanValue ? NavbarPreference.open : NavbarPreference.collapsed;

                try {
                    localStorage.setItem(NAVBAR_PREFERENCE_KEY, value);
                } catch (err) {
                    console.log(err);
                }
            }
        },
        [openByDefault, isOpen]
    );

    useEffect(() => {
        setOverlay(!openByDefault);

        const navbarPreference = localStorage.getItem(NAVBAR_PREFERENCE_KEY);

        if (!navbarPreference || !openByDefault) {
            // no preference? open or close depending on screen width
            // also should automatically close when screen width is no longer open by default
            setIsOpen(openByDefault);
        } else if (navbarPreference === NavbarPreference.open && openByDefault) {
            // preference to have open? automatically open when screen width allows

            setIsOpen(true);
        }
    }, [openByDefault]);

    const prevActiveTab = usePrevious(activeTab);

    useEffect(() => {
        cacheNavState('activeTab', activeTab);
    }, [activeTab]);

    useEffect(() => {
        if (prevActiveTab !== undefined && prevActiveTab !== activeTab) {
            switch (activeTab) {
                case Tab.Home:
                    history.push('/dashboard');
                    break;
                case Tab.Subscriptions:
                    history.push('/subscriptions');
                    break;
                case Tab.Marketplace:
                    history.push('/marketplace');
                    break;
                // Tab.Organization is handled separately
                default:
                    return;
            }
        }
    }, [prevActiveTab, activeTab, history]);

    const cacheNavState = (key, value) => {
        localStorage.setItem(NAV_CACHE_PREFIX + key, value);
    };

    return (
        <Provider
            value={{
                isOpen,
                openNavbar,

                overlay,
                setOverlay,

                activeTab,
                setActiveTab,

                isElectron
            }}
        >
            {children}
        </Provider>
    );
};

export { NavContext, NavProvider, Consumer as NavConsumer };
