import { OwnerInfo } from 'components/ContentContext/Interfaces';
import { gql, GraphQLClient } from 'graphql-request';
import { MutationFunction } from 'react-query';

import buildGqlMutationFn from '../../../../../../../hooks/useGqlClient/helpers/buildGqlMutationFn';

const CREATE_UPLOAD_INTENT_QUERY = gql`
    mutation createAssetUploadIntent(
        $assetType: AssetType
        $attributes: JSON
        $ownerInfo: OwnerInfo!
        $contentType: String
    ) {
        createAssetUploadIntent(
            assetType: $assetType
            attributes: $attributes
            ownerInfo: $ownerInfo
            contentType: $contentType
        ) {
            assetId
            uploadURL
        }
    }
`;

export interface CreateUploadIntentVariables {
    assetType: string;
    attributes?: Record<string, unknown>;
    ownerInfo: OwnerInfo | null;
    contentType?: string;
}

export interface CreateUploadIntentResult {
    createAssetUploadIntent: {
        assetId: string;
        uploadURL: string;
    };
}

const makeCreateUploadIntent = (
    client: GraphQLClient
): MutationFunction<CreateUploadIntentResult, CreateUploadIntentVariables> =>
    buildGqlMutationFn(client, CREATE_UPLOAD_INTENT_QUERY);

export default makeCreateUploadIntent;
