import { EntityInfo, CollaboratorPermissionLevel } from 'components/ContentContext/Interfaces';
import { GraphQLClient } from 'graphql-request';
import gql from 'graphql-tag';
import buildGqlQueryFn from 'hooks/useGqlClient/helpers/buildGqlQueryFn';
import { QueryFunction, QueryKey } from 'react-query';

interface BuildGetEntityCollaboratorsInvitesQueryKeyInput {
    entity: EntityInfo;
    statuses: string[];
}

export const buildGetEntityCollaboratorsInvitesQueryKey = ({
    entity,
    statuses
}: BuildGetEntityCollaboratorsInvitesQueryKeyInput): QueryKey => ['getCollaboratorInvitations', entity, statuses];

//@TODO- ACCOUNTS api refactor to handle entities
const GET_ENTITY_COLLABORATOR_INVITES = gql`
    query getCollaboratorInvitations($skillId: UUIDv4!, $statuses: [String]) {
        getCollaboratorInvitations(skillId: $skillId, statuses: $statuses) {
            role
            email
            id
            sentAt
        }
    }
`;

export interface EntityCollaboratorsInvite {
    id: string | null;
    email: string;
    status: string;
    skillId: string;
    role: CollaboratorPermissionLevel;
    sentAt: string;
}

export enum EntityCollaboratorsStatus {
    Active = 'active',
    Accepted = 'accepted',
    Expired = 'expired'
}

export interface GetEntityCollaboratorsInvitesResponse {
    getCollaboratorInvitations: EntityCollaboratorsInvite[];
}

export interface GetEntityCollaboratorsInvitesVariables {
    skillId: string;
    statuses?: string[];
}

const getEntityCollaboratorsInvites = (
    client: GraphQLClient,
    variables: GetEntityCollaboratorsInvitesVariables
): QueryFunction<GetEntityCollaboratorsInvitesResponse> =>
    buildGqlQueryFn(client, GET_ENTITY_COLLABORATOR_INVITES, variables);

export default getEntityCollaboratorsInvites;
