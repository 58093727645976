import { GraphQLClient } from 'graphql-request';
import gql from 'graphql-tag';
import { MutationFunction } from 'react-query';

import buildGqlMutationFn from '../../../../hooks/useGqlClient/helpers/buildGqlMutationFn';

import { RemoveFavoriteItemResult, RemoveFavoriteItemVariables } from '.';

export const REMOVE_FAVORITE_ITEM = gql`
    mutation removeFavoriteItem($item: EntityInfoInput!) {
        removeFavoriteItem(item: $item) {
            success
        }
    }
`;

const makeRemoveFavoriteItem = (
    client: GraphQLClient
): MutationFunction<RemoveFavoriteItemResult, RemoveFavoriteItemVariables> =>
    buildGqlMutationFn(client, REMOVE_FAVORITE_ITEM);

export default makeRemoveFavoriteItem;
