import React from 'react';

const AddEmoji: React.FC = () => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 24 24"
            xmlSpace="preserve"
            fill="currentColor"
        >
            <title>Add emoji</title>
            <path
                d="M8.5,11C7.7,11,7,10.3,7,9.5S7.7,8,8.5,8S10,8.7,10,9.5S9.3,11,8.5,11z M12,17.5c2.3,0,4.3-1.5,5.1-3.5H6.9
	C7.7,16,9.7,17.5,12,17.5z M20,10c-0.1,0-0.2,0-0.3,0c0.2,0.6,0.3,1.3,0.3,2c0,4.4-3.6,8-8,8s-8-3.6-8-8s3.6-8,8-8
	c0.7,0,1.4,0.1,2,0.3c0-0.1,0-0.2,0-0.3c0-0.6,0.1-1.2,0.3-1.7C13.5,2.1,12.8,2,12,2C6.5,2,2,6.5,2,12c0,5.5,4.5,10,10,10
	s10-4.5,10-10c0-0.8-0.1-1.5-0.3-2.3C21.2,9.9,20.6,10,20,10z M17,9.5C17,8.7,16.3,8,15.5,8S14,8.7,14,9.5s0.7,1.5,1.5,1.5
	S17,10.3,17,9.5z M24,3v2h-3v3h-2V5h-3V3h3V0h2v3"
            />
        </svg>
    );
};

export const AddEmojiPath =
    'M8.5,11C7.7,11,7,10.3,7,9.5S7.7,8,8.5,8S10,8.7,10,9.5S9.3,11,8.5,11z M12,17.5c2.3,0,4.3-1.5,5.1-3.5H6.9C7.7,16,9.7,17.5,12,17.5z M20,10c-0.1,0-0.2,0-0.3,0c0.2,0.6,0.3,1.3,0.3,2c0,4.4-3.6,8-8,8s-8-3.6-8-8s3.6-8,8-8c0.7,0,1.4,0.1,2,0.3c0-0.1,0-0.2,0-0.3c0-0.6,0.1-1.2,0.3-1.7C13.5,2.1,12.8,2,12,2C6.5,2,2,6.5,2,12c0,5.5,4.5,10,10,10s10-4.5,10-10c0-0.8-0.1-1.5-0.3-2.3C21.2,9.9,20.6,10,20,10z M17,9.5C17,8.7,16.3,8,15.5,8S14,8.7,14,9.5s0.7,1.5,1.5,1.5S17,10.3,17,9.5z M24,3v2h-3v3h-2V5h-3V3h3V0h2v3';

export default AddEmoji;
