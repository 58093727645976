import React, { useContext, useRef, useEffect, useCallback } from 'react';

import { EditMode } from 'components/builder/context/EditorContext';
import { OwnerType } from 'components/ContentContext/Interfaces';
import { ComponentEngineMode } from 'components/engine';
import { AutoSaveIndicator } from 'components/engine/AutoSaveIndicator';
import CollectionBreadcrumbMenu from 'components/learn/CollectionBreadcrumbMenu';
import { LearnContext } from 'components/learn/LearnContext';
import { ScrollContext } from 'components/learn/ScrollContext';
import SkillEditToggle from 'components/learn/SkillEditToggle';
import { HeaderContext } from 'components/navigation/HeaderContext';
import { SidebarWrapper } from 'components/Sidebar/SidebarWrapper';
import { Container, Main, ContentWrapper } from 'components/SkillLayout.styles';
import { ProfileContext } from 'context/ProfileContext';
import { useQueryString } from 'context/QueryStringContext';
import useCreateAffiliation from 'helpers/affiliationMutations';
import { LearnEditContext } from 'pages/learn/LearnEditContext';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components';
import { SUB_HEADER_HEIGHT } from 'styles/variables';

import { EditorProviders } from '../Editor/EditorProviders';

import { Content } from './Content';
import { HeaderContent } from './HeaderContent';
import { SidebarContent } from './SidebarContent';

const Header = styled.div`
    display: flex;
    align-items: center;
    padding: 0 1rem;
    width: 100%;
    background-color: ${(props) => props.theme.colors.surface};
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
    height: ${SUB_HEADER_HEIGHT}px;
`;

// shared space between learning and editing, provides a smooth transition between the two (separate concept from previewing)
export const LearnEditContent: React.FC = () => {
    const history = useHistory();
    const { skill, loading, refetchSkill } = useContext(LearnContext);
    const { setScrollRef } = useContext(ScrollContext);
    const { skillEditId } = useContext(LearnEditContext);
    const { tenantSlug } = useParams<{ tenantSlug?: string }>();

    const { setCurrentContentOwner, setIsContentLoading } = useContext(HeaderContext);
    const { availableProfiles } = useContext(ProfileContext);
    const { queryString } = useQueryString();
    const { query, pathname } = queryString;

    const { mutate: createAffiliationMutation } = useCreateAffiliation();

    useEffect(() => {
        if (!skill?.organizationId) return;
        setCurrentContentOwner({ type: OwnerType.Organization, id: skill.organizationId });
    }, [skill?.organizationId]);

    useEffect(() => {
        setIsContentLoading(loading);
    }, [loading]);

    /* Check for Browse Redirect - Auto-affiliate to Skill owner. */
    useEffect(() => {
        /* If no query params, we don't plan to do anything. */
        if (!query) return;

        /* Check if User redirected from Browse */
        const browseRedirect = query.get('browse_redirect');

        if (!browseRedirect) return;

        /* Automatically "follow" Skill Owner on Browse Redirect. */
        const affiliationId = skill.organizationId || null;

        /* Grab Available Profiles and make sure we aren't already "following"/"subscribed". */
        const existingAffiliations = availableProfiles.map((_profile) => _profile.id);
        if (affiliationId !== null && existingAffiliations.indexOf(affiliationId) < 0) {
            createAffiliationMutation({ affiliationId });
        }
    }, [queryString, pathname]); // Omitting some dependencies as we don't want to render this unless path/query args change.

    const onItemSelect = useCallback(
        (trailingUrl) => {
            return history.push(`/${tenantSlug}/${trailingUrl}`);
        },
        [history, tenantSlug]
    );

    const ref = useRef<HTMLElement>(null);

    useEffect(() => {
        if (ref) setScrollRef(ref);
    }, [ref]);

    return (
        <EditorProviders skillId={skill.skillId} editMode={EditMode.None} refetchSkill={refetchSkill} skill={skill}>
            {tenantSlug ? <CollectionBreadcrumbMenu onItemSelect={onItemSelect} /> : null}
            <Container>
                <Main>
                    <Header data-testid={skillEditId ? '' : `learning-header-${skill.skillId}`}>
                        <SkillEditToggle />
                        <AutoSaveIndicator />
                        <HeaderContent skillId={skillEditId} />
                    </Header>
                    <ContentWrapper ref={ref}>
                        <Content skillId={skillEditId} />
                    </ContentWrapper>
                </Main>
                <SidebarWrapper>
                    <SidebarContent />
                </SidebarWrapper>
            </Container>
        </EditorProviders>
    );
};
