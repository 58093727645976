import React, { useContext } from 'react';

import { Button, DialogActions, DialogContent, TextField } from '@material-ui/core';
import { useLinks } from 'components/engine/common/RichTextEditor/useLinks';
import { useForm } from 'react-hook-form';

import { RichTextEditorContext } from '../../common/RichTextEditor/Context';
import { Control } from '../../common/RichTextEditor/LinkModal.styles';

interface FormFields {
    text: string;
    link: string;
}

interface LinkFormProps {
    onClose: () => void;
}

export const LinkForm: React.FC<LinkFormProps> = ({ onClose }) => {
    const { register, handleSubmit, errors } = useForm<FormFields>();
    const { editorState } = useContext(RichTextEditorContext);
    const { toggleLink, addLink, updateLink } = useLinks(onClose);

    const selection = editorState.getSelection();
    const anchorKey = selection.getAnchorKey();
    const start = selection.getStartOffset();
    const end = selection.getEndOffset();

    const currentBlock = editorState.getCurrentContent().getBlockForKey(anchorKey);
    const text = currentBlock.getText();
    const selectedText = text.slice(start, end);

    const entityId = currentBlock.getEntityAt(start) ?? currentBlock.getEntityAt(end);

    let existingLink;
    if (entityId) {
        const existingEntity = editorState.getCurrentContent().getEntity(entityId).getData();
        existingLink = existingEntity?.url;
    }

    const onSubmit = ({ text, link }) => {
        if (entityId) {
            updateLink(entityId, link);
        } else if (selectedText) {
            toggleLink(selectedText, link);
        } else {
            addLink(text, link);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
                {!entityId && !selectedText && (
                    <Control fullWidth>
                        <TextField
                            autoFocus
                            variant="outlined"
                            id="text"
                            label="Text"
                            name="text"
                            type="text"
                            fullWidth
                            defaultValue={selectedText}
                            error={!!errors.text}
                            inputRef={register({
                                required: true,
                                validate: (value) => !!value.trim() || 'At least 1 character is required'
                            })}
                            helperText={errors.text?.message}
                        />
                    </Control>
                )}
                <Control fullWidth>
                    <TextField
                        autoFocus={!!(entityId || selectedText)}
                        variant="outlined"
                        id="link"
                        label="Link"
                        name="link"
                        type="url"
                        fullWidth
                        defaultValue={existingLink}
                        error={!!errors.link}
                        inputRef={register({
                            required: !existingLink
                        })}
                        helperText={errors.link?.message}
                    />
                </Control>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button variant="contained" color="primary" type="submit">
                    Save
                </Button>
            </DialogActions>
        </form>
    );
};
