import React, { useMemo } from 'react';

import { HeadCell } from 'components/SortableTable/EnhancedTableHead';

import { LoadingRow, Row } from './TableRow';

import { CollaborationTableItem } from '.';

interface RowItemProps<T> {
    index: number;
    style?: React.CSSProperties;
    data: {
        items: T[];
        headCellDetails: HeadCell<T>[];
        multiSelect: boolean;
        selected: Set<string>;
        setSelected: React.Dispatch<React.SetStateAction<Set<string>>>;
        ActionComponent?: React.ComponentType<{ item: T }>;
    };
}

export const RowItem = <T extends CollaborationTableItem>({
    index,
    style,
    data: { items, headCellDetails, multiSelect, selected, setSelected, ActionComponent }
}: RowItemProps<T>): React.ReactElement | null => {
    // use index - 1 to skip the header row
    const item = useMemo(() => items[index - 1], [items, index]);

    // header row - returned in innerElement
    if (index === 0) {
        return null;
    }

    return item ? (
        <Row
            style={style}
            key={index}
            headCellDetails={headCellDetails}
            item={item}
            multiSelect={multiSelect}
            selected={selected}
            setSelected={setSelected}
            ActionComponent={ActionComponent}
        />
    ) : (
        <LoadingRow
            style={style}
            key={index}
            multiSelect={multiSelect}
            headCellDetails={headCellDetails}
            index={index}
            hasActionComponent={!!ActionComponent}
        />
    );
};
