import * as yup from 'yup';

import { MessageEventType } from '../..';
import AckableAdepteductMessage from '../../../base/AckableAdepteductMessage';
import PossibleEventFields from '../../PossibleEventFields';

export interface EventFieldsSessionJoined extends PossibleEventFields {
    sessionId: string;
    userId: string;
}

export default class ChannelMeetingSessionJoined extends AckableAdepteductMessage<EventFieldsSessionJoined> {
    schema = yup.object({
        sessionId: yup.string(),
        userId: yup.string()
    });

    type = MessageEventType.ChannelMeetingSessionJoined;
}
