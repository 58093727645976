import React from 'react';

import { EntityType } from 'components/ContentContext/Enums';
import { usePopover } from 'hooks/usePopover';

import DynamicNavigatorMenu from './DynamicNavigatorMenu';
import { useCollectionBreadcrumbs } from './useCollectionBreadcrumbs';

interface CollectionBreadcrumbMenuProps {
    onItemSelect: (trailingUrl: string, type: EntityType) => void;
}

const CollectionBreadcrumbMenu: React.FC<CollectionBreadcrumbMenuProps> = ({ onItemSelect }) => {
    const { open, anchorEl, handlePopoverClick, handlePopoverClose } = usePopover();

    const { currentItemId, parentId, crumbIndex, collectionSlugsArray } = useCollectionBreadcrumbs(
        handlePopoverClick,
        onItemSelect
    );

    return (
        <DynamicNavigatorMenu
            open={open}
            anchorEl={anchorEl}
            handlePopoverClose={handlePopoverClose}
            parentId={parentId}
            currentItemId={currentItemId}
            onItemSelect={(item) => {
                const newCollectionSlugs = collectionSlugsArray.slice(0, crumbIndex);
                const collectionSlugString = newCollectionSlugs.length > 0 ? `${newCollectionSlugs.join('/')}/` : '';

                if ('skillId' in item) {
                    return onItemSelect(`${collectionSlugString}skill/${item.skillSlug}`, EntityType.SKILL);
                }

                return onItemSelect(`${collectionSlugString}${item.collectionSlug}`, EntityType.COLLECTION);
            }}
        />
    );
};

export default CollectionBreadcrumbMenu;
