import React, { Fragment } from 'react';

import { SelectableChip, TooltippedIconButton } from '@adept-at/lib-react-components';
import { mdiTableCog } from '@mdi/js';
import Icon from '@mdi/react';
import { useModal } from 'hooks/useModal';
import { OrganizationTerminology, useOrganizationTerminology } from 'hooks/useOrganizationTerminology';
import pluralize from 'pluralize';
import styled from 'styled-components';
import { uppercaseFirst } from 'utils/uppercaseFirst';

import SearchBar from '../SearchBar';

import CustomizeModal from './CustomizeModal';
import { BaseFilter, Filter, HeadCellPreference } from './utils';

const ActionContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
`;

const FlexContainer = styled.div`
    display: flex;
    align-items: center;
`;

const VerticalDivider = styled.div`
    width: 1px;
    height: 75%;
    flex-shrink: 0;
    background-color: ${(props) => props.theme.colors.border};
    margin: 0 8px;
`;

interface ActionBarInterface {
    availableFilters: {
        filter: Filter;
        iconPath: string;
    }[][];
    filter: Filter;
    setFilter: React.Dispatch<React.SetStateAction<Filter>>;
    search: string;
    setSearch: React.Dispatch<React.SetStateAction<string>>;
    headCellPreferences: HeadCellPreference[];
    setHeadCellPreferences: React.Dispatch<React.SetStateAction<HeadCellPreference[]>>;
}

const buildFilterLabel = (filterOption: Filter, terminology: OrganizationTerminology) => {
    if (filterOption === BaseFilter.SKILLS) {
        return uppercaseFirst(pluralize(terminology.skill));
    }
    if (filterOption === BaseFilter.COLLECTIONS) {
        return uppercaseFirst(pluralize(terminology.collection));
    }
    return filterOption;
};

const ActionBar: React.FC<ActionBarInterface> = ({
    availableFilters,
    setFilter,
    filter,
    search,
    setSearch,
    headCellPreferences,
    setHeadCellPreferences
}) => {
    const { modalOpen, handleModalClose, handleModalOpen } = useModal();
    const { terminology } = useOrganizationTerminology();

    return (
        <ActionContainer>
            <FlexContainer>
                {availableFilters.map((filterGroup, index) => (
                    <Fragment key={index}>
                        {filterGroup.map(({ filter: filterOption, iconPath }) => (
                            <SelectableChip
                                key={filterOption}
                                avatar={<Icon path={iconPath} />}
                                selected={filter === filterOption}
                                label={buildFilterLabel(filterOption, terminology)}
                                onClick={() => setFilter(filterOption)}
                            />
                        ))}
                        {index !== availableFilters.length - 1 ? <VerticalDivider /> : null}
                    </Fragment>
                ))}
            </FlexContainer>
            <FlexContainer>
                <SearchBar search={search} setSearch={setSearch} />
                <TooltippedIconButton
                    aria-label="Customize table"
                    icon={<Icon path={mdiTableCog} />}
                    title="Customize table"
                    onClick={handleModalOpen}
                />
                <CustomizeModal
                    open={modalOpen}
                    onClose={handleModalClose}
                    headCellPreferences={headCellPreferences}
                    setHeadCellPreferences={setHeadCellPreferences}
                />
            </FlexContainer>
        </ActionContainer>
    );
};

export default ActionBar;
