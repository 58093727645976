import * as yup from 'yup';

import { MessageEventType } from '../..';
import AckableAdepteductMessage from '../../../base/AckableAdepteductMessage';
import PossibleEventFields from '../../PossibleEventFields';

export interface EventFieldsSessionPresenterChange extends PossibleEventFields {
    sessionId: string;
    userId: string;
    spot: number;
    subscribeToken: string;
    version: number;
    pinned: boolean;
    micOn: boolean;
}

export default class ChannelMeetingSessionPresenterChange extends AckableAdepteductMessage<EventFieldsSessionPresenterChange> {
    schema = yup.object({
        sessionId: yup.string(),
        userId: yup.string(),
        spot: yup.number(),
        subscribeToken: yup.string(),
        version: yup.number(),
        pinned: yup.boolean(),
        micOn: yup.boolean()
    });

    type = MessageEventType.ChannelMeetingSessionPresenterChange;
}
