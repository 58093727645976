import React from 'react';

import { QuestionType } from '@adept-at/lib-react-components';

import { getQuestionTypeValues } from './lib/question';
import { List, ListItems, ListItem, Title } from './QuestionTypeList.styles';

interface QuestionListProps {
    onClick: (type: QuestionType) => void;
    selectedType: QuestionType;
}

const QuestionList: React.FC<QuestionListProps> = ({ onClick, selectedType }) => {
    return (
        <List data-testid={`questionList-selected-${selectedType}`}>
            <ListItems>
                {getQuestionTypeValues.map((component, i) => {
                    const { Icon, text, key } = component;
                    const selected = selectedType === key;

                    return (
                        <ListItem
                            key={i}
                            $selected={selected}
                            aria-label={`select ${key} question`}
                            data-testid={`questionList-listItem-${key}`}
                            onClick={() => onClick(key)}
                        >
                            <Icon />
                            <Title>{text}</Title>
                        </ListItem>
                    );
                })}
            </ListItems>
        </List>
    );
};

export default QuestionList;
