import { QuestionType, QuestionComponentValues } from '@adept-at/lib-react-components';
import { CurrentUser } from 'hooks/useCurrentUser';

type BlankReturnValue = string[] | undefined;
type BooleanReturnValue = 'true' | 'false' | undefined;
type CheckboxReturnValue = string[] | undefined;
type ChoiceReturnValue = string | undefined;

export interface MatchReturnValue {
    [key: string]: string;
}

export interface OrderedReturnValue {
    [key: string]: string;
}

export type PollReturnValue = string[] | undefined;

export type InitialQuestionValue =
    | BlankReturnValue
    | BooleanReturnValue
    | CheckboxReturnValue
    | ChoiceReturnValue
    | MatchReturnValue
    | OrderedReturnValue
    | PollReturnValue;

interface ComponentValueProps {
    componentValues: QuestionComponentValues;
}

export const buildCorrectBlankValue = ({ componentValues }: ComponentValueProps): BlankReturnValue => {
    return componentValues.blankAnswers.slice(0, 1);
};

export const buildCorrectMatchValue = ({ componentValues }: ComponentValueProps): MatchReturnValue => {
    const initialValue = {};

    componentValues?.answerOptions.forEach((option) => {
        const componentValueKey = option?.key;

        const match = componentValues.matchingPairs[componentValueKey];

        initialValue[componentValueKey] = match.key;
    });

    return initialValue;
};

export const buildCorrectOrderValue = ({ componentValues }: ComponentValueProps): OrderedReturnValue => {
    const initialValue = {};

    componentValues.answerOptions.map((option, index) => {
        initialValue[option.key] = `${index}`;
    });

    return initialValue;
};

interface BuildPollInitialValueProps {
    componentValues: QuestionComponentValues;
    currentUser: CurrentUser;
}

export const buildInitialPollValue = ({
    componentValues,
    currentUser
}: BuildPollInitialValueProps): PollReturnValue => {
    if (componentValues.pollResponses) {
        const { totalResponses, ...stuff } = componentValues.pollResponses;

        const userAnswers = Object.entries(stuff)
            .filter(
                ([_answerKey, userIds]) =>
                    Array.isArray(userIds) && currentUser?.userId && userIds.includes(currentUser.userId)
            )
            .map(([answerKey, _userIds]) => answerKey);

        return userAnswers;
    }

    return;
};

interface BuildCorrectAnswerProps {
    componentValues: QuestionComponentValues;
    currentUser: CurrentUser;
}

const buildCorrectAnswer = ({ componentValues, currentUser }: BuildCorrectAnswerProps) => {
    const { questionType } = componentValues;

    switch (questionType) {
        case QuestionType.Blank:
            return buildCorrectBlankValue({ componentValues });
        case QuestionType.Boolean:
            return `${componentValues.booleanAnswer}`;
        case QuestionType.Checkbox:
            return componentValues.checkboxAnswers;
        case QuestionType.Choice:
            return componentValues.choiceAnswer;
        case QuestionType.Match:
            return buildCorrectMatchValue({ componentValues });
        case QuestionType.Order:
            return buildCorrectOrderValue({ componentValues });
        case QuestionType.Poll:
            return buildInitialPollValue({ componentValues, currentUser });
        default:
            return;
    }
};

interface BuildInitialValueProps {
    componentValues: QuestionComponentValues;
    currentUser: CurrentUser;
    questionCompleted: boolean;
}

export const buildInitialQuestionValue = ({
    componentValues,
    currentUser,
    questionCompleted
}: BuildInitialValueProps): InitialQuestionValue => {
    const { questionType } = componentValues;

    if (questionCompleted) {
        return buildCorrectAnswer({ componentValues, currentUser });
    }

    if (questionType === QuestionType.Poll) {
        return buildInitialPollValue({ componentValues, currentUser });
    }

    return;
};
