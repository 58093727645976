import React from 'react';

import AdepteductMessageProps from 'components/communication/message/AdepteductMessageProps';
import ChannelTemplateChatMessage from 'components/communication/message/chat/ChannelTemplateMessage';
import DefaultAdepteductMessage from 'components/communication/message/chat/DefaultAdepteductMessage';
import * as yup from 'yup';

import { MessageEventType } from '..';
import AckableAdepteductMessage from '../../base/AckableAdepteductMessage';
import { MessageDestination } from '../../MessageRecord';
import PossibleEventFields from '../PossibleEventFields';

interface ChannelTemplateMessageEventFields extends PossibleEventFields {
    template: string;
    user?: string;
    variables?: {
        [key: string]: string;
    };
}

export default class ChannelTemplateMessage extends AckableAdepteductMessage<ChannelTemplateMessageEventFields> {
    schema = yup.object({
        template: yup.string().required(),
        user: yup.string(),
        variables: yup.object()
    });

    type = MessageEventType.ChannelTemplateMessage;

    mapDestinationToComponent = (
        destination?: MessageDestination
    ): React.ForwardRefExoticComponent<
        AdepteductMessageProps<PossibleEventFields> & React.RefAttributes<HTMLDivElement | null>
    > => {
        if (destination === MessageDestination.Chat) {
            return ChannelTemplateChatMessage;
        }

        return DefaultAdepteductMessage;
    };
}
