import { useState, useEffect, useMemo } from 'react';

import { ImageFormat } from 'components/ContentContext/Interfaces';
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';

import { useFileUpload } from '../engine/Components/PDF/FileUpload';
import { AssetType } from '../engine/Components/PDF/FileUpload/hooks/useCreateUploadIntent';

import { CropDetails } from './useCrop';

export enum UploadWithCropStatus {
    None,
    FileSelection,
    FileSelected,
    Crop,
    Uploading,
    Uploaded,
    Error
}

interface UseUploadWithCropProps {
    acceptedFileExtensions: string[];
    maxFileSize: number;
    uploadIntentAttributes?: Record<string, unknown>;
    onFinishUpload?: (assetId: string, image: string, crop: CropDetails) => void;
}

interface UseUploadWithCropInterface {
    getDropzoneProps: () => DropzoneRootProps;
    getInputProps: () => DropzoneInputProps;
    uploadProgress: number | null;
    status: UploadWithCropStatus;
    setCrop: (crop: CropDetails) => void;
    crop: CropDetails | null;
    save: () => void;
    originalImage: string | null;
}

/**
 * When uploading, we want to have the opportunity to crop/edit the image prior to committing.
 *
 * This serves as the glue between the Upload and Crop phases.
 */
export const useUploadWithCrop = ({
    acceptedFileExtensions,
    maxFileSize,
    uploadIntentAttributes = {},
    onFinishUpload = () => {}
}: UseUploadWithCropProps): UseUploadWithCropInterface => {
    const [status, setStatus] = useState<UploadWithCropStatus>(UploadWithCropStatus.None);
    const [originalImage, setOriginalImage] = useState<string | null>(null);
    const [crop, setCrop] = useState<CropDetails | null>(null);
    const [imageFormat, setImageFormat] = useState<ImageFormat | null>(null);

    const attributes = useMemo(() => {
        return {
            ...uploadIntentAttributes,
            format: imageFormat
        };
    }, [uploadIntentAttributes, imageFormat]);

    const { getDropzoneProps, getInputProps, uploadProgress, createUploadIntentAndUploadFile } = useFileUpload({
        onSuccess: (assetId) => {
            if (assetId && originalImage && crop) {
                setStatus(UploadWithCropStatus.Uploaded);

                onFinishUpload(assetId, originalImage, crop);
            }
        },
        onError: () => {
            setStatus(UploadWithCropStatus.Error);
        },
        onFileSelected: (selectedFile) => {
            const uploadedFormat = selectedFile?.type?.split('/')[1];
            const format = uploadedFormat === 'jpeg' ? ImageFormat.JPG : (uploadedFormat.toUpperCase() as ImageFormat);

            setStatus(UploadWithCropStatus.FileSelected);
            setImageFormat(format);

            const reader = new FileReader();
            reader.onload = () => {
                setStatus(UploadWithCropStatus.Crop);
                setOriginalImage(reader.result as string);
            };
            reader.readAsDataURL(selectedFile);
        },
        assetType: AssetType.Image,
        acceptedFileExtensions,
        maxFileSize,
        autoUpload: false
    });

    /**
     * Since this hook's root component isn't unmounted until edit mode is toggled off, we need to reset state
     * in order to re-enable the upload DropZone and serve a clean slate for the next image upload.
     */
    useEffect(() => {
        if (status === UploadWithCropStatus.Uploaded) {
            setStatus(UploadWithCropStatus.None);
            setOriginalImage(null);
            setCrop(null);
        }
    }, [status]);

    return {
        getDropzoneProps,
        getInputProps,
        status,
        uploadProgress,
        crop,
        setCrop,
        save: () => {
            setStatus(UploadWithCropStatus.Uploading);

            /**
             * Calling createUploadIntentAndUploadFile() will kick off the createUploadIntent mutation followed by the
             * actual file upload of the selectedFile that the useFileUpload hook has in its state.
             *
             * We have delayed creating the upload intent until this point becuase we need to have crop details
             * in hand to set as "attributes" on the mutation.
             */
            createUploadIntentAndUploadFile({
                ...attributes,
                crop: crop ?? {}
            });
        },
        originalImage
    };
};
