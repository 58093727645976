import React, { useMemo } from 'react';

import { CollectionCatalogImage as CollectionCatalogImageComponent } from '@adept-at/lib-react-components';
import { useThemeContext, ThemeMode } from 'context/ThemeContext';

import { useCollectionImageContext } from './context/CollectionImageContext';

export const CollectionCatalogImage: React.FC = () => {
    const { themeMode } = useThemeContext();
    const { collectionImages, defaultCatalog, uploadedImages } = useCollectionImageContext();

    const lightModeImage = useMemo(() => {
        return uploadedImages?.catalogLight ?? collectionImages?.catalogLight ?? null;
    }, [collectionImages, uploadedImages]);

    const darkModeImage = useMemo(() => {
        return uploadedImages?.catalogDark ?? collectionImages?.catalogDark ?? null;
    }, [collectionImages, uploadedImages]);

    const imageSrc = useMemo(() => {
        if (themeMode === ThemeMode.DARK) {
            return darkModeImage ?? lightModeImage ?? defaultCatalog;
        }
        return lightModeImage ?? defaultCatalog;
    }, [lightModeImage, darkModeImage, themeMode, defaultCatalog]);

    return <CollectionCatalogImageComponent isDefault={imageSrc === defaultCatalog} src={imageSrc ?? undefined} />;
};
