import React, { useState, useMemo, useCallback } from 'react';

import { CourseIcon } from '@adept-at/lib-react-components';
import { Chip } from '@material-ui/core';
import { mdiUpload, mdiImageArea } from '@mdi/js';
import { Icon } from '@mdi/react';
import { Modal } from 'components/modals';

import { useCollectionImageContext, UploadImageMode, UploadImageType } from '../context/CollectionImageContext';

import { EditCatalogImageModalProps, ColorVariant } from './EditCatalogImageModal';
import {
    ChipWrapper,
    CollapsibleContainer,
    PromptTextContainer,
    RecommendedSizePrompt,
    CatalogImageContainer,
    ImageModeContainer,
    ToggleCustomImagesButton,
    BackgroundPreviewContainer,
    BackgroundColumn,
    BackgroundPreviewWrapper,
    BackgroundColumnWrapper,
    CollectionBackgroundImage,
    CollectionMobileBackground,
    UploadButtonsContainer,
    UploadImageButton,
    BackgroundImageButton
} from './styles';

interface UploadModalProps {
    type: UploadImageType;
    mode: UploadImageMode;
}

export const EditCollectionBackgroundModal: React.FC<EditCatalogImageModalProps> = ({
    contentTitle,
    open,
    onClose,
    onConfirm
}) => {
    const {
        collectionImages,
        defaultImages,
        defaultFeatured,
        setUploadDestination,
        handleUploadModalOpen = () => {},
        handleUseDefaultBackground = () => {}
    } = useCollectionImageContext();

    const [useDefaultImages, setUseDefaultImages] = useState(false);

    const renderDesktopLight = useMemo(() => {
        if (collectionImages?.featuredLight) {
            return <CollectionBackgroundImage $image={collectionImages?.featuredLight} />;
        }
        return <CollectionBackgroundImage $image={defaultFeatured?.featuredLight} />;
    }, [collectionImages, defaultFeatured?.featuredLight]);

    const renderDesktopDark = useMemo(() => {
        const desktopDark = collectionImages?.featuredDark ?? collectionImages?.featuredLight;

        if (desktopDark) {
            return <CollectionBackgroundImage $image={desktopDark} />;
        }

        return <CollectionBackgroundImage $image={defaultFeatured?.featuredDark} />;
    }, [collectionImages, defaultFeatured?.featuredDark]);

    const renderLightMobile = useMemo(() => {
        const mobileImage = collectionImages?.featuredLightMobile ?? collectionImages?.featuredLight;
        if (mobileImage) {
            return <CollectionMobileBackground $image={mobileImage} />;
        }
        return <CollectionMobileBackground $image={defaultFeatured?.featuredLightMobile} $isDefaultImage />;
    }, [collectionImages, defaultFeatured?.featuredLightMobile]);

    const renderDarkMobile = useMemo(() => {
        const mobileDarkImage =
            collectionImages?.featuredDarkMobile ??
            collectionImages?.featuredDark ??
            collectionImages?.featuredLightMobile ??
            collectionImages?.featuredLight;

        if (mobileDarkImage) {
            return <CollectionMobileBackground $image={mobileDarkImage} />;
        }
        return <CollectionMobileBackground $image={defaultFeatured?.featuredDarkMobile} $isDefaultImage />;
    }, [collectionImages, defaultFeatured?.featuredDarkMobile]);

    const onToggleCustomImages = useCallback(() => {
        setUseDefaultImages((prev) => !prev);
    }, []);

    const handleOpenModal = useCallback(
        ({ mode, type }: UploadModalProps) =>
            () => {
                if (setUploadDestination) {
                    setUploadDestination({ destination: 'featured', type, mode });
                    handleUploadModalOpen();
                }
            },
        [setUploadDestination, handleUploadModalOpen]
    );

    return (
        <Modal
            modalTitle="Background image"
            open={open}
            onClose={onClose}
            styleProps={{ 'max-width': '1200px' }}
            confirmButtonText="Done"
            onConfirm={() => {
                onConfirm();
            }}
        >
            <>
                <ChipWrapper>
                    <Chip
                        size="small"
                        icon={<CourseIcon aria-label="Collection" />}
                        label={contentTitle}
                        variant="outlined"
                    />
                </ChipWrapper>
                <CollapsibleContainer $collapsed={useDefaultImages}>
                    <PromptTextContainer>
                        <span>Custom images</span>
                    </PromptTextContainer>
                    <RecommendedSizePrompt>
                        Recommended sizes in pixels : 2400 x 1500 (desktop) • 800 x 1440 (mobile)
                    </RecommendedSizePrompt>
                    <BackgroundPreviewContainer>
                        <BackgroundColumn>
                            <BackgroundColumnWrapper>
                                <BackgroundPreviewWrapper>
                                    <span>Light mode desktop</span>
                                    {renderDesktopLight}
                                </BackgroundPreviewWrapper>
                                <BackgroundPreviewWrapper $mobile>
                                    <span>Mobile</span>
                                    {renderLightMobile}
                                </BackgroundPreviewWrapper>
                            </BackgroundColumnWrapper>
                            <UploadButtonsContainer>
                                <UploadImageButton onClick={handleOpenModal({ mode: 'light', type: 'desktop' })}>
                                    <Icon path={mdiUpload} />
                                    Desktop image
                                </UploadImageButton>
                                <UploadImageButton onClick={handleOpenModal({ mode: 'light', type: 'mobile' })}>
                                    <Icon path={mdiUpload} />
                                    Mobile image
                                </UploadImageButton>
                            </UploadButtonsContainer>
                        </BackgroundColumn>
                        <BackgroundColumn>
                            <BackgroundColumnWrapper>
                                <BackgroundPreviewWrapper>
                                    <span>Dark mode desktop</span>
                                    {renderDesktopDark}
                                </BackgroundPreviewWrapper>
                                <BackgroundPreviewWrapper $mobile>
                                    <span>Mobile</span>
                                    {renderDarkMobile}
                                </BackgroundPreviewWrapper>
                            </BackgroundColumnWrapper>
                            <UploadButtonsContainer>
                                <UploadImageButton onClick={handleOpenModal({ mode: 'dark', type: 'desktop' })}>
                                    <Icon path={mdiUpload} />
                                    Desktop image
                                </UploadImageButton>
                                <UploadImageButton onClick={handleOpenModal({ mode: 'dark', type: 'mobile' })}>
                                    <Icon path={mdiUpload} />
                                    Mobile image
                                </UploadImageButton>
                            </UploadButtonsContainer>
                        </BackgroundColumn>
                    </BackgroundPreviewContainer>
                </CollapsibleContainer>
                <ImageModeContainer $enableGutters={!useDefaultImages}>
                    <ToggleCustomImagesButton variant="outlined" onClick={onToggleCustomImages}>
                        <Icon path={useDefaultImages ? mdiUpload : mdiImageArea} />{' '}
                        {useDefaultImages ? 'Use custom images' : 'Use default images'}
                    </ToggleCustomImagesButton>
                </ImageModeContainer>
                <CollapsibleContainer $collapsed={!useDefaultImages}>
                    <PromptTextContainer>
                        <span>Default images</span>
                    </PromptTextContainer>
                    <CatalogImageContainer $enableGutters>
                        {Object.values(ColorVariant).map((color) => (
                            <BackgroundImageButton
                                key={color}
                                $image={defaultImages?.[color]?.featuredLight}
                                onClick={() => handleUseDefaultBackground(color)}
                            />
                        ))}
                    </CatalogImageContainer>
                </CollapsibleContainer>
            </>
        </Modal>
    );
};
