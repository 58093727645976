import { useMemo } from 'react';

import { useProfile } from 'context/ProfileContext';
import { ClientError } from 'graphql-request';
import gql from 'graphql-tag';
import { useBuildAuthenticatedQueryFn } from 'hooks/useGqlClient/useBuildAuthenticatedQueryFn';
import { Api } from 'lib/ApiConstants';
import { QueryKey, useQuery, UseQueryResult } from 'react-query';

// @GRAPHQL Query getUsersForOrganization
const QUERY_NAME = 'getUsersForOrganization';

export const buildGetUserForOrganizationQueryKey = (type?: string, organizationId?: string): QueryKey => [
    QUERY_NAME,
    type,
    organizationId
];

const GET_USERS_FOR_ORGANIZATION_QUERY = gql`
    query ${QUERY_NAME}($organizationId: String!) {
        ${QUERY_NAME}(organizationId: $organizationId) {
            members {
                id
                alias
            }
        }
    }
`;

export interface UserForOrganization {
    id: string;
    alias: string;
}

export interface GetUsersForOrganizationResult {
    getUsersForOrganization: {
        members: UserForOrganization[];
    };
}

export interface GetUsersForOrganizationVariables {
    organizationId: string;
}

export const useGetUsersForOrganization = (
    variables: GetUsersForOrganizationVariables
): UseQueryResult<GetUsersForOrganizationResult> => {
    const { isOrganization, currentProfile } = useProfile();

    const { queryFn } = useBuildAuthenticatedQueryFn<GetUsersForOrganizationResult, GetUsersForOrganizationVariables>(
        GET_USERS_FOR_ORGANIZATION_QUERY,
        variables,
        { api: Api.Accounts }
    );

    const queryKey = useMemo(
        () => buildGetUserForOrganizationQueryKey(currentProfile?.type, currentProfile?.id),
        [currentProfile]
    );

    return useQuery<GetUsersForOrganizationResult, ClientError>(queryKey, { queryFn, enabled: isOrganization });
};
