import React, { useCallback, useEffect, useContext, useMemo } from 'react';

import { ShareMenu } from '@adept-at/lib-react-components';
import { mdiCalendarClock, mdiHeartOutline, mdiHeart } from '@mdi/js';
import Icon from '@mdi/react';
import { BookshelvesContext } from 'components/bookshelves/BookshelvesContext';
import { EntityType } from 'components/ContentContext/Enums';
import { useFavorites } from 'components/dash/Favorites/useFavorites';
import useRemoveFavoriteItem from 'components/dash/hooks/useRemoveFavoriteItem';
import useUpsertFavoriteItem from 'components/dash/hooks/useUpsertFavoriteItem';
import { BasicCollectionInfo } from 'components/dash/interfaces';
import { ScheduleContext } from 'components/dash/Schedule/ScheduleContext';
import { BookshelfPlusIconPath } from 'components/icons/BookshelfPlus';
import { Descendant } from 'components/modals/Payment/Enroll';
import { EnrollmentButton } from 'components/modals/Payment/EnrollmentButton';
import { CollectionActions } from 'hooks/authorization';
import styled from 'styled-components';
import { isTrue } from 'utils/isTrue';

import { MoreActionsMenu } from './ActionMenus';
import { NestedCollectionContext } from './context/NestedCollectionContext';
import {
    CollectionQuickActions,
    NestedCollectionActions,
    QuickActionItemContainer,
    QuickActionItem
} from './NestedCollection.styles';

const EnrollButtonWrapper = styled.div`
    margin-right: 1rem;
`;

const StyledQuickActionItemContainer = styled(QuickActionItemContainer)<{ $isStickyMode?: boolean }>`
    svg {
        color: ${(props) => (props.$isStickyMode ? props.theme.colors.primary : props.theme.colors.secondary)};
    }
`;

export interface CollectionActionProps {
    isStickyMode?: boolean;
}

export const CollectionActionWrapper = React.forwardRef<HTMLDivElement, CollectionActionProps>(
    ({ isStickyMode }, ref) => {
        const {
            authorizedActions,
            isEditMode,
            setIsEditMode,
            collectionId,
            parentQuerySlug,
            tenantSlug,
            aboutInfo,
            collectionData,
            tenantProfileData,
            childrenBasicDetails
        } = useContext(NestedCollectionContext);

        const { openLearnLaterModal } = useContext(ScheduleContext);
        const { openBookshelfModal } = useContext(BookshelvesContext);

        const { favorites } = useFavorites();
        const { mutate: addToFavorites } = useUpsertFavoriteItem();
        const { mutate: removeFromFavorites } = useRemoveFavoriteItem();

        const canEdit = useMemo(
            () => authorizedActions.includes(CollectionActions.UpdateMetadata),
            [authorizedActions]
        );

        const canEnroll = useMemo(() => authorizedActions.includes(CollectionActions.Enroll), [authorizedActions]);

        const collection = useMemo(
            () => ({
                id: collectionId,
                title: aboutInfo?.title,
                slug: parentQuerySlug
            }),
            [collectionId, parentQuerySlug, aboutInfo]
        );

        const { descendants, continueLearningUrl } = useMemo(() => {
            return childrenBasicDetails.reduce(
                (prev, child) => {
                    if (child && 'skillId' in child) {
                        prev.descendants.push({
                            estimatedTimeToConsume: child.estimatedSecondsToConsume ?? 0,
                            completionDate: child.progress?.completedAt,
                            title: child.title,
                            id: child.skillId
                        });

                        if (!isTrue(child.progress?.completedAt) && !isTrue(prev.continueLearningUrl)) {
                            prev.continueLearningUrl = `/${tenantSlug}/${collectionData?.collectionSlug}/skill/${child.skillSlug}`;
                        }
                    }
                    return prev;
                },
                { descendants: [] as Descendant[], continueLearningUrl: '' }
            );
        }, [childrenBasicDetails, tenantSlug, collectionData?.collectionSlug]);

        const isFavorited = useMemo(() => {
            return isTrue(
                favorites.find((favorite) => (favorite as BasicCollectionInfo).collectionId === collectionId)
            );
        }, [favorites, collectionId]);

        const toggleEditMode = useCallback(() => {
            setIsEditMode((prevState) => !prevState);
        }, [setIsEditMode]);

        const handleLearnLater = () => {
            openLearnLaterModal({ type: EntityType.COLLECTION, id: collection.id });
        };

        const handleBookshelfClicked = () => {
            openBookshelfModal({
                type: EntityType.COLLECTION,
                id: collection.id,
                title: collection.title ?? ''
            });
        };

        const handleAddOrRemoveFavorite = () => {
            const item = { type: EntityType.COLLECTION, id: collection.id };

            if (isFavorited) {
                removeFromFavorites({ item });
            } else {
                addToFavorites({ item });
            }
        };

        return (
            <NestedCollectionActions ref={ref}>
                {canEnroll && collectionData ? (
                    <EnrollButtonWrapper>
                        <EnrollmentButton
                            entityData={collectionData}
                            tenantDataLoading={!tenantProfileData}
                            tenantId={tenantProfileData?.getTenantProfileForOwner.tenantProfileId}
                            descendants={descendants}
                            continueLearningUrl={continueLearningUrl}
                        />
                    </EnrollButtonWrapper>
                ) : null}
                <CollectionQuickActions>
                    <QuickActionItemContainer>
                        <QuickActionItem
                            title="Learn later"
                            $isStickyMode={isStickyMode}
                            icon={<Icon path={mdiCalendarClock} />}
                            onClick={handleLearnLater}
                        />
                    </QuickActionItemContainer>
                    <QuickActionItemContainer $hideOnMobile>
                        <QuickActionItem
                            title="Add to bookshelf"
                            $isStickyMode={isStickyMode}
                            icon={<Icon path={BookshelfPlusIconPath} />}
                            onClick={handleBookshelfClicked}
                        />
                    </QuickActionItemContainer>
                    <QuickActionItemContainer $hideOnMobile>
                        <QuickActionItem
                            title={`${isFavorited ? 'Remove from' : 'Add to'} favorites`}
                            $isStickyMode={isStickyMode}
                            icon={<Icon path={isFavorited ? mdiHeart : mdiHeartOutline} />}
                            onClick={handleAddOrRemoveFavorite}
                        />
                    </QuickActionItemContainer>
                    <StyledQuickActionItemContainer $isStickyMode={isStickyMode} $hideOnMobile>
                        <ShareMenu variant="button" />
                    </StyledQuickActionItemContainer>
                    <QuickActionItemContainer>
                        <MoreActionsMenu
                            canEdit={canEdit}
                            isEditMode={isEditMode}
                            isStickyMode={isStickyMode}
                            isFavorited={isFavorited}
                            toggleEditMode={toggleEditMode}
                            handleLearnLater={handleLearnLater}
                            handleAddToBookshelf={handleBookshelfClicked}
                            handleAddRemoveFavorite={handleAddOrRemoveFavorite}
                        />
                    </QuickActionItemContainer>
                </CollectionQuickActions>
            </NestedCollectionActions>
        );
    }
);
