import React from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { Button } from '@material-ui/core';
import { mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import styled from 'styled-components';

const StyledButton = styled(Button)`
    width: fit-content;
    white-space: nowrap;
    margin-top: 2rem;
`;

interface AddDescriptionButtonProps {
    onClick: () => void;
}

export const AddDescriptionButton: React.FC<AddDescriptionButtonProps> = ({ onClick }) => {
    return (
        <StyledButton
            aria-label="Add about description"
            onClick={onClick}
            color="primary"
            startIcon={<Icon path={mdiPlus} size={IconSize.Small} />}
            variant="outlined"
        >
            Add description
        </StyledButton>
    );
};
