import React from 'react';

const TrackIcon: React.FC = () => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 24 24"
            xmlSpace="preserve"
            fill="currentColor"
        >
            <path d="M14.8,18.5l-7.1-7.1l7.1-7.1l7.1,7.1 M23.3,10l-7.1-7.1c-0.8-0.8-2-0.8-2.8,0L6.3,10c-0.8,0.8-0.8,2,0,2.8l7.1,7.1 c0.8,0.8,2,0.8,2.8,0l7.1-7.1C24.1,12,24.1,10.8,23.3,10 M10.6,2.9L9.2,1.5L0.7,10c-0.8,0.8-0.8,2,0,2.8l8.5,8.5l1.4-1.4l-8.5-8.5 L10.6,2.9z M16.6,13.9l-1.8-1.8l-1.8,1.8l1.8,1.8L16.6,13.9z M19.1,11.4l-1.8-1.8l-1.8,1.8l1.8,1.8L19.1,11.4z M14.1,11.4l-1.8-1.8 l-1.8,1.8l1.8,1.8L14.1,11.4z M16.6,8.9l-1.8-1.8l-1.8,1.8l1.8,1.8L16.6,8.9z" />
        </svg>
    );
};

export const TrackIconPath =
    'M14.8,18.5l-7.1-7.1l7.1-7.1l7.1,7.1 M23.3,10l-7.1-7.1c-0.8-0.8-2-0.8-2.8,0L6.3,10c-0.8,0.8-0.8,2,0,2.8l7.1,7.1 c0.8,0.8,2,0.8,2.8,0l7.1-7.1C24.1,12,24.1,10.8,23.3,10 M10.6,2.9L9.2,1.5L0.7,10c-0.8,0.8-0.8,2,0,2.8l8.5,8.5l1.4-1.4l-8.5-8.5 L10.6,2.9z M16.6,13.9l-1.8-1.8l-1.8,1.8l1.8,1.8L16.6,13.9z M19.1,11.4l-1.8-1.8l-1.8,1.8l1.8,1.8L19.1,11.4z M14.1,11.4l-1.8-1.8 l-1.8,1.8l1.8,1.8L14.1,11.4z M16.6,8.9l-1.8-1.8l-1.8,1.8l1.8,1.8L16.6,8.9z';

export default TrackIcon;
