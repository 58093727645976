import { useMemo } from 'react';

import { OptimistMutationContext, OptimistRegisterUpdaterOptions, useOptimist } from '@adept-at/lib-react-optimist';
import { EntityType } from 'components/ContentContext/Enums';
import { ClientError, gql } from 'graphql-request';
import { useSnackbar } from 'notistack';
import { UseMutateFunction, useMutation } from 'react-query';

import useGqlClient from '../../../../../hooks/useGqlClient';
import buildGqlMutationFn from '../../../../../hooks/useGqlClient/helpers/buildGqlMutationFn';
import { API_ACCOUNTS } from '../../../../../lib/ApiConstants';

import {
    buildGetEntityCollaboratorsInvitesQueryKey,
    GetEntityCollaboratorsInvitesResponse,
    EntityCollaboratorsStatus
} from './getEntityCollaboratorInvites';

const DELETE_ENTITY_COLLABORATOR_INVITATION = gql`
    mutation deleteCollaboratorInvitation($invitationId: String!, $skillId: String!) {
        deleteCollaboratorInvitation(invitationId: $invitationId, skillId: $skillId) {
            success
        }
    }
`;

interface DeleteCollaboratorInvitationResult {
    deleteCollaboratorInvitation: boolean;
}

interface DeleteCollaboratorInvitationProps {
    invitationId: string;
    skillId: string;
}

const useDeleteEntityCollaboratorInvitation = ({
    onFinished,
    skillId
}: {
    onFinished: () => void;
    skillId: string;
}): {
    deleteEntityCollaboratorInvitation: UseMutateFunction<
        DeleteCollaboratorInvitationResult,
        ClientError,
        DeleteCollaboratorInvitationProps,
        OptimistMutationContext
    >;
    deleteEntityCollaboratorInvitationLoading: boolean;
} => {
    const { enqueueSnackbar } = useSnackbar();

    const { registerUpdater: registerDeleteCollaboratorInvite, mutationOptions } = useOptimist<
        DeleteCollaboratorInvitationResult,
        ClientError,
        DeleteCollaboratorInvitationProps
    >();

    const skillCollaboratorQueryKey = useMemo(
        () =>
            buildGetEntityCollaboratorsInvitesQueryKey({
                entity: { type: EntityType.SKILL, id: skillId },
                statuses: [EntityCollaboratorsStatus.Active, EntityCollaboratorsStatus.Expired]
            }),
        [skillId]
    );

    const deleteEntityCollaboratorOptions: OptimistRegisterUpdaterOptions<
        DeleteCollaboratorInvitationResult,
        ClientError,
        DeleteCollaboratorInvitationProps
    > = useMemo(() => {
        return {
            onSuccess: () => {
                onFinished();
                enqueueSnackbar('Invite successfully revoked', { variant: 'success' });
            },
            onError: () => {
                enqueueSnackbar('An error occurred deleting user invite.', { variant: 'error' });
            }
        };
    }, []);

    registerDeleteCollaboratorInvite<GetEntityCollaboratorsInvitesResponse>(
        skillCollaboratorQueryKey,
        (previous, inviteToRemove) => {
            const result = previous.getCollaboratorInvitations.filter((invite) => {
                return invite.id !== inviteToRemove.invitationId;
            });

            return { getCollaboratorInvitations: result };
        },
        deleteEntityCollaboratorOptions
    );

    const { client, withMutationOptions } = useGqlClient(API_ACCOUNTS);
    const { mutate: deleteEntityCollaboratorInvitation, isLoading: deleteEntityCollaboratorInvitationLoading } =
        useMutation<
            DeleteCollaboratorInvitationResult,
            ClientError,
            DeleteCollaboratorInvitationProps,
            OptimistMutationContext
        >(
            buildGqlMutationFn<DeleteCollaboratorInvitationResult, DeleteCollaboratorInvitationProps>(
                client,
                DELETE_ENTITY_COLLABORATOR_INVITATION
            ),
            withMutationOptions(mutationOptions)
        );

    return {
        deleteEntityCollaboratorInvitation,
        deleteEntityCollaboratorInvitationLoading
    };
};

export default useDeleteEntityCollaboratorInvitation;
