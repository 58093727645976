import React from 'react';

import { DeletedComment, EditedComment } from './Comment.style';
import EditComment from './EditComment';
import { Comment } from './interfaces';

interface Props {
    comment: Comment;
    isEditing: boolean;
    onUpdate: (newBody: string) => void;
    onCancelEdit: () => void;
}

const CommentBody: React.FC<Props> = ({ comment, isEditing, onUpdate, onCancelEdit }) => {
    const { body, editedAt, deletedAt } = comment;

    if (deletedAt) {
        return <DeletedComment>[deleted]</DeletedComment>;
    }

    if (isEditing) {
        return <EditComment comment={comment} onSave={onUpdate} onCancel={onCancelEdit} />;
    }

    if (editedAt && !deletedAt) {
        return (
            <>
                {body}
                <EditedComment>&nbsp;(edited)</EditedComment>
            </>
        );
    }

    return <>{body}</>;
};

export default CommentBody;
