import React, { useState, useEffect } from 'react';

import { MaterialTheme as AdeptTheme, DarkTheme, LightTheme } from '@adept-at/lib-react-components';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { useThemeContext, ThemeMode } from 'context/ThemeContext';
import { ThemeProvider } from 'styled-components';

export const ThemeWrapper: React.FC = ({ children }) => {
    const { themeMode } = useThemeContext();

    const [theme, setTheme] = useState(LightTheme);

    useEffect(() => {
        if (themeMode === ThemeMode.LIGHT) {
            setTheme(LightTheme);
        } else {
            setTheme(DarkTheme);
        }
    }, [themeMode]);

    return (
        <MuiThemeProvider theme={AdeptTheme(theme)}>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </MuiThemeProvider>
    );
};

const theme = AdeptTheme;

export default theme;
