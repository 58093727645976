import React, { useContext, useState } from 'react';

import { TooltippedIconButton, TooltippedButtonSize } from '@adept-at/lib-react-components';
import MuiMenuItem from '@material-ui/core/MenuItem';
import { mdiCommentTextOutline, mdiInformationOutline, mdiPencil, mdiSwapVerticalBold, mdiTrashCan } from '@mdi/js';
import Icon from '@mdi/react';
import { scrollComponentIntoView } from 'helpers/scrollComponentIntoView';
import { StyledMenu } from 'styles/Shared.styles';

import { useComponentEditor } from '..';
import { ComponentContext } from '../../../ComponentContext';
import { ComponentControlsProps } from '../../../ComponentControls';

import { RemoveComponentModal } from './RemoveComponentModal';

const EditorComponentControls: React.FC<ComponentControlsProps> = ({ options = {} }) => {
    const { id, type } = useContext(ComponentContext);
    const { onRemoveComponent, onStartEditingComponent, onStartRemovingComponent, onCancelRemovingComponent } =
        useComponentEditor();

    const [removeComponentModalOpen, setRemoveComponentModalOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const { edit, reorder, guidance, del, comment } = options;

    const voidFunction = () => {};

    const onGuidanceClick = guidance?.onClickOverride || voidFunction;
    const onCommentsClick = comment?.onClickOverride || voidFunction;

    const onEdit = edit?.onClickOverride || onStartEditingComponent;
    const onRemove = del?.onClickOverride || onRemoveComponent;

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleScrollTo = () => {
        const element = document.getElementById('sidebar');
        return scrollComponentIntoView(element);
    };

    const renderOrderControls = () => {
        if (!reorder?.enabled) return null;

        const { canComponentMoveUp, canComponentMoveDown, onMoveDown, onMoveUp } = reorder;

        if (!canComponentMoveUp(id) && !canComponentMoveDown(id)) {
            return null;
        }
        const moveUp = () => {
            onMoveUp(id);
            handleClose();
        };

        const moveDown = () => {
            onMoveDown(id);
            handleClose();
        };

        return (
            <>
                <StyledMenu
                    id="change-component-position-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {canComponentMoveUp(id) ? (
                        <MuiMenuItem data-testid={`componentControls-change-position-up-${id}`} onClick={moveUp}>
                            Move Up
                        </MuiMenuItem>
                    ) : null}
                    {canComponentMoveDown(id) ? (
                        <MuiMenuItem data-testid={`componentControls-change-position-down-${id}`} onClick={moveDown}>
                            Move Down
                        </MuiMenuItem>
                    ) : null}
                </StyledMenu>
            </>
        );
    };

    return (
        <>
            <>
                <RemoveComponentModal
                    isOpen={removeComponentModalOpen}
                    componentType={type}
                    onConfirm={() => {
                        onRemove(id);
                        setRemoveComponentModalOpen(false);
                    }}
                    onCancel={() => {
                        setRemoveComponentModalOpen(false);
                        onCancelRemovingComponent(id);
                    }}
                />

                {edit?.enabled ? (
                    <TooltippedIconButton
                        aria-label={'Edit'}
                        size={TooltippedButtonSize.Medium}
                        onClick={() => onEdit(id)}
                        icon={<Icon path={mdiPencil} />}
                        title={'Edit'}
                        data-testid={`componentControls-edit-${type}`}
                    />
                ) : null}
                {reorder?.enabled ? (
                    <TooltippedIconButton
                        aria-label={'Change position'}
                        size={TooltippedButtonSize.Medium}
                        onClick={handleClick}
                        icon={<Icon path={mdiSwapVerticalBold} />}
                        title={'Change position'}
                        data-testid={`componentControls-change-position-${id}`}
                    />
                ) : null}

                {del?.enabled ? (
                    <TooltippedIconButton
                        aria-label={'Delete'}
                        size={TooltippedButtonSize.Medium}
                        onClick={() => {
                            onStartRemovingComponent(id); // Give focus to component
                            setRemoveComponentModalOpen(true);
                            handleClose();
                        }}
                        icon={<Icon path={mdiTrashCan} />}
                        title={'Delete'}
                        data-testid={`componentControls-delete`}
                    />
                ) : null}
                {guidance?.enabled ? (
                    <TooltippedIconButton
                        aria-label={'Guidance'}
                        size={TooltippedButtonSize.Medium}
                        onClick={() => {
                            handleScrollTo();
                            onGuidanceClick(id);
                            handleClose();
                        }}
                        icon={<Icon path={mdiInformationOutline} />}
                        title={'Guidance'}
                    />
                ) : null}
                {comment?.enabled ? (
                    <TooltippedIconButton
                        aria-label={'Comments'}
                        size={TooltippedButtonSize.Medium}
                        onClick={() => {
                            handleScrollTo();
                            onCommentsClick(id);
                            handleClose();
                        }}
                        icon={<Icon path={mdiCommentTextOutline} />}
                        title={'Peer review comments'}
                    />
                ) : null}
            </>
            {renderOrderControls()}
        </>
    );
};

export { EditorComponentControls };
