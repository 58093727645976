import { useMemo } from 'react';

import { FavoriteItem, useGetFavorites } from '../hooks/useGetFavorites';

export interface UserFavoritesOutput {
    favorites: FavoriteItem[];
    favoritesLoading: boolean;
    favoritesErrored: boolean;
}

export const useFavorites = (): UserFavoritesOutput => {
    const { data: favoritesData, isLoading: favoritesLoading, isError: favoritesErrored } = useGetFavorites();

    const favorites = useMemo(
        () => favoritesData?.getUserFavorites?.items?.filter((item) => !!item) ?? [],
        [favoritesData]
    );

    return {
        favorites,
        favoritesLoading,
        favoritesErrored
    };
};
