import React, { useContext } from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { mdiAccountCircle } from '@mdi/js';
import Icon from '@mdi/react';
import styled from 'styled-components';

import { modularScale } from '../../utils/modular_scale';
import Shimmer from '../Shimmer';

import { CommentContext } from './CommentContext';
import CommentControls from './CommentControls';
import { Comment } from './interfaces';

const Container = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    height: 48px;
`;

const ImageContainer = styled.div<{ imageUrl?: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: ${(props) => (props.imageUrl ? `url(${props.imageUrl})` : props.theme.colors.secondaryLightGrey)};
    background-color: ${(props) => props.theme.colors.secondaryLightGrey};
    background-size: cover;
    background-position: center;
    border-radius: 40px;
    margin-right: 1.5rem;

    svg {
        width: ${IconSize.Medium};
        color: ${(props) => props.theme.colors.white};
    }
`;

const Info = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

const Alias = styled.div``;

const DateField = styled.div`
    font-size: ${modularScale(-1)};
`;

const StyledShimmer = styled(Shimmer)`
    margin-bottom: 10px;
`;

interface Props {
    comment: Comment;
    isHovered: boolean;
    onBeginEdit: () => void;
}

const CommentTitle: React.FC<Props> = ({ comment, isHovered, onBeginEdit }) => {
    const { isLoading } = useContext(CommentContext);
    const date = new Date(comment.createdAt).toLocaleDateString('en-US');
    const time = new Date(comment.createdAt).toLocaleTimeString('en-US');

    const renderImage = () => {
        if (comment.imageUrl) {
            return <ImageContainer imageUrl={comment.imageUrl} />;
        }

        return (
            <ImageContainer>
                <Icon path={mdiAccountCircle} />
            </ImageContainer>
        );
    };

    const renderAlias = () => {
        if (isLoading) {
            return <StyledShimmer rows={1} size={16} spacing={0} />;
        }

        return <Alias>{comment.alias}</Alias>;
    };

    return (
        <Container>
            {renderImage()}
            <Info>
                {renderAlias()}
                <DateField>{time + ' ' + date}</DateField>
            </Info>
            {isHovered && <CommentControls comment={comment} onBeginEdit={onBeginEdit} />}
        </Container>
    );
};

export default CommentTitle;
