import React, { useMemo } from 'react';

import { OwnerType } from 'components/ContentContext/Interfaces';
import AppLayout from 'layouts/AppLayout';

import RootProfileCollection from '../..';
import { RootCollectionProvider } from '../../context/RootCollectionContext';
import { TenantProfileDetails } from '../../context/useGetTenantProfile';
import {
    buildGetProfileForOwnerQueryKey,
    useGetTenantProfileForOwner
} from '../../context/useGetTenantProfileForOwner';

export const OrgMemberCatalog: React.FC<{ orgSlug: string; organizationId: string; usePrimary: boolean }> = ({
    organizationId,
    orgSlug,
    usePrimary
}) => {
    const { data, isLoading } = useGetTenantProfileForOwner(
        { owner: { type: OwnerType.Organization, id: organizationId } },
        { enabled: !!organizationId },
        usePrimary
    );

    const { refetch } = useGetTenantProfileForOwner(
        { owner: { type: OwnerType.Organization, id: organizationId } },
        { enabled: false },
        true
    );

    const formattedData = useMemo(() => {
        if (!data) return data;

        const { rootCollection: relevantCollection, ...rest } = data.getTenantProfileForOwner;
        const formatted: TenantProfileDetails = {
            relevantCollection,
            ...rest
        };
        return formatted;
    }, [data]);

    return (
        <RootCollectionProvider
            isInternal={false}
            ownerDetails={{ slug: orgSlug, type: OwnerType.Organization, id: organizationId }}
            tenantDetailsIsLoading={isLoading}
            tenantProfileDetails={formattedData}
            refetch={refetch}
            queryKey={buildGetProfileForOwnerQueryKey(organizationId)}
        >
            <AppLayout withoutPadding fullHeight mainContentOverflow>
                <RootProfileCollection />
            </AppLayout>
        </RootCollectionProvider>
    );
};
