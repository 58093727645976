import React, { useContext, useState } from 'react';

import { InputAdornment, TextField } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import styled from 'styled-components';

import { CommentContext } from './CommentContext';

const Container = styled.div<{ indentation: boolean }>`
    padding: 1rem;
    padding-left: ${(props) => (props.indentation ? '1.5rem' : '1rem')};
`;

const StyledIcon = styled(SendIcon)`
    cursor: pointer;
`;

const StyledTextField = styled(TextField)`
    background-color: ${(props) => props.theme.colors.surface};
`;

const AddCommentInput: React.FC = () => {
    const [commentToSend, setCommentToSend] = useState('');
    const { addComment, isThreadView } = useContext(CommentContext);

    return (
        <Container indentation={isThreadView}>
            <StyledTextField
                fullWidth
                multiline
                autoFocus
                variant="outlined"
                value={commentToSend}
                onChange={(e) => {
                    setCommentToSend(e.target.value);
                }}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                        addComment(commentToSend);
                        setCommentToSend('');
                    }
                }}
                id="comment-id"
                label="add comment"
                placeholder="Add a comment..."
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <StyledIcon
                                onClick={() => {
                                    addComment(commentToSend);
                                    setCommentToSend('');
                                }}
                            />
                        </InputAdornment>
                    )
                }}
            />
        </Container>
    );
};

export default AddCommentInput;
