import React, { useContext, useMemo, useEffect } from 'react';

import { ProfileContext } from 'context/ProfileContext';
import { useLocation, useParams } from 'react-router';
import styled from 'styled-components';

import { WideSidebar, Navbar, StyledBackdrop } from './Nav.styles';
import { NavContext, Tab } from './NavContext';
import NavHome from './NavHome';
import NavOrganization from './NavOrganization';
import NavSubscriptions from './NavSubscriptions';
import NavTabSelector from './NavTabSelector';
import NavTest from './NavTest';

const VersionInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;
`;

const Nav: React.FC = () => {
    const { isOpen, activeTab, openNavbar, setActiveTab, overlay } = useContext(NavContext);
    const { currentProfile } = useContext(ProfileContext);
    const { pathname } = useLocation();

    const params = useParams<{ currentOrgSlug?: string }>();

    useEffect(() => {
        if (params?.currentOrgSlug) {
            setActiveTab(Tab.Organization);
        }
    }, [params?.currentOrgSlug, setActiveTab]);

    useEffect(() => {
        if (pathname === '/marketplace') {
            setActiveTab(Tab.Marketplace);
        }
    }, [pathname, setActiveTab]);

    const backgroundColor = useMemo(() => currentProfile?.colors?.background, [currentProfile?.colors?.background]);

    const getVersion = () => {
        return <VersionInfo>Version: {process.env.ADEPT_VERSION}</VersionInfo>;
    };

    const renderSidebarContent = () => {
        switch (activeTab) {
            case Tab.Home:
                return <NavHome />;
            case Tab.Subscriptions:
                return <NavSubscriptions />;
            case Tab.Organization:
                // since we don't have concept of 'personal org' in Nav, redirect to Home bug ticket ref: https://app.asana.com/0/1191236917834241/1200769613945156
                if (currentProfile?.label === 'Personal') {
                    return <NavHome />;
                }

                return <NavOrganization />;
            case Tab.Test:
                return <NavTest />;
            default:
                return null;
        }
    };

    return (
        <>
            <Navbar $isOpen={isOpen}>
                <NavTabSelector isOpen={isOpen} backgroundColor={backgroundColor} />
                <WideSidebar $isOpen={isOpen} $overlay={overlay} $backgroundColor={backgroundColor}>
                    {renderSidebarContent()}
                    {getVersion()}
                </WideSidebar>
            </Navbar>
            <StyledBackdrop open={isOpen} $overlay={overlay} onClick={() => openNavbar({ isOpen: false })} />
        </>
    );
};

export default Nav;
