import React from 'react';

import { VideoComponentValues } from '@adept-at/lib-react-components';
import { useEditableComponent } from 'components/engine/mixins/editor';

import Editor from './Editor';
import { Loader } from './Editor/Loader';
import { PlaybackDetailsProvider } from './hooks/usePlaybackDetails';

export const Manage: React.FC<{ onCancel?: () => void; onSave?: (values: Partial<VideoComponentValues>) => void }> = ({
    onCancel,
    onSave
}) => {
    const {
        currentValues: { videoId = null }
    } = useEditableComponent<VideoComponentValues>();

    if (!videoId) return null;

    return (
        <>
            <PlaybackDetailsProvider videoId={videoId}>
                <Loader videoId={videoId}>
                    <Editor onCancel={onCancel} onSave={onSave} />
                </Loader>
            </PlaybackDetailsProvider>
        </>
    );
};

export default Manage;
