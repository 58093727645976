import React from 'react';

import { ErrorBoundary } from 'react-error-boundary';

import { AccountProvider } from './AccountContext';
import { AnalyticsTagTrackingProvider } from './AnalyticsTagTrackingContext';
import { AuthProvider } from './AuthContext';
import { AuthFatalError } from './AuthContext/AuthFatalError';
import { AvatarsProvider } from './AvatarsContext';
import { CommunicationChannelsProvider } from './CommunicationChannelsContext';
import { CommunicationUserDetailsProvider } from './CommunicationUserDetailsContext';
import { CommunicationWebsocketProvider } from './CommunicationWebsocketContext';
import { GroupsProvider } from './GroupsContext';
import { HistoricalCommunicationProvider } from './HistoricalCommunicationContext';
import { OrganizationProvider } from './OrganizationContext';
import { PrefetchProvider } from './PrefetchContext';
import { ProfileProvider } from './ProfileContext';
import { RealTimeCommunicationProvider } from './RealTimeCommunicationContext';
import { UserInfoProvider } from './UserInfoContext';

/**
 * This folder contains all of the global contexts for the app, and this file is where all of the providers are instantiated.
 *
 * Please pay very close attention to order on contexts, all contexts dependent on (or have a child component dependent on them), must be inside the other context.
 * I HEAVILY suggest instantiating your context by default with `undefined` rather than a blank object so that you know when this occurs.
 * Please make notes as to what your context is dependent on as you add it, so others can quickly reference where they might fit in.
 */

const GlobalContextProviders: React.FC = ({ children }) => {
    return (
        <AuthProvider>
            <ErrorBoundary FallbackComponent={AuthFatalError}>
                {/* CommunicationWebsocket - Dependent on Auth */}
                <CommunicationWebsocketProvider>
                    {/* HistoricalCommunication - Reliant on nothing, but the hook that uses it needs Auth */}
                    <HistoricalCommunicationProvider>
                        {/* RealTimeCommunication - Dependent on CommunicationWebsocket and HistoricalCommunication (for ChannelEditMessage) */}
                        <RealTimeCommunicationProvider>
                            {/* Avatars - Dependent on Auth indirectly, placed higher due to bug: https://app.asana.com/0/1191236917834241/1199916260975845/f */}
                            <AvatarsProvider>
                                {/* Dependent on Auth */}
                                <UserInfoProvider>
                                    {/* Dependent on Auth */}
                                    <CommunicationUserDetailsProvider>
                                        {/* Profile - Dependent on Auth directly */}
                                        <ProfileProvider>
                                            {/* Groups - Dependent on Auth directly */}
                                            <GroupsProvider>
                                                <CommunicationChannelsProvider>
                                                    {/* Analytics - Not dependent, but placed lower since the API call is not rendering anything */}
                                                    <AnalyticsTagTrackingProvider>
                                                        {/* Completely dependent on ProfileContext */}
                                                        <AccountProvider>
                                                            {/* Completely dependent on ProfileContext */}
                                                            <OrganizationProvider>
                                                                {/* Dependent on Auth */}
                                                                <PrefetchProvider>{children}</PrefetchProvider>
                                                            </OrganizationProvider>
                                                        </AccountProvider>
                                                    </AnalyticsTagTrackingProvider>
                                                </CommunicationChannelsProvider>
                                            </GroupsProvider>
                                        </ProfileProvider>
                                    </CommunicationUserDetailsProvider>
                                </UserInfoProvider>
                            </AvatarsProvider>
                        </RealTimeCommunicationProvider>
                    </HistoricalCommunicationProvider>
                </CommunicationWebsocketProvider>
            </ErrorBoundary>
        </AuthProvider>
    );
};

// eslint-disable-next-line import/no-default-export
export default GlobalContextProviders;
