import { useMemo } from 'react';

import { ClientError } from 'graphql-request';
import useGqlClient from 'hooks/useGqlClient';
import { API_COMMUNICATION } from 'lib/ApiConstants';
import { MessageEntityType } from 'lib/communication/message/MessageRecord';
import { useSnackbar } from 'notistack';
import { useMutation, UseMutationResult, UseQueryOptions } from 'react-query';

import makeEditMessage from './makeEditMessage';

export interface EditMessageResult {
    editEntityMessage: {
        success: boolean;
    };
}

export interface EditMessageVariables {
    entityId: string;
    entityType: MessageEntityType;
    text: string;
    messageId: string;
    hasParent: boolean;
}

const useEditMessage = (): UseMutationResult<EditMessageResult, ClientError, EditMessageVariables> => {
    const { enqueueSnackbar } = useSnackbar();
    const { client, withMutationOptions } = useGqlClient(API_COMMUNICATION);

    const editMessageOptions: UseQueryOptions<EditMessageResult, ClientError> = useMemo(() => {
        return {
            onError: () => {
                enqueueSnackbar('An error occurred. Unable to edit message', { variant: 'error' });
            }
        };
    }, [enqueueSnackbar]);

    return useMutation<EditMessageResult, ClientError, EditMessageVariables>(
        makeEditMessage(client),
        withMutationOptions(editMessageOptions)
    );
};

export default useEditMessage;
