import React from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { Skeleton } from '@material-ui/lab';
import { mdiChevronRight } from '@mdi/js';
import Icon from '@mdi/react';

import BreadCrumbAvatar from './BreadCrumbAvatar';
import {
    StyledBreadcrumbs,
    StyledLink,
    StyledRouterLink,
    StyledText,
    StyledTypography,
    ChevronIcon
} from './BreadCrumbTrail.styles';

export interface BreadCrumbData {
    iconPath?: string | null;
    title: string | null;
    to?: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    loading?: boolean;
    trailingIconPath?: string;
    avatarData?: { assetId: string; avatarAlias?: string };
}

interface BreadCrumbProps {
    breadCrumbData: BreadCrumbData[];
}

const BreadCrumbTrail: React.FC<BreadCrumbProps> = ({ breadCrumbData }) => {
    return (
        <StyledBreadcrumbs
            aria-label="breadcrumb"
            separator={<ChevronIcon size={IconSize.Small} path={mdiChevronRight} />}
        >
            {breadCrumbData.map((crumb, index) => {
                if (crumb?.loading) {
                    return (
                        <StyledTypography key={`${crumb.title}-${index}`}>
                            {crumb.iconPath && <Icon path={crumb.iconPath} />}
                            <Skeleton height={30} width={200} />
                        </StyledTypography>
                    );
                }

                if (crumb?.avatarData) {
                    return <BreadCrumbAvatar key={`${crumb.title}-${index}`} avatarData={crumb.avatarData} />;
                }

                if (crumb?.to) {
                    return (
                        <StyledRouterLink
                            key={`${crumb.title}-${index}`}
                            to={crumb.to}
                            data-testid={`crumb-link-to-${crumb.title}`}
                        >
                            {crumb.iconPath && <Icon path={crumb.iconPath} />}
                            <StyledText>{crumb.title}</StyledText>
                            {crumb.trailingIconPath ? <Icon path={crumb.trailingIconPath} /> : null}
                        </StyledRouterLink>
                    );
                }

                if (crumb?.onClick) {
                    return (
                        <StyledLink key={`${crumb.title}-${index}`} onClick={crumb.onClick}>
                            {crumb.iconPath && <Icon path={crumb.iconPath} />}
                            <StyledText data-testid={`${crumb.title}`}>{crumb.title}</StyledText>
                            {crumb.trailingIconPath ? <Icon path={crumb.trailingIconPath} /> : null}
                        </StyledLink>
                    );
                }

                return (
                    <StyledTypography key={`${crumb.title}-${index}`} data-testid={`crumb-current-location`}>
                        {crumb.iconPath && <Icon path={crumb.iconPath} />}
                        <StyledText>{crumb.title}</StyledText>
                    </StyledTypography>
                );
            })}
        </StyledBreadcrumbs>
    );
};

export default BreadCrumbTrail;
