import React, { useContext, useEffect, useState, useMemo } from 'react';

import { IconButtonContainer, TooltippedIconButton } from '@adept-at/lib-react-components';
import { makeStyles, Tooltip } from '@material-ui/core';
import {
    mdiFormatHeader1,
    mdiFormatBold,
    mdiFormatStrikethrough,
    mdiCodeTags,
    mdiFormatItalic,
    mdiFormatListBulleted,
    mdiFormatListNumbered,
    mdiFormatUnderline,
    mdiLink,
    mdiFormatQuoteClose
} from '@mdi/js';
import { Icon } from '@mdi/react';
import { useModal } from 'hooks/useModal';
import styled from 'styled-components';

import { RichTextEditorContext } from './Context';
import { LinkModal } from './LinkModal';
import { DraftJsBlock, DraftJsInlineStyle } from './utils';

const getSelectionRect = (selection): DOMRect | null => {
    const _rect = selection.getRangeAt(0).getBoundingClientRect();
    let rect = _rect && _rect.top ? _rect : selection.getRangeAt(0).getClientRects()[0];

    if (!rect) {
        if (selection.anchorNode && selection.anchorNode.getBoundingClientRect) {
            rect = selection.anchorNode.getBoundingClientRect();
            rect.isEmptyline = true;
        } else {
            return null;
        }
    }

    return rect;
};

const getSelection = (root): Selection | null => {
    let selection: Selection | null = null;

    if (root.getSelection) {
        selection = root.getSelection();
    } else if (root.document.getSelection) {
        selection = root.document.getSelection();
    } else if (root.document.selection) {
        selection = root.document.selection.createRange().text;
    }

    return selection;
};

const TooltipContainer = styled.div`
    display: flex;
`;

const StyledIconButtonContainer = styled(IconButtonContainer)<{ $active: boolean }>`
    background-color: transparent !important;

    svg {
        color: ${(props) => (props.$active ? props.theme.colors.primary : props.theme.colors.white)};
    }
`;

const options = [
    {
        type: 'block',
        id: DraftJsBlock.H1,
        icon: mdiFormatHeader1,
        title: 'Section'
    },
    {
        type: 'inline',
        id: DraftJsInlineStyle.BOLD,
        icon: mdiFormatBold,
        title: 'Bold'
    },
    {
        type: 'inline',
        id: DraftJsInlineStyle.ITALIC,
        icon: mdiFormatItalic,
        title: 'Italic'
    },
    {
        type: 'inline',
        id: DraftJsInlineStyle.UNDERLINE,
        icon: mdiFormatUnderline,
        title: 'Underline'
    },
    {
        type: 'inline',
        id: DraftJsInlineStyle.STRIKETHROUGH,
        icon: mdiFormatStrikethrough,
        title: 'Strikethrough'
    },
    {
        type: 'block',
        id: DraftJsBlock.UL,
        icon: mdiFormatListBulleted,
        title: 'Bulleted list'
    },
    {
        type: 'block',
        id: DraftJsBlock.OL,
        icon: mdiFormatListNumbered,
        title: 'Numbered list'
    },
    {
        type: 'block',
        id: DraftJsBlock.BLOCKQUOTE,
        icon: mdiFormatQuoteClose,
        title: 'Blockquote'
    },
    {
        type: 'inline',
        id: DraftJsInlineStyle.CODE,
        icon: mdiCodeTags,
        title: 'Code'
    }
];

const useTooltipStyles = makeStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.background.secondary,
        maxWidth: 'none'
    },
    arrow: {
        fontSize: '1rem',
        '&::before': {
            backgroundColor: theme.palette.background.secondary
        }
    }
}));

export const InlineToolbar: React.FC = () => {
    const { editorState, editorRef, toggleBlockType, toggleInlineStyle, setSelectedEntityKey, selectedText } =
        useContext(RichTextEditorContext);
    const [top, setTop] = useState<string>();
    const [left, setLeft] = useState<string>();
    const [isOpen, setIsOpen] = useState(false);
    const classes = useTooltipStyles();
    const {
        modalOpen: linkModalOpen,
        handleModalOpen: handleLinkModalOpen,
        handleModalClose: handleLinkModalClose
    } = useModal();

    useEffect(() => {
        setSelectedEntityKey(undefined);
        const selectionState = editorState.getSelection();

        if (selectionState.isCollapsed()) {
            setIsOpen(false);
            return;
        }

        const nativeSelection = getSelection(window);
        if (nativeSelection?.rangeCount && nativeSelection?.type === 'Range') {
            const selectionBoundary = getSelectionRect(nativeSelection);
            if (selectionBoundary && editorRef.current) {
                const parentBoundary = editorRef.current.getBoundingClientRect();
                setTop(`${selectionBoundary.top - parentBoundary.top}px`);

                const selectionCenter = selectionBoundary.left + selectionBoundary.width / 2 - parentBoundary.left;
                let left = selectionCenter;
                const screenLeft = parentBoundary.left + left;

                if (screenLeft < 0) {
                    // If the toolbar would be off-screen move it as far left as it can without going off-screen
                    left = -parentBoundary.left;
                }

                setLeft(`${left}px`);
                setIsOpen(true);
                return;
            }
        }

        setIsOpen(false);
    }, [editorState, editorRef, setSelectedEntityKey]);

    const currentInlineStyle = useMemo(() => editorState.getCurrentInlineStyle(), [editorState]);
    const currentBlockStyle = useMemo(() => {
        const selection = editorState.getSelection();
        return editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();
    }, [editorState]);

    return (
        <>
            <Tooltip
                arrow
                placement="top"
                interactive
                open={isOpen}
                classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
                title={
                    <TooltipContainer>
                        {options.map((option) => (
                            <TooltippedIconButton
                                key={option.id}
                                onClick={() =>
                                    option.type === 'block' ? toggleBlockType(option.id) : toggleInlineStyle(option.id)
                                }
                                onMouseDown={(e) => e.preventDefault()}
                                styledComponentOverrideProps={{
                                    $active:
                                        option.type === 'block'
                                            ? currentBlockStyle === option.id
                                            : currentInlineStyle.has(option.id)
                                }}
                                styledComponentOverride={StyledIconButtonContainer}
                                aria-label={option.title}
                                title={option.title}
                                icon={<Icon path={option.icon} />}
                            />
                        ))}
                        {!selectedText.multiLine ? (
                            <TooltippedIconButton
                                onClick={handleLinkModalOpen}
                                onMouseDown={(e) => e.preventDefault()}
                                aria-label="Link"
                                title="Link"
                                icon={<Icon path={mdiLink} />}
                                styledComponentOverride={StyledIconButtonContainer}
                            />
                        ) : null}
                    </TooltipContainer>
                }
            >
                <span style={{ top, left, position: 'absolute' }} />
            </Tooltip>
            <LinkModal open={linkModalOpen} onClose={handleLinkModalClose} />
        </>
    );
};
