import React, { useState } from 'react';

import { CodeComponentValues, CodeViewer } from '@adept-at/lib-react-components';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

export interface CodeComponentProps {
    componentValues: CodeComponentValues;
}

const CodeView: React.FC<CodeComponentValues> = (componentValues) => {
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

    return (
        <>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => {
                    setSnackbarOpen(false);
                }}
                anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'top'
                }}
            >
                <Alert severity="success">Code copied to clipboard!</Alert>
            </Snackbar>
            <CodeViewer componentValues={componentValues} onCopied={() => setSnackbarOpen(true)} />
        </>
    );
};

export default CodeView;
