import React from 'react';

import { VideoComponentValues } from '@adept-at/lib-react-components';
import { EncodeStatus } from 'components/engine/Components/Video/types';

import { EditButtons, useEditableComponent } from '../../mixins/editor';

import { useEncodeStatus } from './hooks/useEncodeStatus';
import { Manage } from './Manage';
import { TypeSelector } from './TypeSelector';

const VideoEdit: React.FC<{ onCancel?: () => void; onSave?: (values: Partial<VideoComponentValues>) => void }> = ({
    onCancel,
    onSave
}) => {
    const {
        currentValues: { videoId = null },
        onCancel: defaultOnCancel
    } = useEditableComponent<VideoComponentValues>();

    const { encodeStatus } = useEncodeStatus({ videoId });

    if (
        !videoId ||
        (videoId && [EncodeStatus.Unknown, EncodeStatus.None, EncodeStatus.NotStarted].includes(encodeStatus))
    ) {
        return (
            <>
                <TypeSelector />
                <EditButtons onCancel={onCancel ?? defaultOnCancel} />
            </>
        );
    }

    return <Manage onCancel={onCancel} onSave={onSave} />;
};

export default VideoEdit;
