import { gql, ClientError } from 'graphql-request';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useBuildAuthenticatedQueryFn } from 'hooks/useGqlClient/useBuildAuthenticatedQueryFn';
import { Api } from 'lib/ApiConstants';
import { useQuery, UseQueryResult } from 'react-query';
import { isTrue } from 'utils/isTrue';

import { progressWithCompletedAtFragment, skillFragment, virtualLabFragment, collectionFragment } from '../fragments';
import {
    BasicSkillInfoWithProgress,
    BasicLabInfo,
    BasicCollectionInfo,
    BasicCollectionInfoWithProgress
} from '../interfaces';

export interface GetFavoritesResponse {
    getUserFavorites: Favorites;
}

interface Favorites {
    items: FavoriteItem[];
}

export type FavoriteItem =
    | BasicSkillInfoWithProgress
    | BasicLabInfo
    | BasicCollectionInfo
    | BasicCollectionInfoWithProgress;

// @GRAPHQL Query getUserFavorites
export const queryName = 'getUserFavorites';

const GET_USER_FAVORITES_QUERY = gql`
    query ${queryName} {
        ${queryName} {
            items {
                ... on Skill {
                    ${skillFragment}
                    ${progressWithCompletedAtFragment}
                }
                ... on Collection {
                    ${collectionFragment}
                    ${progressWithCompletedAtFragment}
                }
                ... on VirtualLab {
                    ${virtualLabFragment}
                }
            }
        }
    }
`;

export const useGetFavorites = (): UseQueryResult<GetFavoritesResponse, ClientError> => {
    const { currentUser } = useCurrentUser();

    const { queryFn } = useBuildAuthenticatedQueryFn<GetFavoritesResponse>(
        GET_USER_FAVORITES_QUERY,
        {},
        { api: Api.Content }
    );

    return useQuery<GetFavoritesResponse, ClientError>(queryName, { queryFn, enabled: isTrue(currentUser?.userId) });
};
