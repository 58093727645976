import React, { useMemo, useContext, useCallback } from 'react';

import { Tooltip } from '@material-ui/core';
import { AuthContext } from 'context/AuthContext';
import { ProfileOption, ProfileType, ProfileContext } from 'context/ProfileContext';
import { AvailableProfilesUpdateAction } from 'context/ProfileContext/useAvailableProfiles';
import useReorderMutation from 'context/ProfileContext/useAvailableProfiles/updateAffiliationsforUser';
import { ProfileSelection } from 'context/ProfileContext/useCurrentProfile';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { NavTabItem, NavTabWrapper, ActiveTabWrapper } from './Nav.styles';
import OrgImage from './OrgImage';

interface OrgSelectorDisplayProps {
    onSelect: (selection: ProfileSelection) => void;
    selected: ProfileOption | null;
}

export const OrgSelectorDisplay: React.FC<OrgSelectorDisplayProps> = ({ selected, onSelect }) => {
    useContext(AuthContext);
    const { availableProfiles, setAvailableProfiles } = useContext(ProfileContext);
    const organizationProfiles = useMemo(
        () => availableProfiles.filter((i) => i.type === ProfileType.Organization),
        [availableProfiles]
    );

    const isActive = useCallback((item: ProfileOption) => selected !== null && item.id === selected?.id, [selected]);

    const { mutate: reorderAffiliations } = useReorderMutation({
        affiliations: organizationProfiles.map((e) => e.id)
    });

    const doneDragging = (result) => {
        /* Drag was not Dropped in a valid location. */
        if (!result.destination) {
            return;
        }

        /* Update React view with new Order */
        const reordered: ProfileOption[] = reorder(organizationProfiles, result.source.index, result.destination.index);
        setAvailableProfiles({
            options: reordered,
            action: AvailableProfilesUpdateAction.Reorder
        });

        /* Shoot new order to Accounts for update in backend. */
        const reorderedForServer = reordered.map((e) => e.id);
        reorderAffiliations({ affiliations: reorderedForServer });
    };

    /* Rearrange our list */
    const reorder = (list: ProfileOption[], startIndex: number, endIndex: number): ProfileOption[] => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    return (
        <DragDropContext onDragEnd={doneDragging}>
            <Droppable droppableId="nav-orgs-droppable">
                {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                        {organizationProfiles.map((item, idx) => (
                            <Draggable key={item.id} draggableId={item.id} index={idx}>
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        <Tooltip title={item.label} placement="right" className="organizations">
                                            <NavTabWrapper
                                                onClick={() =>
                                                    onSelect({ type: item.type, id: item.id, alias: item.alias })
                                                }
                                                isActive={isActive(item)}
                                            >
                                                <ActiveTabWrapper isActive={isActive(item)} />
                                                <NavTabItem>
                                                    <OrgImage label={item.label} imageId={item.imageId} />
                                                </NavTabItem>
                                            </NavTabWrapper>
                                        </Tooltip>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};
