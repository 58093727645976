import React, { useMemo, useEffect } from 'react';

import { useSnackbar } from 'notistack';

interface Props {
    error: any | undefined;
}

const AnswerQuestionError: React.FC<Props> = ({ error }) => {
    const { enqueueSnackbar } = useSnackbar();
    const hasError = useMemo(() => error !== null && error !== undefined, error);

    useEffect(() => {
        if (hasError) {
            const errorMessage = error?.response?.errors[0]?.message;

            enqueueSnackbar(errorMessage, { variant: 'error' });
        }
    }, [hasError]);

    return null;
};

export default AnswerQuestionError;
