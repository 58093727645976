import { useMemo } from 'react';

import { OptimistMutationContext, OptimistRegisterUpdaterOptions, useOptimist } from '@adept-at/lib-react-optimist';
import { queryName as getCollectionsForTenantProfileQueryKey } from 'components/modals/Skills/useGetCollectionsForTenantProfile';
import { ClientError } from 'graphql-request';
import { useBuildAuthenticatedMutationFn } from 'hooks/useGqlClient/useBuildAuthenticatedMutationFn';
import { Api } from 'lib/ApiConstants';
import { useSnackbar } from 'notistack';
import { QueryKey, useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { setCollectionEditedInSessionStorage } from 'utils/collection';

import { GetEditableCollectionResponse } from '../useGetEditableCollection';
import { GetCollectionBasicDetailsResponse } from '../useGetNestedCollections/getCollectionBasicDetails';

import { CREATE_NEW_COLLECTION, MakeCollectionInput, MakeCollectionResult } from './makeNewCollection';

interface UseCreateNewCollectionInput {
    basicDetailsQueryKey: QueryKey;
    editableCollectionQueryKey: QueryKey;
    parentQuerySlug: string;
}

const useCreateNewCollection = ({
    basicDetailsQueryKey,
    parentQuerySlug,
    editableCollectionQueryKey
}: UseCreateNewCollectionInput): UseMutationResult<
    MakeCollectionResult,
    ClientError,
    MakeCollectionInput,
    OptimistMutationContext
> => {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const { registerUpdater: registerMakeNewCollection, mutationOptions: makeNewCollectionMutationOptions } =
        useOptimist<MakeCollectionResult, ClientError, MakeCollectionInput>();

    const MakeNewCollectionOptions: OptimistRegisterUpdaterOptions<
        MakeCollectionResult,
        ClientError,
        MakeCollectionInput
    > = useMemo(() => {
        return {
            onError: () => {
                enqueueSnackbar('An error occurred while creating collection.', { variant: 'error' });
            },
            onSuccess: (data, request) => {
                enqueueSnackbar('New collection created.', { variant: 'success' });

                setCollectionEditedInSessionStorage(request.input.parentCollectionId, parentQuerySlug);

                queryClient.setQueryData<GetCollectionBasicDetailsResponse>(basicDetailsQueryKey, (previous) =>
                    !previous
                        ? {
                              getCollectionBasicDetails: [
                                  {
                                      title: data.createCollection.newCollection.title,
                                      collectionId: data.createCollection.newCollection.collectionId,
                                      images: null,
                                      collectionSlug: data.createCollection.newCollection.collectionSlug,
                                      stats: null,
                                      visible: false,
                                      editableCollectionId: ''
                                  }
                              ]
                          }
                        : {
                              getCollectionBasicDetails: [
                                  ...previous?.getCollectionBasicDetails.map((collectionObj) =>
                                      collectionObj.title === data.createCollection.newCollection.title
                                          ? {
                                                ...collectionObj,
                                                collectionId: data.createCollection.newCollection.collectionId,
                                                collectionSlug: data.createCollection.newCollection.collectionSlug
                                            }
                                          : collectionObj
                                  )
                              ]
                          }
                );

                queryClient.setQueryData<GetEditableCollectionResponse>(editableCollectionQueryKey, (previous) => {
                    if (!previous)
                        return {
                            getEditableCollection: {}
                        } as GetEditableCollectionResponse;

                    return {
                        getEditableCollection: {
                            ...previous?.getEditableCollection,
                            children: data.createCollection.parentCollection.children,
                            itemsVersion: data.createCollection.parentCollection.itemsVersion,
                            infoVersion: data.createCollection.parentCollection.infoVersion
                        }
                    };
                });

                queryClient.refetchQueries(getCollectionsForTenantProfileQueryKey);
            },
            refetchQuery: false
        };
    }, [enqueueSnackbar, basicDetailsQueryKey, queryClient, editableCollectionQueryKey, parentQuerySlug]);

    registerMakeNewCollection<GetCollectionBasicDetailsResponse>(
        basicDetailsQueryKey,
        (previous, newCollection) => {
            const previousNestedCollections = previous?.getCollectionBasicDetails ?? [];

            const updatedNestedCollections = [
                ...previousNestedCollections,
                {
                    title: newCollection.input.title,
                    collectionId: '',
                    images: null,
                    collectionSlug: null,
                    stats: null,
                    visible: false,
                    editableCollectionId: ''
                }
            ];

            return {
                getCollectionBasicDetails: updatedNestedCollections
            };
        },
        MakeNewCollectionOptions
    );

    const { mutationFn } = useBuildAuthenticatedMutationFn<MakeCollectionResult, MakeCollectionInput>(
        CREATE_NEW_COLLECTION,
        { api: Api.Content }
    );

    return useMutation<MakeCollectionResult, ClientError, MakeCollectionInput, OptimistMutationContext>(mutationFn, {
        ...makeNewCollectionMutationOptions
    });
};

export default useCreateNewCollection;
