import * as React from 'react';

import styled from 'styled-components';

const CostTitle = styled.div`
    font-size: 12px;
    font-weight: 500;
    align-items: center;
    color: ${({ theme }) => theme.colors.textTertiary};
    display: flex;
`;

const Cost = styled.div`
    font-size: 1.5rem;
    font-weight: 500;
    align-items: center;
    color: ${({ theme }) => theme.colors.success};
    display: flex;
`;

const CostContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
`;

export const YourCost: React.FC<{
    pricePerIncrement: number;
    enrollmentIncrement: number | null;
}> = ({ pricePerIncrement, enrollmentIncrement }) => {
    if (enrollmentIncrement === null)
        return (
            <CostContainer>
                <CostTitle>{'SET YOUR PACE ABOVE TO SEE YOUR COST'}</CostTitle>
            </CostContainer>
        );

    return (
        <CostContainer>
            <CostTitle>{'YOUR COST'}</CostTitle>
            <Cost>{`$${(enrollmentIncrement * pricePerIncrement).toFixed(2)}`}</Cost>
        </CostContainer>
    );
};
