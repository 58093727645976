import styled from 'styled-components';

export const EmbeddedComponentWrapper = styled.div<{ $border?: boolean; $isLink?: boolean }>`
    border: ${(props) => `1px solid ${props.$border ? props.theme.colors.primary : 'transparent'}`};
    border-radius: 0.25rem;

    padding: ${(props) => (props.$isLink ? '0' : '0.5rem')};
    display: ${(props) => (props.$isLink ? 'inline-block' : 'block')};

    &:hover {
        cursor: pointer;
    }

    > * {
        pointer-events: none;
    }

    .video-js {
        pointer-events: all;
    }
`;
