import React from 'react';

import loadable from '@loadable/component';
import ProtectedRoute from 'components/router/ProtectedRoute';
import { RouterFallback } from 'components/router/RouterFallback';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { AuthLayout } from 'layouts/AuthLayout';
import { MainLayout } from 'layouts/MainLayout';
import { ignoreFeatureFlags } from 'lib/constants';
import { EditableSkill } from 'pages/learn/EditableSkill';
import { Switch, Route } from 'react-router-dom';

import { Favorites } from './Favorites';
import { LabLink } from './link/LabLink';
import MyProfilePage from './MyProfile';
import NestedCollectionPage from './NestedCollection';
import NestedCollectionSkillPage from './NestedCollectionSkill';
import NotFoundErrorPage from './NotFoundError';
import OrgHome from './OrgHome';

// Routers
const ReportsRouter = loadable(() => import(/* webpackChunkName: "Reports" */ './reports/index.router'), {
    fallback: <RouterFallback />
});

// Pages
const AccountTenants = loadable(() => import(/* webpackChunkName: "AccountTenants" */ './account/Tenants'), {
    fallback: <RouterFallback />
});
const AccountTenantSetup = loadable(
    () => import(/* webpackChunkName: "AccountTenantSetup" */ './account/TenantSetup'),
    {
        fallback: <RouterFallback />
    }
);
const VerifyEmail = loadable(() => import(/* webpackChunkName: "VerifyEmail" */ './account/VerifyEmail'), {
    fallback: <RouterFallback />
});
const Groups = loadable(() => import(/* webpackChunkName: "Groups" */ './admin/Groups'), {
    fallback: <RouterFallback />
});
const JobRoles = loadable(() => import(/* webpackChunkName: "JobRoles" */ './admin/JobRoles'), {
    fallback: <RouterFallback />
});
const Licenses = loadable(() => import(/* webpackChunkName: "Licenses" */ './admin/Licenses'), {
    fallback: <RouterFallback />
});
const People = loadable(() => import(/* webpackChunkName: "People" */ './admin/People'), {
    fallback: <RouterFallback />
});
const Authorize = loadable(() => import(/* webpackChunkName: "Authorize" */ './auth/Authorize'), {
    fallback: <RouterFallback />
});
const Bookshelves = loadable(() => import(/* webpackChunkName: "Bookshelves" */ './bookshelves/Bookshelves'), {
    fallback: <RouterFallback />
});
const BookshelvesBookshelf = loadable(
    () => import(/* webpackChunkName: "Bookshelf" */ './bookshelves/BookshelvesBookshelf'),
    {
        fallback: <RouterFallback />
    }
);
const ComingSoon = loadable(() => import(/* webpackChunkName: "ComingSoon" */ './ComingSoon'), {
    fallback: <RouterFallback />
});
const CreateOrganization = loadable(() => import(/* webpackChunkName: "CreateOrganization" */ './CreateOrganization'), {
    fallback: <RouterFallback />
});
const Dashboard = loadable(() => import(/* webpackChunkName: "Dashboard" */ './Dashboard'), {
    fallback: <RouterFallback />
});
const ExamBuilder = loadable(() => import(/* webpackChunkName: "ExamBuilder" */ './ExamBuilder'), {
    fallback: <RouterFallback />
});
const ExamBuilderListPage = loadable(() => import(/* webpackChunkName: "ExamBuilderListPage" */ './ExamBuilderList'), {
    fallback: <RouterFallback />
});
const ExamBuilderQuestion = loadable(
    () => import(/* webpackChunkName: "ExamBuilderQuestion" */ './ExamBuilderQuestion'),
    {
        fallback: <RouterFallback />
    }
);
const ForgotPassword = loadable(() => import(/* webpackChunkName: "ForgotPassword" */ './auth/ForgotPassword'), {
    fallback: <RouterFallback />
});

const HomePage = loadable(() => import(/* webpackChunkName: "HomePage" */ './Home'), {
    fallback: <RouterFallback layout={MainLayout} />
});
const JoinAdeptPage = loadable(() => import(/* webpackChunkName: "JoinAdeptPage" */ './auth/JoinAdept'), {
    fallback: <RouterFallback />
});

const LoginPage = loadable(() => import(/* webpackChunkName: "LoginPage" */ './auth/Login'), {
    fallback: <RouterFallback layout={AuthLayout} />
});
const LogoutPage = loadable(() => import(/* webpackChunkName: "LogoutPage" */ './auth/Logout'), {
    fallback: <RouterFallback layout={MainLayout} />
});

const OAuthSuccessPage = loadable(() => import(/* webpackChunkName: "LogoutPage" */ './OAuthSuccess'), {
    fallback: <RouterFallback />
});

const MyCreations = loadable(() => import(/* webpackChunkName: "MyCreations" */ './MyCreations'), {
    fallback: <RouterFallback />
});

const PracticeExam = loadable(() => import(/* webpackChunkName: "PracticeExam" */ './PracticeExam'), {
    fallback: <RouterFallback />
});
const PracticeExamSession = loadable(
    () => import(/* webpackChunkName: "PracticeExamSession" */ './PracticeExamSession'),
    {
        fallback: <RouterFallback />
    }
);
const ResetPassword = loadable(() => import(/* webpackChunkName: "ResetPassword" */ './auth/ResetPassword'), {
    fallback: <RouterFallback />
});
const AppKeySettings = loadable(() => import(/* webpackChunkName: "AppKeySettings" */ './settings/AppKeySettings'), {
    fallback: <RouterFallback />
});

const OrgContentSettings = loadable(
    () => import(/* webpackChunkName: "OrgContentSettings" */ './settings/organization/ContentSettings'),
    {
        fallback: <RouterFallback />
    }
);
const OrganizationSettings = loadable(
    () => import(/* webpackChunkName: "OrganizationSettings" */ './settings/organization/OrganizationSettings'),
    {
        fallback: <RouterFallback />
    }
);
const AccountSettings = loadable(
    () => import(/* webpackChunkName: "AccountSettings" */ './settings/personal/AccountSettings'),
    {
        fallback: <RouterFallback />
    }
);
const PersonalContentSettings = loadable(
    () => import(/* webpackChunkName: "PersonalContentSettings" */ './settings/personal/ContentSettings'),
    {
        fallback: <RouterFallback />
    }
);
const SignupPage = loadable(() => import(/* webpackChunkName: "SignupPage" */ './auth/Signup'), {
    fallback: <RouterFallback layout={AuthLayout} />
});
const CaptionsEdit = loadable(() => import(/* webpackChunkName: "CaptionsEdit" */ './skill/CaptionsEdit'), {
    fallback: <RouterFallback />
});
const AssessmentEdit = loadable(() => import(/* webpackChunkName: "AssessmentEdit" */ './assessment/AssessmentEdit'), {
    fallback: <RouterFallback />
});
const SkillEdit = loadable(() => import(/* webpackChunkName: "SkillEdit" */ './skill/SkillEdit'), {
    fallback: <RouterFallback />
});

const Studio = loadable(() => import(/* webpackChunkName: "Studio" */ './Studio'), {
    fallback: <RouterFallback />
});

const Collaborations = loadable(() => import(/* webpackChunkName: "Collaborations" */ './Collaborations'), {
    fallback: <RouterFallback />
});

const MarketplacePage = loadable(() => import(/* webpackChunkName: "Marketplace" */ './marketplace'), {
    fallback: <RouterFallback />
});

const RootRouter = (): React.ReactElement => {
    const {
        analytics: analyticsLDFlag,
        favorites: favoritesLDFlag,
        myCreations: myCreationsLDFlag,
        marketplace: marketplaceLDFlag,
        createOrg: createOrgLDFlag,
        editSkill: editSkillLDFlag,
        orgSettings: orgSettingsLDFlag,
        store: storeLDFlag,
        subscriptions: subscriptionsLDFlag
    } = useFlags();

    return (
        <Switch>
            <Route path="/" exact={true} component={HomePage} />

            {/* Authentication */}
            <Route path="/login" component={LoginPage} />
            <Route path="/logout" component={LogoutPage} />
            <Route path="/oauth/success/:provider" component={OAuthSuccessPage} />
            <Route path="/signup" component={SignupPage} />
            <Route path="/join-adept" component={JoinAdeptPage} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/reset-password" component={ResetPassword} />
            <ProtectedRoute path="/authorize" exact component={Authorize} />

            {/* Settings */}
            <ProtectedRoute path="/verify-email" exact component={VerifyEmail} />
            <ProtectedRoute path="/settings/account" exact component={AccountSettings} />
            <ProtectedRoute path="/settings/content" exact component={PersonalContentSettings} />
            <ProtectedRoute path="/settings/app-keys" exact component={AppKeySettings} />
            <ProtectedRoute
                path="/settings/organization/info"
                exact
                component={ignoreFeatureFlags || orgSettingsLDFlag ? OrganizationSettings : NotFoundErrorPage}
            />
            <ProtectedRoute path="/settings/organization/content" exact component={OrgContentSettings} />
            <ProtectedRoute
                path="/organization/create"
                exact
                component={ignoreFeatureFlags || createOrgLDFlag ? CreateOrganization : NotFoundErrorPage}
            />

            <ProtectedRoute path="/account/tenants" exact component={AccountTenants} />
            <ProtectedRoute path="/account/monetize" exact component={AccountTenantSetup} />

            {/* Consumption */}
            <ProtectedRoute path="/learn/:tenantSlug/skill/:skillSlug" exact component={EditableSkill} />

            <ProtectedRoute
                path="/learn/:tenantSlug/:collectionSlugs+/skill/:skillSlug"
                exact
                component={EditableSkill}
            />

            {/* Builder */}
            <ProtectedRoute path="/skillbuilder/edit/captions/:videoId" exact component={CaptionsEdit} />
            <ProtectedRoute
                path="/skillbuilder/edit/:id/:editMode?"
                exact
                component={ignoreFeatureFlags || editSkillLDFlag ? SkillEdit : NotFoundErrorPage}
            />
            <ProtectedRoute path="/exambuilder" exact component={ExamBuilderListPage} />
            <ProtectedRoute path="/exambuilder/:eid" exact component={ExamBuilder} />
            <ProtectedRoute path="/exambuilder/:eid/question/:qid" exact component={ExamBuilderQuestion} />
            <ProtectedRoute path="/practice-exam/:eid" exact component={PracticeExam} />
            <ProtectedRoute path="/practice-exam/:eid/session/:sessionId" exact component={PracticeExamSession} />

            {/* Reporting */}
            <Route path="/reports" component={ReportsRouter} />

            <ProtectedRoute path="/dashboard" exact component={Dashboard} />

            {/* Bookshelves */}
            <ProtectedRoute path="/bookshelves" exact component={Bookshelves} />
            <ProtectedRoute path="/bookshelves/:bookshelfId" exact component={BookshelvesBookshelf} />

            <ProtectedRoute path="/profile/:userProfileId" exact component={ComingSoon} />
            <ProtectedRoute path="/profile/:userProfileId/collaborations" exact component={Collaborations} />

            <ProtectedRoute path="/:currentOrgSlug/catalog" exact component={OrgHome} />
            <ProtectedRoute path="/:currentOrgSlug/internal" exact component={OrgHome} />

            <ProtectedRoute
                path="/organization/:currentOrgId/studio/:entityType?/:entityId?"
                exact
                component={Studio}
            />

            <ProtectedRoute
                path="/marketplace"
                exact
                component={ignoreFeatureFlags || marketplaceLDFlag ? MarketplacePage : NotFoundErrorPage}
            />
            <ProtectedRoute
                path="/subscriptions"
                exact
                component={ignoreFeatureFlags || subscriptionsLDFlag ? ComingSoon : NotFoundErrorPage}
            />

            {/* Create Nav */}
            <ProtectedRoute
                path="/my-creations"
                exact
                component={ignoreFeatureFlags || myCreationsLDFlag ? MyCreations : NotFoundErrorPage}
            />
            <ProtectedRoute
                path="/my-creations/skill/:id"
                exact
                component={ignoreFeatureFlags || myCreationsLDFlag ? SkillEdit : NotFoundErrorPage}
            />
            <ProtectedRoute
                path="/my-creations/assessment/:id"
                exact
                component={ignoreFeatureFlags || myCreationsLDFlag ? AssessmentEdit : NotFoundErrorPage}
            />

            <ProtectedRoute path="/my-profile" exact component={MyProfilePage} />
            <ProtectedRoute path="/my-profile/:tenantSlug/:collectionSlugs+" exact component={NestedCollectionPage} />

            <ProtectedRoute
                path="/favorites"
                exact
                component={ignoreFeatureFlags || favoritesLDFlag ? Favorites : NotFoundErrorPage}
            />
            <ProtectedRoute
                path="/analytics"
                exact
                component={ignoreFeatureFlags || analyticsLDFlag ? ComingSoon : NotFoundErrorPage}
            />
            <ProtectedRoute
                path="/store"
                exact
                component={ignoreFeatureFlags || storeLDFlag ? ComingSoon : NotFoundErrorPage}
            />

            <ProtectedRoute path="/lab/link/:entityType/:entityId/:externalId" exact component={LabLink} />
            {/* Admin */}
            <ProtectedRoute path="/admin/people" exact component={People} />
            <ProtectedRoute path="/admin/groups" exact component={Groups} />
            <ProtectedRoute path="/admin/job-roles" exact component={JobRoles} />
            <ProtectedRoute path="/admin/licenses" exact component={Licenses} />

            {/* Corporate Knowledge */}
            <ProtectedRoute path="/ck/labs" exact component={ComingSoon} />
            <ProtectedRoute path="/ck/assessments" exact component={ComingSoon} />
            <ProtectedRoute path="/ck/rooms" exact component={ComingSoon} />
            <ProtectedRoute path="/ck/settings" exact component={ComingSoon} />

            {/* Market Content */}
            <ProtectedRoute path="/market/labs" exact component={ComingSoon} />
            <ProtectedRoute path="/market/assessments" exact component={ComingSoon} />
            <ProtectedRoute path="/market/rooms" exact component={ComingSoon} />
            <ProtectedRoute path="/market/settings" exact component={ComingSoon} />

            <ProtectedRoute
                path="/:tenantSlug/:collectionSlugs+/skill/:skillSlug"
                exact
                component={NestedCollectionSkillPage}
            />
            <ProtectedRoute path="/:tenantSlug/:collectionSlugs+" exact component={NestedCollectionPage} />

            <Route component={NotFoundErrorPage} />
        </Switch>
    );
};

export { RootRouter as Routes };
