import React from 'react';

import { HeadCell } from 'components/SortableTable/EnhancedTableHead';
import { FixedSizeList } from 'react-window';

import { RowItem } from './RowItem';
import { StickyListProvider } from './StickyListContext';

import { CollaborationTableItem } from '.';

interface StickyListProps<T> {
    headCellDetails: HeadCell<T>[];
    order: 'asc' | 'desc';
    orderBy: keyof T;
    handleRequestSort: (event: React.MouseEvent<unknown, MouseEvent>, property) => void;
    onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    isLoading: boolean;
    items: T[];
    multiSelect: boolean;
    selected: Set<string>;
    setSelected: React.Dispatch<React.SetStateAction<Set<string>>>;
    ActionComponent?: React.ComponentType<{ item: T }>;

    height: number;
    width: string;
    itemCount: number;
    itemSize: number;
    innerElementType: React.ForwardRefExoticComponent<React.RefAttributes<HTMLDivElement>> | null;
}

export const StickyList = <T extends CollaborationTableItem>({
    headCellDetails,
    order,
    orderBy,
    handleRequestSort,
    onSelectAllClick,
    isLoading,
    items,
    multiSelect,
    selected,
    setSelected,
    ActionComponent,
    ...rest
}: StickyListProps<T>): React.ReactElement => (
    <StickyListProvider<T>
        headCellDetails={headCellDetails}
        order={order}
        orderBy={orderBy}
        handleRequestSort={handleRequestSort}
        rowCount={items.length}
        onSelectAllClick={onSelectAllClick}
        numSelected={selected.size}
        includeEmptyHeadCell={!!ActionComponent}
        isLoading={isLoading}
    >
        <FixedSizeList
            itemData={{ items, headCellDetails, multiSelect, selected, setSelected, ActionComponent }}
            {...rest}
        >
            {RowItem}
        </FixedSizeList>
    </StickyListProvider>
);
