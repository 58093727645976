import { useReducer, useCallback } from 'react';

import { AckBatchParams, handleAckMessageBatch } from 'hooks/communication/useMessageActions/useAckMessageBatch';
import { AckMessageDetail } from 'hooks/communication/useMessageActions/useAckMessageBatch/ackMessageBatchApi';
import {
    handleMessageUpdateReducer,
    MessageUpdateReducerParams
} from 'hooks/communication/useMessageActions/useUpdateMessage';
import AdepteductMessage from 'lib/communication/message/base/AdepteductMessage';
import { MessageAckType } from 'lib/communication/message/MessageRecord';

import handleAckMessage, { HistoricalMessageAckParams } from './handleAckMessage';
import handleMessagesAppend, { HistoricalMessagesAppendParams } from './handleMessagesAppend';

export interface UseHistoricalMessagesRepositoryOutput {
    messagesByChannelId: Record<string, AdepteductMessage[]>;
    appendMessagesToChannel: (channelId: string, messages: AdepteductMessage[]) => void;
    ackMessage: (channelId: string, messageId: string, ackType: MessageAckType) => void;
    ackMessageBatch: (ackMessageDetails: AckMessageDetail[]) => void;
    updateMessage: (params: MessageUpdateReducerParams) => void;
}

/** By channelId */
export type HistoricalMessagesReducerState = Record<string, AdepteductMessage[]>;

export interface HistoricalMessagesReducerParams
    extends Partial<HistoricalMessageAckParams>,
        Partial<HistoricalMessagesAppendParams>,
        Partial<AckBatchParams>,
        Partial<MessageUpdateReducerParams> {
    action: HistoricalMessagesAction;
}

enum HistoricalMessagesAction {
    Append = 'Append',
    Ack = 'Ack',
    AckBatch = 'AckBatch',
    Update = 'Update'
}

const messagesReducer = (
    state: HistoricalMessagesReducerState,
    params: HistoricalMessagesReducerParams
): HistoricalMessagesReducerState => {
    switch (params?.action) {
        case HistoricalMessagesAction.Append:
            return handleMessagesAppend(state, params);
        case HistoricalMessagesAction.Ack:
            return handleAckMessage(state, params);
        case HistoricalMessagesAction.AckBatch:
            return handleAckMessageBatch(state, params as AckBatchParams) as HistoricalMessagesReducerState;
        case HistoricalMessagesAction.Update:
            return handleMessageUpdateReducer(
                state,
                params as MessageUpdateReducerParams
            ) as HistoricalMessagesReducerState;
        default:
            return state;
    }
};

const useHistoricalMessagesRepository = (): UseHistoricalMessagesRepositoryOutput => {
    const [messagesByChannelId, dispatchMessages] = useReducer(messagesReducer, {});

    const appendMessagesToChannel = useCallback((channelId: string, messages: AdepteductMessage[]): void => {
        dispatchMessages({ action: HistoricalMessagesAction.Append, channelId, messages });
    }, []);

    const ackMessage = useCallback((channelId: string, messageId: string, ackType: MessageAckType) => {
        dispatchMessages({ action: HistoricalMessagesAction.Ack, channelId, messageId, ackType });
    }, []);

    const ackMessageBatch = useCallback((ackMessageDetails: AckMessageDetail[]) => {
        dispatchMessages({ action: HistoricalMessagesAction.AckBatch, ackMessageDetails });
    }, []);

    const updateMessage = useCallback((params: MessageUpdateReducerParams) => {
        dispatchMessages({ action: HistoricalMessagesAction.Update, ...params });
    }, []);

    return { messagesByChannelId, appendMessagesToChannel, ackMessage, ackMessageBatch, updateMessage };
};

export default useHistoricalMessagesRepository;
