import React, { FC, ReactElement, useEffect, useState } from 'react';

import { DialogProps, DialogContent, DialogActions, Button, CircularProgress } from '@material-ui/core';
import AddPersonIcon from '@material-ui/icons/PersonAdd';
import { CollaboratorPermissionLevel } from 'components/ContentContext/Interfaces';
import { useUserSearchSelections } from 'components/UserSearchForm';
import { UserSearchOption } from 'components/UserSearchForm/types';
import { Action as UserSearchSelectionAction } from 'components/UserSearchForm/useUserSearchSelections';
import { useSetAsState } from 'use-set-as-state';

import ExistingUserList, { ExistingUsersListProps } from './ExistingUsersList';
import MessageToNewCollaborators from './MessageToNewUsers';
import { COLLABORATOR_DEFAULT_PERMISSION_LEVEL } from './permissionOptions';
import SharingFooter from './SharingFooter';

export enum PublicPermissions {
    public = 'public'
}

export interface SharingFormProps {
    permissionLevel: CollaboratorPermissionLevel | PublicPermissions;
    setPermissionLevel: (newPermission: CollaboratorPermissionLevel | PublicPermissions) => void;
    selections: Record<string, UserSearchOption>;
    updateSelections: React.Dispatch<UserSearchSelectionAction>;
    isLoading: boolean;
}

export interface AddEntityUserRoleDropInProps extends DialogProps, ExistingUsersListProps {
    /**  Required because used for a11y*/
    id: string;
    createEntityUserRolesLoading: boolean;

    deleteEntityUserRoleInvitationLoading: boolean;
    updateEntityUserRoleInvitationRoleLoading: boolean;
    onAddEntityUsers: (
        emailsOrUserIds: string[],
        permissionLevel: CollaboratorPermissionLevel,
        message?: string | undefined
    ) => void | Promise<void>;
    SharingForm: FC<SharingFormProps>;
    defaultRole?: CollaboratorPermissionLevel | PublicPermissions;
    toggleEntityPublicity: (setIsLoading: any) => Promise<void>;
}

const AddEntityUserRoleDropIn = ({
    id,
    createEntityUserRolesLoading,
    deleteEntityUserRoleInvitationLoading,
    updateEntityUserRoleInvitationRoleLoading,
    onAddEntityUsers,
    onClose,
    existingUsers,
    onExistingPermissionChange,
    onInvitationalPermissionChange,
    onExistingPermissionRemove,
    onInvitationalPermissionRemove,
    defaultRole,
    SharingForm,
    toggleEntityPublicity,
    ...otherProps
}: AddEntityUserRoleDropInProps): ReactElement => {
    const emailsOrUserIds = useSetAsState(new Set<string>());
    const [selections, updateSelections] = useUserSearchSelections();
    const [permissionLevel, setPermission] = useState<CollaboratorPermissionLevel | PublicPermissions>(
        defaultRole || COLLABORATOR_DEFAULT_PERMISSION_LEVEL
    );
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');

    if (!id) {
        throw new Error('AddEntityUserRoleDropIn: id required for a11y and page uniqueness');
    }

    const setPermissionLevel = (newPermission: CollaboratorPermissionLevel | PublicPermissions) => {
        setPermission((previousPermission) => {
            if (previousPermission === PublicPermissions.public || newPermission === PublicPermissions.public) {
                toggleEntityPublicity(setIsLoading);
            }
            return newPermission;
        });
    };

    useEffect(() => {
        if (!selections) return;
        emailsOrUserIds.clear();
        Object.keys(selections).map((selectionKey) => {
            const { id } = selections[selectionKey];
            emailsOrUserIds.add(id);
        });
    }, [selections, updateSelections]);

    useEffect(() => {
        if (!otherProps.open) {
            emailsOrUserIds.clear();
        }
    }, [otherProps.open]);

    useEffect(() => {
        if (
            deleteEntityUserRoleInvitationLoading ||
            updateEntityUserRoleInvitationRoleLoading ||
            createEntityUserRolesLoading
        ) {
            existingUsers?.forEach((collaborator) => {
                collaborator.mutationStatus = 'loading';
            });
        }
    }, [
        deleteEntityUserRoleInvitationLoading,
        updateEntityUserRoleInvitationRoleLoading,
        createEntityUserRolesLoading
    ]);

    const renderBottomContent = (): ReactElement | null => {
        if (permissionLevel === PublicPermissions.public) return null;

        if (emailsOrUserIds.size === 0) {
            return (
                <ExistingUserList
                    existingUsers={existingUsers}
                    onExistingPermissionChange={onExistingPermissionChange}
                    onInvitationalPermissionChange={onInvitationalPermissionChange}
                    onExistingPermissionRemove={onExistingPermissionRemove}
                    onInvitationalPermissionRemove={onInvitationalPermissionRemove}
                />
            );
        }
        return <MessageToNewCollaborators message={message} setMessage={setMessage} />;
    };

    const renderActions = (): ReactElement | null => {
        if (emailsOrUserIds.size === 0) {
            return <SharingFooter onFinished={onClose as any} />;
        }
        return (
            <>
                <Button aria-label="Cancel add user" color="primary" onClick={() => emailsOrUserIds.clear()}>
                    Cancel
                </Button>
                <Button
                    aria-label="Add user"
                    disabled={createEntityUserRolesLoading}
                    color="primary"
                    variant="contained"
                    onClick={() => {
                        if (permissionLevel !== PublicPermissions[permissionLevel]) {
                            onAddEntityUsers(
                                [...emailsOrUserIds],
                                permissionLevel as CollaboratorPermissionLevel,
                                message
                            );
                        }
                    }}
                    startIcon={createEntityUserRolesLoading ? <CircularProgress size={24} /> : <AddPersonIcon />}
                >
                    Add
                </Button>
            </>
        );
    };

    return (
        <div id={id}>
            <DialogContent>
                <SharingForm
                    selections={selections}
                    updateSelections={updateSelections}
                    permissionLevel={permissionLevel}
                    setPermissionLevel={setPermissionLevel}
                    isLoading={isLoading}
                />
                {renderBottomContent()}
            </DialogContent>
            <DialogActions>{renderActions()}</DialogActions>
        </div>
    );
};

export default AddEntityUserRoleDropIn;
