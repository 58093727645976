import React from 'react';

import { Button } from '@material-ui/core';
import { mdiUpload } from '@mdi/js';
import Icon from '@mdi/react';

import { DropzoneWrapper, DragText, SelectText, UploadControls, PreviewWrapper, Container } from './FileUpload.styles';
import { AssetType } from './hooks/useCreateUploadIntent';
import { useFileUpload, FileUploadError } from './hooks/useFileUpload';

interface FileUploadProps {
    onSuccess: (assetId: string | null, previewUrl?: string | null) => void;
    onError: (type: FileUploadError, message, data?) => void;
    assetType: AssetType;
    maxFileSize?: number;
    previewRenderer: (file: any) => React.ReactElement;
    onFileSelected?: (file: File) => void;
}

export const FileUpload: React.FC<FileUploadProps> = ({
    onSuccess,
    onError,
    maxFileSize,
    assetType,
    onFileSelected,
    previewRenderer
}) => {
    const { getDropzoneProps, getInputProps, file, loading, uploadProgress } = useFileUpload({
        onSuccess,
        onError,
        maxFileSize,
        assetType,
        onFileSelected
    });

    return (
        <Container>
            <DropzoneWrapper {...getDropzoneProps()} image={file} isLoading={loading} width={500}>
                {file ? (
                    <PreviewWrapper isLoading={loading}>
                        {previewRenderer({ file, loading, uploadProgress })}
                    </PreviewWrapper>
                ) : (
                    <UploadControls>
                        <Icon path={mdiUpload} />
                        <DragText>Drag your file here</DragText>
                        <SelectText>or select a file</SelectText>
                        <Button color="primary" variant="contained">
                            Choose File
                        </Button>
                    </UploadControls>
                )}
            </DropzoneWrapper>
            <input {...getInputProps()} />
        </Container>
    );
};
