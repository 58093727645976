import React, { useMemo } from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { Chip } from '@material-ui/core';
import { mdiPencil } from '@mdi/js';
import Icon from '@mdi/react';
import { EntityType } from 'components/ContentContext/Enums';
import { OwnerInfo } from 'components/ContentContext/Interfaces';
import { useGetTenantProfileBySlug } from 'components/learn/useGetTenantBySlug';
import { AvailabilityModal as CollectionAvailabilityModal } from 'components/NestedCollection/availability/AvailabilityModal';
import { LoadingProductDetails } from 'components/product/LoadingProductDetails';
import { useCreateProductSaga } from 'components/product/useCreateProductSaga';
import { useUpdateCollectionVisibility } from 'components/RootProfileCollection/useUpdateCollectionVisibility';
import { AvailabilityModal as SkillAvailabilityModal } from 'components/skills/AvailabilityModal';
import { useModal } from 'hooks/useModal';
import styled from 'styled-components';
import { isTrue } from 'utils/isTrue';

import { AncestorCollection, AvailabilityStatus, CollaborativeEntity } from './utils';

export interface AvailabilityChipProps {
    id: string;
    type?: EntityType;
    owner?: OwnerInfo;
    title: string;
    ancestorCollections?: AncestorCollection[];
    free?: boolean;
    visible?: boolean;
    isInternal: boolean;
    loginRequired?: boolean;
    availability?: AvailabilityStatus;
    enrollmentDetails?: CollaborativeEntity['enrollmentDetails'];
}

enum EntityAvailabilityPrefix {
    CATALOG = 'Catalog | '
}

const DeleteIcon = styled(Icon)`
    color: ${(props) => props.theme.colors.textSecondary};
`;

export const AvailabilityChip: React.FC<AvailabilityChipProps> = (entity) => {
    const {
        modalOpen: skillModalOpen,
        handleModalOpen: handleSkillModalOpen,
        handleModalClose: handleSkillModalClose
    } = useModal();
    const {
        modalOpen: collectionModalOpen,
        handleModalOpen: handleCollectionModalOpen,
        handleModalClose: handleCollectionModalClose
    } = useModal();

    const { mutate: updateCollectionVisibility } = useUpdateCollectionVisibility(entity.id, entity.owner);
    const { productInProgress, createProduct } = useCreateProductSaga({
        ownerInfo: entity.owner ?? null,
        immutableCollectionId: entity.enrollmentDetails?.collectionId,
        productName: entity.enrollmentDetails?.productName ?? '',
        productId: entity.enrollmentDetails?.productDetails?.productId ?? null,
        entitySlug: null
    });

    const { data: tenantDetails, isLoading } = useGetTenantProfileBySlug(
        { slug: entity.enrollmentDetails?.tenantSlug ?? '' },
        //only enable for catalog collections with the collection modal open and no existing price.
        //To prevent overfetching on studio page for no reason
        // open to other solutions to get the tenant id for creating products
        isTrue(entity.enrollmentDetails?.tenantSlug) &&
            !isTrue(entity.enrollmentDetails?.productDetails?.productId) &&
            !entity.isInternal &&
            collectionModalOpen
    );
    const handleClick = (e) => {
        e.stopPropagation();
        if (entity.type === EntityType.SKILL) {
            handleSkillModalOpen();
        }
        if (entity.type === EntityType.COLLECTION) {
            handleCollectionModalOpen();
        }
    };

    const onConfirmCollectionAvailability = ({ visible, enrollment, price }) => {
        if (visible !== entity.visible) {
            updateCollectionVisibility({ collectionId: entity.id, visible });
        }
        if (enrollment && price && tenantDetails?.getTenantProfileBySlug) {
            createProduct(price, tenantDetails.getTenantProfileBySlug.tenantId);
        }
        handleCollectionModalClose();
    };

    const entityAvailability = useMemo(() => {
        let prefixedAvailability = '';
        if (entity.type === EntityType.COLLECTION && !entity.isInternal) {
            prefixedAvailability += EntityAvailabilityPrefix.CATALOG;
        }

        return (prefixedAvailability += entity.availability ?? 'Set availability');
    }, [entity.type, entity.availability, entity.isInternal]);

    return (
        <>
            <Chip
                size="medium"
                deleteIcon={<DeleteIcon path={mdiPencil} size={IconSize.Small} />}
                disabled={productInProgress}
                onDelete={handleClick}
                onClick={handleClick}
                label={productInProgress ? <LoadingProductDetails /> : entityAvailability}
                variant="outlined"
            />
            <SkillAvailabilityModal open={skillModalOpen} onClose={handleSkillModalClose} entity={entity} />
            <CollectionAvailabilityModal
                isVisible={Boolean(entity.visible)}
                contentTitle={entity.title}
                open={collectionModalOpen}
                onClose={handleCollectionModalClose}
                onConfirm={onConfirmCollectionAvailability}
                isInternal={entity.isInternal}
                collectionDetails={entity.enrollmentDetails ?? null}
                supportingDetailsLoading={isLoading}
            />
        </>
    );
};
