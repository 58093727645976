export const API_ACCOUNTS = 'accounts';

/**
 * @deprecated
 */
export const API_CONTENT = 'content';

export const API_PAYMENT = 'payment';
export const API_COMMUNICATION = 'communication';
export const API_REPORTS = 'reports';

export enum Api {
    Accounts = 'accounts',
    Content = 'content',
    Payment = 'payment',
    Communication = 'communication',
    Reports = 'reports'
}

export const API_ENDPOINTS = {
    accounts: `${process.env.REACT_APP_ACCOUNTS_HOST}`,
    content: `${process.env.REACT_APP_CONTENT_HOST}/${Api.Content}`,
    payment: `${process.env.REACT_APP_PAYMENT_HOST}/${Api.Payment}`,
    communication: `${process.env.REACT_APP_COMMUNICATION_HOST}/${Api.Communication}`,
    reports: `${process.env.REACT_APP_REPORTS_HOST}/${Api.Reports}`
};

export type ApiEndpoints = typeof API_ENDPOINTS;

export type ApiName = keyof ApiEndpoints;
