import { GraphQLClient } from 'graphql-request';
import gql from 'graphql-tag';
import { MutationFunction } from 'react-query';

import buildGqlMutationFn from '../../../../hooks/useGqlClient/helpers/buildGqlMutationFn';

import { UpsertFavoriteItemResult, UpsertFavoriteItemVariables } from '.';

export const UPSERT_FAVORITE_ITEM = gql`
    mutation upsertFavoriteItem($item: EntityInfoInput!) {
        upsertFavoriteItem(item: $item) {
            success
        }
    }
`;

const makeUpsertFavoriteItem = (
    client: GraphQLClient
): MutationFunction<UpsertFavoriteItemResult, UpsertFavoriteItemVariables> =>
    buildGqlMutationFn(client, UPSERT_FAVORITE_ITEM);

export default makeUpsertFavoriteItem;
