import React, { useContext } from 'react';

import { EditorContext } from '../../context/EditorContext';

import Responder from './Responder';
import { StoryboardContext } from './StoryboardContext';

const How: React.FC = () => {
    const { clearEditMode } = useContext(EditorContext);
    const { how, setHow } = useContext(StoryboardContext);

    const prompts = [
        'How much time will the learner need to study this material to be successful?',
        'What would make the learner feel confident with this knowledge/ ability?',
        'The “How” statement: set the expectations for the amount of time and effort required.'
    ];

    const onFinish = () => {
        clearEditMode();
    };

    return (
        <Responder
            onChange={setHow}
            onFinish={onFinish}
            defaults={how}
            prompts={prompts}
            nextStepName="Finish"
            stepName="How"
        />
    );
};

export default How;
