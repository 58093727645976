import React, { useState, useCallback } from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { Popover, Divider } from '@material-ui/core';
import {
    mdiDotsVertical,
    mdiArrowRightThinCircleOutline,
    mdiArrowDecision,
    mdiMenuRight,
    mdiFileCog,
    mdiPencilOff,
    mdiDelete
} from '@mdi/js';
import { Icon } from '@mdi/react';
import { OwnerInfo } from 'components/ContentContext/Interfaces';
import { usePopover } from 'hooks/usePopover';
import styled from 'styled-components';
import { StyledMenu } from 'styles/Shared.styles';
import { useDebounce } from 'use-debounce';

import { QuickActionItem } from '../NestedCollection.styles';

import { ActionMenuItem } from './ActionMenuItem';

import { WorkflowActionsMenu } from '.';

export const ListItemDivider = styled(Divider)`
    margin: 0.5rem 0;
`;

interface EditCollectionMenuProps {
    owner?: OwnerInfo;
    handleToggleEditMode: () => void;
    handleOpenSEOSettings: () => void;
    handleOpenAvailabilityModal: () => void;
}

export const EditCollectionMenu: React.FC<EditCollectionMenuProps> = ({
    owner,
    handleToggleEditMode,
    handleOpenSEOSettings,
    handleOpenAvailabilityModal
}) => {
    const { open, anchorEl, handlePopoverClick, handlePopoverClose } = usePopover();
    const {
        anchorEl: subAnchorEl,
        handlePopoverClick: handleSubMenuOpen,
        handlePopoverClose: handleSubMenuClose
    } = usePopover();

    const [debounceDelay, setDebounceDelay] = useState(300);

    const [debouncedSubAnchor] = useDebounce(subAnchorEl, debounceDelay);

    const handleOpenSubMenu = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            setDebounceDelay(300);
            handleSubMenuOpen(event);
        },
        [handleSubMenuOpen]
    );

    const handleCloseSubMenu = useCallback(() => {
        setDebounceDelay(0);
        handleSubMenuClose();
    }, [handleSubMenuClose]);

    return (
        <>
            <QuickActionItem
                title="More actions"
                $isStickyMode
                icon={<Icon path={mdiDotsVertical} />}
                onClick={handlePopoverClick}
            />
            <StyledMenu
                anchorEl={anchorEl}
                open={open}
                onClose={handlePopoverClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <ActionMenuItem
                    title="Availability"
                    icon={mdiArrowRightThinCircleOutline}
                    onClick={() => {
                        handlePopoverClose();
                        handleOpenAvailabilityModal();
                    }}
                />
                {owner ? (
                    <ActionMenuItem
                        title="Workflow"
                        icon={mdiArrowDecision}
                        onMouseEnter={handleOpenSubMenu}
                        onMouseLeave={handleSubMenuClose}
                    >
                        <span>Workflow</span>
                        <Icon path={mdiMenuRight} size={IconSize.Medium} />
                        <Popover open={Boolean(debouncedSubAnchor)}>
                            <WorkflowActionsMenu
                                isSubMenu
                                owner={owner}
                                subAnchorEl={debouncedSubAnchor}
                                handleCloseSubMenu={handleCloseSubMenu}
                            />
                        </Popover>
                    </ActionMenuItem>
                ) : null}
                <ActionMenuItem
                    title="SEO settings"
                    icon={mdiFileCog}
                    onClick={() => {
                        handlePopoverClose();
                        handleOpenSEOSettings();
                    }}
                />
                <ActionMenuItem title="Stop editing" icon={mdiPencilOff} onClick={handleToggleEditMode} />
                <ListItemDivider />
                <ActionMenuItem
                    title="Delete collection"
                    icon={mdiDelete}
                    onClick={() => {
                        // @TODO open delete modal
                        handlePopoverClose();
                    }}
                />
            </StyledMenu>
        </>
    );
};
