import React from 'react';

import { AnswerOption, QuestionComponentValues, QuestionType } from '@adept-at/lib-react-components';
import {
    mdiRadioboxMarked,
    mdiCheckBoxOutline,
    mdiFormatListChecks,
    mdiFormatUnderline,
    mdiSwapHorizontal,
    mdiFormatListNumbered,
    mdiChartBoxOutline
} from '@mdi/js';
import MuiIcon from '@mdi/react';
import { EntityType } from 'components/ContentContext/Enums';
import gql from 'graphql-tag';

export const ANSWER_QUESTION = gql`
    mutation answerQuestion($answerSubmission: AnswerSubmission!) {
        answerQuestion(answerSubmission: $answerSubmission) {
            isValid
            hint
            pollResponses
        }
    }
`;

export enum QuestionContextType {
    skill = 'skill',
    questionBank = 'questionBank',
    questionBankSession = 'questionBankSession'
}

export interface QuestionContext {
    type: QuestionContextType;
    id: string;
}

export interface AnswerResponse {
    answerQuestion: {
        isValid: boolean;
        hint?: string;
        pollResponses?: {
            [id: string]: any;
        };
    };
}

export interface AnswerSkillQuestionArgs {
    orgId: string;
    containerId: string;
    containerType: EntityType;
    questionId: string;
    answer: boolean | string | string[] | Record<string, any>;
    attempt: number;
    timeout?: number;
}

export interface AnswerExamQuestionArgs {
    orgId: string;
    questionBankId: string;
    sessionId: string;
    questionId: string;
    answer: Record<string, any>;
    timeout?: number;
}

export const submitSkillQuestionAnswer = async (
    setIsSubmitting: React.Dispatch<React.SetStateAction<any>>,
    setAttempt: React.Dispatch<React.SetStateAction<any>>,
    setTimeoutProgress: React.Dispatch<React.SetStateAction<any>>,
    answerQuestion: (variables) => any,
    args: AnswerSkillQuestionArgs
) => {
    const { timeout = 0, ...variables } = args;
    if (!variables?.answer) {
        return;
    }
    setIsSubmitting(true);

    answerQuestion({
        answerSubmission: variables
    });

    const interval = setInterval(() => {
        setTimeoutProgress((previousProgress) => previousProgress + 1);
    }, 1000);

    setTimeout(() => {
        setAttempt((previousAttempt) => previousAttempt + 1);
        setIsSubmitting(false);
        setTimeoutProgress(0);
        clearInterval(interval);
    }, 1000 * timeout);
};

export const submitExamQuestionAnswer = (
    setIsSubmitting: React.Dispatch<React.SetStateAction<any>>,
    answerQuestion: (variables) => any,
    variables: AnswerExamQuestionArgs
) => {
    if (!variables?.answer) {
        return;
    }

    setIsSubmitting(true);

    answerQuestion({
        answerSubmission: variables
    });

    setIsSubmitting(false);
};

export const getRandomBytes = () => (Math.random() + 1).toString(36).substring(7);

export const initChoiceQuestions = (): AnswerOption[] => {
    const answerOptions: AnswerOption[] = [];
    for (let i = 0; i < 4; i++) {
        answerOptions.push({ key: getRandomBytes(), text: '' });
    }
    return answerOptions;
};

export const emptyQuestionComponentValues: QuestionComponentValues = {
    question: '',
    questionId: undefined,
    questionType: QuestionType.Choice,
    blankAnswers: [''],
    booleanAnswer: false,
    choiceAnswer: '',
    checkboxAnswers: [],
    answerOptions: initChoiceQuestions(),
    hasRandomOrder: true,
    matchingPairs: {},
    hints: [],
    answerDelayInSeconds: 4,
    allowMultiVotes: false
};

const cleanBlankAnswers = (values: QuestionComponentValues, setError: React.Dispatch<React.SetStateAction<any>>) => {
    const blankAnswers = values.blankAnswers.map((answer) => answer?.trim()).filter((answer) => answer);

    if (blankAnswers.length < 1) {
        setError({ 'blankEdit-blankAnswer-0': 'At least one answer is required' });
        return false;
    }

    return blankAnswers;
};

const cleanOrderAnswers = (values: QuestionComponentValues, setError: React.Dispatch<React.SetStateAction<any>>) => {
    const orderOptions = values.answerOptions
        .map(({ key, text }) => ({ key, text: text?.trim() }))
        .filter(({ text }) => text);

    if (orderOptions.length < 1) {
        setError({ 'orderEdit-text-0': 'At least one answer is required' });
        return false;
    }

    return orderOptions;
};

const cleanChoiceAnswers = (values: QuestionComponentValues, setError: React.Dispatch<React.SetStateAction<any>>) => {
    const choices = values.answerOptions
        .map(({ key, text }) => ({ key, text: text?.trim() }))
        .filter(({ text }) => text);

    if (!choices.length) {
        setError({ [`choiceEdit-text-${values.answerOptions[0].key}`]: 'At least one answer is required' });
        return false;
    }

    if (!values.choiceAnswer) {
        setError({ 'choiceEdit-radio': 'Correct choice is required' });
        return false;
    }

    if (!choices.find((option) => option.key === values.choiceAnswer)) {
        setError({ [`choiceEdit-text-${values.choiceAnswer}`]: 'The correct choice must have an answer' });
        return false;
    }

    return choices;
};

const cleanPollAnswers = (values: QuestionComponentValues, setError: React.Dispatch<React.SetStateAction<any>>) => {
    const pollOptions = values.answerOptions
        .map(({ key, text }) => ({ key, text: text?.trim() }))
        .filter(({ text }) => text);

    if (!pollOptions.length) {
        setError({ [`pollEdit-text-${values.answerOptions[0].key}`]: 'At least one option is required' });
        return false;
    }

    return pollOptions;
};

const cleanCheckboxAnswers = (values: QuestionComponentValues, setError: React.Dispatch<React.SetStateAction<any>>) => {
    const answers = values.answerOptions
        .map(({ key, text }) => ({ key, text: text?.trim() }))
        .filter(({ text }) => text);

    if (!answers.length) {
        setError({ [`checkboxEdit-text-${values.answerOptions[0].key}`]: 'At least one answer is required' });
        return false;
    }

    if (!values.checkboxAnswers.length) {
        setError({ 'checkboxEdit-checkbox': 'At least one correct choice is required' });
        return false;
    }

    const answerKeys = answers.map(({ key }) => key);
    const checkboxErrors = {};
    values.checkboxAnswers
        .filter((checkedAnswer) => !answerKeys.includes(checkedAnswer))
        .forEach((answer) => {
            checkboxErrors[`checkboxEdit-text-${answer}`] = 'Each correct choice must have an answer';
        });

    if (Object.keys(checkboxErrors).length) {
        setError(checkboxErrors);
        return false;
    }

    return answers;
};

const cleanMatchingAnswers = (values: QuestionComponentValues, setError: React.Dispatch<React.SetStateAction<any>>) => {
    const matchPairValues = Object.entries(values.matchingPairs)
        .map(([key, { text }]) => ({ key, text: text?.trim() }))
        .filter(({ text }) => text);

    const choiceOptions = values.answerOptions
        .map(({ key, text }) => ({ key, text: text?.trim() }))
        .filter(({ text }) => text);

    if (!choiceOptions.length && !matchPairValues.length) {
        setError({
            [`matchEdit-choice-${values.answerOptions[0].key}`]: 'At least one matching answer is required'
        });
        return false;
    }

    const matchPairKeys = matchPairValues.map(({ key }) => key);
    const matchChoiceKeys = choiceOptions.map(({ key }) => key);

    const choiceDoesNotHaveMatch = matchChoiceKeys.filter((choice) => !matchPairKeys.includes(choice));
    const matchDoesNotHaveChoice = matchPairKeys.filter((match) => !matchChoiceKeys.includes(match));

    const matchErrors = {};
    choiceDoesNotHaveMatch.forEach((choice) => {
        matchErrors[`matchEdit-match-${choice}`] = 'Each choice must have a match';
    });
    matchDoesNotHaveChoice.forEach((match) => {
        matchErrors[`matchEdit-choice-${match}`] = 'Each match must have a choice';
    });

    if (Object.keys(matchErrors).length) {
        setError(matchErrors);
        return false;
    }

    const cleanedMatchPairs = matchPairValues.reduce(
        (memo, matchPair) => ({ ...memo, [matchPair.key]: matchPair }),
        {}
    );

    return { answerOptions: choiceOptions, matchingPairs: cleanedMatchPairs };
};

export const cleanValues = (values: QuestionComponentValues, setError: React.Dispatch<React.SetStateAction<any>>) => {
    if (!values.question) {
        setError({ question: 'Question is required' });
        return false;
    }

    const draftValues = { ...values };

    draftValues.hints = values.hints.map((hint) => hint?.trim()).filter((hint) => hint);

    switch (values.questionType) {
        case 'blank':
            const cleanedBlankAnswers = cleanBlankAnswers(values, setError);
            if (!cleanedBlankAnswers) {
                return false;
            }

            draftValues.blankAnswers = cleanedBlankAnswers;
            break;
        case 'order':
            const cleanedOrderAnswers = cleanOrderAnswers(values, setError);
            if (!cleanedOrderAnswers) {
                return false;
            }

            draftValues.answerOptions = cleanedOrderAnswers;
            break;
        case 'choice':
            const cleanedChoiceAnswers = cleanChoiceAnswers(values, setError);
            if (!cleanedChoiceAnswers) {
                return false;
            }

            draftValues.answerOptions = cleanedChoiceAnswers;
            break;
        case 'checkbox':
            const cleanedCheckboxAnswers = cleanCheckboxAnswers(values, setError);
            if (!cleanedCheckboxAnswers) {
                return false;
            }

            draftValues.answerOptions = cleanedCheckboxAnswers;
            break;
        case 'match':
            const cleanedMatchingAnswers = cleanMatchingAnswers(values, setError);
            if (!cleanedMatchingAnswers) {
                return false;
            }

            draftValues.answerOptions = cleanedMatchingAnswers.answerOptions;
            draftValues.matchingPairs = cleanedMatchingAnswers.matchingPairs;
            break;
        case 'poll':
            const cleanedPollAnswers = cleanPollAnswers(values, setError);

            if (!cleanedPollAnswers) {
                return false;
            }

            draftValues.answerOptions = cleanedPollAnswers;
        default:
            break;
    }

    return removeExtraProperties(draftValues);
};

const removeExtraProperties = (values: QuestionComponentValues) => {
    const desiredProperties = Object.keys(emptyQuestionComponentValues);
    return Object.entries(values).reduce((memo, [key, value]) => {
        if (desiredProperties.includes(key)) {
            memo[key] = value;
        }
        return memo;
    }, {} as QuestionComponentValues);
};

export const getQuestionTypeValues = Object.values(QuestionType).map((type) => {
    switch (type) {
        case QuestionType.Choice:
            return {
                key: type,
                text: 'Single Select',
                Icon: () => <MuiIcon path={mdiRadioboxMarked} />
            };
        case QuestionType.Checkbox:
            return {
                key: type,
                text: 'Multi Select',
                Icon: () => <MuiIcon path={mdiCheckBoxOutline} />
            };
        case QuestionType.Boolean:
            return {
                key: type,
                text: 'True or False',
                Icon: () => <MuiIcon path={mdiFormatListChecks} />
            };
        case QuestionType.Blank:
            return {
                key: type,
                text: 'Fill in the Blank',
                Icon: () => <MuiIcon path={mdiFormatUnderline} />
            };
        case QuestionType.Match:
            return {
                key: type,
                text: 'Matching',
                Icon: () => <MuiIcon path={mdiSwapHorizontal} />
            };
        case QuestionType.Order:
            return {
                key: type,
                text: 'Ordered',
                Icon: () => <MuiIcon path={mdiFormatListNumbered} />
            };
        case QuestionType.Poll:
            return {
                key: type,
                text: 'Poll',
                Icon: () => <MuiIcon path={mdiChartBoxOutline} />
            };
        default:
            throw new Error('Unknown Question Type');
    }
});
