import React from 'react';

import { AccessedAtCell, ACCESSED_AT_CELL_WIDTH } from './AccessedAtCell';
import { AuthorCell } from './AuthorCell';
import { AuthorsCell, AUTHORS_CELL_WIDTH } from './AuthorsCell';
import { AvailabilityCell, AVAILABILITY_CELL_WIDTH } from './AvailabilityCell';
import { CollectionsCell, COLLECTIONS_CELL_WIDTH } from './CollectionsCell';
import { CommentsCell, COMMENTS_CELL_WIDTH } from './CommentsCell';
import { CompletedQuestionsCell, COMPLETED_QUESTIONS_CELL_WIDTH } from './CompletedQuestionsCell';
import { CreatedAtCell, CREATED_AT_CELL_WIDTH } from './CreatedAtCell';
import { EmptyCell } from './EmptyCell';
import { FavoritedCell, FAVORITED_CELL_WIDTH } from './FavoritedCell';
import { LikedCell, LIKED_CELL_WIDTH } from './LikedCell';
import { LikesCell, LIKES_CELL_WIDTH } from './LikesCell';
import { ModifiedAtCell, MODIFIED_AT_CELL_WIDTH } from './ModifiedAtCell';
import { PercentCompleteCell, PERCENT_COMPLETE_WIDTH } from './PercentCompleteCell';
import { TimeTrainedCell, TIME_TRAINED_CELL_WIDTH } from './TimeTrainedCell';
import { TitleCell, TITLE_CELL_WIDTH } from './TitleCell';
import { ViewersCell, VIEWERS_CELL_WIDTH } from './ViewersCell';
import { ViewsCell, VIEWS_CELL_WIDTH } from './ViewsCell';
import { WorkflowCell, WORKFLOW_CELL_WIDTH } from './WorkflowCell';

export const getCellComponent = (id: string): React.FC<any> => {
    switch (id) {
        case 'title':
            return TitleCell;
        case 'createdBy':
            return AuthorCell;
        case 'createdAt':
            return CreatedAtCell;
        case 'modifiedAt':
            return ModifiedAtCell;
        case 'viewers':
            return ViewersCell;
        case 'views':
            return ViewsCell;
        case 'likes':
            return LikesCell;
        case 'comments':
            return CommentsCell;
        case 'workflow':
            return WorkflowCell;
        case 'collections':
            return CollectionsCell;
        case 'availability':
            return AvailabilityCell;
        case 'authors':
            return AuthorsCell;
        case 'accessedAt':
            return AccessedAtCell;
        case 'percentComplete':
            return PercentCompleteCell;
        case 'completedQuestions':
            return CompletedQuestionsCell;
        case 'timeTrained':
            return TimeTrainedCell;
        case 'liked':
            return LikedCell;
        case 'favorited':
            return FavoritedCell;
        default:
            console.warn(`Unsupported cell id ${id}`);
            return EmptyCell;
    }
};

export const getCellWidth = (id: string): number | undefined => {
    switch (id) {
        case 'title':
            return TITLE_CELL_WIDTH;
        case 'createdAt':
            return CREATED_AT_CELL_WIDTH;
        case 'modifiedAt':
            return MODIFIED_AT_CELL_WIDTH;
        case 'viewers':
            return VIEWERS_CELL_WIDTH;
        case 'views':
            return VIEWS_CELL_WIDTH;
        case 'likes':
            return LIKES_CELL_WIDTH;
        case 'comments':
            return COMMENTS_CELL_WIDTH;
        case 'workflow':
            return WORKFLOW_CELL_WIDTH;
        case 'collections':
            return COLLECTIONS_CELL_WIDTH;
        case 'availability':
            return AVAILABILITY_CELL_WIDTH;
        case 'authors':
            return AUTHORS_CELL_WIDTH;
        case 'accessedAt':
            return ACCESSED_AT_CELL_WIDTH;
        case 'percentComplete':
            return PERCENT_COMPLETE_WIDTH;
        case 'completedQuestions':
            return COMPLETED_QUESTIONS_CELL_WIDTH;
        case 'timeTrained':
            return TIME_TRAINED_CELL_WIDTH;
        case 'liked':
            return LIKED_CELL_WIDTH;
        case 'favorited':
            return FAVORITED_CELL_WIDTH;
    }
};
