import { useContext, useMemo, useCallback, useEffect } from 'react';

import HistoricalCommunicationContext from 'context/HistoricalCommunicationContext';
import { RealTimeCommunicationContext, ReceiptAdepteductMessageWithId } from 'context/RealTimeCommunicationContext';
import AdepteductMessage from 'lib/communication/message/base/AdepteductMessage';
import { MessageEntityType } from 'lib/communication/message/MessageRecord';
import PossibleEventFields from 'lib/communication/message/type/PossibleEventFields';

import { sortMessagesBySentAtDesc } from '../../../utils/communication';
import { VALID_MESSAGE_DESTINATIONS } from '../useChannelMessages';

interface UseBulkChannelMessagesOptions {
    channelIds: string[];
}

export interface UseBulkChannelMessagesOutput {
    messages: (ReceiptAdepteductMessageWithId | AdepteductMessage<PossibleEventFields>)[];
    anyIsLoading: boolean;
    anyIsFetchingOlder: boolean;
    channelIdsWithErrors: string[];
    anyHasOlderMessages: boolean;
    fetchOlderMessagesForAll: () => void;
}

/**
 * Used to interact with several channels worth of messages at once. All statuses and interactions are bulked.
 * An example of where this would be useful is for all system channels messages, where do don't care about channelId.
 * This outputs messages by the newest FIRST. Make sure to use flex-direction: column-reverse to display correctly.
 */
const useBulkChannelMessages = ({ channelIds = [] }: UseBulkChannelMessagesOptions): UseBulkChannelMessagesOutput => {
    const { messages: realTimeMessagesByChannelId } = useContext(RealTimeCommunicationContext);

    const {
        entitiesToFetch,
        enableEntityForFetching,
        messagesByChannelId: historicalMessagesByChannelId,
        mostRecentQueryResultByChannelId,
        determineHasOlderMessagesForChannel: hasOlderMessagesForChannel,
        fetchOlderMessagesForChannel
    } = useContext(HistoricalCommunicationContext);

    useEffect(() => {
        for (const channelId of channelIds.filter(
            (channelId) => !entitiesToFetch.includes(`${MessageEntityType.Channel}#${channelId}`)
        )) {
            enableEntityForFetching(MessageEntityType.Channel, channelId);
        }
    }, [channelIds, entitiesToFetch, enableEntityForFetching]);

    const realTimeMessageIds = useMemo(
        () =>
            new Set(
                channelIds.flatMap((channelId) => {
                    const realTimeMessagesForChannel = realTimeMessagesByChannelId?.[channelId];
                    if (realTimeMessagesForChannel) {
                        return realTimeMessagesForChannel.map((message) => message.id);
                    }
                    return [];
                })
            ),
        [channelIds, realTimeMessagesByChannelId]
    );

    const combinedMessages = useMemo(() => {
        if (!channelIds || channelIds.length === 0) return [];

        return channelIds
            .flatMap((channelId) => {
                return [
                    ...(realTimeMessagesByChannelId?.[channelId] ?? []),
                    ...(historicalMessagesByChannelId?.[channelId]?.filter(
                        (historicalMessage) =>
                            !realTimeMessageIds.has(historicalMessage.id) &&
                            (historicalMessage as AdepteductMessage).destinations.some((destination) =>
                                VALID_MESSAGE_DESTINATIONS.includes(destination)
                            )
                    ) ?? [])
                ];
            })
            .sort(sortMessagesBySentAtDesc);
    }, [channelIds, historicalMessagesByChannelId, realTimeMessageIds, realTimeMessagesByChannelId]);

    const anyIsLoading = useMemo(
        () => channelIds.some((channelId) => mostRecentQueryResultByChannelId?.[channelId]?.isLoading),
        [channelIds, mostRecentQueryResultByChannelId]
    );

    const anyIsFetchingOlder = useMemo(
        () =>
            channelIds.some((channelId) => {
                const historicalMessages = historicalMessagesByChannelId?.[channelId];
                return (
                    historicalMessages &&
                    historicalMessages.length > 0 &&
                    mostRecentQueryResultByChannelId?.[channelId]?.isLoading
                );
            }),
        [channelIds, historicalMessagesByChannelId, mostRecentQueryResultByChannelId]
    );

    const channelIdsWithErrors = useMemo(
        () =>
            channelIds.filter((channelId) => {
                return mostRecentQueryResultByChannelId?.[channelId]?.isError;
            }),
        [channelIds, mostRecentQueryResultByChannelId]
    );

    /** useMemo so we dont have to fire iteration callbacks every render, only once per round of calls */
    const anyHasOlderMessages = useMemo(
        () => channelIds.some((channelId) => hasOlderMessagesForChannel(channelId)),
        [channelIds, hasOlderMessagesForChannel]
    );

    const fetchOlderMessagesForAll = useCallback(() => {
        for (const channelId of channelIds) {
            if (hasOlderMessagesForChannel(channelId)) {
                fetchOlderMessagesForChannel(channelId);
            }
        }
    }, [channelIds, fetchOlderMessagesForChannel, hasOlderMessagesForChannel]);

    return {
        messages: combinedMessages,
        anyIsLoading,
        anyIsFetchingOlder,
        channelIdsWithErrors,
        anyHasOlderMessages,
        fetchOlderMessagesForAll
    };
};

export default useBulkChannelMessages;
