import { QuestionType, RichTextComponentValues } from '@adept-at/lib-react-components';
import { Skill } from 'components/ContentContext/Interfaces';
import { DraftJsBlock } from 'components/engine/common/RichTextEditor/utils';
import { BasicDetailsSkillResponse } from 'components/NestedCollection/useGetEntitiesBasicDetails/getEntitiesBasicDetails';
import { CollectionBasicDetail } from 'components/RootProfileCollection/useGetNestedCollections/getCollectionBasicDetails';
import { ProgressState } from 'utils/progress';

export const extractComponentIdsFromTextComponent = (component: RichTextComponentValues): string[] => {
    const componentBody = component?.body?.val;

    const blocks = componentBody?.blocks ?? [];

    const componentIds: string[] = [];
    let previousBlockType = DraftJsBlock.ATOMIC;

    for (const block of blocks) {
        const blockHasText = !!block.text?.trim();

        /*
         * Add to componentIds if
         *  1. It's an atomic block (embedded component)
         *  2. It's a non-empty header-one (section title)
         *  3. The previous block was atomic or header-one and it's non-empty
         *     - This keeps us from counting contiguous text as more than one component
         */
        if (
            block.type === DraftJsBlock.ATOMIC ||
            (block.type === DraftJsBlock.H1 && blockHasText) ||
            ([DraftJsBlock.ATOMIC, DraftJsBlock.H1].includes(previousBlockType) && blockHasText)
        ) {
            componentIds.push(block.key);
            previousBlockType = block.type as DraftJsBlock;
        }
    }

    return componentIds;
};

export const mapComponentIdToQuestion = (
    component?: RichTextComponentValues
): Record<string, { questionId: string; questionType: QuestionType }> => {
    const entityMap = component?.body?.val?.entityMap ?? {};
    const blocks = component?.body?.val?.blocks ?? [];

    const entityKeyToBlockKeyMap = new Map<string, string[]>();
    for (const block of blocks) {
        for (const entityRange of block.entityRanges) {
            const existingArray = entityKeyToBlockKeyMap.get(`${entityRange.key}`) ?? [];
            entityKeyToBlockKeyMap.set(`${entityRange.key}`, [...existingArray, block.key]);
        }
    }

    const componentIdToQuestion: Record<string, { questionId: string; questionType: QuestionType }> = {};
    for (const [entityKey, entity] of Object.entries(entityMap)) {
        if (entity && entity.data && 'questionId' in entity.data) {
            const blockKeys = entityKeyToBlockKeyMap.get(entityKey) ?? [];
            for (const blockKey of blockKeys) {
                componentIdToQuestion[blockKey] = {
                    questionId: entity.data.questionId,
                    questionType: entity.data.questionType
                };
            }
        }
    }

    return componentIdToQuestion;
};

export const allPollsComplete = (component?: RichTextComponentValues): boolean => {
    const entityMap = component?.body?.val?.entityMap ?? {};

    for (const entity of Object.values(entityMap)) {
        if (entity?.data?.questionType === QuestionType.Poll && !Boolean(entity?.data?.pollResponses?.totalResponses)) {
            return false;
        }
    }

    return true;
};

export const isSkillComplete = (skill: Partial<Skill>): boolean => {
    return (skill.estimatedSecondsToConsume ?? 0) === (skill.progress?.secondsConsumed ?? 0);
};

export const isSkillInProgress = (skill: Partial<Skill>): boolean => {
    const secondsConsumed = skill.progress?.secondsConsumed ?? 0;
    return secondsConsumed > 0 && secondsConsumed < (skill.estimatedSecondsToConsume ?? 0);
};

export const isSkillNotStarted = (skill: Partial<Skill>): boolean => {
    const secondsConsumed = skill.progress?.secondsConsumed ?? 0;
    return secondsConsumed === 0;
};

export const getEntityProgressStatus = (
    entity?: BasicDetailsSkillResponse | Skill | CollectionBasicDetail
): ProgressState => {
    if (!entity) {
        return ProgressState.NOT_STARTED;
    }

    return 'estimatedSecondsToConsume' in entity ? getSkillProgressStatus(entity) : getCollectionProgressStatus(entity);
};

const getSkillProgressStatus = (skill: BasicDetailsSkillResponse | Skill): ProgressState => {
    const estimatedSecondsToConsume = skill.estimatedSecondsToConsume ?? 0;
    const secondsConsumed = skill.progress?.secondsConsumed ?? 0;

    if (secondsConsumed > 0 && secondsConsumed < estimatedSecondsToConsume) {
        return ProgressState.IN_PROGRESS;
    }

    if (secondsConsumed === estimatedSecondsToConsume) {
        return ProgressState.COMPLETE;
    }

    return ProgressState.NOT_STARTED;
};

const getCollectionProgressStatus = (collection: CollectionBasicDetail): ProgressState => {
    if (collection.progress?.collectionComplete) {
        return ProgressState.COMPLETE;
    }

    if ((collection.progress?.completedChildren ?? []).length > 0) {
        return ProgressState.IN_PROGRESS;
    }

    return ProgressState.NOT_STARTED;
};
