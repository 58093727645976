const imageTypeFragment = `
    catalog
    featuredLight
    featuredDark
    featuredLightMobile
    featuredDarkMobile
`;

const defaultCollectionImagesFragment = `
    defaultImages {
        red {
            ${imageTypeFragment}
        }
        blue {
            ${imageTypeFragment}
        }
        green {
            ${imageTypeFragment}
        }
        gray {
            ${imageTypeFragment}
        }

    }
`;

export const collectionFragment = `
    collectionId
    rootCollectionId
    createdAt
    title
    titleSub
    description
    createdBy
    createdAt
    collectionSlug
    tenantSlug
    workflow
    productDetails {
        productId
        prices {
            priceId
            pricePerUnitCents
        }
    }
    stats {
        estimatedSecondsToConsume
        descendants {
            type
            count
        }
    }
    owner {
        type
        id
    }
    children {
        type
        id
    }
    images {
        ${defaultCollectionImagesFragment}
        catalog {
            id
            crop
            isProcessed
            processed @sizes(widths: [80, 208]) {
                url
                format
                dimensions {
                    width
                }
            }
            original {
                url
                format
            }
        }
        featured {
            id
            crop
            isProcessed
            processed @sizes(widths: [2400, 1200, 768]) {
                url
                format
            }
            original {
                url
                format
            }
        }
        catalogImages {
            id
            processed @sizes(widths: [208, 80]) {
                url
                format
            }
            original {
                url
                format
            }
            tags
        }
        featuredImages {
            id
            processed @sizes(widths: [2400, 1200, 768]) {
                url
                format
            }
            original {
                url
                format
            }
            tags
        }
    }
    defCat
    defFeat
    infoVersion
    itemsVersion
    editableCollectionId
    immutableCollectionId
    editable
    visible
    tags
    authors {
        userId
        authorTypes
    }
    rootType
`;

export const collectionBasicDetailFragment = `
    collectionId
    title
    collectionSlug
    tenantSlug
    description
    titleSub
    visible
    defCat
    defFeat
    workflow
    images {
        catalog {
            id
            crop
            isProcessed
            original {
                url
            }
        }
        featured {
            id
            crop
            isProcessed
            original {
                url
            }
        }
        featuredImages {
            id
            processed {
                url
            }
        }
        catalogImages {
            id
            crop
            isProcessed
            original {
                url
            }
            tags
        }
    }
    stats {
        estimatedSecondsToConsume
        descendants {
            type
            count
        }
    }
    editableCollectionId
    rootType
`;

export const skillBasicDetailFragment = `
    skillId
    skillSlug
    title
    visible
    images {
        catalog {
            original {
                url
            }
            crop
            isProcessed
            processed @sizes(widths: [208]) {
                url
            }
        }
    }
    estimatedSecondsToConsume
`;
