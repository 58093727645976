import React from 'react';

import { ShareMenu } from '@adept-at/lib-react-components';
import { Button } from '@material-ui/core';
import { NestedCollectionActions, QuickActionItemContainer } from 'components/NestedCollection/NestedCollection.styles';
import styled from 'styled-components';

export interface CollectionActionProps {
    isStickyMode?: boolean;
}

const CollectionActions = styled(NestedCollectionActions)<{ $isStickyMode: boolean }>`
    ${(props) => (props.$isStickyMode ? null : 'margin-top: 1rem;')}

    button, svg {
        color: ${(props) => (props.$isStickyMode ? props.theme.colors.primary : props.theme.colors.secondary)};
    }
`;

const FlexContainer = styled.div`
    display: flex;
`;

export const CollectionActionWrapper = React.forwardRef<HTMLDivElement, CollectionActionProps>(
    ({ isStickyMode }, ref) => {
        return (
            <CollectionActions $isStickyMode={!!isStickyMode} ref={ref}>
                <Button variant="contained" disabled>
                    Subscribe
                </Button>
                <FlexContainer>
                    <Button disabled>Follow</Button>
                    <QuickActionItemContainer>
                        <ShareMenu variant="button" />
                    </QuickActionItemContainer>
                </FlexContainer>
            </CollectionActions>
        );
    }
);
