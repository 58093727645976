import { IconSize, modularScale } from '@adept-at/lib-react-components';
import { Breadcrumbs, Typography } from '@material-ui/core';
import Icon from '@mdi/react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const StyledBreadcrumbs = styled(Breadcrumbs)`
    display: flex;
    align-items: center;
    margin-left: 1rem;
    min-width: 0;

    ol {
        flex-wrap: nowrap;
        max-width: 100%;
    }

    .MuiBreadcrumbs-li {
        overflow: hidden;
    }
`;

const SharedCSS = css`
    display: flex;
    align-items: center;
    max-width: 100%;
    font-size: ${modularScale(-0.5)};

    svg {
        flex-shrink: 0;
        height: ${IconSize.Small};
        width: ${IconSize.Small};
        margin-right: 6px;
    }
`;

export const StyledRouterLink = styled(Link)`
    color: ${(props) => props.theme.colors.primary};
    ${SharedCSS};
`;

export const StyledLink = styled.button`
    ${SharedCSS};

    color: ${(props) => props.theme.colors.primary};
    cursor: pointer;

    background: none;
    border: none;
    padding: 0;
    font-family: ${(props) => props.theme.fonts.fontFamily};
`;

export const ChevronIcon = styled(Icon)`
    color: ${(props) => props.theme.colors.textTertiary};
`;

export const StyledTypography = styled(Typography)`
    ${SharedCSS}
`;

export const StyledText = styled.span`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
