import React, { useContext } from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { mdiDragHorizontalVariant } from '@mdi/js';
import Icon from '@mdi/react';
import { Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';

import { EditorContext } from '../context/EditorContext';

export const COMPONENT_LABELS = {
    text: 'Text',
    sectionTitle: 'Section',
    question: 'Question',
    image: 'Image',
    overview: 'Overview',
    conclusion: 'Conclusion',
    talkinghead: 'Webcam',
    screencapture: 'Screen',
    video: 'Video',
    pdf: 'PDF',
    code: 'Code',
    unsplashImage: 'Image' // DEPRECATED
};

const ComponentItem = styled.div`
    display: flex;
    align-items: center;

    svg {
        width: ${IconSize.Medium};
        margin-right: 1rem;
    }
`;

const DraggableContainer = styled.div`
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.8);
    padding: 1.5rem 2rem;
    background-color: ${(props) => props.theme.colors.surface};

    &:last-child {
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
    }
`;

interface DraggableComponentProps {
    id: string;
    index: number;
}

const DraggableComponent: React.FC<DraggableComponentProps> = ({ children, id, index }) => (
    <Draggable draggableId={id} index={index}>
        {(provided) => (
            <DraggableContainer {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                {children}
            </DraggableContainer>
        )}
    </Draggable>
);

const ReorderComponentList: React.FC = () => {
    const {
        workingSkill: { components }
    } = useContext(EditorContext);

    const componentIds = Object.keys(components);
    const list = componentIds.map((id) => ({ ...components[id], id }));

    const sorted = list.sort((a, b) => a.order - b.order);

    return (
        <>
            {sorted.map((component, i) => (
                <DraggableComponent key={component.id} id={component.id} index={i}>
                    <ComponentItem>
                        <Icon path={mdiDragHorizontalVariant} /> {COMPONENT_LABELS[component.type]} Component
                    </ComponentItem>
                </DraggableComponent>
            ))}
        </>
    );
};

export default ReorderComponentList;
