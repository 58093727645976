import React from 'react';

import { ViewerStatus, viewerStatusToTooltippedIcon } from '../utils';

import { StyledTableCell } from './TableCell.styles';

interface ViewersCellProps {
    viewers?: ViewerStatus;
}

export const VIEWERS_CELL_WIDTH = 115;

export const ViewersCell: React.FC<ViewersCellProps> = ({ viewers }) => {
    return (
        <StyledTableCell width={VIEWERS_CELL_WIDTH}>
            {viewers ? viewerStatusToTooltippedIcon(viewers) : null}
        </StyledTableCell>
    );
};
