import React from 'react';

import { LinkType } from '@adept-at/lib-react-components';
import { capitalize, TextField } from '@material-ui/core';
import { Control } from 'components/engine/common/RichTextEditor/LinkModal.styles';

export const LinkExternalForm: React.FC<{
    onChange: <LinkComponentValues>(field: string) => (value: LinkComponentValues) => void;
    linkType: LinkType;
    text: string;
    externalId: string;
}> = ({ onChange, linkType, text, externalId }) => {
    return (
        <>
            <Control fullWidth>
                <TextField
                    autoFocus
                    variant="outlined"
                    id="text"
                    label={`${capitalize(linkType)} title`}
                    name="text"
                    type="text"
                    fullWidth
                    defaultValue={text}
                    onChange={(e) => onChange('text')(e.target.value)}
                />
            </Control>
            <Control fullWidth>
                <TextField
                    variant="outlined"
                    id="externalId"
                    label={`External ${linkType} id`}
                    name="externalId"
                    type="text"
                    fullWidth
                    defaultValue={externalId ?? ''}
                    onChange={(e) => onChange('externalId')(e.target.value)}
                />
            </Control>
        </>
    );
};
