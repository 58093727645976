import React from 'react';

import { mdiClose } from '@mdi/js';
import { HistoryState } from 'components/navigation/NavContext';
import AppLayout from 'layouts/AppLayout';
import { useHistory } from 'react-router';

import { LearnEditContent } from './LearnEditContent';

export const LearnEditLayout: React.FC = () => {
    const history = useHistory();
    const backButtonPath = (history?.location?.state as HistoryState)?.from ?? '/dashboard';

    return (
        <AppLayout
            withoutPadding
            pageTitle="Skill"
            hideNav
            backButton
            backButtonOptions={{
                onClick: () => history.push(backButtonPath),
                iconPath: mdiClose,
                tooltip: 'Close skill'
            }}
        >
            <LearnEditContent />
        </AppLayout>
    );
};
