import { OwnerInfo } from 'components/ContentContext/Interfaces';
import { ClientError } from 'graphql-request';
import gql from 'graphql-tag';
import { useBuildAuthenticatedQueryFn } from 'hooks/useGqlClient/useBuildAuthenticatedQueryFn';
import { Api } from 'lib/ApiConstants';
import { useQuery, UseQueryResult } from 'react-query';

export const queryName = 'getCollectionsForTenantProfile';

const GET_COLLECTIONS_FOR_TENANT_PROFILE = gql`
    query ${queryName} ($owner: OwnerInfo!, $type: CollectionType!) {
        ${queryName} (owner: $owner, type: $type) {
            collectionId
            title
            collectionSlug
        }
    }
`;

export interface CollectionForTenantProfile {
    collectionId: string;
    title: string;
    collectionSlug: string;
}

export interface GetCollectionsForTenantProfileResponse {
    getCollectionsForTenantProfile: CollectionForTenantProfile[];
}

export enum CollectionType {
    INTERNAL = 'internal',
    CATALOG = 'catalog'
}

export interface GetCollectionsForTenantProfileVariables {
    ownerInfo: OwnerInfo;
    type: CollectionType;
}

export const useGetCollectionsForTenantProfile = (
    owner?: OwnerInfo,
    type?: CollectionType
): UseQueryResult<GetCollectionsForTenantProfileResponse, ClientError> => {
    const { queryFn } = useBuildAuthenticatedQueryFn<GetCollectionsForTenantProfileResponse>(
        GET_COLLECTIONS_FOR_TENANT_PROFILE,
        { owner, type },
        { api: Api.Content }
    );

    return useQuery<GetCollectionsForTenantProfileResponse, ClientError>(queryName, {
        queryFn,
        enabled: false
    });
};
