import React from 'react';

import Modal from 'components/modals';
import useMessageActions from 'hooks/communication/useMessageActions';
import AckableAdepteductMessage from 'lib/communication/message/base/AckableAdepteductMessage';
import { MessageDestination, MessageEntityTypeFromStandard } from 'lib/communication/message/MessageRecord';
import styled from 'styled-components';

import MessageContainer from '../message/MessageContainer';

import useDeleteMessage from './useDeleteMessage';

const StyledMessageContainer = styled.div`
    pointer-events: none;
    border: 1px solid ${(props) => props.theme.colors.border};
    border-radius: 4px;
    margin-top: 1rem;
`;

interface DeleteMessageModalProps {
    open: boolean;
    onClose: () => void;
    message?: AckableAdepteductMessage;
}

const DeleteMessageModal: React.FC<DeleteMessageModalProps> = ({ open, onClose, message }) => {
    const { mutate: deleteMessage } = useDeleteMessage();
    const { optimisticallyDeleteMessage } = useMessageActions();

    const onConfirm = () => {
        if (message) {
            deleteMessage({
                messageId: message.id,
                parentId: message.parent,
                entityId: message.entityId,
                entityType: MessageEntityTypeFromStandard[message.entityType]
            });

            optimisticallyDeleteMessage(message);
        }

        onClose();
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            onConfirm={onConfirm}
            modalTitle="Delete message"
            confirmButtonText="Delete"
        >
            <>
                <p>Are you sure you want to delete this message? This cannot be undone.</p>
                {message ? (
                    <StyledMessageContainer>
                        <MessageContainer message={message} destination={MessageDestination.Chat} />
                    </StyledMessageContainer>
                ) : null}
            </>
        </Modal>
    );
};

export default DeleteMessageModal;
