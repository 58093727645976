import React, { useContext } from 'react';

import { Switch } from '@material-ui/core';
import { Skill } from 'components/ContentContext/Interfaces';
import { AutoSaveIndicator } from 'components/engine/AutoSaveIndicator';
import SubHeader from 'components/SubHeader';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ignoreFeatureFlags } from 'lib/constants';
import styled from 'styled-components';

import { modularScale } from '../../../utils/modular_scale';
import { CommentContext } from '../../Comments/CommentContext';
import { CommentAccess } from '../../Comments/interfaces';
import { EditMode, EditorContext } from '../context/EditorContext';

import { BuildingHeaderContent } from './BuildingHeaderContent';

export const Divider = styled.div`
    width: 1px;
    height: 24px;
    background-color: ${(props) => props.theme.colors.border};
    align-self: center;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
`;

export const PreviewToggle = styled.div<{ $disableMargin?: boolean }>`
    line-height: 1;
    display: flex;
    align-items: center;
    font-size: ${modularScale(-1)};
    margin-right: ${(props) => (props.$disableMargin ? 0 : 2)}rem;
`;

export const CustomSwitch = styled(Switch)`
    .MuiSwitch-thumb {
        background-color: ${(props) => props.theme.colors.primary};
    }

    .MuiSwitch-track {
        background-color: ${(props) => props.theme.colors.primary};
    }
`;

export const ToggleState = styled.div<{ isActive }>`
    color: ${(props) => (props.isActive ? props.theme.colors.primary : 'inherit')};
    font-weight: ${(props) => (props.isActive ? 'bold' : 'inherit')};
    cursor: pointer;
`;

interface BuildingHeaderProps {
    skill: Skill;
}

export const BuildingHeader: React.FC<BuildingHeaderProps> = ({ skill }) => {
    const { editMode, clearEditMode, previewSkill, isPreview, toggleComponentEngineEditMode } =
        useContext(EditorContext);
    const { setContextAccessType } = useContext(CommentContext);

    const { editSkill: editSkillLDFlag } = useFlags();

    const handleChange = () => {
        if (isPreview()) {
            setContextAccessType(CommentAccess.Private);
            toggleComponentEngineEditMode();
            return clearEditMode();
        }

        setContextAccessType(CommentAccess.Public);
        toggleComponentEngineEditMode();
        previewSkill();
    };

    const renderPreviewToggleSwitch = () => {
        if (ignoreFeatureFlags || editSkillLDFlag)
            return (
                <PreviewToggle>
                    <ToggleState onClick={handleChange} isActive={isPreview()}>
                        Preview
                    </ToggleState>
                    <CustomSwitch
                        inputProps={{ 'aria-label': 'preview - edit toggle switch' }}
                        checked={editMode !== EditMode.Preview}
                        onChange={handleChange}
                        color="primary"
                        size={'small'}
                    />
                    <ToggleState onClick={handleChange} isActive={editMode !== EditMode.Preview}>
                        Edit
                    </ToggleState>
                </PreviewToggle>
            );

        return null;
    };

    return (
        <SubHeader>
            {renderPreviewToggleSwitch()}
            <AutoSaveIndicator />
            <BuildingHeaderContent onToggleEdit={handleChange} skillId={skill.skillId} />
        </SubHeader>
    );
};
