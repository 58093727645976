import React from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { mdiHammerScrewdriver } from '@mdi/js';
import Icon from '@mdi/react';
import styled from 'styled-components';

const NoResultsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 24px;

    button {
        margin-top: 24px;
    }
`;

const StyledIcon = styled(Icon)`
    color: ${(props) => props.theme.colors.textDisabled};
    margin-bottom: 32px;
`;

const SubtitleText = styled.p`
    margin-top: 8px;
    max-width: 376px;
    text-align: center;
`;

interface NoResultsProps {
    title?: string;
    subtitle?: string;
}

const NoResults: React.FC<NoResultsProps> = ({
    title = 'No results',
    subtitle = 'You may want to try adjusting your filters',
    children
}) => {
    return (
        <NoResultsWrapper>
            <StyledIcon size={IconSize.Hero} path={mdiHammerScrewdriver} />
            <h2>{title}</h2>
            <SubtitleText>{subtitle}</SubtitleText>
            {children}
        </NoResultsWrapper>
    );
};

export default NoResults;
