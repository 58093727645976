import React from 'react';

import { Switch } from '@material-ui/core';
import { mdiPencilOff } from '@mdi/js';
import Icon from '@mdi/react';
import { PreviewToggle, ToggleState } from 'components/builder/BuildingHeader';
import styled from 'styled-components';

import { QuickActionItemContainer, StyledDivider, QuickActionItem } from './NestedCollection.styles';

const StyledSwitch = styled(Switch)`
    .MuiSwitch-track {
        background-color: ${(props) => props.theme.colors.primary};
    }
`;

interface EditToggleSwitchProps {
    isEditMode: boolean;
    isPreviewMode: boolean;
    setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
    setIsPreviewMode: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EditPreviewToggle: React.FC<EditToggleSwitchProps> = ({
    isEditMode,
    isPreviewMode,
    setIsEditMode,
    setIsPreviewMode
}) => {
    const handleClick = () => {
        setIsEditMode((prevState) => !prevState);
        setIsPreviewMode((prevState) => !prevState);
    };

    const handleStopEditing = () => {
        setIsEditMode(false);
        setIsPreviewMode(false);
    };

    return (
        <>
            <PreviewToggle $disableMargin>
                <ToggleState isActive={isEditMode && !isPreviewMode}>Edit</ToggleState>
                <StyledSwitch
                    inputProps={{ 'aria-label': 'preview - edit toggle switch' }}
                    checked={isPreviewMode}
                    onChange={handleClick}
                    color="primary"
                />
                <ToggleState isActive={isPreviewMode}>Preview</ToggleState>
            </PreviewToggle>
            <StyledDivider orientation="vertical" />
            <QuickActionItemContainer>
                <QuickActionItem
                    title="Stop editing"
                    $isStickyMode
                    icon={<Icon path={mdiPencilOff} />}
                    onClick={handleStopEditing}
                />
            </QuickActionItemContainer>
        </>
    );
};
