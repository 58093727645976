import { AxiosError } from 'axios';
import { ClientError } from 'graphql-request';
import { GraphqlError } from 'hooks/useGqlClient/GraphqlError';
import { RetryValue } from 'react-query/types/core/retryer';

import { RequestError } from './RequestError';

const MAX_RETRIES_DEFAULT = 3;

/**
 * Union type that represent all error types that we currently recognize for our requests
 *
 * Notice: shouldRetry callback takes precedence if it returns false.
 */
type TypeAllErrors = ClientError | AxiosError | RequestError | GraphqlError;

export const getRetryCallback =
    <TError = TypeAllErrors>(
        shouldRetry: (error: TError) => boolean = () => true,
        numRetries = MAX_RETRIES_DEFAULT
    ): RetryValue<TError> =>
    (failureCount: number, error: TError): boolean => {
        // Custom callback can handle control by returning a false value
        if (!shouldRetry(error)) {
            return false;
        }

        if (error instanceof RequestError && error.isFatal()) {
            return false;
        }

        return failureCount < numRetries;
    };

/**
 * Convenience method for generating a custom shouldRetry callback using a list of error codes
 *
 * Usage:
 *
 * {
 *   retry: getRetryCallback(shouldRetryGraphql(FATAL_ERROR_CODES))
 * }
 *
 * @param fatalErrors List of Graphql error codes considered fatal as strings
 */
export const shouldRetryGraphql =
    <TError = TypeAllErrors>(fatalErrors: string[] = []) =>
    (error: TError): boolean =>
        !isGraphqlErrorFatal(error, fatalErrors);

/**
 * Determine if an error should be considered "fatal" when compared to a list of errors
 * @param error
 * @param fatalErrors
 * @returns
 */
export const isGraphqlErrorFatal = <TError = TypeAllErrors>(error: TError, fatalErrors: string[]): boolean =>
    error instanceof GraphqlError && fatalErrors.includes(error.code);
