import { LinkType } from '@adept-at/lib-react-components';
import { Modifier, SelectionState, EditorState, RichUtils } from 'draft-js';

import { useRichTextEditor } from './Context';
import { DraftJsEntity, EditorChangeType } from './utils';

export const useLinks = (
    onClose: () => void
): {
    toggleLink: (text: string, link: string) => void;
    addLink: (link: string, text: string) => void;
    updateLink: (entityId: string, link: string) => void;
    deleteEntireLink: () => void;
    deleteLinkSelection: () => void;
} => {
    const { setEditorState, editorState } = useRichTextEditor();

    const generateUrl = (link) => {
        const urlMatch = /^((http|https|ftp):\/\/)/;
        return link.match(urlMatch) ? encodeURI(link) : encodeURI(`http://${link}`);
    };

    const toggleLink = (text: string, link: string) => {
        if (text && link) {
            const url = generateUrl(link);
            const contentStateWithEntity = editorState
                .getCurrentContent()
                .createEntity(DraftJsEntity.LINK, 'MUTABLE', { url, linkType: LinkType.TEXT, text });
            const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
            const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
            setEditorState(RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey));
        }

        onClose();
    };

    const addLink = (text: string, link: string) => {
        if (text && link) {
            const url = generateUrl(link);
            const contentState = editorState.getCurrentContent();
            const selection = editorState.getSelection();
            const newContent = Modifier.insertText(contentState, selection, text);
            const newContentWithEntity = newContent.createEntity(DraftJsEntity.LINK, 'MUTABLE', {
                text,
                url,
                linkType: LinkType.TEXT
            });
            const entityKey = newContentWithEntity.getLastCreatedEntityKey();
            const anchorOffset = selection.getAnchorOffset();
            const key = selection.getAnchorKey();
            const newSelection = new SelectionState({
                anchorKey: key,
                anchorOffset,
                focusKey: key,
                focusOffset: anchorOffset + text.length
            });
            const newContentWithLink = Modifier.applyEntity(newContentWithEntity, newSelection, entityKey);
            const withLinkText = EditorState.push(editorState, newContentWithLink, EditorChangeType.INSERT_CHARACTERS);
            setEditorState(withLinkText);
        }
        onClose();
    };

    const updateLink = (entityId: string, link: string) => {
        if (!link) {
            setEditorState(RichUtils.toggleLink(editorState, editorState.getSelection(), null));
        } else {
            const url = generateUrl(link);
            const updatedContent = editorState
                .getCurrentContent()
                .replaceEntityData(entityId, { url, linkType: LinkType.TEXT });
            const newEditorState = EditorState.set(editorState, { currentContent: updatedContent });
            setEditorState(RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityId));
        }

        onClose();
    };

    const deleteLinkSelection = () => {
        const contentState = editorState.getCurrentContent();
        const selectionState = editorState.getSelection();
        const startKey = selectionState.getStartKey();
        const contentBlock = contentState.getBlockForKey(startKey);
        const startOffset = selectionState.getStartOffset();
        const entity = contentBlock.getEntityAt(startOffset);

        if (!entity) {
            return editorState;
        }

        if (selectionState) {
            const newContentState = Modifier.applyEntity(contentState, selectionState, null);
            const newEditorState = EditorState.set(editorState, { currentContent: newContentState });
            setEditorState(newEditorState);
        }
        onClose();
    };

    const deleteEntireLink = () => {
        const contentState = editorState.getCurrentContent();
        const selectionState = editorState.getSelection();
        const startKey = selectionState.getStartKey();
        const contentBlock = contentState.getBlockForKey(startKey);
        const startOffset = selectionState.getStartOffset();
        const entity = contentBlock.getEntityAt(startOffset);

        if (!entity) {
            return editorState;
        }

        let entitySelection: SelectionState | undefined;

        contentBlock.findEntityRanges(
            (character) => character.getEntity() === entity,
            (start, end) => {
                entitySelection = selectionState.merge({
                    anchorOffset: start,
                    focusOffset: end
                });
            }
        );
        if (entitySelection) {
            const newContentState = Modifier.applyEntity(contentState, entitySelection, null);
            const newEditorState = EditorState.set(editorState, { currentContent: newContentState });
            setEditorState(newEditorState);
        }
        onClose();
    };

    return {
        addLink,
        updateLink,
        toggleLink,
        deleteEntireLink,
        deleteLinkSelection
    };
};
