import React from 'react';

import { TextField } from '@material-ui/core';
import styled from 'styled-components';

const StyledTextField = styled(TextField)`
    margin-top: 1.5rem;
`;

interface ResponderTextFieldProps {
    name: string;
    defaultValue?: string;
    inputRef: any;
    ariaLabel: string;
    error: boolean;
    helperText: string | null | undefined;
}

const ResponderTextField: React.FC<ResponderTextFieldProps> = ({
    name,
    defaultValue,
    inputRef,
    ariaLabel,
    error = false,
    helperText = null
}) => {
    return (
        <StyledTextField
            name={name}
            defaultValue={defaultValue}
            inputRef={inputRef}
            fullWidth
            autoFocus
            variant="outlined"
            inputProps={{ 'aria-label': ariaLabel }}
            error={error}
            helperText={helperText}
            multiline
        />
    );
};

export default ResponderTextField;
