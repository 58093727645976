import React, { useCallback } from 'react';

import { mdiShareVariant, mdiLink, mdiEmail, mdiTwitter, mdiFacebook, mdiInstagram } from '@mdi/js';
import { Icon } from '@mdi/react';
import { usePopover } from 'hooks/usePopover';
import styled from 'styled-components';
import { StyledMenu } from 'styles/Shared.styles';
import { copyUrlToClipboard } from 'utils/copyUrlToClipboard';

import { QuickActionItem } from '../NestedCollection.styles';

import { ActionMenuItem } from './ActionMenuItem';

const NestedMenuWrapper = styled.div`
    display: initial;
`;

interface ShareCollectionMenuProps {
    isStickyMode?: boolean;
    isSubMenu?: boolean;
    subAnchorEl?: HTMLElement | null;
    handleCloseSubMenu?: () => void;
    handleOpenMenu?: (e: React.MouseEvent<HTMLElement>) => void;
}

export const ShareCollectionWrapper: React.FC<ShareCollectionMenuProps> = ({ isStickyMode, handleOpenMenu }) => {
    return (
        <QuickActionItem
            title="Share"
            $isStickyMode={isStickyMode}
            icon={<Icon path={mdiShareVariant} />}
            onClick={handleOpenMenu}
        />
    );
};

export const ShareCollectionMenu: React.FC<ShareCollectionMenuProps> = ({
    isStickyMode,
    isSubMenu,
    subAnchorEl,
    handleCloseSubMenu = () => {}
}) => {
    const { open, anchorEl, handlePopoverClick, handlePopoverClose } = usePopover();

    const onCloseSubMenu = useCallback(() => {
        if (isSubMenu) {
            handleCloseSubMenu();
        }
    }, [isSubMenu, handleCloseSubMenu]);

    const handleClose = useCallback(() => {
        handlePopoverClose();
        onCloseSubMenu();
    }, [handlePopoverClose, onCloseSubMenu]);

    const handleCopyAndClose = () => {
        handleClose();
        copyUrlToClipboard();
    };

    return (
        <>
            {!isSubMenu ? (
                <ShareCollectionWrapper isStickyMode={isStickyMode} handleOpenMenu={handlePopoverClick} />
            ) : null}
            <StyledMenu
                anchorEl={subAnchorEl ?? anchorEl}
                open={Boolean(subAnchorEl ?? open)}
                onClose={handlePopoverClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: isSubMenu ? 'right' : 'left' }}
            >
                <NestedMenuWrapper onMouseLeave={onCloseSubMenu}>
                    <ActionMenuItem title="Copy link to clipboard" icon={mdiLink} onClick={handleCopyAndClose} />
                    <ActionMenuItem title="Compose email" icon={mdiEmail} onClick={handleClose} />
                    <ActionMenuItem title="Tweet this" icon={mdiTwitter} onClick={handleClose} />
                    <ActionMenuItem title="Post to Facebook" icon={mdiFacebook} onClick={handleClose} />
                    <ActionMenuItem title="Share on Instagram" icon={mdiInstagram} onClick={handleClose} />
                </NestedMenuWrapper>
            </StyledMenu>
        </>
    );
};
