// A reusable Modal component abstraction of MUI Modal for purpose of keeping styles consistent
// This is to be used along w/ our useModal() hook which controls the visibility of the Modal.
import React from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { Fade, ModalProps } from '@material-ui/core';

import { StyledModal, ModalHeader, ModalBody, TitleIcon } from './Modal.styles';
import ModalActionButtons from './ModalActionButtons';
import ModalCloseIcon from './ModalCloseIcon';
import Title from './ModalTitle';

export const DEFAULT_FADE_TIMEOUT = 350;

interface AdeptModalProps extends ModalProps {
    open: boolean;
    onClose: () => void;
    onConfirm?: () => void;
    showCloseIcon?: boolean;
    showActionButtons?: boolean;
    cancelButtonText?: string;
    confirmButtonText?: string;
    confirmButtonDisabled?: boolean;
    modalTitle?: string;
    titleIconPath?: string;
    CancelButton?: React.FC;
    ConfirmButton?: React.FC;
    labelProps?: { 'aria-label'?: string; 'aria-describedby'?: string };
    styleProps?: { 'max-width'?: string };
}

export const Modal: React.FC<AdeptModalProps> = ({
    open = false,
    onClose,
    onConfirm,
    showCloseIcon = true,
    showActionButtons = true,
    cancelButtonText,
    confirmButtonText,
    confirmButtonDisabled,
    modalTitle,
    titleIconPath,
    children,
    CancelButton,
    ConfirmButton,
    labelProps,
    styleProps,
    ...otherProps
}) => {
    return (
        <StyledModal
            open={open}
            onClose={onClose}
            aria-label={labelProps?.['aria-label'] ? labelProps?.['aria-label'] : `modal for ${modalTitle}`}
            aria-describedby={labelProps?.['aria-describedby'] ? labelProps?.['aria-describedby'] : ''}
            {...otherProps}
        >
            <Fade in={open} timeout={DEFAULT_FADE_TIMEOUT}>
                <ModalBody $maxWidth={styleProps?.['max-width']}>
                    <ModalHeader>
                        {titleIconPath ? <TitleIcon path={titleIconPath} size={IconSize.Large} /> : null}
                        <Title modalTitle={modalTitle} />
                        <ModalCloseIcon showCloseIcon={showCloseIcon} onClose={onClose} />
                    </ModalHeader>
                    <>{children}</>
                    <ModalActionButtons
                        showActionButtons={showActionButtons}
                        CancelButton={CancelButton}
                        ConfirmButton={ConfirmButton}
                        onClose={onClose}
                        onConfirm={onConfirm}
                        cancelButtonText={cancelButtonText}
                        confirmButtonText={confirmButtonText}
                        confirmButtonDisabled={confirmButtonDisabled}
                    />
                </ModalBody>
            </Fade>
        </StyledModal>
    );
};

export default Modal;
