import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { AccountContext } from 'context/AccountContext';
import { CommunicationChannelsContext } from 'context/CommunicationChannelsContext';
import { OrganizationContext } from 'context/OrganizationContext';
import { OrganizationGroup } from 'context/OrganizationContext/useGetOrganizationGroups';
import { ProfileContext } from 'context/ProfileContext';
import { ClientError } from 'graphql-request';
import {
    GetOrganizationGroupUsersResult,
    GroupUser,
    useGetOrganizationGroupUsers
} from 'hooks/useGetOrganizationGroupUsers';
import { QueryObserverResult, RefetchOptions } from 'react-query';
import { useParams } from 'react-router';
import { isTrue } from 'utils/isTrue';

import { CollectionDetails } from './hooks/getCollectionDetails';
import useOrganizationCollections from './hooks/useOrganizationCollections';

export interface StackProviderInterface {
    stackId: string;
    organizationId: string;
    stackMembers: GroupUser[];
    stackMembersStatus: 'error' | 'idle' | 'loading' | 'success';
    refetchStackMembers: (
        options?: RefetchOptions | undefined
    ) => Promise<QueryObserverResult<GetOrganizationGroupUsersResult, ClientError>>;
    isStackJoined: boolean;
    cannotLoadChannel: boolean;
    stack?: OrganizationGroup;
    filteredContent?: CollectionDetails[];
    filter: Filter;
    setFilter: React.Dispatch<React.SetStateAction<Filter>>;
    channelId?: string;
    totalCollections: number;
    totalAssignments: number;
    activeSidebarTab: RoomTab;
    setActiveSidebarTab: React.Dispatch<React.SetStateAction<RoomTab>>;
    hasTrackedGroupVisit: boolean;
    setHasTrackedGroupVisit: React.Dispatch<React.SetStateAction<boolean>>;
    sidebarOpen: boolean;
    setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
    collections: CollectionDetails[];
    collectionsLoading: boolean;
    tenantSlug: string;
}

export enum Filter {
    ASSIGNED = 'Assigned content',
    COLLECTIONS = 'Collections'
}

const StackContext = createContext({} as StackProviderInterface);

const { Provider, Consumer } = StackContext;

interface StackProviderProps {
    value: {
        stackId: string;
    };
}

export interface StackInfo {
    stackId: string;
    organizationId: string;
}

export enum RoomTab {
    Chat = 'Chat',
    LiveLearning = 'LiveLearning',
    Resources = 'Resources',
    Comments = 'Comments',
    JoinedSession = 'JoinedSession',
    Collection = 'Collection'
}

const StackProvider: React.FC<StackProviderProps> = ({ value: { stackId }, children }) => {
    const { organizationGroups } = useContext(OrganizationContext);
    const { groups } = useContext(AccountContext);
    const {
        availableChannels = [],
        error: getChannelsForUserError,
        status: getChannelsForUserStatus
    } = useContext(CommunicationChannelsContext);

    const { currentOrgId: organizationId } = useParams<{ currentOrgId: string }>();

    const { availableProfiles, selectProfile } = useContext(ProfileContext);

    const [filteredContent, setFilteredContent] = useState<CollectionDetails[]>();
    const [filter, setFilter] = useState<Filter>(Filter.COLLECTIONS);
    const [activeSidebarTab, setActiveSidebarTab] = useState(RoomTab.Chat);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [hasTrackedGroupVisit, setHasTrackedGroupVisit] = useState(false);

    const stack = useMemo(
        () => organizationGroups?.find((group) => group.id === stackId),
        [organizationGroups, stackId]
    );

    const {
        status: stackMembersStatus,
        data: organizationGroupUsersData,
        refetch: refetchStackMembers
    } = useGetOrganizationGroupUsers(
        {
            organizationId,
            groupId: stackId
        },
        { enabled: isTrue(organizationId) }
    );

    const stackMembers = useMemo(
        () => organizationGroupUsersData?.getOrganizationGroupUsers ?? [],
        [organizationGroupUsersData]
    );

    const channelId = useMemo(
        () => availableChannels.find((channel) => channel.ownerFilter.id === stackId)?.channelId,
        [availableChannels, stackId]
    );

    const isStackJoined = useMemo(() => groups.some((group) => group.id == stackId), [groups, stackId]);

    const cannotLoadChannel = !!getChannelsForUserError || !(getChannelsForUserStatus === 'loading' || channelId);

    useEffect(() => {
        const foundProfile = availableProfiles?.find((profile) => profile.id === organizationId);

        if (foundProfile) {
            selectProfile(foundProfile);
        }
    }, [availableProfiles, selectProfile, organizationId]);

    const { collections, collectionsLoading, tenantSlug } = useOrganizationCollections(organizationId);

    useEffect(() => {
        if (collections) {
            switch (filter) {
                case Filter.ASSIGNED:
                    setFilteredContent([]);
                    break;
                default:
                    setFilteredContent(collections);
                    break;
            }
        }
    }, [filter, stackId, collections]);

    const totalCollections = collections?.length ?? 0;

    return (
        <Provider
            value={{
                stackId,
                organizationId,
                stack,
                collections,
                collectionsLoading,
                tenantSlug,
                filteredContent,
                filter,
                setFilter,
                isStackJoined,
                stackMembers,
                stackMembersStatus,
                refetchStackMembers,
                cannotLoadChannel,
                channelId,
                totalCollections,
                totalAssignments: 0,
                activeSidebarTab,
                setActiveSidebarTab,
                hasTrackedGroupVisit,
                setHasTrackedGroupVisit,
                sidebarOpen,
                setSidebarOpen
            }}
        >
            {children}
        </Provider>
    );
};

export { StackContext, StackProvider, Consumer as StackConsumer };
