import { Button } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
    margin-top: 1rem;
    width: 100%;
`;

export const StyledButton = styled(Button)`
    font-weight: 600;
    color: ${(props) => props.theme.colors.errorText};
    margin-right: -8px;
`;
