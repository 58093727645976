import React, { useMemo } from 'react';

import { AdeptCard, AdeptCardContent, IconSize, PublicIndicator } from '@adept-at/lib-react-components';
import BrandingLabel from 'components/NestedCollection/BrandingLabel';
import { CardPositioningStyles } from 'components/NestedCollection/ContentSection/ContentSectionCard';
import { UploadTag } from 'components/NestedCollection/context/CollectionImageContext';
import { useThemeContext, ThemeMode } from 'context/ThemeContext';
import { useOrganizationTerminology } from 'hooks/useOrganizationTerminology';
import pluralize from 'pluralize';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { formatTime } from 'utils/time';

import CollectionQuickActions from './CollectionQuickActions';
import { CollectionChild } from './context/useGetTenantProfileForOwner';
import { CollectionBasicDetail, CollectionBasicStats } from './useGetNestedCollections/getCollectionBasicDetails';

// this style allows the stacked card background to show, TODO: see if we can apply the overflow in the lib
const StyledAdeptCard = styled(AdeptCard)`
    overflow: visible;
    box-shadow: none;
`;

const COLLECTION_IMAGE_HEIGHT = '106px';
const COLLECTION_IMAGE_WIDTH = '106px';

interface NestedCollectionCardProps {
    collection: CollectionBasicDetail;
    parentCollectionId: string;
    parentSlug?: string;
    linkUrl: string;
}

const formatStat = (type: CollectionChild, stats: CollectionBasicStats[] | null, label?: string): string => {
    const stat = stats?.filter((stat) => stat?.type === type);

    return `${pluralize(label ?? type, stat?.[0]?.count ?? 0, true)}`;
};

const CardWrapper = styled.div`
    ${CardPositioningStyles}
`;

export const NestedCollectionCard: React.FC<NestedCollectionCardProps> = ({
    collection,
    linkUrl,
    parentCollectionId,
    parentSlug
}) => {
    const { terminology } = useOrganizationTerminology();

    const { themeMode } = useThemeContext();

    const catalogImage = useMemo(() => {
        if (collection?.images?.catalogImages?.length) {
            const isLightImage = (tags: string[] | null | undefined) => tags?.join('').includes(UploadTag.LIGHT);

            const catalogLight = collection?.images?.catalogImages?.find((item) => isLightImage(item?.tags));
            const catalogDark = collection?.images?.catalogImages?.find((item) => !isLightImage(item?.tags));

            if (themeMode === ThemeMode.DARK) {
                return catalogDark ?? catalogLight;
            }

            return catalogLight;
        }

        return collection?.images?.catalog;
    }, [collection, themeMode]);

    return (
        <CardWrapper>
            <Link to={linkUrl}>
                <StyledAdeptCard>
                    <AdeptCardContent.Collection
                        organizationComponent={<BrandingLabel iconSize={IconSize.Small} />}
                        quickActionsComponent={
                            <CollectionQuickActions
                                url={linkUrl}
                                collection={collection}
                                parentCollectionId={parentCollectionId}
                                parentSlug={parentSlug}
                            />
                        }
                        title={collection.title}
                        imageProps={{
                            image: catalogImage,
                            style: {
                                borderRadius: '50%',
                                height: COLLECTION_IMAGE_HEIGHT,
                                width: COLLECTION_IMAGE_WIDTH
                            }
                        }}
                        secondaryInfo={[formatTime(collection.stats?.estimatedSecondsToConsume ?? 0, 'medium')]}
                        tertiaryInfo={[
                            formatStat(CollectionChild.Skill, collection.stats?.descendants ?? [], terminology.skill),
                            formatStat(CollectionChild.Lab, collection.stats?.descendants ?? [], 'lab'),
                            formatStat(CollectionChild.Question, collection.stats?.descendants ?? [])
                        ]}
                        lowerRightComponent={<PublicIndicator isPublic={collection.visible} />}
                    />
                </StyledAdeptCard>
            </Link>
        </CardWrapper>
    );
};
