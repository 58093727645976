import React from 'react';

import { QuestionComponentValues } from '@adept-at/lib-react-components';

import { useComponentEngine } from '../../EngineContext';
import { EditableComponentProvider } from '../../mixins/editor';
import { ComponentEngineMode } from '../../types';

import QuestionEdit from './Edit';
import { emptyQuestionComponentValues } from './lib/question';
import QuestionView from './View';

interface QuestionComponentProps {
    id: string;
    order: number;
}

export const Question: React.FC<QuestionComponentProps & QuestionComponentValues> = ({
    id,
    order,
    ...componentValues
}) => {
    const { mode } = useComponentEngine();

    if (mode === ComponentEngineMode.Edit) {
        return (
            <EditableComponentProvider initialValues={{ ...emptyQuestionComponentValues, ...componentValues }}>
                <QuestionEdit />
            </EditableComponentProvider>
        );
    }

    return <QuestionView {...{ ...componentValues, id, order }} />;
};

export const title = 'Question';

export const Component = Question;
