import React, { useCallback, useContext, useMemo, useState } from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { Button, ButtonGroup, Chip, FormControlLabel, Switch } from '@material-ui/core';
import { mdiStoreRemove, mdiStore } from '@mdi/js';
import Icon from '@mdi/react';
import { EntityType } from 'components/ContentContext/Enums';
import { CourseIconPath } from 'components/icons/CourseIcon';
import useUpsertListingStatus, { MarketplaceListingStatus } from 'components/marketplace/hooks/useUpsertListingStatus';
import Modal from 'components/modals';
import { InfoTooltip } from 'components/NestedCollection/InfoTooltip';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';
import { modularScale } from 'utils/modular_scale';

import { RootCollectionContext } from './context/RootCollectionContext';

interface AvailabilityModalProps {
    open: boolean;
    onClose: () => void;
}

const StyledChip = styled(Chip)`
    margin-top: -2rem;
`;

const SwitchGroup = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
`;

const SwitchLabel = styled.span<{ $selected?: boolean }>`
    color: ${(props) => (props.$selected ? props.theme.colors.primary : 'inherit')};
    font-weight: bold;
    font-size: ${modularScale(-0.5)};
    display: flex;
    align-items: center;
`;

const SUBSCRIPTION_INFO =
    'Subscriptions allow other organizations to purchase access to your entire catalog for their learners.';

export const AvailabilityModal: React.FC<AvailabilityModalProps> = ({ open, onClose }) => {
    const { parentQueryKey, rootCollection, marketplaceStatus, aboutInfo, tenantProfileId } =
        useContext(RootCollectionContext);
    const { mutate: updateMarketStatus } = useUpsertListingStatus(parentQueryKey, rootCollection);
    const { enqueueSnackbar } = useSnackbar();

    const originalListed = useMemo(() => marketplaceStatus === MarketplaceListingStatus.listed, [marketplaceStatus]);

    const [listed, setListed] = useState(originalListed);
    const [subscription, setSubscription] = useState(false);

    const closeAndReset = useCallback(() => {
        onClose();
        setListed(originalListed);
    }, [onClose, originalListed]);

    const toggleMarketplaceVisibility = useCallback(() => {
        if (tenantProfileId && rootCollection && originalListed !== listed) {
            const newStatus = listed ? MarketplaceListingStatus.listed : MarketplaceListingStatus.unlisted;

            updateMarketStatus(
                {
                    listingOwner: { type: EntityType.TENANT_PROFILE, id: tenantProfileId },
                    listingContent: { type: EntityType.COLLECTION, id: rootCollection?.collectionId },
                    status: newStatus
                },
                {
                    onSuccess: () => {
                        enqueueSnackbar(
                            `${rootCollection?.title} is now ${
                                newStatus === MarketplaceListingStatus.listed ? `${newStatus} on` : `${newStatus} from`
                            } the marketplace.`,
                            { variant: 'success' }
                        );
                    },
                    onError: () => {
                        enqueueSnackbar(`An error occurred adding ${rootCollection?.title} to the marketplace.`, {
                            variant: 'error'
                        });
                    }
                }
            );
        }

        onClose();
    }, [onClose, listed, originalListed, tenantProfileId, rootCollection, updateMarketStatus, enqueueSnackbar]);

    return (
        <Modal
            open={open}
            onClose={closeAndReset}
            onClick={(e) => e.stopPropagation()}
            modalTitle="Availability"
            confirmButtonText="Save"
            onConfirm={toggleMarketplaceVisibility}
        >
            <>
                <StyledChip
                    size="small"
                    icon={<Icon path={CourseIconPath} size={IconSize.Small} />}
                    label={`${aboutInfo?.title ?? ''} public catalog`}
                    variant="outlined"
                />
                <ButtonGroup fullWidth color="primary">
                    <Button
                        disableElevation
                        onClick={() => setListed(false)}
                        variant={listed ? 'outlined' : 'contained'}
                        startIcon={<Icon path={mdiStoreRemove} size={IconSize.Small} />}
                    >
                        Unlisted
                    </Button>
                    <Button
                        disableElevation
                        onClick={() => setListed(true)}
                        variant={listed ? 'contained' : 'outlined'}
                        startIcon={<Icon path={mdiStore} size={IconSize.Small} />}
                    >
                        Listed
                    </Button>
                </ButtonGroup>
                <SwitchGroup>
                    <FormControlLabel
                        disabled
                        checked={subscription}
                        onChange={() => setSubscription(!subscription)}
                        control={<Switch color="primary" />}
                        labelPlacement="end"
                        label={
                            <SwitchLabel $selected={subscription}>
                                {`Subscription: ${subscription ? 'On' : 'Off'}`}
                                <InfoTooltip title={SUBSCRIPTION_INFO} />
                            </SwitchLabel>
                        }
                    />
                </SwitchGroup>
            </>
        </Modal>
    );
};
