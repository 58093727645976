import { GraphQLClient } from 'graphql-request';
import gql from 'graphql-tag';
import buildGqlMutationFn from 'hooks/useGqlClient/helpers/buildGqlMutationFn';
import { MutationFunction } from 'react-query';

import { ReadyToPresentResult, ReadyToPresentVariables } from '.';

export const READY_TO_PRESENT = gql`
    mutation readyToPresentLiveLearning($sessionId: UUIDv4!) {
        readyToPresentLiveLearning(sessionId: $sessionId) {
            subscribeToken
        }
    }
`;

const makeReadyToPresent = (client: GraphQLClient): MutationFunction<ReadyToPresentResult, ReadyToPresentVariables> =>
    buildGqlMutationFn(client, READY_TO_PRESENT);

export default makeReadyToPresent;
