import React, { useState } from 'react';

import { AnswerOption, QuestionComponentValues, QuestionType } from '@adept-at/lib-react-components';
import { FormControl, TextField, InputAdornment } from '@material-ui/core';
import { isTrue } from 'utils/isTrue';

import { useComponentEngineComponent } from '../..';
import { useEditableComponent, ComponentFocus, EditButtons } from '../../mixins/editor';
import { useFocusableComponent } from '../../mixins/focusable';

import { Container } from './Edit.styles';
import { cleanValues } from './lib/question';
import { QuestionEditForm } from './Question.styles';
import QuestionTypeList from './QuestionTypeList';
import { getEditForType } from './QuestionTypes';
import HintEdit from './QuestionTypes/Hint/Edit';
import QuestionView from './View';

interface QuestionEditProps {
    onCancel?: () => void;
    onSave?: (values: Partial<QuestionComponentValues>) => void;
}

const QuestionEdit: React.FC<QuestionEditProps> = ({ onCancel, onSave }) => {
    const { id } = useComponentEngineComponent();
    const [error, setError] = useState({});
    const { doesComponentHaveAnyFocus } = useFocusableComponent();

    const {
        currentValues,
        onChangeManyAndSave: defaultOnSave,
        onCancel: defaultOnCancel,
        onChange
    } = useEditableComponent<QuestionComponentValues>();

    if (!doesComponentHaveAnyFocus(id, [ComponentFocus.Edit, ComponentFocus.AddAndEdit])) {
        return <QuestionView {...currentValues} />;
    }

    const QuestionTypeEdit = getEditForType(currentValues.questionType);

    return (
        <QuestionEditForm>
            <FormControl component="fieldset">
                <TextField
                    label="Question"
                    fullWidth
                    id="editQuestion"
                    name="question"
                    variant="outlined"
                    value={currentValues.question}
                    onChange={(e) => onChange('question')(e.target.value)}
                    inputProps={{
                        'data-testid': `questionEdit-question`
                    }}
                    error={isTrue(error['question'])}
                    helperText={error['question']}
                    multiline
                />
            </FormControl>
            <FormControl component="fieldset">
                <QuestionTypeList
                    selectedType={currentValues.questionType || QuestionType.Choice}
                    onClick={(questionType) => {
                        onChange('questionType')(questionType);
                    }}
                />
            </FormControl>
            <Container>
                <QuestionTypeEdit
                    values={currentValues}
                    onChange={onChange}
                    onTypeChange={(key: string, index: number | string, option: any) => {
                        const currentValue: any[] | Record<string, unknown> = currentValues[key];

                        const newOptions = Array.isArray(currentValue)
                            ? [...currentValue] || []
                            : { ...currentValue } || {};

                        newOptions[index] = option;
                        onChange(key)(newOptions);
                    }}
                    onOptionChange={(index: number, option: AnswerOption) => {
                        const newOptions = [...currentValues.answerOptions];
                        newOptions[index] = option;
                        onChange('answerOptions')(newOptions);
                    }}
                    error={error}
                />
            </Container>

            {currentValues.questionType !== QuestionType.Poll && (
                <>
                    <TextField
                        name="answerDelayInSeconds"
                        label="Delay Between Answers"
                        id="answerDelayInSeconds"
                        value={currentValues.answerDelayInSeconds}
                        type="number"
                        onChange={(e) => onChange('answerDelayInSeconds')(e.target.value)}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">seconds</InputAdornment>
                        }}
                        inputProps={{
                            'data-testid': `questionEdit-answerDelayInSeconds`
                        }}
                    />
                    <HintEdit
                        values={currentValues}
                        onChange={onChange}
                        onTypeChange={(key: string, index: number | string, option: any) => {
                            const newOptions: string[] = [...currentValues[key]] || [];
                            newOptions[index] = option;
                            onChange(key)(newOptions);
                        }}
                    />
                </>
            )}
            <EditButtons
                onCancel={onCancel ?? defaultOnCancel}
                onSave={() => {
                    const cleanedValues = cleanValues(currentValues, setError);

                    if (!cleanedValues) {
                        return;
                    }

                    if (onSave) {
                        onSave(cleanedValues);
                    } else {
                        defaultOnSave(cleanedValues);
                    }
                }}
            />
        </QuestionEditForm>
    );
};

export default QuestionEdit;
