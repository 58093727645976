import React, { useRef, useMemo } from 'react';

import { SectionTitleComponentValues } from '@adept-at/lib-react-components';
import { ComponentEngineMode, useComponentEngine } from 'components/engine';
import { EditableText } from 'components/forms/EditableText';

import { useEditableComponent } from '../../mixins/editor';

import { DEFAULT_SECTION_TITLE } from '.';

const SectionTitleEdit: React.FC = () => {
    const {
        currentValues: { title },
        onChangeAndSave
    } = useEditableComponent<SectionTitleComponentValues>();
    const containerRef = useRef<HTMLDivElement | null>(null);
    const { mode } = useComponentEngine();

    const canEdit = useMemo(() => mode === ComponentEngineMode.Edit, [mode]);

    return (
        <div ref={containerRef} data-testid={'edit-section-title'}>
            <EditableText
                initialContent={title}
                saveContent={(value: string) => {
                    onChangeAndSave<string>('title', ['title'])(value);
                }}
                modularScaleValue={1}
                inputAriaLabel={'section-title-edit-input-area'}
                buttonAriaLabel={'section-title-edit-input-button'}
                canEdit={canEdit}
                shouldClearOnEdit={title === DEFAULT_SECTION_TITLE}
                validation={(value) => !!value || value === ''}
                maxCharacters={60}
                pencilTooltipText="Edit section title"
            />
        </div>
    );
};

export default SectionTitleEdit;
