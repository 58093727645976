import React, { useCallback, useEffect, useState } from 'react';

import { Backdrop, Button, Fade, TextField } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ClientError } from 'graphql-request';
import gql from 'graphql-tag';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import styled from 'styled-components';

import useGqlClient from '../../../hooks/useGqlClient';
import buildGqlMutationFn from '../../../hooks/useGqlClient/helpers/buildGqlMutationFn';
import { API_CONTENT } from '../../../lib/ApiConstants';
import { OwnerType } from '../../ContentContext/Interfaces';
import { Buttons, ModalBody, StyledModal, Title } from '../Modal.styles';

const StyledTextField = styled(TextField)`
    margin-bottom: 1rem;
`;

const CREATE_ASSESSMENT_FOR_OWNER_MUTATION = gql`
    mutation createAssessmentForOwner($ownerInfo: OwnerInfo!, $title: String!) {
        createAssessmentForOwner(ownerInfo: $ownerInfo, title: $title) {
            assessmentId
        }
    }
`;

const ModalTitle = styled(Title)`
    margin-bottom: 0.5rem;
`;

interface CreateAssessmentResultProps {
    assessmentId: string;
}

interface CreateAssessmentResult {
    createAssessmentForOwner: CreateAssessmentResultProps;
}

interface CreateAssessmentParams {
    title: string;
    ownerInfo: {
        type: string;
        id: string;
    };
    description?: string;
}

interface AssessmentForm {
    title: string;
    description?: string;
}

interface CreateAssessmentModalProps {
    isOpen: boolean;
    handleClose: () => void;
    onFinish?: (labelId: string) => void;
}

const CreateSkillModal: React.FC<CreateAssessmentModalProps> = ({ isOpen, onFinish, handleClose }) => {
    const { currentUser } = useCurrentUser();
    const { register, handleSubmit, errors } = useForm<AssessmentForm>();
    const [isLoading, setIsLoading] = useState(false);
    const [, setSubmitError] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { client } = useGqlClient(API_CONTENT);

    const {
        mutate: createAssessment,
        data,
        error
    } = useMutation<CreateAssessmentResult, ClientError, CreateAssessmentParams>(
        buildGqlMutationFn<CreateAssessmentResult, CreateAssessmentParams>(client, CREATE_ASSESSMENT_FOR_OWNER_MUTATION)
    );

    const createAssessmentForUser = (values: CreateAssessmentParams) => {
        if (currentUser?.userId) {
            createAssessment({
                ownerInfo: {
                    type: OwnerType.User,
                    id: currentUser?.userId
                },
                title: values.title,
                description: values.description
            });
        }
    };

    const onSubmit = (values: CreateAssessmentParams) => {
        setIsLoading(true);

        createAssessmentForUser(values);
    };

    const resetModal = useCallback(() => {
        handleClose();
        setIsLoading(false);
        setSubmitError(false);
    }, [handleClose]);

    useEffect(() => {
        if (data && onFinish) {
            onFinish(data.createAssessmentForOwner.assessmentId);
            resetModal();
        }
    }, [data, onFinish, resetModal]);

    useEffect(() => {
        if (error) {
            enqueueSnackbar('An error occurred while creating your assessment.', { variant: 'error' });
            resetModal();
        }
    }, [error, enqueueSnackbar, resetModal]);

    return (
        <StyledModal
            aria-labelledby="create-label"
            aria-describedby="create-label"
            open={isOpen}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500
            }}
        >
            <Fade in={isOpen}>
                <ModalBody>
                    <ModalTitle aria-label="create new assessment dialog box">Create New Assessment</ModalTitle>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <StyledTextField
                            fullWidth
                            autoFocus
                            name="title"
                            aria-label="create assessment title field"
                            label="Assessment Title"
                            error={errors.title !== undefined}
                            inputRef={register({
                                required: true,
                                validate: (value) => !!value.trim() || 'At least 1 character is required'
                            })}
                            helperText={errors.title?.message}
                            variant="outlined"
                        />
                        <Buttons>
                            <Button aria-label="cancel assessment creation" onClick={handleClose} color="primary">
                                Cancel
                            </Button>
                            <Button
                                aria-label="create assessment confirm button"
                                type="submit"
                                color="primary"
                                variant="contained"
                                style={{ width: '118px' }}
                                disabled={isLoading}
                            >
                                {isLoading ? <CircularProgress size={24} color={'inherit'} /> : 'Create Assessment'}
                            </Button>
                        </Buttons>
                    </form>
                </ModalBody>
            </Fade>
        </StyledModal>
    );
};

export default CreateSkillModal;
