import React, { ReactElement } from 'react';

import { IconSize, TooltippedIconButton } from '@adept-at/lib-react-components';
import { List, ListItem, Popover } from '@material-ui/core';
import { mdiCheck, mdiSwapVertical } from '@mdi/js';
import Icon from '@mdi/react';
import { usePopover } from 'hooks/usePopover';
import styled from 'styled-components';

import { FavoritesSortBy } from './constants';
import { LayoutViewButton } from './FavoritesLayoutViewButtons';

const StyledListItem = styled(ListItem)`
    color: ${(props) => props.theme.colors.primary};
    display: flex;
    flex-wrap: nowrap;
`;

// width & height of icon: will act as placeholder when not rendering icon
const CheckmarkWrapper = styled.div`
    margin-right: 1rem;
    height: 1.5rem;
    width: 1.5rem;
`;

export const SortByButtons = ({
    isSmallScreen,
    sortBy,
    setSortBy
}: {
    isSmallScreen: boolean;
    sortBy: FavoritesSortBy;
    setSortBy: React.Dispatch<React.SetStateAction<FavoritesSortBy>>;
}): ReactElement => {
    const { open, anchorEl, handlePopoverClick, handlePopoverClose } = usePopover();

    return (
        <>
            {isSmallScreen ? (
                <TooltippedIconButton
                    icon={<Icon path={mdiSwapVertical} />}
                    title={sortBy === FavoritesSortBy.Title ? 'Title' : 'Recently added'}
                    aria-label="Click to switch to grid view"
                    onClick={handlePopoverClick}
                />
            ) : (
                <LayoutViewButton
                    onClick={handlePopoverClick}
                    startIcon={<Icon path={mdiSwapVertical} size={IconSize.Small} />}
                >
                    {sortBy === FavoritesSortBy.Title ? 'Title' : 'Recently added'}
                </LayoutViewButton>
            )}
            <Popover
                id={'favorites-sort-by-options-popover'}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <List>
                    <StyledListItem
                        button
                        onClick={() => {
                            setSortBy(FavoritesSortBy.RecentlyAdded);
                            handlePopoverClose();
                        }}
                    >
                        {
                            <CheckmarkWrapper>
                                {sortBy === FavoritesSortBy.RecentlyAdded ? (
                                    <Icon path={mdiCheck} size={IconSize.Medium} />
                                ) : null}
                            </CheckmarkWrapper>
                        }
                        {FavoritesSortBy.RecentlyAdded}
                    </StyledListItem>
                    <StyledListItem
                        button
                        onClick={() => {
                            setSortBy(FavoritesSortBy.Title);
                            handlePopoverClose();
                        }}
                    >
                        {
                            <CheckmarkWrapper>
                                {sortBy === FavoritesSortBy.Title ? (
                                    <Icon path={mdiCheck} size={IconSize.Medium} />
                                ) : null}
                            </CheckmarkWrapper>
                        }
                        {FavoritesSortBy.Title}
                    </StyledListItem>
                </List>
            </Popover>
        </>
    );
};
