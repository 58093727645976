import { useMemo } from 'react';

import { Author, CollectionAuthor } from 'components/ContentContext/Interfaces';
import { AuthorWithDetails } from 'components/NestedCollection/context/NestedCollectionContext';
import useUserDetails from 'hooks/useUserDetails';

export const useGetCollAuthorsDetails = (
    collectionAuthors: CollectionAuthor[]
): { collectionAuthorsDetails: AuthorWithDetails[] } => {
    const collAuthorIdsMappedToTypes: Map<string, Author[]> | undefined = useMemo(() => {
        if (collectionAuthors.length === 0) {
            return;
        }
        return new Map<string, Author[]>(collectionAuthors.map((author) => [author.userId, author.authorTypes]));
    }, [collectionAuthors]);

    const authorUserIds = useMemo(
        () => (collAuthorIdsMappedToTypes ? Array.from(collAuthorIdsMappedToTypes.keys()) : []),
        [collAuthorIdsMappedToTypes]
    );

    const { usersDetails: collectionAuthorsDetails } = useUserDetails({
        userIds: authorUserIds
    });

    const collAuthorsWithDetails: AuthorWithDetails[] = useMemo(
        () =>
            collectionAuthors
                ? collectionAuthors.map((author) => ({
                      ...collectionAuthorsDetails[author.userId],
                      authorTypes: collAuthorIdsMappedToTypes ? collAuthorIdsMappedToTypes.get(author.userId) ?? [] : []
                  }))
                : [],
        [collectionAuthors, collectionAuthorsDetails, collAuthorIdsMappedToTypes]
    );

    return {
        collectionAuthorsDetails: collAuthorsWithDetails
    };
};
