import React, { useCallback, useContext, useMemo } from 'react';

import { CollectionStats } from '@adept-at/lib-react-components';
import { mdiPencilOff, mdiPencil } from '@mdi/js';
import Icon from '@mdi/react';
import { MarketplaceListingStatus } from 'components/marketplace/hooks/useUpsertListingStatus';
import { AboutHeadings } from 'components/NestedCollection/AboutHeadings';
import { EditCollectionImageActions } from 'components/NestedCollection/ActionMenus';
import { CollectionCatalogImage } from 'components/NestedCollection/CollectionCatalogImage';
import { useCollectionImageContext } from 'components/NestedCollection/context/CollectionImageContext';
import {
    NestedCollectionAboutSection,
    AvatarHeadingContainer,
    ExtraInfoOuterContainer,
    ExtraInfoInnerContainer,
    ExtraInfoItemContainer,
    EditCollectionButton
} from 'components/NestedCollection/NestedCollection.styles';
import { CollectionActions } from 'hooks/authorization';
import { useModal } from 'hooks/useModal';
import { useOrganizationTerminology } from 'hooks/useOrganizationTerminology';

import { AvailabilityButton } from './AvailabilityButton';
import { AvailabilityModal } from './AvailabilityModal';
import { CollectionActionWrapper } from './CollectionActions';
import { NESTED_COLLECTION_CONTENT_ID } from './constants';
import { RootCollectionContext } from './context/RootCollectionContext';

interface AboutSectionProps {
    enrollSectionRef?: React.RefObject<HTMLDivElement>;
    setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
    canEdit: boolean;
}

export const AboutSection: React.FC<AboutSectionProps> = ({ enrollSectionRef }) => {
    const {
        authorizedActions,
        isEditMode,
        isPreviewMode,
        setIsEditMode,
        aboutInfo,
        stats,
        collectionId,
        collectionIdForEditableCollectionQuery,
        marketplaceStatus,
        parentQuerySlug
    } = useContext(RootCollectionContext);

    const { terminology } = useOrganizationTerminology();
    const { handleRemoveCatalogDarkImage } = useCollectionImageContext();
    const { modalOpen, handleModalOpen, handleModalClose } = useModal();

    const canEdit = useMemo(() => authorizedActions.includes(CollectionActions.UpdateMetadata), [authorizedActions]);

    const handleStatClick = useCallback(() => {
        const contentAnchor = document.getElementById(NESTED_COLLECTION_CONTENT_ID);
        if (contentAnchor) {
            contentAnchor.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    /* Need to get `productId` for associated collection. Then can check Payment Authorization to see if this should display.
     * -> For now we will lean on overall "CollectionActions.UpdateMetadata" access.
    const canEditPrice = useMemo(
        () => marketplaceLDFlag && hasRole(OrganizationRolesTypes.SuperAdmin) && !isInternal && isEditMode,
        [marketplaceLDFlag, hasRole, isEditMode, isInternal]
    ); */

    return (
        <NestedCollectionAboutSection id="collection-about-info">
            <AvatarHeadingContainer>
                <CollectionCatalogImage />
                <EditCollectionImageActions
                    isEditMode={isEditMode}
                    collectionTitle={aboutInfo?.title ?? ''}
                    handleRemoveDarkImage={handleRemoveCatalogDarkImage}
                />
                <AboutHeadings
                    aboutInfo={aboutInfo}
                    collectionId={collectionId}
                    isEditMode={isEditMode}
                    collectionIdForEditableCollectionQuery={collectionIdForEditableCollectionQuery}
                    parentQuerySlug={parentQuerySlug}
                    hideBrandingLabel
                />
            </AvatarHeadingContainer>
            {isPreviewMode || !isEditMode ? <CollectionActionWrapper ref={enrollSectionRef} /> : null}
            <ExtraInfoOuterContainer>
                <ExtraInfoInnerContainer>
                    <ExtraInfoItemContainer>
                        {canEdit ? (
                            <>
                                <AvailabilityButton
                                    onClick={handleModalOpen}
                                    outlined
                                    isListed={marketplaceStatus === MarketplaceListingStatus.listed}
                                />
                                <AvailabilityModal open={modalOpen} onClose={handleModalClose} />
                            </>
                        ) : null}
                    </ExtraInfoItemContainer>
                    <ExtraInfoItemContainer>
                        {!isPreviewMode && canEdit ? (
                            <EditCollectionButton onClick={() => setIsEditMode((prev) => !prev)}>
                                <Icon path={isEditMode ? mdiPencilOff : mdiPencil} />
                                {isEditMode ? 'Stop editing' : 'Edit'}
                            </EditCollectionButton>
                        ) : null}
                    </ExtraInfoItemContainer>
                </ExtraInfoInnerContainer>
            </ExtraInfoOuterContainer>

            <CollectionStats stats={stats ?? []} onClick={handleStatClick} labelOverrides={{ ...terminology }} />
        </NestedCollectionAboutSection>
    );
};
