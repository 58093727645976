export const LOWERCASE_ALPHANUM_CHARSET = '0123456789abcdefghijklmnopqrstuvwxyz';
export const LOWERCASE_ALPHANUM_RADIX = 36;

export const ALPHANUM_CHARSET = `${LOWERCASE_ALPHANUM_CHARSET}ABCDEFGHIJKLMNOPQRSTUVWXYZ`;

/** Creates a single letter of number, lowercase, random. */
export const buildRandomLowercaseAlphanum = (): string =>
    ((Math.random() * LOWERCASE_ALPHANUM_RADIX) | 0).toString(LOWERCASE_ALPHANUM_RADIX);

/** Creates an id with a prefix of prescribed length, lowercase alphanum */
export const buildRandomIdWithPrefix = (prefix: string, length = 10): string =>
    `${prefix}-${[...Array(length)].map((_) => buildRandomLowercaseAlphanum()).join('')}`;

/** Creates an id of prescribed length, uppercase alphanum */
export const buildRandomAlphanum = (length = 8): string => {
    let random = '';

    for (let i = length; i > 0; --i) {
        random += ALPHANUM_CHARSET[Math.floor(Math.random() * ALPHANUM_CHARSET.length)];
    }

    return random;
};

/** Creates an id of random length, lowercase alphanum */
export const buildRandomNodeId = (): string =>
    Math.random().toString(LOWERCASE_ALPHANUM_RADIX).replace('0.', '').substr(3);
