import React, { useState, useContext, useRef } from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { TextField, IconButton, Button } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { mdiArrowLeft } from '@mdi/js';
import MdiIcon from '@mdi/react';
import { EntityInfo, OwnerType } from 'components/ContentContext/Interfaces';
import { Control } from 'components/CreateModal.styles';
import Loading from 'components/Loading';
import MutationError from 'components/MutationError';
import { ProfileContext, ProfileOption, ProfileType } from 'context/ProfileContext';
import { QueryKey } from 'react-query';
import styled from 'styled-components';

import useTransferManyEntityOwnership, {
    useTransferManyEntityOwnershipProps,
    TransferManyEntityOwnershipProps
} from '../hooks/useTransferManyEntityOwnership';
import { ModalTitle } from '../SharingModal';

const FlexContainer = styled.div`
    display: flex;
    align-items: flex-end;
`;

const FlexFooter = styled.div`
    display: flex;
    position: absolute;
    right: 0;
    bottom: 0;
    justify-content: flex-end;
    margin-bottom: 6px;
`;

const Disclaimer = styled.div`
    margin: 0 1rem 1rem 1rem;
`;

const DetailsFields = styled.div`
    width: 100%;
    margin-top: 1rem;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    margin-right: 30px;
`;

interface TransferFormProps extends useTransferManyEntityOwnershipProps {
    onFinished: () => void;
    onBackClick: () => void;
    entities: EntityInfo[];
    contentQueryKey?: QueryKey;
    contentTransferOptimistCallback?: <T>(previous: T, transferQueryProps: TransferManyEntityOwnershipProps) => T;
}

const TransferForm: React.FC<TransferFormProps> = ({
    onFinished,
    onBackClick,
    contentQueryKey,
    contentTransferOptimistCallback,
    entities
}) => {
    const { availableProfiles } = useContext(ProfileContext);

    const { transferManyEntityOwnership, isLoading, error } = useTransferManyEntityOwnership({
        onFinished: () => {
            onFinished();
        },
        contentQueryKey,
        contentTransferOptimistCallback
    });
    const [selectedOrganization, setSelectedOrganization] = useState<ProfileOption>();

    const [inputValue, setInputValue] = useState('');

    const autocomplete = useRef<HTMLElement>(null);

    const onSubmit = async () => {
        if (!selectedOrganization) return;

        const params = { newOwner: { type: OwnerType.Organization, id: selectedOrganization.id }, entities };

        transferManyEntityOwnership(params);
    };
    const allOrgs = availableProfiles?.filter(({ type }) => type === ProfileType.Organization);

    return (
        <>
            <FlexContainer>
                <IconButton aria-label="back-arrow" onClick={onBackClick} color={'primary'}>
                    <MdiIcon path={mdiArrowLeft} size={IconSize.Medium} />
                </IconButton>
                <ModalTitle aria-label="transfer ownership form">Transfer Ownership</ModalTitle>
            </FlexContainer>
            <form aria-busy={isLoading}>
                {isLoading && <Loading />}
                <MutationError error={error} setError={() => {}} />
                <DetailsFields>
                    <Control>
                        <Autocomplete
                            id="organization"
                            fullWidth
                            autoComplete
                            ref={autocomplete}
                            filterOptions={(x) => x}
                            getOptionLabel={(option) => option?.label}
                            getOptionSelected={(option, value) => option.label === value.label}
                            filterSelectedOptions
                            options={allOrgs}
                            onChange={(_, newValue: ProfileOption | null) => {
                                if (newValue) {
                                    setSelectedOrganization(newValue);
                                }
                            }}
                            onInputChange={(_, newInputValue) => {
                                setInputValue(newInputValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Select a new owner"
                                    inputProps={{ ...params.inputProps, value: inputValue }}
                                    label="Select a new owner"
                                    name="alias"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>
                                    }}
                                />
                            )}
                        />
                    </Control>
                </DetailsFields>
            </form>

            <Disclaimer>
                By transferring ownership, you are granting all intellectual content rights to the new owner. This
                content will no longer be associated to your profile. Current viewers and collaborators will lose access
                to this content, you will remain the content author with editing privileges. The new owner may change
                your privileges at any time.
            </Disclaimer>
            <FlexFooter>
                <Button color="primary" onClick={onBackClick}>
                    Cancel
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    disabled={!selectedOrganization || isLoading}
                    onClick={onSubmit}
                >
                    Transfer ownership
                </Button>
            </FlexFooter>
        </>
    );
};

export default TransferForm;
