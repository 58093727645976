import React, { useMemo } from 'react';

import {
    QuestionViewer,
    QuestionComponentValues,
    QuestionViewerStatus,
    QuestionType
} from '@adept-at/lib-react-components';

import { Container } from './QuestionPreview.styles';

export interface QuestionPreviewProps {
    component: QuestionComponentValues;
}

export const QuestionPreview: React.FC<QuestionPreviewProps> = ({ component }) => {
    const answer = useMemo(() => {
        switch (component.questionType) {
            case QuestionType.Blank:
                return component.blankAnswers.join(', ');
            case QuestionType.Checkbox:
                return component.checkboxAnswers;
            case QuestionType.Choice:
                return component.choiceAnswer;
            case QuestionType.Order:
                const orderAnswer = {};
                component.answerOptions.map((option, index) => {
                    orderAnswer[option.key] = `${index}`;
                });
                return orderAnswer;
            case QuestionType.Match:
                const matchAnswer = {};
                component.answerOptions.forEach((option) => {
                    const componentValueKey = option?.key;
                    const match = component.matchingPairs[componentValueKey];
                    matchAnswer[componentValueKey] = match.key;
                });
                return matchAnswer;
            case QuestionType.Boolean:
                return String(component.booleanAnswer);

            default:
                return;
        }
    }, [
        component.questionType,
        component.answerOptions,
        component.blankAnswers,
        component.booleanAnswer,
        component.checkboxAnswers,
        component.choiceAnswer,
        component.matchingPairs
    ]);

    return (
        <>
            <form data-testid={'question-view-form'} onSubmit={() => {}}>
                <QuestionViewer.Question.Factory
                    disabled={component.questionType === QuestionType.Order}
                    componentValues={component}
                    answer={answer}
                    setAnswer={() => {}}
                />
                <Container>
                    <QuestionViewer.SubmitButton
                        status={QuestionViewerStatus.Initial}
                        disabled={true}
                        type="submit"
                        data-testid="questionView-submit"
                    />
                </Container>
            </form>
        </>
    );
};

export default QuestionPreview;
