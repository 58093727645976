import React from 'react';

import { mdiAccountCheck, mdiCircle } from '@mdi/js';

import { NavHeader, NavTitle, WideDivider } from './Nav.styles';
import NavCollapse from './NavCollapse';
import NavLink from './NavLink';

const NavSubscriptions: React.FC = () => {
    return (
        <div>
            <NavHeader>
                <NavTitle>Subscriptions</NavTitle>
            </NavHeader>
            <NavCollapse title="My Subscriptions" iconPath={mdiAccountCheck}>
                <NavLink depth={1} label={'Treehouse'} iconPath={mdiCircle} />
                <NavLink depth={1} label={'Pluralsight'} iconPath={mdiCircle} />
            </NavCollapse>
            <WideDivider />
            <NavCollapse title="From CBT Nuggets sdf s d" iconPath={mdiCircle}>
                <NavLink depth={1} label={'Corporate Knowledge'} iconPath={mdiCircle} />
                <NavLink depth={1} label={'LinkedIn Learning'} iconPath={mdiCircle} />
            </NavCollapse>
            <WideDivider />
            <NavCollapse title="From Adept" iconPath={mdiCircle}>
                <NavLink depth={1} label={'Corporate Knowledge'} iconPath={mdiCircle} />
                <NavLink depth={1} label={'Pluralsight'} iconPath={mdiCircle} />
            </NavCollapse>
            <WideDivider />
        </div>
    );
};

export default NavSubscriptions;
