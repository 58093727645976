import React, { useContext, useMemo } from 'react';

import {
    sortComponentsByOrder,
    CommonComponentValues,
    IconSize,
    ComponentValuesType,
    QuestionComponentValues,
    QuestionType
} from '@adept-at/lib-react-components';
import { Button } from '@material-ui/core';
import { mdiBullseyeArrow, mdiArrowDown, mdiFlagCheckered } from '@mdi/js';
import Icon from '@mdi/react';
import styled from 'styled-components';

import { LearnContext } from '../../../LearnContext';
import { timeFromWordCount } from '../../../utils';
import { useAccountabilityContext } from '../AccountabilityContext';

import { CountdownTimer } from './CountdownTimer';

const StyledTimer = styled.div`
    display: flex;
    align-items: center;
    border-radius: 5px;
    background-color: #d9ebf5;
    padding: 0;

    margin-top: 30px;
`;

const StyledIcon = styled(Icon)`
    color: ${(props) => props.theme.colors.primaryLighter};
    margin: 4px 9px;
`;

const StyledProceedSpan = styled.span`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    font-size: ${(props) => props.theme.ms(0)};
`;

const StyledTextSpan = styled.span`
    padding-top: 3px;
`;

const StyledInfoSpan = styled.span`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    margin-right: 9px;
    color: ${(props) => props.theme.colors.text};
    font-size: ${(props) => props.theme.ms(0)};
`;

const StyledButton = styled(Button)`
    color: ${(props) => props.theme.colors.primaryLighter};
    margin: 0;
    padding: 0 0 0 18px;
    border-radius: 0;
    font-size: ${(props) => props.theme.ms(0)};
    &:hover {
        background-color: unset;
    }
`;

const StyledCompletedText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const NavContainer: React.FC = ({ children }) => {
    return (
        <StyledTimer>
            <StyledIcon size={IconSize.Medium} path={mdiBullseyeArrow} />
            {children}
        </StyledTimer>
    );
};

interface AccountabilityNavProps {
    componentValues: CommonComponentValues;
    componentId: string;
    componentType: ComponentValuesType;
}

const DEFAULT_COMPONENT_TIME = 3;

const AccountabilityNav: React.FC<AccountabilityNavProps> = ({ componentValues, componentId, componentType }) => {
    const { completeComponent, readyToProceed, skillIsComplete, finalComponentId } = useAccountabilityContext();
    const { skill } = useContext(LearnContext);

    const percentComplete = () => {
        const components = sortComponentsByOrder(skill.components);

        let order = 1;

        components?.forEach((comp, index) => {
            if (comp.id === componentId) {
                order = index + 1;
            }
        });

        const percent = Math.round((order / components.length) * 100);

        return percent;
    };

    const hasAnsweredPoll = () =>
        componentType === ComponentValuesType.Question &&
        (componentValues as QuestionComponentValues).pollResponses?.totalResponses;

    const calculateTime = (componentValues, componentType) => {
        switch (componentType) {
            case ComponentValuesType.RichText:
                return timeFromWordCount(componentValues);
            case ComponentValuesType.Video:
            case ComponentValuesType.ScreenCapture:
            case ComponentValuesType.TalkingHead:
                return componentValues.duration ? Math.round(componentValues.duration) : DEFAULT_COMPONENT_TIME;
            default:
                return DEFAULT_COMPONENT_TIME;
        }
    };

    const seconds = useMemo(() => calculateTime(componentValues, componentType), [componentValues, componentType]);

    if (skillIsComplete) {
        return (
            <NavContainer>
                <StyledProceedSpan>
                    <StyledTextSpan>{percentComplete()}% complete</StyledTextSpan>

                    <StyledCompletedText>
                        <StyledTextSpan>You are incredible!</StyledTextSpan>
                        <StyledIcon size={IconSize.Medium} path={mdiFlagCheckered} />
                    </StyledCompletedText>
                </StyledProceedSpan>
            </NavContainer>
        );
    }

    if (readyToProceed || hasAnsweredPoll()) {
        return (
            <NavContainer>
                <StyledProceedSpan>
                    <span>{percentComplete()}% complete</span>
                    <StyledButton onClick={() => completeComponent(componentId)} variant="text">
                        {finalComponentId === componentId ? 'Complete skill' : 'Proceed to the next section'}
                        <StyledIcon size={IconSize.Medium} path={mdiArrowDown} />
                    </StyledButton>
                </StyledProceedSpan>
            </NavContainer>
        );
    }

    if (componentType === ComponentValuesType.Question) {
        const questionType = (componentValues as QuestionComponentValues).questionType;

        return (
            <NavContainer>
                <StyledInfoSpan>
                    {`Accountability mode is enabled. Please answer the ${
                        questionType === QuestionType.Poll ? 'poll' : 'question correctly'
                    } to continue.`}
                </StyledInfoSpan>
            </NavContainer>
        );
    }

    return (
        <NavContainer>
            <CountdownTimer initialTimeInSeconds={seconds} />
        </NavContainer>
    );
};

export default AccountabilityNav;
