import { gql, GraphQLClient } from 'graphql-request';
import { QueryFunction } from 'react-query';

import buildGqlQueryFn from '../../../../../../../../hooks/useGqlClient/helpers/buildGqlQueryFn';

export interface GetRecorderDownloadUrlQueryResult {
    getAdeptRecorderDownloadURL: string;
}

const GET_ADEPT_DOWNLOAD_QUERY = gql`
    query getAdeptRecorderDownloadURL($userAgent: String!) {
        getAdeptRecorderDownloadURL(userAgent: $userAgent)
    }
`;

export const makeGetRecorderDownloadUrl = (
    client: GraphQLClient,
    variables: { userAgent: string }
): QueryFunction<GetRecorderDownloadUrlQueryResult> =>
    buildGqlQueryFn<GetRecorderDownloadUrlQueryResult>(client, GET_ADEPT_DOWNLOAD_QUERY, variables);
