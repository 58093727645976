import React from 'react';

/* eslint-disable */
const SkillBuilderIcon = props => (
    <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 50 50"
        enableBackground="new 0 0 50 50"
    >
        <g>
            <path
                fill="currentColor"
                d="M22.776,31.805l-4.908,5.012l-2.356-2.356l4.909-5.012c0.488-0.488,0.488-1.279,0-1.768   s-1.279-0.488-1.768,0l-5.792,5.896l-2.838,2.838c-0.58,0.579-0.899,1.35-0.899,2.17c0,0.819,0.319,1.59,0.899,2.169l1.553,1.553   c0.58,0.58,1.35,0.898,2.169,0.898c0.82,0,1.59-0.319,2.169-0.898l2.839-2.838l5.792-5.896c0.488-0.488,0.488-1.279,0-1.768   S23.264,31.317,22.776,31.805z M14.145,40.539c-0.145,0.145-0.313,0.166-0.401,0.166c-0.087,0-0.256-0.021-0.401-0.166   l-1.553-1.553c-0.145-0.145-0.167-0.313-0.167-0.401s0.022-0.257,0.167-0.401l1.955-1.955l2.356,2.356L14.145,40.539z"
            />
            <path
                fill="currentColor"
                d="M30.847,19.125l5.155-5.155l2.356,2.356l-5.155,5.155c-0.488,0.488-0.488,1.279,0,1.768   c0.244,0.244,0.563,0.366,0.884,0.366s0.64-0.122,0.884-0.366l5.693-5.694c0.354-0.354,0.624-0.771,0.804-1.236l2.411-6.255   c0.178-0.461,0.067-0.984-0.282-1.333c-0.351-0.35-0.873-0.459-1.334-0.283l-6.255,2.413c-0.467,0.18-0.883,0.451-1.236,0.805   l-5.692,5.692c-0.488,0.488-0.488,1.279,0,1.768S30.359,19.614,30.847,19.125z M40.533,11.795l-0.882,2.288l-1.406-1.406   L40.533,11.795z"
            />
            <path
                fill="currentColor"
                d="M44.708,37.639L22.705,15.634l4.743-4.743c0.488-0.488,0.488-1.279,0-1.768L24.794,6.47   c-0.488-0.488-1.279-0.488-1.768,0c-1.096,1.096-3.005,1.096-4.101,0c-0.234-0.234-0.552-0.366-0.884-0.366   s-0.649,0.132-0.884,0.366l-1.809,1.809l-1.671-1.671c-0.488-0.488-1.279-0.488-1.768,0l-4.124,4.124   c-0.234,0.234-0.366,0.552-0.366,0.884s0.132,0.649,0.366,0.884l1.672,1.672l-4.166,4.166c-0.488,0.488-0.488,1.279,0,1.768   l5.588,5.588c0.244,0.244,0.564,0.365,0.884,0.365s0.64-0.121,0.884-0.365l4.166-4.167L38.818,43.53   c0.234,0.234,0.553,0.366,0.884,0.366c0.332,0,0.649-0.132,0.884-0.366l4.123-4.124C45.197,38.918,45.197,38.127,44.708,37.639z    M10.438,11.615l2.356-2.356l0.788,0.788l-2.356,2.356L10.438,11.615z M11.763,23.041l-3.82-3.82l3.282-3.282l1.768-1.768   l2.356-2.356l1.768-1.768l1.027-1.027c1.685,1.042,3.977,1.042,5.664,0l0.988,0.988l-3.859,3.859l-1.768,1.768l-2.356,2.356   l-1.768,1.768L11.763,23.041z M39.702,40.879L18.581,19.758l2.356-2.356l21.12,21.12L39.702,40.879z"
            />
        </g>
    </svg>
);

export default SkillBuilderIcon;
