import { useState, useMemo, useEffect, useCallback, Dispatch } from 'react';

import { useCurrentUser } from 'hooks/useCurrentUser';

import { useAuth, AuthStatus } from '../AuthContext';

import { getProfileStorage, setProfileStorage, clearProfileStorage } from './profileContextStorage';
import { AvailableProfilesUpdateAction, AvailableProfilesUpdate } from './useAvailableProfiles';

import { ProfileType, ProfileOption } from '.';

export interface ProfileSelection {
    type: ProfileType;
    id: string;
    alias: string;
}

interface UseCurrentProfileOptions {
    availableProfiles: ProfileOption[];
    setAvailableProfiles: Dispatch<AvailableProfilesUpdate>;
}

export interface UseCurrentProfileOutput {
    currentProfile: ProfileOption | null;
    selectProfile: (profileSelection: ProfileSelection | null) => void;
}

const useCurrentProfile = ({
    availableProfiles,
    setAvailableProfiles
}: UseCurrentProfileOptions): UseCurrentProfileOutput => {
    const { status: authStatus } = useAuth();
    const { currentUser } = useCurrentUser();

    const [desiredProfileSelection, setDesiredProfileSelection] = useState<ProfileSelection | null>(null);

    const currentProfile = useMemo(() => {
        if (!desiredProfileSelection) {
            return null;
        }

        return (
            availableProfiles?.find((availableProfile) => {
                const { id, type, alias } = availableProfile;
                const { id: selectionId, type: selectionType, alias: selectionAlias } = desiredProfileSelection;

                // Allow selections to match the alias now in case ID isn't handy.
                return (id === selectionId || alias === selectionAlias) && type === selectionType;
            }) ?? null
        );
    }, [availableProfiles, desiredProfileSelection]);

    // On mount, we want to make sure the current profile is pulled from local storage and set here
    useEffect(() => {
        const currentProfileFromStorage = getProfileStorage();

        if (!currentProfileFromStorage) {
            return;
        }

        const { id, type, alias } = currentProfileFromStorage;

        setDesiredProfileSelection({ id, type, alias });
    }, []);

    useEffect(() => {
        if (currentProfile) {
            setProfileStorage(currentProfile);
        }
    }, [currentProfile]);

    const reset = useCallback(() => {
        setDesiredProfileSelection(null);
        setAvailableProfiles({ action: AvailableProfilesUpdateAction.Clear, options: [] });
        clearProfileStorage();
    }, [setAvailableProfiles]);

    useEffect(() => {
        /*
         * Handle case where already logged out --> we need to make sure profile in local storage cleared
         */
        if (authStatus === AuthStatus.Unauthenticated) {
            reset();
            return;
        }

        if (currentUser && currentUser.userId && currentUser.alias && !currentProfile) {
            setDesiredProfileSelection(
                (prevDesiredProfileSelection) =>
                    prevDesiredProfileSelection ??
                    ({ type: ProfileType.Personal, id: currentUser.userId } as ProfileSelection)
            );
        }
    }, [currentProfile, currentUser, desiredProfileSelection, reset, authStatus]);

    return { currentProfile, selectProfile: setDesiredProfileSelection };
};

export default useCurrentProfile;
