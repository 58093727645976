import React from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import Fab from '@material-ui/core/Fab';
import { mdiHammerWrench } from '@mdi/js';
import Icon from '@mdi/react';
import styled from 'styled-components';

const ButtonContainer = styled.div`
    position: fixed;
    top: 50%;
    right: 5px;
    z-index: 1500;
`;

const StyledIcon = styled(Icon)`
    margin-right: 3px;
`;

interface DevToolsButtonProps {
    onClick?: () => void;
}

export const DevToolsButton: React.FC<DevToolsButtonProps> = ({ onClick = () => {} }) => (
    <ButtonContainer>
        <Fab variant="extended" color="secondary" size="small" aria-label="dev tools" onClick={onClick}>
            <StyledIcon path={mdiHammerWrench} size={IconSize.Small} />
        </Fab>
    </ButtonContainer>
);
