import { useMemo } from 'react';

import { Author, SkillAuthor } from 'components/ContentContext/Interfaces';
import useUserDetails, { UserDetails } from 'hooks/useUserDetails';

type SkillCollaborator = SkillAuthor & UserDetails & { authorTypes: Author[] };

export const useSkillCollaborators = (authors: SkillAuthor[]): SkillCollaborator[] => {
    const userIds = useMemo(() => (authors ?? []).map((author) => author.userId), [authors]);
    const { usersDetails } = useUserDetails({ userIds });

    const collaborators = useMemo(() => {
        return (authors ?? []).map((author) => ({
            ...author,
            ...usersDetails[author.userId],
            authorTypes: [author.authorType]
        }));
    }, [authors, usersDetails]);

    return collaborators;
};
