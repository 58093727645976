import React from 'react';

import { CodeComponentValues, CodeViewerSupportedLanguagesDetails } from '@adept-at/lib-react-components';
import { FormControl, Select, MenuItem, InputLabel } from '@material-ui/core';
import { highlight, languages } from 'prismjs';

import { useComponentEngineComponent } from '../../';
import { useEditableComponent, ComponentFocus, EditButtons } from '../../mixins/editor';
import { useFocusableComponent } from '../../mixins/focusable';

import { StyledEditor, CodeEditForm } from './Edit.styles';
import CodeView from './View';

import 'prismjs/themes/prism.css';

/**
 * Thanks to:
 * https://codesandbox.io/s/charming-moore-wy240?fontsize=14&hidenavigation=1&theme=dark&file=/src/index.js:412-608
 **/
const highlightWithLineNumbers = (input = '', language) => {
    try {
        return highlight(input, languages[language], language)
            .split('\n')
            .map((line: string, i: number) => `<span class='editorLineNumber'>${i + 1}</span>${line}`)
            .join('\n');
    } catch (e) {
        console.error('Unable to highlight code:', input);

        return input;
    }
};

interface EmbeddedCodeComponentValues {
    codeBody?: string;
}

interface CodeEditProps {
    onCancel?: () => void;
    onSave?: (values: Partial<CodeComponentValues>) => void;
}

const CodeEdit: React.FC<CodeEditProps> = ({ onCancel, onSave }) => {
    const { id } = useComponentEngineComponent();

    const { doesComponentHaveAnyFocus } = useFocusableComponent();

    const {
        currentValues: { body, codeBody, language },
        onSave: defaultOnSave,
        onCancel: defaultOnCancel,
        onChange
    } = useEditableComponent<CodeComponentValues & EmbeddedCodeComponentValues>();
    if (!doesComponentHaveAnyFocus(id, [ComponentFocus.Edit, ComponentFocus.AddAndEdit])) {
        return <CodeView body={body} language={language} />;
    }

    const save = () => {
        if (onSave) {
            return onSave({ body: codeBody, language });
        }

        defaultOnSave();
    };

    return (
        <CodeEditForm aria-label={'code-edit-form'}>
            <label id="code-area-label" aria-label="code-input-area">
                <StyledEditor
                    value={codeBody ?? body ?? ''}
                    onValueChange={codeBody !== undefined ? onChange('codeBody') : onChange('body')}
                    highlight={(code) => highlightWithLineNumbers(code, 'javascript')}
                    padding={10}
                    textareaId="codeArea"
                    data-testid={'code-input-area'}
                />
            </label>
            <FormControl style={{ minWidth: '120px' }}>
                <InputLabel id="language-select-label">Language</InputLabel>
                <Select
                    value={language || ''}
                    id="language-select"
                    labelId="language-select-label"
                    onChange={(e) => {
                        onChange('language')(e.target.value as string);
                    }}
                    label="Language"
                    data-testid={'code-language-select'}
                >
                    <MenuItem value="" disabled>
                        Select Language
                    </MenuItem>
                    {Object.values(CodeViewerSupportedLanguagesDetails).map(({ label, grammar }) => {
                        return (
                            <MenuItem key={grammar} value={grammar} data-testid={`code-language-${label}`}>
                                {label}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            <EditButtons onCancel={onCancel ?? defaultOnCancel} onSave={save} />
        </CodeEditForm>
    );
};

export default CodeEdit;
