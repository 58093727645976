import React, { useEffect, useState } from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { Chip, FormControl, TextField } from '@material-ui/core';
import { mdiFormatListBulleted } from '@mdi/js';
import Icon from '@mdi/react';
import Modal from 'components/modals';
import styled from 'styled-components';

interface SectionDescriptionModalProps {
    open: boolean;
    onClose: () => void;
    title: string;
    onConfirm: (description: string) => void;
    description?: string;
}

const StyledChip = styled(Chip)`
    margin-top: -2rem;
`;

const StyledFormControl = styled(FormControl)`
    margin-top: 1rem;
    width: 100%;
`;

export const SectionDescriptionModal: React.FC<SectionDescriptionModalProps> = ({
    open,
    onClose,
    title,
    description = '',
    onConfirm
}) => {
    const [sectionDescription, setSectionDescription] = useState(description);

    useEffect(() => {
        if (open) {
            setSectionDescription(description);
        }
    }, [open, description]);

    return (
        <Modal
            open={open}
            onClose={onClose}
            onClick={(e) => e.stopPropagation()}
            modalTitle={`${description ? 'Edit' : 'Add'} section description`}
            confirmButtonText="Save"
            onConfirm={() => {
                onConfirm(sectionDescription);
                onClose();
            }}
        >
            <>
                <StyledChip
                    size="small"
                    icon={<Icon path={mdiFormatListBulleted} size={IconSize.Small} />}
                    label={title}
                    variant="outlined"
                />
                <StyledFormControl>
                    <TextField
                        label="Section description"
                        multiline
                        variant="outlined"
                        value={sectionDescription}
                        onChange={(event) => setSectionDescription(event.currentTarget.value)}
                        rows={4}
                    />
                </StyledFormControl>
            </>
        </Modal>
    );
};
