import { GraphQLClient } from 'graphql-request';
import gql from 'graphql-tag';
import { MutationFunction } from 'react-query';

import buildGqlMutationFn from '../../../../hooks/useGqlClient/helpers/buildGqlMutationFn';

export const REMOVE_COMPONENT_MUTATION = gql`
    mutation removeComponent($entity: EntityInfoInput!, $componentId: Alphanumeric!) {
        removeComponent(entity: $entity, componentId: $componentId) {
            success
        }
    }
`;

const makeRemoveComponent = <TData, TVariables>(client: GraphQLClient): MutationFunction<TData, TVariables> =>
    buildGqlMutationFn<TData, TVariables>(client, REMOVE_COMPONENT_MUTATION);

export default makeRemoveComponent;
