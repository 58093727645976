import {
    CodeComponentValues,
    ImageComponentValues,
    VirtualLabComponentValues,
    QuestionComponentValues,
    VideoComponentValues,
    AttachmentComponentValues,
    LinkComponentValues
} from '@adept-at/lib-react-components';
import { EditorState, ContentBlock, Modifier, SelectionState, ContentState } from 'draft-js';
import { Map, List } from 'immutable';
import { buildRandomAlphanum } from 'utils/ids';

export const HANDLED = 'handled';
export const NOT_HANDLED = 'not-handled';

export enum DraftJsEntity {
    LINK = 'LINK',
    VIDEO = 'VIDEO',
    IMAGE = 'IMAGE',
    CODE = 'CODE',
    LAB = 'LAB',
    ATTACHMENT = 'ATTACHMENT',
    DIVIDER = 'DIVIDER',
    QUESTION = 'QUESTION'
}

export enum DraftJsBlock {
    UNSTYLED = 'unstyled',
    OL = 'ordered-list-item',
    UL = 'unordered-list-item',
    H1 = 'header-one',
    ATOMIC = 'atomic',
    BLOCKQUOTE = 'blockquote',
    CODE = 'code-block'
}

export enum DraftJsInlineStyle {
    BOLD = 'BOLD',
    ITALIC = 'ITALIC',
    UNDERLINE = 'UNDERLINE',
    STRIKETHROUGH = 'STRIKETHROUGH',
    CODE = 'CODE'
}

export enum EditorChangeType {
    INSERT_CHARACTERS = 'insert-characters',
    APPLY_ENTITY = 'apply-entity',
    SPLIT_BLOCK = 'split-block',
    CHANGE_BLOCK_TYPE = 'change-block-type',
    REMOVE_RANGE = 'remove-range'
}

export const continuousDraftJsBlocks = [DraftJsBlock.UNSTYLED, DraftJsBlock.OL, DraftJsBlock.UL, DraftJsBlock.CODE];

/*
    Used from [react-rte](https://github.com/sstur/react-rte/blob/master/src/lib/insertBlockAfter.js)
    by [sstur](https://github.com/sstur)
*/
export const addNewBlockAt = (editorState, pivotBlockKey) => {
    const content = editorState.getCurrentContent();
    const blockMap = content.getBlockMap();
    const block = blockMap.get(pivotBlockKey);
    if (!block) {
        throw new Error(`The pivot key - ${pivotBlockKey} is not present in blockMap.`);
    }
    const blocksBefore = blockMap.toSeq().takeUntil((v) => v === block);
    const blocksAfter = blockMap
        .toSeq()
        .skipUntil((v) => v === block)
        .rest();
    const newBlockKey = buildRandomAlphanum();

    const newBlock = new ContentBlock({
        key: newBlockKey,
        type: DraftJsBlock.UNSTYLED,
        text: '',
        characterList: List(),
        depth: 0,
        data: Map({})
    });

    const newBlockMap = blocksBefore
        .concat(
            [
                [pivotBlockKey, block],
                [newBlockKey, newBlock]
            ],
            blocksAfter
        )
        .toOrderedMap();

    const selection = editorState.getSelection();

    const newContent = content.merge({
        blockMap: newBlockMap,
        selectionBefore: selection,
        selectionAfter: selection.merge({
            anchorKey: newBlockKey,
            anchorOffset: 0,
            focusKey: newBlockKey,
            focusOffset: 0,
            isBackward: false
        })
    });

    return EditorState.push(editorState, newContent, EditorChangeType.SPLIT_BLOCK);
};

export const getEntityInBlock = (currentBlock: ContentBlock, start: number, end: number): string | undefined => {
    let entityId;
    //https://github.com/jpuri/draftjs-utils/blob/9e96939aa4a41bb89ad57f8c71c6a8c27efb76f8/js/inline.js#L84
    if (start === end && start === 0) {
        end = 1;
    } else if (start === end) {
        start -= 1;
    }
    console.log(start, end);
    for (let i = start; i <= end; i += 1) {
        const currentEntity = currentBlock.getEntityAt(i);
        if (!currentEntity) {
            entityId = undefined;
            break;
        }
        if (i === start) {
            entityId = currentEntity;
        } else if (entityId !== currentEntity) {
            entityId = undefined;
            break;
        }
    }
    //last ditch effort if selection isnt on an entity
    if (!entityId) {
        currentBlock.findEntityRanges(
            () => true,
            (rangeStart, rangeEnd) => {
                if (start >= rangeStart && end <= rangeEnd) entityId = currentBlock.getEntityAt(start);
            }
        );
    }
    console.log(entityId);
    return entityId;
};

export const getSelectedText = (
    contentState: ContentState,
    selection: SelectionState,
    blockDelimiter = '\n'
): { text: string; multiLine: boolean } => {
    blockDelimiter = blockDelimiter || '\n';
    const startKey = selection.getStartKey();
    const endKey = selection.getEndKey();
    const blocks = contentState.getBlockMap();

    let lastWasEnd = false;
    const selectedBlock = blocks
        .skipUntil((block) => {
            if (!block) return false;
            return block.getKey() === startKey;
        })
        .takeUntil((block) => {
            const result = lastWasEnd;
            if (!block) return false;
            if (block.getKey() === endKey) {
                lastWasEnd = true;
            }

            return result;
        });

    const multiLine = selectedBlock.size > 1;
    const text = selectedBlock
        .map((block) => {
            if (!block) return;
            const key = block.getKey();
            let blockText = block.getText();

            let start = 0;
            let end = blockText.length;

            if (key === startKey) {
                start = selection.getStartOffset();
            }
            if (key === endKey) {
                end = selection.getEndOffset();
            }

            blockText = blockText.slice(start, end);
            return blockText;
        })
        .join(blockDelimiter);
    return { text, multiLine };
};

export const updateEntityText = (editorState: EditorState, entityId: string, text: string): ContentState => {
    const selection = editorState.getSelection();
    const anchorKey = selection.getAnchorKey();
    const currentBlock = editorState.getCurrentContent().getBlockForKey(anchorKey);
    let entityRange;

    currentBlock.findEntityRanges(
        (value) => value.getEntity() === entityId,
        (start, end) => {
            entityRange = {
                start,
                end,
                text: currentBlock.get('text').slice(start, end)
            };
        }
    );

    const newSelectionMap = selection
        .set('anchorOffset', entityRange.start)
        .set('anchorKey', anchorKey)
        .set('focusKey', anchorKey)
        .set('focusOffset', entityRange.end);

    const newSelection = new SelectionState(newSelectionMap);

    const newContent = Modifier.replaceText(editorState.getCurrentContent(), newSelection, text, undefined, entityId);
    return newContent;
};

export const resetBlockWithType = (editorState) => {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const key = selectionState.getStartKey();
    const blockMap = contentState.getBlockMap();
    const block = blockMap.get(key);
    const newBlock = block.mergeDeep(
        {},
        {
            type: DraftJsBlock.UNSTYLED,
            data: {}
        }
    );
    const newContentState = contentState.merge({
        blockMap: blockMap.set(key, newBlock),
        selectionAfter: selectionState.merge({
            anchorOffset: 0,
            focusOffset: 0
        })
    });
    return EditorState.push(editorState, newContentState, EditorChangeType.CHANGE_BLOCK_TYPE);
};

export type EmbeddedEntityComponentValues =
    | CodeComponentValues
    | ImageComponentValues
    | VirtualLabComponentValues
    | QuestionComponentValues
    | VideoComponentValues
    | AttachmentComponentValues
    | LinkComponentValues;
