import React from 'react';

import { BackButton } from './BackButton';
import { SelectorNavigation } from './Navigation.styles';
import { useTypeSelectorNavigation } from './NavigationContext';

export const TypeSelectorNavigation: React.FC = () => {
    const { getCurrentlySelected, navigateBack, enabled } = useTypeSelectorNavigation();

    const currentlySelectedType = getCurrentlySelected();

    return (
        <SelectorNavigation>
            {currentlySelectedType && (
                <BackButton
                    enabled={enabled}
                    onClick={() => {
                        navigateBack();
                    }}
                />
            )}
        </SelectorNavigation>
    );
};
