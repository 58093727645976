import React, { useMemo } from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { Chip } from '@material-ui/core';
import Icon from '@mdi/react';
import { CourseIconPath } from 'components/icons/CourseIcon';
import { useModal } from 'hooks/useModal';
import pluralize from 'pluralize';
import styled from 'styled-components';
import { uppercaseFirst } from 'utils/uppercaseFirst';

import { CollectionsModal } from '../CollectionsModal';
import { AncestorCollection } from '../utils';

import { StyledTableCell } from './TableCell.styles';

interface CollectionsCellProps {
    title: string;
    ancestorCollections?: AncestorCollection[];
}

const StyledIcon = styled(Icon)`
    flex-shrink: 0;
`;

export const COLLECTIONS_CELL_WIDTH = 300;

export const CollectionsCell: React.FC<CollectionsCellProps> = ({ title, ancestorCollections = [] }) => {
    const { modalOpen, handleModalOpen, handleModalClose } = useModal();

    const label = useMemo(() => {
        if (ancestorCollections.length > 0) {
            const numOtherCollections = ancestorCollections.length - 1;
            const title =
                ancestorCollections[0].collectionId === ancestorCollections[0].rootCollectionId
                    ? uppercaseFirst(ancestorCollections[0].rootType)
                    : ancestorCollections[0].title;
            return `${title}${numOtherCollections ? ` + ${pluralize('other', numOtherCollections, true)}` : ''}`;
        }
    }, [ancestorCollections]);

    return (
        <StyledTableCell width={COLLECTIONS_CELL_WIDTH}>
            {ancestorCollections.length > 0 ? (
                <>
                    <Chip
                        size="medium"
                        icon={<StyledIcon path={CourseIconPath} size={IconSize.Small} />}
                        label={label}
                        variant="outlined"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleModalOpen();
                        }}
                    />
                    <CollectionsModal
                        open={modalOpen}
                        onClose={handleModalClose}
                        collections={ancestorCollections}
                        parentCollectionTitle={title}
                    />
                </>
            ) : null}
        </StyledTableCell>
    );
};
