import { useContext, useMemo } from 'react';

import { ProfileContext } from 'context/ProfileContext';
import {
    GetOrganizationsForUserResponse,
    queryKey as getOrganizationsForUserQueryKey
} from 'context/ProfileContext/useAvailableProfiles/useGetOrganizationsForUser';
import { useQueryClient } from 'react-query';

export interface OrganizationTerminology {
    skill: string;
    collection: string;
}

interface UseOrganizationTerminologyOutput {
    terminology: OrganizationTerminology;
}

const DEFAULT_TERMINOLOGY = { skill: 'skill', collection: 'collection' };

export const useOrganizationTerminology = (): UseOrganizationTerminologyOutput => {
    const queryClient = useQueryClient();
    const orgsForUser: GetOrganizationsForUserResponse | undefined = queryClient.getQueryData(
        getOrganizationsForUserQueryKey
    );

    const { currentProfile } = useContext(ProfileContext);
    const { id: organizationId } = currentProfile ?? {};

    const terminology = useMemo(() => {
        const currentOrg = (orgsForUser?.getOrganizationsForUser ?? []).find((org) => org.id === organizationId);
        // remove empty string values so default terminology can be used
        const currentOrgTerminology = Object.entries(currentOrg?.terminology ?? {}).reduce((memo, [key, value]) => {
            if (Boolean(value)) {
                memo[key] = value;
            }
            return memo;
        }, {});
        return { ...DEFAULT_TERMINOLOGY, ...currentOrgTerminology };
    }, [organizationId, orgsForUser?.getOrganizationsForUser]);

    return { terminology };
};
