import React from 'react';

import { mdiCheckCircle } from '@mdi/js';

import RoomIcon from '../icons/Room';

import { NavHeader, NavTitle, WideDivider } from './Nav.styles';
import NavCollapse from './NavCollapse';
import NavLinkLoading from './NavLinkLoading';

const NavTest: React.FC = () => {
    return (
        <div>
            <NavHeader>
                <NavTitle>Load Testing</NavTitle>
            </NavHeader>
            <NavCollapse title="Section Title" Icon={RoomIcon}>
                <NavLinkLoading />
                <NavLinkLoading />
                <NavLinkLoading />
                <NavLinkLoading />
            </NavCollapse>
            <WideDivider />
            <NavCollapse title="Section Title" iconPath={mdiCheckCircle}>
                <NavLinkLoading />
                <NavLinkLoading />
            </NavCollapse>
            <WideDivider />
        </div>
    );
};

export default NavTest;
