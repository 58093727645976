import React from 'react';

import { Typography } from '@material-ui/core';
import { mdiUpload } from '@mdi/js';
import Icon from '@mdi/react';

import { useEditableComponent } from '../../../../mixins/editor';
import { useFileUpload, FileUploadError } from '../../../PDF/FileUpload';
import { AssetType } from '../../../PDF/FileUpload/hooks/useCreateUploadIntent';
import { TypeOptionTitle, useTypeSelectorNavigation } from '../../TypeSelector';

import { DropzoneWrapper, HelpText, UploadProgressIndicator } from './FileUpload.styles';

interface FileUploadProps {
    onSelect: () => void;
    onSuccess?: (assetId: string | null, previewUrl?: string | null) => void;
    onError?: (type: FileUploadError, message, data?) => void;
    previewRenderer?: (file: any) => React.ReactElement;
}

const SUPPORTED_VIDEO_FILE_EXTENSIONS = ['.mp4', '.mov'];
const MAX_VIDEO_SIZE = 10485760000;

export const FileUpload: React.FC<FileUploadProps> = ({ onSelect }) => {
    const { disable } = useTypeSelectorNavigation();

    const { onChange } = useEditableComponent();

    const { getDropzoneProps, getInputProps, loading, uploadProgress } = useFileUpload({
        onSuccess: (assetId) => {
            if (assetId) {
                console.log('updating assetId to: ', assetId);
                // file has finished uploading
                onChange<string>('videoId')(assetId);
            }
        },
        onError: () => {},
        onFileSelected: ({ name: fileName }) => {
            onChange<string>('uploadedFileName')(fileName);
            onSelect();
            disable();
        },
        assetType: AssetType.Video,
        acceptedFileExtensions: SUPPORTED_VIDEO_FILE_EXTENSIONS,

        //undefined to allow any sized file
        maxFileSize: MAX_VIDEO_SIZE
    });

    return (
        <>
            <DropzoneWrapper data-testid="video-component-upload-dropzone" {...getDropzoneProps()}>
                <TypeOptionTitle>
                    <Icon path={mdiUpload} size="24px" />
                    <Typography variant="subtitle1">Upload a Video</Typography>
                </TypeOptionTitle>
                {!uploadProgress && !loading ? (
                    <HelpText data-testid="video-component-upload-dropzone-text" variant="body1">
                        Drag and drop a file into this area or click to select a file
                    </HelpText>
                ) : (
                    <>
                        <UploadProgressIndicator />
                        <Typography>Uploading: {uploadProgress}% complete</Typography>
                    </>
                )}
            </DropzoneWrapper>
            <input {...getInputProps()} data-testid="video-component-upload-dropzone-input" />
        </>
    );
};
