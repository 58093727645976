import React, { useContext, useMemo } from 'react';

import { TooltippedIconButton, TooltippedButtonSize, AttachmentViewer, LinkType } from '@adept-at/lib-react-components';
import { Tooltip } from '@material-ui/core';
import { mdiInformation, mdiPencil, mdiTrashCan } from '@mdi/js';
import { Icon } from '@mdi/react';
import { GuidanceContext, GuidanceType } from 'components/builder/Guidance/GuidanceContext';
import { useComponentEngine } from 'components/engine';
import CodeView from 'components/engine/Components/Code/View';
import ImageView from 'components/engine/Components/Image/View';
import { LinkView } from 'components/engine/Components/Link/View';
import QuestionView from 'components/engine/Components/Question/View';
import { EmbeddedVideo } from 'components/engine/Components/Video/EmbeddedVideo';
import { VirtualLabView } from 'components/engine/Components/VirtualLab/View';
import { useTooltipStyles } from 'components/rooms/GuidanceTooltip';
import { SidebarContext, SidebarTab } from 'components/SidebarContext';
import { ContentBlock, ContentState } from 'draft-js';
import styled from 'styled-components';

import { RichTextEditorContext } from '../Context';
import { useLinks } from '../useLinks';
import { DraftJsEntity, EmbeddedEntityComponentValues } from '../utils';

import { EmbeddedComponentWrapper } from './Plugins.styles';

const findEmbeddedEntities = (
    contentBlock: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState
): void => {
    const entityTypes = Object.keys(DraftJsEntity);
    contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        return entityKey !== null && entityTypes.includes(contentState.getEntity(entityKey).getType());
    }, callback);
};

const TooltipContainer = styled.div`
    display: flex;

    a {
        padding: 6px;
        font-size: 1rem;
        font-weight: 400;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    button {
        margin: 4px;
    }

    svg {
        color: ${(props) => props.theme.colors.white};
    }
`;

const typeToComponentMapping = {
    [DraftJsEntity.CODE]: (componentValues) => <CodeView {...componentValues} />,
    [DraftJsEntity.IMAGE]: (componentValues) => <ImageView {...componentValues} />,
    [DraftJsEntity.ATTACHMENT]: (componentValues) => <AttachmentViewer componentValues={componentValues} />,
    [DraftJsEntity.VIDEO]: (componentValues) => <EmbeddedVideo {...componentValues} />,
    [DraftJsEntity.LAB]: (componentValues) => <VirtualLabView componentValues={{ ...componentValues }} />,
    [DraftJsEntity.QUESTION]: (componentValues) => <QuestionView {...componentValues} />,
    [DraftJsEntity.DIVIDER]: () => <hr />,
    [DraftJsEntity.LINK]: ({ children, container, ...componentValues }: any) => {
        return (
            <LinkView componentValues={componentValues} container={container}>
                {children}
            </LinkView>
        );
    }
};

const typeToGuidanceMapping = {
    [DraftJsEntity.CODE]: GuidanceType.Code,
    [DraftJsEntity.IMAGE]: GuidanceType.Image,
    [DraftJsEntity.ATTACHMENT]: GuidanceType.Attachment,
    [DraftJsEntity.VIDEO]: GuidanceType.Video,
    [DraftJsEntity.QUESTION]: GuidanceType.QuizQuestion,
    [DraftJsEntity.LINK]: GuidanceType.QuizQuestion
};

const getComponentForType = (type?: DraftJsEntity): React.FC<EmbeddedEntityComponentValues> | null =>
    type ? typeToComponentMapping[type] : null;

interface EmbeddedProps {
    contentState: ContentState;
    entityKey: string;
}

export const Embedded: React.FC<EmbeddedProps> = ({ contentState, entityKey, children }) => {
    const classes = useTooltipStyles();
    const { container } = useComponentEngine();
    const guidanceContext = useContext(GuidanceContext);
    const sidebarContext = useContext(SidebarContext);
    const { handleEntityModalOpen, setSelectedEntityKey, selectedEntityKey, removeEntity, selectedText } =
        useContext(RichTextEditorContext);
    const { deleteEntireLink } = useLinks(() => {});
    const componentValues = contentState.getEntity(entityKey).getData();
    const entityType = contentState.getEntity(entityKey).getType();
    const Component = useMemo(() => getComponentForType(entityType as DraftJsEntity), [entityType]);

    const isDefaultLink = useMemo(
        () =>
            entityType === DraftJsEntity.LINK &&
            componentValues.linkType !== LinkType.LAB &&
            componentValues.linkType !== LinkType.META &&
            componentValues.linkType !== LinkType.EXAM,
        [componentValues.linkType, entityType]
    );

    const componentIsSelected = useMemo(() => {
        return selectedEntityKey === entityKey && (selectedText.text?.length ?? 0) < 1;
    }, [selectedText, selectedEntityKey, entityKey]);

    return Component ? (
        <Tooltip
            arrow
            placement="top"
            interactive
            open={componentIsSelected}
            classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
            title={
                <TooltipContainer>
                    {entityType === DraftJsEntity.DIVIDER ? null : (
                        <TooltippedIconButton
                            aria-label="Edit"
                            size={TooltippedButtonSize.Small}
                            onClick={() => {
                                if (entityType === DraftJsEntity.CODE) {
                                    const { body, ...others } = componentValues;
                                    handleEntityModalOpen(entityType, { codeBody: body, ...others }, entityKey);
                                } else {
                                    handleEntityModalOpen(entityType as DraftJsEntity, componentValues, entityKey);
                                }
                            }}
                            icon={<Icon path={mdiPencil} />}
                            title="Edit"
                        />
                    )}

                    {guidanceContext && sidebarContext && typeToGuidanceMapping[entityType] ? (
                        <TooltippedIconButton
                            aria-label="Guidance"
                            size={TooltippedButtonSize.Small}
                            icon={<Icon path={mdiInformation} />}
                            title="Guidance"
                            onClick={() => {
                                const guidanceType = typeToGuidanceMapping[entityType];
                                if (guidanceType) {
                                    guidanceContext.showGuidance(guidanceType);
                                    sidebarContext.setActiveTab(SidebarTab.Guidance);
                                }
                            }}
                        />
                    ) : null}

                    <TooltippedIconButton
                        aria-label="Delete"
                        size={TooltippedButtonSize.Small}
                        icon={<Icon path={mdiTrashCan} />}
                        onClick={isDefaultLink ? deleteEntireLink : removeEntity}
                        title="Delete"
                    />
                </TooltipContainer>
            }
        >
            <Tooltip
                arrow
                placement="top"
                interactive
                classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
                title={
                    isDefaultLink ? (
                        <TooltipContainer>
                            <a href={componentValues.url} target="_blank" rel="noreferrer">
                                {componentValues.url}
                            </a>
                        </TooltipContainer>
                    ) : (
                        ''
                    )
                }
            >
                <EmbeddedComponentWrapper
                    $border={componentIsSelected}
                    $isLink={isDefaultLink}
                    onClick={() => setSelectedEntityKey(entityKey)}
                >
                    <Component {...{ ...componentValues, container }}>{children}</Component>
                </EmbeddedComponentWrapper>
            </Tooltip>
        </Tooltip>
    ) : null;
};

export const EmbeddedDecorator = { strategy: findEmbeddedEntities, component: Embedded };
