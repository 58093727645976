import { useEffect, useMemo } from 'react';

import { ClientError } from 'graphql-request';
import { QueryKey, QueryStatus, useQuery } from 'react-query';

import {
    CollectionBasicDetail,
    GetCollectionBasicDetailsResponse,
    useGetCollectionBasicDetails
} from './getCollectionBasicDetails';

interface UseGetNestedCollectionsOutput {
    status: QueryStatus;
    nestedCollections: CollectionBasicDetail[];
}

export const useGetNestedCollections = (
    queryKey: QueryKey,
    nestedCollectionIds: string[]
): UseGetNestedCollectionsOutput => {
    const queryFn = useGetCollectionBasicDetails(nestedCollectionIds);

    const {
        data,
        status,
        refetch: refetchCollectionBasicDetails
    } = useQuery<GetCollectionBasicDetailsResponse, ClientError>(queryKey, {
        enabled: nestedCollectionIds.length > 0,
        queryFn
    });

    const nestedCollections = useMemo(() => {
        if (!data) return [];

        return data.getCollectionBasicDetails;
    }, [data]);

    useEffect(() => {
        if (nestedCollectionIds.length > 0) refetchCollectionBasicDetails();
    }, [nestedCollectionIds, refetchCollectionBasicDetails]);

    return {
        status,
        nestedCollections
    };
};
