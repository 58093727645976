import React, { createContext, useCallback, Dispatch, useContext } from 'react';

import { OrganizationTerminology } from 'components/settings/OrganizationInfoSettings/Terminology/hooks/useUpdateTerminology';
import { ClientError } from 'graphql-request';
import { QueryStatus } from 'react-query';

import useAvailableProfiles, { AvailableProfilesUpdate, AvailableProfilesUpdateAction } from './useAvailableProfiles';
import { OrganizationForUserTenant } from './useAvailableProfiles/useGetOrganizationsForUser';
import useCurrentProfile, { ProfileSelection, UseCurrentProfileOutput } from './useCurrentProfile';
import useProfileConvenience, { UseProfileConvenienceOutput } from './useProfileConvenience';

export const getOrganizationsForUserReactQueryName = 'getOrganizationsForUser';

export enum ProfileType {
    Personal = 'personal',
    Organization = 'organization'
}

export enum TenantOwnerType {
    User = 'USER',
    Organization = 'ORGANIZATION'
}

export interface ProfileOption {
    type: ProfileType;
    label: string;
    alias: string;
    id: string;
    tenants: OrganizationForUserTenant[];
    imageId?: string;
    images?: {
        logoSquare: string | null;
        logoHeader: string | null;
    };
    colors?: {
        background: string | null;
    };
    terminology?: OrganizationTerminology;
}

export interface ProfileContextInterface extends UseCurrentProfileOutput, UseProfileConvenienceOutput {
    error: ClientError | null | undefined;
    status: QueryStatus;
    availableProfiles: ProfileOption[];
    setAvailableProfiles: Dispatch<AvailableProfilesUpdate>;
    updateAvailableProfile: (profile: ProfileSelection, values: Partial<ProfileOption>, setAsDesired?: boolean) => void;
}

const ProfileContext = createContext(undefined as unknown as ProfileContextInterface);

const { Provider, Consumer } = ProfileContext;

const areSelectionsEqual = (selection1: ProfileSelection, selection2: ProfileSelection) =>
    (selection1?.id === selection2?.id || selection1?.alias === selection2?.alias) &&
    selection1?.type === selection2?.type;

const ProfileProvider: React.FC = ({ children }) => {
    const { availableProfiles, setAvailableProfiles, status, error } = useAvailableProfiles();

    const { currentProfile, selectProfile } = useCurrentProfile({
        availableProfiles,
        setAvailableProfiles
    });

    const updateAvailableProfile = useCallback(
        (profile: ProfileSelection, updateValues: Partial<ProfileOption>, setAsDesired = false): void => {
            if (profile) {
                const profileToUpdate = availableProfiles.find((availableProfile) => {
                    return areSelectionsEqual(profile, availableProfile);
                });

                if (setAsDesired) {
                    // Select profile we found, not partial selection entry we were given.
                    selectProfile(profile);
                }

                if (profileToUpdate) {
                    setAvailableProfiles({
                        options: [
                            {
                                ...profileToUpdate,
                                ...updateValues
                            }
                        ],
                        action: AvailableProfilesUpdateAction.Merge
                    });
                }
            }
        },
        [availableProfiles, selectProfile, setAvailableProfiles]
    );

    const convenienceMethods = useProfileConvenience({ currentProfile });

    return (
        <Provider
            value={{
                availableProfiles,
                setAvailableProfiles,
                updateAvailableProfile,
                status,
                error,
                currentProfile,
                selectProfile,
                ...convenienceMethods
            }}
        >
            {children}
        </Provider>
    );
};

export { ProfileContext, ProfileProvider, Consumer as ProfileConsumer };

export const useProfile = (): ProfileContextInterface => {
    const context = useContext(ProfileContext);

    if (!context) {
        throw new Error('useProfile must be used within a ProfileProvider');
    }

    return context;
};
