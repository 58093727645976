import React, { useCallback } from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { Button, Chip } from '@material-ui/core';
import { mdiCheck, mdiPlus, mdiMenuDown, mdiArrowDecision, mdiMenuRight } from '@mdi/js';
import Icon from '@mdi/react';
import { OwnerInfo } from 'components/builder/context/interfaces';
import { EntityType } from 'components/ContentContext/Enums';
import { useUpdateCollectionWorkflow } from 'components/studio/useUpdateEntityWorkflow/useUpdateCollectionWorkflow';
import { useUpdateSkillWorkflow } from 'components/studio/useUpdateEntityWorkflow/useUpdateSkillWorkflow';
import { usePopover } from 'hooks/usePopover';
import styled from 'styled-components';
import { StyledListItem, StyledMenu } from 'styles/Shared.styles';

import { WorkflowStatus } from './utils';

export interface WorkflowChipProps {
    id: string;
    type: EntityType;
    owner: OwnerInfo;
    workflow?: WorkflowStatus;
    variant?: 'chip' | 'button' | 'listItem';
}

const StyledIcon = styled(Icon)<{ $visible: boolean }>`
    visibility: ${(props) => (props.$visible ? 'visible' : 'hidden')};
`;

const NestedMenuIcon = styled(Icon)`
    margin-left: auto;
    margin-right: 0 !important;
`;

const DeleteIcon = styled(Icon)`
    color: ${(props) => props.theme.colors.textSecondary};
`;

const WorkflowMenu = ({ anchorEl, open, handlePopoverClose, selected, onClick }) => (
    <StyledMenu anchorEl={anchorEl} open={open} onClose={handlePopoverClose} onClick={(e) => e.stopPropagation()}>
        {Object.entries(WorkflowStatus).map(([id, status]) => (
            <StyledListItem
                key={id}
                button
                onClick={() => {
                    onClick(id);
                    handlePopoverClose();
                }}
            >
                <StyledIcon $visible={status === selected} path={mdiCheck} size={IconSize.Medium} />
                {status}
            </StyledListItem>
        ))}
    </StyledMenu>
);

export const WorkflowChip: React.FC<WorkflowChipProps> = ({ id, type, owner, workflow, variant = 'chip' }) => {
    const { open, anchorEl, handlePopoverClick, handlePopoverClose } = usePopover();
    const { mutate: updateSkillWorkflow } = useUpdateSkillWorkflow(owner, id);
    const { mutate: updateCollectionWorkflow } = useUpdateCollectionWorkflow(owner, id);

    const onClick = (workflow: keyof typeof WorkflowStatus) => {
        if (type === EntityType.SKILL) {
            return updateSkillWorkflow({ skillId: id, workflow });
        }
        if (type === EntityType.COLLECTION) {
            return updateCollectionWorkflow({ collectionId: id, workflow });
        }
    };

    const renderActionItem = useCallback(() => {
        switch (variant) {
            case 'button':
                return (
                    <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={handlePopoverClick}
                        startIcon={<Icon path={mdiArrowDecision} size={IconSize.Medium} />}
                    >
                        {workflow ?? 'Set workflow status'}
                    </Button>
                );
            case 'listItem':
                return (
                    <StyledListItem
                        button
                        onClick={(e) => {
                            e.stopPropagation();
                            handlePopoverClick(e);
                        }}
                    >
                        <Icon path={mdiArrowDecision} /> {workflow ?? 'Set workflow status'}
                        <NestedMenuIcon path={mdiMenuRight} size={IconSize.Medium} />
                    </StyledListItem>
                );
            default:
                return (
                    <Chip
                        size="medium"
                        deleteIcon={<DeleteIcon path={workflow ? mdiMenuDown : mdiPlus} size={IconSize.Small} />}
                        onDelete={handlePopoverClick}
                        onClick={(e) => {
                            e.stopPropagation();
                            handlePopoverClick(e);
                        }}
                        label={workflow ?? 'Set workflow status'}
                        variant="outlined"
                    />
                );
        }
    }, [variant, handlePopoverClick, workflow]);

    return (
        <>
            {renderActionItem()}
            <WorkflowMenu
                open={open}
                anchorEl={anchorEl}
                handlePopoverClose={handlePopoverClose}
                selected={workflow}
                onClick={onClick}
            />
        </>
    );
};
