import React from 'react';

import { Skeleton } from '@material-ui/lab';
import styled from 'styled-components';

const Loader = styled(Skeleton)<{ depth? }>`
    height: 28px;
    margin-left: ${(props) => props.depth * 16 + 16 + 'px'};
    margin-right: 1rem;
    animation-direction: alternate;
    border-radius: 4px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
`;

const NavLinkLoading: React.FC = () => {
    return <Loader depth={1} variant="rect" animation="wave" />;
};

export default NavLinkLoading;
