import React from 'react';

import { makeRange } from '@adept-at/lib-react-components';
import { Table, TableBody, TableContainer } from '@material-ui/core';
import { EnhancedTableHead, HeadCell } from 'components/SortableTable/EnhancedTableHead';

import { TableHeadWrapper } from './TableHead';
import { LoadingRow } from './TableRow';

interface TableLoadingProps {
    checkboxes?: boolean;
    headCellDetails: HeadCell<any>[];
    excludeHeader?: boolean;
    hasActionComponent?: boolean;
}

export const CollaborationTableLoading: React.FC<TableLoadingProps> = ({
    checkboxes,
    headCellDetails,
    excludeHeader,
    hasActionComponent = false
}) => {
    return (
        <TableContainer>
            <Table>
                {excludeHeader ? null : (
                    <TableHeadWrapper>
                        <EnhancedTableHead
                            headCellDetails={headCellDetails}
                            order="asc"
                            orderBy=""
                            handleRequestSort={() => {}}
                            onSelectAllClick={checkboxes ? () => {} : undefined}
                            includeEmptyHeadCell={hasActionComponent}
                            isLoading={true}
                        />
                    </TableHeadWrapper>
                )}
                <TableBody>
                    {makeRange(1, 10).map((index) => (
                        <LoadingRow
                            key={index}
                            multiSelect={checkboxes}
                            headCellDetails={headCellDetails}
                            index={index}
                            hasActionComponent={hasActionComponent}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
