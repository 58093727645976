import React, { createContext, useCallback, useEffect, useMemo, useContext, useState } from 'react';

import { useIsFetching } from 'react-query';

import { AuthContext } from '../AuthContext';

/**
 * Context to prefetch items off of the page while api calls sit idle.
 * Add callbacks to the queue using @see addCallbackToPrefetch and it will call them one at a time.
 * This should be used sparingly, as overuse is expensive and potentially slows the site.
 */
const PrefetchContext = createContext<PrefetchContextOutput>(undefined as unknown as PrefetchContextOutput);

export interface PrefetchCallback<TParams = unknown> {
    callback: (params: TParams) => void;
    params?: TParams;
}

export interface PrefetchContextOutput {
    addCallbackToPrefetch: <TParams = unknown>(prefetchCallback?: PrefetchCallback<TParams>) => void;
    queueLength: number;
}

export const PrefetchProvider: React.FC = ({ children }) => {
    const { isAuthenticated } = useContext(AuthContext);

    const numberOfQueriesBeingFetched = useIsFetching();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [callbacksToPrefetch, setCallbacksToPrefetch] = useState(new Array<PrefetchCallback<any>>());

    /**
     * Add callbacks to be fetched while api calls sit idle. @see PrefetchCallback
     */
    // This is just because Generics are tough to do in TSX files inline
    // eslint-disable-next-line prefer-arrow-callback
    const addCallbackToPrefetch = useCallback(function addCallbackToPrefetchCallback<TParams = unknown>(
        prefetchCallback?: PrefetchCallback<TParams>
    ) {
        if (prefetchCallback?.callback) {
            setCallbacksToPrefetch((prevCallbacks) => [...prevCallbacks, prefetchCallback]);
        }
    },
    []);

    const removeFirstCallbackFromState = useCallback(() => {
        setCallbacksToPrefetch((prevCallbacks) => {
            const [, ...allCallbacksBesidesTheFirst] = prevCallbacks;

            return allCallbacksBesidesTheFirst;
        });
    }, []);

    const processNextCallback = useCallback(() => {
        const callbackToProcess = callbacksToPrefetch?.[0];
        if (callbackToProcess) {
            try {
                const { callback, params } = callbackToProcess;
                callback(params);
            } finally {
                removeFirstCallbackFromState();
            }
        }
    }, [callbacksToPrefetch, removeFirstCallbackFromState]);

    /** How long the current prefetch queue is. */
    const queueLength = useMemo(() => callbacksToPrefetch.length, [callbacksToPrefetch]);

    useEffect(() => {
        if (isAuthenticated && numberOfQueriesBeingFetched === 0 && queueLength > 0) {
            processNextCallback();
        }
    }, [isAuthenticated, numberOfQueriesBeingFetched, processNextCallback, queueLength]);

    return (
        <PrefetchContext.Provider value={{ addCallbackToPrefetch, queueLength }}>{children}</PrefetchContext.Provider>
    );
};

export default PrefetchContext;
