import React from 'react';

import { Button, ButtonProps } from '@material-ui/core';
import styled from 'styled-components';

export const ButtonContainer = styled.div`
    display: flex;
    flex-wrap: nowrap;
`;

interface HideableButtonProps {
    $isOpen: boolean;
}

export const HideableButton = styled((props: HideableButtonProps & ButtonProps) => <Button {...props} />)`
    min-width: 0;
    padding: 0.5rem;

    svg {
        transform: ${(props) => (props.$isOpen ? 'rotate(45deg)' : 'rotate(0deg)')};
        transition: transform 0.4s ease-out;
    }
`;
