import { useCallback } from 'react';

import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { RequestDocument } from 'graphql-request/dist/types';
import { Api, API_ENDPOINTS } from 'lib/ApiConstants';
import { CONTENT_TYPE_JSON } from 'lib/constants';

import { GraphqlError } from '../../useGqlClient/GraphqlError';
import { getDefaultRequestCatch } from '../defaultCatch';
import { UseRequestWithTokenInterface } from '../type';
import { useEnsureAuthenticated } from '../useEnsureAuthenticated';

/**
 * Make sure that we have a valid queryDocument value. Should be a RequestDocument
 */
export const getQuerySource = (val: RequestDocument): string => {
    if (typeof val === 'string') {
        return val;
    }

    return val.loc ? val.loc.source.body : '';
};

/**
 * If the error received is from Graphql, there is likely a meaningful code value on it that we can use to inform
 * the client how to proceed. Often this error code will signal a fatal state and the client can avoid needless retries.
 *
 * @param errors
 */
const constructErrorFromResponseErrors = (errors: any[]): null | Error | GraphqlError => {
    if (errors.length === 0) {
        return null;
    }

    // @FIXME Only handle the first error for now
    const [error] = errors;

    if (error.extensions?.code) {
        const code = error.extensions?.code;
        const message = error.message;

        return new GraphqlError({ code, message });
    }

    return new Error(error.toString());
};

const buildGqlClient = (
    api: Api,
    requestInterceptor: (config: AxiosRequestConfig) => Promise<AxiosRequestConfig>
): AxiosInstance => {
    const requestOptions = {
        baseURL: `${API_ENDPOINTS[api]}/graphql`,
        method: 'POST',
        headers: {
            'Content-Type': CONTENT_TYPE_JSON
        }
    } as AxiosRequestConfig;

    const requestClient = axios.create(requestOptions);

    requestClient.interceptors.request.use(async (config) => requestInterceptor(config));

    /**
     * Axios response interceptor for handling a successful response to the API. This means a non 4xx or 5xx status code.
     * In this event, we need to perform introspection on the response body to determine if GraphQL signaled a success
     * status code but in fact contained errors.
     *
     * Note: We interpret the presence of _any_ value in the data.errors property as a request error.
     * */
    requestClient.interceptors.response.use((value) => {
        if (value.data.errors) {
            throw constructErrorFromResponseErrors(value.data.errors);
        }

        /**
         * AxiosResponse container "data" property, and GraphQL response should contain "data" property.
         *
         * Protect against it and return null if not so.
         */
        const { data: graphqlResultData = null } = value.data;

        return graphqlResultData;
    });

    return requestClient;
};

export const useRequestWithToken = (
    requestDocument: RequestDocument,
    api: Api,
    headers?: Record<string, boolean | string | number>
): UseRequestWithTokenInterface => {
    const { requestInterceptor, onAuthError } = useEnsureAuthenticated(requestDocument as string);

    const getClient = useCallback(async () => buildGqlClient(api, requestInterceptor), [api, requestInterceptor]);

    const getWorkingRequest = useCallback(
        async <TVariables, TMutationResponse>(variables: TVariables): Promise<TMutationResponse> =>
            (await getClient())
                .request<TVariables, TMutationResponse>({
                    data: {
                        query: getQuerySource(requestDocument),
                        variables: { ...variables }
                    },
                    headers
                })
                .catch((e) => {
                    if (e instanceof GraphqlError) {
                        throw e;
                    }

                    return getDefaultRequestCatch(api, onAuthError)(e);
                }),
        [getClient, requestDocument, headers, api, onAuthError]
    );

    return {
        getWorkingRequest
    };
};
