import { OrganizationTerminology } from 'components/settings/OrganizationInfoSettings/Terminology/hooks/useUpdateTerminology';
import { ClientError } from 'graphql-request';
import gql from 'graphql-tag';
import { useBuildAuthenticatedQueryFn } from 'hooks/useGqlClient/useBuildAuthenticatedQueryFn';
import { Api } from 'lib/ApiConstants';
import { QueryKey, useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { Tenant } from '../AccountContext';

// @GRAPHQL Query getOrganizationByAlias
const queryName = 'getOrganizationByAlias';

const GET_ORGANIZATION_BY_ALIAS_QUERY = gql`
    query getOrganizationByAlias($alias: String!) {
        getOrganizationByAlias(alias: $alias) {
            id
            name
            image
            alias
            desc
            defaultStacks
            terminology {
                skill
                collection
            }
            tenants {
                id
                name
            }
        }
    }
`;

export const buildGetOrganizationByAliasQueryKey = (organizationAlias?: string): QueryKey => [
    queryName,
    organizationAlias
];
export interface Organization {
    id: string;
    name: string;
    alias: string;
    tenants: Tenant[];
    image?: string;
    desc?: string;
    defaultStacks: string[];
    terminology?: OrganizationTerminology;
}

export interface GetOrganizationByAliasResult {
    getOrganizationByAlias: Organization;
}

export interface GetOrganizationByAliasVariables {
    alias: string;
}

export const useGetOrganizationByAlias = (
    alias: string,
    options: UseQueryOptions<GetOrganizationByAliasResult, ClientError>
): UseQueryResult<GetOrganizationByAliasResult, ClientError> => {
    const { queryFn } = useBuildAuthenticatedQueryFn<GetOrganizationByAliasResult, GetOrganizationByAliasVariables>(
        GET_ORGANIZATION_BY_ALIAS_QUERY,
        { alias },
        { api: Api.Accounts }
    );

    const queryCacheKey = buildGetOrganizationByAliasQueryKey(alias);

    return useQuery<GetOrganizationByAliasResult, ClientError>(queryCacheKey, queryFn, options);
};
