import { OptimistMutationContext, useOptimist } from '@adept-at/lib-react-optimist';
import { ClientError } from 'graphql-request';
import { useMutation, UseMutationResult } from 'react-query';

import useGqlClient from '../../../../../../../hooks/useGqlClient';
import { API_CONTENT } from '../../../../../../../lib/ApiConstants';

import makeCreateUploadIntent, {
    CreateUploadIntentResult,
    CreateUploadIntentVariables
} from './makeCreateUploadIntent';

export enum AssetType {
    PDF = 'pdf',
    Video = 'video',
    Image = 'image',
    Attachment = 'attachment'
}

export const useCreateUploadIntent = (): UseMutationResult<
    CreateUploadIntentResult,
    ClientError,
    CreateUploadIntentVariables,
    OptimistMutationContext
> => {
    const { client, withMutationOptions } = useGqlClient(API_CONTENT);

    const { mutationOptions: CreateUploadIntentMutationOptions } = useOptimist<
        CreateUploadIntentResult,
        ClientError,
        CreateUploadIntentVariables
    >();

    return useMutation<CreateUploadIntentResult, ClientError, CreateUploadIntentVariables, OptimistMutationContext>(
        makeCreateUploadIntent(client),
        withMutationOptions(CreateUploadIntentMutationOptions)
    );
};
