import React, { useState } from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { Collapse, IconButton, ListItem } from '@material-ui/core';
import { mdiChevronDown } from '@mdi/js';
import MdiIcon from '@mdi/react';
import { Link, matchPath } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { NavIconWrapper, NavLinkList } from './Nav.styles';

const CollapseToggle = css`
    cursor: pointer;
    padding: 0.5rem 0 0.5rem 1rem;
    display: flex;
    align-items: center;
    height: 36px;
`;

const CollapseToggleDiv = styled.div`
    ${CollapseToggle}
    opacity: 0.75;

    &:hover button {
        opacity: 1;
    }
`;

const CollapseToggleLink = styled(Link)`
    ${CollapseToggle}
`;

const ArrowWrapper = styled(IconButton)<{ $collapsed: boolean; $selected?: boolean }>`
    margin-left: auto;
    transform: ${(props) => (props.$collapsed ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: transform 0.1s ease-out;
    right: 8px;
    position: absolute;
    margin-top: 3px;
    opacity: ${(props) => (props.$selected ? 1 : 0.75)};

    &:hover {
        background-color: ${(props) => props.theme.colors.navItemHighlight};
        opacity: 1;
    }

    svg {
        color: ${(props) => props.theme.colors.textPrimary};
    }
`;

const Title = styled.div`
    font-size: ${(props) => props.theme.fonts.textSizeSmall};
    text-transform: uppercase;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 65%;
    font-weight: 500;
    letter-spacing: 0.87px;
    line-height: 32px;
`;

const StyledCollapseHeader = styled(ListItem)`
    padding: 0;
    flex-direction: column;
    width: 100%;
    position: absolute;
    height: 36px;
    opacity: 0.75;

    &.Mui-selected,
    &.Mui-selected:hover {
        background-color: rgba(255, 255, 255, 0.15);
        opacity: 1;
    }

    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
        opacity: 1;
    }

    a,
    span {
        display: flex;
        align-items: center;
        width: 100%;
    }
`;

const RelativeParent = styled.div`
    position: relative;
    height: 36px;
    margin-bottom: 8px;
`;

interface CollapseProps {
    to?: string;
    title: string;
    iconPath?: string;
    Icon?: any;
    arrowColor?: string;
}

const NavCollapse: React.FC<CollapseProps> = ({ title, iconPath, Icon, to, children, arrowColor }) => {
    const [isOpen, setIsOpen] = useState(true);

    const onToggle = (e) => {
        e.preventDefault();
        setIsOpen(!isOpen);
    };

    const isActive = (toLocation: string): boolean => {
        const escapedPath = toLocation && toLocation.replace(/([.+*?=^!:${}()[\]|/\\])/g, '\\$1');

        const isMatching = matchPath(location.pathname, {
            path: escapedPath,
            exact: true,
            strict: false
        });

        return !!isMatching;
    };

    const renderIcon = () => {
        if (iconPath) {
            return (
                <NavIconWrapper>
                    <MdiIcon path={iconPath} />
                </NavIconWrapper>
            );
        }

        if (Icon) {
            return (
                <NavIconWrapper>
                    <Icon />
                </NavIconWrapper>
            );
        }
    };

    if (to) {
        return (
            <div>
                <RelativeParent>
                    <StyledCollapseHeader button selected={isActive(to)}>
                        <CollapseToggleLink to={to}>
                            {renderIcon()}
                            <Title>{title}</Title>
                        </CollapseToggleLink>
                    </StyledCollapseHeader>
                    <ArrowWrapper size="small" $selected={isActive(to)} $collapsed={isOpen} onClick={onToggle}>
                        <MdiIcon color="white" size={IconSize.Medium} path={mdiChevronDown} />
                    </ArrowWrapper>
                </RelativeParent>
                <Collapse in={isOpen}>
                    <NavLinkList>{children}</NavLinkList>
                </Collapse>
            </div>
        );
    }

    return (
        <>
            <CollapseToggleDiv onClick={onToggle}>
                {renderIcon()}
                <Title>{title}</Title>
                <ArrowWrapper size="small" $collapsed={isOpen} aria-label={isOpen ? 'Collapse' : 'Expand'}>
                    <MdiIcon size={IconSize.Medium} path={mdiChevronDown} />
                </ArrowWrapper>
            </CollapseToggleDiv>
            <Collapse in={isOpen}>
                <NavLinkList>{children}</NavLinkList>
            </Collapse>
        </>
    );
};

export default NavCollapse;
