import { IconSize } from '@adept-at/lib-react-components';
import Icon from '@mdi/react';
import styled from 'styled-components';
import { APP_HEADER_HEIGHT, SIDEBAR_WIDTH, SKILL_CONTAINER_WIDTH, SUB_HEADER_HEIGHT } from 'styles/variables';

import TabPanel from '../TabPanel';

export const ContentContainer = styled.div`
    display: flex;
    width: 100%;
    height: calc(100vh - ${APP_HEADER_HEIGHT}px + 1px);
    overflow-x: auto;
`;

export const SidebarContainer = styled.div<{ $hidden?: boolean }>`
    background-color: ${(props) => props.theme.colors.surface};
    width: ${(props) => (props.$hidden ? 0 : SIDEBAR_WIDTH)}px;
    min-width: ${(props) => (props.$hidden ? 0 : SIDEBAR_WIDTH)}px;
    border-left: ${(props) => (props.$hidden ? 0 : `1px solid ${props.theme.colors.border}`)};
    overflow: hidden;
    transition: all 300ms cubic-bezier(0.2, 0, 0, 1);
`;

export const SidebarAlert = styled.div`
    padding: 1rem;
`;

export const SidebarHeader = styled.div`
    height: ${SUB_HEADER_HEIGHT}px;
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
    display: flex;
    align-items: center;
    width: 100%;

    .MuiTab-root {
        min-width: inherit;
        padding: 6px 24px;
    }
`;

export const SidebarTabPanel = styled(TabPanel)`
    height: calc(100vh - ${APP_HEADER_HEIGHT}px - ${SUB_HEADER_HEIGHT}px);
    max-height: calc(100vh - ${APP_HEADER_HEIGHT}px - ${SUB_HEADER_HEIGHT}px);
    width: ${SIDEBAR_WIDTH}px;
    overflow-x: hidden;
`;

export const HeaderActions = styled.div`
    display: flex;
    align-items: center;

    svg {
        width: ${IconSize.Small};
    }

    > * {
        margin: 0 5px;
    }
`;

export const ContentWrapper = styled.div<{ ref? }>`
    display: flex;
    position: relative;
    height: calc(100% - ${SUB_HEADER_HEIGHT}px);
    overflow-y: auto;
    scroll-behavior: smooth;
`;

export const StyledIcon = styled(Icon)`
    width: ${IconSize.Small};
    margin-right: 5px;
    flex-shrink: 0;
`;

export const VerticalDivider = styled.div`
    width: 1px;
    height: 1.5rem;
    margin: 0 1rem;
    background-color: ${(props) => props.theme.colors.border};
`;

export const SkillContainer = styled.div`
    width: ${SKILL_CONTAINER_WIDTH}px;
    margin: 0 auto;
    position: relative;
    flex-shrink: 0;
`;

export const RoomContentWithResource = styled.div`
    min-width: 580px;
    flex: 1;
    position: relative;
    overflow-x: auto;
    height: calc(100vh - ${APP_HEADER_HEIGHT}px + 1px);
`;

export const RoomContent = styled.div`
    flex: 1;
    position: relative;
    overflow-x: auto;
`;
