import { useMemo } from 'react';

import { ClientError } from 'graphql-request';
import { useQuery, UseQueryResult, QueryKey } from 'react-query';

import useGqlClient from '../../../hooks/useGqlClient';
import { API_ACCOUNTS } from '../../../lib/ApiConstants';

import makeGetUserByEmail, { GetUserByEmailResult } from './makeGetUserByEmail';

export const buildGetUserByEmailQueryKey = (email: string): QueryKey => ['getUserByEmail', email];
// technically shortest possible email https://stackoverflow.com/questions/1423195/what-is-the-actual-minimum-length-of-an-email-address-as-defined-by-the-ietf
const MINIMUM_EMAIL_LOOKUP_LENGTH = 3;

const useGetUserByEmail = (email: string, options = {}): UseQueryResult<GetUserByEmailResult, ClientError> => {
    const { client, withQueryOptions } = useGqlClient(API_ACCOUNTS);

    const userByEmailQueryKey = useMemo(() => buildGetUserByEmailQueryKey(email), [email]);

    return useQuery<GetUserByEmailResult, ClientError>(
        withQueryOptions({
            ...options,
            queryKey: userByEmailQueryKey,
            queryFn: makeGetUserByEmail(client, { email }),
            enabled: email.length > MINIMUM_EMAIL_LOOKUP_LENGTH
        })
    );
};

export default useGetUserByEmail;
