import React from 'react';

import AppLayout from 'layouts/AppLayout';

/**
 * This may seem a little odd, but this should only show for a split second before the component loading screen renders
 * The idea is not to have two separate looking loaders flash, just go background for a split second, then the component loader.
 */

interface RouterFallbackProps {
    layout?: React.ComponentType;
    children?: React.ReactNode;
}

export const RouterFallback = ({ layout: Layout = AppLayout, children }: RouterFallbackProps): JSX.Element => (
    <Layout>{children ? children : null}</Layout>
);
