import React, { useContext, useEffect } from 'react';

import { CircularProgress } from '@material-ui/core';
import { BookshelvesProvider } from 'components/bookshelves/BookshelvesContext';
import { ScheduleProvider } from 'components/dash/Schedule/ScheduleContext';
import { ModalView } from 'components/modals/Bookshelves/BookshelfModal';
import { NavContext, Tab } from 'components/navigation/NavContext';
import NestedCollection from 'components/NestedCollection';
import { NestedCollectionProvider } from 'components/NestedCollection/context/NestedCollectionContext';
import { ProfileContext, ProfileType } from 'context/ProfileContext';
import AppLayout from 'layouts/AppLayout';
import { useParams } from 'react-router-dom';

const NestedCollectionPage: React.FC = () => {
    const { setActiveTab } = useContext(NavContext);
    const { currentProfile, availableProfiles, selectProfile } = useContext(ProfileContext);
    const { tenantSlug, collectionSlugs } = useParams<{ tenantSlug: string; collectionSlugs: string }>();

    useEffect(() => {
        const foundProfile = availableProfiles?.find((profile) => profile.alias === tenantSlug);

        if (foundProfile) {
            selectProfile(foundProfile);
            setActiveTab(Tab.Organization);
        } else {
            //for outsiders navigating between org catalog collections
            selectProfile(availableProfiles.find((profile) => profile.type === ProfileType.Personal) ?? null);
        }
    }, [availableProfiles, selectProfile, setActiveTab, tenantSlug]);

    if (!currentProfile) {
        return (
            <AppLayout withoutPadding={true} fullHeight>
                <CircularProgress />
            </AppLayout>
        );
    }

    return (
        <NestedCollectionProvider tenantSlug={tenantSlug} collectionSlugs={collectionSlugs}>
            <ScheduleProvider>
                <BookshelvesProvider shouldFetchOnInitialRender={false} initialModalView={ModalView.AddExisting}>
                    <AppLayout withoutPadding fullHeight mainContentOverflow>
                        <NestedCollection />
                    </AppLayout>
                </BookshelvesProvider>
            </ScheduleProvider>
        </NestedCollectionProvider>
    );
};

export default NestedCollectionPage;
