import React from 'react';

import { format } from 'date-fns';

import { StyledTableCell } from './TableCell.styles';

interface ModifiedAtCellProps {
    modifiedAt?: string;
}

export const MODIFIED_AT_CELL_WIDTH = 120;

export const ModifiedAtCell: React.FC<ModifiedAtCellProps> = ({ modifiedAt }) => {
    return (
        <StyledTableCell width={MODIFIED_AT_CELL_WIDTH}>
            {modifiedAt ? format(new Date(modifiedAt), 'MM/dd/yyyy') : null}
        </StyledTableCell>
    );
};
