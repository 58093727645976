import { useContext, useEffect } from 'react';

import { OptimistMutationContext, useOptimist } from '@adept-at/lib-react-optimist';
import { Skill } from 'components/ContentContext/Interfaces';
import { NestedCollectionContext } from 'components/NestedCollection/context/NestedCollectionContext';
import { GetEntitiesBasicDetailsQueryResponse } from 'components/NestedCollection/useGetEntitiesBasicDetails/getEntitiesBasicDetails';
import { ClientError } from 'graphql-request';
import gql from 'graphql-tag';
import { useBuildAuthenticatedMutationFn } from 'hooks/useGqlClient/useBuildAuthenticatedMutationFn';
import { Api } from 'lib/ApiConstants';
import { useSnackbar } from 'notistack';
import { useMutation, UseMutationResult } from 'react-query';

import { buildGetSkillBySlugQueryKey, GetSkillBySlugResponse } from '../getSkillBySlug';

const RESET_SKILL_PROGRESS = gql`
    mutation resetSkillProgress($skillId: UUIDv4!) {
        resetSkillProgress(skillId: $skillId) {
            success
        }
    }
`;

interface ResetSkillProgressResponse {
    resetSkillProgress: {
        success: boolean;
    };
}

interface ResetSkillProgressVariables {
    skillId: string;
}

export const useResetSkillProgress = (
    tenantSlug: string,
    skillSlug: string
): UseMutationResult<ResetSkillProgressResponse, ClientError, ResetSkillProgressVariables, OptimistMutationContext> => {
    const { enqueueSnackbar } = useSnackbar();
    const { registerUpdater, mutationOptions } = useOptimist<
        ResetSkillProgressResponse,
        ClientError,
        ResetSkillProgressVariables
    >();

    registerUpdater<GetSkillBySlugResponse>(
        buildGetSkillBySlugQueryKey(tenantSlug, skillSlug),
        (previous) => {
            const prevSkill = previous?.getEntityBySlug ?? {};

            const updatedSkill: Skill = {
                ...prevSkill,
                progress: {
                    ...prevSkill.progress,
                    secondsConsumed: 0,
                    completedQuestions: [],
                    lastCompletedBlockKey: undefined,
                    updatedAt: new Date().toISOString()
                }
            };

            return {
                getEntityBySlug: updatedSkill
            };
        },
        {
            onError: () => {
                enqueueSnackbar('An error occurred. Unable to update your progress', { variant: 'error' });
            },
            refetchQuery: false
        }
    );

    const nestedCollectionContext = useContext(NestedCollectionContext);
    useEffect(() => {
        if (nestedCollectionContext?.basicDetailsQueryKey) {
            registerUpdater<GetEntitiesBasicDetailsQueryResponse>(
                nestedCollectionContext.basicDetailsQueryKey,
                (previous, variables) => {
                    const updatedDetails = (previous?.getEntityBasicDetails ?? []).map((details) => {
                        if ('skillId' in details && details.skillId === variables.skillId) {
                            return {
                                ...details,
                                progress: {
                                    ...details.progress,
                                    secondsConsumed: 0,
                                    completedQuestions: [],
                                    lastCompletedBlockKey: undefined,
                                    updatedAt: new Date().toISOString()
                                }
                            };
                        }
                        return details;
                    });

                    return {
                        ...previous,
                        getEntityBasicDetails: updatedDetails
                    };
                },
                { refetchQuery: false }
            );
        }
    });

    const { mutationFn } = useBuildAuthenticatedMutationFn<ResetSkillProgressResponse, ResetSkillProgressVariables>(
        RESET_SKILL_PROGRESS,
        {
            api: Api.Content
        }
    );

    return useMutation<ResetSkillProgressResponse, ClientError, ResetSkillProgressVariables, OptimistMutationContext>(
        mutationFn,
        mutationOptions
    );
};
