import * as yup from 'yup';

import { MessageEventType } from '..';
import AdepteductMessage from '../../base/AdepteductMessage';
import PossibleEventFields from '../PossibleEventFields';

export const SHOW_INITIAL_DOWNLOAD_APP_MSG = 'SHOW_INITIAL_DOWNLOAD_APP_MSG';

interface AppDownloadInitialInfoFields extends PossibleEventFields {
    name: typeof SHOW_INITIAL_DOWNLOAD_APP_MSG;
}

export default class AppDownloadInitialInfo extends AdepteductMessage<AppDownloadInitialInfoFields> {
    schema = yup.object({
        name: yup.string()
    });

    type = MessageEventType.AppDownloadInitialInfo;
}
