import React from 'react';

import { makeRange } from '@adept-at/lib-react-components';

import { ContentCardSkeleton, CONTENT_CARD_WIDTH } from '../Dashboard.styles';

export const FavoritesLoading: React.FC = () => (
    <>
        {makeRange(1, 3).map((number) => (
            <ContentCardSkeleton
                key={number}
                height={206}
                width={CONTENT_CARD_WIDTH}
                aria-label={`Favorites card ${number} loading`}
            />
        ))}
    </>
);
