import React from 'react';

const CatalogImageCircled: React.FC = () => {
    return (
        <svg
            enableBackground="new 0 0 24 24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="m12 2c-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10-4.5-10-10-10zm0 18c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8zm2-10.7-2.8 3.5-2-2.4-2.7 3.6h11z"
            />
        </svg>
    );
};

export const CatalogImageCircledPath = 
    "m12 2c-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10-4.5-10-10-10zm0 18c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8zm2-10.7-2.8 3.5-2-2.4-2.7 3.6h11z"

export default CatalogImageCircled;