import pluralize from 'pluralize';

const toTwoDigitString = (number: number): string => {
    return number.toString().padStart(2, '0');
};

type TimeFormat = 'short' | 'medium';

export const formatTime = (secondsRemaining: number, format: TimeFormat): string => {
    const hours = Math.floor(secondsRemaining / 3600);
    const minutes = Math.floor((secondsRemaining % 3600) / 60);
    const seconds = Math.round(secondsRemaining % 60);

    if (format === 'short') {
        if (secondsRemaining < 10) {
            return `${secondsRemaining}`;
        }

        const hrs = hours ? toTwoDigitString(hours) + ':' : '';

        const mins = minutes || hours ? toTwoDigitString(minutes) + ':' : '';

        const secs = seconds || minutes || hours ? toTwoDigitString(seconds) : '';

        return `${hrs}${mins}${secs}`;
    }

    if (format === 'medium') {
        const hrs = hours ? `${hours} ${pluralize('hr', hours)} ` : '';

        return `${hrs}${minutes} ${pluralize('min', minutes)}`;
    }

    throw new Error('Invalid TimeFormat');
};
