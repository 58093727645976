import React from 'react';

import Icon from '@mdi/react';
import styled from 'styled-components';

import { TypeOptionTitle } from '../../TypeSelector';

export const SourceOptionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;

    &:first-child {
        margin-left: 10px;
    }

    &:last-child {
        margin-right: 10px;
    }
`;

export const SourceOption = styled.div`
    flex: 1;
`;

// Circular dependency issue? https://github.com/styled-components/styled-components/issues/1449#issuecomment-420087359
export const SourceOptionTitle = styled((props) => <TypeOptionTitle {...props} />)`
    justify-content: center;
    margin: 0;
`;

export const SourceOptionIcon = styled(Icon)`
    margin-right: 5px;
`;
