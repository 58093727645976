import React, { useCallback, useContext, useEffect } from 'react';

import { LearnContext } from './LearnContext';
import { isSkillComplete } from './modes/accountability/utils';
import { useUpdateProgressTimestamp } from './useProgress/useUpdateProgressTimestamp';

export const AutoProgress: React.FC = () => {
    const { skill } = useContext(LearnContext);
    const { mutate: upsertProgress } = useUpdateProgressTimestamp();

    const startProgressOnSkill = useCallback(() => {
        upsertProgress({ skillId: skill.skillId });
    }, [skill.skillId, upsertProgress]);

    useEffect(() => {
        const skillComplete = isSkillComplete(skill);

        if (!skillComplete) {
            const timeUntilProgressStarts = 3000;

            const timer = setTimeout(() => startProgressOnSkill(), timeUntilProgressStarts);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [skill, startProgressOnSkill]);

    return null;
};
