import React, { useState } from 'react';

import { mdiPencil } from '@mdi/js';
import Icon from '@mdi/react';
import { ComponentEngineMode, useComponentEngine } from 'components/engine';

import { TextViewWrapper, StyledCurrentValue, StyledIconButton, StyledTextField } from './TextEditInPlace.styles';

const KEY_CODE_ENTER = 'Enter';
const KEY_CODE_ESCAPE = 'Escape';

const DEFAULT_INITIAL_VALUE = 'Editable Text Value...';

export interface TextEditInPlaceProps {
    initialValue?: string;
    onSubmit: (value: string) => void;
    onCancel?: () => void;
    onChange?: (value: string) => void;
    onStartEditing?: () => void;
    onStopEditing?: () => void;
    editing?: boolean | (() => boolean);
    showEditIcon?: boolean;
    children: React.ReactNode;
}

const TextEditInPlace: React.FC<TextEditInPlaceProps> = ({
    initialValue = DEFAULT_INITIAL_VALUE,
    onSubmit,
    onCancel = () => {},
    onChange = () => {},
    onStartEditing = () => {},
    onStopEditing = () => {},
    showEditIcon = true,
    editing = false,
    children
}) => {
    const [lastValue, setLastValue] = useState<string>(initialValue);
    const [value, setValue] = useState<string>(initialValue);

    const { mode } = useComponentEngine();

    if (mode === ComponentEngineMode.View) {
        return null;
    }

    const cancel = () => {
        resetState();
        onCancel();
    };

    const startEditing = () => {
        onStartEditing();
    };

    const resetState = () => {
        setValue(lastValue);
        onStopEditing();
    };

    const handleBlur = () => {
        if (value === initialValue) {
            return resetState();
        }

        onSubmit(value);
        onStopEditing();
    };

    if (!editing) {
        return (
            <TextViewWrapper onClick={startEditing}>
                <StyledCurrentValue>{children}</StyledCurrentValue>

                <StyledIconButton hidden={!showEditIcon} onClick={startEditing}>
                    <Icon path={mdiPencil} />
                </StyledIconButton>
            </TextViewWrapper>
        );
    }

    // @todo a11y?
    return (
        <StyledTextField
            value={value}
            onFocus={(e) => {
                if (e.currentTarget.value === DEFAULT_INITIAL_VALUE) {
                    e.currentTarget.setSelectionRange(0, value.length);
                } else {
                    e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length);
                }
            }}
            onKeyDown={(e) => {
                if (e.key === KEY_CODE_ENTER) {
                    e.preventDefault();
                    handleBlur();
                }

                if (e.key === KEY_CODE_ESCAPE) {
                    e.preventDefault();
                    cancel();
                }
            }}
            onChange={({ target: { value } }) => {
                setLastValue(value);
                setValue(value);
                onChange(value);
            }}
            inputProps={{
                'aria-label': 'text-edit-input-area'
            }}
            onBlur={handleBlur}
            fullWidth
            autoFocus
        />
    );
};

export default TextEditInPlace;
