import React from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { makeStyles, Tooltip } from '@material-ui/core';
import { mdiInformationOutline } from '@mdi/js';
import Icon from '@mdi/react';
import styled from 'styled-components';

const useTooltipStyles = makeStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.background.secondary,
        fontSize: '0.875rem',
        maxWidth: '300px'
    }
}));

const StyledIcon = styled(Icon)`
    margin-left: 0.5rem;
    color: ${(props) => props.theme.colors.textTertiary};
`;

const TitleWrapper = styled.div`
    padding: 1rem;
`;

export const InfoTooltip: React.FC<{ title: string }> = ({ title }) => {
    const classes = useTooltipStyles();

    return (
        <Tooltip
            placement="bottom-start"
            title={<TitleWrapper>{title}</TitleWrapper>}
            classes={{ tooltip: classes.tooltip }}
        >
            <StyledIcon size={IconSize.Small} path={mdiInformationOutline} />
        </Tooltip>
    );
};
