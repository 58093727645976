import { OptimistMutationContext, useOptimist } from '@adept-at/lib-react-optimist';
import { EntityInfo, OwnerInfo } from 'components/ContentContext/Interfaces';
import { gql } from 'graphql-request';
import { getRetryCallback, shouldRetryGraphql } from 'hooks/request/retry';
import { GraphqlError } from 'hooks/useGqlClient/GraphqlError';
import { useBuildAuthenticatedMutationFn } from 'hooks/useGqlClient/useBuildAuthenticatedMutationFn';
import { Api } from 'lib/ApiConstants';
import { useSnackbar } from 'notistack';
import { UseMutateFunction, useMutation, QueryKey } from 'react-query';

const SHARE_CONTENT_MUTATION = gql`
    mutation updateManyEntitiesOwnership($entities: [EntityInfoInput]!, $newOwner: OwnerInfo!) {
        updateManyEntitiesOwnership(entities: $entities, newOwner: $newOwner) {
            success
        }
    }
`;

interface TransferManyEntityOwnershipResult {
    transferManyEntityOwnership: boolean;
}

export interface TransferManyEntityOwnershipProps {
    entities: EntityInfo[];
    newOwner: OwnerInfo;
}
export interface useTransferManyEntityOwnershipProps {
    onFinished: () => void;
    contentQueryKey?: QueryKey;
    contentTransferOptimistCallback?: <T>(previous: T, transferQueryProps: TransferManyEntityOwnershipProps) => T;
}

const SKILL_NON_TRANSFERABLE_CODE = 'SKILL_NON_TRANSFERABLE';
const FATAL_ERROR_CODES = [SKILL_NON_TRANSFERABLE_CODE];

const useTransferManyEntityOwnership = ({
    onFinished,
    contentQueryKey,
    contentTransferOptimistCallback
}: useTransferManyEntityOwnershipProps): {
    transferManyEntityOwnership: UseMutateFunction<
        TransferManyEntityOwnershipResult,
        GraphqlError,
        TransferManyEntityOwnershipProps,
        OptimistMutationContext
    >;
    isLoading: boolean;
    error: GraphqlError | null;
} => {
    const { enqueueSnackbar } = useSnackbar();

    const { registerUpdater: registerTransferManyEntityOwnership, mutationOptions } = useOptimist<
        TransferManyEntityOwnershipResult,
        GraphqlError,
        TransferManyEntityOwnershipProps
    >({
        globalMutationOptions: {
            onSuccess: () => {
                onFinished();
                enqueueSnackbar('Successfully transferred ownership', { variant: 'success' });
            },
            onError: (e: GraphqlError) => {
                if (e.code === SKILL_NON_TRANSFERABLE_CODE) {
                    enqueueSnackbar('Skill belongs to one or more collections and cannot be transferred', {
                        variant: 'error'
                    });
                    return;
                }
                enqueueSnackbar('An error occurred transferring ownership.', { variant: 'error' });
            },
            retry: getRetryCallback(shouldRetryGraphql(FATAL_ERROR_CODES))
        }
    });

    if (contentQueryKey && contentTransferOptimistCallback) {
        registerTransferManyEntityOwnership(contentQueryKey, contentTransferOptimistCallback, { refetchQuery: false });
    }

    const { mutationFn } = useBuildAuthenticatedMutationFn<
        TransferManyEntityOwnershipResult,
        TransferManyEntityOwnershipProps
    >(SHARE_CONTENT_MUTATION, { api: Api.Content });

    const {
        mutate: transferManyEntityOwnership,
        isLoading,
        error
    } = useMutation<
        TransferManyEntityOwnershipResult,
        GraphqlError,
        TransferManyEntityOwnershipProps,
        OptimistMutationContext
    >(mutationFn, mutationOptions);

    return {
        transferManyEntityOwnership,
        isLoading,
        error
    };
};

export default useTransferManyEntityOwnership;
