import React, { useState, useEffect } from 'react';

import styled from 'styled-components';

import { formatTime } from '../../../../../utils/time';
import { useAccountabilityContext } from '../AccountabilityContext';

const StyledCountdown = styled.span`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0 9px 0 0;
`;

interface CountdownTimerProps {
    initialTimeInSeconds: number;
}

export const CountdownTimer: React.FC<CountdownTimerProps> = ({ initialTimeInSeconds }) => {
    const [timeRemaining, setTimeRemaining] = useState(initialTimeInSeconds);
    const { setReadyToProceed } = useAccountabilityContext();

    // decrease time remaining every second until no time remaining (ready to proceed)
    useEffect(() => {
        const interval = setInterval(() => {
            if (timeRemaining > 1) {
                setTimeRemaining((prev) => prev - 1);
            } else {
                setReadyToProceed(true);
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [timeRemaining, setReadyToProceed]);

    if (timeRemaining > 0) {
        return <StyledCountdown>Next section available in: {formatTime(timeRemaining, 'short')}</StyledCountdown>;
    }

    return null;
};
