import React from 'react';

import AdepteductMessageProps from 'components/communication/message/AdepteductMessageProps';
import ChannelTemplateChatMessage from 'components/communication/message/chat/ChannelTemplateMessage';
import DefaultAdepteductMessage from 'components/communication/message/chat/DefaultAdepteductMessage';
import SystemNotificationDingerMessage from 'components/communication/message/dinger/SystemNotif';
import * as yup from 'yup';

import { MessageEventType } from '..';
import AckableAdepteductMessage from '../../base/AckableAdepteductMessage';
import { MessageDestination } from '../../MessageRecord';
import PossibleEventFields from '../PossibleEventFields';

export enum MessageFieldsSystemNotificationName {
    OrganizationMembershipAdded = 'organization.membership.added'
}

export interface MessageFieldsSystemNotification {
    name: MessageFieldsSystemNotificationName | string; // Unique identifier per system notification - could map to a CMS/Ad Platform
    template: string;
    variables: any[];
    attachments?: [];
}

interface EventFieldsSystemNotification {
    template: string;
    user: string;
}

export default class SystemNotificationMessage extends AckableAdepteductMessage<EventFieldsSystemNotification> {
    type = MessageEventType.SystemNotification;
    schema = yup.object({
        name: yup.string().required(),
        template: yup.string().required(),
        variables: yup.object(),
        attachments: yup.array()
    });

    mapDestinationToComponent = (
        destination?: MessageDestination
    ): React.ForwardRefExoticComponent<
        AdepteductMessageProps<PossibleEventFields> & React.RefAttributes<HTMLDivElement | null>
    > => {
        switch (destination) {
            case MessageDestination.Dinger:
                return SystemNotificationDingerMessage;
            case MessageDestination.Inbox:
            case MessageDestination.Chat:
                return ChannelTemplateChatMessage;
            default:
                return DefaultAdepteductMessage;
        }
    };
}
