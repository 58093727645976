export enum UserSearchOptionType {
    UserId = 'USER_ID',
    UserEmail = 'USER_EMAIL',
    UserGroup = 'USER_GROUP'
}

export interface UserSearchOption {
    id: string; // user id
    type: UserSearchOptionType;
    label: string; // This often relates to the field that cause them to be found
    details: any;
}
