import React, { createContext, useContext } from 'react';

import { ComponentValuesType } from '@adept-at/lib-react-components';

export interface ComponentContextInterface {
    id: string;
    order: number;
    type: ComponentValuesType;
}

export const ComponentContext = createContext({} as ComponentContextInterface);

interface ComponentContextProps {
    id: string;
    order: number;
    type: ComponentValuesType;
}

export const ComponentContextProvider: React.FC<ComponentContextProps> = ({ id, order, type, children }) => {
    return <ComponentContext.Provider value={{ id, order, type }}>{children}</ComponentContext.Provider>;
};

export const useComponentEngineComponent = (): ComponentContextInterface => {
    const context = useContext(ComponentContext);

    if (!context) {
        throw new Error('useComponentEngineComponent must be used within a ComponentContextInterface');
    }

    return context;
};
