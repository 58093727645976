import React from 'react';

import { ImageComponentValues } from '@adept-at/lib-react-components';
import { useUnsafeMutation } from 'components/GraphqlProvider';
import gql from 'graphql-tag';
import { API_CONTENT } from 'lib/ApiConstants';

import { CaptionEdit } from '../../CaptionEdit';
import { UpdateImageDimensionsResult, UpdateImageDimensionsVariables } from '../../interfaces';

import { UploadedImageEdit } from './ImageEdit';
import { UploadImageEditUpload } from './Upload';

import { ImageComponentImage } from './';

interface EditProps {
    values: ImageComponentValues;
    onChange: <TComponentFieldValue>(field: string) => (value: TComponentFieldValue) => void;
    onChangeAndSave: <TComponentFieldValue>(field: string) => (value: TComponentFieldValue) => void;
    onCancel: () => void;
}

export const UPDATE_IMAGE_DIMENSIONS = gql`
    mutation updateImageDimensions($assetId: UUIDv4!, $dimensions: ImageDetailsDimensionsInput!) {
        updateImageDimensions(assetId: $assetId, dimensions: $dimensions) {
            success
        }
    }
`;

export const Edit: React.FC<EditProps> = ({
    values: { caption, image = null },
    onChange,
    onChangeAndSave,
    onCancel
}) => {
    // If no image value has been set, we need them to upload before going further
    const [updateImageDimensions] = useUnsafeMutation<UpdateImageDimensionsResult, UpdateImageDimensionsVariables>(
        UPDATE_IMAGE_DIMENSIONS,
        {},
        { host: API_CONTENT }
    );

    if (!image) {
        return (
            <UploadImageEditUpload
                onCancel={onCancel}
                onUploaded={(assetId: string, url: string, dimensions: any) => {
                    onChange<ImageComponentImage>('image')({
                        url,
                        assetId,
                        dimensions
                    });
                    onChange('assetId')(assetId);
                }}
            />
        );
    }

    return (
        <>
            <UploadedImageEdit
                image={image}
                onResize={({ width, height }) => {
                    /**
                     * Previous version of this was also calling the mutation updateImageDimensions to update dimensions on
                     * the asset that is used by this image component. I'm not sure I necessarily aggree with that decision
                     * since this component is effectively using an instance of this asset. Thus, omitting for now. --Sean
                     */

                    onChange<ImageComponentImage>('image')({
                        ...image,
                        dimensions: {
                            width,
                            height
                        }
                    });

                    if (image.assetId) {
                        updateImageDimensions({ assetId: image.assetId, dimensions: { width, height } });
                    }
                }}
            />
            <CaptionEdit onSubmit={onChangeAndSave} value={caption} />
        </>
    );
};
