import { RichTextComponentValuesBody } from '@adept-at/lib-react-components';
import { WorkflowStatus } from 'components/CollaborationTable';
import { Author, Images, OwnerInfo, DefaultCollectionImageType } from 'components/ContentContext/Interfaces';
import { MarketplaceListingStatus } from 'components/marketplace/hooks/useUpsertListingStatus';
import { CollectionType } from 'components/modals/Skills/useGetCollectionsForTenantProfile';
import { ClientError } from 'graphql-request';
import gql from 'graphql-tag';
import { useBuildAuthenticatedQueryFn } from 'hooks/useGqlClient/useBuildAuthenticatedQueryFn';
import { Api } from 'lib/ApiConstants';
import { ADEPT_USE_PRIMARY_HEADER } from 'lib/constants';
import { QueryKey, useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { collectionFragment } from '../fragments';

export const getTenantProfileForOwnerQueryName = 'getTenantProfileForOwner';
export const buildGetProfileForOwnerQueryKey = (ownerId: string): QueryKey => [
    getTenantProfileForOwnerQueryName,
    ownerId
];
export interface TenantProfileResponse {
    tenantProfileId: string;
    tenantSlug: string | null;
    rootCollection: RootCollectionResponse;
    marketplaceStatus?: MarketplaceListingStatus;
}

export interface GetTenantProfileForOwnerResponse {
    getTenantProfileForOwner: TenantProfileResponse;
}

interface GetTenantProfileForOwnerVariables {
    owner: OwnerInfo;
}

// labs and assessments not on backend yet
export enum CollectionStatType {
    Skill = 'skill',
    Lab = 'virtual_lab',
    Assessment = 'assessment',
    Video = 'video',
    Question = 'question',
    LiveLearning = 'live-learning-session'
}

export enum CollectionChild {
    Collection = 'collection',
    Skill = 'skill',
    Lab = 'virtual_lab',
    Assessment = 'assessment',
    Question = 'question'
}

export interface CollectionStats {
    estimatedSecondsToConsume: number;
    descendants: StatDescendent[];
}

export interface StatDescendent {
    type: CollectionStatType;
    count: number;
}

interface CollectionAuthor {
    userId: string;
    authorTypes: Author[];
}

export interface RootCollectionResponse {
    collectionId: string;
    title: string;
    titleSub: string | null;
    description: RichTextComponentValuesBody | null;
    stats: CollectionStats | null;
    images: Images | null;
    defCat: DefaultCollectionImageType | null;
    defFeat: DefaultCollectionImageType | null;
    owner: OwnerInfo;
    children: {
        id: string;
        type: CollectionChild;
    }[];
    infoVersion: number;
    itemsVersion: number;
    editableCollectionId: string | undefined;
    immutableCollectionId: string | undefined;
    createdAt: string;
    visible: boolean;
    editable: boolean;
    tags: string[] | null;
    authors: CollectionAuthor[];
    workflow?: WorkflowStatus;
    rootType: CollectionType;
}

export const GET_TENANT_PROFILES_FOR_OWNER_QUERY = gql`
    query getTenantProfileForOwner($owner: OwnerInfo!) {
        getTenantProfileForOwner(owner: $owner) {
            tenantProfileId
            tenantSlug
            rootCollection {
                ${collectionFragment}
            }
            marketplaceStatus
        }
    }
`;

export const useGetTenantProfileForOwner = (
    variables: GetTenantProfileForOwnerVariables,
    options: UseQueryOptions<GetTenantProfileForOwnerResponse, ClientError> = {},
    usePrimary: boolean
): UseQueryResult<GetTenantProfileForOwnerResponse, ClientError> => {
    const { queryFn } = useBuildAuthenticatedQueryFn<
        GetTenantProfileForOwnerResponse,
        GetTenantProfileForOwnerVariables
    >(GET_TENANT_PROFILES_FOR_OWNER_QUERY, variables, {
        api: Api.Content,
        headers: { [ADEPT_USE_PRIMARY_HEADER]: usePrimary }
    });

    const queryCacheKey = buildGetProfileForOwnerQueryKey(variables.owner.id);

    return useQuery<GetTenantProfileForOwnerResponse, ClientError>(queryCacheKey, queryFn, options);
};
