import React, { useContext } from 'react';

import { Button } from '@material-ui/core';
import { useHistory } from 'react-router';

import { ButtonContainer } from './Storyboard.styles';
import { STEPS, StoryboardContext } from './StoryboardContext';

const Tutorial: React.FC = () => {
    const { setStep } = useContext(StoryboardContext);
    const history = useHistory();

    return (
        <>
            <p>
                Plan from the perspective of your audience. Try to think the way they think, feel the way they feel.
                Take some time to fill out the Why, Who, and How below. These three statements will show up in search
                results to help potential learners find and select this content.
            </p>
            <ButtonContainer>
                <Button
                    data-testid="start-storyboard"
                    onClick={() => {
                        setStep(0);
                        history.push({ search: `?prompt=${STEPS[0]}` });
                    }}
                    color="primary"
                    variant="contained"
                >
                    Let&apos;s Start!
                </Button>
            </ButtonContainer>
        </>
    );
};

export default Tutorial;
