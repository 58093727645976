import { useGetDownloadRecorderUrl } from './useGetDownloadRecorderUrl';

interface UseDownloadRecorderInterface {
    downloadUrl: string | null;
}

export const useDownloadRecorder = (): UseDownloadRecorderInterface => {
    const { data } = useGetDownloadRecorderUrl();

    return {
        downloadUrl: data?.getAdeptRecorderDownloadURL || null
    };
};
