import styled from 'styled-components';
import { SKILL_CONTENT_WIDTH } from 'styles/variables';

import { TypeOption } from './TypeOption';

export const OptionsContainer = styled.div`
    align-items: center;
    border-radius: 10px;
    border: 1px dashed #757575;
    display: flex;
    flex-direction: row;
    height: 200px;
    width: 100%;
    max-width: ${SKILL_CONTENT_WIDTH}px;
    margin: 0 auto;
`;

interface TypeOptionWithVisibilityProps {
    visible: boolean;
}

export const TypeOptionWithVisibility = styled(TypeOption)<TypeOptionWithVisibilityProps>`
    display: ${({ visible = true }) => (visible ? 'flex' : 'none')};
`;
