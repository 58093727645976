import React, { useCallback, useState } from 'react';

import { RichTextComponentValuesBody } from '@adept-at/lib-react-components';
import { useModal } from 'hooks/useModal';

import { RichTextEditorProvider } from './Context';
import DraftEditor from './DraftEditor';
import { EmbeddedEntityModal } from './Modal/EmbeddedEntityModal';
import { RichTextEditorContainer } from './RichTextEditor.styles';
import { EmbeddedEntityComponentValues, DraftJsEntity } from './utils';

interface EditorProps {
    placeholder?: string;
    onChange: (value: RichTextComponentValuesBody) => void;
    minHeight?: number;
    value: RichTextComponentValuesBody;
    componentId: string;
}

const RichTextEditor: React.FC<EditorProps> = ({ value, onChange, placeholder, componentId }) => {
    const [entityKey, setEntityKey] = useState<string>();
    const [entity, setEntity] = useState<EmbeddedEntityComponentValues>();
    const [entityType, setEntityType] = useState<DraftJsEntity>(DraftJsEntity.IMAGE);

    const {
        modalOpen: entityModalOpen,
        handleModalOpen: openEntityModal,
        handleModalClose: handleEntityModalClose
    } = useModal();

    const handleEntityModalOpen = (
        type: DraftJsEntity,
        entityToUpdate?: EmbeddedEntityComponentValues,
        key?: string
    ) => {
        setEntityKey(key);
        setEntityType(type);
        setEntity(entityToUpdate);
        openEntityModal();
    };

    return (
        <RichTextEditorContainer>
            <RichTextEditorProvider
                handleEntityModalOpen={handleEntityModalOpen}
                initialValue={value}
                onChange={useCallback(onChange, [onChange])}
                componentId={componentId}
            >
                <DraftEditor componentId={componentId} placeholder={placeholder} />
                {/* Include Any Editor Components from Plugins */}
                <EmbeddedEntityModal
                    entityKey={entityKey}
                    open={entityModalOpen}
                    onClose={handleEntityModalClose}
                    entityType={entityType}
                    entity={entity}
                />
            </RichTextEditorProvider>
        </RichTextEditorContainer>
    );
};

export { RichTextEditor };
