import { useMemo } from 'react';

import { CommonComponentValues } from '@adept-at/lib-react-components';
import { OptimistMutationContext, OptimistRegisterUpdaterOptions, useOptimist } from '@adept-at/lib-react-optimist';
import { ClientError } from 'graphql-request';
import produce from 'immer';
import { useSnackbar } from 'notistack';
import { useMutation, UseMutationResult } from 'react-query';

import useGqlClient from '../../../../hooks/useGqlClient';
import { API_CONTENT } from '../../../../lib/ApiConstants';
import { buildGetAssessmentByIdQueryKey } from '../../../../pages/assessment/AssessmentEdit';
import { EntityType } from '../../../ContentContext/Enums';
import { UpsertComponentVariables, UpsertComponentResult } from '../../../engine/types';
import { GetAssessmentByIdResponse } from '../../queries/getAssessmentById';

import makeUpsertAssessmentComponent from './makeUpsertAssessmentComponent';

const useUpsertAssessmentComponent = (
    entityType: EntityType,
    entityId: string
): UseMutationResult<UpsertComponentResult, ClientError, UpsertComponentVariables, OptimistMutationContext> => {
    const { enqueueSnackbar } = useSnackbar();
    const { client, withMutationOptions } = useGqlClient(API_CONTENT);

    const { registerUpdater: registerUpsertComponentUpdater, mutationOptions: UpsertComponentMutationOptions } =
        useOptimist<UpsertComponentResult, ClientError, UpsertComponentVariables>();

    const UpsertComponentOptions: OptimistRegisterUpdaterOptions<
        UpsertComponentResult,
        ClientError,
        UpsertComponentVariables
    > = useMemo(() => {
        return {
            onError: () => {
                enqueueSnackbar('An error occurred while adding component.', { variant: 'error' });
            },
            onSuccess: () => {
                enqueueSnackbar('Component saved.', { variant: 'success' });
            },
            refetchQuery: true
        };
    }, [enqueueSnackbar]);

    registerUpsertComponentUpdater<GetAssessmentByIdResponse>(
        buildGetAssessmentByIdQueryKey(entityId),
        (previous, { componentId, componentType, order, values }) =>
            produce(previous, (draft) => {
                try {
                    draft.getAssessmentById.components[componentId] = {
                        type: componentType,
                        order: order,
                        values: values
                    } as CommonComponentValues;
                } catch (error) {
                    console.error(error);
                }
            }),
        UpsertComponentOptions
    );

    return useMutation<UpsertComponentResult, ClientError, UpsertComponentVariables, OptimistMutationContext>(
        makeUpsertAssessmentComponent<UpsertComponentResult, UpsertComponentVariables>(client),
        withMutationOptions(UpsertComponentMutationOptions)
    );
};

export default useUpsertAssessmentComponent;
