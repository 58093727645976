import { AvailabilityStatus, WorkflowStatus } from 'components/CollaborationTable';
import { OwnerInfo } from 'components/ContentContext/Interfaces';
import { CollectionType } from 'components/modals/Skills/useGetCollectionsForTenantProfile';
import { EntityChild } from 'components/NestedCollection/context/getNestedCollectionBySlug';
import { ClientError } from 'graphql-request';
import gql from 'graphql-tag';
import { useBuildAuthenticatedQueryFn } from 'hooks/useGqlClient/useBuildAuthenticatedQueryFn';
import { Api } from 'lib/ApiConstants';
import { QueryKey, useQuery, UseQueryResult } from 'react-query';

export const buildGetCollectionQueryKey = (collectionId: string): QueryKey => ['getCollection', collectionId];

export interface CollectionProductDetails {
    productId: string | null;
    prices: {
        priceId: string;
        pricePerUnitCents: number;
    }[];
}

export interface GetCollectionResponse {
    getCollection: {
        createdAt: string;
        updatedAt: string;
        collectionId: string;
        title: string;
        visible: boolean;
        titleSub: string;
        workflow?: keyof typeof WorkflowStatus;
        tenantSlug: string;
        collectionSlug: string;
        availability?: AvailabilityStatus;
        children: EntityChild[];
        owner: OwnerInfo;
        rootType: CollectionType;
        itemsVersion: number;
        productDetails: CollectionProductDetails;
    };
}

export const GET_COLLECTION = gql`
    query getCollection($collectionId: UUIDv4!) {
        getCollection(collectionId: $collectionId) {
            createdAt
            updatedAt
            collectionId
            title
            visible
            titleSub
            workflow
            tenantSlug
            collectionSlug
            children {
                id
                type
            }
            owner {
                id
                type
            }
            rootType
            itemsVersion
            productDetails {
                productId
                prices {
                    pricePerUnitCents
                    priceId
                }
            }
        }
    }
`;

export const useGetCollection = (collectionId: string): UseQueryResult<GetCollectionResponse, ClientError> => {
    const { queryFn } = useBuildAuthenticatedQueryFn<GetCollectionResponse>(
        GET_COLLECTION,
        { collectionId },
        { api: Api.Content }
    );

    return useQuery<GetCollectionResponse, ClientError>(buildGetCollectionQueryKey(collectionId), { queryFn });
};
