import { Skill } from 'components/ContentContext/Interfaces';
import { progressFragment } from 'components/dash/fragments';
import { BASE_SKILL_QUERY_STRING } from 'components/skills/hooks/useGetSkillById';
import { gql, ClientError } from 'graphql-request';
import { useBuildAuthenticatedQueryFn } from 'hooks/useGqlClient/useBuildAuthenticatedQueryFn';
import { Api } from 'lib/ApiConstants';
import { ADEPT_USE_PRIMARY_HEADER } from 'lib/constants';
import { QueryKey, useQuery, UseQueryResult } from 'react-query';

const GET_SKILL_BY_SLUG_QUERY = gql`
    query getEntityBySlug($slug: String) {
        getEntityBySlug(slug: $slug) {
            ... on Skill {
                ${BASE_SKILL_QUERY_STRING}
                ${progressFragment}
            }
        }
    }
`;

export interface GetSkillBySlugResponse {
    getEntityBySlug: Skill;
}

export const buildGetSkillBySlugQueryKey = (tenantSlug: string, skillSlug: string): QueryKey => [
    'learnGetSkillBySlug',
    tenantSlug,
    skillSlug
];

export const useGetSkillBySlug = (
    tenantSlug: string,
    skillSlug: string,
    usePrimary = false,
    enabled = true
): UseQueryResult<GetSkillBySlugResponse, ClientError> => {
    const { queryFn } = useBuildAuthenticatedQueryFn<GetSkillBySlugResponse, { slug: string }>(
        GET_SKILL_BY_SLUG_QUERY,
        { slug: `${tenantSlug}/${skillSlug}` },
        { api: Api.Content, headers: { [ADEPT_USE_PRIMARY_HEADER]: usePrimary } }
    );

    return useQuery<GetSkillBySlugResponse, ClientError>(buildGetSkillBySlugQueryKey(tenantSlug, skillSlug), {
        queryFn,
        enabled: enabled && !!tenantSlug && !!skillSlug
    });
};
