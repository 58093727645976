import { useRef } from 'react';

const DEFAULT_MAX_REFRESHES_WITHOUT_VALID_TOKEN = 3;

interface UseSessionRefreshRetriesInterface {
    incrementRetries: () => void;
    resetRetries: () => void;
    hasReachedMaxRetries: () => boolean;
}

interface UseSessionRefreshRetriesProps {
    maxRetries?: number;
}

export const useSessionRefreshRetries = ({
    maxRetries = DEFAULT_MAX_REFRESHES_WITHOUT_VALID_TOKEN
}: UseSessionRefreshRetriesProps = {}): UseSessionRefreshRetriesInterface => {
    const refreshesWithoutValidToken = useRef<number>(0);

    return {
        incrementRetries: () => {
            refreshesWithoutValidToken.current = refreshesWithoutValidToken.current + 1;
        },
        resetRetries: () => {
            refreshesWithoutValidToken.current = 0;
        },
        hasReachedMaxRetries: () => refreshesWithoutValidToken.current >= maxRetries
    };
};
