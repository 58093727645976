import { useEffect, useState } from 'react';

import { EntityInfoInput } from 'components/ContentContext/Interfaces';
import { ClientError } from 'graphql-request';
import gql from 'graphql-tag';
import { useBuildAuthenticatedQueryFn } from 'hooks/useGqlClient/useBuildAuthenticatedQueryFn';
import { Api } from 'lib/ApiConstants';
import { useQuery, UseQueryResult } from 'react-query';

/* eslint-disable import/namespace */
import { ValidEntityAction, AuthorizedActionsRequest, CONTENT_ENTITIES } from './types';

/* Retrieve ALL authorized actions for the given entity. */
const GET_AUTHORIZED_ENTITY_ACTIONS_QUERY = gql`
    query getAuthorizedEntityActions($entity: EntityInfoInput!) {
        getAuthorizedEntityActions(entity: $entity)
    }
`;

/* GraphQL Request interface*/
export interface AuthorizedEntityActionsResponse<T> {
    getAuthorizedEntityActions: T[];
}

/* Hook Interface */
export interface AuthorizedEntityActions<T> {
    actions: T[] | null; // Ideally this is an "EntityActions[]" type, but those are just string really?
    refetch: () => any;
}

/* GraphQL Hook to hit APIs */
const useGetAuthorizedEntityActions = <T extends ValidEntityAction>(
    variables: AuthorizedActionsRequest,
    options = {}
): UseQueryResult<AuthorizedEntityActionsResponse<T>, ClientError> => {
    const { queryFn } = useBuildAuthenticatedQueryFn<AuthorizedEntityActionsResponse<T>, AuthorizedActionsRequest>(
        GET_AUTHORIZED_ENTITY_ACTIONS_QUERY,
        variables,
        options
    );

    const queryKey = `useGetAuthorizedEntityActions-${variables.entity.id}-${variables.entity}`;

    return useQuery<AuthorizedEntityActionsResponse<T>, ClientError>(queryKey, queryFn, options);
};

/**
 * Exposed hook for internal consumption.
 * Retrieve list of Actions that User can perform on `entity`
 */
export const useAuthorizedEntityActions = <T extends ValidEntityAction>(
    entity: EntityInfoInput
): AuthorizedEntityActions<T> => {
    const [actions, setActions] = useState<T[]>([]);
    /* Revamp this logic when adding a 3rd API. */
    const targetApi = CONTENT_ENTITIES.includes(entity.type) ? Api.Content : Api.Accounts;
    const enabled = entity.id === null ? false : true;

    /* Return a list of the appropriate actions. */
    const { data, status, refetch } = useGetAuthorizedEntityActions({ entity }, { api: targetApi, enabled });

    useEffect(() => {
        if (status === 'success' && data?.getAuthorizedEntityActions) {
            setActions(data.getAuthorizedEntityActions as T[]);
        }
    }, [data, status, actions]);

    useEffect(() => {
        refetch();
    }, [entity, refetch]);

    return { actions, refetch };
};
