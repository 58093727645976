import React, { useContext } from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { Collapse } from '@material-ui/core';
import { mdiChevronDown } from '@mdi/js';
import Icon from '@mdi/react';
import styled from 'styled-components';

import AddCommentInput from './AddCommentInput';
import { CommentContext } from './CommentContext';
import { Comment, Section } from './interfaces';
import Thread from './Thread';

const Container = styled.div`
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
`;

const Header = styled.div`
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    padding: 1rem;
`;

const Title = styled.div`
    flex: 6 1 80%;
    margin-right: 2rem;
    font-weight: bold;
`;

const Count = styled.div`
    flex: 1 0 30px;
`;

const Control = styled.div<{ collapsed: boolean }>`
    display: flex;
    align-items: center;
    flex: 1 0 24px;
    transform: ${(props) => (props.collapsed ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: transform 0.1s ease-out;

    svg {
        width: ${IconSize.Medium};
        height: ${IconSize.Medium};
    }
`;

interface SectionProps {
    section: Section;
    comments: Comment[];
}

const CommentSection: React.FC<SectionProps> = ({ section, comments }) => {
    const { setParent, activeSection, setActiveSection } = useContext(CommentContext);

    const handleClick = () => {
        if (activeSection === section.id) {
            setActiveSection(null);
            setParent('');
            return;
        }

        setActiveSection(section.id);
        setParent(section.id);
    };

    return (
        <Container>
            <Header onClick={handleClick}>
                <Title>{section.title}</Title>
                <Count>{comments.length > 0 ? comments.length : ' '}</Count>
                <Control collapsed={activeSection === section.id}>
                    <Icon path={mdiChevronDown} />
                </Control>
            </Header>
            <Collapse in={activeSection === section.id}>
                <Thread comments={comments} />
                <AddCommentInput />
            </Collapse>
        </Container>
    );
};

export default CommentSection;
