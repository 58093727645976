import * as yup from 'yup';

import { MessageEventType } from '../..';
import AckableAdepteductMessage from '../../../base/AckableAdepteductMessage';
import PossibleEventFields from '../../PossibleEventFields';

export interface ReactionsByTarget {
    [target: string]: {
        [reaction: string]: number;
    };
}

export interface MessageFieldsChannelMeetingReaction extends PossibleEventFields {
    sessionId: string;
    reactions: ReactionsByTarget;
}

export default class ChannelMeetingSessionReactionBatch extends AckableAdepteductMessage<MessageFieldsChannelMeetingReaction> {
    schema = yup.object({
        sessionId: yup.string(),
        reactions: yup.object<ReactionsByTarget>()
    });

    type = MessageEventType.ChannelMeetingSessionReactionBatch;
}
