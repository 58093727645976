import React, { useContext } from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { Modal, Paper } from '@material-ui/core';
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { useSnackbar } from 'notistack';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import styled from 'styled-components';

import { modularScale } from '../../../utils/modular_scale';
import { COMPONENT_SORT_AREA } from '../constants';
import { EditMode, EditorContext } from '../context/EditorContext';
import { SortableContext } from '../context/SortableContext';

import ReorderComponentList from './ReorderComponentList';

const ComponentList = styled.div``;

const ModalMask = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.22s;
    z-index: 2;
`;

const ModalWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
`;

const ModalContainer = styled(Paper)`
    width: 500px;
    background-color: ${(props) => props.theme.colors.surface};
    border-radius: 4px;
    z-index: 3;
    max-height: 75vh;
    overflow: auto;
`;

const ModalHeader = styled.div`
    display: flex;
    align-items: center;
    padding: 1.5rem 2rem;
`;

const Title = styled.h2`
    font-weight: 600;
    font-size: ${modularScale(2)};
`;

const CloseButton = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
    cursor: pointer;

    svg {
        width: ${IconSize.Large};
    }
`;

const ReorderModal: React.FC = () => {
    const { onDragEnd } = useContext(SortableContext);
    const { clearEditMode, editMode } = useContext(EditorContext);
    const { enqueueSnackbar } = useSnackbar();

    const handleClose = () => {
        clearEditMode();
    };

    const performOnDragEnd = (result: DropResult): void => {
        try {
            onDragEnd(result);
        } catch (error) {
            enqueueSnackbar(error.message, { variant: 'warning' });
        }
    };

    return (
        <>
            <Modal
                open={editMode === EditMode.Reorder}
                onClose={handleClose}
                aria-labelledby="reorder-components-modal"
                BackdropComponent={ModalMask}
            >
                <ModalWrapper>
                    <ModalContainer elevation={3}>
                        <ModalHeader>
                            <Title>Reorder Skill Components</Title>
                            <CloseButton onClick={handleClose}>
                                <Icon path={mdiClose} />
                            </CloseButton>
                        </ModalHeader>
                        <DragDropContext onDragEnd={performOnDragEnd}>
                            <Droppable droppableId="components">
                                {(provided) => (
                                    <ComponentList
                                        id={COMPONENT_SORT_AREA}
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        <ReorderComponentList />
                                        {provided.placeholder}
                                    </ComponentList>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </ModalContainer>
                </ModalWrapper>
            </Modal>
        </>
    );
};

export default ReorderModal;
