import React, { useContext } from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { mdiAutorenew, mdiCloudCheckOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { EditMode, EditorContext } from 'components/builder/context/EditorContext';
import { HeaderContext } from 'components/navigation/HeaderContext';
import { LearnEditContext } from 'pages/learn/LearnEditContext';
import styled from 'styled-components';
import { modularScale } from 'utils/modular_scale';

const AutoSaveIndicatorContainer = styled.div`
    display: flex;
    align-items: center;
    font-size: ${modularScale(-0.5)};
    color: ${(props) => props.theme.colors.textTertiary};

    svg {
        margin-right: 0.5rem;
    }
`;

export const AutoSaveIndicator: React.FC = () => {
    const learnEditContext = useContext(LearnEditContext);
    const { isSaving } = useContext(HeaderContext);
    const { editMode } = useContext(EditorContext);

    const shouldRender =
        (learnEditContext && learnEditContext.skillEditId) || (!learnEditContext && editMode !== EditMode.Preview);

    return shouldRender ? (
        <AutoSaveIndicatorContainer data-testid="auto-save-indicator">
            <Icon path={isSaving ? mdiAutorenew : mdiCloudCheckOutline} size={IconSize.Small} />
            {isSaving ? 'Saving...' : 'Saved'}
        </AutoSaveIndicatorContainer>
    ) : null;
};
