import { gql, GraphQLClient } from 'graphql-request';
import buildGqlMutationFn from 'hooks/useGqlClient/helpers/buildGqlMutationFn';
import { MutationFunction } from 'react-query';

import { UpdateComponentsOrderResult, UpdateComponentsOrderVariables } from '../../interfaces';

export const UPDATE_SKILL_COMPONENTS_ORDER = gql`
    mutation updateSkillComponentsOrder($skillId: UUIDv4!, $componentsToOrder: JSON!) {
        updateSkillComponentsOrder(skillId: $skillId, componentsToOrder: $componentsToOrder) {
            components {
                items
            }
        }
    }
`;

const makeUpdateSkillComponentsOrder = (
    client: GraphQLClient
): MutationFunction<UpdateComponentsOrderResult, UpdateComponentsOrderVariables> =>
    buildGqlMutationFn(client, UPDATE_SKILL_COMPONENTS_ORDER);

export default makeUpdateSkillComponentsOrder;
