import React from 'react';

import { Modal as AdeptModal } from 'components/modals';

import { Controls } from './Controls';
import { Crop } from './Crop';
import { Dropzone } from './Dropzone';
import { HelpText } from './HelpText';
import { UploadProgress } from './UploadProgress';
import { CropDetails } from './useCrop';
import { UploadWithCropStatus, useUploadWithCrop } from './useUploadWithCrop';

const ACCEPTED_FILE_EXTENSIONS = ['.jpg', '.jpeg', '.png'];
const MAX_FILE_SIZE = 10485760; // 10MB
const DEFAULT_MAX_WIDTH = '500px';

export interface ModalProps {
    open: boolean;
    onClose: () => void;
    onFinishUpload: (assetId: string, image: string | null, crop: CropDetails) => void;
    attributes?: Record<string, unknown>;
    aspectRatio?: number | null;
    circularCrop?: boolean;
    resolution?: {
        height?: number | null;
        width?: number | null;
    };
    maxWidth?: string | null;
}

/**
 * This component is responsible for composing the display of the dropzone/crop/preview/update
 */
export const Modal: React.FC<ModalProps> = ({
    open,
    onClose,
    onFinishUpload,
    attributes,
    aspectRatio,
    circularCrop,
    resolution,
    maxWidth = DEFAULT_MAX_WIDTH
}) => {
    const { getDropzoneProps, getInputProps, status, uploadProgress, save, setCrop, originalImage } = useUploadWithCrop(
        {
            acceptedFileExtensions: ACCEPTED_FILE_EXTENSIONS,
            maxFileSize: MAX_FILE_SIZE,
            uploadIntentAttributes: attributes,
            onFinishUpload
        }
    );

    return (
        <AdeptModal
            open={open}
            onClose={onClose}
            confirmButtonText="Save"
            onConfirm={save}
            modalTitle="Upload Image"
            styleProps={{ 'max-width': maxWidth ?? DEFAULT_MAX_WIDTH }}
            confirmButtonDisabled={status === UploadWithCropStatus.Uploading || status === UploadWithCropStatus.None}
        >
            <>
                {status === UploadWithCropStatus.Uploading ? (
                    <UploadProgress progress={uploadProgress}>Preparing file for upload</UploadProgress>
                ) : null}
                {status === UploadWithCropStatus.None ? (
                    <>
                        <Dropzone dropzoneProps={getDropzoneProps()} inputProps={getInputProps()}>
                            <Controls />
                        </Dropzone>
                        <HelpText
                            acceptedFileExtensions={ACCEPTED_FILE_EXTENSIONS}
                            maxFileSize={MAX_FILE_SIZE}
                            resolution={resolution}
                        />
                    </>
                ) : null}
                {[UploadWithCropStatus.Crop, UploadWithCropStatus.FileSelected].includes(status) ? (
                    <Crop
                        imageSrc={originalImage}
                        onCropUpdated={setCrop}
                        aspectRatio={aspectRatio}
                        circularCrop={circularCrop}
                    />
                ) : null}
            </>
        </AdeptModal>
    );
};
