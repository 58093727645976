import React from 'react';

import { TextEditInPlaceProps } from './TextEditInPlace';
import { TextEditInPlaceWithState } from './TextEditInPlaceWithState';

/**
 * Use this component for edit in place if you have many fields in your component using TextEditInPlace. This will allow
 * each to have its own state to reflect their current editing status.
 *
 * @TODO Coordinate amongst many fields to only have one editing at a time. Also track dirty state amongst all usages.
 */
export const EditorManyFieldsTextEditInPlace: React.FC<TextEditInPlaceProps> = ({
    children,
    onStartEditing = () => {},
    onStopEditing = () => {},
    ...theRest
}) => {
    return (
        <TextEditInPlaceWithState
            onStartEditing={() => {
                onStartEditing();
            }}
            onStopEditing={() => {
                onStopEditing();
            }}
            {...theRest}
        >
            {children}
        </TextEditInPlaceWithState>
    );
};
