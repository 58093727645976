import React from 'react';

import { StickyContainer, StickyInnerContainer } from 'components/NestedCollection/NestedCollection.styles';

export interface StickyActionsProps {
    visible?: boolean;
}

export const StickyCollectionActions: React.FC<StickyActionsProps> = ({ visible, children }) => {
    return (
        <StickyContainer $visible={visible}>
            <StickyInnerContainer>{children}</StickyInnerContainer>
        </StickyContainer>
    );
};
