import React from 'react';

import { Helmet } from 'react-helmet';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    background-color: ${(props) => props.theme.colors.surface};
`;

const Content = styled.div`
    display: flex;
    width: 100%;
    min-height: 100vh;
    flex-direction: column;
`;

const MainLayout: React.FC = ({ children }) => {
    return (
        <>
            <Helmet>
                <title>Adept</title>
                <link rel="canonical" key="canonical" href={process.env.host} />
                <meta property="og:url" key="ogUrl" content={process.env.host} />
                <link rel="icon" href="/favicon.ico" />
            </Helmet>
            <Container>
                <Content>{children}</Content>
            </Container>
        </>
    );
};

export { MainLayout };
