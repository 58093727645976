import React, { useMemo } from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { Button } from '@material-ui/core';
import { mdiLocationEnter } from '@mdi/js';
import { Icon } from '@mdi/react';
import styled from 'styled-components';

const StyledAvailabilityButton = styled(Button)<{ $isEditMode?: boolean }>`
    margin-right: 0.5rem;

    &&& {
        padding: 6px 15px;
        width: max-content;
    }
`;

interface AvailabilityButtonProps {
    onClick: () => void;
    yearlyPrice?: number;
    isListed?: boolean;
    outlined?: boolean;
}

export const AvailabilityButton: React.FC<AvailabilityButtonProps> = ({ onClick, yearlyPrice, isListed, outlined }) => {
    const listingText = useMemo(() => (isListed ? 'Listed' : 'Unlisted'), [isListed]);
    const priceText = useMemo(() => (yearlyPrice ? `$${yearlyPrice} / year` : 'No subscriptions'), [yearlyPrice]);

    return (
        <>
            <StyledAvailabilityButton
                onClick={onClick}
                variant={outlined ? 'outlined' : 'text'}
                color={outlined ? 'secondary' : 'primary'}
                startIcon={<Icon path={mdiLocationEnter} size={IconSize.Small} />}
            >
                {`${listingText} | ${priceText}`}
            </StyledAvailabilityButton>
        </>
    );
};
