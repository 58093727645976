const SKIPPED_STEPS_STORAGE_KEY = 'storyboard-skips';

const getSkips = (): string[] => {
    const skipsFromStorage = localStorage.getItem(SKIPPED_STEPS_STORAGE_KEY);

    if (skipsFromStorage) {
        try {
            return JSON.parse(skipsFromStorage);
        } catch (e) {
            return [];
        }
    }

    return [];
};

const addSkip = (skillId: string): void => {
    const currentSkips = getSkips();

    localStorage.setItem(SKIPPED_STEPS_STORAGE_KEY, JSON.stringify([...currentSkips, skillId]));
};

interface UseSkippedStorageInterface {
    hasSkippedForSkill: (skillId: string) => boolean;
    addSkipForSkill: (skillId: string) => void;
}

export const useSkippedStorage = (): UseSkippedStorageInterface => {
    return {
        addSkipForSkill: (skillId: string): void => addSkip(skillId),
        hasSkippedForSkill: (skillId: string): boolean => getSkips().includes(skillId)
    };
};
