import React, { useEffect, useState, useMemo } from 'react';

import { IconSize, modularScale } from '@adept-at/lib-react-components';
import { Avatar, Chip, Collapse, Popover, Tooltip } from '@material-ui/core';
import Icon from '@mdi/react';
import EditMessageForm from 'components/communication/EditMessageForm';
import { useChatContext } from 'components/communication/Notifier/ChatContext';
import { isOptimisticMessage } from 'context/RealTimeCommunicationContext';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import { usePopover } from 'hooks/usePopover';
import useUsersDetails from 'hooks/useUserDetails';
import { MessageEntityTypeFromStandard } from 'lib/communication/message/MessageRecord';
import styled from 'styled-components';

import { AddEmojiPath } from '../../../icons/AddEmoji';
import { formatDateForMessageHeader } from '../../functions';
import AdepteductMessageProps from '../AdepteductMessageProps';

import ChatActionTooltip, { useTooltipStyles } from './ChatActionTooltip';
import Reaction from './Reaction';
import ThreadButton from './ThreadButton';
import useToggleReaction from './useToggleReaction';

const ChatMessageContainer = styled.div<{ $hidden: boolean }>`
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    flex-wrap: nowrap;
    padding: 4px 0;

    &:hover button[id^='reaction-picker-'] {
        visibility: visible;
    }

    button[id^='reaction-picker-'] {
        visibility: hidden;

        &:hover {
            background-color: ${(props) => props.theme.colors.surface} !important;
        }

        span {
            display: flex;
        }
    }

    &:hover {
        background: ${(props) => props.theme.colors.componentHighlight};
    }

    ${(props) =>
        props.$hidden
            ? `
            transition: all 1s ease;
            opacity: 0;
            background-color: red;
    `
            : null}
`;

const StyledAvatar = styled(Avatar)`
    width: 40px;
    height: 40px;
    margin: 8px 8px;
    position: initial;
`;

const MessageBody = styled.div`
    flex-direction: column;
    flex-grow: 1;
    display: flex;
    font-size: ${modularScale(-0.5)};
    justify-content: center;
`;

const MessageMetadata = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 4px;
    row-gap: 4px;
`;

const MessageUser = styled.span`
    font-weight: bold;
    display: flex;
    align-items: center;
`;

const MessageTime = styled.span`
    color: ${(props) => props.theme.colors.textSecondary};
    white-space: pre;
    margin: 0 4px;
`;

const MessageContentContainer = styled.div`
    display: flex;
    flex-direction: row;

    &:last-child {
        margin-bottom: 0;
    }
`;

const MessageContent = styled.div`
    width: calc(100% - 0.2rem - 0.2rem);
    display: table;
    table-layout: fixed;
    word-wrap: break-word;
`;

const StyledIcon = styled(Icon)`
    width: ${IconSize.Small};
`;

const StandardChatMessage = React.forwardRef<HTMLDivElement | null, AdepteductMessageProps>(
    ({ message, children }, ref) => {
        const { threadParentId, setThreadParentId, openDeleteModal } = useChatContext();
        const classes = useTooltipStyles();

        const [isEditing, setIsEditing] = useState(false);
        const { mutate: toggleReaction } = useToggleReaction();

        const { handlePopoverClick, handlePopoverClose, open, anchorEl } = usePopover();

        const { senderId, sentAt } = message;

        const [senderDisplayName, setSenderDisplayName] = useState<string>('');
        const [senderImageUrl, setSenderImageUrl] = useState<string | null>(null);

        const userIds = useMemo(() => [senderId], [senderId]);
        const { usersDetails } = useUsersDetails({ userIds });

        useEffect(() => {
            // No op if we already have details for this user
            if (senderDisplayName && senderImageUrl) {
                return;
            }

            const senderDetails = usersDetails[message.senderId];

            if (!senderDetails) {
                return;
            }

            if (senderDetails.alias) setSenderDisplayName(senderDetails.alias);
            setSenderImageUrl(senderDetails.imageUrl ?? null);
        }, [usersDetails, message.senderId, senderDisplayName, senderImageUrl]);

        const formattedDate = formatDateForMessageHeader(sentAt);

        const handleReactionSelect = (emoji) => {
            toggleReaction({
                entityType: MessageEntityTypeFromStandard[message.entityType],
                entityId: message.channelId,
                messageId: message.id,
                reaction: emoji.colons,
                hasParent: !!message.parent
            });
            handlePopoverClose();
        };

        const messageHidden = !!message.deletedAt && message.responses < 1 && threadParentId !== message.id;

        /**
         * @TODO conditionally skip header if previous message was the same author or within appropriate
         * time threshold
         */

        return (
            <Collapse in={!messageHidden} timeout={500}>
                <Tooltip
                    interactive
                    classes={{ tooltip: classes.tooltip, popper: classes.popper }}
                    title={
                        <ChatActionTooltip
                            senderId={senderId}
                            onEditClick={() => setIsEditing(true)}
                            onReplyClick={() => setThreadParentId(message.id)}
                            onDeleteClick={() => openDeleteModal(message)}
                            canReply={!threadParentId && !isOptimisticMessage(message)}
                            canModify={!message.deletedAt && !isOptimisticMessage(message)}
                        />
                    }
                    placement="top-end"
                    PopperProps={{
                        disablePortal: true,
                        modifiers: { preventOverflow: { enabled: false }, hide: { enabled: false } }
                    }}
                >
                    <ChatMessageContainer ref={ref} $hidden={messageHidden}>
                        <StyledAvatar src={senderImageUrl ?? undefined} />
                        <MessageBody>
                            {isEditing ? (
                                <EditMessageForm message={message} stopEditing={() => setIsEditing(false)} />
                            ) : (
                                <>
                                    <MessageMetadata>
                                        <MessageUser>
                                            {usersDetails[senderId] && usersDetails[senderId].alias}
                                        </MessageUser>
                                        <MessageTime>{formattedDate}</MessageTime>
                                        {Object.entries(message.reactions ?? {}).map(([reaction, users]) => (
                                            <Reaction
                                                key={reaction}
                                                reaction={reaction}
                                                users={users}
                                                handleReactionSelect={handleReactionSelect}
                                            />
                                        ))}
                                        <Chip
                                            id={`reaction-picker-${message.id}`}
                                            variant="outlined"
                                            size="small"
                                            label={<StyledIcon path={AddEmojiPath} />}
                                            component="button"
                                            onClick={handlePopoverClick}
                                            aria-label="Add reaction"
                                        />
                                        <Popover open={open} anchorEl={anchorEl} onClose={handlePopoverClose}>
                                            <Picker
                                                showSkinTones={false}
                                                showPreview={false}
                                                onSelect={handleReactionSelect}
                                            />
                                        </Popover>
                                    </MessageMetadata>
                                    <MessageContentContainer>
                                        <MessageContent>{children}</MessageContent>
                                    </MessageContentContainer>
                                    <ThreadButton message={message} />
                                </>
                            )}
                        </MessageBody>
                    </ChatMessageContainer>
                </Tooltip>
            </Collapse>
        );
    }
);

export default StandardChatMessage;
