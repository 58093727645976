import { useMemo } from 'react';

import { useGetEditableCollectionVersions } from './useGetEditableCollectionVersions';

export const useAreVersionsMatching = (
    expectedVersion: number,
    collectionId: string
): { isLoading: boolean; isMatching: boolean } => {
    const { data: versionData, isLoading } = useGetEditableCollectionVersions({ collectionId }, true);

    const isMatching = useMemo(() => {
        if (!versionData) return false;
        const {
            getEditableCollection: { itemsVersion }
        } = versionData;
        return itemsVersion === expectedVersion;
    }, [versionData, expectedVersion]);

    return { isLoading, isMatching };
};
