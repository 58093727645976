import { ComponentValues, LinkType, RichTextComponentValues } from '@adept-at/lib-react-components';
import { WorkingSkill } from 'components/builder/context/interfaces';
import { Skill } from 'components/ContentContext/Interfaces';
import { DraftJsBlock, DraftJsEntity } from 'components/engine/common/RichTextEditor/utils';
import { isSkillComplete } from 'components/learn/modes/accountability/utils';
import { ProgressState } from 'utils/progress';

export interface SkillSection {
    id: string;
    title: string;
    duration: number;
    questions: number;
    labs: number;
    videos: number;
    comments: number;
    status: ProgressState;
    components: Component[];
    description?: string;
}

interface Component {
    id: string;
}

interface SectionDetails {
    id: string;
    title: string;
    skillComplete: boolean;
    duration?: number;
    description?: string;
}

const parseSections = (
    textComponent: RichTextComponentValues,
    commentsByParent: Record<string, Comment[]>,
    blockInfo: { key: string; estimatedSecondsToConsume: number }[],
    completedQuestions: string[],
    skillComplete: boolean
) => {
    const sections: SkillSection[] = [];
    const blocks = textComponent?.body?.val?.blocks ?? [];
    const completedBlocks: string[] = [];
    const entityMap = textComponent?.body?.val?.entityMap ?? {};

    let currentSection: SkillSection | undefined;
    let currentSectionIndex = -1;
    let previousBlockType = DraftJsBlock.ATOMIC;
    let blocksSinceLastQuestion: string[] = [];

    for (const block of blocks) {
        const info = blockInfo.find((b) => b.key === block.key);
        const blockHasText = !!block.text?.trim();

        blocksSinceLastQuestion.push(block.key);

        if (block.type === DraftJsBlock.H1 && blockHasText) {
            const section: SkillSection = defaultSection({
                id: block.key,
                title: block.text,
                skillComplete,
                description: block.data?.description,
                duration: info?.estimatedSecondsToConsume
            });

            if (commentsByParent[block.key]) {
                section.comments = commentsByParent[block.key].length;
            }

            sections.push(section);
            currentSection = section;
            currentSectionIndex += 1;
            previousBlockType = block.type;

            continue;
        }

        if (currentSectionIndex === -1 || !currentSection) {
            continue;
        }

        if (block.type === DraftJsBlock.ATOMIC && block.entityRanges.length > 0) {
            for (const entityInfo of block.entityRanges) {
                const entity = entityMap[`${entityInfo.key}`];

                if (entity?.type === DraftJsEntity.QUESTION) {
                    currentSection.questions++;
                    if (completedQuestions.includes(entity.data.questionId)) {
                        completedBlocks.push(...blocksSinceLastQuestion);
                    }
                    blocksSinceLastQuestion = [];
                }
                if (
                    entity?.type === DraftJsEntity.LAB ||
                    (entity.type === DraftJsEntity.LINK && entity.data.linkType === LinkType.LAB)
                ) {
                    currentSection.labs++;
                }
                if (entity?.type === DraftJsEntity.VIDEO) {
                    currentSection.videos++;
                }
            }
        }

        if (commentsByParent[block.key]) {
            currentSection.comments += commentsByParent[block.key].length;
        }

        currentSection.duration += info?.estimatedSecondsToConsume ?? 0;

        /*
         * Add to components if
         *  1. It's an atomic block (embedded component)
         *  2. The previous block was atomic or header-one and it's non-empty
         *     - This keeps us from counting contiguous text as more than one component
         */
        if (
            block.type === DraftJsBlock.ATOMIC ||
            ([DraftJsBlock.ATOMIC, DraftJsBlock.H1].includes(previousBlockType) && blockHasText)
        ) {
            currentSection.components.push({ id: block.key });
            previousBlockType = block.type as DraftJsBlock;
        }
    }

    if (!skillComplete) {
        for (const section of sections) {
            const blockKeysInSection = section.components.map(({ id }) => id);
            const { completed, total } = blockKeysInSection.reduce(
                (memo, key) => {
                    if (completedBlocks.includes(key)) {
                        memo.completed += 1;
                    }
                    memo.total += 1;
                    return memo;
                },
                { completed: 0, total: 0 }
            );

            if (completed > 0) {
                section.status = completed === total ? ProgressState.COMPLETE : ProgressState.IN_PROGRESS;
            }
        }
    }

    return sections;
};

/**
 * Steps through a list of components and returns an aggregate
 * of sections, their components, and certain stats about the
 * group of components in said section.
 *
 * @param skill
 */
export const getSections = (skill: Skill | WorkingSkill): SkillSection[] => {
    if (!skill) return [];

    const components = Object.values(skill.components ?? {}) as ComponentValues[];

    const allComments = skill.comments.public || [];
    const commentsByParent = organizeCommentsByParent(allComments);

    const textComponent = components[0] as RichTextComponentValues;

    const blockInfo = ('blocks' in skill ? skill.blocks : []) ?? [];
    const progress = 'progress' in skill ? skill.progress : undefined;
    const completedQuestions = progress?.completedQuestions ?? [];

    const skillComplete = isSkillComplete({ estimatedSecondsToConsume: skill.estimatedSecondsToConsume, progress });

    const sections = parseSections(textComponent, commentsByParent, blockInfo, completedQuestions, skillComplete);

    return sections;
};

function defaultSection(component: SectionDetails): SkillSection {
    return {
        id: component.id || '',
        title: component.title,
        duration: component.duration ?? 0,
        questions: 0,
        videos: 0,
        labs: 0,
        comments: 0,
        status: component.skillComplete ? ProgressState.COMPLETE : ProgressState.NOT_STARTED,
        description: component.description,
        components: []
    };
}

interface Comment {
    parent: string;
}

function organizeCommentsByParent(allComments: Comment[]): Record<string, Comment[]> {
    return allComments.reduce((map, comment) => {
        const key = comment.parent;

        if (!map[key]) {
            map[key] = [comment];
            return map;
        }

        map[key].push(comment);
        return map;
    }, {});
}
