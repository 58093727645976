import { AppBar, Tabs, Tab, TableRow, TableCell } from '@material-ui/core';
import styled from 'styled-components';

export const OverlayContainer = styled.div`
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    font-size: 14px;
    z-index: 1000;
    padding: 2px;
    color: #fefefe;
    background: #000;
`;

export const StyledContent = styled.div`
    height: 200px;
    overflow-y: scroll;
`;

export const StyledAppBar = styled(AppBar)`
    display: flex;
    flex-direction: row;
    background: #000;
`;

export const StyledTabs = styled(Tabs)`
    flex: 1 1 auto;

    & .indicator {
        display: flex;
        justify-content: center;
        background-color: transparent;

        & > span {
            max-width: 180px;
            width: 100%;
            background-color: #fefefe;
        }
    }
`;

export const StyledTab = styled(Tab)`
    background: #333;
    opacity: 1;

    &.Mui-selected {
        background: #000;
    }
`;

export const StyledTableRow = styled(TableRow)`
    padding: 5px;
    background: #333;

    &:nth-of-type(odd) {
        background-color: #1d1d1d;
    }
`;

export const StyledTableCell = styled(TableCell)`
    font-size: 14px;
    font-family: monospace;
    color: #fefefe;

    border-bottom: 1px solid #000;
`;
