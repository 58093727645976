import React, { useContext } from 'react';

import Responder from './Responder';
import { StoryboardContext } from './StoryboardContext';

const Why: React.FC = () => {
    const { why, setWhy } = useContext(StoryboardContext);

    const prompts = [
        'What is the learner about to learn?',
        'Why does the learner want to learn that?',
        'What will the learner be able to do with this new knowledge/ ability?',
        'The “Why” statement: Present an obstacle or a challenge and then position your content as the solution.'
    ];

    return <Responder onChange={setWhy} defaults={why} prompts={prompts} stepName="Why" nextStepName="Who" />;
};

export default Why;
