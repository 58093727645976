import React, { useContext, useMemo } from 'react';

import { defaultMuiTheme } from '@adept-at/lib-react-components';
import { EntityType } from 'components/ContentContext/Enums';
import { ComponentEngineMode, EngineProvider } from 'components/engine';
import styled from 'styled-components';
import { SKILL_CONTENT_WIDTH } from 'styles/variables';

import { AutoProgress } from '../AutoProgress';
import SkillCompletionSection from '../components/SkillCompletionSection';
import { LearnContext } from '../LearnContext';
import { useAccountabilityContext } from '../modes/accountability/AccountabilityContext';
import { useAssessmentContext } from '../modes/assessment/AssessmentContext';

import MetricsPixel from './MetricsPixel';
import Sections from './Sections';
import { SkillHeader } from './SkillHeader';

const Container = styled.div`
    ${defaultMuiTheme.breakpoints.up('sm')} {
        padding: 0 0 2rem 0;
    }

    ${defaultMuiTheme.breakpoints.up('md')} {
        margin: 0 auto;
    }
`;

const SkillHeaderContainer = styled.div`
    max-width: ${SKILL_CONTENT_WIDTH}px;
    margin: 1rem auto 0 auto;
`;

const Section = styled.div`
    background-color: ${(props) => props.theme.colors.surface};
    padding: 0 0 1rem 0;

    ${defaultMuiTheme.breakpoints.up('sm')} {
        padding: 0;
        margin-bottom: 2rem;
    }
`;

const Skill: React.FC = () => {
    const { skill, refetchSkill } = useContext(LearnContext);

    const { accountabilityModeOn } = useAccountabilityContext();
    const { assessmentModeOn } = useAssessmentContext();

    const engineMode = useMemo(() => {
        if (accountabilityModeOn) {
            return ComponentEngineMode.Accountability;
        }

        if (assessmentModeOn) {
            return ComponentEngineMode.Assessment;
        }

        return ComponentEngineMode.View;
    }, [accountabilityModeOn, assessmentModeOn]);

    return (
        <Container>
            <EngineProvider
                container={{ type: EntityType.Skill, id: skill.skillId }}
                containerRefetch={refetchSkill}
                components={skill.components ?? {}}
                mode={engineMode}
            >
                <Section>
                    <SkillHeaderContainer>
                        <SkillHeader />
                    </SkillHeaderContainer>
                </Section>
                <Sections skill={skill} />
                <SkillCompletionSection />
                <AutoProgress />
                <MetricsPixel />
            </EngineProvider>
        </Container>
    );
};

export default Skill;
