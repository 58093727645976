import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react';

import { SkillImage, IconSize, Collaborators, FlatChip } from '@adept-at/lib-react-components';
import { Avatar, Button } from '@material-ui/core';
import { mdiPencilOff } from '@mdi/js';
import Icon from '@mdi/react';
import { EditMode, EditorContext } from 'components/builder/context/EditorContext';
import { WorkflowStatus } from 'components/CollaborationTable';
import { WorkflowChip } from 'components/CollaborationTable/WorkflowChip';
import { CommentContext } from 'components/Comments/CommentContext';
import { CommentAccess } from 'components/Comments/interfaces';
import { EntityType } from 'components/ContentContext/Enums';
import { Skill } from 'components/ContentContext/Interfaces';
import { useComponentEngine, ComponentEngineMode } from 'components/engine';
import { EditableText } from 'components/forms/EditableText';
import { Attributes, Info } from 'components/skills/Card.styles';
import { format } from 'date-fns';
import { LearnEditContext } from 'pages/learn/LearnEditContext';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { modularScale } from 'utils/modular_scale';

import { CardImageBorder, HeaderContainer, Section, UploadButton } from '../../Builder.styles';
import { useUpdateSkillMeta } from '../../context/hooks/useUpdateSkillMeta';
import InlineUploadImage, { ImageDestination, ImageDestinationToType } from '../../InlineUploadImage';

import { AvailabilityButton } from './AvailabilityButton';
import { CollaboratorsButton } from './CollaboratorsButton';
import { Subtitle } from './Subtitle';
import { useSkillCollaborators } from './useSkillCollaborators';
import { useSkillOwner } from './useSkillOwner';

export const MyCreationsLink = styled(Link)`
    align-items: center;
    color: ${(props) => props.theme.colors.primary};
    display: flex;
    font-size: ${modularScale(-0.51)};
    svg {
        fill: ${(props) => props.theme.colors.primaryLighter};
        width: ${IconSize.Small};
    }
`;

const StyledAvatar = styled(Avatar)`
    width: ${IconSize.Medium};
    height: ${IconSize.Medium};
`;

const ActionButtons = styled.div`
    button {
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        flex-shrink: 0;
    }

    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const SkillAttributes = styled(Attributes)`
    height: auto;
    align-items: flex-start;
`;

const MAX_TITLE_CHARACTERS = 50;

const SkillHeader: React.FC = () => {
    const {
        workingSkill,
        updateWorkingSkillMetadata,
        updateWorkingSkillImages,
        editMode,
        clearEditMode,
        previewSkill,
        toggleComponentEngineEditMode,
        isPreview
    } = useContext(EditorContext);
    const learnEditContext = useContext(LearnEditContext);
    const { setContextAccessType } = useContext(CommentContext);

    const onToggleEdit = useCallback(() => {
        if (learnEditContext?.setSkillEditId) {
            return learnEditContext.setSkillEditId('');
        }

        if (isPreview()) {
            setContextAccessType(CommentAccess.Private);
            toggleComponentEngineEditMode();
            return clearEditMode();
        }

        setContextAccessType(CommentAccess.Public);
        toggleComponentEngineEditMode();
        previewSkill();
    }, [clearEditMode, isPreview, previewSkill, setContextAccessType, toggleComponentEngineEditMode, learnEditContext]);

    const [tempCatalogImageURL, setTempCatalogImageURL] = useState<string | null>(null);

    const [title, setTitle] = useState(workingSkill.title || '');
    const [subtitle, setSubtitle] = useState(workingSkill.subtitle);

    const { mode } = useComponentEngine();
    const canEditSkillTitle = useMemo(() => mode === ComponentEngineMode.Edit, [mode]);

    useEffect(() => {
        if (subtitle !== workingSkill.subtitle) {
            updateWorkingSkillMetadata({ subtitle: subtitle ?? '' });
        }
    }, [updateWorkingSkillMetadata, subtitle, workingSkill.subtitle]);

    useEffect(() => {
        if (title !== workingSkill.title) {
            updateWorkingSkillMetadata({ title });
        }
    }, [updateWorkingSkillMetadata, title, workingSkill.title]);

    const { mutate: updateImages, isSuccess } = useUpdateSkillMeta({
        skillId: workingSkill.id,
        skillSlug: workingSkill?.skillSlug,
        tenantSlug: workingSkill?.tenantSlug
    });

    const [imageModalOpen, setImageModalOpen] = useState(false);

    const onFinishImageUpload = async (assetId, imageDataURL, imageKey) => {
        if (imageDataURL) {
            if (imageKey === ImageDestination.SkillCatalog) {
                setTempCatalogImageURL(imageDataURL);
            }
        }

        const skillId = workingSkill.id;

        const images = {
            catalog: workingSkill.images?.catalog?.id || null,
            featured: workingSkill.images?.featured?.id || null,

            /* overwrite whichever is being used */
            [ImageDestinationToType[imageKey]]: assetId
        };

        updateImages({ skillId, images });
    };

    useEffect(() => {
        if (isSuccess && tempCatalogImageURL) {
            updateWorkingSkillImages(tempCatalogImageURL, 'catalog');
        }
    }, [isSuccess, tempCatalogImageURL, updateWorkingSkillImages]);

    const onRemoveImage = () => {
        const images = {
            catalog: null,
            featured: workingSkill.images?.featured?.id || null
        };

        setTempCatalogImageURL(null);
        updateImages({ skillId: workingSkill.id, images });
    };

    const skill: Skill = {
        ...workingSkill,
        skillId: workingSkill.id,
        images: null,
        comments: { public: [], private: [] }
    };

    const collaborators = useSkillCollaborators(skill.authors);
    const { name, avatarSrc } = useSkillOwner(skill.owner);

    return (
        <HeaderContainer>
            <Section>
                <Info>
                    <InlineUploadImage
                        imageModalOpen={imageModalOpen}
                        setImageModalOpen={setImageModalOpen}
                        aspectRatio={1}
                        imageURL={tempCatalogImageURL}
                        onFinishUpload={onFinishImageUpload}
                        imageKey={ImageDestination.SkillCatalog}
                        tags={['image', ImageDestination.SkillCatalog]}
                    >
                        <CardImageBorder>
                            <SkillImage.Large image={workingSkill?.images?.catalog} placeholderImage={''} />
                        </CardImageBorder>
                    </InlineUploadImage>
                    {editMode !== EditMode.Preview ? (
                        <UploadButton
                            onReplaceImage={() => setImageModalOpen(true)}
                            onRemoveImage={onRemoveImage}
                            hasExistingImage={!!(workingSkill?.images?.catalog || tempCatalogImageURL)}
                        />
                    ) : null}
                </Info>
                <SkillAttributes>
                    <FlatChip.UppercaseTertiary
                        avatar={
                            <StyledAvatar src={avatarSrc} alt="Content owner logo">
                                {name?.charAt(0)?.toUpperCase()}
                            </StyledAvatar>
                        }
                        label={name}
                    />
                    <EditableText
                        modularScaleValue={3}
                        saveContent={setTitle}
                        initialContent={title}
                        validation={(content) => !!content || content === ''}
                        maxCharacters={MAX_TITLE_CHARACTERS}
                        canEdit={canEditSkillTitle}
                    />
                    <Subtitle subtitle={subtitle} setSubtitle={setSubtitle} canEdit={canEditSkillTitle} />
                    <Collaborators
                        collaborators={collaborators}
                        createdAt={skill.createdAt ? format(new Date(skill.createdAt), 'LLLL d, yyyy') : ''}
                        estimatedSecondsToConsume={skill.estimatedSecondsToConsume ?? 0}
                        parentTitle={skill.title}
                    />
                    {editMode !== EditMode.Preview ? (
                        <ActionButtons>
                            <div>
                                <CollaboratorsButton skill={skill} />
                                <AvailabilityButton skill={skill} />
                                <WorkflowChip
                                    variant="button"
                                    id={skill.skillId}
                                    type={EntityType.SKILL}
                                    owner={skill.owner}
                                    workflow={skill.workflow ? WorkflowStatus[skill.workflow] : undefined}
                                />
                            </div>
                            <Button
                                startIcon={<Icon path={mdiPencilOff} size={IconSize.Small} />}
                                onClick={onToggleEdit}
                            >
                                {mode === ComponentEngineMode.Edit ? 'Stop editing' : 'Edit'}
                            </Button>
                        </ActionButtons>
                    ) : null}
                </SkillAttributes>
            </Section>
        </HeaderContainer>
    );
};

export default SkillHeader;
