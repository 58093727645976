import { OwnerInfo } from 'components/ContentContext/Interfaces';
import { gql, GraphQLClient } from 'graphql-request';
import { QueryFunction } from 'react-query';

import buildGqlQueryFn from '../../../../../../../hooks/useGqlClient/helpers/buildGqlQueryFn';

import { VideoProvider } from '.';

const GET_VIDEO_PLAYBACK_DETAILS = gql`
    query getVideoPlaybackDetails($videoId: UUIDv4!, $provider: SignedUrlProvider) {
        getVideoPlaybackDetails(videoId: $videoId, provider: $provider) {
            playbackUrl
            posterImageUrl
            owner {
                type
                id
            }
        }
    }
`;

export interface GetVideoPlaybackDetailsVariables {
    videoId: string;
    provider?: VideoProvider;
}

export interface GetVideoPlaybackDetailsResult {
    getVideoPlaybackDetails: {
        playbackUrl: string;
        posterImageUrl?: string;
        owner?: OwnerInfo;
    };
}

const getVideoPlaybackDetails = (
    client: GraphQLClient,
    variables: GetVideoPlaybackDetailsVariables
): QueryFunction<GetVideoPlaybackDetailsResult> =>
    buildGqlQueryFn<GetVideoPlaybackDetailsResult>(client, GET_VIDEO_PLAYBACK_DETAILS, variables);

export default getVideoPlaybackDetails;
