import React, { useState } from 'react';

import { ImageComponentValues } from '@adept-at/lib-react-components';

import { useComponentEngineComponent } from '../../';
import { useEditableComponent, ComponentFocus, EditButtons } from '../../mixins/editor';
import { useFocusableComponent } from '../../mixins/focusable';

import { ImageTypeEditContainer } from './Edit.styles';
import { ImageType } from './ImageTypeContext';
import { getEditForType, getTypeFromValues } from './ImageTypes';
import ImageTypeSelector from './ImageTypeSelector';

interface ImageEditProps {
    onCancel?: () => void;
    onSave?: (values: Partial<ImageComponentValues>) => void;
}

const ImageEdit: React.FC<ImageEditProps> = ({ onCancel, onSave }) => {
    const { id } = useComponentEngineComponent();

    const { doesComponentHaveAnyFocus } = useFocusableComponent();

    const {
        currentValues,
        onSave: defaultOnSave,
        onCancel: defaultOnCancel,
        onChange,
        onChangeMany,
        onChangeAndSave
    } = useEditableComponent<ImageComponentValues>();

    const save = () => {
        if (onSave) {
            const { image, caption, profile, photographer, imageUrl } = currentValues;
            return onSave({ image, caption, profile, photographer, imageUrl });
        }

        if (type === ImageType.Uploaded) {
            defaultOnSave(['assetId', 'caption']);
        } else {
            defaultOnSave();
        }
    };

    const [type, setType] = useState(() => getTypeFromValues(currentValues));

    if (!type) {
        return (
            <>
                <ImageTypeSelector onSelect={(selectedType) => setType(selectedType)} />
                <EditButtons onCancel={onCancel ?? defaultOnCancel} />
            </>
        );
    }

    const ImageTypeEdit = getEditForType(type);

    return (
        <>
            <ImageTypeEditContainer>
                <ImageTypeEdit
                    values={currentValues}
                    onCancel={() => setType(null)}
                    onChange={onChange}
                    onChangeMany={onChangeMany}
                    // if custom onSave handler, only call onChange instead of onChangeAndSave
                    onChangeAndSave={onSave ? onChange : onChangeAndSave}
                />
            </ImageTypeEditContainer>

            {doesComponentHaveAnyFocus(id, [ComponentFocus.Edit, ComponentFocus.AddAndEdit]) ? (
                <EditButtons
                    onCancel={onCancel ?? defaultOnCancel}
                    onSave={currentValues.image || currentValues.imageUrl ? save : undefined}
                />
            ) : null}
        </>
    );
};

export default ImageEdit;
