import { useMemo } from 'react';

import { useAuth } from 'context/AuthContext';
import { GraphQLClient } from 'graphql-request';
import { ApiName } from 'lib/ApiConstants';

import buildGqlClient from './buildGqlClient';
import useGqlReactQueryOptions, { UseGqlReactQueryOptionsOutput } from './useGqlReactQueryOptions';

export enum GqlClientStatus {
    NotReady = 'NotReady',
    Ready = 'Ready'
}

interface UseGqlClientOutput extends UseGqlReactQueryOptionsOutput {
    client: GraphQLClient;
    status: GqlClientStatus;
}

/**
 * @deprecated
 *
 * This hook handles Auth and session state management and in turn provides an authenticated GraphQLClient to the
 * consumer.
 */
const useGqlClient = (api: ApiName): UseGqlClientOutput => {
    const { token, isAuthenticated } = useAuth();

    // @TODO SAS client should not be available to use until we have a valid token and are ready to go.
    // This means AuthStatus is "LoggedIn" as a result of SessionStatus === "Ready"

    // useEffect(() => {
    //     if (isAuthenticated && checkTokenIsExpired()) {
    //         retrieveToken();
    //     }
    // }, [isAuthenticated, retrieveToken, checkTokenIsExpired]);

    const client = buildGqlClient(token ?? '', api);

    const queryOptionHelpers = useGqlReactQueryOptions();

    /** Seems no more useful than isAuthenticated?  Correct, but gives us potential uses later. */
    const status = useMemo(() => {
        if (isAuthenticated) return GqlClientStatus.Ready;
        return GqlClientStatus.NotReady;
    }, [isAuthenticated]);

    return { client, status, ...queryOptionHelpers };
};

// const useAuthenticatedGqlClient = (api: ApiName): UseGqlClientOutput => {

// }

export default useGqlClient;
