import React from 'react';

import AdepteductMessageProps from 'components/communication/message/AdepteductMessageProps';
import ChannelMeetingReactionMessage from 'components/communication/message/meeting/Reaction';
import * as yup from 'yup';

import { MessageEventType } from '../..';
import AckableAdepteductMessage from '../../../base/AckableAdepteductMessage';
import { MessageDestination } from '../../../MessageRecord';
import PossibleEventFields from '../../PossibleEventFields';

export interface EventFieldsReactionUpdated extends PossibleEventFields {
    meetingId: string;
    instanceId: string;
    reaction: string;
    sessionId?: string;
    subject?: {
        type: string;
        id: string;
    };
}

// TODO: Figure out the right schema here, this was what was in place but causing error spam:
// schema = yup.object({
//     meetingId: yup.string().required(),
//     instanceId: yup.string().required(),
//     sessionId: yup.string(),
//     reaction: yup.string().required(),
//     subject: yup.object().shape({
//         type: yup.string().required(), // should be an enum
//         id: yup.string().required()
//     })
// });

export default class ChannelMeetingReaction extends AckableAdepteductMessage<EventFieldsReactionUpdated> {
    schema = yup.object({
        meetingId: yup.string(),
        instanceId: yup.string(),
        sessionId: yup.string(),
        reaction: yup.string().required(),
        subject: yup.object().shape({
            type: yup.string(), // should be an enum
            id: yup.string()
        })
    });

    type = MessageEventType.ChannelMeetingReaction;

    mapDestinationToComponent = (
        destination?: MessageDestination
    ): React.ForwardRefExoticComponent<
        AdepteductMessageProps<PossibleEventFields> & React.RefAttributes<HTMLDivElement | null>
    > => {
        if (destination !== MessageDestination.Meeting) {
            console.log(
                `Received a ${this.type} message that did not have destination of ${MessageDestination.Meeting}. ${destination}`
            );
        }

        return ChannelMeetingReactionMessage;
    };
}
