import { gql } from 'graphql-request';

export const UPSERT_PROGRESS = gql`
    mutation (
        $skillId: UUIDv4!
        $organizationId: UUIDv4
        $components: [ComponentProgress]!
        $isStandaloneSkill: Boolean
    ) {
        upsertProgressOnSkill(
            skillId: $skillId
            organizationId: $organizationId
            components: $components
            isStandaloneSkill: $isStandaloneSkill
        ) {
            state

            components {
                key
                state
            }
        }
    }
`;

export const UPSERT_PROGRESS_MODE = gql`
    mutation ($skillId: UUIDv4!, $organizationId: UUIDv4, $managed: Boolean!, $isStandaloneSkill: Boolean) {
        upsertProgressModeOnSkill(
            skillId: $skillId
            organizationId: $organizationId
            managed: $managed
            isStandaloneSkill: $isStandaloneSkill
        ) {
            managed
            state

            components {
                key
                state
                type
            }
        }
    }
`;

export const UPSERT_RATING = gql`
    mutation upsertRating($resourceType: String!, $resourceId: UUIDv4!, $rating: Float!) {
        upsertRating(resourceType: $resourceType, resourceId: $resourceId, rating: $rating)
    }
`;

export const REMOVE_RATING = gql`
    mutation removeRating($resourceType: String!, $resourceId: UUIDv4!) {
        removeRating(resourceType: $resourceType, resourceId: $resourceId) {
            success
        }
    }
`;
