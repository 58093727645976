import React, { useContext } from 'react';

import { NavTabWrapper, NavTabItem, ActiveTabWrapper } from './Nav.styles';
import { NavContext, Tab } from './NavContext';

interface Props {
    tabName: Tab;
    onClick?: () => void;
}

const NavTab: React.FC<Props> = ({ tabName, children, onClick: extraOnClick = () => {} }) => {
    const { activeTab, setActiveTab } = useContext(NavContext);

    const onClick = () => {
        setActiveTab(tabName);

        setTimeout(() => extraOnClick());
    };

    return (
        <>
            <NavTabWrapper isActive={tabName === activeTab} onClick={onClick}>
                <ActiveTabWrapper isActive={tabName === activeTab} />
                <NavTabItem>{children}</NavTabItem>
            </NavTabWrapper>
        </>
    );
};

export default NavTab;
