import { GraphQLClient } from 'graphql-request';
import gql from 'graphql-tag';
import buildGqlMutationFn from 'hooks/useGqlClient/helpers/buildGqlMutationFn';
import { MutationFunction } from 'react-query';

import { ToggleMicrophoneResult, ToggleMicrophoneVariables } from '.';

export const TOGGLE_MICROPHONE = gql`
    mutation toggleMicrophone($sessionId: UUIDv4!, $status: MediaStatus!) {
        toggleMicrophone(sessionId: $sessionId, status: $status) {
            success
        }
    }
`;

const makeToggleMicrophone = (
    client: GraphQLClient
): MutationFunction<ToggleMicrophoneResult, ToggleMicrophoneVariables> => buildGqlMutationFn(client, TOGGLE_MICROPHONE);

export default makeToggleMicrophone;
