import { QuestionType } from '@adept-at/lib-react-components';

import { EntityType } from '../../../../ContentContext/Enums';
import ViewExamQuestion, { ViewExamQuestionProps } from '../QuestionViewers/Exam/ViewExamQuestion';
import SkillQuestion, { SkillQuestionProps } from '../QuestionViewers/Skill';

import * as Blank from './Blank';
import * as QBoolean from './Boolean';
import * as Checkbox from './Checkbox';
import * as Choice from './Choice';
import * as Match from './Match';
import * as Order from './Order';
import * as Poll from './Poll';

const typeToModuleMapping = {
    [QuestionType.Blank]: Blank,
    [QuestionType.Boolean]: QBoolean,
    [QuestionType.Checkbox]: Checkbox,
    [QuestionType.Choice]: Choice,
    [QuestionType.Match]: Match,
    [QuestionType.Order]: Order,
    [QuestionType.Poll]: Poll,
    NO_TYPE: () => null
};

type ContainerToViewPropsMapping = {
    LIBRARY: any;
    SKILL: SkillQuestionProps;
    COURSE: any;
    QUESTION_BANK: any;
    Assessment: ViewExamQuestionProps;
    Skill: SkillQuestionProps;
};

//the below types only work if passing in the base enums keys instead of values :/
//commenting for now for times sake
//{ [type in keyof typeof EntityType]: React.FC<ContainerToViewPropsMapping[type]> }
const containerToViewMapping = {
    library: () => null,
    skill: SkillQuestion,
    collection: SkillQuestion,
    course: () => null,
    question_bank: () => null,
    assessment: ViewExamQuestion
};
//same as above
//export function getViewForContainer<Type extends keyof typeof EntityType>(
//     type: EntityType
// ): React.FC<ContainerToViewPropsMapping[Type]> {
export function getViewForContainer(type: EntityType): React.FC<SkillQuestionProps | ViewExamQuestionProps> {
    return containerToViewMapping[type] || containerToViewMapping.library;
}
// @TODO tighten up typing
export const getEditForType = (type: QuestionType) => typeToModuleMapping[type]?.Edit || typeToModuleMapping.NO_TYPE;
