import React from 'react';

import { DotList, DotListContainer, TooltippedButtonSize, TooltippedIconButton } from '@adept-at/lib-react-components';
import { mdiOpenInNew, mdiPencil } from '@mdi/js';
import Icon from '@mdi/react';
import styled from 'styled-components';

import { StickyTableCell } from './TableCell.styles';

interface TitleCellProps {
    left?: number;
    details?: string[];
    title?: string;
    editUrl?: string;
    consumptionUrl?: string;
}

const StyledDotListContainer = styled(DotListContainer)`
    white-space: nowrap;
`;

const Wrapper = styled.div`
    width: 100%;

    button {
        visibility: hidden;
    }

    &:hover {
        button {
            visibility: visible;
        }
    }
`;

const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const TITLE_CELL_WIDTH = 300;

export const TitleCell: React.FC<TitleCellProps> = ({ editUrl, consumptionUrl, title, details, left }) => {
    return (
        <StickyTableCell $borderRight left={left} $flexGrow width={TITLE_CELL_WIDTH}>
            <Wrapper>
                <TitleWrapper>
                    <p>{title}</p>
                    <div>
                        <TooltippedIconButton
                            aria-label="Edit"
                            size={TooltippedButtonSize.ExtraSmall}
                            onClick={(e) => {
                                e.stopPropagation();
                                window.open(editUrl);
                            }}
                            icon={<Icon path={mdiPencil} />}
                            title="Edit"
                        />
                        <TooltippedIconButton
                            aria-label="View"
                            size={TooltippedButtonSize.ExtraSmall}
                            onClick={(e) => {
                                e.stopPropagation();
                                window.open(consumptionUrl ?? editUrl);
                            }}
                            icon={<Icon path={mdiOpenInNew} />}
                            title="View"
                        />
                    </div>
                </TitleWrapper>
                <DotList styledComponentOverride={StyledDotListContainer}>{details}</DotList>
            </Wrapper>
        </StickyTableCell>
    );
};
