import React, { useMemo } from 'react';

import { IconSize, modularScale } from '@adept-at/lib-react-components';
import { Avatar, Button } from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';
import { useChatContext } from 'components/communication/Notifier/ChatContext';
import useUserDetails from 'hooks/useUserDetails';
import AckableAdepteductMessage from 'lib/communication/message/base/AckableAdepteductMessage';
import PossibleEventFields from 'lib/communication/message/type/PossibleEventFields';
import pluralize from 'pluralize';
import styled from 'styled-components';

const StyledButton = styled(Button)`
    align-self: flex-start;
`;

const StyledAvatarGroup = styled(AvatarGroup)`
    margin-right: 8px;

    div {
        font-size: ${modularScale(-1)};
        border: 0;
        height: ${IconSize.Medium};
        width: ${IconSize.Medium};
        z-index: 0 !important;
    }
`;

const ThreadButton: React.FC<{ message: AckableAdepteductMessage<PossibleEventFields> }> = ({ message }) => {
    const { threadParentId, setThreadParentId } = useChatContext();

    const userIds = useMemo(
        () =>
            Object.entries(message.responders).reduce((memo, [userId, responses]) => {
                if (responses > 0) {
                    memo.push(userId);
                }
                return memo;
            }, [] as string[]),
        [message.responders]
    );

    const { usersDetails } = useUserDetails({ userIds });

    const responderAvatars = useMemo(() => {
        return userIds.map((responder) => {
            const details = Object.values(usersDetails).find((detail) => detail.id === responder);
            return (
                <Avatar key={responder} alt={details?.alias} src={details?.imageUrl ?? undefined}>
                    {details?.alias?.charAt(0)}
                </Avatar>
            );
        });
    }, [userIds, usersDetails]);

    if (threadParentId || message.responses < 1) {
        return null;
    }

    return (
        <StyledButton color="primary" size="small" onClick={() => setThreadParentId(message.id)}>
            <StyledAvatarGroup spacing={-4}>{responderAvatars}</StyledAvatarGroup>
            {pluralize('reply', message.responses, true)}
        </StyledButton>
    );
};

export default ThreadButton;
