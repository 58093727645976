import React, { useState, createContext, useContext, useCallback } from 'react';

import { useImmer } from 'use-immer';

interface TypeSelectorNavigationContextInterface {
    getCurrentlySelected: () => string;
    navigateBack: () => void;
    navigateTo: (item: string) => void;
    getCurrentLevel: () => number;
    isInPath: (item: string) => boolean;
    disable: () => void;
    enable: () => void;
    enabled: boolean;
}

const TypeSelectorNavigationContext = createContext({} as TypeSelectorNavigationContextInterface);

export const TypeSelectorNavigationProvider: React.FC = ({ children }) => {
    const [navigationStack, updateNavigationStack] = useImmer<string[]>([]);
    const [enabled, setEnabled] = useState<boolean>(true);

    const navigateBack = () => {
        updateNavigationStack((draft) => {
            draft.pop();
        });
    };

    const navigateTo = (item: string) => {
        updateNavigationStack((draft) => {
            draft.push(item);
        });
    };

    const isInPath = (item: string): boolean => navigationStack.includes(item);

    const getCurrentLevel = useCallback(() => navigationStack.length, [navigationStack]);

    const getCurrentlySelected = useCallback(() => navigationStack[navigationStack.length - 1], [navigationStack]);

    return (
        <TypeSelectorNavigationContext.Provider
            value={{
                navigateTo,
                isInPath,
                navigateBack,
                getCurrentlySelected,
                getCurrentLevel,
                disable: () => {
                    setEnabled(false);
                },
                enable: () => {
                    setEnabled(true);
                },
                enabled
            }}
        >
            {children}
        </TypeSelectorNavigationContext.Provider>
    );
};

export const useTypeSelectorNavigation = (): TypeSelectorNavigationContextInterface => {
    const context = useContext(TypeSelectorNavigationContext);

    if (!context) {
        throw new Error('useTypeSelectorNavigation must be used within a TypeSelectorNavigationProvider');
    }

    return context;
};
