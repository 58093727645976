import React from 'react';

import styled, { keyframes } from 'styled-components';

const barHeight = 10;
const barWidth = 30;
const barDepth = 100;
const rotX = 0;
const rotY = 0;
const rotZ = 0;
const transX = 0;
const transY = 0;
const animSpeed = 1;
const perspective = 200;

const Inner = styled.div`
    margin: 0 auto;
`;

const animCube = keyframes`
    30% {
        transform: translateZ(${barHeight}px) translatex(${transX}px) translateY(${transY}px) rotateX(${rotX}deg) rotateY(${rotY}deg) rotateZ(${rotZ}deg);
        box-shadow: 0 0 20px rgba(0,0,0,.5), 0 12px 20px 1px rgba(0,0,0,.5);
    }

    100% {
        transform: translateZ(0px) rotateZ(0deg);
        box-shadow: 0 0 30px rgba(0,0,0,.5), 0 0 0 0 rgba(0,0,0,0);
    }
`;

const animCenter = keyframes`
    20% {
        background-color: #0277BD;
        box-shadow: 0 0 3px rgba(255,255,255,0.15), inset 0 1px rgba(255,255,255,.5);
    }
    65% {
        box-shadow: 0 0 3px rgba(255,255,255,0), inset 0 1px rgba(255,255,255,.25);
    }
`;

const animBottom = keyframes`
    30% { height: ${barDepth}px; }
    95% { height: 10px; }
`;

const animSide = keyframes`
    30%  {
        width: ${barDepth}px;
        background-color: #1d1c1f;
    }
    95% {
        width: 10px;
    }
`;

const Cube = styled.div<{ num }>`
    width: ${barWidth}px;
    height: ${barHeight}px;
    position: relative;
    margin: 4px;
    float: left;
    transform-style: preserve-3d;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    animation: ${animCube}; /* Safari and Chrome */
    animation-iteration-count: infinite;
    opacity: 0.75;
    animation-duration: ${animSpeed}s;
    animation-timing-function: ease-in-out;
    z-index: ${(props) => props.num};
    animation-delay: ${(props) => props.num * (animSpeed / 12)}s;

    div {
        position: absolute;
        display: block;
        width: ${barWidth}px;
        height: ${barHeight}px;
        animation-duration: ${animSpeed}s;
        animation-timing-function: ease-in-out;
        z-index: ${(props) => props.num};
        animation-delay: ${(props) => props.num * (animSpeed / 12)}s;
    }
`;

const Center = styled.div`
    width: ${barWidth}px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3), inset 0 1px rgba(255, 255, 255, 0.05);
    animation: ${animCenter};
    animation-iteration-count: infinite;
    background-color: ${(props) => props.theme.colors.surface};
    background-image: linear-gradient(top, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
`;

const Bottom = styled.div`
    width: ${barWidth}px;
    height: 10px;
    bottom: 0;
    transform: rotateX(90deg);
    transform-origin: center bottom;
    animation: ${animBottom};
    animation-iteration-count: infinite;
    background-color: #1f1d23;
    background-image: linear-gradient(bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
`;

const Left = styled.div`
    left: 0;
    transform: rotateY(90deg);
    transform-origin: left center;
    animation: ${animSide};
    animation-iteration-count: infinite;
    background-color: #2b2830;
    background-image: linear-gradient(top, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
`;

const Right = styled.div`
    right: 0;
    transform: rotateY(-90deg);
    transform-origin: right center;
    animation: ${animSide};
    animation-iteration-count: infinite;
    background-color: #2b2830;
    background-image: linear-gradient(top, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
`;

const Container = styled.div`
    width: 100%;
    perspective: ${perspective};
    padding: 20px 60px;
    position: relative;
    display: table-cell;
    vertical-align: middle;

    ${Cube} {
        animation-duration: ${animSpeed}s;
        animation-timing-function: ease-in-out;

        ${Left}, ${Bottom}, ${Center}, ${Right} {
            animation-duration: ${animSpeed}s;
            animation-timing-function: ease-in-out;
        }
    }
`;

const Shadow = styled.div``;

const NUM_LOADING_BARS = 6;

const Loader: React.FC = () => {
    const Item = ({ num }) => {
        return (
            <Cube num={num}>
                <Left />
                <Center />
                <Right />
                <Bottom />
                <Shadow />
            </Cube>
        );
    };

    const renderItems = () => {
        const items: React.ReactElement[] = [];

        for (let i = 0; i < NUM_LOADING_BARS; i++) {
            items.push(<Item key={i} num={i} />);
        }

        return items;
    };

    return (
        <Container data-testid="Loader">
            <Inner>{renderItems()}</Inner>
        </Container>
    );
};

export default React.memo(Loader);
