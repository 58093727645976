import { GraphQLClient } from 'graphql-request';
import { RequestDocument } from 'graphql-request/dist/types';
import { QueryKey, QueryFunctionContext, QueryFunction } from 'react-query';

/**
 * This is a function that returns another function, the exact format react-query needs.
 * Nearly the entire purpose of this is to handle the authenticated GraphQLClient, and hopefully makes the double/triple function easier to grok.
 *
 * @deprecated
 *
 * @param client This should be the authenticated client from this hook, not a standard client, in nearly all cases.
 * @param query @see RequestDocument , usually created by graphql-tag.
 * @param variables Any variables needed for the request.
 */
const buildGqlQueryFn = <TQueryFnData = unknown, TVariables = unknown, TQueryKey extends QueryKey = QueryKey>(
    client: GraphQLClient,
    query: RequestDocument,
    variables: TVariables = {} as TVariables
): QueryFunction<TQueryFnData> => {
    // Query Function context contains the QueryKey, and that's about it. You can use the variables in the query key, but we are not by default.
    const queryFn = async (_: QueryFunctionContext<TQueryKey>): Promise<TQueryFnData> => {
        // @TODO All auth refresh logic here
        // @TODO Scope or recent login awareness/guards

        return await client.request<TQueryFnData, TVariables>(query, {
            ...variables
        });
    };

    return queryFn;
};

export default buildGqlQueryFn;
