import LinearProgress from '@material-ui/core/LinearProgress';
import styled from 'styled-components';

const Loading = styled(LinearProgress)`
    &&& {
        flex-grow: 1;
        width: 100%;
        margin: 1.5rem 0;
        height: 20px;

        .bar {
            border-radius: 20px;
            background-color: #ff6c5c;
        }
    }
`;

export default Loading;
