import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { HeaderContext } from 'components/navigation/HeaderContext';
import { LearnEditContext } from 'pages/learn/LearnEditContext';
import { isTrue } from 'utils/isTrue';

import { EditMode, EditorContext } from './EditorContext';

interface SectionDescriptionEditorContextInterface {
    blockKey?: string;
    setBlockKey: React.Dispatch<React.SetStateAction<string | undefined>>;
    description?: string;
    setDescription: React.Dispatch<React.SetStateAction<string | undefined>>;
    pendingDescriptions: string[];
    setPendingDescriptions: React.Dispatch<React.SetStateAction<string[]>>;
    isEditMode: boolean;
}

const SectionDescriptionEditorContext = createContext({} as SectionDescriptionEditorContextInterface);

const { Provider, Consumer } = SectionDescriptionEditorContext;

const SectionDescriptionEditorProvider: React.FC = ({ children }) => {
    const [blockKey, setBlockKey] = useState<string>();
    const [description, setDescription] = useState<string>();
    const [pendingDescriptions, setPendingDescriptions] = useState<string[]>([]);
    const { isSaving } = useContext(HeaderContext);
    const { editMode } = useContext(EditorContext);
    const learnEditCtx = useContext(LearnEditContext);

    useEffect(() => {
        if (!isSaving) {
            setPendingDescriptions([]);
        }
    }, [isSaving]);

    const isEditMode = useMemo(
        () => editMode !== EditMode.Preview && (!learnEditCtx || isTrue(learnEditCtx?.skillEditId)),
        [editMode, learnEditCtx]
    );

    return (
        <Provider
            value={{
                blockKey,
                setBlockKey,
                description,
                setDescription,
                pendingDescriptions,
                setPendingDescriptions,
                isEditMode
            }}
        >
            {children}
        </Provider>
    );
};

export {
    SectionDescriptionEditorContext,
    SectionDescriptionEditorProvider,
    Consumer as SectionDescriptionEditorConsumer
};
