import React, { ReactElement } from 'react';

import { Typography, Box, List } from '@material-ui/core';
import { CollaboratorPermissionLevel } from 'components/ContentContext/Interfaces';
import { MutationStatus } from 'react-query';

import ExistingUserListItem from './ExistingUserListItem';

export interface ExistingUser {
    userId: string | null;
    avatarSrc?: string;
    name: string;
    addedAt: string;
    permissionLevel: CollaboratorPermissionLevel;
    inviteId?: string | null;

    /** Use this to control the display of the select to change permission level */
    mutationStatus?: MutationStatus;
}

export enum CollaboratorMutationStatus {
    Idle = 'idle',
    Loading = 'loading',
    Success = 'success',
    Error = 'error'
}

export enum CollaboratorPermission {
    Editor = 'editor',
    Commenter = 'commenter',
    Creator = 'creator'
}

export interface ExistingUsersListProps {
    existingUsers?: ExistingUser[];
    onExistingPermissionChange?: ({
        userId,
        newPermission
    }: {
        userId: string;
        newPermission: CollaboratorPermissionLevel;
    }) => void;
    onInvitationalPermissionChange?: (invitationId: string, newPermission: CollaboratorPermissionLevel) => void;
    onExistingPermissionRemove?: (userId: string, specificRole: CollaboratorPermissionLevel) => void;
    onInvitationalPermissionRemove?: (invitationId: string, email: string) => void;
}

const ExistingUsersList = ({ existingUsers, ...otherProps }: ExistingUsersListProps): ReactElement => {
    const renderCollaboratorsList = () => {
        if (existingUsers && existingUsers.length > 0) {
            return (
                <Box height="100%" width="100%">
                    <List dense>
                        {existingUsers.map((existingCollaborator) => (
                            <ExistingUserListItem
                                key={existingCollaborator.userId}
                                existingUser={existingCollaborator}
                                isViewer={existingCollaborator.permissionLevel === CollaboratorPermissionLevel.Viewer}
                                {...otherProps}
                            />
                        ))}
                    </List>
                </Box>
            );
        }

        return (
            <Box display="flex" alignItems="center">
                <Typography variant="subtitle1">No users</Typography>
            </Box>
        );
    };

    return (
        <Box display="flex" alignItems="center" justifyContent="center" marginTop="1rem" height="10rem" overflow="auto">
            {renderCollaboratorsList()}
        </Box>
    );
};

export default ExistingUsersList;
