import React from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { mdiClose, mdiUpload } from '@mdi/js';
import Icon from '@mdi/react';
import styled from 'styled-components';

const AlertContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 32px 32px 0px 32px;
`;

const StyledAlert = styled(Alert)`
    width: 100%;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.primary};

    .MuiAlert-message {
        width: 100%;
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 0;
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;

    .MuiButton-root {
        font-size: 0.82rem;
    }
`;

interface PendingChangesAlertProps {
    showAlert: boolean;
    onDiscardChanges: () => void;
    onPublishChanges: () => void;
}

export const PendingChangesAlert: React.FC<PendingChangesAlertProps> = ({
    showAlert = false,
    onDiscardChanges,
    onPublishChanges
}) => {
    if (showAlert) {
        return (
            <AlertContainer>
                <StyledAlert variant="outlined" severity="info">
                    <div>
                        You’ve made some changes to this page, they will not take effect publicly until published.
                    </div>
                    <ButtonsContainer>
                        <Button
                            color="primary"
                            startIcon={<Icon size={IconSize.Small} path={mdiClose} />}
                            onClick={onDiscardChanges}
                        >
                            Discard changes
                        </Button>
                        <Button
                            color="primary"
                            startIcon={<Icon size={IconSize.Small} path={mdiUpload} />}
                            onClick={onPublishChanges}
                        >
                            Publish changes
                        </Button>
                    </ButtonsContainer>
                </StyledAlert>
            </AlertContainer>
        );
    }

    return null;
};
