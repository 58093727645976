import React, { useContext, useRef, useState } from 'react';

import {
    CollectionDescriptionContainer,
    ComponentValuesType,
    RichTextComponentValuesBody
} from '@adept-at/lib-react-components';
import { EntityType } from 'components/ContentContext/Enums';
import { ComponentEngineComponent, ComponentEngineMode, EngineProvider } from 'components/engine';
import { ComponentEditorProvider, ComponentFocus, EditorComponentControls } from 'components/engine/mixins/editor';
import { ComponentFocusableContext, ComponentFocusableProvider } from 'components/engine/mixins/focusable/Context';
import { AddDescriptionButton } from 'components/RootProfileCollection/AddDescriptionButton';
import { DescriptionTitle } from 'components/RootProfileCollection/Collections.styles';
import { AboutInfo } from 'components/RootProfileCollection/constants';
import { GetEditableCollectionResponse } from 'components/RootProfileCollection/useGetEditableCollection';
import { useUpdateCollectionMeta } from 'components/RootProfileCollection/useUpdateCollectionMeta';
import { ClientError } from 'graphql-request';
import isEqual from 'react-fast-compare';
import { RefetchOptions, QueryObserverResult } from 'react-query';

interface DescriptionDetailsProps {
    aboutInfo: AboutInfo | null;
    collectionId: string;
    isEditMode: boolean;
    collectionIdForEditableCollectionQuery: string;
    parentQuerySlug: string;
}

export interface DescriptionProps extends DescriptionDetailsProps {
    refetchEditableCollection: (
        options?: RefetchOptions | undefined
    ) => Promise<QueryObserverResult<GetEditableCollectionResponse, ClientError>>;
    engineMode: ComponentEngineMode;
}

export const Description: React.FC<DescriptionProps> = ({
    refetchEditableCollection,
    engineMode,
    aboutInfo,
    collectionId,
    isEditMode,
    collectionIdForEditableCollectionQuery,
    parentQuerySlug
}) => {
    return (
        <CollectionDescriptionContainer>
            <EngineProvider
                containerRefetch={refetchEditableCollection}
                container={{ type: EntityType.COLLECTION, id: collectionId }}
                components={{}}
                controlsComponent={EditorComponentControls}
                mode={engineMode}
            >
                <ComponentFocusableProvider>
                    <DescriptionDetails
                        aboutInfo={aboutInfo}
                        collectionId={collectionId}
                        isEditMode={isEditMode}
                        collectionIdForEditableCollectionQuery={collectionIdForEditableCollectionQuery}
                        parentQuerySlug={parentQuerySlug}
                    />
                </ComponentFocusableProvider>
            </EngineProvider>
        </CollectionDescriptionContainer>
    );
};

const DescriptionDetails: React.FC<DescriptionDetailsProps> = ({
    aboutInfo,
    collectionId,
    isEditMode,
    collectionIdForEditableCollectionQuery,
    parentQuerySlug
}) => {
    const { setComponentFocus } = useContext(ComponentFocusableContext);
    const { mutate: updateDescription } = useUpdateCollectionMeta({
        collectionId: collectionIdForEditableCollectionQuery,
        parentQuerySlug
    });

    const [showEditableInput, setShowEditableInput] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    const saveDescription = (description: RichTextComponentValuesBody) => {
        if (isEqual(description, aboutInfo?.description) || !collectionId) return;

        updateDescription({ input: { collectionId, description } });
    };

    if (aboutInfo?.description || showEditableInput) {
        return (
            <ComponentEditorProvider
                autoEditComponentId={collectionId}
                onUpsertComponent={(variables) => {
                    saveDescription(JSON.parse(variables.values).body);
                }}
            >
                {isEditMode ? <DescriptionTitle>DESCRIPTION</DescriptionTitle> : null}
                <ComponentEngineComponent
                    container={ref}
                    id={collectionId}
                    type={ComponentValuesType.RichText}
                    order={0}
                    values={{ body: aboutInfo?.description }}
                    controlOptions={{
                        edit: { enabled: true }
                    }}
                />
            </ComponentEditorProvider>
        );
    }

    if (isEditMode)
        return (
            <AddDescriptionButton
                onClick={() => {
                    setShowEditableInput(true);
                    setComponentFocus(collectionId, ComponentFocus.Edit);
                }}
            />
        );

    return null;
};
