import { useEffect, useMemo, useState } from 'react';

import { Descendant } from './Enroll';

export const useDescendantsFilter = (descendants: Descendant[]) => {
    const [filter, setFilter] = useState<Record<string, Descendant>>({});

    useEffect(() => {
        const initializedFilter = descendants.reduce((prev, descendant) => {
            if (descendant.completionDate) {
                prev[descendant.id] = descendant;
            }
            return prev;
        }, {} as Record<string, Descendant>);
        setFilter(initializedFilter);
    }, [setFilter, descendants]);

    const { count, estimatedTimeToConsume } = useMemo(() => {
        const { estimatedTimeToConsume, count } = descendants.reduce(
            ({ count, estimatedTimeToConsume: previousTotalEstimate }, { estimatedTimeToConsume, id }) => {
                if (!filter[id]) {
                    previousTotalEstimate += estimatedTimeToConsume;
                    count++;
                }
                return { count, estimatedTimeToConsume: previousTotalEstimate };
            },
            { estimatedTimeToConsume: 0, count: 0 }
        );

        return { count, estimatedTimeToConsume };
    }, [descendants, filter]);

    return { count, estimatedTimeToConsume, filter, setFilter };
};
