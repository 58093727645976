import { GraphQLClient } from 'graphql-request';
import gql from 'graphql-tag';
import buildGqlMutationFn from 'hooks/useGqlClient/helpers/buildGqlMutationFn';
import { MutationFunction } from 'react-query';

import { StopPresentingResult, StopPresentingVariables } from '.';

export const STOP_PRESENTING = gql`
    mutation stopPresentingLiveLearning($sessionId: UUIDv4!) {
        stopPresentingLiveLearning(sessionId: $sessionId) {
            success
        }
    }
`;

const StopPresenting = (client: GraphQLClient): MutationFunction<StopPresentingResult, StopPresentingVariables> =>
    buildGqlMutationFn(client, STOP_PRESENTING);

export default StopPresenting;
