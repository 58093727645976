import React from 'react';

import { mdiImageSearchOutline } from '@mdi/js';
import Icon from '@mdi/react';

import { SelectorOptionProps } from '../../ImageTypeSelector';
import { IconLine, ActionItem } from '../../ImageTypeSelector.styles';

export const SelectorOption: React.FC<SelectorOptionProps> = ({ onSelect }) => (
    <>
        <IconLine>
            <Icon path={mdiImageSearchOutline} />
            <span>Unsplash image</span>
        </IconLine>
        <ActionItem onClick={onSelect}>Search Unsplash</ActionItem>
    </>
);
