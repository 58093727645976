import React, { createContext, useContext, useCallback, useEffect } from 'react';

import { ComponentValuesType, RichTextComponentValues } from '@adept-at/lib-react-components';
import { SkillMetadata } from 'components/builder/context/interfaces';

import { useComponentEngine } from '../..';
import { EntityType, SectionId } from '../../../ContentContext/Enums';
import { RemoveComponentVariables, UpsertComponentVariables } from '../../types';
import { useFocusableComponent } from '../focusable';

import { ComponentFocus } from './types';

export const COMPONENT_ORDER_PADDING = 100;

export interface ComponentEditorContextInterface {
    onUpsertComponent: <T>(id: string, type: ComponentValuesType, order: number, values: T) => void;
    onAddComponent: (type: ComponentValuesType, order: number) => void;
    onRemoveComponent: (id: string) => void;
    onStartRemovingComponent: (id: string) => void;
    onCancelRemovingComponent: (id: string) => void;
    onStartEditingComponent: (id: string) => void;
    onStopEditingComponent: (id: string) => void;
}

const ComponentEditorContext = createContext({} as ComponentEditorContextInterface);

const { Provider, Consumer } = ComponentEditorContext;
/**
 * Construct an id that is unique to all the components in the skill
 *
 * @param existingComponents
 */
const getNewComponentId = (existingComponents): string => {
    const newComponentId = Math.random().toString(36).replace('0.', '').substr(3);

    if (newComponentId in existingComponents) {
        return getNewComponentId(existingComponents);
    }

    return newComponentId;
};

export interface ComponentEditorProviderProps {
    children: React.ReactNode;

    autoEditComponentId?: string;

    /**
     * Return value can be a void because expect the result of onUpsertComponent to be an updating
     * of the state in the react-query cache (tied to the key) that will cause a re-render of the component
     * tree for the entity
     */
    onUpsertComponent?: (variables: UpsertComponentVariables) => void;

    onAddComponent?: (variables: UpsertComponentVariables) => void;

    onRemoveComponent?: (variables: RemoveComponentVariables) => void;

    onRemoveComponentFromLocalStateOnly?: (variables: RemoveComponentVariables) => void;

    onUpdateMeta?: (values: SkillMetadata) => void;
}

export interface ContainerEntity {
    id: string;
    type: EntityType;
}

const sectionIdToMetaId = {
    [SectionId.OVERVIEW]: 'overview',
    [SectionId.CONCLUSION]: 'conclusion'
};

const ComponentEditorProvider: React.FC<ComponentEditorProviderProps> = ({
    children,
    onUpsertComponent,
    onUpdateMeta,
    onAddComponent,
    onRemoveComponent,
    onRemoveComponentFromLocalStateOnly,
    autoEditComponentId
}) => {
    const {
        container: { type, id },
        components
    } = useComponentEngine();

    const { setComponentFocus, unsetComponentFocus, doesComponentHaveFocus } = useFocusableComponent();

    // const updateComponentsOrder = (components) => {};

    const doUpsertComponent = <TComponentValues,>(
        componentId: string,
        componentType: ComponentValuesType,
        order: number,
        values: RichTextComponentValues | TComponentValues
    ) => {
        if (componentId === SectionId.OVERVIEW || componentId === SectionId.CONCLUSION) {
            if (onUpdateMeta) {
                onUpdateMeta({ [sectionIdToMetaId[componentId]]: (values as RichTextComponentValues)?.body });
            }
            return;
        }

        if (onUpsertComponent) {
            onUpsertComponent({
                entity: { type, id },
                componentType,
                componentId: componentId,
                order,
                values: JSON.stringify({ ...values, type: componentType })
            });
        }
    };

    const doAddComponent = (componentType: ComponentValuesType, order: number) => {
        const componentId = getNewComponentId(components);

        if (onAddComponent) {
            onAddComponent({
                entity: { type, id },
                componentType,
                componentId,
                order
            } as UpsertComponentVariables);
        }

        setComponentFocus(componentId, ComponentFocus.AddAndEdit);
    };

    const onStartEditingComponent = useCallback(
        (componentId) => {
            setComponentFocus(componentId, ComponentFocus.Edit);
        },
        [setComponentFocus]
    );

    useEffect(() => {
        if (autoEditComponentId) {
            onStartEditingComponent(autoEditComponentId);
        }
    }, [autoEditComponentId, onStartEditingComponent]);

    return (
        <Provider
            value={{
                onUpsertComponent: doUpsertComponent,
                onAddComponent: doAddComponent,

                onStartRemovingComponent: (componentId) => {
                    setComponentFocus(componentId, ComponentFocus.Remove);
                },

                onCancelRemovingComponent: (componentId) => {
                    unsetComponentFocus(componentId, ComponentFocus.Remove);
                },

                onRemoveComponent: (componentId) => {
                    /**
                     * Removing a component from AddAndEdit indicates that a remove request does not need to be made to
                     * the server. This is because a component is not upserted on the server until it is saved once.
                     *
                     * @TODO Consider if component can set a "dirty" state that would allow us to make a decision here.
                     */
                    const onRemoveComponentCallback = doesComponentHaveFocus(componentId, ComponentFocus.AddAndEdit)
                        ? onRemoveComponentFromLocalStateOnly
                        : onRemoveComponent;

                    unsetComponentFocus(componentId, ComponentFocus.Remove);

                    if (onRemoveComponentCallback) {
                        onRemoveComponentCallback({ entity: { id, type }, componentId });
                    }
                },

                onStartEditingComponent,

                onStopEditingComponent: (componentId) => {
                    unsetComponentFocus(componentId, ComponentFocus.Edit);
                }
            }}
        >
            {children}
        </Provider>
    );
};

export const useComponentEditor = (): ComponentEditorContextInterface => {
    const context = useContext(ComponentEditorContext);

    if (!context) {
        throw new Error('useComponentEditor must be used within a ComponentEditorProvider');
    }

    return context;
};

export { ComponentEditorProvider, ComponentEditorContext, Consumer as ComponentEditorConsumer };
