import { CommonComponentValues } from '@adept-at/lib-react-components';
import { WorkflowStatus } from 'components/CollaborationTable';
import { Comment } from 'components/Comments/interfaces';
import { MarketplaceListingStatus } from 'components/marketplace/hooks/useUpsertListingStatus';
import { RootCollectionResponse } from 'components/RootProfileCollection/context/useGetTenantProfileForOwner';

import { EntityType, ProductEntityType } from './Enums';

/**
 * Owner Type as defined in @adept-at/types library: https://github.com/adept-at/types/blob/main/src/OwnerType.ts#L38-L67
 *
 * This should also match the Owner enum in each API's GraphQL schema.
 */
export enum OwnerType {
    Tenant = 'TENANT',
    Group = 'GROUP',
    User = 'USER',
    Organization = 'ORGANIZATION'
}

export enum Type {
    SKILL = 'skill',
    EXAM = 'exam'
}

export enum Interval {
    Day = 'day',
    Week = 'week',
    Month = 'month',
    Year = 'year'
}

export enum Role {
    Creator = 'creator',
    Editor = 'editor',
    Reviewer = 'reviewer',
    Viewer = 'viewer',
    Subscriber = 'subscriber',
    Commenter = 'commenter',
    PeerReviewer = 'peerRvwr'
}

export enum Author {
    Author = 'author',
    PeerReviewer = 'peerReviewer'
}
export interface ItemWithType {
    id: string;
    title: string;
    createdAt: string;
    description?: string;
    slug?: string;
    owner: {
        type: OwnerType;
        id: string;
    };
    type: Type;
}

export interface OwnerInfo {
    type: OwnerType;
    id: string;
}

export interface ImageOriginalDetails {
    url: string;
}

export enum ImageFormat {
    JPG = 'JPG',
    PNG = 'PNG',
    WEBP = 'WEBP'
}

export interface ImageDetailsDimensions {
    width: number;
    height: number;
}

export interface ImageDetails {
    url: string;
    ratio: number;
    format: ImageFormat;
    dimensions: ImageDetailsDimensions;
}

export type DefaultCollectionImageType = 'red' | 'blue' | 'green' | 'gray';

export interface Image {
    id: string | null;
    crop: any;
    isProcessed: boolean;
    processed: ImageDetails[];
    original: ImageDetails;
    tags?: string[] | null;
    format?: ImageFormat;
}

export interface Images {
    catalog: Image | null;
    catalogImages?: Image[] | null;
    featured: Image | null;
    featuredImages?: Image[] | null;
    collage?: Image[];
    defaultImages?: DefaultImagesByColor;
}

export interface DefaultImagesByColor {
    red: DefaultImages;
    blue: DefaultImages;
    green: DefaultImages;
    gray: DefaultImages;
}

interface DefaultImages {
    catalog: string;
    featuredLight: string;
    featuredDark: string;
    featuredLightMobile: string;
    featuredDarkMobile: string;
}

export interface UserProfile {
    userId: string;
    emails: string[];
    firstname: string;
    lastname: string;
    image: string;
    alias: string;
}

export interface SkillMeta {
    storyboard: any;
}

export interface ItemProgress {
    secondsConsumed: number;
    updatedAt: string;
    completedQuestions: string[];
    completedChildren?: string[];
    completedAt?: string;
    lastCompletedBlockKey?: string;
    collectionComplete?: boolean;
}

export interface ResourceRatings {
    likes: number;
    dislikes: number;
    currentUserRating: number | null;
}

export interface CollectionAuthor {
    userId: string;
    authorTypes: Author[];
}
export interface SkillAuthor {
    userId: string;
    authorType: Author;
}
export interface Skill {
    authors: SkillAuthor[];
    organizationId?: string;
    skillId: string;
    title: string;
    subtitle: string | null;
    slug: string;
    createdAt: string;
    createdBy: string;
    ownedBy: string;
    assignedTo: string;
    updatedAt: string;
    description?: string;
    progress?: ItemProgress | null;
    blocks?: {
        key: string;
        estimatedSecondsToConsume: number;
    }[];
    ratings: ResourceRatings;
    shared?: [
        {
            slug: string;
            type: string;
            org: string;
            imageId?: string;
        }
    ];
    owner: OwnerInfo;
    tags: string[];
    skillSlug?: string;
    tenantSlug?: string;
    components: Record<string, CommonComponentValues | any>;
    meta?: SkillMeta;
    views: number;
    order: number;
    images: Images | null;
    estimatedSecondsToConsume: number;
    free: boolean;
    visible: boolean;
    loginRequired: boolean;
    workflow?: keyof typeof WorkflowStatus;
    comments: { public: Comment[]; private: Comment[] };
}

export interface BasicSkill {
    skillId: string;
    title: string;
    description?: string;
    slug?: string;
    createdAt: string;
    createdBy: string;
    owner: OwnerInfo;
    images: Images | null;
    organizationId: string | null;
    collaborators?: string[];
    estimatedSecondsToConsume: number;
    numberOfComponents: number;
}

export interface QuestionBank {
    questionBankId: string;
    name: string;
    type: string;
    order: number;
    owner: OwnerInfo;
    createdAt: string;
}

export interface ProductTerm {
    count: number;
    interval: string;
}

export interface Plan {
    productId: string;
    type: string;
    planId: string;
    title: string;
    price: number;
    term: ProductTerm;
    trialPeriodDay: number | null;
    activatedAt: string;
    deactivatedAt: string;
}

export interface EntityInfo {
    type: EntityType;
    id: string;
}

export interface EntityInfoInput {
    type: EntityType;
    id: string | null;
}

export interface ProductItem {
    type: ProductEntityType;
    id: string;
}

export interface SortableId {
    id: string;
    sort: number;
}

export interface ProductImage extends SortableId {
    url: string;
}

export interface Product {
    productId: string;
    title: string;
    description: string;
    shortDescription: string;
    images: ProductImage[];
    item: ProductItem;
    createdAt: string;
    createdBy: string;
    modifedAt: string;
    plans: Plan[];
}

export interface PackageDetail {
    packageId: string;
    title: string;
    description: string;
    items: ProductItem[];
    createdAt: string;
    createdBy: string;
    plans: Plan[];
    tenantId: string;
}

export interface Lab {
    vLabId: string;
    name: string;
    description: string;
    instructions: VirtualLabInstruction[];
    virtualMachines: VirtualLabVirtualMachine[];
    createdAt: string;
    createdBy: string;
    updatedAt: string;
    owner: OwnerInfo;
    skills: string[];
    slug: string;
    order: number;
}

export interface VirtualLabInstruction {
    step: number;
    details: string;
}

export interface VirtualLabVirtualMachine {
    name: string;
    description: string;
    memory: number;
    cpus: number;
    nics: number;
    operatingSystem: OperatingSystemType;
    username: string;
    password: string;
    cid?: string | null;
}

export enum OperatingSystemType {
    WINDOWS_SERVER_2016 = 'WINDOWS_SERVER_2016',
    WINDOWS_SERVER_2019 = 'WINDOWS_SERVER_2019',
    UBUNTU_SERVER = 'UBUNTU_SERVER',
    UBUNTU_DESKTOP = 'UBUNTU_DESKTOP',
    OTHER_WINDOWS = 'OTHER_WINDOWS',
    OTHER_LINUX = 'OTHER_LINUX',
    WINDOWS_10 = 'WINDOWS_10',
    EVE_NG = 'EVE-NG_NETWORKING',
    UBUNTU_CLI = 'UBUNTU_CLI',
    UBUNTU_GUI = 'UBUNTU_GUI',
    LINUX_MINT = 'LINUX_MINT',
    KALI_LINUX = 'KALI_LINUX',
    OPEN_SUSE = 'OPEN_SUSE',
    CENT_OS = 'CENT_OS'
}

export interface EntityCollaborator {
    role: CollaboratorPermissionLevel;
    alias: string;
    email: string;
    id: string;
    image: string;
    name: {
        first: string;
        last: string;
    };
    addedAt: string;
}

export enum CollaboratorPermissionLevel {
    Editor = 'editor',
    Creator = 'creator',
    Viewer = 'viewer',
    PeerReviewer = 'peerRvwr'
}

export interface TenantProfile {
    tenantProfileId: string;
    tenantId: string;
    tenantSlug: string;
    rootCollectionId: string;
    privateRootCollectionId: string;
    owner: OwnerInfo;
    rootCollection?: RootCollectionResponse;
    createdAt: string;
    marketplaceStatus?: MarketplaceListingStatus;
    thirdPartyCredentials?: {
        skillable?: string;
        hubspot?: string;
        kaplan?: string;
        //exams
    };
}
