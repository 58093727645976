import { OwnerInfo } from 'components/ContentContext/Interfaces';

export interface ProductDetails {
    productId: string;
    productData: { collectionId: string };
    owner: OwnerInfo;
    prices: PriceDetails[];
}

export interface PriceDetails {
    priceId: string;
    stripePriceId: string;
    interval: PurchaseInterval;
    pricePerUnitCents: number;
}

// These must match stripe enum exactly!
export enum SubscriptionInterval {
    monthly = 'month',
    yearly = 'year'
}

export enum EnrollmentInterval {
    week = 'week'
}

export const PurchaseInterval = {
    ...SubscriptionInterval,
    ...EnrollmentInterval
};

export type PurchaseInterval = EnrollmentInterval | SubscriptionInterval;

export enum IntervalType {
    subscription = 'subscription',
    enrollment = 'enrollment'
}

export interface ContentAccessGroup {
    id: string;
    active: boolean;
    collectionId: string;
    seats: {
        max: number;
        avail: number;
    };
    startsAt: string;
    endsAt: string;
}

export interface ContentAccessSeat {
    userId: string;
    assignedAt: string;
    assignedBy: string;
    active: boolean;
    collectionId: string;
    endsAt: string;
    startsAt: string;
}

export enum SagaStatus {
    inProgress = 'inProgress',
    succeeded = 'succeeded',
    failed = 'failed'
}
