import { useMemo, useCallback, useState } from 'react';

import { ClientError, GraphQLClient } from 'graphql-request';
import useGqlClient from 'hooks/useGqlClient';
import { API_COMMUNICATION } from 'lib/ApiConstants';
import AdepteductMessage from 'lib/communication/message/base/AdepteductMessage';
import MessageFactory from 'lib/communication/message/factory';
import { MessageEntityType } from 'lib/communication/message/MessageRecord';
import { UseQueryOptions, useQueries, QueryObserverResult } from 'react-query';
import useMapAsState from 'use-map-as-state';

import getMessagesForChannel, {
    MessagesForChannelResult,
    buildGetMessagesForChannelQueryKey
} from './getMessagesForChannel';

interface UseHistoricalMessageFetcherOptions {
    appendMessagesToChannel: (channelId: string, messages: AdepteductMessage[]) => void;
}

export interface UseHistoricalMessageFetcherOutput {
    entitiesToFetch: string[]; // Example: ['CHANNEL#channelId', 'CHANNEL_MEETING_SESSION#sessionId']
    enableEntityForFetching: (entityType: MessageEntityType, entityId: string) => void;
    fetchOlderMessagesForChannel: (channelId: string) => void;
    determineHasOlderMessagesForChannel: (channelId: string) => boolean;
    mostRecentQueryResultByChannelId: Record<string, QueryObserverResult<MessagesForChannelResult, ClientError>>;
}

interface BuildQueryOptionsFromChannelIdParams {
    client: GraphQLClient;
    nextCursorsByChannelId: Map<string, string>;
    appendMessagesToChannel: (channelId: string, messages: AdepteductMessage[]) => void;
    entityType: MessageEntityType;
    entityId: string;
    fromMessageId?: string;
}

const buildQueryOptionsFromChannelId = ({
    client,
    nextCursorsByChannelId,
    appendMessagesToChannel,
    entityType,
    entityId,
    fromMessageId
}: BuildQueryOptionsFromChannelIdParams): UseQueryOptions<MessagesForChannelResult, ClientError> => {
    return {
        queryKey: buildGetMessagesForChannelQueryKey(entityType, entityId, fromMessageId),
        queryFn: getMessagesForChannel(client, { entityType, entityId, fromMessageId }),
        onSuccess: async (data) => {
            if (data) {
                const pageMessages = data.getEntityMessages?.messages;
                if (pageMessages) {
                    const messagePromises = pageMessages.map((message) =>
                        MessageFactory.construct(message).catch((e) => {
                            console.error(e);

                            return null;
                        })
                    );

                    const messageClasses = await Promise.all(messagePromises);
                    appendMessagesToChannel(entityId, messageClasses.filter(Boolean) as AdepteductMessage[]);
                }

                const cursor = data.getEntityMessages?.cursor;
                if (cursor) {
                    nextCursorsByChannelId.set(entityId, cursor);
                } else if (nextCursorsByChannelId.has(entityId)) {
                    nextCursorsByChannelId.delete(entityId);
                }
            }
        }
    };
};

const useHistoricalMessageFetcher = ({
    appendMessagesToChannel
}: UseHistoricalMessageFetcherOptions): UseHistoricalMessageFetcherOutput => {
    const [entitiesToFetch, setEntitiesToFetch] = useState(new Array<string>());

    const enableEntityForFetching = useCallback(
        (entityType: MessageEntityType, entityId: string) => {
            if (!entitiesToFetch.includes(`${entityType}#${entityId}`)) {
                setEntitiesToFetch((prevEntities) => [...prevEntities, `${entityType}#${entityId}`]);
            }
        },
        [entitiesToFetch]
    );

    const cursorsByChannelId = useMapAsState(new Map<string, string>());
    const nextCursorsByChannelId = useMapAsState(new Map<string, string>());

    const { client, withQueryOptions } = useGqlClient(API_COMMUNICATION);

    const queries = useMemo(() => {
        return entitiesToFetch.map((channelId) => {
            const [entityType, entityId] = channelId.split('#');
            // Cast needed to comply with interface, which is incorrect
            return withQueryOptions(
                buildQueryOptionsFromChannelId({
                    client,
                    nextCursorsByChannelId,
                    appendMessagesToChannel,
                    entityType: entityType as MessageEntityType,
                    entityId,
                    fromMessageId: cursorsByChannelId.get(entityId)
                })
            ) as UseQueryOptions<unknown, unknown>;
        });
    }, [
        appendMessagesToChannel,
        entitiesToFetch,
        client,
        cursorsByChannelId,
        nextCursorsByChannelId,
        withQueryOptions
    ]);

    const queryResultArray = useQueries(queries);

    const fetchOlderMessagesForChannel = useCallback(
        (channelId: string): void => {
            if (nextCursorsByChannelId.has(channelId)) {
                const nextCursor = nextCursorsByChannelId.get(channelId);

                if (nextCursor) {
                    // Once this is set, the queryKey will change and a fetch will run
                    cursorsByChannelId.set(channelId, nextCursor);
                }
            }
        },
        [cursorsByChannelId, nextCursorsByChannelId]
    );

    const determineHasOlderMessagesForChannel = useCallback(
        (channelId: string): boolean => {
            return nextCursorsByChannelId.has(channelId);
        },
        [nextCursorsByChannelId]
    );

    const mostRecentQueryResultByChannelId = useMemo((): Record<
        string,
        QueryObserverResult<MessagesForChannelResult, ClientError>
    > => {
        const queryResultEntries = entitiesToFetch.map((channelId, index) => [
            channelId.split('#').pop(),
            queryResultArray[index]
        ]);
        return Object.fromEntries(queryResultEntries);
    }, [entitiesToFetch, queryResultArray]);

    return {
        entitiesToFetch,
        enableEntityForFetching,
        fetchOlderMessagesForChannel,
        determineHasOlderMessagesForChannel,
        mostRecentQueryResultByChannelId
    };
};

export default useHistoricalMessageFetcher;
