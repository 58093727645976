export enum FavoritesProgressFilter {
    All = 'All',
    InProgress = ' In progress',
    NotStarted = 'Not Started',
    Completed = 'Completed'
}

export enum FavoritesSortBy {
    Title = 'Title',
    RecentlyAdded = 'Recently added'
}
