import React from 'react';

import { modularScale } from '@adept-at/lib-react-components';
import { Button, makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const useTooltipStyles = makeStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.background.secondary
    },
    arrow: {
        fontSize: '1rem',
        '&::before': {
            backgroundColor: theme.palette.background.secondary
        }
    }
}));

const TooltipContainer = styled.div`
    display: flex;
    padding: 20px 16px;
    flex-direction: column;
`;

const HeaderText = styled.h1`
    font-size: ${modularScale(0.5)};
    margin-bottom: 8px;
`;

const InfoText = styled.p`
    font-size: ${modularScale(-0.5)};
    margin-bottom: 16px;
    font-weight: normal;
`;

const StyledButton = styled(Button)`
    align-self: flex-end;
    color: ${(props) => props.theme.colors.primary};
`;

interface TooltipProps {
    onClose: () => void;
    headerText: string;
    infoText: string;
}

export const UNMUTE_HEADER_TEXT = 'Have something to say?';
export const UNMUTE_INFO_TEXT = 'Unmute your microphone to enter the stage above and be heard by everyone.';
export const MUTE_HEADER_TEXT = 'Done talking?';
export const MUTE_INFO_TEXT = "Muting your microphone will remove you from the stage, unless you're pinned.";
export const PIN_HEADER_TEXT = 'Stay on stage?';
export const PIN_INFO_TEXT = 'Pin yourself to be able to mute your microphone without leaving the stage.';
export const CONFIRM_BUTTON_TEXT = 'Got it';

const GuidanceTooltip: React.FC<TooltipProps> = ({ onClose, headerText, infoText }) => {
    return (
        <TooltipContainer>
            <HeaderText>{headerText}</HeaderText>
            <InfoText>{infoText}</InfoText>
            <StyledButton variant="contained" onClick={onClose}>
                {CONFIRM_BUTTON_TEXT}
            </StyledButton>
        </TooltipContainer>
    );
};

export default GuidanceTooltip;
