import React, { createContext, useCallback, useState, useContext } from 'react';

import { SidebarContext } from 'components/SidebarContext';

export enum GuidanceType {
    Why = 'Why',
    Who = 'Who',
    How = 'How',
    Attachment = 'Attachment',
    Text = 'Text',
    Code = 'Code',
    Video = 'Video',
    Skill = 'Skill',
    Title = 'Title',
    Audio = 'Audio',
    Image = 'Image',
    Overview = 'Overview',
    SectionTitle = 'Section',
    Storyboard = 'Storyboard',
    Conclusion = 'Conclusion',
    QuizQuestion = 'Question',
    VirtualLab = 'Virtual Lab',
    Description = 'Description'
}

interface GuidanceContextInterface {
    activeType: GuidanceType;
    showGuidance: (type: GuidanceType) => void;
}

const GuidanceContext = createContext(undefined as unknown as GuidanceContextInterface);

const { Provider, Consumer } = GuidanceContext;

const GuidanceProvider: React.FC = ({ children }) => {
    const [type, setType] = useState<GuidanceType>(GuidanceType.Skill);
    const sidebarContext = useContext(SidebarContext);

    const showGuidance = useCallback(
        (type: GuidanceType) => {
            setType(type);
            sidebarContext?.openSidebar({ isOpen: true });
        },
        [setType, sidebarContext]
    );

    return (
        <Provider
            value={{
                activeType: type,
                showGuidance
            }}
        >
            {children}
        </Provider>
    );
};

export { GuidanceContext, GuidanceProvider, Consumer as GuidanceConsumer };
