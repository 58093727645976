import MessageRecord, { MessageAckType, MessageAckPromptFrequency, MessageAckDetail } from '../MessageRecord';
import PossibleEventFields from '../type/PossibleEventFields';

import AdepteductMessage, { IAdepteductMessage } from './AdepteductMessage';

export interface IAckableAdepteductMessage extends IAdepteductMessage {
    ackable: boolean;
    validAckTypes: MessageAckType[]; // any ack of this type will "handle" the message
    ackRequired: boolean;
    hasValidAck: boolean;
    promptFrequency?: MessageAckPromptFrequency;
    acks: MessageAckDetail[];

    setValidAckTypes(ackTypes: MessageAckType[]): void;
    isValidAckType(ackType: MessageAckType): boolean;
    isAckRequired(): boolean;
    addAck(type: MessageAckType, at: string): void;
    setHasValidAck(): void;
    hasAckOfType(lookupType: MessageAckType): boolean;
}

export default abstract class AckableAdepteductMessage<
    EventFieldsType extends PossibleEventFields = PossibleEventFields
> extends AdepteductMessage<EventFieldsType> {
    ackable = true;
    validAckTypes = new Array<MessageAckType>(); // any ack of this type will "handle" the message
    ackRequired = false;
    hasValidAck: boolean;
    promptFrequency?: MessageAckPromptFrequency;
    acks: MessageAckDetail[];

    constructor(data: MessageRecord) {
        super(data);

        this.acks = data.acks || ([] as MessageAckDetail[]);
        if (data.validAckTypes) this.validAckTypes = data.validAckTypes;
        this.hasValidAck = data.hasValidAck ?? false;
    }

    setValidAckTypes(ackTypes: MessageAckType[]): void {
        this.validAckTypes = ackTypes;
    }

    isValidAckType(ackType: MessageAckType): boolean {
        return this.validAckTypes.includes(ackType);
    }

    isAckRequired(): boolean {
        return this.ackRequired === true;
    }

    addAck(type: MessageAckType, at: string): void {
        // @TODO - don't add ack if already has one of type for message
        this.acks.push({ type, at });

        this.setHasValidAck();
    }

    setHasValidAck(): void {
        this.hasValidAck = !!this.acks.find(({ type }) => this.validAckTypes.includes(type));
    }

    hasAckOfType(lookupType: MessageAckType): boolean {
        return this.acks.some(({ type }) => type === lookupType);
    }
}
