import React, { useState } from 'react';

import { IconSize, TooltippedIconButton } from '@adept-at/lib-react-components';
import { IconButton, TextField } from '@material-ui/core';
import { mdiClose, mdiMagnify } from '@mdi/js';
import Icon from '@mdi/react';
import styled from 'styled-components';

interface SearchBarProps {
    search: string;
    setSearch: React.Dispatch<React.SetStateAction<string>>;
    expandable?: boolean;
    disabled?: boolean;
    autoFocus?: boolean;
    style?: React.CSSProperties;
}

const StyledIcon = styled(Icon)`
    overflow: visible;
    margin-right: 8px;
    color: ${(props) => props.theme.colors.textDisabled};
`;

const StyledTextField = styled(TextField)<{ $expandable: boolean }>`
    margin-right: ${(props) => (props.$expandable ? '-8px' : '0px')};
`;

const SearchBar: React.FC<SearchBarProps> = ({
    search,
    setSearch,
    expandable = true,
    disabled = false,
    autoFocus = true,
    style
}) => {
    const [searchBarExpanded, setSearchBarExpanded] = useState(expandable ? false : true);

    if (searchBarExpanded) {
        return (
            <StyledTextField
                placeholder="Search"
                size="small"
                variant="outlined"
                disabled={disabled}
                $expandable={expandable}
                autoFocus={autoFocus}
                value={search}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearch(event.target.value)}
                inputProps={{
                    'aria-label': 'Search'
                }}
                style={style}
                InputProps={{
                    startAdornment: <StyledIcon path={mdiMagnify} size={IconSize.Small} />,
                    endAdornment: expandable && (
                        <IconButton
                            aria-label="Close search bar"
                            color="primary"
                            onClick={() => {
                                setSearchBarExpanded(false);
                                setSearch('');
                            }}
                        >
                            <Icon size={IconSize.Small} path={mdiClose} />
                        </IconButton>
                    )
                }}
            />
        );
    }

    return (
        <TooltippedIconButton
            aria-label="Expand search bar"
            icon={<Icon path={mdiMagnify} />}
            title="Search"
            onClick={() => setSearchBarExpanded(true)}
        />
    );
};

export default SearchBar;
