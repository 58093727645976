import React, { useContext, useMemo } from 'react';

import { defaultMuiTheme, IconSize } from '@adept-at/lib-react-components';
import CollectionAvatar from 'components/RootProfileCollection/CollectionAvatar';
import { RootCollectionContext } from 'components/RootProfileCollection/context/RootCollectionContext';
import { ProfileContext, ProfileType } from 'context/ProfileContext';
import styled from 'styled-components';
import { modularScale } from 'utils/modular_scale';

import { NestedCollectionContext } from './context/NestedCollectionContext';

const Container = styled.div`
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.textSecondary};
    letter-spacing: 0.06px;
    line-height: 18px;
`;

const StyledProfileAlias = styled.span`
    margin-left: 0.5rem;
    font-size: 0.875rem;
`;

const BrandingLabel: React.FC<{ iconSize?: string; fontSize?: string }> = ({ iconSize, fontSize }) => {
    const { tenantProfileData } = useContext(NestedCollectionContext);
    const { aboutInfo: rootCollectionAboutInfo } = useContext(RootCollectionContext);
    const { currentProfile, status } = useContext(ProfileContext);

    const label = useMemo(() => {
        if (status === 'loading' || !currentProfile) return;

        if (currentProfile.type === ProfileType.Organization) {
            const { label } = currentProfile;
            return label;
        }

        // otherwise we are looking at MyProfile, or a profile of a User that we are following
        if (rootCollectionAboutInfo) return rootCollectionAboutInfo.title ?? '';

        // nested collection views
        return tenantProfileData?.getTenantProfileForOwner?.rootCollection?.title ?? '';
    }, [
        currentProfile,
        status,
        tenantProfileData?.getTenantProfileForOwner?.rootCollection?.title,
        rootCollectionAboutInfo
    ]);

    return (
        <Container>
            <CollectionAvatar
                size={iconSize ? iconSize : IconSize.Medium}
                placeholderFontSize={fontSize ? fontSize : '0.75rem'}
                borderRadius="50%"
            />
            <StyledProfileAlias>{label}</StyledProfileAlias>
        </Container>
    );
};

export default BrandingLabel;
