import { useMemo } from 'react';

import { CommonComponentValues } from '@adept-at/lib-react-components';
import { OptimistMutationContext, OptimistRegisterUpdaterOptions, useOptimist } from '@adept-at/lib-react-optimist';
import {
    buildGetSkillBySlugQueryKey,
    GetSkillBySlugResponse,
    useGetSkillBySlug
} from 'components/learn/getSkillBySlug';
import { ClientError } from 'graphql-request';
import produce from 'immer';
import { useSnackbar } from 'notistack';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { setSkillEditedInSessionStorage } from 'utils/skill/editedSkills';

import useGqlClient from '../../../../../hooks/useGqlClient';
import { API_CONTENT } from '../../../../../lib/ApiConstants';
import {
    GetSkillByIdResponse,
    buildQueryKey as buildGetSkillByIdQueryKey,
    useGetSkillById
} from '../../../../skills/hooks/useGetSkillById';
import { UpsertComponentResult, UpsertComponentVariables } from '../../interfaces';
import { SkillMutationProperties } from '../useUpdateSkillMeta';

import makeUpsertSkillComponent from './makeUpsertSkillComponent';

const useUpsertSkillComponent = ({
    skillId,
    skillSlug,
    tenantSlug
}: SkillMutationProperties): UseMutationResult<
    UpsertComponentResult,
    ClientError,
    UpsertComponentVariables,
    OptimistMutationContext
> => {
    const { enqueueSnackbar } = useSnackbar();
    const { client, withMutationOptions } = useGqlClient(API_CONTENT);
    const queryClient = useQueryClient();

    const skillQueryByIdKey = useMemo(() => buildGetSkillByIdQueryKey(skillId), [skillId]);
    const { refetch } = useGetSkillById(skillId, true, false);

    const skillBySlugQueryKey = useMemo(
        () => buildGetSkillBySlugQueryKey(tenantSlug ?? '', skillSlug ?? ''),
        [tenantSlug, skillSlug]
    );
    const { refetch: refetchSkillBySlug } = useGetSkillBySlug(tenantSlug ?? '', skillSlug ?? '', true, false);

    const {
        registerUpdater: registerUpsertSkillComponentUpdater,
        mutationOptions: upsertSkillComponentMutationOptions
    } = useOptimist<UpsertComponentResult, ClientError, UpsertComponentVariables>();

    const skillByIdOptions: OptimistRegisterUpdaterOptions<
        UpsertComponentResult,
        ClientError,
        UpsertComponentVariables
    > = useMemo(() => {
        return {
            onError: () => {
                enqueueSnackbar('An error occurred while adding component.', { variant: 'error' });
            },
            onSettled: () => {
                const data = queryClient.getQueryData(skillQueryByIdKey);
                if (data) {
                    refetch();
                }
                setSkillEditedInSessionStorage(skillId, tenantSlug, skillSlug);
            },
            refetchQuery: false
        };
    }, [enqueueSnackbar, refetch, queryClient, skillQueryByIdKey, skillId, tenantSlug, skillSlug]);

    const skillBySlugOptions: OptimistRegisterUpdaterOptions<
        UpsertComponentResult,
        ClientError,
        UpsertComponentVariables
    > = useMemo(() => {
        return {
            onError: () => {
                enqueueSnackbar('An error occurred while adding component.', { variant: 'error' });
            },
            onSettled: () => {
                const data = queryClient.getQueryData(skillBySlugQueryKey);
                if (data) {
                    refetchSkillBySlug();
                }
                setSkillEditedInSessionStorage(skillId, tenantSlug, skillSlug);
            },
            refetchQuery: false
        };
    }, [enqueueSnackbar, refetchSkillBySlug, queryClient, skillBySlugQueryKey, skillId, tenantSlug, skillSlug]);

    const updateComponent = ({ components, componentId, componentType, order, values }) => {
        try {
            const newValues = JSON.parse(values);
            components[componentId] = {
                type: componentType,
                order: order,
                ...newValues
            } as CommonComponentValues;
        } catch (error) {
            console.error(error);
        }
    };

    registerUpsertSkillComponentUpdater<GetSkillByIdResponse>(
        skillQueryByIdKey,
        (previous, variables) =>
            produce(previous, (draft) => {
                const components = draft.getSkillById.components;

                return updateComponent({ components, ...variables });
            }),
        skillByIdOptions
    );

    registerUpsertSkillComponentUpdater<GetSkillBySlugResponse>(
        skillBySlugQueryKey,
        (previous, variables) =>
            produce(previous, (draft) => {
                const components = draft.getEntityBySlug.components;

                return updateComponent({ components, ...variables });
            }),
        skillBySlugOptions
    );

    return useMutation<UpsertComponentResult, ClientError, UpsertComponentVariables, OptimistMutationContext>(
        makeUpsertSkillComponent(client),
        withMutationOptions({
            ...upsertSkillComponentMutationOptions,
            onSuccess: (...args) => {
                if (upsertSkillComponentMutationOptions.onSuccess) {
                    upsertSkillComponentMutationOptions.onSuccess(...args);
                }
            }
        })
    );
};

export default useUpsertSkillComponent;
