import React, { useEffect } from 'react';

import { Box, Button, Typography, CircularProgress } from '@material-ui/core';
import { mdiVideoOutline, mdiAccountBoxOutline, mdiMonitorScreenshot, mdiPictureInPictureBottomRight } from '@mdi/js';
import Icon from '@mdi/react';

import { useEditableComponent } from '../../../../mixins/editor';
import { useTypeSelectorNavigation, TypeOptionTitle, VerticalDivider } from '../../TypeSelector';
import { VideoType } from '../../VideoTypes';

import { RecorderLaunchAction, useLaunchRecorder } from './hooks/useLaunchRecorder';
import { RecorderLaunchDetails } from './RecorderLaunchDetails';
import { SourceOptionsContainer, SourceOption, SourceOptionTitle, SourceOptionIcon } from './SourceSelector.styles';

enum SourceType {
    Webcam = 'WEBCAM',
    Screen = 'SCREEN',
    ScreenAndWebcam = 'SCREEN_AND_WEBCAM'
}

const sourceSelectorOptions = {
    [SourceType.Webcam]: {
        title: 'Webcam',
        icon: mdiAccountBoxOutline,
        recordLaunchAction: RecorderLaunchAction.Webcam
    },
    [SourceType.Screen]: {
        title: 'Screen Capture',
        icon: mdiMonitorScreenshot,
        recordLaunchAction: RecorderLaunchAction.Screen
    },
    [SourceType.ScreenAndWebcam]: {
        title: 'Screen & Webcam',
        icon: mdiPictureInPictureBottomRight,
        recordLaunchAction: RecorderLaunchAction.ScreenAndWebcam
    }
};

interface VideoSourceSelectorProps {
    onSelect: () => void;
}

export const VideoSourceSelector: React.FC<VideoSourceSelectorProps> = ({ onSelect }) => {
    const { navigateTo, isInPath } = useTypeSelectorNavigation();
    const { onChange } = useEditableComponent();
    const { launch, loading, launched, relaunch, assetId } = useLaunchRecorder();

    useEffect(() => {
        if (!assetId) {
            return;
        }

        onChange<string>('videoId')(assetId);
    }, [assetId, onChange]);

    if (loading) {
        return (
            <Box display="flex" flexDirection="row" justifyContent="center" height="100%" alignItems="center">
                <CircularProgress />
            </Box>
        );
    }

    if (
        launched &&
        (isInPath(SourceType.Screen) || isInPath(SourceType.Webcam) || isInPath(SourceType.ScreenAndWebcam))
    ) {
        return <RecorderLaunchDetails onRelaunch={relaunch} />;
    }

    if (isInPath(VideoType.Capture)) {
        return (
            <SourceOptionsContainer>
                {Object.entries(sourceSelectorOptions).map(([navId, { title, icon, recordLaunchAction }], idx) => (
                    <React.Fragment key={idx}>
                        {idx !== 0 && <VerticalDivider />}
                        <SourceOption>
                            <SourceOptionTitle data-testid="video-component-record-video-zone">
                                <SourceOptionIcon path={icon} size="24px" />
                                <Typography variant="subtitle1">{title}</Typography>
                            </SourceOptionTitle>
                            <Button
                                data-testid="video-component-select-video-source-button"
                                variant="text"
                                color={'primary'}
                                onClick={(e) => {
                                    e.stopPropagation();

                                    navigateTo(navId);
                                    launch(recordLaunchAction);
                                }}
                            >
                                Select source
                            </Button>
                        </SourceOption>
                    </React.Fragment>
                ))}
            </SourceOptionsContainer>
        );
    }

    return (
        <>
            <TypeOptionTitle data-testid="video-component-record-video-zone">
                <Icon path={mdiVideoOutline} size="24px" />
                <Typography variant="subtitle1">Record a Video</Typography>
            </TypeOptionTitle>
            <Button
                data-testid="video-component-select-video-source-button"
                variant="text"
                color={'primary'}
                onClick={() => {
                    onSelect();
                }}
            >
                Select source
            </Button>
        </>
    );
};
