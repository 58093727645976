import React, { useCallback, useContext } from 'react';

import { matchPath } from 'react-router';
import { Link, useLocation } from 'react-router-dom';

import { LinkName, NavIconWrapper, NavItemIcon, StyledListItem } from './Nav.styles';
import { NavContext } from './NavContext';

interface NavLinkProps {
    to?: string;
    label: string;
    match?: string;
    iconPath?: string;
    Icon?: any;
    depth?: number;
    onClick?: (e) => void;
}

const NavLink: React.FC<NavLinkProps> = ({ Icon, to, label, iconPath, match = null, depth = 0 }) => {
    const location = useLocation();

    const isActive = (toLocation: string): boolean => {
        const escapedPath = toLocation && toLocation.replace(/([.+*?=^!:${}()[\]|/\\])/g, '\\$1');

        const isMatching = matchPath(location.pathname, {
            path: match || escapedPath,
            exact: false,
            strict: false
        });

        return !!isMatching;
    };

    const renderIcon = () => {
        if (iconPath) {
            return <NavItemIcon path={iconPath} />;
        }

        if (Icon) {
            return (
                <NavIconWrapper depth={depth}>
                    <Icon />
                </NavIconWrapper>
            );
        }
    };

    if (!to) {
        return (
            <StyledListItem button>
                <span>
                    {renderIcon()}
                    <LinkName>{label}</LinkName>
                </span>
            </StyledListItem>
        );
    }

    return (
        <Link to={to}>
            <StyledListItem button selected={isActive(to)}>
                {renderIcon()}
                <LinkName>{label}</LinkName>
            </StyledListItem>
        </Link>
    );
};

export default NavLink;
