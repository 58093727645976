import React, { FC, useCallback, useMemo } from 'react';

import { CollaboratorPermissionLevel, EntityInfo } from 'components/ContentContext/Interfaces';
import { EMAIL_REGEX, UUID_REGEX } from 'lib/constants';

import useCreateManyEntityCollbaborators from '../hooks/useCreateManyEntityCollaborators';
import useDeleteEntityCollaborator from '../hooks/useDeleteEntityCollaborator';
import useDeleteEntityCollaboratorInvitation from '../hooks/useDeleteEntityCollaboratorInvitation';
import { useGetEntityCollaborators } from '../hooks/useGetEntityCollaborators';
import useUpdateEntityCollaboratorInvitationRole from '../hooks/useUpdateEntityCollaboratorInvitationRole';
import useUpdateEntityCollaboratorRole from '../hooks/useUpdateEntityCollaboratorRole';

import AddEntityUserRoleDropIn, { PublicPermissions, SharingFormProps } from './AddEntityUserRoleDropIn';
import { COLLABORATOR_DEFAULT_PERMISSION_LEVEL } from './permissionOptions';

interface AddSingleEntityRolesTabProps {
    entity: EntityInfo;
    onClose: () => any;
    roleFilter: CollaboratorPermissionLevel[];
    SharingForm: FC<SharingFormProps>;
    toggleEntityPublicity: (setIsLoading: any) => Promise<void>;
    isPublic?: boolean;
}

interface AddManyEntityRolesTabProps {
    entities: EntityInfo[];
    onClose: () => any;
    SharingForm: FC<SharingFormProps>;
    toggleEntityPublicity: (setIsLoading: any) => Promise<void>;
    isPublic?: boolean;
}

interface AddEntityRolesTabProps extends AddManyEntityRolesTabProps {
    roleFilter: CollaboratorPermissionLevel[];
}

export const AddManyEntitiesCollaboratorsTab: React.FC<AddManyEntityRolesTabProps> = ({
    entities,
    onClose,
    SharingForm,
    toggleEntityPublicity,
    isPublic
}) => {
    const onSuccessAddingOrRemovingEntityCollaborators = (close?: boolean) => {
        if (close) onClose();
    };

    const { createManyEntityCollaborators, createManyEntityCollaboratorsLoading } = useCreateManyEntityCollbaborators({
        onFinished: () => onSuccessAddingOrRemovingEntityCollaborators(true),
        entities
    });

    const addCollaborators = useCallback(
        (emailsOrUserIds: string[], role: CollaboratorPermissionLevel, message: string | undefined) => {
            const { emails, userIds } = emailsOrUserIds.reduce<{ emails: string[]; userIds: string[] }>(
                (prev, emailOrUserId) => {
                    if (emailOrUserId.match(EMAIL_REGEX)) {
                        prev.emails.push(emailOrUserId);
                    } else if (emailOrUserId.match(UUID_REGEX)) {
                        prev.userIds.push(emailOrUserId);
                    }
                    return prev;
                },
                { emails: [], userIds: [] }
            );
            createManyEntityCollaborators({
                entities,
                emails,
                role,
                userIds,
                message,
                overrideExistingPermissions: true
            });
        },
        [createManyEntityCollaborators, entities]
    );

    return (
        <AddEntityUserRoleDropIn
            id="add-viewers-dialog"
            createEntityUserRolesLoading={createManyEntityCollaboratorsLoading}
            deleteEntityUserRoleInvitationLoading={false}
            updateEntityUserRoleInvitationRoleLoading={false}
            existingUsers={[]}
            onAddEntityUsers={addCollaborators}
            onExistingPermissionChange={() => {}}
            onInvitationalPermissionChange={() => {}}
            onExistingPermissionRemove={() => {}}
            onInvitationalPermissionRemove={() => {}}
            onClose={onClose}
            open={true}
            SharingForm={SharingForm}
            //add in logic to determine if the skill is public instead of static viewer role
            defaultRole={isPublic ? PublicPermissions.public : COLLABORATOR_DEFAULT_PERMISSION_LEVEL}
            toggleEntityPublicity={toggleEntityPublicity}
        />
    );
};

export const AddSingleEntityRolesTab: React.FC<AddSingleEntityRolesTabProps> = ({
    entity,
    onClose,
    roleFilter,
    SharingForm,
    toggleEntityPublicity,
    isPublic
}) => {
    const { entityCollaborators = [], refetch } = useGetEntityCollaborators({
        entity,
        roleFilter
    });

    const onSuccessAddingOrRemovingEntityCollaborators = (close?: boolean) => {
        refetch();
        if (close) onClose();
    };

    const { createManyEntityCollaborators, createManyEntityCollaboratorsLoading } = useCreateManyEntityCollbaborators({
        onFinished: () => onSuccessAddingOrRemovingEntityCollaborators(true),
        entities: [entity]
    });

    const addCollaborators = useCallback(
        (emailsOrUserIds: string[], role: CollaboratorPermissionLevel, message: string | undefined) => {
            const { emails, userIds } = emailsOrUserIds.reduce<{ emails: string[]; userIds: string[] }>(
                (prev, emailOrUserId) => {
                    if (emailOrUserId.match(EMAIL_REGEX)) {
                        prev.emails.push(emailOrUserId);
                    } else if (emailOrUserId.match(UUID_REGEX)) {
                        prev.userIds.push(emailOrUserId);
                    }
                    return prev;
                },
                { emails: [], userIds: [] }
            );
            createManyEntityCollaborators({ entities: [entity], emails, role, userIds, message });
        },
        [createManyEntityCollaborators, entity]
    );

    const { deleteEntityCollaborator } = useDeleteEntityCollaborator({
        onFinished: () => {},
        entityInfo: entity,
        roleFilter
    });

    const existingPermissionRemove = useCallback(
        (userId: string, specificRole: CollaboratorPermissionLevel) => {
            deleteEntityCollaborator({ userId, entityInfo: entity, specificRole });
        },
        [deleteEntityCollaborator, entity]
    );

    const { updateEntityCollaboratorRole } = useUpdateEntityCollaboratorRole({
        onFinished: onSuccessAddingOrRemovingEntityCollaborators
    });

    const existingPermissionChange = useCallback(
        ({ userId, newPermission }: { userId: string; newPermission: CollaboratorPermissionLevel }) => {
            updateEntityCollaboratorRole({ userId, entityInfo: entity, role: newPermission });
        },
        [entity, updateEntityCollaboratorRole]
    );

    const { updateEntityCollaboratorInvitationRole, updateEntityCollaboratorInvitationRoleLoading } =
        useUpdateEntityCollaboratorInvitationRole({
            onFinished: onSuccessAddingOrRemovingEntityCollaborators,
            skillId: entity.id
        });

    const invitationalPermissionUpdate = useCallback(
        (invitationId: string, role: CollaboratorPermissionLevel) => {
            updateEntityCollaboratorInvitationRole({ invitationId, skillId: entity.id, role });
        },
        [updateEntityCollaboratorInvitationRole, entity]
    );

    const { deleteEntityCollaboratorInvitation, deleteEntityCollaboratorInvitationLoading } =
        useDeleteEntityCollaboratorInvitation({
            onFinished: onSuccessAddingOrRemovingEntityCollaborators,
            skillId: entity.id
        });

    const invitationalPermissionRemove = useCallback(
        (invitationId: string) => {
            deleteEntityCollaboratorInvitation({ invitationId, skillId: entity.id });
        },
        [deleteEntityCollaboratorInvitation, entity]
    );

    return (
        <AddEntityUserRoleDropIn
            id="add-viewers-dialog"
            createEntityUserRolesLoading={createManyEntityCollaboratorsLoading}
            deleteEntityUserRoleInvitationLoading={deleteEntityCollaboratorInvitationLoading}
            updateEntityUserRoleInvitationRoleLoading={updateEntityCollaboratorInvitationRoleLoading}
            existingUsers={entityCollaborators}
            onAddEntityUsers={addCollaborators}
            onExistingPermissionChange={existingPermissionChange}
            onInvitationalPermissionChange={invitationalPermissionUpdate}
            onExistingPermissionRemove={existingPermissionRemove}
            onInvitationalPermissionRemove={invitationalPermissionRemove}
            onClose={onClose}
            open={true}
            SharingForm={SharingForm}
            //add in logic to determine if the skill is public instead of static role
            defaultRole={isPublic ? PublicPermissions.public : roleFilter[0]}
            toggleEntityPublicity={toggleEntityPublicity}
        />
    );
};
export const AddEntityRolesTab: React.FC<AddEntityRolesTabProps> = ({
    entities,
    onClose,
    roleFilter,
    SharingForm,
    toggleEntityPublicity,
    isPublic
}) => {
    const RoleTab = useMemo(() => {
        if (entities.length > 1) {
            return AddManyEntitiesCollaboratorsTab;
        } else {
            return AddSingleEntityRolesTab;
        }
    }, []);

    return (
        <RoleTab
            entities={entities}
            entity={entities[0]}
            onClose={onClose}
            SharingForm={SharingForm}
            roleFilter={roleFilter}
            toggleEntityPublicity={toggleEntityPublicity}
            isPublic={isPublic}
        />
    );
};

export default AddEntityRolesTab;
