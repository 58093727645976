import { ForwardRefExoticComponent, RefAttributes } from 'react';

import AdepteductMessageProps from 'components/communication/message/AdepteductMessageProps';
import DefaultAdepteductMessage from 'components/communication/message/chat/DefaultAdepteductMessage';
import { ObjectSchema } from 'yup';

import MessageRecord, {
    MessageDestination,
    StandardMessageEntityType,
    Reactions,
    MessageAckDetail
} from '../MessageRecord';
import { MessageEventType } from '../type';
import PossibleEventFields from '../type/PossibleEventFields';

export interface IAdepteductMessage<EventFieldsType extends PossibleEventFields = PossibleEventFields> {
    id: string;
    channelId: string;
    sentAt: string;
    deletedAt?: string;
    fields: EventFieldsType;
    schema;
    senderId: string;
    type: MessageEventType;
    destinations: MessageDestination[];
    reactions: Reactions;
    responses: number;
    responders: Record<string, number>;
    parent?: string;
    hasValidAck: boolean;
    version: number;

    setFieldsFromData(): void;
    isValid(): Promise<boolean>;
    hasDestination(destination: MessageDestination): boolean;
    hasValidDestination(validDestinations: MessageDestination[]): boolean;
    hasOptimisticId(): boolean;
    forGraphql(): { channelId: string };
    mapDestinationToComponent(
        destination?: MessageDestination
    ): ForwardRefExoticComponent<AdepteductMessageProps<PossibleEventFields> & RefAttributes<HTMLDivElement | null>>;
}

export default abstract class AdepteductMessage<EventFieldsType extends PossibleEventFields = PossibleEventFields>
    implements IAdepteductMessage<EventFieldsType>
{
    protected data: MessageRecord;

    public id: string;
    public channelId: string;
    public entityType: StandardMessageEntityType;
    public entityId: string;
    public sentAt: string;
    public deletedAt?: string;
    public fields: EventFieldsType;
    public schema: ObjectSchema | undefined;
    public senderId: string;
    public type: MessageEventType;
    public destinations: MessageDestination[];
    public reactions: Reactions;
    public acks: MessageAckDetail[];
    public responders: Record<string, number>;
    public responses: number;
    public parent?: string;
    public hasValidAck: boolean;
    public version = 1;

    constructor(data: MessageRecord) {
        this.data = data;
        this.hasValidAck = data.hasValidAck;
        this.fields = {} as EventFieldsType;
        this.type = data.type;
        this.id = data.id;
        this.destinations = data.destinations ?? [];
        this.senderId = data.senderId;
        this.sentAt = data.sentAt;
        this.deletedAt = data.deletedAt;
        this.channelId = data.channelId;
        this.entityType = data.entityType;
        this.entityId = data.entityId;
        this.reactions = data.reactions ?? {};
        this.acks = data.acks ?? [];
        this.version = data.version ?? 1;
        this.parent = data.parent;
        this.responses = data.responses ?? 0;
        this.responders = data.responders ?? {};
    }

    setFieldsFromData(): void {
        try {
            /**
             * We cannot use stripUnknown unless fields objects (e.g. variables) have an appropriate shape defined for
             * any subtype, etc.
             */
            // this.fields = this.schema.cast(this.data.fields, { stripUnknown: true });
            this.fields = this.schema?.cast(this.data.fields) as EventFieldsType;
        } catch (e) {
            // do nothing
        }
    }

    async isValid(): Promise<boolean> {
        if (Object.entries(this.fields).length === 0) {
            return false;
        }

        return (await this.schema?.isValid(this.fields)) ?? false;
    }

    hasDestination(destination: MessageDestination): boolean {
        return this.destinations.includes(destination);
    }

    hasValidDestination(validDestinations: MessageDestination[]): boolean {
        return this.destinations.some((destination) => validDestinations.includes(destination));
    }

    // Optimistic ids are given when created client side
    hasOptimisticId = (): boolean => (this.id ? /^tmp-.*/.test(this.id) : false);

    forGraphql(): { channelId: string } {
        return {
            channelId: this.channelId
        };
    }

    mapDestinationToComponent = (
        _?: MessageDestination
    ): ForwardRefExoticComponent<AdepteductMessageProps<PossibleEventFields> & RefAttributes<HTMLDivElement | null>> =>
        DefaultAdepteductMessage;
}
