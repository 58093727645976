import React, { useContext, useState } from 'react';

import styled from 'styled-components';

import Comment from './Comment';
import { CommentContext } from './CommentContext';
import CommentList from './CommentList';
import { Comment as CommentType } from './interfaces';

const NestedThread = styled.div`
    padding-left: 0.5rem;
`;

const Divider = styled.hr`
    border: 0;
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
`;

const ShowMore = styled.div`
    color: ${(props) => props.theme.colors.primary};
    cursor: pointer;
    padding: 1rem;
`;

interface ThreadProps {
    comments: CommentType[];
}

const Thread: React.FC<ThreadProps> = ({ comments }) => {
    const [showAll, setShowAll] = useState(false);
    const { currentComments, isThreadView, threadParentComment } = useContext(CommentContext);

    const renderShowMore = () => {
        if (comments.length < 3) return null;
        return <ShowMore onClick={() => setShowAll(true)}>Show {comments.length - 2} more</ShowMore>;
    };

    if (!currentComments) {
        return null;
    }

    if (isThreadView && threadParentComment) {
        return (
            <div>
                <Comment comment={threadParentComment} />
                <Divider />
                <NestedThread>
                    <CommentList comments={currentComments} />
                </NestedThread>
            </div>
        );
    }

    if (showAll) {
        return (
            <div>
                <CommentList comments={comments} />
            </div>
        );
    }

    const firstTwo = comments.slice(0, 2);

    return (
        <div>
            <CommentList comments={firstTwo} />
            {renderShowMore()}
        </div>
    );
};

export default Thread;
