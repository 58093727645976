import React from 'react';

import { CircularProgress, makeStyles } from '@material-ui/core';
import styled from 'styled-components';

import { StyledTableCell } from './TableCell.styles';

interface PercentCompleteCellProps {
    percentComplete?: number;
}

const CellWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const useProgressStyles = makeStyles((theme) => ({
    bottom: {
        color: theme.palette.action.active
    },
    top: {
        color: theme.palette.primary.main,
        position: 'absolute',
        left: 0
    }
}));

const ProgressWrapper = styled.div`
    position: relative;
    margin-right: 1rem;
`;

export const PERCENT_COMPLETE_WIDTH = 135;

export const PercentCompleteCell: React.FC<PercentCompleteCellProps> = ({ percentComplete }) => {
    const classes = useProgressStyles();

    return (
        <StyledTableCell width={PERCENT_COMPLETE_WIDTH}>
            <CellWrapper>
                <ProgressWrapper>
                    <CircularProgress variant="determinate" size={32} className={classes.bottom} value={100} />
                    <CircularProgress
                        size={32}
                        variant="determinate"
                        value={percentComplete ?? 0}
                        className={classes.top}
                    />
                </ProgressWrapper>
                {`${percentComplete ?? 0}%`}
            </CellWrapper>
        </StyledTableCell>
    );
};
