import React, { ReactElement, useContext, useState } from 'react';

import { CircularProgress } from '@material-ui/core';
import { BookshelvesProvider } from 'components/bookshelves/BookshelvesContext';
import { ScheduleProvider } from 'components/dash/Schedule/ScheduleContext';
import { Favorites as FavoritesView } from 'components/Favorites';
import { ModalView } from 'components/modals/Bookshelves/BookshelfModal';
import { ProfileContext } from 'context/ProfileContext';
import AppLayout from 'layouts/AppLayout';
import { useTheme } from 'styled-components';

export const Favorites = (): ReactElement => {
    const { currentProfile } = useContext(ProfileContext);
    const { colors } = useTheme();
    const [isGridView, setIsGridView] = useState(true);

    if (!currentProfile) {
        return <CircularProgress />;
    }

    return (
        <AppLayout
            withoutPadding
            pageTitle="Favorites"
            backButton={false}
            mainContentOverflow
            backgroundColor={!isGridView ? colors.surface : undefined}
        >
            <ScheduleProvider>
                <BookshelvesProvider shouldFetchOnInitialRender={false} initialModalView={ModalView.AddExisting}>
                    <FavoritesView isGridView={isGridView} setIsGridView={setIsGridView} />
                </BookshelvesProvider>
            </ScheduleProvider>
        </AppLayout>
    );
};
