import styled from 'styled-components';

export const LoadingWrapper = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
`;

export const LoadingSpinner = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    z-index: 2;
    display: flex;
`;
