import React from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { CircularProgress, Tooltip } from '@material-ui/core';
import styled from 'styled-components';

const Container = styled.div`
    align-items: center;
    overflow-x: auto;
    text-overflow: ellipsis;
`;

const LOADING_TEXT = 'Loading Product Details';

export const LoadingProductDetails: React.FC = () => {
    return (
        <Tooltip title={LOADING_TEXT}>
            <Container>
                <CircularProgress
                    size={IconSize.Small}
                    color={'inherit'}
                    style={{ marginRight: '0.5rem', marginLeft: '0.5rem' }}
                />
                {LOADING_TEXT}
            </Container>
        </Tooltip>
    );
};
