import React, { useState } from 'react';

import { CircularProgress } from '@material-ui/core';
import { EditableText } from 'components/forms/EditableText';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';

import { useComponentEngineComponent } from '../../ComponentContext';
import { ComponentFocus, EditButtons, useEditableComponent } from '../../mixins/editor';
import { useFocusableComponent } from '../../mixins/focusable';

import FilePreview from './FilePreview';
import { LoadingSpinner } from './FilePreview.styles';
import { AssetType, FileUpload } from './FileUpload';
import PDFView from './View';

interface PDFEditProps {
    onCancel?: () => void;
    onSave?: (data) => void;
}

interface AttachmentComponentValues {
    assetId: string;
    uploadedFileName: string;
    pdf: {
        url: string;
    };
}

const PreviewWrapper = styled.div`
    color: ${(props) => props.theme.colors.black};
    position: relative;
`;

const PDFEdit: React.FC<PDFEditProps> = ({ onCancel, onSave }) => {
    const { id } = useComponentEngineComponent();
    const { enqueueSnackbar } = useSnackbar();

    const { doesComponentHaveAnyFocus } = useFocusableComponent();

    const {
        currentValues: { pdf, assetId, uploadedFileName },
        onSave: defaultOnSave,
        onCancel: defaultOnCancel,
        onChange
    } = useEditableComponent<AttachmentComponentValues>();

    const [filePreview, setFilePreview] = useState<string | null>(null);

    if (!doesComponentHaveAnyFocus(id, [ComponentFocus.Edit, ComponentFocus.AddAndEdit])) {
        return filePreview ? <FilePreview file={filePreview} /> : <PDFView pdf={pdf} />;
    }

    const save = () => {
        if (onSave) {
            onSave({ assetId, linkText: uploadedFileName });
        } else {
            defaultOnSave(['assetId']);
        }
    };

    return (
        <>
            <FileUpload
                assetType={AssetType.Attachment}
                onSuccess={(assetId, previewUrl) => {
                    onChange<string | null>('assetId')(assetId);

                    if (previewUrl) {
                        setFilePreview(previewUrl);
                    }
                }}
                onError={(_code, message) => {
                    enqueueSnackbar(message, { variant: 'error' });
                }}
                onFileSelected={({ name: fileName }) => {
                    onChange<string>('uploadedFileName')(fileName);
                }}
                previewRenderer={({ loading }) => {
                    return (
                        <PreviewWrapper>
                            <EditableText
                                modularScaleValue={1}
                                initialContent={uploadedFileName}
                                canEdit={!loading}
                                validation={(value) => !!value.trim()}
                                saveContent={(value) => onChange<string>('uploadedFileName')(value)}
                            />
                            {loading && (
                                <LoadingSpinner>
                                    <CircularProgress size={100} color="primary" />
                                </LoadingSpinner>
                            )}
                        </PreviewWrapper>
                    );
                }}
            />

            {/* // onSave should be disabled if there's nothing to save... Could this be dependent on component dirty state? */}
            {/* @TODO onCancel is clearing the changed state from updating the poster image */}
            <EditButtons onCancel={onCancel ?? defaultOnCancel} onSave={save} />
        </>
    );
};

export default PDFEdit;
