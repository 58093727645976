import { useRef, useState, useCallback, useEffect } from 'react';

import { getMaxCropPercentage } from 'lib/imageUtils';
import { useDebouncedCallback } from 'use-debounce';

const BUILD_CROPPED_IMAGE_DELAY_IN_MS = 500;

const UNIT_PERCENT = '%';
const UNIT_PIXELS = 'px';

export interface CropDetails {
    unit: string;
    width: number;
    height: number;
    x: number;
    y: number;
    aspect?: number;
}

interface ImageDimensions {
    width: number;
    height: number;
}
interface ImageTypeDimensions {
    display: ImageDimensions;
    natural: ImageDimensions;
}

/**
 * @FIXME this utility needs to be used because the backend image processor isn't properly make sure it has integers
 * when it requires them.
 */
const cropToIntegers = (crop: CropDetails): CropDetails =>
    Object.entries(crop).reduce(
        (memo, [key, value]) => ({
            ...memo,
            [key]: ['aspect', 'unit'].includes(key) ? value : Math.floor(value)
        }),
        {} as CropDetails
    );

const getCropValuesWithScaleConsidered = (crop: CropDetails, imageDimensions: ImageTypeDimensions) => {
    if (crop.unit === UNIT_PERCENT) {
        return cropToIntegers({
            unit: UNIT_PIXELS,
            x: (crop.x / 100) * imageDimensions.natural.width,
            y: (crop.y / 100) * imageDimensions.natural.height,
            width: (crop.width / 100) * imageDimensions.natural.width,
            height: (crop.height / 100) * imageDimensions.natural.height
        });
    }

    // Scale up crop settings
    const scaleX = imageDimensions.natural.width / imageDimensions.display.width;
    const scaleY = imageDimensions.natural.height / imageDimensions.display.height;

    return cropToIntegers({
        unit: UNIT_PIXELS,
        x: crop.x * scaleX,
        y: crop.y * scaleY,
        width: crop.width * scaleX,
        height: crop.height * scaleY
    });
};

interface UseCropInterface {
    onImageLoaded: (image: HTMLImageElement) => void;
    cropForWorkspace: CropDetails;
    cropForOriginal: CropDetails;
    croppedImage: null | string;
    onChangeCrop: (crop: CropDetails) => void;
}

interface UseCropProps {
    aspectRatio?: number | null;
}

export const useCrop = ({ aspectRatio = null }: UseCropProps): UseCropInterface => {
    const originalImage = useRef<null | HTMLImageElement>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [croppedImage, setCroppedImage] = useState<null | string>(null);
    const [cropForWorkspace, setCropForWorkspace] = useState<CropDetails>({} as CropDetails);
    const [cropForOriginal, setCropForOriginal] = useState<CropDetails>({} as CropDetails);
    const imageTypeDimensions = useRef<ImageTypeDimensions>();

    const updateCrop = useCallback((newCrop: CropDetails) => {
        setCropForWorkspace(newCrop);

        if (!imageTypeDimensions.current) {
            return;
        }

        const cropValuesWithScaleConsidered = getCropValuesWithScaleConsidered(newCrop, imageTypeDimensions.current);

        setCropForOriginal(cropToIntegers(cropValuesWithScaleConsidered));
    }, []);

    const onImageLoaded = useCallback(
        (loadedImage) => {
            imageTypeDimensions.current = {
                display: { width: loadedImage.width, height: loadedImage.height },
                natural: { width: loadedImage.naturalWidth, height: loadedImage.naturalHeight }
            };

            originalImage.current = loadedImage;

            setLoaded(true);

            if (!aspectRatio) {
                updateCrop({
                    unit: UNIT_PERCENT,
                    width: 100,
                    height: 100,
                    x: 0,
                    y: 0
                });

                return;
            }

            // We use the natural width and height because the actual width and height
            // may not have the same aspect ratio due to rounding.
            const { widthPercent, heightPercent } = getMaxCropPercentage({
                imageWidthInPixels: loadedImage.naturalWidth,
                imageHeightInPixels: loadedImage.naturalHeight,
                aspectRatio
            });

            // Center
            const y = (100 - heightPercent) / 2;
            const x = (100 - widthPercent) / 2;

            updateCrop({
                unit: UNIT_PERCENT,
                width: widthPercent,
                height: heightPercent,
                x,
                y,
                aspect: aspectRatio
            });
        },
        [aspectRatio, updateCrop]
    );

    /**
     * Use active image src to render the image "in memory" and provide a base64 encoded string that represents the
     * cropped image. This can then be used to display the state of the crop.
     */
    const [buildCroppedImage] = useDebouncedCallback(async () => {
        if (originalImage.current === null) {
            return Promise.resolve(null);
        }

        const imageToRender: HTMLImageElement = document.createElement('img') as HTMLImageElement;

        if (!imageToRender) {
            return Promise.resolve(null);
        }

        imageToRender.src = originalImage.current.src;

        imageToRender.onload = function () {
            const canvas = document.createElement('canvas');

            if (!imageTypeDimensions.current) {
                return Promise.resolve(null);
            }

            canvas.width = cropForOriginal.width;
            canvas.height = cropForOriginal.height;

            const ctx = canvas.getContext('2d');

            if (!ctx) {
                return Promise.resolve(null);
            }

            ctx.clearRect(0, 0, cropForOriginal.width, cropForOriginal.height);

            ctx.drawImage(
                imageToRender,
                cropForOriginal.x,
                cropForOriginal.y,
                cropForOriginal.width,
                cropForOriginal.height,
                0,
                0,
                cropForOriginal.width,
                cropForOriginal.height
            );

            const base64Image = canvas.toDataURL('image/png'); // Default to PNG to support transparent image uploads

            setCroppedImage(base64Image);
        };
    }, BUILD_CROPPED_IMAGE_DELAY_IN_MS);

    // Update the preview (imageWithCropApplied) each time we set a new crop value
    useEffect(() => {
        buildCroppedImage();
    }, [buildCroppedImage, cropForWorkspace]);

    return {
        onImageLoaded,
        cropForWorkspace,
        cropForOriginal,
        croppedImage,
        onChangeCrop: (newCrop) => {
            // Protect against first image load where the crop values will be empty
            if (!loaded) {
                return;
            }

            updateCrop(newCrop);
        }
    };
};
