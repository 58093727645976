import React from 'react';

import { QuestionComponentValues, QuestionType, AnswerOption } from '@adept-at/lib-react-components';
import { Collapse, Fade, Box, FormControl, TextField, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { AddAnswer } from '../../helpers/AddAnswer';
import { ToggleRandomOrder } from '../../helpers/ToggleRandomOrder';
import { BoxWithPadding } from '../../Question.styles';

interface Props {
    values: QuestionComponentValues;
    onChange: <TComponentFieldValue>(field: string) => (value: TComponentFieldValue) => void;
    onOptionChange: (index: number, value: AnswerOption) => void;
    error: any;
}

export const OrderEdit: React.FC<Props> = ({
    values: { questionType, answerOptions, hasRandomOrder },
    onChange,
    onOptionChange,
    error
}) => (
    <Collapse timeout={500} in={questionType === QuestionType.Order}>
        <Fade timeout={800} in={questionType === QuestionType.Order}>
            <FormControl component="fieldset">
                {answerOptions.map((option, index) => (
                    <BoxWithPadding key={option.key} display="flex" alignItems="space-between">
                        {/* TODO: add drag and drop for ordering? */}
                        <TextField
                            label={`Order ${index + 1}`}
                            key={`${option.key}-text`}
                            name="answerOptions"
                            variant="outlined"
                            fullWidth
                            value={option.text}
                            onChange={({ target: { value } }) => {
                                const updatedContent = { ...option, text: value };
                                onOptionChange(index, updatedContent);
                            }}
                            error={!!error[`orderEdit-text-${index}`]}
                            helperText={error[`orderEdit-text-${index}`]}
                        />

                        <IconButton
                            key={`${option.key}-icon-button`}
                            onClick={() => {
                                if (answerOptions.length <= 1) {
                                    return;
                                }
                                const draftAnswerOptions = [...answerOptions];
                                draftAnswerOptions.splice(index, 1);
                                onChange('answerOptions')(draftAnswerOptions);
                            }}
                        >
                            <DeleteIcon key={`${option.key}-del-icon`} />
                        </IconButton>
                    </BoxWithPadding>
                ))}
                <Box display="flex" justifyContent="space-between">
                    <AddAnswer answerOptions={answerOptions} onChange={onChange}>
                        Add Another Answer
                    </AddAnswer>
                    <ToggleRandomOrder hasRandomOrder={hasRandomOrder} onChange={onChange} />
                </Box>
            </FormControl>
        </Fade>
    </Collapse>
);
export default OrderEdit;
