import React from 'react';

import { AttachmentComponentValues } from '@adept-at/lib-react-components';
import { EmbeddedEntityComponentValues } from 'components/engine/common/RichTextEditor/utils';
import { useEditableComponent } from 'components/engine/mixins/editor';
import { EditableText } from 'components/forms/EditableText';

import PDFEdit from './Edit';

export const emptyAttachmentComponentValues = {
    linkText: undefined,
    url: undefined
};

interface AttachmentEditProps {
    onCancel?: () => void;
    onSave?: (values: Partial<EmbeddedEntityComponentValues>) => void;
}

export const AttachmentEdit: React.FC<AttachmentEditProps> = ({ onCancel, onSave }) => {
    const { currentValues } = useEditableComponent<AttachmentComponentValues>();

    return currentValues?.linkText ? (
        <EditableText
            initialIsEditing={true}
            modularScaleValue={1}
            initialContent={currentValues.linkText}
            validation={(value) => !!value.trim()}
            saveContent={(value) => (onSave ? onSave({ ...currentValues, linkText: value }) : null)}
        />
    ) : (
        <PDFEdit onCancel={onCancel} onSave={onSave} />
    );
};
