import React from 'react';

import styled from 'styled-components';

import SkillBuilderIcon from '../icons/BuilderLoadingIcon';

/**
 * These rates needs to be constant or the components cannot be deterministically created, which causes
 * issues with server vs client side styled components having same ids
 */
const APP_ICON_ANIMATION_RATE = 0.5 * 2.5 + 2.5;
const APP_ICON_TRANSITION_RATE = 0.75 * 2 + 1;
const CONTAINER_ANIMATION_RATE = 0.6 * 6 + 4;
const CONTAINER_ROTATION_RATE_25 = 0.63 * 20 + 5;
const CONTAINER_ROTATION_RATE_75 = 0.34 * 20 + 5;

const AppIcon = styled(SkillBuilderIcon)`
    color: ${(props) => props.theme.accentColor2};
    animation: ${APP_ICON_ANIMATION_RATE}s heartbeat infinite;
    transition: all ${APP_ICON_TRANSITION_RATE}s;
    height: 180px;
    width: 180px;
    margin: 20px;

    @keyframes heartbeat {
        50% {
            height: 220px;
            width: 220px;
            margin: 0;
        }
    }
`;

const Container = styled.div`
    height: 220px;
    width: 220px;
    animation: ${CONTAINER_ANIMATION_RATE}s rotation infinite ease-in-out;

    @keyframes rotation {
        25% {
            transform: rotate(${CONTAINER_ROTATION_RATE_25}deg);
        }

        75% {
            transform: rotate(-${CONTAINER_ROTATION_RATE_75}deg);
        }
    }
`;

const Loading = () => (
    <Container>
        <AppIcon />
    </Container>
);

export default Loading;
