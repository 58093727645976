import React from 'react';

import { ImageComponentValues } from '@adept-at/lib-react-components';

import { useComponentEngine } from '../../EngineContext';
import { EditableComponentProvider } from '../../mixins/editor';
import { ComponentEngineMode } from '../../types';

import ImageEdit from './Edit';
import ImageView from './View';

interface ImageComponentProps {
    id: string;
    order: number;
}

export const emptyImageComponentValues: ImageComponentValues = {
    image: null,
    caption: null,
    imageUrl: null,
    profile: null,
    photographer: null
};

export const Image: React.FC<ImageComponentProps & ImageComponentValues> = ({ id, order, ...componentValues }) => {
    const { mode } = useComponentEngine();

    if (mode === ComponentEngineMode.Edit) {
        return (
            <EditableComponentProvider initialValues={componentValues}>
                <ImageEdit />
            </EditableComponentProvider>
        );
    }

    return <ImageView {...componentValues} />;
};

export const title = 'Image';

export const Component = Image;
