import React, { useContext, useMemo } from 'react';

import { DotList } from '@adept-at/lib-react-components';
import { Avatar } from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';
import { PRESENTER_COLORS_BY_SPOT, PublisherContext } from 'components/rooms/PublisherContext';
import useUserDetails from 'hooks/useUserDetails';
import pluralize from 'pluralize';
import styled from 'styled-components';
import { formatTime } from 'utils/time';

import { modularScale } from '../../utils/modular_scale';
import { SkillSection } from '../../utils/skill/sections';

import { OutlineProgress, ProgressContainer } from './OutlineProgress';
import { SectionDescription } from './SectionDescription';

const Container = styled(ProgressContainer)<{ $isActive?: boolean }>`
    opacity: ${(props) => (props.$isActive ? 1 : 0.75)};
    cursor: pointer;

    &:hover {
        opacity: 1;
    }

    & h4 {
        color: ${(props) => (props.$isActive ? props.theme.colors.primary : props.theme.colors.textSecondary)};
    }
`;

const Details = styled.div`
    padding: 1rem 0 1rem 1rem;
    white-space: nowrap;
    max-width: 400px;
`;

const SectionTitle = styled.h4`
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: bold;
    margin-bottom: 4px;
`;

const Stats = styled.div`
    font-size: ${modularScale(-0.5)};
    color: ${(props) => props.theme.colors.textTertiary};
    display: flex;
    align-items: center;
`;

const StyledAvatarGroup = styled(AvatarGroup)`
    margin-left: auto;
`;

const StyledAvatar = styled(Avatar)<{ $borderColor?: string }>`
    border: 2px solid ${(props) => props.$borderColor ?? 'inherit'};
`;

interface SectionProps {
    section: SkillSection;
    presenters?: string[];
    isActive: boolean;
    isFirst: boolean;
    isLast: boolean;
}

const buildStats = (section: SkillSection) => {
    const stats = ['videos', 'labs', 'questions'];

    const statsStrings = [formatTime(section.duration, 'medium')];
    for (const stat of stats) {
        if (section[stat]) {
            statsStrings.push(pluralize(stat, section[stat], true));
        }
    }

    return statsStrings;
};

const SkillOutlineSection: React.FC<SectionProps> = ({ section, isFirst, isLast, presenters, isActive }) => {
    const realTimePresenters = useContext(PublisherContext)?.realTimePresenters;

    const presenterIds = useMemo(() => (presenters ? presenters : []), [presenters]);
    const { usersDetails: usersDetails } = useUserDetails({ userIds: presenterIds });

    const renderPresenterAvatars = useMemo(() => {
        if (!presenterIds || !realTimePresenters) return null;

        const memberAvatars = presenterIds.map((presenterId) => {
            const details = Object.values(usersDetails).find((detail) => detail.id === presenterId);
            const presenterSpot = realTimePresenters.get(presenterId)?.spot;

            return (
                <StyledAvatar
                    key={presenterId}
                    alt={details?.alias}
                    src={details?.imageUrl ?? undefined}
                    $borderColor={
                        presenterSpot || presenterSpot === 0 ? PRESENTER_COLORS_BY_SPOT[presenterSpot] : undefined
                    }
                >
                    {details?.alias?.charAt(0)}
                </StyledAvatar>
            );
        });

        return <StyledAvatarGroup max={6}>{memberAvatars}</StyledAvatarGroup>;
    }, [presenterIds, realTimePresenters, usersDetails]);

    return (
        <a href={`#component-${section.id}`}>
            <Container $isActive={isActive}>
                <OutlineProgress isFirst={isFirst} isLast={isLast} status={section.status} />
                <Details>
                    <SectionTitle>{section.title}</SectionTitle>
                    <Stats>
                        <DotList>{buildStats(section)}</DotList>
                    </Stats>
                    <SectionDescription section={section} />
                </Details>
                {renderPresenterAvatars}
            </Container>
        </a>
    );
};

export default SkillOutlineSection;
