import React, { useEffect, useState } from 'react';

import { Table, TableBody } from '@material-ui/core';
import { getUnixTime } from 'date-fns';
import jwtDecode from 'jwt-decode';

import { StyledTableRow, StyledTableCell } from './Details.styles';

interface TokenDetailsProps {
    token: any;
}

interface JwtDetails {
    sub: string;
    iat: number;
    exp: number;
    factors: string[];
}

const getTimeUntilExpiry = (exp) => exp - getUnixTime(new Date());

export const TokenDetails: React.FC<TokenDetailsProps> = ({ token }) => {
    const { exp, sub, iat, factors } = jwtDecode<JwtDetails>(token);

    const [timeUntilExpiry, setTimeUntilExpiry] = useState<number>(getTimeUntilExpiry(exp));

    useEffect(() => {
        const interval = setInterval(() => {
            const newValue = getTimeUntilExpiry(exp);

            if (newValue < 0) {
                setTimeUntilExpiry(-1);
                clearInterval(interval);
                return;
            }

            setTimeUntilExpiry(newValue);
        }, 1000);

        return () => clearInterval(interval);
    }, [setTimeUntilExpiry, exp]);

    const details = [
        ['Sub', sub],
        ['User ID', sub.replace(/adept\|/, '')],
        ['Factors', factors.join(', ')],
        ['Issued At', new Date(iat * 1000).toISOString()],
        ['Expires At', new Date(exp * 1000).toISOString()],
        ['Expires In', timeUntilExpiry < 0 ? 'Expired' : `${timeUntilExpiry} seconds`]
    ];

    return (
        <>
            <Table>
                <TableBody>
                    {details.map(([name, value], idx) => (
                        <StyledTableRow key={idx}>
                            <StyledTableCell component="th" scope="row">
                                {name}
                            </StyledTableCell>
                            <StyledTableCell align="left">{value}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
};
