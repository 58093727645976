import React, { useEffect } from 'react';

import { FormControlLabel, Collapse, Switch } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Loading from 'components/Loading';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { modularScale } from 'polished';
import styled from 'styled-components';
import { isTrue } from 'utils/isTrue';

import { InfoTooltip } from '../InfoTooltip';
import { PriceField } from '../PriceField';

import { EnrollmentCollectionDetails } from './AvailabilityModal';
import { useCanAttachProduct } from './useCanAttachProduct';

const EnrollmentSwitchWrapper = styled.div`
    margin: 1.5rem 0;
`;

const PriceWrapper = styled.div`
    margin-bottom: 1.5rem;
`;

const SwitchLabel = styled.span<{ $selected?: boolean }>`
    color: ${(props) => (props.$selected ? props.theme.colors.primary : 'inherit')};
    font-weight: bold;
    font-size: ${modularScale(-0.5)};
    display: flex;
    align-items: center;
`;

const StyledAlert = styled(Alert)`
    color: ${(props) => (props.severity === 'error' ? props.theme.colors.error : props.theme.colors.primary)};
`;

const MINIMUM_PRICE = 500;
export const INITIAL_PRICE = 500;

const ENROLLMENT_INFO =
    'Enrollments allow you to monetize your content and your customers to schedule and commit to consuming it.';
const ENROLLMENT_ALERT =
    //the permanence is temporary until a clear path for retiring active enrollments, ensuring visibility for users currently enrolled and properly removed collection restriction on enrollment toggle
    'If this collection contains any paid skills, the price above will be applied to enrollments. Once turned on it CANNOT be turned off.';
const NO_ENROLLMENT_ALERT =
    'Without enrollment, learners will have to subscribe to your catalog to get access to paid skills through this collection.';

export const EnrollmentSection: React.FC<{
    price: number;
    enrollment: boolean;
    setPrice: React.Dispatch<React.SetStateAction<number>>;
    setEnrollment: React.Dispatch<React.SetStateAction<boolean>>;
    collectionDetails: EnrollmentCollectionDetails;
    supportingDetailsLoading: boolean;
}> = ({ price, enrollment, setEnrollment, setPrice, collectionDetails, supportingDetailsLoading }) => {
    const { subscriptions: subscriptionsLDFlag } = useFlags();

    useEffect(() => {
        if (collectionDetails.productDetails.prices.length === 0) return;
        const newPrice = collectionDetails.productDetails.prices.find(
            ({ pricePerUnitCents }) => pricePerUnitCents > 0
        )?.pricePerUnitCents;

        setPrice(newPrice ?? INITIAL_PRICE);
        setEnrollment(isTrue(newPrice));
    }, [setPrice, collectionDetails.productDetails, setEnrollment]);

    const { canAttach, isLoading, reason } = useCanAttachProduct(collectionDetails);

    if ((isLoading && !enrollment) || supportingDetailsLoading) {
        return <Loading />;
    }
    //Todo break this component out one more layer and have a story for "EnrollmentSettings"
    return (
        <>
            <EnrollmentSwitchWrapper>
                <FormControlLabel
                    checked={enrollment}
                    //disabling once enabled until a clear path for retiring active enrollments is in place
                    disabled={enrollment || !canAttach || !subscriptionsLDFlag}
                    onChange={() => setEnrollment((prev) => !prev)}
                    control={<Switch color="primary" />}
                    labelPlacement="end"
                    label={
                        <SwitchLabel $selected={enrollment}>
                            {`Enrollment: ${enrollment ? 'On' : 'Off'}`}
                            <InfoTooltip title={ENROLLMENT_INFO} />
                        </SwitchLabel>
                    }
                />
            </EnrollmentSwitchWrapper>
            <Collapse in={enrollment}>
                <PriceWrapper>
                    <PriceField
                        label="Price per week in USD ($5 minimum)"
                        initialValue={price}
                        enabled={!collectionDetails?.productDetails?.productId}
                        minimumValue={MINIMUM_PRICE}
                        onValueUpdated={(value) => {
                            setPrice(value);
                        }}
                    />
                </PriceWrapper>
            </Collapse>

            {!canAttach ? (
                <StyledAlert variant="outlined" severity="error">
                    {reason}
                </StyledAlert>
            ) : (
                <StyledAlert variant="outlined" severity="info">
                    {enrollment ? ENROLLMENT_ALERT : NO_ENROLLMENT_ALERT}
                </StyledAlert>
            )}
        </>
    );
};
