import React, { createContext, useState } from 'react';

interface ScrollContextInterface {
    scrollRef: React.RefObject<HTMLElement> | null;
    setScrollRef: (scrollRef: React.RefObject<HTMLElement>) => void;
}

const ScrollContext = createContext(undefined as unknown as ScrollContextInterface);

const { Provider, Consumer } = ScrollContext;

const ScrollProvider: React.FC = ({ children }) => {
    const [scrollRef, setScrollRef] = useState<React.RefObject<HTMLElement> | null>(null);

    return (
        <Provider
            value={{
                scrollRef,
                setScrollRef
            }}
        >
            {children}
        </Provider>
    );
};

export { ScrollContext, ScrollProvider, Consumer as ScrollConsumer };
