import React, { useState, ReactElement, useMemo, useCallback } from 'react';

import { EntityType } from 'components/ContentContext/Enums';
import { OwnerInfo } from 'components/ContentContext/Interfaces';
import { TransferManyEntityOwnershipProps } from 'components/modals/Sharing/SharingModal/hooks/useTransferManyEntityOwnership';
import { HeadCell } from 'components/SortableTable/EnhancedTableHead';
import { Order, useSortableTable } from 'components/SortableTable/useSortableTable';
import { QueryKey } from 'react-query';
import AutoSizer from 'react-virtualized-auto-sizer';
import styled from 'styled-components';
import { forceShowScrollbarsCSSFragment } from 'styles/Shared.styles';
import { APP_HEADER_HEIGHT } from 'styles/variables';

import { CollaborationTableLoading } from './CollaborationTableLoading';
import NoResults from './NoResults';
import SelectedToolbar from './SelectedToolbar';
import { StickyList } from './StickyList';
import { ROW_HEIGHT } from './TableCells/TableCell.styles';
import { TableHead } from './TableHead';
import { CollaborationRowOnClick } from './TableRow';

interface CollaborationTableProps<T> {
    items: T[];
    headCellDetails: HeadCell<T>[];
    initialSortKey: keyof T;
    initialSortOrder?: Order;
    //TODO extract into <T> typed context for this table
    //so we dont have to passthrough so many props to the sharing modal
    contentQueryKey?: QueryKey;
    contentTransferOptimistCallback?: <K>(previous: K, transferQueryProps: TransferManyEntityOwnershipProps) => K;
    multiSelect?: boolean;
    ActionComponent?: React.ComponentType<{ item: T }>;
    headerHeight?: number;
    fetchingMore?: boolean;
    initialBatchLoading: boolean;
}

export interface CollaborationTableItem {
    id: string;
    onClick: CollaborationRowOnClick;
    owner?: OwnerInfo;
    type?: EntityType;
    slug?: string;
    title: string;
}

const HEADER_PADDING = 32 + 16;
const BORDERS = 2;
const TableWrapper = styled.div<{ headerHeight: number }>`
    > div > div {
        ${forceShowScrollbarsCSSFragment}
    }
    height: calc(100vh - ${(props) => APP_HEADER_HEIGHT + HEADER_PADDING + props.headerHeight + BORDERS}px);
    width: 100%;
`;

const LOADING_ROWS = 5;

const CollaborationTable = <T extends CollaborationTableItem>({
    initialBatchLoading,
    items,
    headCellDetails,
    initialSortKey,
    multiSelect = false,
    ActionComponent,
    fetchingMore = false,
    headerHeight = 0,
    initialSortOrder = 'desc',
    ...rest
}: CollaborationTableProps<T>): ReactElement => {
    const [selected, setSelected] = useState(new Set<string>());

    const { order, orderBy, handleRequestSort, sortData } = useSortableTable<T>(
        initialSortKey,
        initialSortOrder,
        0,
        items.length
    );

    const sortedItems = useMemo(() => sortData(items), [sortData, items]);

    const handleSelectAllClick = useCallback(() => {
        setSelected((prev) => {
            if (prev.size > 0) {
                return new Set([]);
            }

            return new Set(items.map((item) => item.id));
        });
    }, [setSelected, items]);

    if (initialBatchLoading) {
        return (
            <CollaborationTableLoading
                checkboxes={multiSelect}
                headCellDetails={headCellDetails}
                hasActionComponent={Boolean(ActionComponent)}
            />
        );
    }

    return (
        <TableWrapper headerHeight={headerHeight}>
            <AutoSizer>
                {({ height, width }) => (
                    <StickyList<T>
                        height={height}
                        width={width}
                        // add a row for the header and some if fetching more content
                        itemCount={items.length + 1 + (fetchingMore ? LOADING_ROWS : 0)}
                        innerElementType={items.length > 0 || fetchingMore ? TableHead : null}
                        itemSize={ROW_HEIGHT}
                        headCellDetails={headCellDetails}
                        order={order}
                        orderBy={orderBy}
                        handleRequestSort={handleRequestSort}
                        onSelectAllClick={multiSelect ? handleSelectAllClick : undefined}
                        isLoading={fetchingMore}
                        items={sortedItems}
                        multiSelect={multiSelect}
                        selected={selected}
                        setSelected={setSelected}
                        ActionComponent={ActionComponent}
                    />
                )}
            </AutoSizer>
            {items.length === 0 && !fetchingMore ? <NoResults /> : null}
            {multiSelect ? (
                <SelectedToolbar selected={selected} setSelected={setSelected} filteredContent={items} {...rest} />
            ) : null}
        </TableWrapper>
    );
};

export default CollaborationTable;
export { default as ActionBar } from './ActionBar';
export * from './ContextMenu';
export { default as CustomizeModal } from './CustomizeModal';
export { default as NoResults } from './NoResults';
export { default as RestoreButton } from './RestoreButton';
export { default as SearchBar } from '../SearchBar';
export { getCellComponent } from './TableCells';
export * from './TableRow';
export * from './TableTitle';
export * from './utils';
