import React from 'react';

import { Container, Button } from '@material-ui/core';
import styled from 'styled-components';

const ErrorContainer = styled(Container)`
    margin-top: 5rem;
`;

const ButtonContainer = styled(Container)`
    margin-top: 2rem;
`;

export const AuthFatalError: React.FC = () => (
    <ErrorContainer>
        <h1>There was an error</h1>
        <p>If this problem continues, please attempt to logout and log back in.</p>
        <ButtonContainer>
            <Button
                onClick={() => {
                    window.location.replace('/logout');
                }}
                variant="contained"
                color="primary"
            >
                Logout
            </Button>
        </ButtonContainer>
    </ErrorContainer>
);
