import { OwnerInfo } from 'components/ContentContext/Interfaces';
import { MarketplaceListingStatus } from 'components/marketplace/hooks/useUpsertListingStatus';
import { ClientError } from 'graphql-request';
import gql from 'graphql-tag';
import { useBuildAuthenticatedQueryFn } from 'hooks/useGqlClient/useBuildAuthenticatedQueryFn';
import { Api } from 'lib/ApiConstants';
import { ADEPT_USE_PRIMARY_HEADER } from 'lib/constants';
import { QueryKey, useQuery, UseQueryResult } from 'react-query';

import { collectionFragment } from '../fragments';

import { getTenantProfileForOwnerQueryName, RootCollectionResponse } from './useGetTenantProfileForOwner';

export const buildGetInternalProfileForOwnerQueryKey = (ownerId: string): QueryKey => [
    getTenantProfileForOwnerQueryName,
    `internal-${ownerId}`
];

export interface InternalTenantProfileResponse {
    tenantProfileId: string;
    tenantSlug: string | null;
    privateRootCollection: RootCollectionResponse;
    marketplaceStatus?: MarketplaceListingStatus;
}

export interface GetInternalTenantProfileForOwnerQueryResponse {
    getTenantProfileForOwner: InternalTenantProfileResponse;
}

export const getInternalTenantProfileForOwnerQuery = gql`
    query getTenantProfileForOwner($owner: OwnerInfo!) {
        getTenantProfileForOwner(owner: $owner) {
            tenantProfileId
            tenantSlug
            privateRootCollection {
                ${collectionFragment}
            }
            marketplaceStatus
        }
    }
`;

export const useGetInternalTenantProfileForOwner = (
    variables: { owner: OwnerInfo },
    queryKey: QueryKey,
    usePrimary: boolean,
    enabled: boolean
): UseQueryResult<GetInternalTenantProfileForOwnerQueryResponse, ClientError> => {
    const { queryFn } = useBuildAuthenticatedQueryFn<
        GetInternalTenantProfileForOwnerQueryResponse,
        { owner: OwnerInfo }
    >(getInternalTenantProfileForOwnerQuery, variables, {
        api: Api.Content,
        headers: { [ADEPT_USE_PRIMARY_HEADER]: usePrimary }
    });

    return useQuery<GetInternalTenantProfileForOwnerQueryResponse, ClientError>(queryKey, {
        queryFn,
        enabled
    });
};
