import React, { createContext, useContext, useState } from 'react';

import { EntityInfo } from 'components/ContentContext/Interfaces';
import { useModal } from 'hooks/useModal';

import { EntityType } from '../../ContentContext/Enums';
import { MeetingSession } from '../../rooms/hooks/getSessionsForChannel';
import { ScheduleItemEntity } from '../hooks/useGetSchedule';
import LearnLaterModal from '../LearnLaterModal';

export interface ScheduleProviderInterface {
    itemToSchedule?: EntityInfo;
    openLearnLaterModal: (item: EntityInfo) => void;
}

export interface GroupedItems {
    [ScheduleTimeBucket.TODAY]: (ScheduleItemEntity | MeetingSession)[];
    [ScheduleTimeBucket.TOMORROW]: (ScheduleItemEntity | MeetingSession)[];
    [ScheduleTimeBucket.THIS_WEEK]: (ScheduleItemEntity | MeetingSession)[];
    [ScheduleTimeBucket.THIS_MONTH]: (ScheduleItemEntity | MeetingSession)[];
    [ScheduleTimeBucket.LATER]: (ScheduleItemEntity | MeetingSession)[];
}

const ScheduleContext = createContext(undefined as unknown as ScheduleProviderInterface);

const { Provider, Consumer } = ScheduleContext;

export interface NormalizedScheduleItem {
    id: string;
    type: EntityType;
    date: string;
    bucket: ScheduleTimeBucket;
    title: string;
    url?: string;
    details: ScheduleItemEntity | MeetingSession;
    progressUpdatedAt?: string;
}

export enum ScheduleTimeBucket {
    TODAY,
    TOMORROW,
    THIS_WEEK,
    THIS_MONTH,
    LATER
}

export enum ScheduleTimeBucketTitle {
    'Today',
    'Tomorrow',
    'This week',
    'This month',
    'Later'
}

const ScheduleProvider: React.FC = ({ children }) => {
    const { modalOpen, handleModalOpen, handleModalClose } = useModal();

    const [itemToSchedule, setItemToSchedule] = useState<EntityInfo>();

    const openLearnLaterModal = (item: EntityInfo) => {
        setItemToSchedule(item);
        handleModalOpen();
    };

    return (
        <Provider
            value={{
                itemToSchedule,
                openLearnLaterModal
            }}
        >
            {children}
            <LearnLaterModal open={modalOpen} onClose={handleModalClose} />
        </Provider>
    );
};

export { ScheduleContext, ScheduleProvider, Consumer as ScheduleConsumer };

export const useSchedule = (): ScheduleProviderInterface => {
    const context = useContext(ScheduleContext);

    if (!context) {
        throw new Error('useSchedule must be used within a ScheduleProvider');
    }

    return context;
};
