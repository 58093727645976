import { RequestDocument } from 'graphql-request/dist/types';
import { Api } from 'lib/ApiConstants';
import { QueryFunctionContext } from 'react-query';

import { useBuildAuthenticatedPromisfiedFetch } from './useBuildAuthenticatedPromisfiedFetch';

interface UseBuildAuthenticatedQueryFnInterface<TQueryResponse> {
    queryFn: (props: QueryFunctionContext) => Promise<TQueryResponse>;
}

interface UseBuildAuthenticatedQueryFnOptions {
    api?: Api;
}

export const useBuildAuthenticatedInfiniteQueryFn = <TQueryResponse, TVariables = Record<string, unknown>>(
    queryDocument: RequestDocument,
    variables: TVariables = {} as TVariables,
    pageParamKey: string,
    { api = Api.Accounts }: UseBuildAuthenticatedQueryFnOptions
): UseBuildAuthenticatedQueryFnInterface<TQueryResponse> => {
    const { fetch } = useBuildAuthenticatedPromisfiedFetch<TQueryResponse, TVariables>(queryDocument, api);

    const infiniteQueryFn = ({ pageParam }: QueryFunctionContext) => fetch({ ...variables, [pageParamKey]: pageParam });

    return { queryFn: infiniteQueryFn };
};
