import React, { ReactElement } from 'react';

import { TextField, Box } from '@material-ui/core';

const MessageToNewCollaborators = ({
    message,
    setMessage
}: {
    message: string;
    setMessage: React.Dispatch<React.SetStateAction<string>>;
}): ReactElement => {
    return (
        <Box display="flex" alignItems="center" justifyContent="center" marginTop="1rem" height="10rem" overflow="auto">
            <TextField
                label="Message"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                value={message}
                onChange={(event) => setMessage(event.currentTarget.value)}
                inputProps={{ 'aria-label': 'Message' }}
            />
        </Box>
    );
};

export default MessageToNewCollaborators;
