import React, { useContext } from 'react';

import { EditorContext } from 'components/builder/context/EditorContext';
import { CollectionTabsContent } from 'components/NestedCollection/CollectionTabs';
import { NestedCollectionContext } from 'components/NestedCollection/context/NestedCollectionContext';
import { StandaloneOrBuildSidebarContent } from 'components/Sidebar/SidebarContent';
import { LearnEditContext } from 'pages/learn/LearnEditContext';

export const SidebarContent: React.FC = () => {
    const nestedCollectionCtx = useContext(NestedCollectionContext);
    const hasNestedCollectionCtx = !!nestedCollectionCtx?.collectionId;
    const {
        childrenBasicDetails,
        getChildrenBasicDetailsStatus,
        isEditMode: nestedCollectionEditMode,
        tenantSlug,
        collectionSlug,
        collectionId,
        refetchEditableCollection,
        parentQuerySlug,
        collectionIdForEditableCollectionQuery
    } = nestedCollectionCtx ?? {};

    const editorCtx = useContext(EditorContext);
    const hasEditorContext = !!editorCtx?.editMode;
    const learnEditCtx = useContext(LearnEditContext);
    const isEditMode = hasEditorContext && (!learnEditCtx || !!learnEditCtx?.skillEditId);

    return hasNestedCollectionCtx && !isEditMode ? (
        <CollectionTabsContent
            isNestedCollection
            childrenBasicDetails={childrenBasicDetails}
            getChildrenBasicDetailsStatus={getChildrenBasicDetailsStatus}
            isEditMode={nestedCollectionEditMode}
            tenantSlug={tenantSlug}
            collectionSlug={collectionSlug}
            collectionId={collectionId}
            refetchEditableCollection={refetchEditableCollection}
            parentQuerySlug={parentQuerySlug}
            collectionIdForEditableCollectionQuery={collectionIdForEditableCollectionQuery}
        />
    ) : (
        <StandaloneOrBuildSidebarContent />
    );
};
