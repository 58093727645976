import React, { FC, useMemo, useState } from 'react';

import { IconSize, modularScale } from '@adept-at/lib-react-components';
import { Button } from '@material-ui/core';
import { mdiKey } from '@mdi/js';
import MdiIcon from '@mdi/react';
import { EntityType } from 'components/ContentContext/Enums';
import { EntityInfo, OwnerType, OwnerInfo } from 'components/ContentContext/Interfaces';
import { UserListItem } from 'components/Users/UserListItem';
import { QueryKey } from 'react-query';
import styled from 'styled-components';

import { TransferManyEntityOwnershipProps } from '../hooks/useTransferManyEntityOwnership';
import SharingFooter from '../SharedComponents/SharingFooter';
import { StyledAlert } from '../SharingModal';

import { OrganizationOwner } from './OrganizationOwner';
import TransferForm from './TransferForm';

const CurrentOwnerContainer = styled.div`
    width: 100%;
    height: 45%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
`;

const Description = styled.div`
    font-weight: 300;
    font-size: ${modularScale(-3)}
    display: block;
    margin-left: auto;
    margin-right: auto;
`;

export const AvatarContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

interface TransferOwnershipTabProps {
    onTransferOwnership: () => void;
    entities: EntityInfo[];
    owner: OwnerInfo;
    contentQueryKey?: QueryKey;
    contentTransferOptimistCallback?: <T>(previous: T, transferQueryProps: TransferManyEntityOwnershipProps) => T;
}

const TransferOwnershipTab: FC<TransferOwnershipTabProps> = ({ onTransferOwnership, entities, owner, ...rest }) => {
    const [isOpen, setIsOpen] = useState(false);

    const entitiesContainCollections = useMemo(
        () => !!entities.find((entity) => entity.type === EntityType.COLLECTION),
        [entities]
    );

    if (entitiesContainCollections) {
        return <StyledAlert severity="info">Ownership transfer of collections is not supported</StyledAlert>;
    }

    return (
        <>
            {isOpen ? (
                <TransferForm
                    onFinished={onTransferOwnership}
                    entities={entities}
                    {...rest}
                    onBackClick={() => {
                        setIsOpen(false);
                    }}
                />
            ) : (
                <>
                    <CurrentOwnerContainer>
                        <Description>Current Owner</Description>
                        {/* todo, add in organization listitem */}
                        <AvatarContainer>
                            {owner.type === OwnerType.User ? (
                                <UserListItem userId={owner.id} multiLine={true} />
                            ) : (
                                <OrganizationOwner organizationId={owner.id} />
                            )}
                        </AvatarContainer>
                        <Button
                            color="primary"
                            onClick={() => {
                                setIsOpen(true);
                            }}
                            startIcon={<MdiIcon path={mdiKey} size={IconSize.Small} />}
                        >
                            Transfer ownership
                        </Button>
                    </CurrentOwnerContainer>
                    <SharingFooter onFinished={onTransferOwnership} />
                </>
            )}
        </>
    );
};

export default TransferOwnershipTab;
