import React from 'react';

import { makeRange } from '@adept-at/lib-react-components';
import { Menu } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { CourseIconPath } from 'components/icons/CourseIcon';
import styled from 'styled-components';
import { StyledListItem } from 'styles/Shared.styles';

import { SkillIconPath } from '../icons/SkillIcon';
import useCollectionContent from '../stacks/hooks/useCollectionContent';
import { StyledIcon } from '../stacks/Stack.styles';

const StyledMenu = styled(Menu)`
    ul {
        padding: 16px;
        width: 400px;
        max-height: 500px;
    }
`;

interface DynamicNavigatorMenuProps {
    open: boolean;
    anchorEl: HTMLElement | null;
    handlePopoverClose: () => void;
    currentItemId: string;
    onItemSelect: (item: TSkill | TCollection) => void;
    parentId?: string;
}

interface TSkill {
    skillId: string;
    title: string;
    skillSlug: string;
}

interface TCollection {
    collectionId: string;
    title: string;
    collectionSlug: string;
}

const DynamicNavigatorMenu: React.FC<DynamicNavigatorMenuProps> = ({
    open,
    anchorEl,
    handlePopoverClose,
    currentItemId,
    parentId,
    onItemSelect,
    children
}) => {
    const { content, contentLoading } = useCollectionContent(parentId ?? '');

    return (
        <StyledMenu
            open={open}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}
        >
            {children}
            {contentLoading
                ? makeRange(1, 3).map((number) => (
                      <StyledListItem button key={number}>
                          <Skeleton width="100%" />
                      </StyledListItem>
                  ))
                : null}
            {(content ?? []).map((item) => {
                const selected =
                    ('skillId' in item && item.skillId === currentItemId) ||
                    ('collectionId' in item && item.collectionId === currentItemId);

                return (
                    <StyledListItem
                        selected={selected}
                        button
                        key={'skillId' in item ? item.skillId : item.collectionId}
                        onClick={() => {
                            handlePopoverClose();
                            if (!selected) {
                                onItemSelect(item);
                            }
                        }}
                    >
                        <StyledIcon path={'skillId' in item ? SkillIconPath : CourseIconPath} /> {item.title}
                    </StyledListItem>
                );
            })}
        </StyledMenu>
    );
};

export default DynamicNavigatorMenu;
