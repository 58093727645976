import { useCallback, useContext, useEffect } from 'react';

import { CommunicationWebsocketContext } from 'context/CommunicationWebsocketContext';
import MessageFactory from 'lib/communication/message/factory';
import { RealTimeMessageRecord } from 'lib/communication/message/MessageRecord';
import { MessageEventType } from 'lib/communication/message/type';
import UserRegistrationComplete from 'lib/communication/message/type/system/userRegistrationComplete';
import { useQueryClient } from 'react-query';

import debugLog from '../../../utils/debugLog';

const useUserRegistrationComplete = (): void => {
    const { connection } = useContext(CommunicationWebsocketContext);
    const queryClient = useQueryClient();

    const onMessage = useCallback(
        async (data: RealTimeMessageRecord): Promise<void> => {
            const message = (await MessageFactory.construct(data).catch((e) => {
                console.error(e, data);
            })) as UserRegistrationComplete;

            if (message?.type === MessageEventType.UserRegistrationComplete) {
                queryClient.refetchQueries('getChannelsForUser');
            }
        },
        [queryClient]
    );

    useEffect(() => {
        if (connection) {
            const eventListener = ({ data }) => {
                if (data) {
                    debugLog(data);
                    onMessage(data as RealTimeMessageRecord);
                }
            };

            connection.addEventListener('message', eventListener);

            return () => connection.removeEventListener('message', eventListener);
        }
    }, [onMessage, connection]);
};

export default useUserRegistrationComplete;
