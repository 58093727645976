import React, { useMemo } from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { Table, TableBody, TableContainer, TablePagination } from '@material-ui/core';
import { FavoriteItem } from 'components/dash/hooks/useGetFavorites';
import { EnhancedTableHead, HeadCell } from 'components/SortableTable/EnhancedTableHead';
import { useSortableTable } from 'components/SortableTable/useSortableTable';
import styled from 'styled-components';

import { FavoritesTableContent } from './FavoritesTableContent';

const StyledTableContainer = styled(TableContainer)`
    padding: 1rem;

    & th {
        white-space: nowrap;
        font-size: ${(props) => props.theme.fonts.textSizeStandard};
    }

    & tr,
    p {
        font-size: ${(props) => props.theme.fonts.textSizeStandard};
    }

    & th:last-of-type {
        span {
            display: none;
        }
    }

    // size the sort arrow icon
    & svg {
        height: ${IconSize.Small};
        width: ${IconSize.Small};
    }
`;

const PaginationContainer = styled.div`
    padding-right: 3rem;
`;

export interface FavoritesTableHeadCell {
    title: string;
    favoritedAt: string;
    extraActionsMenu: null;
}

export const HEAD_CELL_DETAILS: HeadCell<FavoritesTableHeadCell>[] = [
    { id: 'title', label: 'Title' },
    { id: 'favoritedAt', label: 'Date added' },
    { id: 'extraActionsMenu', label: 'Extra Actions' }
];

const INITIAL_SORT_KEY = 'title';
const INITIAL_ORDER = 'asc';
const INITIAL_PAGE = 0;
const INITIAL_ROWS_PER_PAGE = 25;
const TABLE_ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 100];

export const FavoritesTable: React.FC<{ favorites: FavoriteItem[]; favoritesLoading: boolean }> = ({
    favorites,
    favoritesLoading
}) => {
    const {
        order,
        orderBy,
        handleRequestSort,
        sortData,
        rowsPerPage,
        page,
        handleChangePage,
        handleChangeRowsPerPage
    } = useSortableTable<FavoritesTableHeadCell>(INITIAL_SORT_KEY, INITIAL_ORDER, INITIAL_PAGE, INITIAL_ROWS_PER_PAGE);

    const sortedData = useMemo(() => sortData(favorites), [sortData, favorites]);

    return (
        <StyledTableContainer>
            <Table aria-label="Favorites list view">
                <EnhancedTableHead<FavoritesTableHeadCell>
                    headCellDetails={HEAD_CELL_DETAILS}
                    order={order}
                    orderBy={orderBy}
                    handleRequestSort={handleRequestSort}
                />
                <TableBody>
                    <FavoritesTableContent favorites={sortedData} favoritesLoading={favoritesLoading} />
                </TableBody>
            </Table>
            <PaginationContainer>
                <TablePagination
                    rowsPerPageOptions={TABLE_ROWS_PER_PAGE_OPTIONS}
                    component="div"
                    count={favorites?.length ?? 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' }
                    }}
                />
            </PaginationContainer>
        </StyledTableContainer>
    );
};
