import React, { useContext } from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { Button, Fade, MenuItem } from '@material-ui/core';
import { mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import { CourseIconPath } from 'components/icons/CourseIcon';
import { CardPositioningStyles } from 'components/NestedCollection/ContentSection/ContentSectionCard';
import { useModal } from 'hooks/useModal';
import { useOrganizationTerminology } from 'hooks/useOrganizationTerminology';
import { usePopover } from 'hooks/usePopover';
import pluralize from 'pluralize';
import styled from 'styled-components';
import { StyledMenu } from 'styles/Shared.styles';

import AddExistingCollectionModal from './AddExistingCollectionModal';
import { RootCollectionContext } from './context/RootCollectionContext';
import NewCollectionModal from './NewCollectionModal';
import useUpsertCollectionItem from './useUpsertCollectionItem';

const StyledAddContentButton = styled(Button)`
    width: 100%;
`;

const StyledMenuItem = styled(MenuItem)`
    padding: 0.5rem 1.5rem;
    color: ${(props) => props.theme.colors.primary};

    svg {
        margin-right: 1rem;
        color: ${(props) => props.theme.colors.primary};
    }
`;

const Container = styled.div`
    ${CardPositioningStyles}
`;

const AddNestedCollections: React.FC<{ parentIsRootCollection?: boolean }> = () => {
    const {
        existingCollectionsOptions,
        collectionId,
        refetchEditableCollection,
        getExistingCollectionsIsLoading,
        parentQuerySlug,
        collectionIdForEditableCollectionQuery
    } = useContext(RootCollectionContext);

    const { mutate: addExistingCollection } = useUpsertCollectionItem(
        collectionId,
        refetchEditableCollection,
        collectionIdForEditableCollectionQuery,
        parentQuerySlug
    );

    const { terminology } = useOrganizationTerminology();
    const { open, anchorEl, handlePopoverClick, handlePopoverClose } = usePopover();
    const {
        modalOpen: addNewModalOpen,
        handleModalClose: handleCloseAddNewModal,
        handleModalOpen: handleAddNewModalOpen
    } = useModal();
    const {
        modalOpen: addExistingModalOpen,
        handleModalClose: handleCloseAddExistingModal,
        handleModalOpen: handleAddExistingModalOpen
    } = useModal();

    const handleCreateNewClick = () => {
        handleAddNewModalOpen();
    };

    const handleAddExistingClick = () => {
        handleAddExistingModalOpen();
    };

    const handleAddNewClose = () => {
        handlePopoverClose();
        handleCloseAddNewModal();
    };

    const handleAddExistingClose = () => {
        handlePopoverClose();
        handleCloseAddExistingModal();
    };

    return (
        <Container>
            <StyledAddContentButton
                onClick={handlePopoverClick}
                variant={'outlined'}
                color={'primary'}
                startIcon={<Icon path={mdiPlus} size={IconSize.Small} />}
            >
                Add {pluralize(terminology.collection)}
            </StyledAddContentButton>
            <StyledMenu
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handlePopoverClose}
                TransitionComponent={Fade}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <StyledMenuItem onClick={handleCreateNewClick}>
                    <Icon path={mdiPlus} size={IconSize.Medium} /> Create new {terminology.collection}
                </StyledMenuItem>
                <StyledMenuItem onClick={handleAddExistingClick}>
                    <Icon path={CourseIconPath} size={IconSize.Medium} /> Add existing {terminology.collection}
                </StyledMenuItem>
            </StyledMenu>
            <NewCollectionModal
                modalOpen={addNewModalOpen}
                handleModalClose={handleAddNewClose}
                collectionIdForEditableCollectionQuery={collectionIdForEditableCollectionQuery}
                editableCollectionId={collectionId}
                parentQuerySlug={parentQuerySlug}
            />
            <AddExistingCollectionModal
                modalOpen={addExistingModalOpen}
                handleModalClose={handleAddExistingClose}
                options={existingCollectionsOptions}
                parentCollectionId={collectionId}
                mutateFn={addExistingCollection}
                isLoading={getExistingCollectionsIsLoading}
            />
        </Container>
    );
};

export default AddNestedCollections;
