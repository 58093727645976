import React, { createContext, useCallback, useState } from 'react';

import useBulkFetcherQueue from 'hooks/useBulkFetcherQueue';
import useGqlClient, { GqlClientStatus } from 'hooks/useGqlClient';
import { API_COMMUNICATION } from 'lib/ApiConstants';
import ms from 'ms';

import getCommunicationUsersDetails, {
    CommunicationUserDetails,
    GetCommunicationUsersDetailsResponse
} from './getCommunicationUserDetails';

export interface CommunicationUsersDetailsContextOutput {
    /** Get a group of results. */
    getCommUsersDetailsFromMap: (userIds: string[]) => Record<string, CommunicationUserDetails | undefined>;
    /** Fetch a group of users Communcation User Details. */
    fetchCommUsersDetails: (userIds: string[]) => void;
    isLoading?: boolean;
}

const CommunicationUsersDetailsContext = createContext<CommunicationUsersDetailsContextOutput>(
    undefined as unknown as CommunicationUsersDetailsContextOutput
);

/** How often the presence gets updated. */
const COMMUNICATION_USER_DETAILS_UPDATE_FREQUENCY_IN_MS = ms('20 sec');

// Provider is fully tested by useBulkFetcherQueuer itself
export const CommunicationUserDetailsProvider: React.FC = ({ children }) => {
    const [commUserDetailsByUserId, setCommUserDetailsByUserId] = useState(new Map<string, CommunicationUserDetails>());

    const { client, status } = useGqlClient(API_COMMUNICATION);

    const fetchCommunicationUsersDetails = useCallback(
        async (userIds: string[]): Promise<GetCommunicationUsersDetailsResponse> => {
            return await getCommunicationUsersDetails(client, userIds);
        },
        [client]
    );

    const onSuccess = useCallback(async (result: GetCommunicationUsersDetailsResponse): Promise<void> => {
        const newUsersDetails = result?.getUsersDetails;
        if (newUsersDetails) {
            setCommUserDetailsByUserId(
                (prevCommUserDetailsByUserId) =>
                    new Map<string, CommunicationUserDetails>([
                        ...prevCommUserDetailsByUserId.entries(),
                        ...newUsersDetails.map(
                            (newUserDetails) =>
                                [newUserDetails.userId, newUserDetails] as [string, CommunicationUserDetails]
                        )
                    ])
            );
        }
    }, []);

    const { addToQueue, isLoading } = useBulkFetcherQueue({
        fetcher: fetchCommunicationUsersDetails,
        onSuccess,
        disabled: status !== GqlClientStatus.Ready,
        ttlInMs: COMMUNICATION_USER_DETAILS_UPDATE_FREQUENCY_IN_MS
    });

    /** Get a group of results. */
    const getCommUsersDetailsFromMap = useCallback(
        (userIds: string[]): Record<string, CommunicationUserDetails | undefined> => {
            return Object.fromEntries(userIds.map((userId) => [userId, commUserDetailsByUserId.get(userId)]));
        },
        [commUserDetailsByUserId]
    );

    return (
        <CommunicationUsersDetailsContext.Provider
            value={{ getCommUsersDetailsFromMap, fetchCommUsersDetails: addToQueue, isLoading }}
        >
            {children}
        </CommunicationUsersDetailsContext.Provider>
    );
};

export default CommunicationUsersDetailsContext;
