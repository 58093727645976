import React, { useRef } from 'react';

import { VideoComponentValues, VideoViewer } from '@adept-at/lib-react-components';
import { useComponentEngineComponent } from 'components/engine';
import { useFocusableComponent } from 'components/engine/mixins/focusable';

import { ComponentFocus, EditButtons, useEditableComponent } from '../../../mixins/editor';

import { EditorDetails } from './Details';

export const emptyVideoComponentValues = {
    posterImageUrl: null,
    playbackUrl: undefined,
    videoId: null
};

const EditorMain: React.FC<{ onCancel?: () => void; onSave?: (values: Partial<VideoComponentValues>) => void }> = ({
    onCancel,
    onSave
}) => {
    const { id } = useComponentEngineComponent();
    const {
        onCancel: defaultOnCancel,
        onSave: defaultOnSave,
        currentValues
    } = useEditableComponent<VideoComponentValues>();
    const { doesComponentHaveAnyFocus } = useFocusableComponent();
    const videoRef = useRef<HTMLVideoElement>(null);
    const isEditing = doesComponentHaveAnyFocus(id, [ComponentFocus.Edit, ComponentFocus.AddAndEdit]);

    if (!currentValues.playbackUrl) {
        return null;
    }

    return (
        <>
            <VideoViewer
                videoRef={videoRef}
                componentValues={currentValues}
                preload={currentValues.posterImageUrl ? 'none' : 'metadata'}
                muxDataKey={process.env.REACT_APP_MUX_DATA_ENV_KEY ?? ''}
            />
            {isEditing ? (
                <>
                    <EditorDetails videoRef={videoRef} />
                    <EditButtons
                        onCancel={onCancel ?? defaultOnCancel}
                        onSave={() => {
                            if (onSave) {
                                const { playbackUrl, videoId, posterImageUrl } = currentValues;
                                onSave({ playbackUrl, videoId, posterImageUrl });
                            } else {
                                defaultOnSave(['videoId', 'uploadedFileName']);
                            }
                        }}
                    />
                </>
            ) : null}
        </>
    );
};

export default EditorMain;
