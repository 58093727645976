import React, { useContext, useEffect, useRef, useState } from 'react';

import { ComponentValuesType } from '@adept-at/lib-react-components';
import BuilderSections from 'components/builder/Components/BuilderSections';
import { EditorContext } from 'components/builder/context/EditorContext';
import { Skill } from 'components/ContentContext/Interfaces';
import { useComponentEditor } from 'components/engine/mixins/editor';
import styled from 'styled-components';

import GetTheAppAlert from '../GetTheAppAlert';

import { BuilderCard } from './Builder.styles';
import { SKILL_CONTAINER_ID } from './constants';
import SkillHeader from './Meta/SkillHeader';

const StyledBuilderCard = styled(BuilderCard)`
    background-color: transparent;
    border: 0;

    hr {
        border: 0.5px solid ${(props) => props.theme.colors.darkGrey};
    }
`;

const GetTheAppAlertContainer = styled.div``;

const SkillBuilderDetails: React.FC<{ skill: Skill }> = ({ skill }) => {
    const { onAddComponent, onStartEditingComponent } = useComponentEditor();
    const { workingSkill, setComponentContainerRef } = useContext(EditorContext);
    const [componentId] = useState(Object.keys(workingSkill.components)[0]);
    const skillContainer = useRef(null);

    useEffect(() => onStartEditingComponent(componentId), [componentId, onStartEditingComponent]);

    useEffect(() => {
        if (Object.keys(workingSkill.components).length === 0) {
            onAddComponent(ComponentValuesType.RichText, 100);
        }
    }, [workingSkill.components]);

    setComponentContainerRef(skillContainer.current);

    return (
        <>
            <GetTheAppAlertContainer>
                <GetTheAppAlert showAlert={false} setShowAlert={() => {}} downloadURL={undefined} />
            </GetTheAppAlertContainer>
            <StyledBuilderCard id={SKILL_CONTAINER_ID} ref={skillContainer}>
                <SkillHeader />
                <BuilderSections skill={skill} />
            </StyledBuilderCard>
        </>
    );
};

export default SkillBuilderDetails;
