import React from 'react';

import { Container } from './components/Component.styles';

interface PreviewComponentProps {
    component: any;
    children: React.ReactElement | null;
}

const ComponentContainer = React.forwardRef<HTMLInputElement, PreviewComponentProps>(({ component, children }, ref) => {
    return (
        <Container id={`component-${component.id}`} ref={ref}>
            {children}
        </Container>
    );
});

export default ComponentContainer;
