import React, { useMemo } from 'react';

import { TableCell } from '@material-ui/core';
import useUserDetails from 'hooks/useUserDetails';

interface AuthorCellProps {
    createdBy?: string;
}

export const AuthorCell: React.FC<AuthorCellProps> = ({ createdBy }) => {
    const userIds = useMemo(() => (createdBy ? [createdBy] : []), [createdBy]);
    const { usersDetails } = useUserDetails({ userIds });

    return <TableCell>{createdBy ? usersDetails?.[createdBy]?.alias : null}</TableCell>;
};
