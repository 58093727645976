import React from 'react';

import { defaultMuiTheme } from '@adept-at/lib-react-components';
import { Card } from '@material-ui/core';
import Shimmer from 'components/Shimmer';
import styled from 'styled-components';
import { BREAKPOINT_900PX } from 'styles/variables';

import { CardPositioningStyles } from './ContentSection/ContentSectionCard';

const LoadingCard = styled(Card)`
    ${CardPositioningStyles}
    border: 1px solid ${(props) => props.theme.colors.border};
    box-shadow: none;

    ${defaultMuiTheme.breakpoints.down(BREAKPOINT_900PX)} {
        width: 100%;
        max-width: 700px;
    }
`;

const LoadingShimmer = styled(Shimmer)`
    padding: 1.5rem 2rem 0rem 2rem;
`;

const CollectionContentLoadingCards: React.FC = () => {
    return (
        <>
            <LoadingCard>
                <LoadingShimmer rows={3} size={18} />
            </LoadingCard>
            <LoadingCard>
                <LoadingShimmer rows={3} size={18} />
            </LoadingCard>
            <LoadingCard>
                <LoadingShimmer rows={3} size={18} />
            </LoadingCard>
            <LoadingCard>
                <LoadingShimmer rows={3} size={18} />
            </LoadingCard>
        </>
    );
};

export default CollectionContentLoadingCards;
