import { useMemo } from 'react';

import { OptimistMutationContext, useOptimist } from '@adept-at/lib-react-optimist';
import { EntityInfo } from 'components/ContentContext/Interfaces';
import { typeToIdKey } from 'components/dash/utils';
import { ClientError } from 'graphql-request';
import { useSnackbar } from 'notistack';
import { useMutation, UseMutationResult } from 'react-query';

import useGqlClient from '../../../../hooks/useGqlClient';
import { API_CONTENT } from '../../../../lib/ApiConstants';
import { GetFavoritesResponse, queryName } from '../useGetFavorites';

import makeUpsertFavoriteItem from './makeUpsertFavoriteItem';

export interface UpsertFavoriteItemResult {
    upsertFavoriteItem: {
        success: boolean;
    };
}

export interface UpsertFavoriteItemVariables {
    item: EntityInfo;
}

const useUpsertFavoriteItem = (): UseMutationResult<
    UpsertFavoriteItemResult,
    ClientError,
    UpsertFavoriteItemVariables,
    OptimistMutationContext
> => {
    const { enqueueSnackbar } = useSnackbar();
    const { client, withMutationOptions } = useGqlClient(API_CONTENT);

    const { registerUpdater: registerUpsertFavoriteItemUpdater, mutationOptions: upsertFavoriteItemMutationOptions } =
        useOptimist<UpsertFavoriteItemResult, ClientError, UpsertFavoriteItemVariables>();

    const upsertFavoriteItemOptions = useMemo(() => {
        return {
            onError: () => {
                enqueueSnackbar('An error occurred. Unable to add item to favorites', { variant: 'error' });
            },
            onSuccess: () => {
                enqueueSnackbar('Item successfully added to favorites', { variant: 'success' });
            }
        };
    }, [enqueueSnackbar]);

    registerUpsertFavoriteItemUpdater<GetFavoritesResponse>(
        queryName,
        (previous, newItem) => {
            const idKey = typeToIdKey[newItem.item.type];
            // set type as any - missing other required attributes
            const item: any = { [idKey]: newItem.item.id };
            const updatedItems = (previous?.getUserFavorites?.items ?? []).concat([item]);

            return {
                getUserFavorites: {
                    ...previous?.getUserFavorites,
                    items: updatedItems
                }
            };
        },
        upsertFavoriteItemOptions
    );

    return useMutation<UpsertFavoriteItemResult, ClientError, UpsertFavoriteItemVariables, OptimistMutationContext>(
        makeUpsertFavoriteItem(client),
        withMutationOptions(upsertFavoriteItemMutationOptions)
    );
};

export default useUpsertFavoriteItem;
