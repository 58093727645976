import React from 'react';

import { CollectionImage, makeRange, SkillImage, TooltippedButtonSize } from '@adept-at/lib-react-components';
import { TableCell, TableRow, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { FavoriteItem } from 'components/dash/hooks/useGetFavorites';
import { BasicCollectionInfo, BasicSkillInfoWithProgress } from 'components/dash/interfaces';
import styled from 'styled-components';
import { formatDateStringToLocaleString, MonthFormat } from 'utils/date';

import { FavoriteItemExtraActions } from './FavoriteItemExtraActions';
import { HEAD_CELL_DETAILS } from './FavoritesTable';

const FAV_TABLE_ROW_CATALOG_IMAGE_SIZE = '48px';

const ImageTitleTableCell = styled(TableCell)<{ $isCollection: boolean }>`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    & div:first-of-type {
        border-radius: ${(props) => (props.$isCollection ? '50%' : 'inherit')};
        height: ${FAV_TABLE_ROW_CATALOG_IMAGE_SIZE};
        width: ${FAV_TABLE_ROW_CATALOG_IMAGE_SIZE};
        margin-right: 1rem;

        svg {
            // styles default catalog images to correct size
            height: max-content;
            width: max-content;
        }
    }
`;

export const FavoritesTableContent: React.FC<{ favorites: FavoriteItem[]; favoritesLoading: boolean }> = ({
    favorites,
    favoritesLoading
}) => {
    const userLocal = navigator.languages[0];

    if (favoritesLoading)
        return (
            <>
                {makeRange(1, 10).map((index) => (
                    <TableRow key={index}>
                        {HEAD_CELL_DETAILS.map((_headCell, index) => (
                            <TableCell key={index} align="left">
                                <Skeleton variant="text" />
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </>
        );

    return (
        <>
            {favorites.map((favorite, index) => (
                <TableRow key={index}>
                    <ImageTitleTableCell $isCollection={'collectionId' in favorite}>
                        {'skillId' in favorite ? (
                            <SkillImage.Small image={favorite.images.catalog ?? null} />
                        ) : (
                            <CollectionImage.Small image={favorite.images.catalog ?? null} />
                        )}
                        <Typography>
                            {(favorite as BasicSkillInfoWithProgress).skillId
                                ? (favorite as BasicSkillInfoWithProgress).title
                                : (favorite as BasicCollectionInfo).title}
                        </Typography>
                    </ImageTitleTableCell>
                    <TableCell>
                        <Typography>
                            {formatDateStringToLocaleString(favorite.favoritedAt, userLocal, MonthFormat.TwoDigit)}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <FavoriteItemExtraActions
                            favorite={favorite}
                            isVisible
                            buttonSize={TooltippedButtonSize.Medium}
                        />
                    </TableCell>
                </TableRow>
            ))}
        </>
    );
};
