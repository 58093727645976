// Simple
/* instabul ignore file */

export const isElectronUserAgent = (): boolean => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf(' electron/') > -1) {
        return true;
    }

    return false;
};
