import React from 'react';

import { TooltippedButtonSize, TooltippedIconButton, LinkType } from '@adept-at/lib-react-components';
import { mdiFlask, mdiLink, mdiNotebookCheckOutline } from '@mdi/js';
import Icon from '@mdi/react';
import styled from 'styled-components';

const ButtonsContainer = styled.div`
    display: flex;
    margin: 0 0 2rem 0;
`;

const StyledTooltippedIconButton = styled(TooltippedIconButton)<{ $selected: boolean }>`
    outline: ${({ theme, $selected }) => ($selected ? `1px solid ${theme.colors.primary}` : 'none')};
`;

export const LinkOptions: React.FC<{ selectedOption?: LinkType; selectOption: (option: LinkType) => void }> = ({
    selectedOption = LinkType.TEXT,
    selectOption
}) => {
    return (
        <ButtonsContainer>
            <StyledTooltippedIconButton
                aria-label={`metadata link option`}
                size={TooltippedButtonSize.Medium}
                onClick={() => selectOption(LinkType.META)}
                onMouseDown={(e) => e.preventDefault()}
                icon={<Icon path={mdiLink} />}
                title={'Meta'}
                data-testid={`linkoption-meta`}
                $selected={selectedOption === LinkType.META}
            />
            <StyledTooltippedIconButton
                aria-label={`lab link option`}
                size={TooltippedButtonSize.Medium}
                onClick={() => selectOption(LinkType.LAB)}
                onMouseDown={(e) => e.preventDefault()}
                icon={<Icon path={mdiFlask} />}
                title={'Lab'}
                data-testid={`linkoption-lab`}
                $selected={selectedOption === LinkType.LAB}
            />
            <StyledTooltippedIconButton
                aria-label={`exam link option`}
                size={TooltippedButtonSize.Medium}
                onClick={() => selectOption(LinkType.EXAM)}
                onMouseDown={(e) => e.preventDefault()}
                icon={<Icon path={mdiNotebookCheckOutline} />}
                title={'Exam'}
                data-testid={`linkoption-exam`}
                $selected={selectedOption === LinkType.EXAM}
            />
        </ButtonsContainer>
    );
};
