import React from 'react';

import { Button } from '@material-ui/core';

import { Buttons as ModalButtonsContainer } from './Modal.styles';

enum ModalButtonType {
    Confirm = 'Confirm',
    Cancel = 'Cancel'
}

interface ModalActionButtonsProps {
    onClose: () => void;
    onConfirm?: () => void;
    showActionButtons?: boolean;
    cancelButtonText?: string;
    confirmButtonText?: string;
    confirmButtonDisabled?: boolean;
    CancelButton?: React.FC;
    ConfirmButton?: React.FC;
}

const ModalActionButtons: React.FC<ModalActionButtonsProps> = ({
    showActionButtons,
    CancelButton,
    ConfirmButton,
    onClose,
    onConfirm,
    cancelButtonText = ModalButtonType.Cancel,
    confirmButtonText = ModalButtonType.Confirm,
    confirmButtonDisabled = false
}) => {
    if (showActionButtons) {
        return (
            <ModalButtonsContainer>
                {CancelButton ? (
                    <CancelButton />
                ) : (
                    <Button aria-label="modal cancel button" color="primary" onClick={onClose}>
                        {cancelButtonText}
                    </Button>
                )}
                {ConfirmButton ? (
                    <ConfirmButton />
                ) : (
                    <Button
                        aria-label="modal confirm button"
                        color="primary"
                        variant="contained"
                        onClick={onConfirm}
                        disabled={confirmButtonDisabled}
                        disableElevation
                    >
                        {confirmButtonText}
                    </Button>
                )}
            </ModalButtonsContainer>
        );
    }

    return null;
};

export default ModalActionButtons;
