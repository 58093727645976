import { OwnerInfo } from 'components/ContentContext/Interfaces';
import { ClientError } from 'graphql-request';
import gql from 'graphql-tag';
import { useBuildAuthenticatedQueryFn } from 'hooks/useGqlClient/useBuildAuthenticatedQueryFn';
import { Api } from 'lib/ApiConstants';
import { QueryKey, useQuery, UseQueryResult } from 'react-query';

import { PriceDetails } from './types';

const queryName = 'getProduct';
export const buildGetProductQueryKey = (productId: string): QueryKey => [queryName, productId];

export const GET_PRODUCT_QUERY = gql`
    query ${queryName}($productId: String!) {
        ${queryName}(productId: $productId) {
            productId
            productData
            owner{

                id
                type
            }
            prices {
                priceId
                stripePriceId
                interval
                pricePerUnitCents
            }
        }
    }
`;

export interface GetProductResponse {
    getProduct: { productId: string; productData: { collectionId: string }; owner: OwnerInfo; prices: PriceDetails[] };
}

export interface useGetProductDetailsVariables {
    productId?: string | null;
}

export const useGetProductDetails = (
    variables: useGetProductDetailsVariables
): UseQueryResult<GetProductResponse, ClientError> => {
    const { queryFn } = useBuildAuthenticatedQueryFn<GetProductResponse, useGetProductDetailsVariables>(
        GET_PRODUCT_QUERY,
        variables,
        { api: Api.Payment }
    );

    return useQuery<GetProductResponse, ClientError>(buildGetProductQueryKey(variables.productId ?? 'unknown'), {
        queryFn,
        enabled: !!variables.productId
    });
};
