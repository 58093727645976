import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';

type auto = 'auto';

interface ContentsProps {
    width?: number | auto;
    height?: number | auto;
}

const getWidth = (width: ContentsProps['width']) => {
    if (width === 'auto') {
        return width;
    }

    if (!width) {
        return '300px';
    }

    return `${width}px`;
};

const getHeight = (height: ContentsProps['height']) => {
    if (height === 'auto') {
        return height;
    }

    if (!height) {
        return '500px';
    }

    return `${height}px`;
};

const Contents = styled(Paper)<ContentsProps>`
    position: absolute;
    height: ${(props) => getHeight(props.height)};
    width: ${(props) => getWidth(props.width)};
    z-index: 1050;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    cursor: initial;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 30px;
    border-radius: 4px;
    box-shadow: 0px 0px 20px ${(props) => props.theme.colors.darkDropShadow};
`;

export default Contents;
