import React, { useContext } from 'react';

import { ComponentContext } from '../../../ComponentContext';
import { getTitleForType } from '../../../Components/';

const EditorComponentTitle: React.FC = () => {
    const { type } = useContext(ComponentContext);

    const title = getTitleForType(type);

    return <>{title}</>;
};

export { EditorComponentTitle };
