import { useCallback, useEffect, useMemo, useState } from 'react';

import { OwnerInfo } from 'components/ContentContext/Interfaces';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useSnackbar } from 'notistack';
import { isTrue } from 'utils/isTrue';

import { IntervalType, PurchaseInterval, SagaStatus } from './types';
import { useCreateProduct } from './useCreateProduct';
import { useGetSagaDetails } from './useGetSagaStatus';

const PRODUCT_SAGA_STORAGE = 'product/saga/';

export const useCreateProductSaga = ({
    ownerInfo,
    immutableCollectionId,
    productId,
    productName,
    entitySlug
}: {
    ownerInfo: OwnerInfo | null;
    immutableCollectionId?: string;
    productName: string;
    productId: string | null;
    entitySlug: string | null;
}): { createProduct: (price: number, tenantId: string) => void; productInProgress: boolean } => {
    const { currentUser } = useCurrentUser();
    const [sagaStatus, setSagaStatus] = useState<SagaStatus>();

    const { enqueueSnackbar } = useSnackbar();
    const [sagaId, setSagaId] = useState<string>();
    const { mutate: createProductMutation, isLoading: createProductIsLoading } = useCreateProduct(
        setSagaId,
        setSagaStatus,
        ownerInfo,
        entitySlug,
        immutableCollectionId ?? null
    );

    const { data: sagaData, isLoading: sagaIsLoading } = useGetSagaDetails(
        { executionId: sagaId },
        sagaStatus !== SagaStatus.succeeded && sagaStatus !== SagaStatus.failed
    );

    useEffect(() => {
        if (!isTrue(productName)) return;
        const savedSagaId = localStorage.getItem(`${PRODUCT_SAGA_STORAGE}/${productName}`);
        if (savedSagaId !== null) {
            setSagaId(savedSagaId);
        }
    }, [productName, setSagaId]);

    const createProduct = useCallback(
        async (price: number, tenantId: string) => {
            if (
                productId ||
                sagaId ||
                createProductIsLoading ||
                !tenantId ||
                !ownerInfo ||
                !immutableCollectionId ||
                !currentUser?.userId
            )
                return;

            await createProductMutation(
                {
                    input: {
                        productData: { collectionId: immutableCollectionId },
                        userId: currentUser.userId,
                        productName,
                        ownerInput: ownerInfo,
                        tenantId
                    },
                    priceInput: {
                        pricePerUnitCents: price,
                        interval: PurchaseInterval.week,
                        intervalType: IntervalType.enrollment
                    }
                },
                {
                    onSuccess: (data) => {
                        //TODO- create localstorage polling hook for product,enrollments etc
                        //store the sagaId incase page refresh, navigation etc
                        try {
                            localStorage.setItem(
                                `${PRODUCT_SAGA_STORAGE}/${productName}`,
                                data.createProduct.executionId
                            );
                        } catch (e) {
                            console.error('local storage full, cannot track saga');
                        }
                    }
                }
            );
        },
        [
            createProductMutation,
            productId,
            sagaId,
            createProductIsLoading,
            ownerInfo,
            immutableCollectionId,
            currentUser?.userId,
            productName
        ]
    );

    useEffect(() => {
        if (!sagaData) return;
        setSagaStatus(sagaData.getSaga.status);
    }, [sagaData]);

    useEffect(() => {
        if (sagaStatus === SagaStatus.succeeded) {
            enqueueSnackbar('Successfully created a product and price!', { variant: 'success' });
            //TODO- create localstorage polling hook for product,enrollments etc
            localStorage.removeItem(`${PRODUCT_SAGA_STORAGE}/${productName}`);
        }
        if (sagaStatus === SagaStatus.failed) {
            //TODO- create localstorage polling hook for product,enrollments etc
            enqueueSnackbar('An error occured creating a product and price!', { variant: 'error' });
            localStorage.removeItem(`${PRODUCT_SAGA_STORAGE}/${productName}`);
        }
    }, [sagaStatus, enqueueSnackbar, productName]);

    const productInProgress = useMemo(
        () => sagaIsLoading || createProductIsLoading || sagaStatus === SagaStatus.inProgress,
        [sagaIsLoading, createProductIsLoading, sagaStatus]
    );

    return { createProduct, productInProgress };
};
