import React, { useContext, useMemo } from 'react';

import { EditorContext } from 'components/builder/context/EditorContext';
import SkillDetails from 'components/learn/skills/Skill';
import { SkillContainer } from 'components/SkillLayout.styles';

import { LearnEditor } from '../LearnEditor';

export const Content: React.FC<{ skillId?: string }> = ({ skillId }) => {
    const editorCtx = useContext(EditorContext);
    const editorReady = useMemo(() => !!editorCtx?.workingSkill, [editorCtx?.workingSkill]);

    return editorReady && skillId ? (
        <LearnEditor />
    ) : (
        <SkillContainer>
            <SkillDetails />
        </SkillContainer>
    );
};
