import React from 'react';

import { Skeleton } from '@material-ui/lab';

import { SkeletonContainer, StyledListItem } from './styles';

export const LoadingSkeleton: React.FC<{ items: number }> = ({ items }) => {
    return (
        <SkeletonContainer>
            {[...new Array(items)].map((_, i) => (
                <StyledListItem button key={i}>
                    <Skeleton variant="text" width="60%" />
                    <Skeleton variant="text" width="35%" />
                </StyledListItem>
            ))}
        </SkeletonContainer>
    );
};
