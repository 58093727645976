import React, { useEffect, useState } from 'react';

import Loading from 'components/Loading';
import ReactCrop from 'react-image-crop';
import styled from 'styled-components';

import { useCrop, CropDetails } from './useCrop';
// import 'react-image-crop/dist/ReactCrop.css';
const PreviewImage = styled.img`
    height: 100%;
    width: 100%;
`;

interface CropProps {
    imageSrc: string | null;
    aspectRatio?: number | null;
    circularCrop?: boolean;
    showPreview?: boolean;
    onCropUpdated?: (crop: CropDetails) => void;
}

/**
 * Responsibilities:
 *
 * 1. Display image in current state from provided source
 * 2. Facilitate stage area to choose crop region
 * 3. Apply crop values and report updates via onCropUpdated() callback
 */
export const Crop: React.FC<CropProps> = ({
    imageSrc,
    aspectRatio,
    circularCrop = false,
    showPreview = false,
    onCropUpdated = () => {}
}) => {
    const [loading, setLoading] = useState<boolean>(true);

    const { onImageLoaded, cropForWorkspace, cropForOriginal, croppedImage, onChangeCrop } = useCrop({
        aspectRatio
    });

    useEffect(() => {
        onCropUpdated(cropForOriginal);
    }, [cropForOriginal, onCropUpdated]);

    return (
        <>
            {loading ? <Loading /> : null}
            <ReactCrop
                src={imageSrc}
                crop={cropForWorkspace}
                onImageLoaded={(image: HTMLImageElement) => {
                    setLoading(false);
                    onImageLoaded(image);
                }}
                onChange={onChangeCrop}
                onComplete={(crop: CropDetails) => {
                    onChangeCrop(crop);
                }}
                circularCrop={circularCrop}
            />
            {croppedImage && showPreview ? <PreviewImage src={croppedImage} /> : null}
        </>
    );
};
