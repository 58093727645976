import { useEffect, useMemo } from 'react';

import { CollectionBasicDetail } from 'components/RootProfileCollection/useGetNestedCollections/getCollectionBasicDetails';
import { ClientError } from 'graphql-request';
import { useBuildAuthenticatedQueryFn } from 'hooks/useGqlClient/useBuildAuthenticatedQueryFn';
import { Api } from 'lib/ApiConstants';
import { ADEPT_USE_PRIMARY_HEADER } from 'lib/constants';
import { QueryKey, QueryStatus, useQuery } from 'react-query';

import {
    BasicDetailsSkillResponse,
    GetEntitiesBasicDetailsQueryResponse,
    GetEntitiesBasicInputVariables,
    getEntitiesBasicDetailsQuery
} from './getEntitiesBasicDetails';

interface UseGetEntitiesBasicDetailsOutput {
    status: QueryStatus;
    childrenDetails: (BasicDetailsSkillResponse | CollectionBasicDetail)[];
    refetchEntitiesBasicDetails: () => void;
}

export const useGetEntitiesBasicDetails = (
    queryKey: QueryKey,
    entitiesToFetch: GetEntitiesBasicInputVariables[],
    usePrimary = false
): UseGetEntitiesBasicDetailsOutput => {
    const { queryFn } = useBuildAuthenticatedQueryFn<
        GetEntitiesBasicDetailsQueryResponse,
        { entities: GetEntitiesBasicInputVariables[] }
    >(
        getEntitiesBasicDetailsQuery,
        { entities: entitiesToFetch },
        { api: Api.Content, headers: { [ADEPT_USE_PRIMARY_HEADER]: usePrimary } }
    );

    const {
        data,
        status,
        refetch: refetchEntitiesBasicDetails
    } = useQuery<GetEntitiesBasicDetailsQueryResponse, ClientError>(queryKey, {
        enabled: entitiesToFetch.length > 0,
        queryFn
    });

    const childrenDetails = useMemo(() => data?.getEntityBasicDetails ?? [], [data?.getEntityBasicDetails]);

    useEffect(() => {
        if (entitiesToFetch.length > 0) refetchEntitiesBasicDetails();
    }, [entitiesToFetch, refetchEntitiesBasicDetails]);

    return {
        status,
        childrenDetails,
        refetchEntitiesBasicDetails
    };
};
