import { useContext } from 'react';

import { ProfileContext, ProfileType } from 'context/ProfileContext';
import {
    GetOrganizationsForUserResponse,
    queryKey as getOrganizationsForUserQueryKey
} from 'context/ProfileContext/useAvailableProfiles/useGetOrganizationsForUser';
import { useQueryClient } from 'react-query';

export enum OrganizationRolesTypes {
    SuperAdmin = 'superAdmin',
    LearningAdmin = 'learningAdmin',
    StoreAdmin = 'storeAdmin',
    Member = 'member',
    SuperMember = 'superMember'
}

interface UseOrganizationRolesOutput {
    hasRole: (role: string) => boolean;
    hasAnyRole: (includingPersonalTenant?: boolean) => boolean;
    adminOptionsVisible: () => boolean;
}

const useOrganizationRoles = (): UseOrganizationRolesOutput => {
    const queryClient = useQueryClient();
    const orgsForUser: GetOrganizationsForUserResponse | undefined = queryClient.getQueryData(
        getOrganizationsForUserQueryKey
    );

    const { currentProfile } = useContext(ProfileContext);

    const { id: organizationId } = currentProfile ?? {};

    const hasRole = (role) => {
        if (!orgsForUser || !orgsForUser.getOrganizationsForUser) {
            return false;
        }

        const currentOrg = orgsForUser.getOrganizationsForUser.find((org) => org.id === organizationId);
        if (currentOrg && currentOrg.roles) {
            return currentOrg.roles.includes(role);
        }

        return false;
    };
    /* optional includingPersonalTenant to check for 'roles' on your tenant */
    const hasAnyRole = (includingPersonalTenant = false) => {
        if (includingPersonalTenant && currentProfile?.type === ProfileType.Personal) return true;
        if (!orgsForUser || !orgsForUser.getOrganizationsForUser) {
            return false;
        }

        const currentOrg = orgsForUser.getOrganizationsForUser.find((org) => org.id === organizationId);
        if (currentOrg && currentOrg.roles) {
            return currentOrg.roles.length > 0;
        }

        return false;
    };

    /* Should not display the Administration section for Org users who are ONLY members. */
    const adminOptionsVisible = () => {
        if (!orgsForUser || !orgsForUser.getOrganizationsForUser) {
            return false;
        }

        const currentOrg = orgsForUser.getOrganizationsForUser.find((org) => org.id === organizationId);

        if (currentOrg && currentOrg.roles) {
            if (currentOrg.roles.length === 1) {
                // Negate this because if you are only a member, you can't see it.
                return !currentOrg.roles.includes(OrganizationRolesTypes.Member);
            }
        }

        return true;
    };

    return { hasRole, hasAnyRole, adminOptionsVisible };
};

export default useOrganizationRoles;
