import { OwnerInfo } from 'components/ContentContext/Interfaces';
import { gql, GraphQLClient } from 'graphql-request';
import buildGqlInfiniteQueryFn from 'hooks/useGqlClient/helpers/buildGqlInfiniteQueryFn';
import { QueryFunction, QueryKey } from 'react-query';

import { CollaborativeSkill, CollaborativeCollection } from './utils';

export interface GetCollaborationsByOwnerResponse {
    getCollaborationsByOwner: {
        cursor: string | null;
        items: Collaboration[];
    };
}

export interface Collaboration {
    item: CollaborativeSkill | CollaborativeCollection;
}

export const buildGetCollaborationsByOwnerQueryKey = (ownerInfo: OwnerInfo): QueryKey => [
    'getCollaborationsByOwner',
    ownerInfo
];

const ancestorCollectionsFragment = gql`
    ancestorCollections {
        collectionId
        rootCollectionId
        title
        rootType
        stats {
            estimatedSecondsToConsume
            descendants {
                type
                count
            }
        }
    }
`;

export const collaborativeSkillFragment = gql`
    skillId
    title
    createdAt
    createdBy
    updatedAt
    skillSlug
    tenantSlug
    workflow
    free
    visible
    loginRequired
    owner {
        type
        id
    }
    views
    estimatedSecondsToConsume
    reportStats {
        allTime {
            likeCount
        }
    }
    authors {
        userId
        authorType
    }
    ${ancestorCollectionsFragment}
`;

export const collaborativeCollectionFragment = gql`
    collectionId
    title
    visible
    workflow
    colCreatedAt: createdAt
    colCreatedBy: createdBy
    colUpdatedAt: updatedAt
    collectionSlug
    tenantSlug
    productDetails {
        productId
        prices {
            priceId
            pricePerUnitCents
        }
    }
    rootType
    owner {
        type
        id
    }
    reportStats {
        allTime {
            likeCount
            viewCount
        }
    }
    ${ancestorCollectionsFragment}
    stats {
        estimatedSecondsToConsume
    }
    authors {
        userId
        authorTypes
    }
`;

const GET_COLLABORATIONS = gql`
    query getCollaborationsByOwner($ownerInfo: OwnerInfo!, $cursor: String) {
        getCollaborationsByOwner(ownerInfo: $ownerInfo, cursor: $cursor) {
            cursor
            items {
                item {
                    ... on Skill {
                        ${collaborativeSkillFragment}
                    }
                    ... on Collection {
                        ${collaborativeCollectionFragment}
                        children {
                            type
                            id
                        }
                        itemsVersion
                        productDetails {
                            productId
                            prices {
                                pricePerUnitCents
                                priceId
                            }
                        }

                    }
                }
            }
        }
    }
`;

const getCollaborationsByOwner = (
    client: GraphQLClient,
    variables: { ownerInfo: OwnerInfo }
): QueryFunction<GetCollaborationsByOwnerResponse> => {
    return buildGqlInfiniteQueryFn<GetCollaborationsByOwnerResponse>(client, GET_COLLABORATIONS, variables, 'cursor');
};

export default getCollaborationsByOwner;
