import { useMemo } from 'react';

import { OptimistMutationContext, OptimistRegisterUpdaterOptions, useOptimist } from '@adept-at/lib-react-optimist';
import { EntityType } from 'components/ContentContext/Enums';
import { CollaboratorPermissionLevel } from 'components/ContentContext/Interfaces';
import { ClientError, gql } from 'graphql-request';
import { useSnackbar } from 'notistack';
import { UseMutateFunction, useMutation } from 'react-query';

import useGqlClient from '../../../../../hooks/useGqlClient';
import buildGqlMutationFn from '../../../../../hooks/useGqlClient/helpers/buildGqlMutationFn';
import { API_ACCOUNTS } from '../../../../../lib/ApiConstants';

import {
    buildGetEntityCollaboratorsInvitesQueryKey,
    GetEntityCollaboratorsInvitesResponse,
    EntityCollaboratorsInvite,
    EntityCollaboratorsStatus
} from './getEntityCollaboratorInvites';

const UPDATE_SKILL_COLLABORATOR_INVITATION = gql`
    mutation updateCollaboratorInvitationRole($invitationId: String!, $skillId: UUIDv4!, $role: CollaboratorRole!) {
        updateCollaboratorInvitationRole(invitationId: $invitationId, skillId: $skillId, role: $role) {
            success
        }
    }
`;

interface UpdateCollaboratorInvitationRoleResult {
    updateCollaboratorInvitationRole: boolean;
}

interface UpdateCollaboratorInvitationRoleProps {
    invitationId: string;
    skillId: string;
    role: CollaboratorPermissionLevel;
}

const useUpdateEntityCollaboratorInvitationRole = ({
    onFinished,
    skillId
}: {
    onFinished: () => void;
    skillId: string;
}): {
    updateEntityCollaboratorInvitationRole: UseMutateFunction<
        UpdateCollaboratorInvitationRoleResult,
        ClientError,
        UpdateCollaboratorInvitationRoleProps,
        OptimistMutationContext
    >;
    updateEntityCollaboratorInvitationRoleLoading: boolean;
} => {
    const { enqueueSnackbar } = useSnackbar();

    const { registerUpdater: registerUpdateCollaboratorInviteRole, mutationOptions } = useOptimist<
        UpdateCollaboratorInvitationRoleResult,
        ClientError,
        UpdateCollaboratorInvitationRoleProps
    >();

    const entityCollaboratorQueryKey = useMemo(
        () =>
            buildGetEntityCollaboratorsInvitesQueryKey({
                entity: { type: EntityType.SKILL, id: skillId },
                statuses: [EntityCollaboratorsStatus.Active, EntityCollaboratorsStatus.Expired]
            }),
        [skillId]
    );

    const updateEntityCollaboratorOptions: OptimistRegisterUpdaterOptions<
        UpdateCollaboratorInvitationRoleResult,
        ClientError,
        UpdateCollaboratorInvitationRoleProps
    > = useMemo(() => {
        return {
            onSuccess: () => {
                onFinished();
            },
            onError: () => {
                enqueueSnackbar('An error occurred updating user role', { variant: 'error' });
            }
        };
    }, []);

    registerUpdateCollaboratorInviteRole<GetEntityCollaboratorsInvitesResponse>(
        entityCollaboratorQueryKey,
        (previous, updatedCollabInvite) => {
            const invites = Array.from(previous.getCollaboratorInvitations);
            const result: EntityCollaboratorsInvite[] = [];

            invites.forEach((inv) => {
                if (inv.id === updatedCollabInvite.invitationId) {
                    const invite = { ...inv };
                    invite.role = updatedCollabInvite.role;
                    result.push(inv);
                } else {
                    result.push(inv);
                }
            });

            return { getCollaboratorInvitations: result };
        },
        updateEntityCollaboratorOptions
    );

    const { client, withMutationOptions } = useGqlClient(API_ACCOUNTS);
    const { mutate: updateEntityCollaboratorInvitationRole, isLoading: updateEntityCollaboratorInvitationRoleLoading } =
        useMutation<
            UpdateCollaboratorInvitationRoleResult,
            ClientError,
            UpdateCollaboratorInvitationRoleProps,
            OptimistMutationContext
        >(
            buildGqlMutationFn<UpdateCollaboratorInvitationRoleResult, UpdateCollaboratorInvitationRoleProps>(
                client,
                UPDATE_SKILL_COLLABORATOR_INVITATION
            ),
            withMutationOptions(mutationOptions)
        );

    return {
        updateEntityCollaboratorInvitationRole,
        updateEntityCollaboratorInvitationRoleLoading
    };
};

export default useUpdateEntityCollaboratorInvitationRole;
