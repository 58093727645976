import { gql, GraphQLClient } from 'graphql-request';
import { QueryFunction } from 'react-query';

import buildGqlQueryFn from '../../../hooks/useGqlClient/helpers/buildGqlQueryFn';

const GET_USER_BY_EMAIL = gql`
    query ($email: String!) {
        getUserByEmail(email: $email) {
            id
            alias
        }
    }
`;

interface GetUserByEmailVariables {
    email: string;
}

export interface GetUserByEmailResult {
    getUserByEmail: {
        id: string;
        alias: string;
    };
}

const getUserByEmail = (
    client: GraphQLClient,
    variables: GetUserByEmailVariables
): QueryFunction<GetUserByEmailResult> => buildGqlQueryFn<GetUserByEmailResult>(client, GET_USER_BY_EMAIL, variables);

export default getUserByEmail;
