import React, { useContext, useState, useEffect, useMemo, useCallback } from 'react';

import {
    QuestionViewer,
    QuestionResultStatus,
    QuestionComponentValues,
    QuestionViewerStatus
} from '@adept-at/lib-react-components';
import { LinearProgress } from '@material-ui/core';
import { EntityType } from 'components/ContentContext/Enums';
import { Skill } from 'components/ContentContext/Interfaces';
import AnswerQuestionError from 'components/engine/Components/Question/AnswerQuestionError';
import { useUnsafeMutation } from 'components/GraphqlProvider';
import { InitialQuestionValue } from 'components/learn/components/QuestionComponent/helpers';
import { LearnContext } from 'components/learn/LearnContext';
import { useAccountabilityContext } from 'components/learn/modes/accountability/AccountabilityContext';
import { mapComponentIdToQuestion } from 'components/learn/modes/accountability/utils';
import { useQueryClientProgressUpdater } from 'components/learn/useProgress/useQueryClientProgressUpdater';
import { ProfileContext } from 'context/ProfileContext/index';
import { API_CONTENT } from 'lib/ApiConstants';

import {
    AnswerResponse,
    AnswerSkillQuestionArgs,
    ANSWER_QUESTION,
    submitSkillQuestionAnswer
} from '../../lib/question';

import { Container, StyledButton } from './QuestionForm.styles';

interface QuestionFormProps {
    component: QuestionComponentValues;
    initialValue?: InitialQuestionValue;
    containerId: string;
    containerType: EntityType;
    questionCompleted: boolean;
}

export const QuestionForm: React.FC<QuestionFormProps> = ({
    component,
    initialValue = undefined,
    containerId,
    containerType,
    questionCompleted
}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [value, setValue] = useState(initialValue as any);
    const [allowAdditionalAttempt, setAllowAdditionalAttempt] = useState(false);
    const [attempt, setAttempt] = useState(0);
    const [timeoutProgress, setTimeoutProgress] = useState(0);

    const { currentProfile } = useContext(ProfileContext);
    const orgId = currentProfile?.id;

    const { accountabilityModeOn, completeComponent } = useAccountabilityContext();

    const learnContext = useContext(LearnContext);

    const [answerQuestion, { error, data, loading, reset }] = useUnsafeMutation<
        AnswerResponse,
        AnswerSkillQuestionArgs
    >(ANSWER_QUESTION, {}, { host: API_CONTENT });

    const componentId = useMemo(() => {
        if (learnContext?.skill) {
            const componentIdToQuestion = mapComponentIdToQuestion(
                Object.values(learnContext?.skill?.components ?? {})[0]
            );
            const found = Object.entries(componentIdToQuestion).find(
                ([_compId, question]) => question.questionId === component.questionId
            );
            return found?.[0];
        }
    }, [component.questionId, learnContext?.skill]);

    const buildNewProgressFromPrevious = useCallback(
        (previous: Skill) => {
            return {
                secondsConsumed: Math.max(previous?.progress?.secondsConsumed ?? 0, 0.1),
                completedQuestions: (previous?.progress?.completedQuestions ?? []).concat([
                    component.questionId as string
                ]),
                lastCompletedBlockKey: accountabilityModeOn ? componentId : undefined,
                updatedAt: new Date().toISOString()
            };
        },
        [component.questionId, componentId, accountabilityModeOn]
    );

    const { updateSkillProgressQueryData } = useQueryClientProgressUpdater(
        learnContext?.tenantSlug,
        learnContext?.skillSlug,
        buildNewProgressFromPrevious
    );

    useEffect(() => {
        if (data?.answerQuestion && data.answerQuestion.isValid) {
            if (accountabilityModeOn && componentId) {
                return completeComponent(componentId);
            }

            if (learnContext?.tenantSlug && learnContext?.skillSlug && component.questionId) {
                updateSkillProgressQueryData();
            }
        }
    }, [data]);

    const timeoutInSeconds = component.answerDelayInSeconds * 2 ** attempt;

    const mutationParams = {
        orgId: orgId as string,
        containerId,
        containerType,
        questionId: component.questionId as string,
        answer: value,
        attempt,
        timeout: timeoutInSeconds
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isSubmitting || data?.answerQuestion.isValid) {
            return;
        }

        submitSkillQuestionAnswer(setIsSubmitting, setAttempt, setTimeoutProgress, answerQuestion, mutationParams);
    };

    useEffect(() => {
        if (!questionCompleted) {
            reset();
            setValue(undefined);
        }
    }, [questionCompleted, reset, setValue]);

    const questionResultStatus = useMemo(() => {
        if (questionCompleted) {
            return QuestionResultStatus.Correct;
        }

        if (data?.answerQuestion && component.questionType !== 'poll') {
            if (data.answerQuestion.isValid) {
                return QuestionResultStatus.Correct;
            }

            if (allowAdditionalAttempt) {
                return QuestionResultStatus.Unanswered;
            }

            return QuestionResultStatus.Incorrect;
        }

        return QuestionResultStatus.Unanswered;
    }, [data, allowAdditionalAttempt, component.questionType, questionCompleted]);

    const componentValues = useMemo(() => {
        const pollResponses = data?.answerQuestion?.pollResponses ?? component?.pollResponses ?? {};
        return { ...component, pollResponses };
    }, [component, data]);

    const questionViewerStatus = useMemo(() => {
        if (error) {
            // Add Error Status
        }

        if (data && !data.answerQuestion.isValid && isSubmitting) {
            return QuestionViewerStatus.Submitting;
        }

        return QuestionViewerStatus.Initial;
    }, [data, error, isSubmitting]);

    useEffect(() => {
        if (isSubmitting) {
            setAllowAdditionalAttempt(false);
        }
    }, [isSubmitting]);

    interface RenderActionProps {
        timeoutInSeconds: number;
        timeoutProgress: number;
    }

    const renderAction = ({ timeoutInSeconds, timeoutProgress }: RenderActionProps) => {
        const timeRemaining = timeoutInSeconds - timeoutProgress;

        if (questionCompleted || questionResultStatus === QuestionResultStatus.Correct) {
            return null;
        }

        if (!isSubmitting) {
            return (
                <StyledButton data-testid="questionView-tryAgain-btn" onClick={() => setAllowAdditionalAttempt(true)}>
                    Try again
                </StyledButton>
            );
        }

        if (timeRemaining > 0) {
            return <>try again in {timeRemaining} seconds</>;
        }
    };

    const renderHint = () => {
        if (questionResultStatus === QuestionResultStatus.Incorrect) {
            return data?.answerQuestion?.hint;
        }
    };

    const shouldShowSubmitButton =
        (questionResultStatus === QuestionResultStatus.Unanswered && !questionCompleted) ||
        componentValues.questionType === 'poll';

    return (
        <>
            <AnswerQuestionError error={error} />
            {loading && <LinearProgress />}

            <form onSubmit={handleSubmit}>
                <QuestionViewer.Question.Factory
                    componentValues={componentValues}
                    answer={value}
                    setAnswer={setValue}
                    disabled={loading || questionCompleted || questionResultStatus === QuestionResultStatus.Correct}
                />
                <Container>
                    {shouldShowSubmitButton ? (
                        <QuestionViewer.SubmitButton
                            status={questionViewerStatus}
                            disabled={isSubmitting || !!componentValues.pollResponses?.totalResponses}
                            type="submit"
                            timeoutInSeconds={timeoutInSeconds}
                            timeoutProgress={timeoutProgress}
                            data-testid="questionView-submit"
                        />
                    ) : (
                        <QuestionViewer.Result
                            status={questionCompleted ? QuestionResultStatus.Correct : questionResultStatus}
                            hint={renderHint()}
                            action={renderAction({ timeoutInSeconds, timeoutProgress })}
                        />
                    )}
                </Container>
            </form>
        </>
    );
};

export default QuestionForm;
