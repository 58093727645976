import React, { useEffect } from 'react';

import { Button } from '@material-ui/core';
import AckableAdepteductMessage from 'lib/communication/message/base/AckableAdepteductMessage';
import { MessageDestination, MessageAckType } from 'lib/communication/message/MessageRecord';
import { useSnackbar } from 'notistack';
import useSetAsState from 'use-set-as-state';

import useMessageActions from '../../communication/useMessageActions';
import useSystemChannelsMessages from '../../communication/useSystemChannelsMessages';

const useSystemChannelNotifier = (): void => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const alreadyNotifiedMessageIds = useSetAsState(new Set<string>());

    const { messages } = useSystemChannelsMessages();
    const { ackMessage } = useMessageActions();

    useEffect(() => {
        // @TODO Consider omitting snackbars for channel messages if they are currently viewing channel/inbox

        const messagesToNotifyOn = messages.filter((message) => {
            const isATempMessage = !message || !(message as AckableAdepteductMessage).hasAckOfType;
            if (isATempMessage) return false;

            return (
                !alreadyNotifiedMessageIds.has(message.id) &&
                (message as AckableAdepteductMessage)?.destinations.includes(MessageDestination.Dinger) &&
                !(message as AckableAdepteductMessage)?.hasValidAck &&
                !(message as AckableAdepteductMessage)?.hasAckOfType(MessageAckType.View)
            );
        }) as AckableAdepteductMessage[];

        if (messagesToNotifyOn.length === 0) {
            return;
        }

        messagesToNotifyOn.forEach((message) => {
            alreadyNotifiedMessageIds.add(message.id);

            const SnackbarComponent = message.mapDestinationToComponent(MessageDestination.Dinger);

            enqueueSnackbar(<SnackbarComponent destination={MessageDestination.Dinger} message={message} />, {
                onExited: () => {
                    ackMessage(message.channelId, message.id, MessageAckType.View);
                },
                autoHideDuration: 10000,
                action: (key) => {
                    return (
                        <Button
                            onClick={() => {
                                closeSnackbar(key);
                                ackMessage(message.channelId, message.id, MessageAckType.Dismiss);
                            }}
                            style={{ color: 'white' }}
                        >
                            Dismiss
                        </Button>
                    );
                }
            });
        });
    }, [ackMessage, alreadyNotifiedMessageIds, closeSnackbar, enqueueSnackbar, messages]);
};

export default useSystemChannelNotifier;
