import { GuidanceType } from './Guidance/GuidanceContext';

export const SKILL_CONTAINER_ID = 'skill-container';
export const COMPONENT_ID_PREFIX = 'component';
export const COMPONENT_SORT_AREA = 'component-sort-area';

export const COMPONENT_TYPES = {
    text: 'Text',
    sectionTitle: 'Section',
    talkinghead: 'TalkingHead',
    screencapture: 'ScreenCapture',
    image: 'Image',
    audio: 'Audio',
    question: 'Question',
    video: 'Video',
    code: 'Code'
};

export const COMPONENT_GUIDANCE_MAP = {
    text: GuidanceType.Text,
    question: GuidanceType.QuizQuestion,
    sectionTitle: GuidanceType.SectionTitle,
    overview: GuidanceType.Overview,
    conclusion: GuidanceType.Conclusion,
    video: GuidanceType.Video,
    image: GuidanceType.Image,
    pdf: GuidanceType.Attachment,
    code: GuidanceType.Code
};

export const mapComponentType = (componentType) => {
    const type = COMPONENT_TYPES[componentType];
    return type || '';
};
