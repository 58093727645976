import styled from 'styled-components';

import { modularScale } from '../../../../../utils/modular_scale';

export const Container = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

/**
 * @TODO
 *
 * These have been brought over from the ImageUploadModal. We need to move to a global file upload style component that
 * can more easily be used throughout.
 */

interface DropzoneProps {
    image: any;
    isLoading: boolean;
    width: number;
}

export const DropzoneWrapper = styled.div<DropzoneProps>`
    position: relative;
    background-color: rgba(209, 218, 223, 0.15);
    border: ${(props) => (props.image && !props.isLoading ? 'none' : `dashed 4px ${props.theme.colors.border}`)};
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: ${(props) => (props.width ? `${props.width}px` : '100%')};
    margin-bottom: 1rem;

    &:after {
        content: '';
        display: block;
        padding-bottom: 100%;
    }
`;

export const UploadControls = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    svg {
        color: ${(props) => props.theme.colors.border};
        width: 100px;
        margin-bottom: 1rem;
    }
`;

export const DragText = styled.div`
    font-size: ${modularScale(1)};
    margin-bottom: 1rem;
`;

export const SelectText = styled.div`
    position: relative;
    font-style: italic;
    margin-bottom: 2rem;
    color: ${(props) => props.theme.colors.lightGrey};

    &:after,
    &:before {
        position: absolute;
        content: '';
        height: 1px;
        width: 25px;
        background: ${(props) => props.theme.colors.lightGrey};
        top: 0;
        bottom: 0;
        margin: auto;
    }

    &:after {
        left: calc(100% + 10px);
    }

    &:before {
        right: calc(100% + 10px);
    }
`;

export const Buttons = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const PreviewWrapper = styled.div<{ isLoading }>`
    color: ${(props) => props.theme.colors.white};
    position: absolute;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    transform: ${(props) => (props.isLoading ? 'scale(0.8)' : 'scale(1)')};
    border-radius: ${(props) => (props.isLoading ? '50px' : '0')};
    transition: transform 300ms ease, border-radius 300ms ease;
    overflow: hidden;
    justify-content: center;
`;
