import React, { useState } from 'react';

import InlineUploadImage, { ImageDestination } from 'components/engine/InlineUploadImage/index';

// @TODO pull this from the components lib
type ImageDimensions = { width: string | number | null; height: string | number | null };

interface UploadImageEditUploadProps {
    onUploaded: (assetId: string, url: string, dimensions: ImageDimensions) => void;
    onCancel: () => void;
}

export const UploadImageEditUpload: React.FC<UploadImageEditUploadProps> = ({ onUploaded, onCancel }) => {
    const [modalOpen, setModalOpen] = useState<boolean>(true);

    return (
        <>
            {/* @TODO InlineUploadImage should be moved out of engine dir and into a better common area */}
            <InlineUploadImage
                imageModalOpen={modalOpen}
                // setImageModalOpen should become onClose since that's all it's used for
                setImageModalOpen={() => {
                    setModalOpen(false);
                }}
                onCancel={onCancel}
                imageURL={null}
                onFinishUpload={(
                    assetId: string,
                    imageDataURL: string,
                    _key: ImageDestination,
                    dimensions: ImageDimensions
                ) => {
                    setModalOpen(false);
                    onUploaded(assetId, imageDataURL, dimensions);
                }}
                aspectRatio={null}
                // @TODO ImageDestination should denote Component Engine
                imageKey={ImageDestination.SkillComponent}
                tags={['image', ImageDestination.SkillComponent]}
            />
        </>
    );
};
