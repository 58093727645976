import React from 'react';

import { VideoType, getSelectorOptionForType } from '../VideoTypes';

import { useTypeSelectorNavigation } from './NavigationContext';
import { OptionsContainer, TypeOptionWithVisibility } from './TypeOptions.styles';
import { VerticalDivider } from './VerticalDivider';

export const TypeSelectorOptions: React.FC = () => {
    const { navigateTo, isInPath, getCurrentLevel } = useTypeSelectorNavigation();

    return (
        <OptionsContainer>
            {Object.entries(VideoType).map(([, selectorType], idx) => {
                const SelectorOption = getSelectorOptionForType(selectorType);

                return (
                    <React.Fragment key={idx}>
                        {getCurrentLevel() > 0 && idx !== 0 && <VerticalDivider />}
                        <TypeOptionWithVisibility visible={getCurrentLevel() === 0 || isInPath(selectorType)}>
                            <SelectorOption
                                onSelect={() => {
                                    navigateTo(selectorType);
                                }}
                            />
                        </TypeOptionWithVisibility>
                    </React.Fragment>
                );
            })}
        </OptionsContainer>
    );
};
