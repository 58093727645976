import { defaultMuiTheme, modularScale } from '@adept-at/lib-react-components';
import styled from 'styled-components';
import { APP_HEADER_HEIGHT, SUB_HEADER_HEIGHT } from 'styles/variables';

const PARENT_COLLECTION_CARD_HEIGHT = 155;

export const Container = styled.div`
    background-color: ${(props) => props.theme.colors.surface};
`;

export const AboutTitleSubContainer = styled.div``;

export const ContentCardsWrapper = styled.div<{
    $hasParentCollection?: boolean;
    $viewingInCollection?: boolean;
    $padding?: boolean;
}>`
    width: 100%;
    // when viewing in skill sidebar, set to full height
    height: ${(props) =>
        props.$viewingInCollection
            ? `calc(
        90vh - ${APP_HEADER_HEIGHT}px - ${SUB_HEADER_HEIGHT}px -
            ${props.$hasParentCollection ? PARENT_COLLECTION_CARD_HEIGHT : '0'}px
    );`
            : '100%'};
    min-height: 200px;
    overflow-y: auto;
    background-color: ${(props) => props.theme.colors.bottomSurface};
    ${(props) => (props.$padding ? 'padding: 1rem 1rem 1rem 0;' : null)}
    ${defaultMuiTheme.breakpoints.down('md')} {
        /* overwrite the height when content is simply presented at the bottom of the collection page */
        ${(props) => (props?.$viewingInCollection ? 'height: auto;' : '')}
    }
`;

export const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0.5rem 0;
`;

export const MessageFirstLine = styled.div`
    font-weight: 500;
`;

export const DescriptionTitle = styled.div`
    padding: 10px;
    font-size: ${modularScale(-1)};
`;
