import { ComponentValues, RichTextComponentValues, RichTextDraftJsVal } from '@adept-at/lib-react-components';
import { Section } from 'components/Comments/interfaces';
import { Skill } from 'components/ContentContext/Interfaces';
import { DraftJsBlock } from 'components/engine/common/RichTextEditor/utils';

const parseSections = (blocks: RichTextDraftJsVal['blocks'], sections: Section[], defaultSection?: Section) => {
    blocks.forEach((block, index) => {
        const blockHasText = !!block.text?.trim();
        if (block.type === DraftJsBlock.H1 && blockHasText) {
            sections.push({ title: block.text, id: block.key });
        } else if (index === 0 && defaultSection) {
            sections.push(defaultSection);
        }
    });
};

export const getCommentSections = (entity: Skill): Section[] => {
    const components = Object.values(entity.components ?? {}) as ComponentValues[];

    const textComponent = components[0] as RichTextComponentValues;
    const textComponentBlocks = textComponent?.body?.val?.blocks ?? [];

    const sections: Section[] = [];

    parseSections(textComponentBlocks, sections);

    return sections;
};
