import React, { useState, useEffect, useRef } from 'react';

import { CircularProgress, FormControl, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { EMAIL_REGEX } from 'lib/constants';
import styled from 'styled-components';

import { UserSearchOptionType, UserSearchOption } from './types';
import { UserSearchResult } from './useUserSearch';
import { useUserSearchOptions, OptionsReducerActionType } from './useUserSearchOptions';
import { SelectionsReducerActionType, Action as UseUserSearchSelectionsAction } from './useUserSearchSelections';

const Control = styled(FormControl)`
    margin-bottom: 25px;
`;

const StyledTextField = styled(TextField)`
    min-width: fit-content;
`;

const DEFAULT_MINIMUM_INPUT_LENGTH = 3;
const DEFAULT_VALID_SEARCH_TYPES = [UserSearchOptionType.UserId];
const DEFAULT_INPUT_PLACEHOLDER_TEXT = 'Enter Username or e-mail';
const DEFAULT_INPUT_LABEL_TEXT = 'Add people by username or e-mail.';

const ONCHANGE_REASON_CREATE_OPTION = 'create-option';

// @TODO should be able to use props to limit valid UserSearchOptionType options

interface UserSearchFormProps {
    searchResults: UserSearchResult[];
    searchStatus: 'idle' | 'error' | 'loading' | 'success';
    isSearchLoading: boolean;
    performSearch: (type: UserSearchOptionType, id: string) => void;

    selections: Record<string, UserSearchOption>;
    updateSelections: React.Dispatch<UseUserSearchSelectionsAction>;
    renderSelected?: (option: UserSearchOption, tagProps) => React.ReactElement;
    inputLabelText?: string;
    inputPlaceholderText?: string;
    minimumInputLength?: number;
    validSearchTypes?: UserSearchOptionType[];

    optionCreateText?: string;
}

/**
 * Component allows building up a state of found users (or users to invite to join Adept.at platform) and a form of
 * commit through a button click, etc.
 */

export const UserSearchForm: React.FC<UserSearchFormProps> = ({
    performSearch,
    isSearchLoading,
    searchStatus,
    searchResults = [],
    selections,
    updateSelections,
    renderSelected,
    inputLabelText = DEFAULT_INPUT_LABEL_TEXT,
    inputPlaceholderText = DEFAULT_INPUT_PLACEHOLDER_TEXT,
    minimumInputLength = DEFAULT_MINIMUM_INPUT_LENGTH,
    validSearchTypes = DEFAULT_VALID_SEARCH_TYPES,
    optionCreateText
}) => {
    const DEFAULT_HELPER_TEXT = `Enter a username or e-mail. To search, enter at least ${minimumInputLength} characters`;

    const [inputError, setInputError] = useState<boolean>(false);
    const [helperText, setHelperText] = useState<null | string>(DEFAULT_HELPER_TEXT);
    const activeSearchValue = useRef<string | null>(null);

    const [inputValue, setInputValue] = useState<string>('');

    const [options, updateOptions] = useUserSearchOptions();

    const doPerformSearch = async (searchValue: string) => {
        if (searchValue.length < minimumInputLength) {
            return;
        }
        const type = EMAIL_REGEX.test(searchValue) ? UserSearchOptionType.UserEmail : UserSearchOptionType.UserId;

        activeSearchValue.current = searchValue;

        performSearch(type, searchValue);
    };

    // @TODO We do not currently support creating options (from email most likely)
    const doCreateOption = (newValues: string[]) => {
        updateOptions({
            type: OptionsReducerActionType.Clear
        });

        return;
    };

    const handleSelectedMultipleValues = (newValue: (string | UserSearchOption)[]) => {
        if (newValue.length === 0) {
            updateSelections({
                type: SelectionsReducerActionType.Clear
            });
            return;
        }

        // Determine the lastAddedUser
        const lastAddedUser = newValue.length === 0 ? null : newValue[newValue.length - 1];

        // Interpret a string value as an email input
        // @TODO we will support a 'string' input value if they are allowing email address
        if (!lastAddedUser || typeof lastAddedUser === 'string') {
            // @TODO We do not currently support a new value of string
            return;
        }

        updateSelections({
            type: SelectionsReducerActionType.ReplaceAll,
            options: newValue
        });
    };

    const doCreateEmailSearchOption = (inputValue: string, status): void => {
        if (status === 'success') {
            updateOptions({
                type: OptionsReducerActionType.Add,
                option: {
                    id: inputValue,
                    label: `${optionCreateText} ${inputValue}`,
                    type: UserSearchOptionType.UserEmail,
                    details: {}
                }
            });
        }
    };

    useEffect(() => {
        if (!searchResults) {
            return;
        }

        if (isSearchLoading && inputValue.length > 0) {
            setHelperText(`Looking for users that match "${inputValue}"`);
        }
        // Only update helperText is they have not hit enter on the input value
        else if (searchResults.length === 0 && inputValue.length > 0) {
            setHelperText(`No users found for "${inputValue}"`);
        }

        if (searchResults.length === 0) {
            //if theres no searchResults for something that matches the optionCreateRegex,
            //that means we can add it as an option to be "created"
            if (optionCreateText && EMAIL_REGEX.test(inputValue)) {
                doCreateEmailSearchOption(inputValue, searchStatus);
            } else {
                updateOptions({
                    type: OptionsReducerActionType.Clear
                });
            }

            return;
        }

        // If no input, then the results have already been used
        if (inputValue.length === 0 || activeSearchValue.current !== inputValue || isSearchLoading) {
            return;
        }

        const resultPlurality = searchResults.length === 1 ? '' : 's';

        const [{ id, details = {}, type, email, alias }] = searchResults;

        let label = 'non-matching';
        if (type === UserSearchOptionType.UserId && alias) label = alias;
        if (type === UserSearchOptionType.UserEmail && email) label = email;

        // @TODO we need alias to match but set id on click
        updateOptions({
            type: OptionsReducerActionType.Replace,
            option: { label, id, type, details }
        });
        setHelperText(`Found ${searchResults.length} user${resultPlurality}!`);
    }, [searchResults, updateOptions, inputValue, isSearchLoading, searchStatus]);

    const resetOptions = () => {
        updateOptions({
            type: OptionsReducerActionType.Clear
        });

        setHelperText(DEFAULT_HELPER_TEXT);
    };

    const autocompleteProps: { renderTags?: any } = {};

    if (renderSelected) {
        autocompleteProps.renderTags = (value, getTagProps) =>
            value.map((option, index) => renderSelected(option, getTagProps({ index })));
    }

    return (
        <Control fullWidth>
            <Autocomplete
                value={Object.entries(selections).map(([_, selection]) => selection)}
                inputValue={inputValue}
                multiple
                freeSolo
                clearOnBlur
                clearOnEscape
                options={Object.entries(options).map(([_, option]) => option)}
                getOptionLabel={(option: UserSearchOption) => {
                    const { label } = option;

                    return label;
                }}
                onInputChange={(event, newInputValue) => {
                    if (!event) {
                        return;
                    }

                    if (newInputValue.length === 0) {
                        setInputValue('');
                        resetOptions();
                        return;
                    }

                    resetOptions();
                    setInputValue(newInputValue);

                    // @TODO search could also be a usergroup
                    doPerformSearch(newInputValue);
                }}
                onChange={(_event, newValue: (string | UserSearchOption)[] | null, reason) => {
                    if (!newValue) {
                        resetOptions();
                        return;
                    }

                    if (reason === ONCHANGE_REASON_CREATE_OPTION) {
                        return doCreateOption(newValue as string[]);
                    }

                    // If value is a string, we wish to perform the search
                    if (typeof newValue === 'string') {
                        // @TODO search could also be a  usergroup
                        doPerformSearch(newValue);
                        return;
                    }

                    if (Array.isArray(newValue)) {
                        handleSelectedMultipleValues(newValue);
                    }

                    resetOptions();
                }}
                renderOption={({ label }) => <>{label}</>}
                renderInput={(params) => (
                    <StyledTextField
                        {...params}
                        error={inputError}
                        variant="outlined"
                        label={inputLabelText}
                        aria-label="content-tag-input"
                        placeholder={inputPlaceholderText}
                        helperText={helperText ? helperText : ''}
                        InputProps={{
                            ...params.InputProps,
                            'aria-label': 'user-search-input',
                            endAdornment: (
                                <>
                                    {isSearchLoading && (
                                        <CircularProgress size={20} aria-label="share-usernames-loading" />
                                    )}
                                    {params.InputProps.endAdornment}
                                </>
                            )
                        }}
                        inputProps={{
                            ...params.inputProps
                        }}
                    />
                )}
                {...autocompleteProps}
            />
        </Control>
    );
};
