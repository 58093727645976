import React from 'react';

import { StyledTableCell } from './TableCell.styles';

interface CommentsCellProps {
    comments?: number;
}

export const COMMENTS_CELL_WIDTH = 140;

export const CommentsCell: React.FC<CommentsCellProps> = ({ comments }) => {
    return <StyledTableCell width={COMMENTS_CELL_WIDTH}>{comments ?? 0}</StyledTableCell>;
};
