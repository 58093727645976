import React from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { Button, Link, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { mdiDownload } from '@mdi/js';
import Icon from '@mdi/react';
import styled from 'styled-components';

const StyledAlert = styled(Alert)`
    height: 138px;
    margin-top: 2rem;
    background-color: ${(props) => props.theme.colors.surface};

    .MuiAlert-message {
        display: flex;
    }
`;

const AlertMessageSection = styled.div`
    width: 480px;
`;

const AlertActionSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    margin-left: 3rem;
    color: ${(props) => props.theme.colors.primary};
`;

const StyledDownloadButton = styled(Button)`
    white-space: nowrap;

    & svg {
        color: ${(props) => props.theme.colors.primary};
    }

    a {
        cursor: pointer;
        text-decoration: none;
        margin-left: 3px;
    }
`;

const GET_THE_APP_TITLE = 'Get the app';
const GET_THE_APP_MESSAGE = `If you'd like to capture and record video and audio from your computer, you'll need to download and install the Adept desktop application.`;
const GET_THE_APP_DISMISS = 'Dismiss';
const DOWNLOAD_THE_APP_TEXT = 'Download and install the app';

const GetTheAppAlert: React.FC<{
    showAlert: boolean;
    setShowAlert: React.Dispatch<React.SetStateAction<boolean>>;
    downloadURL: string | undefined;
}> = ({ showAlert, setShowAlert, downloadURL }) => {
    const renderAlert = () => {
        if (showAlert)
            return (
                <StyledAlert severity="info" variant="outlined">
                    <AlertMessageSection>
                        <AlertTitle>{GET_THE_APP_TITLE}</AlertTitle>
                        <Typography variant={'body2'}>{GET_THE_APP_MESSAGE}</Typography>
                    </AlertMessageSection>
                    <AlertActionSection>
                        <Button color="inherit" size="small" onClick={() => setShowAlert(false)}>
                            {GET_THE_APP_DISMISS}
                        </Button>
                        <StyledDownloadButton size="small">
                            <Icon title="download" path={mdiDownload} size={IconSize.Small} />
                            <Link href={downloadURL}>{DOWNLOAD_THE_APP_TEXT}</Link>
                        </StyledDownloadButton>
                    </AlertActionSection>
                </StyledAlert>
            );

        return null;
    };

    return renderAlert();
};

export default GetTheAppAlert;
