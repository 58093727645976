import { useContext, useEffect } from 'react';

import { OptimistMutationContext, useOptimist } from '@adept-at/lib-react-optimist';
import {
    buildGetCollaborationsByOwnerQueryKey,
    GetCollaborationsByOwnerResponse
} from 'components/CollaborationTable/getCollaborationsByOwner';
import { OwnerInfo } from 'components/ContentContext/Interfaces';
import { GetSkillBySlugResponse, buildGetSkillBySlugQueryKey } from 'components/learn/getSkillBySlug';
import { LearnContext } from 'components/learn/LearnContext';
import {
    buildQueryKey as buildGetSkillByIdQueryKey,
    GetSkillByIdResponse
} from 'components/skills/hooks/useGetSkillById';
import { GetCollectionChildrenDetailsResponse } from 'components/studio/collection/getCollectionChildrenDetails';
import { StudioCollectionContext } from 'components/studio/collection/StudioCollectionContext';
import { buildGetStudioSkillQueryKey, GetStudioSkillResponse } from 'components/studio/skill/useGetStudioSkill';
import { ClientError, gql } from 'graphql-request';
import { useBuildAuthenticatedMutationFn } from 'hooks/useGqlClient/useBuildAuthenticatedMutationFn';
import { Api } from 'lib/ApiConstants';
import { useSnackbar } from 'notistack';
import { InfiniteData, useMutation, UseMutationResult } from 'react-query';
import { setSkillEditedInSessionStorage } from 'utils/skill/editedSkills';

export const UPDATE_SKILL_AVAILABILITY_MUTATION = gql`
    mutation updateSkillMeta($skillId: UUIDv4!, $visible: Boolean, $free: Boolean, $loginRequired: Boolean) {
        updateSkillMeta(skillId: $skillId, visible: $visible, free: $free, loginRequired: $loginRequired) {
            success
        }
    }
`;

export interface UpdateSkillAvailabilityResult {
    success: boolean;
}

interface UpdateSkillAvailabilityProps {
    skillId: string;
    visible?: boolean;
    free: boolean;
    loginRequired: boolean;
}

export const useUpdateSkillAvailability = (
    owner?: OwnerInfo,
    skillId?: string
): UseMutationResult<
    UpdateSkillAvailabilityResult,
    ClientError,
    UpdateSkillAvailabilityProps,
    OptimistMutationContext
> => {
    const studioCollectionContext = useContext(StudioCollectionContext);
    const { enqueueSnackbar } = useSnackbar();

    const { skillSlug, tenantSlug } = useContext(LearnContext) ?? {};
    const { registerUpdater, mutationOptions } = useOptimist<
        UpdateSkillAvailabilityResult,
        ClientError,
        UpdateSkillAvailabilityProps
    >({ globalMutationOptions: { onSettled: () => setSkillEditedInSessionStorage(skillId, tenantSlug, skillSlug) } });

    useEffect(() => {
        if (owner) {
            registerUpdater<InfiniteData<GetCollaborationsByOwnerResponse>>(
                buildGetCollaborationsByOwnerQueryKey(owner),
                (previous, args) => {
                    const updatedPages = (previous?.pages ?? []).map((page) => {
                        return {
                            ...page,
                            getCollaborationsByOwner: {
                                ...page?.getCollaborationsByOwner,
                                items: (page?.getCollaborationsByOwner?.items ?? []).map((item) =>
                                    'skillId' in item.item && item.item.skillId === args.skillId
                                        ? {
                                              ...item,
                                              item: {
                                                  ...item.item,
                                                  free: args.free,
                                                  visible: args.visible ?? item.item.visible,
                                                  loginRequired: args.loginRequired
                                              }
                                          }
                                        : item
                                )
                            }
                        };
                    });

                    return {
                        ...previous,
                        pages: updatedPages
                    };
                },
                {
                    refetchQuery: false,
                    onError: () => {
                        enqueueSnackbar('An error occurred. Unable to update availability', { variant: 'error' });
                    }
                }
            );
        }
    }, [owner, registerUpdater, enqueueSnackbar]);

    useEffect(() => {
        if (studioCollectionContext?.contentQueryKey) {
            registerUpdater<GetCollectionChildrenDetailsResponse>(
                studioCollectionContext?.contentQueryKey,
                (previous, variables) => {
                    const updatedDetails = (previous?.getEntityBasicDetails ?? []).map((details) => {
                        if ('skillId' in details && details.skillId === variables.skillId) {
                            return {
                                ...details,
                                free: variables.free,
                                visible: variables.visible ?? details.visible,
                                loginRequired: variables.loginRequired
                            };
                        }
                        return details;
                    });

                    return {
                        ...previous,
                        getEntityBasicDetails: updatedDetails
                    };
                },
                { refetchQuery: false }
            );
        }
    }, [studioCollectionContext?.contentQueryKey, registerUpdater]);

    useEffect(() => {
        if (skillId) {
            registerUpdater<GetStudioSkillResponse>(
                buildGetStudioSkillQueryKey(skillId),
                (previous, args) => {
                    return {
                        ...previous,
                        getSkillById: {
                            ...previous?.getSkillById,
                            free: args.free,
                            visible: args.visible ?? previous?.getSkillById?.visible,
                            loginRequired: args.loginRequired
                        }
                    };
                },
                { refetchQuery: false }
            );

            registerUpdater<GetSkillByIdResponse>(
                buildGetSkillByIdQueryKey(skillId),
                (previous, args) => {
                    return {
                        ...previous,
                        getSkillById: {
                            ...previous?.getSkillById,
                            free: args.free,
                            visible: args.visible ?? previous?.getSkillById?.visible,
                            loginRequired: args.loginRequired
                        }
                    };
                },
                { refetchQuery: false }
            );
        }
    }, [skillId, registerUpdater]);

    useEffect(() => {
        if (skillSlug && tenantSlug) {
            registerUpdater<GetSkillBySlugResponse>(
                buildGetSkillBySlugQueryKey(tenantSlug, skillSlug),
                (previous, args) => {
                    return {
                        ...previous,
                        getEntityBySlug: {
                            ...previous?.getEntityBySlug,
                            free: args.free,
                            visible: args.visible ?? previous?.getEntityBySlug?.visible,
                            loginRequired: args.loginRequired
                        }
                    };
                },
                { refetchQuery: false }
            );
        }
    }, [skillSlug, tenantSlug, registerUpdater]);

    const { mutationFn } = useBuildAuthenticatedMutationFn<UpdateSkillAvailabilityResult, UpdateSkillAvailabilityProps>(
        UPDATE_SKILL_AVAILABILITY_MUTATION,
        { api: Api.Content }
    );

    return useMutation<
        UpdateSkillAvailabilityResult,
        ClientError,
        UpdateSkillAvailabilityProps,
        OptimistMutationContext
    >(mutationFn, mutationOptions);
};
