import React, { createContext, useState } from 'react';

import { show, hide, isVisible } from './VisibilityStorage';

interface DisplayContextInterface {
    show: () => void;
    hide: () => void;
    visible: boolean;
}

const DisplayContext = createContext({} as DisplayContextInterface);

const { Provider } = DisplayContext;

const DisplayProvider: React.FC = ({ children }) => {
    const [visible, setVisible] = useState<boolean>(() => isVisible());

    return (
        <Provider
            value={{
                show: () => {
                    setVisible(true);
                    show();
                },
                hide: () => {
                    setVisible(false);
                    hide();
                },
                visible
            }}
        >
            {children}
        </Provider>
    );
};

export { DisplayContext, DisplayProvider };
