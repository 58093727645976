import styled from 'styled-components';
import { SKILL_CONTENT_WIDTH } from 'styles/variables';

export const Container = styled.div<{
    fullBleed?: string;
}>`
    position: relative;
    max-width: ${SKILL_CONTENT_WIDTH}px;
    margin: 0 auto;
    padding: 2rem 8px;

    ${(props) =>
        props.fullBleed
            ? `
                max-width: 100%;
                padding-left: 0;
                padding-right: 0;
            `
            : ''};
`;
