import React, { useContext, useRef, useMemo } from 'react';

import {
    CollectionAboutContainer,
    CollectionBottomContainer,
    CollectionLoader,
    CollectionTopContainer,
    defaultMuiTheme
} from '@adept-at/lib-react-components';
import { useMediaQuery } from '@material-ui/core';
import { BookshelvesProvider } from 'components/bookshelves/BookshelvesContext';
import { ScheduleProvider } from 'components/dash/Schedule/ScheduleContext';
import { ComponentEngineMode } from 'components/engine';
import { MarketplaceListingStatus } from 'components/marketplace/hooks/useUpsertListingStatus';
import { ModalView } from 'components/modals/Bookshelves/BookshelfModal';
import { CollectionType } from 'components/modals/Skills/useGetCollectionsForTenantProfile';
import { CollectionBackgroundImage } from 'components/NestedCollection/CollectionBackgroundImage';
import { CollectionTabs } from 'components/NestedCollection/CollectionTabs';
import { CollectionImageProvider } from 'components/NestedCollection/context/CollectionImageContext';
import { Description } from 'components/NestedCollection/Description';
import { EditCollectionHeader } from 'components/NestedCollection/EditCollectionHeader';
import { PendingChangesAlert } from 'components/NestedCollection/PendingChangesAlert';
import { CollectionActions } from 'hooks/authorization';
import { useIsElementVisible } from 'hooks/useIsElementVisible';
import { useSnackbar } from 'notistack';

import { AboutSection } from './AboutSection';
import { CollectionActionWrapper } from './CollectionActions';
import { Container } from './Collections.styles';
import { NESTED_COLLECTION_CONTENT_ID } from './constants';
import { RootCollectionContext } from './context/RootCollectionContext';
import { StickyCollectionActions } from './StickyCollectionActions';
import { useCommitEdits } from './useCommitEdits';
import { useDiscardEdits } from './useDiscardEdits';

const RootProfileCollection: React.FC = () => {
    const {
        authorizedActions,
        collectionId,
        images,
        getTenantProfileIsLoading,
        isEditMode,
        setIsEditMode,
        pendingChanges,
        setPendingChanges,
        collectionIdForEditableCollectionQuery,
        expectedInfoVersionForEdits,
        expectedItemsVersionForEdits,
        refetchTenantProfile,
        parentQuerySlug,
        refetchEditableCollection,
        marketplaceStatus,
        isInternal,
        tenantSlug,
        aboutInfo,
        isPreviewMode,
        setIsPreviewMode,
        defaultCatalogImage,
        defaultFeaturedImage,
        getNestedCollectionsStatus,
        nestedCollections,
        rootProfileType
    } = useContext(RootCollectionContext);

    const { enqueueSnackbar } = useSnackbar();
    const hideDescription = useMediaQuery(defaultMuiTheme.breakpoints.down('md'));

    const enrollSectionRef = useRef<HTMLDivElement>(null);
    const isEnrollSectionVisible = useIsElementVisible(enrollSectionRef);
    const showStickyActions = !isEditMode && isEnrollSectionVisible !== undefined ? !isEnrollSectionVisible : false;

    const { mutate: commitEdits } = useCommitEdits(
        setPendingChanges,
        refetchEditableCollection,
        refetchTenantProfile,
        parentQuerySlug
    );
    const { mutate: discardEdits } = useDiscardEdits(setPendingChanges, refetchEditableCollection, parentQuerySlug);

    const canPublishChanges = useMemo(
        () => authorizedActions.includes(CollectionActions.CommitEdits),
        [authorizedActions]
    );

    const canEdit = useMemo(() => authorizedActions.includes(CollectionActions.UpdateMetadata), [authorizedActions]);

    const onPublishChanges = () => {
        if (!canPublishChanges) {
            enqueueSnackbar('Insufficient permissions to Publish Changes. Contact an Organization Admin.', {
                variant: 'warning',
                preventDuplicate: true
            });
            return;
        }
        if (collectionId && expectedInfoVersionForEdits !== undefined && expectedItemsVersionForEdits !== undefined) {
            commitEdits({
                collectionId,
                expectedInfoVersion: expectedInfoVersionForEdits,
                expectedItemsVersion: expectedItemsVersionForEdits
            });
        }
    };

    const onDiscardChanges = () => {
        if (!canPublishChanges) {
            enqueueSnackbar('Insufficient permissions to Discard Changes. Contact an Organization Admin.', {
                variant: 'warning',
                preventDuplicate: true
            });
            return;
        }
        if (collectionId && expectedInfoVersionForEdits !== undefined && expectedItemsVersionForEdits !== undefined) {
            discardEdits({
                collectionId,
                expectedInfoVersion: expectedInfoVersionForEdits,
                expectedItemsVersion: expectedItemsVersionForEdits
            });
        }
    };

    /** -> Rely on Collection auth until we figure out how to get `productId` here.
    const canEditPrice = useMemo(
        () => marketplaceLDFlag && hasRole(OrganizationRolesTypes.SuperAdmin) && !isInternal && isEditMode,
        [marketplaceLDFlag, hasRole, isEditMode, isInternal]
    );
     */

    if (getTenantProfileIsLoading) return <CollectionLoader hideCollaborators hideOwnerChip />;

    return (
        <ScheduleProvider>
            <BookshelvesProvider shouldFetchOnInitialRender={false} initialModalView={ModalView.AddExisting}>
                <CollectionImageProvider
                    images={images}
                    defaultCatalogImage={defaultCatalogImage}
                    defaultFeaturedImage={defaultFeaturedImage}
                    collectionId={collectionId}
                    parentQuerySlug={parentQuerySlug}
                    refetchEditableCollection={refetchEditableCollection}
                    collectionIdForEditableCollectionQuery={collectionIdForEditableCollectionQuery}
                >
                    <Container id="profile-tenant-collection">
                        <StickyCollectionActions visible={showStickyActions}>
                            <CollectionActionWrapper isStickyMode />
                        </StickyCollectionActions>
                        {isEditMode || isPreviewMode ? (
                            <EditCollectionHeader
                                isVisible
                                aboutInfo={aboutInfo}
                                rootType={isInternal ? CollectionType.INTERNAL : CollectionType.CATALOG}
                                collectionId={collectionIdForEditableCollectionQuery}
                                isEditMode={isEditMode}
                                isPreviewMode={isPreviewMode}
                                setIsEditMode={setIsEditMode}
                                setIsPreviewMode={setIsPreviewMode}
                                canEdit={canEdit}
                                isListed={marketplaceStatus === MarketplaceListingStatus.listed}
                                canEditPrice={canEdit}
                            />
                        ) : null}
                        <PendingChangesAlert
                            showAlert={isEditMode && pendingChanges}
                            onDiscardChanges={onDiscardChanges}
                            onPublishChanges={onPublishChanges}
                        />
                        <CollectionTopContainer>
                            <CollectionAboutContainer>
                                <AboutSection
                                    enrollSectionRef={enrollSectionRef}
                                    canEdit={canEdit}
                                    setIsEditMode={setIsEditMode}
                                />
                            </CollectionAboutContainer>
                            <CollectionBackgroundImage />
                        </CollectionTopContainer>
                        <CollectionBottomContainer id={NESTED_COLLECTION_CONTENT_ID}>
                            {hideDescription ? null : (
                                <Description
                                    engineMode={
                                        isEditMode || isPreviewMode
                                            ? ComponentEngineMode.Edit
                                            : ComponentEngineMode.View
                                    }
                                    refetchEditableCollection={refetchEditableCollection}
                                    aboutInfo={aboutInfo}
                                    collectionId={collectionId}
                                    isEditMode={isEditMode}
                                    collectionIdForEditableCollectionQuery={collectionIdForEditableCollectionQuery}
                                    parentQuerySlug={parentQuerySlug}
                                />
                            )}
                            <CollectionTabs
                                engineMode={
                                    isEditMode || isPreviewMode ? ComponentEngineMode.Edit : ComponentEngineMode.View
                                }
                                aboutInfo={aboutInfo}
                                rootProfileType={rootProfileType}
                                isNestedCollection={false}
                                childrenBasicDetails={nestedCollections}
                                getChildrenBasicDetailsStatus={getNestedCollectionsStatus}
                                isEditMode={isEditMode}
                                tenantSlug={tenantSlug}
                                collectionId={collectionId}
                                refetchEditableCollection={refetchEditableCollection}
                                parentQuerySlug={parentQuerySlug}
                                collectionIdForEditableCollectionQuery={collectionIdForEditableCollectionQuery}
                            />
                        </CollectionBottomContainer>
                    </Container>
                </CollectionImageProvider>
            </BookshelvesProvider>
        </ScheduleProvider>
    );
};

export default RootProfileCollection;
