import { RichTextComponentValuesBody } from '@adept-at/lib-react-components';
import { DefaultCollectionImageType, Image } from 'components/ContentContext/Interfaces';
import gql from 'graphql-tag';

export const UPDATE_COLLECTION_META = gql`
    mutation updateCollectionMetadata($input: UpdateCollectionMetadataInput!) {
        updateCollectionMetadata(input: $input) {
            infoVersion
            itemsVersion
        }
    }
`;

export interface UpdateCollectionMetaResult {
    updateCollectionMetadata: { infoVersion: number; itemsVersion: number };
}

export interface UpdateCollectionMetaInput {
    input: {
        collectionId: string;
        title?: string;
        titleSub?: string;
        description?: RichTextComponentValuesBody;
        tags?: string[];
        defaultCatalogImageKey?: DefaultCollectionImageType;
        defaultFeaturedImageKey?: DefaultCollectionImageType;
        images?: {
            catalog: string | null;
            catalogImages?: Image[] | null;
            featured: string | null;
            featuredImages?: Image[] | null;
        };
    };
}
