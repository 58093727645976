import * as yup from 'yup';

import { MessageEventType } from '../..';
import AckableAdepteductMessage from '../../../base/AckableAdepteductMessage';
import PossibleEventFields from '../../PossibleEventFields';

export interface EventFieldsSessionSkillChange extends PossibleEventFields {
    sessionId: string;
    collectionId: string;
    skillId: string;
}

export default class ChannelMeetingSessionSkillChange extends AckableAdepteductMessage<EventFieldsSessionSkillChange> {
    schema = yup.object({
        sessionId: yup.string(),
        collectionId: yup.string(),
        skillId: yup.string()
    });

    type = MessageEventType.ChannelMeetingSessionSkillChange;
}
