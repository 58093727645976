import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { LearningMode } from '@adept-at/lib-react-components';
import { mdiClipboardCheckOutline } from '@mdi/js';
import { useResetSkillProgress } from 'components/learn/useProgress/useResetSkillProgress';
import { useModal } from 'hooks/useModal';

import { LearnContext } from '../../LearnContext';
import { mapComponentIdToQuestion } from '../accountability/utils';
import EnableModeModal from '../EnableModeModal';

import useAnswerQuestionBatch from './useAnswerQuestionBatch';
import { ValidatedAnswer } from './useAnswerQuestionBatch/makeAnswerQuestionBatch';

interface AssessmentContextInterface {
    skillHasQuestions: boolean;
    assessmentModeOn: boolean;
    handleModalOpen: () => void;
    disableAssessmentMode: () => void;
    setAnswers: React.Dispatch<React.SetStateAction<Record<string, any>>>;
    submitAssessment: () => void;
    submitAssessmentLoading: boolean;
    results: ValidatedAnswer[];
    numberOfValidAnswers: number;
    numberOfQuestions: number;
    attempt: number;
    score: number;
    passedAssessment: boolean;
}

const PASS_ASSESSMENT_THRESHOLD = 70;

const AssessmentContext = createContext({} as AssessmentContextInterface);

const { Provider } = AssessmentContext;

const AssessmentProvider: React.FC = ({ children }) => {
    const { skill, tenantSlug, skillSlug } = useContext(LearnContext);
    const [assessmentModeOn, setAssessmentModeOn] = useState(false);
    const [answers, setAnswers] = useState<Record<string, any>>({});
    const [attempt, setAttempt] = useState(0);
    const [results, setResults] = useState<ValidatedAnswer[]>([]);
    const { mutate: answerQuestionBatch, isLoading, data, isSuccess } = useAnswerQuestionBatch();
    const { modalOpen, handleModalOpen, handleModalClose } = useModal();
    const { mutate: resetSkillProgress } = useResetSkillProgress(tenantSlug, skillSlug);

    const enableAssessmentMode = () => {
        setAssessmentModeOn(true);
        resetState();
        handleModalClose();
    };

    const resetState = () => {
        setAttempt(0);
        setResults([]);
        setAnswers({});
        resetSkillProgress({ skillId: skill.skillId });
    };

    const disableAssessmentMode = useCallback(() => setAssessmentModeOn(false), []);

    const numberOfQuestions = useMemo(() => {
        const componentIdToQuestion = mapComponentIdToQuestion(Object.values(skill.components ?? {})[0]);
        return Object.keys(componentIdToQuestion).length;
    }, [skill.components]);

    const skillHasQuestions = numberOfQuestions > 0;

    const numberOfValidAnswers = useMemo(() => results.filter((result) => result.isValid).length, [results]);
    const score = useMemo(
        () => Math.round((numberOfValidAnswers / numberOfQuestions) * 100),
        [numberOfValidAnswers, numberOfQuestions]
    );
    const passedAssessment = useMemo(() => score >= PASS_ASSESSMENT_THRESHOLD, [score]);

    const submitAssessment = () => {
        const answerSubmission = {
            orgId: skill.organizationId,
            skillId: skill.skillId,
            questionAnswers: answers,
            attempt
        };
        answerQuestionBatch({ answerSubmission });
    };

    useEffect(() => {
        if (isSuccess) {
            setAttempt((prev) => prev + 1);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (data?.answerQuestionBatch) {
            setResults(data.answerQuestionBatch);
        }
    }, [data]);

    return (
        <Provider
            value={{
                skillHasQuestions,
                assessmentModeOn,
                handleModalOpen,
                disableAssessmentMode,
                setAnswers,
                submitAssessment,
                submitAssessmentLoading: isLoading,
                results,
                numberOfValidAnswers,
                numberOfQuestions,
                attempt,
                score,
                passedAssessment
            }}
        >
            {children}
            <EnableModeModal
                open={modalOpen}
                onClose={handleModalClose}
                onConfirm={enableAssessmentMode}
                title="Assessment mode"
                iconPath={mdiClipboardCheckOutline}
                subtitle="Test your understanding"
                mode={LearningMode.ASSESSMENT}
            />
        </Provider>
    );
};

const useAssessmentContext = (): AssessmentContextInterface => {
    const context = useContext(AssessmentContext);

    if (!context) {
        throw new Error('useAssessmentContext must be used within an AssessmentProvider');
    }

    return context;
};

export { AssessmentProvider, useAssessmentContext };
