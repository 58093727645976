import { defaultMuiTheme, IconSize } from '@adept-at/lib-react-components';
import { IconButton, Tab } from '@material-ui/core';
import styled from 'styled-components';
import { SIDEBAR_WIDTH, BREAKPOINT_900PX, SUB_HEADER_HEIGHT, APP_HEADER_HEIGHT } from 'styles/variables';

import TabPanel from '../TabPanel';

export const SidebarContainer = styled.div<{ $isOpen: boolean; $overlay: boolean }>`
    flex: 0 0 ${(props) => (props.$isOpen ? SIDEBAR_WIDTH : 0)}px;
    width: ${(props) => (props.$isOpen ? SIDEBAR_WIDTH : 0)}px;
    background-color: ${(props) => props.theme.colors.surface};
    border-left: ${(props) => (props.$isOpen ? `1px solid ${props.theme.colors.border}` : 0)};
    transition: all 300ms cubic-bezier(0.2, 0, 0, 1);
    overflow: hidden;

    ${(props) =>
        props.$overlay
            ? `
            position: absolute;
            top: 0;
            right: 0;
            z-index: 3;
            height: 100vh;
            height: -webkit-fill-available;
            box-shadow: 0 0 45px -2px rgba(0, 0, 0, 0.2);
            clip-path: inset(0 -45px);
            `
            : null}

    ${defaultMuiTheme.breakpoints.down(BREAKPOINT_900PX)} {
        width: ${(props) => (props.$isOpen ? '100%' : '0px')};
    }
`;

export const Header = styled.div`
    height: ${SUB_HEADER_HEIGHT}px;
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
    display: flex;
    align-items: center;
`;

export const StyledTab = styled(Tab)`
    min-width: 150px;

    ${defaultMuiTheme.breakpoints.down('xs')} {
        min-width: 0;
    }
`;

export const StyledIconCloseButton = styled(IconButton)`
    color: ${(props) => props.theme.colors.primary};
    margin-left: auto;
    margin-right: 0.5rem;

    svg {
        width: ${IconSize.Medium};
    }
`;

export const StyledTabPanel = styled(TabPanel)<{ padding?: string }>`
    height: calc(100vh - ${APP_HEADER_HEIGHT}px - ${SUB_HEADER_HEIGHT}px);
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    display: ${(props) => (props.value === props.index ? 'grid' : 'none')};

    grid-gap: ${(props) => props?.padding ?? '1rem'};

    grid-template-columns: auto minmax(auto, 700px) auto;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
        'tab-panel-header tab-panel-header tab-panel-header'
        'left-gutter tab-panel-content right-gutter';

    background-color: ${(props) => props.theme.colors.bottomSurface};
`;

export const TabContent = styled.div`
    grid-area: tab-panel-content;
`;
