import React, { useContext, useEffect, useMemo } from 'react';

import { OrgMember } from 'components/RootProfileCollection/Views/OrgMember/index';
import { OrgOutsider } from 'components/RootProfileCollection/Views/OrgOutsider';
import { ProfileContext, ProfileType } from 'context/ProfileContext';
import { useLocation, useParams } from 'react-router';

const OrgHomePage: React.FC = () => {
    const { selectProfile, currentProfile } = useContext(ProfileContext);
    const { currentOrgSlug: orgSlugFromParams } = useParams<{ currentOrgSlug: string }>();

    const { pathname } = useLocation();
    const isInternal = useMemo(() => pathname.includes(`${orgSlugFromParams}/internal`), [orgSlugFromParams, pathname]);

    useEffect(() => {
        if (orgSlugFromParams !== currentProfile?.alias) {
            selectProfile({ type: ProfileType.Organization, id: '', alias: orgSlugFromParams });
        }
    }, [orgSlugFromParams, currentProfile]);

    return currentProfile && currentProfile.type === ProfileType.Organization ? (
        <OrgMember orgSlug={orgSlugFromParams} isInternal={isInternal} organizationId={currentProfile.id} />
    ) : (
        <OrgOutsider orgSlug={orgSlugFromParams} />
    );
};

export default OrgHomePage;
