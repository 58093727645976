import React from 'react';

import { LinkComponentValues } from '@adept-at/lib-react-components';

import { useComponentEngine } from '../../EngineContext';
import { EditableComponentProvider } from '../../mixins/editor';
import { ComponentEngineMode } from '../../types';

import { LinkEdit } from './Edit';
import { LinkView } from './View';

export const Link: React.FC<LinkComponentValues> = ({ ...componentValues }) => {
    const { mode, container } = useComponentEngine();

    if (mode === ComponentEngineMode.Edit) {
        return (
            <EditableComponentProvider initialValues={componentValues}>
                <LinkEdit />
            </EditableComponentProvider>
        );
    }

    return <LinkView componentValues={componentValues} container={container} />;
};

export const title = 'Link';

export const Component = Link;
