import React, { useContext, useMemo } from 'react';

import { DotList, IconSize } from '@adept-at/lib-react-components';
import { BookshelvesContext } from 'components/bookshelves/BookshelvesContext';
import { BookshelfMinimal } from 'components/bookshelves/hooks/getItemIdsInBookshelves';
import { useUpsertBookshelfItem } from 'components/bookshelves/hooks/useUpsertBookshelfItem';
import { TEMP_BOOKSHELF_ID } from 'components/bookshelves/utils';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useSnackbar } from 'notistack';
import pluralize from 'pluralize';

import { StyledListItem, StyledCircularProgress, StyledSuccessIcon } from './styles';

interface BookshelfListItemProps {
    bookshelf: BookshelfMinimal;
}

const BookshelfListItem: React.FC<BookshelfListItemProps> = ({ bookshelf }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { selectedItemToAdd } = useContext(BookshelvesContext);

    const { mutate, isLoading, status } = useUpsertBookshelfItem(bookshelf.title, {
        onError: () => {
            enqueueSnackbar('An error occurred. Unable to add item to bookshelf', { variant: 'error' });
        },
        onSuccess: () => {
            enqueueSnackbar(`${selectedItemToAdd?.title} added to ${bookshelf.title}`, { variant: 'success' });
        }
    });

    const { currentUser } = useCurrentUser();
    const assignedBookshelfId = currentUser?.userId;
    const isAssignedBookshelf = useMemo(
        () => bookshelf.bookshelfId === assignedBookshelfId,
        [bookshelf.bookshelfId, assignedBookshelfId]
    );

    if (!selectedItemToAdd || isAssignedBookshelf) {
        return null;
    }

    const isInBookshelf = bookshelf?.items?.some((item) => item?.id === selectedItemToAdd?.id);
    const isCreatingBookshelf = bookshelf.bookshelfId === TEMP_BOOKSHELF_ID;

    const returnStatusIcon = () => {
        if (isCreatingBookshelf || isLoading) {
            return <StyledCircularProgress color="secondary" size={IconSize.Small} />;
        }

        if (isInBookshelf || status === 'success') {
            return <StyledSuccessIcon />;
        }

        return null;
    };

    return (
        <StyledListItem
            button
            disabled={isCreatingBookshelf}
            key={bookshelf.bookshelfId}
            onClick={() => {
                if (isInBookshelf) {
                    return;
                }

                mutate({
                    bookshelfId: bookshelf.bookshelfId,
                    itemId: selectedItemToAdd.id,
                    itemType: selectedItemToAdd?.type
                });
            }}
        >
            <h4>{bookshelf.title}</h4>
            <DotList>
                {[
                    // todo - bookshelf time roll ups
                    // '1hr 24mins',
                    pluralize('item', bookshelf?.items?.length, true)
                ]}
            </DotList>
            {returnStatusIcon()}
        </StyledListItem>
    );
};

export default BookshelfListItem;
