import { useContext, useEffect } from 'react';

import { OptimistMutationContext, useOptimist } from '@adept-at/lib-react-optimist';
import { OwnerInfo } from 'components/ContentContext/Interfaces';
import { buildGetSkillBySlugQueryKey, GetSkillBySlugResponse } from 'components/learn/getSkillBySlug';
import { LearnContext } from 'components/learn/LearnContext';
import {
    GetSkillByIdResponse,
    buildQueryKey as buildGetSkillByIdQueryKey
} from 'components/skills/hooks/useGetSkillById';
import { ClientError, gql } from 'graphql-request';
import { useBuildAuthenticatedMutationFn } from 'hooks/useGqlClient/useBuildAuthenticatedMutationFn';
import { Api } from 'lib/ApiConstants';
import { useSnackbar } from 'notistack';
import { InfiniteData, useMutation, UseMutationResult } from 'react-query';
import { setSkillEditedInSessionStorage } from 'utils/skill/editedSkills';

import {
    buildGetCollaborationsByOwnerQueryKey,
    GetCollaborationsByOwnerResponse
} from '../../CollaborationTable/getCollaborationsByOwner';
import { WorkflowStatus } from '../../CollaborationTable/utils';
import { buildGetStudioSkillQueryKey, GetStudioSkillResponse } from '../skill/useGetStudioSkill';

import { useOptimistForEntityWorkflowUpdates } from './useOptimistForEntityWorkflowUpdates';

const UPDATE_SKILL_WORKFLOW = gql`
    mutation updateSkillMeta($skillId: UUIDv4!, $workflow: WorkflowStatus) {
        updateSkillMeta(skillId: $skillId, workflow: $workflow) {
            success
        }
    }
`;

export interface UpdateSkillWorkflowResult {
    updateSkillMeta: {
        success: boolean;
    };
}

export interface UpdateSkillWorkflowProps {
    skillId: string;
    workflow: keyof typeof WorkflowStatus;
}

export const useUpdateSkillWorkflow = (
    owner: OwnerInfo,
    skillId: string
): UseMutationResult<UpdateSkillWorkflowResult, ClientError, UpdateSkillWorkflowProps, OptimistMutationContext> => {
    const { enqueueSnackbar } = useSnackbar();
    const { skillSlug, tenantSlug } = useContext(LearnContext) ?? {};
    const { registerUpdater, mutationOptions } = useOptimist<
        UpdateSkillWorkflowResult,
        ClientError,
        UpdateSkillWorkflowProps
    >({ globalMutationOptions: { onSettled: () => setSkillEditedInSessionStorage(skillId, tenantSlug, skillSlug) } });

    registerUpdater<InfiniteData<GetCollaborationsByOwnerResponse>>(
        buildGetCollaborationsByOwnerQueryKey(owner),
        (previous, args) => {
            const updatedPages = (previous?.pages ?? []).map((page) => {
                return {
                    ...page,
                    getCollaborationsByOwner: {
                        ...page?.getCollaborationsByOwner,
                        items: (page?.getCollaborationsByOwner?.items ?? []).map((item) =>
                            'skillId' in item.item && item.item.skillId === args.skillId
                                ? { ...item, item: { ...item.item, workflow: args.workflow } }
                                : item
                        )
                    }
                };
            });

            return {
                ...previous,
                pages: updatedPages
            };
        },
        {
            refetchQuery: false,
            onError: () => {
                enqueueSnackbar('An error occurred. Unable to update workflow status', { variant: 'error' });
            }
        }
    );

    useOptimistForEntityWorkflowUpdates<{
        props: UpdateSkillWorkflowProps;
        result: UpdateSkillWorkflowResult;
    }>(registerUpdater);

    registerUpdater<GetStudioSkillResponse>(
        buildGetStudioSkillQueryKey(skillId),
        (previous, args) => {
            return {
                ...previous,
                getSkillById: {
                    ...previous?.getSkillById,
                    workflow: args.workflow
                }
            };
        },
        { refetchQuery: false }
    );

    registerUpdater<GetSkillByIdResponse>(
        buildGetSkillByIdQueryKey(skillId),
        (previous, args) => {
            return {
                ...previous,
                getSkillById: {
                    ...previous?.getSkillById,
                    workflow: args.workflow
                }
            };
        },
        { refetchQuery: false }
    );

    useEffect(() => {
        if (skillSlug && tenantSlug) {
            registerUpdater<GetSkillBySlugResponse>(
                buildGetSkillBySlugQueryKey(tenantSlug, skillSlug),
                (previous, args) => {
                    return {
                        ...previous,
                        getEntityBySlug: {
                            ...previous?.getEntityBySlug,
                            workflow: args.workflow
                        }
                    };
                },
                { refetchQuery: false }
            );
        }
    }, [skillSlug, tenantSlug, registerUpdater]);

    const { mutationFn } = useBuildAuthenticatedMutationFn<UpdateSkillWorkflowResult, UpdateSkillWorkflowProps>(
        UPDATE_SKILL_WORKFLOW,
        { api: Api.Content }
    );

    return useMutation<UpdateSkillWorkflowResult, ClientError, UpdateSkillWorkflowProps, OptimistMutationContext>(
        mutationFn,
        mutationOptions
    );
};
