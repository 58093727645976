import { useContext, useEffect, useMemo } from 'react';

import { useGetCollectionsBySlugs } from 'components/learn/useGetCollectionsBySlugs';
import { useGetTenantProfileBySlug } from 'components/learn/useGetTenantBySlug';
import { ProfileContext, ProfileOption } from 'context/ProfileContext';
import { useParams, useLocation } from 'react-router';

import { DestinationOption, destinationOptions } from './CreateSkillModal';
import { CollectionForTenantProfile } from './useGetCollectionsForTenantProfile';

interface UseDynamicCreateSkillOptionsInterface {
    setSelectedOwner: React.Dispatch<React.SetStateAction<ProfileOption | undefined>>;
    setDestination: React.Dispatch<React.SetStateAction<DestinationOption | null>>;
    setCollections: React.Dispatch<React.SetStateAction<CollectionForTenantProfile[]>>;
    collectionOptions: CollectionForTenantProfile[];
}

export const useDynamicCreateSkillOptions = ({
    setSelectedOwner,
    setDestination,
    setCollections,
    collectionOptions
}: UseDynamicCreateSkillOptionsInterface): void => {
    const { currentProfile } = useContext(ProfileContext);
    useEffect(() => {
        if (currentProfile) {
            setSelectedOwner(currentProfile);
        }
    }, [currentProfile, setSelectedOwner]);

    const {
        tenantSlug,
        currentOrgSlug,
        collectionSlugs = ''
    } = useParams<{ tenantSlug?: string; currentOrgSlug?: string; collectionSlugs?: string }>();
    const { pathname } = useLocation();

    const collectionSlugsArray = useMemo(() => collectionSlugs.split('/'), [collectionSlugs]);
    const { data: collectionsBySlugData } = useGetCollectionsBySlugs(
        { tenantSlug: tenantSlug ?? '', collectionSlugs: collectionSlugsArray },
        collectionSlugsArray.length > 0 && !!tenantSlug
    );

    const { data: tenantBySlugData } = useGetTenantProfileBySlug(
        { slug: tenantSlug ?? '' },
        collectionSlugsArray.length > 0 && !!tenantSlug
    );

    const collections = useMemo(
        () => collectionsBySlugData?.getCollectionBasicDetailsBySlugs ?? [],
        [collectionsBySlugData]
    );

    const dynamicDestination = useMemo(() => {
        if (currentOrgSlug) {
            return pathname.includes(`${currentOrgSlug}/internal`) ? destinationOptions[0] : destinationOptions[1];
        }
        if (tenantBySlugData?.getTenantProfileBySlug) {
            return tenantBySlugData?.getTenantProfileBySlug?.privateRootCollection?.collectionId ===
                collections[0]?.rootCollectionId
                ? destinationOptions[0]
                : destinationOptions[1];
        }
        return null;
    }, [currentOrgSlug, pathname, collections, tenantBySlugData?.getTenantProfileBySlug]);

    useEffect(() => {
        setDestination(dynamicDestination);
    }, [dynamicDestination, setDestination]);

    const currentCollectionSlug = collectionSlugsArray[collectionSlugsArray.length - 1];
    useEffect(() => {
        const collections = collectionOptions.filter(
            (collection) => collection.collectionSlug === currentCollectionSlug
        );
        setCollections(collections);
    }, [currentCollectionSlug, setCollections, collectionOptions]);
};
