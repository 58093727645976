import AdepteductMessage from 'lib/communication/message/base/AdepteductMessage';

import { sortMessagesBySentAtDesc } from '../../../utils/communication';

import { HistoricalMessagesReducerState, HistoricalMessagesReducerParams } from '.';

export interface HistoricalMessagesAppendParams {
    channelId: string;
    messages: AdepteductMessage[];
}

/**
 * We use append as opposed to replace for performance reasons, due to message class validation.
 * There is no downside to this, as the API calls being made are not re-querying pages of the historical messages, so they never change.
 */
const handleMessagesAppend = (
    state: HistoricalMessagesReducerState,
    { channelId, messages }: Omit<HistoricalMessagesReducerParams, 'action'>
): HistoricalMessagesReducerState => {
    if (!channelId || !messages) {
        return state;
    }

    const existingMessages = state?.[channelId] ?? [];
    const existingMessageIds = new Set(existingMessages?.map((message) => message.id));
    const newMessages = messages.filter((stateChannelMessage) => !existingMessageIds.has(stateChannelMessage.id));

    // Incoming messages will be oldest, putting them in back improves sort performance for desc
    const updatedChannelMessages = [...existingMessages, ...newMessages].sort(sortMessagesBySentAtDesc);

    return {
        ...state,
        [channelId]: updatedChannelMessages
    };
};

export default handleMessagesAppend;
