import React, { useCallback, useContext } from 'react';

import { CollectionStats, Collaborators } from '@adept-at/lib-react-components';
import { mdiPencil, mdiPencilOff } from '@mdi/js';
import Icon from '@mdi/react';
import { OwnerInfo } from 'components/builder/context/interfaces';
import { CollectionType } from 'components/modals/Skills/useGetCollectionsForTenantProfile';
import { useCreateProductSaga } from 'components/product/useCreateProductSaga';
import { NESTED_COLLECTION_CONTENT_ID } from 'components/RootProfileCollection/constants';
import { useUpdateCollectionVisibility } from 'components/RootProfileCollection/useUpdateCollectionVisibility';
import { useModal } from 'hooks/useModal';
import { useOrganizationTerminology } from 'hooks/useOrganizationTerminology';
import { isTrue } from 'utils/isTrue';

import { AboutHeadings } from './AboutHeadings';
import { WorkflowActionsMenu, EditCollectionImageActions } from './ActionMenus';
import { AvailabilityModal } from './availability/AvailabilityModal';
import { CollectionActionWrapper } from './CollectionActions';
import { CollectionCatalogImage } from './CollectionCatalogImage';
import { useCollectionImageContext } from './context/CollectionImageContext';
import { NestedCollectionContext } from './context/NestedCollectionContext';
import {
    NestedCollectionAboutSection,
    AvatarHeadingContainer,
    ExtraInfoOuterContainer,
    ExtraInfoInnerContainer,
    ExtraInfoItemContainer,
    EditCollectionButton
} from './NestedCollection.styles';
import { PriceButton } from './PriceButton';

interface AboutSectionProps {
    entity: {
        owner: OwnerInfo;
    };
    enrollSectionRef?: React.RefObject<HTMLDivElement>;
    setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
    canEdit: boolean;
}

export const AboutSection: React.FC<AboutSectionProps> = ({ entity, enrollSectionRef, setIsEditMode, canEdit }) => {
    const {
        isEditMode,
        isPreviewMode,
        stats,
        createdAt,
        aboutInfo,
        estimatedSecondsToConsume,
        isVisible,
        collectionIdForEditableCollectionQuery,
        authorsDetails,
        rootType,
        collectionId,
        immutableCollectionId,
        tenantProfileData,
        ownerInfo,
        tenantSlug,
        collectionSlug,
        parentQuerySlug,
        collectionData
    } = useContext(NestedCollectionContext);

    const { handleRemoveCatalogDarkImage } = useCollectionImageContext();

    const { productInProgress, createProduct } = useCreateProductSaga({
        ownerInfo,
        immutableCollectionId,
        productName: tenantSlug + '/' + collectionSlug,
        productId: collectionData?.productDetails?.productId ?? null,
        entitySlug: parentQuerySlug
    });

    const { terminology } = useOrganizationTerminology();
    const toggleEditMode = () => setIsEditMode((prevState) => !prevState);

    const { mutate: updateCollectionVisibility } = useUpdateCollectionVisibility(
        collectionIdForEditableCollectionQuery
    );

    const onConfirmEnrollment = ({ visible, price, enrollment }) => {
        //visibility need immutable collectionId regardless of editmode
        if (visible !== isVisible && immutableCollectionId) {
            updateCollectionVisibility({ collectionId: immutableCollectionId, visible });
        }
        if (enrollment && price && tenantProfileData) {
            createProduct(price, tenantProfileData.getTenantProfileForOwner.tenantProfileId);
        }
        handlePriceModalClose();
    };

    const collectionEnrollmentDetails = collectionData
        ? {
              collectionId: collectionData.collectionId,
              children: collectionData.children,
              productDetails: collectionData.productDetails,
              itemsVersion: collectionData.itemsVersion
          }
        : null;

    const {
        modalOpen: priceModalOpen,
        handleModalOpen: handlePriceModalOpen,
        handleModalClose: handlePriceModalClose
    } = useModal();

    const handleStatClick = useCallback(() => {
        const contentAnchor = document.getElementById(NESTED_COLLECTION_CONTENT_ID);
        if (contentAnchor) {
            contentAnchor.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    return (
        <NestedCollectionAboutSection id="collection-about-info">
            <AvatarHeadingContainer>
                <CollectionCatalogImage />
                <EditCollectionImageActions
                    isEditMode={isEditMode}
                    collectionTitle={aboutInfo?.title ?? ''}
                    handleRemoveDarkImage={handleRemoveCatalogDarkImage}
                />
                <AboutHeadings
                    aboutInfo={aboutInfo}
                    collectionId={collectionId}
                    isEditMode={isEditMode}
                    collectionIdForEditableCollectionQuery={collectionIdForEditableCollectionQuery}
                    parentQuerySlug={parentQuerySlug}
                />
            </AvatarHeadingContainer>
            <Collaborators
                collaborators={authorsDetails}
                createdAt={createdAt}
                estimatedSecondsToConsume={estimatedSecondsToConsume}
                parentTitle={aboutInfo?.title}
            />
            {isPreviewMode || !isEditMode ? <CollectionActionWrapper ref={enrollSectionRef} /> : null}
            <ExtraInfoOuterContainer>
                <ExtraInfoInnerContainer>
                    <ExtraInfoItemContainer>
                        {isPreviewMode || !isEditMode ? null : (
                            <>
                                <PriceButton
                                    isEditMode
                                    isVisible={isVisible}
                                    onClick={handlePriceModalOpen}
                                    productExists={isTrue(collectionData?.productDetails?.productId)}
                                    productInProgress={productInProgress}
                                />
                                <WorkflowActionsMenu owner={{ ...entity.owner }} />
                            </>
                        )}
                        <AvailabilityModal
                            contentTitle={aboutInfo?.title ?? ''}
                            open={priceModalOpen}
                            onClose={handlePriceModalClose}
                            isVisible={isVisible}
                            onConfirm={onConfirmEnrollment}
                            isInternal={rootType === CollectionType.INTERNAL}
                            collectionDetails={collectionEnrollmentDetails}
                        />
                    </ExtraInfoItemContainer>
                    <ExtraInfoItemContainer>
                        {!isPreviewMode && canEdit ? (
                            <EditCollectionButton onClick={toggleEditMode}>
                                <Icon path={isEditMode ? mdiPencilOff : mdiPencil} />
                                {isEditMode ? 'Stop editing' : 'Edit'}
                            </EditCollectionButton>
                        ) : null}
                    </ExtraInfoItemContainer>
                </ExtraInfoInnerContainer>
            </ExtraInfoOuterContainer>

            <CollectionStats stats={stats ?? []} onClick={handleStatClick} labelOverrides={{ ...terminology }} />
        </NestedCollectionAboutSection>
    );
};
