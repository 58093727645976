import React from 'react';

import SubHeader from 'components/SubHeader';
import styled from 'styled-components';

import { FavoritesProgressFilter, FavoritesSortBy } from './constants';
import { FavoritesProgressFilters } from './FavoriteProgressFilters';
import { FavoritesLayoutViewButtons } from './FavoritesLayoutViewButtons';

const SubHeaderStickyWrapper = styled.div`
    position: sticky;
    top: 0;
    z-index: 3; // the cards have z-index of 1, the overlay when sidebar is open has z-index of 10 - should sit between cards and overlay
`;

interface FavoritesSubheaderProps {
    isGridView: boolean;
    setIsGridView: React.Dispatch<React.SetStateAction<boolean>>;
    progressFilter: FavoritesProgressFilter;
    setProgressFilter: React.Dispatch<React.SetStateAction<FavoritesProgressFilter>>;
    search: string;
    setSearch: React.Dispatch<React.SetStateAction<string>>;
    sortBy: FavoritesSortBy;
    setSortBy: React.Dispatch<React.SetStateAction<FavoritesSortBy>>;
}

export const FavoritesSubheader: React.FC<FavoritesSubheaderProps> = ({
    isGridView,
    setIsGridView,
    progressFilter,
    setProgressFilter,
    search,
    setSearch,
    sortBy,
    setSortBy
}) => {
    return (
        <SubHeaderStickyWrapper>
            <SubHeader>
                <FavoritesProgressFilters activeFilter={progressFilter} setActiveFilter={setProgressFilter} />
                <FavoritesLayoutViewButtons
                    isGridView={isGridView}
                    setIsGridView={setIsGridView}
                    search={search}
                    setSearch={setSearch}
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                />
            </SubHeader>
        </SubHeaderStickyWrapper>
    );
};
