import React from 'react';

import { QuestionComponentValues } from '@adept-at/lib-react-components';

import { useComponentEngine } from '../..';

import { getViewForContainer } from './QuestionTypes';

const QuestionView: React.FC<QuestionComponentValues> = (component) => {
    const { container } = useComponentEngine();
    const QuestionViewForContainer = getViewForContainer(container.type);
    return <QuestionViewForContainer component={component} />;
};

export default QuestionView;
