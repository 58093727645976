import React, { useMemo, useState } from 'react';

import { IconSize, TooltippedIconButton } from '@adept-at/lib-react-components';
import { Fade, Backdrop, Select, MenuItem } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { mdiInformationOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import Visa from 'components/icons/Visa';
import { EntityBySlug } from 'components/NestedCollection/context/getNestedCollectionBySlug';
import { ContentAccessSeat, EnrollmentInterval } from 'components/product/types';
import { isTrue } from 'utils/isTrue';

import ModalCloseIcon from '../ModalCloseIcon';

import { SkillSelection } from './SkillSelector';
import { PaymentRadios } from './Slider/PaymentRadios';
import { calculateConsumptionValue, PaymentSlider } from './Slider/PaymentSlider';
import {
    Buttons,
    ContentImage,
    ContentTitle,
    CostPerInterval,
    FormBody,
    IntervalText,
    ModalBody,
    ModalCloseContainer,
    ModalHeaderContainer,
    SelectTitle,
    Divider,
    StyledModal,
    SubmitButton,
    ModalContainer,
    CenterContainer,
    SmallVisa
} from './styles';
import { SubscriptionOption } from './SubscriptionOption';
import { useDescendantsFilter } from './useDescendantsFilter';
import { YourCost } from './YourCost';

export interface EnrollmentOptions {
    startDate: Date;
    endDate: Date;
    enrollmentIncrement: number;
    pricePerIncrement: number;
    resetSkills: string[];
}

export interface Descendant {
    id: string;
    title: string;
    estimatedTimeToConsume: number;
    completionDate?: string;
}

interface EnrollModalProps {
    isOpen: boolean;
    content: EntityBySlug | null;
    pricePerIncrement: number;
    enrollmentInterval: EnrollmentInterval;
    handleClose: () => void;
    onEnroll: (enrollmentOptions: EnrollmentOptions) => void;
    currentEnrollment: ContentAccessSeat | null;
    tenantAlias: string;
    subscriptionData: {
        cost: number;
        includedCollections: number;
    };
    descendants: Descendant[];
}

export const EnrollModal: React.FC<EnrollModalProps> = ({
    content,
    isOpen,
    onEnroll,
    handleClose,
    enrollmentInterval,
    pricePerIncrement,
    currentEnrollment = null,
    tenantAlias,
    subscriptionData,
    descendants
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [consumptionIncrement, setConsumptionIncrement] = useState<number | null>(null);

    const isFree = pricePerIncrement === 0;

    const { filter, setFilter, count, estimatedTimeToConsume } = useDescendantsFilter(descendants);

    const enrollmentIncrement = useMemo(() => {
        if (!consumptionIncrement) return null;
        //consumption per day seems to be the defacto measurement going forward. If we end up supporting other intervals,
        //we can just change consumptionIncrement to use the entire interval object from paymentMarks and include the interval there for maths purposes.
        const consumptionPacePerWeekInSeconds = calculateConsumptionValue(consumptionIncrement) * 60 * 7;
        return Math.ceil(estimatedTimeToConsume / consumptionPacePerWeekInSeconds);
    }, [estimatedTimeToConsume, consumptionIncrement]);

    const onSubmit = async () => {
        if (!enrollmentIncrement) return;
        setIsLoading(true);
        handleClose();

        const resetSkills = descendants.reduce((prev, { completionDate, id }) => {
            if (isTrue(completionDate) && !isTrue(filter[id])) {
                prev.push(id);
            }
            return prev;
        }, [] as string[]);

        await onEnroll({
            startDate: new Date(),
            enrollmentIncrement,
            endDate: new Date(),
            pricePerIncrement,
            resetSkills
        });
        setIsLoading(false);
    };

    return (
        <StyledModal
            aria-labelledby="enroll-in-content"
            aria-describedby="enroll-in-content"
            open={isOpen}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500
            }}
        >
            <Fade in={isOpen}>
                <ModalContainer style={{ maxHeight: '100%' }}>
                    <ModalCloseContainer>
                        <ModalCloseIcon
                            showCloseIcon
                            onClose={handleClose}
                            style={{ marginTop: '2.5rem', marginRight: '0.75rem' }}
                        />
                    </ModalCloseContainer>
                    <ModalBody>
                        <ModalHeaderContainer>
                            <ContentImage image={content?.images?.catalog} />
                            <ContentTitle>{content?.title}</ContentTitle>
                            <SkillSelection
                                descendants={descendants}
                                filter={filter}
                                setFilter={setFilter}
                                skillCount={count}
                                estimatedTimeToConsume={estimatedTimeToConsume}
                            />
                            <CostPerInterval>
                                <ContentTitle>{`$${pricePerIncrement} `}</ContentTitle>
                                <IntervalText>{` / ${enrollmentInterval.toUpperCase()}`}</IntervalText>
                            </CostPerInterval>
                        </ModalHeaderContainer>
                        <Divider />
                        <FormBody>
                            {!currentEnrollment ? (
                                <>
                                    <PaymentRadios
                                        {...{
                                            enrollmentIncrement,
                                            consumptionIncrement,
                                            setConsumptionIncrement
                                        }}
                                    />
                                    <PaymentSlider
                                        {...{
                                            enrollmentIncrement,
                                            enrollmentInterval,
                                            consumptionIncrement,
                                            setConsumptionIncrement
                                        }}
                                    />
                                </>
                            ) : (
                                <SelectTitle style={{ marginTop: '1rem' }}>
                                    Your current enrollment period
                                    <TooltippedIconButton
                                        title={`Your current enrollment ends at ${currentEnrollment.endsAt}`}
                                        icon={<Icon path={mdiInformationOutline} size={IconSize.Small} />}
                                    />
                                </SelectTitle>
                            )}
                        </FormBody>
                        <Divider />
                        <YourCost pricePerIncrement={pricePerIncrement} enrollmentIncrement={enrollmentIncrement} />

                        {!isFree ? (
                            <CenterContainer>
                                <SelectTitle style={{ marginBottom: '1rem', marginTop: '1rem' }}>Pay with</SelectTitle>
                                <Select
                                    fullWidth
                                    labelId="payment-selection-label"
                                    id="payment-selection"
                                    value={1}
                                    variant="outlined"
                                    label="Payment selection"
                                    startAdornment={
                                        <SmallVisa>
                                            <Visa />
                                        </SmallVisa>
                                    }
                                >
                                    <MenuItem value={1}>{'***** ***** ***** 4242'}</MenuItem>
                                </Select>
                            </CenterContainer>
                        ) : null}
                        <Buttons>
                            <SubmitButton
                                type="submit"
                                onClick={onSubmit}
                                variant="contained"
                                // temporary until we enable editing enrollment periods
                                disabled={isTrue(currentEnrollment) || !enrollmentIncrement}
                                color="primary"
                                style={{ width: '80px' }}
                            >
                                {isLoading ? <CircularProgress size={IconSize.Medium} color={'inherit'} /> : 'Enroll'}
                            </SubmitButton>
                        </Buttons>
                        {!isFree ? (
                            <CenterContainer>
                                <IntervalText>{'OR'}</IntervalText>
                                <SubscriptionOption
                                    tenantAlias={tenantAlias}
                                    subscriptionCost={subscriptionData.cost}
                                    tenantCollectionCount={subscriptionData.includedCollections}
                                    onSubscribe={() => {}}
                                />
                            </CenterContainer>
                        ) : null}
                    </ModalBody>
                </ModalContainer>
            </Fade>
        </StyledModal>
    );
};
