import { GraphQLClient } from 'graphql-request';
import gql from 'graphql-tag';
import buildGqlMutationFn from 'hooks/useGqlClient/helpers/buildGqlMutationFn';
import { MutationFunction } from 'react-query';

import { ToggleCameraResult, ToggleCameraVariables } from '.';

export const TOGGLE_CAMERA = gql`
    mutation toggleCamera($sessionId: UUIDv4!, $status: MediaStatus!) {
        toggleCamera(sessionId: $sessionId, status: $status) {
            success
        }
    }
`;

const makeToggleCamera = (client: GraphQLClient): MutationFunction<ToggleCameraResult, ToggleCameraVariables> =>
    buildGqlMutationFn(client, TOGGLE_CAMERA);

export default makeToggleCamera;
