import React from 'react';

import { IconSize, LightTheme } from '@adept-at/lib-react-components';
import { Button } from '@material-ui/core';
import { mdiCheckCircle, mdiCircleOutline, mdiCloseCircle } from '@mdi/js';
import Icon from '@mdi/react';
import { useAssessmentContext } from 'components/learn/modes/assessment/AssessmentContext';
import styled from 'styled-components';

import { CompletionPrompt } from '.';

const ScoreContainer = styled.div`
    text-align: center;
    margin-bottom: 3rem;
`;

const StatWrapper = styled.div`
    svg {
        margin-right: 8px;
    }

    display: flex;
    align-items: center;
    justify-content: center;
`;

const ScoreText = styled.p<{ $passingScore: boolean }>`
    color: ${(props) => (props.$passingScore ? props.theme.colors.success : props.theme.colors.errorText)};
`;

const AssessmentSection: React.FC<{ completeSkill: () => void }> = ({ completeSkill }) => {
    const { submitAssessmentLoading, submitAssessment, attempt, passedAssessment } = useAssessmentContext();

    return (
        <CompletionPrompt>
            {attempt > 0 ? <Results /> : null}
            <Button
                color="primary"
                variant="contained"
                disabled={submitAssessmentLoading}
                onClick={() => (passedAssessment ? completeSkill() : submitAssessment())}
            >
                {passedAssessment ? 'Complete skill' : 'Submit assessment'}
            </Button>
        </CompletionPrompt>
    );
};

const Results: React.FC = () => {
    const { numberOfQuestions, numberOfValidAnswers, results, score, passedAssessment } = useAssessmentContext();

    return (
        <ScoreContainer>
            <h3>
                You scored: <ScoreText $passingScore={passedAssessment}>{score}%</ScoreText>
            </h3>
            <StatWrapper>
                <Icon path={mdiCheckCircle} size={IconSize.Small} color={LightTheme.colors.success} />
                {numberOfValidAnswers} questions correct
            </StatWrapper>
            <StatWrapper>
                <Icon path={mdiCloseCircle} size={IconSize.Small} color={LightTheme.colors.errorText} />
                {results.length - numberOfValidAnswers} questions missed
            </StatWrapper>
            <StatWrapper>
                <Icon path={mdiCircleOutline} size={IconSize.Small} color="secondary" />
                {numberOfQuestions - results.length} questions unanswered
            </StatWrapper>
        </ScoreContainer>
    );
};

export default AssessmentSection;
