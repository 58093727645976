import { ClientError } from 'graphql-request';
import gql from 'graphql-tag';
import { useBuildAuthenticatedMutationFn } from 'hooks/useGqlClient/useBuildAuthenticatedMutationFn';
import { Api } from 'lib/ApiConstants';
import { useMutation, UseMutationResult } from 'react-query';

const UPDATE_PROGRESS_TIMESTAMP = gql`
    mutation updateProgressTimestamp($skillId: UUIDv4!) {
        updateProgressTimestamp(skillId: $skillId) {
            success
        }
    }
`;

interface UpdateProgressTimestampResponse {
    updateProgressTimestamp: {
        success: boolean;
    };
}

interface UpdateProgressTimestampVariables {
    skillId: string;
}

export const useUpdateProgressTimestamp = (): UseMutationResult<
    UpdateProgressTimestampResponse,
    ClientError,
    UpdateProgressTimestampVariables
> => {
    const { mutationFn } = useBuildAuthenticatedMutationFn<
        UpdateProgressTimestampResponse,
        UpdateProgressTimestampVariables
    >(UPDATE_PROGRESS_TIMESTAMP, {
        api: Api.Content
    });

    return useMutation<UpdateProgressTimestampResponse, ClientError, UpdateProgressTimestampVariables>(mutationFn);
};
