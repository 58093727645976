import styled from 'styled-components';

import { ImageDimensions } from '../../ResizableContainer';

export const ImageWrapper = styled.div<ImageDimensions>`
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;

    img {
        width: ${(props) => `${props.width}px` || '100%'};
        max-width: 100%;
        user-select: none;
    }
`;
