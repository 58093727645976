import { CollaborativeCollection, CollaborativeSkill } from 'components/CollaborationTable';
import {
    collaborativeCollectionFragment,
    collaborativeSkillFragment
} from 'components/CollaborationTable/getCollaborationsByOwner';
import { EntityChild } from 'components/NestedCollection/context/getNestedCollectionBySlug';
import { ClientError } from 'graphql-request';
import gql from 'graphql-tag';
import { useBuildAuthenticatedQueryFn } from 'hooks/useGqlClient/useBuildAuthenticatedQueryFn';
import { Api } from 'lib/ApiConstants';
import { QueryKey, useQuery, UseQueryResult } from 'react-query';

export const buildGetCollectionChildrenDetailsQueryKey = (children: EntityChild[]): QueryKey => [
    'getCollectionChildrenDetails',
    children
];

export interface GetCollectionChildrenDetailsResponse {
    getEntityBasicDetails: (CollaborativeSkill | CollaborativeCollection)[];
}

export const GET_COLLECTION_CHILDREN_DETAILS = gql`
    query getEntityBasicDetails($entities: [EntityInfoInput]!) {
        getEntityBasicDetails(entities: $entities) {
            ... on SkillBasicDetail {
                ${collaborativeSkillFragment}
            }
            ... on CollectionBasicDetail {
                ${collaborativeCollectionFragment}
            }
        }
    }
`;

export const useGetCollectionChildrenDetails = (
    children: EntityChild[]
): UseQueryResult<GetCollectionChildrenDetailsResponse, ClientError> => {
    const { queryFn } = useBuildAuthenticatedQueryFn<GetCollectionChildrenDetailsResponse>(
        GET_COLLECTION_CHILDREN_DETAILS,
        { entities: children },
        { api: Api.Content }
    );

    return useQuery<GetCollectionChildrenDetailsResponse, ClientError>(
        buildGetCollectionChildrenDetailsQueryKey(children),
        {
            queryFn,
            enabled: false
        }
    );
};
