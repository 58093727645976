import { useCallback, useEffect, useRef } from 'react';

import { AxiosRequestConfig } from 'axios';
import { AuthStatus, useAuth } from 'context/AuthContext';
import { useGateForRequest } from 'hooks/request/useGateForRequest';

interface UseEnsureAuthenticatedInterface {
    requestInterceptor: (config: AxiosRequestConfig) => Promise<AxiosRequestConfig>;
    onAuthError: () => void;
}

export const useEnsureAuthenticated = (label = ''): UseEnsureAuthenticatedInterface => {
    const { token, status, refreshSession } = useAuth();

    const workingToken = useRef<string | null>(token);
    const tokenToBeReplacedByRefresh = useRef<null | string>(null);

    useEffect(() => {
        workingToken.current = token;

        if (token !== tokenToBeReplacedByRefresh.current) {
            tokenToBeReplacedByRefresh.current = null;
        }
    }, [token]);

    const { ensureGateOpen: ensureHasToken } = useGateForRequest(
        // Function will be invoked and its result evaluated via a quick poll to determine if request can be performed
        {
            isReady: (): boolean =>
                workingToken.current !== null &&
                status === AuthStatus.Authenticated &&
                tokenToBeReplacedByRefresh.current === null,

            label
        }
    );

    const requestInterceptor = async (config: AxiosRequestConfig) => {
        /**
         * We will block until we have a valid token or timeout occurs.
         */
        await ensureHasToken();

        if (!workingToken.current) {
            throw new Error('Unable to retrieve a client due to no valid token');
        }

        config.headers.Authorization = `Bearer ${workingToken.current}`;

        return config;
    };

    const onAuthError = useCallback(() => {
        tokenToBeReplacedByRefresh.current = workingToken.current;

        refreshSession();
    }, [refreshSession]);

    return {
        requestInterceptor,
        onAuthError
    };
};
