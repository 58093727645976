import React from 'react';

import { CourseListItem, IconSize } from '@adept-at/lib-react-components';
import { Chip } from '@material-ui/core';
import Icon from '@mdi/react';
import { CourseIconPath } from 'components/icons/CourseIcon';
import Modal from 'components/modals';
import { CollectionStatType } from 'components/RootProfileCollection/context/useGetTenantProfileForOwner';
import { useOrganizationTerminology } from 'hooks/useOrganizationTerminology';
import pluralize from 'pluralize';
import styled from 'styled-components';
import { formatTime } from 'utils/time';
import { uppercaseFirst } from 'utils/uppercaseFirst';

import { AncestorCollection } from './utils';

interface CollectionsModalProps {
    open: boolean;
    onClose: () => void;
    collections: AncestorCollection[];
    parentCollectionTitle: string;
}

const StyledChip = styled(Chip)`
    margin-top: -2rem;
`;

const StyledCourseListItem = styled(CourseListItem)`
    h6 {
        font-weight: 400;
    }

    > div > div {
        margin-top: 0;
    }
`;

export const CollectionsModal: React.FC<CollectionsModalProps> = ({
    open,
    onClose,
    collections,
    parentCollectionTitle
}) => {
    const { terminology } = useOrganizationTerminology();

    return (
        <Modal
            open={open}
            onClose={onClose}
            onClick={(e) => e.stopPropagation()}
            showActionButtons={false}
            modalTitle="Collections present in"
        >
            <>
                <StyledChip
                    size="small"
                    icon={<Icon path={CourseIconPath} size={IconSize.Small} />}
                    label={parentCollectionTitle}
                    variant="outlined"
                />
                {collections.map((collection) => (
                    <StyledCourseListItem
                        key={collection.collectionId}
                        title={
                            collection.collectionId === collection.rootCollectionId
                                ? uppercaseFirst(collection.rootType)
                                : collection.title
                        }
                        subtitle={formatTime(collection.stats?.estimatedSecondsToConsume ?? 0, 'medium')}
                        description={[
                            pluralize(
                                terminology.skill,
                                collection.stats?.descendants?.find(
                                    (descendant) => descendant.type === CollectionStatType.Skill
                                )?.count ?? 0,
                                true
                            ),
                            pluralize(
                                'lab',
                                collection.stats?.descendants?.find(
                                    (descendant) => descendant.type === CollectionStatType.Lab
                                )?.count ?? 0,
                                true
                            ),
                            pluralize(
                                'question',
                                collection.stats?.descendants?.find(
                                    (descendant) => descendant.type === CollectionStatType.Question
                                )?.count ?? 0,
                                true
                            )
                        ]}
                    />
                ))}
            </>
        </Modal>
    );
};
