import styled from 'styled-components';

export const List = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 1;
    border: 1px solid ${(props) => props.theme.colors.primary};
    padding: 0.25rem;
    border-radius: 0.25rem;
`;

// Override MuiButtonBase with 0px padding or the open state will expand height
export const ListItems = styled.div`
    display: flex;
    overflow: hidden;
    background-color: ${(props) => props.theme.colors.surface};

    .MuiButtonBase-root.MuiIconButton-root {
        padding: 0px;
    }
`;
