import { CollectionImage, defaultMuiTheme, modularScale } from '@adept-at/lib-react-components';
import { Button, Modal } from '@material-ui/core';
import styled from 'styled-components';
import { forceShowScrollbarsCSSFragment } from 'styles/Shared.styles';

const BUTTONS_HEIGHT = 84;
const MAX_MODAL_WIDTH = 444;
export const StyledModal = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 144px;
    max-height: 80vh;
    margin-bottom: auto;
    margin-top: 10vh;
    z-index: 100;

    ${defaultMuiTheme.breakpoints.down(750)} {
        max-height: 100vh;
        margin-top: 0;
    }
`;

export const ModalContainer = styled.div`
    background: ${(props) => props.theme.colors.surface};
    width: 100%;
    max-height: fit-content;
    overscroll-behavior: contain;
    overflow-y: auto;
    max-width: ${MAX_MODAL_WIDTH}px;
    outline: none;
    border-radius: 4px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
    ${forceShowScrollbarsCSSFragment}
    ${defaultMuiTheme.breakpoints.down(750)} {
        max-width: 100%;
    }
`;

export const ModalBody = styled.div`
    background: ${(props) => props.theme.colors.surface};
    width: 100%;
    outline: none;
    height: 100%;
    overflow: auto;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
`;

export const Buttons = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: ${BUTTONS_HEIGHT}px;
`;

export const SubmitButton = styled(Button)`
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.primary};
`;

export const ModalCloseContainer = styled.div`
    height: 0;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    width: ${MAX_MODAL_WIDTH}px;
    align-items: center;
`;
export const ModalHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    align-content: center;
`;

export const ContentImage = styled(CollectionImage.Medium)`
    flex-shrink: 0;
    border-radius: 100%;
    margin-top: 1rem;
`;

export const CenterContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
`;

export const SelectTitle = styled.div`
    font-size: 1rem;
    font-weight: 600;
    align-items: center;
    color: ${({ theme }) => theme.colors.textSecondary};
    display: flex;
`;

export const ModalNameBar = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-left: 1.5rem;
    margin-bottom: 1rem;
`;

export const ModalName = styled.div`
    font-size: ${modularScale(0.75)};
    font-weight: 600;
    align-items: center;
    color: ${({ theme }) => theme.colors.text};
    display: flex;
    padding-top: 1.5rem;
`;

export const ContentTitle = styled.div`
    font-size: ${modularScale(0.5)};
    font-weight: 500;
    color: ${({ theme }) => theme.colors.textSecondary};
`;

export const IntervalText = styled.div`
    font-size: 0.75rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.textTertiary};
    margin-left: 0.25rem;
`;

export const CostPerInterval = styled.div`
    display: flex;
    margin-top: 1rem;
    align-items: center;
    -webkit-box-align: baseline;
    -webkit-align-items: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
`;

export const FormBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Divider = styled.hr`
    margin: 1rem 0;
    border: none;
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
`;

export const SmallVisa = styled.div`
    height: 2rem;
    width: 5rem;
    display: flex;
    align-items: center;
`;
