import React from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import Logo from 'components/icons/Logo';
import styled from 'styled-components';

const LogoWrapper = styled.div`
    display: flex;
    align-items: center;
    margin: 0;
    margin-right: 1rem;
    justify-content: flex-start;

    svg {
        transform: rotate(270deg);
    }
`;

const Title = styled.h1`
    display: inline;
    font-size: 33px;
    letter-spacing: -2px;
    margin: 2px 0 0 5px;
`;

export const AdeptLogo: React.FC = () => {
    return (
        <LogoWrapper>
            <Logo size={IconSize.Large} />
            <Title>Adept</Title>
        </LogoWrapper>
    );
};
