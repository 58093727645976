import React, { useContext } from 'react';

import { Button } from '@material-ui/core';
import { mdiArrowLeft } from '@mdi/js';
import Icon from '@mdi/react';
import { TabContent } from 'components/Sidebar/index.styles';
import styled from 'styled-components';

import AddCommentInput from './AddCommentInput';
import { ThreadHeader } from './Comment.style';
import { CommentContext } from './CommentContext';
import CommentSection from './CommentSection';
import Thread from './Thread';

const StyledButton = styled(Button)`
    margin-left: 0.5rem;
`;

const Title = styled.div`
    line-height: 1;
    font-weight: bold;
    margin-left: 0.5rem;
`;

const CommentSections: React.FC = () => {
    const { isThreadView, threadParentComment, showComments, commentSections } = useContext(CommentContext);
    const { allComments, contextAccessType } = useContext(CommentContext);

    const handleLevelChange = () => {
        if (!threadParentComment?.parent) return;

        showComments(threadParentComment.parent);
    };

    const renderThreadControls = () => {
        if (!isThreadView || !threadParentComment) return;

        return (
            <>
                <ThreadHeader>
                    <StyledButton onClick={handleLevelChange} color="primary">
                        <Icon path={mdiArrowLeft} />
                    </StyledButton>
                    <Title>Comment replies</Title>
                </ThreadHeader>
                <Thread comments={[]} />
                <AddCommentInput />
            </>
        );
    };

    const renderSections = () => {
        if (isThreadView) return;

        return commentSections.map((section, i) => {
            const comments = allComments.filter((i) => i.parent === section.id && i.access === contextAccessType);

            return <CommentSection key={i} section={section} comments={comments} />;
        });
    };

    return (
        <TabContent>
            {renderThreadControls()}
            {renderSections()}
        </TabContent>
    );
};

export default CommentSections;
