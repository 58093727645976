import { useReducer } from 'react';

import { UserSearchOption, UserSearchOptionType } from './types';

export enum SelectionsReducerActionType {
    Clear,
    Replace,
    ReplaceAll,
    Add
}

interface ClearAction {
    type: SelectionsReducerActionType.Clear;
}

interface AddAction {
    type: SelectionsReducerActionType.Add;
    option: UserSearchOption;
}

interface ReplaceAction {
    type: SelectionsReducerActionType.Replace;
    option: UserSearchOption;
}

interface ReplaceAllAction {
    type: SelectionsReducerActionType.ReplaceAll;
    options: (string | UserSearchOption)[];
}

export type Action = ReplaceAction | AddAction | ClearAction | ReplaceAllAction;

// @TODO should have a validation state to support freeform entries

const buildIdForOption = (option: string | UserSearchOption) =>
    typeof option == 'string' ? `free-${option}` : option.id;

const selectionsReducer = (
    selections: Record<string, UserSearchOption>,
    action: ReplaceAction | AddAction | ClearAction | ReplaceAllAction
): Record<string, UserSearchOption> => {
    switch (action.type) {
        case SelectionsReducerActionType.Clear:
            return {};

        case SelectionsReducerActionType.ReplaceAll:
            return action.options.reduce((memo, option) => {
                const id = buildIdForOption(option);

                memo[id] = option;

                return memo;
            }, {});

        case SelectionsReducerActionType.Replace:
            return {
                [action.option.id]: action.option
            };

        case SelectionsReducerActionType.Add:
            selections[action.option.id] = action.option;
            return selections;

        default:
            return selections;
    }
};

export const useUserSearchSelections = (): [Record<string, UserSearchOption>, React.Dispatch<Action>] => {
    return useReducer(selectionsReducer, {});
};
