import { IconSize } from '@adept-at/lib-react-components';
import { Button, IconButton } from '@material-ui/core';
import styled from 'styled-components';
import { SKILL_CONTENT_WIDTH } from 'styles/variables';

export const AddComponentContainer = styled.div`
    align-items: center;
    display: flex;
    margin: 5px auto 0;
    max-width: ${SKILL_CONTENT_WIDTH}px;
    position: relative;
`;

export const AddComponentIcon = styled(IconButton)<{ $isOpen: boolean }>`
    svg {
        color: ${(props) => props.theme.colors.primary};
        transform: ${(props) => (props.$isOpen ? 'rotate(45deg)' : 'rotate(0deg)')};
    }
`;

export const Title = styled.div`
    color: ${(props) => props.theme.colors.primary};
    font-size: ${(props) => props.theme.fonts.textSizeSmall};
    font-weight: ${(props) => props.theme.weight.bold};
`;

export const StyledButton = styled(Button)`
    svg {
        margin-right: 4px;
    }
`;
