import { useMemo, useContext } from 'react';

import { CommonComponentValues } from '@adept-at/lib-react-components';
import cloneDeep from 'clone-deep';
import { Skill } from 'components/ContentContext/Interfaces';
import { buildGetSkillBySlugQueryKey, GetSkillBySlugResponse } from 'components/learn/getSkillBySlug';
import { LearnContext } from 'components/learn/LearnContext';
import { useQueryClient } from 'react-query';

import { UpsertComponentVariables } from '../../../../engine/types';
import {
    buildQueryKey as buildGetSkillByIdQueryKey,
    GetSkillByIdResponse
} from '../../../../skills/hooks/useGetSkillById';

type MutationFunction = (variables: UpsertComponentVariables) => void;

export const useAddSkillBuilderComponent = (skillId: string): MutationFunction => {
    const queryClient = useQueryClient();
    const learnCtx = useContext(LearnContext);
    const hasLearnContext = !!learnCtx;

    const skillQueryByIdKey = useMemo(() => buildGetSkillByIdQueryKey(skillId), [skillId]);
    const skillQueryBySlugKey = useMemo(
        () => buildGetSkillBySlugQueryKey(learnCtx?.tenantSlug ?? '', learnCtx?.skillSlug ?? ''),
        [learnCtx?.tenantSlug, learnCtx?.skillSlug]
    );

    const updateSkill = ({ skill, variables }: { skill: Skill; variables: UpsertComponentVariables }): Skill => {
        const { componentType, order, values, componentId } = variables;

        skill.components[componentId] = {
            type: componentType,
            order: order,
            values: values
        } as CommonComponentValues;

        return skill;
    };

    /**
     * @FIXME - We are modifying the queryCache but not mutating on the server. So we're using queryCache as a shared
     * state store that is out of sync with the server. Not sure this is how we want this to work.
     */
    return (variables: UpsertComponentVariables) => {
        if (hasLearnContext) {
            queryClient.setQueryData<GetSkillBySlugResponse>(skillQueryBySlugKey, (previous) => {
                if (!previous) return { getEntityBySlug: {} } as GetSkillBySlugResponse;
                const skill = cloneDeep(previous.getEntityBySlug);
                const updatedSkill = updateSkill({ skill, variables });

                return { getEntityBySlug: updatedSkill };
            });
        } else {
            queryClient.setQueryData<GetSkillByIdResponse>(skillQueryByIdKey, (previous) => {
                if (!previous) return { getSkillById: {} } as GetSkillByIdResponse;
                const skill = cloneDeep(previous.getSkillById);
                const updatedSkill = updateSkill({ skill, variables });

                return { getSkillById: updatedSkill };
            });
        }
    };
};
