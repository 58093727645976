import React from 'react';

import { CourseIcon, SkillIcon } from '@adept-at/lib-react-components';
import { Chip, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { EntityType } from 'components/ContentContext/Enums';
import { Modal } from 'components/modals';
import styled from 'styled-components';

import { OpenGraphSettings } from './OpenGraphSettings';
import { SEOSettings } from './SEOSettings';

const ChipWrapper = styled.div`
    margin-top: -1rem;
    margin-bottom: 1.5rem;
`;

const StyledModalBody = styled.div`
    max-height: 450px;
    overflow: auto;
`;

const StyledAccordion = styled(Accordion)`
    box-shadow: none;
    border-top: 1px solid ${(props) => props.theme.colors.border};

    svg {
        fill: ${(props) => props.theme.colors.primary};
    }

    :before {
        display: none;
    }
`;

interface PageSettingsModalProps {
    contentType: EntityType;
    contentTitle: string;
    description: string;
    catalogImage?: string | null;
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
}

export const PageSettingsModal: React.FC<PageSettingsModalProps> = ({
    contentType,
    contentTitle,
    description,
    catalogImage,
    open,
    onClose,
    onConfirm
}) => {
    return (
        <Modal
            modalTitle="Page settings"
            open={open}
            onClose={onClose}
            styleProps={{ 'max-width': '600px' }}
            confirmButtonText="Done"
            onConfirm={() => {
                onConfirm();
            }}
        >
            <>
                <ChipWrapper>
                    <Chip
                        size="small"
                        icon={
                            contentType === EntityType.COLLECTION ? (
                                <CourseIcon aria-label="Collection" />
                            ) : (
                                <SkillIcon aria-label="Skill" />
                            )
                        }
                        label={contentTitle}
                        variant="outlined"
                    />
                </ChipWrapper>
                <StyledModalBody>
                    <StyledAccordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="SEO Settings"
                            id="SEO Settings"
                        >
                            SEO Settings
                        </AccordionSummary>
                        <AccordionDetails>
                            <SEOSettings contentTitle={contentTitle} contentDescription={description} />
                        </AccordionDetails>
                    </StyledAccordion>
                    <StyledAccordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="Open Graph Settings"
                            id="Open Graph Settings"
                        >
                            Open Graph Settings
                        </AccordionSummary>
                        <AccordionDetails>
                            <OpenGraphSettings
                                contentType={contentType}
                                contentTitle={contentTitle}
                                contentDescription={description}
                                catalogImage={catalogImage}
                            />
                        </AccordionDetails>
                    </StyledAccordion>
                </StyledModalBody>
            </>
        </Modal>
    );
};
