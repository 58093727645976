import React from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { Typography } from '@material-ui/core';
import { mdiRhombusOutline } from '@mdi/js';
import Icon from '@mdi/react';
import AddNestedCollections from 'components/RootProfileCollection/AddNestedCollections';
import { MessageContainer, MessageFirstLine } from 'components/RootProfileCollection/Collections.styles';
import styled from 'styled-components';

import { AddToCollection } from '../AddToCollection';

const EMPTY_NESTED_COLLECTION_MESSAGE = 'This collection is empty';
const EMPTY_ROOT_COLLECTION_MESSAGE = 'Your catalog is empty';
const EMPTY_NESTED_COLLECTION_INFO = 'Add skills or other collections.';
const EMPTY_ROOT_COLLECTION_INFO = 'Organize your content by creating or adding existing collections.';

const EmptyContentStateWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3rem 1rem;
    text-align: center;
`;

const StyledIcon = styled(Icon)`
    color: ${(props) => props.theme.colors.lightGrey};
`;

export const EmptyContentState: React.FC<{ isNestedCollection: boolean }> = ({ isNestedCollection }) => (
    <EmptyContentStateWrapper>
        <StyledIcon path={mdiRhombusOutline} size={IconSize.Hero} />
        <MessageContainer>
            <MessageFirstLine>
                {isNestedCollection ? EMPTY_NESTED_COLLECTION_MESSAGE : EMPTY_ROOT_COLLECTION_MESSAGE}
            </MessageFirstLine>
            <Typography variant="subtitle1">
                {isNestedCollection ? EMPTY_NESTED_COLLECTION_INFO : EMPTY_ROOT_COLLECTION_INFO}
            </Typography>
        </MessageContainer>
        {isNestedCollection ? <AddToCollection /> : <AddNestedCollections />}
    </EmptyContentStateWrapper>
);
