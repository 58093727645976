import React, { useMemo } from 'react';

import { Avatar } from '@material-ui/core';
import { useAvatars } from 'context/AvatarsContext/useAvatars';
import styled from 'styled-components';

import { StyledText } from './BreadCrumbTrail.styles';

const AvatarCrumbContainer = styled.div`
    display: flex;
`;

const StyledAvatar = styled(Avatar)`
    width: 24px;
    height: 24px;
    margin-right: 0.3rem;
`;
interface BreadCrumbAvatarProps {
    avatarData: { assetId: string; avatarAlias?: string };
}

const BreadCrumbAvatar: React.FC<BreadCrumbAvatarProps> = ({ avatarData }) => {
    const memoedAssetIdArray = useMemo(() => (avatarData.assetId ? [avatarData.assetId] : []), [avatarData.assetId]);

    const { determineAvatarSourceById } = useAvatars({ assetIds: memoedAssetIdArray });

    return (
        <AvatarCrumbContainer data-testid={`crumb-userName-${avatarData.avatarAlias}`}>
            <StyledAvatar alt={avatarData.avatarAlias} src={determineAvatarSourceById(avatarData.assetId ?? '')} />
            <StyledText>{avatarData.avatarAlias ?? ''}</StyledText>
        </AvatarCrumbContainer>
    );
};

export default BreadCrumbAvatar;
