import React from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import styled from 'styled-components';

import LogoIcon from './icons/Logo';
import Loader from './Loader';

const Container = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: ${(props) => props.theme.colors.surface};
`;

const Inner = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 5rem;
`;

const LogoWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
`;

const Logo = styled.div`
    color: ${(props) => props.theme.colors.primary};
    max-width: 200px;
    transform: rotate(270deg);
`;

interface Props {
    text: string;
}

const AppLoading: React.FC<Props> = ({ text }) => {
    return (
        <Container>
            <Inner>
                <LogoWrapper>
                    <Logo>
                        <LogoIcon size={IconSize.Hero} />
                    </Logo>
                </LogoWrapper>
                <span>{text}</span>
                <Loader />
            </Inner>
        </Container>
    );
};

export default AppLoading;
