import { GraphQLClient } from 'graphql-request';
import gql from 'graphql-tag';
import { MutationFunction } from 'react-query';

import buildGqlMutationFn from '../../../../../hooks/useGqlClient/helpers/buildGqlMutationFn';
import { UpsertComponentResult, UpsertComponentVariables } from '../../interfaces';

export const UPSERT_SKILL_COMPONENT = gql`
    mutation upsertSkillComponent(
        $skillId: UUIDv4!
        $componentType: Alphanumeric!
        $componentId: Alphanumeric!
        $values: JSON!
        $order: Int
    ) {
        upsertSkillComponent(
            skillId: $skillId
            componentType: $componentType
            componentId: $componentId
            values: $values
            order: $order
        ) {
            components {
                items
            }
        }
    }
`;

const makeUpsertSkillComponent = (
    client: GraphQLClient
): MutationFunction<UpsertComponentResult, UpsertComponentVariables> =>
    buildGqlMutationFn(client, UPSERT_SKILL_COMPONENT);

export default makeUpsertSkillComponent;
