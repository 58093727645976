import React from 'react';

import { NotFoundError } from 'components/NotFoundError';
import AppLayout from 'layouts/AppLayout';

const NotFoundPage: React.FC = () => (
    <AppLayout pageTitle="Oops!">
        <NotFoundError />
    </AppLayout>
);

export default NotFoundPage;
