import { useMemo } from 'react';

import { ClientError } from 'graphql-request';
import { useQuery, UseQueryResult, QueryKey } from 'react-query';

import useGqlClient from '../../../../../../../hooks/useGqlClient';
import { API_CONTENT } from '../../../../../../../lib/ApiConstants';

import makeGetPlaybackDetails, { GetVideoPlaybackDetailsResult } from './makeGetPlaybackDetails';

const buildGetVideoPlaybackDetailsQueryKey = (videoId: string): QueryKey => ['getVideoPlaybackDetails', videoId];

export enum VideoProvider {
    S3 = 's3',
    CLOUDFRONT = 'cloudFront',
    MUX = 'mux'
}

const useGetPlaybackDetails = (
    videoId: string,
    enabled: boolean,
    provider?: VideoProvider
): UseQueryResult<GetVideoPlaybackDetailsResult, ClientError> => {
    const { client, withQueryOptions } = useGqlClient(API_CONTENT);

    const videoQueryKey = useMemo(() => buildGetVideoPlaybackDetailsQueryKey(videoId), [videoId]);

    return useQuery<GetVideoPlaybackDetailsResult, ClientError>(
        withQueryOptions({
            queryKey: videoQueryKey,
            queryFn: makeGetPlaybackDetails(client, { videoId, provider }),
            enabled: !!videoId && enabled
        })
    );
};

export default useGetPlaybackDetails;
