import React, { createContext, useReducer, useCallback, useContext, useEffect } from 'react';

import HistoricalCommunicationContext from 'context/HistoricalCommunicationContext';
import { AckBatchParams, handleAckMessageBatch } from 'hooks/communication/useMessageActions/useAckMessageBatch';
import { AckMessageDetail } from 'hooks/communication/useMessageActions/useAckMessageBatch/ackMessageBatchApi';
import {
    handleMessageUpdateReducer,
    MessageUpdateReducerParams
} from 'hooks/communication/useMessageActions/useUpdateMessage';
import AdepteductMessage from 'lib/communication/message/base/AdepteductMessage';
import MessageFactory, { MessageInstance } from 'lib/communication/message/factory';
import { RealTimeMessageRecord } from 'lib/communication/message/MessageRecord';
import {
    ChannelDeleteMessage,
    ChannelEditMessage,
    ChannelMeetingSessionCameraToggled,
    ChannelMeetingSessionChanged,
    ChannelMeetingSessionDrawing,
    ChannelMeetingSessionJoined,
    ChannelMeetingSessionLeave,
    ChannelMeetingSessionPresenterChange,
    ChannelMeetingSessionPresenterFocusChange,
    ChannelMeetingSessionQuickPollCreated,
    ChannelMeetingSessionReactionBatch,
    ChannelMeetingSessionSkillChange,
    MessageEventType
} from 'lib/communication/message/type';
import ChannelReactionUpdated from 'lib/communication/message/type/channel/reactionUpdated';
import PossibleEventFields from 'lib/communication/message/type/PossibleEventFields';
import { v4 as generateUuid } from 'uuid';

import debugLog from '../../utils/debugLog';
import { CommunicationWebsocketContext, WebsocketConnectionStatus } from '../CommunicationWebsocketContext';

import handleMessageAppend from './handleMessageAppend';
import handleMessageExchange from './handleMessageExchange';

/**
 * Do not consume directly, use hooks in the hooks/communication directory instead.
 * This is because it works in tandem with HistoricalCommunicationContext.
 */
export interface RealTimeCommunicationProviderInterface {
    connectionStatus: WebsocketConnectionStatus;
    /**
     * Messages mapped by channelId
     *
     * Note that message classes are not render safe:
     * The messages object will change equality, as will the channelId, but individual messages will not.
     * They maintain reference and mutate, so do not rely on them for dependencies (in dependency arrays) or for props.
     */
    messages: RealTimeMessagesReducerState;
    addOptimisticMessage: (message: AdepteductMessage<PossibleEventFields>) => string;
    optimisticallyDeleteMessage: (message: AdepteductMessage<PossibleEventFields>) => void;
    isOptimisticMessage: (message?: AdepteductMessage<PossibleEventFields> | ReceiptAdepteductMessageWithId) => boolean;
    attachReceiptToOptimisticMessage: (receiptMessage: ReceiptAdepteductMessage) => void;
    ackMessageBatch: (ackMessageDetails: AckMessageDetail[]) => void;
    updateMessage: (messageDetails: MessageUpdateReducerParams) => void;
}

const RealTimeCommunicationContext = createContext(undefined as unknown as RealTimeCommunicationProviderInterface);

const { Provider, Consumer } = RealTimeCommunicationContext;

enum MessageDispatchType {
    Append = 0,
    Exchange = 1,
    Update = 2,
    AckBatch = 3
}

export interface ReceiptAdepteductMessage {
    channelId: string;
    temporaryId: string;
    receiptId: string;
}

export interface ReceiptAdepteductMessageWithId extends ReceiptAdepteductMessage {
    id: string;
    sentAt: string;
    acks?: Record<string, unknown>[];
}

/** By channelId */
export type RealTimeMessagesReducerState = Record<string, (AdepteductMessage | ReceiptAdepteductMessageWithId)[]>;

export interface UpdateMessagesParams<T extends MessageDispatchType> {
    action: T;
    values:
        | AdepteductMessage<PossibleEventFields>
        | MessageUpdateReducerParams
        | ReceiptAdepteductMessage
        | AckBatchParams;
}

export const MAX_MESSAGES_PER_CHANNEL = 1000;

function messageReducer<T extends MessageDispatchType>(
    state: RealTimeMessagesReducerState,
    { action, values }: UpdateMessagesParams<T>
): RealTimeMessagesReducerState {
    switch (action) {
        case MessageDispatchType.Append:
            return handleMessageAppend(state, values as AdepteductMessage<PossibleEventFields>);
        case MessageDispatchType.Exchange:
            return handleMessageExchange(state, values as ReceiptAdepteductMessage);
        case MessageDispatchType.Update:
            return handleMessageUpdateReducer(state, values as MessageUpdateReducerParams);
        case MessageDispatchType.AckBatch:
            return handleAckMessageBatch(state, values as AckBatchParams);
        default:
            return state;
    }
}

const OPTIMISTIC_MESSAGE_PREFIX = 'tmp-';

export const isOptimisticMessage = (
    message?: AdepteductMessage<PossibleEventFields> | ReceiptAdepteductMessageWithId
): message is ReceiptAdepteductMessageWithId => message?.id?.startsWith(OPTIMISTIC_MESSAGE_PREFIX) ?? false;

const isHandledSeparately = (message: MessageInstance) => {
    return [
        ChannelMeetingSessionJoined,
        ChannelMeetingSessionLeave,
        ChannelMeetingSessionPresenterChange,
        ChannelMeetingSessionPresenterFocusChange,
        ChannelMeetingSessionChanged,
        ChannelMeetingSessionSkillChange,
        ChannelMeetingSessionCameraToggled,
        ChannelMeetingSessionQuickPollCreated,
        ChannelMeetingSessionDrawing,
        ChannelMeetingSessionReactionBatch
    ].some((messageInstance) => message instanceof messageInstance);
};

const RealTimeCommunicationProvider: React.FC = ({ children }) => {
    const [messages, dispatchMessage] = useReducer(messageReducer, {});
    const { connectionStatus, connection } = useContext(CommunicationWebsocketContext);
    const { updateMessage: updateHistoricalMessage } = useContext(HistoricalCommunicationContext);

    const ackMessageBatch = useCallback((ackMessageDetails: AckMessageDetail[]) => {
        dispatchMessage({ action: MessageDispatchType.AckBatch, values: { ackMessageDetails } });
    }, []);

    const updateMessage = useCallback(
        (values: MessageUpdateReducerParams) => {
            updateHistoricalMessage(values);
            dispatchMessage({ action: MessageDispatchType.Update, values });
        },
        [updateHistoricalMessage]
    );

    const incrementParentThread = useCallback(
        (message: AdepteductMessage<PossibleEventFields>) => {
            if (message.parent && message.type === MessageEventType.ChannelPostMessage) {
                updateMessage({
                    channelId: message.channelId,
                    messageId: message.parent,
                    threadDetails: {
                        messageId: message.id,
                        senderId: message.senderId,
                        modifier: 1
                    }
                });
            }
        },
        [updateMessage]
    );

    const decrementParentThread = useCallback(
        (message: AdepteductMessage<PossibleEventFields>) => {
            if (message.parent) {
                updateMessage({
                    channelId: message.channelId,
                    messageId: message.parent,
                    threadDetails: {
                        messageId: message.id,
                        senderId: message.senderId,
                        modifier: -1
                    }
                });
            }
        },
        [updateMessage]
    );

    const onMessage = useCallback(
        async (data: RealTimeMessageRecord): Promise<void> => {
            const message = await MessageFactory.construct(data).catch((e) => {
                console.error(e, data);
            });

            if (!message || isHandledSeparately(message)) {
                return;
            }

            if (message instanceof ChannelEditMessage) {
                return updateMessage({
                    channelId: message.channelId,
                    messageId: message.id,
                    updatedText: message.fields.text
                });
            }

            if (message instanceof ChannelReactionUpdated) {
                return updateMessage({
                    channelId: message.channelId,
                    messageId: message.id,
                    updatedReactions: message.reactions
                });
            }

            if (message instanceof ChannelDeleteMessage) {
                updateMessage({
                    channelId: message.channelId,
                    messageId: message.id,
                    deletedAt: message.sentAt
                });

                return decrementParentThread(message);
            }

            dispatchMessage({ action: MessageDispatchType.Append, values: message });

            incrementParentThread(message);
        },
        [updateMessage, incrementParentThread, decrementParentThread]
    );

    useEffect(() => {
        if (connection) {
            const eventListener = ({ data }) => {
                if (data) {
                    debugLog(data);
                    onMessage(data as RealTimeMessageRecord);
                }
            };

            connection.addEventListener('message', eventListener);

            return () => connection.removeEventListener('message', eventListener);
        }
    }, [onMessage, connection]);

    const addOptimisticMessage = useCallback(
        (message: AdepteductMessage<PossibleEventFields>): string => {
            message.id = `${OPTIMISTIC_MESSAGE_PREFIX}${generateUuid()}`;
            message.sentAt = new Date().toISOString();

            dispatchMessage({ action: MessageDispatchType.Append, values: message });
            incrementParentThread(message);

            return message.id;
        },
        [incrementParentThread]
    );

    const optimisticallyDeleteMessage = useCallback(
        (message: AdepteductMessage<PossibleEventFields>) => {
            updateMessage({
                channelId: message.channelId,
                messageId: message.id,
                deletedAt: message.sentAt
            });
            decrementParentThread(message);
        },
        [updateMessage, decrementParentThread]
    );

    const attachReceiptToOptimisticMessage = useCallback(
        ({ channelId, temporaryId, receiptId }: ReceiptAdepteductMessage): void => {
            dispatchMessage({
                action: MessageDispatchType.Exchange,
                values: {
                    channelId,
                    temporaryId,
                    receiptId
                }
            });
        },
        []
    );

    return (
        <Provider
            value={{
                connectionStatus,
                messages,
                addOptimisticMessage,
                optimisticallyDeleteMessage,
                isOptimisticMessage,
                attachReceiptToOptimisticMessage,
                ackMessageBatch,
                updateMessage
            }}
        >
            {children}
        </Provider>
    );
};

export {
    RealTimeCommunicationContext,
    RealTimeCommunicationProvider,
    Consumer as RealTimeCommunicationConsumer,
    WebsocketConnectionStatus
};
