// 100% thin wrappers, no need to test
/* istanbul ignore file */
import Unsplash, { toJson } from 'unsplash-js';

import { UnsplashImage } from './Search';

const unsplash = new Unsplash({
    accessKey: process.env.REACT_APP_UNSPLASH_API_KEY ?? ''
});

export const getRandomPhoto = (): Promise<Response> => {
    return unsplash.photos.getRandomPhoto({}).then(toJson);
};

export const trackDownload = (image: UnsplashImage): Promise<Response> => {
    return unsplash.photos.trackDownload(image);
};

interface UnsplashSearchResponse {
    results: UnsplashImage[];
    errors?: string[];
    total_pages?: number;
}

export const searchPhotos = (
    keywords: string,
    pageNumber: number,
    perPage: number
): Promise<UnsplashSearchResponse> => {
    return unsplash.search.photos(keywords, pageNumber, perPage).then(toJson);
};
