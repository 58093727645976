import React from 'react';

import { CircularProgress } from '@material-ui/core';
import { useAvatars } from 'context/AvatarsContext/useAvatars';
import styled from 'styled-components';

import { modularScale } from '../../utils/modular_scale';

const Container = styled.div<{ image; $size?: string }>`
    height: ${(props) => (props.$size ? props.$size : '36px')};
    width: ${(props) => (props.$size ? props.$size : '36px')};
    min-width: ${(props) => (props.$size ? props.$size : 'inherit')};
    background-color: ${(props) => props.theme.colors.border};
    background-image: url(${(props) => props.image});
    border-radius: 50%;
    background-size: cover;
    background-position: center;
`;

const Placeholder = styled.div<{ $size?: string; $fontSize?: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    line-height: 1;
    height: ${(props) => (props.$size ? props.$size : '36px')};
    width: ${(props) => (props.$size ? props.$size : '36px')};
    min-width: ${(props) => (props.$size ? props.$size : 'inherit')};
    font-size: ${(props) => (props.$fontSize ? props.$fontSize : modularScale(1))};
    background-color: ${(props) => props.theme.colors.secondaryLightGrey};
    border-radius: 50%;
    font-weight: 600;
`;

export const Progress = styled(CircularProgress)`
    display: flex;
`;

interface AvatarProps {
    imageId?: string;
    label: string;
    size?: string;
    placeholderFontSize?: string;
    borderRadius?: string;
}

const OrgImage: React.FC<AvatarProps> = ({ imageId, label, size, placeholderFontSize, borderRadius }) => {
    const { determineAvatarSourceById } = useAvatars({ assetIds: imageId ? [imageId] : [] });

    if (!imageId || !determineAvatarSourceById(imageId)) {
        return (
            <Placeholder $size={size} $fontSize={placeholderFontSize}>
                {label.charAt(0)}
            </Placeholder>
        );
    }

    return <Container image={determineAvatarSourceById(imageId)} $size={size} />;
};

export default OrgImage;
