import React, { useContext, useEffect, useMemo } from 'react';

import { RichTextEditorContext } from 'components/engine/common/RichTextEditor/Context';
import { emptyCodeComponentValues } from 'components/engine/Components/Code';
import CodeEdit from 'components/engine/Components/Code/Edit';
import { emptyImageComponentValues } from 'components/engine/Components/Image';
import ImageEdit from 'components/engine/Components/Image/Edit';
import { emptyLinkComponentValues, LinkEdit } from 'components/engine/Components/Link/Edit';
import { AttachmentEdit, emptyAttachmentComponentValues } from 'components/engine/Components/PDF/AttachmentEdit';
import QuestionEdit from 'components/engine/Components/Question/Edit';
import { emptyQuestionComponentValues } from 'components/engine/Components/Question/lib/question';
import VideoEdit from 'components/engine/Components/Video/Edit';
import { emptyVideoComponentValues } from 'components/engine/Components/Video/Editor';
import { emptyLabComponentValues } from 'components/engine/Components/VirtualLab';
import VirtualLabEdit from 'components/engine/Components/VirtualLab/Edit';
import { useEditableComponent } from 'components/engine/mixins/editor';
import { ComponentTypeSpecificValues } from 'components/engine/mixins/editor/EditableComponentContext';
import Modal from 'components/modals';
import { v4 as uuid } from 'uuid';

import { DraftJsEntity, EmbeddedEntityComponentValues } from '../utils';

interface EmbeddedEntityModalProps {
    open: boolean;
    onClose: () => void;
    entity?: EmbeddedEntityComponentValues;
    entityKey?: string;
    entityType: DraftJsEntity;
}

const typeToComponentMapping = {
    [DraftJsEntity.LINK]: LinkEdit,
    [DraftJsEntity.CODE]: CodeEdit,
    [DraftJsEntity.IMAGE]: ImageEdit,
    [DraftJsEntity.ATTACHMENT]: AttachmentEdit,
    [DraftJsEntity.VIDEO]: VideoEdit,
    [DraftJsEntity.LAB]: VirtualLabEdit,
    [DraftJsEntity.QUESTION]: QuestionEdit
};

const getComponentForType = (type?: DraftJsEntity): React.FC<EditProps> | null =>
    type ? typeToComponentMapping[type] : null;

interface EditProps {
    onCancel?: () => void;
    onSave?: (values: Partial<EmbeddedEntityComponentValues>) => void;
    entityKey?: string;
}

const setDefaultValues = (
    entityType: DraftJsEntity,
    onChangeMany: (fieldsWithValues: Partial<ComponentTypeSpecificValues<EmbeddedEntityComponentValues>>) => void,
    entity?: EmbeddedEntityComponentValues
) => {
    switch (entityType) {
        case DraftJsEntity.QUESTION:
            const questionId = uuid();
            onChangeMany({ ...emptyQuestionComponentValues, questionId, ...entity });
            break;
        case DraftJsEntity.VIDEO:
            onChangeMany({ ...emptyVideoComponentValues, ...entity });
            break;
        case DraftJsEntity.CODE:
            onChangeMany({ ...emptyCodeComponentValues, ...entity });
            break;
        case DraftJsEntity.IMAGE:
            onChangeMany({ ...emptyImageComponentValues, ...entity });
            break;
        case DraftJsEntity.ATTACHMENT:
            onChangeMany({ ...emptyAttachmentComponentValues, ...entity });
            break;
        case DraftJsEntity.LINK:
            onChangeMany({ ...emptyLinkComponentValues, ...entity });
            break;
        case DraftJsEntity.LAB:
            const vLabId = uuid();
            onChangeMany({ ...emptyLabComponentValues, vLabId, ...entity });
            break;
        default:
            onChangeMany({ ...entity });
            break;
    }
};

export const EmbeddedEntityModal: React.FC<EmbeddedEntityModalProps> = ({
    open,
    onClose,
    entity,
    entityKey,
    entityType
}) => {
    const { addEntity, updateEntity } = useContext(RichTextEditorContext);
    const { onChangeMany } = useEditableComponent<EmbeddedEntityComponentValues>();

    const Component = useMemo(() => getComponentForType(entityType), [entityType]);

    useEffect(() => {
        setDefaultValues(entityType, onChangeMany, entity);
        // leaving out the onChangeMany dependency because that has a dependency on currentValues so when that changes, it overwrites the whole entity
    }, [entity, entityType, open]);

    return Component ? (
        <Modal
            open={open}
            onClose={onClose}
            showActionButtons={false}
            styleProps={{ 'max-width': '700px' }}
            disableRestoreFocus
        >
            <Component
                entityKey={entityKey}
                onCancel={onClose}
                onSave={(entityValues) => {
                    if (!entityKey) {
                        addEntity(entityType, entityValues);
                    } else {
                        updateEntity(entityKey, entityValues);
                    }
                    onClose();
                }}
            />
        </Modal>
    ) : null;
};
