import React from 'react';

import AdepteductMessageProps from 'components/communication/message/AdepteductMessageProps';
import ChannelPostMessageChatMessage from 'components/communication/message/chat/ChannelPostMessageComponent';
import * as yup from 'yup';

import { MessageEventType } from '..';
import AckableAdepteductMessage, { IAckableAdepteductMessage } from '../../base/AckableAdepteductMessage';
import PossibleEventFields from '../PossibleEventFields';

interface EventFieldsPostMessage {
    user: string;
    text: string;
}

export default class ChannelPostMessage
    extends AckableAdepteductMessage<EventFieldsPostMessage>
    implements IAckableAdepteductMessage
{
    schema = yup.object({
        user: yup.string().required(),
        text: yup.string().required()
    });

    type = MessageEventType.ChannelPostMessage;

    mapDestinationToComponent = (): React.ForwardRefExoticComponent<
        AdepteductMessageProps<PossibleEventFields> & React.RefAttributes<HTMLDivElement | null>
    > => ChannelPostMessageChatMessage;
}
