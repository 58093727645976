import React, { useContext, useMemo } from 'react';

import { Button } from '@material-ui/core';
import { useResetSkillProgress } from 'components/learn/useProgress/useResetSkillProgress';
import { ProgressState } from 'utils/progress';
import { getFirstBlockKey, getFirstUnfinishedBlockKey } from 'utils/skill/textBlocks';

import { LearnContext } from '../LearnContext';
import { getEntityProgressStatus } from '../modes/accountability/utils';

export const ContinueLearningButton: React.FC = () => {
    const { skill, skillSlug, tenantSlug } = useContext(LearnContext);

    const { mutate: resetProgress } = useResetSkillProgress(tenantSlug, skillSlug);
    const progressState = useMemo(() => getEntityProgressStatus(skill), [skill]);

    const scrollToBlock = (blockKey?: string) => {
        if (blockKey) {
            const element = document.getElementById(`component-${blockKey}`);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    };

    const scrollToFirstUnfinishedSection = () => {
        const firstUnfinishedBlockKey = getFirstUnfinishedBlockKey(skill) ?? getFirstBlockKey(skill);
        scrollToBlock(firstUnfinishedBlockKey);
    };

    const resetProgressOnSkill = () => {
        resetProgress({ skillId: skill.skillId });
        const firstBlockKey = getFirstBlockKey(skill);
        scrollToBlock(firstBlockKey);
    };

    return (
        <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={progressState === ProgressState.COMPLETE ? resetProgressOnSkill : scrollToFirstUnfinishedSection}
        >
            {progressState === ProgressState.COMPLETE
                ? 'Restart'
                : progressState === ProgressState.IN_PROGRESS
                ? 'Continue'
                : 'Start'}
        </Button>
    );
};
