import { useMemo } from 'react';

import { CommonComponentValues } from '@adept-at/lib-react-components';
import produce from 'immer';
import { useQueryClient } from 'react-query';

import { buildGetAssessmentByIdQueryKey } from '../../../../pages/assessment/AssessmentEdit';
import { UpsertComponentVariables } from '../../../engine/types';
import { GetAssessmentByIdResponse } from '../../queries/getAssessmentById';

type MutationFunction = (variables: UpsertComponentVariables) => void;

const useAddAssessmentComponent = (assessmentId: string): MutationFunction => {
    const queryClient = useQueryClient();

    const entityQueryKey = useMemo(() => buildGetAssessmentByIdQueryKey(assessmentId), [assessmentId]);

    return ({ componentId, componentType, order, values }: UpsertComponentVariables) => {
        queryClient.setQueryData<GetAssessmentByIdResponse>(entityQueryKey, (previous) =>
            !previous
                ? ({} as GetAssessmentByIdResponse)
                : produce(previous, (draft) => {
                      try {
                          draft.getAssessmentById.components[componentId] = {
                              type: componentType,
                              order: order,
                              values: values
                          } as CommonComponentValues;
                      } catch (error) {
                          console.error(error);
                      }
                  })
        );
    };
};

export default useAddAssessmentComponent;
