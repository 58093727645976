import React, { useContext } from 'react';

import Responder from './Responder';
import { StoryboardContext } from './StoryboardContext';

const Who: React.FC = () => {
    const { who, setWho } = useContext(StoryboardContext);

    const prompts = [
        'What experience does the learner have?',
        'What knowledge or ability does the learner have?',
        'What did the learner learn before this?',
        'What will the learner want to learn next?',
        'The “Who” statement: profile the person who will benefit the most from this content.'
    ];

    return <Responder onChange={setWho} defaults={who} prompts={prompts} stepName="Who" nextStepName="How" />;
};

export default Who;
