import React, { useContext, useState, useRef, useEffect } from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { Table, TableBody, IconButton } from '@material-ui/core';
import { mdiCloseBoxOutline } from '@mdi/js';
import MdiIcon from '@mdi/react';
import { useCollector } from 'components/devtools/Collector';

import { DisplayContext } from './Display';
import {
    OverlayContainer,
    StyledAppBar,
    StyledContent,
    StyledTabs,
    StyledTab,
    StyledTableRow,
    StyledTableCell
} from './Overlay.styles';

const HISTORY_ITEMS = ['authStatus', 'sessionStatus', 'tokenStatus', 'authTokenStatus', 'refreshStatus'];

export const DevToolsOverlay: React.FC = () => {
    const { visible, hide } = useContext(DisplayContext);

    const { getHistory } = useCollector();
    const itemsBottomRef = useRef<HTMLDivElement>(null);

    // Use the index of the tab to track selected
    const [selectedTab, setSelectedTab] = useState<number>(0);

    const scrollToBottom = () => {
        if (itemsBottomRef.current && itemsBottomRef.current?.scrollIntoView) {
            itemsBottomRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [getHistory]);

    useEffect(() => {
        scrollToBottom();
    }, [selectedTab]);

    return (
        <OverlayContainer hidden={!visible}>
            <StyledAppBar position="static">
                <StyledTabs
                    variant="fullWidth"
                    value={selectedTab}
                    onChange={(event, newValue) => {
                        setSelectedTab(newValue);
                    }}
                    aria-label="simple tabs example"
                    classes={{
                        indicator: 'indicator'
                    }}
                    TabIndicatorProps={{ children: <span /> }}
                >
                    {HISTORY_ITEMS.map((historyItem, idx) => (
                        <StyledTab label={historyItem} key={idx} />
                    ))}
                </StyledTabs>
                <IconButton
                    color="inherit"
                    aria-label="menu"
                    onClick={() => {
                        hide();
                    }}
                >
                    <MdiIcon path={mdiCloseBoxOutline} size={IconSize.Medium} />
                </IconButton>
            </StyledAppBar>
            <StyledContent>
                {HISTORY_ITEMS.map((historyItem, idx) => (
                    <div key={idx} hidden={selectedTab !== idx}>
                        <Table size="small">
                            <TableBody>
                                {[...(getHistory(historyItem) ?? [])].map(({ at, value: status }, idx) => (
                                    <StyledTableRow key={idx}>
                                        <StyledTableCell component="th" scope="row" width="260px">
                                            {at}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">{status}</StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                ))}
                <div ref={itemsBottomRef} />
            </StyledContent>
        </OverlayContainer>
    );
};
