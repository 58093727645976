import { useMemo } from 'react';

import { OptimistMutationContext, useOptimist } from '@adept-at/lib-react-optimist';
import { OwnerInfo } from 'components/ContentContext/Interfaces';
import { EntityBySlug } from 'components/NestedCollection/context/getNestedCollectionBySlug';
import {
    buildGetEntitiesBasicDetailsQueryKey,
    GetEntitiesBasicDetailsQueryResponse
} from 'components/NestedCollection/useGetEntitiesBasicDetails/getEntitiesBasicDetails';
import { ClientError, gql } from 'graphql-request';
import { useBuildAuthenticatedMutationFn } from 'hooks/useGqlClient/useBuildAuthenticatedMutationFn';
import { Api } from 'lib/ApiConstants';
import { useMutation, UseMutationResult } from 'react-query';

import { IsFreeInput } from './useGetFreePayload';

export interface CreateEnrollmentToProductResult {
    createEnrollmentToProduct: {
        success: boolean;
    };
}

export interface CreateEnrollmentToProductVariables {
    input: {
        idempotencyKey: string;
        subscriber: OwnerInfo;
        tenantId: string;
        productId: string;
        priceId: string;
        startDate: string;
        length: number;
        resetSkills: string[];
        isFree?: IsFreeInput;
    };
}

const CREATE_ENROLLMENT_MUTATION = gql`
    mutation createEnrollmentToProduct($input: CreateEnrollmentInput!) {
        createEnrollmentToProduct(input: $input) {
            success
        }
    }
`;

export const useCreateEnrollmentToProduct = (
    entity: EntityBySlug
): UseMutationResult<
    CreateEnrollmentToProductResult,
    ClientError,
    CreateEnrollmentToProductVariables,
    OptimistMutationContext
> => {
    const { mutationFn } = useBuildAuthenticatedMutationFn<
        CreateEnrollmentToProductResult,
        CreateEnrollmentToProductVariables
    >(CREATE_ENROLLMENT_MUTATION, { api: Api.Payment });
    const { registerUpdater, mutationOptions } = useOptimist<
        CreateEnrollmentToProductResult,
        ClientError,
        CreateEnrollmentToProductVariables
    >();

    const basicDetailsQueryKey = useMemo(
        () => buildGetEntitiesBasicDetailsQueryKey(entity.collectionId, entity.collectionSlug),
        [entity.collectionId, entity.collectionSlug]
    );

    registerUpdater<GetEntitiesBasicDetailsQueryResponse>(
        basicDetailsQueryKey,
        (previous, createEnrollmentVariables) => {
            const newDetails = previous.getEntityBasicDetails.map((entity) => {
                if ('skillId' in entity && createEnrollmentVariables.input.resetSkills.includes(entity.skillId)) {
                    return { ...entity, progress: undefined };
                } else {
                    return entity;
                }
            });
            return { getEntityBasicDetails: newDetails };
        },
        { refetchQuery: false }
    );

    return useMutation<
        CreateEnrollmentToProductResult,
        ClientError,
        CreateEnrollmentToProductVariables,
        OptimistMutationContext
    >(mutationFn, mutationOptions);
};
