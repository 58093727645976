import { useState, useEffect, useMemo } from 'react';

import { HeadCell } from 'components/SortableTable/EnhancedTableHead';
import { StudioProgress } from 'components/studio/skill/StudioSkillContext';

import { CollaborativeEntity, Creation, HeadCellPreference } from './utils';

interface UseHeadCellPreferencesOutput {
    headCellDetails: HeadCell<CollaborativeEntity | Creation | StudioProgress>[];
    headCellPreferences: HeadCellPreference[];
    setHeadCellPreferences: React.Dispatch<React.SetStateAction<HeadCellPreference[]>>;
}

const getHeadCellPreferences = (key: string, defaultPreferences: HeadCellPreference[]): HeadCellPreference[] => {
    try {
        const preferences = localStorage.getItem(key);

        if (preferences) {
            const parsedPreferences: HeadCellPreference[] = JSON.parse(preferences);
            const storedColumns = parsedPreferences.map((pref) => pref.id);
            const defaultColumns = defaultPreferences.map((pref) => pref.id);

            const defaultColumnsMatchStoredColumns =
                storedColumns.length === defaultColumns.length &&
                defaultColumns.every((column) => storedColumns.includes(column));

            if (defaultColumnsMatchStoredColumns) {
                return parsedPreferences;
            }
        }

        return defaultPreferences;
    } catch (_err) {
        return defaultPreferences;
    }
};

const saveHeadCellPreferences = (key: string, headCellPreferences: HeadCellPreference[]): void => {
    try {
        localStorage.setItem(key, JSON.stringify(headCellPreferences));
    } catch (_err) {
        console.debug(`Unable to save column preferences for ${key} in local storage`);
    }
};

export const useHeadCellPreferences = (
    key: string,
    defaultPreferences: HeadCellPreference[]
): UseHeadCellPreferencesOutput => {
    const [headCellPreferences, setHeadCellPreferences] = useState(getHeadCellPreferences(key, defaultPreferences));

    useEffect(() => {
        saveHeadCellPreferences(key, headCellPreferences);
    }, [headCellPreferences, key]);

    const headCellDetails = useMemo(
        () =>
            headCellPreferences.filter((headCell) => headCell.checked) as HeadCell<
                CollaborativeEntity | Creation | StudioProgress
            >[],
        [headCellPreferences]
    );

    return { headCellDetails, headCellPreferences, setHeadCellPreferences };
};
