import React, { useContext, useEffect, useState } from 'react';

import { CollectionTabContainer, defaultMuiTheme } from '@adept-at/lib-react-components';
import { Tabs, useMediaQuery } from '@material-ui/core';
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { LearnContext } from 'components/learn/LearnContext';
import { ContentCardsWrapper } from 'components/RootProfileCollection/Collections.styles';
import { StyledIconCloseButton, StyledTab, StyledTabPanel, TabContent } from 'components/Sidebar/index.styles';
import { SidebarContext } from 'components/SidebarContext';
import SkillOutline from 'components/skills/SkillOutline';
import TabPanel from 'components/TabPanel';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { getSections } from 'utils/skill/sections';

import { ContentSection, ContentSectionProps } from './ContentSection';
import { Description, DescriptionProps } from './Description';
import { ParentCollectionCard } from './ParentCollectionCard';

const StyledContentTabPanel = styled(TabPanel)`
    border-radius: 4px;
    background-color: ${(props) => props.theme.colors.bottomSurface};
    border: 1px solid ${(props) => props.theme.colors.border};
    height: 100%;
`;

const TabsContainer = styled.div`
    background-color: transparent;
    display: flex;
    align-items: center;
`;

export const CollectionTabs: React.FC<ContentSectionProps & DescriptionProps> = (contentSectionProps) => {
    return (
        <CollectionTabContainer>
            <CollectionTabsContent {...contentSectionProps} />
        </CollectionTabContainer>
    );
};

export const CollectionTabsContent: React.FC<ContentSectionProps & Partial<DescriptionProps>> = (props) => {
    const [tabIndex, setTabIndex] = useState(0);
    const learnSkill = useContext(LearnContext);
    const { close } = useContext(SidebarContext);
    const { skillSlug } = useParams<{ skillSlug?: string }>();
    const showCollectionDescription = useMediaQuery(defaultMuiTheme.breakpoints.down('md'));

    const handleTabChange = (event: React.ChangeEvent<any>, newValue: number) => {
        setTabIndex(newValue);
    };

    useEffect(() => {
        if (skillSlug) {
            setTabIndex(1);
        }
    }, [skillSlug, setTabIndex]);

    return (
        <>
            <TabsContainer>
                <Tabs
                    variant="scrollable"
                    scrollButtons="off"
                    value={tabIndex}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <StyledTab aria-label="collection content" label="Content" />
                    {skillSlug ? <StyledTab aria-label="collection skill outline" label="Outline" /> : null}
                    {showCollectionDescription && !skillSlug ? (
                        <StyledTab aria-label="collection description" label="About" />
                    ) : null}
                    <StyledTab aria-label="collection about" label={skillSlug ? 'About' : 'FAQs'} />
                </Tabs>
                {skillSlug ? (
                    <StyledIconCloseButton aria-label="close sidebar" onClick={() => close()}>
                        <Icon path={mdiClose} />
                    </StyledIconCloseButton>
                ) : null}
            </TabsContainer>
            <StyledContentTabPanel value={tabIndex} index={0}>
                <ParentCollectionCard />
                <TabContent>
                    <ContentSection {...props} />
                </TabContent>
            </StyledContentTabPanel>
            {skillSlug ? (
                <StyledTabPanel value={tabIndex} index={1} padding="2rem">
                    <SkillOutline sections={getSections(learnSkill?.skill)} />
                </StyledTabPanel>
            ) : null}
            {showCollectionDescription && !skillSlug ? (
                <StyledContentTabPanel value={tabIndex} index={1}>
                    <ContentCardsWrapper $viewingInCollection>
                        <Description {...(props as DescriptionProps)} />
                    </ContentCardsWrapper>
                </StyledContentTabPanel>
            ) : null}
            <StyledContentTabPanel value={tabIndex} index={skillSlug || showCollectionDescription ? 2 : 1}>
                <ContentCardsWrapper $viewingInCollection />
                {/* TODO: add in about info from org catalog or myProfile root */}
            </StyledContentTabPanel>
        </>
    );
};
