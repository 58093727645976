import { OwnerType } from 'components/ContentContext/Interfaces';
import { CollectionChild } from 'components/RootProfileCollection/context/useGetTenantProfileForOwner';
import { GraphQLClient } from 'graphql-request';
import gql from 'graphql-tag';
import buildGqlQueryFn from 'hooks/useGqlClient/helpers/buildGqlQueryFn';
import { QueryFunction, QueryKey } from 'react-query';

export const buildGetOrganizationRootCollectionsQueryKey = (organizationId: string): QueryKey => [
    'getOrganizationRootCollections',
    organizationId
];

export interface CollectionChildren {
    children: {
        id: string;
        type: CollectionChild;
    }[];
}

export interface GetOrganizationCollectionsQueryResponse {
    getTenantProfileForOwner: {
        tenantSlug: string;
        rootCollection: CollectionChildren;
        privateRootCollection: CollectionChildren;
    };
}

export const getOrganizationRootCollectionsQuery = gql`
    query getTenantProfileForOwner($owner: OwnerInfo!) {
        getTenantProfileForOwner(owner: $owner) {
            tenantSlug
            rootCollection {
                children {
                    id
                    type
                }
            }
            privateRootCollection {
                children {
                    id
                    type
                }
            }
        }
    }
`;

const getOrganizationRootCollections = (
    client: GraphQLClient,
    organizationId: string
): QueryFunction<GetOrganizationCollectionsQueryResponse> =>
    buildGqlQueryFn(client, getOrganizationRootCollectionsQuery, {
        owner: { type: OwnerType.Organization, id: organizationId }
    });

export default getOrganizationRootCollections;
