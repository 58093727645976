import { GraphQLClient } from 'graphql-request';
import { API_ACCOUNTS, API_ENDPOINTS } from 'lib/ApiConstants';

export default (token: string, api = API_ACCOUNTS): GraphQLClient =>
    new GraphQLClient(`${API_ENDPOINTS[api]}/graphql`, {
        headers: {
            Authorization: `Bearer ${token}`
        },
        credentials: 'include',
        mode: 'cors'
    });

// export const buildAuthenticatedGqlClient = async (token: string | null, api): Promise<GraphQLClient> => {
//     if (!token) {

//     }
// }
