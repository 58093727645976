import { GraphQLClient } from 'graphql-request';
import gql from 'graphql-tag';
import { MutationFunction } from 'react-query';

import buildGqlMutationFn from '../../../../hooks/useGqlClient/helpers/buildGqlMutationFn';

export const UPSERT_COMPONENT = gql`
    mutation upsertComponent(
        $entity: EntityInfoInput!
        $componentType: Alphanumeric!
        $componentId: Alphanumeric!
        $values: JSON!
        $order: Int
    ) {
        upsertComponent(
            entity: $entity
            componentType: $componentType
            componentId: $componentId
            values: $values
            order: $order
        ) {
            result
            orderedComponents {
                result
                items
            }
        }
    }
`;

const makeUpsertComponent = <TData, TVariables>(client: GraphQLClient): MutationFunction<TData, TVariables> =>
    buildGqlMutationFn<TData, TVariables>(client, UPSERT_COMPONENT);

export default makeUpsertComponent;
