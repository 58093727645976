import React, { useContext } from 'react';

import pluralize from 'pluralize';
import styled from 'styled-components';

import { CommentContext } from './CommentContext';
import { Comment } from './interfaces';

const ThreadButton = styled.div`
    color: ${(props) => props.theme.colors.primary};
    margin-top: 0.5rem;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;

interface Props {
    comment: Comment;
    onThreadClick: () => void;
}

const CommentMeta: React.FC<Props> = ({ comment, onThreadClick }) => {
    const { thread, commentId } = comment;
    const { threadParentComment } = useContext(CommentContext);

    if (thread.length < 1) return null;
    if (commentId === threadParentComment?.commentId) return null;

    return (
        <ThreadButton onClick={onThreadClick}>
            {thread.length} {pluralize('reply', thread.length)}
        </ThreadButton>
    );
};

export default CommentMeta;
