import React from 'react';

import { PDFComponentValues, PDFViewer } from '@adept-at/lib-react-components';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

const PDFView: React.FC<PDFComponentValues> = (component) => {
    return (
        <PDFViewer
            componentValues={component}
            DocumentComponent={Document as unknown as any}
            PageComponent={Page as any}
        />
    );
};

export default PDFView;
