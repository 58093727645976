import React, { useContext } from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { Button, CircularProgress } from '@material-ui/core';
import { EntityBySlug } from 'components/NestedCollection/context/getNestedCollectionBySlug';
import { ProductContext } from 'components/product/ProductContext';
import { EnrollmentInterval } from 'components/product/types';
import { useModal } from 'hooks/useModal';
import { useHistory } from 'react-router';
import { isTrue } from 'utils/isTrue';

import { Descendant, EnrollModal } from './Enroll';
import { useManageEnrollment } from './useManageEnrollment';

export const EnrollmentButton: React.FC<{
    entityData: EntityBySlug;
    tenantId?: string;
    tenantDataLoading: boolean;
    descendants: Descendant[];
    continueLearningUrl: string;
}> = ({ entityData, tenantId, tenantDataLoading, descendants, continueLearningUrl }) => {
    const { product } = useContext(ProductContext);
    const history = useHistory();

    const { modalOpen, handleModalClose, handleModalOpen } = useModal();
    const {
        onEnroll,
        priceToUse,
        currentEnrollment,
        isLoading: enrollmentDataLoading
    } = useManageEnrollment({
        entityData,
        tenantId,
        tenantDataIsLoading: tenantDataLoading
    });

    const onEnrollButtonClick = () => {
        if (!isTrue(currentEnrollment)) {
            handleModalOpen();
            return;
        }
        if (continueLearningUrl) {
            history.push(continueLearningUrl);
        }
    };

    if (enrollmentDataLoading) {
        return <CircularProgress size={IconSize.Medium} color={'primary'} />;
    }

    if (!product || !priceToUse) return null;

    return (
        <>
            <Button
                color="secondary"
                variant="contained"
                disabled={(priceToUse?.pricePerUnitCents ?? 0) > 0}
                onClick={onEnrollButtonClick}
            >
                {isTrue(currentEnrollment) ? 'Continue' : 'Enroll'}
            </Button>
            <EnrollModal
                isOpen={modalOpen}
                currentEnrollment={currentEnrollment ?? null}
                handleClose={handleModalClose}
                onEnroll={onEnroll}
                pricePerIncrement={priceToUse?.pricePerUnitCents ?? 0}
                enrollmentInterval={EnrollmentInterval.week}
                content={entityData}
                descendants={descendants}
                subscriptionData={{ cost: 100, includedCollections: 0 }}
                tenantAlias="unknown"
            />
        </>
    );
};
