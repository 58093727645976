import { useContext } from 'react';

import { HistoricalMessagesReducerState } from 'context/HistoricalCommunicationContext/useHistoricalMessageRepository';
import { RealTimeCommunicationContext, RealTimeMessagesReducerState } from 'context/RealTimeCommunicationContext';
import MessageFactory from 'lib/communication/message/factory';
import { Reactions } from 'lib/communication/message/MessageRecord';

export interface UseUpdateMessageOutput {
    updateMessage: (params: MessageUpdateReducerParams) => void;
}

export interface MessageUpdateReducerParams {
    channelId: string;
    messageId: string;
    updatedText?: string;
    threadDetails?: {
        messageId: string;
        senderId: string;
        modifier: number;
    };
    deletedAt?: string;
    updatedReactions?: Reactions;
}

export const handleMessageUpdateReducer = (
    state: RealTimeMessagesReducerState | HistoricalMessagesReducerState,
    values: MessageUpdateReducerParams
): RealTimeMessagesReducerState | HistoricalMessagesReducerState => {
    const { channelId, messageId, updatedText, threadDetails, deletedAt, updatedReactions } = values;

    if (channelId && messageId && (updatedText || threadDetails || deletedAt || updatedReactions)) {
        const foundMessage = state?.[channelId]?.find((message) => message.id === messageId);

        let updatedMessage;
        if (foundMessage && 'fields' in foundMessage) {
            try {
                const workingMessage = { ...foundMessage };
                if (updatedText) {
                    workingMessage.fields.text = updatedText;
                    workingMessage.version += 1;
                }

                if (updatedReactions) {
                    workingMessage.reactions = updatedReactions;
                }

                if (deletedAt) {
                    workingMessage.deletedAt = deletedAt;
                }

                if (threadDetails) {
                    const { modifier, senderId } = threadDetails;
                    workingMessage.responses = workingMessage.responses + modifier;

                    workingMessage.responders = {
                        ...workingMessage.responders,
                        [senderId]: (workingMessage.responders[senderId] ?? 0) + modifier
                    };
                }

                updatedMessage = MessageFactory.constructWithoutValidation(workingMessage);
            } catch (err) {
                console.warn('Unable to update message', values, err);
            }
        }

        return {
            ...state,
            [channelId]:
                state?.[channelId]?.map((workingMessage) => {
                    const id = workingMessage.id;

                    if (id === messageId && updatedMessage) {
                        return updatedMessage;
                    }

                    return workingMessage;
                }) ?? []
        };
    }

    return state;
};

/**
 * We are unsure if the message is historical or real time
 * Try to optimistically update both - one will be a noop
 *
 * RealTimeCommunicationContext's updateMessage calls HistoricalCommunicationContext's updateMessage
 */
const useUpdateMessage = (): UseUpdateMessageOutput => {
    const { updateMessage } = useContext(RealTimeCommunicationContext);

    return { updateMessage };
};

export default useUpdateMessage;
