import React from 'react';

import * as Code from './Code';
import * as Image from './Image';
import * as Link from './Link';
import * as PDF from './PDF';
import * as Question from './Question';
import * as SectionTitle from './SectionTitle';
import * as Text from './Text';
import * as Video from './Video';
import * as VirtualLab from './VirtualLab';

const typeToComponentMapping = {
    code: Code,
    sectionTitle: SectionTitle,
    text: Text,
    image: Image,
    question: Question,
    virtualLab: VirtualLab,
    video: Video,
    pdf: PDF,
    link: Link,
    talkinghead: Video, // DEPRECATED
    screencapture: Video, // DEPRECATED
    unsplashImage: Image // DEPRECATED
};

export const getComponentForType = (type: string): React.ComponentType<any> => {
    return typeToComponentMapping[type]?.Component;
};

export const getTitleForType = (type: string): string => typeToComponentMapping[type].title;
