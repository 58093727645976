import React, { createContext, useState, useMemo, useContext } from 'react';

import { EntityType } from 'components/ContentContext/Enums';
import BookshelfModal, { ModalView } from 'components/modals/Bookshelves/BookshelfModal';
import { ClientError } from 'graphql-request';
import { useCurrentUser } from 'hooks/useCurrentUser';
import useGqlClient from 'hooks/useGqlClient';
import { API_CONTENT } from 'lib/ApiConstants';
import { useQuery } from 'react-query';

import getItemIdsInBookshelves, {
    GetItemIdsInBookshelvesResponse,
    buildGetItemIdsInBookshelvesQueryKey,
    BookshelfMinimal
} from './hooks/getItemIdsInBookshelves';
import { Bookshelf } from './hooks/useGetBookshelf';
import { useGetBookshelves } from './hooks/useGetBookshelves';

export enum PageView {
    Grid = 'grid',
    List = 'list'
}

export enum SortBy {
    Recent = 'recent',
    Alphabetical = 'alphabetical'
}

const BookshelvesContext = createContext(undefined as unknown as BookshelvesContextInterface);

const { Provider, Consumer } = BookshelvesContext;

interface SelectedItemToAdd {
    id: string;
    type: EntityType;
    title: string;
}

export interface BookshelvesContextInterface {
    view: PageView;
    setView: (view: PageView) => void;

    sortBy: SortBy;
    setSortBy: (view: SortBy) => void;

    selectedItemToAdd: SelectedItemToAdd | null;
    bookshelfModalOpen: boolean;
    openBookshelfModal: (item?: SelectedItemToAdd) => void;
    closeBookshelfModal: () => void;

    bookshelves?: Bookshelf[];
    bookshelvesLoading: boolean;
    bookshelvesErrored: boolean;

    itemIdsInBookshelves?: BookshelfMinimal[];
    itemIdsInBookshelvesLoading: boolean;
    itemIdsInBookshelvesErrored: boolean;
}

const BookshelvesProvider: React.FC<{ initialModalView: ModalView; shouldFetchOnInitialRender: boolean }> = ({
    children,
    initialModalView,
    shouldFetchOnInitialRender
}) => {
    const [view, setView] = useState<PageView>(PageView.Grid);
    const [sortBy, setSortBy] = useState<SortBy>(SortBy.Recent);

    const [bookshelfModalOpen, setBookshelfModalOpen] = useState(false);
    const [selectedItemToAdd, setSelectedItemToAdd] = useState<SelectedItemToAdd | null>(null);

    const { currentUser } = useCurrentUser();
    const { client: contentClient, withQueryOptions } = useGqlClient(API_CONTENT);

    const openBookshelfModal = (item?: SelectedItemToAdd) => {
        if (item) {
            setSelectedItemToAdd(item);
        }

        setBookshelfModalOpen(true);
    };

    const closeBookshelfModal = () => {
        setBookshelfModalOpen(false);
        setSelectedItemToAdd(null);
    };

    const {
        data: bookshelvesData,
        isLoading: bookshelvesLoading,
        isError: bookshelvesErrored
    } = useGetBookshelves(shouldFetchOnInitialRender);

    const bookshelves = useMemo(() => bookshelvesData?.getBookshelves, [bookshelvesData?.getBookshelves]);

    const {
        data: itemIdsInBookshelvesData,
        isLoading: itemIdsInBookshelvesLoading,
        isError: itemIdsInBookshelvesErrored
    } = useQuery<GetItemIdsInBookshelvesResponse, ClientError>(
        withQueryOptions({
            queryKey: buildGetItemIdsInBookshelvesQueryKey(currentUser?.userId),
            queryFn: getItemIdsInBookshelves(contentClient),
            enabled: bookshelfModalOpen
        })
    );

    const itemIdsInBookshelves = useMemo(
        () => itemIdsInBookshelvesData?.getBookshelves,
        [itemIdsInBookshelvesData?.getBookshelves]
    );

    return (
        <Provider
            value={{
                view,
                setView,

                sortBy,
                setSortBy,

                selectedItemToAdd,
                bookshelfModalOpen,
                openBookshelfModal,
                closeBookshelfModal,

                bookshelves,
                bookshelvesLoading,
                bookshelvesErrored,

                itemIdsInBookshelves,
                itemIdsInBookshelvesLoading,
                itemIdsInBookshelvesErrored
            }}
        >
            {children}
            <BookshelfModal initialView={initialModalView} />
        </Provider>
    );
};

export { BookshelvesContext, BookshelvesProvider, Consumer as BookshelvesConsumer };

export const useBookShelves = (): BookshelvesContextInterface => {
    const context = useContext(BookshelvesContext);

    if (!context) {
        throw new Error('useBookShelves must be used within a BookshelvesProvider');
    }

    return context;
};
