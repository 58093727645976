import { PresenceStatus } from 'components/communication/UserPresenceCard';
import * as yup from 'yup';

import { MessageEventType } from '..';
import AckableAdepteductMessage from '../../base/AckableAdepteductMessage';
import PossibleEventFields from '../PossibleEventFields';

interface EventFieldsPresenceChange extends PossibleEventFields {
    status: PresenceStatus;
}

export const DEFAULT_TTL_IN_MS = 5;

export default class PresenceChange extends AckableAdepteductMessage<EventFieldsPresenceChange> {
    schema = yup.object({
        status: yup.string().required(),
        reason: yup.string()
    });

    type = MessageEventType.PresenceChange;

    ttl = DEFAULT_TTL_IN_MS;
}
