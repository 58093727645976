import { OptimistMutationContext, useOptimist } from '@adept-at/lib-react-optimist';
import { ClientError } from 'graphql-request';
import gql from 'graphql-tag';
import { useBuildAuthenticatedMutationFn } from 'hooks/useGqlClient/useBuildAuthenticatedMutationFn';
import { Api } from 'lib/ApiConstants';
import { useSnackbar } from 'notistack';
import { useMutation, UseMutationResult } from 'react-query';
import { setCollectionEditedInSessionStorage } from 'utils/collection';

export const DISCARD_EDITS = gql`
    mutation discardEdits($collectionId: UUIDv4!, $expectedInfoVersion: Int!, $expectedItemsVersion: Int!) {
        discardEdits(
            collectionId: $collectionId
            expectedInfoVersion: $expectedInfoVersion
            expectedItemsVersion: $expectedItemsVersion
        ) {
            success
        }
    }
`;

export interface DiscardEditsResponse {
    discardEdits: {
        success: boolean;
    };
}

export interface DiscardEditsVariables {
    collectionId: string;
    expectedInfoVersion: number;
    expectedItemsVersion: number;
}

export const useDiscardEdits = (
    setPendingChanges: React.Dispatch<React.SetStateAction<boolean>>,
    refetchEditableCollection: () => void,
    parentQuerySlug: string
): UseMutationResult<DiscardEditsResponse, ClientError, DiscardEditsVariables, OptimistMutationContext> => {
    const { mutationOptions } = useOptimist<DiscardEditsResponse, ClientError, DiscardEditsVariables>();

    const { mutationFn } = useBuildAuthenticatedMutationFn<DiscardEditsResponse, DiscardEditsVariables>(DISCARD_EDITS, {
        api: Api.Content
    });

    const { enqueueSnackbar } = useSnackbar();

    return useMutation<DiscardEditsResponse, ClientError, DiscardEditsVariables, OptimistMutationContext>(mutationFn, {
        ...mutationOptions,
        onError: () => {
            enqueueSnackbar('An error occurred while discarding edits', { variant: 'error' });
        },
        onSuccess: (data, request) => {
            if (!data.discardEdits) {
                // versions out of sync - refresh view for user ?
                enqueueSnackbar('Editable collection version was out of sync, please try to discard changes again', {
                    variant: 'warning'
                });
            }

            if (data.discardEdits?.success === true) {
                enqueueSnackbar('Successfully discarded edits', { variant: 'success' });
                setCollectionEditedInSessionStorage(request.collectionId, parentQuerySlug);
                setPendingChanges(false);
            }

            refetchEditableCollection();
        }
    });
};
