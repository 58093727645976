import { GraphQLClient } from 'graphql-request';
import gql from 'graphql-tag';
import buildGqlQueryFn from 'hooks/useGqlClient/helpers/buildGqlQueryFn';
import { QueryFunction, QueryKey } from 'react-query';

import { CollectionChildren } from './getOrganizationRootCollections';

export const buildGetCollectionChildrenQueryKey = (collectionId?: string): QueryKey => [
    'getCollectionChildren',
    collectionId
];

export interface GetCollectionChildrenResponse {
    getCollection: CollectionChildren;
}

export const getCollectionChildrenQuery = gql`
    query getCollection($collectionId: UUIDv4!) {
        getCollection(collectionId: $collectionId) {
            children {
                id
                type
            }
        }
    }
`;

const getCollectionChildren = (
    client: GraphQLClient,
    collectionId: string
): QueryFunction<GetCollectionChildrenResponse> =>
    buildGqlQueryFn(client, getCollectionChildrenQuery, { collectionId });

export default getCollectionChildren;
