export class GraphqlError extends Error {
    readonly code: string;
    readonly message: string;

    constructor({ code, message }: { code: string; message: string }) {
        super(message);

        Object.defineProperty(this, 'name', { value: 'GraphqlError' });
        Object.setPrototypeOf(this, new.target.prototype);

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, this.constructor);
        }

        this.code = code;
        this.message = message;
    }
}
