import React, { useRef, useState, useContext } from 'react';

import { EditorContext } from 'components/builder/context/EditorContext';
import { ComponentFocus } from 'components/engine/mixins/editor';
import { useFocusableComponent } from 'components/engine/mixins/focusable';
import styled from 'styled-components';
import { SKILL_CONTENT_WIDTH } from 'styles/variables';

import { ComponentEngineComponent } from '../../engine';
import { ControlOptions } from '../../engine/ComponentControls';
import { EngineComponent } from '../../engine/types';
import { Spacer, ComponentContainer } from '../Builder.styles';

const Container = styled(ComponentContainer)`
    max-width: ${SKILL_CONTENT_WIDTH}px;
    padding: 8px;

    ${(props) => (props.isDeleteFocus ? `border: 4px solid red` : '')};

    ${(props) =>
        props.controlsVisible && !props.isPreview
            ? `
        border-radius: 4px;
        background-color: ${(props) => props.theme.colors.surface};
    `
            : ''};

    ${(props) =>
        props.fullBleed
            ? `
                padding: 0;
                max-width: 100%;`
            : ''};
`;

interface BuilderComponentProps {
    component: EngineComponent | null;
    controlOptions: ControlOptions;
}

const BuilderComponent: React.FC<BuilderComponentProps> = ({
    component: { id, type, order, ...values },
    controlOptions
}) => {
    const [controlsVisible, setControlsVisible] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const isFullBleed = false;
    const { isPreview } = useContext(EditorContext);
    const { doesComponentHaveFocus } = useFocusableComponent();
    return (
        <>
            {type === 'sectionTitle' ? <Spacer /> : null}

            <div>
                <Container
                    ref={ref}
                    id={`component-${id}`}
                    data-testid={'component-container'}
                    fullBleed={isFullBleed}
                    onMouseEnter={() => setControlsVisible(true)}
                    onMouseLeave={() => setControlsVisible(false)}
                    controlsVisible={controlsVisible}
                    type={type}
                    isPreview={isPreview()}
                    isDeleteFocus={doesComponentHaveFocus(id, ComponentFocus.Remove)}
                >
                    <ComponentEngineComponent
                        container={ref}
                        id={id}
                        type={type}
                        order={order}
                        values={values}
                        controlOptions={controlOptions}
                    />
                </Container>
            </div>
        </>
    );
};

export default BuilderComponent;
