import React, { useState, useMemo } from 'react';

import { CollectionImage, SkillImage } from '@adept-at/lib-react-components';
import { Typography, FormControl, InputLabel, OutlinedInput, Switch } from '@material-ui/core';
import { PreviewToggle, ToggleState } from 'components/builder/BuildingHeader';
import { EntityType } from 'components/ContentContext/Enums';
import styled from 'styled-components';

import { PreviewWrapper } from './SEOSettings';

const StyledTitle = styled(Typography)`
    line-height: 20px;
    color: ${(props) => props.theme.colors.textSecondary};
    font-size: 0.75rem;
    letter-spacing: 0.08333em;
    text-transform: uppercase;
    margin-bottom: 4px;
`;

const Container = styled.div`
    width: 100%;
`;

const ImageWrapper = styled.div`
    margin-bottom: 1rem;
`;

const StyledContentImage = styled.div<{ $image?: string | null }>`
    background-image: ${(props) => `url(${props.$image})`};
    background-size: cover;
    width: 124px;
    height: 124px;
    border-radius: 50%;
`;

const TitleTag = styled(Typography)`
    margin-bottom: 8px;
    font-size: 1.25rem;
    /* These preview components should't change between light / dark mode according to design */
    color: rgba(26, 35, 39, 0.8);
`;

const SearchDescription = styled(Typography)`
    font-size: 0.875rem;
    color: rgba(26, 35, 39, 0.8);
`;

const FormWrapper = styled.div`
    margin: 2rem 0;

    .MuiFormControl-root {
        width: 100%;
    }

    .MuiFormHelperText-root {
        margin-left: 4px;
        margin-top: 4px;
        color: ${(props) => props.theme.colors.textTertiary};
        font-size: 0.875rem;
        letter-spacing: 0;
        line-height: inherit;
    }
`;

interface OpenGraphSettingsProps {
    contentType: EntityType;
    contentTitle?: string;
    contentDescription?: string | null;
    catalogImage: string | null | undefined;
}

export const OpenGraphSettings: React.FC<OpenGraphSettingsProps> = ({
    contentType,
    contentTitle,
    contentDescription,
    catalogImage
}) => {
    const [title, setTitle] = useState<string>(contentTitle ?? '');
    const [description, setDescription] = useState<string>(contentDescription ?? '');
    const [ogImage, setOgImage] = useState<string>(catalogImage ?? '');

    const [checked, setChecked] = useState({
        title: true,
        metaDesc: true,
        ogImage: true
    });

    const renderOgTitle = useMemo(() => {
        if (contentTitle && checked.title) {
            return contentTitle;
        } else if (contentTitle && !checked.title) {
            return title;
        }
    }, [title, checked, contentTitle]);

    const renderMetaDescription = useMemo(() => {
        if (contentDescription && checked.metaDesc) {
            return contentDescription;
        } else if (contentDescription && !checked.metaDesc) {
            return description;
        }
    }, [description, checked, contentDescription]);

    const renderContentImage = useMemo(() => {
        if (ogImage) {
            if (checked.ogImage) {
                return <StyledContentImage $image={catalogImage} />;
            } else {
                return <StyledContentImage $image={ogImage} />;
            }
        }
        return contentType === EntityType.COLLECTION ? <CollectionImage.Large /> : <SkillImage.Large />;
    }, [ogImage, checked, catalogImage, contentType]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked({ ...checked, [event.target.name]: event.target.checked });
    };

    return (
        <Container>
            <StyledTitle>Open Graph Preview</StyledTitle>
            <PreviewWrapper>
                <ImageWrapper>{renderContentImage}</ImageWrapper>
                <TitleTag>{renderOgTitle}</TitleTag>
                <SearchDescription>{renderMetaDescription}</SearchDescription>
            </PreviewWrapper>
            <FormWrapper>
                <FormControl variant="outlined">
                    <InputLabel htmlFor="url-slug">Open Graph title</InputLabel>
                    <OutlinedInput
                        id="url-slug"
                        label="Open Graph title"
                        defaultValue={title}
                        value={renderOgTitle}
                        disabled={checked.title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                    <PreviewToggle>
                        <Switch name="title" color="primary" checked={checked.title} onChange={handleChange} />
                        <ToggleState isActive={false}>Use SEO tile tag</ToggleState>
                    </PreviewToggle>
                </FormControl>
            </FormWrapper>
            <FormWrapper>
                <FormControl variant="outlined">
                    <InputLabel htmlFor="meta-description">Meta Description tag</InputLabel>
                    <OutlinedInput
                        multiline
                        rows={3}
                        maxRows={4}
                        id="meta-description"
                        label="Meta Description tag"
                        disabled={checked.metaDesc}
                        defaultValue={description}
                        value={renderMetaDescription}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    <PreviewToggle>
                        <Switch name="metaDesc" color="primary" checked={checked.metaDesc} onChange={handleChange} />
                        <ToggleState isActive={false}>Use SEO meta description tag</ToggleState>
                    </PreviewToggle>
                </FormControl>
            </FormWrapper>
            <FormWrapper>
                <FormControl variant="outlined">
                    <InputLabel htmlFor="og-image">Open Graph image URL</InputLabel>
                    <OutlinedInput
                        id="og-image"
                        label="Open Graph image URL"
                        disabled={checked.ogImage}
                        onChange={(e) => setOgImage(e.target.value)}
                    />
                    <PreviewToggle>
                        <Switch name="ogImage" color="primary" checked={checked.ogImage} onChange={handleChange} />
                        <ToggleState isActive={false}>Use SEO catalog image</ToggleState>
                    </PreviewToggle>
                </FormControl>
            </FormWrapper>
        </Container>
    );
};
