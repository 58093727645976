import React from 'react';

const PrivateRoom: React.FC = () => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 24 24"
            xmlSpace="preserve"
            fill="currentColor"
        >
            <title>Private room</title>
            <path
                d="M6,13.3l3-1.2V7.4L6,8.6V13.3z M12.6,19.8L12,20l-6-2.4l6-2.4l1.4,0.5c0.4-0.6,0.8-1.2,1.3-1.6L13,13.5V4.4l7,2.8v5.4
	c0.7,0.1,1.4,0.3,2,0.7V6.5c0-0.4-0.2-0.8-0.6-0.9l-8.6-3.5c-0.5-0.2-1-0.2-1.5,0L2.6,5.6C2.2,5.7,2,6.1,2,6.5v11
	c0,0.4,0.2,0.8,0.6,0.9l8.6,3.5c0.5,0.2,1,0.2,1.5,0l0.4-0.1C12.8,21.1,12.6,20.5,12.6,19.8z M4,7.2l7-2.8v9.1l-7,2.8V7.2z M15,13.9
	c0.9-0.7,1.9-1.1,3-1.3v-4l-3-1.2V13.9z M19,14c1.6,0,2.9,1.2,2.9,2.8v0.6c0.7,0,1.1,0.4,1.1,1.1v3.4c0,0.7-0.5,1.1-1.1,1.1h-5.7
	c-0.7,0-1.1-0.4-1.1-1.1v-3.4c0-0.7,0.5-1.1,1.1-1.1v-0.6C16.1,15.2,17.4,14,19,14 M19,15.1c-0.9,0-1.7,0.8-1.7,1.7v0.6h3.4v-0.6
	C20.7,15.9,19.9,15.1,19,15.1z"
            />
        </svg>
    );
};

export const PrivateRoomPath =
    'M6,13.3l3-1.2V7.4L6,8.6V13.3z M12.6,19.8L12,20l-6-2.4l6-2.4l1.4,0.5c0.4-0.6,0.8-1.2,1.3-1.6L13,13.5V4.4l7,2.8v5.4c0.7,0.1,1.4,0.3,2,0.7V6.5c0-0.4-0.2-0.8-0.6-0.9l-8.6-3.5c-0.5-0.2-1-0.2-1.5,0L2.6,5.6C2.2,5.7,2,6.1,2,6.5v11c0,0.4,0.2,0.8,0.6,0.9l8.6,3.5c0.5,0.2,1,0.2,1.5,0l0.4-0.1C12.8,21.1,12.6,20.5,12.6,19.8z M4,7.2l7-2.8v9.1l-7,2.8V7.2z M15,13.9c0.9-0.7,1.9-1.1,3-1.3v-4l-3-1.2V13.9z M19,14c1.6,0,2.9,1.2,2.9,2.8v0.6c0.7,0,1.1,0.4,1.1,1.1v3.4c0,0.7-0.5,1.1-1.1,1.1h-5.7c-0.7,0-1.1-0.4-1.1-1.1v-3.4c0-0.7,0.5-1.1,1.1-1.1v-0.6C16.1,15.2,17.4,14,19,14 M19,15.1c-0.9,0-1.7,0.8-1.7,1.7v0.6h3.4v-0.6C20.7,15.9,19.9,15.1,19,15.1z';

export default PrivateRoom;
