import React, { ReactElement, useContext, useMemo } from 'react';

import { ShareMenu, TooltippedIconButton } from '@adept-at/lib-react-components';
import { Divider } from '@material-ui/core';
import { mdiBookshelf, mdiCalendarClock, mdiDelete, mdiDotsVertical, mdiHeart, mdiOpenInNew, mdiPencil } from '@mdi/js';
import Icon from '@mdi/react';
import { BookshelvesContext } from 'components/bookshelves/BookshelvesContext';
import { EntityType } from 'components/ContentContext/Enums';
import { useFavorites } from 'components/dash/Favorites/useFavorites';
import useRemoveFavoriteItem from 'components/dash/hooks/useRemoveFavoriteItem';
import useUpsertFavoriteItem from 'components/dash/hooks/useUpsertFavoriteItem';
import { ScheduleContext } from 'components/dash/Schedule/ScheduleContext';
import { useOrganizationTerminology } from 'hooks/useOrganizationTerminology';
import { usePopover } from 'hooks/usePopover';
import { StyledListItem, StyledMenu } from 'styles/Shared.styles';

import { useEditItemContext } from './EditItemContext';
import { Creation } from './utils';

interface ContextMenuProps<T> {
    item: T;
}

const getEntityTypeTerm = (terminology, type) => {
    if (type === EntityType.SKILL) {
        return terminology.skill;
    }
    if (type === EntityType.COLLECTION) {
        return terminology.collection;
    }
    return type;
};

export const ContextMenu = <T extends Creation>({ item }: ContextMenuProps<T>): ReactElement => {
    const { open, anchorEl, handlePopoverClick, handlePopoverClose } = usePopover();
    const { openLearnLaterModal } = useContext(ScheduleContext);
    const { openBookshelfModal } = useContext(BookshelvesContext);
    const { editItem } = useEditItemContext();
    const { terminology } = useOrganizationTerminology();

    const { favorites } = useFavorites();
    const isFavorited = useMemo(
        () =>
            !!favorites.find((favorite) => {
                if ('skillId' in favorite) {
                    return favorite.skillId === item.id;
                }
                if ('collectionId' in favorite) {
                    return favorite.collectionId === item.id;
                }
                return false;
            }),
        [favorites, item.id]
    );
    const { mutate: addToFavorites } = useUpsertFavoriteItem();
    const { mutate: removeFromFavorites } = useRemoveFavoriteItem();

    const itemType = useMemo(() => getEntityTypeTerm(terminology, item.type), [terminology, item.type]);

    const handleEdit = () => {
        editItem(item);
        handlePopoverClose();
    };

    return (
        <>
            <TooltippedIconButton
                aria-label="More actions for item"
                icon={<Icon path={mdiDotsVertical} />}
                title="More actions"
                onClick={(e) => {
                    e.stopPropagation();
                    handlePopoverClick(e);
                }}
            />
            <StyledMenu
                anchorEl={anchorEl}
                open={open}
                onClose={handlePopoverClose}
                onClick={(e) => e.stopPropagation()}
            >
                <StyledListItem
                    button
                    onClick={() => (item.consumptionUrl ? window.open(item.consumptionUrl) : window.open(item.editUrl))}
                >
                    <Icon path={mdiOpenInNew} /> View {itemType}
                </StyledListItem>
                <StyledListItem
                    button
                    onClick={() => {
                        openLearnLaterModal({
                            type: item.type,
                            id: item.id
                        });
                        handlePopoverClose();
                    }}
                >
                    <Icon path={mdiCalendarClock} /> Learn later
                </StyledListItem>
                <StyledListItem
                    button
                    onClick={() => {
                        openBookshelfModal({ type: item.type, id: item.id, title: item.title });
                        handlePopoverClose();
                    }}
                >
                    <Icon path={mdiBookshelf} /> Add to bookshelf
                </StyledListItem>
                <StyledListItem
                    button
                    onClick={() => {
                        const entity = { type: item.type, id: item.id };
                        isFavorited ? removeFromFavorites({ item: entity }) : addToFavorites({ item: entity });
                        handlePopoverClose();
                    }}
                >
                    <Icon path={mdiHeart} /> {`${isFavorited ? 'Remove from' : 'Add to'} favorites`}
                </StyledListItem>
                <ShareMenu closeParent={handlePopoverClose} />
                <Divider />
                <StyledListItem button onClick={handleEdit}>
                    <Icon path={mdiPencil} /> Edit {itemType}
                </StyledListItem>
                <StyledListItem button disabled>
                    <Icon path={mdiDelete} /> Delete {itemType}
                </StyledListItem>
            </StyledMenu>
        </>
    );
};
