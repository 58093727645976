import React from 'react';

import { Button, Divider } from '@material-ui/core';
import styled from 'styled-components';

const AbsoluteContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 15%;
    bottom: 0;
`;
const FlexFooter = styled.div`
    display: flex;
    margin: 1rem 1rem 0 1rem;
`;

const StyledDivider = styled(Divider)`
    margin-bottom: 1.5rem;
`;
const StyledButton = styled(Button)`
    position: absolute;
    right: 0%;
`;

const SharingFooter: React.FC<{ onFinished?: () => void }> = ({ onFinished }) => {
    return (
        <AbsoluteContainer>
            <StyledDivider />
            <FlexFooter>
                <StyledButton
                    onClick={() => {
                        if (onFinished) onFinished();
                    }}
                    variant="contained"
                    color="primary"
                >
                    Done
                </StyledButton>
            </FlexFooter>
        </AbsoluteContainer>
    );
};

export default SharingFooter;
