import { collectionBasicDetailFragment } from 'components/RootProfileCollection/fragments';
import { CollectionBasicDetail } from 'components/RootProfileCollection/useGetNestedCollections/getCollectionBasicDetails';
import { ClientError, gql } from 'graphql-request';
import { useBuildAuthenticatedQueryFn } from 'hooks/useGqlClient/useBuildAuthenticatedQueryFn';
import { Api } from 'lib/ApiConstants';
import { ADEPT_USE_PRIMARY_HEADER } from 'lib/constants';
import { QueryKey, useQuery, UseQueryResult } from 'react-query';

export interface GetMarketplaceListingsResponse {
    getMarketplaceListings: CollectionBasicDetail[];
}

// @GRAPHQL Query getMarketplaceListings
const queryName = 'getMarketplaceListings';

export const buildMarketplaceQueryKey = (): QueryKey => [queryName];

const GET_MARKETPLACE_LISTINGS = gql`
    query getMarketplaceListings($fifo: Boolean) {
        getMarketplaceListings(fifo: $fifo) {
            ${collectionBasicDetailFragment}
        }
    }
`;

export const useGetMarketplaceListings = (
    fifo = true,
    usePrimary = false,
    enabled = true
): UseQueryResult<GetMarketplaceListingsResponse, ClientError> => {
    const { queryFn } = useBuildAuthenticatedQueryFn<GetMarketplaceListingsResponse, { fifo }>(
        GET_MARKETPLACE_LISTINGS,
        { fifo },
        { api: Api.Content, headers: { [ADEPT_USE_PRIMARY_HEADER]: usePrimary } }
    );

    return useQuery<GetMarketplaceListingsResponse, ClientError>(buildMarketplaceQueryKey(), {
        queryFn,
        enabled: enabled
    });
};
