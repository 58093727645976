import React, { createContext, useContext, ComponentType, ReactElement, Fragment, FC } from 'react';

import { EntityType } from '../ContentContext/Enums';

import { ComponentControlsProps } from './ComponentControls';
import { ComponentEngineMode, EngineComponent } from './types';

export interface EngineContextInterface {
    components: Record<string, EngineComponent>;
    container: ContainerEntity;
    mode: ComponentEngineMode;

    containerRefetch?: () => void;

    ComponentControls: ComponentType<ComponentControlsProps>;
    ComponentTitle: ComponentType;
}

const EngineContext = createContext({} as EngineContextInterface);

const { Provider, Consumer } = EngineContext;

/**
 * Construct an id that is unique to all the components in the skill
 *
 * @param existingComponents
 */
const getNewComponentId = (existingComponents: Record<string, EngineComponent>) => {
    const newComponentId = Math.random().toString(36).replace('0.', '').substr(3);

    if (newComponentId in existingComponents) {
        return getNewComponentId(existingComponents);
    }

    return newComponentId;
};

export interface EngineProviderProps {
    container: ContainerEntity;
    components: Record<string, EngineComponent>;
    controlsComponent?: ComponentType<ComponentControlsProps>;
    titleComponent?: ComponentType;
    mode: ComponentEngineMode;
    children: JSX.Element[] | ReactElement | null;
    containerRefetch?: () => void;
}

export interface ContainerEntity {
    id: string;
    type: EntityType;
}

const EngineProvider: FC<EngineProviderProps> = ({
    container,
    containerRefetch,
    components = {},
    controlsComponent,
    titleComponent,
    children,
    mode
}) => (
    <Provider
        value={{
            container,
            components,
            mode,

            containerRefetch,

            ComponentControls: controlsComponent || Fragment,
            ComponentTitle: titleComponent || Fragment
        }}
    >
        {children}
    </Provider>
);

export const useComponentEngine = (): EngineContextInterface => {
    const context = useContext(EngineContext);
    if (!context) {
        throw new Error('useComponentEngine must be used within a ComponentEngineContext');
    }

    return context;
};

export { EngineProvider, EngineContext, Consumer as EngineConsumer };
