import { useEffect } from 'react';

import { useCollector } from 'components/devtools/Collector';
import { useAuth } from 'context/AuthContext';

export const useHistory = (): void => {
    const { status: authStatus } = useAuth();
    const { addHistory } = useCollector();

    useEffect(() => {
        addHistory('authStatus', authStatus);
    }, [authStatus, addHistory]);
};
