import React from 'react';

import { useMediaQuery } from '@material-ui/core';
import { BookshelvesProvider } from 'components/bookshelves/BookshelvesContext';
import { GuidanceProvider } from 'components/builder/Guidance/GuidanceContext';
import { ScheduleProvider } from 'components/dash/Schedule/ScheduleContext';
import { AccountabilityProvider } from 'components/learn/modes/accountability/AccountabilityContext';
import { AssessmentProvider } from 'components/learn/modes/assessment/AssessmentContext';
import { ScrollProvider } from 'components/learn/ScrollContext';
import { ModalView } from 'components/modals/Bookshelves/BookshelfModal';
import { SidebarProvider } from 'components/SidebarContext';
import { QueryStringProvider } from 'context/QueryStringContext';

const SkillProviders: React.FC = ({ children }) => {
    return (
        <QueryStringProvider>
            <AccountabilityProvider>
                <AssessmentProvider>
                    <SidebarProvider>
                        <GuidanceProvider>
                            <ScrollProvider>
                                <BookshelvesProvider
                                    shouldFetchOnInitialRender={false}
                                    initialModalView={ModalView.CreateNew}
                                >
                                    <ScheduleProvider>{children}</ScheduleProvider>
                                </BookshelvesProvider>
                            </ScrollProvider>
                        </GuidanceProvider>
                    </SidebarProvider>
                </AssessmentProvider>
            </AccountabilityProvider>
        </QueryStringProvider>
    );
};

export default SkillProviders;
