import React, { useContext, useMemo, useEffect } from 'react';

import Alert from '@material-ui/lab/Alert';
import { ClientError } from 'graphql-request';
import { QueryKey, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import getAssessmentById, { GetAssessmentByIdResponse } from '../../components/assessments/queries/getAssessmentById';
import Loading from '../../components/builder/Loading';
import { NavContext } from '../../components/navigation/NavContext';
import { ProfileContext } from '../../context/ProfileContext';
import useGqlClient from '../../hooks/useGqlClient';
import AppLayout from '../../layouts/AppLayout';
import { API_CONTENT } from '../../lib/ApiConstants';
import { isForbiddenError } from '../../utils/error';

import Editor from './Editor';

const LoadingView = styled.div`
    background: inherit;
    color: initial;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`;

export const buildGetAssessmentByIdQueryKey = (id: string): QueryKey => ['getAssessmentById', id];

const WithNavigationClosed: React.FC = () => {
    const { openNavbar } = useContext(NavContext);

    useEffect(() => {
        openNavbar({ isOpen: false });
    }, [openNavbar]);

    return null;
};

const AssessmentEdit: React.FC = () => {
    const { currentProfile } = useContext(ProfileContext);
    const { id: assessmentId, editMode } = useParams<{ id: string; editMode: any }>();

    const queryKey = useMemo(() => buildGetAssessmentByIdQueryKey(assessmentId), [assessmentId]);
    const { client, withQueryOptions } = useGqlClient(API_CONTENT);

    const { status, error, data } = useQuery<GetAssessmentByIdResponse, ClientError>(
        withQueryOptions({ queryKey, queryFn: getAssessmentById(client, { assessmentId }), retry: false })
    );

    const assessmentDetails = useMemo(() => data?.getAssessmentById || {}, [data]);

    if (error) {
        return isForbiddenError(error) ? (
            <AppLayout>
                <Alert severity="warning" variant="filled">
                    {`You don't have access to this content.`}
                </Alert>
            </AppLayout>
        ) : (
            <AppLayout>
                <Alert severity="warning" variant="filled">
                    There was an issue loading this assessment. Please contact our team.
                </Alert>
            </AppLayout>
        );
    }

    if (status === 'loading' || !currentProfile) {
        return (
            <AppLayout>
                <LoadingView data-testid={`loadingAssessment-${assessmentId}`}>
                    <Loading />
                </LoadingView>
            </AppLayout>
        );
    }

    return (
        <AppLayout withoutPadding pageTitle="Assessment">
            <WithNavigationClosed />
            <Editor
                // queryKey={queryKey}

                // editMode={editMode}
                // TODO: Reconcile these types
                assessmentDetails={assessmentDetails}
                assessmentId={assessmentId}
                // comments={comments}
            />
        </AppLayout>
    );
};

export default AssessmentEdit;
