import { useSnackbar } from 'notistack';

interface UseCreateBookshelfNotifsInterface {
    onError: () => void;
    onSuccess: () => void;
}

export const useCreateBookshelfNotifs = (): UseCreateBookshelfNotifsInterface => {
    const { enqueueSnackbar } = useSnackbar();

    return {
        onError: () => {
            enqueueSnackbar('An error occurred. Unable to create bookshelf', { variant: 'error' });
        },
        onSuccess: () => {
            enqueueSnackbar('Bookshelf created', { variant: 'success' });
        }
    };
};
