import React, { createContext, useContext, useState, useCallback, useMemo } from 'react';

import { ClientError } from 'graphql-request';
import useGqlClient from 'hooks/useGqlClient';
import AdeptOwnerType from 'lib/AdeptOwnerType';
import { API_COMMUNICATION } from 'lib/ApiConstants';
import { useQuery, QueryStatus, RefetchOptions, QueryObserverResult } from 'react-query';

import { ProfileContext, ProfileType } from '../ProfileContext';

import getChannelsForUserQueryFn, { ChannelDetails, ChannelType, ChannelsForUserResult } from './getChannelsForUser';

export interface FindChannelsFilter {
    ids?: string[];
    type?: ChannelType;
    name?: string;
    owner?: string;
}

export interface CommunicationChannelsProviderInterface {
    error?: ClientError | null;
    status: QueryStatus;
    selectChannel: (channelIdToSelect: string) => void;
    selectedChannel: ChannelDetails | null;
    availableChannels: ChannelDetails[];
    channelsForProfile: ChannelDetails[];
    findChannels: (filter: FindChannelsFilter) => ChannelDetails[];
    getChannelDetails: (channelId: string) => ChannelDetails | null;
    fetchChannelsForUser: (
        options?: RefetchOptions | undefined
    ) => Promise<QueryObserverResult<ChannelsForUserResult, ClientError>>;
}

const CommunicationChannelsContext = createContext(undefined as unknown as CommunicationChannelsProviderInterface);

const { Provider, Consumer } = CommunicationChannelsContext;

const CommunicationChannelsProvider: React.FC = ({ children }) => {
    const { currentProfile } = useContext(ProfileContext);

    const [selectedChannel, setSelectedChannel] = useState<ChannelDetails | null>(null);

    const { client, withQueryOptions } = useGqlClient(API_COMMUNICATION);
    const {
        status,
        error,
        data,
        refetch: fetchChannelsForUser
    } = useQuery<ChannelsForUserResult, ClientError>(
        withQueryOptions({
            queryKey: 'getChannelsForUser',
            queryFn: getChannelsForUserQueryFn(client),
            retry: false
        })
    );

    const availableChannels = useMemo(() => {
        return data?.getChannelsForUser ?? new Array<ChannelDetails>();
    }, [data]);

    const channelDetailsMap = useMemo(() => {
        if (availableChannels?.length > 0) {
            return Object.fromEntries(
                availableChannels.map((availableChannel) => [availableChannel.channelId, availableChannel])
            );
        }
        return {};
    }, [availableChannels]);

    const selectChannel = useCallback(
        (channelIdToSelect: string): void => {
            const foundChannel = availableChannels.find(({ channelId }) => channelId === channelIdToSelect);

            if (!foundChannel) {
                setSelectedChannel(null);
                return;
            }

            setSelectedChannel(foundChannel);
        },
        [availableChannels]
    );

    const channelsForProfile = useMemo(() => {
        if (!currentProfile || !availableChannels) {
            return new Array<ChannelDetails>();
        }

        if (currentProfile.type === ProfileType.Organization) {
            return availableChannels.filter(
                ({ owner: { type, id } }) => type === AdeptOwnerType.Organization && id === currentProfile.id
            );
        }

        if (currentProfile.type === ProfileType.Personal) {
            return availableChannels.filter(
                ({ owner: { type, id } }) => type === AdeptOwnerType.User && id === currentProfile.id
            );
        }

        return new Array<ChannelDetails>();
    }, [currentProfile, availableChannels]);

    const getChannelDetails = useCallback(
        (channelId: string): ChannelDetails | null => channelDetailsMap[channelId] || null,
        [channelDetailsMap]
    );

    const findChannels = useCallback(
        (filter: FindChannelsFilter): ChannelDetails[] => {
            if (filter?.ids) {
                return availableChannels.filter(({ channelId }) => (filter.ids ?? []).includes(channelId));
            }

            if (filter?.type && filter.type === ChannelType.System) {
                return availableChannels.filter(({ type }) => type === filter.type);
            }

            if (filter?.name) {
                return availableChannels.filter(({ name }) => name === filter.name);
            }

            if (filter?.owner) {
                return availableChannels.filter(({ owner }) => owner.id === filter.owner);
            }

            return new Array<ChannelDetails>();
        },
        [availableChannels]
    );

    return (
        <Provider
            value={{
                error,
                status,
                channelsForProfile,
                availableChannels,
                selectedChannel,
                selectChannel,
                findChannels,
                getChannelDetails,
                fetchChannelsForUser
            }}
        >
            {children}
        </Provider>
    );
};

export { CommunicationChannelsContext, CommunicationChannelsProvider, Consumer as CommunicationChannelsConsumer };
