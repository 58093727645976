const DEVTOOLS_VISIBLE_STORAGE_KEY = 'devtools.visible';

export const isVisible = (): boolean => {
    try {
        if (localStorage) {
            const devToolsEnabled = localStorage.getItem(DEVTOOLS_VISIBLE_STORAGE_KEY);

            return Boolean(devToolsEnabled && devToolsEnabled === 'true');
        }

        return false;
    } catch (error) {
        return false;
    }
};

export const show = (): void => {
    localStorage.setItem(DEVTOOLS_VISIBLE_STORAGE_KEY, 'true');
};

export const hide = (): void => {
    localStorage.removeItem(DEVTOOLS_VISIBLE_STORAGE_KEY);
};
