import React, { ReactElement } from 'react';

import { TooltippedButtonSize, TooltippedIconButton } from '@adept-at/lib-react-components';
import { Divider, Menu } from '@material-ui/core';
import { mdiCalendarClock, mdiClose, mdiDotsVertical, mdiPlayCircle } from '@mdi/js';
import Icon from '@mdi/react';
import { useBookShelves } from 'components/bookshelves/BookshelvesContext';
import { EntityType } from 'components/ContentContext/Enums';
import { FavoriteItem } from 'components/dash/hooks/useGetFavorites';
import useRemoveFavoriteItem from 'components/dash/hooks/useRemoveFavoriteItem';
import { BasicCollectionInfo } from 'components/dash/interfaces';
import { useSchedule } from 'components/dash/Schedule/ScheduleContext';
import { generateItemUrl } from 'components/dash/utils';
import { BookshelfPlusIconPath } from 'components/icons/BookshelfPlus';
import { usePopover } from 'hooks/usePopover';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { StyledListItem, StyledMenu } from 'styles/Shared.styles';

const BUTTON_SIZE_PLACEHOLDER = '24PX';
const Placeholder = styled.div`
    width: ${BUTTON_SIZE_PLACEHOLDER};
    height: ${BUTTON_SIZE_PLACEHOLDER};
`;

interface FavoriteItemExtraActionsProps {
    favorite: FavoriteItem;
    isVisible: boolean;
    buttonSize: TooltippedButtonSize;
}

export const FavoriteItemExtraActions = ({
    favorite,
    isVisible = false,
    buttonSize
}: FavoriteItemExtraActionsProps): ReactElement => {
    const { openLearnLaterModal } = useSchedule();
    const { openBookshelfModal } = useBookShelves();
    const { mutate: removeFromFavorites } = useRemoveFavoriteItem();

    const { open, anchorEl, handlePopoverClick, handlePopoverClose } = usePopover();
    const history = useHistory();

    if (!isVisible) return <Placeholder />;

    const itemDetails = {
        type: 'skillId' in favorite ? EntityType.SKILL : EntityType.COLLECTION,
        id: 'skillId' in favorite ? favorite.skillId : (favorite as BasicCollectionInfo).collectionId
    };

    return (
        <>
            <TooltippedIconButton
                aria-label="More actions for item"
                icon={<Icon path={mdiDotsVertical} />}
                title="More actions"
                size={buttonSize}
                onClick={(e) => {
                    e.stopPropagation();
                    handlePopoverClick(e);
                }}
            />
            <StyledMenu
                anchorEl={anchorEl}
                open={open}
                onClose={handlePopoverClose}
                onClick={(e) => e.stopPropagation()}
            >
                <StyledListItem
                    aria-label="Learn now"
                    button
                    onClick={() => history.push(generateItemUrl(favorite) ?? '')}
                >
                    <Icon path={mdiPlayCircle} /> Learn Now
                </StyledListItem>
                <StyledListItem
                    aria-label="Learn later"
                    button
                    onClick={() => {
                        openLearnLaterModal(itemDetails);
                        handlePopoverClose();
                    }}
                >
                    <Icon path={mdiCalendarClock} /> Learn later
                </StyledListItem>
                <StyledListItem
                    aria-label="Add to bookshelf"
                    button
                    onClick={() => {
                        openBookshelfModal({
                            title: 'name' in favorite ? favorite.name : favorite.title,
                            ...itemDetails
                        });
                        handlePopoverClose();
                    }}
                >
                    <Icon path={BookshelfPlusIconPath} /> Add to bookshelf
                </StyledListItem>
                <Divider />
                <StyledListItem
                    aria-label="Remove from favorites"
                    button
                    onClick={() => {
                        removeFromFavorites({
                            item: itemDetails
                        });

                        handlePopoverClose();
                    }}
                >
                    <Icon path={mdiClose} /> Remove
                </StyledListItem>
            </StyledMenu>
        </>
    );
};
