export const REFRESH_SESSION_ERROR_MESSAGE = 'Unable to refresh token for session';

interface RefreshSessionResponse {
    success: boolean;
    userId: string;
}

export const refreshSession = async (): Promise<RefreshSessionResponse> => {
    const response = await fetch(`${process.env.REACT_APP_ACCOUNTS_HOST}/web/sessionRefresh`, {
        method: 'post',
        credentials: 'include',
        mode: 'cors'
    });

    if (!response.ok) {
        throw new Error(REFRESH_SESSION_ERROR_MESSAGE);
    }

    return response.json();
};
