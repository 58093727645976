export const catalogImage = `
    catalog {
        original {
            url
        }
        crop
        isProcessed
        processed @sizes(widths: [208]) {
            url
        }
    }
`;

export const collageImages = `
    collage {
        id
        crop
        original {
            url
        }
        isProcessed
        processed @sizes(widths: [80]) {
            url
        }
    }
`;

export const progressFragment = `
    progress {
        secondsConsumed
        updatedAt
        completedQuestions
        completedChildren
        collectionComplete
        lastCompletedBlockKey
    }
`;

export const progressWithCompletedAtFragment = `
    progress {
        secondsConsumed
        updatedAt
        completedQuestions
        completedAt
        lastCompletedBlockKey
    }
`;

export const skillFragment = `
    favoritedAt
    skillId
    title
    skillSlug
    tenantSlug
    createdBy
    estimatedSecondsToConsume
    organizationId
    images {
        ${catalogImage}
    }
`;

export const collectionFragment = `
    favoritedAt
    collectionId
    collectionSlug
    tenantSlug
    title
    images {
        ${catalogImage}
    }
    stats {
        estimatedSecondsToConsume
        descendants {
            type
            count
        }
    }
`;

export const virtualLabFragment = `
    favoritedAt
    vLabId
    name
    created: createdBy
    organizationId
`;

export const liveLearningSessionFragment = `
    sessionId
`;
