import { useCallback } from 'react';

import { RequestDocument } from 'graphql-request/dist/types';
import { Api } from 'lib/ApiConstants';

import { useRequestWithToken } from './useRequestWithToken';

interface UseBuildAuthenticatedPromisfiedFetchInterface<TQueryResponse, TVariables> {
    fetch: (variables: TVariables) => Promise<TQueryResponse>;
}

export const useBuildAuthenticatedPromisfiedFetch = <TQueryResponse, TVariables = Record<string, unknown>>(
    queryDocument: RequestDocument,
    api = Api.Accounts,
    headers?: Record<string, boolean | string | number>
): UseBuildAuthenticatedPromisfiedFetchInterface<TQueryResponse, TVariables> => {
    const { getWorkingRequest } = useRequestWithToken(queryDocument, api, headers);

    const fetch = useCallback(
        (variables: TVariables) => getWorkingRequest<TVariables, TQueryResponse>(variables),
        [getWorkingRequest]
    );

    return { fetch };
};
