import { useCallback, useMemo } from 'react';

import { FavoriteItem } from 'components/dash/hooks/useGetFavorites';

import { FavoritesSortBy } from '../constants';

const sortByTitle = (favorites: FavoriteItem[]) =>
    favorites.sort((favItemA, favItemB) => {
        if (
            ('skillId' in favItemA || 'collectionId' in favItemA
                ? favItemA.title.toLowerCase()
                : favItemA.name.toLowerCase()) >
            ('skillId' in favItemB || 'collectionId' in favItemB
                ? favItemB.title.toLowerCase()
                : favItemB.name.toLowerCase())
        ) {
            return 1;
        }
        return -1;
    });

const sortByDateAdded = (favorites: FavoriteItem[]) =>
    favorites.sort((favItemA, favItemB) => (favItemA.favoritedAt < favItemB.favoritedAt ? 1 : -1));

export const useSortFavorites = ({
    favorites,
    sortBy
}: {
    favorites: FavoriteItem[];
    sortBy: FavoritesSortBy;
}): { sortedFavorites: FavoriteItem[] } => {
    const favoritesSortedByTitle = useCallback(() => sortByTitle(favorites), [favorites]);
    const favoritesSortedByDateAdded = useCallback(() => sortByDateAdded(favorites), [favorites]);

    return {
        sortedFavorites: sortBy === FavoritesSortBy.Title ? favoritesSortedByTitle() : favoritesSortedByDateAdded()
    };
};
