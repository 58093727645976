import { GraphQLClient } from 'graphql-request';
import gql from 'graphql-tag';
import buildGqlQueryFn from 'hooks/useGqlClient/helpers/buildGqlQueryFn';
import { QueryFunction, QueryKey } from 'react-query';

import { EntityInfo, EntityCollaborator, CollaboratorPermissionLevel } from '../../../../ContentContext/Interfaces';

interface BuildGetEntityCollaboratorsQueryKeyInput {
    entity: EntityInfo;
    roleFilter: CollaboratorPermissionLevel[];
}

export const buildGetEntityCollaboratorsQueryKey = ({
    entity: { id, type },
    roleFilter
}: BuildGetEntityCollaboratorsQueryKeyInput): QueryKey => [
    'getEntityCollaborators',
    `${type}#${id}`,
    ...(roleFilter ?? [])
];

const GET_ENTITY_COLLABORATORS = gql`
    query getEntityCollaborators($entityInfo: EntityInfoInput!, $roleFilter: [CollaboratorRole]) {
        getEntityCollaborators(entityInfo: $entityInfo, roleFilter: $roleFilter) {
            alias
            email
            role
            id
            image
            name {
                first
                last
            }
            addedAt
        }
    }
`;

export interface GetEntityCollaboratorsResponse {
    getEntityCollaborators: EntityCollaborator[];
}

export interface GetEntityCollaboratorsVariables {
    entityInfo: EntityInfo;
    roleFilter?: CollaboratorPermissionLevel[];
}

const getEntityCollaborators = (
    client: GraphQLClient,
    variables: GetEntityCollaboratorsVariables
): QueryFunction<GetEntityCollaboratorsResponse> => buildGqlQueryFn(client, GET_ENTITY_COLLABORATORS, variables);

export default getEntityCollaborators;
