import 'draft-js/dist/Draft.css';
import React, { useContext, useEffect, useRef, KeyboardEvent } from 'react';

import { UploadProgressModal } from 'components/engine/Components/Image/UploadProgressModal';
import { usePastedImage } from 'components/engine/Components/Image/usePastedImage';
import {
    Editor,
    EditorState,
    RichUtils,
    getDefaultKeyBinding,
    Modifier,
    DefaultDraftBlockRenderMap,
    ContentBlock,
    SelectionState
} from 'draft-js';
import { Map } from 'immutable';

import { AddComponentButton } from './AddComponentButton';
import { RichTextEditorContext } from './Context';
import { EditorContainer } from './DraftEditor.styles';
import { InlineToolbar } from './InlineToolbar';
import { useEditableSectionDescriptions } from './useEditableSectionDescriptions';
import {
    addNewBlockAt,
    continuousDraftJsBlocks,
    DraftJsBlock,
    EditorChangeType,
    HANDLED,
    NOT_HANDLED,
    resetBlockWithType
} from './utils';

interface DraftEditorProps {
    placeholder?: string;
    minHeight?: number;
    componentId: string;
}

const styleMap = {
    CODE: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        fontSize: 16,
        padding: 2
    }
};

const HeaderOne = (props) => {
    return props.children.map((child) => (
        <span key={child.key} id={`component-${child.key}`}>
            {child}
        </span>
    ));
};

const blockRenderMap = Map({
    [DraftJsBlock.H1]: {
        element: 'h2',
        wrapper: <HeaderOne />
    }
});
const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderMap);

const blockRenderFn = (contentBlock: ContentBlock) => {
    const type = contentBlock.getType();
    if (type === DraftJsBlock.ATOMIC) {
        return {
            editable: false
        };
    }
};

const blockStyleFn = () => {
    return 'draft-editor-padded-block';
};

const DraftEditor: React.FC<DraftEditorProps> = ({ componentId, placeholder, minHeight = 200 }) => {
    const { editorState, setEditorState, editorRef: editorContainerRef } = useContext(RichTextEditorContext);
    useEditableSectionDescriptions();

    const { handlePastedFiles, uploadProgress, isLoading } = usePastedImage();

    const editorRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (editorRef.current) {
            editorRef.current.focus();
        }
    }, [componentId]);

    if (!editorState) {
        return null;
    }

    const keyBindingFn = (e: KeyboardEvent): string | null => {
        if (e.key === 'Tab') {
            const maxDepth = 4;

            e.preventDefault();

            const selection = editorState.getSelection();
            const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();

            if (blockType === DraftJsBlock.UL || blockType === DraftJsBlock.OL) {
                handleChange(RichUtils.onTab(e, editorState, maxDepth));
                return null;
            }

            const newContentState = Modifier.replaceText(
                editorState.getCurrentContent(),
                editorState.getSelection(),
                '    '
            );

            handleChange(EditorState.push(editorState, newContentState, EditorChangeType.INSERT_CHARACTERS));
            return null;
        }

        if (e.key === 'End') {
            e.preventDefault();

            const key = editorState.getSelection().getStartKey();
            const offset = editorState.getCurrentContent().getBlockForKey(key).getLength();

            const selection = new SelectionState({
                anchorKey: key,
                anchorOffset: offset,
                focusKey: key,
                focusOffset: offset
            });

            handleChange(EditorState.forceSelection(editorState, selection));
            return null;
        }

        return getDefaultKeyBinding(e);
    };

    const handleChange = (e: EditorState) => {
        if (!e) {
            return;
        }

        setEditorState(e);
    };

    const handleKeyCommand = (command, editorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);

        if (newState) {
            handleChange(newState);
            return HANDLED;
        }

        return NOT_HANDLED;
    };

    /*
        Used from [medium-draft](https://github.com/brijeshb42/medium-draft/blob/c5350bb76489fd4f2f90f1dc69db92224138d20a/src/editor.js#L386)
        by [brijeshb42](https://github.com/brijeshb42)
    */
    const handleReturn = () => {
        const selectionState = editorState.getSelection();
        const contentState = editorState.getCurrentContent();
        const currentBlock = contentState.getBlockForKey(selectionState.getStartKey());
        const blockType = currentBlock.getType();

        if (blockType.indexOf(DraftJsBlock.ATOMIC) === 0) {
            setEditorState(addNewBlockAt(editorState, currentBlock.getKey()));
            return HANDLED;
        }

        if (currentBlock.getLength() === 0) {
            switch (blockType) {
                case DraftJsBlock.UL:
                case DraftJsBlock.OL:
                case DraftJsBlock.H1:
                    setEditorState(resetBlockWithType(editorState));
                    return HANDLED;
                default:
                    return NOT_HANDLED;
            }
        }

        const selection = editorState.getSelection();

        if (selection.isCollapsed() && currentBlock.getLength() === selection.getStartOffset()) {
            if (continuousDraftJsBlocks.indexOf(blockType as DraftJsBlock) < 0) {
                setEditorState(addNewBlockAt(editorState, currentBlock.getKey()));
                return HANDLED;
            }
        }

        return NOT_HANDLED;
    };

    return (
        <EditorContainer
            data-testid="draftEditor-editor"
            minHeight={minHeight}
            className="editor"
            ref={editorContainerRef}
        >
            <Editor
                ref={editorRef as any}
                customStyleMap={styleMap}
                placeholder={placeholder}
                editorState={editorState}
                handleKeyCommand={handleKeyCommand}
                onChange={handleChange}
                keyBindingFn={keyBindingFn}
                spellCheck={true}
                handleReturn={handleReturn}
                blockRenderMap={extendedBlockRenderMap}
                blockRendererFn={blockRenderFn}
                blockStyleFn={blockStyleFn}
                handlePastedFiles={handlePastedFiles}
            />
            <UploadProgressModal uploadProgress={uploadProgress} open={isLoading} />
            <InlineToolbar />
            <AddComponentButton />
        </EditorContainer>
    );
};

export default DraftEditor;
