import { GraphQLClient, gql } from 'graphql-request';
import { MessageAckType, MessageEntityType } from 'lib/communication/message/MessageRecord';
import { MutationFunction } from 'react-query';

import buildGqlMutationFn from '../../../useGqlClient/helpers/buildGqlMutationFn';

const ACK_MESSAGE_BATCH_MUTATION = gql`
    mutation ackEntityMessageBatch($ackMessageDetails: [AckMessageDetail]) {
        ackEntityMessageBatch(ackMessageDetails: $ackMessageDetails) {
            success
        }
    }
`;

interface AckMessageBatchResult {
    ackEntityMessageBatch: { success: boolean };
}

interface AckMessageBatchParams {
    ackMessageDetails: AckMessageDetail[];
}

export interface AckMessageDetail {
    messageId: string;
    ackType: MessageAckType;
    entityId: string;
    entityType: MessageEntityType;
}

const ackMessageApi = (client: GraphQLClient): MutationFunction<AckMessageBatchResult, AckMessageBatchParams> =>
    buildGqlMutationFn<AckMessageBatchResult, AckMessageBatchParams>(client, ACK_MESSAGE_BATCH_MUTATION);

export default ackMessageApi;
