import React, { useCallback, useContext } from 'react';

import { useLocation } from 'react-router';
import styled from 'styled-components';

import { SkillSection } from '../../utils/skill/sections';
import { FocusObserverContext } from '../rooms/FocusObserverContext';

import SkillOutlineSection from './SkillOutlineSection';

const Content = styled.div`
    overflow-y: auto;
`;

interface SkillOutlineProps {
    sections: SkillSection[];
}

const SkillOutlineContent: React.FC<SkillOutlineProps> = ({ sections }) => {
    const presentersBySection = useContext(FocusObserverContext)?.presentersBySection;
    const sectionInView = useContext(FocusObserverContext)?.sectionInView;

    const { hash } = useLocation();

    const isActiveSection = useCallback(
        (sectionId: string, sectionMapIndex: number): boolean => {
            if (sectionInView) return sectionInView === `component-${sectionId}`;

            if (!hash) return sectionMapIndex === 0;

            return hash === `#component-${sectionId}`;
        },
        [sectionInView, hash]
    );

    return (
        <Content>
            {sections.map((section, i) => (
                <SkillOutlineSection
                    key={i}
                    isFirst={i === 0}
                    isLast={i === sections.length - 1}
                    section={section}
                    presenters={presentersBySection?.[`component-${section.id}`]}
                    isActive={isActiveSection(section.id, i)}
                />
            ))}
        </Content>
    );
};

export default SkillOutlineContent;
