import React from 'react';

import { Table, TableBody } from '@material-ui/core';
import { CurrentUser } from 'hooks/useCurrentUser';

import { StyledTableRow, StyledTableCell } from './Details.styles';

export const UserDetails: React.FC<{ user: CurrentUser }> = ({ user }) => {
    const { alias, emails, firstName, image, lastName, userId } = user;
    const details = [
        ['User ID', userId],
        ['Alias', alias],
        ['First Name', firstName],
        ['Last Name', lastName],
        ['Emails', (emails ?? []).map(({ address }) => address).join(', ')],
        ['Image Asset ID', image]
    ];

    return (
        <>
            <Table>
                <TableBody>
                    {details.map(([name, value], idx) => (
                        <StyledTableRow key={idx}>
                            <StyledTableCell component="th" scope="row">
                                {name}
                            </StyledTableCell>
                            <StyledTableCell align="left">{value}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
};
