import { defaultMuiTheme } from '@adept-at/lib-react-components';
import styled from 'styled-components';
import { APP_HEADER_HEIGHT, SKILL_CONTAINER_WIDTH, SUB_HEADER_HEIGHT } from 'styles/variables';

export const Container = styled.div`
    display: flex;
    position: relative;
`;

export const LearnSkillContainer = styled.div`
    display: flex;
    position: relative;

    ${defaultMuiTheme.breakpoints.down('xs')} {
        word-break: break-word;
    }
`;

export const ContentWrapper = styled.div<{ ref }>`
    display: flex;
    position: relative;
    height: calc(100vh - ${APP_HEADER_HEIGHT}px - ${SUB_HEADER_HEIGHT}px);
    overflow-y: auto;
    scroll-behavior: smooth;
`;

export const Main = styled.div`
    flex: 0 1 100%;
    display: flex;
    flex-direction: column;
`;

export const SkillContainer = styled.div`
    background-color: ${(props) => props.theme.colors.surface};
    max-width: ${SKILL_CONTAINER_WIDTH}px;
    width: 100%;
    margin: 0 auto;
    border-left: 1px solid ${(props) => props.theme.colors.border};
    border-right: 1px solid ${(props) => props.theme.colors.border};
    box-shadow: 0 0 8px 0 rgba(55, 71, 79, 0.15), 0 -8px 0 0 rgba(55, 71, 79, 0.15);
    height: fit-content;
    min-height: 100%;
`;
