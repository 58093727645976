import { IconSize, DotListContainer, modularScale } from '@adept-at/lib-react-components';
import { Skeleton } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const SCHEDULE_WIDTH = 442;

export const ROOM_CARD_WIDTH = 192;
export const ROOM_CARD_HEIGHT = 135;
export const CONTINUE_LEARNING_CARD_HEIGHT = 248;
export const CONTENT_CARD_WIDTH = 408;

export const CARD_MARGIN = 24;

export const ContentCardSkeleton = styled(Skeleton)`
    margin: -50px ${CARD_MARGIN}px -50px 0;
`;

export const HoverStyles = css`
    box-shadow: none;
    transition: box-shadow 0.15s ease, border-color 0.15s ease;
    &:hover {
        cursor: pointer;
        border-color: ${(props) => props.theme.colors.floatingSurface};
        background-color: ${(props) => props.theme.colors.floatingSurface};
        box-shadow: ${(props) => props.theme.colors.cardBoxShadow};
    }
`;

export const RelativeContainer = styled.div`
    position: relative;
`;

export const SectionHeader = styled.div`
    display: flex;
    align-items: center;
    margin: 64px 38px 16px 0;

    svg {
        margin-right: 18px;
        height: ${IconSize.Medium};
        width: ${IconSize.Medium};
    }
`;

export const StyledLink = styled(Link)`
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.primary};
    font-size: ${modularScale(-1)};
    margin-left: auto;

    svg {
        margin-right: 8px;
    }
`;

export const EmptySectionText = styled.p`
    width: 550px;
`;

export const StyledDotListContainer = styled(DotListContainer)`
    color: ${(props) => props.theme.colors.textSecondary};
    font-size: ${modularScale(-0.5)};
`;

export const BlueText = styled.p`
    color: ${(props) => props.theme.colors.primary};
`;

export const TitleText = styled.p`
    font-weight: 500;
    max-width: ${CONTENT_CARD_WIDTH - 48}px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const DotListItem = styled.div`
    display: flex;
    align-items: center;

    svg {
        width: ${IconSize.Small};
        height: ${IconSize.Small};
        margin-right: 8px;
    }
`;

export const GreenText = styled.p`
    color: green;
    display: inline;
    margin-right: 4px;
`;
