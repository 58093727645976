import React, { useState, useContext, useMemo, createContext, useCallback } from 'react';

export enum ThemeMode {
    LIGHT = 'light',
    DARK = 'dark'
}

const key = 'theme_mode';

interface ThemeContextInterface {
    themeMode?: string;
    updateTheme?: () => void;
}

export const ThemeContext = createContext(undefined as unknown as ThemeContextInterface);

export const ThemeContextProvider: React.FC = ({ children }) => {
    const currentTheme = useMemo(() => localStorage.getItem(key) ?? ThemeMode.LIGHT, []);
    const [themeMode, setThemeMode] = useState(currentTheme);

    const updateTheme = useCallback((): void => {
        const newMode = themeMode === ThemeMode.LIGHT ? ThemeMode.DARK : ThemeMode.LIGHT;
        setThemeMode(newMode);
        window.localStorage.setItem(key, newMode);
    }, [themeMode]);

    return (
        <ThemeContext.Provider
            value={{
                themeMode,
                updateTheme
            }}
        >
            {children}
        </ThemeContext.Provider>
    );
};

export const useThemeContext = () => {
    const context = useContext(ThemeContext);

    if (!context) {
        throw new Error('useThemeContext must be used within ThemeProvider');
    }

    return context;
};
