/*
The Root collection context works for 3 different scenarios.
1. my-profile view
2. org home aka 'catalog' view
3. org internal training view
4. or non member view

Query(s) Info:
tenant profile data is now provided to the context from the view thats trying to use it. This was done with the introduction of slug usage
to allow access for non org members.

TODO: kick editmode down the hierarchy. non members & even most org members once we get permissions running  do not need this functionality
In editMode we use a copy of the above query (depending on the context) using getEditableCollection and the user makes edits against that.
Once the user is ready to publish or discard their changes we mutate with calls to either commitEdits or discardEdits.
*/

import React, { createContext, useEffect, useMemo, useState } from 'react';

import { EntityType } from 'components/ContentContext/Enums';
import { Images, OwnerType, OwnerInfo, DefaultCollectionImageType } from 'components/ContentContext/Interfaces';
import { MarketplaceListingStatus } from 'components/marketplace/hooks/useUpsertListingStatus';
import { ClientError } from 'graphql-request';
import { useAuthorizedEntityActions, CollectionActions } from 'hooks/authorization';
import { QueryKey, QueryObserverResult, QueryStatus, RefetchOptions } from 'react-query';
import { getEditedCollectionsFromSessionStorage } from 'utils/collection';

import { AboutInfo, ContentLayoutView, CollectionsContentFilter } from '../constants';
import { GetEditableCollectionResponse, useGetEditableCollection } from '../useGetEditableCollection';
import { useGetNestedCollections } from '../useGetNestedCollections';
import {
    buildGetCollectionBasicDetailsQueryKey,
    CollectionBasicDetail
} from '../useGetNestedCollections/getCollectionBasicDetails';

import useGetAvailableExistingCollections from './useGetAvailableExistingCollections';
import { GetTenantProfileResponse, TenantProfileDetails } from './useGetTenantProfile';
import { CollectionChild, RootCollectionResponse, StatDescendent } from './useGetTenantProfileForOwner';

interface RootCollectionProviderInterface {
    rootProfileType: OwnerType;
    ownerId?: string;
    getTenantProfileIsLoading: boolean;
    refetchTenantProfile: (
        options?: RefetchOptions | undefined
    ) => Promise<QueryObserverResult<GetTenantProfileResponse, ClientError>>;
    authorizedActions: CollectionActions[];
    isEditMode: boolean;
    setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
    collectionId: string;
    tenantSlug: string;
    aboutInfo: AboutInfo | null;
    stats: StatDescendent[] | null;
    images: Images | null;
    defaultCatalogImage: DefaultCollectionImageType;
    defaultFeaturedImage: DefaultCollectionImageType;
    isPreviewMode: boolean;
    setIsPreviewMode: React.Dispatch<React.SetStateAction<boolean>>;
    nestedCollections: (CollectionBasicDetail | undefined)[];
    getNestedCollectionsStatus: QueryStatus;
    activeFilter: CollectionsContentFilter;
    setActiveFilter: React.Dispatch<React.SetStateAction<CollectionsContentFilter>>;
    search: string;
    setSearch: React.Dispatch<React.SetStateAction<string>>;
    layoutView: ContentLayoutView;
    setLayoutView: React.Dispatch<React.SetStateAction<ContentLayoutView>>;
    existingCollectionsOptions: {
        label: string;
        id: string;
    }[];
    getExistingCollectionsIsLoading: boolean;
    basicDetailsQueryKey: QueryKey;
    parentQueryKey: QueryKey;
    parentQuerySlug: string;
    isInternal: boolean;
    pendingChanges: boolean;
    setPendingChanges: React.Dispatch<React.SetStateAction<boolean>>;
    collectionIdForEditableCollectionQuery: string;
    expectedInfoVersionForEdits: number | undefined;
    expectedItemsVersionForEdits: number | undefined;
    refetchEditableCollection: (
        options?: RefetchOptions | undefined
    ) => Promise<QueryObserverResult<GetEditableCollectionResponse, ClientError>>;

    collectionOwnerInfo: OwnerInfo | null | undefined;
    marketplaceStatus: MarketplaceListingStatus;
    rootCollection?: RootCollectionResponse;
    tenantProfileId?: string;
}

const RootCollectionContext = createContext({} as RootCollectionProviderInterface);

const { Provider, Consumer } = RootCollectionContext;

interface RootCollectionProviderProps {
    isInternal: boolean;
    tenantProfileDetails?: TenantProfileDetails;
    queryKey: QueryKey;
    tenantDetailsIsLoading: boolean;
    ownerDetails: {
        type: OwnerType;
        id?: string;
        slug?: string;
    };
    refetch: (
        options?: RefetchOptions | undefined
    ) => Promise<QueryObserverResult<GetTenantProfileResponse, ClientError>>;
}
/*
    TODO as we move on with different views of the root collection provider,
    it makes sense to move all of  the editing details and functionality in this provider into a EditableContextProvider
    since  99% of the time someone is going to be looking at a collection in the future they will not need editing features.
*/
const RootCollectionProvider: React.FC<RootCollectionProviderProps> = ({
    children,
    isInternal,
    tenantProfileDetails,
    queryKey,
    tenantDetailsIsLoading,
    ownerDetails,
    refetch
}) => {
    const [authorizedActions, setAuthorizedActions] = useState<CollectionActions[]>([]);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isPreviewMode, setIsPreviewMode] = useState(false);
    const [activeFilter, setActiveFilter] = useState<CollectionsContentFilter>(CollectionsContentFilter.All);
    const [search, setSearch] = useState('');
    const [layoutView, setLayoutView] = useState<ContentLayoutView>(ContentLayoutView.Grid);
    const [pendingChanges, setPendingChanges] = useState(false);

    const parentQuerySlug = useMemo(() => {
        if ('slug' in ownerDetails) {
            return `${isInternal ? 'internal-' : ''}${ownerDetails.slug}`;
        } else {
            return `${isInternal ? 'internal-' : ''}${ownerDetails.id}`;
        }
    }, [isInternal, ownerDetails]);

    // ensure we are not in editMode and reset pendingChanges to false if user switches to new org home or between catalog and internal
    useEffect(() => {
        if (ownerDetails.id || ownerDetails.slug || isInternal) {
            setIsEditMode(false);
            setPendingChanges(false);
        }
    }, [ownerDetails.slug, ownerDetails.id, isInternal]);

    const collectionIdForEditableCollectionQuery = tenantProfileDetails?.relevantCollection?.collectionId;

    const usePrimary = useMemo(() => {
        const editedCollections = getEditedCollectionsFromSessionStorage();
        return (
            !!collectionIdForEditableCollectionQuery &&
            editedCollections.includes(collectionIdForEditableCollectionQuery)
        );
    }, [collectionIdForEditableCollectionQuery]);

    const { data: editableCollection } = useGetEditableCollection(
        { collectionId: collectionIdForEditableCollectionQuery ?? '' },
        usePrimary,
        !!collectionIdForEditableCollectionQuery
    );
    const { refetch: refetchEditableCollection } = useGetEditableCollection(
        { collectionId: collectionIdForEditableCollectionQuery ?? '' },
        true,
        false
    );

    const collectionEntity = useMemo(() => {
        return {
            type: EntityType.Collection,
            id: collectionIdForEditableCollectionQuery ?? null
        };
    }, [collectionIdForEditableCollectionQuery]);

    /* Check if <CURRENT_USER> can CommitEdits on the collection */
    const { actions, refetch: refetchAuthorizedActions } =
        useAuthorizedEntityActions<CollectionActions>(collectionEntity);

    /* If Collection ID changes, recheck authorization. */
    useEffect(() => {
        refetchAuthorizedActions();
        setAuthorizedActions(actions ? actions : []);
    }, [actions, refetchAuthorizedActions, collectionIdForEditableCollectionQuery]);

    useEffect(() => {
        if (!editableCollection?.getEditableCollection) return;

        const { infoVersion: editableInfoVersion, itemsVersion: editableItemsVersion } =
            editableCollection.getEditableCollection;

        if (tenantProfileDetails?.relevantCollection) {
            const { infoVersion: catalogInfoVersion, itemsVersion: catalogItemsVersion } =
                tenantProfileDetails.relevantCollection;

            if (editableInfoVersion !== catalogInfoVersion || editableItemsVersion !== catalogItemsVersion) {
                setPendingChanges(true);
            }
        }

        return;
    }, [tenantProfileDetails?.relevantCollection, editableCollection?.getEditableCollection]);

    const collectionData = useMemo(() => {
        if (!tenantProfileDetails) return undefined;

        if (isEditMode) return editableCollection?.getEditableCollection;

        return tenantProfileDetails?.relevantCollection;
    }, [tenantProfileDetails, isEditMode, editableCollection]);

    const aboutInfo: AboutInfo | null = useMemo(() => {
        if (!collectionData) return null;

        return {
            title: collectionData.title,
            titleSub: collectionData.titleSub ?? null,
            description: collectionData.description ?? null,
            tags: collectionData.tags ?? null,
            publicLink: null // null until we figure out where this is coming from
        };
    }, [collectionData]);

    const images = useMemo(() => collectionData?.images ?? null, [collectionData?.images]);

    const nestedCollectionIds: string[] = useMemo(
        () =>
            collectionData?.children
                ?.filter((child) => child.type === CollectionChild.Collection)
                .map((child) => child.id) ?? [],
        [collectionData]
    );

    const rootCollectionId = useMemo(() => collectionData?.collectionId ?? '', [collectionData?.collectionId]);

    const stats = useMemo(() => collectionData?.stats?.descendants ?? null, [collectionData?.stats?.descendants]);

    const basicDetailsQueryKey = useMemo(
        () => buildGetCollectionBasicDetailsQueryKey(rootCollectionId),
        [rootCollectionId]
    );

    const { nestedCollections, status: getNestedCollectionsStatus } = useGetNestedCollections(
        basicDetailsQueryKey,
        nestedCollectionIds
    );

    const childCollectionIds = useMemo(() => nestedCollections.map((child) => child.collectionId), [nestedCollections]);

    const { data: existingCollections, isLoading: getExistingCollectionsIsLoading } =
        useGetAvailableExistingCollections({ parentCollectionId: rootCollectionId }, !!rootCollectionId);

    const existingCollectionsOptions: {
        label: string;
        id: string;
    }[] = useMemo(() => {
        return (
            existingCollections?.getInsertableCollections
                .filter((collection) => !childCollectionIds.includes(collection.collectionId))
                .map((collection) => ({
                    label: collection.title,
                    id: collection.collectionId
                })) ?? []
        );
    }, [existingCollections?.getInsertableCollections, childCollectionIds]);

    const orderedNestedCollections = useMemo(
        () =>
            nestedCollectionIds
                .map((id) => nestedCollections.find((collection) => collection.collectionId === id))
                .filter(Boolean),
        [nestedCollectionIds, nestedCollections]
    );

    const filteredNestedCollections = useMemo(
        () =>
            isEditMode ? orderedNestedCollections : orderedNestedCollections.filter((child) => child && child.visible),
        [orderedNestedCollections, isEditMode]
    );

    const defaultCatalogImage = useMemo(() => collectionData?.defCat ?? 'gray', [collectionData]);
    const defaultFeaturedImage = useMemo(() => collectionData?.defFeat ?? 'gray', [collectionData]);

    return (
        <Provider
            value={{
                authorizedActions,
                rootProfileType: ownerDetails.type,
                ownerId: ownerDetails.id ?? collectionData?.owner?.id,
                getTenantProfileIsLoading: tenantDetailsIsLoading,
                refetchTenantProfile: refetch,
                collectionId: rootCollectionId,
                aboutInfo,
                stats,
                images,
                defaultCatalogImage,
                defaultFeaturedImage,
                isPreviewMode,
                setIsPreviewMode,
                isEditMode,
                setIsEditMode,

                nestedCollections: filteredNestedCollections,
                getNestedCollectionsStatus,

                existingCollectionsOptions,
                getExistingCollectionsIsLoading,

                activeFilter,
                setActiveFilter,

                search,
                setSearch,

                layoutView,
                setLayoutView,

                isInternal,
                basicDetailsQueryKey,
                parentQueryKey: queryKey,
                parentQuerySlug,

                pendingChanges,
                setPendingChanges,

                collectionIdForEditableCollectionQuery: collectionIdForEditableCollectionQuery ?? '',
                expectedInfoVersionForEdits: editableCollection?.getEditableCollection?.infoVersion,
                expectedItemsVersionForEdits: editableCollection?.getEditableCollection?.itemsVersion,
                refetchEditableCollection,

                collectionOwnerInfo: tenantProfileDetails?.relevantCollection?.owner,
                marketplaceStatus: tenantProfileDetails?.marketplaceStatus ?? MarketplaceListingStatus.unlisted,
                rootCollection: tenantProfileDetails?.relevantCollection,
                tenantProfileId: tenantProfileDetails?.tenantProfileId,
                tenantSlug: ownerDetails.slug ?? tenantProfileDetails?.tenantSlug ?? ''
            }}
        >
            {children}
        </Provider>
    );
};

export { RootCollectionContext, RootCollectionProvider, Consumer as RootCollectionConsumer };
