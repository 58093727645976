import React from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { Button } from '@material-ui/core';
import { mdiAccountMultiple } from '@mdi/js';
import Icon from '@mdi/react';
import { OwnerType, Skill } from 'components/ContentContext/Interfaces';
import SharingModal from 'components/modals/Sharing/SharingModal/SharingModal';
import { buildQueryKey as buildGetSkillByIdQueryKey } from 'components/skills/hooks/useGetSkillById';
import { useModal } from 'hooks/useModal';
import { StyledListItem } from 'styles/Shared.styles';

interface CollaboratorsButtonProps {
    skill: Skill;
    variant?: 'button' | 'listItem';
}

export const CollaboratorsButton: React.FC<CollaboratorsButtonProps> = ({ skill, variant = 'button' }) => {
    const { modalOpen, handleModalOpen, handleModalClose } = useModal();

    return (
        <>
            {variant === 'button' ? (
                <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={handleModalOpen}
                    startIcon={<Icon path={mdiAccountMultiple} size={IconSize.Medium} />}
                >
                    Collaborators
                </Button>
            ) : (
                <StyledListItem button onClick={handleModalOpen}>
                    <Icon path={mdiAccountMultiple} /> Collaborators
                </StyledListItem>
            )}
            <SharingModal
                entities={[skill]}
                isOpen={modalOpen}
                handleClose={handleModalClose}
                owner={skill.organizationId ? { type: OwnerType.Organization, id: skill.organizationId } : skill.owner}
                contentQueryKey={buildGetSkillByIdQueryKey(skill.skillId)}
            />
        </>
    );
};
