import { ComponentValues, RichTextComponentValues } from '@adept-at/lib-react-components';
import { Skill } from 'components/ContentContext/Interfaces';
import { DraftJsBlock, DraftJsEntity } from 'components/engine/common/RichTextEditor/utils';

export const getFirstUnfinishedBlockKey = (skill: Skill): string | undefined => {
    const components = Object.values(skill.components ?? {}) as ComponentValues[];
    const textComponent = components[0] as RichTextComponentValues;
    const blocks = textComponent?.body?.val?.blocks ?? [];
    const entityMap = textComponent?.body?.val?.entityMap ?? {};
    const completedQuestions = skill.progress?.completedQuestions ?? [];
    let firstUnfinishedBlockKey: string | undefined;

    for (const block of blocks) {
        if (firstUnfinishedBlockKey === undefined) {
            firstUnfinishedBlockKey = block.key;
        }
        if (block.type === DraftJsBlock.ATOMIC && block.entityRanges.length > 0) {
            for (const entityInfo of block.entityRanges) {
                const entity = entityMap[`${entityInfo.key}`];

                if (entity?.type === DraftJsEntity.QUESTION) {
                    if (completedQuestions.includes(entity.data.questionId)) {
                        firstUnfinishedBlockKey = undefined;
                    } else {
                        return firstUnfinishedBlockKey;
                    }
                }
            }
        }
    }

    return firstUnfinishedBlockKey;
};

export const getFirstBlockKey = (skill: Skill): string | undefined => {
    const components = Object.values(skill.components ?? {}) as ComponentValues[];
    const textComponent = components[0] as RichTextComponentValues;
    const blocks = textComponent?.body?.val?.blocks ?? [];
    return blocks[0]?.key;
};
