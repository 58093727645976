// Copied from https://github.com/rnkvogel/Millicast-Podcaster/blob/master/podcaster/js/publisher.js#L195

import { addMinutes } from 'date-fns';

import { DrawEvent } from './DrawableCanvas';
import { MeetingSession } from './hooks/getSessionsForChannel';
import { DevicePreferences, DEVICE_PREFERENCES_KEY, HIDE_TOOLTIPS_KEY, TooltipPreferences } from './PublisherContext';

const TURN_URL = 'https://turn.millicast.com/webrtc/_turn';
export const MILLICAST_PUBLISH_URL = `${process.env.REACT_APP_MILLICAST_HOST}/publish`;
export const MILLICAST_SUBSCRIBE_URL = `${process.env.REACT_APP_MILLICAST_HOST}/subscribe`;
export const MILLICAST_ACCOUNT_ID = process.env.REACT_APP_MILLICAST_ACCOUNT_ID;

export function getICEServers(): Promise<RTCIceServer[]> {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function (_event) {
            if (xhr.readyState !== 4) {
                return;
            }

            if (xhr.status < 200 || xhr.status >= 300) {
                reject(new Error(`IceServers call failed. StatusCode: ${xhr.status} Response: ${xhr.responseText}`));
                return;
            }

            const jsonResponse = JSON.parse(xhr.responseText);
            if (!jsonResponse || jsonResponse['s'] !== 'ok') {
                reject(new Error(`IceServers invalid response. Response: ${xhr.responseText}`));
                return;
            }

            // final resolve array
            const finalServers: RTCIceServer[] = [];
            const credentials: RTCIceServer[] = [];

            const valIceServers = jsonResponse['v']['iceServers'] ?? jsonResponse['v'] ?? [];

            for (const server of valIceServers) {
                // normalize server.urls
                if (server.url) {
                    // convert to new urls format if detected
                    server.urls = [server.url];
                    delete server.url;
                } else if (server.urls && !Array.isArray(server.urls)) {
                    // assuming this is using legacy notation where urls is a single string
                    server.urls = [server.urls];
                } else {
                    // assure we have an array of something
                    server.urls = [];
                }

                // skip empty urls
                if (!server.urls.length) {
                    continue;
                }

                // now to identify servers with identical credentials
                // not everything has credentials
                if (!server.username || !server.credential) {
                    finalServers.push(server);
                    continue;
                }

                const credIndex = credentials.findIndex(
                    (s) => s.username === server.username && s.credential === server.credential
                );

                if (credIndex === -1) {
                    // new credential pair
                    credentials.push(server);
                    continue;
                }

                // else we want to merge with credIndex
                const mergeServer = credentials[credIndex];
                for (const urlStr of server.urls) {
                    (mergeServer.urls as string[]).push(urlStr);
                }
            }

            // separate udp from tcp and unspecified
            for (const server of credentials) {
                const udpUrls: string[] = [];
                const tcpUrls: string[] = [];
                const unspecifiedUrls: string[] = [];

                for (const urlStr of server.urls) {
                    const queryIndex = urlStr.indexOf('?');
                    if (queryIndex === -1) {
                        unspecifiedUrls.push(urlStr);
                        continue;
                    }

                    const queryString = new URLSearchParams(urlStr.substr(queryIndex + 1));
                    const transport = queryString.get('transport');
                    switch (transport) {
                        case 'udp':
                            udpUrls.push(urlStr);
                            break;
                        case 'tcp':
                            tcpUrls.push(urlStr);
                            break;
                        default:
                            unspecifiedUrls.push(urlStr);
                            break;
                    }
                }

                if (udpUrls.length) {
                    const newServer = {
                        ...server,
                        urls: udpUrls
                    };
                    finalServers.push(newServer);
                }

                if (tcpUrls.length) {
                    const newServer = {
                        ...server,
                        urls: tcpUrls
                    };
                    finalServers.push(newServer);
                }

                if (unspecifiedUrls.length) {
                    const newServer = {
                        ...server,
                        urls: unspecifiedUrls
                    };
                    finalServers.push(newServer);
                }
            }

            resolve(finalServers);
        };

        xhr.open('PUT', TURN_URL, true);
        xhr.send();
    });
}

interface SessionsByDate {
    past: MeetingSession[];
    inProgress: MeetingSession[];
    future: MeetingSession[];
}

export const groupSessionsByStartDate = (sessions: MeetingSession[]): SessionsByDate => {
    const now = new Date();
    const tenMinutesFromNow = addMinutes(now, 10);

    return sessions.reduce(
        (memo, session) => {
            if (session) {
                const startDate = new Date(session.startTime);
                const endDate = new Date(session.details.endTime);

                if (startDate > tenMinutesFromNow) {
                    memo.future.push(session);
                } else if (endDate > now) {
                    memo.inProgress.push(session);
                } else {
                    memo.past.push(session);
                }
            }
            return memo;
        },
        { future: [], inProgress: [], past: [] } as SessionsByDate
    );
};

interface RegroupedSessions {
    expired: MeetingSession[];
    inProgress: MeetingSession[];
    starting: MeetingSession[];
    future: MeetingSession[];
}

export const regroupSessionsByStartDate = (
    inProgressSessions: MeetingSession[],
    futureSessions: MeetingSession[]
): RegroupedSessions => {
    const now = new Date();
    const tenMinutesFromNow = addMinutes(now, 10);

    const { expired, inProgress } = inProgressSessions.reduce(
        (memo, session) => {
            if (new Date(session.details.endTime) < now) {
                memo.expired.push(session);
            } else {
                memo.inProgress.push(session);
            }
            return memo;
        },
        { expired: [], inProgress: [] } as { expired: MeetingSession[]; inProgress: MeetingSession[] }
    );

    const { starting, future } = futureSessions.reduce(
        (memo, session) => {
            if (new Date(session.startTime) < tenMinutesFromNow) {
                memo.starting.push(session);
            } else {
                memo.future.push(session);
            }
            return memo;
        },
        { starting: [], future: [] } as { starting: MeetingSession[]; future: MeetingSession[] }
    );

    return { expired, inProgress, starting, future };
};

export const getDevicePreferences = (): DevicePreferences => {
    try {
        const preferences = localStorage.getItem(DEVICE_PREFERENCES_KEY);

        return preferences ? JSON.parse(preferences) : {};
    } catch (_err) {
        return {};
    }
};

export const getTooltipPreferences = (): TooltipPreferences => {
    try {
        const preferences = localStorage.getItem(HIDE_TOOLTIPS_KEY);

        return preferences ? JSON.parse(preferences) : {};
    } catch (_err) {
        return {};
    }
};

export const drawLine = (context: CanvasRenderingContext2D, event: DrawEvent, erase?: boolean): void => {
    const { fromX, fromY, toX, toY, color } = event;
    context.globalCompositeOperation = erase ? 'destination-out' : 'source-over';
    context.beginPath();
    context.strokeStyle = color;
    context.lineWidth = erase ? 6 : 5;
    context.moveTo(fromX, fromY);
    context.lineTo(toX, toY);
    context.stroke();
};
