import React, { useState, useEffect } from 'react';

export const useIsElementVisible = (element: React.MutableRefObject<Element | null>): boolean | undefined => {
    const [hasEntry, setHasEntry] = useState(false);
    const [isElementVisible, setIsElementVisible] = useState(false);

    const observer = new IntersectionObserver(
        ([entry]) => {
            if (entry) {
                setHasEntry(true);
                setIsElementVisible(entry.isIntersecting);
            }
        },
        { threshold: 0 }
    );

    useEffect(() => {
        const currentElement = element.current;
        if (currentElement) {
            observer.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                observer.unobserve(currentElement);
            }
        };
    });

    return !hasEntry ? undefined : isElementVisible;
};
