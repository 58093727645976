import React, { useState } from 'react';

import { defaultMuiTheme, IconSize } from '@adept-at/lib-react-components';
import { Button, ListItem } from '@material-ui/core';
import { mdiFormatListChecks, mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import SkillIcon from 'components/icons/SkillIcon';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { StyledMenu } from 'styles/Shared.styles';

import CreateAssessmentModal from './modals/Assessments/CreateAssessmentModal';
import { CreateSkillModal } from './modals/Skills/CreateSkillModal';

const StyledButton = styled(Button)`
    display: none;
    ${defaultMuiTheme.breakpoints.up('sm')} {
        display: flex;
    }

    flex-shrink: 0;
    margin-right: 1rem;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    min-width: 40px;
    padding: 0;
    box-shadow: ${(props) => props.theme.colors.cardBoxShadow};
`;

const InnerMenu = styled.div`
    display: flex;
    flex-direction: column;
`;

const Item = styled(ListItem)`
    padding: 0.5rem 2rem 0.5rem 1rem;
    color: ${(props) => props.theme.colors.primary};
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-right: 1rem;

    svg {
        width: ${IconSize.Medium};
    }
`;

interface CreateButtonProps {
    title?: string;
}

export const CreateButton: React.FC<CreateButtonProps> = () => {
    const history = useHistory();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [skillIsOpen, setSkillIsOpen] = useState(false);
    const [assessmentIsOpen, setAssessmentIsOpen] = useState(false);

    const openSkillModal = () => {
        setSkillIsOpen(true);
        handleClose();
    };

    const closeSkillModal = () => {
        setSkillIsOpen(false);
    };

    const openAssessmentModal = () => {
        setAssessmentIsOpen(true);
        handleClose();
    };

    const closeAssessmentModal = () => {
        setAssessmentIsOpen(false);
    };

    const onAssessmentFinish = (assessmentId) => {
        history.push(`/my-creations/assessment/${assessmentId}`);
    };

    // const openLabModal = () => {
    //     setLabIsOpen(true);
    // };

    // const closeLabModal = () => {
    //     setLabIsOpen(false);
    // };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <StyledButton aria-label="Create new entity" onClick={handleClick} color="primary" variant="contained">
                <Icon path={mdiPlus} size={IconSize.Medium} />
            </StyledButton>
            <StyledMenu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <InnerMenu>
                    <Item button aria-label="Create new skill" onClick={openSkillModal}>
                        <IconWrapper>
                            <SkillIcon />
                        </IconWrapper>{' '}
                        Skill
                    </Item>
                    <Item button aria-label="Create new assessment" onClick={openAssessmentModal}>
                        <IconWrapper>
                            <Icon path={mdiFormatListChecks} />
                        </IconWrapper>{' '}
                        Assessment
                    </Item>
                    {/* <Item button aria-label="Create new lab" onClick={openLabModal}>
                        <IconWrapper>
                            <Icon path={mdiFlaskOutline} />
                        </IconWrapper>{' '}
                        Lab Template
                    </Item> */}
                </InnerMenu>
            </StyledMenu>
            <CreateSkillModal isOpen={skillIsOpen} handleClose={closeSkillModal} />
            <CreateAssessmentModal
                isOpen={assessmentIsOpen}
                handleClose={closeAssessmentModal}
                onFinish={onAssessmentFinish}
            />
            {/* {currentUser?.userId ? (
                <CreateLabModal
                    isOpen={labIsOpen}
                    handleClose={closeLabModal}
                    context={{ type: VirtualLabTypeInput.user, id: currentUser.userId }}
                />
            ) : null} */}
        </>
    );
};
