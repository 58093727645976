import React, { FC, memo, useEffect, useRef, useState } from 'react';

import { Button, TextField } from '@material-ui/core';
import styled from 'styled-components';

import { Comment } from './interfaces';

interface CommentProps {
    comment: Comment;
    onSave: (newBody: string) => void;
    onCancel: () => void;
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: right;
    flex-direction: row-reverse;
    margin-top: 1rem;
    width: 100%;
`;

const StyledButton = styled(Button)`
    margin-left: 1rem;
`;

const EditComment: FC<CommentProps> = memo(({ comment, onSave, onCancel }) => {
    const { body } = comment;
    const [newBody, setNewBody] = useState(body);
    const ref = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const input = ref.current;

        if (!input) return;

        input.focus();
        input.setSelectionRange(body.length, body.length);
    }, [ref]);

    const onFinishEdit = () => {
        onSave(newBody);
    };

    return (
        <Container>
            <TextField
                multiline
                fullWidth
                inputRef={ref}
                value={newBody}
                variant="outlined"
                onChange={(e) => {
                    setNewBody(e.target.value);
                }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                        onFinishEdit();
                    }
                }}
                id={`comment-${comment.commentId}`}
            />
            <ButtonContainer>
                <StyledButton size="small" variant="contained" color="primary" onClick={onFinishEdit}>
                    Save
                </StyledButton>
                <StyledButton size="small" variant="outlined" color="primary" onClick={onCancel}>
                    Cancel
                </StyledButton>
            </ButtonContainer>
        </Container>
    );
});

export default EditComment;
