import { IconSize } from '@adept-at/lib-react-components';
import { ListItem, Menu, IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { modularScale } from '../utils/modular_scale';

export const Crumb = styled.div`
    font-size: ${modularScale(1)};

    & + &:before {
        content: '/';
        margin: 0.5rem;
    }

    &:last-child {
        color: rgba(0, 0, 0, 0.87);
        opacity: 50%;
    }
`;

export const ContentWrapper = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: auto 70% auto;
    justify-content: center;
    width: 100%;
`;

export const BoxHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-right: 0.75rem;

    svg {
        width: ${IconSize.ExtraLarge};
        margin-right: 20px;
        opacity: 0.7;
    }
`;

export const AvatarIcon = styled.div`
    display: flex;
    align-items: end;

    svg {
        width: ${IconSize.Medium};
    }
`;

export const NewDayWrapper = styled.div`
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 1;
`;

export const StickyFallback = styled.div<{ $hidden?: boolean }>`
    position: relative;
    flex-shrink: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    max-height: 500px;

    ${(props) =>
        props.$hidden
            ? `
        max-height: 0px;
        transition: max-height 1s linear;
    `
            : null};

    span {
        border-radius: 5rem;
        border: 1px solid ${(props) => props.theme.colors.border};
        background: ${(props) => props.theme.colors.surface};
        padding: 0 6px;
        z-index: 1;
        color: ${(props) => props.theme.colors.textSecondary};
        font-size: ${modularScale(-0.5)};
        margin: 4px;
    }
`;

export const LineBreak = styled.div`
    position: absolute;
    top: -1px;
    height: calc(50% + 1px);
    width: 100%;
    background: ${(props) => props.theme.colors.surface};
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
    z-index: 0;
`;

export const StyledLink = styled(Link)`
    color: ${(props) => props.theme.colors.primary};
`;

export const StyledListItem = styled(ListItem)`
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.primary};
    cursor: pointer;

    svg {
        width: ${IconSize.Small};
        margin-right: 1rem;
    }
`;

export const StyledOptionsButton = styled(IconButton)`
    svg {
        fill: ${(props) => props.theme.colors.primary};
    }
`;

export const StyledMenu = styled(Menu)`
    .MuiPaper-root {
        background-color: ${(props) => props.theme.colors.floatingSurface};
    }
`;

//source https://gist.github.com/IceCreamYou/cd517596e5847a88e2bb0a091da43fb4
//this bit of css will override MacOS X hiding scrollbars on webkit browsers. Can be added to any component
//with overflow to always show the scrollbar. Will not propogate to children.
export const forceShowScrollbarsCSSFragment = `

   ::-webkit-scrollbar-track:vertical {
        border-left: 1px solid #e7e7e7;
        box-shadow: 1px 0 1px 0 #f6f6f6 inset, -1px 0 1px 0 #f6f6f6 inset;
    }

    ::-webkit-scrollbar-track:horizontal {
        border-top: 1px solid #e7e7e7;
        box-shadow: 0 1px 1px 0 #f6f6f6 inset, 0 -1px 1px 0 #f6f6f6 inset;
    }

    ::-webkit-scrollbar {
        -webkit-appearance: none;
        background-color: #fafafa;
        width: 16px;
    }

    ::-webkit-scrollbar-thumb {
        background-clip: padding-box;
        background-color: #c1c1c1;
        border-color: transparent;
        border-radius: 9px 8px 8px 9px;
        border-style: solid;
        border-width: 3px 3px 3px 4px; /* Workaround because margins aren't supported */
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }

    /* Unfortunately scrollbars can't use CSS transitions. Also, it's not possible
   to highlight the thumb when the scrollbar track is hovered without some
   JavaScript acrobatics; https://jsfiddle.net/QcqBM/6/ is a start, but you
   also have to check whether the element has a scrollbar and if so how wide
   it is. */
    ::-webkit-scrollbar-thumb:hover {
        background-color: rgba(0, 0, 0, 0.5);
    }
`;
export const forceShowScrollbarsJSSFragment = {
    webkitScrollbarTrack: {
        borderLeft: '1px solid #e7e7e7',
        boxShadow: '1px 0 1px 0 #f6f6f6 inset, -1px 0 1px 0 #f6f6f6 inset'
    },
    webkitScrollbar: {
        webkitAppearance: 'none',
        backgroundColor: '#fafafa',
        width: '16px'
    },
    webkitScrollbarThumb: {
        backgroundClip: 'padding-box',
        backgroundColor: '#c1c1c1',
        borderColor: 'transparent',
        borderRadius: '9px 8px 8px 9px',
        borderStyle: 'solid',
        borderWidth: '3px 3px 3px 4px',
        boxShadow: '0 0 1px rgba(255, 255, 255, 0.5)',
        ':hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
        }
    }
};
