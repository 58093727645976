import * as yup from 'yup';

import { MessageEventType } from '..';
import AckableAdepteductMessage from '../../base/AckableAdepteductMessage';

export enum MessageFieldsAffiliationModifiedName {
    Added = 'user.affiliation.added',
    Removed = 'user.affiliation.removed'
}

export interface MessageFieldsAffiliationModified {
    name: MessageFieldsAffiliationModifiedName;
    userId: string;
    affiliationId: string;
}

export default class UserAffiliationsModifiedMsg extends AckableAdepteductMessage<MessageFieldsAffiliationModified> {
    schema = yup.object({
        userId: yup.string().required(),
        affiliationId: yup.string().required(),
        name: yup.string().required()
    });

    type = MessageEventType.UserAffiliationsModified;
}
