import React, { ReactElement } from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { mdiHeart } from '@mdi/js';
import Icon from '@mdi/react';
import styled from 'styled-components';

const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 32px;
    background-color: ${(props) => props.theme.colors.surface};
`;

const Title = styled.h1`
    margin-left: 0.5rem;
`;

export const FavoritesHeader = (): ReactElement => (
    <HeaderContainer>
        <Icon path={mdiHeart} size={IconSize.Large} />
        <Title>Favorites</Title>
    </HeaderContainer>
);
