import { Images, ItemProgress } from 'components/ContentContext/Interfaces';
import { progressFragment, progressWithCompletedAtFragment } from 'components/dash/fragments';
import { CollectionChild } from 'components/RootProfileCollection/context/useGetTenantProfileForOwner';
import { collectionBasicDetailFragment, skillBasicDetailFragment } from 'components/RootProfileCollection/fragments';
import { CollectionBasicDetail } from 'components/RootProfileCollection/useGetNestedCollections/getCollectionBasicDetails';
import gql from 'graphql-tag';
import { QueryKey } from 'react-query';

export const buildGetEntitiesBasicDetailsQueryKey = (parentCollectionId: string, parentSlug: string): QueryKey => [
    'getEntitiesBasicDetails',
    parentCollectionId + parentSlug
];

export const getEntitiesBasicDetailsQuery = gql`
    query getEntityBasicDetails($entities: [EntityInfoInput]!) {
        getEntityBasicDetails(entities: $entities) {
            ... on SkillBasicDetail {
                ${skillBasicDetailFragment}
                ${progressWithCompletedAtFragment}
            }
            ... on CollectionBasicDetail {
                ${collectionBasicDetailFragment}
                ${progressFragment}
            }
        }
    }
`;

export interface GetEntitiesBasicInputVariables {
    type: CollectionChild;
    id: string;
}

export interface BasicDetailsSkillResponse {
    skillId: string;
    skillSlug: string;
    title: string;
    images: Images | null;
    visible: boolean;
    estimatedSecondsToConsume: number | null;
    progress?: ItemProgress;
}

export interface GetEntitiesBasicDetailsQueryResponse {
    getEntityBasicDetails: (BasicDetailsSkillResponse | CollectionBasicDetail)[];
}
