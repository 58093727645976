import { useEffect, useRef, useState } from 'react';

export interface ImageDimensions {
    width: string | number | null;
    height: string | number | null;
}

export const useResizableContainer = (ref: React.MutableRefObject<Element | null>): ImageDimensions => {
    const [size, setSize] = useState<ImageDimensions>({ width: null, height: null });

    const observer = useRef(
        new ResizeObserver((entries) => {
            // Only care about the first element, we expect one element to be watched
            const { width, height } = entries[0].contentRect;
            setSize({ width, height });
        })
    );

    useEffect(() => {
        if (ref.current) {
            observer.current.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                observer.current.unobserve(ref.current);
            }
        };
    }, [ref, observer]);

    return size;
};
