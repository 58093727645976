import React from 'react';

import { PDFComponentValues } from '@adept-at/lib-react-components';

import { useComponentEngine } from '../../EngineContext';
import { EditableComponentProvider } from '../../mixins/editor';
import { ComponentEngineMode } from '../../types';

import PDFEdit from './Edit';
import PDFView from './View';

interface PDFComponentProps {
    id: string;
    order: number;
}

export const PDF: React.FC<PDFComponentProps & PDFComponentValues> = ({ id, order, ...componentValues }) => {
    const { mode } = useComponentEngine();

    if (mode === ComponentEngineMode.Edit) {
        return (
            <EditableComponentProvider initialValues={componentValues}>
                <PDFEdit />
            </EditableComponentProvider>
        );
    }

    return <PDFView {...componentValues} />;
};

export const title = 'PDF';

export const Component = PDF;
