import React, { useContext } from 'react';

import { CircularProgress } from '@material-ui/core';
import { mdiDomain, mdiHammerScrewdriver, mdiBriefcaseAccount } from '@mdi/js';
import { TrackIconPath } from 'components/icons/TrackIcon';
import { ProfileContext } from 'context/ProfileContext';
import useOrganizationRoles, { OrganizationRolesTypes } from 'hooks/useOrganizationRoles';

import { WideDivider, NavLinkList } from './Nav.styles';
import NavCollapse from './NavCollapse';
import NavLink from './NavLink';

const NavOrganization: React.FC = () => {
    const { currentProfile } = useContext(ProfileContext);
    const { hasRole, hasAnyRole, adminOptionsVisible } = useOrganizationRoles();

    if (!currentProfile) {
        return <CircularProgress />;
    }

    const isOrgAdmin = () =>
        hasRole(OrganizationRolesTypes.SuperAdmin) || hasRole(OrganizationRolesTypes.LearningAdmin);

    const canSeeStudio = () => hasAnyRole() && !hasRole(OrganizationRolesTypes.Member);

    return (
        <>
            <NavLinkList>
                <NavLink to={`/${currentProfile?.alias}/catalog`} label="Catalog" iconPath={TrackIconPath} />
                <NavLink
                    to={`/${currentProfile?.alias}/internal`}
                    label="Internal Training"
                    iconPath={mdiBriefcaseAccount}
                />
            </NavLinkList>
            <WideDivider />
            {adminOptionsVisible() ? (
                <NavCollapse title="Administration">
                    {isOrgAdmin() ? (
                        <NavLink depth={1} to="/settings/organization/info" label="Organization" iconPath={mdiDomain} />
                    ) : null}

                    {canSeeStudio() ? (
                        <NavLink
                            depth={1}
                            to={`/organization/${currentProfile?.id}/studio`}
                            label="Studio"
                            iconPath={mdiHammerScrewdriver}
                        />
                    ) : null}
                </NavCollapse>
            ) : null}
        </>
    );
};

export default NavOrganization;
