import { IconSize } from '@adept-at/lib-react-components';
import styled from 'styled-components';

export const MenuItem = styled.div`
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.primary};
    margin: 0.4rem;

    svg {
        width: ${IconSize.Medium};
        margin-right: 1.5rem;
    }
`;
