import { Button } from '@material-ui/core';
import styled from 'styled-components';
import { SKILL_CONTENT_WIDTH } from 'styles/variables';

export const Container = styled.div`
    margin-top: 1rem;
    margin: 1rem auto 0;
    max-width: ${SKILL_CONTENT_WIDTH}px;

    fieldset {
        display: block;
        margin: 10px 0;

        label {
            position: relative;
            transform: none;
            padding-bottom: 5px;
        }
    }
`;

export const Info = styled.div`
    display: flex;
    align-items: center;
`;

export const VideoFileInfo = styled.div`
    display: flex;
    align-items: center;
`;

export const VideoEncodingInfo = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
`;

export const Controls = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
`;

export const ControlButton = styled(Button)`
    svg {
        width: 16px;
        margin-right: 0.5rem;
    }
`;
