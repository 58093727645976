import { gql, GraphQLClient } from 'graphql-request';
import { QueryFunction } from 'react-query';

import buildGqlQueryFn from '../../../hooks/useGqlClient/helpers/buildGqlQueryFn';

const GET_USER_BY_ALIAS = gql`
    query ($alias: String!) {
        getUserByAlias(alias: $alias) {
            id
            alias
        }
    }
`;

interface GetUserByAliasVariables {
    alias: string;
}

export interface GetUserByAliasResult {
    getUserByAlias: {
        id: string;
        alias: string;
    };
}

const getUserByAlias = (
    client: GraphQLClient,
    variables: GetUserByAliasVariables
): QueryFunction<GetUserByAliasResult> => buildGqlQueryFn<GetUserByAliasResult>(client, GET_USER_BY_ALIAS, variables);

export default getUserByAlias;
