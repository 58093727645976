import { Button } from '@material-ui/core';
import { ToggleState } from 'components/builder/BuildingHeader';
import { DefaultCollectionImageType } from 'components/ContentContext/Interfaces';
import styled, { css } from 'styled-components';

export const ChipWrapper = styled.div`
    margin-top: -1rem;
`;

export const PromptTextContainer = styled.div`
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 500;

    span {
        margin-right: 0.5rem;
    }

    svg {
        fill: ${(props) => props.theme.colors.textTertiary};
        width: 1rem;
        height: 1rem;
    }
`;

export const CollapsibleContainer = styled.div<{ $collapsed?: boolean }>`
    transition: max-height 0.3s ease;
    overflow: hidden;
    margin-top: 1.2rem;
    max-height: ${(props) => (props.$collapsed ? '0px' : '500px')};
`;

export const RecommendedSizePrompt = styled.span`
    color: ${(props) => props.theme.colors.textTertiary};
    font-size: 0.875rem;
`;

export const CatalogImageContainer = styled.div<{ $enableGutters?: boolean }>`
    display: flex;
    margin-bottom: 1rem;
    margin-top: 1.5rem;
    justify-content: ${(props) => (props.$enableGutters ? 'space-around' : 'flex-start')};
`;

export const EditImageWrapper = styled.div`
    margin-right: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 164px;
    min-height: 164px;

    span {
        text-transform: uppercase;
        font-size: 0.75rem;
        letter-spacing: 0.1rem;
        color: ${(props) => props.theme.colors.textTertiary};
    }
`;

export const ImageContainer = styled.div<{ $asDarkMode?: boolean }>`
    border-radius: 4px;
    display: flex;
    align-items: center;
    width: 164px;
    height: 164px;
    justify-content: center;
    margin-bottom: 0.5rem;
    border: 1px solid ${(props) => (props.$asDarkMode ? '#3c3f40' : '#d1dadf')};
    background-color: ${(props) => (props.$asDarkMode ? '#1d1e1f' : '#f2f4f5')};
`;

export const BackgroundPreviewContainer = styled.div`
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 3rem;
    display: flex;
    justify-content: space-between;
`;

export const BackgroundColumn = styled.div`
    width: calc(50% - 24px);

    span {
        text-transform: uppercase;
        font-size: 0.75rem;
        letter-spacing: 0.1rem;
        color: ${(props) => props.theme.colors.textTertiary};
    }
`;

export const BackgroundColumnWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const BackgroundPreviewWrapper = styled.div<{ $mobile?: boolean }>`
    width: ${(props) => (props.$mobile ? 'calc(30% - 24px)' : '70%')};
`;

const ShareBackgroundStyles = css`
    background-size: cover;
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.colors.border};
    overflow: hidden;
    width: 100%;
    height: 250px;
`;

interface BackgroundImageProps {
    $image?: DefaultCollectionImageType | string | null;
    $isDefaultImage?: boolean;
}

export const CollectionBackgroundImage = styled.div<BackgroundImageProps>`
    ${ShareBackgroundStyles};
    background-image: url(${(props) => props.$image});
`;

export const CollectionMobileBackground = styled.div<BackgroundImageProps>`
    ${ShareBackgroundStyles};
    background-image: url(${(props) => props.$image});
    background-position: ${(props) => (props.$isDefaultImage ? 'initial' : '90% 50%,0px 0px')};
`;

export const StyledCollectionImage = styled.div<{ $image?: string | null | undefined; $isDefault?: boolean }>`
    background-image: url(${(props) => props.$image});
    background-size: cover;
    background-color: ${(props) => (props.$isDefault ? 'white' : 'transparent')};
    border-radius: 50%;
    width: 124px;
    height: 124px;
`;

const SharedButtonStyles = css`
    span {
        text-transform: none;
        line-height: 1;
        letter-spacing: 0.02857em;
        white-space: nowrap;
        color: ${(props) => props.theme.colors.primary};
    }
    svg {
        width: 18px;
        height: 18px;
        color: inherit;
        margin-right: 8px;
    }
`;

export const UploadButtonsContainer = styled.div`
    padding-top: 0.5rem;
    display: flex;
    align-items: center;
`;

export const UploadImageButton = styled(Button)`
    ${SharedButtonStyles};
    font-weight: 500;
    font-size: 0.8125rem;
    padding-left: 9px;
    border-color: transparent;

    :disabled {
        color: ${(props) => props.theme.colors.primary};
        opacity: 0.5;
    }
`;

export const ToggleCustomImagesButton = styled(Button)`
    ${SharedButtonStyles};
    border-color: ${(props) => props.theme.colors.primary};
    padding: 8px 15px;
    font-weight: 600;
`;

export const StyledToggleState = styled(ToggleState)`
    font-size: 1rem;
`;

export const ImageModeContainer = styled.div<{ $enableGutters?: boolean }>`
    transition: 0.4s;
    margin-top: ${(props) => (props.$enableGutters ? '3rem' : '0')};
`;

export const ButtonContainer = styled.div<{ $selected?: boolean }>`
    width: 125px;
    height: 125px;
    min-width: 125;
    background-color: ${(props) => props.theme.colors.surface};
    border-radius: 50%;
    border: 1px solid ${(props) => (props.$selected ? props.theme.colors.primary : props.theme.colors.border)};
`;

export const CollectionImageButton = styled.img`
    cursor: pointer;
`;

export const BackgroundImageButton = styled.div<{ $image?: string; $selected?: boolean }>`
    cursor: pointer;
    background-image: url(${(props) => props.$image});
    background-size: cover;
    border-radius: 4px;
    overflow: hidden;
    width: 270px;
    height: 170px;
    border: ${(props) => (props.$selected ? `1px solid ${props.theme.colors.primary}` : 'none')};
`;
