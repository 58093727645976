import React, { useCallback, useContext, useEffect, useMemo } from 'react';

import { IconSize, defaultMuiTheme, QuestionType } from '@adept-at/lib-react-components';
import { Button } from '@material-ui/core';
import { mdiCheckCircleOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Skill } from 'components/ContentContext/Interfaces';
import { useAccountabilityContext } from 'components/learn/modes/accountability/AccountabilityContext';
import { useAssessmentContext } from 'components/learn/modes/assessment/AssessmentContext';
import { useQueryClientProgressUpdater } from 'components/learn/useProgress/useQueryClientProgressUpdater';
import styled from 'styled-components';

import { LearnContext } from '../../LearnContext';
import { allPollsComplete, isSkillComplete, mapComponentIdToQuestion } from '../../modes/accountability/utils';

import AssessmentSection from './AssessmentSection';

export const CompletionPrompt = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 8rem;

    margin-top: 2rem;
    ${defaultMuiTheme.breakpoints.up('sm')} {
        margin-top: 0;
    }
`;

const StyledButton = styled(Button)`
    svg {
        width: ${IconSize.Medium};
        margin-right: 0.5rem;
    }
`;

const StyledHeader = styled.h3`
    margin-bottom: 0.7rem;
`;

const StyledSpan = styled.span`
    margin-bottom: 1.2rem;
`;

const SkillCompletionSection: React.FC = () => {
    const { skill, tenantSlug, skillSlug } = useContext(LearnContext);
    const { accountabilityModeOn, completeSkill, finalComponentId } = useAccountabilityContext();
    const { assessmentModeOn } = useAssessmentContext();

    const allQuestionsComplete = useMemo(() => {
        const component = Object.values(skill?.components ?? {})[0];
        const componentIdToQuestion = mapComponentIdToQuestion(component);
        const completedQuestions = skill?.progress?.completedQuestions ?? [];

        return (
            Object.values(componentIdToQuestion).every(
                ({ questionId, questionType }) =>
                    completedQuestions.includes(questionId) || questionType === QuestionType.Poll
            ) && allPollsComplete(component)
        );
    }, [skill?.components, skill?.progress?.completedQuestions]);

    const buildNewProgressFromPrevious = useCallback(
        (previous: Skill) => {
            return {
                secondsConsumed: previous?.estimatedSecondsToConsume ?? 0,
                completedQuestions: previous?.progress?.completedQuestions ?? [],
                lastCompletedBlockKey: finalComponentId,
                updatedAt: new Date().toISOString()
            };
        },
        [finalComponentId]
    );

    const { updateSkillProgressQueryData } = useQueryClientProgressUpdater(
        tenantSlug,
        skillSlug,
        buildNewProgressFromPrevious
    );

    const isComplete = isSkillComplete(skill);

    const skillHasBeenUpdatedSinceLastProgress =
        skill.progress?.updatedAt && skill.progress.updatedAt < skill.updatedAt;

    const lastComponentIsQuestionAndAllQuestionsComplete = useMemo(() => {
        const componentIdToQuestion = mapComponentIdToQuestion(Object.values(skill?.components ?? {})[0]);

        return Boolean(
            finalComponentId &&
                allQuestionsComplete &&
                componentIdToQuestion[finalComponentId] &&
                !skillHasBeenUpdatedSinceLastProgress
        );
    }, [allQuestionsComplete, skill?.components, finalComponentId, skillHasBeenUpdatedSinceLastProgress]);

    useEffect(() => {
        if (lastComponentIsQuestionAndAllQuestionsComplete) {
            updateSkillProgressQueryData();
        }
    }, [lastComponentIsQuestionAndAllQuestionsComplete, updateSkillProgressQueryData]);

    if (isComplete || lastComponentIsQuestionAndAllQuestionsComplete) {
        return (
            <CompletionPrompt>
                <StyledHeader>Congrats!</StyledHeader>
                <StyledSpan>You{"'"}ve completed this skill!</StyledSpan>
            </CompletionPrompt>
        );
    }

    if (accountabilityModeOn) {
        return null;
    }

    if (assessmentModeOn) {
        return <AssessmentSection completeSkill={completeSkill} />;
    }

    if (allQuestionsComplete) {
        return (
            <CompletionPrompt>
                <StyledHeader>Congrats!</StyledHeader>
                <StyledSpan>You{"'"}ve made it to the end of this skill!</StyledSpan>
                <StyledButton variant="contained" color="primary" onClick={() => completeSkill()}>
                    <Icon path={mdiCheckCircleOutline} />
                    Complete skill
                </StyledButton>
            </CompletionPrompt>
        );
    } else {
        return (
            <CompletionPrompt>
                <span>Complete your quiz questions before proceeding</span>
            </CompletionPrompt>
        );
    }
};

export default SkillCompletionSection;
