import { ClientError } from 'graphql-request';
import gql from 'graphql-tag';
import { useBuildAuthenticatedQueryFn } from 'hooks/useGqlClient/useBuildAuthenticatedQueryFn';
import { Api } from 'lib/ApiConstants';
import { QueryKey, useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

// @GRAPHQL Query getOrganizationByAlias
const queryName = 'getOrganizationByAlias';

const GET_ORGANIZATION_GROUP_USERS = gql`
    query getOrganizationGroupUsers($organizationId: String!, $groupId: String!) {
        getOrganizationByAlias(alias: $alias) {
            id
            email
        }
    }
`;

export const buildGetOrganizationGroupUsersQueryKey = (organizationId?: string, groupId?: string): QueryKey => [
    queryName,
    organizationId,
    groupId
];

export interface GroupUser {
    id: string;
    email: string;
}

export interface GetOrganizationGroupUsersResult {
    getOrganizationGroupUsers: GroupUser[];
}

export interface GetOrganizationGroupUsersVariables {
    organizationId: string;
    groupId: string;
}

export const useGetOrganizationGroupUsers = (
    variables: GetOrganizationGroupUsersVariables,
    options: UseQueryOptions<GetOrganizationGroupUsersResult, ClientError>
): UseQueryResult<GetOrganizationGroupUsersResult, ClientError> => {
    const { queryFn } = useBuildAuthenticatedQueryFn<
        GetOrganizationGroupUsersResult,
        GetOrganizationGroupUsersVariables
    >(GET_ORGANIZATION_GROUP_USERS, variables, { api: Api.Accounts });

    const queryCacheKey = buildGetOrganizationGroupUsersQueryKey(variables.organizationId, variables.groupId);

    return useQuery<GetOrganizationGroupUsersResult, ClientError>(queryCacheKey, queryFn, options);
};
