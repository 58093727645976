import React, { useContext, useEffect } from 'react';

import { defaultMuiTheme } from '@adept-at/lib-react-components';
import { CircularProgress, useMediaQuery } from '@material-ui/core';
import { BookshelvesProvider } from 'components/bookshelves/BookshelvesContext';
import { ScheduleProvider } from 'components/dash/Schedule/ScheduleContext';
import { ScrollProvider } from 'components/learn/ScrollContext';
import { ModalView } from 'components/modals/Bookshelves/BookshelfModal';
import { NavContext, Tab } from 'components/navigation/NavContext';
import NestedCollection from 'components/NestedCollection';
import { NestedCollectionProvider } from 'components/NestedCollection/context/NestedCollectionContext';
import { ProfileContext, ProfileType } from 'context/ProfileContext';
import AppLayout from 'layouts/AppLayout';
import { useParams } from 'react-router-dom';
import { BREAKPOINT_900PX } from 'styles/variables';

import { EditableSkill } from './learn/EditableSkill';

const NestedCollectionSkillPage: React.FC = () => {
    const { setActiveTab } = useContext(NavContext);
    const { currentProfile, availableProfiles, selectProfile } = useContext(ProfileContext);

    const { skillSlug, tenantSlug, collectionSlugs } = useParams<{
        skillSlug: string;
        tenantSlug: string;
        collectionSlugs: string;
    }>();

    useEffect(() => {
        const foundProfile = availableProfiles?.find((profile) => profile.alias === tenantSlug);

        if (foundProfile) {
            selectProfile(foundProfile);
            setActiveTab(Tab.Organization);
        } else {
            selectProfile(availableProfiles.find((profile) => profile.type === ProfileType.Personal) ?? null);
        }
    }, [availableProfiles, selectProfile, setActiveTab, tenantSlug]);

    const is900PxOrSmallerScreenWidth = useMediaQuery(defaultMuiTheme.breakpoints.down(BREAKPOINT_900PX));
    if (!currentProfile) {
        return (
            <AppLayout withoutPadding={true} fullHeight>
                <CircularProgress />
            </AppLayout>
        );
    }

    return (
        <NestedCollectionProvider tenantSlug={tenantSlug} collectionSlugs={collectionSlugs}>
            <ScrollProvider>
                <ScheduleProvider>
                    <BookshelvesProvider shouldFetchOnInitialRender={false} initialModalView={ModalView.AddExisting}>
                        {skillSlug ? (
                            <EditableSkill />
                        ) : (
                            <AppLayout withoutPadding fullHeight mainContentOverflow={is900PxOrSmallerScreenWidth}>
                                <NestedCollection />
                            </AppLayout>
                        )}
                    </BookshelvesProvider>
                </ScheduleProvider>
            </ScrollProvider>
        </NestedCollectionProvider>
    );
};

export default NestedCollectionSkillPage;
