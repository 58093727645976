import React, {
    useState,
    useEffect,
    useRef,
    ChangeEvent,
    KeyboardEvent,
    useMemo,
    useCallback,
    CSSProperties
} from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { IconButton, TextField, Tooltip } from '@material-ui/core';
import { mdiPencil } from '@mdi/js';
import Icon from '@mdi/react';
import styled from 'styled-components';

const StyledIconButton = styled(IconButton)<{ $iconSize: IconSize; $buttonPadding: string }>`
    padding: ${(props) => props.$buttonPadding};

    &.MuiIconButton-root {
        padding: ${(props) => props.$buttonPadding};
    }

    svg {
        width: ${(props) => props.$iconSize};
        color: ${(props) => props.theme.colors.primary};
    }
`;

const StyledForm = styled.form<{
    $width: string;
    $canEdit: boolean;
}>`
    display: flex;
    align-items: start;
    height: fit-content;

    width: inherit;

    &:hover {
        ${StyledIconButton} {
            background: ${(props) => props.theme.colors.actionHover};
        }
    }
`;

const StyledTextField = styled(TextField)<{
    $inputPaddingRight: string;
    $maxWidth: string | number;
    $width: string | number;
}>`
    max-width: ${(props) => props.$maxWidth};
    width: ${(props) => props.$width};
    overflow-x: visible;

    .MuiInputBase-input {
        color: ${(props) => props.theme.colors.text};
        cursor: pointer;
        padding-right: ${(props) => props.$inputPaddingRight};

        &.Mui-disabled {
            cursor: text;
        }
    }

    .MuiInput-underline:before {
        border: 0;
    }

    .MuiInput-underline:hover:not(.MuiDisabled):before {
        border: 0;
    }
`;
export interface EditableTextProps {
    saveContent: (content: string) => void;
    initialContent: string;
    validation?: (content: string) => boolean;
    buttonAriaLabel?: string;
    inputAriaLabel?: string;
    shouldClearOnEdit?: boolean;
    canEdit?: boolean;
    pencilTooltipText?: string;
    styleProps?: CSSProperties;
    initialIsEditing?: boolean;
    allowEmptyContent?: boolean;
}

const EditableTextArea: React.FC<EditableTextProps> = ({
    canEdit = true,
    shouldClearOnEdit = false,
    inputAriaLabel,
    buttonAriaLabel,
    initialContent,
    saveContent,
    validation,
    initialIsEditing,
    allowEmptyContent,
    pencilTooltipText,
    styleProps
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [content, setContent] = useState(initialContent);
    const targetRef = useRef<HTMLSpanElement>(null);

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setContent(initialContent);
    }, [initialContent]);

    const isValid = validation ? validation(content || '') : true;

    const beginEdit = useCallback(() => {
        if (shouldClearOnEdit) {
            setContent('');
        }

        inputRef?.current?.select();
        inputRef?.current?.focus();

        setIsEditing(true);
    }, [shouldClearOnEdit]);

    useEffect(() => {
        if (initialIsEditing) {
            beginEdit();
        }
    }, [initialIsEditing, beginEdit]);

    const onSubmit = () => {
        if (!allowEmptyContent && content === '') {
            setContent(initialContent);
        } else if (isValid) {
            save();
        }

        inputRef?.current?.blur();
        setIsEditing(false);
    };

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setContent(e.target.value);
    };

    const onBlur = () => {
        setIsEditing(false);

        onSubmit();
    };

    const onFocus = () => {
        beginEdit();
    };

    const onKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            inputRef?.current?.blur();
        }
    };

    const save = () => {
        saveContent(content || '');
    };

    const DEFAULT_INPUT_WIDTH = 'fit-content';

    const [inputWidth, setInputWidth] = useState(DEFAULT_INPUT_WIDTH);

    useEffect(() => {
        const width = targetRef?.current?.offsetWidth ? `${targetRef?.current?.offsetWidth}px` : DEFAULT_INPUT_WIDTH;

        setInputWidth(width);
    }, [content]);

    const iconSize = IconSize.Small;
    const buttonPadding = '0.5rem';
    const buttonMarginLeft = useMemo(() => `calc(${iconSize} / 2)`, [iconSize]);
    const containerWidth = useMemo(
        () => `calc(${inputWidth} + ${iconSize} + ${buttonMarginLeft} + ${buttonPadding} + ${buttonPadding})`,
        [inputWidth, iconSize, buttonMarginLeft, buttonPadding]
    );

    const shouldRenderEditButton: boolean = useMemo(() => {
        return !isEditing && canEdit;
    }, [isEditing, canEdit]);

    return (
        <StyledForm aria-label="editable text form" $width={containerWidth} $canEdit={canEdit}>
            <span
                style={{
                    position: 'absolute',
                    opacity: 0
                }}
                ref={targetRef}
            >
                {content}
            </span>
            <StyledTextField
                id="standard-multiline-flexible"
                name="content"
                aria-label={inputAriaLabel ? inputAriaLabel : 'editable textarea input'}
                multiline
                value={content}
                onChange={onChange}
                onBlur={onBlur}
                onKeyPress={onKeyPress}
                onFocus={onFocus}
                disabled={!canEdit}
                onMouseMove={(e) => {
                    e.preventDefault();
                }}
                onTouchMove={(e) => {
                    e.preventDefault();
                }}
                data-testid="EditableTextArea-text-field"
                inputRef={inputRef}
                $inputPaddingRight={buttonMarginLeft}
                $maxWidth={styleProps?.maxWidth ? styleProps.maxWidth : 'inherit'}
                $width={styleProps?.width ? styleProps.width : 'initial'}
                inputProps={{ style: { ...styleProps } }}
            />
            {shouldRenderEditButton && (
                <Tooltip title={pencilTooltipText ?? ''} hidden={!pencilTooltipText}>
                    <StyledIconButton
                        $iconSize={iconSize}
                        $buttonPadding={buttonPadding}
                        onClick={beginEdit}
                        aria-label={buttonAriaLabel || 'edit text'}
                    >
                        <Icon path={mdiPencil} />
                    </StyledIconButton>
                </Tooltip>
            )}
        </StyledForm>
    );
};

export default EditableTextArea;
