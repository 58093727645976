import React from 'react';

import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';
import styled from 'styled-components';

interface DropzoneWrapperProps {
    image?: string;
    isLoading?: boolean;
    width?: string;
}

// @TODO should this background-color be something from our theme?
const DropzoneWrapper = styled.div<DropzoneWrapperProps>`
    cursor: pointer;
    position: relative;
    background-color: rgba(209, 218, 223, 0.15);
    border: ${(props) => (props.image && !props.isLoading ? 'none' : `dashed 2px ${props.theme.colors.border}`)};
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: ${(props) => (props.width ? props.width : '100%')};
    min-height: 400px;
`;

interface DropzoneProps {
    dropzoneProps?: DropzoneRootProps;
    inputProps?: DropzoneInputProps;
}

export const Dropzone: React.FC<DropzoneProps> = ({
    dropzoneProps = {},
    inputProps = { type: 'hidden' },
    children
}) => (
    <>
        <DropzoneWrapper data-testid="image-upload-dropzone" {...dropzoneProps}>
            {children}
        </DropzoneWrapper>
        <input {...inputProps} data-testid="image-upload-dropzone-input" />
    </>
);
