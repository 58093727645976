import React from 'react';

import { v4 as uuid } from 'uuid';

import { useComponentEngine } from '../../EngineContext';
import { EditableComponentProvider } from '../../mixins/editor';
import { ComponentEngineMode } from '../../types';

import VirtualLabEdit from './Edit';
import { VirtualLabView } from './View';
import { TemporaryLabValues } from './VirtualLabComponentValues';

export const emptyLabComponentValues = {
    vLabId: '',
    vLabTemplateId: '',
    name: '',
    vms: ''
};

export const VirtualLab: React.FC<TemporaryLabValues> = ({ name, order, ...componentValues }) => {
    const { mode } = useComponentEngine();
    const vLabId = componentValues.vLabId ?? uuid();

    if (mode === ComponentEngineMode.Edit) {
        return (
            <EditableComponentProvider initialValues={{ ...componentValues, vLabId }}>
                <VirtualLabEdit />
            </EditableComponentProvider>
        );
    }

    return <VirtualLabView componentValues={{ ...componentValues, name, order }} />;
};

export const title = 'Virtual Lab';
export const Component = VirtualLab;
