import React, { useState, useRef, useEffect } from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { Button } from '@material-ui/core';
import { mdiContentCopy } from '@mdi/js';
import Icon from '@mdi/react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled from 'styled-components';

interface CopyableTextProps {
    text: string | undefined;
}

const COPY_BUTTON_FEEDBACK_TIMEOUT = 3000;

const StyledIcon = styled(Icon)`
    color: ${(props) => props.theme.colors.text};
`;

const StyledButton = styled(Button)`
    float: right;
    margin-bottom: 10px;
`;

const TextContainer = styled.div`
    clear: right;
`;

export const CopyableText: React.FC<CopyableTextProps> = ({ text, children }) => {
    const [copied, setCopied] = useState<boolean>(false);
    const copiedTimeout = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        return () => {
            if (copiedTimeout.current) {
                clearTimeout(copiedTimeout.current);
            }
        };
    }, []);

    return (
        <>
            <CopyToClipboard
                text={text ?? ''}
                onCopy={() => {
                    console.log('copied');
                    setCopied(true);

                    if (copiedTimeout.current) {
                        clearTimeout(copiedTimeout.current);
                    }

                    copiedTimeout.current = setTimeout(() => {
                        setCopied(false);
                    }, COPY_BUTTON_FEEDBACK_TIMEOUT);
                }}
            >
                <StyledButton
                    aria-label="Copy Code to Clipboard"
                    variant="contained"
                    startIcon={<StyledIcon path={mdiContentCopy} size={IconSize.Small} />}
                >
                    {copied ? 'Copied' : 'Copy'}
                </StyledButton>
            </CopyToClipboard>
            <TextContainer>{children}</TextContainer>
        </>
    );
};
