import React, { createContext, useContext, useState } from 'react';

import { EntityType } from 'components/ContentContext/Enums';
import { useHistory } from 'react-router-dom';

export type EditableItem = { id: string; editUrl: string; type: string; canArchive: boolean };

export type EditItemFunction = <T extends EditableItem>(item: T) => void;

export interface EditItemContextInterface {
    editingLabId: string | null;
    isEditLabModalOpen: boolean;
    editItem: EditItemFunction;
    handleEditLabModalClosed: () => void;
}

export const EditItemContext = createContext({} as EditItemContextInterface);

const { Provider } = EditItemContext;

export const EditItemProvider: React.FC = ({ children }) => {
    const history = useHistory();
    const [editingLabId, setEditingLabId] = useState<string | null>(null);

    const editItem: EditItemFunction = (item) => {
        if (item.type === EntityType.VIRTUAL_LAB) {
            setEditingLabId(item.id);
        } else {
            // All other items are fully editable at their URL, so there's nothing else to do with them in this hook.
            history.push(item.editUrl);
        }
    };

    const handleEditLabModalClosed = () => {
        console.log(`handleEditLabModalClosed`);
        setEditingLabId(null);
    };

    return (
        <Provider value={{ editingLabId, isEditLabModalOpen: !!editingLabId, editItem, handleEditLabModalClosed }}>
            {children}
        </Provider>
    );
};

export const useEditItemContext = (): EditItemContextInterface => {
    const context = useContext(EditItemContext);

    if (!context) {
        throw new Error('useEditItemContext must be used within an EditItemProvider');
    }

    return context;
};
