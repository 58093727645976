import { gql, ClientError } from 'graphql-request';
import { useBuildAuthenticatedQueryFn } from 'hooks/useGqlClient/useBuildAuthenticatedQueryFn';
import { Api } from 'lib/ApiConstants';
import { QueryKey, useQuery, UseQueryResult } from 'react-query';

export interface GetCollectionsBySlugsResponse {
    getCollectionBasicDetailsBySlugs: CollectionBySlug[];
}

export interface CollectionBySlug {
    collectionId: string;
    title: string;
    collectionSlug: string;
    rootCollectionId: string;
}

// @GRAPHQL Query getCollectionBasicDetailsBySlugs
const queryName = 'getCollectionBasicDetailsBySlugs';

export const buildQueryKey = (collectionSlugs: string[]): QueryKey => [queryName, collectionSlugs];

const GET_COLLECTIONS_BY_SLUGS = gql`
    query ${queryName}($tenantSlug: String!, $collectionSlugs: [String!]) {
        ${queryName}(tenantSlug: $tenantSlug, collectionSlugs: $collectionSlugs) {
            collectionId
            title
            collectionSlug
            rootCollectionId
        }
    }
`;

interface GetCollectionsBySlugsVariables {
    collectionSlugs: string[];
    tenantSlug: string;
}

export const useGetCollectionsBySlugs = (
    variables: GetCollectionsBySlugsVariables,
    enabled: boolean
): UseQueryResult<GetCollectionsBySlugsResponse, ClientError> => {
    const { queryFn } = useBuildAuthenticatedQueryFn<GetCollectionsBySlugsResponse, GetCollectionsBySlugsVariables>(
        GET_COLLECTIONS_BY_SLUGS,
        variables,
        { api: Api.Content }
    );

    return useQuery<GetCollectionsBySlugsResponse, ClientError>(buildQueryKey(variables.collectionSlugs), {
        queryFn,
        enabled
    });
};
