import React, { useMemo, useRef } from 'react';

import { ComponentValuesType } from '@adept-at/lib-react-components';
import { ComponentEngineMode } from 'components/engine/types';
import AccountabilityNav from 'components/learn/modes/accountability/AccountabilityNav/AccountabilityNav';
import HiddenComponent from 'components/learn/modes/accountability/HiddenComponent/HiddenComponent';
import { ProgressState } from 'utils/progress';

import { getComponentForType } from '../engine/Components';

import { ComponentContextProvider } from './ComponentContext';
import { ControlOptions } from './ComponentControls';
import { useComponentEngine } from './EngineContext';

interface ComponentEngineComponentProps {
    id: string;
    type: ComponentValuesType;
    order: number;
    values: Record<string, any>;
    container: React.RefObject<HTMLDivElement> | null;
    controlOptions?: ControlOptions;
}

const ComponentEngineComponent: React.FC<ComponentEngineComponentProps> = ({ id, type, order, values, container }) => {
    const Component = useMemo(() => getComponentForType(type), [type]);
    const componentContainerRef = useRef<HTMLDivElement>(null);
    const { mode } = useComponentEngine();

    if (!Component) {
        console.warn(`Unable to display component of type: ${type}`);
        // @TODO error or notification here
        return <></>;
    }

    // @TODO validate component before attempting to render
    if (!id) {
        return null;
    }

    return (
        <div ref={componentContainerRef}>
            <ComponentContextProvider id={id} type={type} order={order}>
                {mode === ComponentEngineMode.Accountability && values.progress === ProgressState.NOT_STARTED ? (
                    <HiddenComponent componentValues={values} componentType={type} />
                ) : (
                    <Component id={id} order={order} {...values} />
                )}
                {mode === ComponentEngineMode.Accountability && values.progress === ProgressState.IN_PROGRESS ? (
                    <AccountabilityNav componentValues={values} componentId={id} componentType={type} />
                ) : null}
            </ComponentContextProvider>
        </div>
    );
};

export { ComponentEngineComponent };
