import { ClientError } from 'graphql-request';
import gql from 'graphql-tag';
import { useBuildAuthenticatedQueryFn } from 'hooks/useGqlClient/useBuildAuthenticatedQueryFn';
import { Api } from 'lib/ApiConstants';
import { QueryKey, useQuery, UseQueryResult } from 'react-query';

const queryName = 'getCollectionIsFree';
export const buildGetFreePayloadQueryKey = (collecitonId: string): QueryKey => [queryName, collecitonId];

export const GET_FREE_PAYLOAD_QUERY = gql`
    query ${queryName}($collectionId: UUIDv4!) {
        ${queryName}(collectionId: $collectionId){
            payload {
                collectionId
                now  
                free
            }
            signature
        }
    }
`;

export interface IsFreeInput {
    payload: {
        collectionId: string;
        now: string;
        free: boolean;
    };
    signature: string;
}

export interface GetFreePayloadResponse {
    getCollectionIsFree: IsFreeInput;
}

export interface useGetFreePayloadVariables {
    collectionId?: string | null;
}

export const useGetFreePayload = (
    variables: useGetFreePayloadVariables,
    enabled: boolean
): UseQueryResult<GetFreePayloadResponse, ClientError> => {
    const { queryFn } = useBuildAuthenticatedQueryFn<GetFreePayloadResponse, useGetFreePayloadVariables>(
        GET_FREE_PAYLOAD_QUERY,
        variables,
        { api: Api.Content }
    );

    return useQuery<GetFreePayloadResponse, ClientError>(
        buildGetFreePayloadQueryKey(variables.collectionId ?? 'unknown'),
        {
            queryFn,
            enabled: !!variables.collectionId && enabled
        }
    );
};
