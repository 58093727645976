import React from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { Button } from '@material-ui/core';
import { mdiLocationEnter } from '@mdi/js';
import Icon from '@mdi/react';
import { mapAvailabilityToStatus } from 'components/CollaborationTable';
import { Skill } from 'components/ContentContext/Interfaces';
import { AvailabilityModal } from 'components/skills/AvailabilityModal';
import { useModal } from 'hooks/useModal';
import { StyledListItem } from 'styles/Shared.styles';

interface AvailabilityButtonProps {
    skill: Skill;
    variant?: 'button' | 'listItem';
}

export const AvailabilityButton: React.FC<AvailabilityButtonProps> = ({ skill, variant = 'button' }) => {
    const { modalOpen, handleModalOpen, handleModalClose } = useModal();

    return (
        <>
            {variant === 'button' ? (
                <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    startIcon={<Icon path={mdiLocationEnter} size={IconSize.Medium} />}
                    onClick={handleModalOpen}
                >
                    {mapAvailabilityToStatus(skill)}
                </Button>
            ) : (
                <StyledListItem button onClick={handleModalOpen}>
                    <Icon path={mdiLocationEnter} /> {mapAvailabilityToStatus(skill)}
                </StyledListItem>
            )}
            <AvailabilityModal
                open={modalOpen}
                onClose={handleModalClose}
                entity={{ ...skill, id: skill.skillId, isInternal: false }}
            />
        </>
    );
};
