import { datadogLogs, HandlerType, LogsEvent } from '@datadog/browser-logs';
import { datadogRum, RumEvent } from '@datadog/browser-rum';

const DATADOG_RUM_APPLICATION_ID = 'c6274707-a6d4-4aff-a21f-01ca0425f6d8';
const DATADOG_CLIENT_TOKEN = 'pub24acaee57c048667a1b7cb4328d014b4';
const DATADOG_SERVICE = 'learn-webapp';
const DATADOG_SITE = 'datadoghq.com';
const DATADOG_SAMPLE_RATE = 100;

/**
 * filter out Mux GET requests with status codes of 0(no response body) as these are likely cancelled requests for more
 * chunks that result from reloads or navigating away while loading videos
 */
const isEventForMux = (event): boolean =>
    event.type === 'error' &&
    event.error &&
    (event.error as any)?.resource &&
    (event.error as any).resource?.status_code === 0 &&
    typeof (event.error as any).resource?.url === 'string' &&
    (event.error as any).resource?.url.startsWith('https://stream.mux.com');

export const initialize = (): void => {
    // For production builds, derive environment from the URL
    let environment: string = process.env.NODE_ENV;
    if (process.env.NODE_ENV === 'production' && window.location) {
        const hostParts: string[] = window.location.host.split('.');
        // ex: learn.bonanza.adept.at. env = bonanza
        if (hostParts.length === 4) {
            environment = hostParts[1];
        }
    }

    const loggingEnabled: boolean =
        process.env.NODE_ENV === 'production' || process.env.REACT_APP_LOGGING_ENABLED === 'true';

    datadogLogs.logger.setHandler(loggingEnabled ? HandlerType.http : HandlerType.console);

    if (loggingEnabled) {
        datadogRum.init({
            applicationId: DATADOG_RUM_APPLICATION_ID,
            clientToken: DATADOG_CLIENT_TOKEN,
            site: DATADOG_SITE,
            service: DATADOG_SERVICE,
            env: environment,
            version: process.env.ADEPT_VERSION,
            sampleRate: DATADOG_SAMPLE_RATE,
            trackInteractions: true,
            allowedTracingOrigins: ['https://api.adept.at', /https:\/\/api\..*\.adept\.at/],
            beforeSend: (event: RumEvent) => {
                if (isEventForMux(event)) {
                    return false;
                }
            }
        });

        datadogLogs.init({
            clientToken: DATADOG_CLIENT_TOKEN,
            site: DATADOG_SITE,
            service: DATADOG_SERVICE,
            env: environment,
            version: process.env.ADEPT_VERSION,
            forwardErrorsToLogs: true,
            sampleRate: DATADOG_SAMPLE_RATE,
            beforeSend: (event: LogsEvent) => {
                if (isEventForMux(event)) {
                    return false;
                }
            }
        });

        // Do not record headless chrome tests
        const isHeadlessChrome = /HeadlessChrome/.test(window.navigator.userAgent);
        const isDatadogSynthetic = /Datadog/.test(window.navigator.userAgent);
        const isAdeptUserAgent = /Adept/.test(window.navigator.userAgent);

        const recordEnabled = !isHeadlessChrome && !isDatadogSynthetic && !isAdeptUserAgent;

        if (environment === 'production' && recordEnabled) {
            datadogRum.startSessionReplayRecording();
        }
    }
};
