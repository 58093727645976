import { CropDetails } from '@adept-at/lib-react-components';
import { gql, ClientError } from 'graphql-request';
import { useBuildAuthenticatedPromisfiedFetch } from 'hooks/useGqlClient/useBuildAuthenticatedPromisfiedFetch';
import { useBuildAuthenticatedQueryFn } from 'hooks/useGqlClient/useBuildAuthenticatedQueryFn';
import { Api } from 'lib/ApiConstants';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

export interface GetAvatarsVariables {
    assetIds: string[];
}

export interface GetAvatarsResponse {
    getAvatars: AvatarResponse[];
}

export interface AvatarResponse {
    id: string;
    crop: CropDetails;
    original: {
        url: string;
    };
    processed: [{ url: string }];
}

/**
 * @TODO optimization here -- we should be using processed values and selecting size specifically. Seems like
 * consumers of this are just dealing with the original + crop
 */

const GET_AVATARS_QUERY = gql`
    query getAvatars($assetIds: [String]!) {
        getAvatars(assetIds: $assetIds) {
            id
            crop
            original {
                url
            }
            processed @sizes(widths: [72]) @formats(exts: [JPG]) {
                url
            }
        }
    }
`;

interface UseGetAvatarsPromisfiedFetchInterface {
    fetch: (variables: GetAvatarsVariables) => Promise<GetAvatarsResponse>;
}

export const useGetAvatarsPromisfiedFetch = (): UseGetAvatarsPromisfiedFetchInterface => {
    const { fetch } = useBuildAuthenticatedPromisfiedFetch<GetAvatarsResponse, GetAvatarsVariables>(
        GET_AVATARS_QUERY,
        Api.Content
    );

    return { fetch };
};

export const useGetAvatars = (
    { assetIds }: GetAvatarsVariables,
    options: UseQueryOptions<GetAvatarsResponse, ClientError> = { enabled: false }
): UseQueryResult<GetAvatarsResponse, ClientError> => {
    const { queryFn } = useBuildAuthenticatedQueryFn<GetAvatarsResponse, GetAvatarsVariables>(
        GET_AVATARS_QUERY,
        { assetIds },
        { api: Api.Content }
    );

    return useQuery<GetAvatarsResponse, ClientError>(['getAvatars', assetIds], queryFn, options);
};
