import React, { useContext, useMemo } from 'react';

import { Box, ListItemText } from '@material-ui/core';
import OrgImage from 'components/navigation/OrgImage';
import { ProfileType, ProfileContext } from 'context/ProfileContext';

import { AvatarContainer } from '.';

interface OrgOwnerDisplayProps {
    organizationId: string;
}
export const OrganizationOwner: React.FC<OrgOwnerDisplayProps> = ({ organizationId }) => {
    const { availableProfiles } = useContext(ProfileContext);

    const matchingOrg = useMemo(() => {
        return availableProfiles.find((i) => i.type === ProfileType.Organization && i.id === organizationId);
    }, [availableProfiles]);

    return (
        <Box display={'block'}>
            <AvatarContainer>
                <OrgImage label={matchingOrg?.label ?? 'Unknown'} imageId={matchingOrg?.imageId} />
            </AvatarContainer>
            <ListItemText>{matchingOrg?.label ?? 'Unknown organization'}</ListItemText>
        </Box>
    );
};
