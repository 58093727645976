import { OptimistMutationContext, useOptimist } from '@adept-at/lib-react-optimist';
import { EntityInfo } from 'components/ContentContext/Interfaces';
import { ClientError } from 'graphql-request';
import gql from 'graphql-tag';
import { useBuildAuthenticatedMutationFn } from 'hooks/useGqlClient/useBuildAuthenticatedMutationFn';
import { Api } from 'lib/ApiConstants';
import { useMutation, UseMutationResult } from 'react-query';

interface CreateLinkLaunchUrlResult {
    launchLink: {
        linkUrl: string;
    };
}

interface CreateLinkLaunchUrlVariables {
    externalId: string;
    entity: EntityInfo;
}

const CREATE_LINK_LAUNCH_URL_MUTATION = gql`
    mutation launchLink($externalId: String!, $entity: EntityInfoInput!) {
        launchLink(externalId: $externalId, entity: $entity) {
            linkUrl
        }
    }
`;

interface UseCreateLinkLaunchUrlOptions {
    onSuccess?: () => void;
}

export const useCreateLinkLaunchUrl = (
    options?: UseCreateLinkLaunchUrlOptions
): UseMutationResult<CreateLinkLaunchUrlResult, ClientError, CreateLinkLaunchUrlVariables, OptimistMutationContext> => {
    const { onSuccess } = options ?? {};

    const { mutationOptions } = useOptimist<CreateLinkLaunchUrlResult, ClientError, CreateLinkLaunchUrlVariables>({
        globalMutationOptions: {
            onSuccess: () => {
                if (onSuccess) onSuccess();
            }
        }
    });

    const { mutationFn } = useBuildAuthenticatedMutationFn<CreateLinkLaunchUrlResult, CreateLinkLaunchUrlVariables>(
        CREATE_LINK_LAUNCH_URL_MUTATION,
        { api: Api.Content }
    );

    return useMutation<CreateLinkLaunchUrlResult, ClientError, CreateLinkLaunchUrlVariables, OptimistMutationContext>(
        mutationFn,
        mutationOptions
    );
};
