import { ClientError } from 'graphql-request';
import gql from 'graphql-tag';
import { useBuildAuthenticatedQueryFn } from 'hooks/useGqlClient/useBuildAuthenticatedQueryFn';
import { Api } from 'lib/ApiConstants';
import { useQuery, UseQueryResult, QueryKey } from 'react-query';

import { CollectionBasicDetail } from '../useGetNestedCollections/getCollectionBasicDetails';

const buildQueryKey = (parentCollectionId: string): QueryKey => [queryName, parentCollectionId];

const queryName = 'getInsertableCollections';

const GET_AVAILABLE_COLLECTIONS = gql`
    query ${queryName}($parentCollectionId: UUIDv4!) {
        ${queryName}(parentCollectionId: $parentCollectionId) {
            collectionId
            title
            collectionSlug
        }
    }
`;

export interface GetInsertableCollectionsResponse {
    getInsertableCollections: CollectionBasicDetail[];
}

export interface GetInsertableCollectionsVariables {
    parentCollectionId: string;
}

const useGetAvailableExistingCollections = (
    variables: GetInsertableCollectionsVariables,
    enabled = true
): UseQueryResult<GetInsertableCollectionsResponse, ClientError> => {
    const { queryFn } = useBuildAuthenticatedQueryFn<
        GetInsertableCollectionsResponse,
        GetInsertableCollectionsVariables
    >(GET_AVAILABLE_COLLECTIONS, variables, { api: Api.Content });

    return useQuery<GetInsertableCollectionsResponse, ClientError>(buildQueryKey(variables.parentCollectionId), {
        queryFn,
        enabled
    });
};

export default useGetAvailableExistingCollections;
