import React, { useCallback, useMemo } from 'react';

import { CollectionImage, DotList, IconSize } from '@adept-at/lib-react-components';
import { mdiArrowLeft } from '@mdi/js';
import Icon from '@mdi/react';
import { useGetNestedCollectionBySlug } from 'components/NestedCollection/context/getNestedCollectionBySlug';
import { CollectionStatType } from 'components/RootProfileCollection/context/useGetTenantProfileForOwner';
import { useOrganizationTerminology } from 'hooks/useOrganizationTerminology';
import pluralize from 'pluralize';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { getEditedCollectionsFromSessionStorage } from 'utils/collection';
import { formatTime } from 'utils/time';

import { useCollectionSlugs } from './hooks/useCollectionSlugs';

const StyledCard = styled.div`
    height: ${155}px;
    width: 100%;
    background-color: ${(props) => props.theme.colors.surface};
    border-bottom: 1px solid ${(props) => props.theme.colors.border};

    grid-area: tab-panel-header;
    padding: 0.5rem;
`;

const Title = styled.div`
    display: flex;
    align-content: center;
    text-overflow: ellipsis;
    flex-shrink: 0;
`;

const StyledIcon = styled(Icon)`
    flex-shrink: 0;
    width: ${IconSize.Small};
    height: ${IconSize.Small};
    color: ${(props) => props.theme.colors.primary};
    margin-top: 0.2rem;
    margin-right: 0.5rem;
`;

const StyledAdeptCardContent = styled(Link)`
    grid-area: card-content;
    padding: 1.5rem;
    width: 100%;
    max-width: 700px;
    margin: 0 auto;

    display: flex;
    align-items: center;
`;

const InfoContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 1rem;
    flex-shrink: 1;
    height: 90px;
`;

const StyledParagraph = styled.p`
    font-size: ${(props) => props.theme.ms(-0.5)};
    flex-shrink: 1;
`;

const StyledCollectionImage = styled(CollectionImage.Medium)`
    flex-shrink: 0;
    border-radius: 100%;
`;

export const ParentCollectionCard: React.FC = () => {
    const { collectionSlugsArray, hasParentCollection, collectionSlugs, tenantSlug, skillSlug, parentQuerySlug } =
        useCollectionSlugs();

    const usePrimary = useMemo(() => {
        const editedCollections = getEditedCollectionsFromSessionStorage();
        return editedCollections.includes(parentQuerySlug ?? '');
    }, [parentQuerySlug]);

    const { data, isLoading } = useGetNestedCollectionBySlug(parentQuerySlug ?? '', usePrimary, hasParentCollection);

    const parentCollection = useMemo(() => {
        return data?.getEntityBySlug;
    }, [data?.getEntityBySlug]);

    const getStatCount = useCallback(
        (type: CollectionStatType) => {
            const statForType = parentCollection?.stats?.descendants?.find((stat) => stat.type === type);
            return statForType?.count ?? 0;
        },
        [parentCollection?.stats?.descendants]
    );

    const { terminology } = useOrganizationTerminology();

    if (hasParentCollection) {
        if (isLoading) {
            return <StyledCard />;
        }

        return (
            <StyledCard>
                <StyledAdeptCardContent
                    to={`/${tenantSlug}/${
                        skillSlug
                            ? collectionSlugs
                            : collectionSlugsArray.slice(0, collectionSlugsArray?.length - 1).join('/')
                    }`}
                    aria-label="Return to collection"
                >
                    <StyledCollectionImage image={parentCollection?.images?.catalog} />
                    <InfoContent>
                        <Title>
                            <StyledIcon path={mdiArrowLeft} />
                            <h4>{parentCollection?.title}</h4>
                        </Title>
                        <div>
                            <StyledParagraph>
                                {formatTime(parentCollection?.stats?.estimatedSecondsToConsume ?? 0, 'medium')}
                            </StyledParagraph>
                            <DotList>
                                {[
                                    pluralize(terminology.skill, getStatCount(CollectionStatType.Skill), true),
                                    pluralize('lab', getStatCount(CollectionStatType.Lab), true),
                                    pluralize('question', getStatCount(CollectionStatType.Question), true)
                                ]}
                            </DotList>
                        </div>
                    </InfoContent>
                </StyledAdeptCardContent>
            </StyledCard>
        );
    }

    return null;
};
