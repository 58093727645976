import { useContext, useCallback } from 'react';

import HistoricalCommunicationContext from 'context/HistoricalCommunicationContext';
import { API_COMMUNICATION } from 'lib/ApiConstants';
import { MessageAckType, MessageEntityType } from 'lib/communication/message/MessageRecord';
import { useMutation } from 'react-query';

import useGqlClient from '../../../useGqlClient';

import ackMessageApi from './ackMessageApi';

export interface UseAckMessageOutput {
    ackMessage: (channelId: string, messageId: string, ackType: MessageAckType) => void;
}

/**
 * Ack any message with any ack type, and it will be correctly handled both render-wise and in the backend.
 * Acking in RealTimeCommunicationContext is handled by an update message, no intervention needed by this hook.
 */
const useAckMessage = (): UseAckMessageOutput => {
    const { ackMessage: ackMessageInContext } = useContext(HistoricalCommunicationContext);

    const { client, withMutationOptions } = useGqlClient(API_COMMUNICATION);
    const { mutate: ackMessageInApi } = useMutation(ackMessageApi(client), withMutationOptions());

    const ackMessage = useCallback(
        (channelId: string, messageId: string, ackType: MessageAckType) => {
            ackMessageInContext(channelId, messageId, ackType);
            ackMessageInApi({ entityId: channelId, entityType: MessageEntityType.Channel, messageId, ackType });
        },
        [ackMessageInApi, ackMessageInContext]
    );

    return { ackMessage };
};

export default useAckMessage;
