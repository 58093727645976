import { useMemo } from 'react';

import { OptimistMutationContext, useOptimist } from '@adept-at/lib-react-optimist';
import cloneDeep from 'clone-deep';
import { EntityInfo, OwnerInfo } from 'components/ContentContext/Interfaces';
import { GetTenantProfileResponse } from 'components/RootProfileCollection/context/useGetTenantProfile';
import { RootCollectionResponse } from 'components/RootProfileCollection/context/useGetTenantProfileForOwner';
import { CollectionBasicDetail } from 'components/RootProfileCollection/useGetNestedCollections/getCollectionBasicDetails';
import { ClientError, gql } from 'graphql-request';
import { useBuildAuthenticatedMutationFn } from 'hooks/useGqlClient/useBuildAuthenticatedMutationFn';
import { Api } from 'lib/ApiConstants';
import { QueryKey, useMutation, UseMutationResult } from 'react-query';

import { buildMarketplaceQueryKey, GetMarketplaceListingsResponse } from './useGetMarketplaceListings';

export interface UpsertListingStatusResult {
    upsertListingStatus: {
        success: boolean;
    };
}

export enum MarketplaceListingStatus {
    unlisted = 'unlisted',
    listed = 'listed'
}

export interface UpserListingStatusVariables {
    listingOwner: EntityInfo;
    listingContent: EntityInfo;
    status: MarketplaceListingStatus;
}

const UPSERT_LISTING_STATUS_MUTATION = gql`
    mutation upsertListingStatus(
        $listingOwner: EntityInfoInput!
        $listingContent: EntityInfoInput!
        $status: MarketplaceListingStatus!
    ) {
        upsertListingStatus(listingOwner: $listingOwner, listingContent: $listingContent, status: $status) {
            success
        }
    }
`;

const useUpsertListingStatus = (
    queryKey: QueryKey,
    tenantRootCollection?: RootCollectionResponse
): UseMutationResult<UpsertListingStatusResult, ClientError, UpserListingStatusVariables, OptimistMutationContext> => {
    const { mutationFn } = useBuildAuthenticatedMutationFn<UpsertListingStatusResult, UpserListingStatusVariables>(
        UPSERT_LISTING_STATUS_MUTATION,
        { api: Api.Content }
    );
    const { registerUpdater, mutationOptions } = useOptimist<
        UpsertListingStatusResult,
        ClientError,
        UpserListingStatusVariables
    >();

    const marketplaceQueryKey = useMemo(() => buildMarketplaceQueryKey(), []);

    const upsertListingStatusOptions = useMemo(() => {
        return {
            refetchQuery: false
        };
    }, []);

    registerUpdater<GetMarketplaceListingsResponse>(
        marketplaceQueryKey,
        (previous, upsertVariables) => {
            if (upsertVariables.status === MarketplaceListingStatus.listed && tenantRootCollection) {
                previous.getMarketplaceListings.push(tenantRootCollection as unknown as CollectionBasicDetail);
            }
            if (upsertVariables.status === MarketplaceListingStatus.unlisted) {
                previous.getMarketplaceListings = previous.getMarketplaceListings.filter(
                    (collection) => collection.collectionId !== upsertVariables.listingContent.id
                );
            }
            return previous;
        },
        { refetchQuery: true }
    );

    registerUpdater<GetTenantProfileResponse>(
        queryKey,
        (previous, upsertVariables) => {
            previous = cloneDeep(previous);
            Object.keys(previous).map((queryName) => {
                previous[queryName].marketplaceStatus = upsertVariables.status;
            });
            return previous;
        },
        upsertListingStatusOptions
    );

    return useMutation<UpsertListingStatusResult, ClientError, UpserListingStatusVariables, OptimistMutationContext>(
        mutationFn,
        mutationOptions
    );
};

export default useUpsertListingStatus;
