import React from 'react';

import { StyledTableCell } from './TableCell.styles';

interface CompletedQuestionsCellProps {
    completedQuestions?: number;
}

export const COMPLETED_QUESTIONS_CELL_WIDTH = 255;

export const CompletedQuestionsCell: React.FC<CompletedQuestionsCellProps> = ({ completedQuestions }) => {
    return <StyledTableCell width={COMPLETED_QUESTIONS_CELL_WIDTH}>{completedQuestions ?? 0}</StyledTableCell>;
};
