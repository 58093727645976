import React from 'react';

import { AvailabilityChip } from '../AvailabilityChip';
import { CollaborativeEntity } from '../utils';

import { StyledTableCell } from './TableCell.styles';

export const AVAILABILITY_CELL_WIDTH = 250;

export const AvailabilityCell: React.FC<CollaborativeEntity> = (entity) => {
    return (
        <StyledTableCell width={AVAILABILITY_CELL_WIDTH}>
            <AvailabilityChip {...entity} />
        </StyledTableCell>
    );
};
