import { TableRow, TableCell } from '@material-ui/core';
import styled from 'styled-components';

export const StyledTableRow = styled(TableRow)`
    &:nth-of-type(odd) {
        background-color: #fefefe;
    }
`;

export const StyledTableCell = styled(TableCell)``;
