import React from 'react';

import { AnswerOption } from '@adept-at/lib-react-components';
import { Button } from '@material-ui/core';

import { getRandomBytes } from '../lib/question';

interface Props {
    answerOptions: AnswerOption[]; // an immer immutable state
    onChange: <TComponentFieldValue>(field: string) => (value: TComponentFieldValue) => void;
}

export const AddAnswer: React.FC<Props> = ({ answerOptions, onChange, children }) => (
    <Button
        data-testid="questionEdit-addAnswer"
        onClick={() => {
            const draftAnswerOptions = [...answerOptions, { key: getRandomBytes(), text: '' }];
            onChange('answerOptions')(draftAnswerOptions);
        }}
    >
        {children}
    </Button>
);
