import { AxiosError } from 'axios';
import { Api } from 'lib/ApiConstants';

import { ErrorStatusCode, RequestError } from './RequestError';

/**
 * Handle an error resulting from an Axios request.
 *
 * Not, this will be invoked _after_ the response interceptor has processed the response.
 *
 * @param api Api for request, used to make decisions about auth related errors
 * @param onAuthError Handler for an auth error
 * @throws RequestError
 */
// No return type because it only throws
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getDefaultRequestCatch = (api: Api, onAuthError: () => void) => (error: AxiosError) => {
    /**
     * @FIXME - Seeing bad behavior where a non-Accounts API Authorizer can get in a bad state and cause this to get wedged
     *
     * We should consider introducing the idea of a "Gatekeeper" who can be responsible for performing the check to ensure if the
     * gate actually should be closed for _all_ requests
     */
    if (error?.response?.status === ErrorStatusCode.Unauthorized && api === Api.Accounts) {
        onAuthError();

        throw new RequestError(ErrorStatusCode.Unauthorized, 'Unauthorized');
    }

    // @TODO How can we best surface these errors when they occur?

    throw new RequestError(error?.response?.status ?? 500, error?.response?.statusText ?? 'Invalid request');
};
