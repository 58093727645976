import React from 'react';

import { IconSize, defaultMuiTheme, TooltippedIconButton } from '@adept-at/lib-react-components';
import { List, ListItem, Popover, Link } from '@material-ui/core';
import { mdiImageEdit, mdiRefresh, mdiTrashCan } from '@mdi/js';
import Icon from '@mdi/react';
import { usePopover } from 'hooks/usePopover';
import styled from 'styled-components';
import { SKILL_CONTENT_WIDTH } from 'styles/variables';
import { modularScale } from 'utils/modular_scale';

const skillPadding = '1.5rem';
const skillPaddingMobile = '0.75rem';

export const BuilderCard = styled.div`
    margin-top: 2rem;
    border-radius: 4px;
    margin-bottom: 3rem;
    box-sizing: content-box;
    background-color: ${(props) => props.theme.colors.surface};
    border: 1px solid ${(props) => props.theme.colors.border};
    margin: 2rem auto 3rem;
`;

export const HeaderContainer = styled.div`
    max-width: ${SKILL_CONTENT_WIDTH}px;
    margin: 0 auto;
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
`;

export const Spacer = styled.div`
    position: relative;
`;

export const HeaderSection = styled.div`
    padding: ${skillPaddingMobile};

    ${defaultMuiTheme.breakpoints.up('sm')} {
        padding: ${skillPadding};
    }
`;

export const Section = styled.div`
    margin: 12px 12px 2rem 12px;
`;

export const SectionTitle = styled.div`
    text-transform: uppercase;
    font-size: ${modularScale(-1)};
    font-weight: bold;
    color: ${(props) => props.theme.colors.textSecondary};
    letter-spacing: 1px;

    margin-bottom: ${skillPaddingMobile};
    ${defaultMuiTheme.breakpoints.up('sm')} {
        margin-bottom: ${skillPadding};
    }
`;

export const Divider = styled.hr`
    border: 0;
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
`;

export const ComponentContainer = styled.div<{
    fullBleed?: boolean;
    controlsVisible?: boolean;
    type?: string;
    isPreview?: boolean;
    isDeleteFocus?: boolean;
}>`
    position: relative;
    max-width: ${SKILL_CONTENT_WIDTH}px;
    margin: 0 auto;
`;

export const BuilderHeading = styled.div`
    border-radius: 4px;
    background-color: ${(props) => props.theme.colors.surface};
    border: 1px solid ${(props) => props.theme.colors.border};
    margin-bottom: 3rem;
`;

export const AddComponentContainer = styled.button`
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    position: relative;
    max-width: ${SKILL_CONTENT_WIDTH}px;
    margin: 0 auto;
`;

export const CardImageBorder = styled.div`
    margin-right: 0.75rem;
    padding: 0;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.colors.surface};
`;

const MenuItems = styled(List)`
    color: ${(props) => props.theme.colors.primary};
    svg {
        width: ${IconSize.Small};
        margin-right: 1.5rem;
    }
`;

export const ButtonLink = styled(Link)`
    font-size: 16px;
    cursor: pointer;
`;

interface UploadButtonProps {
    onReplaceImage: () => void;
    onRemoveImage: () => void;
    hasExistingImage: boolean;
}

export const UploadButton: React.FC<UploadButtonProps> = ({ onReplaceImage, onRemoveImage, hasExistingImage }) => {
    const { open, anchorEl, handlePopoverClick, handlePopoverClose } = usePopover();

    return (
        <>
            <TooltippedIconButton
                title="Edit image"
                onClick={hasExistingImage ? handlePopoverClick : onReplaceImage}
                icon={<Icon path={mdiImageEdit} />}
                data-testid="image-upload-button"
                aria-label="image upload button"
            />

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                data-testid="image-upload-menu"
            >
                <MenuItems>
                    <ListItem
                        data-testid="replace-image-button"
                        button
                        onClick={() => {
                            onReplaceImage();
                            handlePopoverClose();
                        }}
                    >
                        <Icon path={mdiRefresh} /> Replace image
                    </ListItem>
                    {hasExistingImage ? (
                        <ListItem
                            data-testid="remove-image-button"
                            button
                            onClick={() => {
                                onRemoveImage();
                                handlePopoverClose();
                            }}
                        >
                            <Icon path={mdiTrashCan} /> Remove image
                        </ListItem>
                    ) : null}
                </MenuItems>
            </Popover>
        </>
    );
};
