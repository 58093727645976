import { ListItem, CircularProgress } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import styled, { css } from 'styled-components';

export const SharedStyles = css`
    height: 400px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
`;

export const SkeletonContainer = styled.div`
    ${SharedStyles};
`;

export const BookshelvesContainer = styled.div`
    ${SharedStyles};
`;

export const StyledListItem = styled(ListItem)`
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr auto;
    grid-auto-flow: column;
    padding: 1rem 1.5rem;
`;

export const StyledCircularProgress = styled(CircularProgress)`
    grid-row: span 2;
`;

export const StyledSuccessIcon = styled(Check)`
    grid-row: span 2;
    color: ${(props) => props.theme.colors.success};
`;
