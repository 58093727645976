import React, { ReactElement, useState } from 'react';

import { FavoritesProgressFilter, FavoritesSortBy } from './constants';
import { FavoritesHeader } from './FavoritesHeader';
import { FavoritesMainContent } from './FavoritesMainContent';
import { FavoritesSubheader } from './FavoriteSubheader';
import { useFavoriteFilters } from './hooks/useFavoriteFilters';

interface FavoritesProps {
    isGridView: boolean;
    setIsGridView: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Favorites = ({ isGridView, setIsGridView }: FavoritesProps): ReactElement => {
    const [progressFilter, setProgressFilter] = useState<FavoritesProgressFilter>(FavoritesProgressFilter.All);
    const [search, setSearch] = useState('');
    const [sortBy, setSortBy] = useState(FavoritesSortBy.Title);

    const { filteredFavorites, favoritesLoading } = useFavoriteFilters(progressFilter, search, sortBy);

    return (
        <>
            <FavoritesHeader />
            <FavoritesSubheader
                setIsGridView={setIsGridView}
                isGridView={isGridView}
                progressFilter={progressFilter}
                setProgressFilter={setProgressFilter}
                search={search}
                setSearch={setSearch}
                sortBy={sortBy}
                setSortBy={setSortBy}
            />
            <FavoritesMainContent
                isGridView={isGridView}
                favorites={filteredFavorites}
                favoritesLoading={favoritesLoading}
            />
        </>
    );
};
