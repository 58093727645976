import React, { ReactElement } from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import {
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    ListItemSecondaryAction,
    NativeSelect,
    FormControl,
    useMediaQuery,
    useTheme as useMuiTheme,
    Grid,
    Box,
    IconButton
} from '@material-ui/core';
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import DoneIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import LoadingIcon from '@material-ui/icons/HourglassFull';
import { mdiDelete } from '@mdi/js';
import MdiIcon from '@mdi/react';
import { CollaboratorPermissionLevel } from 'components/ContentContext/Interfaces';

import { COLLABORATOR_PERMISSION_OPTIONS } from '../permissionOptions';

import { ExistingUser } from '.';

interface ExistingUserListItemProps {
    existingUser: ExistingUser;
    onExistingPermissionChange?: ({
        userId,
        newPermission
    }: {
        userId: string;
        newPermission: CollaboratorPermissionLevel;
    }) => void;
    onInvitationalPermissionChange?: (invitationId: string, newPermission: CollaboratorPermissionLevel) => void;
    onExistingPermissionRemove?: (userId: string, specificRole: CollaboratorPermissionLevel) => void;
    onInvitationalPermissionRemove?: (invitationId: string, email: string) => void;
    isViewer: boolean;
}

const REMOVE_VALUE = 'remove';
const EXISTING_COLLABORATOR_PERMISSION_OPTIONS = [
    ...COLLABORATOR_PERMISSION_OPTIONS,
    { value: REMOVE_VALUE, label: 'Remove' }
];

const ExistingUserListItem = ({
    existingUser,
    onExistingPermissionChange,
    onInvitationalPermissionChange,
    onExistingPermissionRemove,
    onInvitationalPermissionRemove,
    isViewer
}: ExistingUserListItemProps): ReactElement => {
    const muiTheme = useMuiTheme();
    const isSmallOrLarger = useMediaQuery(muiTheme.breakpoints.up('sm'));
    const handleSelectChange = (
        event: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ) => {
        const pendingCollaboratorInviteId = existingUser.inviteId;
        if (event.currentTarget.value === REMOVE_VALUE && onExistingPermissionRemove) {
            if (pendingCollaboratorInviteId && onInvitationalPermissionRemove) {
                onInvitationalPermissionRemove(pendingCollaboratorInviteId, existingUser.name);
            } else if (existingUser.userId && onExistingPermissionRemove) {
                onExistingPermissionRemove(existingUser.userId, existingUser.permissionLevel);
            }
            return;
        }

        if (pendingCollaboratorInviteId && onInvitationalPermissionChange) {
            onInvitationalPermissionChange(
                pendingCollaboratorInviteId,
                event.currentTarget.value as CollaboratorPermissionLevel
            );
        } else if (onExistingPermissionChange && existingUser.userId) {
            onExistingPermissionChange({
                userId: existingUser.userId,
                newPermission: event.currentTarget.value as CollaboratorPermissionLevel
            });
        }
    };

    // Why is this not a render method? So MUI can pass down props.
    const selectIcon = (): React.ElementType => {
        switch (existingUser.mutationStatus) {
            case 'loading':
                return LoadingIcon;
            case 'success':
                return DoneIcon;
            case 'error':
                return ErrorIcon;
            default:
                return ArrowDownIcon;
        }
    };

    const renderAvatar = (): ReactElement | null => {
        if (isSmallOrLarger) {
            return (
                <ListItemAvatar>
                    <Avatar src={existingUser.avatarSrc} alt={existingUser.name} />
                </ListItemAvatar>
            );
        }
        return null;
    };

    return (
        <ListItem dense>
            <Grid container>
                <Grid item xs={6} sm="auto">
                    <Box display="flex">
                        {renderAvatar()}
                        <ListItemText>{existingUser.name}</ListItemText>
                    </Box>
                </Grid>
                <Grid item xs={6} sm="auto">
                    <ListItemSecondaryAction>
                        <FormControl size="small" error>
                            {isViewer ? (
                                <IconButton
                                    aria-label="delete-viewer"
                                    color="primary"
                                    onClick={() =>
                                        handleSelectChange({
                                            currentTarget: { value: REMOVE_VALUE, name: 'delete' }
                                        } as React.ChangeEvent<{
                                            name?: string | undefined;
                                            value: unknown;
                                        }>)
                                    }
                                >
                                    <MdiIcon path={mdiDelete} size={IconSize.Medium} />
                                </IconButton>
                            ) : (
                                <NativeSelect
                                    disabled={existingUser.mutationStatus === 'loading'}
                                    error={existingUser.mutationStatus === 'error'}
                                    disableUnderline={existingUser.mutationStatus !== 'error'}
                                    value={existingUser.permissionLevel}
                                    onChange={handleSelectChange}
                                    margin="dense"
                                    data-testid="collaborator-role-select"
                                    inputProps={{ 'aria-label': `Permissions for ${existingUser.name}` }}
                                    IconComponent={selectIcon()}
                                >
                                    {EXISTING_COLLABORATOR_PERMISSION_OPTIONS.map(({ value, label }) => (
                                        <option key={value} value={value}>
                                            {label}
                                        </option>
                                    ))}
                                </NativeSelect>
                            )}
                        </FormControl>
                    </ListItemSecondaryAction>
                </Grid>
            </Grid>
        </ListItem>
    );
};

export default ExistingUserListItem;
