import React, { ReactElement, useMemo } from 'react';

import { modularScale, IconSize } from '@adept-at/lib-react-components';
import { Grid, Radio, Chip } from '@material-ui/core';
import { mdiEarth, mdiAccountMultiple } from '@mdi/js';
import MuiIcon from '@mdi/react';
import { CollaboratorPermissionLevel } from 'components/ContentContext/Interfaces';
import { UserChip } from 'components/Users/UserChip';
import { UserSearchForm, useUserSearch } from 'components/UserSearchForm';
import { UserSearchOption, UserSearchOptionType } from 'components/UserSearchForm/types';
import { EMAIL_REGEX } from 'lib/constants';
import styled from 'styled-components';

import { PublicPermissions, SharingFormProps } from '../SharedComponents/AddEntityUserRoleDropIn';

// TODO in future iterations, allow adding by username/id. update this input text accordingly
export const EMAIL_USERNAME_INPUT_LABEL_TEXT = 'Add by e-mail';
export const PERMISSIONS_SELECT_LABEL_TEXT = 'Permission Level';

export const StyledChip = styled(Chip)`
    border: 1px solid ${(props) => props.theme.colors.lightGrey};
    margin: 0.1rem 0.1rem 0.5rem 0.1rem;
`;

const RadioButtonContainer = styled.div`
    display: flex;
    max-width: 100%;
    margin-right: 2rem;
`;
const StyledRadioButton = styled(Radio)`
    color: ${({ theme }) => theme.colors.textSecondary};
    &.Mui-checked {
        color: ${({ theme }) => theme.colors.primary};
    }
`;

const PublicContentDisclaimerContainer = styled.div`
    margin-top: 1rem;
`;

const ClickableDiv = styled.div`
    color: ${(props) => props.theme.colors.textSecondary};
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    > svg {
        width: ${IconSize.Medium};
    }
`;

const Title = styled.div`
    font-size: ${modularScale(0)};
    text-align: center;
    margin-left: 0.5rem;
`;

const AddViewersSharingForm = ({
    permissionLevel,
    setPermissionLevel,
    selections,
    updateSelections,
    isLoading
}: SharingFormProps): ReactElement => {
    const userSearch = useUserSearch();

    return (
        <Grid container spacing={1} alignItems="flex-start" justify="flex-end" id={'viewers-sharing-Form'}>
            <Grid item xs={12} md={12}>
                <RadioButtonContainer>
                    <ClickableDiv
                        onClick={() => {
                            if (!isLoading) setPermissionLevel(CollaboratorPermissionLevel.Viewer);
                        }}
                    >
                        <StyledRadioButton
                            disabled={isLoading}
                            checked={permissionLevel === CollaboratorPermissionLevel.Viewer}
                            name="Specific People"
                            value="Specific People"
                            inputProps={{ 'aria-label': 'select-specific-people' }}
                        />
                        <MuiIcon path={mdiAccountMultiple} />
                        <Title>Specific People</Title>
                    </ClickableDiv>
                    <ClickableDiv
                        onClick={() => {
                            if (!isLoading) setPermissionLevel(PublicPermissions.public);
                        }}
                    >
                        <StyledRadioButton
                            //wont share publicly until browse is built out
                            disabled={isLoading}
                            checked={permissionLevel === PublicPermissions.public}
                            name="Public"
                            value="Public"
                            inputProps={{ 'aria-label': 'select-share-with-public' }}
                        />
                        <MuiIcon path={mdiEarth} />
                        <Title>Public</Title>
                    </ClickableDiv>
                </RadioButtonContainer>
                {permissionLevel === PublicPermissions.public ? (
                    <PublicContentDisclaimerContainer>
                        Anyone on the internet can discover and view this content.
                    </PublicContentDisclaimerContainer>
                ) : (
                    <UserSearchForm
                        {...userSearch}
                        {...{ selections, updateSelections }}
                        optionCreateText={'Invite:'}
                        renderSelected={({ id, type }: UserSearchOption, props) => {
                            const isEmail = EMAIL_REGEX.test(id);
                            if (
                                (type === UserSearchOptionType.UserId || type === UserSearchOptionType.UserEmail) &&
                                !isEmail
                            ) {
                                return <UserChip userId={id} {...props} />;
                            } else if (isEmail) {
                                return (
                                    <StyledChip
                                        key={'email' + id}
                                        label={id}
                                        onDelete={props.onDelete}
                                        variant="outlined"
                                    />
                                );
                            }

                            return <>Unsupported Type</>;
                        }}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default AddViewersSharingForm;
