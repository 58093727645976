import * as yup from 'yup';

import { MessageEventType } from '../..';
import AckableAdepteductMessage from '../../../base/AckableAdepteductMessage';
import PossibleEventFields from '../../PossibleEventFields';

export interface EventFieldsSessionQuickPollCreated extends PossibleEventFields {
    sessionId: string;
    userId: string;
    pollId: string;
}

export default class ChannelMeetingSessionQuickPollCreated extends AckableAdepteductMessage<EventFieldsSessionQuickPollCreated> {
    schema = yup.object({
        sessionId: yup.string(),
        userId: yup.string(),
        pollId: yup.string()
    });

    type = MessageEventType.ChannelMeetingSessionQuickPollCreated;
}
