import { useMemo, useRef } from 'react';

import { ProfileOption, useProfile } from '.';

interface UseLastKnownProfileInterface {
    lastKnownProfile: ProfileOption | null;
}

/**
 * This hook exists to help us persist current (selected) profile across token refreshes or other events that causes an
 * auth token to become null.
 *
 * A couple things:
 * - We buffer that event by continuing to return the previously known profile until we determine that the session has
 * entered a terminal state.
 * - This is useful for the <ProtectedRoute/> component that boostraps display of the application. If we don't use this,
 * that component will revert to a "Loading Profile..." message whenever a token refresh occurs.
 *
 * @TODO we need to ensure that this gets reset upon logout or terminal session
 */
export const useLastKnownProfile = (): UseLastKnownProfileInterface => {
    const { currentProfile } = useProfile();

    const previousProfile = useRef<ProfileOption | null>(null);

    const lastKnownProfile = useMemo(() => {
        if (!currentProfile && previousProfile.current !== null) {
            return previousProfile.current;
        }

        previousProfile.current = currentProfile;
        return currentProfile;
    }, [currentProfile]);

    return {
        lastKnownProfile
    };
};
