import React from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import styled from 'styled-components';

import { progressToString } from '../../utils/progress';

import { ProgressIcon } from './ProgressIcon';

const Progress = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0.5rem 1rem;

    svg {
        width: ${IconSize.Medium};
        margin-right: 0.5rem;
    }

    span {
        padding-top: 2px;
    }
`;

const StyledSpan = styled.span`
    white-space: nowrap;
`;

interface SkillInput {
    state: number;
}

const SelfReportedSkillProgress: React.FC<SkillInput> = ({ state }) => {
    return (
        <Progress>
            <ProgressIcon state={state} />
            <StyledSpan>{progressToString(state)}</StyledSpan>
        </Progress>
    );
};

export default SelfReportedSkillProgress;
