import React from 'react';

import { ImageType } from './ImageTypeContext';
import { getSelectorOptionForType } from './ImageTypes';
import { Container, SelectionContainer, TypeOption } from './ImageTypeSelector.styles';

interface ImageTypeSelectorProps {
    onSelect: (type: ImageType) => void;
}

export interface SelectorOptionProps {
    onSelect: () => void;
}

const ImageTypeSelector: React.FC<ImageTypeSelectorProps> = ({ onSelect }) => (
    <Container>
        <SelectionContainer>
            {Object.entries(ImageType).map(([, selectorType], idx) => {
                const SelectorOption = getSelectorOptionForType(selectorType);

                return (
                    <TypeOption key={idx}>
                        <SelectorOption onSelect={() => onSelect(selectorType)} />
                    </TypeOption>
                );
            })}
        </SelectionContainer>
    </Container>
);

export default ImageTypeSelector;
