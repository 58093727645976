import React, { useCallback, useContext } from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { Button, ListItem } from '@material-ui/core';
import { mdiCheck, mdiMenuDown, mdiArrowDecision } from '@mdi/js';
import { Icon } from '@mdi/react';
import { WorkflowStatus } from 'components/CollaborationTable';
import { OwnerInfo } from 'components/ContentContext/Interfaces';
import { useUpdateCollectionWorkflow } from 'components/studio/useUpdateEntityWorkflow/useUpdateCollectionWorkflow';
import { usePopover } from 'hooks/usePopover';
import styled from 'styled-components';
import { StyledMenu } from 'styles/Shared.styles';

import { NestedCollectionContext } from '../context/NestedCollectionContext';

const NestedMenuWrapper = styled.div`
    display: initial;
`;

const CheckmarkWrapper = styled.div<{ $isActive?: boolean }>`
    display: flex;
    align-items: center;
    margin-right: 1rem;
    min-width: 24px;

    svg {
        display: ${(props) => (props.$isActive ? 'initial' : 'none')};
        width: ${IconSize.Medium};
    }
`;

const WorkflowButton = styled(Button)<{ $isStickyMode?: boolean }>`
    font-weight: 500;
    font-size: 0.875rem;
    border: 1px solid ${(props) => (props.$isStickyMode ? 'transparent' : props.theme.colors.secondary)};
    color: ${(props) => props.theme.colors[props.$isStickyMode ? 'primary' : 'textSecondary']};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &&& {
        svg {
            width: 1.25rem;
            height: 1.25rem;
            margin-right: 0.5rem;

            &:last-of-type {
                margin-right: 0px;
                margin-left: 0.5rem;
            }
        }
    }
`;

const DropdownArrowWrapper = styled(Icon)<{ $active?: boolean }>`
    transform: ${(props) => (props.$active ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: transform 0.1s ease-out;
`;

const StyledMenuItem = styled(ListItem)`
    padding: 12px 16px;
    color: ${(props) => props.theme.colors.primary};

    span {
        flex-grow: 1;
    }
`;

const WorkflowMenu = ({ selected, onClick, onCloseSubMenu }) => (
    <NestedMenuWrapper onMouseLeave={onCloseSubMenu}>
        {Object.entries(WorkflowStatus).map(([id, status]) => (
            <StyledMenuItem
                key={id}
                button
                onClick={() => {
                    onClick(id);
                }}
            >
                <CheckmarkWrapper $isActive={status === selected}>
                    <Icon path={mdiCheck} />
                </CheckmarkWrapper>
                {status}
            </StyledMenuItem>
        ))}
    </NestedMenuWrapper>
);

interface WorkflowActionsMenuProps {
    isStickyMode?: boolean;
    isSubMenu?: boolean;
    subAnchorEl?: HTMLElement | null;
    owner: OwnerInfo;
    handleCloseSubMenu?: () => void;
}

export const WorkflowActionsMenu: React.FC<WorkflowActionsMenuProps> = ({
    isSubMenu,
    isStickyMode,
    subAnchorEl,
    owner,
    handleCloseSubMenu = () => {}
}) => {
    const { workflow, parentQuerySlug, collectionIdForEditableCollectionQuery } = useContext(NestedCollectionContext);

    const { open, anchorEl, handlePopoverClick, handlePopoverClose } = usePopover();

    const { mutate: updateCollectionWorkflow } = useUpdateCollectionWorkflow(
        owner,
        collectionIdForEditableCollectionQuery,
        parentQuerySlug
    );

    const handleClose = useCallback(() => {
        if (isSubMenu) {
            handleCloseSubMenu();
        }
        handlePopoverClose();
    }, [isSubMenu, handlePopoverClose, handleCloseSubMenu]);

    const onCloseSubMenu = () => {
        if (isSubMenu && handleCloseSubMenu) {
            handleCloseSubMenu();
        }
    };

    const calculateAnchorPos = (): number => {
        return window.innerWidth - 590;
    };

    const onClick = useCallback(
        (workflow: keyof typeof WorkflowStatus) => {
            updateCollectionWorkflow({ collectionId: collectionIdForEditableCollectionQuery, workflow });
            handleClose();
        },
        [updateCollectionWorkflow, collectionIdForEditableCollectionQuery, handleClose]
    );

    return (
        <>
            {!isSubMenu ? (
                <WorkflowButton $isStickyMode={isStickyMode} onClick={handlePopoverClick}>
                    <Icon path={mdiArrowDecision} />
                    {workflow ?? 'Add workflow status'}
                    <DropdownArrowWrapper $active={Boolean(anchorEl)} path={mdiMenuDown} />
                </WorkflowButton>
            ) : null}
            <StyledMenu
                keepMounted
                anchorReference={isSubMenu ? 'anchorPosition' : 'anchorEl'}
                anchorEl={subAnchorEl ?? anchorEl}
                anchorPosition={{ top: 170, left: calculateAnchorPos() }}
                open={Boolean(subAnchorEl ?? open)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <WorkflowMenu selected={workflow} onClick={onClick} onCloseSubMenu={onCloseSubMenu} />
            </StyledMenu>
        </>
    );
};
