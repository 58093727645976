import React, { useContext } from 'react';

import { TooltippedIconButton, TooltippedButtonSize } from '@adept-at/lib-react-components';
import Icon from '@mdi/react';
import styled from 'styled-components';

import { HeaderContext } from './HeaderContext';

const NavButtonsContainer = styled.div`
    display: flex;
`;

export interface NavButton {
    title: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    iconPath: string;
}

export const NavButtons: React.FC = () => {
    const { navButtons } = useContext(HeaderContext);

    return navButtons.length > 0 ? (
        <NavButtonsContainer>
            {navButtons.map(({ title, onClick, iconPath }, index) => (
                <TooltippedIconButton
                    key={index}
                    aria-label={title}
                    size={TooltippedButtonSize.Medium}
                    onClick={onClick}
                    disabled={!onClick}
                    icon={<Icon path={iconPath} />}
                    title={title}
                />
            ))}
        </NavButtonsContainer>
    ) : null;
};
