import styled from 'styled-components';

const DEFAULT_IMAGE_SIZE = 70;

interface ResizableContainerProps {
    initialWidth: number;
    $isBeingEdited: boolean;
}

export const ResizableContainer = styled.div<ResizableContainerProps>`
    position: relative;
    resize: ${(props) => (props.$isBeingEdited ? 'horizontal' : 'none')};
    overflow: hidden;
    height: auto;
    width: ${(props) => props.initialWidth ?? DEFAULT_IMAGE_SIZE}px;
    max-width: 100%;

    div,
    div > img {
        height: inherit;
        width: inherit;
        max-width: 100%;
    }
`;

export const Resizer = styled.span`
    position: absolute;
    bottom: ${(DEFAULT_IMAGE_SIZE / 2) * -1}px;
    right: ${(DEFAULT_IMAGE_SIZE / 2) * -1}px;
    background-color: ${(props) => props.theme.colors.background};
    height: ${DEFAULT_IMAGE_SIZE}px;
    width: ${DEFAULT_IMAGE_SIZE}px;
    transform: rotate(45deg);

    svg {
        color: ${(props) => props.theme.colors.primary};
        width: 20px;
        transform: rotate(315deg);
        position: absolute;
        top: 25px;
        left: 4px;
    }
`;
