import React from 'react';

import { RichTextComponentValues } from '@adept-at/lib-react-components';

import { useComponentEngine } from '../../EngineContext';
import { EditableComponentProvider } from '../../mixins/editor';
import { ComponentEngineMode } from '../../types';

import TextEdit from './Edit';
import TextView from './View';

interface TextComponentProps {
    id: string;
    order: number;
}

export const Text: React.FC<TextComponentProps & RichTextComponentValues> = ({ id, order, ...componentValues }) => {
    const { mode } = useComponentEngine();

    if (mode === ComponentEngineMode.Edit) {
        return (
            <EditableComponentProvider initialValues={componentValues}>
                <TextEdit />
            </EditableComponentProvider>
        );
    }
    return <TextView {...componentValues} />;
};

export const title = 'Text';

export const Component = Text;
