import { useMemo } from 'react';

import produce from 'immer';
import { useQueryClient } from 'react-query';

import { RemoveComponentVariables } from '../../../../engine/types';
import {
    buildQueryKey as buildGetSkillByIdQueryKey,
    GetSkillByIdResponse
} from '../../../../skills/hooks/useGetSkillById';

type MutationFunction = (variables: RemoveComponentVariables) => void;

const useRemoveSkillComponentLocally = (skillId: string): MutationFunction => {
    const queryClient = useQueryClient();

    const entityQueryKey = useMemo(() => buildGetSkillByIdQueryKey(skillId), [skillId]);

    return ({ componentId }: RemoveComponentVariables) => {
        queryClient.setQueryData<GetSkillByIdResponse>(entityQueryKey, (previous) =>
            !previous
                ? ({} as GetSkillByIdResponse)
                : produce(previous, (draft) => {
                      try {
                          delete draft.getSkillById.components[componentId];
                      } catch (e) {}
                  })
        );
    };
};

export default useRemoveSkillComponentLocally;
