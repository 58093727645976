import React, { FC, memo, useContext, useState } from 'react';

import { CommentBodyContainer, CommentContainer } from './Comment.style';
import CommentBody from './CommentBody';
import { CommentContext } from './CommentContext';
import CommentMeta from './CommentMeta';
import CommentTitle from './CommentTitle';
import { Comment as CommentType } from './interfaces';

interface CommentProps {
    comment: CommentType;
}

const Comment: FC<CommentProps> = memo(({ comment }) => {
    const { showThread, updateComment } = useContext(CommentContext);
    const [isTargeted, setIsTargeted] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const onMouseEnter = () => {
        setIsTargeted(true);
    };

    const onMouseLeave = () => {
        setIsTargeted(false);
    };

    const onThreadClick = () => {
        showThread(comment.commentId);
    };

    const onBeginEdit = () => {
        setIsEditing(true);
    };

    const onUpdate = (newBody) => {
        updateComment(comment, newBody);
        setIsEditing(false);
    };

    const onCancelEdit = () => {
        setIsEditing(false);
    };

    return (
        <CommentContainer onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <CommentTitle comment={comment} isHovered={isTargeted} onBeginEdit={onBeginEdit} />
            <CommentBodyContainer>
                <CommentBody comment={comment} isEditing={isEditing} onUpdate={onUpdate} onCancelEdit={onCancelEdit} />
                <CommentMeta comment={comment} onThreadClick={onThreadClick} />
            </CommentBodyContainer>
        </CommentContainer>
    );
});

export default Comment;
