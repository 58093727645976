import React, { useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextField } from '@material-ui/core';
import Modal from 'components/modals';
import { useOrganizationTerminology } from 'hooks/useOrganizationTerminology';
import { useForm } from 'react-hook-form';
import { uppercaseFirst } from 'utils/uppercaseFirst';
import * as Yup from 'yup';

import useCreateNewCollection from './useCreateNewCollection';
import { buildGetEditableCollectionQueryKey } from './useGetEditableCollection';
import { buildGetCollectionBasicDetailsQueryKey } from './useGetNestedCollections/getCollectionBasicDetails';

interface CreateCollectionFormFields {
    title: string;
}

interface NewCollectionModalProps {
    modalOpen: boolean;
    handleModalClose: () => void;
    editableCollectionId: string;
    collectionIdForEditableCollectionQuery: string;
    parentQuerySlug: string;
}
const NewCollectionModal: React.FC<NewCollectionModalProps> = ({
    modalOpen,
    handleModalClose,
    editableCollectionId,
    parentQuerySlug,
    collectionIdForEditableCollectionQuery
}) => {
    const editableCollectionQueryKey = useMemo(
        () => buildGetEditableCollectionQueryKey(collectionIdForEditableCollectionQuery),
        [collectionIdForEditableCollectionQuery]
    );

    const editableCollectionBasicDetailsQueryKey = useMemo(
        () => buildGetCollectionBasicDetailsQueryKey(editableCollectionId),
        [editableCollectionId]
    );

    const { mutate: createNewCollection, isLoading: creatingNewCollection } = useCreateNewCollection({
        basicDetailsQueryKey: editableCollectionBasicDetailsQueryKey,
        editableCollectionQueryKey,
        parentQuerySlug
    });

    const { handleSubmit, register, errors } = useForm<CreateCollectionFormFields>({
        mode: 'onBlur',

        resolver: yupResolver(
            Yup.object().shape({
                title: Yup.string().required('New collection title required')
            })
        )
    });

    const { terminology } = useOrganizationTerminology();

    const onSubmit = ({ title }) => {
        createNewCollection({ input: { title, parentCollectionId: editableCollectionId } });
        handleModalClose();
    };

    return (
        <Modal
            open={modalOpen}
            onClose={handleModalClose}
            ConfirmButton={() => (
                <Button form="new-collection-form" variant="contained" color="primary" size="large" type="submit">
                    New {terminology.collection}
                </Button>
            )}
            modalTitle={`New ${terminology.collection}`}
            labelProps={{ 'aria-label': 'Create new collection modal' }}
        >
            <form onSubmit={handleSubmit(onSubmit)} id="new-collection-form">
                <TextField
                    fullWidth={true}
                    variant="outlined"
                    disabled={creatingNewCollection}
                    id="title"
                    type="title"
                    name="title"
                    label={`${uppercaseFirst(terminology.collection)} title`}
                    error={!!errors?.title}
                    helperText={errors.title ? 'Title required' : null}
                    inputProps={{ 'data-testid': 'New-collection-title' }}
                    inputRef={register}
                    autoComplete="off"
                />
            </form>
        </Modal>
    );
};

export default NewCollectionModal;
