import { useMemo } from 'react';

import { OptimistMutationContext, useOptimist } from '@adept-at/lib-react-optimist';
import { TEMP_BOOKSHELF_ID } from 'components/bookshelves/utils';
import { OwnerType } from 'components/ContentContext/Interfaces';
import { ClientError, gql } from 'graphql-request';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useBuildAuthenticatedMutationFn } from 'hooks/useGqlClient/useBuildAuthenticatedMutationFn';
import { Api } from 'lib/ApiConstants';
import { useMutation, UseMutationResult } from 'react-query';

import {
    GetItemIdsInBookshelvesResponse,
    buildGetItemIdsInBookshelvesQueryKey,
    BookshelfMinimal
} from './getItemIdsInBookshelves';
import { Bookshelf } from './useGetBookshelf';
import {
    GetBookshelvesResponse,
    bookshelfFragment,
    buildQueryKey as buildGetBookshelvesQueryKey
} from './useGetBookshelves';

// @GRAPHQL Mutation updateBookshelf
const queryName = 'createBookshelf';

const CREATE_BOOKSHELF_MUTATION = gql`
    mutation ${queryName}($title: String!) {
        ${queryName}(title: $title) {
            ${bookshelfFragment}
        }
    }
`;

export interface CreateBookshelfResult {
    createBookshelf: {
        title: string;
        bookshelfId: string;
        createdAt: string;
        updatedAt: string;
        totalItems: number;
    };
}

export interface CreateBookshelfVariables {
    title: string;
}

interface UseCreateBookshelfOptions {
    onSuccess?: () => void;
    onError?: () => void;
}

export const useCreateBookshelf = ({
    onSuccess = () => {},
    onError = () => {}
}: UseCreateBookshelfOptions): UseMutationResult<
    CreateBookshelfResult,
    ClientError,
    CreateBookshelfVariables,
    OptimistMutationContext
> => {
    const { currentUser } = useCurrentUser();

    const { registerUpdater, mutationOptions } = useOptimist<
        CreateBookshelfResult,
        ClientError,
        CreateBookshelfVariables
    >();

    const getBookshelvesQueryKey = buildGetBookshelvesQueryKey();

    registerUpdater<GetBookshelvesResponse>(getBookshelvesQueryKey, (previous, { title }) => {
        const previousBookshelves = previous?.getBookshelves ?? [];

        const newBookshelf: Bookshelf = {
            bookshelfId: TEMP_BOOKSHELF_ID,
            title: title,
            owner: {
                type: OwnerType.User,
                id: currentUser?.userId ?? ''
            },
            items: [],
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            totalItems: 0
        };

        const updatedBookshelves: Bookshelf[] = [...previousBookshelves, newBookshelf];

        return {
            getBookshelves: updatedBookshelves
        };
    });

    const getItemIdsInBookshelvesQueryKey = useMemo(
        () => buildGetItemIdsInBookshelvesQueryKey(currentUser?.userId),
        [currentUser?.userId]
    );

    registerUpdater<GetItemIdsInBookshelvesResponse>(getItemIdsInBookshelvesQueryKey, (previous, { title }) => {
        const previousBookshelves = previous?.getBookshelves ?? [];

        const newBookshelf: BookshelfMinimal = {
            bookshelfId: TEMP_BOOKSHELF_ID,
            createdAt: '',
            title: title,
            items: []
        };

        const updatedBookshelves: BookshelfMinimal[] = [newBookshelf, ...previousBookshelves];

        return {
            getBookshelves: updatedBookshelves
        };
    });

    const { mutationFn } = useBuildAuthenticatedMutationFn<CreateBookshelfResult, CreateBookshelfVariables>(
        CREATE_BOOKSHELF_MUTATION,
        { api: Api.Content }
    );

    return useMutation<CreateBookshelfResult, ClientError, CreateBookshelfVariables, OptimistMutationContext>(
        mutationFn,
        { ...mutationOptions, onSuccess, onError }
    );
};
