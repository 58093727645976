import { useEffect, useState } from 'react';

import { EntityType } from 'components/ContentContext/Enums';
import { ClientError } from 'graphql-request';
import gql from 'graphql-tag';
import { useBuildAuthenticatedQueryFn } from 'hooks/useGqlClient/useBuildAuthenticatedQueryFn';
import { Api } from 'lib/ApiConstants';
import { useQuery, UseQueryResult } from 'react-query';

/* eslint-disable import/namespace */
import { ValidEntityAction, CONTENT_ENTITIES } from './types';

/* Individual methods to see if a singular entity action is authorized (i.e Read Skill). */
const IS_AUTHORIZED_ENTITY_ACTION_QUERY = gql`
    query getIsAuthorizedEntityAction($entity: EntityInfoInput!, $action: String!) {
        getIsAuthorizedEntityAction(entity: $entity, action: $action)
    }
`;

/* GraphQL Request */
export interface IsAuthorizedEntityActionRequest {
    entity: EntityInfoInput;
    action: ValidEntityAction;
}

// Need id to possibly be null (Query disabled) for easier front end usage.
export interface EntityInfoInput {
    type: EntityType;
    id: string | null;
}

/* GraphQL Response */
export interface IsAuthorizedEntityActionResponse {
    getIsAuthorizedEntityAction: boolean;
}

/* Hook interface */
export interface IsAuthorizedEntityAction {
    isAuthorized: boolean;
    refetch: () => any;
}

/* GraphQL Request to get the desired response. */
const useGetIsAuthorizedEntityAction = (
    variables: IsAuthorizedEntityActionRequest,
    options = {}
): UseQueryResult<IsAuthorizedEntityActionResponse, ClientError> => {
    const { queryFn } = useBuildAuthenticatedQueryFn<IsAuthorizedEntityActionResponse, IsAuthorizedEntityActionRequest>(
        IS_AUTHORIZED_ENTITY_ACTION_QUERY,
        variables,
        options
    );

    const queryKey = `useIsAuthorizedEntityAction-${variables.entity.id}-${variables.entity}-${variables.action}`;

    return useQuery<IsAuthorizedEntityActionResponse, ClientError>(queryKey, queryFn, options);
};

/**
 * Convenience hook used for internal consumption.
 * Determine if the User is authorized to perform Action on Entity.
 */
export const useIsAuthorizedEntityAction = (
    entity: EntityInfoInput,
    action: ValidEntityAction
): IsAuthorizedEntityAction => {
    const [isAuthorized, setIsAuthorized] = useState(false);
    const targetApi = CONTENT_ENTITIES.includes(entity.type) ? Api.Content : Api.Accounts;
    const enabled = entity.id === null ? false : true;

    /* Return a list of the appropriate actions. */
    const { data, status, refetch } = useGetIsAuthorizedEntityAction({ entity, action }, { api: targetApi, enabled });

    useEffect(() => {
        if (status === 'success' && data?.getIsAuthorizedEntityAction) {
            setIsAuthorized(data.getIsAuthorizedEntityAction);
        }
    }, [data, status]);

    return { isAuthorized, refetch };
};
