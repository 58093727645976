import React from 'react';

import AdepteductMessageProps from 'components/communication/message/AdepteductMessageProps';
import ChannelTemplateChatMessage from 'components/communication/message/chat/ChannelTemplateMessage';
import DefaultAdepteductMessage from 'components/communication/message/chat/DefaultAdepteductMessage';
import * as yup from 'yup';

import { MessageEventType } from '..';
import AckableAdepteductMessage from '../../base/AckableAdepteductMessage';
import { MessageDestination } from '../../MessageRecord';
import PossibleEventFields from '../PossibleEventFields';

interface EventFieldsPostMessage extends PossibleEventFields {
    user?: string;
    template: string;
    variables: any;
}

export default class ChannelCreate extends AckableAdepteductMessage<EventFieldsPostMessage> {
    schema = yup.object({
        user: yup.string().required(),
        template: yup.string().required(),
        variables: yup.object()
    });

    type = MessageEventType.ChannelCreate;

    mapDestinationToComponent = (
        destination?: MessageDestination
    ): React.ForwardRefExoticComponent<
        AdepteductMessageProps<PossibleEventFields> & React.RefAttributes<HTMLDivElement | null>
    > => {
        if (destination === MessageDestination.Chat) {
            return ChannelTemplateChatMessage;
        }

        return DefaultAdepteductMessage;
    };
}
