import { useContext, useState } from 'react';

import { RichTextEditorContext } from 'components/engine/common/RichTextEditor/Context';
import { DraftJsEntity, HANDLED, NOT_HANDLED } from 'components/engine/common/RichTextEditor/utils';
import { ImageDestination } from 'components/engine/InlineUploadImage';
import { DraftModel } from 'draft-js';
import { useSnackbar } from 'notistack';

import { useFileUpload, AssetType } from '../PDF/FileUpload';

interface UsePastedImageInterface {
    handlePastedFiles: (files: Blob[]) => DraftModel.Constants.DraftHandleValue;
    uploadProgress: number | null;
    isLoading: boolean;
}

export const usePastedImage = (): UsePastedImageInterface => {
    const { enqueueSnackbar } = useSnackbar();
    const { addEntity } = useContext(RichTextEditorContext);
    const [pastedImage, setPastedImage] = useState<string>();
    const [imageDimensions, setImageDimensions] = useState<Record<string, number>>({});
    const [isLoading, setIsLoading] = useState(false);

    const reset = () => {
        setIsLoading(false);
        setPastedImage(undefined);
        setImageDimensions({});
    };

    const { setSelectedFile, createUploadIntentAndUploadFile, uploadProgress } = useFileUpload({
        onSuccess: (assetId) => {
            reset();
            addEntity(DraftJsEntity.IMAGE, { image: { assetId, url: pastedImage, dimensions: imageDimensions } });
        },
        onError: (_type, message) => {
            reset();
            enqueueSnackbar(message, { variant: 'error' });
        },
        assetType: AssetType.Image,
        attributes: { tags: [AssetType.Image, ImageDestination.SkillComponent] }
    });

    const handlePastedFiles = (files: Blob[]) => {
        const file = files[0];

        if (['image/png', 'image/jpg', 'image/jpeg'].includes(file.type)) {
            setIsLoading(true);
            const reader = new FileReader();
            reader.onload = () => {
                setPastedImage(reader.result as string);

                const image = new Image();
                image.src = reader.result as string;
                image.onload = () => {
                    setImageDimensions({ width: image.naturalWidth, height: image.naturalHeight });
                };
            };
            reader.readAsDataURL(file);

            setSelectedFile(file as File);
            createUploadIntentAndUploadFile();

            return HANDLED;
        }

        enqueueSnackbar('Invalid file provided for upload. Accepted files include PNG, JPG, and JPEG', {
            variant: 'error'
        });

        return NOT_HANDLED;
    };

    return {
        handlePastedFiles,
        uploadProgress,
        isLoading
    };
};
