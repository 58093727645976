import React, { useMemo, useContext } from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { CircularProgress, Tooltip } from '@material-ui/core';
import { mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import { ProfileContext } from 'context/ProfileContext';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { NavTabItem, NavTabWrapper } from './Nav.styles';
import { NavContext, Tab } from './NavContext';
import { OrgSelectorDisplay } from './OrgSelectorDisplay';

interface Props {
    canCreateOrg: boolean;
}

const Loading = styled(CircularProgress)`
    color: ${(props) => props.theme.colors.white};
    margin: 1rem 0;
    opacity: 0.75;
`;

const OrgSelector: React.FC<Props> = ({ canCreateOrg }) => {
    const { status, currentProfile } = useContext(ProfileContext);
    const { activeTab } = useContext(NavContext);
    const history = useHistory();

    const onNewOrg = () => {
        history.push('/organization/create');
    };

    const orgIsSelected = useMemo(() => activeTab === Tab.Organization, [activeTab]);

    if (status === 'loading' || !currentProfile) {
        return <Loading size={IconSize.Medium} />;
    }

    return (
        <>
            <OrgSelectorDisplay
                onSelect={({ alias }) => {
                    history.push(`/${alias}/catalog`);
                }}
                selected={orgIsSelected ? currentProfile : null}
            />

            {canCreateOrg ? (
                <Tooltip title={'Create an organization'} placement="right">
                    <NavTabWrapper onClick={onNewOrg}>
                        <NavTabItem>
                            <Icon path={mdiPlus} />
                        </NavTabItem>
                    </NavTabWrapper>
                </Tooltip>
            ) : null}
        </>
    );
};

export default OrgSelector;
