import React, { createContext, useMemo, useContext } from 'react';

import { ClientError } from 'graphql-request';

import { GroupType } from '../OrganizationContext';
import { ProfileContext } from '../ProfileContext';

import { useGetOrganizationGroupsForUser, OrganizationGroupForUser } from './useGetOrganizationGroupsForUser';
import { useGetUsersForOrganization, UserForOrganization } from './useGetUsersForOrganization';

export interface Tenant {
    id: string;
    name: string;
}

export interface OrganizationDetails {
    organizationId: string;
    alias: string;
    tenants: Tenant[];
}

export interface AccountContextInterface {
    isOrganizationOwner: boolean;
    groups: OrganizationGroupForUser[];
    loading: boolean;
    error?: ClientError | null | unknown;
    users: UserForOrganization[];
    loadingUsers: boolean;
}

const AccountContext = createContext(undefined as unknown as AccountContextInterface);

const { Provider, Consumer } = AccountContext;

const AccountProvider: React.FC = ({ children }) => {
    const { currentProfile } = useContext(ProfileContext);

    const {
        isLoading: loading,
        error,
        data: organizationGroupsForUserData
    } = useGetOrganizationGroupsForUser({
        organizationId: currentProfile?.id ?? ''
    });

    const result = useGetUsersForOrganization({
        organizationId: currentProfile?.id ?? ''
    });

    const { isLoading: loadingUsers, data: usersForOrg } = result;

    const groups = useMemo(() => {
        if (!organizationGroupsForUserData) {
            return [];
        }

        const { getOrganizationGroupsForUser: groupsForOrganization } = organizationGroupsForUserData;

        return groupsForOrganization;
    }, [organizationGroupsForUserData]);

    const isOrganizationOwner = useMemo(() => {
        return groups?.some((group) => group.type === GroupType.OWNER);
    }, [groups]);

    const users = useMemo(() => {
        if (!usersForOrg) {
            return [];
        }

        const { getUsersForOrganization: orgUsers } = usersForOrg;
        return orgUsers.members;
    }, [usersForOrg]);

    return (
        <Provider
            value={{
                groups,
                loading,
                error,
                users,
                loadingUsers,
                isOrganizationOwner
            }}
        >
            {children}
        </Provider>
    );
};

export { AccountContext, AccountProvider, Consumer as AccountConsumer };
