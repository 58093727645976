import { GraphQLClient } from 'graphql-request';
import gql from 'graphql-tag';

export interface GetUserInfoByUserIdsResponse {
    getUserInfoByUserIds: UserInfo[];
}

export interface UserInfo {
    id: string;
    name: {
        first: string;
        last: string;
    };
    image: string | null;
    alias: string;
}

const GET_USER_INFO_BY_USER_IDS_QUERY = gql`
    query getUserInfoByUserIds($userIds: [UUIDv4]) {
        getUserInfoByUserIds(userIds: $userIds) {
            id
            name {
                first
                last
            }
            image
            alias
        }
    }
`;

const getUserInfoByUserIds = async (
    client: GraphQLClient,
    userIds: string[]
): Promise<GetUserInfoByUserIdsResponse> => {
    return await client.request<GetUserInfoByUserIdsResponse, { userIds: string[] }>(GET_USER_INFO_BY_USER_IDS_QUERY, {
        userIds
    });
};

export default getUserInfoByUserIds;
