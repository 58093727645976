import React, { createContext, useMemo, useEffect, useContext, useCallback } from 'react';

import jwtDecode from 'jwt-decode';

import { useSession, SessionStatus } from './hooks/useSession';

export enum AuthStatus {
    Initial = 'Initial',
    Authenticating = 'Authenticating',
    Reauthenticating = 'Reauthenticating',
    Authenticated = 'Authenticated',
    Unauthenticated = 'Unauthenticated',
    Error = 'Error'
}

export interface AuthContextInterface {
    token: string | null;
    tokenDetails: {
        tokenProvider: string;
        userId: string;
    } | null;
    initializeSession: () => void;
    refreshSession: () => void;

    detectOAuth: (clearHash?: boolean) => boolean;
    redirectToOAuth: () => void;

    status: AuthStatus;
    isAuthenticating: boolean;
    isAuthenticated: boolean;
    reset: () => void;
}

const AuthContext = createContext({} as AuthContextInterface);

const { Provider, Consumer } = AuthContext;

const AuthProvider: React.FC = ({ children }) => {
    const {
        initialize: initializeSession,
        token,
        status: sessionStatus,
        reset: resetSession,
        refresh: refreshSession
    } = useSession();

    const tokenDetails = useMemo(() => {
        if (!token) {
            return null;
        }

        const { sub } = jwtDecode<{ sub: string }>(token);

        const [tokenProvider, userId] = sub.split('|');

        return {
            tokenProvider,
            userId
        };
    }, [token]);

    /**
     * Coalescence of session status, etc into a derived, publicly facing auth status value.
     */
    const status: AuthStatus = useMemo(() => {
        /**
         * Session reaching a None state is a terminal state that forces us to believe we will require authentication to
         * recover from.
         */
        if (sessionStatus === SessionStatus.None) {
            return AuthStatus.Unauthenticated;
        }

        // @TODO SAS do we really want to interpret errors as an Error? How about LoggedOut?
        if (sessionStatus === SessionStatus.Error) {
            return AuthStatus.Error;
        }

        if (sessionStatus === SessionStatus.Refreshing) {
            return AuthStatus.Reauthenticating;
        }

        // Session exists and is being setup so we can retrieve the user's profile
        if (sessionStatus === SessionStatus.Initializing || sessionStatus === SessionStatus.Restoring) {
            return AuthStatus.Authenticating;
        }

        if (sessionStatus === SessionStatus.Ready) {
            return AuthStatus.Authenticated;
        }

        return AuthStatus.Initial;
    }, [sessionStatus]);

    useEffect(() => {
        if (status === AuthStatus.Error) {
            resetSession();
        }
    }, [status, resetSession]);

    const isAuthenticated = useMemo(
        () => [AuthStatus.Authenticated, AuthStatus.Reauthenticating].includes(status),
        [status]
    );

    const isAuthenticating = useMemo(() => status === AuthStatus.Authenticating, [status]);

    const reset = useCallback(() => {
        resetSession();
    }, [resetSession]);

    return (
        <Provider
            value={{
                token,
                tokenDetails,
                initializeSession,
                refreshSession,
                detectOAuth: () => false,
                redirectToOAuth: () => {},
                status,
                isAuthenticated,
                isAuthenticating,
                reset
            }}
        >
            {children}
        </Provider>
    );
};

export const useAuth = (): AuthContextInterface => {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error('useAuth must be used within a AuthProvider');
    }

    return context;
};

export { AuthContext, AuthProvider, Consumer as AuthConsumer };
