import React, { useRef } from 'react';

import { ComponentEngineComponent } from '../../engine';
import { EngineComponent } from '../../engine/types';
import ComponentContainer from '../ComponentContainer';

const Component: React.FC<EngineComponent | null> = ({ id, type, order, ...values }) => {
    const ref = useRef<HTMLDivElement>(null);

    return (
        <>
            {/* {type === 'sectionTitle' ? <Spacer /> : null} */}

            <ComponentContainer component={{ id, type }}>
                <>
                    <ComponentEngineComponent container={ref} id={id} type={type} order={order} values={values} />
                </>
            </ComponentContainer>
        </>
    );
};

export default Component;
