import React, { useEffect, useState } from 'react';

import AppLoading from 'components/AppLoading';
import { EntityType } from 'components/ContentContext/Enums';
import { useCreateLinkLaunchUrl } from 'components/engine/Components/Link/hooks/useCreateLinkLaunchUrl';
import { Container } from 'components/RootProfileCollection/Collections.styles';
import AppLayout from 'layouts/AppLayout';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

const LoaderContainer = styled.div`
    display: flex;
    align-self: center;
    width: 100%;
`;
const StyledVirtualLab = styled.div`
    position: relative;
    height: 100vh;
    width: 100vw;
    iframe {
        border: 0;
        height: calc(100% - 4rem);
        width: 100%;
    }

    main {
        height: 100%;
    }

    .navbar-item-vertical {
        flex-direction: column;
    }
`;
const Loader = () => (
    <Container>
        <LoaderContainer>
            <AppLoading text={'Loading your lab instance'} />
        </LoaderContainer>
    </Container>
);

interface LabLinkPageParams {
    externalId: string;
    entityType: string;
    entityId: string;
}

export const LabLink: React.FC<RouteComponentProps<LabLinkPageParams>> = ({ match }) => {
    const [launchUrl, setLaunchUrl] = useState('');

    const { mutate } = useCreateLinkLaunchUrl();

    useEffect(() => {
        if (!match.params) return;
        const { entityId, entityType, externalId } = match.params;
        mutate(
            {
                entity: { id: entityId, type: entityType as EntityType },
                externalId
            },
            {
                onSuccess: (data) => {
                    setLaunchUrl(data.launchLink.linkUrl);
                }
            }
        );
    }, [match.params, mutate]);

    if (!launchUrl) return <Loader />;

    return (
        <AppLayout withoutPadding pageTitle="Lab Link Viewer" hideNav>
            <StyledVirtualLab>
                <iframe src={launchUrl} aria-label="virtual lab" />
            </StyledVirtualLab>
        </AppLayout>
    );
};
