import DOMPurify from 'dompurify';
import marked, { Renderer } from 'marked';

const ONE_OR_MORE_NEWLINES_REGEX = new RegExp(/[␤␍␊↵⏎\n]{1,}/g);

const renderer = {
    paragraph(text) {
        const splitByNewline = text.split(ONE_OR_MORE_NEWLINES_REGEX);

        return `<p>${splitByNewline.join('<br/>')}</p>\n`;
    }
} as Renderer;

marked.use({ renderer });

export const renderFormattedContent = (content: string, edited?: boolean): string => {
    if (edited) {
        content += '<span id="edited-text">(edited)</span>';
    }

    const sanitizedContent = DOMPurify.sanitize(content);

    const rawMarkup = marked(sanitizedContent);

    const finalFormattedHtmlContent = rawMarkup;

    return finalFormattedHtmlContent;
};
