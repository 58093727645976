import React, { useContext } from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { mdiInformationOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { TabContent } from 'components/Sidebar/index.styles';
import styled from 'styled-components';

import { modularScale } from '../../../utils/modular_scale';

import { GuidanceContext, GuidanceType } from './GuidanceContext';
import {
    Code,
    Conclusion,
    How,
    Image,
    Overview,
    Attachment,
    QuizQuestion,
    SectionTitle,
    Skill,
    Text,
    Title as TitleGuidance,
    Video,
    Who,
    Why
} from './Guidances';

const Heading = styled.div`
    color: ${(props) => props.theme.colors.textSecondary};
    margin-bottom: 1rem;
`;

const Title = styled.div`
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: ${modularScale(2)};

    svg {
        width: ${IconSize.Medium};
        margin-right: 1rem;
    }
`;

const Body = styled.div``;

const Content = styled.div``;

const Guidance: React.FC = () => {
    const { activeType } = useContext(GuidanceContext);

    const renderBody = () => {
        switch (activeType) {
            case GuidanceType.Skill:
                return <Skill />;
            case GuidanceType.Text:
                return <Text />;
            case GuidanceType.SectionTitle:
                return <SectionTitle />;
            case GuidanceType.Overview:
                return <Overview />;
            case GuidanceType.Conclusion:
                return <Conclusion />;
            case GuidanceType.QuizQuestion:
                return <QuizQuestion />;
            case GuidanceType.Title:
                return <TitleGuidance />;
            case GuidanceType.Description:
                return <Overview />;
            case GuidanceType.Image:
                return <Image />;
            case GuidanceType.How:
                return <How />;
            case GuidanceType.Why:
                return <Why />;
            case GuidanceType.Who:
                return <Who />;
            case GuidanceType.Code:
                return <Code />;
            case GuidanceType.Attachment:
                return <Attachment />;
            case GuidanceType.Video:
                return <Video />;
            default:
                return <Skill />;
        }
    };

    return (
        <TabContent>
            <Content>
                <Heading>
                    <Title>
                        <Icon path={mdiInformationOutline} /> {activeType}
                    </Title>
                </Heading>
                <Body>{renderBody()}</Body>
            </Content>
        </TabContent>
    );
};

export default Guidance;
