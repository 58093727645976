import { IconSize } from '@adept-at/lib-react-components';
import { Button } from '@material-ui/core';
import styled from 'styled-components';
import { SUB_HEADER_HEIGHT } from 'styles/variables';

export const Header = styled.div`
    display: flex;
    align-items: center;
    padding: 0 1rem;
    width: 100%;
    background-color: ${(props) => props.theme.colors.surface};
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
    height: ${SUB_HEADER_HEIGHT}px;
`;

export const Divider = styled.div`
    width: 2px;
    height: 23px;
    background: ${(props) => props.theme.colors.border};
    opacity: 0.7;
    margin: 0 0.75rem;
`;

export const StyledButton = styled(Button)`
    svg {
        width: ${IconSize.Small};
        margin-right: 6px;
    }
`;

export const Controls = styled.div`
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-left: auto;
`;
