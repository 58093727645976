import React, { useState } from 'react';

import { CourseIcon, IconSize } from '@adept-at/lib-react-components';
import { Button, ButtonGroup, Chip } from '@material-ui/core';
import { mdiEyeOff, mdiEye } from '@mdi/js';
import Icon from '@mdi/react';
import { Modal } from 'components/modals';
import { CollectionProductDetails } from 'components/studio/collection/getCollection';
import styled from 'styled-components';
import { isTrue } from 'utils/isTrue';

import { EntityChild } from '../context/getNestedCollectionBySlug';

import { EnrollmentSection, INITIAL_PRICE } from './EnrollmentSection';

const ChipWrapper = styled.div`
    margin: -1rem 0 1.5rem 0;
`;

export interface ConfirmAvailabilityArgs {
    enrollment: boolean;
    price: number;
    visible: boolean;
}

export interface EnrollmentCollectionDetails {
    collectionId: string;
    productDetails: CollectionProductDetails;
    children: EntityChild[];
    itemsVersion: number;
}

interface AvailabilityModalProps {
    contentTitle: string;
    isVisible: boolean;
    open: boolean;
    disabled?: boolean;
    onClose: () => void;
    onConfirm: (args: ConfirmAvailabilityArgs) => void;
    isInternal: boolean;
    collectionDetails: EnrollmentCollectionDetails | null;
    supportingDetailsLoading?: boolean;
}

export const AvailabilityModal: React.FC<AvailabilityModalProps> = ({
    contentTitle,
    isVisible,
    open,
    onClose,
    onConfirm,
    isInternal,
    collectionDetails,
    supportingDetailsLoading = false
}) => {
    const [visible, setVisible] = useState<boolean>(isVisible);
    const [enrollment, setEnrollment] = useState<boolean>(isTrue(collectionDetails?.productDetails?.productId));
    const [price, setPrice] = useState<number>(INITIAL_PRICE);

    return (
        <Modal
            modalTitle="Availability"
            open={open}
            onClick={(e) => e.stopPropagation()}
            onClose={onClose}
            styleProps={{ 'max-width': '450px' }}
            confirmButtonText="Save"
            onConfirm={() => {
                onConfirm({ enrollment, price, visible });
            }}
        >
            <>
                <ChipWrapper>
                    <Chip
                        size="small"
                        icon={<CourseIcon aria-label="Collection" />}
                        label={contentTitle}
                        variant="outlined"
                    />
                </ChipWrapper>
                <ButtonGroup fullWidth color="primary">
                    <Button
                        disableElevation
                        onClick={() => setVisible(false)}
                        variant={visible ? 'outlined' : 'contained'}
                        startIcon={<Icon path={mdiEyeOff} size={IconSize.Small} />}
                    >
                        Hidden
                    </Button>
                    <Button
                        disableElevation
                        onClick={() => setVisible(true)}
                        variant={visible ? 'contained' : 'outlined'}
                        startIcon={<Icon path={mdiEye} size={IconSize.Small} />}
                    >
                        Visible
                    </Button>
                </ButtonGroup>
                {!isInternal && collectionDetails ? (
                    <EnrollmentSection
                        price={price}
                        enrollment={enrollment}
                        setEnrollment={setEnrollment}
                        setPrice={setPrice}
                        collectionDetails={collectionDetails}
                        supportingDetailsLoading={supportingDetailsLoading}
                    />
                ) : null}
            </>
        </Modal>
    );
};
