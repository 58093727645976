import React, { useContext, useMemo } from 'react';

import { QuestionComponentValues } from '@adept-at/lib-react-components';
import { EngineContext } from 'components/engine/EngineContext';
import { buildInitialQuestionValue } from 'components/learn/components/QuestionComponent/helpers';
import { LearnContext } from 'components/learn/LearnContext';
import { useCurrentUser } from 'hooks/useCurrentUser';

import QuestionForm from './QuestionForm';

export interface QuestionConsumeProps {
    component: QuestionComponentValues;
}

const QuestionConsume: React.FC<QuestionConsumeProps> = ({ component }) => {
    const {
        container: { id, type }
    } = useContext(EngineContext);
    const { currentUser } = useCurrentUser();
    const learnContext = useContext(LearnContext);

    const questionCompleted = useMemo(() => {
        const completedQuestions = learnContext?.skill?.progress?.completedQuestions ?? [];
        return !!(component.questionId && completedQuestions.find((questionId) => questionId === component.questionId));
    }, [component.questionId, learnContext?.skill?.progress]);

    const initialValue = useMemo(() => {
        if (currentUser) {
            return buildInitialQuestionValue({ componentValues: component, currentUser, questionCompleted });
        }
    }, [currentUser, component, questionCompleted]);

    if (currentUser) {
        return (
            <QuestionForm
                component={component}
                questionCompleted={questionCompleted}
                initialValue={initialValue}
                containerId={id}
                containerType={type}
            />
        );
    }

    return null;
};

export default QuestionConsume;
