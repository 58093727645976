import { Alert } from '@material-ui/lab';
import styled from 'styled-components';

export const SearchContainer = styled.div`
    width: 100%;
`;

export const FlexContainer = styled.div`
    display: flex;
`;

export const ImageContainer = styled.div`
    padding: 5px;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;

    &:hover img {
        filter: brightness(50%);
    }

    img {
        width: 100%;
    }

    &:hover a {
        visibility: visible;
    }

    a {
        visibility: hidden;
        position: absolute;
        bottom: 12px;
        left: 10px;
        color: rgba(255, 255, 255, 0.85);

        &:hover {
            color: rgba(255, 255, 255, 0.97);
        }
    }
`;

export const ImageColumn = styled.div`
    flex: 1;
`;

export const NoResults = styled.p`
    margin-top: 10px;
    text-align: center;
`;

export const StyledAlert = styled(Alert)`
    margin-bottom: 1rem;
`;
