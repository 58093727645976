import React from 'react';

import { VideoComponentValues } from '@adept-at/lib-react-components';
import { mdiClosedCaption, mdiVideoImage } from '@mdi/js';
import Icon from '@mdi/react';
import { useEncodeStatus } from 'components/engine/Components/Video/hooks/useEncodeStatus';
import { EncodeStatus } from 'components/engine/Components/Video/types';
import { useComponentEngine } from 'components/engine/EngineContext';
import { useEditableComponent } from 'components/engine/mixins/editor/index';

import { Container, Info, VideoFileInfo, VideoEncodingInfo, Controls, ControlButton } from './Details.styles';
import { EncodeStatusDisplay } from './EncodeStatusDisplay';
import { useUpdatePosterImage } from './hooks/useUpdatePosterImage';

interface VideoDetailsProps {
    videoRef: React.RefObject<HTMLVideoElement>;
}

export const EditorDetails: React.FC<VideoDetailsProps> = ({ videoRef }) => {
    const {
        container: { id: containerId }
    } = useComponentEngine();

    const { updatePosterImage } = useUpdatePosterImage({ containerId });

    const {
        currentValues: { uploadedFileName, videoId = null }
    } = useEditableComponent<VideoComponentValues>();

    const { encodeStatus } = useEncodeStatus({ videoId });

    return (
        <Container>
            <Info>
                <VideoFileInfo data-testid="uploaded-file-name">
                    {!!uploadedFileName ? `Currently uploaded video: ${uploadedFileName}` : null}
                </VideoFileInfo>
                <VideoEncodingInfo data-testid="encoding-status">
                    <EncodeStatusDisplay status={encodeStatus} />
                </VideoEncodingInfo>
            </Info>
            <Controls>
                <a href={`/skillbuilder/edit/captions/${videoId}`} target="_blank" rel="noreferrer">
                    <ControlButton
                        color="primary"
                        disabled={encodeStatus !== EncodeStatus.Complete}
                        data-testid="edit-captions-button"
                    >
                        <Icon path={mdiClosedCaption} /> Edit captions
                    </ControlButton>
                </a>
                <ControlButton
                    color="primary"
                    disabled={encodeStatus !== EncodeStatus.Complete || !videoRef.current}
                    onClick={() => {
                        if (videoRef.current?.currentTime) {
                            updatePosterImage(videoRef.current.currentTime);
                        }
                    }}
                    data-testid="set-poster-image-button"
                >
                    <Icon path={mdiVideoImage} /> Set current frame as poster image
                </ControlButton>
            </Controls>
        </Container>
    );
};
