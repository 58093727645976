import 'styles.css';
import React, { ReactElement } from 'react';

import { OptimistProvider } from '@adept-at/lib-react-optimist';
import CssBaseline from '@material-ui/core/CssBaseline';
// import AsanaFeedbackBtn from 'components/AsanaFeedbackButton'; // leave here in case we decide to add btn back for new set of users to provide feedback.
import { AuthExplorer } from 'components/AuthExplorer';
import { DevTools, useDevTools } from 'components/devtools';
import { NavProvider } from 'components/navigation/NavContext';
import { ThemeWrapper } from 'components/ThemeWrapper';
import VersionSharer from 'components/VersionSharer';
import { ThemeContextProvider } from 'context/ThemeContext';
import { getRetryCallback } from 'hooks/request/retry';
import { SnackbarProvider } from 'notistack';
import { QueryClientProvider, QueryClient } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import GlobalContextProviders from './context/GlobalContextProviders';
import GlobalHooks from './hooks/GlobalHooks';
import { Routes } from './pages/index.router';

import 'video.js/dist/video-js.css';

import './utils/debugLog';

const reactQueryClient = new QueryClient({
    defaultOptions: {
        queries: { refetchOnWindowFocus: false, retry: getRetryCallback() },
        mutations: { retry: getRetryCallback() }
    }
});

const App = (): ReactElement => {
    const { enabled: devToolsEnabled } = useDevTools();

    return (
        <ThemeContextProvider>
            <ThemeWrapper>
                <CssBaseline />
                <DevTools>
                    {/* <AsanaFeedbackBtn /> */}
                    <QueryClientProvider client={reactQueryClient}>
                        <OptimistProvider>
                            <BrowserRouter>
                                <GlobalContextProviders>
                                    {devToolsEnabled ? <AuthExplorer /> : null}
                                    <NavProvider>
                                        <SnackbarProvider
                                            maxSnack={3}
                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                        >
                                            <GlobalHooks>
                                                <Routes />
                                            </GlobalHooks>
                                            <VersionSharer />
                                        </SnackbarProvider>
                                    </NavProvider>
                                </GlobalContextProviders>
                            </BrowserRouter>
                        </OptimistProvider>
                    </QueryClientProvider>
                </DevTools>
            </ThemeWrapper>
        </ThemeContextProvider>
    );
};

export default App;
