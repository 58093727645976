import { IconSize } from '@adept-at/lib-react-components';
import styled from 'styled-components';

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    width: 100%;

    svg {
        width: ${IconSize.Small};
        margin-right: 0.5rem;
    }

    button + button {
        margin-left: auto;

        svg {
            margin-left: 0.5rem;
            margin-right: 0;
        }
    }
`;

export const StepContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;
