import { useMemo } from 'react';

import { OptimistMutationContext, OptimistRegisterUpdaterOptions, useOptimist } from '@adept-at/lib-react-optimist';
import { ClientError } from 'graphql-request';
import { useBuildAuthenticatedMutationFn } from 'hooks/useGqlClient/useBuildAuthenticatedMutationFn';
import { Api } from 'lib/ApiConstants';
import { useSnackbar } from 'notistack';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { setCollectionEditedInSessionStorage } from 'utils/collection';

import { Image } from '../../ContentContext/Interfaces';
import { buildGetEditableCollectionQueryKey, GetEditableCollectionResponse } from '../useGetEditableCollection';

import {
    UpdateCollectionMetaInput,
    UpdateCollectionMetaResult,
    UPDATE_COLLECTION_META
} from './makeUpdateCollectionMeta';

interface UseUpdateCollectionMetaInput {
    collectionId: string;
    parentQuerySlug: string;
}

export const useUpdateCollectionMeta = ({
    collectionId,
    parentQuerySlug
}: UseUpdateCollectionMetaInput): UseMutationResult<
    UpdateCollectionMetaResult,
    ClientError,
    UpdateCollectionMetaInput,
    OptimistMutationContext
> => {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const collectionQueryKey = useMemo(() => buildGetEditableCollectionQueryKey(collectionId), [collectionId]);

    const { registerUpdater: registerUpdateProfileMetaUpdater, mutationOptions: UpdateProfileMetaMutationOptions } =
        useOptimist<UpdateCollectionMetaResult, ClientError, UpdateCollectionMetaInput>();

    const UpdateCollectionMetaOptions: OptimistRegisterUpdaterOptions<
        UpdateCollectionMetaResult,
        ClientError,
        UpdateCollectionMetaInput
    > = useMemo(() => {
        return {
            onError: () => {
                enqueueSnackbar('An error occurred while updating collection.', { variant: 'error' });
            },
            onSuccess: (data, request) => {
                setCollectionEditedInSessionStorage(request.input.collectionId, parentQuerySlug);

                // update versions on editableCollection from response to keep in sync w/ what backend is expecting
                queryClient.setQueryData<GetEditableCollectionResponse>(collectionQueryKey, (previous) => {
                    if (!previous) return { getEditableCollection: {} } as GetEditableCollectionResponse;

                    return {
                        getEditableCollection: {
                            ...previous?.getEditableCollection,
                            itemsVersion: data.updateCollectionMetadata.itemsVersion,
                            infoVersion: data.updateCollectionMetadata.infoVersion
                        }
                    };
                });
            },
            refetchQuery: false
        };
    }, [enqueueSnackbar, parentQuerySlug, queryClient, collectionQueryKey]);

    registerUpdateProfileMetaUpdater<GetEditableCollectionResponse>(
        collectionQueryKey,
        (previous, newCollectionMeta) => {
            const previousCollection = previous?.getEditableCollection ?? {};

            const updatedCollection = {
                ...previous?.getEditableCollection,
                title: newCollectionMeta.input.title ?? previousCollection.title,
                titleSub: newCollectionMeta.input.titleSub ?? previousCollection.titleSub,
                description: newCollectionMeta.input.description ?? previousCollection.description,
                tags: newCollectionMeta.input.tags ?? previousCollection.tags,
                defCat: newCollectionMeta.input?.defaultCatalogImageKey ?? previousCollection.defCat,
                defFeat: newCollectionMeta.input?.defaultFeaturedImageKey ?? previousCollection.defFeat
            };

            if (newCollectionMeta.input.images) {
                updatedCollection.images = {
                    ...previousCollection.images,
                    catalog:
                        newCollectionMeta.input.images.catalog === null
                            ? null
                            : {
                                  ...(previousCollection.images?.catalog as Image),
                                  id: newCollectionMeta.input.images.catalog
                              },
                    featured:
                        newCollectionMeta.input.images?.featured === null
                            ? null
                            : {
                                  ...(previousCollection.images?.featured as Image),
                                  id: newCollectionMeta.input.images.featured
                              }
                };
            }

            return {
                getEditableCollection: updatedCollection
            };
        },
        UpdateCollectionMetaOptions
    );

    const { mutationFn } = useBuildAuthenticatedMutationFn<UpdateCollectionMetaResult, UpdateCollectionMetaInput>(
        UPDATE_COLLECTION_META,
        { api: Api.Content }
    );

    return useMutation<UpdateCollectionMetaResult, ClientError, UpdateCollectionMetaInput, OptimistMutationContext>(
        mutationFn,
        { ...UpdateProfileMetaMutationOptions }
    );
};
