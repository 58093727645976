import { useRef, useState, useCallback, useMemo } from 'react';

const TIME_BETWEEN_CHECKS_IN_MS = 250;
const DEFAULT_WAIT_TIMEOUT_IN_MS = 30000;

const sleep = (timeToSleep: number) => new Promise((resolve) => setTimeout(resolve, timeToSleep));

type Ready = boolean | null | string | number;

interface UseGateForRequestInterface {
    gateOpen: boolean;
    setOpen: (open: Ready) => void;
    ensureGateOpen: () => Promise<unknown>;
}

interface UseGateForRequestProps {
    isReady?: () => boolean;
    initialOpen?: boolean;
    label?: string;
    timeout?: number;
}

export const useGateForRequest = ({
    isReady = () => false,
    label = 'unknown',
    initialOpen = false,
    timeout = DEFAULT_WAIT_TIMEOUT_IN_MS
}: UseGateForRequestProps = {}): UseGateForRequestInterface => {
    const waitMaxAttempts = timeout / TIME_BETWEEN_CHECKS_IN_MS;
    const [gateOpen, setGateOpen] = useState<boolean>(initialOpen);
    const gateOpenRef = useRef<boolean>(initialOpen);

    const setOpen = useCallback((open: Ready) => {
        const gateShouldBeOpen = Boolean(open);

        gateOpenRef.current = gateShouldBeOpen;
    }, []);

    const checkState = useCallback(
        async (resolve, reject, count) => {
            if (isReady() || gateOpenRef.current) {
                setGateOpen(true);
                return resolve();
            }

            setGateOpen(false);

            // @TODO back off the more we check.
            await sleep(TIME_BETWEEN_CHECKS_IN_MS);

            count++;

            if (count < waitMaxAttempts) {
                checkState(resolve, reject, count);
            } else {
                return reject(new Error(`Timeout while waiting for gate to be open for ${label}`));
            }
        },
        [isReady, label, waitMaxAttempts]
    );

    const ensureGateOpen = useMemo(
        () => () => new Promise((resolve, reject) => checkState(resolve, reject, 0)),
        [checkState]
    );

    return {
        setOpen,
        gateOpen,
        ensureGateOpen
    };
};
