import React from 'react';

import { mdiArrowLeft } from '@mdi/js';
import Icon from '@mdi/react';

import { StyledIconButton } from './BackButton.styles';

export interface BackButtonProps {
    onClick: () => void;
    enabled: boolean;
}

export const BackButton: React.FC<BackButtonProps> = ({ onClick, enabled = true }) => (
    <StyledIconButton
        disabled={!enabled}
        onClick={() => {
            onClick();
        }}
    >
        <Icon path={mdiArrowLeft} size="24px" />
    </StyledIconButton>
);
