export interface ContainerClickProps {
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

export const useContainerClickProps = ({ onClick }: ContainerClickProps): ContainerClickProps => {
    const containerClickProps: ContainerClickProps = {};

    if (onClick) {
        containerClickProps.onClick = onClick;
    }

    return containerClickProps;
};
