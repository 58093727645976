import React, { useContext, useState } from 'react';

import { ComponentEngineMode, useComponentEngine } from 'components/engine';

import { RichTextEditorContext } from '../common/RichTextEditor/Context';
import { DraftJsEntity } from '../common/RichTextEditor/utils';

import { AddComponentContainer } from './AddComponent.styles';
import { ComponentList } from './ComponentList';

interface AddComponentProps {
    fromComponentId?: null | string;
}

export const AddComponent: React.FC<AddComponentProps> = () => {
    const { handleEntityModalOpen } = useContext(RichTextEditorContext);
    const { mode } = useComponentEngine();

    const [isOpen, setIsOpen] = useState(false);

    if (mode === ComponentEngineMode.View) {
        return null;
    }

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const onClickMenuIconItem = (entityType: DraftJsEntity) => {
        try {
            handleEntityModalOpen(entityType);
        } catch (e) {
            // @TODO Should not be able to get here... how do we handle if the unthinkable happens?
            console.error(e);
        }

        toggleMenu();
    };

    return (
        <AddComponentContainer>
            <ComponentList isOpen={isOpen} onClick={onClickMenuIconItem} toggleMenu={toggleMenu} />
        </AddComponentContainer>
    );
};
