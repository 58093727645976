import { useState, useMemo, useEffect } from 'react';

import { mdiStore, mdiBriefcaseAccount } from '@mdi/js';
import { CollaborativeEntity, WorkflowStatus, AvailabilityStatus } from 'components/CollaborationTable';
import { EntityType } from 'components/ContentContext/Enums';
import { CourseIconPath } from 'components/icons/CourseIcon';
import { SkillIconPath } from 'components/icons/SkillIcon';
import { CollectionType } from 'components/modals/Skills/useGetCollectionsForTenantProfile';
import { OrganizationTerminology, useOrganizationTerminology } from 'hooks/useOrganizationTerminology';
import pluralize from 'pluralize';
import { uppercaseFirst } from 'utils/uppercaseFirst';

export enum StudioFilterCategory {
    CONTENT_TYPE = 'type',
    AUTHOR = 'author',
    WORKFLOW = 'workflow',
    CATALOG = 'catalog',
    AVAILABILITY = 'availability',
    TIME = 'time'
}

export interface StudioFilter {
    multiSelect: boolean;
    label?: string;
    category: StudioFilterCategory;
    options: StudioFilterOption[];
    hideChip?: boolean;
    rightDivider?: boolean;
}

interface StudioFilterOption {
    id: string;
    title: string;
    selected?: boolean;
    disabled?: boolean;
    iconPath?: string;
    avatarSrc?: string | null;
    loading?: boolean;
}

export interface SelectedFilterOption extends StudioFilterOption {
    hideChip?: boolean;
    category: StudioFilterCategory;
}

export const getFilterExpressionForOption = (option: SelectedFilterOption) => {
    switch (option.category) {
        case StudioFilterCategory.CONTENT_TYPE: {
            if (option.id === EntityType.SKILL) {
                return (e: CollaborativeEntity) => e.type === EntityType.SKILL;
            } else if (option.id === EntityType.COLLECTION) {
                return (e: CollaborativeEntity) => e.type === EntityType.COLLECTION;
            }
            return;
        }
        case StudioFilterCategory.AUTHOR:
            return (e: CollaborativeEntity) => e.authors.find(({ userId }) => userId === option.id);
        case StudioFilterCategory.WORKFLOW:
            return (e: CollaborativeEntity) => e.workflow === option.title;
        case StudioFilterCategory.CATALOG:
            if (option.id === CollectionType.INTERNAL) {
                return (e: CollaborativeEntity) =>
                    e.ancestorCollections.find(
                        (collection) =>
                            collection.rootCollectionId === collection.collectionId &&
                            collection.rootType === CollectionType.INTERNAL
                    );
            } else if (option.id === CollectionType.CATALOG) {
                return (e: CollaborativeEntity) =>
                    e.ancestorCollections.find(
                        (collection) =>
                            collection.rootCollectionId === collection.collectionId &&
                            collection.rootType === CollectionType.CATALOG
                    );
            }
            return;
        case StudioFilterCategory.AVAILABILITY:
            return (e: CollaborativeEntity) => e.availability === option.title;
        default:
            return;
    }
};

export const buildFilterExpression = (selectedFilterOptions: SelectedFilterOption[]) => {
    const filterExpressions = selectedFilterOptions.map((option) => getFilterExpressionForOption(option));
    return (e: CollaborativeEntity) => filterExpressions.every((expression) => (expression ? expression(e) : true));
};

const LOADING_AUTHOR_OPTION = { title: '', id: 'LOADING', avatarSrc: null, loading: true, disabled: true };

const buildDefaultFilters = (
    filtersToInclude: StudioFilterCategory[],
    terminology: OrganizationTerminology,
    startingFilters: Record<string, string | string[]>
) => {
    const filters: StudioFilter[] = [];

    for (const filter of filtersToInclude) {
        switch (filter) {
            case StudioFilterCategory.CONTENT_TYPE:
                filters.push({
                    multiSelect: false,
                    category: StudioFilterCategory.CONTENT_TYPE,
                    label: 'Content type',
                    options: [
                        {
                            selected: startingFilters[StudioFilterCategory.CONTENT_TYPE] === EntityType.SKILL,
                            iconPath: SkillIconPath,
                            title: uppercaseFirst(pluralize(terminology.skill)),
                            id: EntityType.SKILL
                        },
                        {
                            selected: startingFilters[StudioFilterCategory.CONTENT_TYPE] === EntityType.COLLECTION,
                            iconPath: CourseIconPath,
                            title: uppercaseFirst(pluralize(terminology.collection)),
                            id: EntityType.COLLECTION
                        }
                    ]
                });
                break;
            case StudioFilterCategory.AUTHOR:
                filters.push({
                    multiSelect: true,
                    category: StudioFilterCategory.AUTHOR,
                    label: uppercaseFirst(StudioFilterCategory.AUTHOR),
                    options: [LOADING_AUTHOR_OPTION, LOADING_AUTHOR_OPTION, LOADING_AUTHOR_OPTION]
                });
                break;
            case StudioFilterCategory.WORKFLOW:
                filters.push({
                    multiSelect: false,
                    category: StudioFilterCategory.WORKFLOW,
                    label: uppercaseFirst(StudioFilterCategory.WORKFLOW),
                    options: Object.entries(WorkflowStatus).map(([id, title]) => ({
                        id,
                        title,
                        selected: startingFilters[StudioFilterCategory.WORKFLOW] === id
                    }))
                });
                break;
            case StudioFilterCategory.CATALOG:
                filters.push({
                    multiSelect: false,
                    category: StudioFilterCategory.CATALOG,
                    label: uppercaseFirst(StudioFilterCategory.CATALOG),
                    options: [
                        {
                            iconPath: mdiStore,
                            title: 'Public Catalog',
                            id: CollectionType.CATALOG,
                            selected: startingFilters[StudioFilterCategory.CATALOG] === CollectionType.CATALOG
                        },
                        {
                            iconPath: mdiBriefcaseAccount,
                            title: 'Internal Training',
                            id: CollectionType.INTERNAL,
                            selected: startingFilters[StudioFilterCategory.CATALOG] === CollectionType.INTERNAL
                        }
                    ]
                });
                break;
            case StudioFilterCategory.AVAILABILITY:
                filters.push({
                    multiSelect: true,
                    rightDivider: true,
                    category: StudioFilterCategory.AVAILABILITY,
                    label: uppercaseFirst(StudioFilterCategory.AVAILABILITY),
                    options: Object.entries(AvailabilityStatus).map(([id, title]) => ({
                        id,
                        title,
                        selected: (startingFilters[StudioFilterCategory.AVAILABILITY] ?? []).includes(id)
                    }))
                });
                break;
            case StudioFilterCategory.TIME:
                filters.push({
                    multiSelect: false,
                    category: StudioFilterCategory.TIME,
                    hideChip: true,
                    options: [
                        { title: 'All time', selected: true, id: '0' },
                        { title: 'Past 30 days', disabled: true, id: '30' },
                        { title: 'Past 90 days', disabled: true, id: '90' },
                        { title: 'Past year', disabled: true, id: '365' }
                    ]
                });
                break;
        }
    }

    return filters;
};

const DEFAULT_FILTERS = [
    StudioFilterCategory.CONTENT_TYPE,
    StudioFilterCategory.AUTHOR,
    StudioFilterCategory.WORKFLOW,
    StudioFilterCategory.CATALOG,
    StudioFilterCategory.AVAILABILITY,
    StudioFilterCategory.TIME
];

export const useFilters = (
    startingFilters?: Record<string, string | string[]>,
    filtersToInclude = DEFAULT_FILTERS
): {
    selectedFilterOptions: SelectedFilterOption[];
    filters: StudioFilter[];
    setFilters: React.Dispatch<React.SetStateAction<StudioFilter[]>>;
} => {
    const { terminology } = useOrganizationTerminology();
    const [filters, setFilters] = useState<StudioFilter[]>(
        buildDefaultFilters(filtersToInclude, terminology, startingFilters ?? {})
    );

    useEffect(() => {
        setFilters(buildDefaultFilters(filtersToInclude, terminology, startingFilters ?? {}));
    }, [filtersToInclude, terminology, startingFilters]);

    const selectedFilterOptions = useMemo(
        () =>
            filters
                .flatMap((filter) =>
                    filter.options.map((option) => ({
                        ...option,
                        category: filter.category,
                        hideChip: filter.hideChip
                    }))
                )
                .filter((option) => option.selected && !option.hideChip),
        [filters]
    );

    return { selectedFilterOptions, filters, setFilters };
};
