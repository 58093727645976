import React, { createContext, useEffect, useMemo, useState } from 'react';

import getCollaborationsByOwner, {
    buildGetCollaborationsByOwnerQueryKey,
    GetCollaborationsByOwnerResponse
} from 'components/CollaborationTable/getCollaborationsByOwner';
import { useHeadCellPreferences } from 'components/CollaborationTable/useHeadCellPreferences';
import {
    buildCollaborationFromCollection,
    buildCollaborationFromSkill,
    CollaborativeEntity,
    HeadCellPreference,
    refineResults
} from 'components/CollaborationTable/utils';
import { EntityType } from 'components/ContentContext/Enums';
import { OwnerType } from 'components/ContentContext/Interfaces';
import { TransferManyEntityOwnershipProps } from 'components/modals/Sharing/SharingModal/hooks/useTransferManyEntityOwnership';
import { CollectionType } from 'components/modals/Skills/useGetCollectionsForTenantProfile';
import { HeadCell } from 'components/SortableTable/EnhancedTableHead';
import { useProfile } from 'context/ProfileContext';
import { ClientError } from 'graphql-request';
import { useCurrentUser } from 'hooks/useCurrentUser';
import useGqlClient from 'hooks/useGqlClient';
import { useOrganizationTerminology } from 'hooks/useOrganizationTerminology';
import useUserDetails from 'hooks/useUserDetails';
import { API_CONTENT } from 'lib/ApiConstants';
import { InfiniteData, QueryKey, useInfiniteQuery } from 'react-query';

import { StudioFilterCategory, useFilters } from './useFilters';
import { useQueryParams } from './useQueryParams';

export interface StudioProviderInterface {
    organizationId: string;
    organizationSlug: string;
    noStudioEntities: boolean;
    filteredStudioEntities: CollaborativeEntity[];
    isLoading: boolean;
    isError: boolean;
    selectedFilterOptions: SelectedFilterOption[];
    filters: StudioFilter[];
    search: string;
    setSearch: React.Dispatch<React.SetStateAction<string>>;
    headCellDetails: HeadCell<CollaborativeEntity>[];
    headCellPreferences: HeadCellPreference[];
    setHeadCellPreferences: React.Dispatch<React.SetStateAction<HeadCellPreference[]>>;
    contentQueryKey: QueryKey;
    contentTransferOptimistCallback: (
        previous: InfiniteData<GetCollaborationsByOwnerResponse>,
        transferQueryProps: TransferManyEntityOwnershipProps
    ) => InfiniteData<GetCollaborationsByOwnerResponse>;
    fetchingMore: boolean;
}

const getFilterExpressionForOption = (option: SelectedFilterOption) => {
    switch (option.category) {
        case StudioFilterCategory.CONTENT_TYPE: {
            if (option.id === EntityType.SKILL) {
                return (e: CollaborativeEntity) => e.type === EntityType.SKILL;
            } else if (option.id === EntityType.COLLECTION) {
                return (e: CollaborativeEntity) => e.type === EntityType.COLLECTION;
            }
            return;
        }
        case StudioFilterCategory.AUTHOR:
            return (e: CollaborativeEntity) => e.authors.find(({ userId }) => userId === option.id);
        case StudioFilterCategory.WORKFLOW:
            return (e: CollaborativeEntity) => e.workflow === option.id;
        case StudioFilterCategory.CATALOG:
            if (option.id === CollectionType.INTERNAL) {
                return (e: CollaborativeEntity) =>
                    e.ancestorCollections.find(
                        (collection) =>
                            collection.rootCollectionId === collection.collectionId &&
                            collection.rootType === CollectionType.INTERNAL
                    );
            } else if (option.id === CollectionType.CATALOG) {
                return (e: CollaborativeEntity) =>
                    e.ancestorCollections.find(
                        (collection) =>
                            collection.rootCollectionId === collection.collectionId &&
                            collection.rootType === CollectionType.CATALOG
                    );
            }
            return;
        case StudioFilterCategory.AVAILABILITY:
            return (e: CollaborativeEntity) => e.availability === option.id;
        default:
            return;
    }
};

const buildFilterExpression = (selectedFilterOptions: SelectedFilterOption[]) => {
    const filterExpressions = selectedFilterOptions.map((option) => getFilterExpressionForOption(option));
    return (e: CollaborativeEntity) => filterExpressions.every((expression) => (expression ? expression(e) : true));
};

export const INITIAL_SORT_KEY = 'modifiedAt';

const STUDIO_PREFERENCES_KEY = 'studioColumns';

export const DEFAULT_HEAD_CELL_PREFERENCES: HeadCellPreference[] = [
    { id: 'title', label: 'Title', checked: true, sticky: true, borderRight: true },
    { id: 'createdAt', label: 'Created', checked: true },
    { id: 'modifiedAt', label: 'Modified', checked: true },
    { id: 'availability', label: 'Availability', checked: true },
    { id: 'workflow', label: 'Workflow', checked: true },
    { id: 'views', label: 'Public views', checked: true },
    { id: 'likes', label: 'Likes', checked: true },
    { id: 'authors', label: 'Authors', checked: true },
    { id: 'collections', label: 'Collections present in', checked: true }
];

interface StudioFilter {
    multiSelect: boolean;
    label?: string;
    category: StudioFilterCategory;
    options: StudioFilterOption[];
    hideChip?: boolean;
    rightDivider?: boolean;
}

interface StudioFilterOption {
    id: string;
    title: string;
    selected?: boolean;
    disabled?: boolean;
    iconPath?: string;
    avatarSrc?: string | null;
}

interface SelectedFilterOption extends StudioFilterOption {
    hideChip?: boolean;
    category: StudioFilterCategory;
}

const StudioContext = createContext(undefined as unknown as StudioProviderInterface);

const { Provider, Consumer } = StudioContext;

const StudioProvider: React.FC<{ organizationId: string }> = ({ children, organizationId }) => {
    const { currentUser } = useCurrentUser();
    const { currentProfile } = useProfile();

    const ownerInfo = useMemo(() => ({ type: OwnerType.Organization, id: organizationId }), [organizationId]);

    const { headCellDetails, headCellPreferences, setHeadCellPreferences } = useHeadCellPreferences(
        STUDIO_PREFERENCES_KEY,
        DEFAULT_HEAD_CELL_PREFERENCES
    );

    const { terminology } = useOrganizationTerminology();

    const queryParams = useQueryParams();
    const { selectedFilterOptions, filters, setFilters } = useFilters(queryParams);
    const [search, setSearch] = useState('');

    const { client, withInfiniteQueryOptions } = useGqlClient(API_CONTENT);

    const getStudioEntitiesQueryKey = useMemo(() => buildGetCollaborationsByOwnerQueryKey(ownerInfo), [ownerInfo]);

    const { data, isLoading, isError, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery<
        GetCollaborationsByOwnerResponse,
        ClientError
    >(
        withInfiniteQueryOptions({
            queryKey: getStudioEntitiesQueryKey,
            queryFn: getCollaborationsByOwner(client, { ownerInfo }),
            getNextPageParam: (res) => res?.getCollaborationsByOwner?.cursor ?? undefined
        })
    );

    useEffect(() => {
        if (hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

    const entities = useMemo(
        () =>
            (data?.pages
                ?.flatMap((page) =>
                    page.getCollaborationsByOwner?.items
                        ?.filter((item) => !!item?.item)
                        ?.map(({ item }) =>
                            'skillId' in item
                                ? buildCollaborationFromSkill(item, terminology.skill)
                                : buildCollaborationFromCollection(item, terminology.collection)
                        )
                )
                .filter(Boolean) as CollaborativeEntity[]) ?? [],
        [data, terminology.skill, terminology.collection]
    );

    const authorIds = useMemo(
        () => [...new Set(entities.flatMap((entity) => entity.authors.map((author) => author.userId)))],
        [entities]
    );
    const { usersDetails, loading } = useUserDetails({ userIds: authorIds });

    useEffect(() => {
        if (!loading && !hasNextPage && !isFetchingNextPage) {
            setFilters((prev) =>
                prev.map((filter) => {
                    if (filter.category === StudioFilterCategory.AUTHOR) {
                        const options = authorIds.map((id) => ({
                            selected: (queryParams[StudioFilterCategory.AUTHOR] ?? []).includes(id),
                            title: usersDetails?.[id]?.displayName ?? 'User',
                            id,
                            avatarSrc: usersDetails?.[id]?.imageUrl ?? null
                        }));

                        return { ...filter, options };
                    }
                    return filter;
                })
            );
        }
    }, [authorIds, usersDetails, setFilters, hasNextPage, isFetchingNextPage, loading, queryParams]);

    const noStudioEntities = useMemo(() => !isLoading && entities.length === 0, [isLoading, entities]);

    const contentTransferOptimistCallback: {
        (
            previous: InfiniteData<GetCollaborationsByOwnerResponse>,
            transferQueryProps: TransferManyEntityOwnershipProps
        ): InfiniteData<GetCollaborationsByOwnerResponse>;
    } = (previous: InfiniteData<GetCollaborationsByOwnerResponse>, transferQueryProps) => {
        const updatedPages = previous.pages.map((page) => {
            return {
                getCollaborationsByOwner: {
                    ...page.getCollaborationsByOwner,
                    items: page.getCollaborationsByOwner.items.filter(
                        ({ item }) =>
                            !transferQueryProps.entities.find(({ id }) => {
                                const entityId = 'skillId' in item ? item.skillId : item.collectionId;
                                return id === entityId;
                            })
                    )
                }
            };
        });

        return {
            ...previous,
            pages: updatedPages
        };
    };

    const filteredStudioEntities = useMemo(() => {
        const filterExpression = buildFilterExpression(selectedFilterOptions);

        return refineResults({
            results: entities,
            filter: filterExpression,
            search,
            userId: currentUser?.userId
        });
    }, [entities, selectedFilterOptions, search, currentUser?.userId]);

    return (
        <Provider
            value={{
                organizationId,
                organizationSlug: currentProfile?.alias ?? '',
                noStudioEntities,
                filteredStudioEntities,
                isLoading,
                isError,
                filters,
                selectedFilterOptions,
                search,
                setSearch,
                headCellDetails,
                headCellPreferences,
                setHeadCellPreferences,
                contentQueryKey: getStudioEntitiesQueryKey,
                contentTransferOptimistCallback,
                fetchingMore: hasNextPage || isFetchingNextPage
            }}
        >
            {children}
        </Provider>
    );
};

export { StudioContext, StudioProvider, Consumer as StudioConsumer };
