import React, { useMemo } from 'react';

import { OwnerType } from 'components/ContentContext/Interfaces';
import {
    buildGetInternalProfileForOwnerQueryKey,
    useGetInternalTenantProfileForOwner
} from 'components/RootProfileCollection/context/getInternalTenantProfile';
import AppLayout from 'layouts/AppLayout';

import RootProfileCollection from '../..';
import { RootCollectionProvider } from '../../context/RootCollectionContext';
import { TenantProfileDetails } from '../../context/useGetTenantProfile';

export const OrgMemberInternal: React.FC<{ orgSlug: string; organizationId: string; usePrimary: boolean }> = ({
    organizationId,
    orgSlug,
    usePrimary
}) => {
    const queryKey = buildGetInternalProfileForOwnerQueryKey(organizationId);
    const { data, isLoading } = useGetInternalTenantProfileForOwner(
        { owner: { type: OwnerType.Organization, id: organizationId } },
        queryKey,
        usePrimary,
        !!organizationId
    );

    const { refetch } = useGetInternalTenantProfileForOwner(
        { owner: { type: OwnerType.Organization, id: organizationId } },
        queryKey,
        true,
        false
    );

    const formattedData = useMemo(() => {
        if (!data) return data;

        const { privateRootCollection: relevantCollection, ...rest } = data.getTenantProfileForOwner;
        const formatted: TenantProfileDetails = {
            relevantCollection,
            ...rest
        };
        return formatted;
    }, [data]);

    return (
        <RootCollectionProvider
            isInternal={true}
            ownerDetails={{ slug: orgSlug, type: OwnerType.Organization, id: organizationId }}
            tenantDetailsIsLoading={isLoading}
            tenantProfileDetails={formattedData}
            refetch={refetch}
            queryKey={queryKey}
        >
            <AppLayout withoutPadding fullHeight mainContentOverflow>
                <RootProfileCollection />
            </AppLayout>
        </RootCollectionProvider>
    );
};
