import { useState, useEffect } from 'react';

import { ClientError } from 'graphql-request';
import gql from 'graphql-tag';
import { useQuery } from 'react-query';

import useGqlClient from '../../../../../hooks/useGqlClient';
import buildGqlQueryFn from '../../../../../hooks/useGqlClient/helpers/buildGqlQueryFn';
import { API_CONTENT } from '../../../../../lib/ApiConstants';
import { EncodeStatus } from '../types';

const GET_ENCODE_STATUS_QUERY = gql`
    query getVideoEncodingStatus($videoId: UUIDv4!) {
        getVideoEncodingStatus(videoId: $videoId)
    }
`;

interface UseEncodeStatusProps {
    videoId: string | null;
}

const ENCODE_STATUS_REFRESH_INTERVAL_MS = 2000;

interface EncodeStatusResult {
    getVideoEncodingStatus: string;
}

interface UseEncodeStatusInterface {
    encodeStatus: EncodeStatus;
}

export const useEncodeStatus = ({ videoId }: UseEncodeStatusProps): UseEncodeStatusInterface => {
    const [encodeStatus, setEncodeStatus] = useState<EncodeStatus>(EncodeStatus.Unknown);

    const { client, withQueryOptions } = useGqlClient(API_CONTENT);

    const { data: encodeStatusData } = useQuery<EncodeStatusResult, ClientError>(
        withQueryOptions({
            // Only look for changes while status is not in a terminal state (complete/error)
            queryKey: ['getVideoEncodingStatus', videoId],
            queryFn: buildGqlQueryFn(client, GET_ENCODE_STATUS_QUERY, { videoId }),
            refetchInterval: ENCODE_STATUS_REFRESH_INTERVAL_MS,
            refetchOnWindowFocus: false,
            enabled:
                videoId !== null &&
                videoId !== '' &&
                ![EncodeStatus.Complete, EncodeStatus.Error].includes(encodeStatus)
        })
    );

    useEffect(() => {
        if (!encodeStatusData) {
            return;
        }

        const status = encodeStatusData?.getVideoEncodingStatus ?? null;

        if (!status) {
            return;
        }

        const statusKey = Object.keys(EncodeStatus).find((val) => EncodeStatus[val] == status);

        if (!statusKey) {
            return;
        }

        setEncodeStatus(EncodeStatus[statusKey]);
    }, [encodeStatusData]);

    return { encodeStatus };
};

export default useEncodeStatus;
