import { useEffect, useContext, useRef, useMemo } from 'react';

import AvatarsContext, { AvatarDetails, WorkingAvatarDetailsResult } from '..';

export interface UseAvatarsOptions {
    assetIds?: string[];
}

export interface UseAvatarsResult {
    loading: boolean;
    /**
     * An object mapping assetId to @see AvatarDetails
     * crop is the only field prioritized to send out, so make sure you use this field as a primary and fallback to others
     */
    avatars: Record<string, AvatarDetails>;
    /** Set a temporary imageSrc for a given assetId. This will be image on the @see AvatarDetails */
    setTempAvatar: (assetId: string, tempImageSrc: string | null) => void;
    determineAvatarSourceById: (assetId: string) => string | undefined;
}

/**
 * This is the a simplified public interface for AvatarsContext, and contains commonly used helpers.
 *
 * @param options @see UseAvatarsOptions
 */
export const useAvatars = ({ assetIds: assetIdsFromProps = [] }: UseAvatarsOptions = {}): UseAvatarsResult => {
    const requestedAssetIds = useRef(new Set<string>());

    const { fetchAvatars, getAvatarsFromMap, setTempAvatar, isLoading } = useContext(AvatarsContext);

    useEffect(() => {
        if (assetIdsFromProps.length === 0) {
            return;
        }

        const avatarsLeftToFetch = assetIdsFromProps.filter((assetId) => !requestedAssetIds.current.has(assetId));
        // @FIXME Why is fetchAvatars part of the check?
        if (avatarsLeftToFetch && fetchAvatars) {
            requestedAssetIds.current = new Set<string>([...requestedAssetIds.current, ...avatarsLeftToFetch]);
            fetchAvatars(avatarsLeftToFetch);
        }
    }, [assetIdsFromProps, fetchAvatars]);

    const { avatars } = useMemo(() => {
        if (getAvatarsFromMap) {
            return getAvatarsFromMap(assetIdsFromProps);
        }
        return {} as WorkingAvatarDetailsResult;
    }, [getAvatarsFromMap, assetIdsFromProps]);

    const determineAvatarSourceById = (assetId: string) => {
        if (avatars && assetId) {
            if (avatars[assetId]?.crop) {
                return avatars[assetId]?.crop;
            }
            // fall back to .image if no crop
            if (avatars[assetId]?.image) {
                return avatars[assetId]?.image ?? undefined;
            }
        }
        return undefined;
    };

    return {
        loading: isLoading,
        avatars,
        setTempAvatar,
        determineAvatarSourceById
    };
};
