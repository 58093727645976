import React from 'react';

import { QuestionComponentValues, QuestionType } from '@adept-at/lib-react-components';
import { Collapse, Fade, TextField, FormControl, IconButton, Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { BoxWithPadding } from '../../Question.styles';

interface Props {
    values: QuestionComponentValues;
    onChange: <TComponentFieldValue>(field: string) => (value: TComponentFieldValue) => void;
    onTypeChange: (key: string, index: number | string, value: any) => void;
    error: any;
}

export const BlankEdit: React.FC<Props> = ({
    values: { questionType, blankAnswers },
    onChange,
    onTypeChange,
    error
}) => {
    return (
        <Collapse timeout={500} in={questionType === QuestionType.Blank}>
            <Fade timeout={800} in={questionType === QuestionType.Blank}>
                <FormControl component="fieldset">
                    {blankAnswers.map((answer, index) => (
                        <BoxWithPadding key={index} display="flex" alignItems="center">
                            <TextField
                                id={`blankEdit-blankAnswer-${index}`}
                                key={`${index}-answer`}
                                label="Answer"
                                name="blankAnswer"
                                variant="outlined"
                                fullWidth
                                value={answer}
                                onChange={(e) => {
                                    onTypeChange('blankAnswers', index, e.target.value);
                                }}
                                inputProps={{
                                    'data-testid': `blankEdit-blankAnswer-${index}`
                                }}
                                error={!!error[`blankEdit-blankAnswer-${index}`]}
                                helperText={error[`blankEdit-blankAnswer-${index}`]}
                            />
                            <IconButton
                                aria-label={`remove answer ${index}`}
                                data-testid={`blankEdit-removeAnswer-${index}`}
                                key={`${index}-icon-button`}
                                onClick={() => {
                                    if (blankAnswers.length <= 1) {
                                        return;
                                    }
                                    const draftBlankAnswer = [...blankAnswers];
                                    draftBlankAnswer.splice(index, 1);
                                    onChange('blankAnswers')(draftBlankAnswer);
                                }}
                            >
                                <DeleteIcon key={`${index}-del-icon`} />
                            </IconButton>
                        </BoxWithPadding>
                    ))}
                    <BoxWithPadding display="flex" justifyContent="space-between">
                        <Button
                            data-testid="blankEdit-addAnswer"
                            onClick={() => {
                                const draftBlankAnswer = [...blankAnswers, ''];
                                onChange('blankAnswers')(draftBlankAnswer);
                            }}
                        >
                            Add another answer
                        </Button>
                    </BoxWithPadding>
                </FormControl>
            </Fade>
        </Collapse>
    );
};
export default BlankEdit;
