import React from 'react';

import { defaultMuiTheme } from '@adept-at/lib-react-components';
import { Button, useMediaQuery } from '@material-ui/core';
import SearchBar from 'components/SearchBar';
import styled from 'styled-components';

import { FavoritesSortBy } from './constants';
import { GridViewButtons } from './GridViewButtons';
import { ListViewButtons } from './ListViewButtons';
import { SortByButtons } from './SortByButtons';

export const LayoutViewButton = styled(Button)`
    color: ${(props) => props.theme.colors.primary};
    white-space: nowrap;
`;

const Container = styled.div`
    display: flex;
`;

const SearchWrapper = styled.div`
    margin-right: 0.5rem;
`;

export const FavoritesLayoutViewButtons: React.FC<{
    isGridView: boolean;
    setIsGridView: React.Dispatch<React.SetStateAction<boolean>>;
    search: string;
    setSearch: React.Dispatch<React.SetStateAction<string>>;
    sortBy: FavoritesSortBy;
    setSortBy: React.Dispatch<React.SetStateAction<FavoritesSortBy>>;
}> = ({ isGridView, setIsGridView, search, setSearch, sortBy, setSortBy }) => {
    const isSmallScreenWidth = useMediaQuery(defaultMuiTheme.breakpoints.down(765));

    return (
        <Container>
            <SearchWrapper>
                <SearchBar search={search} setSearch={setSearch} />
            </SearchWrapper>
            {isGridView ? (
                <>
                    <SortByButtons isSmallScreen={isSmallScreenWidth} sortBy={sortBy} setSortBy={setSortBy} />
                    <ListViewButtons isSmallScreen={isSmallScreenWidth} setIsGridView={setIsGridView} />
                </>
            ) : (
                <GridViewButtons isSmallScreen={isSmallScreenWidth} setIsGridView={setIsGridView} />
            )}
        </Container>
    );
};
