import { useContext, useCallback } from 'react';

import { AuthContext } from 'context/AuthContext';
import { ClientError } from 'graphql-request';
import { UseQueryOptions, UseInfiniteQueryOptions, UseMutationOptions } from 'react-query';

export interface UseGqlReactQueryOptionsOutput {
    withQueryOptions: <TQueryFnData, TError extends Error, TData>(
        useQueryOptions?: UseQueryOptions<TQueryFnData, TError, TData> | undefined
    ) => UseQueryOptions<TQueryFnData, TError, TData>;
    withInfiniteQueryOptions: <TQueryFnData, TError extends Error, TData, TQueryData>(
        useInfiniteQueryOptions: UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryData>
    ) => UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryData>;
    withMutationOptions: <TData, TError extends Error, TVariables, TContext>(
        useMutationOptions?: UseMutationOptions<TData, TError, TVariables, TContext> | undefined
    ) => UseMutationOptions<TData, TError, TVariables, TContext>;
}

/**
 * @deprecated
 */
const useGqlReactQueryOptions = (): UseGqlReactQueryOptionsOutput => {
    // const { refreshSession, resetAuth, isAuthenticated } = useContext(AuthContext);

    // @TODO This should not know ANYTHING about auth state
    const { isAuthenticated } = useContext(AuthContext);

    // @TODO SAS this needs to be reworked entirely
    const retry = useCallback(
        <TError extends Error>(
                retryFromConsumer?: boolean | number | ((failureCount: number, error: TError) => boolean)
            ) =>
            (failureCount: number, err: TError): boolean => {
                if (typeof retryFromConsumer === 'function') return retryFromConsumer(failureCount, err);

                // refresh token and retry request
                if (err instanceof ClientError && err?.response?.statusCode === 401 && failureCount < 3) {
                    // @TODO SAS handle this differently
                    // refreshSession();
                }

                if (failureCount < 3) {
                    return true;
                }

                if (err instanceof ClientError && err?.response?.statusCode === 401 && failureCount >= 3) {
                    // resetAuth();
                    return false;
                }

                return false;
            },
        // [refreshSession, resetAuth]
        []
    );

    const retryDelay = useCallback(
        (retryDelayFromConsumer?: ((failureCount: number) => number) | number) =>
            (retryAttempt: number): number => {
                if (retryDelayFromConsumer) {
                    if (typeof retryDelayFromConsumer === 'function') return retryDelayFromConsumer(retryAttempt);
                    return retryDelayFromConsumer;
                }
                return retryAttempt * 1000;
            },
        []
    );

    const withQueryOptions = useCallback(
        <TQueryFnData, TError extends Error, TData>(
            useQueryOptions?: UseQueryOptions<TQueryFnData, TError, TData>
        ): UseQueryOptions<TQueryFnData, TError, TData> => {
            let override = true;
            if (Array.isArray(useQueryOptions?.queryKey) && useQueryOptions?.queryKey[0] === 'getFavorites') {
                override = false;
            }

            return {
                retry: retry(useQueryOptions?.retry),
                retryDelay: retryDelay(useQueryOptions?.retryDelay),
                ...useQueryOptions, // location to overwrite retry logic if specified

                // @TODO We want loading state to reflect that the request is in flight. This approach does not give this to us
                enabled: override && isAuthenticated && useQueryOptions?.enabled
            };
        },
        [isAuthenticated, retry, retryDelay]
    );

    const withInfiniteQueryOptions = useCallback(
        <TQueryFnData, TError extends Error, TData, TQueryData>(
            useInfiniteQueryOptions: UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryData>
        ): UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryData> => {
            return {
                retry: retry(useInfiniteQueryOptions?.retry),
                retryDelay: retryDelay(useInfiniteQueryOptions?.retryDelay),
                ...useInfiniteQueryOptions, // location to overwrite retry logic if specified
                enabled: isAuthenticated && useInfiniteQueryOptions?.enabled
            };
        },
        [isAuthenticated, retry, retryDelay]
    );

    const withMutationOptions = useCallback(
        <TResult, TError extends Error, TVariables, TContext>(
            useMutationOptions?: UseMutationOptions<TResult, TError, TVariables, TContext>
        ): UseMutationOptions<TResult, TError, TVariables, TContext> => {
            return {
                retry: retry(useMutationOptions?.retry),
                retryDelay: retryDelay(useMutationOptions?.retryDelay),
                ...useMutationOptions // location to overwrite retry logic if specified
            };
        },
        [retry, retryDelay]
    );

    return { withQueryOptions, withInfiniteQueryOptions, withMutationOptions };
};

export default useGqlReactQueryOptions;
