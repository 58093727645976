// This hook is for use with the title component in Catalog and Collection views.
import { useMemo } from 'react';

import { defaultMuiTheme } from '@adept-at/lib-react-components';
import { useMediaQuery } from '@material-ui/core';
import {
    BREAKPOINT_1015PX,
    BREAKPOINT_1150PX,
    BREAKPOINT_600PX,
    BREAKPOINT_900PX,
    VIEW_WIDTH_20,
    VIEW_WIDTH_25,
    VIEW_WIDTH_30,
    VIEW_WIDTH_45,
    VIEW_WIDTH_65
} from 'styles/variables';

export const useMediaQueriesForTextareaTitleWidth = (): string => {
    const isUnder600ScreenWidth = useMediaQuery(defaultMuiTheme.breakpoints.down(BREAKPOINT_600PX));
    const isBetween600and900ScreenWidth = useMediaQuery(
        defaultMuiTheme.breakpoints.between(BREAKPOINT_600PX, BREAKPOINT_900PX)
    );
    const isBetween900And1015ScreenWidth = useMediaQuery(
        defaultMuiTheme.breakpoints.between(BREAKPOINT_900PX, BREAKPOINT_1015PX)
    );
    const isBetween1015And1150ScreenWidth = useMediaQuery(
        defaultMuiTheme.breakpoints.between(BREAKPOINT_1015PX, BREAKPOINT_1150PX)
    );

    const textareaWidth: string = useMemo(() => {
        if (isUnder600ScreenWidth) return VIEW_WIDTH_65;

        if (isBetween600and900ScreenWidth) return VIEW_WIDTH_45;

        if (isBetween900And1015ScreenWidth) return VIEW_WIDTH_20;

        if (isBetween1015And1150ScreenWidth) return VIEW_WIDTH_25;

        return VIEW_WIDTH_30;
    }, [
        isUnder600ScreenWidth,
        isBetween600and900ScreenWidth,
        isBetween900And1015ScreenWidth,
        isBetween1015And1150ScreenWidth
    ]);

    return textareaWidth;
};
