import { ClientError } from 'graphql-request';
import gql from 'graphql-tag';
import { useBuildAuthenticatedQueryFn } from 'hooks/useGqlClient/useBuildAuthenticatedQueryFn';
import { Api } from 'lib/ApiConstants';
import { ADEPT_USE_PRIMARY_HEADER } from 'lib/constants';
import { QueryKey, useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { collectionFragment } from '../fragments';

import { TenantProfileResponse } from './useGetTenantProfileForOwner';

export const getTenantProfileBySlugQueryName = 'getTenantProfileBySlug';
export const buildGetProfileBySlugQueryKey = (slug: string): QueryKey => [getTenantProfileBySlugQueryName, slug];

export interface GetTenantProfileBySlugResponse {
    getTenantProfileBySlug: TenantProfileResponse;
}

interface GetTenantProfileBySlugVariables {
    slug: string;
}

export const GET_TENANT_PROFILES_BY_SLUG_QUERY = gql`
    query getTenantProfileBySlug($slug: String!) {
        getTenantProfileBySlug(slug: $slug) {
            tenantProfileId
            tenantSlug
            rootCollection {
                ${collectionFragment}
            }
            marketplaceStatus
        }
    }
`;

export const useGetTenantProfileBySlug = (
    variables: GetTenantProfileBySlugVariables,
    options: UseQueryOptions<GetTenantProfileBySlugResponse, ClientError> = {},
    usePrimary: boolean
): UseQueryResult<GetTenantProfileBySlugResponse, ClientError> => {
    const { queryFn } = useBuildAuthenticatedQueryFn<GetTenantProfileBySlugResponse, GetTenantProfileBySlugVariables>(
        GET_TENANT_PROFILES_BY_SLUG_QUERY,
        variables,
        {
            api: Api.Content,
            headers: { [ADEPT_USE_PRIMARY_HEADER]: usePrimary }
        }
    );

    const queryCacheKey = buildGetProfileBySlugQueryKey(variables.slug);

    return useQuery<GetTenantProfileBySlugResponse, ClientError>(queryCacheKey, queryFn, options);
};
