import React from 'react';

import { useMediaQuery, useTheme as useMuiTheme, Avatar, Box, ListItemAvatar, ListItemText } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import styled from 'styled-components';

import { ContainerClickProps, useContainerClickProps } from '../hooks/useContainerClick';
import { useFormattedUserDetails } from '../hooks/useFormattedUserDetails';

const AVATAR_SIZE_LARGE = 'l';
const AVATAR_SIZE_MEDIUM = 'm';

const AVATAR_SIZE_LARGE_SIZE_IN_PIXELS = 40;
const AVATAR_SIZE_MEDIUM_SIZE_IN_PIXELS = 30;

const AVATAR_SIZE_LARGE_PADDING_RIGHT_IN_PIXELS = 8;
const AVATAR_SIZE_MEDIUM_PADDING_RIGHT_IN_PIXELS = 6;

const AVATAR_SIZE_LARGE_NAME_MARGIN_TOP_IN_PIXELS = 7;
const AVATAR_SIZE_MEDIUM_NAME_MARGIN_TOP_IN_PIXELS = 2;

export type AvatarSizes = 'l' | 'm';

interface StyledListItemAvatarProps {
    $avatarSize?: string;
}

const StyledListItemAvatar = styled(ListItemAvatar)<StyledListItemAvatarProps>`
    && {
        min-width: ${({ $avatarSize = AVATAR_SIZE_LARGE }) =>
            ($avatarSize === AVATAR_SIZE_MEDIUM &&
                `${AVATAR_SIZE_MEDIUM_SIZE_IN_PIXELS + AVATAR_SIZE_MEDIUM_PADDING_RIGHT_IN_PIXELS}px`) ||
            ($avatarSize === AVATAR_SIZE_LARGE &&
                `${AVATAR_SIZE_LARGE_SIZE_IN_PIXELS + AVATAR_SIZE_LARGE_PADDING_RIGHT_IN_PIXELS}px`)};
    }
`;

interface StyledAvatarProps {
    $size?: string;
}

const StyledAvatar = styled(Avatar)<StyledAvatarProps>`
    && {
        height: ${({ $size }) =>
            ($size === AVATAR_SIZE_MEDIUM && `${AVATAR_SIZE_MEDIUM_SIZE_IN_PIXELS}px`) ||
            ($size === AVATAR_SIZE_LARGE && `${AVATAR_SIZE_LARGE_SIZE_IN_PIXELS}px`)};
        width: ${({ $size }) =>
            ($size === AVATAR_SIZE_MEDIUM && `${AVATAR_SIZE_MEDIUM_SIZE_IN_PIXELS}px`) ||
            ($size === AVATAR_SIZE_LARGE && `${AVATAR_SIZE_LARGE_SIZE_IN_PIXELS}px`)};
    }
`;

interface StyledListItemTextProps {
    $avatarSize?: string;
}

const StyledListItemText = styled(ListItemText)<StyledListItemTextProps>`
    && {
        margin: ${({ $avatarSize }) =>
            ($avatarSize === AVATAR_SIZE_MEDIUM && `${AVATAR_SIZE_MEDIUM_NAME_MARGIN_TOP_IN_PIXELS}px 0`) ||
            ($avatarSize === AVATAR_SIZE_LARGE && `${AVATAR_SIZE_LARGE_NAME_MARGIN_TOP_IN_PIXELS}px 0`)};
    }
`;

interface UserListItemProps extends ContainerClickProps {
    userId: string;
    avatarSize?: AvatarSizes;
    multiLine?: boolean;
}

/**
 * Note: You will see avatarSize used throughout this component for items not related to avatars (such as displayname)
 * This is because we need to make decisions around height, margin, etc based on the size of the avatar. In other words,
 * the avatar is dictating the overall size of the card.
 */
export const UserListItem: React.FC<UserListItemProps> = ({
    userId,
    avatarSize = AVATAR_SIZE_LARGE,
    multiLine = false,
    ...theRest
}) => {
    const muiTheme = useMuiTheme();
    const isSmallOrLarger = useMediaQuery(muiTheme.breakpoints.up('sm'));
    const containerClickProps = useContainerClickProps(theRest);

    const {
        loading,
        details: { displayName, avatarSrc }
    } = useFormattedUserDetails(userId);

    return (
        <Box
            display={multiLine ? 'block' : 'flex'}
            aria-busy={loading}
            aria-label={`user-list-item-userId-${userId}`}
            {...containerClickProps}
        >
            {/* Do not display avatar on mobile views */}
            {isSmallOrLarger && (
                <StyledListItemAvatar $avatarSize={avatarSize}>
                    {loading ? (
                        <Skeleton
                            variant="circle"
                            // Skeleton likes to have its values set here rather than in CSS
                            width={
                                (avatarSize === AVATAR_SIZE_MEDIUM && AVATAR_SIZE_MEDIUM_SIZE_IN_PIXELS) ||
                                AVATAR_SIZE_LARGE_SIZE_IN_PIXELS // large is default
                            }
                            height={
                                (avatarSize === AVATAR_SIZE_MEDIUM && AVATAR_SIZE_MEDIUM_SIZE_IN_PIXELS) ||
                                AVATAR_SIZE_LARGE_SIZE_IN_PIXELS // large is default
                            }
                        />
                    ) : (
                        <StyledAvatar src={avatarSrc} alt={displayName} $size={avatarSize} />
                    )}
                </StyledListItemAvatar>
            )}
            <StyledListItemText $avatarSize={avatarSize}>
                {loading ? <Skeleton width="100%" height="20" /> : displayName}
            </StyledListItemText>
        </Box>
    );
};
