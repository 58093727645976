import React from 'react';

import { UploadProgress } from 'components/EditableImage/UploadProgress';
import Modal from 'components/modals';

interface UploadProgressModalProps {
    open: boolean;
    uploadProgress: number | null;
}

export const UploadProgressModal: React.FC<UploadProgressModalProps> = ({ uploadProgress, open }) => {
    return (
        <Modal showCloseIcon={false} showActionButtons={false} open={open} onClose={() => {}}>
            <UploadProgress progress={uploadProgress}>Preparing file for upload</UploadProgress>
        </Modal>
    );
};
