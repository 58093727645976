import React, { ReactNode } from 'react';

import { ComponentValues } from '@adept-at/lib-react-components';
import { Skill } from 'components/ContentContext/Interfaces';

import { WorkingSkill } from '../../builder/context/interfaces';
import { ComponentFocusableProvider } from '../../engine/mixins/focusable';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { VideoRefsProvider } from '../videoRefs/VideoRefsContext';

import Component from './Component';

interface SectionProps {
    skill: Skill | WorkingSkill;
}

const Sections: React.FC<SectionProps> = ({ skill }) => {
    const renderComponents = (components: ComponentValues[] = []): ReactNode => {
        return components.map((component, i) => <Component key={i} {...component} />);
    };

    return (
        <ComponentFocusableProvider>
            <VideoRefsProvider>
                <>
                    {renderComponents(
                        Object.entries(skill.components ?? {})
                            .sort((a, b) => a[1].order - b[1].order)
                            .map(([id, comp]) => ({ ...comp, id }))
                    )}
                </>
            </VideoRefsProvider>
        </ComponentFocusableProvider>
    );
};

export default Sections;
