export enum RequestErrorSeverity {
    Retryable,
    Fatal
}

export enum ErrorStatusCode {
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    MethodNotAllowed = 405,
    RateLimitExceeded = 429,
    InternalServerError = 500,
    NotImplemented = 501,
    BadGateway = 502,
    ServiceUnavailable = 503,
    GatewayTimeout = 504
}

export const FATAL_ERROR_CODES = [
    ErrorStatusCode.BadRequest,
    ErrorStatusCode.Forbidden,
    ErrorStatusCode.MethodNotAllowed,
    ErrorStatusCode.InternalServerError,
    ErrorStatusCode.NotImplemented,
    ErrorStatusCode.ServiceUnavailable,
    ErrorStatusCode.BadGateway
];

export class RequestError extends Error {
    readonly statusCode: ErrorStatusCode;
    readonly message: string;
    readonly severity: RequestErrorSeverity;

    constructor(statusCode: ErrorStatusCode, message: string) {
        super(message);

        Object.defineProperty(this, 'name', { value: 'RequestError' });
        Object.setPrototypeOf(this, new.target.prototype);

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, this.constructor);
        }

        this.statusCode = statusCode;
        this.message = message;

        this.severity = FATAL_ERROR_CODES.includes(statusCode)
            ? RequestErrorSeverity.Fatal
            : RequestErrorSeverity.Retryable;
    }

    isFatal(): boolean {
        return this.severity === RequestErrorSeverity.Fatal;
    }

    isRetryable(): boolean {
        return this.severity === RequestErrorSeverity.Retryable;
    }
}
