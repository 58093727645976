import { GraphQLClient } from 'graphql-request';
import gql from 'graphql-tag';
import buildGqlMutationFn from 'hooks/useGqlClient/helpers/buildGqlMutationFn';
import { MutationFunction } from 'react-query';

import { DeleteMessageResult, DeleteMessageVariables } from '.';

export const DELETE_MESSAGE = gql`
    mutation deleteEntityMessage(
        $entityId: UUIDv4!
        $entityType: MessageEntityType!
        $messageId: String!
        $parentId: String
    ) {
        deleteEntityMessage(messageId: $messageId, entityId: $entityId, entityType: $entityType, parentId: $parentId) {
            success
        }
    }
`;

const makeDeleteMessage = (client: GraphQLClient): MutationFunction<DeleteMessageResult, DeleteMessageVariables> =>
    buildGqlMutationFn(client, DELETE_MESSAGE);

export default makeDeleteMessage;
