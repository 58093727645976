import { useMemo } from 'react';

import { ClientError } from 'graphql-request';
import { useQuery, UseQueryResult, QueryKey } from 'react-query';

import useGqlClient from '../../../hooks/useGqlClient';
import { API_ACCOUNTS } from '../../../lib/ApiConstants';

import makeGetUserByAlias, { GetUserByAliasResult } from './makeGetUserByAlias';

export const buildGetUserByAliasQueryKey = (alias: string): QueryKey => ['getUserByAlias', alias];

const MINIMUM_ALIAS_LOOKUP_LENGTH = 3;

const useGetUserByAlias = (alias: string, options = {}): UseQueryResult<GetUserByAliasResult, ClientError> => {
    const { client, withQueryOptions } = useGqlClient(API_ACCOUNTS);

    const userByAliasQueryKey = useMemo(() => buildGetUserByAliasQueryKey(alias), [alias]);

    return useQuery<GetUserByAliasResult, ClientError>(
        withQueryOptions({
            ...options,
            queryKey: userByAliasQueryKey,
            queryFn: makeGetUserByAlias(client, { alias }),
            enabled: alias.length > MINIMUM_ALIAS_LOOKUP_LENGTH
        })
    );
};

export default useGetUserByAlias;
