import styled from 'styled-components';

export const TypeOption = styled.div`
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;

    &:first-child {
        margin-left: 10px;
    }

    &:last-child {
        margin-right: 10px;
    }
`;
