import { OptimistMutationContext, useOptimist } from '@adept-at/lib-react-optimist';
import { CollaboratorPermissionLevel, EntityInfo } from 'components/ContentContext/Interfaces';
import { ClientError, gql } from 'graphql-request';
import useGqlClient from 'hooks/useGqlClient';
import buildGqlMutationFn from 'hooks/useGqlClient/helpers/buildGqlMutationFn';
import { API_CONTENT } from 'lib/ApiConstants';
import { useSnackbar } from 'notistack';
import { UseMutateFunction, useMutation } from 'react-query';

const UPDATE_ENTITY_COLLABORATOR_ROLE = gql`
    mutation updateEntityCollaboratorRole($entityInfo: EntityInfoInput!, $userId: UUIDv4!, $role: CollaboratorRole!) {
        updateEntityCollaboratorRole(entityInfo: $entityInfo, userId: $userId, role: $role) {
            success
        }
    }
`;

interface UpdateSkillCollaboratorRoleResult {
    updateEntityCollaboratorRole: boolean;
}

interface UpdateSkillCollaboratorRoleProps {
    entityInfo: EntityInfo;
    userId: string;
    role: CollaboratorPermissionLevel;
}

const useUpdateEntityCollaboratorRole = ({
    onFinished
}: {
    onFinished: () => void;
}): {
    updateEntityCollaboratorRole: UseMutateFunction<
        UpdateSkillCollaboratorRoleResult,
        ClientError,
        UpdateSkillCollaboratorRoleProps,
        OptimistMutationContext
    >;
} => {
    const { enqueueSnackbar } = useSnackbar();

    const { registerUpdater, mutationOptions } = useOptimist<
        UpdateSkillCollaboratorRoleResult,
        ClientError,
        UpdateSkillCollaboratorRoleProps
    >({
        globalMutationOptions: {
            onSuccess: () => {
                onFinished();
            },
            onError: () => {
                enqueueSnackbar('An error occurred updating user.', { variant: 'error' });
            }
        }
    });

    const { client, withMutationOptions } = useGqlClient(API_CONTENT);
    const { mutate: updateEntityCollaboratorRole } = useMutation<
        UpdateSkillCollaboratorRoleResult,
        ClientError,
        UpdateSkillCollaboratorRoleProps,
        OptimistMutationContext
    >(
        buildGqlMutationFn<UpdateSkillCollaboratorRoleResult, UpdateSkillCollaboratorRoleProps>(
            client,
            UPDATE_ENTITY_COLLABORATOR_ROLE
        ),
        withMutationOptions(mutationOptions)
    );

    return {
        updateEntityCollaboratorRole
    };
};

export default useUpdateEntityCollaboratorRole;
