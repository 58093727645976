import React from 'react';

import { defaultMuiTheme } from '@adept-at/lib-react-components';
import { AboutTitle } from 'components/RootProfileCollection/AboutTitle';
import { AboutTitleSub } from 'components/RootProfileCollection/AboutTitleSub';
import { AboutInfo } from 'components/RootProfileCollection/constants';
import { useUpdateCollectionMeta } from 'components/RootProfileCollection/useUpdateCollectionMeta';
import styled from 'styled-components';
import { BREAKPOINT_600PX } from 'styles/variables';

import BrandingLabel from './BrandingLabel';

const AboutHeadingsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    ${defaultMuiTheme.breakpoints.down(BREAKPOINT_600PX)} {
        margin-left: 0;
    }
`;

const TITLE_FONT_SIZE = 3;

interface AboutHeadingsProps {
    aboutInfo: AboutInfo | null;
    collectionId: string;
    isEditMode: boolean;
    collectionIdForEditableCollectionQuery: string;
    parentQuerySlug: string;
    hideBrandingLabel?: boolean;
}

export const AboutHeadings: React.FC<AboutHeadingsProps> = ({
    aboutInfo,
    collectionId,
    isEditMode,
    collectionIdForEditableCollectionQuery,
    parentQuerySlug,
    hideBrandingLabel = false
}) => {
    const { mutate: updateTitle } = useUpdateCollectionMeta({
        collectionId: collectionIdForEditableCollectionQuery,
        parentQuerySlug
    });

    const saveTitle = (newTitle: string) => {
        if (newTitle === aboutInfo?.title || !collectionId) return;

        updateTitle({ input: { collectionId, title: newTitle } });
    };

    return (
        <AboutHeadingsContainer>
            {hideBrandingLabel ? null : <BrandingLabel />}
            <AboutTitle
                isEditMode={isEditMode}
                title={aboutInfo?.title ?? ''}
                onSave={saveTitle}
                fontSize={TITLE_FONT_SIZE}
            />
            <AboutTitleSub
                aboutInfo={aboutInfo}
                isEditMode={isEditMode}
                collectionId={collectionId}
                parentQuerySlug={parentQuerySlug}
                collectionIdForEditableCollectionQuery={collectionIdForEditableCollectionQuery}
            />
        </AboutHeadingsContainer>
    );
};
