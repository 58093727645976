import styled from 'styled-components';
import { SKILL_CONTENT_WIDTH } from 'styles/variables';

export const EditButtonsContainer = styled.div`
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    margin: 0 auto;
    max-width: ${SKILL_CONTENT_WIDTH}px;
    padding: 8px 0;

    button {
        &:first-of-type {
            margin-right: 16px;
        }
    }
`;
