import React, { useContext, useMemo } from 'react';

import { ProfileContext, ProfileType } from 'context/ProfileContext';

import { AdeptLogo } from './AdeptLogo';
import { HeaderContext } from './HeaderContext';
import { OrganizationHeaderLogo } from './OrganizationHeaderLogo';

export const HeaderLogo: React.FC = () => {
    const { currentProfile, isOrganization } = useContext(ProfileContext);
    const { currentContentOwner, isContentLoading } = useContext(HeaderContext);

    const headerAssetId = useMemo(() => {
        return currentContentOwner?.images?.logoHeader
            ? currentContentOwner.images.logoHeader
            : currentProfile?.images?.logoHeader;
    }, [currentProfile?.images?.logoHeader, currentContentOwner]);

    if (currentContentOwner?.type === ProfileType.Organization || isOrganization || isContentLoading) {
        return <OrganizationHeaderLogo logoHeaderAssetId={headerAssetId ?? null} />;
    }

    return <AdeptLogo />;
};
