import { useMemo } from 'react';

import { useProfile } from 'context/ProfileContext';
import { ClientError } from 'graphql-request';
import gql from 'graphql-tag';
import { useBuildAuthenticatedQueryFn } from 'hooks/useGqlClient/useBuildAuthenticatedQueryFn';
import { Api } from 'lib/ApiConstants';
import { QueryKey, useQuery, UseQueryResult } from 'react-query';

// @GRAPHQL Query getOrganizationGroupsForUser
const QUERY_NAME = 'getOrganizationGroupsForUser';

export const buildGetOrganizationGroupsForUserQueryKey = (type?: string, organizationId?: string): QueryKey => [
    QUERY_NAME,
    type,
    organizationId
];

export const GET_ORGANIZATION_GROUPS_FOR_USER_QUERY = gql`
    query ${QUERY_NAME}($organizationId: String!) {
        ${QUERY_NAME}(organizationId: $organizationId) {
            id
            name
            slug
            type
        }
    }
`;

export interface OrganizationGroupForUser {
    id: string;
    name: string;
    slug: string;
    type: string;
}

export interface OrganizationsGroupsForUserResult {
    getOrganizationGroupsForUser: OrganizationGroupForUser[];
}

export interface OrganizationGroupsForUserVariables {
    organizationId: string;
}

export const useGetOrganizationGroupsForUser = (
    variables: OrganizationGroupsForUserVariables
): UseQueryResult<OrganizationsGroupsForUserResult> => {
    const { isOrganization, currentProfile } = useProfile();

    const { queryFn } = useBuildAuthenticatedQueryFn<
        OrganizationsGroupsForUserResult,
        OrganizationGroupsForUserVariables
    >(GET_ORGANIZATION_GROUPS_FOR_USER_QUERY, variables, { api: Api.Accounts });

    const queryKey = useMemo(
        () => buildGetOrganizationGroupsForUserQueryKey(currentProfile?.type, currentProfile?.id),
        [currentProfile]
    );

    return useQuery<OrganizationsGroupsForUserResult, ClientError>(queryKey, { queryFn, enabled: isOrganization });
};
