import React from 'react';

import { TypeSelectorNavigation } from './Navigation';
import { TypeSelectorNavigationProvider } from './NavigationContext';
import { TypeSelectorOptions } from './TypeOptions';
import { Container } from './TypeSelector.styles';

export const TypeSelector: React.FC = () => (
    <Container>
        <TypeSelectorNavigationProvider>
            <TypeSelectorNavigation />
            <TypeSelectorOptions />
        </TypeSelectorNavigationProvider>
    </Container>
);
