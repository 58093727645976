import { useCallback, useMemo } from 'react';

import { OptimistMutationContext, useOptimist } from '@adept-at/lib-react-optimist';
import { EntityType } from 'components/ContentContext/Enums';
import {
    BasicDetailsSkillResponse,
    GetEntitiesBasicDetailsQueryResponse
} from 'components/NestedCollection/useGetEntitiesBasicDetails/getEntitiesBasicDetails';
import { ClientError } from 'graphql-request';
import gql from 'graphql-tag';
import { useBuildAuthenticatedMutationFn } from 'hooks/useGqlClient/useBuildAuthenticatedMutationFn';
import { Api } from 'lib/ApiConstants';
import { useSnackbar } from 'notistack';
import {
    QueryKey,
    QueryObserverResult,
    RefetchOptions,
    useMutation,
    UseMutationResult,
    useQueryClient
} from 'react-query';
import { setCollectionEditedInSessionStorage } from 'utils/collection';

import { buildGetEditableCollectionQueryKey, GetEditableCollectionResponse } from './useGetEditableCollection';
import {
    CollectionBasicDetail,
    GetCollectionBasicDetailsResponse
} from './useGetNestedCollections/getCollectionBasicDetails';

const queryName = 'removeCollectionItem';

const REMOVE_COLLECTION_FROM_CATALOG = gql`
    mutation ${queryName}($collectionId: UUIDv4!, $item: EntityInfoInput!) {
        ${queryName}(collectionId: $collectionId, item: $item) {
            infoVersion
            itemsVersion
        }
    }
`;

export interface RemoveCollectionResponse {
    removeCollectionItem: {
        infoVersion: number;
        itemsVersion: number;
    };
}

export interface RemoveCollectionVariables {
    collectionId: string;
    item: {
        type: EntityType;
        id: string;
    };
}

interface UseRemoveCollectionItemProps {
    isNestedCollection: boolean;
    collectionIdForEditableCollectionQuery: string;
    basicDetailsQueryKey: QueryKey;
    parentQuerySlug: string;
    refetchEditableCollection: (
        options?: RefetchOptions | undefined
    ) => Promise<QueryObserverResult<GetEditableCollectionResponse, ClientError>>;
}

export const useRemoveCollectionItem = ({
    isNestedCollection,
    collectionIdForEditableCollectionQuery,
    basicDetailsQueryKey,
    refetchEditableCollection,
    parentQuerySlug
}: UseRemoveCollectionItemProps): UseMutationResult<
    RemoveCollectionResponse,
    ClientError,
    RemoveCollectionVariables,
    OptimistMutationContext
> => {
    const { registerUpdater, mutationOptions } = useOptimist<
        RemoveCollectionResponse,
        ClientError,
        RemoveCollectionVariables
    >();

    const { enqueueSnackbar } = useSnackbar();

    const queryClient = useQueryClient();

    const collectionQueryKey = useMemo(
        () => buildGetEditableCollectionQueryKey(collectionIdForEditableCollectionQuery),
        [collectionIdForEditableCollectionQuery]
    );

    const updateEditableCollection = useCallback(
        (data: RemoveCollectionResponse) => {
            enqueueSnackbar('Successfully removed collection', { variant: 'success' });

            queryClient.setQueryData<GetEditableCollectionResponse>(collectionQueryKey, (previous) => {
                if (!previous) return { getEditableCollection: {} } as GetEditableCollectionResponse;

                return {
                    getEditableCollection: {
                        ...previous?.getEditableCollection,
                        itemsVersion: data.removeCollectionItem.itemsVersion,
                        infoVersion: data.removeCollectionItem.infoVersion
                    }
                };
            });

            // keep children of parent collection in sync
            refetchEditableCollection();
        },
        [collectionQueryKey, queryClient, refetchEditableCollection, enqueueSnackbar]
    );

    if (!isNestedCollection) {
        // update Catalog/Internal
        registerUpdater<GetCollectionBasicDetailsResponse>(
            basicDetailsQueryKey,
            (previous, { item: { id } }) => {
                const previousCollectionItems = previous?.getCollectionBasicDetails ?? [];

                const updatedItems = previousCollectionItems.filter(
                    (collectionItem) => collectionItem.collectionId !== id
                );

                return {
                    getCollectionBasicDetails: updatedItems
                };
            },
            {
                onError: () => {
                    enqueueSnackbar('An error occurred while removing collection', { variant: 'error' });
                },
                onSuccess: (data, request) => {
                    setCollectionEditedInSessionStorage(request.collectionId, parentQuerySlug);
                    updateEditableCollection(data);
                },
                refetchQuery: false
            }
        );
    } else {
        // update NestedCollection
        registerUpdater<GetEntitiesBasicDetailsQueryResponse>(
            basicDetailsQueryKey,
            (previous, { item: { type, id } }) => {
                const previousCollectionItems = previous?.getEntityBasicDetails ?? [];
                let updatedItems: (BasicDetailsSkillResponse | CollectionBasicDetail)[] = [];

                if (type === EntityType.SKILL) {
                    updatedItems = previousCollectionItems.filter(
                        (collectionItem) =>
                            (collectionItem as CollectionBasicDetail).collectionId ||
                            (collectionItem as BasicDetailsSkillResponse).skillId !== id
                    );
                }

                if (type === EntityType.COLLECTION) {
                    updatedItems = previousCollectionItems.filter(
                        (collectionItem) =>
                            (collectionItem as BasicDetailsSkillResponse).skillId ||
                            (collectionItem as CollectionBasicDetail).collectionId !== id
                    );
                }

                return {
                    getEntityBasicDetails: updatedItems
                };
            },
            {
                onError: () => {
                    enqueueSnackbar('An error occurred while removing item', { variant: 'error' });
                },
                onSuccess: (data, request) => {
                    setCollectionEditedInSessionStorage(request.collectionId, parentQuerySlug);
                    updateEditableCollection(data);
                },
                refetchQuery: false
            }
        );
    }

    const { mutationFn } = useBuildAuthenticatedMutationFn<RemoveCollectionResponse, RemoveCollectionVariables>(
        REMOVE_COLLECTION_FROM_CATALOG,
        { api: Api.Content }
    );

    return useMutation<RemoveCollectionResponse, ClientError, RemoveCollectionVariables, OptimistMutationContext>(
        mutationFn,
        mutationOptions
    );
};
