import { useCallback } from 'react';

import useBulkFetcherQueue from 'hooks/useBulkFetcherQueue';
import ms from 'ms';

import { WorkingAvatarDetailsResult, AvatarDetails } from '..';
import { GetAvatarsResponse, AvatarResponse, useGetAvatarsPromisfiedFetch } from '../useGetAvatars';

interface UseAvatarFetchingOptions {
    avatarsMapByAssetId: Map<string, AvatarDetails>;
    saveAvatars: (avatar: AvatarResponse[]) => Promise<AvatarDetails[]>;
}

interface UseAvatarFetchingOutput {
    getAvatarsFromMap: (assetIds: string[]) => WorkingAvatarDetailsResult;
    fetchAvatars: (assetIds: string[]) => void;
    isLoading: boolean;
}

const AVATARS_UPDATE_FREQENCY_IN_MS = ms('1 day');

const useAvatarFetching = ({ avatarsMapByAssetId, saveAvatars }: UseAvatarFetchingOptions): UseAvatarFetchingOutput => {
    const { fetch: promisfiedFetch } = useGetAvatarsPromisfiedFetch();

    const fetcher = useCallback((assetIds: string[]) => promisfiedFetch({ assetIds }), [promisfiedFetch]);

    const onSuccess = useCallback(
        async (result: GetAvatarsResponse): Promise<void> => {
            const newAvatars = result?.getAvatars;
            if (newAvatars) {
                await saveAvatars(newAvatars);
            }
        },
        [saveAvatars]
    );

    const { addToQueue, isLoading } = useBulkFetcherQueue({
        fetcher,
        onSuccess,
        ttlInMs: AVATARS_UPDATE_FREQENCY_IN_MS
    });

    const getAvatarsFromMap = useCallback(
        (assetIds: string[]): WorkingAvatarDetailsResult => {
            const avatars = {} as Record<string, AvatarDetails>;

            for (const assetId of assetIds) {
                const result = avatarsMapByAssetId.get(assetId);
                if (result) {
                    avatars[assetId] = result;
                }
            }

            return { avatars };
        },
        [avatarsMapByAssetId]
    );

    const fetchAvatars = useCallback(
        (assetIds: string[]): void => {
            const contextRemainingIds = assetIds.filter((assetId) => !avatarsMapByAssetId.get(assetId)?.url);
            if (contextRemainingIds.length === 0) {
                return;
            }

            if (!isLoading && contextRemainingIds.length > 0) {
                addToQueue(contextRemainingIds);
            }
        },
        [addToQueue, avatarsMapByAssetId, isLoading]
    );

    return { getAvatarsFromMap, fetchAvatars, isLoading };
};

export default useAvatarFetching;
