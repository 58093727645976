import { useMemo, useRef } from 'react';

import { EntityType } from 'components/ContentContext/Enums';
import { OwnerType } from 'components/ContentContext/Interfaces';
import { AuthStatus, useAuth } from 'context/AuthContext';
import { ClientError } from 'graphql-request';
import { OrganizationActions, useIsAuthorizedOwnerAction } from 'hooks/authorization';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { ignoreFeatureFlags } from 'lib/constants';
import { QueryKey, RefetchOptions, QueryObserverResult, QueryStatus } from 'react-query';

import { useGetCurrentUser, GetCurrentUserResponse, CurrentUser } from './useGetCurrentUser';

export interface UseCurrentUserInput {
    token?: string | null;
}

export interface UserAuthChecks {
    canCreateOrg: boolean;
}

export interface UseCurrentUserOutput {
    fetchCurrentUser: (
        options?: RefetchOptions | undefined
    ) => Promise<QueryObserverResult<GetCurrentUserResponse, ClientError>>;
    currentUser?: CurrentUser | null;
    authChecks: UserAuthChecks | null;
    status: QueryStatus;
}

export const buildCurrentUserQueryKey = (token: string | null): QueryKey => ['getCurrentUser', token];

export const formatCurrentUser = (response?: GetCurrentUserResponse): CurrentUser | null => {
    if (!response?.getCurrentUser) {
        return null;
    }

    const { id: userId, alias, image, name, emails } = response.getCurrentUser;

    const builtName = [name?.first, name?.last].filter(Boolean).join(' ');

    return {
        userId,
        alias,
        emails,
        firstName: name?.first,
        lastName: name?.last,
        name: !!builtName ? builtName : alias,
        image
    };
};

export const useCurrentUser = (): UseCurrentUserOutput => {
    const { status: authStatus, isAuthenticated: authenticated } = useAuth();

    const ldClient = useLDClient();

    const prevGetCurrentUserData = useRef<GetCurrentUserResponse | undefined>(undefined);

    const {
        data,
        status,
        refetch: fetchCurrentUser
    } = useGetCurrentUser({
        enabled: authenticated,

        onSuccess: async (data) => {
            try {
                const primaryEmail =
                    data?.getCurrentUser?.emails.find((email) => email.primary === true) ||
                    data?.getCurrentUser?.emails[0];

                if (ldClient && !ignoreFeatureFlags) {
                    await ldClient.identify({
                        key: data?.getCurrentUser?.id,
                        email: primaryEmail.address
                    });
                }
            } catch (e) {
                console.error(e);
            }
        }
    });

    const currentUser = useMemo(() => {
        /**
         * We wish to stick with the currentUser until we have proven that they are not who they are (logout/authorization
         * error). Due to this, we hold onto our last currentUser and return that if data is null but previously wasn't.
         */
        if (!data && authStatus !== AuthStatus.Unauthenticated) {
            return formatCurrentUser(prevGetCurrentUserData.current);
        }

        prevGetCurrentUserData.current = data;
        return formatCurrentUser(data);
    }, [data, authStatus]);

    const { isAuthorized: createOrgCheck } = useIsAuthorizedOwnerAction(
        currentUser?.userId ? { type: OwnerType.User, id: currentUser.userId } : null,
        EntityType.Organization,
        OrganizationActions.Create
    );

    const authChecks: UserAuthChecks = useMemo(() => {
        return {
            canCreateOrg: createOrgCheck
        };
    }, [createOrgCheck]);

    return { fetchCurrentUser, currentUser, status, authChecks };
};
