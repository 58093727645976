import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';

import { useAvatars } from 'context/AvatarsContext/useAvatars';
import { ClientError } from 'graphql-request';
import { useCurrentUser } from 'hooks/useCurrentUser';
import useGqlClient from 'hooks/useGqlClient';
import buildGqlMutationFn from 'hooks/useGqlClient/helpers/buildGqlMutationFn';
import useUsersDetails from 'hooks/useUserDetails';
import { API_CONTENT } from 'lib/ApiConstants';
import { useMutation } from 'react-query';

import {
    AddCommentResult,
    AddCommentVariables,
    Comment,
    CommentAccess,
    RemoveCommentResult,
    RemoveCommentVariables,
    Section,
    UpdateCommentResult,
    UpdateCommentVariables
} from './interfaces';
import { ADD_COMMENT, REMOVE_COMMENT, UPDATE_COMMENT } from './queries';

export enum CommentContextType {
    skill = 'skill'
}

export interface CommentContextInterface {
    contextType: CommentContextType;
    setContextType: (type: CommentContextType) => void;

    contextAccessType: CommentAccess;
    setContextAccessType: (type: CommentAccess) => void;

    isThreadView: boolean;
    threadParentComment: Comment | null;

    addComment: (body: string) => void;
    deleteComment: (comment: Comment) => void;
    updateComment: (comment: Comment, newBody: string) => void;

    currentComments: Comment[];
    commentSections: Section[];

    showThread: (parent: string) => void;
    showComments: (parent: string) => void;
    allComments: Comment[];

    parent: string;
    setParent: (parent: string) => void;

    activeSection: string | null;
    setActiveSection: (activeSection: string | null) => void;

    isLoading: boolean;
}

const CommentContext = createContext(undefined as unknown as CommentContextInterface);

const { Provider, Consumer } = CommentContext;

interface CommentContextProps {
    type: string;
    id: string;
    access: CommentAccess;
    comments: {
        private: Comment[];
        public: Comment[];
    };
    sections: Section[];
}

const CommentProvider: React.FC<CommentContextProps> = ({ type, id, access, comments, sections, children }) => {
    const userIds = useMemo(() => {
        const ids: string[] = [];

        if (comments && comments.private) {
            comments.private.forEach((i) => ids.push(i.createdBy));
        }

        if (comments && comments.public) {
            comments.public.forEach((i) => ids.push(i.createdBy));
        }

        return ids;
    }, [comments]);

    const mergedComments = useMemo(() => {
        const merged: Comment[] = [];

        if (comments && comments.private) {
            comments.private.forEach((comment) => merged.push({ ...comment, access: CommentAccess.Private }));
        }

        if (comments && comments.public) {
            comments.public.forEach((comment) => merged.push({ ...comment, access: CommentAccess.Public }));
        }

        return merged;
    }, [comments]);

    const { loading: userDetailsLoading, usersDetails } = useUsersDetails({ userIds });

    const { client, withMutationOptions } = useGqlClient(API_CONTENT);
    const { currentUser } = useCurrentUser();
    const { userId } = currentUser ?? {};

    const [isLoading, setIsLoading] = useState(true);
    const [contextType, setContextType] = useState<CommentContextType>(CommentContextType[type]);
    const [contextAccessType, setContextAccessType] = useState<CommentAccess>(access);
    const [contextId] = useState<string>(id);

    const [commentSections, setCommentSections] = useState<Section[]>(sections);
    const [activeSection, setActiveSection] = useState<string | null>(null);

    const [parent, setParent] = useState<string>(id);
    const [allComments, setAllComments] = useState<Comment[]>(mergedComments);
    const [currentComments, setCurrentComments] = useState<Comment[]>([]);

    const [isThreadView, setIsThreadView] = useState<boolean>(false);
    const [threadParentComment, setThreadParentComment] = useState<Comment | null>(null);

    const { avatars } = useAvatars({ assetIds: currentUser?.image ? [currentUser?.image] : [] });
    const getAvatarImage = () => {
        if (!(currentUser?.image && avatars[currentUser?.image])) {
            return;
        }

        return avatars[currentUser.image].crop ?? avatars[currentUser.image].image ?? undefined;
    };

    useEffect(() => {
        if (!userDetailsLoading) {
            setAllComments((prev) =>
                prev.map((comment) => ({
                    ...comment,
                    alias: usersDetails[comment.createdBy]?.alias,
                    image: usersDetails[comment.createdBy]?.image,
                    imageUrl: usersDetails[comment.createdBy]?.imageUrl
                }))
            );

            setIsLoading(false);
        }
    }, [userDetailsLoading, usersDetails]);

    useEffect(() => {
        setCommentSections(sections);
    }, [sections]);

    useEffect(() => {
        setContextAccessType(access);
    }, [access]);

    const getComments = useCallback(
        (parent: string): Comment[] => {
            const comments: Comment[] = [];

            for (let i = 0; i < allComments.length; i++) {
                if (allComments[i].parent === parent && allComments[i].access === contextAccessType) {
                    comments.push(allComments[i]);
                }
            }

            return comments;
        },
        [allComments, contextAccessType]
    );

    useEffect(() => {
        if (parent) {
            setCurrentComments(getComments(parent));
        }
    }, [parent, allComments, contextAccessType, getComments]);

    const onCreate = (data, variables) => {
        const comment: Comment = {
            ...data.createComment,
            access: contextAccessType,
            alias: currentUser?.alias,
            imageUrl: getAvatarImage()
        };

        setAllComments((prev) => {
            const next = prev.slice(0);

            // Update the fake comment with the real comment ID
            const pendingIndex = next.findIndex((i) => i.commentId === variables.pendingId);

            if (!pendingIndex) return next;
            next[pendingIndex] = comment;

            // Update the parent thread if it exists with the real comment ID
            const parentIndex = next.findIndex((i) => i.commentId === variables.parent);
            if (parentIndex < 0) return next;

            next[parentIndex] = {
                ...next[parentIndex],
                thread: [...next[parentIndex].thread, data.commentId]
            };

            return next;
        });
    };

    const onDelete = (data, variables) => {
        //
    };

    const onUpdate = (data, variables) => {
        //
    };

    const { mutate: addCommentMutation } = useMutation<AddCommentResult, ClientError, AddCommentVariables>(
        buildGqlMutationFn<AddCommentResult, AddCommentVariables>(client, ADD_COMMENT),
        withMutationOptions({
            onSuccess: onCreate
        })
    );

    const { mutate: deleteCommentMutation } = useMutation<RemoveCommentResult, ClientError, RemoveCommentVariables>(
        buildGqlMutationFn<RemoveCommentResult, RemoveCommentVariables>(client, REMOVE_COMMENT),
        withMutationOptions({
            onSuccess: onDelete
        })
    );

    const { mutate: updateCommentMutation } = useMutation<UpdateCommentResult, ClientError, UpdateCommentVariables>(
        buildGqlMutationFn<UpdateCommentResult, UpdateCommentVariables>(client, UPDATE_COMMENT),
        withMutationOptions({
            onSuccess: onUpdate
        })
    );

    const addComment = (body: string) => {
        if (!body || body === '' || !userId) return;

        const pendingId = `pending-${Math.random().toString(36).substr(2, 9)}`;

        const newComment: Comment = {
            body,
            createdBy: userId,
            parent: parent,
            commentId: pendingId,
            createdAt: new Date().toISOString(),
            editedAt: null,
            deletedAt: null,
            alias: currentUser?.alias,
            imageUrl: getAvatarImage(),
            thread: [],
            access: contextAccessType
        };

        setAllComments((prev) => [...prev, newComment]);

        addCommentMutation({
            contextId,
            type: contextType,
            body,
            access: contextAccessType,
            parent: parent,
            pendingId
        });
    };

    const deleteComment = (comment: Comment) => {
        const { commentId } = comment;

        const newComment: Comment = {
            ...comment,
            body: '[deleted]',
            deletedAt: new Date().toISOString()
        };

        setAllComments((prev) => {
            const next = prev.slice(0);

            const index = prev.findIndex((i) => i.commentId === commentId);
            next[index] = newComment;

            return next;
        });

        deleteCommentMutation({
            commentId,
            contextId,
            type: contextType,
            access: contextAccessType
        });
    };

    const updateComment = (comment: Comment, newBody: string) => {
        if (!newBody || newBody === '' || !userId) return;

        const { commentId } = comment;

        const newComment: Comment = {
            ...comment,
            body: newBody,
            editedAt: new Date().toISOString()
        };

        setAllComments((prev) => {
            const next = prev.slice(0);

            const index = prev.findIndex((i) => i.commentId === commentId);
            next[index] = newComment;

            return next;
        });

        updateCommentMutation({
            commentId,
            contextId,
            type: contextType,
            body: newBody,
            access: contextAccessType
        });
    };

    const showComments = (parent) => {
        setParent(parent);
        setCurrentComments(getComments(parent));

        const parentComment = allComments.find((i) => i.commentId === parent);

        if (!parentComment) {
            setIsThreadView(false);
            setThreadParentComment(null);
            return;
        }

        setIsThreadView(true);
        setThreadParentComment(parentComment);
    };

    const showThread = (parent) => {
        const threadParent = allComments.find((i) => i.commentId === parent);

        if (!threadParent) {
            return setIsThreadView(false);
        }

        setCurrentComments(getComments(parent));

        setParent(parent);
        setIsThreadView(true);
        setThreadParentComment(threadParent);
    };

    return (
        <Provider
            value={{
                contextType,
                setContextType,

                contextAccessType,
                setContextAccessType,

                isThreadView,
                threadParentComment,

                addComment,
                deleteComment,
                updateComment,

                showThread,
                showComments,
                allComments,

                currentComments,
                commentSections,

                parent,
                setParent,

                activeSection,
                setActiveSection,

                isLoading
            }}
        >
            {children}
        </Provider>
    );
};

export { CommentContext, CommentProvider, Consumer as CommentConsumer };
