import { useEffect, useCallback, useRef, useMemo } from 'react';

import { useCollector } from 'components/devtools/Collector';

import { useStorage } from './useStorage';
import { TokenWithRefreshStatus, useTokenWithRefresh } from './useTokenWithRefresh';

/**
 * Scenario #1: Successful login via login form:
 *
 * Initial -> None -> Initializing -> Ready
 *
 * Scenario #2: Page load for user with an available token/session:
 *
 * Initial -> Restoring -> Ready
 *
 */
export enum SessionStatus {
    Initial = 'Initial', // Initial state, no idea whether or not we have a session
    Initializing = 'Initializing',
    Restoring = 'Restoring', // Restore (rehydrate) session that we think we have in hand
    Ready = 'Ready', // Session is ready for use
    Refreshing = 'Refreshing', // Session expired, refreshing
    None = 'None', // No session. A final state.
    Error = 'Error'
}

interface UseSessionInterface {
    status: SessionStatus;
    refresh: () => void;
    reset: () => void;
    token: string | null;
    initialize: () => void;
}

export const useSession = (): UseSessionInterface => {
    const { available: awarenessCookieIsSet, reset: resetAuthStorage, initialize: initializeStorage } = useStorage();

    const { addHistory } = useCollector();

    const previousStatus = useRef<SessionStatus>(SessionStatus.Initial);

    const {
        token,
        retrieveToken,
        status: tokenStatus,
        reset: resetTokenAndRefresh,
        refresh: refreshToken
    } = useTokenWithRefresh();

    const status = useMemo(() => {
        const determineNewStatus = () => {
            if (tokenStatus === TokenWithRefreshStatus.Ready) {
                return SessionStatus.Ready;
            }

            if (tokenStatus === TokenWithRefreshStatus.None) {
                return SessionStatus.None;
            }

            /**
             * Any one of these statuses in conjunction with the presence of the Auth Awareness cookie identify that we
             * are setting up (restoring) or refreshing a previous session.
             */
            const settingUpWithRefresh =
                [
                    TokenWithRefreshStatus.RefreshingSession,
                    TokenWithRefreshStatus.RetrievingToken,
                    TokenWithRefreshStatus.StartingTokenRetrievalFollowingRefresh
                ].includes(tokenStatus) && awarenessCookieIsSet;

            // If the session was previously Ready and we are Refreshing the token/session, consider as a Refreshing
            if (settingUpWithRefresh && previousStatus.current === SessionStatus.Ready) {
                return SessionStatus.Refreshing;
            }

            // Can only transition to Restoring from Initial
            if (settingUpWithRefresh && previousStatus.current === SessionStatus.Initial) {
                return SessionStatus.Restoring;
            }

            // Can only transition to Initializing from Initial/None
            if (
                tokenStatus === TokenWithRefreshStatus.RetrievingToken &&
                [SessionStatus.Initial, SessionStatus.None].includes(previousStatus.current)
            ) {
                return SessionStatus.Initializing;
            }

            // If client is not aware of a session, we assume there is not one.
            if (!awarenessCookieIsSet) {
                return SessionStatus.None;
            }

            // Transition to Refreshing if we are not in a Restoring status
            if (
                tokenStatus === TokenWithRefreshStatus.RefreshingSession &&
                previousStatus.current !== SessionStatus.Restoring
            ) {
                return SessionStatus.Refreshing;
            }

            // No state transition identified, return the current value
            return previousStatus.current;
        };

        previousStatus.current = determineNewStatus();

        return previousStatus.current;
    }, [awarenessCookieIsSet, tokenStatus]);

    useEffect(() => {
        addHistory('sessionStatus', status);
    }, [status, addHistory]);

    useEffect(() => {
        // Look at session awareness cookie to determine if we should attempt to refreshSession right away
        if (
            awarenessCookieIsSet &&
            status === SessionStatus.Initial &&
            tokenStatus === TokenWithRefreshStatus.Initial
        ) {
            retrieveToken();
        }
    }, [retrieveToken, awarenessCookieIsSet, tokenStatus, status]);

    /**
     * Key off a tokenStatus of None to clear out the awareness cookie if we are, as it currently stands, unable to
     * acquire a token for this session.
     */
    useEffect(() => {
        if (awarenessCookieIsSet && status === SessionStatus.None && tokenStatus === TokenWithRefreshStatus.None) {
            resetAuthStorage();
        }
    }, [status, tokenStatus, awarenessCookieIsSet, resetAuthStorage]);

    /**
     * If we reach a ready status, we can initialize the storage. This is safe to do each time because it is an
     * idempotent operation.
     */
    useEffect(() => {
        if (status === SessionStatus.Ready) {
            initializeStorage();
        }
    }, [status, initializeStorage]);

    const reset = useCallback(() => {
        resetAuthStorage();
        resetTokenAndRefresh();
    }, [resetAuthStorage, resetTokenAndRefresh]);

    const initialize = useCallback(() => {
        reset();
        retrieveToken();
    }, [retrieveToken, reset]);

    return { initialize, status, refresh: refreshToken, reset, token };
};
