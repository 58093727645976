import { TableCell } from '@material-ui/core';
import styled from 'styled-components';

export const ROW_HEIGHT = 74;

export const StyledTableCell = styled(TableCell)<{ $flexGrow?: boolean }>`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    ${(props) =>
        props.width
            ? `
    ${props.$flexGrow ? 'flex-grow: 1' : `max-width: ${props.width}px`};
    min-width: ${props.width}px;

    > div {
        max-width: 100%;
    }
    `
            : null}
`;

export const StickyTableCell = styled(StyledTableCell)<{
    $borderRight?: boolean;
    $borderLeft?: boolean;
    left?: number;
    right?: string;
}>`
    position: sticky;
    z-index: 5;
    background-color: ${(props) => props.theme.colors.surface};

    p {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    ${(props) => (props.$borderLeft ? `border-left: 1px solid ${props.theme.colors.border};` : null)}
    ${(props) => (props.$borderRight ? `border-right: 1px solid ${props.theme.colors.border};` : null)}

    ${(props) => (props.left !== undefined ? `left: ${props.left}px;` : null)}
    ${(props) => (props.right !== undefined ? `right: ${props.right};` : null)}
`;
