export enum ProductEntityType {
    SKILL = 'skill',
    PACKAGE = 'package',
    FEATURE = 'feature'
}

export enum EntityType {
    SKILL = 'skill',
    COLLECTION = 'collection',
    Organization = 'organization', // orgs are entitys as well as owners.
    QUESTION_BANK = 'question_bank',
    Assessment = 'assessment',
    Skill = 'skill',
    Collection = 'collection',
    VIRTUAL_LAB = 'virtual_lab',
    TENANT_PROFILE = 'tenant_profile',
    LIVE_LEARNING_SESSION = 'LIVE_LEARNING_SESSION'
}

export enum RuleTrigger {
    SKILL_STEP_CHANGED = 'SKILL_STEP_CHANGED'
}

export enum RuleAction {
    ASSIGN = 'ASSIGN',
    SEND_MESSAGE_TO_USER = 'SEND_MESSAGE_TO_USER',
    SEND_MESSAGE_TO_GROUP = 'SEND_MESSAGE_TO_GROUP',
    PUBLISH = 'PUBLISH'
}

export enum SortDirection {
    ASC = 'asc',
    DESC = 'desc'
}

export enum SectionId {
    OVERVIEW = 'component-overview',
    CONCLUSION = 'component-conclusion'
}
