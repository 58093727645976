import React from 'react';

import { useCroppedImage } from '@adept-at/lib-react-components';

import { ImageWrapper } from './Preview.styles';

import { ImageComponentImage } from './';

interface ImagePreviewProps {
    image: ImageComponentImage;
}

const ImagePreview: React.FC<ImagePreviewProps> = ({ image }) => {
    const {
        url,
        crop,
        dimensions: { width, height }
    } = image;

    const { imageSrc } = useCroppedImage(url, crop, { placeholderImageSrc: url });

    return (
        <ImageWrapper width={width} height={height}>
            {imageSrc && <img src={imageSrc} alt="error" />}
        </ImageWrapper>
    );
};

export default ImagePreview;
