import { useCallback } from 'react';

import { cropImage } from '@adept-at/lib-react-components';

import { AvatarDetails } from '..';
import { AvatarResponse } from '../useGetAvatars';

interface UseAvatarSavingOptions {
    setAvatarsMapByAssetId: React.Dispatch<React.SetStateAction<Map<string, AvatarDetails>>>;
}

interface UseAvatarSavingOutput {
    saveAvatars: (avatars: AvatarResponse[]) => Promise<AvatarDetails[]>;
}

const cropAvatar = async (avatar: AvatarResponse): Promise<AvatarDetails> => {
    if (avatar?.original?.url && avatar?.crop) {
        const croppedImage = await cropImage(avatar.original.url, avatar.crop);
        if (croppedImage) {
            return {
                id: avatar?.id,
                url: avatar.original.url,
                crop: croppedImage,
                image: avatar.original.url
            };
        }
    }

    return { id: avatar?.id, url: avatar.original.url, image: avatar.original.url };
};

const cropAllAvatars = async (avatars: AvatarResponse[]): Promise<AvatarDetails[]> => {
    const promises = avatars.map((avatar) => cropAvatar(avatar));

    return await Promise.all(promises);
};

const useAvatarSaving = ({ setAvatarsMapByAssetId }: UseAvatarSavingOptions): UseAvatarSavingOutput => {
    const bulkSaveAvatarsToMap = useCallback(
        (avatarsDetails: AvatarDetails[]): void => {
            setAvatarsMapByAssetId(
                (prevAvatarsMapByAssetId) =>
                    new Map<string, AvatarDetails>([
                        ...prevAvatarsMapByAssetId.entries(),
                        ...avatarsDetails.map(
                            (avatarDetails) => [avatarDetails.id, avatarDetails] as [string, AvatarDetails]
                        )
                    ])
            );
        },
        [setAvatarsMapByAssetId]
    );

    const saveAvatars = useCallback(
        async (avatars: AvatarResponse[]): Promise<AvatarDetails[]> => {
            const avatarsDetails = await cropAllAvatars(avatars);

            bulkSaveAvatarsToMap(avatarsDetails);

            return avatarsDetails;
        },
        [bulkSaveAvatarsToMap]
    );

    return { saveAvatars };
};

export default useAvatarSaving;
