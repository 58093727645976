import React, { useCallback, useContext, useMemo } from 'react';

import { TooltippedIconButton } from '@adept-at/lib-react-components';
import { mdiBookOpenOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { EntityType } from 'components/ContentContext/Enums';
import { useHistory } from 'react-router';

import { SidebarContext } from '../../SidebarContext';
import CollectionBreadcrumbMenu from '../CollectionBreadcrumbMenu';
import { LearnContext } from '../LearnContext';
import { getEntityProgressStatus } from '../modes/accountability/utils';
import ProgressSpan from '../ProgressSpan';

import { Divider, Controls } from './index.styles';

export const LearningHeaderContent: React.FC = () => {
    const history = useHistory();
    const { skill, tenantSlug } = useContext(LearnContext);

    const { isOpen, openSidebar } = useContext(SidebarContext);

    const currentProgress = useMemo(() => getEntityProgressStatus(skill), [skill]);

    const onItemSelect = useCallback(
        (trailingUrl, type) =>
            history.push({
                pathname: `${type === EntityType.SKILL ? '/learn' : ''}/${tenantSlug}/${trailingUrl}`,
                state: history.location?.state /* persist previous state */
            }),
        [history, tenantSlug]
    );

    return (
        <>
            <CollectionBreadcrumbMenu onItemSelect={onItemSelect} />
            <Controls>
                <ProgressSpan state={currentProgress} />
                {!isOpen && (
                    <>
                        <Divider />
                        <TooltippedIconButton
                            title="Open skill panel"
                            aria-label="skill-panel-control"
                            icon={<Icon path={mdiBookOpenOutline} />}
                            onClick={() => openSidebar({ isOpen: true, savePreference: true })}
                        />
                    </>
                )}
            </Controls>
        </>
    );
};
