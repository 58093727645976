import { useCallback } from 'react';

import { LinkComponentValues, LinkType } from '@adept-at/lib-react-components';
import { EntityInfo } from 'components/ContentContext/Interfaces';

import { useCreateLinkLaunchUrl } from './useCreateLinkLaunchUrl';

export const useGenerateLinkOnClick = (link: LinkComponentValues, container: EntityInfo): (() => void) | undefined => {
    const { mutateAsync } = useCreateLinkLaunchUrl();

    const linkOnClick = useCallback(async () => {
        switch (link.linkType) {
            case LinkType.EXAM: {
                if (!link.externalId) return;

                const launchUrlResponse = await mutateAsync({ externalId: link.externalId, entity: container });

                window.open(`${launchUrlResponse.launchLink.linkUrl}`, '_blank');

                return;
            }

            case LinkType.LAB: {
                window.open(`/lab/link/${container.type}/${container.id}/${link.externalId}`, '_blank');
                return;
            }

            case LinkType.META: {
                window.open(`${link.url}`, '_blank');
                return;
            }
        }
        return;
    }, [container, link.url, link.linkType, link.externalId, mutateAsync]);

    //REMOVE BEFORE FLIGHT (or after content api exam stuff is alive and swell)
    if (link.linkType === LinkType.EXAM) {
        return undefined;
    }

    return linkOnClick;
};
