import { GraphQLClient, gql } from 'graphql-request';
import buildGqlQueryFn from 'hooks/useGqlClient/helpers/buildGqlQueryFn';
import MessageRecord, { MessageEntityType } from 'lib/communication/message/MessageRecord';
import { QueryFunction, QueryKey } from 'react-query';

/** In order to recreate the results from the queryCache, start here and keep using the cursor to continue */
const FIRST_PAGE_FROM_MESSAGE_ID_KEY = 'first';

export const buildGetMessagesForChannelQueryKey = (
    entityType: MessageEntityType,
    entityId: string,
    fromMessageId?: string
): QueryKey => ['getEntityMessages', entityType, entityId, fromMessageId ?? FIRST_PAGE_FROM_MESSAGE_ID_KEY];

export const messageFragment = gql`
    id
    channelId
    entityId
    entityType
    senderId
    sentAt
    deletedAt
    type
    version
    destinations
    fields
    hasValidAck
    validAckTypes
    acks {
        type
        at
    }
    reactions
    parent
    responses
    responders
`;

const GET_MESSAGES_FOR_CHANNEL_QUERY = gql`
    query getEntityMessages($entityType: MessageEntityType, $entityId: UUIDv4!, $fromMessageId: String) {
        getEntityMessages(entityType: $entityType, entityId: $entityId, fromMessageId: $fromMessageId) {
            messages {
                ${messageFragment}
            }
            cursor
        }
    }
`;

export interface MessagesForChannelResult {
    getEntityMessages: {
        messages: MessageRecord[];
        cursor: string | null;
    };
}

interface MessagesForChannelRequest {
    entityType: MessageEntityType;
    entityId: string;
    fromMessageId?: string;
}

const getMessagesForChannel = (
    client: GraphQLClient,
    variables: MessagesForChannelRequest
): QueryFunction<MessagesForChannelResult> =>
    buildGqlQueryFn<MessagesForChannelResult, MessagesForChannelRequest>(
        client,
        GET_MESSAGES_FOR_CHANNEL_QUERY,
        variables
    );

export default getMessagesForChannel;
