import React, { useMemo } from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { Button, CircularProgress } from '@material-ui/core';
import { mdiLocationEnter } from '@mdi/js';
import { Icon } from '@mdi/react';
import styled from 'styled-components';

const StyledPriceButton = styled(Button)<{ $isEditMode?: boolean }>`
    color: ${(props) => (props.$isEditMode ? props.theme.colors.textSecondary : props.theme.colors.primary)};
    border-color: ${(props) => (props.$isEditMode ? props.theme.colors.secondary : 'transparent')};
    margin-right: 0.5rem;

    &&& {
        padding: 6px 15px;
        width: max-content;
    }
`;

interface PriceButtonProps {
    onClick: () => void;
    disabled?: boolean;
    productInProgress?: boolean;
    productExists?: boolean;
    isVisible?: boolean;
    isEditMode?: boolean;
}

export const PriceButton: React.FC<PriceButtonProps> = ({
    onClick,
    disabled,
    productInProgress,
    productExists,
    isVisible,
    isEditMode
}) => {
    const visibilityText = useMemo(() => {
        if (isVisible) return 'Visible |';
        return 'Hidden |';
    }, [isVisible]);

    const buttonPriceText = useMemo(() => {
        if (productExists) return 'View Product Details';
        return '$ Create Price';
    }, [productExists]);

    return (
        <StyledPriceButton
            $isEditMode={isEditMode}
            disabled={disabled || productInProgress}
            onClick={onClick}
            variant={isEditMode ? 'outlined' : 'text'}
            startIcon={
                productInProgress ? (
                    <CircularProgress size={IconSize.Small} color={'inherit'} />
                ) : (
                    <Icon path={mdiLocationEnter} size={IconSize.Small} />
                )
            }
        >
            {productInProgress ? 'Loading Product Details' : `${visibilityText} ${buttonPriceText}`}
        </StyledPriceButton>
    );
};
