import { useState } from 'react';

import { hasSession, clearSession, setSession } from './storage';

interface UseStorageInterface {
    available: boolean;
    setAvailable: (available: boolean) => void;
    initialize: () => void;
    reset: () => void;
}

export const useStorage = (): UseStorageInterface => {
    const [available, setAvailable] = useState<boolean>(() => hasSession());

    return {
        available,

        setAvailable,

        /**
         * Notice: Everything within initialize should be idempotent.
         */
        initialize: () => {
            setSession();
            setAvailable(true);
        },

        reset: () => {
            clearSession();
            setAvailable(false);
        }
    };
};
