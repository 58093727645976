import React from 'react';

import { Typography } from '@material-ui/core';
import styled from 'styled-components';

import { modularScale } from '../../utils/modular_scale';

// Courtesy of: https://stackoverflow.com/q/10420352
const getReadableFileSizeString = (fileSizeInBytes: number | null | undefined): string => {
    if (!fileSizeInBytes) {
        return '';
    }

    let i = -1;
    const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
    do {
        fileSizeInBytes = fileSizeInBytes / 1024;
        i++;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
};

export const StyledText = styled(Typography)`
    font-size: ${modularScale(-1)};
    color: ${(props) => props.theme.colors.textTertiary};
    margin-bottom: 2rem;
`;

interface HelpTextProps {
    acceptedFileExtensions: string[];
    maxFileSize: number;
    resolution?: {
        height?: number | null;
        width?: number | null;
    };
}

export const HelpText: React.FC<HelpTextProps> = ({ acceptedFileExtensions, maxFileSize, resolution }) => (
    <StyledText>
        <span>Accepted formats: {acceptedFileExtensions.join(', ')}</span>
        <span> | </span>
        <span>Maximum file size: {getReadableFileSizeString(maxFileSize)}</span>
        {resolution?.height && resolution?.width ? (
            <>
                <span> | </span>
                <br />
                <span>
                    Resolution: {resolution.width} x {resolution.height}
                </span>
            </>
        ) : null}
    </StyledText>
);
