import React from 'react';

import AdepteductMessageProps from '../AdepteductMessageProps';

const DefaultAdepteductMessage = React.forwardRef<HTMLDivElement | null, AdepteductMessageProps>(
    // eslint-disable-next-line prefer-arrow-callback
    function RefForwardedDefaultAdepteductMessage(_, ref) {
        return <div ref={ref} />;
    }
);

export default DefaultAdepteductMessage;
