import { ClientError, gql } from 'graphql-request';
import { useBuildAuthenticatedInfiniteQueryFn } from 'hooks/useGqlClient/useBuildAuthenticatedInfiniteQueryFn';
import { Api } from 'lib/ApiConstants';
import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';

import { EntityType } from '../../ContentContext/Enums';
import {
    collectionFragment,
    liveLearningSessionFragment,
    progressFragment,
    skillFragment,
    virtualLabFragment
} from '../fragments';
import { BasicCollectionInfo, BasicLabInfo, BasicSessionInfo, BasicSkillInfoWithProgress } from '../interfaces';

export type ScheduleItemEntity = BasicSkillInfoWithProgress | BasicLabInfo | BasicCollectionInfo | BasicSessionInfo;

export interface GetScheduleResponse {
    getSchedule: Schedule;
}

interface Schedule {
    cursor: string;
    items: ScheduleItem[];
}

export interface ScheduleItem {
    item: ScheduleItemEntity;
    date: string;
    type: EntityType;
}

export const queryKey = 'getSchedule';

export const upcomingFilter = [{ type: 'date', value: 'upcoming' }];
export const pastFilter = [{ type: 'date', value: 'past' }];
export const allFilter = [{ type: 'date', value: 'all' }];

const GET_SCHEDULE_QUERY = gql`
    query getSchedule($filters: JSON, $limit: Int, $cursor: String) {
        getSchedule(filters: $filters, limit: $limit, cursor: $cursor) {
            cursor
            items {
                date
                type
                item {
                    ... on Skill {
                        ${skillFragment}
                        ${progressFragment}
                    }
                    ... on VirtualLab {
                        ${virtualLabFragment}
                    }
                    ... on LiveLearningSession {
                        ${liveLearningSessionFragment}
                    }
                    ... on Collection {
                        ${collectionFragment}
                    }
                }
            }
        }
    }
`;

interface GetScheduleVariables {
    filters?: string | Record<string, string>[];
    limit?: number;
    cursor?: string | null;
}

/**
 * Don't refetch for 30 seconds when toggling between todo/complete
 */
const GET_SCHEDULE_RESULTS_TTL = 30 * 1000;

export const useGetSchedule = (
    variables: GetScheduleVariables = {}
): UseInfiniteQueryResult<GetScheduleResponse, ClientError> => {
    const { queryFn } = useBuildAuthenticatedInfiniteQueryFn<GetScheduleResponse, GetScheduleVariables>(
        GET_SCHEDULE_QUERY,
        {
            ...variables,
            filters: typeof variables.filters !== 'string' ? JSON.stringify(variables.filters ?? []) : variables.filters
        },
        'cursor',
        { api: Api.Content }
    );

    return useInfiniteQuery<GetScheduleResponse, ClientError>([queryKey, variables], {
        queryFn,
        staleTime: GET_SCHEDULE_RESULTS_TTL,
        getNextPageParam: (lastPage) => lastPage.getSchedule.cursor
    });
};
