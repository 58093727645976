/* istanbul ignore file */

import { useContext } from 'react';

import { RealTimeCommunicationContext, ReceiptAdepteductMessage } from 'context/RealTimeCommunicationContext';
import AdepteductMessage from 'lib/communication/message/base/AdepteductMessage';
import { MessageAckType } from 'lib/communication/message/MessageRecord';
import PossibleEventFields from 'lib/communication/message/type/PossibleEventFields';

import useAckMessage from './useAckMessage';
import useAckMessageBatch from './useAckMessageBatch';
import { AckMessageDetail } from './useAckMessageBatch/ackMessageBatchApi';
import useUpdateMessage, { MessageUpdateReducerParams } from './useUpdateMessage';

export interface UseMessageActionsOutput {
    addOptimisticMessage: (message: AdepteductMessage<PossibleEventFields>) => string;
    optimisticallyDeleteMessage: (message: AdepteductMessage<PossibleEventFields>) => void;
    attachReceiptToOptimisticMessage: (receiptMessage: ReceiptAdepteductMessage) => void;
    ackMessage: (channelId: string, messageId: string, ackType: MessageAckType) => void;
    ackMessageBatch: (ackMessageDetails: AckMessageDetail[]) => void;
    updateMessage: (params: MessageUpdateReducerParams) => void;
}

/**
 * All interactions with messages should run through this hook to ensure all necessary actions are taken.
 * Do not interact with the Contexts or API calls directly.
 */
const useMessageActions = (): UseMessageActionsOutput => {
    const { addOptimisticMessage, attachReceiptToOptimisticMessage, optimisticallyDeleteMessage } =
        useContext(RealTimeCommunicationContext);

    const { ackMessage } = useAckMessage();
    const { ackMessageBatch } = useAckMessageBatch();

    const { updateMessage } = useUpdateMessage();

    return {
        addOptimisticMessage,
        attachReceiptToOptimisticMessage,
        ackMessage,
        ackMessageBatch,
        updateMessage,
        optimisticallyDeleteMessage
    };
};

export default useMessageActions;
