import { GraphQLClient } from 'graphql-request';
import gql from 'graphql-tag';
import buildGqlMutationFn from 'hooks/useGqlClient/helpers/buildGqlMutationFn';
import { MutationFunction } from 'react-query';

import { UpdateComponentFocusResult, UpdateComponentFocusVariables } from '.';

export const UPDATE_COMPONENT_FOCUS = gql`
    mutation updatePresenterComponentFocusLiveLearning($sessionId: UUIDv4!, $componentId: String) {
        updatePresenterComponentFocusLiveLearning(sessionId: $sessionId, componentId: $componentId) {
            success
        }
    }
`;

const makeUpdateComponentFocus = (
    client: GraphQLClient
): MutationFunction<UpdateComponentFocusResult, UpdateComponentFocusVariables> =>
    buildGqlMutationFn(client, UPDATE_COMPONENT_FOCUS);

export default makeUpdateComponentFocus;
