import React from 'react';

import { mdiHeart, mdiPoll, mdiViewDashboard, mdiBookshelf, mdiHammerScrewdriver, mdiAccountCircle } from '@mdi/js';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ignoreFeatureFlags } from 'lib/constants';

import { NavHeader, NavLinkList } from './Nav.styles';
import NavLink from './NavLink';

const NavHome: React.FC = () => {
    const { analytics: analyticsLDFlag, favorites: favoritesLDFlag, myCreations: myCreationsLDFlag } = useFlags();

    return (
        <div>
            <NavLinkList>
                <NavLink to={'/dashboard'} label="Dashboard" iconPath={mdiViewDashboard} />
                <NavLink to={'/bookshelves'} label="Bookshelves" iconPath={mdiBookshelf} />
                {ignoreFeatureFlags || myCreationsLDFlag ? (
                    <NavLink to={'/my-creations'} label="Creations" iconPath={mdiHammerScrewdriver} />
                ) : null}
                <NavLink to={'/my-profile'} label="Profile" iconPath={mdiAccountCircle} />
                {ignoreFeatureFlags || favoritesLDFlag ? (
                    <NavLink to={'/favorites'} label="Favorites" iconPath={mdiHeart} />
                ) : null}
                {ignoreFeatureFlags || analyticsLDFlag ? (
                    <NavLink to={'/analytics'} label="Analytics" iconPath={mdiPoll} />
                ) : null}
            </NavLinkList>
        </div>
    );
};

export default NavHome;
