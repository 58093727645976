const DEVTOOLS_STORAGE_KEY = 'devtools';

export const isDevToolsEnabled = (): boolean => {
    try {
        if (localStorage) {
            const devToolsEnabled = localStorage.getItem(DEVTOOLS_STORAGE_KEY);

            return Boolean(devToolsEnabled && devToolsEnabled === 'true');
        }

        return false;
    } catch (error) {
        return false;
    }
};
