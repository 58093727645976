import React, { useContext } from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { IconButton } from '@material-ui/core';
import MuiMenuItem from '@material-ui/core/MenuItem';
import { mdiCogOutline, mdiDotsVertical, mdiReply, mdiTrashCan } from '@mdi/js';
import Icon from '@mdi/react';
import { useCurrentUser } from 'hooks/useCurrentUser';
import styled from 'styled-components';
import { StyledMenu } from 'styles/Shared.styles';

import { MenuItem } from '../builder/Menu.styles';

import { CommentContext } from './CommentContext';
import { Comment } from './interfaces';

const Controls = styled.div`
    margin-left: auto;
`;

const ControlButton = styled(IconButton)`
    color: ${(props) => props.theme.colors.primary};

    svg {
        width: ${IconSize.Medium};
    }
`;

interface Props {
    comment: Comment;
    onBeginEdit: () => void;
}

const CommentControls: React.FC<Props> = ({ comment, onBeginEdit }) => {
    const { createdBy, commentId } = comment;
    const { currentUser } = useCurrentUser();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { deleteComment, showThread } = useContext(CommentContext);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = () => {
        deleteComment(comment);
        handleClose();
    };

    const handleUpdate = () => {
        onBeginEdit();
        handleClose();
    };

    const handleReply = () => {
        showThread(comment.commentId);
    };

    /**
     * Comments have a 'pending-<random>' ID when they are optimistically
     * displayed. Prevent any replying or editing until it has a real ID.
     */
    if (commentId.includes('pending')) {
        return null;
    }

    return (
        <>
            <Controls>
                <ControlButton onClick={handleReply}>
                    <Icon path={mdiReply} />
                </ControlButton>
                {currentUser?.userId === createdBy && !comment.deletedAt ? (
                    <ControlButton onClick={handleClick}>
                        <Icon path={mdiDotsVertical} />
                    </ControlButton>
                ) : null}
            </Controls>
            <StyledMenu
                id={`menu-${commentId}`}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <div>
                    <MuiMenuItem onClick={handleUpdate}>
                        <MenuItem>
                            <Icon path={mdiCogOutline} />
                            Edit
                        </MenuItem>
                    </MuiMenuItem>
                    <MuiMenuItem onClick={handleDelete}>
                        <MenuItem>
                            <Icon path={mdiTrashCan} />
                            Delete
                        </MenuItem>
                    </MuiMenuItem>
                </div>
            </StyledMenu>
        </>
    );
};

export default CommentControls;
