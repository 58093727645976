import React, { useState, useMemo } from 'react';

import { CourseIcon } from '@adept-at/lib-react-components';
import { Chip, Tooltip, Switch } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { mdiUpload, mdiImageArea } from '@mdi/js';
import { Icon } from '@mdi/react';
import { PreviewToggle } from 'components/builder/BuildingHeader';
import { DefaultCollectionImageType } from 'components/ContentContext/Interfaces';
import { Modal } from 'components/modals';
import { isTrue } from 'utils/isTrue';

import { useCollectionImageContext, UploadImageMode } from '../context/CollectionImageContext';

import {
    ChipWrapper,
    PromptTextContainer,
    RecommendedSizePrompt,
    CatalogImageContainer,
    EditImageWrapper,
    ImageContainer,
    StyledCollectionImage,
    UploadImageButton,
    StyledToggleState,
    ImageModeContainer,
    ToggleCustomImagesButton,
    CollapsibleContainer,
    ButtonContainer,
    CollectionImageButton
} from './styles';

export enum ColorVariant {
    GRAY = 'gray',
    RED = 'red',
    BLUE = 'blue',
    GREEN = 'green'
}

interface EditOptions {
    removeDarkImage?: boolean;
}
export interface EditCatalogImageModalProps {
    contentTitle: string;
    open: boolean;
    onClose: () => void;
    onConfirm: (options?: EditOptions) => void;
}

export const EditCatalogImageModal: React.FC<EditCatalogImageModalProps> = ({
    open,
    contentTitle,
    onClose,
    onConfirm
}) => {
    const {
        collectionImages,
        defaultImages,
        defaultCatalog,
        handleUploadModalOpen,
        setUploadDestination,
        handleUseDefaultCatalogImage
    } = useCollectionImageContext();

    const hasDarkModeImage = useMemo(() => {
        return isTrue(
            collectionImages?.catalogDark && collectionImages?.catalogDark !== collectionImages?.catalogLight
        );
    }, [collectionImages]);

    const [useDarkMode, setUseDarkMode] = useState(hasDarkModeImage);
    const [useDefaultImages, setUseDefaultImages] = useState(false);
    const [selectedDefaultImage, setSelectedDefaultImage] = useState('');

    const renderLightModeImage = useMemo(() => {
        const lightUrl = collectionImages?.catalogLight ?? defaultCatalog;

        return <StyledCollectionImage $image={lightUrl} $isDefault={lightUrl === defaultCatalog} />;
    }, [collectionImages, defaultCatalog]);

    const renderDarkModeImage = useMemo(() => {
        const darkUrl = collectionImages?.catalogDark ?? collectionImages?.catalogLight ?? defaultCatalog;

        return <StyledCollectionImage $image={darkUrl} $isDefault={darkUrl === defaultCatalog} />;
    }, [collectionImages, defaultCatalog]);

    const onToggleDarkMode = () => {
        setUseDarkMode((prev) => !prev);
    };

    const onToggleCustomImages = () => {
        setUseDefaultImages((prev) => !prev);
    };

    const onDefaultImageSelected = (type: DefaultCollectionImageType) => {
        if (handleUseDefaultCatalogImage) {
            handleUseDefaultCatalogImage(type);
        }
        setSelectedDefaultImage(type);
    };

    const isSelected = (type: DefaultCollectionImageType) => {
        return selectedDefaultImage === type;
    };

    const checkUpdatesAndClose = () => {
        if (collectionImages?.catalogDark && !useDarkMode) {
            return onConfirm({ removeDarkImage: true });
        }
        return onConfirm();
    };

    const handleOpenModal = (mode: UploadImageMode) => {
        if (setUploadDestination && handleUploadModalOpen) {
            setUploadDestination({ destination: 'catalog', type: 'desktop', mode });
            handleUploadModalOpen();
        }
    };

    return (
        <Modal
            modalTitle="Catalog image"
            open={open}
            onClose={onClose}
            styleProps={{ 'max-width': '600px' }}
            confirmButtonText="Done"
            onConfirm={checkUpdatesAndClose}
        >
            <>
                <ChipWrapper>
                    <Chip
                        size="small"
                        icon={<CourseIcon aria-label="Collection" />}
                        label={contentTitle}
                        variant="outlined"
                    />
                </ChipWrapper>
                <CollapsibleContainer $collapsed={useDefaultImages}>
                    <PromptTextContainer>
                        <span>Custom images</span>
                        <Tooltip title="To use a custom image for dark mode, toggle the switch below.">
                            <InfoOutlinedIcon />
                        </Tooltip>
                    </PromptTextContainer>
                    <RecommendedSizePrompt>Recommended size in pixels: 125 x 125</RecommendedSizePrompt>
                    <CatalogImageContainer>
                        <EditImageWrapper>
                            <span>Light mode</span>
                            <ImageContainer>{renderLightModeImage}</ImageContainer>
                            <UploadImageButton onClick={() => handleOpenModal('light')}>
                                <Icon path={mdiUpload} />
                                Light mode image
                            </UploadImageButton>
                        </EditImageWrapper>
                        <EditImageWrapper>
                            <span>Dark mode</span>
                            <ImageContainer $asDarkMode>{renderDarkModeImage}</ImageContainer>
                            <UploadImageButton disabled={!useDarkMode} onClick={() => handleOpenModal('dark')}>
                                <Icon path={mdiUpload} />
                                Dark mode image
                            </UploadImageButton>
                        </EditImageWrapper>
                    </CatalogImageContainer>
                    <PreviewToggle>
                        <Switch
                            color="primary"
                            checked={useDarkMode}
                            onChange={onToggleDarkMode}
                            defaultChecked={hasDarkModeImage}
                        />
                        <StyledToggleState onClick={onToggleDarkMode} isActive={false}>
                            Use custom dark mode image
                        </StyledToggleState>
                    </PreviewToggle>
                </CollapsibleContainer>
                <ImageModeContainer $enableGutters={!useDefaultImages}>
                    <ToggleCustomImagesButton variant="outlined" onClick={onToggleCustomImages}>
                        <Icon path={useDefaultImages ? mdiUpload : mdiImageArea} />{' '}
                        {useDefaultImages ? 'Use custom images' : 'Use default images'}
                    </ToggleCustomImagesButton>
                </ImageModeContainer>
                <CollapsibleContainer $collapsed={!useDefaultImages}>
                    <PromptTextContainer>
                        <span>Default images</span>
                    </PromptTextContainer>
                    <CatalogImageContainer $enableGutters>
                        {Object.values(ColorVariant).map((color) => (
                            <ButtonContainer
                                key={color}
                                $selected={isSelected(color)}
                                onClick={() => onDefaultImageSelected(color)}
                            >
                                <CollectionImageButton src={defaultImages?.[color]?.catalog} />
                            </ButtonContainer>
                        ))}
                    </CatalogImageContainer>
                </CollapsibleContainer>
            </>
        </Modal>
    );
};
