import * as yup from 'yup';

import { MessageEventType } from '..';
import AckableAdepteductMessage from '../../base/AckableAdepteductMessage';

interface EventFieldsUserTyping {
    user: string;
}

export const DEFAULT_TTL_IN_MS = 5000;

export default class ChannelUserTyping extends AckableAdepteductMessage<EventFieldsUserTyping> {
    schema = yup.object({
        user: yup.string().required()
    });

    type = MessageEventType.ChannelUserTyping;

    ttl = DEFAULT_TTL_IN_MS;
}
