import { ClientError } from 'graphql-request';
import useGqlClient from 'hooks/useGqlClient';
import { API_COMMUNICATION } from 'lib/ApiConstants';
import { MessageEntityType } from 'lib/communication/message/MessageRecord';
import { useMutation, UseMutationResult } from 'react-query';

import makeToggleReaction from './makeToggleReaction';

export interface ToggleReactionResult {
    toggleEntityReaction: {
        id: string;
    };
}

export interface ToggleReactionVariables {
    entityType: MessageEntityType;
    entityId: string;
    messageId: string;
    reaction: string;
    hasParent: boolean;
}

const useToggleReaction = (): UseMutationResult<ToggleReactionResult, ClientError, ToggleReactionVariables> => {
    const { client, withMutationOptions } = useGqlClient(API_COMMUNICATION);

    return useMutation<ToggleReactionResult, ClientError, ToggleReactionVariables>(
        makeToggleReaction(client),
        withMutationOptions({})
    );
};

export default useToggleReaction;
