import React from 'react';

import { Skeleton } from '@material-ui/lab';
import styled from 'styled-components';

import { usePublicImage } from './hooks/usePublicImage';

const StyledLogoWrapper = styled.div`
    height: 50px;
    overflow: hidden;
    display: flex;
    margin-right: 1rem;
`;

const StyledImage = styled.img``;

const LogoDisplay: React.FC<{ assetId: string }> = ({ assetId }) => {
    const { data } = usePublicImage({ assetId });

    if (!data) {
        return <Skeleton variant="rect" width={300} height={50} />;
    }

    const [{ url }] = data?.getPublicImage?.processed ?? [{ url: null }];

    return <StyledImage src={url} />;
};

export const OrganizationHeaderLogo: React.FC<{ logoHeaderAssetId: string | null }> = ({ logoHeaderAssetId }) => {
    if (!logoHeaderAssetId) {
        return null;
    }

    return (
        <StyledLogoWrapper>
            <LogoDisplay assetId={logoHeaderAssetId} />
        </StyledLogoWrapper>
    );
};
