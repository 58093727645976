import { useState, useEffect } from 'react';

import { EntityType } from 'components/ContentContext/Enums';
import { OwnerInfo } from 'components/ContentContext/Interfaces';
import { ClientError } from 'graphql-request';
import gql from 'graphql-tag';
import { useBuildAuthenticatedQueryFn } from 'hooks/useGqlClient/useBuildAuthenticatedQueryFn';
import { Api } from 'lib/ApiConstants';
import { useQuery, UseQueryResult } from 'react-query';

import { AuthorizedActionsMap } from './types';

/* GraphlQL Request Interface*/
export interface AuthorizedOwnerActionsRequest {
    owner: OwnerInfo;
    entityList: EntityType[];
}

/* GraphQL Response Interface */
export interface AuthorizedOwnerActionsResponse {
    getAuthorizedOwnerActions: AuthorizedActionsMap;
}

/* Exposed Hook interface */
export interface AuthorizedOwnerActions {
    actions: AuthorizedActionsMap | null;
    refetch: () => any;
}

/* Retrieve ALL actions authorized on behalf of owner for multiple entities. */
const GET_AUTHORIZED_OWNER_ACTIONS_QUERY = gql`
    query getAuthorizedOwnerActions($owner: OwnerInfoInput, $entityList: [EntityType]) {
        getAuthorizedOwnerActions(owner: $owner, entityList: $entityList)
    }
`;

/* GraphQL Hook to get response */
const useGetAuthorizedOwnerActions = (
    variables: AuthorizedOwnerActionsRequest,
    options = {}
): UseQueryResult<AuthorizedOwnerActionsResponse, ClientError> => {
    const { queryFn } = useBuildAuthenticatedQueryFn<AuthorizedOwnerActionsResponse, AuthorizedOwnerActionsRequest>(
        GET_AUTHORIZED_OWNER_ACTIONS_QUERY,
        variables,
        options
    );

    const queryKey = `useGetAuthorizedOwnerActions-${variables.owner.id}-allEntities`;

    return useQuery<AuthorizedOwnerActionsResponse, ClientError>(queryKey, queryFn, options);
};

/**
 * Hook exposed for internal consumption.
 * What actions can `currentuser` perform on behalf of `owner` (organization)
 */
export const useAuthorizedOwnerActions = (owner: OwnerInfo, options: { enabled: boolean }): AuthorizedOwnerActions => {
    const [actions, setActions] = useState<AuthorizedActionsMap | null>(null);

    /* Return a list of the appropriate actions - TODO rework as its integrated more completely.. */
    const { data, status, refetch } = useGetAuthorizedOwnerActions(
        { owner, entityList: [EntityType.COLLECTION, EntityType.SKILL] },
        { api: Api.Content, enabled: options.enabled }
    );

    useEffect(() => {
        if (status !== 'success' && data?.getAuthorizedOwnerActions) {
            setActions(data.getAuthorizedOwnerActions);
        }
    }, [status, data]);

    return { actions, refetch };
};
