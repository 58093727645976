export enum CommentAccess {
    Private = 'private',
    Public = 'public'
}

export interface AddCommentResult {
    createComment: Comment;
}

export interface AddCommentVariables {
    contextId: string;
    type: string;
    body: string;
    access: string;
    parent: string;
    pendingId?: string;
}

export interface UpdateCommentResult {
    commentId: string;
    body: string;
    editedAt: string;
}

export interface UpdateCommentVariables {
    commentId: string;
    contextId: string;
    type: string;
    body: string;
    access: string;
}

export interface RemoveCommentResult {
    commentId: string;
    deletedAt: string;
}

export interface RemoveCommentVariables {
    commentId: string;
    contextId: string;
    type: string;
    access: string;
}

export interface Section {
    id: string;
    title: string;
}

export interface Comment {
    commentId: string;
    parent: string;
    body: string;
    createdAt: string;
    createdBy: string;
    editedAt: string | null;
    deletedAt: string | null;
    thread: string[];
    access: CommentAccess;
    alias?: string;
    image?: string | null;
    imageUrl?: string | null;
}
