import React, { createContext, useState, useContext, useMemo, useCallback } from 'react';

import { LearningMode } from '@adept-at/lib-react-components';
import { mdiBullseyeArrow } from '@mdi/js';
import { useCompleteSkill } from 'components/learn/useProgress/useCompleteSkill';
import { useResetSkillProgress } from 'components/learn/useProgress/useResetSkillProgress';
import { useUpsertLastCompletedBlockKey } from 'components/learn/useProgress/useUpsertLastCompletedBlockKey';
import { useModal } from 'hooks/useModal';

import { LearnContext } from '../../LearnContext';
import EnableModeModal from '../EnableModeModal';

import { extractComponentIdsFromTextComponent, isSkillComplete } from './utils';

interface AccountabilityProviderInterface {
    handleModalOpen: () => void;
    accountabilityModeOn: boolean;
    disableAccountabilityMode: () => void;
    completeComponent: (componentId: string) => void;
    completeSkill: (lastCompletedBlockKey?: string) => void;
    skillIsComplete: boolean;
    setSkillIsComplete: React.Dispatch<React.SetStateAction<boolean>>;
    readyToProceed: boolean;
    setReadyToProceed: React.Dispatch<React.SetStateAction<boolean>>;
    finalComponentId?: string;
}

const AccountabilityContext = createContext({} as AccountabilityProviderInterface);
const { Provider, Consumer } = AccountabilityContext;

const AccountabilityProvider: React.FC = ({ children }) => {
    const { modalOpen, handleModalOpen, handleModalClose } = useModal();
    const [readyToProceed, setReadyToProceed] = useState(false);
    const { skill, tenantSlug, skillSlug } = useContext(LearnContext);
    const { mutate: completeSkillMutation } = useCompleteSkill(tenantSlug, skillSlug);
    const { mutate: setLastCompletedBlockKey } = useUpsertLastCompletedBlockKey();
    const { mutate: resetProgress } = useResetSkillProgress(tenantSlug, skillSlug);
    const [accountabilityModeOn, setAccountabilityModeOn] = useState(!!skill?.progress?.lastCompletedBlockKey);
    const [skillIsComplete, setSkillIsComplete] = useState(isSkillComplete(skill));

    const componentIds = useMemo(
        () => extractComponentIdsFromTextComponent(Object.values(skill.components ?? {})[0]),
        [skill.components]
    );

    const finalComponentId = componentIds[componentIds.length - 1];

    const completeComponent = (componentId: string) => {
        if (finalComponentId === componentId) {
            return completeSkill(componentId);
        }

        setLastCompletedBlockKey({ skillId: skill.skillId, lastCompletedBlockKey: componentId });
        setReadyToProceed(false);
    };

    const completeSkill = (lastCompletedBlockKey?: string) => {
        completeSkillMutation({ skillId: skill.skillId, lastCompletedBlockKey });
        setSkillIsComplete(true);
    };

    const enableAccountabilityMode = async () => {
        resetProgress({
            skillId: skill.skillId
        });
        setReadyToProceed(false);
        setSkillIsComplete(false);
        setAccountabilityModeOn(true);
        handleModalClose();
    };

    const disableAccountabilityMode = useCallback(async () => {
        resetProgress({
            skillId: skill.skillId
        });
        setAccountabilityModeOn(false);
    }, [skill.skillId, resetProgress]);

    return (
        <Provider
            value={{
                handleModalOpen,
                accountabilityModeOn,
                disableAccountabilityMode,
                completeComponent,
                completeSkill,
                skillIsComplete,
                setSkillIsComplete,
                readyToProceed,
                setReadyToProceed,
                finalComponentId
            }}
        >
            {children}
            <EnableModeModal
                open={modalOpen}
                onClose={handleModalClose}
                onConfirm={enableAccountabilityMode}
                title="Accountability mode"
                iconPath={mdiBullseyeArrow}
                subtitle="Be accountable to get the best results"
                mode={LearningMode.ACCOUNTABILITY}
            />
        </Provider>
    );
};

const useAccountabilityContext = (): AccountabilityProviderInterface => {
    const context = useContext(AccountabilityContext);

    if (!context) {
        throw new Error('useAccountabilityContext must be used within an AccountabilityProvider');
    }

    return context;
};

export { AccountabilityProvider, Consumer as AccountabilityConsumer, useAccountabilityContext };
