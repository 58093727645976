import { ClientError } from 'graphql-request';
import gql from 'graphql-tag';
import { useBuildAuthenticatedQueryFn } from 'hooks/useGqlClient/useBuildAuthenticatedQueryFn';
import { Api } from 'lib/ApiConstants';
import { QueryKey, UseQueryOptions, UseQueryResult, useQuery } from 'react-query';

const GET_ORGANIZATION_INVITATIONS_QUERY = gql`
    query getOrganizationInvitations($organizationId: String!, $mostRecentOnly: Boolean) {
        getOrganizationInvitations(organizationId: $organizationId, mostRecentOnly: $mostRecentOnly) {
            id
            email
            status
            roles
            groups
            sentAt
        }
    }
`;

export interface OrganizationInvitationDetails {
    id: string;
    email: string;
    roles: string[];
    status: string;
    groups: string[];
    sentAt: string;
    stringifiedRoles?: string;
}

export interface GetOrganizationInvitationsResponse {
    getOrganizationInvitations: OrganizationInvitationDetails[];
}

export interface GetOrganizationInvitationsVariables {
    organizationId: string;
    mostRecentOnly: boolean;
}

export const buildGetOrganizationsInvitationsQueryKey = (organizationId: string | undefined): QueryKey => [
    'getOrganizationInvitations',
    organizationId
];

export const useGetOrganizationInvitations = (
    variables: GetOrganizationInvitationsVariables,
    options: UseQueryOptions<GetOrganizationInvitationsResponse, ClientError>
): UseQueryResult<GetOrganizationInvitationsResponse, ClientError> => {
    const { queryFn } = useBuildAuthenticatedQueryFn<
        GetOrganizationInvitationsResponse,
        GetOrganizationInvitationsVariables
    >(GET_ORGANIZATION_INVITATIONS_QUERY, variables, { api: Api.Accounts });

    const queryCacheKey = buildGetOrganizationsInvitationsQueryKey(variables.organizationId);

    return useQuery<GetOrganizationInvitationsResponse, ClientError>(queryCacheKey, queryFn, options);
};
