import { useMemo } from 'react';

import useUsersDetails from '../../../hooks/useUserDetails';

interface UseFormattedUserDetailsResult {
    loading: boolean;
    details: {
        realName: string;
        displayName: string;
        alias: string;
        avatarSrc: string | undefined;
    };
}

export const useFormattedUserDetails = (userId: string): UseFormattedUserDetailsResult => {
    const userIds = useMemo(() => [userId], [userId]);
    const { loading, usersDetails: usersDetails } = useUsersDetails({ userIds });

    const userDetails = usersDetails[userId];

    return {
        loading,
        details: useMemo(() => {
            const realName = Object.values(userDetails.name || {})
                .filter((value) => value !== null)
                .join(' ')
                .trim();

            const displayName = [userDetails.alias, realName ? `(${realName})` : ''].join(' ').trim() || 'unknown';

            // @TODO we should be able to pull back email here if the viewer has permissions

            return {
                realName,
                alias: userDetails.alias || '',
                displayName,

                // Material UI Avatar component wants src as string | undefined
                avatarSrc: userDetails?.imageUrl ?? undefined
            };
        }, [userDetails])
    };
};
