import { ProfileOption } from '.';

const PROFILE_STORAGE_KEY = 'selected_profile';

export const setProfileStorage = (value: ProfileOption): void => {
    localStorage.setItem(PROFILE_STORAGE_KEY, JSON.stringify(value));
};
export const getProfileStorage = (): ProfileOption | null => {
    const inStorage = localStorage.getItem(PROFILE_STORAGE_KEY);

    if (inStorage) {
        try {
            return JSON.parse(inStorage);
        } catch {
            return null;
        }
    }
    return null;
};

export const clearProfileStorage = (): void => {
    localStorage.removeItem(PROFILE_STORAGE_KEY);
};
