import { useState, useCallback, useEffect, useRef } from 'react';

import { useDebounce } from 'use-debounce/lib';

/**
 * fetcher is how it obtains items from ids.
 * onSuccess is what to do when it has a bulk result.
 */
export interface UseBulkFetcherQueueOptions<TId, TResult, TError> {
    /** fetcher is how it obtains items from ids. */
    fetcher: (ids: TId[]) => Promise<TResult>;
    /** onSuccess is what to do when it has a bulk result. */
    onSuccess: (result: TResult) => void;
    /** React to an error occurring */
    onError?: (error: TError) => void;
    /** Disable the queue from processing (but not accumulating) */
    disabled?: boolean;
    /** The length of time each result should be cached, in MS. Do not set this to zero, or you are highly likely to hang the website. */
    ttlInMs?: number;
    /** The length of time to hold off on making an API call from the last time more ids were requested in milliseconds. */
    debounceLengthInMs?: number;
}

export interface UseBulkFetcherQueueOutput<TId> {
    /** Add more ids to the queue, automatically deduped as added, no need to dedupe */
    addToQueue: (ids: TId[]) => void;
    /** How long is the queue? */
    queueLength: number;
    /**
     * If an Id has already been fetched, and alwaysRefetch !== true, this must be executed before a refetch of an id will occur.
     * Safe to run even if Id has not been previously fetched.
     */
    setIdToBeRefetched: (id: TId) => void;
    /** Are things currently being fetched? */
    isLoading: boolean;
}

const DEFAULT_DEBOUNCE_LENGTH_IN_MS = 250;

/**
 * This is a little hook that will fetch groups of entities that you specify based on ids (addToQueue), and will act accordingly for each response.
 * It also dedupes ids if it is already processing it.
 * @param options @see UseBulkFetcherQueueOptions
 */
const useBulkFetcherQueue = <TId, TResult, TError = Error>(
    options: UseBulkFetcherQueueOptions<TId, TResult, TError>
): UseBulkFetcherQueueOutput<TId> => {
    const { fetcher, onSuccess, onError, disabled, debounceLengthInMs } = options;

    const [queueToFetch, setQueueToFetch] = useState<TId[]>([]);
    const [debouncedQueueToFetch] = useDebounce(queueToFetch, debounceLengthInMs ?? DEFAULT_DEBOUNCE_LENGTH_IN_MS);

    const processingIds = useRef(new Set<TId>());
    const alreadyProcessedIds = useRef(new Map<TId, { expiresAt: number | null }>());
    const [isLoading, setIsLoading] = useState(false);

    const filterIdsToFetch = useCallback(
        (ids: TId[]): TId[] =>
            ids.filter((id) => {
                if (processingIds.current.has(id)) {
                    return false;
                }
                if (alreadyProcessedIds.current.has(id)) {
                    const { expiresAt } = alreadyProcessedIds.current.get(id) ?? {};

                    if (!expiresAt || expiresAt >= Date.now()) {
                        return false;
                    }
                }
                return true;
            }),
        []
    );

    const processIdGroup = useCallback(
        async (ids: TId[]): Promise<void> => {
            if (!ids) return;
            if (!fetcher || !onSuccess) {
                throw new Error('fetcher and onSuccess must be provided to useBulkFetcherQueue');
            }

            const idsToFetch = filterIdsToFetch(ids);
            if (idsToFetch.length > 0) {
                try {
                    setIsLoading(true);
                    idsToFetch.forEach((id) => processingIds.current.add(id));

                    const result = await fetcher(idsToFetch);

                    onSuccess(result);
                } catch (error) {
                    if (onError) onError(error);
                } finally {
                    idsToFetch.forEach((id) => {
                        processingIds.current.delete(id);

                        alreadyProcessedIds.current.set(id, {
                            expiresAt: !isNaN(Number(options?.ttlInMs)) ? Date.now() + Number(options.ttlInMs) : null
                        });
                    });
                    setIsLoading(false);
                }
            }
        },
        [fetcher, onSuccess, onError, options?.ttlInMs, filterIdsToFetch]
    );

    useEffect(() => {
        const allIdsInTheQueue = new Set(debouncedQueueToFetch);
        const idsToFetch = filterIdsToFetch(debouncedQueueToFetch);
        if (!disabled && idsToFetch.length > 0) {
            setQueueToFetch((prevQueueToFetch) => prevQueueToFetch.filter((id) => !allIdsInTheQueue.has(id)));
            processIdGroup(idsToFetch);
        }
    }, [disabled, debouncedQueueToFetch, processIdGroup, filterIdsToFetch]);

    /** Add more ids to the queue, automatically deduped as added, no need to dedupe */
    const addToQueue = useCallback((ids: TId[]): void => {
        setQueueToFetch((prevQueueToFetch) => [...new Set([...prevQueueToFetch, ...ids])]);
    }, []);

    /**
     * If an Id has already been fetched, this must be executed before a refetch of an id will occur.
     * Safe to run even if Id has not been previously fetched.
     */
    const setIdToBeRefetched = useCallback(
        (id: TId) => {
            alreadyProcessedIds.current.delete(id);
        },
        [alreadyProcessedIds]
    );

    return {
        addToQueue,
        queueLength: queueToFetch.length,
        setIdToBeRefetched,
        isLoading
    };
};

export default useBulkFetcherQueue;
