import React, { useContext, useEffect, useState } from 'react';

import { AddComponent } from 'components/engine/AddComponent';

import { RichTextEditorContext } from './Context';
import { DraftJsBlock } from './utils';

const getSelectedBlockNode = (root): HTMLElement | null => {
    const selection = root.getSelection();

    if (selection?.rangeCount === 0) {
        return null;
    }

    let node = selection?.getRangeAt(0).startContainer;

    do {
        if (node?.getAttribute && node.getAttribute('data-block') === 'true') {
            return node;
        }

        node = node?.parentNode;
    } while (Boolean(node));

    return null;
};

export const AddComponentButton: React.FC = () => {
    const { editorState, editorRef } = useContext(RichTextEditorContext);
    const [top, setTop] = useState<number>();
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const contentState = editorState.getCurrentContent();
        const selectionState = editorState.getSelection();

        if (
            !selectionState.isCollapsed() ||
            selectionState.getAnchorKey() !== selectionState.getFocusKey() ||
            contentState.getBlockForKey(selectionState.getAnchorKey()).getType().indexOf(DraftJsBlock.ATOMIC) >= 0
        ) {
            setIsVisible(false);
            return;
        }

        const block = contentState.getBlockForKey(selectionState.getAnchorKey());
        const blockType = block.getType();
        if (block.getLength() > 0 || [DraftJsBlock.UL, DraftJsBlock.OL].includes(blockType as DraftJsBlock)) {
            setIsVisible(false);
            return;
        }

        const node = getSelectedBlockNode(window);
        if (!node) {
            setIsVisible(false);
            return;
        }

        setIsVisible(true);
        setTop(node.offsetTop + 9 + (node.offsetHeight - 26) / 2);
    }, [editorState, editorRef]);

    return isVisible ? (
        <span style={{ top, position: 'absolute', left: '-46px' }}>
            <AddComponent />
        </span>
    ) : null;
};
