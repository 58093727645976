import React from 'react';

import { AnswerOption, QuestionComponentValues, QuestionType } from '@adept-at/lib-react-components';
import { Collapse, Fade, FormControl, TextField, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { AddAnswer } from '../../helpers/AddAnswer';
import { ToggleMultipleVotes } from '../../helpers/ToggleMultipleVotes';
import { BoxWithPadding } from '../../Question.styles';

interface Props {
    values: QuestionComponentValues;
    onChange: <TComponentFieldValue>(field: string) => (value: TComponentFieldValue) => void;
    onOptionChange: (index: number, value: AnswerOption) => void;
    error: any;
}

export const PollEdit: React.FC<Props> = ({
    values: { questionType, answerOptions, allowMultiVotes },
    onChange,
    onOptionChange,
    error
}) => {
    return (
        <Collapse timeout={500} in={questionType === QuestionType.Poll}>
            <Fade timeout={800} in={questionType === QuestionType.Poll}>
                <FormControl component="fieldset">
                    {answerOptions.map((option, index) => (
                        <BoxWithPadding key={option.key} display="flex" alignItems="center">
                            <TextField
                                id={`pollEdit-text-${index}`}
                                key={`${option.key}-text`}
                                name="answerOptions"
                                variant="outlined"
                                fullWidth
                                label={`Option ${index + 1}`}
                                value={option.text}
                                onChange={({ target: { value } }) => {
                                    const updatedContent = { ...option, text: value };
                                    onOptionChange(index, updatedContent);
                                }}
                                error={!!error[`pollEdit-text-${option.key}`]}
                                helperText={error[`pollEdit-text-${option.key}`]}
                                inputProps={{
                                    'data-testid': `pollEdit-text-${index}`
                                }}
                            />
                            <IconButton
                                aria-label={`remove answer ${index}`}
                                key={`${option.key}-icon-button`}
                                data-testid={`pollEdit-removeAnswer-${index}`}
                                onClick={() => {
                                    if (answerOptions.length > 1) {
                                        const draftAnswerOptions = [...answerOptions];
                                        draftAnswerOptions.splice(index, 1);
                                        onChange('answerOptions')(draftAnswerOptions);
                                    }
                                }}
                            >
                                <DeleteIcon key={`${option.key}-delete-icon`} />
                            </IconButton>
                        </BoxWithPadding>
                    ))}
                    <BoxWithPadding display="flex" justifyContent="space-between">
                        <AddAnswer answerOptions={answerOptions} onChange={onChange}>
                            Add Another Answer
                        </AddAnswer>
                        <ToggleMultipleVotes allowMultiVotes={allowMultiVotes} onChange={onChange} />
                    </BoxWithPadding>
                </FormControl>
            </Fade>
        </Collapse>
    );
};
export default PollEdit;
