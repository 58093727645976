import React, { useMemo } from 'react';

import useUsersDetails from 'hooks/useUserDetails';

import { placeholders } from '../../functions';
import AdepteductMessageProps from '../AdepteductMessageProps';

const renderFromTemplate = ({ fields }, usersDetails) => {
    // @TODO -- add more fields here
    const { template = '', user, variables } = fields;

    return placeholders(template, { ...variables, user: usersDetails[user]?.alias || 'A user' });
};

const SystemNotifDingerMessage = React.forwardRef<HTMLDivElement | null, AdepteductMessageProps>(({ message }, ref) => {
    const {
        fields: { user }
    } = message;

    const userIds = useMemo(() => (user ? [user] : []), [user]);
    const { loading, usersDetails } = useUsersDetails({
        userIds
    });

    if (loading || !usersDetails) {
        return null;
    }

    return <div ref={ref}>{renderFromTemplate(message, usersDetails)}</div>;
});

export default SystemNotifDingerMessage;
