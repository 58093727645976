import React, { useMemo } from 'react';

import { CollaboratorsModal, IconSize } from '@adept-at/lib-react-components';
import { Avatar, Chip } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { mdiAccount } from '@mdi/js';
import Icon from '@mdi/react';
import { CollectionAuthor } from 'components/ContentContext/Interfaces';
import { useModal } from 'hooks/useModal';
import useUserDetails from 'hooks/useUserDetails';
import pluralize from 'pluralize';
import styled from 'styled-components';

import { StyledTableCell } from './TableCell.styles';

interface AuthorsCellProps {
    title: string;
    authors?: CollectionAuthor[];
}

const StyledAvatar = styled(Avatar)`
    width: ${IconSize.Small};
    height: ${IconSize.Small};
`;

export const AUTHORS_CELL_WIDTH = 300;

export const AuthorsCell: React.FC<AuthorsCellProps> = ({ title, authors = [] }) => {
    const userIds = useMemo(() => authors.map((author) => author.userId), [authors]);
    const { usersDetails } = useUserDetails({ userIds });
    const userDetails = useMemo(() => usersDetails[authors[0]?.userId], [authors, usersDetails]);
    const { modalOpen, handleModalOpen, handleModalClose } = useModal();

    const collaborators = useMemo(() => {
        return authors.map((author) => ({ ...author, ...usersDetails[author.userId] }));
    }, [authors, usersDetails]);

    const otherAuthors = useMemo(() => {
        const numOtherAuthors = authors.length - 1;
        return numOtherAuthors > 0 ? ` + ${pluralize('other', numOtherAuthors, true)}` : '';
    }, [authors]);

    return (
        <StyledTableCell width={AUTHORS_CELL_WIDTH}>
            {authors.length > 0 ? (
                <>
                    <Chip
                        size="medium"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleModalOpen();
                        }}
                        icon={
                            <StyledAvatar src={userDetails?.imageUrl ?? undefined}>
                                <Icon path={mdiAccount} size={IconSize.Small} />
                            </StyledAvatar>
                        }
                        label={
                            !userDetails?.displayName ? (
                                <Skeleton variant="text" width={100} />
                            ) : (
                                `${userDetails?.displayName}${otherAuthors}`
                            )
                        }
                        variant="outlined"
                    />
                    <CollaboratorsModal
                        open={modalOpen}
                        onClose={handleModalClose}
                        collaborators={collaborators}
                        parentTitle={title}
                    />
                </>
            ) : null}
        </StyledTableCell>
    );
};
