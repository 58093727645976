import React, { createContext, useCallback, useState } from 'react';

import useBulkFetcherQueue from 'hooks/useBulkFetcherQueue';
import useGqlClient, { GqlClientStatus } from 'hooks/useGqlClient';
import { API_ACCOUNTS } from 'lib/ApiConstants';
import ms from 'ms';

import getUserInfoByUserIds, { UserInfo, GetUserInfoByUserIdsResponse } from './getUserInfoByUserIds';

export interface UserInfoContextOutput {
    /** Get a group of results. */
    getUserInfoFromMap: (userIds: string[]) => Record<string, UserInfo | undefined>;
    /** Fetch a group of users Communcation User Details. */
    fetchUserInfo: (userIds: string[]) => void;
    /**
     * If an Id has already been fetched, this must be executed before a refetch of an id will occur.
     * Safe to run even if Id has not been previously fetched.
     */
    setIdToBeRefetched: (id: string) => void;
    isLoading?: boolean;
}

const UserInfoContext = createContext<UserInfoContextOutput>(undefined as unknown as UserInfoContextOutput);

const USER_INFO_UPDATE_FREQUENCY_IN_MS = ms('1 day');

// Provider is fully tested by useBulkFetcherQueuer itself
export const UserInfoProvider: React.FC = ({ children }) => {
    const [userInfoByUserId, setUserInfoByUserId] = useState(new Map<string, UserInfo>());

    const { client, status } = useGqlClient(API_ACCOUNTS);

    const fetchUserInfo = useCallback(
        async (userIds: string[]): Promise<GetUserInfoByUserIdsResponse> => {
            return await getUserInfoByUserIds(client, userIds);
        },
        [client]
    );

    const onSuccess = useCallback(async (result: GetUserInfoByUserIdsResponse): Promise<void> => {
        const newUserInfos = result?.getUserInfoByUserIds;
        if (newUserInfos) {
            setUserInfoByUserId(
                (prevUserInfoByUserId) =>
                    new Map<string, UserInfo>([
                        ...prevUserInfoByUserId.entries(),
                        ...newUserInfos.map((newUserInfo) => [newUserInfo.id, newUserInfo] as [string, UserInfo])
                    ])
            );
        }
    }, []);

    const { addToQueue, setIdToBeRefetched, isLoading } = useBulkFetcherQueue({
        fetcher: fetchUserInfo,
        onSuccess,
        disabled: status !== GqlClientStatus.Ready,
        ttlInMs: USER_INFO_UPDATE_FREQUENCY_IN_MS
    });

    /** Get a group of results. */
    const getUserInfoFromMap = useCallback(
        (userIds: string[]): Record<string, UserInfo | undefined> => {
            return Object.fromEntries(userIds.map((userId) => [userId, userInfoByUserId.get(userId)]));
        },
        [userInfoByUserId]
    );

    return (
        <UserInfoContext.Provider
            value={{
                getUserInfoFromMap,
                fetchUserInfo: addToQueue,
                setIdToBeRefetched,
                isLoading
            }}
        >
            {children}
        </UserInfoContext.Provider>
    );
};

export default UserInfoContext;
