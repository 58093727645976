import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { datadogLogs } from '@datadog/browser-logs';
import { ClientError } from 'graphql-request';
import { useCurrentUser } from 'hooks/useCurrentUser';
import useGqlClient from 'hooks/useGqlClient';
import { API_COMMUNICATION } from 'lib/ApiConstants';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router';

import { StackContext } from '../stacks/StackContext';

import getSessionById, { buildGetSessionByIdQueryKey, GetSessionByIdResponse } from './hooks/getSessionById';
import { MeetingSession, Presenter, QuickPoll } from './hooks/getSessionsForChannel';
import getUsersForSession, {
    buildGetUsersForSessionQueryKey,
    GetUsersForSessionResponse
} from './hooks/getUsersForSession';
import useJoinMeeting from './hooks/useJoinMeeting';
import { usePublisher } from './hooks/usePublisher';
import { UserCameraStatus, useRealTimeCameraStatuses } from './hooks/useRealTimeCameraStatuses';
import { BasicPresenterDetails, useRealTimePresenters } from './hooks/useRealTimePresenters';
import { getTooltipPreferences, getDevicePreferences, getICEServers } from './utils';

export interface PublisherProviderInterface {
    session?: MeetingSession;
    isPublishing: boolean;
    bindVideo: (videoElement: HTMLVideoElement) => void;
    realTimePresenters: Map<string, BasicPresenterDetails>;
    sessionId: string;
    mediaReady: number;
    microphoneOn: boolean;
    toggleMicrophone: (status: MediaStatus) => void;
    toggleMicrophoneLoading: boolean;
    cameraOn: boolean;
    toggleCamera: (status: MediaStatus) => void;
    toggleCameraLoading: boolean;
    setPublishToken: React.Dispatch<React.SetStateAction<string | undefined>>;
    devicePreferences: DevicePreferences;
    setDevicePreferences: React.Dispatch<React.SetStateAction<DevicePreferences>>;
    devices?: MediaDeviceInfo[];
    setUserSelectedDevices: React.Dispatch<React.SetStateAction<boolean>>;
    userCameraStatuses: Map<string, UserCameraStatus>;
    sessionUserIds?: string[];
    usersForSessionLoading: boolean;
    presenters?: Presenter[];
    sessionLoading: boolean;
    initialCollectionId?: string;
    initialSkillId?: string;
    isPresenting: boolean;
    setIsPresenting: React.Dispatch<React.SetStateAction<boolean>>;
    isDrawing: boolean;
    setIsDrawing: React.Dispatch<React.SetStateAction<boolean>>;
    startingToPresent: boolean;
    setStartingToPresent: React.Dispatch<React.SetStateAction<boolean>>;
    hasJoinedSession: boolean;
    gotUserMedia?: UserMediaStatus;
    getUserMedia: () => Promise<boolean>;
    hideTooltipsById: TooltipPreferences;
    setHideTooltipsById: React.Dispatch<React.SetStateAction<TooltipPreferences>>;
    outputSelectionSupported: boolean;
    setMicrophoneOn: React.Dispatch<React.SetStateAction<boolean>>;
    currentUserPresenterColor: string;
    fetchIceServers: () => Promise<RTCIceServer[]>;
    iceServers?: RTCIceServer[];
    polls?: {
        [id: string]: QuickPoll;
    };
}

export const DEVICE_PREFERENCES_KEY = 'devicePreferences';
export const HIDE_TOOLTIPS_KEY = 'hideTooltipsById';

interface PublisherProviderProps {
    sessionId: string;
    meetingId: string;
}

export interface DevicePreferences {
    microphone?: string;
    camera?: string;
    speaker?: string;
}

export interface TooltipPreferences {
    mute?: boolean;
    pin?: boolean;
    unmute?: boolean;
}

interface UserMediaStatus {
    audio: boolean;
    video: boolean;
}

export enum MediaStatus {
    ON = 'on',
    OFF = 'off',
    BLOCKED = 'blocked'
}

export const PRESENTER_COLORS_BY_SPOT = ['#90CAF9', '#EF9A9A', '#A5D6A7', '#FFCC80', '#CE93D8', '#80DEEA'];

// sinkId and setSinkId are experimental features available on Chrome, Edge and Opera
// https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/setSinkId
export interface ExperimentalMedia extends HTMLMediaElement {
    sinkId?: string;
    setSinkId?: (id: string) => void;
}

const PublisherContext = createContext(undefined as unknown as PublisherProviderInterface);

const { Provider, Consumer } = PublisherContext;

const PublisherProvider: React.FC<PublisherProviderProps> = ({ meetingId, sessionId, children }) => {
    const history = useHistory();
    const [hasInitialized, setHasInitialized] = useState(false);
    const [publishToken, setPublishToken] = useState<string>();
    const [startingToPresent, setStartingToPresent] = useState(false);
    const [isPresenting, setIsPresenting] = useState(false);
    const [hasJoinedSession, setHasJoinedSession] = useState(false);
    const [outputSelectionSupported, setOutputSelectionSupported] = useState(false);
    const [isDrawing, setIsDrawing] = useState(false);

    const [hideTooltipsById, setHideTooltipsById] = useState(getTooltipPreferences());
    const [devicePreferences, setDevicePreferences] = useState(getDevicePreferences());

    const [devices, setDevices] = useState<MediaDeviceInfo[]>();
    const [userSelectedDevices, setUserSelectedDevices] = useState(false);
    const [gotUserMedia, setGotUserMedia] = useState<UserMediaStatus>();

    const { currentUser } = useCurrentUser();
    const { channelId, organizationId, stackId } = useContext(StackContext);

    const { client, withQueryOptions } = useGqlClient(API_COMMUNICATION);

    const { mutate: joinMeeting, isSuccess, isError } = useJoinMeeting();

    useEffect(() => {
        try {
            localStorage.setItem(HIDE_TOOLTIPS_KEY, JSON.stringify(hideTooltipsById));
        } catch (_err) {
            console.debug('Unable to save live learning warning modal preferences in local storage');
        }
    }, [hideTooltipsById]);

    useEffect(() => {
        try {
            localStorage.setItem(DEVICE_PREFERENCES_KEY, JSON.stringify(devicePreferences));
        } catch (_err) {
            console.debug('Unable to save device preferences in local storage');
        }
    }, [devicePreferences]);

    useEffect(() => {
        const audioTest = document.createElement('audio') as ExperimentalMedia;

        if (audioTest.sinkId || audioTest.sinkId === '') {
            setOutputSelectionSupported(true);
        }
    }, []);

    useEffect(() => {
        if (channelId) {
            joinMeeting({ channelId, meetingId, sessionId });
        }
    }, [joinMeeting, channelId, sessionId, meetingId]);

    useEffect(() => {
        if (isSuccess) {
            setHasJoinedSession(true);
            datadogLogs.logger.debug('User joined session', {
                sessionId,
                userId: currentUser?.userId
            });
        }
    }, [isSuccess, sessionId, currentUser?.userId]);

    useEffect(() => {
        if (isError) {
            history.push(`/organization/${organizationId}/rooms/${stackId}`);
        }
    }, [isError, history, organizationId, stackId]);

    const usersForSessionQueryKey = useMemo(() => buildGetUsersForSessionQueryKey(sessionId), [sessionId]);
    const { data: usersForSession, isLoading: usersForSessionLoading } = useQuery<
        GetUsersForSessionResponse,
        ClientError
    >(
        withQueryOptions({
            queryKey: usersForSessionQueryKey,
            queryFn: getUsersForSession(client, { sessionId }),
            enabled: !!sessionId
        })
    );

    const sessionUserIds = useMemo(
        () => usersForSession?.getUsersForMeetingSession?.map((user) => user.userId),
        [usersForSession]
    );

    const userMap = useMemo(
        () =>
            new Map(
                usersForSession?.getUsersForMeetingSession?.map(({ userId, version, camera }) => [
                    userId,
                    { cameraStatus: camera, version }
                ])
            ),
        [usersForSession]
    );
    const userCameraStatuses = useRealTimeCameraStatuses(userMap);

    const sessionByIdQueryKey = useMemo(() => buildGetSessionByIdQueryKey(sessionId), [sessionId]);
    const {
        data: sessionById,
        isLoading: sessionLoading,
        refetch: refetchSessionById
    } = useQuery<GetSessionByIdResponse, ClientError>(
        withQueryOptions({
            queryKey: sessionByIdQueryKey,
            queryFn: getSessionById(client, { sessionId }),
            enabled: !!sessionId
        })
    );

    useEffect(() => {
        const timer = setInterval(() => refetchSessionById(), 3000);

        return () => {
            clearInterval(timer);
        };
    }, [refetchSessionById]);

    const { presenters, collectionId, skillId, session, polls } = useMemo(() => {
        const details = sessionById?.getMeetingSessionById?.details;
        return {
            session: sessionById?.getMeetingSessionById,
            polls: details?.polls,
            presenters: details?.presenters,
            collectionId: details?.collectionId,
            skillId: details?.skillId
        };
    }, [sessionById]);

    const [iceServers, setIceServers] = useState<RTCIceServer[]>();
    const fetchIceServers = useCallback(async () => {
        const servers = await getICEServers();
        setIceServers(servers);
        return servers;
    }, []);
    useEffect(() => {
        fetchIceServers();
    }, [fetchIceServers]);

    const {
        initializeCapture,
        isPublishing,
        startPublishing,
        bindVideo,
        shutdown,
        mediaReady,
        cameraOn,
        toggleCamera,
        toggleCameraLoading,
        microphoneOn,
        toggleMicrophone,
        setMicrophoneOn,
        toggleMicrophoneLoading
    } = usePublisher({
        userId: currentUser?.userId,
        sessionId,
        setStartingToPresent,
        setPublishToken,
        isPresenting,
        fetchIceServers,
        iceServers
    });

    useEffect(() => {
        if (publishToken && mediaReady) {
            startPublishing(publishToken);
        }
    }, [publishToken, mediaReady, startPublishing]);

    // reset local microphoneOn state when stop presenting to prep for next time start presenting
    useEffect(() => {
        if (!publishToken) {
            setMicrophoneOn(true);
        }
    }, [publishToken, setMicrophoneOn]);

    useEffect(() => {
        return () => {
            shutdown();
        };
    }, [shutdown]);

    const updateAvailableDevices = useCallback(() => {
        navigator.mediaDevices
            .enumerateDevices()
            .then((devices) => {
                setDevices(devices);
            })
            .catch((err) => {
                console.error('Unable to list available media devices', err);
            });
    }, []);

    useEffect(() => {
        if (devices) {
            const { microphone, speaker, camera } = devicePreferences;
            if (microphone && !devices.find((device) => device.deviceId === microphone)) {
                setDevicePreferences((prev) => ({ ...prev, microphone: undefined }));
            }
            if (camera && !devices.find((device) => device.deviceId === camera)) {
                setDevicePreferences((prev) => ({ ...prev, camera: undefined }));
            }
            if (speaker && !devices.find((device) => device.deviceId === speaker)) {
                setDevicePreferences((prev) => ({ ...prev, speaker: undefined }));
            }
        }
    }, [devices, devicePreferences]);

    useEffect(() => {
        if (hasInitialized) {
            updateAvailableDevices();
            navigator.mediaDevices.addEventListener('devicechange', updateAvailableDevices);

            return () => {
                navigator.mediaDevices.removeEventListener('devicechange', updateAvailableDevices);
            };
        }
    }, [updateAvailableDevices, hasInitialized]);

    const getMedia = useCallback(
        async (shouldGetAudio: boolean, shouldGetVideo: boolean) => {
            const { microphone, camera } = devicePreferences;
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: shouldGetAudio && microphone ? { deviceId: { exact: microphone } } : shouldGetAudio,
                video: shouldGetVideo && camera ? { deviceId: { exact: camera } } : shouldGetVideo
            });

            const results = { audio: false, video: false };
            const tracks = stream.getTracks();
            tracks.forEach((track) => {
                if (track.kind === 'audio') {
                    results.audio = true;
                    if (!microphone) {
                        setDevicePreferences((prev) => ({ ...prev, microphone: track.getSettings().deviceId }));
                    }
                }
                if (track.kind === 'video') {
                    results.video = true;
                    if (!camera) {
                        setDevicePreferences((prev) => ({ ...prev, camera: track.getSettings().deviceId }));
                    }
                }
            });

            setHasInitialized(true);
            initializeCapture(stream);

            return results;
        },
        [initializeCapture, devicePreferences]
    );

    const getUserMedia = useCallback(async () => {
        setUserSelectedDevices(false);
        try {
            const res = await getMedia(true, true);
            setGotUserMedia(res);
            return true;
        } catch (err) {
            if (err.name === 'OverconstrainedError') {
                setDevicePreferences({});
                return false;
            }
            datadogLogs.logger.debug('Unable to get both audio and video streams - attempting to get audio only', {
                sessionId,
                userId: currentUser?.userId
            });
        }

        try {
            const res = await getMedia(true, false);
            setGotUserMedia(res);
            return true;
        } catch (err) {
            datadogLogs.logger.debug('Unable to get audio only', {
                sessionId,
                userId: currentUser?.userId
            });
        }

        setGotUserMedia({ audio: false, video: false });
        return false;
    }, [getMedia, sessionId, currentUser?.userId]);

    useEffect(() => {
        if (gotUserMedia?.video === false && cameraOn) {
            datadogLogs.logger.debug('Unable to get video stream - setting camera status as blocked', {
                sessionId,
                userId: currentUser?.userId
            });
            toggleCamera(MediaStatus.BLOCKED);
        }
    }, [gotUserMedia?.video, cameraOn, toggleCamera, currentUser?.userId, sessionId]);

    useEffect(() => {
        if (!hasInitialized || userSelectedDevices) {
            getUserMedia();
        }
    }, [hasInitialized, userSelectedDevices, getUserMedia]);

    const presenterMap = useMemo(
        () =>
            new Map(
                presenters?.map(({ userId, version, subscribeToken, pinned, micOn }, index) => [
                    index,
                    { userId, version, subscribeToken, pinned, micOn, spot: index }
                ])
            ),
        [presenters]
    );

    useEffect(() => {
        if (presenterMap) {
            datadogLogs.logger.debug(`Setting initial presenter map: ${JSON.stringify([...presenterMap])}`, {
                sessionId,
                userId: currentUser?.userId
            });
        }
    }, [presenterMap, currentUser?.userId, sessionId]);

    const realTimePresenters = useRealTimePresenters(presenterMap);

    const currentUserPresenterColor = useMemo(() => {
        const spot = realTimePresenters.get(currentUser?.userId ?? '')?.spot ?? 0;
        return PRESENTER_COLORS_BY_SPOT[spot];
    }, [realTimePresenters, currentUser?.userId]);

    return (
        <Provider
            value={{
                isPublishing,
                bindVideo,
                realTimePresenters,
                session,
                sessionId,
                mediaReady,
                microphoneOn,
                toggleMicrophone,
                toggleMicrophoneLoading,
                cameraOn,
                toggleCamera,
                toggleCameraLoading,
                devicePreferences,
                setDevicePreferences,
                devices,
                setUserSelectedDevices,
                userCameraStatuses,
                sessionUserIds,
                usersForSessionLoading,
                presenters,
                sessionLoading,
                setPublishToken,
                initialCollectionId: collectionId,
                initialSkillId: skillId,
                isPresenting,
                setIsPresenting,
                isDrawing,
                setIsDrawing,
                startingToPresent,
                setStartingToPresent,
                hasJoinedSession,
                gotUserMedia,
                getUserMedia,
                hideTooltipsById,
                setHideTooltipsById,
                outputSelectionSupported,
                setMicrophoneOn,
                polls,
                currentUserPresenterColor,
                fetchIceServers,
                iceServers
            }}
        >
            {children}
        </Provider>
    );
};

export { PublisherContext, PublisherProvider, Consumer as PublisherConsumer };
