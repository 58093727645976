import React, { useCallback, useState, useEffect } from 'react';

export type Order = 'asc' | 'desc';

function descendingComparator<D>(a: D, b: D, orderBy: keyof D) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Comparator<D> = (a: D, b: D) => number;

function getComparator<D>(order: Order, orderBy: keyof D): Comparator<D> {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<D>(array: D[], comparator: Comparator<D>): D[] {
    const stabilizedThis = array.map((el, index) => [el, index]) as [D, number][];
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface UseSortableTableOutput<T> {
    handleChangePage: (event: unknown, newPage: number) => void;
    handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
    sortData: <D extends Partial<T>>(data: D[]) => D[];
    page: number;
    rowsPerPage: number;
    order: Order;
    orderBy: keyof T;
}

/**
 *
 * @param T the array type the table is expecting
 * @param initialSortKey keyof T
 */

function useSortableTable<T>(
    initialSortKey: keyof T,
    initialOrder: Order = 'asc',
    initialPage = 0,
    initialRowsPerPage = 5
): UseSortableTableOutput<T> {
    const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
    const [page, setPage] = useState(initialPage);
    const [order, setOrder] = useState<Order>(initialOrder);
    const [orderBy, setOrderBy] = useState(initialSortKey);

    useEffect(() => {
        setRowsPerPage(initialRowsPerPage);
    }, [initialRowsPerPage]);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof T) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function sortCallback<D extends Partial<T>>(data: D[]): D[] {
        return stableSort<D>(data, getComparator<D>(order, orderBy)).slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        );
    }

    const sortData = useCallback(sortCallback, [order, orderBy, page, rowsPerPage]);

    return {
        handleChangePage,
        handleChangeRowsPerPage,
        handleRequestSort,
        sortData,
        page,
        rowsPerPage,
        order,
        orderBy
    };
}

export { useSortableTable };
