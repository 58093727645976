import React from 'react';

import { defaultMuiTheme } from '@adept-at/lib-react-components';
import styled from 'styled-components';
import { APP_HEADER_HEIGHT } from 'styles/variables';

import Header from '../components/navigation/Header';
import { HeaderProvider } from '../components/navigation/HeaderContext';
import Sidebar from '../components/navigation/Nav';
import { NavBackButtonOptions } from '../components/navigation/NavBackButtonOptions';

interface AppLayoutProps {
    withoutPadding?: boolean;
    hasSubNav?: boolean;
    pageTitle?: string;
    backButton?: boolean;
    backButtonOptions?: NavBackButtonOptions;
    fullHeight?: boolean;
    hideNav?: boolean;
    mainContentOverflow?: boolean; // Note: mainContentOverflow can be used when the main content doesn't have a subheader and needs to scroll at small widths like Dashboard.
    backgroundColor?: string;
}

const AppContent = styled.div<AppLayoutProps>`
    display: flex;
    width: 100%;
    height: calc(100vh - ${APP_HEADER_HEIGHT + 1}px);
    flex-direction: column;
    background-color: ${(props) => props.theme.colors.surface};
    transition: padding-left 220ms cubic-bezier(0.15, 1, 0.3, 1);
    overflow-y: auto;
`;

const MainContentWrapper = styled.div<AppLayoutProps>`
    padding: 0;
    ${defaultMuiTheme.breakpoints.up('sm')} {
        padding: ${(props) => (props.withoutPadding ? '0' : '2rem')};
    }

    height: ${(props) => (props.fullHeight ? '100%' : 'auto')};
    overflow-y: ${(props) => (props.mainContentOverflow ? 'auto' : 'hidden')};
    background-color: ${(props) => props.theme.colors.surface};
    overflow-x: hidden;
`;

const AppLayoutContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const InnerContainer = styled.div`
    display: flex;
`;

export const MAIN_CONTENT_WRAPPER_ID = 'MainContentWrapper';

const AppLayout: React.FC<AppLayoutProps> = ({
    children,
    withoutPadding,
    fullHeight,
    hideNav,
    backButton,
    backButtonOptions,
    mainContentOverflow,
    backgroundColor
}) => {
    // @TODO SAS is this safe?
    // const { currentProfile } = useContext(ProfileContext);

    // if (!currentProfile) {
    //     return <div>Loading...</div>;
    // }

    return (
        <AppLayoutContainer>
            <HeaderProvider>
                <Header
                    hideNav={hideNav}
                    backButton={backButton}
                    backButtonAction={backButtonOptions?.onClick}
                    backButtonIconPath={backButtonOptions?.iconPath}
                    backButtonTooltip={backButtonOptions?.tooltip}
                />

                <InnerContainer>
                    {hideNav ? null : <Sidebar />}
                    <AppContent backgroundColor={backgroundColor}>
                        <MainContentWrapper
                            id={MAIN_CONTENT_WRAPPER_ID}
                            withoutPadding={withoutPadding}
                            fullHeight={fullHeight}
                            mainContentOverflow={mainContentOverflow}
                        >
                            {children}
                        </MainContentWrapper>
                    </AppContent>
                </InnerContainer>
            </HeaderProvider>
        </AppLayoutContainer>
    );
};

export default AppLayout;
