import { AncestorCollection, WorkflowStatus } from 'components/CollaborationTable';
import { OwnerInfo } from 'components/ContentContext/Interfaces';
import { ClientError } from 'graphql-request';
import gql from 'graphql-tag';
import { useBuildAuthenticatedQueryFn } from 'hooks/useGqlClient/useBuildAuthenticatedQueryFn';
import { Api } from 'lib/ApiConstants';
import { QueryKey, useQuery, UseQueryResult } from 'react-query';

export const buildGetStudioSkillQueryKey = (skillId: string): QueryKey => ['getStudioSkill', skillId];

export interface GetStudioSkillResponse {
    getSkillById: {
        createdAt: string;
        updatedAt: string;
        title: string;
        subtitle: string;
        workflow?: keyof typeof WorkflowStatus;
        tenantSlug: string;
        skillSlug: string;
        free: boolean;
        loginRequired: boolean;
        visible: boolean;
        owner: OwnerInfo;
        ancestorCollections?: AncestorCollection[];
        estimatedSecondsToConsume: number;
    };
}

export const GET_STUDIO_SKILL_QUERY = gql`
    query getSkillById($skillId: UUIDv4!) {
        getSkillById(skillId: $skillId) {
            updatedAt
            createdAt
            title
            subtitle
            workflow
            tenantSlug
            skillSlug
            free
            loginRequired
            visible
            estimatedSecondsToConsume
            ancestorCollections {
                collectionId
                title
            }
            owner {
                type
                id
            }
        }
    }
`;

export const useGetStudioSkill = (skillId: string): UseQueryResult<GetStudioSkillResponse, ClientError> => {
    const { queryFn } = useBuildAuthenticatedQueryFn<GetStudioSkillResponse>(
        GET_STUDIO_SKILL_QUERY,
        { skillId },
        { api: Api.Content }
    );

    return useQuery<GetStudioSkillResponse, ClientError>(buildGetStudioSkillQueryKey(skillId), { queryFn });
};
