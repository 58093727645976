import { MessageEventType } from './type';
import PossibleEventFields from './type/PossibleEventFields';

export enum MessageAckType {
    Confirm = 'CONFIRM',
    Dismiss = 'DISMISS',
    View = 'VIEW',
    Hide = 'HIDE'
}

export enum MessageEntityType {
    Channel = 'CHANNEL',
    MeetingSession = 'CHANNEL_MEETING_SESSION'
}

export enum StandardMessageEntityType {
    Channel = 'channel',
    MeetingSession = 'channelMeetingSession'
}

export const MessageEntityTypeFromStandard = {
    [StandardMessageEntityType.Channel]: MessageEntityType.Channel,
    [StandardMessageEntityType.MeetingSession]: MessageEntityType.MeetingSession
};

export interface MessageAckDetail {
    type: MessageAckType;
    at: string;
}

export enum MessageDestination {
    Chat = 'chat',
    Dinger = 'dinger',
    Inbox = 'inbox',
    Banner = 'banner',
    InlineWidget = 'widget',
    Modal = 'modal',
    Push = 'push',
    Presence = 'presence',
    Meeting = 'meeting',
    MeetingSession = 'meetingSession'
}

export interface Reactions {
    [userId: string]: string[];
}

export default interface MessageRecord {
    id: string;
    name?: string;
    channelId: string;
    entityType: StandardMessageEntityType;
    entityId: string;
    senderId: string;
    sentAt: string;
    type: MessageEventType;
    version?: number;
    destinations?: MessageDestination[];
    fields: PossibleEventFields;
    hasValidAck: boolean;
    validAckTypes?: MessageAckType[]; // an ack of this type will handle the message
    acks: MessageAckDetail[];
    reactions: Reactions;
    parent?: string;
    responses?: number;
    responders?: Record<string, number>;
    deletedAt?: string;
}

// Support rules like "show to them in a week if they dismiss and don't confirm"
export enum MessageAckPromptFrequency {
    Daily = 'daily',
    Weekly = 'weekly',
    Monthly = 'monthly'
}

export interface RealTimeMessageRecord extends MessageRecord {
    ackRequired?: boolean;
    promptFrequency?: MessageAckPromptFrequency;
}
