import { useCallback, useContext, useEffect } from 'react';

import { CommunicationWebsocketContext } from 'context/CommunicationWebsocketContext';
import { ProfileType } from 'context/ProfileContext';
import { AvailableProfilesUpdateAction } from 'context/ProfileContext/useAvailableProfiles';
import useAckMessage from 'hooks/communication/useMessageActions/useAckMessage';
import MessageFactory from 'lib/communication/message/factory';
import MessageRecord, { MessageAckType } from 'lib/communication/message/MessageRecord';
import { MessageEventType } from 'lib/communication/message/type';
import UserAffiliationsModified from 'lib/communication/message/type/system/userAffiliationsModified';

const Listener = (refetch, dispatcher): void => {
    const { connection } = useContext(CommunicationWebsocketContext);

    const { ackMessage } = useAckMessage();

    const onMessage = useCallback(
        async (data: MessageRecord): Promise<void> => {
            try {
                const message = (await MessageFactory.construct(data).catch((e) => {
                    console.error(e, data);
                })) as UserAffiliationsModified;

                if (message?.type === MessageEventType.UserAffiliationsModified) {
                    /* If affiliation-added we want to try and highlight the newly added org. */
                    /* Would be sick if the message just sent us everything we needed? */
                    if (message.fields.name === 'user.affiliation.added') {
                        /* Potential API call to 'getAffiliationMeta?' */
                        refetch();
                    }

                    if (message.fields.name === 'user.affiliation.removed') {
                        dispatcher({
                            /* Placeholder Org to remove. No need to refetch. */
                            options: [
                                {
                                    type: ProfileType.Organization,
                                    label: 'Remove-Org',
                                    id: message.fields.affiliationId,
                                    alias: 'temp',
                                    tenants: []
                                }
                            ],
                            action: AvailableProfilesUpdateAction.Remove
                        });
                    }

                    ackMessage(message.channelId, message.id, MessageAckType.Confirm);
                }
            } catch (E) {
                console.log('Message Construction Error:', E);
                // Lets just ack the message so they don't pile up.
                ackMessage(data.channelId, data.id, MessageAckType.Confirm);
            }
        },
        [ackMessage, refetch, dispatcher]
    );

    useEffect(() => {
        if (connection) {
            const eventListener = ({ data }) => {
                if (data) {
                    onMessage(data as MessageRecord);
                }
            };

            connection.addEventListener('message', eventListener);

            return () => connection.removeEventListener('message', eventListener);
        }
    }, [onMessage, connection]);
};

export default Listener;
