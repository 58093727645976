import styled from 'styled-components';

interface EditorContainerProps {
    minHeight: number;
}

export const EditorContainer = styled.div<EditorContainerProps>`
    display: block;
    min-height: ${(props) => props.minHeight}px !important;
    background: inherit; // TODO: Was (props) => props.theme.accentBackgroundColor1
    font-size: 16px;
    color: inherit; // TODO: Was (props) => props.theme.accentColor6
    width: fill-available;
    resize: vertical;
    padding: 10px;
    position: relative;

    blockquote {
        border-left: 4px solid ${(props) => props.theme.colors.border};
        padding-left: 1rem;
    }

    a {
        color: ${(props) => props.theme.colors.primary};
    }

    &::placeholder {
        color: inherit; // TODO: Was (props) => props.theme.accentColor2
    }

    .public-DraftEditorPlaceholder-root {
        color: inherit; // TODO: Was (props) => props.theme.accentColor2
    }

    .public-DraftEditor-content {
        min-height: ${(props) => props.minHeight || '75'}px;
        cursor: text;
    }

    .public-DraftStyleDefault-pre {
        background: rgba(0, 0, 0, 0.05);
        padding: 20px;
        overflow-x: auto;

        pre {
            font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;

            div {
                white-space: nowrap;
            }
        }
    }

    .draft-editor-padded-block :not(ul):not(li) {
        margin: 1rem 0;
    }
`;
