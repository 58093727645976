import React, { useContext, useEffect, useMemo } from 'react';

import { Tabs } from '@material-ui/core';
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import SkillOutline from 'components/skills/SkillOutline';
import { LearnEditContext } from 'pages/learn/LearnEditContext';
import { getSections } from 'utils/skill/sections';

import { EditorContext } from '../builder/context/EditorContext';
import Guidance from '../builder/Guidance/Guidance';
import { CommentContext } from '../Comments/CommentContext';
import CommentSections from '../Comments/CommentSections';
import { LearnContext } from '../learn/LearnContext';
import { SidebarContext, SidebarTab } from '../SidebarContext';

import { Header, StyledIconCloseButton, StyledTab, StyledTabPanel } from './index.styles';

export const StandaloneOrBuildSidebarContent: React.FC = () => {
    const { parent, activeSection } = useContext(CommentContext);
    const learnCtx = useContext(LearnContext);
    const learnEditCtx = useContext(LearnEditContext);
    const { activeTab, setActiveTab, openSidebar, close } = useContext(SidebarContext);

    const editorCtx = useContext(EditorContext);

    // editor context will be loaded in for those who have the capability to edit a skill, so should go off of learnEditCtx
    const hasEditorContext = !!editorCtx?.editMode;
    const isEditMode = hasEditorContext && (!learnEditCtx || !!learnEditCtx?.skillEditId);
    const isPreviewMode = hasEditorContext && editorCtx.isPreview();

    const skillId = useMemo(
        () => learnCtx?.skill?.skillId ?? learnEditCtx?.skillEditId ?? editorCtx?.workingSkill?.skillId,
        [editorCtx?.workingSkill?.skillId, learnCtx?.skill?.skillId, learnEditCtx?.skillEditId]
    );

    const skill = useMemo(
        () => (isEditMode ? editorCtx?.workingSkill : learnCtx?.skill),
        [editorCtx?.workingSkill, isEditMode, learnCtx?.skill]
    );

    useEffect(() => {
        if (activeSection) {
            openSidebar({ isOpen: true });
        }
    }, [activeSection, openSidebar]);

    useEffect(() => {
        if (parent && parent !== skillId) {
            openSidebar({ isOpen: true });
        }
    }, [parent, skillId, openSidebar]);

    const handleTabChange = (event: React.ChangeEvent<unknown>, value) => {
        setActiveTab(value);
    };

    useEffect(() => {
        setActiveTab(SidebarTab.Outline);
    }, [setActiveTab]);

    const a11yProps = (index: number) => {
        return {
            id: `editor-tab-${index}`,
            'aria-controls': `editor-tabpanel-${index}`
        };
    };

    const commentsTabName = (): string => {
        if (!isEditMode) {
            return 'Comments';
        }

        if (isPreviewMode) {
            return 'Public Comments';
        }

        return 'Peer Review';
    };

    return (
        <>
            <Header aria-label="comments sidebar" id="sidebar">
                <Tabs
                    variant="scrollable"
                    scrollButtons="off"
                    value={activeTab}
                    onChange={handleTabChange}
                    textColor="primary"
                    indicatorColor="primary"
                >
                    <StyledTab label={commentsTabName()} {...a11yProps(0)} />
                    <StyledTab label="Outline" {...a11yProps(2)} data-testid="outline-tab" />
                    {isEditMode && !isPreviewMode && (
                        <StyledTab label="Best Practices" {...a11yProps(3)} data-testid="guidance-tab" />
                    )}
                </Tabs>
                <StyledIconCloseButton aria-label="close comments sidebar" onClick={() => close()}>
                    <Icon path={mdiClose} />
                </StyledIconCloseButton>
            </Header>
            <StyledTabPanel id="editor-tabpanel-0" value={activeTab} index={0}>
                <CommentSections />
            </StyledTabPanel>
            <StyledTabPanel value={activeTab} index={1} data-testid="outline-tab-panel" padding="2rem">
                <SkillOutline sections={getSections(skill)} />
            </StyledTabPanel>
            {isEditMode && (
                <StyledTabPanel id="editor-tabpanel-1" value={activeTab} index={2} data-testid="guidance-tab-panel">
                    <Guidance />
                </StyledTabPanel>
            )}
        </>
    );
};
