import React from 'react';

import { SectionTitleComponentValues } from '@adept-at/lib-react-components';

import { useComponentEngine } from '../../EngineContext';
import { EditableComponentProvider } from '../../mixins/editor';
import { ComponentEngineMode } from '../../types';

import SectionTitleEdit from './Edit';
import SectionTitleView from './View';

interface SectionTitleComponentProps {
    id: string;
    order: number;
}
export const DEFAULT_SECTION_TITLE = 'Enter Section Title...';

export const SectionTitle: React.FC<SectionTitleComponentProps & SectionTitleComponentValues> = ({
    id,
    order,
    ...componentValues
}) => {
    const { mode } = useComponentEngine();

    if (mode === ComponentEngineMode.Edit) {
        return (
            <EditableComponentProvider
                initialValues={{ ...componentValues, title: componentValues.title || DEFAULT_SECTION_TITLE }}
            >
                <SectionTitleEdit />
            </EditableComponentProvider>
        );
    }

    return <SectionTitleView {...componentValues} />;
};

export const title = 'Section';

export const Component = SectionTitle;
