import React, { useContext, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Modal from 'components/modals';
import { CollectionChild } from 'components/RootProfileCollection/context/useGetTenantProfileForOwner';
import useUpsertCollectionItem from 'components/RootProfileCollection/useUpsertCollectionItem';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { NestedCollectionContext } from './context/NestedCollectionContext';

interface AddExistingFormFields {
    title: string;
    id: string;
}

interface AddContentToCollectionModalProps {
    modalOpen: boolean;
    handleModalClose: () => void;
    options: { title: string; id: string }[] | undefined;
    loading: boolean;
}
const AddContentToCollectionModal: React.FC<AddContentToCollectionModalProps> = ({
    modalOpen,
    handleModalClose,
    options,
    loading
}) => {
    const { collectionId, refetchEditableCollection, collectionIdForEditableCollectionQuery, parentQuerySlug } =
        useContext(NestedCollectionContext);

    const [optionIdFromSelect, setOptionIdFromSelect] = useState<string | undefined>(undefined);

    const { handleSubmit, register, errors } = useForm<AddExistingFormFields>({
        mode: 'onBlur',

        resolver: yupResolver(
            Yup.object().shape({
                title: Yup.string().required('Selection required')
            })
        )
    });

    const { mutate: addItemToCollection } = useUpsertCollectionItem(
        collectionId,
        refetchEditableCollection,
        collectionIdForEditableCollectionQuery,
        parentQuerySlug
    );

    const onSubmit = () => {
        if (optionIdFromSelect) {
            addItemToCollection({
                collectionId,
                item: {
                    type: CollectionChild.Skill,
                    id: optionIdFromSelect
                }
            });
        }
        handleModalClose();
    };

    return (
        <Modal
            open={modalOpen}
            onClose={handleModalClose}
            ConfirmButton={() => (
                <Button form="add-content-form" variant="contained" color="primary" size="large" type="submit">
                    Add content
                </Button>
            )}
            modalTitle={'Add content'}
            labelProps={{ 'aria-label': 'Add content to collection modal' }}
        >
            <form onSubmit={handleSubmit(onSubmit)} id="add-content-form">
                <Autocomplete
                    loading={loading}
                    fullWidth
                    options={options ?? []}
                    getOptionLabel={(option) => option.title}
                    onChange={(event, value) => {
                        if (!optionIdFromSelect || optionIdFromSelect !== value?.id) setOptionIdFromSelect(value?.id);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            id="title"
                            type="text"
                            name="title"
                            label="Select existing content"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off' // disable browser autocomplete and autofill
                            }}
                            variant="outlined"
                            inputRef={register}
                            error={!!errors?.title}
                        />
                    )}
                />
            </form>
        </Modal>
    );
};

export default AddContentToCollectionModal;
