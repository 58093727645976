import React, { useContext, useCallback, useRef, useMemo } from 'react';

import {
    CollectionAboutContainer,
    CollectionBottomContainer,
    CollectionLoader,
    CollectionTopContainer,
    defaultMuiTheme
} from '@adept-at/lib-react-components';
import { useMediaQuery } from '@material-ui/core';
import { EntityType } from 'components/ContentContext/Enums';
import { ComponentEngineMode } from 'components/engine';
import CollectionBreadcrumbMenu from 'components/learn/CollectionBreadcrumbMenu';
import { NotFoundError } from 'components/NotFoundError';
import { ProductProvider } from 'components/product/ProductContext';
import { Container } from 'components/RootProfileCollection/Collections.styles';
import { NESTED_COLLECTION_CONTENT_ID } from 'components/RootProfileCollection/constants';
import { StickyCollectionActions } from 'components/RootProfileCollection/StickyCollectionActions';
import { useCommitEdits } from 'components/RootProfileCollection/useCommitEdits';
import { useDiscardEdits } from 'components/RootProfileCollection/useDiscardEdits';
import { CollectionActions } from 'hooks/authorization';
import { useIsElementVisible } from 'hooks/useIsElementVisible';
import { useSnackbar } from 'notistack';
import { useHistory, useParams } from 'react-router';

import { AboutSection } from './AboutSection';
import { CollectionActionWrapper } from './CollectionActions';
import { CollectionBackgroundImage } from './CollectionBackgroundImage';
import { CollectionTabs } from './CollectionTabs';
import { CollectionImageProvider } from './context/CollectionImageContext';
import { NestedCollectionContext } from './context/NestedCollectionContext';
import { Description } from './Description';
import { EditCollectionHeader } from './EditCollectionHeader';
import { PendingChangesAlert } from './PendingChangesAlert';

export const NestedCollection: React.FC = () => {
    const history = useHistory();
    const {
        authorizedActions,
        collectionId,
        collectionIdForEditableCollectionQuery,
        tenantSlug,
        getCollectionIsLoading,
        isEditMode,
        setIsEditMode,
        isPreviewMode,
        setIsPreviewMode,
        pendingChanges,
        expectedInfoVersionForEdits,
        expectedItemsVersionForEdits,
        refetchEditableCollection,
        setPendingChanges,
        parentQuerySlug,
        refetchParentCollection,
        productId,
        images,
        isVisible,
        aboutInfo,
        rootType,
        defaultCatalogImage,
        defaultFeaturedImage,
        childrenBasicDetails,
        getChildrenBasicDetailsStatus,
        collectionSlug,
        collectionData,
        isValidCollectionSlug
    } = useContext(NestedCollectionContext);

    const { enqueueSnackbar } = useSnackbar();

    const hideDescription = useMediaQuery(defaultMuiTheme.breakpoints.down('md'));

    const { currentOrgId, roomId } = useParams<{ currentOrgId?: string; roomId?: string }>();

    const enrollSectionRef = useRef<HTMLDivElement>(null);
    const isEnrollSectionVisible = useIsElementVisible(enrollSectionRef);
    const showStickyActions = !isEditMode && isEnrollSectionVisible !== undefined ? !isEnrollSectionVisible : false;

    const { mutate: commitEdits } = useCommitEdits(
        setPendingChanges,
        refetchEditableCollection,
        refetchParentCollection,
        parentQuerySlug
    );
    const { mutate: discardEdits } = useDiscardEdits(setPendingChanges, refetchEditableCollection, parentQuerySlug);

    const onItemSelect = useCallback(
        (trailingUrl, type) =>
            currentOrgId && roomId
                ? history.push(`/organization/${currentOrgId}/rooms/${roomId}/${trailingUrl}`)
                : history.push(`${type === EntityType.SKILL ? '/learn' : ''}/${tenantSlug}/${trailingUrl}`),
        [history, tenantSlug, currentOrgId, roomId]
    );

    const canEdit = useMemo(() => authorizedActions.includes(CollectionActions.UpdateMetadata), [authorizedActions]);

    const canCommitEdits = useMemo(
        () => authorizedActions.includes(CollectionActions.CommitEdits),
        [authorizedActions]
    );

    const onPublishChanges = () => {
        if (!canCommitEdits) {
            enqueueSnackbar('Not authorized to publish changes - Contact an Org Administrator to be granted rights.', {
                variant: 'warning',
                preventDuplicate: true
            });

            return;
        }
        if (collectionId && expectedInfoVersionForEdits !== undefined && expectedItemsVersionForEdits !== undefined) {
            commitEdits({
                collectionId,
                expectedInfoVersion: expectedInfoVersionForEdits,
                expectedItemsVersion: expectedItemsVersionForEdits
            });
        }
    };

    const onDiscardChanges = () => {
        if (!canCommitEdits) {
            enqueueSnackbar('Not authorized to discard changes - Contact an Org Administrator to be granted rights.', {
                variant: 'warning',
                preventDuplicate: true
            });

            return;
        }
        if (collectionId && expectedInfoVersionForEdits !== undefined && expectedItemsVersionForEdits !== undefined) {
            discardEdits({
                collectionId,
                expectedInfoVersion: expectedInfoVersionForEdits,
                expectedItemsVersion: expectedItemsVersionForEdits
            });
        }
    };

    if (!isValidCollectionSlug) return <NotFoundError />;

    if (getCollectionIsLoading || !collectionData) return <CollectionLoader />;

    return (
        <ProductProvider refetch={refetchParentCollection} productId={productId}>
            <CollectionImageProvider
                images={images}
                defaultCatalogImage={defaultCatalogImage}
                defaultFeaturedImage={defaultFeaturedImage}
                collectionId={collectionId}
                parentQuerySlug={parentQuerySlug}
                refetchEditableCollection={refetchEditableCollection}
                collectionIdForEditableCollectionQuery={collectionIdForEditableCollectionQuery}
            >
                <Container id="collection-wrapper">
                    <CollectionBreadcrumbMenu onItemSelect={onItemSelect} />
                    <StickyCollectionActions visible={showStickyActions}>
                        <CollectionActionWrapper isStickyMode />
                    </StickyCollectionActions>
                    {isEditMode || isPreviewMode ? (
                        <EditCollectionHeader
                            isVisible={isVisible}
                            aboutInfo={aboutInfo}
                            rootType={rootType}
                            collectionId={collectionIdForEditableCollectionQuery}
                            isEditMode={isEditMode}
                            isPreviewMode={isPreviewMode}
                            setIsEditMode={setIsEditMode}
                            setIsPreviewMode={setIsPreviewMode}
                            canEdit={canEdit}
                            entity={{ ...collectionData }}
                        />
                    ) : null}
                    <PendingChangesAlert
                        showAlert={isEditMode && pendingChanges}
                        onDiscardChanges={onDiscardChanges}
                        onPublishChanges={onPublishChanges}
                    />
                    <CollectionTopContainer>
                        <CollectionAboutContainer>
                            <AboutSection
                                enrollSectionRef={enrollSectionRef}
                                setIsEditMode={setIsEditMode}
                                entity={{ ...collectionData }}
                                canEdit={canEdit}
                            />
                        </CollectionAboutContainer>
                        <CollectionBackgroundImage />
                    </CollectionTopContainer>
                    <CollectionBottomContainer id={NESTED_COLLECTION_CONTENT_ID}>
                        {hideDescription ? null : (
                            <Description
                                refetchEditableCollection={refetchEditableCollection}
                                engineMode={
                                    isEditMode || isPreviewMode ? ComponentEngineMode.Edit : ComponentEngineMode.View
                                }
                                aboutInfo={aboutInfo}
                                collectionId={collectionId}
                                isEditMode={isEditMode}
                                collectionIdForEditableCollectionQuery={collectionIdForEditableCollectionQuery}
                                parentQuerySlug={parentQuerySlug}
                            />
                        )}
                        {currentOrgId && roomId ? null : (
                            <CollectionTabs
                                isNestedCollection
                                engineMode={
                                    isEditMode || isPreviewMode ? ComponentEngineMode.Edit : ComponentEngineMode.View
                                }
                                aboutInfo={aboutInfo}
                                childrenBasicDetails={childrenBasicDetails}
                                getChildrenBasicDetailsStatus={getChildrenBasicDetailsStatus}
                                isEditMode={isEditMode}
                                tenantSlug={tenantSlug}
                                collectionSlug={collectionSlug}
                                collectionId={collectionId}
                                refetchEditableCollection={refetchEditableCollection}
                                parentQuerySlug={parentQuerySlug}
                                collectionIdForEditableCollectionQuery={collectionIdForEditableCollectionQuery}
                            />
                        )}
                    </CollectionBottomContainer>
                </Container>
            </CollectionImageProvider>
        </ProductProvider>
    );
};

export default NestedCollection;
