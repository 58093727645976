import { useMemo } from 'react';

import { RichTextComponentValuesBody } from '@adept-at/lib-react-components';
import { WorkflowStatus } from 'components/CollaborationTable';
import {
    CollectionAuthor,
    Images,
    DefaultCollectionImageType,
    OwnerInfo,
    SkillAuthor
} from 'components/ContentContext/Interfaces';
import { CollectionType } from 'components/modals/Skills/useGetCollectionsForTenantProfile';
import { CollectionChild, CollectionStats } from 'components/RootProfileCollection/context/useGetTenantProfileForOwner';
import { collectionFragment } from 'components/RootProfileCollection/fragments';
import { CollectionProductDetails } from 'components/studio/collection/getCollection';
import { ClientError } from 'graphql-request';
import gql from 'graphql-tag';
import { useBuildAuthenticatedQueryFn } from 'hooks/useGqlClient/useBuildAuthenticatedQueryFn';
import { Api } from 'lib/ApiConstants';
import { ADEPT_USE_PRIMARY_HEADER } from 'lib/constants';
import { QueryKey, useQuery, UseQueryResult } from 'react-query';

export const buildGetNestedCollectionBySlugQueryKey = (slug?: string): QueryKey => ['getNestedCollectionBySlug', slug];
export interface EntityBySlug {
    collectionId: string;
    title: string;
    titleSub: string | null;
    description: RichTextComponentValuesBody | null;
    createdAt: string | null;
    stats: CollectionStats | null;
    images: Images | null;
    defCat?: DefaultCollectionImageType | null;
    defFeat?: DefaultCollectionImageType | null;
    owner: OwnerInfo;
    children: EntityChild[];
    visible: boolean;
    infoVersion: number;
    itemsVersion: number;
    editableCollectionId: string;
    immutableCollectionId: string;
    editable: boolean;
    collectionSlug: string;
    tags: string[] | null;
    authors: SkillAuthor[] | CollectionAuthor[];
    productDetails: CollectionProductDetails;
    workflow?: keyof typeof WorkflowStatus;
    rootType: CollectionType;
}

export interface EntityChild {
    id: string;
    type: CollectionChild;
}
export interface GetNestedCollectionBySlugQueryResponse {
    getEntityBySlug: EntityBySlug;
}

export const getNestedCollectionBySlugQuery = gql`
    query getEntityBySlug($slug: String!) {
        getEntityBySlug(slug: $slug) {
            ... on Collection {
                ${collectionFragment}
            }
        }
    }
`;

export const useGetNestedCollectionBySlug = (
    slug: string,
    usePrimary: boolean,
    enabled = true
): UseQueryResult<GetNestedCollectionBySlugQueryResponse, ClientError> => {
    const { queryFn } = useBuildAuthenticatedQueryFn<GetNestedCollectionBySlugQueryResponse, { slug: string }>(
        getNestedCollectionBySlugQuery,
        { slug },
        { api: Api.Content, headers: { [ADEPT_USE_PRIMARY_HEADER]: usePrimary } }
    );

    const queryKey = useMemo(() => buildGetNestedCollectionBySlugQueryKey(slug), [slug]);

    return useQuery<GetNestedCollectionBySlugQueryResponse, ClientError>(queryKey, {
        queryFn,
        enabled
    });
};
