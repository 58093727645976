import React from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { mdiCheckCircle } from '@mdi/js';
import Icon from '@mdi/react';
import styled, { css } from 'styled-components';
import { ProgressState } from 'utils/progress';

const ProgressWrapper = styled.div`
    width: 32px;
    position: relative;
`;

const Shared = css<{ $isFirst: boolean; $isLast: boolean }>`
    width: 8px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    ${(props) => (props.$isFirst ? First : null)}
    ${(props) => (props.$isLast ? Last : null)}
`;

const First = css`
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
`;

const Last = css`
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
`;

const ProgressBackground = styled.div<{ $isFirst: boolean; $isLast: boolean }>`
    ${Shared}
    height: 100%;
    background-color: ${(props) => props.theme.colors.border};
`;

const ProgressForeground = styled.div<{ $isFirst: boolean; $isLast: boolean; status: ProgressState }>`
    ${Shared}
    height: ${(props) =>
        props.status === ProgressState.COMPLETE ? '100%' : props.status === ProgressState.IN_PROGRESS ? '50%' : '0'};
    background-color: ${(props) => props.theme.colors.primary};
`;

const ProgressIcon = styled(Icon)`
    background-color: ${(props) => props.theme.colors.bottomSurface};
    color: ${(props) => props.theme.colors.primary};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const ProgressContainer = styled.div`
    display: flex;
    border: 1px solid transparent;
`;

interface OutlineProgressProps {
    isFirst: boolean;
    isLast: boolean;
    status: ProgressState;
}

export const OutlineProgress: React.FC<OutlineProgressProps> = ({ isFirst, isLast, status }) => {
    return (
        <ProgressWrapper>
            <ProgressBackground $isFirst={isFirst} $isLast={isLast} />
            <ProgressForeground $isFirst={isFirst} $isLast={isLast} status={status} />
            {status === ProgressState.COMPLETE ? <ProgressIcon path={mdiCheckCircle} size={IconSize.Medium} /> : null}
        </ProgressWrapper>
    );
};
