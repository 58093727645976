const DAY_NAMES = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const MONTH_NAMES = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

//'h:mm a'
export const formatTimeAsAMPM = (date: Date): string => {
    const workingDate = new Date(date);

    const hours = workingDate.getHours();
    const minutes = workingDate.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';

    const hoursInTwelveHourFormat = hours % 12;

    // the hour '0' should be '12'
    const hoursFixedForZeroHour = hoursInTwelveHourFormat ? hoursInTwelveHourFormat : 12;

    const minutesWithLeadingZero = minutes < 10 ? '0' + minutes : minutes;

    return `${hoursFixedForZeroHour}:${minutesWithLeadingZero} ${ampm}`;
};

// YYYY-MM-DD
export const formatDateForYearMonthDay = (date: Date): string => {
    const workingDate = new Date(date);
    const month = (workingDate.getMonth() + 1).toString();
    const day = (workingDate.getDate() + 1).toString();
    const year = workingDate.getFullYear();

    return [year, month.padStart(2, '0'), day.padStart(2, '0')].join('-');
};

export const TODAY_DATE_TEXT = 'Today';
export const YESTERDAY_DATE_TEXT = 'Yesterday';

export const lineBreakDateFormat = (dateKey: string): string => {
    const now = new Date();

    const todayAsDateKey = formatDateForYearMonthDay(now);

    const yesterday = new Date(now);
    yesterday.setDate(yesterday.getDate() - 1);

    const yesterdayAsDateKey = formatDateForYearMonthDay(yesterday);

    if (dateKey === todayAsDateKey) {
        return TODAY_DATE_TEXT;
    }

    if (dateKey === yesterdayAsDateKey) {
        return YESTERDAY_DATE_TEXT;
    }

    const workingDate = new Date(dateKey);

    return `${DAY_NAMES[workingDate.getDay()]}, ${MONTH_NAMES[workingDate.getMonth()]} ${workingDate.getDate()}`;
};

export const groupMessagesByDate = <TMessage extends { sentAt: string }>(
    messages: TMessage[]
): Record<string, TMessage[]> =>
    messages.reduce((memo, message) => {
        const { sentAt } = message;

        const dateKey = formatDateForYearMonthDay(new Date(sentAt));

        return {
            ...memo,
            [dateKey]: [message, ...(memo[dateKey] ?? [])]
        };
    }, {} as Record<string, TMessage[]>);

export const groupSessionsByDate = <TSession extends { startTime: string }>(
    sessions: TSession[]
): Record<string, TSession[]> =>
    sessions
        .sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime())
        .reduce((memo, session) => {
            const { startTime } = session;

            const dateKey = formatDateForYearMonthDay(new Date(startTime));

            return {
                ...memo,
                [dateKey]: [session, ...(memo[dateKey] ?? [])]
            };
        }, {} as Record<string, TSession[]>);
