import React from 'react';

import { IconSize, TooltippedIconButton } from '@adept-at/lib-react-components';
import { Box, Radio } from '@material-ui/core';
import { mdiInformationOutline } from '@mdi/js';
import Icon from '@mdi/react';
import styled from 'styled-components';

import { SelectTitle } from '../styles';

import { MarkLabelComponent } from './MarkLabelComponent';
import { paymentMarks } from './PaymentSlider';

const RadioContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 4.5rem;
`;

export const PaymentRadios: React.FC<{
    setConsumptionIncrement: (increment: number) => void;
    consumptionIncrement: number | null;
    enrollmentIncrement: number | null;
}> = ({ setConsumptionIncrement, consumptionIncrement, enrollmentIncrement }) => {
    if (enrollmentIncrement !== null && consumptionIncrement !== null) return null;

    return (
        <>
            <SelectTitle>
                Set your pace
                <TooltippedIconButton
                    title={'Select how long you want to be enrolled'}
                    icon={<Icon path={mdiInformationOutline} size={IconSize.Small} />}
                />
            </SelectTitle>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    marginRight: '0.5rem'
                }}
            >
                {paymentMarks.map(({ value, label }, i) => {
                    return (
                        <RadioContainer onClick={() => setConsumptionIncrement(value)} key={'consumption-pace-' + i}>
                            <Radio color="primary" style={{ width: '2rem', height: '2rem' }} />
                            <MarkLabelComponent label={label} pace={'per day'} />
                        </RadioContainer>
                    );
                })}
            </Box>
        </>
    );
};
