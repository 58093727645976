import { useCallback, useEffect, useMemo } from 'react';

import { LearningMode } from '@adept-at/lib-react-components';

import { useAccountabilityContext } from './accountability/AccountabilityContext';
import { useAssessmentContext } from './assessment/AssessmentContext';

interface UseLearningModeButtonOutput {
    mode: LearningMode;
    onChangeLearningMode: (mode: LearningMode) => void;
    disabledModes: LearningMode[];
}

export const useLearningModeButton = (): UseLearningModeButtonOutput => {
    const {
        handleModalOpen: openAccountabilityModal,
        accountabilityModeOn,
        disableAccountabilityMode
    } = useAccountabilityContext();
    const {
        handleModalOpen: openAssessmentModal,
        assessmentModeOn,
        disableAssessmentMode,
        skillHasQuestions
    } = useAssessmentContext();

    const currentMode = useMemo(() => {
        if (assessmentModeOn) {
            return LearningMode.ASSESSMENT;
        }

        if (accountabilityModeOn) {
            return LearningMode.ACCOUNTABILITY;
        }

        return LearningMode.STANDARD;
    }, [assessmentModeOn, accountabilityModeOn]);

    useEffect(() => {
        if (accountabilityModeOn) {
            disableAssessmentMode();
        }
    }, [accountabilityModeOn, disableAssessmentMode]);

    useEffect(() => {
        if (assessmentModeOn) {
            disableAccountabilityMode();
        }
    }, [assessmentModeOn, disableAccountabilityMode]);

    const resetLearningMode = useCallback(() => {
        disableAssessmentMode();
        disableAccountabilityMode();
    }, [disableAssessmentMode, disableAccountabilityMode]);

    const disabledModes = useMemo(
        () => [
            ...(skillHasQuestions ? [] : [LearningMode.ASSESSMENT]),
            LearningMode.ACCOUNTABILITY,
            LearningMode.FOCUS
        ],
        [skillHasQuestions]
    );

    const onChangeLearningMode = useCallback(
        (mode: LearningMode) => {
            switch (mode) {
                case LearningMode.STANDARD:
                    if (currentMode !== LearningMode.STANDARD) {
                        return resetLearningMode();
                    }
                    return;
                case LearningMode.ACCOUNTABILITY:
                    if (currentMode !== LearningMode.ACCOUNTABILITY) {
                        openAccountabilityModal();
                    }
                    return;
                case LearningMode.ASSESSMENT:
                    if (currentMode !== LearningMode.ASSESSMENT) {
                        openAssessmentModal();
                    }
                    return;
                default:
                    return;
            }
        },
        [resetLearningMode, openAccountabilityModal, openAssessmentModal, currentMode]
    );

    return {
        mode: currentMode,
        onChangeLearningMode,
        disabledModes
    };
};
