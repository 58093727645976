import { useMemo } from 'react';

import { OptimistMutationContext, OptimistRegisterUpdaterOptions, useOptimist } from '@adept-at/lib-react-optimist';
import clone from 'clone-deep';
import { Skill } from 'components/ContentContext/Interfaces';
import { buildGetSkillBySlugQueryKey, GetSkillBySlugResponse } from 'components/learn/getSkillBySlug';
import { ClientError } from 'graphql-request';
import useGqlClient from 'hooks/useGqlClient';
import { API_CONTENT } from 'lib/ApiConstants';
import { useSnackbar } from 'notistack';
import { useMutation, UseMutationResult } from 'react-query';

import {
    GetSkillByIdResponse,
    buildQueryKey as buildGetSkillByIdQueryKey
} from '../../../../skills/hooks/useGetSkillById';
import { ReorderedComponents, UpdateComponentsOrderResult, UpdateComponentsOrderVariables } from '../../interfaces';
import { SkillMutationProperties } from '../useUpdateSkillMeta';

import makeUpdateSkillComponentsOrder from './makeUpdateSkillComponentsOrder';

const useUpdateSkillComponentOrder = ({
    skillId,
    skillSlug,
    tenantSlug
}: SkillMutationProperties): UseMutationResult<
    UpdateComponentsOrderResult,
    ClientError,
    UpdateComponentsOrderVariables,
    OptimistMutationContext
> => {
    const { enqueueSnackbar } = useSnackbar();
    const { client, withMutationOptions } = useGqlClient(API_CONTENT);

    const {
        registerUpdater: registerUpsertSkillComponentUpdater,
        mutationOptions: UpsertSkillComponentMutationOptions
    } = useOptimist<UpdateComponentsOrderResult, ClientError, UpdateComponentsOrderVariables>();

    const UpsertSkillComponentOptions: OptimistRegisterUpdaterOptions<
        UpdateComponentsOrderResult,
        ClientError,
        UpdateComponentsOrderVariables
    > = useMemo(() => {
        return {
            onError: () => {
                enqueueSnackbar('An error occurred while updating the order.', { variant: 'error' });
            },
            onSuccess: () => {
                enqueueSnackbar('Order updated.', { variant: 'success' });
            },
            refetchQuery: false //don't want to refetch since multiple reorders at the same time can cause jumpiness between states
        };
    }, [enqueueSnackbar]);

    const skillQueryByIdKey = useMemo(() => buildGetSkillByIdQueryKey(skillId), [skillId]);
    const skillQueryBySlugKey = useMemo(
        () => buildGetSkillBySlugQueryKey(tenantSlug ?? '', skillSlug ?? ''),
        [tenantSlug, skillSlug]
    );

    const updateOrder = ({
        skill,
        updatedComponentsOrder
    }: {
        skill: Skill;
        updatedComponentsOrder: UpdateComponentsOrderVariables;
    }): Record<string, any> => {
        const skillComponentsClone = clone(skill?.components || {});
        try {
            const newOrder: ReorderedComponents = JSON.parse(updatedComponentsOrder?.componentsToOrder);
            const componentIds = Object.keys(newOrder);

            componentIds.map((id: string) => {
                skillComponentsClone[id].order = newOrder[id];
            });
        } catch (error) {}

        return skillComponentsClone;
    };

    registerUpsertSkillComponentUpdater<GetSkillByIdResponse>(
        skillQueryByIdKey,
        (previous, updatedComponentsOrder) => {
            const skill = previous?.getSkillById;
            const updatedComponents = updateOrder({ skill, updatedComponentsOrder });

            return {
                getSkillById: {
                    ...skill,
                    components: updatedComponents
                }
            };
        },
        UpsertSkillComponentOptions
    );

    registerUpsertSkillComponentUpdater<GetSkillBySlugResponse>(
        skillQueryBySlugKey,
        (previous, updatedComponentsOrder) => {
            const skill = previous?.getEntityBySlug;
            const updatedComponents = updateOrder({ skill, updatedComponentsOrder });

            return {
                getEntityBySlug: {
                    ...skill,
                    components: updatedComponents
                }
            };
        }
    );

    return useMutation<
        UpdateComponentsOrderResult,
        ClientError,
        UpdateComponentsOrderVariables,
        OptimistMutationContext
    >(makeUpdateSkillComponentsOrder(client), withMutationOptions(UpsertSkillComponentMutationOptions));
};

export default useUpdateSkillComponentOrder;
