import React from 'react';

import { TooltippedButtonSize } from '@adept-at/lib-react-components';
import ContentCard from 'components/dash/ContentCard';
import { FavoritesLoading } from 'components/dash/Favorites/FavoritesLoading';
import { FavoriteItem } from 'components/dash/hooks/useGetFavorites';
import styled from 'styled-components';

import { FavoriteItemExtraActions } from './FavoriteItemExtraActions';

export const CardDisplay = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-template-rows: repeat(auto-fit, minmax(auto, 1fr));
    grid-column-gap: 24px;
    grid-row-gap: 24px;
`;

export const FavoriteCards: React.FC<{ favorites: FavoriteItem[]; favoritesLoading: boolean }> = ({
    favorites,
    favoritesLoading
}) => {
    if (favoritesLoading) {
        return (
            <CardDisplay>
                <FavoritesLoading />
            </CardDisplay>
        );
    }

    return (
        <CardDisplay>
            {favorites.map((item, index) => (
                <ContentCard
                    item={item}
                    key={index}
                    extraActions={(isVisible: boolean) => (
                        <FavoriteItemExtraActions
                            favorite={item}
                            isVisible={isVisible}
                            buttonSize={TooltippedButtonSize.ExtraSmall}
                        />
                    )}
                />
            ))}
        </CardDisplay>
    );
};
