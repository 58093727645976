import React from 'react';

import { StyledTableCell } from './TableCell.styles';

interface TimeTrainedCellProps {
    timeTrained?: string;
}

export const TIME_TRAINED_CELL_WIDTH = 150;

export const TimeTrainedCell: React.FC<TimeTrainedCellProps> = ({ timeTrained }) => {
    return <StyledTableCell width={TIME_TRAINED_CELL_WIDTH}>{timeTrained}</StyledTableCell>;
};
