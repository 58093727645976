// Testing these would be re-writing the function itself
/* istanbul ignore next */

export const formatDateString = (dateString: string): string => {
    const date = new Date(dateString);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

// Testing these would be re-writing the function itself
/* istanbul ignore next */

export const convertIsoToDateString = (iso: string): string => {
    const date = new Date(iso);
    return date.toDateString();
};

export enum MonthFormat {
    Numeric = 'numeric',
    TwoDigit = '2-digit',
    Long = 'long',
    Short = 'short',
    Narrow = 'narrow'
}

export const formatDateStringToLocaleString = (dateString: string, locale: string, monthFormat?: MonthFormat): string =>
    new Date(dateString).toLocaleString(locale, {
        month: `${monthFormat ?? 'long'}`,
        day: 'numeric',
        year: 'numeric'
    });
