import React from 'react';

import { mdiUpload } from '@mdi/js';
import Icon from '@mdi/react';

import { SelectorOptionProps } from '../../ImageTypeSelector';
import { IconLine, ActionItem } from '../../ImageTypeSelector.styles';

export const SelectorOption: React.FC<SelectorOptionProps> = ({ onSelect }) => (
    <>
        <IconLine>
            <Icon path={mdiUpload} />
            <span>Upload an image</span>
        </IconLine>
        <ActionItem onClick={onSelect}>Select file</ActionItem>
    </>
);
