import { ClientError, gql } from 'graphql-request';
import { useBuildAuthenticatedQueryFn } from 'hooks/useGqlClient/useBuildAuthenticatedQueryFn';
import { Api } from 'lib/ApiConstants';
import { ADEPT_USE_PRIMARY_HEADER } from 'lib/constants';
import { QueryKey, useQuery, UseQueryResult } from 'react-query';

import { Skill } from '../../ContentContext/Interfaces';

export interface GetSkillByIdResponse {
    getSkillById: Skill;
}

// @GRAPHQL Query getSkillById
const queryName = 'getSkillById';

export const buildQueryKey = (skillId: string): QueryKey => [queryName, skillId];

export const AUTHORS_QUERY_STRING = gql`
    authors {
        userId
        authorType
    }
`;

const SKILL_IMAGES_QUERY_STRING = gql`
    images {
        catalog {
            id
            crop
            original {
                url
            }
            isProcessed
            processed @sizes(widths: [208]) {
                url
            }
        }
    }
`;

const COMMENT_PROPERTIES_STRING = gql`
    commentId
    body
    parent
    editedAt
    createdAt
    createdBy
    deletedAt
    thread
`;

export const BASE_SKILL_QUERY_STRING = gql`
   ${AUTHORS_QUERY_STRING}
    skillId
    title
    subtitle
    description
    organizationId
    slug
    skillSlug
    tenantSlug
    updatedAt
    createdAt
    ownedBy
    estimatedSecondsToConsume
    free
    visible
    loginRequired
    workflow
    ${SKILL_IMAGES_QUERY_STRING}
    owner {
        type
        id
    }
    ratings {
        likes
        dislikes
        currentUserRating
    }
    meta {
        storyboard
    }
    components
    blocks {
        key
        estimatedSecondsToConsume
    }
    comments {
        private {
            ${COMMENT_PROPERTIES_STRING}
        }
        public {
            ${COMMENT_PROPERTIES_STRING}
        }
    }
    tags
    views
    order
`;

const GET_SKILL_BY_ID_QUERY = gql`
    query getSkillById($skillId: UUIDv4!) {
        getSkillById(skillId: $skillId) {
            ${BASE_SKILL_QUERY_STRING}
        }
    }
`;

export const useGetSkillById = (
    skillId: string,
    usePrimary = false,
    enabled = true
): UseQueryResult<GetSkillByIdResponse, ClientError> => {
    const { queryFn } = useBuildAuthenticatedQueryFn<GetSkillByIdResponse, { skillId: string }>(
        GET_SKILL_BY_ID_QUERY,
        { skillId },
        { api: Api.Content, headers: { [ADEPT_USE_PRIMARY_HEADER]: usePrimary } }
    );

    return useQuery<GetSkillByIdResponse, ClientError>(buildQueryKey(skillId), {
        queryFn,
        enabled: enabled && !!skillId
    });
};
