import React from 'react';

import { QuestionComponentValues } from '@adept-at/lib-react-components';
import { Collapse, Fade, TextField, FormControl, IconButton, Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { BoxWithPadding } from '../../Question.styles';

interface Props {
    values: QuestionComponentValues;
    onChange: <TComponentFieldValue>(field: string) => (value: TComponentFieldValue) => void;
    onTypeChange: (key: string, index: number | string, value: any) => void;
}

export const HintEdit: React.FC<Props> = ({ values: { hints }, onChange, onTypeChange }) => {
    return (
        <>
            <Collapse timeout={500} in={!!hints.length}>
                <Fade timeout={800} in={!!hints.length}>
                    <FormControl component="fieldset">
                        {hints.map((answer, index) => (
                            <BoxWithPadding key={index} display="flex" alignItems="center">
                                <TextField
                                    id={`hintEdit-hint-${index}`}
                                    key={`${index}-hint`}
                                    label="Hint"
                                    name="hint"
                                    variant="outlined"
                                    fullWidth
                                    value={answer}
                                    onChange={(e) => {
                                        onTypeChange('hints', index, e.target.value);
                                    }}
                                    inputProps={{
                                        'data-testid': `hintEdit-hint-${index}`
                                    }}
                                />
                                <IconButton
                                    aria-label={`remove hint ${index}`}
                                    data-testid={`hintEdit-removeHint-${index}`}
                                    key={`${index}-icon-button`}
                                    onClick={() => {
                                        const draftHints = [...hints];
                                        draftHints.splice(index, 1);
                                        onChange('hints')(draftHints);
                                    }}
                                >
                                    <DeleteIcon key={`${index}-delete-icon`} />
                                </IconButton>
                            </BoxWithPadding>
                        ))}
                    </FormControl>
                </Fade>
            </Collapse>
            <BoxWithPadding display="flex" justifyContent="space-between">
                <Button
                    data-testid="hintEdit-addHint"
                    onClick={() => {
                        const draftHints = [...hints, ''];
                        onChange('hints')(draftHints);
                    }}
                >
                    Add{hints.length ? ' another ' : ' '}hint
                </Button>
            </BoxWithPadding>
        </>
    );
};

export default HintEdit;
