import { RichTextComponentValuesBody } from '@adept-at/lib-react-components';

export const NESTED_COLLECTION_CONTENT_ID = 'nested-collection-content';

export enum CollectionsContentFilter {
    All = 'All',
    Skills = 'Skills',
    Labs = 'Labs',
    Assessments = 'Assessments'
}

export interface AboutStats {
    skillsLink: string;
    skillsTotal: number;
    skillsHrsOfTraining: number;
    labsLink: string;
    labsTotal: number;
    labsTotalSkills: number;
    assessmentsLink: string;
    assessmentsTotal: number;
    assessmentsQuestionsTotal: number;
}

export interface AboutInfo {
    title: string;
    titleSub: string | null;
    description: RichTextComponentValuesBody | null;
    publicLink: string | null;
    tags: string[] | null;
}

export enum ContentLayoutView {
    List = 'List',
    Grid = 'Grid'
}
