import { useMemo } from 'react';

import { ClientError } from 'graphql-request';
import { useSnackbar } from 'notistack';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import useGqlClient from '../../../../../../../hooks/useGqlClient';
import { API_CONTENT } from '../../../../../../../lib/ApiConstants';
import { buildGetAssessmentByIdQueryKey } from '../../../../../../../pages/assessment/AssessmentEdit';

import makeSetPosterImageFrameTime from './makeSetPosterImageFrameTime';

export interface SetPosterImageFrameTimeResult {
    setPosterImageFrameTime: {
        success: boolean;
    };
}

export interface SetPosterImageFrameTimeVariables {
    videoId: string | null;
    frameTime: number;
}

const useSetPosterImageFrameTime = (
    skillId: string
): UseMutationResult<SetPosterImageFrameTimeResult, ClientError, SetPosterImageFrameTimeVariables> => {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const { client, withMutationOptions } = useGqlClient(API_CONTENT);

    // @TODO this should work for all containers, not just skills.
    // const skillQueryKey = useMemo(() => buildGetSkillByIdQueryKey(skillId), [skillId]);
    const skillQueryKey = useMemo(() => buildGetAssessmentByIdQueryKey(skillId), [skillId]);

    const setPosterImageFrameTimeOptions = useMemo(() => {
        return {
            onError: () => {
                enqueueSnackbar('An error occurred while setting poster image.', { variant: 'error' });
            },
            onSuccess: () => {
                enqueueSnackbar('Poster image set.', { variant: 'success' });
                queryClient.refetchQueries(skillQueryKey);
            }
        };
    }, [enqueueSnackbar, skillQueryKey, queryClient]);

    return useMutation<SetPosterImageFrameTimeResult, ClientError, SetPosterImageFrameTimeVariables>(
        makeSetPosterImageFrameTime(client),
        withMutationOptions(setPosterImageFrameTimeOptions)
    );
};

export default useSetPosterImageFrameTime;
