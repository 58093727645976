import { useMemo } from 'react';

import { OptimistMutationContext, useOptimist } from '@adept-at/lib-react-optimist';
import { EntityType } from 'components/ContentContext/Enums';
import { ClientError, gql } from 'graphql-request';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useBuildAuthenticatedMutationFn } from 'hooks/useGqlClient/useBuildAuthenticatedMutationFn';
import { Api } from 'lib/ApiConstants';
import { useMutation, UseMutationResult } from 'react-query';

import { buildGetItemIdsInBookshelvesQueryKey, GetItemIdsInBookshelvesResponse } from './getItemIdsInBookshelves';
import { bookshelfFragment } from './useGetBookshelves';

// @GRAPHQL Mutation upsertBookshelfItem
const queryName = 'upsertBookshelfItem';

const UPSERT_BOOKSHELF_ITEM_MUTATION = gql`
    mutation ${queryName}($bookshelfId: UUIDv4!, $itemId: UUIDv4!, $itemType: EntityType!) {
        ${queryName}(bookshelfId: $bookshelfId, item: { id: $itemId, type: $itemType }) {
            ${bookshelfFragment}
        }
    }
`;

interface UpsertBookshelfItemResult {
    upsertBookshelfItem: {
        title: string;
        bookshelfId: string;
        createdAt: string;
        updatedAt: string;
        totalItems: number;
    };
}

interface UpsertBookshelfItemVariables {
    bookshelfId: string;
    itemId: string;
    itemType: EntityType;
}

interface UseUpsertBookshelfItemOptions {
    onSuccess?: () => void;
    onError?: () => void;
}

export const useUpsertBookshelfItem = (
    bookshelfTitle: string,
    { onSuccess = () => {}, onError = () => {} }: UseUpsertBookshelfItemOptions
): UseMutationResult<UpsertBookshelfItemResult, ClientError, UpsertBookshelfItemVariables, OptimistMutationContext> => {
    const { currentUser } = useCurrentUser();

    const { registerUpdater, mutationOptions } = useOptimist<
        UpsertBookshelfItemResult,
        ClientError,
        UpsertBookshelfItemVariables
    >();

    const getItemIdsInBookshelvesQueryKey = useMemo(
        () => buildGetItemIdsInBookshelvesQueryKey(currentUser?.userId),
        [currentUser?.userId]
    );

    registerUpdater<GetItemIdsInBookshelvesResponse>(
        getItemIdsInBookshelvesQueryKey,
        (previous, { itemId, bookshelfId }) => {
            const previousBookshelves = previous?.getBookshelves ?? [];

            const updatedBookshelves = previousBookshelves.map((previous) => {
                if (previous?.bookshelfId === bookshelfId) {
                    return {
                        ...previous,
                        items: [...previous.items, { id: itemId }]
                    };
                }

                return previous;
            });

            return {
                getBookshelves: updatedBookshelves
            };
        }
    );

    const { mutationFn } = useBuildAuthenticatedMutationFn<UpsertBookshelfItemResult, UpsertBookshelfItemVariables>(
        UPSERT_BOOKSHELF_ITEM_MUTATION,
        { api: Api.Content }
    );

    return useMutation<UpsertBookshelfItemResult, ClientError, UpsertBookshelfItemVariables, OptimistMutationContext>(
        mutationFn,
        { ...mutationOptions, onSuccess, onError }
    );
};

export default useUpsertBookshelfItem;
