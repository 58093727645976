import { useContext, useMemo } from 'react';

import { ReceiptAdepteductMessageWithId, RealTimeCommunicationContext } from 'context/RealTimeCommunicationContext';
import AdepteductMessage from 'lib/communication/message/base/AdepteductMessage';
import { MessageDestination, MessageEntityType } from 'lib/communication/message/MessageRecord';
import PossibleEventFields from 'lib/communication/message/type/PossibleEventFields';
import { QueryStatus } from 'react-query';

import { sortMessagesBySentAtDesc } from '../../../utils/communication';

import useHistoricalMessagesForChannel, {
    UseHistoricalMessagesForChannelOutput
} from './useHistoricalMessagesForChannel';

interface UseChannelMessagesOptions {
    entityType: MessageEntityType;
    entityId: string;
}

export interface UseChannelMessagesOutput extends Omit<UseHistoricalMessagesForChannelOutput, 'messages' | 'status'> {
    messages: (AdepteductMessage<PossibleEventFields> | ReceiptAdepteductMessageWithId)[];
    historicalMessagesStatus: QueryStatus;
}

export const VALID_MESSAGE_DESTINATIONS = [
    MessageDestination.Chat,
    MessageDestination.Inbox,
    MessageDestination.Presence,
    MessageDestination.Meeting,
    MessageDestination.Banner
];

/**
 * Use this hook for all messages when you have a single channelId. Do not use the underlying contexts or hooks.
 * This will auto-fetch one page's worth of historical messages and direct all real-time messages to the output.
 * This outputs messages by the newest FIRST. Make sure to use flex-direction: column-reverse to display correctly.
 */
const useChannelMessages = ({ entityType, entityId }: UseChannelMessagesOptions): UseChannelMessagesOutput => {
    const { messages: realTimeMessagesByChannelId } = useContext(RealTimeCommunicationContext);
    const {
        messages: historicalMessages,
        status: historicalMessagesStatus,
        ...otherHistorical
    } = useHistoricalMessagesForChannel({
        entityType,
        entityId
    });

    const realTimeMessages = useMemo(() => {
        const realTimeMessagesForChannel = realTimeMessagesByChannelId?.[entityId];
        if (realTimeMessagesForChannel) {
            return realTimeMessagesForChannel;
        }
        return [];
    }, [entityId, realTimeMessagesByChannelId]);

    const realTimeMessageIds = useMemo(
        () => new Set(realTimeMessages.map((message) => message.id)),
        [realTimeMessages]
    );

    const combinedMessages = useMemo(() => {
        // Historical messages last for correct desc sort, but filtered on what is present in real time
        // We have to sort again because real-time messages may not all be newer than historical ones.
        return [
            ...(realTimeMessages ?? []),
            ...(historicalMessages?.filter(
                (historicalMessage) =>
                    !realTimeMessageIds.has(historicalMessage.id) &&
                    historicalMessage.hasValidDestination(VALID_MESSAGE_DESTINATIONS)
            ) ?? [])
        ].sort(sortMessagesBySentAtDesc);
    }, [historicalMessages, realTimeMessageIds, realTimeMessages]);

    return { messages: combinedMessages, historicalMessagesStatus, ...otherHistorical };
};

export default useChannelMessages;
