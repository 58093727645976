import { useMemo } from 'react';

import { useAuth } from 'context/AuthContext';
import { gql, ClientError } from 'graphql-request';
import { useBuildAuthenticatedQueryFn } from 'hooks/request/graphql/useBuildAuthenticatedQueryFn';
import { getRetryCallback } from 'hooks/request/retry';
import { Api } from 'lib/ApiConstants';
import { QueryKey, useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

export interface CurrentUser {
    userId?: string;
    alias?: string;
    emails?: CurrentUserEmail[];
    firstName?: string;
    lastName?: string;
    image?: string;
    name?: string;
}

interface CurrentUserEmail {
    address: string;
    primary: boolean;
    orgs?: string[] | null;
    verified: boolean;
}

export interface GetCurrentUserResponse {
    getCurrentUser: {
        id: string;
        alias: string;
        image: string;
        name: {
            first: string;
            last: string;
        };
        emails: CurrentUserEmail[];
    };
}

// @GRAPHQL Query getCurrentUser
const queryName = 'getCurrentUser';

export const buildCurrentUserQueryKey = (token: string | null): QueryKey => [queryName, token];

export const GET_CURRENT_USER_QUERY = gql`
    query ${queryName} {
        ${queryName} {
            id
            alias
            image
            name {
                first
                last
            }
            emails {
                address
                primary
                orgs
                verified
            }
        }
    }
`;

/**
 * Use this value and don't hit the server again for one hour.
 *
 * @TODO certain queries probably don't want stale data at all. We will allow them to override by passing in their own
 * staleTime value of Infinity
 *
 * Please read for more information on this caching strategy:
 *
 * https://react-query.tanstack.com/guides/caching
 * https://react-query.tanstack.com/guides/important-defaults
 */
const DEFAULT_STALE_TIME = 3600 * 1000;

export const useGetCurrentUser = (
    options: UseQueryOptions<GetCurrentUserResponse, ClientError>
): UseQueryResult<GetCurrentUserResponse, ClientError> => {
    const { token } = useAuth();

    const { queryFn } = useBuildAuthenticatedQueryFn<GetCurrentUserResponse>(
        GET_CURRENT_USER_QUERY,
        {},
        { api: Api.Accounts }
    );

    const getCurrentUserQueryKey = useMemo(() => buildCurrentUserQueryKey(token), [token]);

    return useQuery<GetCurrentUserResponse, ClientError>(getCurrentUserQueryKey, queryFn, {
        staleTime: DEFAULT_STALE_TIME,

        ...options,

        /**
         * Attention: If we cannot successfully determine the current user, application has reached a fatal state and
         * it's best that we display the fatal error appropriately. A throw here will leak out to the <ErrorBoundary>
         * for Auth
         */
        useErrorBoundary: true
    });
};
