import { useMemo } from 'react';

import { RequestDocument } from 'graphql-request/dist/types';
import { Api } from 'lib/ApiConstants';

import { UseBuildAuthenticatedQueryFnInterface, UseBuildAuthenticatedQueryFnOptions } from '../type';

import { useRequestWithToken } from './useRequestWithToken';

export const useBuildAuthenticatedMutationFn = <TMutationResponse, TVariables = Record<string, unknown>>(
    mutationDocument: RequestDocument,
    { api = Api.Accounts, headers }: UseBuildAuthenticatedQueryFnOptions
): UseBuildAuthenticatedQueryFnInterface<TMutationResponse, TVariables> => {
    const { getWorkingRequest } = useRequestWithToken(mutationDocument, api, headers);

    const mutationFn = useMemo(
        () =>
            (variables: TVariables): Promise<TMutationResponse> =>
                getWorkingRequest<TVariables, TMutationResponse>(variables),
        [getWorkingRequest]
    );

    return { mutationFn };
};
