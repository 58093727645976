import React, { useContext } from 'react';

import { Skill as TSkill } from '../../ContentContext/Interfaces';
import BuilderDetails from '../BuilderDetails';
import { EditorContext, EditMode } from '../context/EditorContext';
import { useDetermineStoryboardDisplay } from '../Meta/Storyboard/hooks/useDetermineStoryboardDisplay';
import Storyboard from '../Meta/Storyboard/Storyboard';

const Skill: React.FC<{ skill: TSkill }> = ({ skill }) => {
    const { editMode } = useContext(EditorContext);

    useDetermineStoryboardDisplay(skill);

    if (editMode === EditMode.Storyboard) {
        return <Storyboard />;
    }

    return <BuilderDetails skill={skill} />;
};

export default Skill;
