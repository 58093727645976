import {
    skillFragment,
    virtualLabFragment,
    collectionFragment,
    progressWithCompletedAtFragment
} from 'components/dash/fragments';
import { gql, ClientError } from 'graphql-request';
import { useBuildAuthenticatedQueryFn } from 'hooks/useGqlClient/useBuildAuthenticatedQueryFn';
import { Api } from 'lib/ApiConstants';
import { useQuery, QueryKey, UseQueryResult } from 'react-query';

import { Bookshelf } from './useGetBookshelf';

export interface GetBookshelvesResponse {
    getBookshelves: Bookshelf[];
}

// @GRAPHQL Query getBookshelves
export const queryName = 'getBookshelves';

export const buildQueryKey = (): QueryKey => queryName;

export const bookshelfEntityFragment = gql`
    ... on Skill {
        ${skillFragment}
        ${progressWithCompletedAtFragment}
    }
    ...on VirtualLab {
        ${virtualLabFragment}
    }
    ...on Collection {
        ${collectionFragment}
    }
`;

export const bookshelfItemsFragment = gql`
    id
    pin
    updatedAt
    entity {
        ${bookshelfEntityFragment}
    }
`;

export const bookshelfFragment = gql`
    bookshelfId
    title
    owner {
        id
        type
    }
    createdAt
    updatedAt
    totalItems
    items {
        ${bookshelfItemsFragment}
    }
`;

const GET_BOOKSHELVES_QUERY = gql`
    query ${queryName} {
        ${queryName} {
            ${bookshelfFragment}
        }
    }
`;

export const useGetBookshelves = (enabled: boolean): UseQueryResult<GetBookshelvesResponse, ClientError> => {
    const { queryFn } = useBuildAuthenticatedQueryFn<GetBookshelvesResponse>(
        GET_BOOKSHELVES_QUERY,
        {},
        { api: Api.Content }
    );

    return useQuery<GetBookshelvesResponse, ClientError>(buildQueryKey(), { queryFn, enabled });
};
