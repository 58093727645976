import React, { ReactElement } from 'react';

import { TooltippedIconButton } from '@adept-at/lib-react-components';
import { mdiDeleteRestore } from '@mdi/js';
import Icon from '@mdi/react';
import { EntityType } from 'components/ContentContext/Enums';

import useRestoreEntity from './useRestoreEntity';

interface RestoreButtonProps<T> {
    item: T;
}

const RestoreButton = <T extends { id: string; type: EntityType }>({ item }: RestoreButtonProps<T>): ReactElement => {
    const { mutate: restoreEntity } = useRestoreEntity();

    return (
        <TooltippedIconButton
            aria-label="Restore"
            icon={<Icon path={mdiDeleteRestore} />}
            title="Restore"
            onClick={(e) => {
                e.stopPropagation();
                restoreEntity({ entityInfo: { id: item.id, type: item.type } });
            }}
        />
    );
};

export default RestoreButton;
