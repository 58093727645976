import { useState, useCallback, useEffect } from 'react';

import { useDebouncedCallback } from 'use-debounce';

import { UserSearchOptionType } from './types';
import useGetUserByAlias from './useGetUserByAlias';
import useGetUserByEmail from './useGetUserByEmail';

const DEFAULT_DEBOUNCE_MS = 500;
const MINIMUM_SEARCH_LENGTH = 3;

export interface UserSearchResult {
    id: string;
    type: UserSearchOptionType;
    details: any;
    alias: string;
    email?: string;
}

export interface UseUserSearchProps {
    debounceInterval?: number;
    minimumSearchLength?: number;
}

interface UseUserSearchInterface {
    performSearch: (type: UserSearchOptionType, id: string) => void;
    searchResults: UserSearchResult[];
    clearResults: () => void;
    isSearchLoading: boolean;
    searchStatus: 'idle' | 'error' | 'loading' | 'success';
}

export const useUserSearch = ({
    debounceInterval = DEFAULT_DEBOUNCE_MS,
    minimumSearchLength = MINIMUM_SEARCH_LENGTH
}: UseUserSearchProps = {}): UseUserSearchInterface => {
    const [userAliasLookupValue, setUserAliasLookupValue] = useState<string | null>(null);
    const [userEmailLookupValue, setUserEmailLookupValue] = useState<string | null>(null);
    const [searchResults, setResults] = useState<UserSearchResult[]>([]);

    //theres a clean split in here somewhere to become DRY but alas it's lost on me. perhaps someone else can identify the split
    const { data: aliasData, isLoading } = useGetUserByAlias(userAliasLookupValue ?? '');
    const { data: emailData, isLoading: emailLoading, status } = useGetUserByEmail(userEmailLookupValue ?? '');

    const setResultsFromAliasData = useCallback(() => {
        if (!aliasData) {
            return;
        }

        const { id, alias } = aliasData?.getUserByAlias ?? { id: null, alias: null };

        if (!id || !alias) {
            setResults([]);
            return;
        }

        setResults([{ alias, id, type: UserSearchOptionType.UserId, details: {} }]);
    }, [aliasData]);

    const setResultsFromEmailData = useCallback(() => {
        if (!emailData) {
            return;
        }

        const { id, alias } = emailData?.getUserByEmail ?? { id: null, alias: null };

        if (!id || !alias || !userEmailLookupValue) {
            setResults([]);
            return;
        }

        setResults([{ alias, email: userEmailLookupValue, id, type: UserSearchOptionType.UserEmail, details: {} }]);
    }, [emailData]);

    const [lookupUserByUsername] = useDebouncedCallback((alias: string): void => {
        /**
         * aliasData won't change from useGetUserByAlias if it's the same search value as last time, so we need this check
         * to trigger the handler for that effect.
         *
         * This is useful in the case that a user enters a value for search, performs input changes that don't result
         * in a subsequent search for a different value, and then attempts to enter the previous value again.
         *
         * Note: We cannot rely on a consumer of this hook to inform us when a search does not occur. e.g. outside
         * validation prevents invoking the search.
         *
         * Derived state appears to be the best way to manage and transform the searchResults with consistency. We absorb
         * searchResults (aliasData) from the useGetUserByAlias hook and appropriately update searchResults to reflect.
         *
         * Search input reproduction example:
         * 1. 'username' - performs search and displays options
         * 2. '' - performs no search and displays no options
         * 3. 'username' - does not perform search (as it's cached) but options should still be displayed
         *
         * This logic will ensure that the options present 'sean' searchResults again
         */
        if (alias === userAliasLookupValue) {
            setResultsFromAliasData();
            return;
        }

        const sanitizedLookupValue = alias.toLowerCase();

        setUserAliasLookupValue(sanitizedLookupValue);
    }, debounceInterval);

    const [lookupUserByEmail] = useDebouncedCallback((email: string): void => {
        if (email === userEmailLookupValue) {
            setResultsFromEmailData();
            return;
        }

        const sanitizedLookupValue = email.toLowerCase();

        setUserEmailLookupValue(sanitizedLookupValue);
    }, debounceInterval);

    useEffect(() => {
        setResultsFromAliasData();
    }, [aliasData, setResultsFromAliasData]);

    useEffect(() => {
        setResultsFromEmailData();
    }, [emailData, setResultsFromEmailData]);

    return {
        isSearchLoading: isLoading || emailLoading,
        searchResults,
        searchStatus: status,
        clearResults: () => {
            setResults([]);
        },
        performSearch: (type: UserSearchOptionType, inputValue: string) => {
            if (inputValue.length < minimumSearchLength) {
                setResults([]);
                return;
            }

            if (type !== UserSearchOptionType.UserId && type !== UserSearchOptionType.UserEmail) {
                setResults([]);
                return;
            }

            if (type === UserSearchOptionType.UserEmail) {
                lookupUserByEmail(inputValue);
            } else {
                lookupUserByUsername(inputValue);
            }
        }
    };
};
