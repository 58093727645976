import React, { useEffect, useRef } from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import styled from 'styled-components';

import CroppingImageUploadModal from '../../modals/ImageUpload/CroppingImageUploadModal';

export enum ImageDestination {
    SkillCatalog = 'skill-catalog',
    SkillFeatured = 'skill-featured',
    SkillComponent = 'skill-component',
    LabCatalog = 'lab-catalog',
    LabFeatured = 'lab-featured',
    Avatar = 'avatar',
    CollectionCatalog = 'collection-catalog',
    CollectionCatalogDark = 'collection-catalog-dark',
    CollectionFeatured = 'collection-featured',
    CollectionFeaturedDark = 'collection-featured-dark'
}

export const ImageDestinationToType = {
    [ImageDestination.SkillFeatured]: 'featured',
    [ImageDestination.SkillCatalog]: 'catalog',
    [ImageDestination.LabFeatured]: 'featured',
    [ImageDestination.LabCatalog]: 'catalog',
    [ImageDestination.CollectionCatalog]: 'catalog',
    [ImageDestination.CollectionCatalogDark]: 'catalogDark',
    [ImageDestination.CollectionFeatured]: 'featured',
    [ImageDestination.CollectionFeaturedDark]: 'featuredDark'
};

export const UploadButton = styled.button`
    position: absolute;
    cursor: pointer;
    right: 0;
    top: 0;
    border: 0;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.45);
    padding: 5px;
    svg {
        width: ${IconSize.Small};
        margin: 0;
    }
`;

const ImageControls = styled.div<{ fullWidth }>`
    position: relative;
    max-width: 100%;
    width: ${(props) => (props.fullWidth ? '100%' : 'unset')};
`;

interface ImageUploadProps {
    imageModalOpen: boolean;
    setImageModalOpen: (isOpen: boolean) => void;
    fullWidth?: boolean;

    aspectRatio: number | null;
    imageURL: string | null;
    onFinishUpload: (
        assetId: string,
        imageDataURL: string,
        key: ImageDestination,
        dimensions: { width: string | number | null; height: string | number | null }
    ) => void;
    imageKey: ImageDestination;
    tags: string[];
}

const InlineUploadImage: React.FC<ImageUploadProps> = ({
    imageModalOpen,
    setImageModalOpen,
    fullWidth = false,
    aspectRatio = null,
    onFinishUpload,
    children,
    tags,
    imageKey
}) => {
    // Update this value everytime the modal is opened, this will cause the modal state to be reset each time.

    const modalOpenId = useRef<number>(0);
    const modalOpenRef = useRef<boolean>(imageModalOpen);

    const handleClose = () => {
        setImageModalOpen(false);
    };

    const closeAndCallFinish = (image, imageDataURL, dimensions) => {
        onFinishUpload(image, imageDataURL, imageKey, dimensions);
        setImageModalOpen(false);
    };

    useEffect(() => {
        if (imageModalOpen === true && modalOpenRef?.current !== imageModalOpen) {
            modalOpenId.current = modalOpenId.current + 1;
        }
    }, [imageModalOpen]);
    return (
        <ImageControls fullWidth={fullWidth}>
            <CroppingImageUploadModal
                isOpen={imageModalOpen}
                handleClose={handleClose}
                onFinishUpload={closeAndCallFinish}
                attributes={{ tags }}
                cropRatio={aspectRatio}
                key={modalOpenId.current} // updating value causes modal state reset
            />

            {children}
        </ImageControls>
    );
};

export default InlineUploadImage;
