import React from 'react';

import { EntityLoading } from 'components/EntityLoading';
import { LearnProvider } from 'components/learn/LearnContext';
import { Container } from 'components/RootProfileCollection/Collections.styles';
import { LearnEditLayout } from 'pages/skill/LearnEditLayout';
import SkillProviders from 'pages/skill/SkillProviders';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { LearnEditProvider } from '../LearnEditContext';

import { CommentsWrapper } from './CommentsWrapper';

const LoaderContainer = styled.div`
    display: flex;
    align-self: center;
    width: 100%;
`;

export const EditableSkill: React.FC = () => {
    const { skillSlug, tenantSlug } = useParams<{ skillSlug: string; tenantSlug: string }>();

    const SkillLoader = () => (
        <Container>
            <LoaderContainer>
                <EntityLoading />
            </LoaderContainer>
        </Container>
    );

    return (
        <LearnProvider skillSlug={skillSlug} tenantSlug={tenantSlug} LoadingComponent={SkillLoader}>
            <LearnEditProvider>
                <CommentsWrapper>
                    <SkillProviders>
                        <LearnEditLayout />
                    </SkillProviders>
                </CommentsWrapper>
            </LearnEditProvider>
        </LearnProvider>
    );
};
