import { useMemo } from 'react';

import { OptimistMutationContext, OptimistRegisterUpdaterOptions, useOptimist } from '@adept-at/lib-react-optimist';
import { Author, CollaboratorPermissionLevel, EntityInfo, SkillAuthor } from 'components/ContentContext/Interfaces';
import { GetSkillByIdResponse, buildQueryKey } from 'components/skills/hooks/useGetSkillById';
import { ClientError, gql } from 'graphql-request';
import useGqlClient from 'hooks/useGqlClient';
import buildGqlMutationFn from 'hooks/useGqlClient/helpers/buildGqlMutationFn';
import { API_CONTENT } from 'lib/ApiConstants';
import { useSnackbar } from 'notistack';
import { QueryKey, UseMutateFunction, useMutation } from 'react-query';

import { GetEntityCollaboratorsInvitesResponse, EntityCollaboratorsStatus } from './getEntityCollaboratorInvites';

const CREATE_MANY_ENTITY_COLLABORATORS = gql`
    mutation createManyEntityCollaborators(
        $entities: [EntityInfoInput]!
        $role: CollaboratorRole!
        $emails: [String]
        $userIds: [String]
        $message: String
        $overrideExistingPermissions: Boolean
    ) {
        createManyEntityCollaborators(
            entities: $entities
            role: $role
            emails: $emails
            userIds: $userIds
            message: $message
            overrideExistingPermissions: $overrideExistingPermissions
        ) {
            success
        }
    }
`;

interface CreateMultipleEntityCollaboratorResult {
    createManyEntityCollaborators: boolean;
}

interface CreateMultipleEntityCollaboratorsProps {
    entities: EntityInfo[];
    userIds: string[];
    role: CollaboratorPermissionLevel;
    emails: string[];
    message?: string;
    overrideExistingPermissions?: boolean;
}

interface BuildGetEntityCollaboratorsQueryKeyInput {
    entities: EntityInfo[];
    statuses: string[];
}

export const buildGetEntityCollaboratorsQueryKey = ({
    entities,
    statuses
}: BuildGetEntityCollaboratorsQueryKeyInput): QueryKey => [
    'createManyEntityCollaborators',
    entities.map(({ id }) => id),
    statuses
];

const useCreateManyEntityCollbaborators = ({
    onFinished,
    entities
}: {
    onFinished: () => void;
    entities: EntityInfo[];
}): {
    createManyEntityCollaborators: UseMutateFunction<
        CreateMultipleEntityCollaboratorResult,
        ClientError,
        CreateMultipleEntityCollaboratorsProps,
        OptimistMutationContext
    >;
    createManyEntityCollaboratorsLoading: boolean;
} => {
    const { enqueueSnackbar } = useSnackbar();

    const { registerUpdater: registerAddCollaborator, mutationOptions } = useOptimist<
        CreateMultipleEntityCollaboratorResult,
        ClientError,
        CreateMultipleEntityCollaboratorsProps
    >();

    const entitiesCollaboratorsQueryKey = useMemo(
        () =>
            buildGetEntityCollaboratorsQueryKey({
                entities: entities,
                statuses: [EntityCollaboratorsStatus.Active, EntityCollaboratorsStatus.Expired]
            }),
        [entities]
    );

    const addEntityCollaboratorsOptions: OptimistRegisterUpdaterOptions<
        CreateMultipleEntityCollaboratorResult,
        ClientError,
        CreateMultipleEntityCollaboratorsProps
    > = useMemo(() => {
        return {
            onSuccess: () => {
                onFinished();
                enqueueSnackbar('Successfully sent invite to users.', { variant: 'success' });
            },
            onError: () => {
                enqueueSnackbar('An error occurred inviting users.', { variant: 'error' });
            }
        };
    }, []);

    registerAddCollaborator<GetEntityCollaboratorsInvitesResponse>(
        entitiesCollaboratorsQueryKey,
        (previous, newCollaborators) => {
            const result = previous.getCollaboratorInvitations;
            entities.map((entity) => {
                newCollaborators.emails.forEach((email) => {
                    result.push({
                        id: null,
                        email,
                        status: EntityCollaboratorsStatus.Active,
                        role: newCollaborators.role,
                        sentAt: Date.now().toLocaleString(),
                        skillId: entity.id
                    });
                });
            });

            return { getCollaboratorInvitations: result };
        },
        addEntityCollaboratorsOptions
    );

    // optimistically update authors on skill
    const getSkillByIdQueryKey = useMemo(() => buildQueryKey(entities[0].id), [entities]);
    registerAddCollaborator<GetSkillByIdResponse>(getSkillByIdQueryKey, (previous, newAuthors) => {
        if (
            newAuthors.role !== CollaboratorPermissionLevel.Editor &&
            newAuthors.role !== CollaboratorPermissionLevel.PeerReviewer
        ) {
            return previous;
        }

        const authorsToUpdate: SkillAuthor[] = newAuthors.userIds.map((userId) => ({
            userId,
            authorType: newAuthors.role === CollaboratorPermissionLevel.Editor ? Author.Author : Author.PeerReviewer
        }));

        const responseUpdate = {
            ...previous.getSkillById,
            authors: [...previous.getSkillById.authors, ...authorsToUpdate]
        };

        return { getSkillById: responseUpdate };
    });

    const { client, withMutationOptions } = useGqlClient(API_CONTENT);
    const { mutate: createManyEntityCollaborators, isLoading: createManyEntityCollaboratorsLoading } = useMutation<
        CreateMultipleEntityCollaboratorResult,
        ClientError,
        CreateMultipleEntityCollaboratorsProps,
        OptimistMutationContext
    >(
        buildGqlMutationFn<CreateMultipleEntityCollaboratorResult, CreateMultipleEntityCollaboratorsProps>(
            client,
            CREATE_MANY_ENTITY_COLLABORATORS
        ),
        withMutationOptions(mutationOptions)
    );

    return {
        createManyEntityCollaborators,
        createManyEntityCollaboratorsLoading
    };
};

export default useCreateManyEntityCollbaborators;
