import React from 'react';

import styled from 'styled-components';

const List = styled.ol`
    padding-left: 1rem;
    margin: 1rem;
`;

export const Skill: React.FC = () => {
    return (
        <>
            <p>
                Your preparation creates your ceiling for success. Make sure to consider the following as you prepare to
                create:
            </p>

            <List>
                <li>If the learner only learns one thing, what would that one thing be (Main Intent)?</li>
                <li>How do you plan to approach teaching this?</li>
                <li>Which component types will you use?</li>
                <li>What will you need to learn before you can teach this?</li>
                <li>What notes will be most helpful to type up for each component?</li>
            </List>

            <p>
                Once you’re prepared, start. Just start typing or talking. Choose a text or video component and go. This
                is warming up the creative muscles. It’s a stream of consciousness that will get you creating. You’ll
                likely delete most of what you start with, but having a draft will motivate you to improve on it.
                Remember that you are the expert. Learn your subject matter until you are confident beyond reproach in
                your knowledge and ability, and then trust yourself to speak from the heart. Make your skill a
                representation of your desire to give and to help. Someone is going to appreciate what you are creating.
            </p>
        </>
    );
};

export const Text: React.FC = () => {
    return (
        <p>
            When adding text, think of every word as something your customer has to pay for with their time. Their time
            is a very valuable resource. Get your point across, communicate your message, but don’t use extra words
            unless your text is written for entertainment value. The text component is a great way to set up a video or
            image component. Using a text component between two video components can help you provide a transition. Use
            a text component when you think one of your other components needs explanation.
        </p>
    );
};

export const SectionTitle: React.FC = () => {
    return (
        <p>
            Multiple components can be used together to create one thought or convey a certain meaning. When you want to
            move to another subject, consider using a section title. Each section title you use will give your consumer
            a link to jump to a new area of your content.
        </p>
    );
};

export const Overview: React.FC = () => {
    return (
        <p>
            The overview is where you need to grab attention. Be concise, and get right to the point. You have two
            sentences to convince someone to continue to give you attention. These two sentences will be the text that
            shows up in search engines and store listings to sell your content. The overview should present the reason
            for your content. Don’t create content unless you have a reason. Capture that reason here.
        </p>
    );
};

export const Conclusion: React.FC = () => {
    return (
        <p>
            The conclusion is where you want to instill pride and confidence in your learner. “This is what you learned,
            this is what you can do.” It doesn’t need to be long, concise always wins over verbose when it comes to
            instructional copy. Learners may look to both the overview and the conclusion to determine whether or not
            they want to consume the content in between. If you didn’t already do this throughout the skill, let your
            learner know what the next step is and if possible direct them to a specific next step.
        </p>
    );
};

export const QuizQuestion: React.FC = () => {
    return (
        <p>
            When your intention is to have interaction, the quiz component will engage the consumer. The quiz question
            can be used as a poll, a trivia question, a learning tool, or simply a way to make sure your consumer is
            paying attention. Content that engages the consumer will be more successful than content that is merely read
            or watched.
        </p>
    );
};

export const Title: React.FC = () => {
    return (
        <p>
            The title is very important. This is where your primary exposure to your customer comes from. Use a maximum
            of 70 characters (including spaces) if you want the entire title used in search listings and storefront
            listings, otherwise everything after 70 characters will be concatenated like so:{' '}
            <em>Lessons Learned from the Great Depression and how those lessons relate to the M…</em> Your title should
            be descriptive and representative of your content, and it should also make your customer want to consume it.
        </p>
    );
};

export const Description: React.FC = () => {
    return (
        <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum, tempore, vel? Architecto consequuntur nam
            recusandae tempora tempore voluptas. Architecto ipsam quaerat quos sequi sint unde.
        </p>
    );
};

export const Image: React.FC = () => {
    return (
        <p>
            A picture is worth a thousand words. Images mixed in with your written text and video provide a powerful
            visual aid to learners. Use images that relate closely to your subject and have significant educational
            value.
        </p>
    );
};

export const Why: React.FC = () => {
    return (
        <p>
            The consumer needs an answer to the why question to proceed. Why do I need this? Why do I want this? Why
            does this matter? Edit the “why” text below, or use the storyboarder to create a “why”.
        </p>
    );
};

export const Who: React.FC = () => {
    return (
        <p>
            You need to know who you’re creating for in order to have empathy and see your content through your
            consumer’s eyes. In addition, the consumer needs to know that this content is created for a specific target
            that the consumer fits into.
        </p>
    );
};

export const How: React.FC = () => {
    return (
        <p>
            Expectations need to be set. The consumer needs to know how long this will take, how much effort, and the
            expectation of participation.
        </p>
    );
};

export const Code: React.FC = () => {
    return (
        <p>
            Use this component any time your intention is to have someone copy the text within and then paste somewhere
            else. Typically the text will be a command pasted into a terminal or a block of code pasted into a code
            editor.
        </p>
    );
};

export const Attachment: React.FC = () => {
    return <p>Use the Attachment component when you want to supplement your content with a downloadable file.</p>;
};

export const Video: React.FC = () => {
    return (
        <>
            <p style={{ marginBottom: '1rem' }}>
                Video content is best used when you need to connect with an audience. If you are a better writer than
                speaker, write, otherwise video can be your primary form of content. With video, make sure not to repeat
                yourself, and keep your videos as short as possible. Get an idea in your head of what you want to
                communicate, hit record and go. When you talked about what you prepared to talk about, hit stop. Don’t
                worry about transitions. You can effectively transition from one topic to the next with a text block if
                you want or need to. Don’t use a different voice when you record. Be yourself and be relaxed. Have
                someone record you talking when you are unaware so you hear what your voice sounds like naturally.
                Compare that to your recorded videos to make sure your recordings are natural and unaffected. Your goal
                is to connect with your audience. Remember that means the real you needs to connect with that audience,
                not the version of you that you imagine they want to hear, but the real you.
            </p>
            <p>
                Anytime you want to demonstrate how to do something on a computer, consider using the screen capture
                video. When recording, realize that it isn’t always easy to follow a cursor when you are viewing someone
                else using a computer. To compensate for this, speak about your movements and clicks; “now I’m going to
                click on the start menu and then click on the icon you see here”. Tell your viewers to perform the same
                actions on their computer. Many viewers will only do what the presenter tells them to do. By default,
                viewers will normally just watch video. You’ll need to encourage any participation you want to happen.
            </p>
        </>
    );
};
