import React, { useState, ReactElement } from 'react';

import { Paginator } from '@adept-at/lib-react-components';
import TextField from '@material-ui/core/TextField';
import { useForm } from 'react-hook-form';

import { SearchContainer, StyledAlert, NoResults, FlexContainer, ImageColumn, ImageContainer } from './Search.styles';
import { searchPhotos, trackDownload } from './util';

interface KeywordsForm {
    keywords: string;
}

export interface UnsplashImage {
    id: string;
    alt_description: string;
    urls: {
        full: string;
        thumb: string;
    };
    links: {
        download_location: string;
    };
    user: {
        name: string;
        links: {
            html: string;
        };
    };
}

interface UnsplashSearchProps {
    onSelectImage: (image: UnsplashImage) => void;
}

export const UnsplashSearch = ({ onSelectImage }: UnsplashSearchProps): ReactElement => {
    const { register, handleSubmit } = useForm<KeywordsForm>();
    const [unsplashResults, setUnsplashResults] = useState(new Array<UnsplashImage>());
    const [page, setPage] = useState(1);
    const [query, setQuery] = useState('');
    const [totalPages, setTotalPages] = useState(0);
    const [noResults, setNoResults] = useState(false);
    const [error, setError] = useState('');

    const onSelect = (image: UnsplashImage): void => {
        const unsplashResult = unsplashResults.find(({ id }) => id === image.id);
        if (unsplashResult) trackDownload(unsplashResult);
        onSelectImage(image);
    };

    const searchUnsplash = (keywords, pageNum) => {
        searchPhotos(keywords, pageNum, 9)
            .then((json) => {
                if (json.errors && !json.results) {
                    setError(json.errors[0]);
                    return;
                }
                setTotalPages(json.total_pages ?? 0);
                setUnsplashResults(json.results);
                if (json.results.length < 1) {
                    setNoResults(true);
                }
            })
            .catch((_error) => {
                setError('An unknown error occurred');
            });
    };

    const onSubmit = ({ keywords }) => {
        setError('');
        setNoResults(false);
        setQuery(keywords);
        setPage(1);
        searchUnsplash(keywords, 1);
    };

    const changePage = (pageNum) => {
        setPage(pageNum);
        searchUnsplash(query, pageNum);
    };

    const chunkResults = () => {
        const copy = [...unsplashResults];
        const itemsPerArray = Math.floor(copy.length / 3);
        const extraItems = copy.length % 3;
        return [
            copy.splice(0, itemsPerArray + (extraItems >= 1 ? 1 : 0)),
            copy.splice(0, itemsPerArray + (extraItems === 2 ? 1 : 0)),
            copy.splice(0, itemsPerArray)
        ];
    };

    const chunkedResults = chunkResults();

    if (error) {
        console.error(`Unsplash API error: ${error}`);
    }

    return (
        <SearchContainer>
            {!!error && <StyledAlert severity="error">Unable to fetch Unsplash images.</StyledAlert>}
            <form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                    name="keywords"
                    id="keywords"
                    placeholder="Type keywords to search Unsplash, and press Enter"
                    fullWidth
                    autoFocus
                    inputRef={register({ required: true })}
                />
            </form>
            {noResults && <NoResults>No results</NoResults>}
            <Paginator
                currentPage={page}
                totalPages={totalPages}
                onPreviousClick={() => changePage(page - 1)}
                onNextClick={() => changePage(page + 1)}
            />
            <FlexContainer>
                {chunkedResults.map((chunk, index) => (
                    <ImageColumn key={index}>
                        {chunk.map((image) => (
                            <ImageContainer key={image.id}>
                                <img
                                    onClick={() => onSelect(image)}
                                    alt={image.alt_description}
                                    src={image.urls.thumb}
                                />
                                <a href={image.user.links.html} target="_blank" rel="noreferrer">
                                    {image.user.name}
                                </a>
                            </ImageContainer>
                        ))}
                    </ImageColumn>
                ))}
            </FlexContainer>
        </SearchContainer>
    );
};
