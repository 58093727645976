import React, { useContext, useMemo } from 'react';

import { LearnEditContext } from 'pages/learn/LearnEditContext';

import { BuildingHeaderContent } from '../../../components/builder/BuildingHeader/BuildingHeaderContent';
import { EditorContext } from '../../../components/builder/context/EditorContext';
import { LearningHeaderContent } from '../../../components/learn/LearningHeader/LearningHeaderContent';

export const HeaderContent: React.FC<{ skillId?: string }> = ({ skillId }) => {
    const editorCtx = useContext(EditorContext);
    const { setSkillEditId } = useContext(LearnEditContext);
    const editorReady = useMemo(() => !!editorCtx?.workingSkill, [editorCtx?.workingSkill]);

    return editorReady && skillId ? (
        <BuildingHeaderContent onToggleEdit={() => setSkillEditId('')} skillId={skillId} />
    ) : (
        <LearningHeaderContent />
    );
};
