import React, { useContext, useEffect, useMemo, useRef } from 'react';

import { RichTextComponentValues, RichTextComponentValuesBody } from '@adept-at/lib-react-components';
import FormControl from '@material-ui/core/FormControl';
import { HeaderContext } from 'components/navigation/HeaderContext';
import { useDebouncedCallback } from 'use-debounce/lib';

import { useComponentEngineComponent } from '../../';
import RichTextEditor from '../../common/RichTextEditor';
import { useEditableComponent, ComponentFocus } from '../../mixins/editor';
import { useFocusableComponent } from '../../mixins/focusable';

import { TextEditForm } from './Edit.styles';
import TextView from './View';

const TextEdit: React.FC = () => {
    const { setIsSaving } = useContext(HeaderContext);
    const { id } = useComponentEngineComponent();
    const initialLoad = useRef(true);

    const { doesComponentHaveAnyFocus } = useFocusableComponent();

    const {
        currentValues: { body },
        onSave,
        onChange
    } = useEditableComponent<RichTextComponentValues>();

    const [save, _cancel, callPending] = useDebouncedCallback(() => onSave(['body']), 2000);

    const stringifiedBody = useMemo(() => (body?.val ? JSON.stringify(body.val) : null), [body?.val]);
    const bodyIsNull = useRef(!stringifiedBody);
    useEffect(() => {
        bodyIsNull.current = !stringifiedBody;
    }, [stringifiedBody]);

    useEffect(() => {
        if (bodyIsNull.current) {
            return;
        }
        if (initialLoad.current) {
            initialLoad.current = false;
            return;
        }
        if (stringifiedBody) {
            setIsSaving(true);
            save();
        }
    }, [stringifiedBody, save, setIsSaving]);

    useEffect(() => {
        return () => {
            callPending();
        };
    }, [callPending]);

    if (!doesComponentHaveAnyFocus(id, [ComponentFocus.Edit, ComponentFocus.AddAndEdit])) {
        return <TextView body={body} />;
    }

    return (
        <TextEditForm>
            <FormControl component="fieldset">
                <RichTextEditor
                    componentId={id}
                    value={body}
                    onChange={onChange<RichTextComponentValuesBody>('body')}
                />
            </FormControl>
        </TextEditForm>
    );
};

export default TextEdit;
