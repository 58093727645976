import { CommonComponentValues, ComponentValuesType } from '@adept-at/lib-react-components';

import { EntityType } from '../ContentContext/Enums';

// @TODO make typescript happy
export type EngineComponent = CommonComponentValues | any;

enum UpdateResultTypes {
    noop = 'noop',
    inserted = 'inserted',
    updated = 'updated',
    ordered = 'ordered'
}

enum UpdateResultComponentUpdateTypes {
    noop = 'noop',
    ordered = 'ordered'
}

export interface UpsertComponentResult {
    upsertComponent: {
        result: UpdateResultTypes;
        components: {
            result: UpdateResultComponentUpdateTypes;
            items: { [id: string]: EngineComponent };
        };
    };
}

interface Entity {
    type: EntityType;
    id: string;
}

export interface UpsertComponentVariables {
    entity: Entity;
    componentType: ComponentValuesType;
    componentId: string;
    order: number;
    values: any;
}

export interface RemoveComponentVariables {
    entity: Entity;
    componentId: string;
}

export interface RemoveComponentResult {
    removeComponent: {
        success: boolean;
    };
}

export enum ComponentEngineMode {
    Edit = 'edit',
    View = 'view',
    Accountability = 'accountability',
    Presentation = 'presentation',
    Collaboration = 'collaboration',
    Assessment = 'assessment'
}
