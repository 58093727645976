import { useContext, useCallback } from 'react';

import { ProfileContext, ProfileType } from '.';

interface UseSelectPersonalProfileInterface {
    selectPersonalProfile: () => void;
}

export const useSelectPersonalProfile = (): UseSelectPersonalProfileInterface => {
    const { selectProfile, availableProfiles } = useContext(ProfileContext);

    const selectPersonalProfile = useCallback(() => {
        const personalProfile = availableProfiles.find(({ type }) => type === ProfileType.Personal);

        if (personalProfile) {
            selectProfile(personalProfile);
        }
    }, [availableProfiles, selectProfile]);

    return { selectPersonalProfile };
};
