import React, { useMemo } from 'react';

import { getEditedCollectionsFromSessionStorage } from 'utils/collection';

import { OrgMemberCatalog } from './OrgMemberCatalog';
import { OrgMemberInternal } from './OrgMemberInternal';

export const OrgMember: React.FC<{ orgSlug: string; organizationId: string; isInternal: boolean }> = ({
    organizationId,
    orgSlug,
    isInternal
}) => {
    const usePrimary = useMemo(() => {
        if (!organizationId) return false;
        const isInternalPrefix = isInternal ? 'internal-' : '';
        const editedCollections = getEditedCollectionsFromSessionStorage();
        return editedCollections.includes(`${isInternalPrefix + organizationId}`);
    }, [organizationId, isInternal]);

    if (isInternal)
        return <OrgMemberInternal orgSlug={orgSlug} organizationId={organizationId} usePrimary={usePrimary} />;

    return <OrgMemberCatalog orgSlug={orgSlug} organizationId={organizationId} usePrimary={usePrimary} />;
};
