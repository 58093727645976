import { RealTimeMessagesReducerState, ReceiptAdepteductMessage } from '.';

const handleMessageExchange = (
    state: RealTimeMessagesReducerState,
    values: ReceiptAdepteductMessage
): RealTimeMessagesReducerState => {
    const { channelId, temporaryId, receiptId } = values;

    return {
        ...state,
        [channelId]:
            state?.[channelId]?.map((workingMessage) => {
                const id = workingMessage.id;

                if (id === temporaryId) {
                    workingMessage.id = receiptId;
                }

                return workingMessage;
            }) ?? []
    };
};

export default handleMessageExchange;
