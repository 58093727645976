import React, { useRef } from 'react';

import { ComponentContainer, Spacer } from '../builder/Builder.styles';
import { ComponentEngineComponent } from '../engine/';
import { EngineComponent } from '../engine/types';

// import { CommentContext } from '../../Comments/CommentContext';

// @TODO Where should display items come from? e.g. ComponentContainer, Spacer

const Component: React.FC<EngineComponent | null> = ({ id, type, order, values }) => {
    // const { parent } = useContext(CommentContext);

    const ref = useRef<HTMLDivElement>(null);

    return (
        <>
            {type === 'sectionTitle' ? <Spacer /> : null}

            <ComponentContainer ref={ref} id={`component-${id}`}>
                <ComponentEngineComponent container={ref} id={id} type={type} order={order} values={values} />
            </ComponentContainer>
        </>
    );
};

export default Component;
