import React from 'react';

import { IconSize, LightTheme } from '@adept-at/lib-react-components';
import { IconButton, makeStyles } from '@material-ui/core';
import { mdiDelete, mdiPencil, mdiReply } from '@mdi/js';
import Icon from '@mdi/react';
import { useCurrentUser } from 'hooks/useCurrentUser';
import styled from 'styled-components';

export const useTooltipStyles = makeStyles({
    popper: {
        top: '10px !important'
    },
    tooltip: {
        margin: '0',
        padding: '0',
        backgroundColor: LightTheme.colors.white,
        borderRadius: '20px',
        boxShadow: `0 0 5px ${LightTheme.colors.dropShadow}`
    }
});

const Container = styled.div`
    display: flex;
`;

const StyledIconButton = styled(IconButton)`
    margin: 0 2px;
    color: ${(props) => props.theme.colors.primary};
`;

interface TooltipProps {
    senderId: string;
    onEditClick: () => void;
    onReplyClick: () => void;
    onDeleteClick: () => void;
    canReply: boolean;
    canModify: boolean;
}

const ChatActionTooltip: React.FC<TooltipProps> = ({
    senderId,
    canReply,
    canModify,
    onEditClick,
    onReplyClick,
    onDeleteClick
}) => {
    const { currentUser } = useCurrentUser();

    return (
        <Container>
            {canReply ? (
                <StyledIconButton size="small" onClick={onReplyClick}>
                    <Icon path={mdiReply} size={IconSize.Small} />
                </StyledIconButton>
            ) : null}
            {canModify && currentUser?.userId === senderId ? (
                <>
                    <StyledIconButton size="small" onClick={onEditClick}>
                        <Icon path={mdiPencil} size={IconSize.Small} />
                    </StyledIconButton>
                    <StyledIconButton size="small" onClick={onDeleteClick}>
                        <Icon path={mdiDelete} size={IconSize.Small} />
                    </StyledIconButton>
                </>
            ) : null}
        </Container>
    );
};

export default ChatActionTooltip;
