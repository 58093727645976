import React from 'react';

import { Button } from '@material-ui/core';

import { EditButtonsContainer } from './EditButtons.styles';

interface EditButtonsProps {
    onCancel: () => void;
    onSave?: () => void;
}

export const EditButtons: React.FC<EditButtonsProps> = ({ onCancel = () => {}, onSave }) => (
    <EditButtonsContainer data-testid="editButtons">
        <Button data-testid="editButtons-cancel" color="primary" onClick={onCancel}>
            Cancel
        </Button>
        {onSave && (
            <Button data-testid="editButtons-save" color="primary" variant="contained" onClick={() => onSave()}>
                Save
            </Button>
        )}
    </EditButtonsContainer>
);
