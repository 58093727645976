import React from 'react';

import { Box, CircularProgress } from '@material-ui/core';

import { useDownloadRecorder } from './hooks/useDownloadRecorder';
import { RecordWindowText, InstructionsWrapper, InstructionalText, StyledLink } from './RecorderLaunchDetails.styles';

interface RecorderLaunchDetailsProps {
    onRelaunch: () => void;
}

export const RecorderLaunchDetails: React.FC<RecorderLaunchDetailsProps> = ({ onRelaunch }) => {
    const { downloadUrl } = useDownloadRecorder();

    return (
        <Box
            display="flex"
            flexDirection="column"
            width="100%"
            height="100%"
            justifyContent="center"
            justifyItems="center"
            data-testid="video-component-waiting-for-recorder"
        >
            <RecordWindowText>Use the Screen Capture App to record a video.</RecordWindowText>
            <RecordWindowText>When you are done it will appear here.</RecordWindowText>
            <InstructionsWrapper>
                {downloadUrl ? (
                    <>
                        <InstructionalText>
                            If you don&apos;t have the Adept Recorder app, please{' '}
                            <StyledLink href={downloadUrl}>download</StyledLink> and install it now.
                        </InstructionalText>
                        <InstructionalText>
                            Once installed,{' '}
                            <StyledLink
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    onRelaunch();
                                }}
                            >
                                launch the app
                            </StyledLink>{' '}
                            from here to record your video.
                        </InstructionalText>
                    </>
                ) : (
                    <CircularProgress />
                )}
            </InstructionsWrapper>
        </Box>
    );
};
