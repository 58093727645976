import { useMemo } from 'react';

import { OptimistMutationContext, useOptimist } from '@adept-at/lib-react-optimist';
import { EntityInfo } from 'components/ContentContext/Interfaces';
import {
    queryCacheKey as getSkillCreationsQueryKey,
    GetSkillCreationsResponse
} from 'components/creations2/useGetSkillCreations';
import { ClientError } from 'graphql-request';
import useGqlClient from 'hooks/useGqlClient';
import { API_CONTENT } from 'lib/ApiConstants';
import { useSnackbar } from 'notistack';
import { useMutation, UseMutationResult } from 'react-query';

import makeRestoreEntity from './makeRestoreEntity';

export interface RestoreEntityResult {
    restoreEntity: {
        success: boolean;
    };
}

export interface RestoreEntityVariables {
    entityInfo: EntityInfo;
}

const useRestoreEntity = (): UseMutationResult<
    RestoreEntityResult,
    ClientError,
    RestoreEntityVariables,
    OptimistMutationContext
> => {
    const { enqueueSnackbar } = useSnackbar();
    const { client, withMutationOptions } = useGqlClient(API_CONTENT);

    const { registerUpdater: registerRestoreEntityUpdater, mutationOptions: restoreEntityMutationOptions } =
        useOptimist<RestoreEntityResult, ClientError, RestoreEntityVariables>();

    const restoreEntityOptions = useMemo(() => {
        return {
            onError: () => {
                enqueueSnackbar('An error occurred. Unable to restore item', { variant: 'error' });
            },
            onSuccess: () => {
                enqueueSnackbar('Item restored', { variant: 'success' });
            },
            refetchQuery: false
        };
    }, [enqueueSnackbar]);

    registerRestoreEntityUpdater<GetSkillCreationsResponse>(
        getSkillCreationsQueryKey,
        (previous, queryArgs) => {
            const updatedSkills = previous?.getSkills?.map((skill) => {
                if (skill.skillId === queryArgs.entityInfo.id) {
                    return { ...skill, archived: false };
                }

                return skill;
            });

            return {
                getSkills: updatedSkills
            };
        },
        restoreEntityOptions
    );

    return useMutation<RestoreEntityResult, ClientError, RestoreEntityVariables, OptimistMutationContext>(
        makeRestoreEntity(client),
        withMutationOptions(restoreEntityMutationOptions)
    );
};

export default useRestoreEntity;
