import React from 'react';

import { StyledTableCell } from './TableCell.styles';

interface LikesCellProps {
    likes?: number;
}

export const LIKES_CELL_WIDTH = 100;

export const LikesCell: React.FC<LikesCellProps> = ({ likes }) => {
    return <StyledTableCell width={LIKES_CELL_WIDTH}>{likes ?? 0}</StyledTableCell>;
};
