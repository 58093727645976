import { useEffect, useRef, useMemo } from 'react';

import { useCollector } from 'components/devtools/Collector';
import { UseMutateFunction, useMutation } from 'react-query';

import { refreshSession } from './sessionHelpers';

export enum SessionRefreshStatus {
    Initial = 'Initial', // Unknown
    Loading = 'Loading',
    Error = 'Error',
    Success = 'Success',
    None = 'None' // Terminal
}

interface UseSessionRefreshInterface {
    refresh: UseMutateFunction<any, any, void, unknown>;

    status: SessionRefreshStatus;
    reset: () => void;

    onTokenRetrievalSuccess: () => void;
    onTokenRetrievalError: () => void;
}

export const useSessionRefresh = (): UseSessionRefreshInterface => {
    const previousStatus = useRef<SessionRefreshStatus>(SessionRefreshStatus.Initial);
    const { mutate: refresh, status: mutationStatus, reset } = useMutation(refreshSession);

    const { addHistory } = useCollector();

    const status = useMemo(() => {
        const determineNewStatus = () => {
            // @TODO SAS differentiate between error and expired/invalid
            if (mutationStatus === 'error') {
                return SessionRefreshStatus.Error;
            }

            if (mutationStatus === 'loading') {
                return SessionRefreshStatus.Loading;
            }

            if (mutationStatus === 'success') {
                return SessionRefreshStatus.Success;
            }

            if (mutationStatus === 'idle') {
                return SessionRefreshStatus.Initial;
            }

            return previousStatus.current;
        };

        previousStatus.current = determineNewStatus();

        return previousStatus.current;
    }, [mutationStatus]);

    useEffect(() => {
        addHistory('refreshStatus', status);
    }, [status, addHistory]);

    return { refresh, status, reset, onTokenRetrievalSuccess: () => {}, onTokenRetrievalError: () => {} };
};
