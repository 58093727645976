import React from 'react';

const SkillIcon: React.FC = () => {
    return (
        <svg
            version="1.1"
            id="Skill_Icon"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 24 24"
            xmlSpace="preserve"
            fill="currentColor"
        >
            <path d="M10.6,2.1c0.8-0.8,2-0.8,2.8,0l8.5,8.5c0.8,0.8,0.8,2,0,2.8l-8.5,8.5c-0.8,0.8-2,0.8-2.8,0l-8.5-8.5c-0.8-0.8-0.8-2.1,0-2.8 L10.6,2.1 M12,3.5L3.5,12l8.5,8.5l8.5-8.5L12,3.5z M12,14.3l2.3-2.3L12,9.7L9.7,12L12,14.3z" />
        </svg>
    );
};

export const SkillIconPath =
    'M10.6,2.1c0.8-0.8,2-0.8,2.8,0l8.5,8.5c0.8,0.8,0.8,2,0,2.8l-8.5,8.5c-0.8,0.8-2,0.8-2.8,0l-8.5-8.5c-0.8-0.8-0.8-2.1,0-2.8 L10.6,2.1 M12,3.5L3.5,12l8.5,8.5l8.5-8.5L12,3.5z M12,14.3l2.3-2.3L12,9.7L9.7,12L12,14.3z';

export default SkillIcon;
