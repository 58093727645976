import React from 'react';

import { GlobalErrorBoundary } from 'components/GlobalErrorBoundary';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import ReactDOM from 'react-dom';
import * as serviceWorker from 'serviceWorker';

import App from './App';
import { initialize as initializeDatadog } from './lib/datadog';

import './index.css';

(window as any).chimeMaxVideos = process.env.REACT_APP_CHIME_MAX_VIDEOS
    ? parseInt(process.env.REACT_APP_CHIME_MAX_VIDEOS)
    : 16;

initializeDatadog();

/**
 * The purpose here of ANON_USER_KEY is to use the same user key for every initialization and then replacing that with
 * the actual user key when identified in AuthContext this should bring our MAU (Monthly Active User) count down. The
 * limitation is if we ever want to use percentage based flag roll outs we may need to remove this and potentially
 * increase our LD account plan.
 */
const ANON_USER_KEY = 'anonymous_user_key';

(async () => {
    const LaunchDarklyProvider = process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID
        ? await asyncWithLDProvider({
              clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID || '',
              user: { key: ANON_USER_KEY },
              options: { allAttributesPrivate: true },
              deferInitialization: true
          })
        : ({ children }) => <>{children}</>;

    ReactDOM.render(
        <React.StrictMode>
            <GlobalErrorBoundary>
                <LaunchDarklyProvider>
                    <App />
                </LaunchDarklyProvider>
            </GlobalErrorBoundary>
        </React.StrictMode>,
        document.getElementById('root')
    );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
