import React from 'react';

import { VideoComponentValues } from '@adept-at/lib-react-components';
import { useVideoRef } from 'components/learn/videoRefs/useVideoRefs';

import VideoView from './View';

export const EmbeddedVideo: React.FC<VideoComponentValues> = (componentValues) => {
    const videoRef = useVideoRef();

    return (
        <VideoView
            videoRef={videoRef}
            componentValues={componentValues}
            preload={componentValues.posterImageUrl ? 'none' : 'metadata'}
            muxDataKey={process.env.REACT_APP_MUX_DATA_ENV_KEY ?? ''}
        />
    );
};
