import React, { useState } from 'react';

import { OptimistMutationContext } from '@adept-at/lib-react-optimist';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Modal from 'components/modals';
import { GraphqlError } from 'hooks/useGqlClient/GraphqlError';
import { useOrganizationTerminology } from 'hooks/useOrganizationTerminology';
import { useForm } from 'react-hook-form';
import { UseMutateFunction } from 'react-query';
import * as Yup from 'yup';

import { CollectionChild } from './context/useGetTenantProfileForOwner';
import { UpsertCollectionItemResult, UpsertCollectionItemVariables } from './useUpsertCollectionItem';

interface AddExistingFormFields {
    collectionTitle: string;
}

interface AddExistingCollectionModalProps {
    modalOpen: boolean;
    handleModalClose: () => void;
    options: {
        label: string;
        id: string;
    }[];
    mutateFn: UseMutateFunction<
        UpsertCollectionItemResult,
        GraphqlError,
        UpsertCollectionItemVariables,
        OptimistMutationContext
    >;
    parentCollectionId: string;
    isLoading: boolean;
}
const AddExistingCollectionModal: React.FC<AddExistingCollectionModalProps> = ({
    modalOpen,
    handleModalClose,
    options,
    mutateFn,
    parentCollectionId,
    isLoading
}) => {
    const [optionIdFromSelect, setOptionIdFromSelect] = useState<string | undefined>(undefined);
    const { terminology } = useOrganizationTerminology();

    const { handleSubmit, register, errors } = useForm<AddExistingFormFields>({
        mode: 'onBlur',

        resolver: yupResolver(
            Yup.object().shape({
                collectionTitle: Yup.string().required('Selection required')
            })
        )
    });

    const onSubmit = () => {
        if (optionIdFromSelect) {
            mutateFn({
                collectionId: parentCollectionId,
                item: {
                    type: CollectionChild.Collection,
                    id: optionIdFromSelect
                }
            });
        }
        handleModalClose();
    };

    return (
        <Modal
            open={modalOpen}
            onClose={handleModalClose}
            ConfirmButton={() => (
                <Button form="existing-collection-form" variant="contained" color="primary" size="large" type="submit">
                    Add {terminology.collection}
                </Button>
            )}
            modalTitle={'Add existing'}
            labelProps={{ 'aria-label': 'Add existing collection modal' }}
        >
            <form onSubmit={handleSubmit(onSubmit)} id="existing-collection-form">
                <Autocomplete
                    fullWidth
                    options={options}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, value) => {
                        if (!optionIdFromSelect || optionIdFromSelect !== value?.id) setOptionIdFromSelect(value?.id);
                    }}
                    loading={isLoading}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            id="collectionTitle"
                            type="text"
                            name="collectionTitle"
                            label={`Select existing ${terminology.collection}`}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off' // disable browser autocomplete and autofill
                            }}
                            variant="outlined"
                            inputRef={register}
                            error={!!errors?.collectionTitle}
                        />
                    )}
                />
            </form>
        </Modal>
    );
};

export default AddExistingCollectionModal;
