import React, { useContext } from 'react';

import { LearnContext } from '../LearnContext';

const MetricsPixel: React.FC = () => {
    const { skill } = useContext(LearnContext);
    const pixelUrl = `${process.env.REACT_APP_CONTENT_HOST}/content/getPixel?skillId=${skill.skillId}`;

    return <img src={pixelUrl} />;
};

export default MetricsPixel;
