import React from 'react';

const BaseContainer =
    ({ baseClassName }) =>
    ({ className = '', children, ...baseProps }) =>
        (
            <div className={`${baseClassName} ${className}`} {...baseProps}>
                {children}
            </div>
        );

export default BaseContainer;
