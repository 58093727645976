import React from 'react';

import { useModal } from 'hooks/useModal';

import { AuthExplorerButton } from './AuthExplorerButton';
import { AuthExplorerDialog } from './AuthExplorerDialog';
import { useHistory } from './useHistory';

export { AuthExplorerButton } from './AuthExplorerButton';

export const AuthExplorer: React.FC = () => {
    const { handleModalOpen, handleModalClose, modalOpen } = useModal();

    useHistory();

    return (
        <>
            <AuthExplorerButton onClick={handleModalOpen} />
            <AuthExplorerDialog open={modalOpen} onClose={handleModalClose} />
        </>
    );
};
