import React from 'react';

import { FavoriteItem } from 'components/dash/hooks/useGetFavorites';
import styled from 'styled-components';

import { FavoriteCards } from './FavoriteCards';
import { FavoritesTable } from './FavoritesTable';

const Main = styled.main`
    padding: 1rem;
`;

export const FavoritesMainContent: React.FC<{
    isGridView: boolean;
    favorites: FavoriteItem[];
    favoritesLoading: boolean;
}> = ({ isGridView, favorites, favoritesLoading }) => (
    <Main>
        {isGridView ? (
            <FavoriteCards favorites={favorites} favoritesLoading={favoritesLoading} />
        ) : (
            <FavoritesTable favorites={favorites} favoritesLoading={favoritesLoading} />
        )}
    </Main>
);
