export const RETRIEVE_TOKEN_ERROR_MESSAGE = 'Unable to retrieve token for session';

export const retrieveTokenForSession = async (): Promise<string> => {
    const response = await fetch(`${process.env.REACT_APP_ACCOUNTS_HOST}/web/token`, {
        method: 'get',
        credentials: 'include',
        mode: 'cors'
    });

    if (!response.ok) {
        throw new Error(RETRIEVE_TOKEN_ERROR_MESSAGE);
    }

    const { accessToken } = await response.json();

    return accessToken;
};
