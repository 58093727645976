import React, { createContext, useReducer, useCallback, useContext } from 'react';

interface HistoryEntry {
    at: string;
    value: string;
}
interface CollectorContextInterface {
    addHistory: (type: string, value: string) => void;
    getHistory: (type: string) => HistoryEntry[];
    history: Map<string, HistoryEntry[]>;
}

const CollectorContext = createContext({} as CollectorContextInterface);

const { Provider } = CollectorContext;

/**
 * Do not add to history for type if the value we received is the same as the last value in the array. The purpose of
 * history is to display state transitions. The same value does not represent a transition.
 *
 * We _could_ protect against duplicate history additions further up the stack (in the reporter itself); however,
 * with React.js, we embrace the fact that renders can happen many times, reporting the same status/state/value.
 */
const isValueSameAsLastForType = (history: Map<string, HistoryEntry[]>, type: string, value: string) => {
    const entriesForType = history.get(type) ?? [];
    const { value: lastValueForType = null } = entriesForType[entriesForType.length - 1] ?? {};

    return lastValueForType === value;
};

const updateHistoryReducer = (history: Map<string, HistoryEntry[]>, [type, value]) => {
    const workingMap = new Map(history);

    // if (isValueSameAsLastForType(history, type, value)) {
    //     return workingMap;
    // }

    workingMap.set(type, [...(history.get(type) ?? []), { at: new Date().toISOString(), value }]);

    return workingMap;
};

const CollectorProvider: React.FC = ({ children }) => {
    const [history, updateHistory] = useReducer(updateHistoryReducer, new Map<string, HistoryEntry[]>());

    const addHistory = useCallback((type: string, value: string) => {
        updateHistory([type, value]);
    }, []);

    return (
        <Provider
            value={{
                addHistory,
                getHistory: (type: string) => history.get(type) ?? [],
                history
            }}
        >
            {children}
        </Provider>
    );
};

export { CollectorContext, CollectorProvider };

export const useCollector = (): CollectorContextInterface => {
    const context = useContext(CollectorContext);

    if (!context) {
        throw new Error('useCollector must be used within a CollectorProvider');
    }

    return context;
};
