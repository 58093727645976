import React, { useEffect, useState } from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { Button, ButtonGroup, Chip, FormControlLabel, Switch } from '@material-ui/core';
import { mdiEyeOff, mdiEye } from '@mdi/js';
import Icon from '@mdi/react';
import { AvailabilityChipProps } from 'components/CollaborationTable/AvailabilityChip';
import { CourseIconPath } from 'components/icons/CourseIcon';
import { SkillIconPath } from 'components/icons/SkillIcon';
import Modal from 'components/modals';
import styled from 'styled-components';
import { modularScale } from 'utils/modular_scale';

import { useUpdateSkillAvailability } from './hooks/useUpdateSkillAvailability';

interface AvailabilityModalProps {
    open: boolean;
    onClose: () => void;
    entity: AvailabilityChipProps;
}

const IncludedWithText = styled.div`
    margin-top: 1rem;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.textSecondary};
    font-size: ${(props) => props.theme.fonts.textSizeSmall};
`;

const StyledChip = styled(Chip)`
    margin-top: -2rem;
`;

const AncestorChip = styled(Chip)`
    margin: 0.25rem;
`;

const SwitchGroup = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
`;

const SwitchLabel = styled.span<{ $selected?: boolean }>`
    color: ${(props) => (props.$selected ? props.theme.colors.primary : 'inherit')};
    font-weight: 500;
    font-size: ${modularScale(-0.5)};
`;

export const AvailabilityModal: React.FC<AvailabilityModalProps> = ({ open, onClose, entity }) => {
    const [visible, setVisible] = useState(entity.visible ?? true);
    const [free, setFree] = useState(entity.free ?? false);
    const [loginRequired, setLoginRequired] = useState(entity.loginRequired ?? true);

    const { mutate } = useUpdateSkillAvailability(entity.owner, entity.id);

    const updateAvailability = () => {
        if (entity.free !== free || entity.loginRequired !== loginRequired || entity.visible !== visible) {
            mutate({ skillId: entity.id, free, loginRequired, visible });
        }
        onClose();
    };

    useEffect(() => {
        if (!loginRequired) {
            setFree(true);
        }
    }, [loginRequired]);

    return (
        <Modal
            open={open}
            onClose={onClose}
            onClick={(e) => e.stopPropagation()}
            modalTitle="Availability"
            confirmButtonText="Save"
            onConfirm={updateAvailability}
        >
            <>
                <StyledChip
                    size="small"
                    icon={<Icon path={SkillIconPath} size={IconSize.Small} />}
                    label={entity.title}
                    variant="outlined"
                />
                <ButtonGroup fullWidth color="primary">
                    <Button
                        disableElevation
                        onClick={() => setVisible(false)}
                        variant={visible ? 'outlined' : 'contained'}
                        startIcon={<Icon path={mdiEyeOff} size={IconSize.Small} />}
                    >
                        Hidden
                    </Button>
                    <Button
                        disableElevation
                        onClick={() => setVisible(true)}
                        variant={visible ? 'contained' : 'outlined'}
                        startIcon={<Icon path={mdiEye} size={IconSize.Small} />}
                    >
                        Visible
                    </Button>
                </ButtonGroup>
                <SwitchGroup>
                    <FormControlLabel
                        checked={loginRequired}
                        onChange={() => setLoginRequired(!loginRequired)}
                        control={<Switch color="primary" />}
                        labelPlacement="end"
                        label={
                            <SwitchLabel $selected={loginRequired}>{`Log in required: ${
                                loginRequired ? 'On' : 'Off'
                            }`}</SwitchLabel>
                        }
                    />
                    <FormControlLabel
                        disabled={!loginRequired}
                        checked={!free}
                        onChange={() => setFree(!free)}
                        control={<Switch color="primary" />}
                        labelPlacement="end"
                        label={<SwitchLabel $selected={!free}>{`Paid skill: ${free ? 'Off' : 'On'}`}</SwitchLabel>}
                    />
                </SwitchGroup>
                {(entity.ancestorCollections ?? []).length > 0 ? (
                    <IncludedWithText>Included with</IncludedWithText>
                ) : null}
                {(entity.ancestorCollections ?? []).map((collection) => (
                    <AncestorChip
                        key={collection.collectionId}
                        size="small"
                        icon={<Icon path={CourseIconPath} size={IconSize.Small} />}
                        label={collection.title}
                        variant="outlined"
                    />
                ))}
            </>
        </Modal>
    );
};
