import React, { useState, useMemo, FC, useEffect, useContext } from 'react';

import { ChipContainer, Divider, IconSize } from '@adept-at/lib-react-components';
import { Backdrop, Chip, Fade, Link, Tab, Tabs, Button } from '@material-ui/core';
import { Link as IconLink } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { mdiFlask } from '@mdi/js';
import MuiIcon from '@mdi/react';
import { CollaborativeCollection, Creation, CollaborationTableItem } from 'components/CollaborationTable';
import { EntityType } from 'components/ContentContext/Enums';
import { CollaboratorPermissionLevel, EntityInfo, OwnerInfo, Lab, Skill } from 'components/ContentContext/Interfaces';
import { SkillIconPath } from 'components/icons/SkillIcon';
import { TrackIconPath } from 'components/icons/TrackIcon';
import { useUpdateSkillAvailability } from 'components/skills/hooks/useUpdateSkillAvailability';
import TabPanel from 'components/TabPanel';
import { ProfileContext } from 'context/ProfileContext';
import { QueryKey, useQueryClient } from 'react-query';
import styled from 'styled-components';

import { ModalBody, StyledModal, Title } from '../../Modal.styles';

import AddCollaboratorsSharingForm from './AddCollaborators/AddCollaboratorsSharingForm';
import AddViewersSharingForm from './AddViewers/AddViewersSharingForm';
import { TransferManyEntityOwnershipProps } from './hooks/useTransferManyEntityOwnership';
import AddEntityRolesTab from './SharedComponents/AddEntityCollaboratorsTab';
import TransferOwnershipTab from './TransferOwnership';

export const ModalTitle = styled(Title)`
    margin-bottom: 0.5rem;
`;

export const StyledAlert = styled(Alert)`
    margin: 1rem 0;
`;

const StyledTabPanel = styled(TabPanel)`
    height: 28rem;
`;

const FullHeightContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;
const StyledChip = styled(Chip)`
    margin-left: auto;
    background-color: ${(props) => props.theme.colors.surface};
`;

const LinkContainer = styled.div`
    margin-top: -2.7rem;
    max-width: 55%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    align-items: center;

    a {
        text-overflow: ellipsis;
        overflow: hidden;
    }

    button {
        flex-shrink: 0;
    }
`;

interface SupportedSharingEntities {
    entities: Array<Skill | Lab | CollaborativeCollection | Creation | CollaborationTableItem>;
}

export enum SharingModalTabs {
    Viewers,
    Collaborators,
    TransferOwnership
}

interface SharingModalProps extends SupportedSharingEntities {
    isOpen: boolean;
    handleClose: () => void;
    onFinish?: (labelId: string) => void;
    owner: OwnerInfo;
    contentQueryKey?: QueryKey;
    contentTransferOptimistCallback?: <T>(previous: T, transferQueryProps: TransferManyEntityOwnershipProps) => T;
    startingTab?: SharingModalTabs;
}

const a11yProps = (index) => {
    return {
        id: `sharing-modal-tab-${index}`,
        'aria-controls': `sharing-modal-tabpanel-${index}`
    };
};

const SharingModal: FC<SharingModalProps> = ({
    isOpen,
    handleClose,
    entities,
    owner,
    contentQueryKey,
    contentTransferOptimistCallback,
    startingTab
}) => {
    const queryClient = useQueryClient();
    const [isPublic, setIsPublic] = useState(false);
    const [currentTab, setCurrentTab] = useState(startingTab ?? SharingModalTabs.Collaborators);
    const { availableProfiles } = useContext(ProfileContext);
    const { mutate: updateSkillAvailability } = useUpdateSkillAvailability();

    const sharingLinkUrl = useMemo(() => {
        if (entities.length !== 1) return '';
        const entity = entities[0];
        const entitySlug =
            'skillId' in entity ? entity.skillSlug : 'collectionId' in entity ? entity.collectionSlug : entity.slug;
        const matchingOwner = availableProfiles.find((i) => i.id === entity.owner?.id);

        if (!matchingOwner || !entitySlug) return '';
        //@todo env var perhaps?
        return `https://adept.at/${matchingOwner.alias}/${entitySlug}`;
    }, [entities, availableProfiles]);

    const copySharingLink = () => {
        navigator.clipboard.writeText(sharingLinkUrl);
    };

    useEffect(() => {
        const entitiesArePublic = entities.every((entity) => {
            if ('skillId' in entity || ('id' in entity && entity.type === EntityType.SKILL)) {
                const { free, loginRequired } = entity as Skill;
                return free && !loginRequired;
            }
            return true;
        });

        setIsPublic(entitiesArePublic);
    }, []);
    //todo move to hook
    const toggleEntityPublicity = async (setIsLoading) => {
        setIsLoading(true);
        const shouldBePublic = !isPublic;

        //temporary until backend mass label  edit is setup
        await entities.reduce(async (prevprom: Promise<void>, entity) => {
            await prevprom;
            if ('skillId' in entity || ('id' in entity && entity.type === 'skill')) {
                const id = 'skillId' in entity ? entity.skillId : entity.id;
                const { free, loginRequired } = entity as Skill;
                const entityIsPublic = free && !loginRequired;

                if (!shouldBePublic && entityIsPublic) {
                    return new Promise<void>((resolve, reject) => {
                        updateSkillAvailability(
                            {
                                skillId: id,
                                free: false,
                                loginRequired: true
                            },
                            {
                                onSuccess: () => {
                                    resolve();
                                },
                                onError: () => {
                                    reject(null);
                                }
                            }
                        );
                    });
                } else if (shouldBePublic && !entityIsPublic) {
                    return new Promise<void>((resolve, reject) => {
                        updateSkillAvailability(
                            {
                                skillId: id,
                                free: true,
                                loginRequired: false
                            },
                            {
                                onSuccess: () => {
                                    resolve();
                                },
                                onError: () => {
                                    reject(null);
                                }
                            }
                        );
                    });
                }
            }
            return Promise.resolve();
        }, Promise.resolve());

        if (contentQueryKey) await queryClient.refetchQueries(contentQueryKey);

        setIsPublic(!isPublic);
        setIsLoading(false);
    };

    useEffect(() => {
        if (startingTab !== undefined) setCurrentTab(startingTab);
    }, [startingTab]);

    const handleTabChange = (_event, newValue) => {
        setCurrentTab(newValue);
    };

    const onTransferComplete = () => {
        if (contentQueryKey && !contentTransferOptimistCallback) {
            queryClient.refetchQueries(contentQueryKey);
        }
        handleClose();
    };
    //@TODO- temporary while vlabs and other creations are added into the sharing ecosystem
    const { onlySkills, entitiesInfo, EntityChips } = useMemo<{
        onlySkills: boolean;
        entitiesInfo: EntityInfo[];
        EntityChips: FC[];
    }>(() => {
        let onlySkills = true;
        const EntityChips: FC[] = [];

        const entitiesInfo: EntityInfo[] = [];
        entities.forEach((entity) => {
            if ('skillId' in entity || ('type' in entity && entity.type === EntityType.SKILL)) {
                entitiesInfo.push({
                    type: EntityType.SKILL,
                    id: 'skillId' in entity ? entity.skillId : entity.id
                });

                EntityChips.push(() => (
                    <StyledChip
                        size="medium"
                        variant="outlined"
                        label={entity.title}
                        icon={<MuiIcon path={SkillIconPath} size={IconSize.Small} />}
                    />
                ));
            } else if ('vLabId' in entity || ('type' in entity && entity.type === EntityType.VIRTUAL_LAB)) {
                onlySkills = false;
                entitiesInfo.push({
                    type: EntityType.VIRTUAL_LAB,
                    id: 'vLabId' in entity ? entity.vLabId : entity.id
                });
                EntityChips.push(() => (
                    //todo, add and use vlab icon from lib react
                    <StyledChip
                        size="medium"
                        variant="outlined"
                        label={'vLabId' in entity ? entity.name : entity.title}
                        icon={<MuiIcon path={mdiFlask} size={IconSize.Small} />}
                    />
                ));
            } else if ('collectionId' in entity || ('type' in entity && entity.type === EntityType.COLLECTION)) {
                onlySkills = false;
                entitiesInfo.push({
                    type: EntityType.COLLECTION,
                    id: 'collectionId' in entity ? entity.collectionId : entity.id
                });
                EntityChips.push(() => (
                    <StyledChip
                        size="medium"
                        variant="outlined"
                        label={entity.title}
                        icon={<MuiIcon path={TrackIconPath} size={IconSize.Small} />}
                    />
                ));
            }
        });

        return { onlySkills, entitiesInfo, EntityChips };
    }, [entities]);

    return (
        <StyledModal
            aria-labelledby="sharing-entity-dialog-box"
            aria-describedby="sharing-entity-dialog-box"
            open={isOpen}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500
            }}
        >
            <Fade in={isOpen}>
                <ModalBody $maxWidth={'600px'} $minHeight={'35rem'}>
                    <ModalTitle aria-label="sharing skill dialog box">Sharing</ModalTitle>
                    <ChipContainer>
                        {EntityChips.map((Chip, i) => (
                            <Chip key={`${i}-entity-chip`} />
                        ))}
                    </ChipContainer>
                    <Tabs
                        value={currentTab}
                        onChange={handleTabChange}
                        textColor="primary"
                        indicatorColor="primary"
                        aria-label="Sharing modal tabs"
                    >
                        <Tab label={'Viewers'} {...a11yProps(0)} data-testid="viewer-tab" />
                        <Tab label="Collaborators" {...a11yProps(1)} data-testid="collaborator-tab" />
                        <Tab label="Owner" {...a11yProps(1)} data-testid="owner-tab" />
                    </Tabs>
                    <Divider />
                    <StyledTabPanel value={currentTab} index={SharingModalTabs.Viewers}>
                        {onlySkills ? (
                            <FullHeightContainer>
                                <AddEntityRolesTab
                                    toggleEntityPublicity={toggleEntityPublicity}
                                    entities={entitiesInfo}
                                    onClose={handleClose}
                                    SharingForm={AddViewersSharingForm}
                                    roleFilter={[CollaboratorPermissionLevel.Viewer]}
                                    isPublic={isPublic}
                                />
                            </FullHeightContainer>
                        ) : (
                            <StyledAlert severity="info">
                                Only skill viewership changes are supported at the moment. More coming soon!
                            </StyledAlert>
                        )}
                    </StyledTabPanel>
                    <StyledTabPanel value={currentTab} index={SharingModalTabs.Collaborators}>
                        {onlySkills ? (
                            <FullHeightContainer>
                                <AddEntityRolesTab
                                    toggleEntityPublicity={async () => {}}
                                    entities={entitiesInfo}
                                    onClose={handleClose}
                                    SharingForm={AddCollaboratorsSharingForm}
                                    roleFilter={[
                                        CollaboratorPermissionLevel.Editor,
                                        CollaboratorPermissionLevel.PeerReviewer
                                    ]}
                                />
                            </FullHeightContainer>
                        ) : (
                            <StyledAlert severity="info">
                                Only skill collaborator changes are supported at the moment. More coming soon!
                            </StyledAlert>
                        )}
                    </StyledTabPanel>
                    <StyledTabPanel value={currentTab} index={SharingModalTabs.TransferOwnership}>
                        <FullHeightContainer>
                            <TransferOwnershipTab
                                entities={entitiesInfo}
                                owner={owner}
                                onTransferOwnership={onTransferComplete}
                                contentQueryKey={contentQueryKey}
                                contentTransferOptimistCallback={contentTransferOptimistCallback}
                            />
                        </FullHeightContainer>
                    </StyledTabPanel>
                    {sharingLinkUrl ? (
                        <LinkContainer>
                            <Link>{sharingLinkUrl}</Link>
                            <Button onClick={copySharingLink} color={'primary'} startIcon={<IconLink />}>
                                Copy Link
                            </Button>
                        </LinkContainer>
                    ) : null}
                </ModalBody>
            </Fade>
        </StyledModal>
    );
};

export default SharingModal;
