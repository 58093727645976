import React, { useEffect, useState } from 'react';

import { CircularProgress } from '@material-ui/core';

import { LoadingWrapper } from './FilePreview.styles';
import PDFView from './View';

interface FilePreviewProps {
    file: any;
}

const FilePreview: React.FC<FilePreviewProps> = React.memo(({ file }) => {
    const [fileData, setFileData] = useState<any>();
    const [loadingTimeout, setLoadingTimeout] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoadingTimeout(false);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        setFileData(file);
    }, [file]);

    useEffect(() => {
        if (loadingTimeout || !file) {
            return;
        }

        setFileData({ url: file });
    }, [loadingTimeout, setFileData, file]);

    if (loadingTimeout) {
        return (
            <LoadingWrapper>
                <CircularProgress size={100} color="primary" />
            </LoadingWrapper>
        );
    }

    return <PDFView {...{ pdf: { url: fileData } }} />;
});

export default FilePreview;
