import { GraphQLClient } from 'graphql-request';
import gql from 'graphql-tag';
import buildGqlMutationFn from 'hooks/useGqlClient/helpers/buildGqlMutationFn';
import { MutationFunction } from 'react-query';

import { JoinMeetingResult, JoinMeetingVariables } from '.';

export const JOIN_MEETING = gql`
    mutation joinChannelMeetingSession($channelId: UUIDv4!, $meetingId: UUIDv4!, $sessionId: UUIDv4!) {
        joinChannelMeetingSession(channelId: $channelId, meetingId: $meetingId, sessionId: $sessionId) {
            success
        }
    }
`;

const makeJoinMeeting = (client: GraphQLClient): MutationFunction<JoinMeetingResult, JoinMeetingVariables> =>
    buildGqlMutationFn(client, JOIN_MEETING);

export default makeJoinMeeting;
