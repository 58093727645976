import React, { useState, createContext, useCallback } from 'react';

import { enableMapSet } from 'immer';
import { useImmer } from 'use-immer';

enableMapSet();

export interface ComponentFocusableProviderProps {
    children: React.ReactNode;
}

type ComponentFocus = any;

interface ComponentWithFocus {
    componentId: string;
    focusType: ComponentFocus;
}

export interface ComponentFocusableContextInterface<TComponentFocus = any> {
    setComponentFocus: (componentId: string, focusType: TComponentFocus) => void;
    getComponentFocuses: (componentId: string) => TComponentFocus[];
    unsetComponentFocus: (componentId: string, focusType?: TComponentFocus | TComponentFocus[]) => void;
    doesComponentHaveFocus: (componentId: string, focusType?: TComponentFocus | null) => boolean;
    doesComponentHaveAnyFocus: (componentId: string, focusTypes: TComponentFocus[]) => boolean;
    doesComponentHaveAllFocuses: (componentId: string, focusTypes: TComponentFocus[]) => boolean;
    doesComponentHaveOtherFocuses: (componentId: string, focusTypes: TComponentFocus[]) => boolean;
    lastFocused: ComponentWithFocus | null;
}

export const ComponentFocusableContext = createContext({} as ComponentFocusableContextInterface);

export const ComponentFocusableProvider: React.FC<ComponentFocusableProviderProps> = ({ children }) => {
    const [lastFocused, setLastFocused] = useState<ComponentWithFocus | null>(null);

    const [componentFocuses, updateComponentFocuses] = useImmer<Map<string, ComponentFocus[]>>(
        new Map<string, ComponentFocus[]>()
    );

    const setComponentFocus = useCallback(
        (componentId: string, focusType: ComponentFocus) => {
            setLastFocused({ componentId, focusType });

            updateComponentFocuses((draft) => {
                const currentComponentFocuses = draft.get(componentId);

                if (!currentComponentFocuses) {
                    draft.set(componentId, [focusType]);
                    return;
                }

                // Unique
                draft.set(componentId, [...new Set([...currentComponentFocuses, focusType])]);
            });
        },
        [setLastFocused, updateComponentFocuses]
    );

    const getComponentFocuses = (componentId: string) => componentFocuses.get(componentId) || [];

    const unsetComponentFocus = (componentId: string, focusType?: ComponentFocus | ComponentFocus[]) => {
        setLastFocused(null);

        updateComponentFocuses((draft) => {
            const currentComponentFocuses = draft.get(componentId);

            if (!currentComponentFocuses) {
                return;
            }

            if (!focusType) {
                draft.delete(componentId);
                return;
            }

            const focusTypesToRemove = Array.isArray(focusType) ? focusType : [focusType];

            draft.set(
                componentId,
                currentComponentFocuses.filter((componentFocus) => !focusTypesToRemove.includes(componentFocus))
            );
        });
    };

    const doesComponentHaveFocus = (componentId: string, focusType: ComponentFocus | null = null) => {
        if (!componentFocuses.has(componentId) || getComponentFocuses(componentId).length === 0) {
            return false;
        }

        if (focusType !== null) {
            return (componentFocuses.get(componentId) || []).includes(focusType);
        }

        return true;
    };

    const doesComponentHaveAnyFocus = (componentId: string, focusTypes: ComponentFocus[]) =>
        getComponentFocuses(componentId).filter((existingFocusType) => focusTypes.includes(existingFocusType)).length >
        0;

    const doesComponentHaveAllFocuses = (componentId: string, focusTypes: ComponentFocus[]) =>
        focusTypes.every((focusType) => getComponentFocuses(componentId).includes(focusType));

    const doesComponentHaveOtherFocuses = (componentId: string, focusTypes: ComponentFocus[]) =>
        getComponentFocuses(componentId).filter((focusType) => focusTypes.includes(focusType)).length > 0;

    return (
        <ComponentFocusableContext.Provider
            value={{
                setComponentFocus,
                getComponentFocuses,
                unsetComponentFocus,
                doesComponentHaveFocus,
                doesComponentHaveAnyFocus,
                doesComponentHaveAllFocuses,
                doesComponentHaveOtherFocuses,
                lastFocused
            }}
        >
            {children}
        </ComponentFocusableContext.Provider>
    );
};

export const useFocusableComponent = (): ComponentFocusableContextInterface => {
    const context = React.useContext(ComponentFocusableContext);

    if (!context) {
        throw new Error('useFocusableComponent must be used within a ComponentFocusableProvider');
    }

    return context;
};
