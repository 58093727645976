import * as Capture from './Capture';
import * as Upload from './Upload';

export enum VideoType {
    Upload = 'UPLOAD',
    Capture = 'CAPTURE'
}

const typeToModuleMapping = {
    [VideoType.Upload]: Upload,
    [VideoType.Capture]: Capture
};

interface SelectorOptionProps {
    onSelect: () => void;
}

export const getSelectorOptionForType = (type: VideoType): React.ComponentType<SelectorOptionProps> =>
    typeToModuleMapping[type].SelectorOption;
