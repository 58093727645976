import { ClientError } from 'graphql-request';
import gql from 'graphql-tag';
import { useBuildAuthenticatedQueryFn } from 'hooks/useGqlClient/useBuildAuthenticatedQueryFn';
import { Api } from 'lib/ApiConstants';
import { QueryKey, UseQueryOptions, UseQueryResult, useQuery } from 'react-query';

import { GroupType } from './index';

const GET_ORGANIZATION_GROUPS_QUERY = gql`
    query getOrganizationGroups($organizationId: String!) {
        getOrganizationGroups(organizationId: $organizationId) {
            id
            name
            slug
            type
            private
            description
            createdAt
            createdBy
            archived
        }
    }
`;

export interface OrganizationGroup {
    id: string;
    name: string;
    description: string;
    private: boolean;
    slug: string;
    type: GroupType;
    createdAt: string;
    createdBy: string;
    archived: boolean;
}

export interface GetOrganizationGroupsResponse {
    getOrganizationGroups: OrganizationGroup[];
}

export interface GetOrganizationGroupsVariables {
    organizationId: string;
}

export const buildGetOrganizationGroupsQueryKey = (organizationId: string | undefined): QueryKey => [
    'getOrganizationGroups',
    organizationId
];

export const useGetOrganizationGroups = (
    variables: GetOrganizationGroupsVariables,
    options: UseQueryOptions<GetOrganizationGroupsResponse, ClientError>
): UseQueryResult<GetOrganizationGroupsResponse, ClientError> => {
    const { queryFn } = useBuildAuthenticatedQueryFn<GetOrganizationGroupsResponse, GetOrganizationGroupsVariables>(
        GET_ORGANIZATION_GROUPS_QUERY,
        variables,
        { api: Api.Accounts }
    );

    const queryCacheKey = buildGetOrganizationGroupsQueryKey(variables.organizationId);

    return useQuery<GetOrganizationGroupsResponse, ClientError>(queryCacheKey, queryFn, options);
};
