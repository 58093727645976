import * as yup from 'yup';

import { MessageEventType } from '..';
import AckableAdepteductMessage from '../../base/AckableAdepteductMessage';

interface EventFieldsEditMessage {
    text: string;
}

export default class ChannelEditMessage extends AckableAdepteductMessage<EventFieldsEditMessage> {
    schema = yup.object({
        text: yup.string().required()
    });

    type = MessageEventType.ChannelEditMessage;
}
