import React from 'react';

import { TooltippedIconButton, TooltippedButtonSize } from '@adept-at/lib-react-components';
import { useTheme } from '@material-ui/core/styles';
import Icon from '@mdi/react';

interface PriceFieldEndAdornmentButtonProps {
    enabled: boolean;
    tooltipText: string;
    onClick: () => void;
    iconPath: string;
}

export const PriceFieldEndAdornmentButton: React.FC<PriceFieldEndAdornmentButtonProps> = ({
    enabled,
    tooltipText,
    onClick,
    iconPath
}) => {
    const theme = useTheme();

    return (
        <TooltippedIconButton
            disabled={!enabled}
            size={TooltippedButtonSize.Medium}
            title={tooltipText}
            onClick={onClick}
            icon={<Icon path={iconPath} color={enabled ? theme.palette.primary.main : theme.palette.grey[400]} />}
        />
    );
};
