import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { SectionId } from '../ContentContext/Enums';

import { PresentersBySection, useRealTimePresenterFocus } from './hooks/useRealTimePresenterFocus';
import useUpdateComponentFocus from './hooks/useUpdateComponentFocus';
import { PublisherContext } from './PublisherContext';

export interface FocusObserverProviderInterface {
    observer: IntersectionObserver | null;
    presentersBySection: PresentersBySection;
    sectionInView: string;
}

interface FocusObserverProviderProps {
    rootRef: React.RefObject<HTMLElement>;
}

const FocusObserverContext = createContext(undefined as unknown as FocusObserverProviderInterface);

const { Provider, Consumer } = FocusObserverContext;

const FocusObserverProvider: React.FC<FocusObserverProviderProps> = ({ rootRef, children }) => {
    const { sessionId, isPresenting, presenters } = useContext(PublisherContext);
    const { mutate: updateComponentFocus } = useUpdateComponentFocus();
    const [sectionInView, setSectionInView] = useState<string>(SectionId.OVERVIEW);
    const observerRef = useRef<IntersectionObserver | null>(null);

    const initialPresenterFocus = useMemo(() => {
        const focusMap = new Map<string, string>();
        presenters?.forEach((presenter) => {
            if (presenter.userId && presenter.componentId) {
                focusMap.set(presenter.userId, presenter.componentId);
            }
        });
        return focusMap;
    }, [presenters]);

    const presentersBySection = useRealTimePresenterFocus(initialPresenterFocus);

    useEffect(() => {
        const intersectionObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setSectionInView(entry.target.id);
                    }
                });
            },
            {
                root: rootRef.current,
                rootMargin: '-30% 0px -60% 0px'
            }
        );

        observerRef.current = intersectionObserver;
    }, [rootRef]);

    useEffect(() => {
        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
        };
    }, []);

    useEffect(() => {
        if (isPresenting && sectionInView) {
            updateComponentFocus({ componentId: sectionInView, sessionId });
        }
    }, [sectionInView, isPresenting, updateComponentFocus, sessionId]);

    return (
        <Provider
            value={{
                observer: observerRef.current,
                presentersBySection,
                sectionInView
            }}
        >
            {children}
        </Provider>
    );
};

export { FocusObserverContext, FocusObserverProvider, Consumer as FocusObserverConsumer };
