import React from 'react';

import { Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { UserListItem } from 'components/Users/UserListItem';
import { useAuth } from 'context/AuthContext';
import { useCurrentUser } from 'hooks/useCurrentUser';
import styled from 'styled-components';

import { CopyableText } from './CopyableText';
import { TokenDetails } from './TokenDetails';
import { UserDetails } from './UserDetails';

const StyledToken = styled.pre`
    border: 1px solid #ccc;
    background: #eee;
    text-overflow: wrap;

    white-space: pre-wrap;
    padding: 10px;
    word-wrap: break-word;
    font-size: 0.8rem;
`;

const StyledGroup = styled.div`
    margin-top: 1rem;
    margin-bottom: 1rem;
`;

export const CurrentStatus: React.FC = () => {
    const { token } = useAuth();
    const { currentUser } = useCurrentUser();

    if (!token && !currentUser) {
        return <Alert severity="error">Not currently authenticated</Alert>;
    }

    return (
        <>
            <Typography variant="h6">Current Status</Typography>
            <StyledGroup>
                {currentUser && currentUser.userId ? <UserListItem userId={currentUser.userId} /> : null}
            </StyledGroup>
            <StyledGroup>
                <Typography variant="h6">User Details</Typography>
                {currentUser && <UserDetails user={currentUser} />}
            </StyledGroup>
            <StyledGroup>
                <Typography variant="h6">Token Details</Typography>
                {token && <TokenDetails token={token} />}
            </StyledGroup>
            <StyledGroup>
                <Typography variant="h6">Access Token</Typography>
                <CopyableText text={token ?? ''}>
                    <StyledToken>{token}</StyledToken>
                </CopyableText>
            </StyledGroup>
        </>
    );
};
