import React, { forwardRef, useContext } from 'react';

import { EnhancedTableHead } from 'components/SortableTable/EnhancedTableHead';
import styled from 'styled-components';

import { StickyListContext } from './StickyListContext';
import { ROW_HEIGHT } from './TableCells/TableCell.styles';

export const TableHeadWrapper = styled.div`
    position: sticky !important;
    position: -webkit-sticky !important;
    display: flex;
    align-items: center;
    z-index: 10;
    min-width: 100%;
    width: unset !important;
    top: 0;
    left: 0;
    height: ${ROW_HEIGHT}px;

    thead {
        display: flex;
        flex-grow: 1;
    }

    th {
        height: ${ROW_HEIGHT}px;
        background-color: ${(props) => props.theme.colors.surface};
        display: flex;
        flex-grow: 1;
    }

    tr {
        display: flex;
        min-width: 100%;
        width: unset !important;
    }
`;

export const TableHead = forwardRef<HTMLDivElement>(({ children, ...rest }, ref) => {
    const {
        headCellDetails,
        order,
        orderBy,
        handleRequestSort,
        rowCount,
        onSelectAllClick,
        numSelected,
        includeEmptyHeadCell,
        isLoading
    } = useContext(StickyListContext);
    return (
        <div ref={ref} {...rest}>
            <TableHeadWrapper>
                <EnhancedTableHead
                    headCellDetails={headCellDetails}
                    order={order}
                    orderBy={orderBy as string}
                    handleRequestSort={handleRequestSort}
                    rowCount={rowCount}
                    onSelectAllClick={onSelectAllClick}
                    numSelected={numSelected}
                    includeEmptyHeadCell={includeEmptyHeadCell}
                    isLoading={isLoading}
                />
            </TableHeadWrapper>
            {children}
        </div>
    );
});
