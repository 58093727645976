import { useMemo } from 'react';

import { OptimistMutationContext, useOptimist } from '@adept-at/lib-react-optimist';
import { EntityInfo } from 'components/ContentContext/Interfaces';
import { typeToIdKey } from 'components/dash/utils';
import { ClientError } from 'graphql-request';
import useGqlClient from 'hooks/useGqlClient';
import { API_CONTENT } from 'lib/ApiConstants';
import { useSnackbar } from 'notistack';
import { useMutation, UseMutationResult } from 'react-query';

import { GetFavoritesResponse, queryName } from '../useGetFavorites';

import makeRemoveFavoriteItem from './makeRemoveFavoriteItem';

export interface RemoveFavoriteItemResult {
    removeFavoriteItem: {
        success: boolean;
    };
}

export interface RemoveFavoriteItemVariables {
    item: EntityInfo;
}

const useRemoveFavoriteItem = (): UseMutationResult<
    RemoveFavoriteItemResult,
    ClientError,
    RemoveFavoriteItemVariables,
    OptimistMutationContext
> => {
    const { enqueueSnackbar } = useSnackbar();
    const { client, withMutationOptions } = useGqlClient(API_CONTENT);

    const { registerUpdater: registerRemoveFavoriteItemUpdater, mutationOptions: removeFavoriteItemMutationOptions } =
        useOptimist<RemoveFavoriteItemResult, ClientError, RemoveFavoriteItemVariables>();

    const removeFavoriteItemOptions = useMemo(() => {
        return {
            onError: () => {
                enqueueSnackbar('An error occurred. Unable to remove item from favorites', { variant: 'error' });
            },
            onSuccess: () => {
                enqueueSnackbar('Item removed from favorites', { variant: 'success' });
            },
            refetchQuery: false
        };
    }, [enqueueSnackbar]);

    registerRemoveFavoriteItemUpdater<GetFavoritesResponse>(
        queryName,
        (previous, newItem) => {
            const idKey = typeToIdKey[newItem.item.type];
            const updatedItems = previous?.getUserFavorites?.items?.filter(
                (item) => !!item && item[idKey] !== newItem.item.id
            );

            return {
                getUserFavorites: {
                    ...previous?.getUserFavorites,
                    items: updatedItems
                }
            };
        },
        removeFavoriteItemOptions
    );

    return useMutation<RemoveFavoriteItemResult, ClientError, RemoveFavoriteItemVariables, OptimistMutationContext>(
        makeRemoveFavoriteItem(client),
        withMutationOptions(removeFavoriteItemMutationOptions)
    );
};

export default useRemoveFavoriteItem;
