import { OptimistMutationContext, useOptimist } from '@adept-at/lib-react-optimist';
import gql from 'graphql-tag';
import { getRetryCallback, shouldRetryGraphql } from 'hooks/request/retry';
import { GraphqlError } from 'hooks/useGqlClient/GraphqlError';
import { useBuildAuthenticatedMutationFn } from 'hooks/useGqlClient/useBuildAuthenticatedMutationFn';
import { Api } from 'lib/ApiConstants';
import { useSnackbar } from 'notistack';
import { useMutation, UseMutationResult } from 'react-query';
import { setCollectionEditedInSessionStorage } from 'utils/collection';

export const COMMIT_EDITS = gql`
    mutation commitEdits($collectionId: UUIDv4!, $expectedInfoVersion: Int!, $expectedItemsVersion: Int!) {
        commitEdits(
            collectionId: $collectionId
            expectedInfoVersion: $expectedInfoVersion
            expectedItemsVersion: $expectedItemsVersion
        ) {
            success
        }
    }
`;

export interface CommitEditsResponse {
    commitEdits: {
        success: boolean;
    };
}

export interface CommitEditsVariables {
    collectionId: string;
    expectedInfoVersion: number;
    expectedItemsVersion: number;
}

const COLLECTION_CHILDREN_MUST_HAVE_SAME_OWNER_CODE = 'COLLECTION_CHILDREN_MUST_HAVE_SAME_OWNER';
const FATAL_ERROR_CODES = [COLLECTION_CHILDREN_MUST_HAVE_SAME_OWNER_CODE];

export const useCommitEdits = (
    setPendingChanges: React.Dispatch<React.SetStateAction<boolean>>,
    refetchEditableCollection: () => void,
    refetchImmutableCollection: () => void,
    parentQuerySlug: string
): UseMutationResult<CommitEditsResponse, GraphqlError, CommitEditsVariables, OptimistMutationContext> => {
    const { mutationOptions } = useOptimist<CommitEditsResponse, GraphqlError, CommitEditsVariables>();

    const { mutationFn } = useBuildAuthenticatedMutationFn<CommitEditsResponse, CommitEditsVariables>(COMMIT_EDITS, {
        api: Api.Content
    });

    const { enqueueSnackbar } = useSnackbar();

    return useMutation<CommitEditsResponse, GraphqlError, CommitEditsVariables, OptimistMutationContext>(mutationFn, {
        ...mutationOptions,
        retry: getRetryCallback(shouldRetryGraphql(FATAL_ERROR_CODES)),
        onError: (e: GraphqlError) => {
            if (e.code === COLLECTION_CHILDREN_MUST_HAVE_SAME_OWNER_CODE) {
                enqueueSnackbar(e.message, { variant: 'error' });
                return;
            }
            enqueueSnackbar('An error occurred while publishing edits', { variant: 'error' });
        },
        onSuccess: (data, request) => {
            if (!data.commitEdits) {
                // versions out of sync - refresh view for user
                enqueueSnackbar('Editable collection version was out of sync, please try to publish changes again', {
                    variant: 'warning'
                });

                refetchEditableCollection();
            }

            if (data.commitEdits?.success === true) {
                enqueueSnackbar('Successfully published edits', { variant: 'success' });
                setCollectionEditedInSessionStorage(request.collectionId, parentQuerySlug);
                setPendingChanges(false);
                refetchImmutableCollection();
            }
        }
    });
};
