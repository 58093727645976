import React from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import styled from 'styled-components';
import { SKILL_CONTENT_WIDTH } from 'styles/variables';

import { EditorManyFieldsTextEditInPlace } from '../../mixins/editor/';

import { StyledButton } from './CaptionEdit.styles';

const EditorManyFieldsTextEditInPlaceContainer = styled.div`
    max-width: ${SKILL_CONTENT_WIDTH}px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
`;

interface CaptionEditProps {
    onSubmit: <TComponentFieldValue>(field: string) => (value: TComponentFieldValue) => void;
    value: string | undefined | null;
}

export const CaptionEdit: React.FC<CaptionEditProps> = ({ onSubmit, value }) => (
    <EditorManyFieldsTextEditInPlaceContainer>
        <EditorManyFieldsTextEditInPlace
            initialValue={value || ''}
            onSubmit={(value: string) => {
                onSubmit<string>('caption')(value);
            }}
        >
            {value ? (
                <p>{value}</p>
            ) : (
                <StyledButton color="primary" startIcon={<Icon path={mdiPlus} size={IconSize.Small} />}>
                    Add Caption
                </StyledButton>
            )}
        </EditorManyFieldsTextEditInPlace>
    </EditorManyFieldsTextEditInPlaceContainer>
);
