import { useMemo } from 'react';

import { ClientError } from 'graphql-request';
import useGqlClient from 'hooks/useGqlClient';
import { API_CONTENT } from 'lib/ApiConstants';
import { useSnackbar } from 'notistack';
import { useMutation, UseMutationResult } from 'react-query';

import makeAnswerQuestionBatch, {
    AnswerQuestionBatchProps,
    AnswerQuestionBatchResult
} from './makeAnswerQuestionBatch';

const useAnswerQuestionBatch = (): UseMutationResult<
    AnswerQuestionBatchResult,
    ClientError,
    AnswerQuestionBatchProps
> => {
    const { enqueueSnackbar } = useSnackbar();
    const { client, withMutationOptions } = useGqlClient(API_CONTENT);

    const answerQuestionBatchOptions = useMemo(() => {
        return {
            onError: () => {
                enqueueSnackbar('An error occurred unable to submit assessment', { variant: 'error' });
            }
        };
    }, [enqueueSnackbar]);

    return useMutation<AnswerQuestionBatchResult, ClientError, AnswerQuestionBatchProps>(
        makeAnswerQuestionBatch(client),
        withMutationOptions(answerQuestionBatchOptions)
    );
};

export default useAnswerQuestionBatch;
