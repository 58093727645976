import React, { createContext, useState, useContext, useMemo, useEffect } from 'react';

import { EntityType } from 'components/ContentContext/Enums';
import { LearnContext } from 'components/learn/LearnContext';
import { useIsAuthorizedEntityAction, SkillActions } from 'hooks/authorization';

interface LearnEditContextInterface {
    skillEditId: string;
    setSkillEditId: (skillId: string) => void;

    hasUpdatePermission: boolean;
}

const LearnEditContext = createContext(undefined as unknown as LearnEditContextInterface);

const { Provider, Consumer } = LearnEditContext;

const LearnEditProvider: React.FC = ({ children }) => {
    const [skillEditId, setSkillEditId] = useState('');

    const learnCtx = useContext(LearnContext);

    const skillEntity = useMemo(() => {
        return {
            type: EntityType.Skill,
            id: learnCtx?.skill?.skillId ?? skillEditId ?? null
        };
    }, [skillEditId, learnCtx.skill?.skillId]);

    const { isAuthorized, refetch } = useIsAuthorizedEntityAction(skillEntity, SkillActions.Update);

    useEffect(() => {
        if (skillEntity) {
            refetch();
        }
    }, [skillEntity]);

    return (
        <Provider
            value={{
                skillEditId,
                setSkillEditId,
                hasUpdatePermission: isAuthorized
            }}
        >
            {children}
        </Provider>
    );
};

export { LearnEditContext, LearnEditProvider, Consumer as LearnEditConsumer };
