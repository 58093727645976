import React, { ReactNode, useContext } from 'react';

import { Skill } from '../../ContentContext/Interfaces';
import { ControlOptions } from '../../engine/ComponentControls';
import { EngineComponent } from '../../engine/types';
import { SidebarContext, SidebarTab } from '../../SidebarContext';
import { COMPONENT_GUIDANCE_MAP } from '../constants';
import { GuidanceContext } from '../Guidance/GuidanceContext';

import BuilderComponent from './BuilderComponent';

const BuilderSections: React.FC<{ skill: Skill }> = ({ skill }) => {
    const { setActiveTab } = useContext(SidebarContext);
    const { showGuidance } = useContext(GuidanceContext);

    const renderComponents = (components: EngineComponent[]): ReactNode => {
        if (!components) return;

        const componentControlOptions: ControlOptions = {
            edit: {
                enabled: true
            }
        };

        return components.map((componentValues) => {
            const { id, type, order } = componentValues;

            componentControlOptions.guidance = {
                enabled: true,
                onClickOverride: () => {
                    showGuidance(COMPONENT_GUIDANCE_MAP[type]);
                    setActiveTab(SidebarTab.Guidance);
                }
            };

            return (
                <div key={`builder-component-${id}-${type}-${order}`}>
                    <BuilderComponent component={{ ...componentValues }} controlOptions={componentControlOptions} />
                </div>
            );
        });
    };

    return (
        <>
            {renderComponents(
                Object.entries(skill.components ?? {})
                    .sort((a, b) => a[1].order - b[1].order)
                    .map(([id, comp]) => ({ ...comp, id }))
            )}
        </>
    );
};

export default BuilderSections;
