import { useEffect, useRef, useCallback, useMemo } from 'react';

import { useCollector } from 'components/devtools/Collector';

import { useAuthToken, AuthTokenStatus } from '../useAuthToken';

import { SessionRefreshStatus, useSessionRefresh } from './useSessionRefresh';
import { useSessionRefreshRetries } from './useSessionRefreshRetries';

/**
 * Notice, due to the nature of React rendering non-deterministically, we may see gaps in these transitions.
 *
 * Example #1: Successful login via login form for user:
 *
 * Initial -> RetrievingToken -> Ready
 *
 * Example #2: Page load for user with a valid (non-expired) token:
 *
 * Initial -> RetrievingToken -> Ready
 *
 * Example #3: Page load for user with an expired token but a valid session to be refreshed:
 *
 * Initial -> RetrievingToken -> RefreshingSession -> StartingTokenRetrievalFollowingRefresh -> RetrievingToken -> Ready
 *
 * Example #4: Page load for invalid session (prompted by Auth Awareness Cookie set even though session is bad):
 *
 * Initial -> RetrievingToken -> RefreshingSession -> None
 *
 * Example #5: Session refresh from Ready state
 *
 * Ready -> RefreshingSession -> StartingTokenRetrievalFollowingRefresh -> RetrievingToken -> Ready
 *
 * Example #6: Session refresh succeeds but subsequently retrieved token is invalid
 *
 * Ready -> RefreshingSession -> StartingTokenRetrievalFollowingRefresh -> RetrievingToken -> Ready
 *
 */
export enum TokenWithRefreshStatus {
    Initial = 'Initial',
    StartingSessionRefresh = 'StartingSessionRefresh',
    RefreshingSession = 'RefreshingSession',

    // The time between a refresh success and a token retrieval that is about to start
    StartingTokenRetrievalFollowingRefresh = 'StartingTokenRetrievalFollowingRefresh',

    RetrievingToken = 'RetrievingToken',
    RetrievingTokenRetry = 'RetrievingTokenRetry',
    Ready = 'Ready',
    None = 'None'
}

interface UseTokenWithRefreshInterface {
    token: string | null;
    retrieveToken: () => void;
    refresh: () => void;
    reset: () => void;
    status: TokenWithRefreshStatus;
}

/**
 * A valid web session has the means to refresh itself, which means it will communicate with Accounts API in a web
 * context. This will, in turn, set a new auth cookie (httpOnly) on the browser, that can then be used to retrieve
 * a new access token for API calls.
 *
 * This custom hook should observe the auth token for errors and report if the session is refreshing or has gone bad
 * due to not being able to refresh and subsequently obtain a valid token
 */
export const useTokenWithRefresh = (): UseTokenWithRefreshInterface => {
    /**
     * Flags to help protect against firing off too many requests
     */
    const tokenHasBeenRetrievedSinceRefresh = useRef<boolean>(false);

    const previousStatus = useRef<TokenWithRefreshStatus>(TokenWithRefreshStatus.Initial);

    const {
        incrementRetries: incrementRefreshRetries,
        resetRetries: resetRefreshRetries,
        hasReachedMaxRetries: hasReachedMaxRefreshRetries
    } = useSessionRefreshRetries();

    const { refresh: refreshSession, status: refreshStatus, reset: resetRefreshMutation } = useSessionRefresh();

    const { addHistory } = useCollector();

    const {
        token,
        retrieveToken: retrieveTokenMutation,
        status: authTokenStatus,
        reset: resetTokenMutation
    } = useAuthToken();

    // Protect against hammering refreshes when something expires, allows only one through
    const refresh = useCallback(() => {
        if (refreshStatus !== SessionRefreshStatus.Loading) {
            incrementRefreshRetries();
            tokenHasBeenRetrievedSinceRefresh.current = false;
            refreshSession();
        }
    }, [refreshSession, refreshStatus, incrementRefreshRetries]);

    /**
     * Do not retrieve token if a refresh is in flight. Wait until the refresh is done
     */
    const retrieveToken = useCallback(() => {
        if (
            // Protect against loading statuses
            refreshStatus === SessionRefreshStatus.Loading ||
            authTokenStatus === AuthTokenStatus.Loading ||
            tokenHasBeenRetrievedSinceRefresh.current
        ) {
            return; // no-op if any mutation is already loading or we already pulled a token since refreshing
        }

        tokenHasBeenRetrievedSinceRefresh.current = true;

        retrieveTokenMutation();
    }, [retrieveTokenMutation, refreshStatus, authTokenStatus]);

    /**
     * React to a change in the refresh status by:
     *
     * - resetting indicator of token retrieved since refresh for this session
     * - opening circuit (setting inFlight to false)
     * - retrieving new token
     *
     */
    useEffect(() => {
        if (refreshStatus === SessionRefreshStatus.Success) {
            retrieveToken();
        }
    }, [refreshStatus, retrieveToken]);

    const status = useMemo(() => {
        const determineNewStatus = () => {
            if (authTokenStatus === AuthTokenStatus.Initial && refreshStatus === SessionRefreshStatus.Initial) {
                return TokenWithRefreshStatus.Initial;
            }

            // Can only transition to Ready from RetrievingToken
            if (authTokenStatus === AuthTokenStatus.Ready) {
                return TokenWithRefreshStatus.Ready;
            }

            if (refreshStatus === SessionRefreshStatus.Loading) {
                return TokenWithRefreshStatus.RefreshingSession;
            }

            if (hasReachedMaxRefreshRetries() && authTokenStatus === AuthTokenStatus.Error) {
                return TokenWithRefreshStatus.None;
            }

            // If we cannot refresh due to an error, we have reached a final state of "None"
            if (refreshStatus === SessionRefreshStatus.Error) {
                return TokenWithRefreshStatus.None;
            }

            if (authTokenStatus === AuthTokenStatus.Loading) {
                return TokenWithRefreshStatus.RetrievingToken;
            }

            if (authTokenStatus === AuthTokenStatus.Error) {
                return TokenWithRefreshStatus.StartingSessionRefresh;
            }

            if (refreshStatus === SessionRefreshStatus.Success) {
                return TokenWithRefreshStatus.StartingTokenRetrievalFollowingRefresh;
            }

            // No new state identified, return the current value
            return previousStatus.current;
        };

        previousStatus.current = determineNewStatus();

        return previousStatus.current;
    }, [authTokenStatus, refreshStatus, hasReachedMaxRefreshRetries]);

    useEffect(() => {
        addHistory('tokenStatus', status);
    }, [status, addHistory]);

    /**
     * Observe authTokenStatus for an error and perform a refresh if it occurs. This will allow any token retrieval to
     * appropriately kick off a refresh.
     *
     * Note, this does not occur when a query or mutation is performed from down within the application. This is purely
     * for initial load or subsequent token retrieval issues.
     *
     * @TODO SAS make sure this does not fire if we perform a refresh from a Mutation error handler that does not succeed
     */
    useEffect(() => {
        if (
            status === TokenWithRefreshStatus.StartingSessionRefresh &&
            authTokenStatus === AuthTokenStatus.Error &&
            !hasReachedMaxRefreshRetries()
        ) {
            /**
             * Resetting prior to performing refresh will update authTokenStatus to Initial, keeping this from being
             * invoked multiple times from the same auth token retrieval error
             */
            resetTokenMutation();
            refresh();
        }
    }, [refresh, status, authTokenStatus, resetTokenMutation, hasReachedMaxRefreshRetries]);

    useEffect(() => {
        if (authTokenStatus === AuthTokenStatus.Ready) {
            resetRefreshRetries();
        }
    }, [authTokenStatus, resetRefreshRetries]);

    const reset = () => {
        tokenHasBeenRetrievedSinceRefresh.current = false;
        resetRefreshRetries();
        resetTokenMutation();
        resetRefreshMutation();
    };

    return {
        // @TODO expose error communicating with Accounts API

        token,
        retrieveToken,
        refresh: () => {
            reset();
            refresh();
        },
        status,
        reset
    };
};
