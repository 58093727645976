import React from 'react';

import styled from 'styled-components';

import AssessmentComponents from '../../components/assessments/Components';
import useAddAssessmentComponent from '../../components/assessments/hooks/useAddAssessmentComponent';
import useRemoveAssessmentComponent from '../../components/assessments/hooks/useRemoveAssessmentComponent';
import { useRemoveComponentFromLocalStateOnly } from '../../components/assessments/hooks/useRemoveComponentFromLocalStateOnly';
import useUpsertAssessmentComponent from '../../components/assessments/hooks/useUpsertAssessmentComponent';
import { Assessment } from '../../components/assessments/types';
import { EntityType } from '../../components/ContentContext/Enums';
import { EngineProvider } from '../../components/engine/EngineContext';
import {
    ComponentEditorProvider,
    EditorComponentControls,
    EditorComponentTitle
} from '../../components/engine/mixins/editor';
import { ComponentFocusableProvider } from '../../components/engine/mixins/focusable';
import { ComponentEngineMode, EngineComponent } from '../../components/engine/types';
import { SKILL_CONTAINER_WIDTH } from '../../styles/variables';

interface EditorProps {
    // queryKey: any;
    assessmentDetails: {
        components?: Record<string, EngineComponent> | null;
    };
    assessmentId: string;
    // comments: { private: Comment[]; public: Comment[] };
}

export interface GetAssessmentByIdResponse {
    getAssessmentById: Assessment;
}

export const AssessmentEditorWrapper = styled.div`
    max-width: ${SKILL_CONTAINER_WIDTH}px;
    width: 100%;
    margin: 0 auto;
`;

export const ComponentsWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const ComponentControls: React.FC = () => (
    <>
        <EditorComponentControls />
    </>
);
const ComponentTitle: React.FC = () => <EditorComponentTitle />;

const Editor: React.FC<EditorProps> = ({ assessmentId, assessmentDetails }) => {
    const { mutate: upsertComponent } = useUpsertAssessmentComponent(EntityType.Assessment, assessmentId);
    const addComponent = useAddAssessmentComponent(assessmentId);

    // Component removal can take two forms-- one requires informing the server about the removal, one does not.
    const { mutate: removeComponent } = useRemoveAssessmentComponent(assessmentId);
    const removeComponentFromLocalStateOnly = useRemoveComponentFromLocalStateOnly(assessmentId);

    return (
        <AssessmentEditorWrapper>
            <EngineProvider
                container={{ type: EntityType.Assessment, id: assessmentId }}
                components={assessmentDetails.components ?? {}}
                controlsComponent={ComponentControls}
                titleComponent={ComponentTitle}
                mode={ComponentEngineMode.Edit}
            >
                <ComponentFocusableProvider>
                    <ComponentEditorProvider
                        onUpsertComponent={upsertComponent}
                        onAddComponent={addComponent}
                        onRemoveComponent={removeComponent}
                        onRemoveComponentFromLocalStateOnly={removeComponentFromLocalStateOnly}
                    >
                        <ComponentsWrapper>
                            <AssessmentComponents />
                        </ComponentsWrapper>
                    </ComponentEditorProvider>
                </ComponentFocusableProvider>
            </EngineProvider>
        </AssessmentEditorWrapper>
    );
};

export default Editor;
