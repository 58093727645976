import { useState, useCallback } from 'react';

export interface UseModalOutput {
    modalOpen: boolean;
    handleModalOpen: () => void;
    handleModalClose: () => void;
}

export const useModal = (openByDefault = false): UseModalOutput => {
    const [modalOpen, setModalOpen] = useState(openByDefault);

    const handleModalOpen = useCallback(() => {
        setModalOpen(true);
    }, []);

    const handleModalClose = useCallback(() => {
        setModalOpen(false);
    }, []);

    return { modalOpen, handleModalOpen, handleModalClose };
};
