import Editor from 'react-simple-code-editor';
import styled from 'styled-components';

export const CodeEditForm = styled.div`
    fieldset {
        display: block;
        margin: 10px 0;

        label {
            position: relative;
            transform: none;
            padding-bottom: 5px;
        }
    }
`;

export const StyledEditor = styled(Editor)`
    font-family: "Inconsolata", "Menlo", "Consolas", monospace;
    font-size: 18;
    outline: 1
    counter-reset: line;
    border: 1px solid #ced4da;
    margin-bottom: 25px;

    #codeArea {
        outline: none;
        padding-left: 60px !important;
    }

    pre {
        padding-left: 60px !important;
    }

    .editorLineNumber {
        position: absolute;
        left: 0px;
        color: #cccccc;
        text-align: right;
        width: 40px;
        font-weight: 100;
    }
`;
