export const canUseDOM = () => !!(typeof window !== 'undefined' && window.document);

export const scrollComponentIntoView = (element: HTMLElement | null) => {
    if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
};

export const scrollToTargetWithOffset = (element: HTMLElement | null, offset = 0) => {
    if (element && canUseDOM()) {
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPos = elementRect - bodyRect;
        const offsetPos = elementPos - offset;

        window.scrollTo({
            top: offsetPos,
            behavior: 'smooth'
        });
    }
};

export default scrollComponentIntoView;
