import React from 'react';

import { CropDetails } from '@adept-at/lib-react-components/build/cjs/lib/SkillComponents/ImageComponentValues';
import { Modal } from 'components/EditableImage/Modal';

import { useCollectionImageContext } from '../context/CollectionImageContext';

interface UploadImageModalProps {
    circularCrop?: boolean;
    maxWidth?: string;
}

export const UploadImageModal: React.FC<UploadImageModalProps> = ({ circularCrop, maxWidth }) => {
    const { uploadModalOpen, uploadResolutions, uploadAttributes, handleUploadModalClose, onFinishUpload } =
        useCollectionImageContext();

    return (
        <Modal
            circularCrop={circularCrop}
            maxWidth={maxWidth ?? undefined}
            open={uploadModalOpen ?? false}
            onClose={() => {
                if (handleUploadModalClose) {
                    handleUploadModalClose();
                }
            }}
            onFinishUpload={(assetId: string, image: string | null, crop: CropDetails) => {
                if (onFinishUpload) {
                    onFinishUpload(assetId, image, crop);
                }
            }}
            aspectRatio={uploadResolutions?.aspectRatio}
            attributes={uploadAttributes}
            resolution={uploadResolutions}
        />
    );
};
