import { GraphQLClient, gql } from 'graphql-request';
import { MessageEntityType } from 'lib/communication/message/MessageRecord';
import { MutationFunction } from 'react-query';

import buildGqlMutationFn from '../../../useGqlClient/helpers/buildGqlMutationFn';

const ACK_MESSAGE_MUTATION = gql`
    mutation ackEntityMessage(
        $entityType: MessageEntityType
        $entityId: UUIDv4!
        $messageId: String
        $ackType: MessageAckType
    ) {
        ackEntityMessage(entityType: $entityType, entityId: $entityId, messageId: $messageId, ackType: $ackType) {
            success
        }
    }
`;

interface AckMessageResult {
    ackEntityMessage: { success: boolean };
}

interface AckMessageParams {
    entityType: MessageEntityType;
    entityId: string;
    messageId: string;
    ackType: string;
}

const ackMessageApi = (client: GraphQLClient): MutationFunction<AckMessageResult, AckMessageParams> =>
    buildGqlMutationFn<AckMessageResult, AckMessageParams>(client, ACK_MESSAGE_MUTATION);

export default ackMessageApi;
