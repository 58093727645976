import React, { useMemo, createContext, useEffect, useState, useRef, useContext, useCallback } from 'react';

import { RichTextComponentValuesBody } from '@adept-at/lib-react-components';
import { SectionId } from 'components/ContentContext/Enums';
import { DEFAULT_SECTION_TITLE } from 'components/engine/Components/SectionTitle';
import {
    EditorState,
    convertFromRaw,
    convertToRaw,
    CompositeDecorator,
    ContentState,
    RichUtils,
    Modifier,
    SelectionState,
    convertFromHTML,
    AtomicBlockUtils,
    RawDraftContentState,
    ContentBlock
} from 'draft-js';

import { EmbeddedDecorator } from './Plugins/Decorator';
import {
    addNewBlockAt,
    DraftJsBlock,
    DraftJsEntity,
    EditorChangeType,
    EmbeddedEntityComponentValues,
    getSelectedText
} from './utils';

interface RichTextEditorContextInterface {
    toggleBlockType: (e) => void;
    toggleInlineStyle: (e) => void;
    value: RichTextComponentValuesBody;
    decorators: CompositeDecorator;
    editorState: EditorState;
    setEditorState: React.Dispatch<React.SetStateAction<EditorState>>;
    onPluginIconClicked: (name) => void;
    registerPluginCallback: (name, callback) => void;
    addEntity: (entityType: DraftJsEntity, data?) => void;
    updateEntity: (entityKey: string, data) => void;
    handleEntityModalOpen: (type: DraftJsEntity, entityToUpdate?: EmbeddedEntityComponentValues, key?: string) => void;
    editorRef: React.RefObject<HTMLDivElement>;
    selectedEntityKey?: string;
    addSection: () => void;
    removeEntity: () => void;
    setSelectedEntityKey: React.Dispatch<React.SetStateAction<string | undefined>>;
    selectedText: {
        text: string | undefined;
        multiLine: boolean;
    };
}

export const DRAFTJS_V0 = 'draftjs-v0';

export const RichTextEditorContext = createContext({} as RichTextEditorContextInterface);

interface RichTextEditorProviderProps {
    initialValue: RichTextComponentValuesBody;
    onChange: (value: RichTextComponentValuesBody) => void;
    handleEntityModalOpen: (type: DraftJsEntity, entityToUpdate?: EmbeddedEntityComponentValues, key?: string) => void;
    componentId?: string;
}

const buildDefaultEditorState = (decorators, defaultText) => {
    const defaultEditorState = EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(`<h1>${defaultText}</h1>`) as unknown as ContentBlock[]),
        decorators
    );
    const contentState = defaultEditorState.getCurrentContent();
    const contentBlock = contentState.getFirstBlock();

    if (contentBlock) {
        return EditorState.forceSelection(
            defaultEditorState,
            new SelectionState({
                anchorKey: contentBlock.getKey(),
                anchorOffset: 0,
                focusKey: contentBlock.getKey(),
                focusOffset: contentBlock.getLength()
            })
        );
    }
    return defaultEditorState;
};

const componentIdToDefaultText = {
    [SectionId.OVERVIEW]: 'Overview',
    [SectionId.CONCLUSION]: 'Conclusion'
};

export const RichTextEditorProvider: React.FC<RichTextEditorProviderProps> = ({
    initialValue,
    handleEntityModalOpen,
    onChange,
    componentId,
    children
}) => {
    const decorators = new CompositeDecorator([EmbeddedDecorator]);

    const [editorState, setEditorState] = useState(() => {
        const defaultText = (componentId ? componentIdToDefaultText[componentId] : null) ?? DEFAULT_SECTION_TITLE;

        if (!initialValue || (initialValue as unknown as string) === '') {
            return buildDefaultEditorState(decorators, defaultText);
        }

        const { type, val: rawValue } = initialValue;

        if (!type || type !== DRAFTJS_V0) {
            return buildDefaultEditorState(decorators, defaultText);
        }

        // use default editor state if raw value only has 1 block that is empty
        if (rawValue?.blocks?.length === 1 && !rawValue.blocks[0].text?.trim()) {
            return buildDefaultEditorState(decorators, defaultText);
        }

        // Attempt to convert from raw.
        try {
            const content = convertFromRaw(rawValue as unknown as RawDraftContentState);
            return EditorState.createWithContent(content, decorators);
        } catch (error) {
            console.error('An error occurred while converting the content from raw.', {
                error,
                initialValue
            });
        }

        // Attempt to start with a simple string value
        try {
            // start from a simple string
            const content = ContentState.createFromText(rawValue as unknown as string);
            return EditorState.createWithContent(content, decorators);
        } catch (error) {
            console.error('An error occurred while converting the content.', { error, initialValue });
        }

        return buildDefaultEditorState(decorators, defaultText);
    });

    const selectedText = useMemo(() => {
        return getSelectedText(editorState.getCurrentContent(), editorState.getSelection());
    }, [editorState]);

    const editorRawValue = useMemo(
        () => ({ type: 'draftjs-v0', val: convertToRaw(editorState.getCurrentContent()) }),
        [editorState]
    );

    useEffect(() => {
        onChange(editorRawValue as any);
    }, [editorRawValue, onChange]);

    const pluginCallbacks = useRef({});

    const registerPluginCallback = (name, callback) => {
        pluginCallbacks.current[name] = callback;
    };

    const onPluginIconClicked = (name) => {
        const callback = pluginCallbacks.current[name];

        if (callback) {
            callback();
        }
    };

    const toggleBlockType = (styleName) => {
        setEditorState(RichUtils.toggleBlockType(editorState, styleName));
    };

    const removeEntity = () => {
        const contentState = editorState.getCurrentContent();
        const selectionState = editorState.getSelection();
        const startKey = selectionState.getStartKey();
        const contentBlock = contentState.getBlockForKey(startKey);
        let newlySelectedBlock = contentState.getBlockAfter(startKey) ?? contentState.getBlockBefore(startKey);

        let updatedContentState;
        if (!newlySelectedBlock) {
            const updatedEditorState = addNewBlockAt(editorState, contentBlock.getKey());
            updatedContentState = updatedEditorState.getCurrentContent();
            newlySelectedBlock = updatedContentState.getBlockAfter(contentBlock.getKey());
        }

        const withoutEntity = Modifier.removeRange(
            updatedContentState ?? contentState,
            new SelectionState({
                anchorKey: contentBlock.getKey(),
                anchorOffset: 0,
                focusKey: contentBlock.getKey(),
                focusOffset: contentBlock.getLength()
            }),
            'forward'
        );

        if (newlySelectedBlock) {
            const blockMap = withoutEntity.getBlockMap().delete(contentBlock.getKey());
            const withoutBlock = withoutEntity.merge({
                blockMap,
                selectionAfter: new SelectionState({
                    anchorKey: newlySelectedBlock?.getKey(),
                    anchorOffset: 0,
                    focusKey: newlySelectedBlock?.getKey(),
                    focusOffset: newlySelectedBlock?.getLength()
                })
            });
            return setEditorState(EditorState.push(editorState, withoutBlock as any, EditorChangeType.REMOVE_RANGE));
        }

        setEditorState(EditorState.push(editorState, withoutEntity, EditorChangeType.REMOVE_RANGE));
    };

    const addSection = () => {
        const selectionState = editorState.getSelection();
        const contentState = editorState.getCurrentContent();
        const currentBlock = contentState.getBlockForKey(selectionState.getStartKey());

        let state = editorState;
        const blockType = currentBlock.getType();
        if (blockType !== DraftJsBlock.H1) {
            state = RichUtils.toggleBlockType(state, DraftJsBlock.H1);
        }

        const inlineStyles = state.getCurrentInlineStyle();
        for (const style of inlineStyles.toArray()) {
            state = RichUtils.toggleInlineStyle(state, style);
        }

        setEditorState(state);
    };

    const toggleInlineStyle = (styleName) => {
        setEditorState(RichUtils.toggleInlineStyle(editorState, styleName));
    };

    const addEntity = (entityType: DraftJsEntity, data = {}) => {
        if (data) {
            const contentState = editorState.getCurrentContent();
            const contentStateWithEntity = contentState.createEntity(entityType, 'IMMUTABLE', data);
            const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
            const newEditorState = EditorState.set(editorState, {
                currentContent: contentStateWithEntity
            });
            const withAtomicBlock = AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');
            setEditorState(withAtomicBlock);
        }
    };

    const updateEntity = (entityKey: string, data) => {
        const updatedContent = editorState.getCurrentContent().replaceEntityData(entityKey, data);
        const newEditorState = EditorState.set(editorState, { currentContent: updatedContent });
        setEditorState(newEditorState);
    };

    const editorRef = useRef<HTMLDivElement>(null);
    const [selectedEntityKey, setSelectedEntityKey] = useState<string>();

    return (
        <RichTextEditorContext.Provider
            value={{
                editorState,
                setEditorState,
                editorRef,
                selectedEntityKey,
                setSelectedEntityKey,
                value: initialValue,
                decorators,
                toggleBlockType,
                toggleInlineStyle,
                addEntity,
                updateEntity,
                removeEntity,
                onPluginIconClicked,
                registerPluginCallback,
                addSection,
                handleEntityModalOpen,
                selectedText
            }}
        >
            {children}
        </RichTextEditorContext.Provider>
    );
};

export const useRichTextEditor = (): RichTextEditorContextInterface => {
    const context = useContext(RichTextEditorContext);

    if (!context) {
        throw new Error('useRichTextEditor must be used within a RichTextEditor context');
    }

    return context;
};
