import React, { Fragment } from 'react';

import styled from 'styled-components';

import CommentComponent from './Comment';
import { Divider } from './Comment.style';
import { Comment } from './interfaces';

const DividerWithPadding = styled(Divider)`
    margin: 0 1rem;
`;

interface Props {
    comments: Comment[];
}

const CommentList: React.FC<Props> = ({ comments }) => {
    return (
        <>
            {comments.map((comment, i) => (
                <Fragment key={i}>
                    <CommentComponent comment={comment} />
                    <DividerWithPadding />
                </Fragment>
            ))}
        </>
    );
};

export default CommentList;
