import { MediaStatus } from 'components/rooms/PublisherContext';
import * as yup from 'yup';

import { MessageEventType } from '../..';
import AckableAdepteductMessage from '../../../base/AckableAdepteductMessage';
import PossibleEventFields from '../../PossibleEventFields';

export interface EventFieldsSessionCameraToggled extends PossibleEventFields {
    sessionId: string;
    userId: string;
    status: MediaStatus;
    version: number;
}

export default class ChannelMeetingSessionCameraToggled extends AckableAdepteductMessage<EventFieldsSessionCameraToggled> {
    schema = yup.object({
        sessionId: yup.string(),
        userId: yup.string(),
        status: yup.string(),
        version: yup.number()
    });

    type = MessageEventType.ChannelMeetingSessionCameraToggled;
}
