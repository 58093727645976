import React from 'react';

import { ComponentValuesType } from '@adept-at/lib-react-components';
import { Backdrop, Button, Fade } from '@material-ui/core';

import { Buttons, ModalBody, StyledModal, Title } from '../../../../modals/Modal.styles';

interface RemoveComponentModal {
    isOpen: boolean;
    componentType: ComponentValuesType;
    onConfirm: () => void;
    onCancel?: () => void;
}

const BACKDROP_TIMEOUT_DEFAULT = 500;

const getRemoveMessage = (componentType: ComponentValuesType) => {
    if (componentType === ComponentValuesType.SectionTitle) {
        return 'Removing a section will not delete the components in that section.';
    }

    return 'Are you sure you want to delete this component?';
};

const getRemoveConfirmMessage = (componentType: ComponentValuesType) => {
    if (componentType === ComponentValuesType.SectionTitle) {
        return 'Confirm Removal';
    }

    return 'Delete Component';
};

const RemoveComponentModal: React.FC<RemoveComponentModal> = ({
    isOpen = false,
    componentType,
    onConfirm = () => {},
    onCancel = () => {}
}) => (
    <StyledModal
        aria-labelledby="remove-component"
        aria-describedby="remove-component"
        open={isOpen}
        onClose={onCancel}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: BACKDROP_TIMEOUT_DEFAULT
        }}
    >
        <Fade in={isOpen}>
            <ModalBody>
                <Title aria-label="confirm delete component">{getRemoveMessage(componentType)}</Title>
                <Buttons>
                    <Button color="primary" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button color="primary" variant="contained" onClick={onConfirm}>
                        {getRemoveConfirmMessage(componentType)}
                    </Button>
                </Buttons>
            </ModalBody>
        </Fade>
    </StyledModal>
);

export { RemoveComponentModal };
