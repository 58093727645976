const EDITED_SKILLS_KEY = 'edited-skills';

export const getEditedSkillsFromSessionStorage = (): string[] => {
    const fromStorage = sessionStorage.getItem(EDITED_SKILLS_KEY);
    const editedSkills = fromStorage ? JSON.parse(fromStorage) : [];
    return editedSkills;
};

export const setSkillEditedInSessionStorage = (skillId?: string, tenantSlug?: string, skillSlug?: string): void => {
    try {
        const editedSkills = getEditedSkillsFromSessionStorage();
        sessionStorage.setItem(
            EDITED_SKILLS_KEY,
            JSON.stringify([
                ...new Set([
                    ...editedSkills,
                    ...(skillId ? [skillId] : []),
                    ...(tenantSlug && skillSlug ? [`${tenantSlug}/${skillSlug}`] : [])
                ])
            ])
        );
    } catch (err) {
        console.error(`Unable to add skill ${skillId} to session storage "${EDITED_SKILLS_KEY}"`, err);
    }
};
