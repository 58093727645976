import { Images, ItemProgress } from 'components/ContentContext/Interfaces';
import { progressFragment } from 'components/dash/fragments';
import gql from 'graphql-tag';
import { useBuildAuthenticatedQueryFn } from 'hooks/useGqlClient/useBuildAuthenticatedQueryFn';
import { Api } from 'lib/ApiConstants';
import { QueryFunction, QueryKey } from 'react-query';

import { CollectionChild } from '../context/useGetTenantProfileForOwner';
import { collectionBasicDetailFragment } from '../fragments';

export const buildGetCollectionBasicDetailsQueryKey = (parentCollectionId: string): QueryKey => [
    'getCollectionBasicDetails',
    parentCollectionId
];

export interface CollectionBasicStats {
    type: CollectionChild;
    count: number;
}

export interface CollectionBasicDetail {
    collectionId: string;
    title: string;
    titleSub?: string;
    tenantSlug?: string;
    description?: any;
    images: Images | null;
    collectionSlug: string | null;
    visible: boolean;
    stats: {
        estimatedSecondsToConsume: number;
        descendants: CollectionBasicStats[];
    } | null;
    editableCollectionId: string;
    progress?: ItemProgress;
}

export interface GetCollectionBasicDetailsResponse {
    getCollectionBasicDetails: CollectionBasicDetail[];
}

export const getCollectionBasicDetailsQuery = gql`
    query getCollectionBasicDetails($collectionIds: [UUIDv4!]) {
        getCollectionBasicDetails(collectionIds: $collectionIds) {
            ${collectionBasicDetailFragment}
            ${progressFragment}
        }
    }
`;

export const useGetCollectionBasicDetails = (
    collectionIds: string[]
): QueryFunction<GetCollectionBasicDetailsResponse> => {
    const { queryFn } = useBuildAuthenticatedQueryFn<GetCollectionBasicDetailsResponse, { collectionIds: string[] }>(
        getCollectionBasicDetailsQuery,
        { collectionIds },
        { api: Api.Content }
    );

    return queryFn;
};
