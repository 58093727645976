/* istanbul ignore file */

import React, { createContext } from 'react';

import useHistoricalMessageFetcher, { UseHistoricalMessageFetcherOutput } from './useHistoricalMessageFetcher';
import useHistoricalMessagesRepository, {
    UseHistoricalMessagesRepositoryOutput
} from './useHistoricalMessageRepository';

/**
 * Do not consume directly, use hooks in the hooks/communication directory instead.
 * This is because it works in tandem with RealTimeCommunicationContext.
 */
export interface HistoricalCommunicationContextOutput
    extends UseHistoricalMessagesRepositoryOutput,
        UseHistoricalMessageFetcherOutput {}

const HistoricalCommunicationContext = createContext<HistoricalCommunicationContextOutput>(
    undefined as unknown as HistoricalCommunicationContextOutput
);

export const HistoricalCommunicationProvider: React.FC = ({ children }) => {
    const { messagesByChannelId, appendMessagesToChannel, ackMessage, ackMessageBatch, updateMessage } =
        useHistoricalMessagesRepository();

    const fetchedQueriesOutput = useHistoricalMessageFetcher({ appendMessagesToChannel });

    return (
        <HistoricalCommunicationContext.Provider
            value={{
                messagesByChannelId,
                appendMessagesToChannel,
                ackMessage,
                ackMessageBatch,
                updateMessage,
                ...fetchedQueriesOutput
            }}
        >
            {children}
        </HistoricalCommunicationContext.Provider>
    );
};

export default HistoricalCommunicationContext;
