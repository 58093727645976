import React, { useMemo } from 'react';

import { modularScale } from '@adept-at/lib-react-components';
import { renderFormattedContent } from 'lib/communication/markdown';
import styled from 'styled-components';

import AdepteductMessageProps from '../AdepteductMessageProps';

import StandardChatMessage from './StandardChatMessage';

const MessagePostRenderedMarkdown = styled.div`
    width: calc(100% - 0.2rem - 0.2rem);
    display: table;
    table-layout: fixed;
    word-wrap: break-word;

    > *:not(:first-child) {
        margin-top: 10px;
    }

    a {
        color: ${(props) => props.theme.colors.primary};

        &:hover {
            text-decoration: underline;
        }
    }

    code {
        display: block;
        border: 1px solid ${(props) => props.theme.colors.border};
        background-color: ${(props) => props.theme.colors.codeBackground};
        padding: 10px 5px;
        border-radius: 5px;
    }

    ul {
        display: block;
    }

    li {
        margin-left: 20px;
    }

    #edited-text {
        margin-left: 4px;
        color: ${(props) => props.theme.colors.textTertiary};
        font-size: ${modularScale(-1)};
    }

    #deleted-text {
        color: ${(props) => props.theme.colors.textTertiary};
    }
`;

/** Named Component to differentiate from the message itself */
const ChannelPostMessageComponent = React.forwardRef<HTMLDivElement | null, AdepteductMessageProps>(
    ({ destination, message }, ref) => {
        const formattedContent = useMemo(
            () =>
                message.deletedAt
                    ? '<span id="deleted-text">This message was deleted.</span>'
                    : renderFormattedContent(message.fields.text ?? '', message.version > 1),
            [message.deletedAt, message.fields.text, message.version]
        );

        return (
            <StandardChatMessage destination={destination} message={message} ref={ref}>
                <MessagePostRenderedMarkdown dangerouslySetInnerHTML={{ __html: formattedContent }} />
            </StandardChatMessage>
        );
    }
);

export default ChannelPostMessageComponent;
