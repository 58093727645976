import { useState, useEffect } from 'react';

interface UseEnrollmentPriceValueProps {
    initialValue?: number;
    minimumValue?: number;
    onValueUpdated?: (value: number) => void;
}

interface UseEnrollmentPriceValueInterface {
    formattedValue: string;
    onIncrease: () => void;
    onDecrease: () => void;
    onChange: (e) => void;
    onFocus: () => void;
    onBlur: () => void;
}

const CENTS_MULTIPLIER = 100;

// Default to $5 if they haven't provided a value
const DEFAULT_INTIIAL_VALUE = 5 * CENTS_MULTIPLIER;

// Increment/Decrement value to be used by the onIncrease() and onDecrease() callbacks
const INCREMENT_STEP = 1 * CENTS_MULTIPLIER;

// Tranform cents into a float with two decimals.
const formatValue = (value: number) => (value / (1 * INCREMENT_STEP)).toFixed(2);

export const useEnrollmentPriceValue = (
    {
        initialValue = DEFAULT_INTIIAL_VALUE,
        minimumValue = DEFAULT_INTIIAL_VALUE,
        onValueUpdated
    }: UseEnrollmentPriceValueProps = {
        initialValue: DEFAULT_INTIIAL_VALUE,
        minimumValue: DEFAULT_INTIIAL_VALUE
    }
): UseEnrollmentPriceValueInterface => {
    const [value, setValue] = useState<number>(initialValue);
    const [formattedValue, setFormattedValue] = useState<string>(formatValue(initialValue));

    // currentlyEditingValue is represented as a string since the controlled input is a string value
    const [currentlyEditingValue, setCurrentlyEditingValue] = useState<string>(formatValue(initialValue));

    // Track typing state so we know if we should format the current value for the controlled input
    const [isTyping, setIsTyping] = useState<boolean>(false);

    // React to updates to value so we can trigger callback. Don't do this if use is actively typing (editing)
    useEffect(() => {
        if (!onValueUpdated || isTyping) {
            return;
        }

        onValueUpdated(value);
    }, [value, onValueUpdated, isTyping]);

    const onIncrease = () => {
        const newValue = value + INCREMENT_STEP;

        setValue(newValue);
        setFormattedValue(formatValue(newValue));
    };

    const onDecrease = () => {
        // Be sure they can't decrease the value below the minimum
        const newValue = value <= minimumValue + INCREMENT_STEP ? minimumValue : value - INCREMENT_STEP;

        setValue(newValue);
        setFormattedValue(formatValue(newValue));
    };

    /**
     * Perform validation and formatting of entered value.
     *
     * @param value
     */
    const updateFromStringValue = (enteredValue: string | number) => {
        if (typeof enteredValue === 'number') {
            enteredValue = enteredValue.toString();
        }

        // Determine dollars and cents from entered value using regex
        const [, dollars, cents] = enteredValue.match(/([0-9]+)\.?([0-9]{1,2})?/) || [null, null, null];

        // Default to the current value if regex fails. Otherwise, build the number of cents to be processed.
        const parsedValue = !dollars && !cents ? value : Number(`${dollars}.${cents || 0}`) * CENTS_MULTIPLIER;

        // Validate that value is not below the minimum. If it is, pin to minimum value
        const newValue = parsedValue < minimumValue ? minimumValue : parsedValue;

        setValue(newValue);
        setFormattedValue(formatValue(newValue));
    };

    const onFocus = () => {
        setIsTyping(true);

        // Since user has focused the field, we want to sync the internal state value (for the controlled input)
        setCurrentlyEditingValue(formattedValue);
    };

    /**
     * Blur of field indicates that user is done editing and it's time for us to validate/format their entered value
     */
    const onBlur = () => {
        setIsTyping(false);

        updateFromStringValue(currentlyEditingValue);
    };

    return {
        /**
         * If use is actively typing in the input field, want to return the value they have entered unformatted.
         * Formatting will occur upon onBlur.
         */
        formattedValue: isTyping ? currentlyEditingValue.toString() : formattedValue,

        onIncrease,
        onDecrease,
        onChange: (e) => {
            // Input is controlled and we wish to update our internal state if we have identified user is typing
            if (isTyping) {
                setCurrentlyEditingValue(e.target.value);
                return;
            }
        },
        onFocus,
        onBlur
    };
};
