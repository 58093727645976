import React from 'react';

import { Dialog, DialogTitle } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { LinkForm } from 'components/engine/Components/Link/Form';

import { IconCloseButton } from './LinkModal.styles';

interface LinkModalProps {
    open: boolean;
    onClose: () => void;
}

export const LinkModal: React.FC<LinkModalProps> = ({ open, onClose }) => {
    return (
        <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="sm">
            <DialogTitle>
                Add Link
                <IconCloseButton onClick={onClose}>
                    <CloseIcon />
                </IconCloseButton>
            </DialogTitle>
            <LinkForm onClose={onClose} />
        </Dialog>
    );
};
