import React, { useMemo } from 'react';

import { OwnerType } from 'components/ContentContext/Interfaces';
import AppLayout from 'layouts/AppLayout';
import { getEditedCollectionsFromSessionStorage } from 'utils/collection';

import RootProfileCollection from '..';
import { buildGetInternalProfileBySlugQueryKey } from '../context/getInternalTenantProfileBySlug';
import { RootCollectionProvider } from '../context/RootCollectionContext';
import { TenantProfileDetails } from '../context/useGetTenantProfile';
import { useGetTenantProfileBySlug } from '../context/useGetTenantProfileBySlug';

export const OrgOutsider: React.FC<{ orgSlug: string }> = ({ orgSlug }) => {
    const usePrimary = useMemo(() => {
        if (!orgSlug) return false;
        const editedCollections = getEditedCollectionsFromSessionStorage();
        return editedCollections.includes(`${orgSlug}`);
    }, [orgSlug]);

    const { data, isLoading } = useGetTenantProfileBySlug({ slug: orgSlug }, { enabled: !!orgSlug }, usePrimary);

    const { refetch } = useGetTenantProfileBySlug({ slug: orgSlug }, { enabled: false }, true);

    const formattedData = useMemo(() => {
        if (!data) return data;

        const { rootCollection: relevantCollection, ...rest } = data.getTenantProfileBySlug;
        const formatted: TenantProfileDetails = {
            relevantCollection,
            ...rest
        };
        return formatted;
    }, [data]);

    return (
        <RootCollectionProvider
            isInternal={false}
            ownerDetails={{ slug: orgSlug, type: OwnerType.Organization }}
            tenantDetailsIsLoading={isLoading}
            tenantProfileDetails={formattedData}
            refetch={refetch}
            queryKey={buildGetInternalProfileBySlugQueryKey(orgSlug)}
        >
            <AppLayout withoutPadding fullHeight mainContentOverflow>
                <RootProfileCollection />
            </AppLayout>
        </RootCollectionProvider>
    );
};
