import React from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { ListItem } from '@material-ui/core';
import { Icon } from '@mdi/react';
import styled from 'styled-components';

const MenuItem = styled(ListItem)`
    padding: 12px 16px;
    color: ${(props) => props.theme.colors.primary};

    span {
        flex-grow: 1;
    }
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-right: 1rem;

    svg {
        width: ${IconSize.Medium};
    }
`;

interface MenuItemProps {
    title: string;
    icon: string;
    onClick?: () => void;
    onMouseEnter?: (e: React.MouseEvent<HTMLElement>) => void;
    onMouseLeave?: () => void;
}

export const ActionMenuItem: React.FC<MenuItemProps> = ({
    title,
    icon,
    onClick = () => {},
    onMouseEnter,
    onMouseLeave,
    children
}) => {
    if (children) {
        return (
            <MenuItem
                button
                aria-label={title}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                <IconWrapper>
                    <Icon path={icon} />
                </IconWrapper>
                {children}
            </MenuItem>
        );
    }

    return (
        <MenuItem button aria-label={title} onClick={onClick}>
            <IconWrapper>
                <Icon path={icon} />
            </IconWrapper>
            {title}
        </MenuItem>
    );
};
