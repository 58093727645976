import React from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import Fab from '@material-ui/core/Fab';
import { mdiShieldLock } from '@mdi/js';
import Icon from '@mdi/react';
import styled from 'styled-components';

const ButtonContainer = styled.div`
    position: fixed;
    transform: rotate(-90deg) translate(0, -100%);
    transform-origin: 100% 0;
    top: 25%;
    right: 0px;
    z-index: 1201;

    & button {
        border-radius: 24px 24px 0 0;
    }
`;

const StyledIcon = styled(Icon)`
    margin-right: 3px;
`;

interface AuthExplorerButtonProps {
    onClick: () => void;
}

export const AuthExplorerButton: React.FC<AuthExplorerButtonProps> = ({ onClick }) => (
    <ButtonContainer>
        <Fab variant="extended" color="secondary" aria-label="auth explorer" onClick={onClick}>
            <StyledIcon path={mdiShieldLock} size={IconSize.Small} /> Auth
        </Fab>
    </ButtonContainer>
);
