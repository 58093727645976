import React from 'react';

import { sortComponentsByOrder } from '@adept-at/lib-react-components';

import { ComponentContainer } from '../builder/Builder.styles';
import { useComponentEngine } from '../engine/';
import { AddComponent } from '../engine/AddComponent';

import Component from './Component';

const AssessmentComponents: React.FC = () => {
    const { components } = useComponentEngine();

    // @TODO - section logic will live here I imagine. Then how do we push into engine
    // Perhaps section support will come at the hand of skill, which will have a special renderer?

    return (
        <>
            <AddComponent fromComponentId={null} />
            {sortComponentsByOrder(components).map(({ id, type, order, ...values }) => (
                <React.Fragment key={id}>
                    {/* {type === 'sectionTitle' ? <Spacer /> : null} */}
                    <ComponentContainer>
                        <Component id={id} type={type} order={order} values={values} />
                    </ComponentContainer>
                    <AddComponent fromComponentId={id} />
                </React.Fragment>
            ))}
        </>
    );
};

export default AssessmentComponents;
