import { useContext, useMemo } from 'react';

import { OwnerInfo } from 'components/ContentContext/Interfaces';
import { useAvatars } from 'context/AvatarsContext/useAvatars';
import { ProfileContext, ProfileType } from 'context/ProfileContext';

export const useSkillOwner = (ownerInfo?: OwnerInfo): { name?: string; avatarSrc?: string } => {
    const { availableProfiles } = useContext(ProfileContext);

    const { name, imageId } = useMemo(() => {
        const owner = availableProfiles.find((profile) => profile.id === ownerInfo?.id);
        return {
            imageId: owner?.imageId,
            name: owner?.type === ProfileType.Personal ? owner?.alias : owner?.label
        };
    }, [availableProfiles, ownerInfo?.id]);

    const assetIds = useMemo(() => (imageId ? [imageId] : []), [imageId]);
    const { determineAvatarSourceById } = useAvatars({ assetIds });
    const avatarSrc = useMemo(
        () => (imageId ? determineAvatarSourceById(imageId) : undefined),
        [imageId, determineAvatarSourceById]
    );

    return { name, avatarSrc };
};
