import React, { useContext } from 'react';

import { QuestionComponentValues } from '@adept-at/lib-react-components';
import { EngineContext } from 'components/engine/EngineContext';
import { ComponentEngineMode } from 'components/engine/types';

import QuestionAssessment from './QuestionAssessment';
import QuestionConsume from './QuestionConsume';
import QuestionPreview from './QuestionPreview';

export interface SkillQuestionProps {
    component: QuestionComponentValues;
}

const SkillQuestion: React.FC<SkillQuestionProps> = ({ component }) => {
    const { mode } = useContext(EngineContext);

    if (mode === ComponentEngineMode.Edit) {
        return <QuestionPreview component={component} />;
    }

    if (mode === ComponentEngineMode.Assessment) {
        return <QuestionAssessment component={component} />;
    }

    return <QuestionConsume component={component} />;
};

export default SkillQuestion;
