import { IconSize, defaultMuiTheme } from '@adept-at/lib-react-components';
import { List, ListItem, Backdrop } from '@material-ui/core';
import Icon from '@mdi/react';
import styled, { css } from 'styled-components';
import { NAV_FULL_WIDTH, NAV_THIN_WIDTH, APP_HEADER_HEIGHT, NAV_WIDE_WIDTH } from 'styles/variables';

import { modularScale } from '../../utils/modular_scale';

export const Sidebar = styled.div<{ $isOpen: boolean; $backgroundColor?: string | null }>`
    display: none;
    flex: 0 0 ${(props) => (props.$isOpen ? NAV_FULL_WIDTH : NAV_THIN_WIDTH)}px;
    position: relative;
    flex-direction: row;
    background-color: ${(props) => (props?.$backgroundColor ? props.$backgroundColor : props.theme.colors.surface)};
    color: ${(props) => props.theme.colors.white};
    width: ${(props) => (props.$isOpen ? NAV_FULL_WIDTH : NAV_THIN_WIDTH)}px;
    z-index: 100;
    padding: 0;
    transition: all 300ms cubic-bezier(0.2, 0, 0, 1);
    overflow-x: hidden;

    ${defaultMuiTheme.breakpoints.up('sm')} {
        display: flex;
    }
`;

export const StyledBackdrop = styled(Backdrop)<{ $overlay: boolean }>`
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.2);

    ${(props) =>
        props.$overlay
            ? `
        position: absolute;
        top: ${APP_HEADER_HEIGHT + 1}px;
        left: ${NAV_THIN_WIDTH}px;
        z-index: 4;
        width: calc(100% - ${NAV_THIN_WIDTH}px);
        `
            : `
        display: none;
        z-index: unset;
    `}
`;

export const Navbar = styled.div<{ $isOpen?: boolean }>`
    display: flex;
    position: relative;
    color: ${(props) => props.theme.colors.white};
    width: ${(props) => (props.$isOpen ? NAV_FULL_WIDTH : 0)}px;
    transition: 0.2s;

    ${defaultMuiTheme.breakpoints.down('sm')} {
        width: 0px;
    }
`;

export const ThinSidebar = styled.div<{ $isOpen?: boolean; $backgroundColor?: string | null }>`
    padding-top: 9px;
    display: flex;
    width: ${(props) => (props.$isOpen ? NAV_THIN_WIDTH : 0)}px;
    flex: 0 0 ${(props) => (props.$isOpen ? NAV_THIN_WIDTH : 0)}px;
    overflow: ${(props) => (props.$isOpen ? 'visible' : 'hidden')};
    flex-direction: column;
    align-items: center;
    border-right: 1px solid rgba(255, 255, 255, 0.25);
    z-index: 110;
    background-color: ${(props) => props.theme.colors.bottomSurface};
`;

const sidebarOverlayStyles = css`
    display: flex;
    position: absolute;
    left: ${NAV_THIN_WIDTH}px;
    top: 0;
    z-index: 100;
    height: 100vh;
    height: -webkit-fill-available;

    clip-path: inset(0 -45px 0 0);
    box-shadow: 0 0 45px -2px rgba(0, 0, 0, 0.55);
`;

export const WideSidebar = styled.div<{ $isOpen: boolean; $overlay: boolean; $backgroundColor?: string | null }>`
    display: flex;
    flex: 0 0 ${(props) => (props.$isOpen ? NAV_WIDE_WIDTH : 0)}px;
    width: ${(props) => (props.$isOpen ? NAV_WIDE_WIDTH : 0)}px;
    flex-direction: column;
    justify-content: flex-start;
    background-color: ${(props) => props.theme.colors.surface};
    color: ${(props) => props.theme.colors.text};
    overflow: hidden;
    border-right: 1px solid ${(props) => props.theme.colors.border};
    border-left: 1px solid ${(props) => props.theme.colors.border};
    z-index: 10;

    button {
        ${(props) => (props.$isOpen ? null : 'display: none;')}
    }

    transition: all 300ms cubic-bezier(0.2, 0, 0, 1);

    ${defaultMuiTheme.breakpoints.up('sm')} {
        ${(props) => (props.$overlay ? sidebarOverlayStyles : null)};
    }

    ${defaultMuiTheme.breakpoints.down('md')} {
        display: ${(props) => (props.$isOpen ? 'flex' : 'none')};
    }
`;

export const WideDivider = styled.hr`
    display: flex;
    border: 0;
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
    opacity: 0.8;
    margin: 8px 15px;
`;

export const StyledListItem = styled(ListItem)<{ depth? }>`
    height: 40px;
    padding: 8px;
    opacity: ${(props) => (props.selected ? 1 : 0.75)};
    border-radius: 5px;
    background-color: ${(props) => (props.selected ? props.theme.colors.navItemHighlight : 'none')};
    margin-bottom: 8px;

    &:hover {
        opacity: 1;
        background-color: ${(props) => props.theme.colors.navItemHighlight};
    }

    a {
        display: flex;
        align-items: center;
    }
    span {
        margin-left: 12px;
    }
`;

export const NavItemIcon = styled(Icon)`
    flex: 0 0 24px;
    width: ${IconSize.Medium};
    margin-right: 12px;
`;

export const NavIconWrapper = styled.div<{ depth? }>`
    margin-right: 1rem;
    margin-left: ${(props) => (props.depth ? props.depth * 1 : 0)}rem;
    width: 24px;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.white};

    svg {
        width: ${IconSize.Medium};
        height: ${IconSize.Medium};
    }
`;

export const Divider = styled.hr`
    margin: 7px 0;
    display: block;
    border-top: 1px solid ${(props) => props.theme.colors.border};
    opacity: 0.5;
    width: 34px;
`;

export const LinkName = styled.div`
    font-size: ${modularScale(0)};
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

interface NavTabProps {
    isActive?: boolean;
}

const SharedNavTabStyles = css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
`;

export const NavTabItem = styled.div`
    ${SharedNavTabStyles}
    background-color: 26${(props) => props.theme.colors.surface};
    border-radius: 50%;
    width: 36px;
    height: 36px;
    z-index: 2;

    svg {
        width: 26px; // This is the only case Nikita mentioned that would be a different icon size (for now), so we won't use IconSize enum here until redesign happens (this is as of 5/11/21)
        color: ${(props) => props.theme.colors.textPrimary};
    }
`;

export const ActiveTabWrapper = styled.div<NavTabProps>`
    position: absolute;
    width: ${(props) => (props.isActive ? 57 : 0)}px;
    height: 44px;
    background: ${(props) => props.theme.colors.surface};
    border-radius: 20px 0 0 20px;
    left: -4px;
    border: ${(props) => (props.isActive ? `1px solid ${props.theme.colors.border}` : 'none')};
    border-right: none;
    transition: 0.3s;
`;

export const NavTabWrapper = styled.div<NavTabProps>`
    ${SharedNavTabStyles}
    box-shadow: ${(props) => (props.isActive ? `0 0 0 2px ${props.theme.colors.white}` : 'none')};
    background-color: ${(props) => props.theme.colors.surface};
    border: ${(props) => (props.isActive ? 'none' : `1px solid ${props.theme.colors.border}`)};
    border-radius: 20px;
    margin: 5px 0;
    width: 38px;
    height: 38px;
    position: relative;

    div {
        opacity: ${(props) => (props.isActive ? 1 : 0.75)};

        &:hover {
            opacity: 1;
        }
    }
`;

export const NavHeader = styled.div`
    position: relative;
    height: ${APP_HEADER_HEIGHT}px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    margin-bottom: 8px;
`;

export const NavTitle = styled.h1<{ onClick? }>`
    font-size: ${(props) => props.theme.fonts.textSizeStandard};
    font-weight: 500;
    margin-right: 0.5rem;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
`;

export const NavLinkList = styled(List)`
    width: 100%;
    padding: 12px 12px 2px 12px;

    .Mui-selected,
    .Mui-selected:hover {
        background-color: ${(props) => props.theme.colors.navItemHighlight} !important;
    }

    .MuiListItem-button:hover {
        background-color: ${(props) => props.theme.colors.navItemHighlight};
    }
`;
