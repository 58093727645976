import React from 'react';

import { mdiCheckCircle, mdiClockTimeEightOutline, mdiCloseCircle } from '@mdi/js';
import Icon from '@mdi/react';

import { EncodeStatus } from '../types';

import { Status, StatusMessage, Green, Yellow, Red } from './EncodeStatusDisplay.styles';

interface EncodeStatusProps {
    status: EncodeStatus;
}

export const EncodeStatusDisplay: React.FC<EncodeStatusProps> = ({ status }) => {
    if (status === EncodeStatus.None) {
        return (
            <Status>
                <StatusMessage>No encodings</StatusMessage>
            </Status>
        );
    }

    if (status === EncodeStatus.Error) {
        return (
            <Status>
                <Red>
                    Encoding error <Icon path={mdiCloseCircle} />
                </Red>
            </Status>
        );
    }

    if (status === EncodeStatus.Complete) {
        return (
            <Status>
                <Green>
                    Encoding complete <Icon path={mdiCheckCircle} />
                </Green>
            </Status>
        );
    }

    if (status === EncodeStatus.InProgress) {
        return (
            <Status>
                <Yellow>
                    Encoding in progress <Icon path={mdiClockTimeEightOutline} />
                </Yellow>
            </Status>
        );
    }

    if (status === EncodeStatus.NotStarted) {
        return (
            <Status>
                <Yellow>
                    Initializing encoding <Icon path={mdiClockTimeEightOutline} />
                </Yellow>
            </Status>
        );
    }

    return (
        <Status>
            <StatusMessage>Unknown encode status</StatusMessage>
        </Status>
    );
};
