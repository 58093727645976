import React from 'react';

const PublicRoom: React.FC = () => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 24 24"
            xmlSpace="preserve"
            fill="currentColor"
        >
            <title>Public room</title>
            <path
                d="M19,24c-2.8,0-5-2.2-5-5s2.2-5,5-5s5,2.2,5,5S21.8,24,19,24z M18.2,21.1c0.2,0.5,0.4,1.1,0.8,1.6
	c0.3-0.5,0.6-1,0.8-1.6H18.2z M21,21.1c-0.1,0.5-0.3,1-0.5,1.4c0.7-0.3,1.2-0.8,1.6-1.4H21z M15.8,21.1c0.4,0.6,1,1.1,1.6,1.4
	c-0.2-0.4-0.4-0.9-0.5-1.4H15.8z M22.7,19.9c0.1-0.3,0.1-0.6,0.1-0.9s0-0.6-0.1-0.9h-1.4c0,0.3,0.1,0.6,0.1,0.9s0,0.6-0.1,0.9H22.7z
	 M20,19.9c0-0.3,0.1-0.6,0.1-0.9s0-0.6-0.1-0.9h-2c0,0.3-0.1,0.6-0.1,0.9s0,0.6,0.1,0.9H20z M16.7,19.9c0-0.3-0.1-0.6-0.1-0.9
	s0-0.6,0.1-0.9h-1.4c-0.1,0.3-0.1,0.6-0.1,0.9s0,0.6,0.1,0.9H16.7z M22.2,16.9c-0.4-0.6-1-1.1-1.6-1.4c0.2,0.4,0.4,0.9,0.5,1.4H22.2
	z M19.8,16.9c-0.2-0.5-0.4-1.1-0.8-1.6c-0.3,0.5-0.6,1-0.8,1.6H19.8z M17,16.9c0.1-0.5,0.3-1,0.5-1.4c-0.7,0.3-1.2,0.8-1.6,1.4H17z
	 M6,13.3l3-1.2V7.4L6,8.6V13.3z M12.6,19.8L12,20l-6-2.4l6-2.4l1.4,0.5c0.4-0.6,0.8-1.2,1.3-1.6L13,13.5V4.4l7,2.8v5.4
	c0.7,0.1,1.4,0.3,2,0.7V6.5c0-0.4-0.2-0.8-0.6-0.9l-8.6-3.5c-0.5-0.2-1-0.2-1.5,0L2.6,5.6C2.2,5.7,2,6.1,2,6.5v11
	c0,0.4,0.2,0.8,0.6,0.9l8.6,3.5c0.5,0.2,1,0.2,1.5,0l0.4-0.1C12.8,21.1,12.6,20.5,12.6,19.8z M4,7.2l7-2.8v9.1l-7,2.8V7.2z M15,13.9
	c0.9-0.7,1.9-1.1,3-1.3v-4l-3-1.2V13.9z"
            />
        </svg>
    );
};

export const PublicRoomPath =
    'M19,24c-2.8,0-5-2.2-5-5s2.2-5,5-5s5,2.2,5,5S21.8,24,19,24z M18.2,21.1c0.2,0.5,0.4,1.1,0.8,1.6c0.3-0.5,0.6-1,0.8-1.6H18.2z M21,21.1c-0.1,0.5-0.3,1-0.5,1.4c0.7-0.3,1.2-0.8,1.6-1.4H21z M15.8,21.1c0.4,0.6,1,1.1,1.6,1.4c-0.2-0.4-0.4-0.9-0.5-1.4H15.8z M22.7,19.9c0.1-0.3,0.1-0.6,0.1-0.9s0-0.6-0.1-0.9h-1.4c0,0.3,0.1,0.6,0.1,0.9s0,0.6-0.1,0.9H22.7z M20,19.9c0-0.3,0.1-0.6,0.1-0.9s0-0.6-0.1-0.9h-2c0,0.3-0.1,0.6-0.1,0.9s0,0.6,0.1,0.9H20z M16.7,19.9c0-0.3-0.1-0.6-0.1-0.9s0-0.6,0.1-0.9h-1.4c-0.1,0.3-0.1,0.6-0.1,0.9s0,0.6,0.1,0.9H16.7z M22.2,16.9c-0.4-0.6-1-1.1-1.6-1.4c0.2,0.4,0.4,0.9,0.5,1.4H22.2z M19.8,16.9c-0.2-0.5-0.4-1.1-0.8-1.6c-0.3,0.5-0.6,1-0.8,1.6H19.8z M17,16.9c0.1-0.5,0.3-1,0.5-1.4c-0.7,0.3-1.2,0.8-1.6,1.4H17z M6,13.3l3-1.2V7.4L6,8.6V13.3z M12.6,19.8L12,20l-6-2.4l6-2.4l1.4,0.5c0.4-0.6,0.8-1.2,1.3-1.6L13,13.5V4.4l7,2.8v5.4c0.7,0.1,1.4,0.3,2,0.7V6.5c0-0.4-0.2-0.8-0.6-0.9l-8.6-3.5c-0.5-0.2-1-0.2-1.5,0L2.6,5.6C2.2,5.7,2,6.1,2,6.5v11c0,0.4,0.2,0.8,0.6,0.9l8.6,3.5c0.5,0.2,1,0.2,1.5,0l0.4-0.1C12.8,21.1,12.6,20.5,12.6,19.8z M4,7.2l7-2.8v9.1l-7,2.8V7.2z M15,13.9c0.9-0.7,1.9-1.1,3-1.3v-4l-3-1.2V13.9z';

export default PublicRoom;
