import React, { createContext, useEffect, useMemo, useState } from 'react';

import { WorkflowStatus } from 'components/CollaborationTable';
import { EntityType } from 'components/ContentContext/Enums';
import {
    Author,
    CollectionAuthor,
    Images,
    DefaultCollectionImageType,
    OwnerType,
    OwnerInfo
} from 'components/ContentContext/Interfaces';
import { CollectionType } from 'components/modals/Skills/useGetCollectionsForTenantProfile';
import {
    useGetInternalTenantProfileForOwner,
    buildGetInternalProfileForOwnerQueryKey,
    GetInternalTenantProfileForOwnerQueryResponse
} from 'components/RootProfileCollection/context/getInternalTenantProfile';
import useGetAvailableExistingCollections from 'components/RootProfileCollection/context/useGetAvailableExistingCollections';
import {
    CollectionChild,
    useGetTenantProfileForOwner,
    GetTenantProfileForOwnerResponse,
    StatDescendent
} from 'components/RootProfileCollection/context/useGetTenantProfileForOwner';
import {
    GetEditableCollectionResponse,
    useGetEditableCollection
} from 'components/RootProfileCollection/useGetEditableCollection';
import { ClientError } from 'graphql-request';
import { useAuthorizedEntityActions, CollectionActions } from 'hooks/authorization';
import { useGetCollAuthorsDetails } from 'hooks/useGetCollAuthorsDetails';
import { UserDetails } from 'hooks/useUserDetails';
import { QueryKey, QueryObserverResult, QueryStatus, RefetchOptions } from 'react-query';
import { getEditedCollectionsFromSessionStorage } from 'utils/collection';
import { formatDateStringToLocaleString } from 'utils/date';

import { AboutInfo, ContentLayoutView } from '../../RootProfileCollection/constants';
import { CollectionBasicDetail } from '../../RootProfileCollection/useGetNestedCollections/getCollectionBasicDetails';
import { useGetParentCollectionsForItem } from '../hooks/useGetParentCollections';
import { useGetEntitiesBasicDetails } from '../useGetEntitiesBasicDetails';
import {
    BasicDetailsSkillResponse,
    buildGetEntitiesBasicDetailsQueryKey
} from '../useGetEntitiesBasicDetails/getEntitiesBasicDetails';

import {
    useGetNestedCollectionBySlug,
    buildGetNestedCollectionBySlugQueryKey,
    EntityBySlug
} from './getNestedCollectionBySlug';

export interface AuthorWithDetails extends UserDetails {
    authorTypes?: Author[];
    aboutInfo?: string; // Author about info not supported in content API yet
}

interface NestedCollectionProviderInterface {
    tenantProfileData: GetTenantProfileForOwnerResponse | undefined;
    getCollectionIsLoading: boolean;
    collectionId: string;
    immutableCollectionId?: string;
    refetchParentCollection: () => void;
    tenantSlug: string;
    parentQuerySlug: string;
    collectionSlug: string;
    collectionSlugs: string[];
    aboutInfo: AboutInfo | null;
    ownerInfo: OwnerInfo | null;
    authorizedActions: CollectionActions[];
    isEditMode: boolean;
    setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
    isPreviewMode: boolean;
    setIsPreviewMode: React.Dispatch<React.SetStateAction<boolean>>;
    stats: StatDescendent[] | null;
    images: Images | null;
    defaultCatalogImage: DefaultCollectionImageType;
    defaultFeaturedImage: DefaultCollectionImageType;
    estimatedSecondsToConsume: number;
    childrenBasicDetails: (BasicDetailsSkillResponse | CollectionBasicDetail | undefined)[];
    getChildrenBasicDetailsStatus: QueryStatus;
    authorsDetails: AuthorWithDetails[];
    search: string;
    setSearch: React.Dispatch<React.SetStateAction<string>>;
    layoutView: ContentLayoutView;
    setLayoutView: React.Dispatch<React.SetStateAction<ContentLayoutView>>;
    existingCollectionsOptions: {
        label: string;
        id: string;
    }[];
    getExistingCollectionsIsLoading: boolean;
    createdAt: string;
    basicDetailsQueryKey: QueryKey;
    parentQueryKey: QueryKey;
    isVisible: boolean;
    appearsInParentCollections: CollectionBasicDetail[];
    internalTenantProfileData: GetInternalTenantProfileForOwnerQueryResponse | undefined;
    pendingChanges: boolean;
    setPendingChanges: React.Dispatch<React.SetStateAction<boolean>>;
    collectionIdForEditableCollectionQuery: string;
    expectedInfoVersionForEdits: number | undefined;
    expectedItemsVersionForEdits: number | undefined;
    refetchEditableCollection: (
        options?: RefetchOptions | undefined
    ) => Promise<QueryObserverResult<GetEditableCollectionResponse, ClientError>>;
    productId?: string | null;
    collectionData?: EntityBySlug;
    workflow?: WorkflowStatus;
    rootType?: CollectionType;
    isValidCollectionSlug?: boolean;
}

const NestedCollectionContext = createContext({} as NestedCollectionProviderInterface);

const { Provider, Consumer } = NestedCollectionContext;

interface NestedCollectionProviderProps {
    tenantSlug: string;
    collectionSlugs: string;
}
//this needs to be refactored in a similar fasion to rootcollectionProvider
//collection visitors from the marketplace will not have the ownerId and root profile type and need to just provide the collection data initially to this
//additionally we need to kick editing out into its own provider since the vast majority of collection consumers will not be doing any editing
const NestedCollectionProvider: React.FC<NestedCollectionProviderProps> = ({
    children,
    tenantSlug,
    collectionSlugs
}) => {
    const [authorizedActions, setAuthorizedActions] = useState<CollectionActions[]>([]);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isPreviewMode, setIsPreviewMode] = useState(false);
    const [search, setSearch] = useState('');
    const [layoutView, setLayoutView] = useState<ContentLayoutView>(ContentLayoutView.Grid);
    const [pendingChanges, setPendingChanges] = useState(false);

    // ensure we are not in editMode and reset pendingChanges to false if user switches to different collection
    useEffect(() => {
        if (collectionSlugs) {
            setIsEditMode(false);
            setPendingChanges(false);
        }
    }, [collectionSlugs]);

    const collectionSlugsArray = useMemo(() => collectionSlugs.split('/'), [collectionSlugs]);
    const currentCollectionSlug = useMemo(
        () => collectionSlugsArray[collectionSlugsArray.length - 1],
        [collectionSlugsArray]
    );

    const parentQuerySlug = useMemo(
        () => `${tenantSlug}/${currentCollectionSlug}`,
        [tenantSlug, currentCollectionSlug]
    );

    const parentQueryKey = useMemo(() => buildGetNestedCollectionBySlugQueryKey(parentQuerySlug), [parentQuerySlug]);

    const { usePrimaryForNestedCollectionQuery } = useMemo(() => {
        const editedCollections = getEditedCollectionsFromSessionStorage();
        return {
            usePrimaryForNestedCollectionQuery: editedCollections.includes(parentQuerySlug)
        };
    }, [parentQuerySlug]);

    const { data: collectionData, isLoading: getCollectionIsLoading } = useGetNestedCollectionBySlug(
        parentQuerySlug,
        usePrimaryForNestedCollectionQuery
    );
    const { refetch: refetchParentCollection } = useGetNestedCollectionBySlug(parentQuerySlug, true, false);

    const isValidCollectionSlug = useMemo(() => {
        if (
            !getCollectionIsLoading &&
            (!collectionData || Object.keys(collectionData?.getEntityBySlug)?.length === 0)
        ) {
            return false;
        }
        return true;
    }, [collectionData, getCollectionIsLoading]);

    const collectionIdForEditableCollectionQuery = useMemo(() => {
        if (!collectionData) return null;

        return collectionData?.getEntityBySlug.collectionId ?? null;
    }, [collectionData]);

    const usePrimaryForEditableCollectionQuery = useMemo(() => {
        const editedCollections = getEditedCollectionsFromSessionStorage();
        return (
            !!collectionIdForEditableCollectionQuery &&
            editedCollections.includes(collectionIdForEditableCollectionQuery)
        );
    }, [collectionIdForEditableCollectionQuery]);

    const { data: editableCollection } = useGetEditableCollection(
        { collectionId: collectionIdForEditableCollectionQuery ?? '' },
        usePrimaryForEditableCollectionQuery,
        !!collectionIdForEditableCollectionQuery
    );

    const { refetch: refetchEditableCollection } = useGetEditableCollection(
        { collectionId: collectionIdForEditableCollectionQuery ?? '' },
        true,
        false
    );

    const data = useMemo(() => {
        if (!isEditMode) return collectionData?.getEntityBySlug;

        return editableCollection?.getEditableCollection;
    }, [isEditMode, collectionData?.getEntityBySlug, editableCollection?.getEditableCollection]);

    useEffect(() => {
        if (!editableCollection?.getEditableCollection) return;

        const { infoVersion: editableInfoVersion, itemsVersion: editableItemsVersion } =
            editableCollection.getEditableCollection;

        if (collectionData?.getEntityBySlug) {
            const { infoVersion: collectionInfoVersion, itemsVersion: collectionItemsVersion } =
                collectionData?.getEntityBySlug;

            if (editableInfoVersion !== collectionInfoVersion || editableItemsVersion !== collectionItemsVersion) {
                setPendingChanges(true);
            }
        }
    }, [editableCollection?.getEditableCollection, collectionData?.getEntityBySlug]);

    const createdAt = useMemo(() => {
        const date = data?.createdAt;
        const userLocal = navigator.languages[0];

        if (!date) return '';

        return formatDateStringToLocaleString(date, userLocal);
    }, [data?.createdAt]);

    const isVisible = useMemo(() => data?.visible ?? false, [data?.visible]);

    const workflow = useMemo(() => {
        return collectionData?.getEntityBySlug.workflow
            ? WorkflowStatus[collectionData?.getEntityBySlug.workflow]
            : WorkflowStatus.NEEDS_CONTENT;
    }, [collectionData]);

    const aboutInfo: AboutInfo | null = useMemo(() => {
        if (!data) return null;

        return {
            title: data.title,
            titleSub: data.titleSub ?? null,
            description: data.description ?? null,
            tags: data.tags ?? null,
            publicLink: null // null until we figure out where this is coming from
        };
    }, [data]);

    const stats = useMemo(() => data?.stats?.descendants ?? null, [data?.stats?.descendants]);

    const estimatedSecondsToConsume = useMemo(
        () => data?.stats?.estimatedSecondsToConsume ?? 0,
        [data?.stats?.estimatedSecondsToConsume]
    );

    const images = useMemo(() => data?.images ?? null, [data]);

    const defaultCatalogImage = useMemo(() => data?.defCat ?? 'gray', [data]);
    const defaultFeaturedImage = useMemo(() => data?.defFeat ?? 'gray', [data]);

    const collectionId = useMemo(() => data?.collectionId ?? '', [data?.collectionId]);

    const ownerInfo = useMemo(() => data?.owner ?? null, [data?.owner]);

    const getEntitiesBasicInputVariables = useMemo(
        () =>
            data?.children?.filter(
                (child) => child.type === CollectionChild.Skill || child.type === CollectionChild.Collection
            ) ?? [],
        [data]
    );

    const basicDetailsQueryKey = useMemo(
        () => buildGetEntitiesBasicDetailsQueryKey(collectionId, currentCollectionSlug),
        [currentCollectionSlug, collectionId]
    );

    const { childrenDetails, status: getChildrenBasicDetailsStatus } = useGetEntitiesBasicDetails(
        basicDetailsQueryKey,
        getEntitiesBasicInputVariables
    );

    // Map collectionChildrenIds to child basic details to get correct order for content cards
    const orderedChildrenDetails = useMemo(() => {
        if (!data?.children) return [];

        return (
            data?.children
                .map((child) =>
                    childrenDetails.find(
                        (detail) =>
                            (detail as BasicDetailsSkillResponse).skillId === child.id ||
                            (detail as CollectionBasicDetail).collectionId === child.id
                    )
                )
                .filter(Boolean) ?? []
        );
    }, [childrenDetails, data?.children]);

    const childCollectionIds = useMemo(
        () => (childrenDetails as CollectionBasicDetail[])?.map((child) => child.collectionId).filter(Boolean) ?? [],
        [childrenDetails]
    );

    const { data: existingCollections, isLoading: getExistingCollectionsIsLoading } =
        useGetAvailableExistingCollections({ parentCollectionId: collectionId }, !!collectionId);

    const existingCollectionsOptions: {
        label: string;
        id: string;
    }[] = useMemo(() => {
        return existingCollections?.getInsertableCollections
            ? existingCollections?.getInsertableCollections
                  .filter((collection) => !childCollectionIds.includes(collection.collectionId))
                  .map((collection) => ({
                      label: collection.title,
                      id: collection.collectionId
                  }))
            : [];
    }, [existingCollections?.getInsertableCollections, childCollectionIds]);

    const filteredOrderedChildrenDetails = useMemo(
        () => (isEditMode ? orderedChildrenDetails : orderedChildrenDetails.filter((child) => child && child.visible)),
        [orderedChildrenDetails, isEditMode]
    );
    //this mess will be resolved from the refactor notes at context declaration, using selected profiles as a source of truth (for ownership) needs to go :wave:
    const { id: ownerId, type: rootProfileType } = useMemo(
        () => collectionData?.getEntityBySlug?.owner ?? { id: undefined, type: undefined },
        [collectionData?.getEntityBySlug?.owner]
    );

    const { usePrimaryForInternalProfileQuery, usePrimaryForProfileQuery } = useMemo(() => {
        const editedCollections = getEditedCollectionsFromSessionStorage();
        return {
            usePrimaryForInternalProfileQuery: editedCollections.includes(`internal-${ownerId}`),
            usePrimaryForProfileQuery: editedCollections.includes(`${ownerId}`)
        };
    }, [ownerId]);

    const { data: tenantProfileData } = useGetTenantProfileForOwner(
        //these fallbacks will not be used. see refactor notes at context declaration
        { owner: { type: rootProfileType ?? OwnerType.User, id: ownerId ?? 'unknown' } },
        { enabled: !!ownerId && !!rootProfileType },
        usePrimaryForProfileQuery
    );

    const internalRootProfileQueryKey = useMemo(
        () => buildGetInternalProfileForOwnerQueryKey(ownerId ?? 'unknown'),
        [ownerId]
    );

    const { data: internalTenantProfileData } = useGetInternalTenantProfileForOwner(
        {
            owner: { type: rootProfileType ?? OwnerType.User, id: ownerId ?? 'unknown' }
        },
        internalRootProfileQueryKey,
        usePrimaryForInternalProfileQuery,
        !!ownerId && !!rootProfileType
    );

    const { data: appearsInCollections } = useGetParentCollectionsForItem(
        {
            item: {
                type: EntityType.COLLECTION,
                id: collectionId
            }
        },
        !!collectionId
    );

    const authors = useMemo(() => {
        if (isEditMode) {
            return [] as CollectionAuthor[];
        }
        return (data?.authors ?? []) as CollectionAuthor[];
    }, [data?.authors, isEditMode]);

    const { collectionAuthorsDetails } = useGetCollAuthorsDetails(authors);

    /* Fire of an Authorization calls we need for conditional component renders. */
    const collectionEntity = useMemo(() => {
        return {
            type: EntityType.Collection,
            id: collectionIdForEditableCollectionQuery ?? null
        };
    }, [collectionIdForEditableCollectionQuery]);

    //    /* Check if <CURRENT_USER> can Edit the collection */
    const { actions, refetch: refetchAuthorizedActions } =
        useAuthorizedEntityActions<CollectionActions>(collectionEntity);

    /* If Collection ID changes, recheck authorization. */
    useEffect(() => {
        refetchAuthorizedActions();
        setAuthorizedActions(actions ? actions : []);
    }, [actions, refetchAuthorizedActions, collectionEntity]);

    return (
        <Provider
            value={{
                authorizedActions,
                tenantProfileData,
                getCollectionIsLoading,
                refetchParentCollection,
                collectionId,
                parentQuerySlug,
                tenantSlug,
                collectionSlug: currentCollectionSlug,
                collectionSlugs: collectionSlugsArray,
                ownerInfo,
                aboutInfo,
                stats,
                images,
                defaultCatalogImage,
                defaultFeaturedImage,
                createdAt, // formatted date 'month day, year'
                estimatedSecondsToConsume,
                isEditMode,
                setIsEditMode,
                isPreviewMode,
                setIsPreviewMode,
                isVisible,
                authorsDetails: collectionAuthorsDetails,

                existingCollectionsOptions,
                getExistingCollectionsIsLoading,

                childrenBasicDetails: filteredOrderedChildrenDetails,
                getChildrenBasicDetailsStatus,

                search,
                setSearch,

                layoutView,
                setLayoutView,

                basicDetailsQueryKey,
                parentQueryKey,
                appearsInParentCollections: appearsInCollections?.getParentCollectionsForItem ?? [],
                internalTenantProfileData,

                pendingChanges,
                setPendingChanges,

                collectionIdForEditableCollectionQuery: collectionIdForEditableCollectionQuery ?? '',
                expectedInfoVersionForEdits: editableCollection?.getEditableCollection?.infoVersion,
                expectedItemsVersionForEdits: editableCollection?.getEditableCollection?.itemsVersion,
                refetchEditableCollection,

                productId: collectionData?.getEntityBySlug?.productDetails?.productId,
                collectionData: collectionData?.getEntityBySlug,
                immutableCollectionId: collectionData?.getEntityBySlug.collectionId,

                workflow,
                rootType: data?.rootType,
                isValidCollectionSlug
            }}
        >
            {children}
        </Provider>
    );
};

export { NestedCollectionContext, NestedCollectionProvider, Consumer as NestedCollectionConsumer };
