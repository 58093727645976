import { useMemo } from 'react';

import { OptimistMutationContext, OptimistRegisterUpdaterOptions, useOptimist } from '@adept-at/lib-react-optimist';
import { EntityInfo, CollaboratorPermissionLevel } from 'components/ContentContext/Interfaces';
import { ClientError, gql } from 'graphql-request';
import useGqlClient from 'hooks/useGqlClient';
import buildGqlMutationFn from 'hooks/useGqlClient/helpers/buildGqlMutationFn';
import { API_CONTENT } from 'lib/ApiConstants';
import { useSnackbar } from 'notistack';
import { UseMutateFunction, useMutation } from 'react-query';

import { buildGetEntityCollaboratorsQueryKey, GetEntityCollaboratorsResponse } from './getEntityCollaborators';

const DELETE_ENTITY_COLLABORATOR = gql`
    mutation deleteEntityCollaborator(
        $entityInfo: EntityInfoInput!
        $userId: UUIDv4!
        $specificRole: CollaboratorRole
    ) {
        deleteEntityCollaborator(entityInfo: $entityInfo, userId: $userId, specificRole: $specificRole) {
            success
        }
    }
`;

interface DeleteEntityCollaboratorResult {
    deleteEntityCollaborator: boolean;
}

interface DeleteEntityCollaboratorProps {
    entityInfo: EntityInfo;
    userId: string;
    specificRole?: CollaboratorPermissionLevel;
}

const useDeleteEntityCollaborator = ({
    onFinished,
    entityInfo,
    roleFilter
}: {
    onFinished: () => void;
    entityInfo: EntityInfo;
    roleFilter: CollaboratorPermissionLevel[];
}): {
    deleteEntityCollaborator: UseMutateFunction<
        DeleteEntityCollaboratorResult,
        ClientError,
        DeleteEntityCollaboratorProps,
        OptimistMutationContext
    >;
} => {
    const { enqueueSnackbar } = useSnackbar();

    const { registerUpdater: registerDeleteEntityCollaborator, mutationOptions } = useOptimist<
        DeleteEntityCollaboratorResult,
        ClientError,
        DeleteEntityCollaboratorProps
    >({
        globalMutationOptions: {
            onError: () => {
                enqueueSnackbar('An error occurred deleting user.', { variant: 'error' });
            }
        }
    });

    const entityQueryKey = useMemo(
        () =>
            buildGetEntityCollaboratorsQueryKey({
                entity: entityInfo,
                roleFilter
            }),
        [entityInfo]
    );

    const deleteEntityCollaboratorOptions: OptimistRegisterUpdaterOptions<
        DeleteEntityCollaboratorResult,
        ClientError,
        DeleteEntityCollaboratorProps
    > = useMemo(() => {
        return {
            onSuccess: () => {
                onFinished();
                enqueueSnackbar('Successfully removed user', { variant: 'success' });
            },
            onError: () => {
                enqueueSnackbar('An error occurred deleting user.', { variant: 'error' });
            }
        };
    }, []);

    registerDeleteEntityCollaborator<GetEntityCollaboratorsResponse>(
        entityQueryKey,
        (previous, collaboratorToRemove) => {
            const result = previous.getEntityCollaborators.filter((collaborator) => {
                return collaborator.id !== collaboratorToRemove.userId;
            });

            return { getEntityCollaborators: result };
        },
        deleteEntityCollaboratorOptions
    );

    const { client, withMutationOptions } = useGqlClient(API_CONTENT);
    const { mutate: deleteEntityCollaborator } = useMutation<
        DeleteEntityCollaboratorResult,
        ClientError,
        DeleteEntityCollaboratorProps,
        OptimistMutationContext
    >(
        buildGqlMutationFn<DeleteEntityCollaboratorResult, DeleteEntityCollaboratorProps>(
            client,
            DELETE_ENTITY_COLLABORATOR
        ),
        withMutationOptions(mutationOptions)
    );

    return {
        deleteEntityCollaborator
    };
};

export default useDeleteEntityCollaborator;
