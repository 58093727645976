import React from 'react';

import { TextField } from '@material-ui/core';
import { Control } from 'components/engine/common/RichTextEditor/LinkModal.styles';

const generateUrl = (link) => {
    const urlMatch = /^((http|https|ftp):\/\/)/;
    return link.match(urlMatch) ? encodeURI(link) : encodeURI(`https://${link}`);
};

export const LinkMetaForm: React.FC<{
    onChange: <LinkComponentValues>(field: string) => (value: LinkComponentValues) => void;
    url: string;
    text: string;
}> = ({ onChange, url, text }) => {
    return (
        <>
            <Control fullWidth>
                <TextField
                    autoFocus
                    variant="outlined"
                    id="metadata-url"
                    label={`Url`}
                    name="URL"
                    type="text"
                    fullWidth
                    defaultValue={url ?? ''}
                    onChange={(e) => onChange('url')(generateUrl(e.target.value))}
                />
            </Control>
            <Control fullWidth>
                <TextField
                    variant="outlined"
                    id="metadata fallback text"
                    label={`Fallback meta link title`}
                    name="fallback text"
                    type="text"
                    fullWidth
                    defaultValue={text}
                    onChange={(e) => onChange('text')(e.target.value)}
                />
            </Control>
        </>
    );
};
