import * as yup from 'yup';

import { MessageEventType } from '..';
import AdepteductMessage from '../../base/AdepteductMessage';
import PossibleEventFields from '../PossibleEventFields';

interface UserRegistrationCompleteFields extends PossibleEventFields {
    userId: string;
}

export default class UserRegistrationComplete extends AdepteductMessage<UserRegistrationCompleteFields> {
    schema = yup.object({
        userId: yup.string()
    });

    type = MessageEventType.UserRegistrationComplete;
}
