import React, { useState, useMemo, useContext } from 'react';

import { IconSize, modularScale } from '@adept-at/lib-react-components';
import DateFnsUtils from '@date-io/date-fns';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { mdiCalendarCheck, mdiCalendarEdit, mdiCalendarMonth, mdiCalendarToday, mdiCalendarWeek } from '@mdi/js';
import Icon from '@mdi/react';
import { format, add } from 'date-fns';
import styled from 'styled-components';

import useUpsertScheduleItem from './hooks/useUpsertScheduleItem';
import { ScheduleContext } from './Schedule/ScheduleContext';
import { SCHEDULE_TIME_FORMAT } from './utils';

const IconCloseButton = styled(IconButton)`
    float: right;
`;

const TimeOption = styled.button`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
    padding: 8px;
    font-size: 100%;
    font-family: inherit;
    border: 0;
    background-color: inherit;

    svg {
        color: ${(props) => props.theme.colors.primary};
    }

    &:hover {
        cursor: pointer;
        background-color: ${(props) => props.theme.colors.actionHover};
    }
`;

const OptionsRow = styled.div`
    justify-content: space-around;
    display: flex;
    margin-bottom: 24px;
`;

const ToolbarWrapper = styled.div`
    padding: 16px 20px;
    font-size: ${modularScale(1)};
    display: flex;
    align-items: center;

    button {
        margin-left: auto;
    }
`;

interface LearnLaterModalProps {
    open: boolean;
    onClose: () => void;
}

const LearnLaterModal: React.FC<LearnLaterModalProps> = ({ open, onClose }) => {
    const { itemToSchedule } = useContext(ScheduleContext);
    const [date, changeDate] = useState<Date | null>(new Date());
    const [pickerOpen, setPickerOpen] = useState(false);
    const { mutate: upsertScheduleItem } = useUpsertScheduleItem();

    const { today, tomorrow, week, month } = useMemo(() => {
        const now = new Date();

        return {
            today: now,
            tomorrow: add(now, { days: 1 }),
            week: add(now, { days: 7 }),
            month: add(now, { months: 1 })
        };
    }, []);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (itemToSchedule) {
            upsertScheduleItem({ item: itemToSchedule, date: e.currentTarget.value });
        }
        onClose();
    };

    const handlePickerAccept = (selectedDate: Date | null) => {
        if (selectedDate && itemToSchedule) {
            upsertScheduleItem({ item: itemToSchedule, date: format(selectedDate, SCHEDULE_TIME_FORMAT) });
        }
        setPickerOpen(false);
        onClose();
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
                <DialogTitle id="LearnLaterModalTitle">
                    When would you like to learn this?
                    <IconCloseButton color="secondary" aria-label="Close modal" onClick={onClose}>
                        <CloseIcon />
                    </IconCloseButton>
                </DialogTitle>
                <DialogContent>
                    <OptionsRow>
                        <TimeOption onClick={handleClick} value={format(today, SCHEDULE_TIME_FORMAT)}>
                            <Icon path={mdiCalendarCheck} size={IconSize.Large} />
                            <p>Today</p>
                            <p>{format(today, 'MM/dd/yyyy')}</p>
                        </TimeOption>
                        <TimeOption onClick={handleClick} value={format(tomorrow, SCHEDULE_TIME_FORMAT)}>
                            <Icon path={mdiCalendarToday} size={IconSize.Large} />
                            <p>Tomorrow</p>
                            <p>{format(tomorrow, 'MM/dd/yyyy')}</p>
                        </TimeOption>
                        <TimeOption onClick={handleClick} value={format(week, SCHEDULE_TIME_FORMAT)}>
                            <Icon path={mdiCalendarWeek} size={IconSize.Large} />
                            <p>In a week</p>
                            <p>{format(week, 'MM/dd/yyyy')}</p>
                        </TimeOption>
                        <TimeOption onClick={handleClick} value={format(month, SCHEDULE_TIME_FORMAT)}>
                            <Icon path={mdiCalendarMonth} size={IconSize.Large} />
                            <p>In a month</p>
                            <p>{format(month, 'MM/dd/yyyy')}</p>
                        </TimeOption>
                    </OptionsRow>
                    <OptionsRow>
                        <TimeOption onClick={() => setPickerOpen(true)}>
                            <Icon path={mdiCalendarEdit} size={IconSize.Large} />
                            <p>Pick a day</p>
                        </TimeOption>
                        <DatePicker
                            variant="dialog"
                            disablePast
                            open={pickerOpen}
                            value={date}
                            onChange={changeDate}
                            onAccept={handlePickerAccept}
                            onClose={() => setPickerOpen(false)}
                            TextFieldComponent={() => null}
                            ToolbarComponent={() => <Toolbar onClose={() => setPickerOpen(false)} />}
                        />
                    </OptionsRow>
                </DialogContent>
            </Dialog>
        </MuiPickersUtilsProvider>
    );
};

const Toolbar = ({ onClose }) => {
    return (
        <ToolbarWrapper>
            <p>Pick a day</p>
            <IconCloseButton color="secondary" aria-label="Close date picker" onClick={onClose}>
                <CloseIcon />
            </IconCloseButton>
        </ToolbarWrapper>
    );
};

export default LearnLaterModal;
