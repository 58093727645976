import React, { createContext, useContext } from 'react';

import { ComponentValuesType, sortComponentsByOrder } from '@adept-at/lib-react-components';
import { DropResult } from 'react-beautiful-dnd';

import { COMPONENT_ORDER_PADDING, EditorContext } from './EditorContext';

interface SortableContextInterface {
    onDragEnd: (result: DropResult) => void;
    onMoveUp: (componentId: string) => void;
    onMoveDown: (componentId: string) => void;
    canComponentMoveUp: (componentId: string) => boolean;
    canComponentMoveDown: (componentId: string) => boolean;
}

const SortableContext = createContext({} as SortableContextInterface);

const { Provider, Consumer } = SortableContext;

const SortProvider: React.FC = ({ children }) => {
    const {
        workingSkill: { components },
        updateWorkingSkillComponentsOrder
    } = useContext(EditorContext);

    const onDragEnd = (result: DropResult): void => {
        const { destination, source, draggableId } = result;

        if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
            return;
        }

        if (
            (destination.index < source.index && !canComponentMoveUp(draggableId)) ||
            (destination.index > source.index && !canComponentMoveDown(draggableId))
        ) {
            throw new Error('Order not permitted');
        }

        const orderedComponents = sortComponentsByOrder(components);
        const newIds = orderedComponents.map((component) => component.id);

        newIds.splice(source.index, 1);
        newIds.splice(destination.index, 0, draggableId);

        reorganizeComponents(newIds);
    };

    const onMoveUp = (id: string): void => {
        const orderedComponents = sortComponentsByOrder(components);
        const newIds = orderedComponents.map((component) => component.id);

        const sourceComponent = orderedComponents.find((i) => i.id === id);
        if (!sourceComponent) return;

        const sourceIndex = orderedComponents.indexOf(sourceComponent);
        const destinationIndex = sourceIndex - 1;

        if (destinationIndex < 0) return;

        newIds.splice(sourceIndex, 1);
        newIds.splice(destinationIndex, 0, id);

        reorganizeComponents(newIds);
    };

    const onMoveDown = (id: string): void => {
        const orderedComponents = sortComponentsByOrder(components);
        const newIds = orderedComponents.map((component) => component.id);

        const sourceComponent = orderedComponents.find((i) => i.id === id);
        if (!sourceComponent) return;

        const sourceIndex = orderedComponents.indexOf(sourceComponent);
        const destinationIndex = sourceIndex + 1;

        if (destinationIndex > orderedComponents.length - 1) return;

        newIds.splice(sourceIndex, 1);
        newIds.splice(destinationIndex, 0, id);

        reorganizeComponents(newIds);
    };

    const reorganizeComponents = (newOrder: string[]) => {
        const newComponents = {};

        newOrder.forEach((id, i) => {
            newComponents[id] = {
                ...components[id],
                order: (i + 1) * COMPONENT_ORDER_PADDING
            };
        });

        const payload = {};

        newOrder.forEach((id) => {
            payload[id] = newComponents[id].order;
        });

        updateWorkingSkillComponentsOrder(payload);
    };

    const canComponentMoveUp = (id: string): boolean => {
        if (!components || Object.keys(components).length < 1) {
            return false;
        }

        // cannot move up if component is the first element.
        const sortedComponentsIds = sortComponentsByOrder(components).map((comp) => comp.id);
        const [firstComponentId, secondComponentId] = sortedComponentsIds;

        if (id === firstComponentId) {
            return false;
        }

        // Also cannot move up if component is the second and component title is the first
        // unless you're moving up a section title
        const firstComponent = components[firstComponentId];
        const { type } = firstComponent;
        if (type === ComponentValuesType.SectionTitle && id === secondComponentId) {
            const { type: secondComponentType } = components[secondComponentId];
            return secondComponentType === ComponentValuesType.SectionTitle;
        }

        return true;
    };

    const canComponentMoveDown = (id: string): boolean => {
        if (!components || Object.keys(components).length < 1) {
            return false;
        }

        // can move down unless it's the last component
        const sortedComponentsIds = sortComponentsByOrder(components).map((comp) => comp.id);
        const [firstComponentId, secondComponentId] = sortedComponentsIds;
        const lastComponentId = sortedComponentsIds[sortedComponentsIds.length - 1];

        if (lastComponentId === id) {
            return false;
        }

        // or you're trying to move the first and the second is a section title
        const firstComponent = components[firstComponentId];
        const { type } = firstComponent;
        if (type === ComponentValuesType.SectionTitle && id === firstComponentId) {
            const { type: secondComponentType } = components[secondComponentId];
            return secondComponentType === ComponentValuesType.SectionTitle;
        }

        return true;
    };

    return (
        <Provider
            value={{
                onDragEnd,

                onMoveDown,
                onMoveUp,
                canComponentMoveUp,
                canComponentMoveDown
            }}
        >
            {children}
        </Provider>
    );
};

export { SortableContext, SortProvider, Consumer as SidebarSortableConsumer };
