const EDITED_COLLECTIONS_KEY = 'edited-collections';

export const getEditedCollectionsFromSessionStorage = (): string[] => {
    const fromStorage = sessionStorage.getItem(EDITED_COLLECTIONS_KEY);
    const editedCollections = fromStorage ? JSON.parse(fromStorage) : [];
    return editedCollections;
};

export const setCollectionEditedInSessionStorage = (collectionId: string, collectionSlug: string): void => {
    try {
        const editedCollections = getEditedCollectionsFromSessionStorage();
        sessionStorage.setItem(
            EDITED_COLLECTIONS_KEY,
            JSON.stringify([...new Set([...editedCollections, collectionId, collectionSlug])])
        );
    } catch (err) {
        console.error(`Unable to add collection ${collectionId} to session storage "${EDITED_COLLECTIONS_KEY}"`, err);
    }
};
