import React from 'react';

import { OwnerInfo } from 'components/builder/context/interfaces';
import { EntityType } from 'components/ContentContext/Enums';

import { WorkflowStatus } from '../utils';
import { WorkflowChip } from '../WorkflowChip';

import { StyledTableCell } from './TableCell.styles';

interface WorkflowCellProps {
    id: string;
    type?: EntityType;
    owner?: OwnerInfo;
    workflow?: WorkflowStatus;
}

export const WORKFLOW_CELL_WIDTH = 330;

export const WorkflowCell: React.FC<WorkflowCellProps> = ({ id, type, owner, workflow }) => {
    return (
        <StyledTableCell width={WORKFLOW_CELL_WIDTH}>
            {owner && type ? <WorkflowChip id={id} type={type} owner={owner} workflow={workflow} /> : null}
        </StyledTableCell>
    );
};
