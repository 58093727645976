export interface CropInputs {
    imageWidthInPixels: number;
    imageHeightInPixels: number;
    aspectRatio: number;
}

export interface CropOutputs {
    heightPercent: number;
    widthPercent: number;
}

// Solution found here:
// https://stackoverflow.com/questions/8541380/max-crop-area-for-a-given-image-and-aspect-ratio
export function getMaxCropPercentage({ imageWidthInPixels, imageHeightInPixels, aspectRatio }): CropOutputs {
    let croppedHeightInPixels = 0;

    let croppedWidthInPixels = 0;

    if (aspectRatio < 1) {
        croppedWidthInPixels = Math.min(Math.ceil(imageHeightInPixels * aspectRatio), imageWidthInPixels);
        croppedHeightInPixels = croppedWidthInPixels / aspectRatio;
    } else {
        croppedHeightInPixels = Math.min(Math.ceil(imageWidthInPixels / aspectRatio), imageHeightInPixels);
        croppedWidthInPixels = Math.ceil(croppedHeightInPixels * aspectRatio);
    }

    // convert to %. Make sure we don't go over 100
    const heightPercent = Math.min(Math.floor((croppedHeightInPixels / imageHeightInPixels) * 100), 100);
    const widthPercent = Math.min(Math.floor((croppedWidthInPixels / imageWidthInPixels) * 100), 100);

    return {
        heightPercent,
        widthPercent
    };
}

export const buildResponsiveProps = (
    imageDetails?: { url: string; dimensions: { width: number } }[]
): { sizes: string; srcSet: string } | undefined => {
    if (imageDetails) {
        const sortedImageDetails = imageDetails
            .filter((detail) => detail?.dimensions?.width && detail?.url)
            .sort((a, b) => a?.dimensions?.width - b?.dimensions?.width);

        const { sizesArray, srcSetArray } = sortedImageDetails.reduce(
            (memo, detail, index) => {
                memo.sizesArray.push(
                    index === sortedImageDetails.length - 1
                        ? `${detail.dimensions.width}px`
                        : `(max-width: ${detail.dimensions.width}px) ${detail.dimensions.width}px`
                );

                memo.srcSetArray.push(`${detail.url} ${detail.dimensions.width}w`);

                return memo;
            },
            { sizesArray: [], srcSetArray: [] } as { sizesArray: string[]; srcSetArray: string[] }
        );

        return { sizes: sizesArray.join(', '), srcSet: srcSetArray.join(', ') };
    }
};
