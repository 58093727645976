import React, { useMemo } from 'react';

import useUsersDetails from 'hooks/useUserDetails';
import PossibleEventFields from 'lib/communication/message/type/PossibleEventFields';

import { placeholders } from '../../functions';
import AdepteductMessageProps from '../AdepteductMessageProps';

import StandardChatMessage from './StandardChatMessage';

const renderFromTemplate = ({ fields }, usersDetails) => {
    // @TODO -- add more fields here
    const { template = '', user, variables } = fields;

    return placeholders(template, { ...variables, user: usersDetails[user]?.alias || 'A user' });
};

interface ChannelTemplateEventFields extends PossibleEventFields {
    user?: string;
}

const ChannelTemplateMessage = React.forwardRef<
    HTMLDivElement | null,
    AdepteductMessageProps<ChannelTemplateEventFields>
>(({ destination, message }, ref) => {
    const {
        senderId,
        fields: { user: userId }
    } = message;

    const userIdsToFetch = useMemo(() => {
        return [userId as string, senderId].filter(Boolean);
    }, [userId, senderId]);
    const { usersDetails } = useUsersDetails({ userIds: userIdsToFetch });

    if (!usersDetails || Object.keys(usersDetails).length === 0) {
        return null;
    }

    return (
        <StandardChatMessage destination={destination} message={message} ref={ref}>
            {renderFromTemplate(message, usersDetails)}
        </StandardChatMessage>
    );
});

export default ChannelTemplateMessage;
