import React from 'react';

import { Chip } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { Emoji } from 'emoji-mart';
import { useCurrentUser } from 'hooks/useCurrentUser';
import useUsersDetails from 'hooks/useUserDetails';
import styled from 'styled-components';

const StyledChip = styled(Chip)<{ $currentUserReacted: boolean }>`
    margin-right: 4px;
    border: 1px solid ${(props) => (props.$currentUserReacted ? props.theme.colors.primary : props.theme.colors.border)};
    position: initial;

    &:hover {
        background-color: ${(props) => props.theme.colors.surface} !important;
    }

    .MuiTouchRipple-root {
        display: none;
    }
`;

const StyledLabel = styled.span`
    display: flex;
    align-items: center;
    margin: -2px;

    span {
        position: initial;
        margin-right: 2px;
    }
`;

interface ReactionProps {
    reaction: string;
    users: string[];
    handleReactionSelect: any;
}

const Reaction: React.FC<ReactionProps> = ({ reaction, users = [], handleReactionSelect }) => {
    const { currentUser } = useCurrentUser();
    const { usersDetails } = useUsersDetails({ userIds: users });

    const currentUserReacted = !!currentUser?.userId && users.includes(currentUser.userId);

    const userAliases = users.filter((user) => user !== currentUser?.userId).map((user) => usersDetails[user]?.alias);
    if (currentUserReacted) {
        userAliases.unshift('You (click to remove)');
    }
    return (
        <Tooltip title={`${userAliases.join(', ').replace(/, ([^,]*)$/, ' and $1')} reacted with ${reaction}`}>
            <StyledChip
                variant="outlined"
                size="small"
                label={
                    <StyledLabel>
                        <Emoji emoji={reaction} size={18} />
                        {users.length}
                    </StyledLabel>
                }
                onClick={() => handleReactionSelect({ colons: reaction })}
                $currentUserReacted={currentUserReacted}
            />
        </Tooltip>
    );
};

export default Reaction;
