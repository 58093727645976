import React from 'react';

const CourseIcon: React.FC<{ id?: string }> = ({ id }) => {
    return (
        <svg
            version="1.1"
            id={id ? id : 'Course_Icon'}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 24 24"
            xmlSpace="preserve"
            fill="currentColor"
        >
            <path d="M10.6,2.1c0.8-0.8,2-0.8,2.8,0l8.5,8.5c0.8,0.8,0.8,2,0,2.8l-8.5,8.5c-0.8,0.8-2,0.8-2.8,0l-8.5-8.5c-0.8-0.8-0.8-2.1,0-2.8 L10.6,2.1 M12,3.5L3.5,12l8.5,8.5l8.5-8.5L12,3.5z M8.7,14.3L11,12L8.7,9.7L6.3,12L8.7,14.3z M12,17.7l2.3-2.3L12,13l-2.3,2.3 L12,17.7z M12,11l2.3-2.3L12,6.3L9.7,8.7L12,11z M15.3,14.3l2.3-2.3l-2.3-2.3L13,12L15.3,14.3z" />
            <title>Course_Icon</title>
        </svg>
    );
};

export const CourseIconPath =
    'M10.6,2.1c0.8-0.8,2-0.8,2.8,0l8.5,8.5c0.8,0.8,0.8,2,0,2.8l-8.5,8.5c-0.8,0.8-2,0.8-2.8,0l-8.5-8.5c-0.8-0.8-0.8-2.1,0-2.8 L10.6,2.1 M12,3.5L3.5,12l8.5,8.5l8.5-8.5L12,3.5z M8.7,14.3L11,12L8.7,9.7L6.3,12L8.7,14.3z M12,17.7l2.3-2.3L12,13l-2.3,2.3 L12,17.7z M12,11l2.3-2.3L12,6.3L9.7,8.7L12,11z M15.3,14.3l2.3-2.3l-2.3-2.3L13,12L15.3,14.3z';

export default CourseIcon;
