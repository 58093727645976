import React from 'react';

const Visa: React.FC = () => {
    return (
        <svg viewBox="0 0 200 75.785455" xmlSpace="preserve" version="1.1" id="svg2">
            <defs id="defs6" />
            <g transform="matrix(1.25,0,0,-1.25,-74.19125,149.0417)" id="g10">
                <g transform="matrix(0.6384875,0,0,0.6384875,24.348951,24.07854)" id="g4163">
                    <g id="g12" transform="translate(143.448,67.609)">
                        <path fill="#2b3894" d="m 0,0 10.938,67.713 17.484,0 L 17.485,0 0,0" id="path14" />
                    </g>
                    <g id="g16" transform="translate(224.353,133.66)">
                        <path
                            fill="#2b3894"
                            d="m 0,0 c -3.457,1.359 -8.894,2.842 -15.667,2.842 -17.286,0 -29.454,-9.19 -29.555,-22.348 -0.11,-9.732 8.683,-15.161 15.312,-18.396 6.813,-3.316 9.102,-5.438 9.075,-8.403 -0.047,-4.535 -5.438,-6.613 -10.465,-6.613 -7,0 -10.719,1.023 -16.469,3.551 l -2.249,1.078 -2.445,-15.157 c 4.077,-1.886 11.635,-3.523 19.479,-3.609 18.371,0 30.308,9.078 30.448,23.129 0.063,7.718 -4.596,13.569 -14.686,18.399 -6.11,3.139 -9.86,5.219 -9.817,8.397 0.004,2.815 3.168,5.827 10.016,5.827 5.719,0.092 9.859,-1.225 13.085,-2.601 l 1.57,-0.775 L 0,0"
                            id="path18"
                        />
                    </g>
                    <g id="g20" transform="translate(247.634,91.616)">
                        <path
                            fill="#2b3894"
                            d="m 0,0 c 1.449,3.892 6.965,18.926 6.965,18.926 -0.102,-0.178 1.441,3.923 2.324,6.461 l 1.18,-5.84 c 0,0 3.355,-16.162 4.046,-19.547 C 11.793,0 3.414,0 0,0 Z m 21.57,43.637 -13.512,0 c -4.191,0 -7.328,-1.198 -9.164,-5.613 l -25.965,-62.055 18.364,0 c 0,0 2.996,8.348 3.676,10.18 2.003,0 19.847,-0.035 22.394,-0.035 0.524,-2.363 2.129,-10.145 2.129,-10.145 l 16.228,0 -14.15,67.668"
                            id="path22"
                        />
                    </g>
                    <g id="g24" transform="translate(128.78,135.265)">
                        <path
                            fill="#2b3894"
                            d="m 0,0 -17.113,-46.164 -1.833,9.384 c -3.189,10.812 -13.116,22.531 -24.22,28.403 l 15.656,-59.22 18.504,0.011 L 18.528,0 0,0"
                            id="path26"
                        />
                    </g>
                    <g id="g28" transform="translate(95.781,135.311)">
                        <path
                            fill="#f79510"
                            d="m 0,0 -28.195,0 -0.233,-1.403 C -6.488,-7.012 8.028,-20.551 14.053,-36.826 L 7.926,-5.713 C 6.869,-1.422 3.797,-0.15 0,0"
                            id="path30"
                        />
                    </g>
                    <g id="g32" transform="translate(288.329,72.034)">
                        <path
                            fill="#2b3894"
                            d="M 0,0 0.828,0 C 1.413,0 1.533,0.342 1.533,0.617 1.533,1.09 1.226,1.2 0.75,1.2 L 0,1.2 0,0 Z m -0.715,1.726 1.707,0 c 0.398,0 1.271,-0.105 1.271,-1.109 0,-0.266 -0.079,-0.641 -0.65,-0.885 l 0,-0.021 c 0.493,-0.084 0.519,-0.55 0.548,-0.925 0.036,-0.598 0,-0.705 0.156,-0.916 l -0.741,0 c -0.141,0.142 -0.109,0.253 -0.141,0.88 0,0.356 -0.045,0.719 -0.847,0.719 l -0.588,0 0,-1.599 -0.715,0 0,3.856 z m 1.41,-5.234 c 1.754,0 3.132,1.456 3.132,3.275 0,1.821 -1.378,3.276 -3.132,3.276 -1.753,0 -3.13,-1.455 -3.13,-3.276 0,-1.819 1.377,-3.275 3.13,-3.275 z m 0,7.137 c 2.163,0 3.862,-1.731 3.862,-3.862 0,-2.13 -1.699,-3.861 -3.862,-3.861 -2.162,0 -3.859,1.731 -3.859,3.861 0,2.131 1.697,3.862 3.859,3.862"
                            id="path34"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default Visa;
