import { OptimistMutationContext, useOptimist } from '@adept-at/lib-react-optimist';
import { OwnerInfo } from 'components/ContentContext/Interfaces';
import {
    GetNestedCollectionBySlugQueryResponse,
    buildGetNestedCollectionBySlugQueryKey
} from 'components/NestedCollection/context/getNestedCollectionBySlug';
import { ClientError, gql } from 'graphql-request';
import { useBuildAuthenticatedMutationFn } from 'hooks/useGqlClient/useBuildAuthenticatedMutationFn';
import { Api } from 'lib/ApiConstants';
import { useSnackbar } from 'notistack';
import { InfiniteData, useMutation, UseMutationResult } from 'react-query';

import {
    buildGetCollaborationsByOwnerQueryKey,
    GetCollaborationsByOwnerResponse
} from '../../CollaborationTable/getCollaborationsByOwner';
import { WorkflowStatus } from '../../CollaborationTable/utils';
import { buildGetCollectionQueryKey, GetCollectionResponse } from '../collection/getCollection';

import { useOptimistForEntityWorkflowUpdates } from './useOptimistForEntityWorkflowUpdates';

const UPDATE_COLLECTION_WORKFLOW = gql`
    mutation updateCollectionWorkflow($collectionId: UUIDv4!, $workflow: WorkflowStatus!) {
        updateCollectionWorkflow(collectionId: $collectionId, workflow: $workflow) {
            success
        }
    }
`;

export interface UpdateCollectionWorkflowResult {
    updateCollectionWorkflow: {
        success: boolean;
    };
}

export interface UpdateCollectionWorkflowProps {
    collectionId: string;
    workflow: keyof typeof WorkflowStatus;
}

export const useUpdateCollectionWorkflow = (
    owner: OwnerInfo,
    collectionId: string,
    slug?: string
): UseMutationResult<
    UpdateCollectionWorkflowResult,
    ClientError,
    UpdateCollectionWorkflowProps,
    OptimistMutationContext
> => {
    const { enqueueSnackbar } = useSnackbar();
    const { registerUpdater, mutationOptions } = useOptimist<
        UpdateCollectionWorkflowResult,
        ClientError,
        UpdateCollectionWorkflowProps
    >();

    registerUpdater<InfiniteData<GetCollaborationsByOwnerResponse>>(
        buildGetCollaborationsByOwnerQueryKey(owner),
        (previous, args) => {
            const updatedPages = (previous?.pages ?? []).map((page) => {
                return {
                    ...page,
                    getCollaborationsByOwner: {
                        ...page?.getCollaborationsByOwner,
                        items: (page?.getCollaborationsByOwner?.items ?? []).map((item) =>
                            'collectionId' in item.item && item.item.collectionId === args.collectionId
                                ? { ...item, item: { ...item.item, workflow: args.workflow } }
                                : item
                        )
                    }
                };
            });

            return {
                ...previous,
                pages: updatedPages
            };
        },
        {
            refetchQuery: false,
            onError: () => {
                enqueueSnackbar('An error occurred. Unable to update workflow status', { variant: 'error' });
            }
        }
    );

    useOptimistForEntityWorkflowUpdates<{
        props: UpdateCollectionWorkflowProps;
        result: UpdateCollectionWorkflowResult;
    }>(registerUpdater);

    registerUpdater<GetCollectionResponse>(
        buildGetCollectionQueryKey(collectionId),
        (previous, args) => {
            return {
                ...previous,
                getCollection: {
                    ...previous?.getCollection,
                    workflow: args.workflow
                }
            };
        },
        { refetchQuery: false }
    );

    registerUpdater<GetNestedCollectionBySlugQueryResponse>(
        buildGetNestedCollectionBySlugQueryKey(slug),
        (previous, args) => {
            return {
                ...previous,
                getEntityBySlug: {
                    ...previous?.getEntityBySlug,
                    workflow: args.workflow
                }
            };
        },
        { refetchQuery: false }
    );

    const { mutationFn } = useBuildAuthenticatedMutationFn<
        UpdateCollectionWorkflowResult,
        UpdateCollectionWorkflowProps
    >(UPDATE_COLLECTION_WORKFLOW, { api: Api.Content });

    return useMutation<
        UpdateCollectionWorkflowResult,
        ClientError,
        UpdateCollectionWorkflowProps,
        OptimistMutationContext
    >(mutationFn, mutationOptions);
};
