import React from 'react';

import { format } from 'date-fns';

import { StyledTableCell } from './TableCell.styles';

interface CreatedAtCellProps {
    createdAt?: string;
}

export const CREATED_AT_CELL_WIDTH = 120;

export const CreatedAtCell: React.FC<CreatedAtCellProps> = ({ createdAt }) => {
    return (
        <StyledTableCell width={CREATED_AT_CELL_WIDTH}>
            {createdAt ? format(new Date(createdAt), 'MM/dd/yyyy') : null}
        </StyledTableCell>
    );
};
