import { CollaboratorPermissionLevel } from 'components/ContentContext/Interfaces';

export const COLLABORATOR_PERMISSION_OPTIONS: { value: CollaboratorPermissionLevel; label: string }[] = [
    {
        value: CollaboratorPermissionLevel.Editor,
        label: 'Author'
    },
    {
        value: CollaboratorPermissionLevel.PeerReviewer,
        label: 'Peer Reviewer'
    }
];

export const COLLABORATOR_DEFAULT_PERMISSION_LEVEL: CollaboratorPermissionLevel =
    COLLABORATOR_PERMISSION_OPTIONS[0].value;
