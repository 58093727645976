import * as Cookies from 'es-cookie';

interface ClientStorageOptions {
    expires?: number;
    domain?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getCookie = <T = any>(key: string): T | undefined => {
    const value = Cookies.get(key);

    if (typeof value === 'undefined') {
        return;
    }

    return JSON.parse(value);
};

export const saveCookie = (key: string, value: unknown, options: ClientStorageOptions): void => {
    Cookies.set(key, JSON.stringify(value), options);
};

export const removeCookie = (key: string, options: ClientStorageOptions): void => {
    Cookies.remove(key, options);
};

/**
 * Note: This cookie is not the definitive source on whether or not the client has a valid session, just a hint to
 * inform the client-side code to attempt to automatically (silently) rehydrate a session via a token retrieval.
 *
 * This cookie is to serve as a client-side representation of whether or not we should attempt to retrieve a token from
 * the Accounts API. Client-side visibility into logged in state is performed via this separate cookie because the
 * session cookie set by the Accounts API is set as HttpOnly and cannot be read/discovered via client-side code.
 */
const SESSION_AWARENESS_COOKIE_NAME = 'adept.authed';
const ADEPT_COOKIE_DOMAIN = '.adept.at';

/**
 * The interval that we want to force the client application to re-login.
 */
const SESSION_CHECK_EXPIRY_DAYS = 7;

export const setSession = (): void => {
    saveCookie(SESSION_AWARENESS_COOKIE_NAME, true, {
        expires: SESSION_CHECK_EXPIRY_DAYS,
        domain: ADEPT_COOKIE_DOMAIN
    });
};

export const hasSession = (): boolean => getCookie<boolean>(SESSION_AWARENESS_COOKIE_NAME) ?? false;

export const clearSession = (): void => {
    removeCookie(SESSION_AWARENESS_COOKIE_NAME, { domain: ADEPT_COOKIE_DOMAIN });
};
