import { useContext, useMemo } from 'react';

import { CommunicationChannelsContext } from 'context/CommunicationChannelsContext';
import { ChannelType } from 'context/CommunicationChannelsContext/getChannelsForUser';

import useBulkChannelMessages, { UseBulkChannelMessagesOutput } from '../useBulkChannelMessages';

/**
 * The most common implementation of useBulkChannelMessages, the system messaging channels.
 */
const useSystemChannelsMessages = (): UseBulkChannelMessagesOutput => {
    const { status, findChannels } = useContext(CommunicationChannelsContext);

    const systemChannelIds = useMemo(() => {
        if (status === 'success') {
            const systemChannels = findChannels({
                type: ChannelType.System
            });
            if (systemChannels) {
                return systemChannels.map((channel) => channel.channelId);
            }
        }
        return [];
    }, [findChannels, status]);

    return useBulkChannelMessages({ channelIds: systemChannelIds });
};

export default useSystemChannelsMessages;
