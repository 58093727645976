import { Box } from '@material-ui/core';
import styled from 'styled-components';

export const PreviewText = styled.h3`
    white-space: pre-line;
`;

export const BoxWithPadding = styled(Box)`
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
`;

export const QuestionEditForm = styled.div`
    fieldset {
        display: block;
        padding-bottom: 0.8rem;
        padding-top: 0.8rem;
    }
`;
