import React, { useMemo } from 'react';

import { CollectionFeaturedImage } from '@adept-at/lib-react-components';
import { useThemeContext, ThemeMode } from 'context/ThemeContext';

import { useCollectionImageContext } from './context/CollectionImageContext';

export const CollectionBackgroundImage: React.FC = () => {
    const { themeMode } = useThemeContext();
    const { collectionImages, defaultFeatured } = useCollectionImageContext();

    const { desktopSrc, mobileSrc } = useMemo(() => {
        const desktopLightSrc = collectionImages?.featuredLight ?? defaultFeatured?.featuredLight;
        const desktopDarkSrc = collectionImages?.featuredDark ?? defaultFeatured?.featuredDark;
        const mobileLightSrc = collectionImages?.featuredLightMobile ?? defaultFeatured?.featuredLightMobile;
        const mobileDarkSrc = collectionImages?.featuredDarkMobile ?? defaultFeatured?.featuredDarkMobile;

        return {
            desktopSrc: themeMode === ThemeMode.DARK ? desktopDarkSrc : desktopLightSrc,
            mobileSrc: themeMode === ThemeMode.DARK ? mobileDarkSrc : mobileLightSrc
        };
    }, [
        collectionImages,
        themeMode,
        defaultFeatured?.featuredLight,
        defaultFeatured?.featuredDark,
        defaultFeatured?.featuredLightMobile,
        defaultFeatured?.featuredDarkMobile
    ]);

    return <CollectionFeaturedImage desktopSrc={desktopSrc ?? undefined} mobileSrc={mobileSrc ?? undefined} />;
};
