import React, { useContext, useMemo } from 'react';

import { IconSize, TooltippedIconButton, TooltippedButtonSize } from '@adept-at/lib-react-components';
import {
    mdiCodeTags,
    mdiVideoOutline,
    mdiPlus,
    mdiHelpCircleOutline,
    mdiFormatHeader1,
    mdiImageOutline,
    mdiPaperclip,
    mdiFormatListBulleted,
    mdiFormatListNumbered,
    mdiLink,
    mdiMinus
} from '@mdi/js';
import Icon from '@mdi/react';

import { RichTextEditorContext } from '../common/RichTextEditor/Context';
import { DraftJsBlock, DraftJsEntity } from '../common/RichTextEditor/utils';

import { List, ListItems } from './ComponentList.styles';
import { HideableButton } from './index.styles';

interface ComponentListProps {
    onClick: (id: DraftJsEntity) => void;
    isOpen: boolean;
    toggleMenu: () => void;
}

export const ComponentList: React.FC<ComponentListProps> = ({ onClick, toggleMenu, isOpen }) => {
    const { addSection, toggleBlockType, addEntity } = useContext(RichTextEditorContext);

    const options = useMemo(
        () => [
            {
                label: 'Section',
                onClick: () => {
                    addSection();
                    toggleMenu();
                },
                iconPath: mdiFormatHeader1
            },
            {
                label: 'Bulleted list',
                onClick: () => {
                    toggleBlockType(DraftJsBlock.UL);
                    toggleMenu();
                },
                iconPath: mdiFormatListBulleted
            },
            {
                label: 'Numbered list',
                onClick: () => {
                    toggleBlockType(DraftJsBlock.OL);
                    toggleMenu();
                },
                iconPath: mdiFormatListNumbered
            },
            {
                label: 'Video',
                onClick: () => onClick(DraftJsEntity.VIDEO),
                iconPath: mdiVideoOutline
            },
            {
                label: 'Image',
                onClick: () => onClick(DraftJsEntity.IMAGE),
                iconPath: mdiImageOutline
            },
            {
                label: 'Question',
                onClick: () => onClick(DraftJsEntity.QUESTION),
                iconPath: mdiHelpCircleOutline
            },
            {
                label: 'Code',
                onClick: () => onClick(DraftJsEntity.CODE),
                iconPath: mdiCodeTags
            },
            {
                label: 'Attachment',
                onClick: () => onClick(DraftJsEntity.ATTACHMENT),
                iconPath: mdiPaperclip
            },
            {
                label: 'Link',
                onClick: () => onClick(DraftJsEntity.LINK),
                iconPath: mdiLink
            },
            {
                label: 'Divider',
                onClick: () => {
                    addEntity(DraftJsEntity.DIVIDER);
                    toggleMenu();
                },
                iconPath: mdiMinus
            }
            // {
            //     label: 'Virtual Lab',
            //     onClick: () => onClick(DraftJsEntity.LAB),
            //     iconPath: mdiFlask
            // }
        ],
        [addSection, toggleBlockType, toggleMenu, onClick, addEntity]
    );

    return (
        <List>
            <HideableButton
                color="primary"
                onClick={toggleMenu}
                onMouseDown={(e) => e.preventDefault()}
                data-testid="add-component-button"
                aria-label="Add component"
                $isOpen={isOpen}
            >
                <Icon size={IconSize.Medium} path={mdiPlus} />
            </HideableButton>
            {isOpen ? (
                <ListItems>
                    {options.map((option, i) => {
                        const { iconPath, label, onClick } = option;

                        return (
                            <TooltippedIconButton
                                key={i}
                                aria-label={`Add ${label}`}
                                size={TooltippedButtonSize.Medium}
                                onClick={onClick}
                                onMouseDown={(e) => e.preventDefault()}
                                icon={<Icon path={iconPath} />}
                                title={label}
                                data-testid={`componentList-listItem-${label}`}
                            />
                        );
                    })}
                </ListItems>
            ) : null}
        </List>
    );
};
