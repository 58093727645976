import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';

import ReconnectingWebSocket from 'reconnecting-websocket';

import { AuthContext } from '../AuthContext';

export interface CommunicationWebsocketContextInterface {
    connectionStatus: WebsocketConnectionStatus;
    connection?: ReconnectingWebSocket;
}

export enum WebsocketConnectionStatus {
    Connecting = 'connecting',
    Connected = 'connected',
    Disconnected = 'disconnected',
    Unknown = 'unknown',
    Errored = 'errored'
}

export const DISCONNECTED_WEBSOCKET_CONNECTION_STATUSES = [
    WebsocketConnectionStatus.Disconnected,
    WebsocketConnectionStatus.Unknown
];

export enum SocketEventType {
    Draw = 'draw'
}

const CommunicationWebsocketContext = createContext(undefined as unknown as CommunicationWebsocketContextInterface);

const { Provider, Consumer } = CommunicationWebsocketContext;

const buildCommunicationWebsocketUrl = (token?: string): string => {
    const host = `${process.env.REACT_APP_COMMUNICATION_HOST_WEBSOCKET as string}`;

    /**
     * Serverless offline does not provide customer authorizer support for websockets
     * https://github.com/dherault/serverless-offline/issues/951
     */
    if (process.env.NODE_ENV === 'development') {
        return `${host}?access_token=${token}`;
    }

    return host;
};

const MAX_CONNECTION_ATTEMPTS = 5;

const CommunicationWebsocketProvider: React.FC = ({ children }) => {
    const { isAuthenticated, token } = useContext(AuthContext);

    const [connectionStatus, setConnectionStatus] = useState(WebsocketConnectionStatus.Unknown);
    const connection = useRef<ReconnectingWebSocket | undefined>();

    const connect = useCallback(() => {
        if (!isAuthenticated || !token) {
            return;
        }

        if (!connection.current) {
            setConnectionStatus(WebsocketConnectionStatus.Connecting);
            console.log(`Connecting to Communication Websocket`);

            const communicationWebsocketUrl = buildCommunicationWebsocketUrl(token);

            connection.current = new ReconnectingWebSocket(communicationWebsocketUrl, undefined, {
                maxRetries: MAX_CONNECTION_ATTEMPTS,
                connectionTimeout: 5000
            });

            connection.current.onopen = () => {
                console.log('Communication Websocket Connected.');

                setConnectionStatus(WebsocketConnectionStatus.Connected);
            };

            connection.current.onclose = () => {
                setConnectionStatus(WebsocketConnectionStatus.Connecting);
            };

            connection.current.onerror = (errorEvent) => {
                console.log(errorEvent);
                const errorToLog = [
                    'RetryCount:',
                    connection.current?.retryCount,
                    'Websocket Connection Error: ',
                    errorEvent
                ];

                if (connection.current?.retryCount === MAX_CONNECTION_ATTEMPTS) {
                    setConnectionStatus(WebsocketConnectionStatus.Errored);
                    console.error(...errorToLog);
                } else {
                    console.warn(...errorToLog);
                }
            };
        }
    }, [isAuthenticated, token]);

    useEffect(() => {
        if (isAuthenticated && token && DISCONNECTED_WEBSOCKET_CONNECTION_STATUSES.includes(connectionStatus)) {
            connect();
        }
    }, [isAuthenticated, token, connect, connectionStatus]);

    return (
        <Provider
            value={{
                connectionStatus,
                connection: connection.current
            }}
        >
            {children}
        </Provider>
    );
};

export { CommunicationWebsocketContext, CommunicationWebsocketProvider, Consumer as CommunicationWebsocketConsumer };
