import { EntityType } from 'components/ContentContext/Enums';
import { MeetingSession } from 'components/rooms/hooks/getSessionsForChannel';
import { add, format } from 'date-fns';
import { formatDateForYearMonthDay } from 'utils/communication/dates';
import { ProgressState } from 'utils/progress';

import { FavoriteItem } from './hooks/useGetFavorites';
import { ScheduleItem, ScheduleItemEntity } from './hooks/useGetSchedule';
import { BasicLabInfo, BasicSkillInfoWithProgress } from './interfaces';
import { GroupedItems, ScheduleTimeBucket, NormalizedScheduleItem } from './Schedule/ScheduleContext';

export const SCHEDULE_TIME_FORMAT = 'yyyy-MM-dd';

export const typeToIdKey = {
    [EntityType.SKILL]: 'skillId',
    [EntityType.VIRTUAL_LAB]: 'vLabId',
    [EntityType.LIVE_LEARNING_SESSION]: 'sessionId',
    [EntityType.COLLECTION]: 'collectionId'
};

const emptyResponse = (): GroupedItems => ({
    [ScheduleTimeBucket.TODAY]: [],
    [ScheduleTimeBucket.TOMORROW]: [],
    [ScheduleTimeBucket.THIS_WEEK]: [],
    [ScheduleTimeBucket.THIS_MONTH]: [],
    [ScheduleTimeBucket.LATER]: []
});

export const groupByTimeBucket = (items: NormalizedScheduleItem[]): GroupedItems => {
    return items.reduce((memo, item) => {
        const bucket = item.bucket;

        memo[bucket].push(item.details);

        return memo;
    }, emptyResponse());
};

interface TimeComparators {
    today: string;
    tomorrow: string;
    oneWeekFromToday: string;
    oneMonthFromToday: string;
}

export const determineTimeBucket = (date: string, timeComparators: TimeComparators): ScheduleTimeBucket => {
    const { today, tomorrow, oneWeekFromToday, oneMonthFromToday } = timeComparators;

    if (date <= today) {
        return ScheduleTimeBucket.TODAY;
    } else if (date <= tomorrow) {
        return ScheduleTimeBucket.TOMORROW;
    } else if (date <= oneWeekFromToday) {
        return ScheduleTimeBucket.THIS_WEEK;
    } else if (date <= oneMonthFromToday) {
        return ScheduleTimeBucket.THIS_MONTH;
    } else {
        return ScheduleTimeBucket.LATER;
    }
};

export const sortByTimeBucket = (items: NormalizedScheduleItem[]): NormalizedScheduleItem[] =>
    items.sort((a, b) => a.bucket - b.bucket);

export const getTimeComparators = (): TimeComparators => {
    const now = new Date();
    const today = format(now, SCHEDULE_TIME_FORMAT);
    const tomorrow = format(add(now, { days: 1 }), SCHEDULE_TIME_FORMAT);
    const oneWeekFromToday = format(add(now, { days: 7 }), SCHEDULE_TIME_FORMAT);
    const oneMonthFromToday = format(add(now, { months: 1 }), SCHEDULE_TIME_FORMAT);

    return { today, tomorrow, oneWeekFromToday, oneMonthFromToday };
};

export const normalizeItems = (items: ScheduleItem[]): NormalizedScheduleItem[] => {
    const timeComparators = getTimeComparators();

    return items.map(({ date, type, item }) => ({
        id: item[typeToIdKey[type]],
        date,
        type,
        title: 'title' in item ? item.title : (item as BasicLabInfo).name,
        bucket: determineTimeBucket(date, timeComparators),
        url: generateItemUrl(item),
        progressUpdatedAt: (item as BasicSkillInfoWithProgress).progress?.updatedAt,
        details: item
    }));
};

export const generateItemUrl = (item: ScheduleItemEntity | MeetingSession | FavoriteItem): string | undefined => {
    if ('meetingId' in item) {
        return `/organization/${item.details.organizationId}/rooms/${item.details.roomId}`;
    }

    if ('skillId' in item) {
        return `/learn/${item.tenantSlug}/skill/${item.skillSlug}`;
    }

    if ('collectionId' in item) {
        return `/${item.tenantSlug}/${item.collectionSlug}`;
    }

    return;
};

export const groupItemsByDate = (
    items: NormalizedScheduleItem[],
    dateField: keyof Pick<NormalizedScheduleItem, 'date' | 'progressUpdatedAt'>
): Record<string, (ScheduleItemEntity | MeetingSession)[]> =>
    items
        .sort(({ [dateField]: dateValueA }, { [dateField]: dateValueB }) => {
            const dateA = dateValueA ? new Date(dateValueA as string).getTime() : 0;
            const dateB = dateValueB ? new Date(dateValueB as string).getTime() : 0;

            return dateB - dateA;
        })
        .reduce((memo, item) => {
            const { [dateField]: dateValue } = item;

            if (!dateValue || typeof dateValue !== 'string') {
                return memo;
            }

            const dateKey = formatDateForYearMonthDay(new Date(dateValue));

            return {
                ...memo,
                [dateKey]: [item.details, ...(memo[dateKey] ?? [])]
            };
        }, {} as Record<string, (ScheduleItemEntity | MeetingSession)[]>);
