import { useEffect, useMemo, useContext, useCallback, useState } from 'react';

import { PresenceStatus } from 'components/communication/UserPresenceCard';
import { AvatarDetails } from 'context/AvatarsContext';
import { useAvatars } from 'context/AvatarsContext/useAvatars';
import CommunicationUsersDetailsContext from 'context/CommunicationUserDetailsContext';
import { CommunicationUserDetails } from 'context/CommunicationUserDetailsContext/getCommunicationUserDetails';
import UserInfoContext from 'context/UserInfoContext';
import { UserInfo } from 'context/UserInfoContext/getUserInfoByUserIds';

export interface UserDetails {
    id?: string;
    name?: {
        first?: string;
        last?: string;
    };
    displayName?: string;
    image?: string | null; // Reference to the asset that lives in Content API
    imageUrl?: string | null; // Resolved image details from communication with Content API
    alias?: string;
    presenceStatus?: PresenceStatus;
}

interface UserDetailsResult {
    // @TODO would be great to split out loading into numerous states so we can show avatar other details when ready
    loading: boolean;
    usersDetails: Record<string, UserDetails>;
}

const buildDisplayName = (user?: UserInfo) => {
    if (user?.name?.first) {
        return [user.name.first, user.name?.last].join(' ').trim();
    }

    return user?.alias;
};

/** If you are getting an infinite loop while using this hook, be sure that the userIds are not a new array every render. (useMemo) */
const useUserDetails = ({ userIds = new Array<string>() } = {}): UserDetailsResult => {
    const [isAboutToLoad, setIsAboutToLoad] = useState(userIds?.length > 0);

    const { getUserInfoFromMap, fetchUserInfo, isLoading: userInfoIsLoading } = useContext(UserInfoContext);
    const {
        getCommUsersDetailsFromMap,
        fetchCommUsersDetails,
        isLoading: commIsLoading
    } = useContext(CommunicationUsersDetailsContext);

    useEffect(() => {
        if (isAboutToLoad) setIsAboutToLoad(false);
    }, [isAboutToLoad]);

    useEffect(() => {
        if (userIds.length > 0 && !userInfoIsLoading && !commIsLoading) {
            fetchUserInfo(userIds);
            fetchCommUsersDetails(userIds);
        }
    }, [userIds, fetchUserInfo, fetchCommUsersDetails, userInfoIsLoading, commIsLoading]);

    const assetIds = useMemo(() => {
        const userInfos = getUserInfoFromMap(userIds);
        return Object.values(userInfos)
            .map((userInfo) => userInfo?.image)
            .filter(Boolean) as string[];
    }, [userIds, getUserInfoFromMap]);

    const { avatars, loading: avatarsAreLoading } = useAvatars({ assetIds });

    const formatUserDetails = useCallback(
        (userInfo?: UserInfo, commUserDetails?: CommunicationUserDetails, avatar?: AvatarDetails) => ({
            id: userInfo?.id,
            name: {
                first: userInfo?.name?.first,
                last: userInfo?.name?.last
            },
            image: userInfo?.image,
            imageUrl: avatar?.crop ?? avatar?.image,
            alias: userInfo?.alias,
            displayName: buildDisplayName(userInfo),
            presenceStatus: commUserDetails?.status
        }),
        []
    );

    const usersDetails = useMemo(() => {
        const userInfos = getUserInfoFromMap(userIds);
        const commUsersDetails = getCommUsersDetailsFromMap(userIds);

        return Object.fromEntries(
            userIds.map((userId) => {
                const userInfo = userInfos?.[userId];
                const avatar = userInfo?.image ? avatars?.[userInfo.image] : undefined;
                return [userId, formatUserDetails(userInfo, commUsersDetails?.[userId], avatar)];
            })
        );
    }, [userIds, getUserInfoFromMap, getCommUsersDetailsFromMap, avatars, formatUserDetails]);

    const loading = useMemo(
        () => isAboutToLoad || userInfoIsLoading || commIsLoading || avatarsAreLoading,
        [isAboutToLoad, userInfoIsLoading, commIsLoading, avatarsAreLoading]
    );

    return { loading, usersDetails };
};

export default useUserDetails;
