import React, { useContext } from 'react';

import { TooltippedIconButton, TooltippedButtonSize } from '@adept-at/lib-react-components';
import { mdiMenu, mdiMenuOpen, mdiArrowLeft } from '@mdi/js';
import Icon from '@mdi/react';
import BreadCrumbTrail from 'components/BreadCrumbTrail';
import styled from 'styled-components';
import { APP_HEADER_HEIGHT, NAV_THIN_WIDTH } from 'styles/variables';

import AccountMenu from '../account/AccountMenu';
import { CreateButton } from '../CreateButton';
import { DarkModeButton } from '../DarkModeButton';

import { HeaderContext } from './HeaderContext';
import { HeaderLogo } from './HeaderLogo';
import { NavButtons } from './NavButtons';
import { NavContext } from './NavContext';

const HeaderContainer = styled.div`
    background-color: ${(props) => props.theme.colors.surface};
    display: grid;
    grid-template-columns: minmax(0, 1fr) auto;
    grid-template-rows: ${APP_HEADER_HEIGHT}px;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
`;

const Account = styled.div`
    display: flex;
    align-items: center;
`;

const HeaderLeft = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
`;

const HeaderRight = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
`;

const NavButtonWrapper = styled.div`
    width: ${NAV_THIN_WIDTH}px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

interface HeaderProps {
    hideNav?: boolean;
    backButton?: boolean;
    backButtonAction?: () => void;
    backButtonIconPath?: string;
    backButtonTooltip?: string;
}

const Header: React.FC<HeaderProps> = ({
    hideNav,
    backButton,
    backButtonAction,
    backButtonIconPath,
    backButtonTooltip
}) => {
    const { isOpen, openNavbar } = useContext(NavContext);
    const { breadcrumbs } = useContext(HeaderContext);

    return (
        <HeaderContainer>
            <HeaderLeft>
                <NavButtonWrapper>
                    {hideNav ? null : (
                        <TooltippedIconButton
                            aria-label={`${isOpen ? 'Close' : 'Open'} left navigation bar`}
                            size={TooltippedButtonSize.Medium}
                            onClick={() => openNavbar({ toggle: true, savePreference: true })}
                            icon={<Icon path={isOpen ? mdiMenuOpen : mdiMenu} />}
                            title={isOpen ? 'Close' : 'Open'}
                        />
                    )}
                    {backButton ? (
                        <TooltippedIconButton
                            aria-label="Return to previous page"
                            size={TooltippedButtonSize.Medium}
                            onClick={() => backButtonAction && backButtonAction()}
                            icon={<Icon path={backButtonIconPath ?? mdiArrowLeft} />}
                            title={backButtonTooltip ?? 'Previous page'}
                        />
                    ) : null}
                </NavButtonWrapper>
                <HeaderLogo />
                <NavButtons />
                <BreadCrumbTrail breadCrumbData={breadcrumbs} />
            </HeaderLeft>
            <HeaderRight>
                <CreateButton />
                <DarkModeButton />
                <Account>
                    <AccountMenu />
                </Account>
            </HeaderRight>
        </HeaderContainer>
    );
};

export default Header;
