import { useMemo } from 'react';

import { OwnerType, OwnerInfo } from 'components/ContentContext/Interfaces';

import { ProfileOption, ProfileType } from '..';

interface UseProfileConvenienceOptions {
    currentProfile: ProfileOption | null;
}

export interface UseProfileConvenienceOutput {
    isOrganization: boolean;
    currentProfileOwnerInfo: OwnerInfo | null;
}

const useProfileConvenience = ({ currentProfile }: UseProfileConvenienceOptions): UseProfileConvenienceOutput => {
    const isOrganization = useMemo(
        () => Boolean(currentProfile?.type && currentProfile.type === ProfileType.Organization),
        [currentProfile?.type]
    );

    const currentProfileOwnerInfo = useMemo((): OwnerInfo | null => {
        if (!currentProfile?.id) return null;
        return {
            type: currentProfile?.type === ProfileType.Organization ? OwnerType.Organization : OwnerType.User,
            id: currentProfile.id
        };
    }, [currentProfile]);

    return { isOrganization, currentProfileOwnerInfo };
};

export default useProfileConvenience;
