import { useMemo } from 'react';

import { CollectionChild } from 'components/RootProfileCollection/context/useGetTenantProfileForOwner';
import { ClientError } from 'graphql-request';
import useGqlClient from 'hooks/useGqlClient';
import { API_CONTENT } from 'lib/ApiConstants';
import { useQuery } from 'react-query';

import getCollectionTitles, {
    buildGetCollectionDetailsQueryKey,
    CollectionDetails,
    GetCollectionDetailsResponse
} from './getCollectionDetails';
import getOrganizationRootCollections, {
    buildGetOrganizationRootCollectionsQueryKey,
    GetOrganizationCollectionsQueryResponse
} from './getOrganizationRootCollections';

interface UseOrganizationCollectionsOutput {
    collectionsLoading: boolean;
    collections: CollectionDetails[];
    tenantSlug: string;
}

const useOrganizationCollections = (organizationId: string): UseOrganizationCollectionsOutput => {
    const { client: contentClient, withQueryOptions } = useGqlClient(API_CONTENT);

    const rootCollectionsQueryKey = useMemo(
        () => buildGetOrganizationRootCollectionsQueryKey(organizationId),
        [organizationId]
    );
    const { data: rootCollectionsData, isLoading: rootCollectionsLoading } = useQuery<
        GetOrganizationCollectionsQueryResponse,
        ClientError
    >(
        withQueryOptions({
            queryKey: rootCollectionsQueryKey,
            queryFn: getOrganizationRootCollections(contentClient, organizationId)
        })
    );

    const tenantSlug = useMemo(
        () => rootCollectionsData?.getTenantProfileForOwner?.tenantSlug ?? '',
        [rootCollectionsData?.getTenantProfileForOwner?.tenantSlug]
    );

    const childCollections = useMemo(() => {
        return (rootCollectionsData?.getTenantProfileForOwner?.privateRootCollection?.children ?? [])
            .concat(rootCollectionsData?.getTenantProfileForOwner?.rootCollection?.children ?? [])
            .reduce((memo, child) => {
                if (child.type === CollectionChild.Collection) {
                    memo.push(child.id);
                }
                return memo;
            }, [] as string[]);
    }, [rootCollectionsData]);

    const collectionTitlesQueryKey = useMemo(
        () => buildGetCollectionDetailsQueryKey(childCollections),
        [childCollections]
    );
    const { data: collectionTitlesData, isLoading: collectionTitlesLoading } = useQuery<
        GetCollectionDetailsResponse,
        ClientError
    >(
        withQueryOptions({
            queryKey: collectionTitlesQueryKey,
            queryFn: getCollectionTitles(contentClient, childCollections),
            enabled: childCollections.length > 0
        })
    );

    const collections = useMemo(
        () => collectionTitlesData?.getCollectionBasicDetails?.sort((a, b) => a.title.localeCompare(b.title)) ?? [],
        [collectionTitlesData?.getCollectionBasicDetails]
    );

    return {
        collectionsLoading: rootCollectionsLoading || collectionTitlesLoading,
        collections,
        tenantSlug
    };
};

export default useOrganizationCollections;
