import React, { useContext, useState } from 'react';

import { TooltippedButtonSize, TooltippedIconButton } from '@adept-at/lib-react-components';
import { Divider } from '@material-ui/core';
import {
    mdiAccountMultiple,
    mdiClipboardTextOutline,
    mdiDelete,
    mdiDotsVertical,
    mdiFileCog,
    mdiKey,
    mdiPencil,
    mdiPencilOff
} from '@mdi/js';
import Icon from '@mdi/react';
import { WorkflowStatus } from 'components/CollaborationTable';
import { WorkflowChip } from 'components/CollaborationTable/WorkflowChip';
import { EntityType } from 'components/ContentContext/Enums';
import { Skill, OwnerType } from 'components/ContentContext/Interfaces';
import SharingModal, { SharingModalTabs } from 'components/modals/Sharing/SharingModal/SharingModal';
import { PageSettingsModal } from 'components/NestedCollection/PageSettingsModal';
import { buildQueryKey as buildGetSkillByIdQueryKey } from 'components/skills/hooks/useGetSkillById';
import { useModal } from 'hooks/useModal';
import { usePopover } from 'hooks/usePopover';
import { StyledListItem, StyledMenu } from 'styles/Shared.styles';

import { EditMode, EditorContext } from '../context/EditorContext';
import { AvailabilityButton } from '../Meta/SkillHeader/AvailabilityButton';

interface SkillEditMenuProps {
    skill: Skill;
    onToggleEdit: () => void;
}

export const SkillEditMenu: React.FC<SkillEditMenuProps> = ({ skill, onToggleEdit }) => {
    const { editStoryboard, editMode } = useContext(EditorContext);

    const [startingTab, setStartingTab] = useState(SharingModalTabs.Collaborators);
    const { open, anchorEl, handlePopoverClick, handlePopoverClose } = usePopover();
    const { modalOpen, handleModalOpen, handleModalClose } = useModal();

    const {
        modalOpen: pageSettingsOpen,
        handleModalClose: handlePageSettingsClose,
        handleModalOpen: handlePageSettingsOpen
    } = useModal();

    return (
        <>
            <TooltippedIconButton
                aria-label={'more actions menu'}
                size={TooltippedButtonSize.Medium}
                onClick={handlePopoverClick}
                icon={<Icon path={mdiDotsVertical} />}
                title={'More actions'}
            />
            <StyledMenu
                id="skill-edit-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handlePopoverClose}
                getContentAnchorEl={null}
                onClick={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <StyledListItem
                    button
                    onClick={() => {
                        setStartingTab(SharingModalTabs.Collaborators);
                        handleModalOpen();
                    }}
                >
                    <Icon path={mdiAccountMultiple} /> Collaborators
                </StyledListItem>
                <SharingModal
                    entities={[skill]}
                    isOpen={modalOpen}
                    handleClose={handleModalClose}
                    startingTab={startingTab}
                    owner={
                        skill.organizationId ? { type: OwnerType.Organization, id: skill.organizationId } : skill.owner
                    }
                    contentQueryKey={buildGetSkillByIdQueryKey(skill.skillId)}
                />
                <AvailabilityButton variant="listItem" skill={skill} />
                <WorkflowChip
                    variant="listItem"
                    id={skill.skillId}
                    type={EntityType.SKILL}
                    owner={skill.owner}
                    workflow={skill.workflow ? WorkflowStatus[skill.workflow] : undefined}
                />
                <StyledListItem button onClick={handlePageSettingsOpen}>
                    <Icon path={mdiFileCog} /> Page settings
                </StyledListItem>
                <PageSettingsModal
                    contentType={EntityType.SKILL}
                    contentTitle={skill.title}
                    description={skill.subtitle ?? ''}
                    catalogImage={skill.images?.catalog?.processed?.[0]?.url}
                    open={pageSettingsOpen}
                    onClose={handlePageSettingsClose}
                    onConfirm={() => {
                        // @TODO Update and save values
                        handlePageSettingsClose();
                    }}
                />
                <StyledListItem button onClick={editStoryboard}>
                    <Icon path={mdiClipboardTextOutline} /> Edit storyboard
                </StyledListItem>
                <StyledListItem button onClick={onToggleEdit}>
                    <Icon path={editMode !== EditMode.Preview ? mdiPencilOff : mdiPencil} />
                    {editMode !== EditMode.Preview ? 'Stop editing' : 'Edit'}
                </StyledListItem>
                <Divider />
                <StyledListItem
                    button
                    onClick={() => {
                        setStartingTab(SharingModalTabs.TransferOwnership);
                        handleModalOpen();
                    }}
                >
                    <Icon path={mdiKey} /> Transfer ownership
                </StyledListItem>
                <StyledListItem button disabled>
                    <Icon path={mdiDelete} /> Delete
                </StyledListItem>
            </StyledMenu>
        </>
    );
};
