import React, { useState } from 'react';

import { LARGE_BACKGROUND_IMAGE_SIZE_IN_PX } from '@adept-at/lib-react-components';
import { Button } from '@material-ui/core';
import EditableTextArea from 'components/forms/EditableText/EditableTextArea';
import styled from 'styled-components';
import { SKILL_CONTENT_WIDTH } from 'styles/variables';

const AddSubtitleButton = styled(Button)`
    padding: 0;
    margin-bottom: 0.5rem;
    width: fit-content;
`;

interface SubtitleProps {
    subtitle: string | null;
    canEdit: boolean;
    setSubtitle: React.Dispatch<React.SetStateAction<string | null>>;
}

export const Subtitle: React.FC<SubtitleProps> = ({ subtitle, setSubtitle, canEdit }) => {
    const [showEditableInput, setShowEditableInput] = useState(false);

    if (!!subtitle?.trim() || showEditableInput) {
        return (
            <EditableTextArea
                allowEmptyContent
                saveContent={(newSubtitle: string) => {
                    setSubtitle(newSubtitle);
                    setShowEditableInput(false);
                }}
                initialContent={subtitle ?? ''}
                validation={(content) => !!content || content === ''}
                canEdit={canEdit}
                initialIsEditing={showEditableInput}
                styleProps={{
                    maxWidth: `${SKILL_CONTENT_WIDTH - LARGE_BACKGROUND_IMAGE_SIZE_IN_PX}px`,
                    width: '100%'
                }}
            />
        );
    }

    return canEdit ? (
        <AddSubtitleButton onClick={() => setShowEditableInput(true)} color="primary">
            Add subtitle
        </AddSubtitleButton>
    ) : null;
};
