import React from 'react';

import styled from 'styled-components';

const MarkLabelContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0.5rem;
    margin-top: 0.25rem;
`;

const MarkLabel = styled.div`
    font-size: 1rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.text};
`;

const MarkConsumptionTime = styled.div`
    font-size: 0.75rem;
    font-weight: 200;
    color: ${({ theme }) => theme.colors.textTertiary};
`;

export const MarkLabelComponent: React.FC<{
    label: string;
    pace: string;
}> = ({ label, pace }) => {
    return (
        <MarkLabelContainer>
            <MarkLabel>{label} </MarkLabel>
            <MarkConsumptionTime>{pace}</MarkConsumptionTime>
        </MarkLabelContainer>
    );
};
