import React, { useContext, useState, useMemo } from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextField, Fade } from '@material-ui/core';
import { mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import { useCreateBookshelf } from 'components/bookshelves/hooks/useCreateBookshelf';
import { useCreateBookshelfNotifs } from 'components/bookshelves/hooks/useCreateBookshelfNotifs';
import { sortBookshelvesBy } from 'components/bookshelves/utils';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import * as Yup from 'yup';

import { DEFAULT_FADE_TIMEOUT } from '..';
import { BookshelvesContext } from '../../bookshelves/BookshelvesContext';
import { Title, ModalBody, ModalHeader, StyledModal } from '../Modal.styles';
import ModalActionButtons from '../ModalActionButtons';
import ModalCloseIcon from '../ModalCloseIcon';

import BookshelfListItem from './BookshelfListItem';
import { LoadingSkeleton } from './LoadingSkeleton';
import { BookshelvesContainer } from './styles';

const TopSection = styled.div`
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
    padding: 1.5rem;
`;

const StyledButton = styled(Button)`
    margin-bottom: 0.5rem;
`;

const StyledModalBody = styled(ModalBody)`
    padding: 0;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
`;

export enum ModalView {
    AddExisting = 'add',
    CreateNew = 'create'
}

interface CreateBookshelfFormFields {
    bookshelf: string;
}

interface BookshelfModalProps {
    initialView: ModalView;
}

const BookshelfModal: React.FC<BookshelfModalProps> = ({ initialView }) => {
    const {
        itemIdsInBookshelves,
        itemIdsInBookshelvesLoading,
        bookshelfModalOpen,
        closeBookshelfModal,
        selectedItemToAdd,
        sortBy
    } = useContext(BookshelvesContext);

    const createBookshelfNotifs = useCreateBookshelfNotifs();

    const { mutate: createBookshelf, isLoading: creatingBookshelf } = useCreateBookshelf(createBookshelfNotifs);

    const { handleSubmit, register, errors } = useForm<CreateBookshelfFormFields>({
        mode: 'onBlur',

        resolver: yupResolver(
            Yup.object().shape({
                bookshelf: Yup.string().required('Bookshelf name required')
            })
        )
    });

    const onSubmitCreateBookshelf = ({ bookshelf }) => {
        createBookshelf({ title: bookshelf });

        if (initialView === ModalView.CreateNew) {
            handleCloseModal();
        }

        if (initialView === ModalView.AddExisting) {
            setModalView(initialView);
        }
    };

    const [modalView, setModalView] = useState(initialView);

    const bookshelves = useMemo(() => {
        if (itemIdsInBookshelves) {
            return sortBookshelvesBy({ bookshelves: itemIdsInBookshelves, sortBy });
        }

        return [];
    }, [itemIdsInBookshelves, sortBy]);

    const handleCloseModal = () => {
        closeBookshelfModal();
        setModalView(initialView);
    };

    return (
        <StyledModal open={bookshelfModalOpen} onClose={handleCloseModal}>
            <Fade in={bookshelfModalOpen} timeout={DEFAULT_FADE_TIMEOUT}>
                {modalView === ModalView.AddExisting ? (
                    <StyledModalBody>
                        <TopSection>
                            <ModalHeader>
                                <Title>Add to bookshelf</Title>
                                <ModalCloseIcon showCloseIcon onClose={closeBookshelfModal} />
                            </ModalHeader>
                            <ButtonContainer>
                                <StyledButton
                                    disableElevation
                                    color="primary"
                                    variant="contained"
                                    startIcon={<Icon size={IconSize.Small} path={mdiPlus} />}
                                    onClick={() => setModalView(ModalView.CreateNew)}
                                >
                                    New bookshelf
                                </StyledButton>
                            </ButtonContainer>
                        </TopSection>

                        {itemIdsInBookshelvesLoading || !selectedItemToAdd ? (
                            <LoadingSkeleton items={5} />
                        ) : (
                            <BookshelvesContainer>
                                {bookshelves?.map((bookshelf) => {
                                    return <BookshelfListItem key={bookshelf.bookshelfId} bookshelf={bookshelf} />;
                                })}
                            </BookshelvesContainer>
                        )}
                    </StyledModalBody>
                ) : (
                    <ModalBody>
                        <ModalHeader>
                            <Title>New Bookshelf</Title>
                            <ModalCloseIcon showCloseIcon onClose={closeBookshelfModal} />
                        </ModalHeader>
                        <form onSubmit={handleSubmit(onSubmitCreateBookshelf)}>
                            <TextField
                                fullWidth={true}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                disabled={creatingBookshelf}
                                id="bookshelf"
                                type="text"
                                name="bookshelf"
                                label="Bookshelf name"
                                error={!!errors?.bookshelf}
                                helperText={errors.bookshelf ? 'Bookshelf name required' : null}
                                inputProps={{ 'data-testid': 'bookshelf-name' }}
                                inputRef={register}
                            />
                        </form>
                        <ModalActionButtons
                            showActionButtons
                            onClose={() =>
                                selectedItemToAdd ? setModalView(ModalView.AddExisting) : closeBookshelfModal()
                            }
                            confirmButtonText="Create bookshelf"
                            onConfirm={handleSubmit(onSubmitCreateBookshelf)}
                        />
                    </ModalBody>
                )}
            </Fade>
        </StyledModal>
    );
};

export default BookshelfModal;
