import { EntityType } from 'components/ContentContext/Enums';
import { CollectionBasicDetail } from 'components/RootProfileCollection/useGetNestedCollections/getCollectionBasicDetails';
import { ClientError } from 'graphql-request';
import gql from 'graphql-tag';
import { useBuildAuthenticatedQueryFn } from 'hooks/useGqlClient/useBuildAuthenticatedQueryFn';
import { Api } from 'lib/ApiConstants';
import { QueryKey, useQuery, UseQueryResult } from 'react-query';

const queryName = 'getParentCollectionsForItem';
export const buildGetParentCollectionsForItemQueryKey = (itemId: string): QueryKey => [queryName, itemId];

export const GET_PARENT_COLLECTIONS_FOR_ITEM_QUERY = gql`
    query ${queryName}($item: EntityInfoInput!) {
        ${queryName}(item: $item) {
            collectionId
            title
            collectionSlug 
        }
    }
`;

export interface GetParentCollectionsForItemResponse {
    getParentCollectionsForItem: CollectionBasicDetail[];
}

export interface useGetParentCollectionsForItemVariables {
    item: {
        type: EntityType;
        id: string;
    };
}

export const useGetParentCollectionsForItem = (
    variables: useGetParentCollectionsForItemVariables,
    enabled = true
): UseQueryResult<GetParentCollectionsForItemResponse, ClientError> => {
    const { queryFn } = useBuildAuthenticatedQueryFn<
        GetParentCollectionsForItemResponse,
        useGetParentCollectionsForItemVariables
    >(GET_PARENT_COLLECTIONS_FOR_ITEM_QUERY, variables, { api: Api.Content });

    return useQuery<GetParentCollectionsForItemResponse, ClientError>(
        buildGetParentCollectionsForItemQueryKey(variables.item.id),
        { queryFn, enabled }
    );
};
