import React, { useState, useEffect, useMemo } from 'react';

import {
    QuestionViewer,
    QuestionComponentValues,
    QuestionType,
    QuestionResultStatus
} from '@adept-at/lib-react-components';
import { useAssessmentContext } from 'components/learn/modes/assessment/AssessmentContext';

import QuestionConsume from './QuestionConsume';

interface QuestionAssessmentProps {
    component: QuestionComponentValues;
}

export const QuestionAssessment: React.FC<QuestionAssessmentProps> = ({ component }) => {
    const [value, setValue] = useState<any>();
    const { setAnswers, results, submitAssessmentLoading } = useAssessmentContext();

    useEffect(() => {
        if (component.questionId) {
            setAnswers((prev) => ({ ...prev, [component.questionId as string]: value }));
        }
    }, [value, component.questionId, component.questionType, setAnswers]);

    const result = useMemo(
        () => results.find((result) => result.questionId === component.questionId),
        [results, component.questionId]
    );

    const questionResultStatus = useMemo(() => {
        if (!result) {
            return QuestionResultStatus.Unanswered;
        }

        return result.isValid ? QuestionResultStatus.Correct : QuestionResultStatus.Incorrect;
    }, [result]);

    if (component.questionType === QuestionType.Poll) {
        return <QuestionConsume component={component} />;
    }

    return (
        <>
            <QuestionViewer.Question.Factory
                componentValues={component}
                answer={value}
                setAnswer={setValue}
                disabled={submitAssessmentLoading || questionResultStatus === QuestionResultStatus.Correct}
            />
            <QuestionViewer.Result status={questionResultStatus} hint={result?.hint} />
        </>
    );
};

export default QuestionAssessment;
