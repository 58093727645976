import { useMemo } from 'react';

import { EntityInfo, CollaboratorPermissionLevel } from 'components/ContentContext/Interfaces';
import { useAvatars } from 'context/AvatarsContext/useAvatars';
import { ClientError } from 'graphql-request';
import { useCurrentUser } from 'hooks/useCurrentUser';
import useGqlClient from 'hooks/useGqlClient';
import { API_CONTENT, API_ACCOUNTS } from 'lib/ApiConstants';
import { useQuery } from 'react-query';

import { ExistingUser } from '../SharedComponents/ExistingUsersList';

import getEntityCollaboratorsInvites, {
    buildGetEntityCollaboratorsInvitesQueryKey,
    GetEntityCollaboratorsInvitesResponse,
    EntityCollaboratorsInvite,
    EntityCollaboratorsStatus
} from './getEntityCollaboratorInvites';
import getEntityCollaborators, {
    buildGetEntityCollaboratorsQueryKey,
    GetEntityCollaboratorsResponse
} from './getEntityCollaborators';

export const useGetEntityCollaborators = ({
    entity,
    roleFilter = []
}: {
    entity: EntityInfo;
    roleFilter: CollaboratorPermissionLevel[];
}): {
    entityCollaborators?: ExistingUser[];
    getEntityCollaboratorsStatus: 'idle' | 'error' | 'loading' | 'success';
    refetch: () => void;
} => {
    const { currentUser } = useCurrentUser();
    const { client: contentClient } = useGqlClient(API_CONTENT);
    const { client: accountClient } = useGqlClient(API_ACCOUNTS);
    const {
        refetch: refetchCollaboratorships,
        status: getEntityCollaboratorsStatus,
        data: getEntityCollaboratorsData
    } = useQuery<GetEntityCollaboratorsResponse, ClientError>({
        queryKey: buildGetEntityCollaboratorsQueryKey({ entity, roleFilter }),
        queryFn: getEntityCollaborators(contentClient, { entityInfo: entity, roleFilter })
    });

    const { refetch: refetchCollaboratorsInvites, data: getEntityCollaboratorsInvitesData } = useQuery<
        GetEntityCollaboratorsInvitesResponse,
        ClientError
    >({
        queryKey: buildGetEntityCollaboratorsInvitesQueryKey({
            entity,
            statuses: [EntityCollaboratorsStatus.Active, EntityCollaboratorsStatus.Expired]
        }),
        queryFn: getEntityCollaboratorsInvites(accountClient, {
            skillId: entity.id,
            statuses: [EntityCollaboratorsStatus.Active, EntityCollaboratorsStatus.Expired]
        })
    });

    const refetch = () => {
        refetchCollaboratorships();
        refetchCollaboratorsInvites();
    };

    const assetIds = useMemo(() => {
        const viewers = getEntityCollaboratorsData?.getEntityCollaborators;
        if (viewers) {
            return viewers?.map((user) => user.image).filter(Boolean);
        }
    }, [getEntityCollaboratorsData?.getEntityCollaborators]);

    const { avatars } = useAvatars({ assetIds: assetIds });

    const mapAndFilterCollaboratorsInvites = (viewersInvites: EntityCollaboratorsInvite[]) => {
        return viewersInvites?.reduce<ExistingUser[]>((prev, invite) => {
            const collaboratorInvite: ExistingUser = {
                userId: null,
                name: invite.email,
                permissionLevel: invite.role ?? CollaboratorPermissionLevel.Viewer,
                avatarSrc: undefined,
                addedAt: invite.sentAt,
                inviteId: invite.id // this is set for pending viewers
            };
            //@TODO when refactoring accounts api to not rely on skillid/ be entity agnostic,
            //add server side filters
            if (roleFilter.includes(invite.role)) prev.push(collaboratorInvite);

            return prev;
        }, []);
    };

    const entityCollaborators = useMemo(() => {
        const collaborators = getEntityCollaboratorsData?.getEntityCollaborators;
        const collaboratorsInvites = getEntityCollaboratorsInvitesData?.getCollaboratorInvitations;
        let entityCollaboratorshipsAndInvites: ExistingUser[] = [];
        if (collaborators) {
            const result = collaborators?.map<ExistingUser>((user) => {
                const existingCollaboratorship: ExistingUser = {
                    userId: user.id,
                    permissionLevel: user.role ?? CollaboratorPermissionLevel.Viewer,
                    name:
                        user.name && (user.name.first || user.name.last)
                            ? [user.name.first, user.name.last].filter(Boolean).join(' ')
                            : user.alias ?? '',
                    avatarSrc: avatars[user.image]?.crop ?? avatars[user.image]?.image ?? undefined,
                    addedAt: user.addedAt
                };

                return existingCollaboratorship;
            });

            entityCollaboratorshipsAndInvites = [...entityCollaboratorshipsAndInvites, ...result];
        }

        if (collaboratorsInvites) {
            const result = mapAndFilterCollaboratorsInvites(collaboratorsInvites);
            entityCollaboratorshipsAndInvites = [...entityCollaboratorshipsAndInvites, ...result];
        }

        // sorting entity collaborators by their (ascending) addedAt date
        const result = entityCollaboratorshipsAndInvites.concat().sort((a, b) => {
            return a.addedAt < b.addedAt ? -1 : a.addedAt > b.addedAt ? 1 : 0;
        });

        return result;
    }, [
        avatars,
        currentUser?.userId,
        getEntityCollaboratorsData?.getEntityCollaborators,
        getEntityCollaboratorsInvitesData?.getCollaboratorInvitations,
        getEntityCollaboratorsInvitesData?.getCollaboratorInvitations?.length
    ]);

    return {
        entityCollaborators,
        getEntityCollaboratorsStatus,
        refetch
    };
};
