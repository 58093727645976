import { IconSize } from '@adept-at/lib-react-components';
import styled from 'styled-components';
import { SUB_HEADER_HEIGHT } from 'styles/variables';

import { modularScale } from '../../utils/modular_scale';

export const CommentBodyContainer = styled.div`
    overflow-wrap: break-word;
`;

export const CommentContainer = styled.div`
    padding: 1rem;
`;

export const Title = styled.div`
    margin-top: 0.5rem;
    vertical-align: middle;
    display: inline-flex;
`;

export const DeletedComment = styled.div`
    color: ${(props) => props.theme.colors.textTertiary};
`;

export const EditedComment = styled.div`
    color: ${(props) => props.theme.colors.textTertiary};
    display: inline-block;
    font-size: ${modularScale(-1)};
`;

export const CommentMeta = styled.div`
    margin: 0;
    display: flex;
    align-items: center;
`;

export const Divider = styled.hr`
    border: 0;
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
`;

export const ThreadHeader = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
    height: ${SUB_HEADER_HEIGHT}px;

    svg {
        width: ${IconSize.Medium};
    }
`;
