import { ImageComponentValues } from '@adept-at/lib-react-components';

import { ImageType } from '../ImageTypeContext';
import { SelectorOptionProps } from '../ImageTypeSelector';

import * as Unsplash from './Unsplash';
import * as Uploaded from './Uploaded';

const typeToModuleMapping = {
    [ImageType.Unsplash]: Unsplash,
    [ImageType.Uploaded]: Uploaded
};

// @TODO tighten up typing
export const getEditForType = (type: ImageType): React.ComponentType<any> => typeToModuleMapping[type].Edit;

export const getSelectorOptionForType = (type: ImageType): React.ComponentType<SelectorOptionProps> =>
    typeToModuleMapping[type].SelectorOption;

/**
 * The data coming from the service does not inform us on what type of image we have in hand. We need to evaluate the
 * properties and, from these, infer the type. This is effectively duck typing.
 *
 * @param values
 */
export const getTypeFromValues = (values: ImageComponentValues): ImageType | null => {
    if (values.imageUrl) {
        return ImageType.Unsplash;
    }

    if (values.image) {
        return ImageType.Uploaded;
    }

    return null;
};
