import * as yup from 'yup';

import { MessageEventType } from '../..';
import AckableAdepteductMessage from '../../../base/AckableAdepteductMessage';
import PossibleEventFields from '../../PossibleEventFields';

export interface EventFieldsSessionPresenterFocusChange extends PossibleEventFields {
    sessionId: string;
    userId: string;
    componentId: string;
}

export default class ChannelMeetingSessionPresenterFocusChange extends AckableAdepteductMessage<EventFieldsSessionPresenterFocusChange> {
    schema = yup.object({
        sessionId: yup.string(),
        userId: yup.string(),
        componentId: yup.string()
    });

    type = MessageEventType.ChannelMeetingSessionPresenterFocusChange;
}
