import { gql, GraphQLClient } from 'graphql-request';
import buildGqlQueryFn from 'hooks/useGqlClient/helpers/buildGqlQueryFn';
import { QueryFunction, QueryKey } from 'react-query';

import { MeetingSession } from './getSessionsForChannel';

export interface GetSessionByIdResponse {
    getMeetingSessionById: MeetingSession;
}

export const buildGetSessionByIdQueryKey = (sessionId?: string): QueryKey => ['getSessionById', sessionId];

const GET_SESSION_BY_ID = gql`
    query getMeetingSessionById($sessionId: UUIDv4!) {
        getMeetingSessionById(sessionId: $sessionId) {
            id
            createdBy
            startTime
            details
        }
    }
`;

const getSessionById = (
    client: GraphQLClient,
    variables: { sessionId: string }
): QueryFunction<GetSessionByIdResponse> =>
    buildGqlQueryFn<GetSessionByIdResponse>(client, GET_SESSION_BY_ID, variables);

export default getSessionById;
