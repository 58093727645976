import React, { useContext } from 'react';

import { VirtualLabViewer } from '@adept-at/lib-react-components';
import { NavContext } from 'components/navigation/NavContext';

import { TemporaryLabValues } from './VirtualLabComponentValues';

export const VirtualLabView: React.FC<{ componentValues: TemporaryLabValues }> = ({ componentValues }) => {
    const { isElectron } = useContext(NavContext);

    //a temporary travesty since vms are json strings in content for now
    componentValues.virtualMachines = JSON.parse(componentValues?.vms ?? '[]');

    return (
        <VirtualLabViewer
            componentValues={componentValues}
            isElectron={isElectron()}
            appDownloadUrl={'https://adept.at'}
        />
    );
};
