import React from 'react';

import styled from 'styled-components';

export const HeaderPlaceholder = styled.div`
    width: 50%;
    height: calc(0.4rem + 24px);

    border-radius: 2px;
    background-color: #e8ecef;

    margin-bottom: 15px;
`;

const StyledTextSection = styled.div`
    /* margin-bottom: 35px; */
`;

export const ButtonPlaceholder = styled.div`
    background-color: #e8ecef;
    height: 25px;
    width: 100px;
    border-radius: 3px;
`;

export const VideoBlock = styled.div`
    width: 100%;
    /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    padding-top: 56.25%;
    background-color: #e8ecef;
`;

export const SingleLine = styled.div`
    width: 100%;
    height: 20px;

    border-radius: 2px;
    background-color: #e8ecef;

    margin-bottom: 5px;
`;

interface TextSectionProps {
    lines?: number;
}

export const TextSection: React.FC<TextSectionProps> = ({ lines = 9 }) => {
    const Lines: any = [];

    for (let i = 0; i < lines; i++) {
        Lines.push(SingleLine);
    }

    return (
        <StyledTextSection>
            {Lines.map((Line, i) => (
                <Line key={i} />
            ))}
        </StyledTextSection>
    );
};
