import { GraphQLClient } from 'graphql-request';
import gql from 'graphql-tag';
import buildGqlQueryFn from 'hooks/useGqlClient/helpers/buildGqlQueryFn';
import AdeptOwnerType from 'lib/AdeptOwnerType';
import { QueryFunction } from 'react-query';

const GET_CHANNELS_FOR_USER_QUERY = gql`
    query getChannelsForUser {
        getChannelsForUser {
            channelId
            name
            type
            owner {
                id
                type
            }
            ownerFilter {
                id
                type
            }
            members
        }
    }
`;

export enum ChannelType {
    OrganizationGroup = 'ORGANIZATION_GROUP',
    OrganizationJobRole = 'ORGANIZATION_JOB_ROLE',
    System = 'SYSTEM',
    Party = 'PARTY',
    Classroom = 'CLASSROOM',
    UserGroup = 'USER_GROUP'
}

export interface ChannelDetails {
    channelId: string;
    name: string;
    type: ChannelType;
    owner: {
        id: string;
        type: AdeptOwnerType;
    };
    ownerFilter: {
        id: string;
        type: AdeptOwnerType;
    };
    members: string[];
    meetings?: unknown[];
}

export interface ChannelsForUserResult {
    getChannelsForUser: ChannelDetails[];
}

const getChannelsForUser = (client: GraphQLClient): QueryFunction<ChannelsForUserResult> =>
    buildGqlQueryFn<ChannelsForUserResult>(client, GET_CHANNELS_FOR_USER_QUERY);

export default getChannelsForUser;
