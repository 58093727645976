import React from 'react';

import { Typography } from '@material-ui/core';
import Modal from 'components/modals';

const DELETE_COLLECTION_TITLE = 'Delete collection';
const DELETE_COLLECTION_CONFIRM_BTN_TEXT = 'Delete collection';
const DELETE_COLLECTION_TEXT = `This will remove all instances of this collection. Any content associated with the collection will not
be deleted. Are you sure you want to proceed?`;

export const DeleteCollectionDialog: React.FC<{ isOpen: boolean; onClose: () => void; onConfirm: () => void }> = ({
    isOpen,
    onClose,
    onConfirm
}) => {
    return (
        <Modal
            modalTitle={DELETE_COLLECTION_TITLE}
            onClose={onClose}
            open={isOpen}
            onConfirm={onConfirm}
            confirmButtonText={DELETE_COLLECTION_CONFIRM_BTN_TEXT}
        >
            <Typography>{DELETE_COLLECTION_TEXT}</Typography>
        </Modal>
    );
};
