import React from 'react';

import { QuestionComponentValues, QuestionType, AnswerOption } from '@adept-at/lib-react-components';
import {
    Radio,
    RadioGroup,
    FormControlLabel,
    Collapse,
    Fade,
    FormControl,
    TextField,
    IconButton,
    FormHelperText
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { AddAnswer } from '../../helpers/AddAnswer';
import { ToggleRandomOrder } from '../../helpers/ToggleRandomOrder';
import { BoxWithPadding } from '../../Question.styles';

interface Props {
    values: QuestionComponentValues;
    onChange: <TComponentFieldValue>(field: string) => (value: TComponentFieldValue) => void;
    onOptionChange: (index: number, value: AnswerOption) => void;
    error: any;
}

export const ChoiceEdit: React.FC<Props> = ({
    values: { questionType, choiceAnswer, answerOptions, hasRandomOrder },
    onChange,
    onOptionChange,
    error
}) => (
    <Collapse timeout={500} in={questionType === QuestionType.Choice}>
        <Fade timeout={800} in={questionType === QuestionType.Choice}>
            <FormControl component="fieldset" error={error['choiceEdit-radio']}>
                <RadioGroup
                    name="choiceAnswer"
                    id="choiceAnswer"
                    value={choiceAnswer}
                    onChange={(e) => onChange('choiceAnswer')(e.target.value)}
                >
                    {answerOptions.map((option, index) => (
                        <BoxWithPadding key={option.key} display="flex" alignItems="center">
                            <FormControlLabel
                                key={`${option.key}-radio`}
                                value={option.key}
                                control={<Radio color="primary" />}
                                label=""
                            />
                            <TextField
                                key={`${option.key}-text`}
                                name="answerOptions"
                                variant="outlined"
                                fullWidth
                                value={option.text}
                                onChange={({ target: { value } }) => {
                                    const updatedContent = { ...option, text: value };
                                    onOptionChange(index, updatedContent);
                                }}
                                error={!!error[`choiceEdit-text-${option.key}`]}
                                helperText={error[`choiceEdit-text-${option.key}`]}
                            />
                            <IconButton
                                key={`${option.key}-icon-button`}
                                onClick={() => {
                                    if (answerOptions.length <= 1) {
                                        return;
                                    }
                                    if (choiceAnswer === answerOptions[index].key) {
                                        onChange('choiceAnswer')('');
                                    }
                                    const draftAnswerOptions = [...answerOptions];
                                    draftAnswerOptions.splice(index, 1);
                                    onChange('answerOptions')(draftAnswerOptions);
                                }}
                            >
                                <DeleteIcon key={`${option.key}-del-icon`} />
                            </IconButton>
                        </BoxWithPadding>
                    ))}
                    <FormHelperText>{error['choiceEdit-radio']}</FormHelperText>
                    <BoxWithPadding display="flex" justifyContent="space-between">
                        <AddAnswer answerOptions={answerOptions} onChange={onChange}>
                            Add Another Answer
                        </AddAnswer>
                        <ToggleRandomOrder hasRandomOrder={hasRandomOrder} onChange={onChange} />
                    </BoxWithPadding>
                </RadioGroup>
            </FormControl>
        </Fade>
    </Collapse>
);

export default ChoiceEdit;
