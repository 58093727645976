import { SortBy } from './BookshelvesContext';
import { BookshelfMinimal } from './hooks/getItemIdsInBookshelves';

interface SortByRecentlyCreatedProps<Type> {
    assignedBookshelfId?: string;
    bookshelves: Type[];
}

export const sortByRecentlyCreated = <Type extends BookshelfMinimal>({
    bookshelves,
    assignedBookshelfId
}: SortByRecentlyCreatedProps<Type>): Type[] => {
    const assignedBookshelf = bookshelves.find((bookshelf) => bookshelf.bookshelfId === assignedBookshelfId);
    const sortableBookshelves = bookshelves.filter((bookshelf) => bookshelf.bookshelfId !== assignedBookshelfId);

    const sortedBookshelves = sortableBookshelves.sort((bookshelfA, bookshelfB) => {
        const dateA = new Date(bookshelfA.createdAt);
        const dateB = new Date(bookshelfB.createdAt);

        return dateB.getTime() - dateA.getTime();
    });

    return assignedBookshelf ? [assignedBookshelf, ...sortedBookshelves] : sortedBookshelves;
};

interface SortByAlphabeticalProps<Type> {
    assignedBookshelfId?: string;
    bookshelves: Type[];
}

export const sortByAlphabetical = <Type extends BookshelfMinimal>({
    bookshelves,
    assignedBookshelfId
}: SortByAlphabeticalProps<Type>): Type[] => {
    const assignedBookshelf = bookshelves.find((bookshelf) => bookshelf.bookshelfId === assignedBookshelfId);
    const sortableBookshelves = bookshelves.filter((bookshelf) => bookshelf.bookshelfId !== assignedBookshelfId);

    const sortedBookshelves = sortableBookshelves.sort((bookshelfA, bookshelfB) => {
        const titleA = bookshelfA.title.toLowerCase();
        const titleB = bookshelfB.title.toLowerCase();

        if (titleA < titleB) {
            return -1;
        }
        if (titleA > titleB) {
            return 1;
        }
        return 0;
    });

    return assignedBookshelf ? [assignedBookshelf, ...sortedBookshelves] : sortedBookshelves;
};

interface SortBookshelvesProps<Type> {
    assignedBookshelfId?: string;
    bookshelves: Type[];
    sortBy: SortBy;
}

export const sortBookshelvesBy = <Type extends BookshelfMinimal>({
    assignedBookshelfId,
    bookshelves,
    sortBy
}: SortBookshelvesProps<Type>): Type[] => {
    switch (sortBy) {
        case SortBy.Recent:
            return sortByRecentlyCreated({ bookshelves, assignedBookshelfId });
        case SortBy.Alphabetical:
            return sortByAlphabetical({ bookshelves, assignedBookshelfId });
        default:
            return bookshelves;
    }
};

export const TEMP_BOOKSHELF_ID = 'temp-bookshelf-id';
