import { useMemo } from 'react';

import { OptimistMutationContext, OptimistRegisterUpdaterOptions, useOptimist } from '@adept-at/lib-react-optimist';
import { CollectionChild } from 'components/RootProfileCollection/context/useGetTenantProfileForOwner';
import gql from 'graphql-tag';
import { getRetryCallback, shouldRetryGraphql } from 'hooks/request/retry';
import { GraphqlError } from 'hooks/useGqlClient/GraphqlError';
import { useBuildAuthenticatedMutationFn } from 'hooks/useGqlClient/useBuildAuthenticatedMutationFn';
import { Api } from 'lib/ApiConstants';
import { useSnackbar } from 'notistack';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { setCollectionEditedInSessionStorage } from 'utils/collection';

import { buildGetEditableCollectionQueryKey, GetEditableCollectionResponse } from './useGetEditableCollection';
import {
    buildGetCollectionBasicDetailsQueryKey,
    GetCollectionBasicDetailsResponse
} from './useGetNestedCollections/getCollectionBasicDetails';

export const upsertCollectionItem = gql`
    mutation upsertCollectionItem($collectionId: UUIDv4!, $item: EntityInfoInput!, $order: Int) {
        upsertCollectionItem(collectionId: $collectionId, item: $item, order: $order) {
            infoVersion
            itemsVersion
        }
    }
`;

export interface UpsertCollectionItemResult {
    upsertCollectionItem: {
        infoVersion: number;
        itemsVersion: number;
    };
}

export interface UpsertCollectionItemVariables {
    collectionId: string;
    item: {
        type: CollectionChild;
        id: string;
    };
    order?: number;
}

const ROOT_COLLECTION_MISMATCH_CODE = 'ROOT_COLLECTION_MISMATCH';
const FATAL_ERROR_CODES = [ROOT_COLLECTION_MISMATCH_CODE];

const useUpsertCollectionItem = (
    parentCollectionId: string,
    refetchParentCollection: () => void,
    collectionIdForEditableCollectionQuery: string,
    parentQuerySlug: string
): UseMutationResult<
    UpsertCollectionItemResult,
    GraphqlError,
    UpsertCollectionItemVariables,
    OptimistMutationContext
> => {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const collectionQueryKey = useMemo(
        () => buildGetEditableCollectionQueryKey(collectionIdForEditableCollectionQuery),
        [collectionIdForEditableCollectionQuery]
    );

    const { registerUpdater: registerUpdateCollectionUpdater, mutationOptions: updateCollectionMetaMutationOptions } =
        useOptimist<UpsertCollectionItemResult, GraphqlError, UpsertCollectionItemVariables>();

    const UpdateCollectionOptions: OptimistRegisterUpdaterOptions<
        UpsertCollectionItemResult,
        GraphqlError,
        UpsertCollectionItemVariables
    > = useMemo(() => {
        return {
            onError: (e: GraphqlError) => {
                if (e.code === ROOT_COLLECTION_MISMATCH_CODE) {
                    enqueueSnackbar('Content can only belong to the catalog or internal training, not both', {
                        variant: 'error'
                    });
                    return;
                }
                enqueueSnackbar('An error occurred while updating content.', { variant: 'error' });
            },
            onSuccess: (data, request) => {
                setCollectionEditedInSessionStorage(request.collectionId, parentQuerySlug);
                // update versions on editableCollection from response to keep in sync w/ what backend is expecting
                queryClient.setQueryData<GetEditableCollectionResponse>(collectionQueryKey, (previous) => {
                    if (!previous) return { getEditableCollection: {} } as GetEditableCollectionResponse;

                    return {
                        getEditableCollection: {
                            ...previous?.getEditableCollection,
                            itemsVersion: data.upsertCollectionItem.itemsVersion,
                            infoVersion: data.upsertCollectionItem.infoVersion
                        }
                    };
                });

                // keep children of parent collection in sync
                refetchParentCollection();
            },
            refetchQuery: false
        };
    }, [enqueueSnackbar, collectionQueryKey, queryClient, refetchParentCollection, parentQuerySlug]);

    registerUpdateCollectionUpdater<GetCollectionBasicDetailsResponse>(
        buildGetCollectionBasicDetailsQueryKey(parentCollectionId),
        (previous, newCollectionDetail) => {
            const previousCollectionChildrenDetails = previous?.getCollectionBasicDetails ?? [];

            if (newCollectionDetail.order !== undefined) {
                // here we are just re-ordering so no need to optimistically add a new child
                return {
                    getCollectionBasicDetails: previousCollectionChildrenDetails
                };
            }

            const updatedCollection = [
                ...previousCollectionChildrenDetails,
                {
                    collectionId: newCollectionDetail.item.id,
                    collectionSlug: '',
                    title: '',
                    images: null,
                    stats: null,
                    visible: false,
                    editableCollectionId: ''
                }
            ];

            return {
                getCollectionBasicDetails: updatedCollection
            };
        },
        UpdateCollectionOptions
    );

    const { mutationFn } = useBuildAuthenticatedMutationFn<UpsertCollectionItemResult, UpsertCollectionItemVariables>(
        upsertCollectionItem,
        { api: Api.Content }
    );

    return useMutation<
        UpsertCollectionItemResult,
        GraphqlError,
        UpsertCollectionItemVariables,
        OptimistMutationContext
    >(mutationFn, {
        ...updateCollectionMetaMutationOptions,
        retry: getRetryCallback(shouldRetryGraphql(FATAL_ERROR_CODES))
    });
};

export default useUpsertCollectionItem;
