// Pixels
export const SKILL_CONTENT_WIDTH = 700;
export const SKILL_CONTAINER_WIDTH = 1200;
export const LAB_CONTENT_WIDTH = 800;
export const COURSE_CONTENT_WIDTH = 700;

export const SIDEBAR_WIDTH = 500;

export const NAV_FULL_WIDTH = 280;
export const NAV_THIN_WIDTH = 65;
export const NAV_WIDE_WIDTH = NAV_FULL_WIDTH - NAV_THIN_WIDTH;
export const CLOSED_NAV_WIDTH = 0;

export const APP_HEADER_HEIGHT = 65;
export const SUB_HEADER_HEIGHT = 48;

export const BREAKPOINT_900PX = 900;
export const BREAKPOINT_600PX = 600;
export const BREAKPOINT_1015PX = 1015;
export const BREAKPOINT_1150PX = 1150;
export const BREAKPOINT_1500PX = 1500;

export const VIEW_WIDTH_20 = '20vw';
export const VIEW_WIDTH_25 = '25vw';
export const VIEW_WIDTH_30 = '30vw';
export const VIEW_WIDTH_45 = '45vw';
export const VIEW_WIDTH_65 = '65vw';

export const FEATURED_IMAGE_HEIGHT_IN_PX = 160;
export const FEATURED_IMAGE_WIDTH_IN_PX = 1200;
export const FEATURED_IMAGE_WIDTH_TO_HEIGHT_RATIO = FEATURED_IMAGE_WIDTH_IN_PX / FEATURED_IMAGE_HEIGHT_IN_PX;
