import React from 'react';

import { ImageComponentValues } from '@adept-at/lib-react-components';

import { CaptionEdit } from '../../CaptionEdit';

import { UnsplashImagePreview } from './Preview';
import { UnsplashSearch } from './Search';

interface EditProps {
    values: ImageComponentValues;
    onChangeMany: (fieldsWithValues: Record<string, any>) => void;
    onChangeAndSave: <TComponentFieldValue>(field: string) => (value: TComponentFieldValue) => void;
}

export const Edit: React.FC<EditProps> = ({ values: { caption, imageUrl = null }, onChangeMany, onChangeAndSave }) => {
    if (!imageUrl) {
        return (
            <UnsplashSearch
                onSelectImage={({
                    urls: { full: imageUrl },
                    user: {
                        name: photographer,
                        links: { html: profile }
                    }
                }) => {
                    onChangeMany({
                        image: null,
                        imageUrl,
                        photographer,
                        profile
                    });
                }}
            />
        );
    }

    return (
        <>
            <UnsplashImagePreview image={imageUrl} />
            <CaptionEdit onSubmit={onChangeAndSave} value={caption} />
        </>
    );
};
