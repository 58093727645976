import React, { useState, useCallback, useMemo } from 'react';

export interface UsePopoverOutput {
    open: boolean;
    anchorEl: HTMLElement | null;
    activeId: string;
    handlePopoverClick: (event: React.MouseEvent<HTMLElement>) => void;
    handlePopoverClose: () => void;
}

export const usePopover = (): UsePopoverOutput => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handlePopoverClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handlePopoverClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const activeId = useMemo(() => anchorEl?.dataset.id ?? '', [anchorEl]);
    const open = useMemo(() => !!anchorEl, [anchorEl]);

    return { open, anchorEl, activeId, handlePopoverClick, handlePopoverClose };
};
