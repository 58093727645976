import React, { useContext } from 'react';

import { defaultMuiTheme, IconSize } from '@adept-at/lib-react-components';
import { Button } from '@material-ui/core';
import { mdiWeatherNight, mdiWeatherSunny } from '@mdi/js';
import { Icon } from '@mdi/react';
import { useThemeContext, ThemeMode } from 'context/ThemeContext';
import styled from 'styled-components';

const StyledButton = styled(Button)`
    display: none;
    ${defaultMuiTheme.breakpoints.up('sm')} {
        display: flex;
    }
    margin-right: 1rem;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    min-width: 40px;
    padding: 0;
    border-color: ${(props) => props.theme.colors.border};
`;

export const DarkModeButton = () => {
    const { themeMode, updateTheme } = useThemeContext();

    const buttonIcon = themeMode === ThemeMode.LIGHT ? mdiWeatherNight : mdiWeatherSunny;

    const handleOnClick = () => {
        if (updateTheme) {
            updateTheme();
        }
    };

    return (
        <StyledButton aria-label="Toggle dark mode" variant="outlined" disableRipple onClick={handleOnClick}>
            <Icon path={buttonIcon} size={IconSize.Medium} />
        </StyledButton>
    );
};
