import React, { memo } from 'react';

import styled, { keyframes } from 'styled-components';

const highlightSize = 0.5;

const itemShine = keyframes`
    to {
        background-position:
            200% 0, /* move highlight to right */
            0 0;
    }
`;

interface ShimmerStyles {
    size: number;
    rows: number;
    spacing: number;
}

const Shine = styled.div<ShimmerStyles>`
    margin: auto;
    width: 100%;
    height: ${(props) => (props.size + props.spacing) * props.rows}px; /* change height to see repeat-y behavior */
    border: 0;

    background-image: linear-gradient(100deg, rgba(255, 255, 255, 0), #f2f5f6 50%, rgba(255, 255, 255, 0) 100%),
        linear-gradient(rgba(105, 105, 105, 0.15) ${(props) => props.size}px, transparent 0);

    background-repeat: repeat-y;

    background-size: ${highlightSize * 100}% 200px, /* highlight */ 100% ${(props) => props.size + props.spacing}px; /* background */

    background-position: ${-(highlightSize * 2) * 100}% 0, /* highlight */ 0 0; /* background */

    animation: ${itemShine} 1s linear infinite;
`;

interface ShimmerProps {
    size?: number;
    rows?: number;
    spacing?: number;
    className?: string;
}

const Shimmer: React.FC<ShimmerProps> = ({ size = 16, rows = 5, spacing = 16, className, ...props }) => {
    return (
        <div className={className} {...props}>
            <Shine size={size} rows={rows} spacing={spacing} />
        </div>
    );
};

export default memo(Shimmer);
