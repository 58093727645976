import { ClientError } from 'graphql-request';
import gql from 'graphql-tag';
import { useBuildAuthenticatedQueryFn } from 'hooks/useGqlClient/useBuildAuthenticatedQueryFn';
import { Api } from 'lib/ApiConstants';
import { ADEPT_USE_PRIMARY_HEADER } from 'lib/constants';
import { QueryKey, useQuery, UseQueryResult } from 'react-query';

import { collectionFragment } from '../fragments';

import { InternalTenantProfileResponse } from './getInternalTenantProfile';
import { getTenantProfileBySlugQueryName } from './useGetTenantProfileBySlug';

export const buildGetInternalProfileBySlugQueryKey = (slug: string): QueryKey => [
    getTenantProfileBySlugQueryName,
    `internal-${slug}`
];

export interface GetInternalTenantProfileBySlugQueryResponse {
    getTenantProfileBySlug: InternalTenantProfileResponse;
}

export const getInternalTenantProfileBySlugQuery = gql`
    query getTenantProfileBySlug($slug: String!) {
        getTenantProfileBySlug(slug: $slug) {
            tenantProfileId
            tenantSlug
            privateRootCollection {
                ${collectionFragment}
            }
            marketplaceStatus
        }
    }
`;

export const useGetInternalTenantProfileBySlug = (
    variables: { slug: string },
    queryKey: QueryKey,
    usePrimary: boolean,
    enabled: boolean
): UseQueryResult<GetInternalTenantProfileBySlugQueryResponse, ClientError> => {
    const { queryFn } = useBuildAuthenticatedQueryFn<GetInternalTenantProfileBySlugQueryResponse, { slug: string }>(
        getInternalTenantProfileBySlugQuery,
        variables,
        {
            api: Api.Content,
            headers: { [ADEPT_USE_PRIMARY_HEADER]: usePrimary }
        }
    );

    return useQuery<GetInternalTenantProfileBySlugQueryResponse, ClientError>(queryKey, {
        queryFn,
        enabled
    });
};
