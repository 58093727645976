import React, { createContext, useCallback, useState } from 'react';

import useAvatarFetching from './useAvatarFetching';
import useAvatarSaving from './useAvatarSaving';

/** The main objects returned from this Context, with varying forms of fetching and returning */
export interface AvatarDetails {
    /** assetId (should probably be renamed as such) */
    id: string;
    /** Optional storage of original.url. Will not be present if result grabbed from localStorage */
    url?: string | null;
    /**
     * Just the base64 result, should probably be renamed to croppedResult to distinguish from API, also the most important piece of data, and the only one required before the result is sent out.
     * Use this as primary, but it may not exist, in which case you should fallback to @see image.
     */
    crop?: string;
    /** This is for temp images (and should probably be renamed as such), or when crop isn't ready yet. */
    image?: string | null;
}

export interface WorkingAvatarDetailsResult {
    avatars: Record<string, AvatarDetails>;
}

export interface AvatarsContextInput {
    initialAvatars?: Record<string, AvatarDetails>;
}

export interface AvatarsContextOutput {
    /** Fetch avatars in one of several ways, prioritized by speed. */
    fetchAvatars: (assetIds: string[]) => void;
    /** Check the results of your fetch */
    getAvatarsFromMap: (assetIds: string[]) => WorkingAvatarDetailsResult;
    /** Set a temporary imageSrc for a given assetId. This will be image on the @see AvatarDetails */
    setTempAvatar: (assetId: string, tempImageSrc?: string | null) => void;
    /** Avatars are being fetched. */
    isLoading: boolean;
}

/** It is unlikely you would want to use this directly, @see useAvatars */
const AvatarsContext = createContext<AvatarsContextOutput>(undefined as unknown as AvatarsContextOutput);

export const AvatarsProvider: React.FC<AvatarsContextInput> = ({ initialAvatars, children }) => {
    const [avatarsMapByAssetId, setAvatarsMapByAssetId] = useState(
        new Map<string, AvatarDetails>(initialAvatars ? Object.entries(initialAvatars) : null)
    );

    const { saveAvatars } = useAvatarSaving({ setAvatarsMapByAssetId });

    const { fetchAvatars, getAvatarsFromMap, isLoading } = useAvatarFetching({
        avatarsMapByAssetId,
        saveAvatars
    });

    const setTempAvatar = useCallback(
        (assetId: string, tempImageSrc?: string | null): boolean => {
            if (tempImageSrc) {
                const currentResult = avatarsMapByAssetId.get(assetId);

                if (currentResult) {
                    setAvatarsMapByAssetId((prevMap) => {
                        const newMap = new Map(prevMap);
                        const newAvatar = { ...currentResult, image: tempImageSrc };
                        newMap.set(assetId, newAvatar);
                        return newMap;
                    });
                    return true;
                }

                setAvatarsMapByAssetId((prevMap) => {
                    const newMap = new Map(prevMap);
                    newMap.set(assetId, { id: assetId, image: tempImageSrc });
                    return newMap;
                });
                return true;
            }
            return false;
        },
        [avatarsMapByAssetId]
    );

    return (
        <AvatarsContext.Provider value={{ fetchAvatars, getAvatarsFromMap, setTempAvatar, isLoading }}>
            {children}
        </AvatarsContext.Provider>
    );
};

export default AvatarsContext;
