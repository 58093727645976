import { GraphQLClient } from 'graphql-request';
import gql from 'graphql-tag';
import buildGqlMutationFn from 'hooks/useGqlClient/helpers/buildGqlMutationFn';
import { MutationFunction } from 'react-query';

import { EditMessageResult, EditMessageVariables } from '.';

export const EDIT_MESSAGE = gql`
    mutation editEntityMessage(
        $entityId: UUIDv4!
        $entityType: MessageEntityType!
        $text: String!
        $messageId: String!
        $hasParent: Boolean
    ) {
        editEntityMessage(
            text: $text
            messageId: $messageId
            entityId: $entityId
            entityType: $entityType
            hasParent: $hasParent
        ) {
            success
        }
    }
`;

const makeEditMessage = (client: GraphQLClient): MutationFunction<EditMessageResult, EditMessageVariables> =>
    buildGqlMutationFn(client, EDIT_MESSAGE);

export default makeEditMessage;
