import React, { useState, useMemo } from 'react';

import { IconSize, TooltippedIconButton } from '@adept-at/lib-react-components';
import { AppBar, Button, Toolbar } from '@material-ui/core';
import { mdiAccountEdit, mdiShare, mdiDelete, mdiFileEye } from '@mdi/js';
import { Icon } from '@mdi/react';
import { TransferManyEntityOwnershipProps } from 'components/modals/Sharing/SharingModal/hooks/useTransferManyEntityOwnership';
import SharingModal, { SharingModalTabs } from 'components/modals/Sharing/SharingModal/SharingModal';
import { QueryKey } from 'react-query';
import styled from 'styled-components';

import { CollaborationTableItem } from '.';

interface SelectedToolbarProps {
    selected: Set<string>;
    setSelected: React.Dispatch<React.SetStateAction<Set<string>>>;
    filteredContent: CollaborationTableItem[];
    //TODO extract into <T> typed context for this table
    //so we dont have to passthrough so many props to the sharing modal
    contentQueryKey?: QueryKey;
    contentTransferOptimistCallback?: <K>(previous: K, transferQueryProps: TransferManyEntityOwnershipProps) => K;
}

const VerticalDivider = styled.div`
    width: 1px;
    height: 1.5rem;
    flex-shrink: 0;
    background-color: ${(props) => props.theme.colors.surface};
    margin: 0 8px;
`;

const StyledAppBar = styled(AppBar)`
    top: auto;
    bottom: 48px;
    width: fit-content;
    transform: translateX(-50%);
    border-radius: 48px;
    color: ${(props) => props.theme.colors.white};
    font-size: 14px;

    button {
        color: ${(props) => props.theme.colors.white};
    }

    svg {
        color: ${(props) => props.theme.colors.white};
    }

    div > * {
        margin: 0 8px;
    }
`;

const SelectedToolbar: React.FC<SelectedToolbarProps> = ({ selected, setSelected, filteredContent, ...rest }) => {
    const [sharingTab, setSharingTab] = useState<SharingModalTabs | undefined>();

    const entities = useMemo(() => {
        const selectedCreations: CollaborationTableItem[] = [];
        filteredContent.forEach((creation) => {
            if (selected.has(creation.id)) {
                selectedCreations.push(creation);
            }
        });
        return selectedCreations;
    }, [selected, filteredContent]);

    const owner = useMemo(() => entities[0]?.owner, [entities]);

    return selected.size > 0 && owner ? (
        <StyledAppBar position="fixed" color="primary">
            <Toolbar>
                <p>{selected.size} selected</p>
                <Button onClick={() => setSelected(new Set([]))}>Deselect</Button>
                <VerticalDivider />
                <Button
                    onClick={() => setSharingTab(SharingModalTabs.Viewers)}
                    startIcon={<Icon size={IconSize.Small} path={mdiFileEye} />}
                >
                    Viewers
                </Button>
                <Button
                    onClick={() => setSharingTab(SharingModalTabs.Collaborators)}
                    startIcon={<Icon size={IconSize.Small} path={mdiAccountEdit} />}
                >
                    Collaborators
                </Button>
                <Button
                    onClick={() => setSharingTab(SharingModalTabs.TransferOwnership)}
                    startIcon={<Icon size={IconSize.Small} path={mdiShare} />}
                >
                    Transfer ownership
                </Button>
                <VerticalDivider />
                <TooltippedIconButton aria-label="Delete" disabled icon={<Icon path={mdiDelete} />} title="Delete" />
            </Toolbar>
            <SharingModal
                entities={entities}
                isOpen={(sharingTab ?? -1) >= 0}
                handleClose={() => {
                    setSelected(new Set());
                    setSharingTab(undefined);
                }}
                owner={owner}
                startingTab={sharingTab}
                {...rest}
            />
        </StyledAppBar>
    ) : null;
};

export default SelectedToolbar;
