import AckableAdepteductMessage from 'lib/communication/message/base/AckableAdepteductMessage';
import { MessageAckType } from 'lib/communication/message/MessageRecord';

import { sortMessagesBySentAtDesc } from '../../../utils/communication';

import { HistoricalMessagesReducerState } from '.';

export interface HistoricalMessageAckParams {
    channelId?: string;
    messageId?: string;
    ackType?: MessageAckType;
}

const handleAckMessage = (
    state: HistoricalMessagesReducerState,
    { channelId, messageId, ackType }: HistoricalMessageAckParams
): HistoricalMessagesReducerState => {
    if (channelId && messageId && ackType) {
        const channelMessages = state?.[channelId];

        if (channelMessages) {
            const workingMessage = channelMessages.find(({ id }) => id === messageId);

            if (workingMessage && ackType && workingMessage instanceof AckableAdepteductMessage) {
                workingMessage.addAck(ackType, new Date().toISOString());

                const filteredStateMessages = channelMessages.filter(({ id }) => id !== messageId);

                return {
                    ...state,
                    [channelId]: [...filteredStateMessages, workingMessage].sort(sortMessagesBySentAtDesc)
                };
            }
        }
    }
    console.warn('Ack Message No-op:', channelId, messageId, ackType);
    return state;
};

export default handleAckMessage;
