import React from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { Switch } from '@material-ui/core';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import Modal from 'components/modals';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import styled from 'styled-components';

import { HeadCellPreference } from './utils';

const DraggableComponent = styled.div`
    display: flex;
    align-items: center;

    svg {
        width: ${IconSize.Medium};
        margin-right: 1rem;
        flex-shrink: 0;
    }
`;

const DraggableContainer = styled.div`
    padding: 12px 0;
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
`;

const StyledSwitch = styled(Switch)`
    margin-left: auto;
`;

const reorder = (list: HeadCellPreference[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

interface CustomizeModalProps {
    open: boolean;
    onClose: () => void;
    headCellPreferences: HeadCellPreference[];
    setHeadCellPreferences: React.Dispatch<React.SetStateAction<HeadCellPreference[]>>;
}

const CustomizeModal: React.FC<CustomizeModalProps> = ({
    open,
    onClose,
    headCellPreferences,
    setHeadCellPreferences
}) => {
    const onDragEnd = (result: DropResult) => {
        const { destination, source } = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        const reordered = reorder(headCellPreferences, source.index, destination.index);

        setHeadCellPreferences(reordered);
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            onConfirm={onClose}
            modalTitle="Customize"
            CancelButton={() => null}
            confirmButtonText="Done"
        >
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="columns">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {headCellPreferences.map((headCell, index) => (
                                <Draggable key={headCell.id} draggableId={headCell.id} index={index}>
                                    {(provided) => (
                                        <DraggableContainer
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            ref={provided.innerRef}
                                        >
                                            <DraggableComponent>
                                                <DragIndicatorIcon /> {headCell.label}
                                                <StyledSwitch
                                                    checked={headCell.checked}
                                                    onChange={(e) =>
                                                        setHeadCellPreferences((prev) =>
                                                            prev.map((preference) => {
                                                                if (preference.id === headCell.id) {
                                                                    return { ...preference, checked: e.target.checked };
                                                                }
                                                                return preference;
                                                            })
                                                        )
                                                    }
                                                    color="primary"
                                                    inputProps={{
                                                        'aria-label': `${headCell.checked ? 'Hide' : 'Show'} ${
                                                            headCell.label
                                                        } column`
                                                    }}
                                                />
                                            </DraggableComponent>
                                        </DraggableContainer>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </Modal>
    );
};

export default CustomizeModal;
