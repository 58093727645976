import * as yup from 'yup';

import { MessageEventType } from '../..';
import AckableAdepteductMessage from '../../../base/AckableAdepteductMessage';
import PossibleEventFields from '../../PossibleEventFields';

export interface EventFieldsSessionLeave extends PossibleEventFields {
    sessionId: string;
    userId: string;
}

export default class ChannelMeetingSessionLeave extends AckableAdepteductMessage<EventFieldsSessionLeave> {
    schema = yup.object({
        sessionId: yup.string(),
        userId: yup.string()
    });

    type = MessageEventType.ChannelMeetingSessionLeave;
}
