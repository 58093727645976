// Too simple to test
/* istanbul ignore file */

export const progressToString = (progressState: number): string => {
    if (progressState === 0) {
        return 'Not started';
    }

    if (progressState > 0 && progressState < 1) {
        return 'In progress';
    }

    return 'Complete';
};

export enum ProgressState {
    NOT_STARTED = 0,
    IN_PROGRESS = 0.1,
    COMPLETE = 1
}
