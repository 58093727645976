import { FormControl, IconButton } from '@material-ui/core';
import styled from 'styled-components';

export const Control = styled(FormControl)`
    margin-bottom: 25px;
`;

export const IconCloseButton = styled(IconButton)`
    float: right;
`;
