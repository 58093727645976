import React, { ReactElement } from 'react';

import { IconSize, LightTheme } from '@adept-at/lib-react-components';
import { Chip, Avatar } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { mdiAlertCircle } from '@mdi/js';
import Icon from '@mdi/react';

import { ContainerClickProps, useContainerClickProps } from '../hooks/useContainerClick';
import { useFormattedUserDetails } from '../hooks/useFormattedUserDetails';

interface OverrideableChipProps {
    onDelete?: () => void;
    className?: string;
    icon?: ReactElement;
}

interface AvatarChipProps {
    avatar?: ReactElement;
    icon?: ReactElement;
}

interface UserChipProps extends OverrideableChipProps, ContainerClickProps {
    userId: string;
    valid?: boolean;
}

export const UserChip: React.FC<UserChipProps> = ({ userId, valid = true, onDelete, className, ...theRest }) => {
    const {
        loading,
        details: { displayName, alias, avatarSrc }
    } = useFormattedUserDetails(userId);

    const containerClickProps = useContainerClickProps(theRest);

    const additionalProps: OverrideableChipProps = {
        onDelete,
        className
    };

    const avatarProps: AvatarChipProps = {
        avatar: loading ? <Skeleton variant="circle" height="20" width="20" /> : <Avatar src={avatarSrc} />
    };

    if (!valid) {
        avatarProps.icon = <Icon path={mdiAlertCircle} color={LightTheme.colors.error} size={IconSize.Medium} />;

        // Need to unset avatar prop if we have an icon as the avatar will take precedence
        avatarProps.avatar = undefined;
    }

    return (
        <Chip
            {...avatarProps}
            label={loading ? <Skeleton width="80px" /> : displayName}
            size="medium"
            aria-label={`user-chip-user-${alias || userId}`}
            aria-busy={loading}
            disabled={loading}
            variant="outlined"
            {...additionalProps}
            {...containerClickProps}
        />
    );
};
