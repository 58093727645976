import React from 'react';

import { VideoComponentValues } from '@adept-at/lib-react-components';
import { useVideoRef } from 'components/learn/videoRefs/useVideoRefs';

import { useComponentEngine } from '../../EngineContext';
import { EditableComponentProvider } from '../../mixins/editor';
import { ComponentEngineMode } from '../../types';

import VideoEdit from './Edit';
import VideoView from './View';

interface VideoComponentProps {
    id: string;
    order: number;
}

export const Video: React.FC<VideoComponentProps & VideoComponentValues> = ({ id, order, ...componentValues }) => {
    const videoRef = useVideoRef();
    const { mode } = useComponentEngine();

    if (mode === ComponentEngineMode.Edit || !componentValues.videoId || !componentValues.playbackUrl) {
        return (
            <EditableComponentProvider initialValues={componentValues}>
                <VideoEdit />
            </EditableComponentProvider>
        );
    }

    return (
        <VideoView
            videoRef={videoRef}
            componentValues={componentValues}
            preload={componentValues.posterImageUrl ? 'none' : 'metadata'}
            muxDataKey={process.env.REACT_APP_MUX_DATA_ENV_KEY ?? ''}
        />
    );
};

export const title = 'Video';
export const Component = Video;
