import { ClientError } from 'graphql-request';
import gql from 'graphql-tag';
import { useBuildAuthenticatedQueryFn } from 'hooks/useGqlClient/useBuildAuthenticatedQueryFn';
import { Api } from 'lib/ApiConstants';
import { ADEPT_USE_PRIMARY_HEADER } from 'lib/constants';
import { QueryKey, useQuery, UseQueryResult } from 'react-query';

import { RootCollectionResponse } from './context/useGetTenantProfileForOwner';
import { collectionFragment } from './fragments';

const queryName = 'getEditableCollection';

export const buildGetEditableCollectionQueryKey = (collectionId: string): QueryKey => [queryName, collectionId];

export const GET_EDITABLE_COLLECTION_QUERY = gql`
    query ${queryName}($collectionId: UUIDv4!) {
        ${queryName}(collectionId: $collectionId) {
            ${collectionFragment}
        }
    }
`;

export interface GetEditableCollectionResponse {
    getEditableCollection: RootCollectionResponse;
}

export interface UseGetEditableCollectionVariables {
    collectionId: string;
}

export const useGetEditableCollection = (
    variables: UseGetEditableCollectionVariables,
    usePrimary: boolean,
    enabled = false
): UseQueryResult<GetEditableCollectionResponse, ClientError> => {
    const { queryFn } = useBuildAuthenticatedQueryFn<GetEditableCollectionResponse, UseGetEditableCollectionVariables>(
        GET_EDITABLE_COLLECTION_QUERY,
        variables,
        { api: Api.Content, headers: { [ADEPT_USE_PRIMARY_HEADER]: usePrimary } }
    );

    return useQuery<GetEditableCollectionResponse, ClientError>(
        buildGetEditableCollectionQueryKey(variables.collectionId),
        {
            queryFn,
            enabled
        }
    );
};
