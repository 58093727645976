import { useState, useRef, useEffect } from 'react';

import { useAssetOwner } from 'hooks/useAssetOwner';
import { DropzoneInputProps, DropzoneRootProps, useDropzone } from 'react-dropzone';

import { useCreateUploadIntent, AssetType } from './useCreateUploadIntent';
import { useUploadFileForIntent } from './useUploadFileForIntent';

const DEFAULT_MAX_FILESIZE = 10485760; // 10MB

export enum FileUploadError {
    InvalidFiles = 'INVALID_FILES',
    CreateUploadIntentFailed = 'CREATE_UPLOAD_INTENT_FAILED',
    UploadFailed = 'UPLOAD_FAILED'
}

interface UseFileUploadParameters {
    onFileSelected?: (file: File) => void;
    onSuccess: (assetId: string | null, previewUrl?: string | null) => void;
    onError: (type: FileUploadError, message: string, data?) => void;
    acceptedFileExtensions?: string[];
    assetType: AssetType;
    attributes?: Record<string, unknown>;
    maxFileSize?: number;
    autoUpload?: boolean;
}

interface UseFileUploadInterface {
    getDropzoneProps: (props?: DropzoneRootProps | undefined) => DropzoneRootProps;
    getInputProps: () => DropzoneInputProps;
    file: string | null;
    setSelectedFile: (file: File) => void;
    loading: boolean;
    uploadProgress: number | null;
    createUploadIntentAndUploadFile: (attributes?: Record<string, unknown>) => void;
}

export const useFileUpload = ({
    onSuccess,
    onError,
    onFileSelected = () => {},
    acceptedFileExtensions,
    assetType,
    maxFileSize = DEFAULT_MAX_FILESIZE,
    autoUpload = true,
    attributes = {}
}: UseFileUploadParameters): UseFileUploadInterface => {
    const [file, setFile] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [uploadProgress, setUploadProgress] = useState<number | null>(null);
    const assetId = useRef<string | null>(null);

    // Used to hold the selected file (from dropzone component) so we can send via the PUT endpoint on the url
    const selectedFile = useRef<File | null>(null);

    const ownerInfo = useAssetOwner();

    const {
        mutate: uploadFileForIntent,
        error: uploadFileForIntentError,
        isSuccess: isUploadFileForIntentSuccess,
        reset: resetUploadFile
    } = useUploadFileForIntent();

    const {
        mutate: createUploadIntent,
        data: createUploadIntentData,
        error: createUploadIntentError,
        status: createUploadIntentStatus,
        reset: resetCreateUploadIntent
    } = useCreateUploadIntent();

    // Handle successful creation of upload intent
    useEffect(() => {
        if (!createUploadIntentData || !selectedFile.current) {
            return;
        }

        const {
            createAssetUploadIntent: { uploadURL, assetId: createdAssetId }
        } = createUploadIntentData;

        assetId.current = createdAssetId;

        uploadFileForIntent({
            uploadUrl: uploadURL,
            file: selectedFile.current,
            onProgress: (percentComplete) => {
                setUploadProgress(percentComplete);
            }
        });
    }, [createUploadIntentData, uploadFileForIntent]);

    // Handle failure in creation of upload intent
    useEffect(() => {
        if (!createUploadIntentError) {
            return;
        }

        console.error(createUploadIntentError);
        setLoading(false);
        onError(
            FileUploadError.CreateUploadIntentFailed,
            'Unable to create upload intent due to unknown error',
            createUploadIntentError
        );
        resetCreateUploadIntent();
    }, [createUploadIntentError, onError, resetCreateUploadIntent]);

    // Handle successful file upload
    useEffect(() => {
        if (!isUploadFileForIntentSuccess) {
            return;
        }

        setUploadProgress(100);
        onSuccess(assetId.current, file);
        setLoading(false);
        resetCreateUploadIntent();
        resetUploadFile();
    }, [isUploadFileForIntentSuccess, file, onSuccess, resetCreateUploadIntent, resetUploadFile]);

    // Handle file upload error
    useEffect(() => {
        if (!uploadFileForIntentError) {
            return;
        }

        setUploadProgress(0);
        setFile(null);
        selectedFile.current = null;
        onError(FileUploadError.UploadFailed, 'Unable to upload file', uploadFileForIntentError);
        resetCreateUploadIntent();
        resetUploadFile();
    }, [uploadFileForIntentError, onError, resetCreateUploadIntent, resetUploadFile]);

    const { getRootProps, getInputProps } = useDropzone({
        onDropAccepted: ([acceptedFile]) => {
            setLoading(true);
            selectedFile.current = acceptedFile;
            onFileSelected(acceptedFile);

            const reader = new FileReader();
            reader.onload = () => setFile(reader.result as string);
            reader.readAsDataURL(acceptedFile);

            if (autoUpload) {
                createUploadIntent({ assetType, attributes, ownerInfo, contentType: acceptedFile.type }, {});
            }
        },
        onDropRejected: (rejectedFiles) => {
            onError(FileUploadError.InvalidFiles, 'Invalid files provided for upload', rejectedFiles);
        },
        accept: acceptedFileExtensions,
        maxSize: maxFileSize,
        disabled: Boolean(loading)
    });

    return {
        file,
        getDropzoneProps: getRootProps,
        getInputProps,
        loading,
        uploadProgress,
        setSelectedFile: (file) => {
            selectedFile.current = file;
        },
        createUploadIntentAndUploadFile: (additionalAttributes: Record<string, unknown> = {}) => {
            setUploadProgress(null);
            // Make sure we haven't already created the upload intent
            if (createUploadIntentStatus === 'idle') {
                createUploadIntent({ assetType, ownerInfo, attributes: { ...attributes, ...additionalAttributes } });
            }
        }
    };
};
