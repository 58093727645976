import { CircularProgress, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const DropzoneWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
`;

export const HelpText = styled(Typography)`
    color: ${(props) => props.theme.colors.textSecondary};
    width: 80%;
    margin-top: 20px;
`;

export const UploadProgressIndicator = styled(CircularProgress)`
    margin: 10px;
`;
