import React, { useCallback, useContext, useEffect, useState } from 'react';

import { mdiHammerScrewdriver, mdiViewDashboard } from '@mdi/js';
import { EditorProvider } from 'components/builder/context/EditorContext';
import { useAddSkillBuilderComponent } from 'components/builder/context/hooks/useAddSkillBuilderComponent';
import useRemoveSkillComponent from 'components/builder/context/hooks/useRemoveSkillComponent';
import useRemoveSkillComponentLocally from 'components/builder/context/hooks/useRemoveSkillComponentLocally';
import { useUpdateSkillMeta } from 'components/builder/context/hooks/useUpdateSkillMeta';
import useUpsertSkillComponent from 'components/builder/context/hooks/useUpsertSkillComponent';
import { SkillMetadata } from 'components/builder/context/interfaces';
import { SectionDescriptionEditorProvider } from 'components/builder/context/SectionDescriptionEditorContext';
import { SortProvider } from 'components/builder/context/SortableContext';
import DeleteModal from 'components/builder/DeleteComponentModal';
import { GuidanceProvider } from 'components/builder/Guidance/GuidanceContext';
import { StoryboardProvider } from 'components/builder/Meta/Storyboard/StoryboardContext';
import ReorderModal from 'components/builder/settings/ReorderModal';
import { EntityType } from 'components/ContentContext/Enums';
import { EngineProvider, ComponentEngineMode } from 'components/engine';
import { ComponentEditorProvider, EditorComponentTitle } from 'components/engine/mixins/editor';
import { ComponentFocusableProvider } from 'components/engine/mixins/focusable';
import { RemoveComponentVariables, UpsertComponentVariables } from 'components/engine/types';
import { SkillIconPath } from 'components/icons/SkillIcon';
import { LearnContext } from 'components/learn/LearnContext';
import { ScrollProvider } from 'components/learn/ScrollContext';
import { HeaderContext } from 'components/navigation/HeaderContext';
import { NavContext } from 'components/navigation/NavContext';
import { SidebarProvider } from 'components/SidebarContext';
import { useHistory, useParams } from 'react-router';

import { ComponentsWrapper } from '../../assessment/Editor';

import { EditorSharedProps } from './index';

export const EditorProviders: React.FC<EditorSharedProps> = ({ editMode, refetchSkill, skillId, skill, children }) => {
    const { openNavbar } = useContext(NavContext);
    const { setBreadcrumbs, setIsSaving } = useContext(HeaderContext);
    const history = useHistory();

    useEffect(() => {
        openNavbar({ isOpen: false });
    }, []);

    const skillMutationProperties = {
        skillId: skillId,
        skillSlug: skill.skillSlug,
        tenantSlug: skill.tenantSlug
    };

    const { mutate: updateMeta, isSuccess: updateMetaSuccess } = useUpdateSkillMeta(skillMutationProperties);
    const { mutate: upsertComponent, isSuccess: upsertComponentSuccess } =
        useUpsertSkillComponent(skillMutationProperties);
    useEffect(() => {
        if (upsertComponentSuccess || updateMetaSuccess) {
            setIsSaving(false);
        }
    }, [setIsSaving, upsertComponentSuccess, updateMetaSuccess]);

    const addComponent = useAddSkillBuilderComponent(skillId);
    const { mutate: removeComponent } = useRemoveSkillComponent(skillMutationProperties);
    const removeComponentLocally = useRemoveSkillComponentLocally(skillId);

    const { tenantSlug } = useParams<{ tenantSlug?: string }>();

    const learnCtx = useContext(LearnContext);
    const hasLearnContext = !!learnCtx;

    useEffect(() => {
        if (tenantSlug) {
            return;
        }

        const learnCrumbs = [
            {
                iconPath: mdiViewDashboard,
                title: 'Dashboard',
                onClick: () => history.push('/dashboard')
            },
            {
                iconPath: SkillIconPath,
                title: skill?.title ?? ''
            }
        ];

        const editCrumbs = [
            {
                iconPath: mdiHammerScrewdriver,
                title: skill?.organizationId ? 'Studio' : 'My creations',
                onClick: () =>
                    history.push(
                        skill?.organizationId ? `/organization/${skill?.organizationId}/studio` : '/my-creations'
                    )
            },
            {
                iconPath: SkillIconPath,
                title: skill?.title ?? ''
            }
        ];

        const crumbs = hasLearnContext ? learnCrumbs : editCrumbs;

        setBreadcrumbs(crumbs);
    }, [hasLearnContext, history, setBreadcrumbs, skill?.organizationId, skill?.title, tenantSlug]);

    const [componentEngineMode, setComponentEngineMode] = useState(ComponentEngineMode.Edit);

    const toggleEditMode = useCallback(() => {
        setComponentEngineMode((prev) =>
            prev === ComponentEngineMode.View ? ComponentEngineMode.Edit : ComponentEngineMode.View
        );
    }, []);

    return (
        <>
            <EngineProvider
                container={{ type: EntityType.SKILL, id: skillId }}
                components={skill?.components ?? {}}
                containerRefetch={refetchSkill}
                titleComponent={EditorComponentTitle}
                mode={componentEngineMode}
            >
                <ComponentFocusableProvider>
                    <ComponentEditorProvider
                        onUpdateMeta={(values: SkillMetadata) => {
                            updateMeta({ ...values, skillId });
                        }}
                        onUpsertComponent={({ entity, ...rest }: UpsertComponentVariables) =>
                            upsertComponent({ skillId: entity.id, ...rest })
                        }
                        onAddComponent={addComponent}
                        onRemoveComponent={({ entity, ...rest }: RemoveComponentVariables) =>
                            removeComponent({ skillId: entity.id, ...rest })
                        }
                        onRemoveComponentFromLocalStateOnly={removeComponentLocally}
                    >
                        <EditorProvider
                            mode={editMode}
                            skill={{ ...skill, id: skillId }}
                            toggleComponentEngineEditMode={toggleEditMode}
                        >
                            <SectionDescriptionEditorProvider>
                                <ComponentsWrapper>
                                    <SortProvider>
                                        <StoryboardProvider>
                                            <SidebarProvider>
                                                <GuidanceProvider>
                                                    <ScrollProvider>{children}</ScrollProvider>
                                                </GuidanceProvider>
                                            </SidebarProvider>
                                        </StoryboardProvider>
                                        <ReorderModal />
                                    </SortProvider>
                                    <DeleteModal />
                                </ComponentsWrapper>
                            </SectionDescriptionEditorProvider>
                        </EditorProvider>
                    </ComponentEditorProvider>
                </ComponentFocusableProvider>
            </EngineProvider>
        </>
    );
};
