import React, { ReactElement } from 'react';

import { Alert } from '@material-ui/lab';
import { ClientError } from 'graphql-request';
import { GraphqlError } from 'hooks/useGqlClient/GraphqlError';
import styled from 'styled-components';

export const ErrorWrapper = styled.div`
    margin-bottom: 25px;
`;

interface MutationErrorProps {
    error?: string | ClientError | GraphqlError | null;
    setError?: (errorIsOpen: boolean) => void;
}

const MutationError = ({ error, setError }: MutationErrorProps): ReactElement | null => {
    if (!error) {
        return null;
    }

    let message = 'There was an error. Please contact Support';
    if (typeof error === 'string') {
        message = error;
    } else {
        const errorMessage = 'response' in error ? error.response?.errors?.[0]?.message : error.message;
        message = errorMessage ?? error.message ?? message;
    }

    return (
        <ErrorWrapper>
            {setError ? (
                <Alert severity="error" onClose={() => setError(false)}>
                    {message}
                </Alert>
            ) : (
                <Alert severity="error">{message}</Alert>
            )}
        </ErrorWrapper>
    );
};

export default MutationError;
