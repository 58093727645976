import { useContext, useEffect, useRef, useState } from 'react';

import customProtocolCheck from 'custom-protocol-check';
import { useAssetOwner } from 'hooks/useAssetOwner';

import { NavContext } from '../../../../../../navigation/NavContext';
import { AssetType, useCreateUploadIntent } from '../../../../PDF/FileUpload';

export enum RecorderLaunchAction {
    Screen = 'screen',
    Webcam = 'webcam',
    ScreenAndWebcam = 'screen_webcam'
}

enum RecordLaunchSource {
    App = 'app',
    Website = 'web'
}

const ADEPT_APP_CUSTOM_PROTOCOL = 'adeptapp';
const ASSET_ID_TO_ACTION_LS_KEY = 'video-upload-action';

interface UseLaunchRecorderInterface {
    launch: (action: RecorderLaunchAction) => void;
    relaunch: (assetId?: string) => void;
    loading: boolean;
    launched: boolean;
    error: null | any;
    assetId: string | null;
}

export const useLaunchRecorder = (): UseLaunchRecorderInterface => {
    const { isElectron } = useContext(NavContext);

    const assetId = useRef<string | null>(null);
    const launchUrl = useRef<string | null>(null);

    const [launchAction, setLaunchAction] = useState<RecorderLaunchAction | null>(null);
    const [launched, setLaunched] = useState<boolean>(false);

    const ownerInfo = useAssetOwner();

    const {
        mutate: createUploadIntent,
        data: createUploadIntentData,
        error: createUploadIntentError,
        isLoading
    } = useCreateUploadIntent();

    const doLaunch = (assetIdOverride?: string) => {
        if (!launchUrl.current) {
            if (!assetId?.current && !assetIdOverride) return;
            if (assetIdOverride) assetId.current = assetIdOverride;

            try {
                const uploadActions = localStorage.getItem(ASSET_ID_TO_ACTION_LS_KEY) || '';
                let foundAction = false;
                (uploadActions.split('###') || []).forEach((assetIdActionEntry) => {
                    const splitAssetIdActionEntry = assetIdActionEntry.split('::');
                    if (assetId.current === splitAssetIdActionEntry[0] && splitAssetIdActionEntry[1]) {
                        foundAction = true;
                        launchUrl.current = splitAssetIdActionEntry[1];
                    }
                });
                if (!foundAction) {
                    alert(
                        'An error has occured trying to relaunch the recorder. Please delete the component and try again.'
                    );
                    return;
                }
            } catch (e) {
                console.error(e);
            }
        }

        if (launchUrl.current) {
            customProtocolCheck(
                launchUrl.current,
                () => {},
                () => {},
                1000
            );
        }
    };

    // Handle successful creation of upload intent
    useEffect(() => {
        if (!createUploadIntentData || !launchAction) {
            return;
        }

        const {
            createAssetUploadIntent: { uploadURL, assetId: createdAssetId }
        } = createUploadIntentData;

        assetId.current = createdAssetId;
        //get assetids by actions from local storage and remove the first entry;
        const uploadActions = localStorage.getItem(ASSET_ID_TO_ACTION_LS_KEY);
        const actions = (uploadActions?.split('###') || []).slice(1, 2);

        // We now have the uploadURL - launch the adept recorder
        const launchData = {
            v: 1,
            uploadURL: uploadURL,
            assetId,
            action: launchAction,
            launchedBy: isElectron() ? RecordLaunchSource.App : RecordLaunchSource.Website
        };

        const protocolData = btoa(JSON.stringify(launchData));

        launchUrl.current = `${ADEPT_APP_CUSTOM_PROTOCOL}://${protocolData}`;
        actions.push(`${assetId.current}::${launchUrl.current}`);
        const newUploadActions = actions.join('###');

        localStorage.setItem(ASSET_ID_TO_ACTION_LS_KEY, newUploadActions);
        /**
         * Use customProtocolCheck to launch the app - but we no longer care if it fails - we'll tell them how to
         * download in the page
         */
        doLaunch();

        setLaunched(true);
    }, [createUploadIntentData, setLaunched, isElectron, launchAction]);

    // Handle failure in creation of upload intent
    useEffect(() => {
        if (!createUploadIntentError) {
            return;
        }

        console.error(createUploadIntentError);
    }, [createUploadIntentError]);

    return {
        launch: (action: RecorderLaunchAction) => {
            setLaunchAction(action);
            createUploadIntent({ assetType: AssetType.Video, ownerInfo });
        },
        relaunch: (assetId?: string) => doLaunch(assetId),
        loading: isLoading,
        error: createUploadIntentError,
        launched,
        assetId: assetId.current
    };
};
