import React, { useContext } from 'react';

import { SidebarContext } from 'components/SidebarContext';

import { SidebarContainer } from './index.styles';

export const SidebarWrapper: React.FC = ({ children }) => {
    const { isOpen, overlay } = useContext(SidebarContext);

    return (
        <SidebarContainer $isOpen={isOpen} $overlay={overlay}>
            {children}
        </SidebarContainer>
    );
};
