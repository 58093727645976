import { ClientError } from 'graphql-request';
import { useQuery, QueryKey, UseQueryResult } from 'react-query';

import useGqlClient from '../../../../../../../../hooks/useGqlClient';
import { API_CONTENT } from '../../../../../../../../lib/ApiConstants';

import { makeGetRecorderDownloadUrl, GetRecorderDownloadUrlQueryResult } from './makeGetDownloadRecorderUrl';

export interface GetAdeptDownloadQueryResponse {
    getAdeptRecorderDownloadURL: string;
}

const buildGetAdeptDownloadURLQueryKey = (userAgent: string): QueryKey => ['getAdeptDownloadQuery', userAgent];

export const useGetDownloadRecorderUrl = (): UseQueryResult<GetRecorderDownloadUrlQueryResult, ClientError> => {
    const { client, withQueryOptions } = useGqlClient(API_CONTENT);

    const recorderQueryKey = buildGetAdeptDownloadURLQueryKey(window.navigator.userAgent);
    return useQuery<GetAdeptDownloadQueryResponse, ClientError>(
        withQueryOptions({
            queryKey: recorderQueryKey,
            queryFn: makeGetRecorderDownloadUrl(client, { userAgent: window.navigator.userAgent })
        })
    );
};
