import React, { ReactElement } from 'react';

import { version } from '../../package.json';

/** For troubleshooting purposes */
const VersionSharer = (): ReactElement => {
    return (
        <div
            data-testid="VersionSharer"
            data-packagejsonversion={version}
            data-adeptversion={process.env.ADEPT_VERSION}
        />
    );
};

export default VersionSharer;
