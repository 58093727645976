import { PresenceStatus } from 'components/communication/UserPresenceCard';
import { GraphQLClient } from 'graphql-request';
import gql from 'graphql-tag';

export interface GetCommunicationUsersDetailsResponse {
    getUsersDetails: CommunicationUserDetails[];
}

export interface CommunicationUserDetails {
    userId: string;
    status: PresenceStatus;
}

const GET_COMMUNICATION_USERS_DETAILS_QUERY = gql`
    query getUsersDetails($userIds: [UUIDv4]!) {
        getUsersDetails(userIds: $userIds) {
            userId
            status
        }
    }
`;

const getCommunicationUsersDetails = async (
    client: GraphQLClient,
    userIds: string[]
): Promise<GetCommunicationUsersDetailsResponse> => {
    return await client.request<GetCommunicationUsersDetailsResponse, { userIds: string[] }>(
        GET_COMMUNICATION_USERS_DETAILS_QUERY,
        { userIds }
    );
};

export default getCommunicationUsersDetails;
