import { OwnerInfo } from 'components/ContentContext/Interfaces';
import { ClientError } from 'graphql-request';
import gql from 'graphql-tag';
import { useBuildAuthenticatedMutationFn } from 'hooks/useGqlClient/useBuildAuthenticatedMutationFn';
import { Api } from 'lib/ApiConstants';
import { useSnackbar } from 'notistack';
import { useMutation, UseMutationResult } from 'react-query';
import { useHistory } from 'react-router';

const CREATE_SKILL = gql`
    mutation createSkillForOwner(
        $ownerInfo: OwnerInfo!
        $title: String!
        $creatorIsAuthor: Boolean
        $collectionIds: [String!]
    ) {
        createSkillForOwner(
            ownerInfo: $ownerInfo
            title: $title
            creatorIsAuthor: $creatorIsAuthor
            collectionIds: $collectionIds
        ) {
            skillId
        }
    }
`;

export interface CreateSkillResponse {
    createSkillForOwner: {
        skillId: string;
    };
}

export interface CreateSkillVariables {
    ownerInfo: OwnerInfo;
    title: string;
    creatorIsAuthor: boolean;
    collectionIds: string[];
}

export const useCreateSkill = (): UseMutationResult<CreateSkillResponse, ClientError, CreateSkillVariables> => {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const { mutationFn } = useBuildAuthenticatedMutationFn<CreateSkillResponse, CreateSkillVariables>(CREATE_SKILL, {
        api: Api.Content
    });

    return useMutation<CreateSkillResponse, ClientError, CreateSkillVariables>(mutationFn, {
        onError: () => {
            enqueueSnackbar('An error occurred while creating your skill.', { variant: 'error' });
        },
        onSuccess: (data) => {
            history.push(`/skillbuilder/edit/${data.createSkillForOwner.skillId}`);
        }
    });
};
