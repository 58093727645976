import React from 'react';

import { format } from 'date-fns';

import { StyledTableCell } from './TableCell.styles';

interface AccessedAtCellProps {
    accessedAt?: string;
}

export const ACCESSED_AT_CELL_WIDTH = 155;

export const AccessedAtCell: React.FC<AccessedAtCellProps> = ({ accessedAt }) => {
    return (
        <StyledTableCell width={ACCESSED_AT_CELL_WIDTH}>
            {accessedAt ? format(new Date(accessedAt), 'MM/dd/yyyy') : null}
        </StyledTableCell>
    );
};
