import { Skill } from '../ContentContext/Interfaces';

export const getWordCount = (blocks) => {
    let count = 0;

    blocks.forEach((block) => {
        const wordsInBlock = block?.text?.split(' ')?.length || 0;

        count += wordsInBlock;
    });

    return count;
};

export const timeFromWordCount = (component) => {
    const wordCount = getWordCount(component?.body?.val?.blocks || []);

    const AVERAGE_NUMBER_OF_WORDS = 250;

    const WORDS_PER_SIXTY_SECONDS = Math.round((AVERAGE_NUMBER_OF_WORDS / 60) * 100) / 100;

    const seconds = Math.floor(wordCount / WORDS_PER_SIXTY_SECONDS) || 0;

    return seconds;
};
