import React from 'react';

import { ShareMenu } from '@adept-at/lib-react-components';
import { Divider } from '@material-ui/core';
import {
    mdiDotsVertical,
    mdiDotsHorizontal,
    mdiCalendarClock,
    mdiHeart,
    mdiCog,
    mdiPencilOff,
    mdiPencil
} from '@mdi/js';
import { Icon } from '@mdi/react';
import { BookshelfPlusIconPath } from 'components/icons/BookshelfPlus';
import { usePopover } from 'hooks/usePopover';
import styled from 'styled-components';
import { StyledMenu } from 'styles/Shared.styles';

import { QuickActionItem } from '../NestedCollection.styles';

import { ActionMenuItem } from './ActionMenuItem';

const InnerMenu = styled.div`
    display: flex;
    flex-direction: column;
`;

const ListItemDivider = styled(Divider)`
    margin: 0.5rem 0;
`;

interface MoreActionsMenuProps {
    canEdit: boolean;
    isEditMode?: boolean;
    isStickyMode?: boolean;
    isFavorited?: boolean;
    toggleEditMode: () => void;
    handleLearnLater: () => void;
    handleAddToBookshelf: () => void;
    handleAddRemoveFavorite: () => void;
}

export const MoreActionsMenu: React.FC<MoreActionsMenuProps> = ({
    canEdit,
    isEditMode,
    isStickyMode,
    isFavorited,
    toggleEditMode,
    handleLearnLater,
    handleAddToBookshelf,
    handleAddRemoveFavorite
}) => {
    const { open, anchorEl, handlePopoverClick, handlePopoverClose } = usePopover();

    const handleToggleEditMode = () => {
        handlePopoverClose();
        toggleEditMode();
    };

    const onLearnLaterClicked = () => {
        handleLearnLater();
        handlePopoverClose();
    };

    const onBookshelfClicked = () => {
        handleAddToBookshelf();
        handlePopoverClose();
    };

    const onFavoriteCollectionClicked = () => {
        handleAddRemoveFavorite();
        handlePopoverClose();
    };

    return (
        <>
            <QuickActionItem
                title="More actions"
                $isStickyMode={isStickyMode}
                icon={<Icon path={isStickyMode ? mdiDotsVertical : mdiDotsHorizontal} />}
                onClick={handlePopoverClick}
            />
            <StyledMenu
                anchorEl={anchorEl}
                open={open}
                onClose={handlePopoverClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <InnerMenu>
                    <ActionMenuItem title="Learn later" icon={mdiCalendarClock} onClick={onLearnLaterClicked} />
                    <ActionMenuItem
                        title="Add to bookshelf"
                        icon={BookshelfPlusIconPath}
                        onClick={onBookshelfClicked}
                    />
                    <ActionMenuItem
                        title={`${isFavorited ? 'Remove from' : 'Add to'} favorites`}
                        icon={mdiHeart}
                        onClick={onFavoriteCollectionClicked}
                    />
                    <ShareMenu closeParent={handlePopoverClose} />
                    <ListItemDivider />
                    <ActionMenuItem title="Manage enrollment" icon={mdiCog} onClick={handlePopoverClose} />
                    {canEdit ? (
                        <ActionMenuItem
                            title={isEditMode ? 'Stop editing' : 'Edit'}
                            icon={isEditMode ? mdiPencilOff : mdiPencil}
                            onClick={handleToggleEditMode}
                        />
                    ) : null}
                </InnerMenu>
            </StyledMenu>
        </>
    );
};
