import React, { ReactElement } from 'react';

import { Grid, Select, FormControl } from '@material-ui/core';
import { CollaboratorPermissionLevel } from 'components/ContentContext/Interfaces';
import { UserChip } from 'components/Users/UserChip';
import { UserSearchForm, useUserSearch } from 'components/UserSearchForm';
import { UserSearchOption, UserSearchOptionType } from 'components/UserSearchForm/types';
import { EMAIL_REGEX } from 'lib/constants';

import { StyledChip } from '../AddViewers/AddViewersSharingForm';
import { SharingFormProps } from '../SharedComponents/AddEntityUserRoleDropIn';
import { COLLABORATOR_PERMISSION_OPTIONS } from '../SharedComponents/permissionOptions';

// TODO in future iterations, allow adding by username/id. update this input text accordingly
export const EMAIL_USERNAME_INPUT_LABEL_TEXT = 'Add by e-mail';
export const PERMISSIONS_SELECT_LABEL_TEXT = 'Permission Level';

const AddCollaboratorsSharingForm = ({
    permissionLevel,
    setPermissionLevel,
    selections,
    updateSelections
}: SharingFormProps): ReactElement => {
    const userSearch = useUserSearch();

    return (
        <Grid container spacing={1} alignItems="flex-start" justify="flex-end" id={'collaborators-sharing-Form'}>
            <Grid item xs={12} md={9}>
                <UserSearchForm
                    {...userSearch}
                    {...{ selections, updateSelections }}
                    optionCreateText={'Invite:'}
                    renderSelected={({ id, type }: UserSearchOption, props) => {
                        const isEmail = EMAIL_REGEX.test(id);
                        if (
                            (type === UserSearchOptionType.UserId || type === UserSearchOptionType.UserEmail) &&
                            !isEmail
                        ) {
                            return <UserChip userId={id} {...props} />;
                        } else if (isEmail) {
                            return (
                                <StyledChip
                                    key={'email' + id}
                                    label={id}
                                    onDelete={props.onDelete}
                                    variant="outlined"
                                />
                            );
                        }
                        return <>Unsupported Type</>;
                    }}
                />
            </Grid>
            <Grid item xs="auto" md={3}>
                <FormControl variant="outlined">
                    <Select
                        id="permission-level-select"
                        native
                        inputProps={{ 'aria-label': PERMISSIONS_SELECT_LABEL_TEXT }}
                        value={permissionLevel}
                        onChange={(event) => setPermissionLevel(event.target.value as CollaboratorPermissionLevel)}
                    >
                        {COLLABORATOR_PERMISSION_OPTIONS.map(({ value, label }) => (
                            <option key={value} value={value}>
                                {label}
                            </option>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default AddCollaboratorsSharingForm;
