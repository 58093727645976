import { GraphQLClient } from 'graphql-request';
import gql from 'graphql-tag';
import { MutationFunction } from 'react-query';

import buildGqlMutationFn from '../../../../../hooks/useGqlClient/helpers/buildGqlMutationFn';

export const REMOVE_SKILL_COMPONENT = gql`
    mutation removeSkillComponent($skillId: UUIDv4!, $componentId: Alphanumeric!) {
        removeSkillComponent(skillId: $skillId, componentId: $componentId) {
            success
        }
    }
`;

export interface RemoveSkillComponentResult {
    success: boolean;
}

export interface RemoveSkillComponentProps {
    skillId: string;
    componentId: string;
}

const makeRemoveSkillComponent = (
    client: GraphQLClient
): MutationFunction<RemoveSkillComponentResult, RemoveSkillComponentProps> =>
    buildGqlMutationFn(client, REMOVE_SKILL_COMPONENT);

export default makeRemoveSkillComponent;
