import { useRef, useEffect, useMemo } from 'react';

import { useCollector } from 'components/devtools/Collector';
import { useMutation, UseMutateAsyncFunction, UseMutateFunction } from 'react-query';

import { retrieveTokenForSession } from './tokenHelpers';

interface UseAuthTokenInterface {
    token: string | null;

    retrieveToken: UseMutateFunction<string, Error, void, unknown>;

    // @TODO This is used by signup form only. Not sure that's what we want to do there
    retrieveTokenAsync: UseMutateAsyncFunction<string, Error, void, unknown>;

    status: AuthTokenStatus;
    reset: () => void;
}

export enum AuthTokenStatus {
    Initial = 'Initial', // Unknown
    Loading = 'Loading',
    Error = 'Error',
    Ready = 'Ready',
    None = 'None' // Terminal?
}

export const useAuthToken = (): UseAuthTokenInterface => {
    const previousStatus = useRef<AuthTokenStatus>(AuthTokenStatus.Initial);
    const { addHistory } = useCollector();
    const {
        mutate: retrieveToken,
        mutateAsync: retrieveTokenAsync,
        data: token = null,
        status: retrieveTokenStatus,
        reset: resetRetrieveToken
    } = useMutation<string, Error>(retrieveTokenForSession);

    const status = useMemo(() => {
        const determineNewStatus = () => {
            // @TODO SAS differentiate between error and expired/invalid
            if (retrieveTokenStatus === 'error') {
                return AuthTokenStatus.Error;
            }

            if (retrieveTokenStatus === 'loading') {
                return AuthTokenStatus.Loading;
            }

            if (retrieveTokenStatus === 'success') {
                return AuthTokenStatus.Ready;
            }

            if (retrieveTokenStatus === 'idle') {
                return AuthTokenStatus.Initial;
            }

            return previousStatus.current;
        };

        previousStatus.current = determineNewStatus();

        return previousStatus.current;
    }, [retrieveTokenStatus]);

    useEffect(() => {
        addHistory('authTokenStatus', status);
    }, [status, addHistory]);

    return {
        token,
        retrieveToken,
        retrieveTokenAsync,
        status,
        reset: resetRetrieveToken
    };
};
