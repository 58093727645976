import styled from 'styled-components';

export const TextEditForm = styled.div`
    fieldset {
        display: block;
        label {
            position: relative;
            transform: none;
            padding-bottom: 5px;
        }
    }
`;
