import React, { useContext, useMemo } from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { Button, Tooltip } from '@material-ui/core';
import { mdiInformationOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { SectionDescriptionEditorContext } from 'components/builder/context/SectionDescriptionEditorContext';
import { useModal } from 'hooks/useModal';
import styled from 'styled-components';

import { SkillSection } from '../../utils/skill/sections';

import { SectionDescriptionModal } from './SectionDescriptionModal';

interface SectionDescriptionProps {
    section: SkillSection;
}

const DESCRIPTION_TOOLTIP =
    'Descriptions appear in the skill outline for users who do not have access to the full skill.';

const SectionDescriptionWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-left: -4px;
`;

const StyledIcon = styled(Icon)`
    margin-left: 0.5rem;
    color: ${(props) => props.theme.colors.textTertiary};
`;

export const SectionDescription: React.FC<SectionDescriptionProps> = ({ section }) => {
    const { handleModalOpen, handleModalClose, modalOpen } = useModal();
    const {
        setBlockKey,
        isEditMode,
        setDescription,
        pendingDescriptions = [],
        setPendingDescriptions
    } = useContext(SectionDescriptionEditorContext) ?? {};

    const isSaving = useMemo(() => pendingDescriptions.includes(section.id), [section.id, pendingDescriptions]);

    if (!isEditMode) {
        return null;
    }

    return (
        <SectionDescriptionWrapper>
            <Button size="small" onClick={handleModalOpen} disabled={isSaving}>
                {isSaving ? 'Saving...' : section.description ? 'Edit description' : 'Add description'}
            </Button>
            <Tooltip placement="bottom" title={DESCRIPTION_TOOLTIP}>
                <StyledIcon size={IconSize.Small} path={mdiInformationOutline} />
            </Tooltip>
            <SectionDescriptionModal
                open={modalOpen}
                onClose={handleModalClose}
                title={section.title}
                description={section.description}
                onConfirm={(description) => {
                    setBlockKey(section.id);
                    setDescription(description);
                    setPendingDescriptions((prev) => [...prev, section.id]);
                }}
            />
        </SectionDescriptionWrapper>
    );
};
