export enum ComponentFocus {
    AddAndEdit = 'editor.addAndEdit',
    Edit = 'editor.edit',
    Remove = 'editor.remove'
}

export enum SaveSkillType {
    componentUpsert = 'componentUpsert',
    componentOrder = 'componentOrder',
    componentDelete = 'componentDelete',
    meta = 'meta'
}
