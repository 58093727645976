import { Images } from 'components/ContentContext/Interfaces';
import { GraphQLClient } from 'graphql-request';
import gql from 'graphql-tag';
import buildGqlQueryFn from 'hooks/useGqlClient/helpers/buildGqlQueryFn';
import { QueryFunction, QueryKey } from 'react-query';

export const buildGetCollectionDetailsQueryKey = (collectionIds: string[]): QueryKey => [
    'getCollectionDetails',
    collectionIds
];

export interface CollectionDetails {
    collectionId: string;
    collectionSlug: string;
    title: string;
    createdBy: string;
    images: Images;
}

export interface GetCollectionDetailsResponse {
    getCollectionBasicDetails: CollectionDetails[];
}

export const getCollectionDetailsQuery = gql`
    query getCollectionBasicDetails($collectionIds: [UUIDv4!]) {
        getCollectionBasicDetails(collectionIds: $collectionIds) {
            collectionId
            collectionSlug
            title
            createdBy
            images {
                catalog {
                    original {
                        url
                    }
                    id
                    crop
                    isProcessed
                    processed @sizes(widths: [208]) {
                        url
                    }
                }
            }
        }
    }
`;

const getCollectionDetails = (
    client: GraphQLClient,
    collectionIds: string[]
): QueryFunction<GetCollectionDetailsResponse> => buildGqlQueryFn(client, getCollectionDetailsQuery, { collectionIds });

export default getCollectionDetails;
