import React, { useContext, useMemo } from 'react';

import { CircularProgress } from '@material-ui/core';
import { OwnerType } from 'components/ContentContext/Interfaces';
import RootProfileCollection from 'components/RootProfileCollection';
import { RootCollectionProvider } from 'components/RootProfileCollection/context/RootCollectionContext';
import { TenantProfileDetails } from 'components/RootProfileCollection/context/useGetTenantProfile';
import {
    buildGetProfileForOwnerQueryKey,
    useGetTenantProfileForOwner
} from 'components/RootProfileCollection/context/useGetTenantProfileForOwner';
import { ProfileContext, ProfileType } from 'context/ProfileContext';
import AppLayout from 'layouts/AppLayout';
import { getEditedCollectionsFromSessionStorage } from 'utils/collection';

const MyProfilePage: React.FC = () => {
    const { currentProfile, selectProfile, availableProfiles } = useContext(ProfileContext);

    const matchingProfile = useMemo(() => {
        const userProfile = availableProfiles?.find((profile) => profile.type === ProfileType.Personal);

        if (userProfile) {
            selectProfile(userProfile);
            return userProfile;
        }
    }, [availableProfiles, selectProfile]);

    const usePrimary = useMemo(() => {
        if (!matchingProfile) return false;
        const editedCollections = getEditedCollectionsFromSessionStorage();
        return editedCollections.includes(`${matchingProfile?.id}`);
    }, [matchingProfile]);

    const { data, isLoading } = useGetTenantProfileForOwner(
        { owner: { type: OwnerType.User, id: matchingProfile?.id ?? '' } },
        { enabled: !!matchingProfile?.id },
        usePrimary
    );
    const { refetch } = useGetTenantProfileForOwner(
        { owner: { type: OwnerType.User, id: matchingProfile?.id ?? '' } },
        { enabled: false },
        true
    );

    const formattedData = useMemo(() => {
        if (!data) return data;

        const { rootCollection: relevantCollection, ...rest } = data.getTenantProfileForOwner;
        const formatted: TenantProfileDetails = {
            relevantCollection,
            ...rest
        };
        return formatted;
    }, [data]);

    if (!currentProfile || currentProfile.type !== ProfileType.Personal) {
        return (
            <AppLayout withoutPadding={true} fullHeight>
                <CircularProgress />
            </AppLayout>
        );
    }

    return (
        <RootCollectionProvider
            ownerDetails={{ type: OwnerType.User, id: currentProfile.id }}
            isInternal={false}
            tenantDetailsIsLoading={isLoading}
            queryKey={buildGetProfileForOwnerQueryKey(currentProfile.id)}
            tenantProfileDetails={formattedData}
            refetch={refetch}
        >
            <AppLayout withoutPadding fullHeight mainContentOverflow>
                <RootProfileCollection />
            </AppLayout>
        </RootCollectionProvider>
    );
};

export default MyProfilePage;
