import React, { useCallback, useContext, useMemo } from 'react';

import { defaultMuiTheme } from '@adept-at/lib-react-components';
import { Avatar } from '@material-ui/core';
import OrgImage from 'components/navigation/OrgImage';
import { useAvatars } from 'context/AvatarsContext/useAvatars';
import { ProfileContext, ProfileType } from 'context/ProfileContext';
import { useCurrentUser } from 'hooks/useCurrentUser';
import styled from 'styled-components';
import { BREAKPOINT_600PX } from 'styles/variables';

const Container = styled.div`
    ${defaultMuiTheme.breakpoints.down(BREAKPOINT_600PX)} {
        display: none;
    }
`;

const StyleAvatar = styled(Avatar)<{ $size?: string }>`
    width: ${(props) => (props.$size ? props.$size : '125px')};
    height: ${(props) => (props.$size ? props.$size : '125px')};
`;

const CollectionAvatar: React.FC<{ size?: string; placeholderFontSize?: string; borderRadius?: string }> = ({
    size,
    placeholderFontSize,
    borderRadius
}) => {
    const locationPath = window.location.pathname;

    //  my profile image
    const { currentUser } = useCurrentUser();
    const { avatars } = useAvatars({
        assetIds: currentUser?.image ? [currentUser?.image] : []
    });

    const getUserAvatarImage = useCallback(() => {
        if (!(currentUser?.image && avatars[currentUser?.image])) {
            return;
        }

        return avatars[currentUser.image].crop ?? avatars[currentUser.image].image ?? undefined;
    }, [avatars, currentUser?.image]);

    // org image
    const { currentProfile, status } = useContext(ProfileContext);
    const orgImageData = useMemo(() => {
        if (status === 'loading' || !currentProfile) return;

        if (currentProfile.type === ProfileType.Organization) {
            const { label, imageId } = currentProfile;

            return { label, imageId };
        }

        return;
    }, [currentProfile, status]);

    if (orgImageData) {
        return (
            <Container>
                <OrgImage
                    label={orgImageData?.label ?? ''}
                    imageId={orgImageData?.imageId}
                    size={size}
                    placeholderFontSize={placeholderFontSize}
                    borderRadius={borderRadius}
                />
            </Container>
        );
    }

    if (locationPath.includes('my-profile')) {
        return (
            <Container>
                <StyleAvatar $size={size} src={getUserAvatarImage()} />
            </Container>
        );
    }

    // TODO: when we add ability to follow users we need way to show the correct avatar image for the user here when we look at their profile.
    // (maybe there will be a user id in the path we can reference..?)
    return null;
};

export default CollectionAvatar;
