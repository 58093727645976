import { useEffect, useMemo } from 'react';

import { CollaborativeSkill } from 'components/CollaborationTable';
import getCollaborationsByOwner, {
    buildGetCollaborationsByOwnerQueryKey,
    GetCollaborationsByOwnerResponse
} from 'components/CollaborationTable/getCollaborationsByOwner';
import { OwnerType, Role } from 'components/ContentContext/Interfaces';
import { ClientError } from 'graphql-request';
import useGqlClient from 'hooks/useGqlClient';
import { API_CONTENT } from 'lib/ApiConstants';
import { QueryStatus, useInfiniteQuery } from 'react-query';

interface CollaborationSkill {
    item: CollaborativeSkill;
    role: Role;
}

interface UseCollaborationSkillsOutput {
    collaborationSkills: CollaborationSkill[];
    status: QueryStatus;
}

export const useCollaborationSkills = (orgId = ''): UseCollaborationSkillsOutput => {
    const ownerInfo = useMemo(() => ({ type: OwnerType.Organization, id: orgId }), [orgId]);
    const getCollaborationSkillsQueryKey = useMemo(() => buildGetCollaborationsByOwnerQueryKey(ownerInfo), [ownerInfo]);
    const { client, withInfiniteQueryOptions } = useGqlClient(API_CONTENT);

    const { data, status, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery<
        GetCollaborationsByOwnerResponse,
        ClientError
    >(
        withInfiniteQueryOptions({
            enabled: !!orgId,
            queryKey: getCollaborationSkillsQueryKey,
            queryFn: getCollaborationsByOwner(client, { ownerInfo }),
            getNextPageParam: (res) => res?.getCollaborationsByOwner?.cursor ?? undefined
        })
    );

    useEffect(() => {
        if (hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

    const collaborationSkills = useMemo(
        () =>
            (data?.pages
                ?.map((page) =>
                    page?.getCollaborationsByOwner?.items?.filter((item) => !!item?.item && 'skillId' in item.item)
                )
                .flat()
                .filter(Boolean) as CollaborationSkill[]) ?? [],
        [data?.pages]
    );

    return {
        collaborationSkills,
        status
    };
};
