import { DrawEvent } from 'components/rooms/DrawableCanvas';
import * as yup from 'yup';

import { MessageEventType } from '../..';
import AckableAdepteductMessage from '../../../base/AckableAdepteductMessage';
import PossibleEventFields from '../../PossibleEventFields';

export interface EventFieldsSessionDrawing extends PossibleEventFields {
    sessionId: string;
    userId: string;
    drawEvents: DrawEvent[];
}

export default class ChannelMeetingSessionDrawing extends AckableAdepteductMessage<EventFieldsSessionDrawing> {
    schema = yup.object({
        sessionId: yup.string(),
        userId: yup.string(),
        drawEvents: yup.array()
    });

    type = MessageEventType.ChannelMeetingSessionDrawing;
}
