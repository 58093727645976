import React, { createContext, useContext } from 'react';

import { VideoComponentValues } from '@adept-at/lib-react-components';
import { useEditableComponent } from 'components/engine/mixins/editor';
import { ClientError } from 'graphql-request';
import { QueryObserverResult, RefetchOptions } from 'react-query';

import useGetPlaybackDetails from '../Editor/hooks/useGetPlaybackDetails';
import { GetVideoPlaybackDetailsResult } from '../Editor/hooks/useGetPlaybackDetails/makeGetPlaybackDetails';

interface PlaybackDetailsContextInterface {
    playbackUrl: string | null;
    posterImageUrl: string | null;
    refetch: (
        options?: RefetchOptions | undefined
    ) => Promise<QueryObserverResult<GetVideoPlaybackDetailsResult, ClientError>>;
}

const PlaybackDetailsContext = createContext({} as PlaybackDetailsContextInterface);

interface PlaybackDetailsProviderProps {
    videoId: string;
}

export const PlaybackDetailsProvider: React.FC<PlaybackDetailsProviderProps> = ({ children, videoId }) => {
    const {
        currentValues: { playbackUrl, posterImageUrl = null }
    } = useEditableComponent<VideoComponentValues>();
    const { data: playbackDetails, refetch } = useGetPlaybackDetails(videoId, !playbackUrl);

    const { playbackUrl: fetchedPlaybackUrl = null, posterImageUrl: fetchedPosterImageUrl = null } =
        playbackDetails?.getVideoPlaybackDetails ?? {};

    return (
        <PlaybackDetailsContext.Provider
            value={{
                playbackUrl: fetchedPlaybackUrl ?? playbackUrl,
                posterImageUrl: fetchedPosterImageUrl ?? posterImageUrl,
                refetch
            }}
        >
            {children}
        </PlaybackDetailsContext.Provider>
    );
};

export const usePlaybackDetails = (): PlaybackDetailsContextInterface => {
    const context = useContext(PlaybackDetailsContext);

    if (!context) {
        throw new Error('usePlaybackDetails must be used within a PlaybackDetailsProvider');
    }

    return context;
};
