import { IconSize } from '@adept-at/lib-react-components';
import { Button } from '@material-ui/core';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
    background-color: ${(props) => props.theme.colors.surface};
    svg {
        width: ${IconSize.Small};
        margin-right: 4px;
    }

    &:hover {
        background-color: #f7fbfd !important;
    }
`;
