import { IconSize } from '@adept-at/lib-react-components';
import { IconButton, Modal } from '@material-ui/core';
import Icon from '@mdi/react';
import styled from 'styled-components';

import { modularScale } from '../../utils/modular_scale';

export const StyledModal = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 1.5rem;
`;

export const Subtitle = styled.h3`
    font-weight: 400;
    font-size: ${modularScale(0)};
    margin-bottom: 2rem;
    color: ${(props) => props.theme.colors.textSecondary};

    span {
        text-transform: uppercase;
        letter-spacing: 1px;
    }
`;

export const Badge = styled.span`
    background-color: ${(props) => props.theme.colors.textTertiary};
    font-size: ${modularScale(-1)};
    color: ${(props) => props.theme.colors.white};
    text-transform: uppercase;
    border-radius: 4px;
    padding: 3px 5px;
    letter-spacing: 1px;
    margin-right: 0.5rem;
`;

export const Title = styled.h2`
    font-weight: 400;
    font-size: ${modularScale(1)};
    margin-bottom: 1rem;
`;

export const ModalBody = styled.div<{ $maxWidth?: string; $minHeight?: string }>`
    background: ${(props) => props.theme.colors.surface};
    width: 100%;
    max-width: ${(props) => (props.$maxWidth ? props.$maxWidth : '500px')};
    min-height: ${(props) => (props.$minHeight ? props.$minHeight : 'inherit')};
    max-height: 90vh;
    overflow: auto;
    outline: none;
    padding: 1.5rem;
    border-radius: 4px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
`;

export const Buttons = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 2rem;

    button {
        margin-left: 1rem;
    }
`;

// can be used with a Modal title and a close button
export const ModalHeader = styled.div`
    display: flex;
    margin-bottom: 1rem;
    align-items: center;

    & button {
        margin-left: auto;
    }

    & h2 {
        margin-bottom: 0;
        font-weight: 500;
    }
`;

export const StyledCloseIcon = styled(IconButton)`
    & svg {
        width: ${IconSize.Medium};
        color: ${(props) => props.theme.colors.textSecondary};
    }
`;

export const TitleIcon = styled(Icon)`
    margin-right: 1rem;
`;
