import React from 'react';

import { mdiCart, mdiHome } from '@mdi/js';
import Icon from '@mdi/react';
import { useSelectPersonalProfile } from 'context/ProfileContext/useSelectPersonalProfile';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ignoreFeatureFlags } from 'lib/constants';

import { Divider, ThinSidebar } from './Nav.styles';
import { Tab } from './NavContext';
import NavTab from './NavTab';
import OrgSelector from './OrgSelector';

const NavTabSelector: React.FC<{ backgroundColor?: string | null; isOpen?: boolean }> = ({
    backgroundColor,
    isOpen
}) => {
    const { marketplace: marketplaceLDFlag } = useFlags();

    const { selectPersonalProfile } = useSelectPersonalProfile();
    const { authChecks } = useCurrentUser();

    return (
        <ThinSidebar $isOpen={isOpen} $backgroundColor={backgroundColor}>
            {/* Clicking on the Home icon should always take us back to the personal profile */}
            <NavTab tabName={Tab.Home} onClick={selectPersonalProfile}>
                <Icon path={mdiHome} />
            </NavTab>
            {/*<NavTab tabName={Tab.Subscriptions}>*/}
            {/*    <Icon path={mdiCheckCircle} />*/}
            {/*</NavTab>*/}
            <Divider />
            <OrgSelector canCreateOrg={authChecks?.canCreateOrg ?? false} />
            {ignoreFeatureFlags || marketplaceLDFlag ? (
                <>
                    <Divider />
                    <NavTab tabName={Tab.Marketplace}>
                        <Icon path={mdiCart} />
                    </NavTab>
                </>
            ) : null}
            {/*<NavTab tabName={Tab.Test}>*/}
            {/*    <Icon path={mdiTestTube} />*/}
            {/*</NavTab>*/}
        </ThinSidebar>
    );
};

export default NavTabSelector;
