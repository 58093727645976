import { ClientError } from 'graphql-request';
import gql from 'graphql-tag';
import { useBuildAuthenticatedMutationFn } from 'hooks/useGqlClient/useBuildAuthenticatedMutationFn';
import { Api } from 'lib/ApiConstants';
import { useMutation, UseMutationResult } from 'react-query';

const UPSERT_LAST_COMPLETED_BLOCK_EY = gql`
    mutation upsertLastCompletedBlockKey($skillId: UUIDv4!, $lastCompletedBlockKey: String!) {
        upsertLastCompletedBlockKey(skillId: $skillId, lastCompletedBlockKey: $lastCompletedBlockKey) {
            success
        }
    }
`;

interface UpsertLastCompletedBlockKeyResponse {
    upsertLastCompletedBlockKey: {
        success: boolean;
    };
}

interface UpsertLastCompletedBlockKeyVariables {
    skillId: string;
    lastCompletedBlockKey: string;
}

export const useUpsertLastCompletedBlockKey = (): UseMutationResult<
    UpsertLastCompletedBlockKeyResponse,
    ClientError,
    UpsertLastCompletedBlockKeyVariables
> => {
    const { mutationFn } = useBuildAuthenticatedMutationFn<
        UpsertLastCompletedBlockKeyResponse,
        UpsertLastCompletedBlockKeyVariables
    >(UPSERT_LAST_COMPLETED_BLOCK_EY, {
        api: Api.Content
    });

    return useMutation<UpsertLastCompletedBlockKeyResponse, ClientError, UpsertLastCompletedBlockKeyVariables>(
        mutationFn
    );
};
