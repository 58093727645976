import React, { FC, createContext, useMemo } from 'react';

import { useGetGroupsForUser, GroupForUser } from './useGetGroupsForUser';

export interface GroupsProviderInterface {
    isLoading: boolean;
    groups: GroupForUser[];
}

const GroupsContext = createContext({} as GroupsProviderInterface);

const { Provider } = GroupsContext;

// @TODO why does this need to be a context? If we make a hook, then react-query will provide it for us
const GroupsProvider: FC = ({ children }) => {
    const { isLoading, data } = useGetGroupsForUser();

    const groups = useMemo(() => data?.getGroupsForUser ?? [], [data]);

    return (
        <Provider
            value={{
                isLoading,
                groups
            }}
        >
            {children}
        </Provider>
    );
};

export { GroupsContext, GroupsProvider };
