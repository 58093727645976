/**
 * Create GraphQL Queries to interact with the CRUD Affiliation Interface in Accounts API.
 * NOTE: Is there a better place for these?
 */

import { useMemo } from 'react';

import { ClientError, GraphQLClient } from 'graphql-request';
import gql from 'graphql-tag';
import useGqlClient from 'hooks/useGqlClient';
import buildGqlMutationFn from 'hooks/useGqlClient/helpers/buildGqlMutationFn';
import { Api } from 'lib/ApiConstants';
import { useSnackbar } from 'notistack';
import { MutationFunction, useMutation, UseMutationResult } from 'react-query';

export interface CreateAffiliationVariables {
    affiliationId: string;
}

export interface CreateAffiliationVoidResponse {
    success: boolean;
}

const CREATE_AFFILIATION_FOR_USER_QUERY = gql`
    mutation createAffiliationForUser($affiliationId: String) {
        createAffiliationForUser(affiliationId: $affiliationId) {
            success
        }
    }
`;

const makeCreateAffiliationMutation = (
    client: GraphQLClient
): MutationFunction<CreateAffiliationVoidResponse, CreateAffiliationVariables> =>
    buildGqlMutationFn(client, CREATE_AFFILIATION_FOR_USER_QUERY);

const useCreateAffiliationMutation = (): UseMutationResult<
    CreateAffiliationVoidResponse,
    ClientError,
    CreateAffiliationVariables
> => {
    const { client, withMutationOptions } = useGqlClient(Api.Accounts);
    const { enqueueSnackbar } = useSnackbar();

    const createAffiliationOptions = useMemo(() => {
        return {
            onError: () => {
                enqueueSnackbar('Unable to subscribe to Skill Owner.');
            },
            onSuccess: () => {
                enqueueSnackbar('Subscribed to Skill Owner.');
            }
        };
    }, [enqueueSnackbar]);

    return useMutation<CreateAffiliationVoidResponse, ClientError, CreateAffiliationVariables>(
        makeCreateAffiliationMutation(client),
        withMutationOptions(createAffiliationOptions)
    );
};

export default useCreateAffiliationMutation;
