import React from 'react';

import { QuestionComponentValues, QuestionType } from '@adept-at/lib-react-components';
import { Radio, RadioGroup, FormControlLabel, Collapse, Fade, FormControl } from '@material-ui/core';

interface Props {
    values: QuestionComponentValues;
    onChange: <TComponentFieldValue>(field: string) => (value: TComponentFieldValue) => void;
}

export const BooleanEdit: React.FC<Props> = ({ values: { questionType, booleanAnswer }, onChange }) => {
    return (
        <Collapse timeout={500} in={questionType === QuestionType.Boolean}>
            <Fade timeout={800} in={questionType === QuestionType.Boolean}>
                <FormControl component="fieldset">
                    <RadioGroup
                        name="booleanAnswer"
                        id="booleanAnswer"
                        value={booleanAnswer}
                        onChange={(e) => onChange('booleanAnswer')(JSON.parse(e.target.value))}
                    >
                        <FormControlLabel value={true} control={<Radio color="primary" />} label="True" />
                        <FormControlLabel value={false} control={<Radio color="primary" />} label="False" />
                    </RadioGroup>
                </FormControl>
            </Fade>
        </Collapse>
    );
};

export default BooleanEdit;
