import React, { createContext } from 'react';

import { HeadCell } from 'components/SortableTable/EnhancedTableHead';

export interface StickyListProviderInterface<T> {
    children?: React.ReactNode;
    headCellDetails: HeadCell<T>[];
    order: 'asc' | 'desc';
    orderBy: keyof T;
    handleRequestSort: (event: React.MouseEvent<unknown, MouseEvent>, property) => void;
    rowCount: number;
    onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    numSelected: number;
    includeEmptyHeadCell: boolean;
    isLoading: boolean;
}

const StickyListContext = createContext(undefined as unknown as StickyListProviderInterface<any>);

const { Provider, Consumer } = StickyListContext;

const StickyListProvider = <T,>({
    children,
    headCellDetails,
    order,
    orderBy,
    handleRequestSort,
    rowCount,
    onSelectAllClick,
    numSelected,
    includeEmptyHeadCell,
    isLoading
}: StickyListProviderInterface<T>): React.ReactElement => {
    return (
        <Provider
            value={{
                headCellDetails,
                order,
                orderBy,
                handleRequestSort,
                rowCount,
                onSelectAllClick,
                numSelected,
                includeEmptyHeadCell,
                isLoading
            }}
        >
            {children}
        </Provider>
    );
};

export { StickyListContext, StickyListProvider, Consumer as StickyListConsumer };
