import React, { createContext, useState, useContext } from 'react';

export enum ImageType {
    Uploaded = 'UPLOADED',
    Unsplash = 'UNSPLASH'
}

interface ImageTypeContextInterface {
    setType: (type: ImageType) => void;
    type: ImageType | null;
}
const ImageTypeContext = createContext({} as ImageTypeContextInterface);

interface ImageTypeProviderProps {
    type: ImageType | null;
    children: any;
}

export const ImageTypeProvider: React.FC<ImageTypeProviderProps> = ({ type: initialType, children }) => {
    const [type, setType] = useState<ImageType | null>(initialType);

    return <ImageTypeContext.Provider value={{ type, setType }}>{children}</ImageTypeContext.Provider>;
};

export const useImageType = (): ImageTypeContextInterface => {
    const context = useContext(ImageTypeContext);

    if (!context) {
        throw new Error('useImageType must be used within a ImageTypeProvider');
    }

    return context;
};
