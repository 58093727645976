import { useMemo } from 'react';

import { OptimistMutationContext, OptimistRegisterUpdaterOptions, useOptimist } from '@adept-at/lib-react-optimist';
import clone from 'clone-deep';
import { buildGetSkillBySlugQueryKey, GetSkillBySlugResponse } from 'components/learn/getSkillBySlug';
import { ClientError } from 'graphql-request';
import useGqlClient from 'hooks/useGqlClient';
import { API_CONTENT } from 'lib/ApiConstants';
import { useSnackbar } from 'notistack';
import { useMutation, UseMutationResult } from 'react-query';

import {
    GetSkillByIdResponse,
    buildQueryKey as buildGetSkillByIdQueryKey
} from '../../../../skills/hooks/useGetSkillById';
import { SkillMutationProperties } from '../useUpdateSkillMeta';

import makeRemoveSkillComponent, {
    RemoveSkillComponentProps,
    RemoveSkillComponentResult
} from './makeRemoveSkillComponent';

const useRemoveSkillComponent = ({
    skillId,
    skillSlug,
    tenantSlug
}: SkillMutationProperties): UseMutationResult<
    RemoveSkillComponentResult,
    ClientError,
    RemoveSkillComponentProps,
    OptimistMutationContext
> => {
    const { enqueueSnackbar } = useSnackbar();
    const { client, withMutationOptions } = useGqlClient(API_CONTENT);

    const {
        registerUpdater: registerUpsertSkillComponentUpdater,
        mutationOptions: removeSkillComponentMutationOptions
    } = useOptimist<RemoveSkillComponentResult, ClientError, RemoveSkillComponentProps>();

    const UpsertSkillComponentOptions: OptimistRegisterUpdaterOptions<
        RemoveSkillComponentResult,
        ClientError,
        RemoveSkillComponentProps
    > = useMemo(() => {
        return {
            onError: () => {
                enqueueSnackbar('An error occurred while removing component.', { variant: 'error' });
            },
            onSuccess: () => {
                enqueueSnackbar('Component removed.', { variant: 'success' });
            }
        };
    }, [enqueueSnackbar]);

    const skillQueryByIdKey = useMemo(() => buildGetSkillByIdQueryKey(skillId), [skillId]);
    const skillQueryBySlugKey = useMemo(
        () => buildGetSkillBySlugQueryKey(tenantSlug ?? '', skillSlug ?? ''),
        [tenantSlug, skillSlug]
    );

    registerUpsertSkillComponentUpdater<GetSkillByIdResponse>(
        skillQueryByIdKey,
        (previous, deletedSkillComponent) => {
            const skill = previous.getSkillById;
            const skillComponentsClone = clone(previous.getSkillById.components);
            try {
                delete skillComponentsClone[deletedSkillComponent.componentId];
            } catch (error) {}
            return {
                getSkillById: {
                    ...skill,
                    components: skillComponentsClone
                }
            };
        },
        UpsertSkillComponentOptions
    );

    registerUpsertSkillComponentUpdater<GetSkillBySlugResponse>(
        skillQueryBySlugKey,
        (previous, deletedSkillComponent) => {
            const skill = previous.getEntityBySlug;
            const skillComponentsClone = clone(previous.getEntityBySlug.components);
            try {
                delete skillComponentsClone[deletedSkillComponent.componentId];
            } catch (error) {}
            return {
                getEntityBySlug: {
                    ...skill,
                    components: skillComponentsClone
                }
            };
        }
    );

    return useMutation<RemoveSkillComponentResult, ClientError, RemoveSkillComponentProps, OptimistMutationContext>(
        makeRemoveSkillComponent(client),
        withMutationOptions({
            ...removeSkillComponentMutationOptions,
            onSuccess: (...args) => {
                if (removeSkillComponentMutationOptions.onSuccess) {
                    removeSkillComponentMutationOptions.onSuccess(...args);
                }
            }
        })
    );
};

export default useRemoveSkillComponent;
