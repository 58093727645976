import { useState, useMemo, useCallback, useContext } from 'react';

import { OwnerType } from 'components/ContentContext/Interfaces';
import { EntityBySlug } from 'components/NestedCollection/context/getNestedCollectionBySlug';
import { ProductContext } from 'components/product/ProductContext';
import { ContentAccessSeat, PriceDetails } from 'components/product/types';
import { useCreateEnrollmentToProduct } from 'components/product/useCreateEnrollmentToProduct';
import { useGetContentAccessGroupSeatsForUser } from 'components/product/useGetAccessGroupSeatsForUser';
import { useGetFreePayload } from 'components/product/useGetFreePayload';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useSnackbar } from 'notistack';
import { useDebouncedCallback } from 'use-debounce/lib';
import { isTrue } from 'utils/isTrue';

import { EnrollmentOptions } from './Enroll';

export const useManageEnrollment = ({
    entityData,
    tenantId,
    tenantDataIsLoading
}: {
    entityData: EntityBySlug;
    tenantId?: string;
    tenantDataIsLoading: boolean;
}): {
    onEnroll: (enrollmentOptions: EnrollmentOptions) => Promise<void>;
    priceToUse: PriceDetails | undefined;
    currentEnrollment: ContentAccessSeat | undefined;
    isLoading: boolean;
} => {
    const user = useCurrentUser();
    const { mutate: enroll, isLoading: isCreatingEnrollment } = useCreateEnrollmentToProduct(entityData);
    const { productInProgress, product } = useContext(ProductContext);
    const { enqueueSnackbar } = useSnackbar();
    const [pollForSeats, setPollForSeats] = useState(false);

    const { data: seatData, isLoading: isAccessLoading } = useGetContentAccessGroupSeatsForUser(
        {
            active: true
        },
        !!user.currentUser?.userId,
        pollForSeats
    );

    const [debouncedSuccess] = useDebouncedCallback(
        () => enqueueSnackbar(`Enrollment successfully created!`, { variant: 'success' }),
        50
    );

    const currentEnrollment = useMemo(() => {
        if (!product?.productData.collectionId || !seatData?.getContentAccessGroupSeatsForUser) return undefined;

        const seat = seatData.getContentAccessGroupSeatsForUser.find(
            ({ collectionId, active }) => collectionId === product.productData.collectionId && active
        );

        if (seat && pollForSeats) {
            setPollForSeats(false);
            debouncedSuccess();
        }

        return seat;
    }, [product?.productData.collectionId, seatData, debouncedSuccess, pollForSeats]);

    const { data: freePayload, isLoading: isFreeLoading } = useGetFreePayload(
        {
            collectionId: entityData.collectionId
        },
        !isTrue(currentEnrollment)
    );

    const priceToUse = useMemo(() => {
        if (freePayload && freePayload.getCollectionIsFree.payload.free) {
            return product?.prices.find(({ pricePerUnitCents }) => pricePerUnitCents === 0);
        } else {
            return product?.prices.find(({ pricePerUnitCents }) => pricePerUnitCents > 0);
        }
    }, [product?.prices, freePayload]);

    const onEnroll = useCallback(
        async (enrollmentOptions: EnrollmentOptions) => {
            if (!user.currentUser?.userId || !product || !priceToUse || !tenantId) return;

            //todo will this suffice for idempotency?
            const idempotencyKey = `${product.productId}`;
            await enroll(
                {
                    input: {
                        idempotencyKey,
                        subscriber: { id: user.currentUser.userId, type: OwnerType.User },
                        tenantId,
                        length: enrollmentOptions.enrollmentIncrement,
                        startDate: enrollmentOptions.startDate.toISOString(),
                        productId: product.productId,
                        priceId: priceToUse.priceId,
                        resetSkills: enrollmentOptions.resetSkills,
                        isFree: freePayload?.getCollectionIsFree
                    }
                },
                {
                    onSuccess: () => {
                        //not complete in the users sense until content api has a accessSeat for them
                        setPollForSeats(true);
                    },
                    onError: () => {
                        enqueueSnackbar(`Something went wrong creating your enrollment!`, { variant: 'error' });
                    }
                }
            );
        },
        [user, product, enqueueSnackbar, enroll, priceToUse, tenantId, freePayload]
    );

    const isLoading =
        productInProgress ||
        isAccessLoading ||
        isFreeLoading ||
        pollForSeats ||
        isCreatingEnrollment ||
        tenantDataIsLoading;

    return { onEnroll, priceToUse, currentEnrollment, isLoading };
};
