import AppDownloadInitialInfo from './appDownload/appDownloadInitialInfo';
import ChannelArchived from './channel/archived';
import ChannelCreate from './channel/create';
import ChannelDeleteMessage from './channel/deleteMessage';
import ChannelEditMessage from './channel/editMessage';
import ChannelJoin from './channel/join';
import ChannelMeetingSessionCameraToggled from './channel/meeting/cameraToggled';
import ChannelMeetingSessionDrawing from './channel/meeting/drawing';
import ChannelMeetingSessionPresenterChange from './channel/meeting/presenterChange';
import ChannelMeetingSessionPresenterFocusChange from './channel/meeting/presenterFocusChange';
import ChannelMeetingSessionQuickPollCreated from './channel/meeting/quickPollCreated';
import ChannelMeetingReaction from './channel/meeting/reaction';
import ChannelMeetingSessionChanged from './channel/meeting/sessionChanged';
import ChannelMeetingSessionJoined from './channel/meeting/sessionJoined';
import ChannelMeetingSessionLeave from './channel/meeting/sessionLeave';
import ChannelMeetingSessionReactionBatch from './channel/meeting/sessionReactionBatch';
import ChannelMeetingSessionSkillChange from './channel/meeting/skillChange';
import ChannelPostMessage from './channel/postMessage';
import ChannelReactionUpdated from './channel/reactionUpdated';
import ChannelTemplateMessage from './channel/templateMessage';
import ChannelUserTyping from './channel/userTyping';
import PresenceChange from './presence/change';
import SystemNotificationMessage from './system/notif';
import UserAffiliationsModified from './system/userAffiliationsModified';
import UserRegistrationComplete from './system/userRegistrationComplete';

export enum MessageEventType {
    ChannelInfo = 'channel.info',
    ChannelInviteUser = 'channel.inviteUser',
    ChannelAddUser = 'channel.addUser',
    ChannelPostMessage = 'channel.postMessage',
    ChannelTemplateMessage = 'channel.templateMessage',
    ChannelUserTyping = 'channel.userTyping',
    ChannelDeleteMessage = 'channel.deleteMessage',
    ChannelEditMessage = 'channel.editMessage',
    ChannelCreate = 'channel.create',
    ChannelJoin = 'channel.join',
    ChannelLeave = 'channel.leave',
    ChannelDisbanded = 'channel.disband',
    ChannelArchived = 'channel.archived',
    ChannelNavigate = 'channel.navigate',
    ChannelReactionUpdated = 'channel.reactionUpdated',
    ChannelMeetingReaction = 'channel.meeting.reaction',
    SystemStatus = 'system.status',
    SystemNotification = 'system.notif',
    CustomAppEvent = 'app.event',
    PresenceChange = 'presence.change',
    ChannelMeetingSessionJoined = 'channel.meeting.session.joined',
    ChannelMeetingSessionLeave = 'channel.meeting.session.leave',
    ChannelMeetingSessionPresenterChange = 'channel.meeting.session.presenterChange',
    ChannelMeetingSessionPresenterFocusChange = 'channel.meeting.session.presenterFocusChange',
    ChannelMeetingSessionSkillChange = 'channel.meeting.session.skillChange',
    ChannelMeetingSessionChanged = 'channel.meeting.session.changed',
    ChannelMeetingSessionCameraToggled = 'channel.meeting.session.cameraToggled',
    ChannelMeetingSessionQuickPollCreated = 'channel.meeting.session.quickPollCreated',
    ChannelMeetingSessionDrawing = 'channel.meeting.session.drawing',
    ChannelMeetingSessionReactionBatch = 'channel.meeting.session.reaction.batch',
    AppDownloadInitialInfo = 'appDownload.initialInfo',
    UserRegistrationComplete = 'user.registration.complete',
    UserAffiliationsModified = 'user.affiliations.modified'
}

type MessageClassType =
    | typeof ChannelCreate
    | typeof ChannelPostMessage
    | typeof ChannelEditMessage
    | typeof ChannelDeleteMessage
    | typeof ChannelTemplateMessage
    | typeof ChannelUserTyping
    | typeof ChannelMeetingReaction
    | typeof SystemNotificationMessage
    | typeof PresenceChange
    | typeof ChannelMeetingSessionJoined
    | typeof ChannelMeetingSessionLeave
    | typeof ChannelMeetingSessionPresenterChange
    | typeof ChannelMeetingSessionPresenterFocusChange
    | typeof ChannelMeetingSessionSkillChange
    | typeof ChannelMeetingSessionChanged
    | typeof ChannelMeetingSessionCameraToggled
    | typeof ChannelMeetingSessionQuickPollCreated
    | typeof ChannelMeetingSessionDrawing
    | typeof ChannelMeetingSessionReactionBatch
    | typeof AppDownloadInitialInfo
    | typeof UserRegistrationComplete
    | typeof UserAffiliationsModified
    | null;

export const mapTypeToClass = (type: MessageEventType): MessageClassType => {
    switch (type) {
        case MessageEventType.ChannelCreate:
            return ChannelCreate;
        case MessageEventType.ChannelJoin:
            return ChannelJoin;
        case MessageEventType.ChannelArchived:
            return ChannelArchived;
        case MessageEventType.ChannelPostMessage:
            return ChannelPostMessage;
        case MessageEventType.ChannelEditMessage:
            return ChannelEditMessage;
        case MessageEventType.ChannelDeleteMessage:
            return ChannelDeleteMessage;
        case MessageEventType.ChannelTemplateMessage:
            return ChannelTemplateMessage;
        case MessageEventType.ChannelUserTyping:
            return ChannelUserTyping;
        case MessageEventType.ChannelReactionUpdated:
            return ChannelReactionUpdated;
        case MessageEventType.ChannelMeetingReaction:
            return ChannelMeetingReaction;
        case MessageEventType.SystemNotification:
            return SystemNotificationMessage;
        case MessageEventType.PresenceChange:
            return PresenceChange;
        case MessageEventType.ChannelMeetingSessionJoined:
            return ChannelMeetingSessionJoined;
        case MessageEventType.ChannelMeetingSessionLeave:
            return ChannelMeetingSessionLeave;
        case MessageEventType.ChannelMeetingSessionPresenterChange:
            return ChannelMeetingSessionPresenterChange;
        case MessageEventType.ChannelMeetingSessionPresenterFocusChange:
            return ChannelMeetingSessionPresenterFocusChange;
        case MessageEventType.ChannelMeetingSessionSkillChange:
            return ChannelMeetingSessionSkillChange;
        case MessageEventType.ChannelMeetingSessionChanged:
            return ChannelMeetingSessionChanged;
        case MessageEventType.ChannelMeetingSessionCameraToggled:
            return ChannelMeetingSessionCameraToggled;
        case MessageEventType.ChannelMeetingSessionQuickPollCreated:
            return ChannelMeetingSessionQuickPollCreated;
        case MessageEventType.ChannelMeetingSessionDrawing:
            return ChannelMeetingSessionDrawing;
        case MessageEventType.ChannelMeetingSessionReactionBatch:
            return ChannelMeetingSessionReactionBatch;
        case MessageEventType.AppDownloadInitialInfo:
            return AppDownloadInitialInfo;
        case MessageEventType.UserRegistrationComplete:
            return UserRegistrationComplete;
        case MessageEventType.UserAffiliationsModified:
            return UserAffiliationsModified;
        default:
            return null;
    }
};

export {
    ChannelCreate,
    ChannelPostMessage,
    ChannelEditMessage,
    ChannelDeleteMessage,
    ChannelTemplateMessage,
    ChannelUserTyping,
    ChannelMeetingReaction,
    SystemNotificationMessage,
    PresenceChange,
    ChannelMeetingSessionJoined,
    ChannelMeetingSessionLeave,
    ChannelMeetingSessionPresenterChange,
    ChannelMeetingSessionPresenterFocusChange,
    ChannelMeetingSessionSkillChange,
    ChannelMeetingSessionChanged,
    ChannelMeetingSessionCameraToggled,
    ChannelMeetingSessionQuickPollCreated,
    ChannelMeetingSessionDrawing,
    ChannelMeetingSessionReactionBatch,
    AppDownloadInitialInfo,
    UserRegistrationComplete,
    UserAffiliationsModified
};
