import { OwnerInfo } from 'components/ContentContext/Interfaces';
import { gql, ClientError } from 'graphql-request';
import { useBuildAuthenticatedQueryFn } from 'hooks/useGqlClient/useBuildAuthenticatedQueryFn';
import { Api } from 'lib/ApiConstants';
import { QueryKey, useQuery, UseQueryResult } from 'react-query';

export interface GetTenantProfileBySlugResponse {
    getTenantProfileBySlug: TenantProfileBySlug;
}

export interface TenantProfileBySlug {
    tenantId: string;
    owner: OwnerInfo;
    privateRootCollection: {
        collectionId: string;
    };
}

// @GRAPHQL Query getTenantProfileBySlug
const queryName = 'getTenantProfileBySlug';

export const buildQueryKey = (tenantSlug: string): QueryKey => [queryName, tenantSlug];

const GET_TENANT_PROFILE_BY_SLUG = gql`
    query ${queryName}($slug: String) {
        ${queryName}(slug: $slug) {
            tenantId
            owner {
                type
                id
            }
            privateRootCollection {
                collectionId
            }
        }
    }
`;

interface GetTenantProfileBySlugVariables {
    slug: string;
}

export const useGetTenantProfileBySlug = (
    variables: GetTenantProfileBySlugVariables,
    enabled: boolean
): UseQueryResult<GetTenantProfileBySlugResponse, ClientError> => {
    const { queryFn } = useBuildAuthenticatedQueryFn<GetTenantProfileBySlugResponse, GetTenantProfileBySlugVariables>(
        GET_TENANT_PROFILE_BY_SLUG,
        variables,
        { api: Api.Content }
    );

    return useQuery<GetTenantProfileBySlugResponse, ClientError>(buildQueryKey(variables.slug), {
        queryFn,
        enabled
    });
};
