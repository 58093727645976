import gql from 'graphql-tag';

import { CollectionChild } from '../context/useGetTenantProfileForOwner';

export const CREATE_NEW_COLLECTION = gql`
    mutation createCollection($input: CreateCollectionInput!) {
        createCollection(input: $input) {
            newCollection {
                collectionId
                title
                collectionSlug
            }
            parentCollection {
                itemsVersion
                infoVersion
                children {
                    id
                    type
                }
            }
        }
    }
`;

export interface MakeCollectionResult {
    createCollection: {
        newCollection: {
            collectionId: string;
            title: string;
            collectionSlug: string;
        };
        parentCollection: {
            itemsVersion: number;
            infoVersion: number;
            children: {
                id: string;
                type: CollectionChild;
            }[];
        };
    };
}

export interface MakeCollectionInput {
    input: {
        parentCollectionId: string;
        title: string;
    };
}
