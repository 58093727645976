import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';

import { useHeadCellPreferences } from 'components/CollaborationTable/useHeadCellPreferences';
import {
    buildCollaborationWithOrder,
    CollaborativeEntity,
    HeadCellPreference,
    refineResults
} from 'components/CollaborationTable/utils';
import { TransferManyEntityOwnershipProps } from 'components/modals/Sharing/SharingModal/hooks/useTransferManyEntityOwnership';
import { HeadCell } from 'components/SortableTable/EnhancedTableHead';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useOrganizationTerminology } from 'hooks/useOrganizationTerminology';
import useUserDetails from 'hooks/useUserDetails';
import { QueryKey } from 'react-query';

import { DEFAULT_HEAD_CELL_PREFERENCES } from '../StudioContext';
import {
    SelectedFilterOption,
    StudioFilter,
    StudioFilterCategory,
    useFilters,
    buildFilterExpression
} from '../useFilters';
import { useQueryParams } from '../useQueryParams';

import { GetCollectionResponse, useGetCollection } from './getCollection';
import {
    buildGetCollectionChildrenDetailsQueryKey,
    useGetCollectionChildrenDetails,
    GetCollectionChildrenDetailsResponse
} from './getCollectionChildrenDetails';

export interface StudioCollectionProviderInterface {
    collectionLoading: boolean;
    childrenLoading: boolean;
    collectionError: boolean;
    childrenError: boolean;
    selectedFilterOptions: SelectedFilterOption[];
    filters: StudioFilter[];
    search: string;
    setSearch: React.Dispatch<React.SetStateAction<string>>;
    headCellDetails: HeadCell<CollaborativeEntity>[];
    headCellPreferences: HeadCellPreference[];
    setHeadCellPreferences: React.Dispatch<React.SetStateAction<HeadCellPreference[]>>;
    noStudioEntities: boolean;
    filteredStudioEntities: CollaborativeEntity[];
    collectionId: string;
    organizationId: string;
    collection?: GetCollectionResponse['getCollection'];
    contentQueryKey: QueryKey;
    contentTransferOptimistCallback: (
        previous: GetCollectionChildrenDetailsResponse,
        transferQueryProps: TransferManyEntityOwnershipProps
    ) => GetCollectionChildrenDetailsResponse;
}

export const INITIAL_SORT_KEY = 'order';

const STUDIO_COLLECTION_PREFERENCES_KEY = 'studioCollectionColumns';

const StudioCollectionContext = createContext(undefined as unknown as StudioCollectionProviderInterface);

const { Provider } = StudioCollectionContext;

const StudioCollectionProvider: React.FC<{ collectionId: string; organizationId: string }> = ({
    children,
    collectionId,
    organizationId
}) => {
    const { headCellDetails, headCellPreferences, setHeadCellPreferences } = useHeadCellPreferences(
        STUDIO_COLLECTION_PREFERENCES_KEY,
        DEFAULT_HEAD_CELL_PREFERENCES
    );

    const { currentUser } = useCurrentUser();

    const queryParams = useQueryParams();
    const { selectedFilterOptions, filters, setFilters } = useFilters(queryParams);
    const [search, setSearch] = useState('');

    const {
        data: collectionData,
        isLoading: collectionLoading,
        isError: collectionError
    } = useGetCollection(collectionId);

    const collection = useMemo(() => collectionData?.getCollection, [collectionData?.getCollection]);
    const collectionChildren = useMemo(
        () => collectionData?.getCollection?.children ?? [],
        [collectionData?.getCollection?.children]
    );

    const getCollectionChildrenDetailsQueryKey = useMemo(
        () => buildGetCollectionChildrenDetailsQueryKey(collectionChildren),
        [collectionChildren]
    );

    const {
        refetch,
        data: childrenData,
        isLoading: childrenLoading,
        isError: childrenError
    } = useGetCollectionChildrenDetails(collectionChildren);

    useEffect(() => {
        if (collectionChildren.length > 0) {
            refetch();
        }
    }, [collectionChildren, refetch]);

    const { terminology } = useOrganizationTerminology();
    const entities = useMemo(
        () =>
            (childrenData?.getEntityBasicDetails
                ?.filter((entity) => !!entity)
                ?.map((entity) => buildCollaborationWithOrder(collectionChildren, entity, null, terminology))
                ?.filter(Boolean) as CollaborativeEntity[]) ?? [],
        [childrenData?.getEntityBasicDetails, terminology, collectionChildren]
    );

    const authorIds = useMemo(
        () => [...new Set(entities.flatMap((entity) => entity.authors.map((author) => author.userId)))],
        [entities]
    );
    const { usersDetails, loading } = useUserDetails({ userIds: authorIds });

    useEffect(() => {
        if (!loading) {
            setFilters((prev) =>
                prev.map((filter) => {
                    if (filter.category === StudioFilterCategory.AUTHOR) {
                        const options = authorIds.map((id) => ({
                            selected: (queryParams[StudioFilterCategory.AUTHOR] ?? []).includes(id),
                            title: usersDetails?.[id]?.displayName ?? 'User',
                            id,
                            avatarSrc: usersDetails?.[id]?.imageUrl ?? null
                        }));

                        return { ...filter, options };
                    }
                    return filter;
                })
            );
        }
    }, [authorIds, usersDetails, loading, setFilters, queryParams]);

    const noStudioEntities = useMemo(
        () => !childrenLoading && !collectionLoading && entities.length === 0,
        [childrenLoading, collectionLoading, entities]
    );

    const contentTransferOptimistCallback = useCallback(
        (previous: GetCollectionChildrenDetailsResponse, transferQueryProps: TransferManyEntityOwnershipProps) => {
            const updatedEntities = (previous?.getEntityBasicDetails ?? []).filter(
                (entity) =>
                    !transferQueryProps.entities.find(({ id }) => {
                        const entityId = 'skillId' in entity ? entity.skillId : entity.collectionId;
                        return id === entityId;
                    })
            );

            return {
                ...previous,
                getEntityBasicDetails: updatedEntities
            };
        },
        []
    );

    const filteredStudioEntities = useMemo(() => {
        const filterExpression = buildFilterExpression(selectedFilterOptions);

        return refineResults({
            results: entities,
            filter: filterExpression,
            search,
            userId: currentUser?.userId
        });
    }, [entities, selectedFilterOptions, search, currentUser?.userId]);

    return (
        <Provider
            value={{
                collectionLoading,
                childrenLoading: childrenLoading || collectionLoading,
                collectionError,
                childrenError,
                filters,
                selectedFilterOptions,
                search,
                setSearch,
                headCellDetails,
                headCellPreferences,
                setHeadCellPreferences,
                filteredStudioEntities,
                noStudioEntities,
                collectionId,
                organizationId,
                collection,
                contentQueryKey: getCollectionChildrenDetailsQueryKey,
                contentTransferOptimistCallback
            }}
        >
            {children}
        </Provider>
    );
};

export { StudioCollectionContext, StudioCollectionProvider };
