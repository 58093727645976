import React, { createContext, ReactNode, useMemo } from 'react';

import { Alert } from '@material-ui/lab';
import { getEditedSkillsFromSessionStorage } from 'utils/skill/editedSkills';

import { isForbiddenError } from '../../utils/error';
import { Skill } from '../ContentContext/Interfaces';
import { EntityLoading } from '../EntityLoading';

import { useGetSkillBySlug } from './getSkillBySlug';

export interface LearnProviderInterface {
    loading: boolean;
    skill: Skill;
    skillSlug: string;
    tenantSlug: string;
    refetchSkill: () => void;
}

export interface LearnProviderProps {
    children: ReactNode;
    skillSlug: string;
    tenantSlug: string;
    collectionSlugs?: string;
    LoadingComponent?: React.ComponentType;
}

const LearnContext = createContext(undefined as unknown as LearnProviderInterface);
const { Provider, Consumer } = LearnContext;

const LearnProvider: React.FC<LearnProviderProps> = ({ children, tenantSlug, skillSlug, LoadingComponent }) => {
    const usePrimary = useMemo(() => {
        const editedSkills = getEditedSkillsFromSessionStorage();
        return editedSkills.includes(`${tenantSlug}/${skillSlug}`);
    }, [tenantSlug, skillSlug]);

    const { data, error, isLoading, refetch } = useGetSkillBySlug(tenantSlug, skillSlug, usePrimary);

    if (error) {
        return (
            <Alert severity="warning" variant="filled">
                {isForbiddenError(error)
                    ? "You don't have access to this content."
                    : 'There was an issue loading this skill. Please contact our team.'}
            </Alert>
        );
    }

    if (isLoading || !data) {
        return LoadingComponent ? <LoadingComponent /> : <EntityLoading />;
    }

    return (
        <Provider
            value={{
                loading: isLoading,
                skill: data.getEntityBySlug ?? {},
                tenantSlug,
                skillSlug,
                refetchSkill: refetch
            }}
        >
            {children}
        </Provider>
    );
};

export { LearnContext, LearnProvider, Consumer as LearnConsumer };
