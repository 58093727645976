import { isDevToolsEnabled } from './EnabledCheck';

interface UseDevToolsInterface {
    enabled: boolean;
}

export const useDevTools = (): UseDevToolsInterface => {
    return {
        enabled: isDevToolsEnabled()
    };
};
