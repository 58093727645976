import { useContext, useEffect, useMemo, useState } from 'react';

import { mdiAccount, mdiMenuDown, mdiViewDashboard } from '@mdi/js';
import { EntityType } from 'components/ContentContext/Enums';
import { OwnerType } from 'components/ContentContext/Interfaces';
import { CourseIconPath } from 'components/icons/CourseIcon';
import { PrivateRoomPath } from 'components/icons/PrivateRoom';
import { PublicRoomPath } from 'components/icons/PublicRoom';
import { SkillIconPath } from 'components/icons/SkillIcon';
import { TrackIconPath } from 'components/icons/TrackIcon';
import { HeaderContext } from 'components/navigation/HeaderContext';
import { StackContext } from 'components/stacks/StackContext';
import { useHistory, useParams } from 'react-router';

import { LearnContext } from './LearnContext';
import { useGetCollectionsBySlugs } from './useGetCollectionsBySlugs';
import { useGetTenantProfileBySlug } from './useGetTenantBySlug';

interface UseCollectionBreadcrumbsOutput {
    currentItemId: string;
    parentId?: string;
    crumbIndex: number;
    collectionSlugsArray: string[];
}

export const useCollectionBreadcrumbs = (
    openNavigatorMenu: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
    onItemSelect: (trailingUrl: string, type: EntityType) => void
): UseCollectionBreadcrumbsOutput => {
    const learnContext = useContext(LearnContext);
    const stackContext = useContext(StackContext);
    const { setBreadcrumbs } = useContext(HeaderContext);

    const [currentItemId, setCurrentItemId] = useState('');
    const [parentId, setParentId] = useState<string>();
    const [crumbIndex, setCrumbIndex] = useState(0);

    const history = useHistory();

    const { tenantSlug = stackContext?.tenantSlug, collectionSlugs } =
        useParams<{ tenantSlug?: string; collectionSlugs?: string }>();

    const collectionSlugsArray = useMemo(() => collectionSlugs?.split('/') ?? [], [collectionSlugs]);

    const { data: collectionsBySlugData, isLoading: collectionsBySlugLoading } = useGetCollectionsBySlugs(
        { tenantSlug: tenantSlug ?? '', collectionSlugs: collectionSlugsArray },
        collectionSlugsArray.length > 0 && !!tenantSlug
    );

    const { data: tenantBySlugData, isLoading: tenantBySlugLoading } = useGetTenantProfileBySlug(
        { slug: tenantSlug ?? '' },
        collectionSlugsArray.length > 0 && !!tenantSlug
    );

    const collections = useMemo(
        () => collectionsBySlugData?.getCollectionBasicDetailsBySlugs ?? [],
        [collectionsBySlugData]
    );

    const rootBreadcrumb = useMemo(() => {
        if (stackContext?.stack) {
            return {
                iconPath: stackContext.stack.private ? PrivateRoomPath : PublicRoomPath,
                title: stackContext.stack.name,
                onClick: () => {
                    history.push(`/organization/${stackContext.organizationId}/rooms/${stackContext.stackId}`);
                }
            };
        }

        if (!collectionSlugs) {
            return {
                iconPath: mdiViewDashboard,
                title: 'Dashboard',
                onClick: () => history.push('/dashboard')
            };
        }

        const breadcrumb = {
            iconPath: TrackIconPath,
            loading: tenantBySlugLoading
        };

        if (tenantBySlugData?.getTenantProfileBySlug?.owner?.type === OwnerType.User) {
            return {
                ...breadcrumb,
                iconPath: mdiAccount,
                title: 'Profile',
                onClick: () => history.push(`/my-profile`)
            };
        }

        if (
            tenantBySlugData?.getTenantProfileBySlug?.privateRootCollection?.collectionId ===
            collections[0]?.rootCollectionId
        ) {
            return {
                ...breadcrumb,
                title: 'Internal training',
                onClick: () => history.push(`/${tenantSlug}/internal`)
            };
        }

        return {
            ...breadcrumb,
            title: 'Catalog',
            onClick: () => history.push(`/${tenantSlug}/catalog`)
        };
    }, [
        history,
        stackContext.organizationId,
        stackContext.stack,
        stackContext.stackId,
        tenantSlug,
        collections,
        collectionSlugs,
        tenantBySlugData?.getTenantProfileBySlug?.owner?.type,
        tenantBySlugData?.getTenantProfileBySlug?.privateRootCollection?.collectionId,
        tenantBySlugLoading
    ]);

    const onSkill = !!learnContext?.skill;

    const collectionBreadcrumbs = useMemo(
        () =>
            collectionsBySlugLoading
                ? collectionSlugsArray.map((_slug) => ({
                      iconPath: CourseIconPath,
                      title: '',
                      loading: collectionsBySlugLoading
                  }))
                : collections.map((collection, index) => ({
                      iconPath: CourseIconPath,
                      title: collection.title,
                      loading: collectionsBySlugLoading,
                      onClick:
                          // display as link if skill trailing breadcrumb or if not last collection breadcrumb
                          onSkill || index !== collections.length - 1
                              ? () => {
                                    const newCollections = collections.slice(0, index);
                                    const collectionSlugString =
                                        newCollections.length > 0
                                            ? `${newCollections
                                                  .map((collection) => collection.collectionSlug)
                                                  .join('/')}/`
                                            : '';

                                    onItemSelect(
                                        `${collectionSlugString}${collection.collectionSlug}`,
                                        EntityType.COLLECTION
                                    );
                                }
                              : undefined
                  })),
        [collectionSlugsArray, collectionsBySlugLoading, collections, onItemSelect, onSkill]
    );

    const skillDropdownEnabled = collections.length > 0;

    const skillBreadcrumb = useMemo(() => {
        if (learnContext?.skill) {
            return {
                iconPath: SkillIconPath,
                title: learnContext?.skill?.title,
                loading: learnContext.loading,
                trailingIconPath: skillDropdownEnabled ? mdiMenuDown : undefined,
                onClick: skillDropdownEnabled
                    ? (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          setCurrentItemId(learnContext?.skill?.skillId);
                          setParentId(collections[collections.length - 1]?.collectionId);
                          setCrumbIndex(collections.length);
                          openNavigatorMenu(e);
                      }
                    : undefined
            };
        }
    }, [learnContext?.skill, collections, learnContext?.loading, openNavigatorMenu, skillDropdownEnabled]);

    useEffect(() => {
        setBreadcrumbs([rootBreadcrumb, ...collectionBreadcrumbs, ...(skillBreadcrumb ? [skillBreadcrumb] : [])]);
    }, [history, tenantSlug, rootBreadcrumb, collectionBreadcrumbs, skillBreadcrumb, setBreadcrumbs]);

    return { currentItemId, parentId, crumbIndex, collectionSlugsArray };
};
