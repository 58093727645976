import { useMemo, useContext, useEffect } from 'react';

import { EditorContext } from 'components/builder/context/EditorContext';
import { useSkippedStorage } from 'components/builder/Meta/Storyboard/hooks/useSkippedStorage';
import { Skill } from 'components/ContentContext/Interfaces';
import { useComponentEngine } from 'components/engine';

import { StoryboardContext } from '../StoryboardContext';

export const useDetermineStoryboardDisplay = (skill: Skill): void => {
    const { skillId } = skill;

    const { workingStoryboard } = useContext(StoryboardContext);

    const { editStoryboard } = useContext(EditorContext);
    const { components } = useComponentEngine();

    const { hasSkippedForSkill } = useSkippedStorage();

    // As of late, each new skill starts with a section component, so this should be <= 1?
    const hasNoComponents = useMemo(() => Object.keys(components).length <= 1, [components]);

    const hasFinishedStoryboard = useMemo(() => {
        if (!workingStoryboard || Object.entries(workingStoryboard).length === 0) {
            return false;
        }

        // @TODO Any value not equal to null will cause us to skip storyboard, this should be a better falsey check
        if (
            workingStoryboard.how?.summary === null &&
            workingStoryboard.who?.summary === null &&
            workingStoryboard.why?.summary === null
        ) {
            return false;
        }

        return true;
    }, [workingStoryboard]);

    useEffect(() => {
        /**
         * Determine whether or not we should be in storyboard mode based on three criteria. These are indicative of
         * whether or not the user has already seen/worked on the storyboard dialog.
         *
         * 1. Has user added any components to the skill
         * 2. Has user added any information to the storyboard already
         * 3. Has "skipped" the storyboard dialog for this skill
         */
        if (hasNoComponents && !hasFinishedStoryboard && !hasSkippedForSkill(skillId)) {
            /**
             * Calling this method will place the editMode equal to EditMode.Storyboard, causing the storyboard dialog
             * to render instead of the SkillBuilder view.
             */
            editStoryboard();
        }
    }, [editStoryboard, hasFinishedStoryboard, hasNoComponents, hasSkippedForSkill, skillId]);
};
