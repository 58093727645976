import React from 'react';

import { Button } from '@material-ui/core';
import { mdiUpload } from '@mdi/js';
import Icon from '@mdi/react';
import styled from 'styled-components';

import { modularScale } from '../../utils/modular_scale';

const ControlsWrapper = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    svg {
        color: ${(props) => props.theme.colors.border};
        width: 100px;
        margin-bottom: 1rem;
    }
`;

const DragText = styled.div`
    font-size: ${modularScale(1)};
    margin-bottom: 1rem;
`;

const SelectText = styled.div`
    position: relative;
    font-style: italic;
    margin-bottom: 2rem;
    color: ${(props) => props.theme.colors.lightGrey};

    &:after,
    &:before {
        position: absolute;
        content: '';
        height: 1px;
        width: 25px;
        background: ${(props) => props.theme.colors.lightGrey};
        top: 0;
        bottom: 0;
        margin: auto;
    }

    &:after {
        left: calc(100% + 10px);
    }

    &:before {
        right: calc(100% + 10px);
    }
`;

export const StyledButton = styled(Button)`
    font-size: ${modularScale(0)};
`;

export const Controls: React.FC = () => (
    <ControlsWrapper>
        <Icon path={mdiUpload} />
        <DragText>Drag your image here</DragText>
        <SelectText>or select a file</SelectText>
        <StyledButton
            color="primary"
            variant="contained"
            onClick={() => {
                //intentionally left blank - no-op
            }}
        >
            Choose File
        </StyledButton>
    </ControlsWrapper>
);
