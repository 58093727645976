import React, { useContext } from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { Button, Divider, Fade, MenuItem, Tooltip } from '@material-ui/core';
import { mdiPlus, mdiShape } from '@mdi/js';
import Icon from '@mdi/react';
import { CourseIconPath } from 'components/icons/CourseIcon';
import AddExistingCollectionModal from 'components/RootProfileCollection/AddExistingCollectionModal';
import NewCollectionModal from 'components/RootProfileCollection/NewCollectionModal';
import useUpsertCollectionItem from 'components/RootProfileCollection/useUpsertCollectionItem';
import { useModal } from 'hooks/useModal';
import { useOrganizationTerminology } from 'hooks/useOrganizationTerminology';
import { usePopover } from 'hooks/usePopover';
import styled from 'styled-components';
import { StyledMenu } from 'styles/Shared.styles';
import { isTrue } from 'utils/isTrue';

import AddContentToCollectionModal from './AddContentToCollectionModal';
import { CardPositioningStyles } from './ContentSection/ContentSectionCard';
import { NestedCollectionContext } from './context/NestedCollectionContext';
import { useAvailableSkillOptions } from './hooks/useAvailableSkillOptions';

const StyledMenuItem = styled(MenuItem)`
    padding: 1rem 1.5rem;
    color: ${(props) => props.theme.colors.primary};

    svg {
        margin-right: 1rem;
        color: ${(props) => props.theme.colors.primary};
    }
`;

const StyledButton = styled(Button)`
    width: 100%;
`;

const Container = styled.div`
    ${CardPositioningStyles}
`;

const DISABLED_ADDING_COLLECTIONS = 'Cannot add collections to a collection with an active enrollment product.';

export const AddToCollection: React.FC = () => {
    const {
        existingCollectionsOptions,
        collectionId,
        getExistingCollectionsIsLoading,
        collectionIdForEditableCollectionQuery,
        parentQuerySlug,
        productId,
        refetchEditableCollection
    } = useContext(NestedCollectionContext);

    const { mutate: addExistingCollection } = useUpsertCollectionItem(
        collectionId,
        refetchEditableCollection,
        collectionIdForEditableCollectionQuery,
        parentQuerySlug
    );

    const { terminology } = useOrganizationTerminology();
    const { open, anchorEl, handlePopoverClick, handlePopoverClose } = usePopover();
    const { availableSkills, isLoading: getAvailableSkillsIsLoading } = useAvailableSkillOptions();

    const {
        modalOpen: addNewModalOpen,
        handleModalClose: handleCloseAddNewModal,
        handleModalOpen: handleAddNewModalOpen
    } = useModal();
    const {
        modalOpen: addExistingModalOpen,
        handleModalClose: handleCloseAddExistingModal,
        handleModalOpen: handleAddExistingModalOpen
    } = useModal();
    const {
        modalOpen: addContentModalOpen,
        handleModalClose: handleCloseAddContentModal,
        handleModalOpen: handleAddContentModalOpen
    } = useModal();

    const handleCreateNewClick = () => {
        handleAddNewModalOpen();
    };

    const handleAddExistingClick = () => {
        handleAddExistingModalOpen();
    };

    const handleAddContentClick = () => {
        handleAddContentModalOpen();
    };

    const handleAddNewClose = () => {
        handlePopoverClose();
        handleCloseAddNewModal();
    };

    const handleAddExistingClose = () => {
        handlePopoverClose();
        handleCloseAddExistingModal();
    };

    const handleAddContentModalClose = () => {
        handlePopoverClose();
        handleCloseAddContentModal();
    };

    return (
        <Container>
            <Tooltip title={isTrue(productId) ? DISABLED_ADDING_COLLECTIONS : ''}>
                <StyledButton
                    onClick={handlePopoverClick}
                    variant={'outlined'}
                    color="primary"
                    startIcon={<Icon path={mdiPlus} size={IconSize.Small} />}
                >
                    Add
                </StyledButton>
            </Tooltip>
            <StyledMenu
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handlePopoverClose}
                TransitionComponent={Fade}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <StyledMenuItem onClick={handleAddContentClick}>
                    <Icon path={mdiShape} size={IconSize.Medium} /> Add content
                </StyledMenuItem>
                <Divider />
                <StyledMenuItem onClick={handleCreateNewClick} disabled={isTrue(productId)}>
                    <Icon path={mdiPlus} size={IconSize.Medium} /> Create new {terminology.collection}
                </StyledMenuItem>

                <StyledMenuItem onClick={handleAddExistingClick} disabled={isTrue(productId)}>
                    <Icon path={CourseIconPath} size={IconSize.Medium} /> Add existing {terminology.collection}
                </StyledMenuItem>
            </StyledMenu>
            <NewCollectionModal
                modalOpen={addNewModalOpen}
                handleModalClose={handleAddNewClose}
                editableCollectionId={collectionId}
                collectionIdForEditableCollectionQuery={collectionIdForEditableCollectionQuery}
                parentQuerySlug={parentQuerySlug}
            />
            <AddExistingCollectionModal
                modalOpen={addExistingModalOpen}
                handleModalClose={handleAddExistingClose}
                options={existingCollectionsOptions}
                mutateFn={addExistingCollection}
                parentCollectionId={collectionId}
                isLoading={getExistingCollectionsIsLoading}
            />
            <AddContentToCollectionModal
                modalOpen={addContentModalOpen}
                handleModalClose={handleAddContentModalClose}
                options={availableSkills}
                loading={getAvailableSkillsIsLoading}
            />
        </Container>
    );
};
