import { Lab } from 'components/ContentContext/Interfaces';
import { ClientError, gql } from 'graphql-request';
import { useBuildAuthenticatedQueryFn } from 'hooks/useGqlClient/useBuildAuthenticatedQueryFn';
import { Api } from 'lib/ApiConstants';
import { QueryKey, useQuery, UseQueryResult } from 'react-query';

export const buildGetLabsByCreatorQueryKey = (): QueryKey => ['getVirtualLabsByCreator'];

export interface GetLabsByCreatorResponse {
    getVirtualLabsByCreator: Lab[];
}

const GET_LABS_BY_CREATOR = gql`
    query getVirtualLabsByCreator {
        getVirtualLabsByCreator {
            vLabId
            name
            createdAt
            updatedAt
            owner {
                type
                id
            }
            slug
            virtualMachines {
                name
                description
                memory
                cpus
                nics
                operatingSystem
                username
                password
                cid
                sha256
                sizeMB
            }
        }
    }
`;

export const useGetLabsByCreator = (): UseQueryResult<GetLabsByCreatorResponse, ClientError> => {
    const { queryFn } = useBuildAuthenticatedQueryFn<GetLabsByCreatorResponse>(
        GET_LABS_BY_CREATOR,
        {},
        { api: Api.Content }
    );

    return useQuery<GetLabsByCreatorResponse, ClientError>(buildGetLabsByCreatorQueryKey(), { queryFn });
};
