import { useMemo } from 'react';

import { OptimistMutationContext, useOptimist } from '@adept-at/lib-react-optimist';
import {
    buildGetEntitiesBasicDetailsQueryKey,
    GetEntitiesBasicDetailsQueryResponse
} from 'components/NestedCollection/useGetEntitiesBasicDetails/getEntitiesBasicDetails';
import { ClientError } from 'graphql-request';
import gql from 'graphql-tag';
import { useBuildAuthenticatedMutationFn } from 'hooks/useGqlClient/useBuildAuthenticatedMutationFn';
import { Api } from 'lib/ApiConstants';
import { useSnackbar } from 'notistack';
import { QueryKey, useMutation, UseMutationResult } from 'react-query';
import { setCollectionEditedInSessionStorage } from 'utils/collection';

import {
    buildGetCollectionBasicDetailsQueryKey,
    GetCollectionBasicDetailsResponse
} from './useGetNestedCollections/getCollectionBasicDetails';

const mutationName = 'deleteCollection';

export const DELETE_COLLECTION = gql`
    mutation ${mutationName}($collectionId: UUIDv4!) {
        ${mutationName}(collectionId: $collectionId) {
            success
        }
    }
`;

export interface DeleteCollectionResponse {
    deleteCollection: {
        success: boolean;
    };
}

export interface DeleteCollectionVariables {
    collectionId: string;
}

interface UseDeleteCollectionProps {
    collectionId: string; //id of collection being deleted
    parentCollectionId: string;
    parentSlug?: string;
    editedCollectionSlug: string;
}

export const useDeleteCollection = ({
    collectionId,
    parentCollectionId,
    editedCollectionSlug,
    parentSlug
}: UseDeleteCollectionProps): UseMutationResult<
    DeleteCollectionResponse,
    ClientError,
    DeleteCollectionVariables,
    OptimistMutationContext
> => {
    const { mutationOptions, registerUpdater } = useOptimist<
        DeleteCollectionResponse,
        ClientError,
        DeleteCollectionVariables
    >();

    const { enqueueSnackbar } = useSnackbar();

    const { mutationFn } = useBuildAuthenticatedMutationFn<DeleteCollectionResponse, DeleteCollectionVariables>(
        DELETE_COLLECTION,
        { api: Api.Content }
    );

    const queryKey: QueryKey = useMemo(() => {
        if (parentSlug) return buildGetEntitiesBasicDetailsQueryKey(parentCollectionId, parentSlug);

        return buildGetCollectionBasicDetailsQueryKey(parentCollectionId);
    }, [parentCollectionId, parentSlug]);

    registerUpdater<GetEntitiesBasicDetailsQueryResponse>(
        queryKey,
        (previous) => {
            if (!previous) return { getEntityBasicDetails: [] } as GetEntitiesBasicDetailsQueryResponse;

            const previousDetails = previous.getEntityBasicDetails ?? [];

            const updatedResponse = previousDetails.filter((childEntity) => {
                if ('collectionId' in childEntity) return childEntity.collectionId !== collectionId;

                return true;
            });

            return {
                getEntityBasicDetails: updatedResponse
            };
        },
        {
            onSuccess: () => {
                setCollectionEditedInSessionStorage(parentCollectionId, editedCollectionSlug);
            },
            refetchQuery: false
        }
    );

    registerUpdater<GetCollectionBasicDetailsResponse>(
        queryKey,
        (previous) => {
            if (!previous) return { getCollectionBasicDetails: [] } as GetCollectionBasicDetailsResponse;

            const previousDetails = previous.getCollectionBasicDetails ?? [];

            const updatedResponse = previousDetails.filter((childDetail) => childDetail.collectionId !== collectionId);

            return {
                getCollectionBasicDetails: updatedResponse
            };
        },
        {
            onError: () => {
                enqueueSnackbar('An error occurred while deleting collection', { variant: 'error' });
            },
            onSuccess: () => {
                setCollectionEditedInSessionStorage(parentCollectionId, editedCollectionSlug);
                enqueueSnackbar('Successfully deleted collection', { variant: 'success' });
            },
            refetchQuery: false
        }
    );

    return useMutation<DeleteCollectionResponse, ClientError, DeleteCollectionVariables, OptimistMutationContext>(
        mutationFn,
        mutationOptions
    );
};
