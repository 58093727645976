/* eslint-disable react/function-component-definition */
import React, { ReactElement } from 'react';

import { IconSize } from '@adept-at/lib-react-components';
import { TableHead, TableRow, TableSortLabel, Checkbox } from '@material-ui/core';
import { getCellWidth } from 'components/CollaborationTable/TableCells';
import { StickyTableCell, StyledTableCell } from 'components/CollaborationTable/TableCells/TableCell.styles';
import styled, { css } from 'styled-components';

const ARROW_MARGIN_RIGHT = 4;
export const CHECKBOX_WIDTH = 46;

const inactiveStyle = css`
    svg {
        display: none;
    }

    /* The width of the table's content needs to stay consistent to prevent jumping,
    margin-right should be equal to the width + left and right margin of the svg that it's replacing */
    margin-right: calc(${IconSize.Medium} + ${ARROW_MARGIN_RIGHT}px);
`;

interface TableSortLabelReverseProps {
    $active: boolean;
}

export const TableSortLabelReverse = styled(TableSortLabel)<TableSortLabelReverseProps>`
    flex-direction: row-reverse;
    font-weight: 600;

    svg {
        width: ${IconSize.Medium};
        height: ${IconSize.Medium};
        margin-left: 0;
        margin-right: ${ARROW_MARGIN_RIGHT}px;
    }

    ${(props) => (!props.$active ? inactiveStyle : '')}
`;

type Order = 'asc' | 'desc';

export interface HeadCell<T> {
    id: keyof T;
    sticky?: boolean;
    borderLeft?: boolean;
    borderRight?: boolean;
    label: string;
}

interface EnhancedTableProps<T> {
    headCellDetails: HeadCell<T>[];
    orderBy: string;
    order: Order;
    handleRequestSort: (event: React.MouseEvent<unknown, MouseEvent>, property: keyof T) => void;
    numSelected?: number;
    rowCount?: number;
    onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    includeEmptyHeadCell?: boolean;
    isLoading?: boolean;
}

export function EnhancedTableHead<T>(props: EnhancedTableProps<T>): ReactElement {
    const {
        headCellDetails,
        handleRequestSort,
        orderBy,
        order,
        numSelected = 0,
        rowCount = 0,
        onSelectAllClick,
        includeEmptyHeadCell,
        isLoading
    } = props;

    const createSortHandler = (property: keyof T) => (event: React.MouseEvent<unknown>) => {
        handleRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {onSelectAllClick ? (
                    <StickyTableCell left={0} padding="checkbox" width={CHECKBOX_WIDTH}>
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{ 'aria-label': 'Select all rows' }}
                        />
                    </StickyTableCell>
                ) : null}
                {headCellDetails.map((headCell) => {
                    const CellWrapper = headCell.sticky ? StickyTableCell : StyledTableCell;
                    const width = getCellWidth(headCell.id as string);

                    const additionalProps = headCell.sticky
                        ? {
                              left: onSelectAllClick ? CHECKBOX_WIDTH : 0,
                              $borderLeft: headCell.borderLeft,
                              $borderRight: headCell.borderRight
                          }
                        : {};

                    return (
                        <CellWrapper
                            key={headCell.id as string}
                            align="left"
                            $flexGrow={headCell.id === 'title'}
                            width={width}
                            sortDirection={orderBy === headCell.id ? order : false}
                            {...additionalProps}
                        >
                            <TableSortLabelReverse
                                $active={orderBy === headCell.id && !isLoading}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                                disabled={isLoading}
                            >
                                {orderBy === headCell.id ? (
                                    <span style={{ display: 'none' }}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                                {headCell.label}
                            </TableSortLabelReverse>
                        </CellWrapper>
                    );
                })}
                {includeEmptyHeadCell ? (
                    <StickyTableCell $borderLeft right="0px" padding="checkbox" width={CHECKBOX_WIDTH} />
                ) : null}
            </TableRow>
        </TableHead>
    );
}
