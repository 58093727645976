import { GraphQLClient } from 'graphql-request';
import gql from 'graphql-tag';
import buildGqlMutationFn from 'hooks/useGqlClient/helpers/buildGqlMutationFn';
import { MutationFunction } from 'react-query';

const ANSWER_QUESTION_BATCH = gql`
    mutation ($answerSubmission: BatchAnswerSubmission!) {
        answerQuestionBatch(answerSubmission: $answerSubmission) {
            isValid
            hint
            questionId
        }
    }
`;

export interface AnswerQuestionBatchResult {
    answerQuestionBatch: ValidatedAnswer[];
}

export interface ValidatedAnswer {
    isValid: boolean;
    questionId: string;
    hint?: string;
}

type QuestionAnswer = boolean | string | string[] | Record<string, any>;

export interface AnswerQuestionBatchProps {
    answerSubmission: {
        skillId: string;
        questionAnswers: Record<string, QuestionAnswer>;
        orgId?: string | null;
        attempt?: number;
    };
}
const makeAnswerQuestionBatch = (
    client: GraphQLClient
): MutationFunction<AnswerQuestionBatchResult, AnswerQuestionBatchProps> =>
    buildGqlMutationFn(client, ANSWER_QUESTION_BATCH);

export default makeAnswerQuestionBatch;
