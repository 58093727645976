import React, { useMemo } from 'react';

import { Divider } from '@material-ui/core';
import { mdiFileCog } from '@mdi/js';
import Icon from '@mdi/react';
import { EntityType } from 'components/ContentContext/Enums';
import { CollectionType } from 'components/modals/Skills/useGetCollectionsForTenantProfile';
import { AvailabilityButton } from 'components/RootProfileCollection/AvailabilityButton';
import { AvailabilityModal as RootAvailabilityModal } from 'components/RootProfileCollection/AvailabilityModal';
import { AboutInfo } from 'components/RootProfileCollection/constants';
import { useUpdateCollectionVisibility } from 'components/RootProfileCollection/useUpdateCollectionVisibility';
import { useModal } from 'hooks/useModal';
import styled from 'styled-components';

import { WorkflowActionsMenu, EditCollectionMenu } from './ActionMenus';
import { AvailabilityModal as NestedAvailabilityModal } from './availability/AvailabilityModal';
import { useCollectionImageContext } from './context/CollectionImageContext';
import { EntityBySlug } from './context/getNestedCollectionBySlug';
import { EditPreviewToggle } from './EditPreviewToggle';
import { StickyContainer, QuickActionItemContainer, QuickActionItem } from './NestedCollection.styles';
import { PageSettingsModal } from './PageSettingsModal';
import { PriceButton } from './PriceButton';

const ActionsContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    justify-content: space-between;
`;

const ActionsInnerContainer = styled.div`
    display: flex;
    align-items: center;
`;

const StyledDivider = styled(Divider)`
    height: 1.5rem;
    margin: 0 0.5rem;
`;

interface EditCollectionHeaderProps {
    collectionId: string;
    isEditMode: boolean;
    setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
    isPreviewMode: boolean;
    setIsPreviewMode: React.Dispatch<React.SetStateAction<boolean>>;
    canEdit: boolean;
    canEditPrice?: boolean;
    isListed?: boolean;
    isVisible: boolean;
    aboutInfo: AboutInfo | null;
    rootType?: CollectionType;
    entity?: EntityBySlug;
}

export const EditCollectionHeader: React.FC<EditCollectionHeaderProps> = ({
    isEditMode,
    setIsEditMode,
    isPreviewMode,
    setIsPreviewMode,
    canEdit,
    canEditPrice,
    isListed,
    entity,
    collectionId,
    isVisible,
    aboutInfo,
    rootType,
    children
}) => {
    const {
        modalOpen: priceModalOpen,
        handleModalClose: handlePriceModalClose,
        handleModalOpen: handlePriceModalOpen
    } = useModal();

    const {
        modalOpen: pageSettingsOpen,
        handleModalClose: handlePageSettingsClose,
        handleModalOpen: handlePageSettingsOpen
    } = useModal();

    const { mutate: updateCollectionVisibility } = useUpdateCollectionVisibility(collectionId);

    const onConfirmEnrollment = ({ visible }) => {
        if (visible !== isVisible) {
            updateCollectionVisibility({ collectionId, visible });
        }
        handlePriceModalClose();
    };

    const onToggleEditMode = () => {
        setIsEditMode((prev) => !prev);
    };

    const { collectionImages, defaultCatalog } = useCollectionImageContext();

    const catalogImage = useMemo(() => {
        return collectionImages?.catalogLight ?? defaultCatalog;
    }, [collectionImages, defaultCatalog]);

    return (
        <StickyContainer $visible>
            <ActionsContainer>
                {canEdit ? (
                    <>
                        <ActionsInnerContainer>
                            <EditPreviewToggle
                                isEditMode={isEditMode}
                                isPreviewMode={isPreviewMode}
                                setIsEditMode={setIsEditMode}
                                setIsPreviewMode={setIsPreviewMode}
                            />
                        </ActionsInnerContainer>
                        <ActionsInnerContainer>
                            {entity ? (
                                <>
                                    <PriceButton isVisible={isVisible} onClick={handlePriceModalOpen} />
                                    <NestedAvailabilityModal
                                        contentTitle={aboutInfo?.title ?? ''}
                                        open={priceModalOpen}
                                        onClose={handlePriceModalClose}
                                        isVisible={isVisible}
                                        onConfirm={onConfirmEnrollment}
                                        isInternal={rootType === CollectionType.INTERNAL}
                                        collectionDetails={entity}
                                    />
                                    <WorkflowActionsMenu isStickyMode owner={{ ...entity.owner }} />
                                </>
                            ) : null}
                            {canEditPrice ? (
                                <>
                                    <AvailabilityButton onClick={handlePriceModalOpen} isListed={isListed} />
                                    <RootAvailabilityModal open={priceModalOpen} onClose={handlePriceModalClose} />
                                </>
                            ) : null}
                            <StyledDivider orientation="vertical" />
                            <QuickActionItemContainer>
                                <QuickActionItem
                                    title="SEO Settings"
                                    $isStickyMode
                                    icon={<Icon path={mdiFileCog} />}
                                    onClick={handlePageSettingsOpen}
                                />
                                <PageSettingsModal
                                    contentType={EntityType.COLLECTION}
                                    contentTitle={aboutInfo?.title ?? ''}
                                    description={aboutInfo?.titleSub ?? ''}
                                    catalogImage={catalogImage}
                                    open={pageSettingsOpen}
                                    onClose={handlePageSettingsClose}
                                    onConfirm={() => {
                                        // @TODO Update and save values
                                        handlePageSettingsClose();
                                    }}
                                />
                            </QuickActionItemContainer>
                            <StyledDivider orientation="vertical" />
                            <EditCollectionMenu
                                owner={entity?.owner ?? undefined}
                                handleToggleEditMode={onToggleEditMode}
                                handleOpenSEOSettings={handlePageSettingsOpen}
                                handleOpenAvailabilityModal={handlePriceModalOpen}
                            />
                        </ActionsInnerContainer>
                    </>
                ) : null}
                {children}
            </ActionsContainer>
        </StickyContainer>
    );
};
