import MessageRecord from './MessageRecord';
import {
    AppDownloadInitialInfo,
    ChannelCreate,
    ChannelEditMessage,
    ChannelDeleteMessage,
    ChannelMeetingReaction,
    ChannelMeetingSessionCameraToggled,
    ChannelMeetingSessionChanged,
    ChannelMeetingSessionDrawing,
    ChannelMeetingSessionJoined,
    ChannelMeetingSessionLeave,
    ChannelMeetingSessionPresenterChange,
    ChannelMeetingSessionPresenterFocusChange,
    ChannelMeetingSessionQuickPollCreated,
    ChannelMeetingSessionReactionBatch,
    ChannelMeetingSessionSkillChange,
    ChannelPostMessage,
    ChannelTemplateMessage,
    ChannelUserTyping,
    mapTypeToClass,
    PresenceChange,
    SystemNotificationMessage,
    UserRegistrationComplete,
    UserAffiliationsModified
} from './type';

const parseJsonString = (data) => {
    try {
        return JSON.parse(data);
    } catch (e) {
        console.error(e);

        throw new Error('Unable to parse message data. Invalid message received');
    }
};

export type MessageInstance =
    | ChannelCreate
    | ChannelPostMessage
    | ChannelEditMessage
    | ChannelDeleteMessage
    | ChannelTemplateMessage
    | ChannelUserTyping
    | ChannelMeetingReaction
    | SystemNotificationMessage
    | PresenceChange
    | ChannelMeetingSessionJoined
    | ChannelMeetingSessionLeave
    | ChannelMeetingSessionPresenterChange
    | ChannelMeetingSessionPresenterFocusChange
    | ChannelMeetingSessionChanged
    | ChannelMeetingSessionSkillChange
    | ChannelMeetingSessionCameraToggled
    | ChannelMeetingSessionQuickPollCreated
    | ChannelMeetingSessionDrawing
    | ChannelMeetingSessionReactionBatch
    | AppDownloadInitialInfo
    | UserRegistrationComplete
    | UserAffiliationsModified
    | null;

const MessageFactory = {
    construct: async (data: MessageRecord): Promise<MessageInstance> => {
        if (typeof data === 'string') {
            data = parseJsonString(data);
        }

        const klass = mapTypeToClass(data.type);

        if (!klass) {
            throw new Error('Invalid message');
        }

        const messageInstance = new klass(data);

        messageInstance.setFieldsFromData();

        if (!(await messageInstance.isValid())) {
            throw new Error('Invalid message values for type');
        }

        return messageInstance;
    },

    constructWithoutValidation: (data: MessageRecord): MessageInstance => {
        if (typeof data === 'string') {
            data = parseJsonString(data);
        }

        const klass = mapTypeToClass(data.type);

        if (!klass) {
            throw new Error('Invalid message');
        }

        const messageInstance = new klass(data);

        messageInstance.setFieldsFromData();

        return messageInstance;
    }
};

export default MessageFactory;
