import { useMemo } from 'react';

import { useParams } from 'react-router';

interface UseHasParentCollectionOutput {
    collectionSlugsArray: string[];
    hasParentCollection: boolean;
    collectionSlugs?: string;
    parentQuerySlug?: string;
    tenantSlug?: string;
    skillSlug?: string;
}

export const useCollectionSlugs = (): UseHasParentCollectionOutput => {
    const { skillSlug, tenantSlug, collectionSlugs } = useParams<{
        skillSlug?: string;
        tenantSlug?: string;
        collectionSlugs?: string;
    }>();

    const collectionSlugsArray = useMemo(() => collectionSlugs?.split('/') ?? [], [collectionSlugs]);

    const parentQuerySlug = useMemo(() => {
        const index = skillSlug ? collectionSlugsArray.length - 1 : collectionSlugsArray.length - 2;
        const parentCollectionSlug = collectionSlugsArray[index];

        if (parentCollectionSlug) {
            return `${tenantSlug}/${parentCollectionSlug}`;
        }
    }, [collectionSlugsArray, skillSlug, tenantSlug]);

    const hasParentCollection = useMemo(() => {
        return !!parentQuerySlug;
    }, [parentQuerySlug]);

    return {
        collectionSlugsArray,
        hasParentCollection,
        collectionSlugs,
        parentQuerySlug,
        tenantSlug,
        skillSlug
    };
};
