import React, { useMemo } from 'react';

import { AdeptCard, AdeptCardContent, IconSize, modularScale, PublicIndicator } from '@adept-at/lib-react-components';
import { HoverStyles } from 'components/dash/Dashboard.styles';
import { Link, useLocation, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { formatTime } from 'utils/time';

import BrandingLabel from '../BrandingLabel';
import { BasicDetailsSkillResponse } from '../useGetEntitiesBasicDetails/getEntitiesBasicDetails';

import ActionOverlay from './ActionOverlay';

export const CardPositioningStyles = css`
    width: calc(100% - 2rem);
    max-width: 700px;
    margin: 1rem auto;
`;

const CardWrapper = styled.div<{ $isActive?: boolean }>`
    border: 1px solid ${(props) => (props.$isActive ? 'transparent' : props.theme.colors.border)};
    /* preference for outline because it won't change the card's height */
    outline: ${(props) => (props.$isActive ? `2px solid ${props.theme.colors.primary}` : 0)};
    border-radius: 4px;
    position: relative;
    ${HoverStyles}
    ${CardPositioningStyles}
`;

export const COLLECTION_CONTENT_CARD_WIDTH = '433px';

interface ContentSectionCardProps {
    entity: BasicDetailsSkillResponse;
    isActiveSkill?: boolean;
    index: number;
}

export const ContentSectionCard: React.FC<ContentSectionCardProps> = ({ entity, isActiveSkill = false }) => {
    const { currentOrgId, roomId, tenantSlug, collectionSlugs } = useParams<{
        currentOrgId?: string;
        roomId?: string;
        tenantSlug?: string;
        collectionSlugs?: string;
    }>();
    const location = useLocation();

    const learnUrl = useMemo(() => {
        if (currentOrgId && roomId) return `${location.pathname}/skill/${entity.skillSlug}`;

        if (tenantSlug && collectionSlugs) return `/${tenantSlug}/${collectionSlugs}/skill/${entity.skillSlug}`;

        return `/learn${location.pathname.replace('my-profile/', '')}/skill/${entity.skillSlug}`;
    }, [location.pathname, entity.skillSlug, currentOrgId, roomId, tenantSlug, collectionSlugs]);

    return (
        <CardWrapper $isActive={isActiveSkill}>
            <Link to={{ pathname: learnUrl, state: { from: location.pathname } }}>
                <AdeptCard style={{ boxShadow: 'none' }}>
                    <AdeptCardContent.Skill
                        showContentTypeComponent={false}
                        organizationComponent={<BrandingLabel iconSize={IconSize.Small} />}
                        title={entity.title}
                        imageProps={{ image: entity.images?.catalog }}
                        secondaryInfo={[`${formatTime(entity.estimatedSecondsToConsume ?? 0, 'medium')}`]}
                        style={{ boxShadow: '0' }}
                        lowerRightComponent={<PublicIndicator isPublic={entity.visible} />}
                    />
                </AdeptCard>
            </Link>
            <ActionOverlay learnUrl={learnUrl} skill={entity} />
        </CardWrapper>
    );
};
