import { GraphQLClient } from 'graphql-request';
import gql from 'graphql-tag';
import buildGqlMutationFn from 'hooks/useGqlClient/helpers/buildGqlMutationFn';
import { MutationFunction } from 'react-query';

import { RestoreEntityResult, RestoreEntityVariables } from '.';

export const RESTORE_ENTITY = gql`
    mutation restoreEntity($entityInfo: EntityInfoInput!) {
        restoreEntity(entityInfo: $entityInfo) {
            success
        }
    }
`;

const makeRestoreEntity = (client: GraphQLClient): MutationFunction<RestoreEntityResult, RestoreEntityVariables> =>
    buildGqlMutationFn(client, RESTORE_ENTITY);

export default makeRestoreEntity;
